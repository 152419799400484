import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import {priceFormat} from "../../../../utils/helper";
import _ from "lodash";

class TVPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            type: {
                list:[
                    {
                        id: 1,
                        type: "OPTION",
                        code: "TVPLUS",
                        short_name: "TV플러스(신규)",
                        name:  "신규 가입자",
                        show_name:"결합 신규 가입자",
                        description: "무선 1,100원 / TV 1,100원 할인",
                        price: 1100
                    },
                    {
                        id: 2,
                        type: "OPTION",
                        code: "TVPLUS",
                        short_name: "TV플러스(기존)",
                        name: "기존 가입자",
                        show_name:"결합 기존 가입자",
                        description: "무선 2,200원 할인",
                        price: 2200
                    }
                ],
                selected: null
            },
            line:{
                list: [],
                selected: null
            },
        };
    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }



    /**
     * close modal
     */
    onCloseModal = () => {

    }


    /**
     * detect open modal
     */
    onOpened = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        this.setState({
            type:{
                ...this.state.type,
                selected: this.props.selected && this.props.selected.type ? this.props.selected.type : null
            },
            line: {
                list: plans_line_not_null,
                selected: this.props.selected && this.props.selected.line && this.props.selected.line.idx !== 1
                    ? this.props.selected.line
                    : (plans_line_not_null && plans_line_not_null.length > 0 ? plans_line_not_null[0] : null)
            }
        })
    }


    onApply = (e) => {
        let dataSelected = {
            type: this.state.type.selected,
            line: this.state.line.selected,
        }
        this.setState({
            isShowModal: !this.state.isShowModal,

        }, () => {
            this.props.onApply(dataSelected, "tv_plus");
        })

    }

    cancelApply = (e) => {
        this.setState({
            type:{
                ...this.state.type,
                selected: this.props.selected.type
            },
            line: {
                ...this.state.line,
                selected: this.props.selected.line

            },
        }, () => {
            this.toggleModal(e)
        })
    }
    renderTypeOption = () => {
        return (
            <ul className="list-item mt-0 max-auto">
                <li className={!this.state.type.selected ? "bg-item" : "" }
                    onClick={() => this.setState({
                        type: {
                            ...this.state.type,
                            selected: null
                        },
                        line: {
                            ...this.state.line,
                            selected: this.props.plan && this.props.plan.length > 0 ? this.props.plan[0] : null
                        }
                    })}>
                    <p>미적용</p>
                </li>
                {
                    this.state.type.list.map((item, key) => {
                        return (
                            <li key={key}
                                className={this.state.type.selected && this.state.type.selected.id == item.id ? "bg-item" : ""}
                                onClick={() => this.setState({
                                    type: {
                                        ...this.state.type,
                                        selected: item
                                    },
                                    // line: {
                                    //     ...this.state.line,
                                    //     selected: this.state.line.list[0]
                                    // }
                                })}
                            >
                                <div>
                                    <p>{ item.show_name }</p>
                                    <span>{ item.description }</span>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    renderLineOption = () => {
        return (
            <ul className="list-item mt-0 max-auto">
                {
                    this.state.line.list && this.state.line.list.map( (value, key) => {
                        return (
                            <li key={key}
                                className={`${this.state.line.selected && this.state.line.selected.idx == value.idx ? "bg-item" : ""} ${!this.state.type.selected ? "disabled" : ""}`}
                                onClick={() => this.setState({
                                    line: {
                                            ...this.state.line,
                                            selected: value
                                    }})}
                            >
                                회선 { value.idx}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    componentDidUpdate(prevProps){
        if(prevProps.selected && prevProps.selected !== this.props.selected){
            this.setState({
                type: {
                    ...this.state.type,
                    selected: this.props.selected.type
                },
                line: {
                    ...this.state.line,
                    selected: this.props.selected.line
                },
            })
        }
    }


    render() {
        return(
            <Fragment>
                <div className="form-group mt-5">
                    <label className="text-name text-wrap text-bold">TV 플러스<span
                        className="weight-normal">(가입자구분/회선 선택)</span> </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            //disabled={_.reject(this.props.plans, {plan: null}).length > 0 ? false : true} // nếu k chọn gói phí nào thì disabled
                            onClick={(e) => this.toggleModal(e)}
                    >
                        {
                            this.props.selected && this.props.selected.type
                            && this.props.selected.line
                                ? `${this.props.selected.type.name} / 회선 ${this.props.selected.line.idx}`
                                :  (this.state.type.selected
                                    && this.state.line.selected
                                    ? `${this.state.type.selected.short_name} / 회선 ${this.state.line.selected.idx}`
                                    : "미적용")
                        }
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered tvplus-modal"
                           contentClassName="pr-5 pl-5"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">TV 플러스</h5>
                            <button type="button" className="close close-modal"
                                    onClick={(e) => this.toggleModal(e)}
                            >
                           <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body pr-0 pl-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="top-content h-44">
                                        <h5 className="mb-0">1. 가입자 구분</h5>
                                    </div>
                                    { this.renderTypeOption()}
                                </div>
                                <div className="col-md-6">
                                    <div className="top-content  h-44">
                                        <h5 className="mb-0">2. 할인적용 회선</h5>
                                    </div>
                                    { this.renderLineOption()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 mb-4">
                            <button type="button" className="btn btn-gray pr-5 pl-5"
                                    onClick={(e) => this.cancelApply(e)}
                            >취소</button>
                            <button type="button"
                                    className="btn btn-submit pl-5 pr-5"
                                    onClick={(e) => this.onApply(e)}
                            >확인</button>
                        </div>
                    </Modal>
                </div>

            </Fragment>
        );
    }
}

TVPlus.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(TVPlus);