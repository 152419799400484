 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import WirelessExtraServiceList from './ExtraService/ExtraServiceList'
import WirelessExtraServiceAdd from './ExtraService/ExtraServiceAdd'
import ServiceMoreComponent from '../WirelessAdvice/ServiceMoreModal'

class WirelessExtraService extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mt-2" style={{marginBottom: "2px"}}>
            <h4 className="label-text text-bold mt-3" style={{paddingTop: "4px"}}>
            부가서비스
            </h4>
        </div>
        <WirelessExtraServiceList/>
        <WirelessExtraServiceAdd showExtraServiceList = {this.showExtraServiceList} />
      </Fragment>
    );
  }
}

WirelessExtraService.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessExtraService);
