import React, { Component, Fragment } from 'react';
import ChangeDatePlanModal from './ChangeDatePlanModal';

class ChangePlanInformationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value : '',
            isShowModal : false
        };
    }

    /**
     * show modal plan
     */
    handleShowModal = () => {
        this.setState({
            isShowModal : !this.state.isShowModal
        })
    }

    /**
     * 
     */
    getValuesDate = (value) => {
        this.setState({
            value : value
        });
    }

    render() {
        let {isShowModal} = this.state;
        return(
            <Fragment>
                <div className="flex-wrapper">
                    <div className="left border-0">
                        <div className="form-group row align-items-start">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">변경 가능일자</label>
                            <div className="col-sm-8 pl-2">
                                <input type="button" className="form-control text-left h-44" value={this.state.value ? this.state.value : '변경 가능일자'} onClick={this.handleShowModal}/>
                            </div>
                        </div>
                    </div>
                </div>
                <ChangeDatePlanModal
                    key={isShowModal}
                    isShowModal={isShowModal}
                    toggleModal = {this.handleShowModal}
                    getValuesDate = {this.getValuesDate}
                />
            </Fragment>
        );
    }
}


 
export default ChangePlanInformationComponent;

