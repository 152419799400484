import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import CurrencyInput from 'react-currency-input';
import CurrencyFormat from "react-currency-format";
class OtherModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limitMoney: 0,
            value: 0,
        };
        this.closeRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.WirelessAdvice?.WirelessAdviceStep3?.other) {
            this.setState({
                value: this.props.WirelessAdvice.WirelessAdviceStep3.other.toString().split('.')[0]
            })
        }
    }

    handleChange = (val) => {
        this.setState({
            total: val
        }, () => {
            this.props.setValuePay(this.state.total)

        })
    }

    setMoney = (e, val) => {
        e.preventDefault()
        const {WirelessAdvice} = this.props;
        let other=WirelessAdvice.WirelessAdviceStep3.other
        let  installmentPrincipal = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal;

        if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                let instPrincipalUsedPhone2 = WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

                if((WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35)) < this.state.value){
                    alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                    return;
                }
            }
            else{
                if(Number(installmentPrincipal)+Number(other)<this.state.value){
                    alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                    return;
                }
            }
        }
        else{
            if(Number(installmentPrincipal)+Number(other)<this.state.value){
                alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                return;
            }
        }

        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        this.props.change('WirelessAdviceStep3.other', val)
        this.closeRef.current.click()
    }


    changeMoney = (values) => {
        const {formattedValue, value} = values;
        this.setState({
            value: value,
            profit: formattedValue,
        });


    }
    render() {
        let {
            value,
            limitMoney
        } = this.state;
        const {WirelessAdvice} = this.props;
        let  installmentPrincipal = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal
        let other = WirelessAdvice.WirelessAdviceStep3.other
        const formatter = new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
          });

        if(value == 0){
            value = "";
        }

        return (
            <Fragment>
                {/* modal */}
                <div className="modal fade"
                     // data-backdrop="static"
                     data-keyboard="false"
                     id="modal-90" tabIndex={-1}
                     role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog  modal-dialog-centered modal-custom" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">기타할인 입력</h5>
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                </button>
                            </div>
                            <div className="modal-body px-0">
                                <form onSubmit={e => this.setMoney(e, this.state.value)} className="body-plans row_custom">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-modal-gray mt-8">
                                            할부원금: {
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation
                                                &&
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18
                                                &&
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191
                                                    ? formatter.format(Math.round(WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - (WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100) - ((WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100) / 65 * 35)) - 1).replace('$','')
                                                    : formatter.format(Number(installmentPrincipal)+Number(other)).replace('$','')
                                            }원
                                        </p>
                                    </div>
                                    <div className="form-group row mt-4 align-items-center">
                                        <div className="col-10 pr-0">
                                            {/*<input onChange={this.changeMoney} className="form-control h-44" value={this.state.value} />*/}
                                            <CurrencyFormat thousandSeparator={true} prefix={''}
                                                            value={value}
                                                            onValueChange={(value) => this.changeMoney(value)}
                                                            className="form-control h-44"

                                            />
                                        </div>
                                        <div className="col-2">
                                            <span className="text-wrapper-gray">원</span>
                                        </div>
                                    </div>
                                    <h5  className="text-wrapper-gray text-left color-orange mb-3">
                                        ※할부원금을 초과한 금액을 입력하실 수 없습니다.
                                    </h5>
                                    <h5 className="text-wrapper-gray text-left">
                                        입력한 금액을 적용하시겠습니까?
                                    </h5>
                                    <div className="d-flex justify-content-center border-top-0 mt-5">
                                        <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" data-dismiss="modal" ref={this.closeRef}>취소</button>
                                        <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                {/* end modal */}
            </Fragment>

        );
    }
}

OtherModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(OtherModal);
