import React, {Fragment} from 'react';
import {priceFormat} from "../../../../utils/helper";
import {KT_S23_SBS_INS_GRC_VAL, KT_S23_SBS_PRM_Y_EDT} from "../../../../utils/constant";
import ResultList from '../../CombinedDiscount/ResultList';


class First extends React.Component{

  getPrincipalHtml = () => {
    let result = null;
    let {data} = this.props;
    let tupId  = +(data?.usedPhoneCompensation?.tup_id || 0);

    if(tupId == 191){
      result = <div class="form-group row justify-content-between align-items-start">
        <div class="col-auto">
          <p class="mb-0 label-prices color-blue color-reds">1구간 할부원금</p>
        </div>
        <div class="col-auto">
          <p class="text-right mb-0 prices-blue">
            0
            <span class="small-color">원</span>
          </p>
        </div>
      </div>;
    }
    else if(tupId == 190){
      result = <div className="form-group mt-5  row">
        <div className="col-md-4">
          <p className="mb-0 label-prices color-reds">할부유예금</p>
        </div>
        <div className="col-sm-8">
          <p className="text-right mb-0 prices-blue">
            {priceFormat(KT_S23_SBS_INS_GRC_VAL)}
            <span className="small-color">원</span>
          </p>
        </div>
      </div>;
    }
    else if(tupId == 192){
      result = <Fragment>
        <div className="form-group mt-5  row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-reds">할부유예금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue">
              {priceFormat(KT_S23_SBS_PRM_Y_EDT)}
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
        <div className="form-group mt-5  row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-blue">할부원금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue">
              {priceFormat(data?.installmentPrincipal)}
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
      </Fragment>;
    }
    else{
      result = <div className="form-group mt-5  row">
        <div className="col-md-4">
          <p className="mb-0 label-prices color-blue">할부원금</p>
        </div>
        <div className="col-sm-8">
          <p className="text-right mb-0 prices-blue">
            {priceFormat(data?.installmentPrincipal)}
            <span className="small-color">원</span>
          </p>
        </div>
      </div>;
    }

    return result;
  }

  getIsKtS23PremiumOptionfee0 = () => {
    let {data}        = this.props;
    let tupId         = +(data?.usedPhoneCompensation?.tup_id || 0);
    let result        = false;

    if(tupId == 192){
      result = true;
    }

    return result;
  }

  getPointClassName = () => {
    let result        = "btn btn-input-form mb-0";
    let {data}        = this.props;
    let pointDiscount = +(data?.pointDiscount || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      pointDiscount = 0;
    }

    if(pointDiscount > 0){
      result = result + " btn-prices-wp";
    }

    return result;
  }

  getPointValue = () => {
    let result        = null;
    let {data}        = this.props;
    let pointDiscount = +(data?.pointDiscount || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      pointDiscount = 0;
    }

    if(pointDiscount > 0){
      result = '-' + priceFormat(pointDiscount);
    }
    else{
      result = pointDiscount;
    }

    return result;
  }

  getAdvancedDiscountCardValue = () => {
    let result               = null;
    let {data}               = this.props;
    let advancedDiscountCard = data.advancedDiscountCard;

    if( this.getIsKtS23PremiumOptionfee0() ){
      advancedDiscountCard = null;
    }

    if(advancedDiscountCard){
      result = '-' + priceFormat(advancedDiscountCard) + '원';
    }
    else{
      result = '미적용';
    }

    return result;
  }

  getAdvancedDiscountCardObjectDccName = () => {
    let result                     = null;
    let {data}                     = this.props;
    let advancedDiscountCardObject = data.advancedDiscountCardObject;
    let dccName                    = (data.advancedDiscountCardObject?.dcc_name || "");

    if( this.getIsKtS23PremiumOptionfee0() ){
      advancedDiscountCardObject = null;
    }

    if(advancedDiscountCardObject){
      result = <p class="small-color mb-0 pl-2">{ dccName }</p>;
    }
    
    return result;
  }

  getCashPaymentClassName = () => {
    let result      = "btn btn-input-form mb-0";
    let {data}      = this.props;
    let cashPayment = +(data?.cashPayment || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      cashPayment = 0;
    }

    if(cashPayment > 0){
      result = result + " btn-prices-wp";
    }

    return result;
  }

  getCashPaymentValue = () => {
    let result        = null;
    let {data}        = this.props;
    let cashPayment = +(data?.cashPayment || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      cashPayment = 0;
    }

    if(cashPayment > 0){
      result = '-' + priceFormat(cashPayment);
    }
    else{
      result = cashPayment;
    }

    return result;
  }

  getCashPaymentSub = () => {
    let result        = null;
    let {data}        = this.props;
    let tupId         = +(data?.usedPhoneCompensation?.tup_id || 0);
    let cashPayment = +(data?.cashPayment || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      cashPayment = 0;
    }

    if(tupId == 190 && cashPayment > 0){
      result = <p className="small-color mb-0 pl-2 color-reds">개통 시 즉납 필수</p>;
    }

    return result;
  }

  getOtherClassName = () => {
    let result = "btn btn-input-form mb-0";
    let {data} = this.props;
    let other  = +(data?.other || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      other = 0;
    }

    if(other > 0){
      result = result + " btn-prices-wp";
    }

    return result;
  }

  getOtherValue = () => {
    let result = null;
    let {data} = this.props;
    let other  = +(data?.other || 0);

    if( this.getIsKtS23PremiumOptionfee0() ){
      other = 0;
    }

    if(other > 0){
      result = '-' + priceFormat(other);
    }
    else{
      result = other;
    }

    return result;
  }

  render() {
    let {data} = this.props;

    let other = data.other
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    let monthVal= data.installmentMonth;


    return (
      <div className="left">
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전환지원금</label>
          <div className="col-sm-8">
            <table style={{width : '100%'}}>
              <tr>
                <td style={{width : '50%'}}>
                  <button className={"btn btn-input-form mb-0 " + (data.conversion_price_input > 0 ? ' btn-prices-wp' : '')} type="button">
                    {
                      data.optionFee == 0 ?
                      <Fragment>0<span>원</span></Fragment> :
                      data.manual_conversion_confirm == 1 ?
                        '확인불가' : 
                        <Fragment>
                          { (+data.conversion_price_input != 0 ? '-' : '') + priceFormat(data.conversion_price_input) }
                          <span>원</span>
                        </Fragment>
                        
                    }
                  </button>
                </td>
                <td className="d-flex justify-content-center">
                  <button
                    class="btn btn-gray-white"
                    style={{width : '95%', height : '44px'}}
                    type="button"
                  >
                    { +data.conversion_price_input != 0 ? "미적용" : "적용" }
                  </button>
                </td>
              </tr>
            </table>
            {
              data.optionFee == 0 ?
              "" :
              (data.manual_conversion_confirm == 1 ?
              "" :
              data.conversion_apply_date != null && data.conversion_apply_date != "" ?
              (
                <p class="text-left mb-0 small-color">
                  {"(" + data.conversion_apply_date + " 기준)"}
                </p>
              ) :
              "")
            }
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전환추가지원금</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0 " + (data.conversion_price_input_add > 0 ? ' btn-prices-wp' : '')} type="button">
              {data.optionFee == 0 ?
                0 : 
                (+data.conversion_price_input_add != 0 ?
                  '-' :
                  '') + priceFormat(data.conversion_price_input_add)
              }
              <span>원</span>
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">포인트 할인</label>
          <div className="col-sm-8">
            <button className={ this.getPointClassName() } type="button">
              { this.getPointValue() }
              <span>원</span>
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">선할인 카드</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0"} type="button">
              { this.getAdvancedDiscountCardValue() }
            </button>
            { this.getAdvancedDiscountCardObjectDccName() }
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">중고폰보상 <br />
            프로그램</label>
          <div className="col-sm-8">
            <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-65">
              {data.usedPhoneCompensation && data.usedPhoneCompensation.tup_id ? data.usedPhoneCompensation.tup_name : '미적용'}
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">현금납부</label>
          <div className="col-sm-8">
            <button className={ this.getCashPaymentClassName() } type="button">
              { this.getCashPaymentValue() }
              <span>원</span>
            </button>
            { this.getCashPaymentSub() }
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">기타할인</label>
          <div className="col-sm-8">
            <button className={ this.getOtherClassName() } type="button">
              { this.getOtherValue() }
              <span>원</span>
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4  col-form-label label-text ">할부개월</label>
          <div className="col-sm-8">
            <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-29">
              {monthVal?monthVal:24}개월
            </button>
          </div>
        </div>
        { this.getPrincipalHtml() }
        <div className="form-group row align-items-start mt-4">
          {/*
          <div className="col-md-4">
            <p className="mb-0 label-prices mt-2">할부수수료</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-number">
              {priceFormat(data?.installmentFee)}
              <span className="small-color">원</span></p>
            <p className="text-right mb-0 small-color">
              (원리금균등, 연 {priceFormat(data?.interestRate, 1)}%)
            </p>
          </div>
           */}
          {+(data?.usedPhoneCompensation?.tup_id || 0) == 190
          ? null
          : (<Fragment>
            <div className="col-md-4">
              {+(data?.usedPhoneCompensation?.tup_id || 0) == 191
                ? (<p className="mb-0 label-prices mt-2 color-reds">할부수수료</p>)
                : (<p className="mb-0 label-prices mt-2">할부수수료</p>)
              }
            </div>
            <div className="col-sm-8">
              {+(data?.usedPhoneCompensation?.tup_id || 0) == 191
                ? (<p className="text-right mb-0 prices-number color-reds">
                {priceFormat(data?.installmentFee)}
                <span className="small-color">원</span>
              </p>)
                : (<p className="text-right mb-0 prices-number">
                {priceFormat(data?.installmentFee)}
                <span className="small-color">원</span>
              </p>)
              }
              {+(data?.usedPhoneCompensation?.tup_id || 0) == 191
              ? null
              : (<p className="text-right mb-0 small-color">
                  (원리금균등, 연 {priceFormat(data?.interestRate, 1)}%)
                </p>)
              }
            </div>
          </Fragment>)
          }
        </div>
        <div className="form-group row justify-content-between align-items-start">
          {/* 
          <div className="col-auto">
            <p className="mb-0 label-prices">월 단말 할부금</p>
          </div>
          */}
          {+(data?.usedPhoneCompensation?.tup_id || 0) == 190 || +(data?.usedPhoneCompensation?.tup_id || 0) == 191
          ?
          (<div className="col-auto">
            <p className="mb-0 label-prices color-reds">월 단말 할부금</p>
          </div>)
          :
          (<div className="col-auto">
            <p className="mb-0 label-prices">월 단말 할부금</p>
          </div>)
          }
          <div className="col-auto">
            <p className="text-right mb-0 prices-blue">
              {priceFormat(data?.installmentMonthly)} 
              <span className="small-color">원</span>
            </p>
            {/* 
            <p className="text-right mb-0 small-color">
              {priceFormat(data?.installmentMonthlyPrincipal)}원 + {priceFormat(data?.installmentMonthlyFee)}원
            </p>
            */}
            {+(data?.usedPhoneCompensation?.tup_id || 0) == 190 || +(data?.usedPhoneCompensation?.tup_id || 0) == 191
            ?
            null
            :
            (<p className="text-right mb-0 small-color">
              {priceFormat(data?.installmentMonthlyPrincipal)}원 + {priceFormat(data?.installmentMonthlyFee)}원
            </p>)
            }
          </div>
          
        </div>
      </div>
    )
  }
}


export default First;
