import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import TelcomList from './../Telcom/ListTelcom'
import {Modal} from 'reactstrap';

const ModalReset = ({isOpenModal, closeModal, onSelected}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={closeModal}
      fade={true}
      // backdrop={"static"}
      className={'modal-dialog modal-dialog-centered'}
    >
      <div className="modal-content">
        <div className="modal-body text-center p-5">
          <span className="notification_delete">현재 화면 모두 초기화 됩니다</span>
        </div>
        <div className="modal-footer justify-content-center border-top-0 mb-4">
          <button type="button" className="btn btn_close" onClick={closeModal}>취소</button>
          <button type="button" className="btn btn-submit pl-5 pr-5" onClick={onSelected}>확인</button>
        </div>
      </div>
    </Modal>
  );
}

ModalReset.propTypes = {
  onSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
};

export default ModalReset;

