import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Field, getFormValues, reduxForm,FieldArray} from 'redux-form';
import renderTextField from "../../components/FormHelper/TextField";
import renderByFormatField from "../../components/FormHelper/RenderRB";
import renderRadioField from "../../components/FormHelper/RadioField";
import renderRB from "../../components/FormHelper/RenderRB/RenderRBField";
import renderRBDeduct from "../../components/FormHelper/RenderRB/RenderRBDeductField";
import NumberFormat from "react-number-format";

const SALE_TYPES = ['','기기변경','번호이동','신규가입','중고가입'];
class CreateReportSaleStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrRB: [],
            isDisableMoneyRechargeAtPlaceTransaction: true,
        }
    }



    changeTextArea = (event) => {
        this.props.change('step2.detail_memo_support',event.target.value);
    }

    componentDidMount() {
        this.checkMoneyRechargeAtPlaceTransaction(this.props.consultant);
    }
    handleChangeInput = (e,type) => {
        switch (type) {
            case 1:
                this.props.change('step2.mnp_fee',e.target.value);
                break;
            case 2:
                this.props.change('step2.sim_fee',e.target.value);
                break;
            case 3:
                this.props.change('step2.tax_rate',e.target.value);
                break;
        }
    }

    checkMoneyRechargeAtPlaceTransaction = (data) => {
        if (data.consultant_wireless && data.consultant_wireless.cash_payment_id == 1) {
            this.setState({
                isDisableMoneyRechargeAtPlaceTransaction: false
            })
        } else {
            const { step2 } = this.props.ReportSale;
            if (+step2.discount_installment_fee > 0) {
                this.setState({
                    isDisableMoneyRechargeAtPlaceTransaction: false
                })
            } else if(data.wirelessAdviceStep3.additionalGrants) {
                this.setState({
                    isDisableMoneyRechargeAtPlaceTransaction: false
                })
            }
        }
    }

    render() {
        const { step2 } = this.props.ReportSale;
        let rbTotal = step2.rebail_basic_price? parseFloat(step2.rebail_basic_price.replace(',','')):0;
        const {consultant} = this.props;
        step2.rb.forEach((val,i) => {
            let price = typeof val.price === 'string' || val.price instanceof String ? parseFloat(val.price.replace(',','')) : parseFloat(val.price);
            rbTotal += price || 0
        })
        step2.rbDeduct.forEach((val,i) => {
            let price = typeof val.price === 'string' || val.price instanceof String ? parseFloat(val.price.replace(',','')) : parseFloat(val.price);
            rbTotal += price || 0
        })

        let device_recharge = typeof step2.device_recharge === 'string' || step2.device_recharge instanceof String ? parseFloat(step2.device_recharge.replace(',','')) : parseFloat(step2.device_recharge);
        let spRecharge = step2.support_recharge ? parseFloat(step2.support_recharge.replace(',','')) : 0
        let settlememntFee = +rbTotal + parseFloat(device_recharge) + +step2.mnp_fee + +step2.sim_fee;
        let actual_profit = settlememntFee +
            spRecharge +
            ((settlememntFee +
                spRecharge)*step2.tax_rate)/100;
        this.props.change('step2.total_rb',rbTotal);
        this.props.change('step2.actual_profit',actual_profit);
        return (
            <div className="container">
                <div className="report-sale-step main-step-box">
                    <ul className="step-wireless">
                        <li className="item-step" data-link="/create-report-sale.html">
                            <div className="open-item">
                                <span className="step-name">Step 1</span>
                                <span>개통정보</span>
                            </div>
                        </li>
                        <li className="item-step active">
                            <div className="open-item">
                                <span className="step-name">Step 2</span>
                                <span>차감정보</span>
                            </div>
                        </li>
                    </ul>
                    {this.props.ReportSale.sales_report_id && this.props.consultant.state == 1 ? <button className="btn btn-gray-white h-44 btn-edit-form">판매수정</button> : ''}
                    <div className="box-right">
                        <div className="row">
                            <div className="col-6 text-right">
                                <span className="title">고객명</span>
                            </div>
                            <div className="col-6">
                                <span className="content">{this.props.ReportSale.step1.customer_name}</span>
                            </div>
                            <div className="col-6 text-right">
                                <span className="title">단말기</span>
                            </div>
                            <div className="col-6">
                                <span className="content">{consultant ? consultant.model_name : ''}</span>
                            </div>
                            <div className="col-6 text-right">
                                <span className="title">가입유형</span>
                            </div>
                            <div className="col-6">
                                <span className="content">{SALE_TYPES[this.props.ReportSale.step1.open_type - 1]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="form-box mt-5">
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="content-form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="title-box-line mb-3">
                                                    <span>단말기 판매 정보</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">출고가</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.startingPrice"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">공시지원금</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.disclosureSubsidy"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">추가지원금</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.additionalGrants"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                    </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">할부원금</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.installmentPrincipal"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">선할인카드</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.advancedDiscountCard"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                    <p className="font-10 text-right">
                                                        {consultant.consultant_wireless?.dcc?.dcc_name || ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">포인트 할인</label>
                                                    <Field type="text"
                                                           className="form-control h-44"
                                                           name={"step2.pointDiscount"}
                                                           component={renderByFormatField}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">판매점 프리할부 할인</label>
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           name={"step2.discount_installment_fee"}
                                                    disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">할인메모</label>
                                                    <Field component={renderTextField} type="text"
                                                           className="form-control h-44"
                                                           placeholder="할인메모" name="step2.memo_discount"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-3">
                                                <h5 className="mb-0 label-prices text-wrap title_custom"><span
                                                    className="color-reds">(A)</span> 거래처
                                                    단말기 판매 입금액</h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group mb-0">
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           name={'step2.device_recharge'}
                                                           placeholder="0 원"
                                                           disabled={this.state.isDisableMoneyRechargeAtPlaceTransaction}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="title-box-line mt-5 mb-3">
                                                    <span>R/B 정보 (추가및 차감)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group ">
                                                            <label htmlFor="" className="label-text">기본 R/B</label>
                                                            <Field type="text" className="form-control h-44"
                                                                   component={renderByFormatField}
                                                                   placeholder="0 원"
                                                                   name={'step2.rebail_basic_price'} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group ">
                                                            <label htmlFor="" className="label-text">단가표호수 </label>
                                                            <Field type="text" className="form-control h-44"
                                                                   component={renderTextField}
                                                                   placeholder="단가표호수"
                                                                   name={'step2.unit_price_code'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <FieldArray name="step2.rb" component={renderRB} />

                                        </div>
                                        <FieldArray name="step2.rbDeduct" component={renderRBDeduct} />

                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-3">
                                                <h5 className="mb-0 label-prices text-right text-wrap title_custom pr-custom"><span
                                                    className="color-reds">(B)</span> 지급받을
                                                    리데이트 총액</h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group mb-0">
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                           name={"step2.total_rb"}
                                                           disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(A+B)</span> 거래처에서 받을 정산금</label>
                                                    <NumberFormat type="text" className="form-control h-44"
                                                           value={(+rbTotal + parseFloat(device_recharge) + "원")}
                                                           displayType={'input'}
                                                           thousandSeparator={true}
                                                           suffix={"원"}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group h-100 form-custom">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(C)</span> MNP 수수료</label>
                                                    <div className="row pt-2">
                                                        <div className="col-6">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="item0013"
                                                                       name="mnp_fee"
                                                                       className="custom-control-input"
                                                                       value={0}
                                                                       checked={step2.mnp_fee}
                                                                       onChange={(e) => this.handleChangeInput(e,1)}
                                                                       component={renderRadioField}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="item0013">미적용</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="customRadio2252343"
                                                                       name="mnp_fee"
                                                                       className="custom-control-input"
                                                                       component={renderRadioField}
                                                                       value={800}
                                                                       onChange={(e) => this.handleChangeInput(e,1)}
                                                                       checked={step2.mnp_fee}
                                                                />
                                                                <label className="custom-control-label"
                                                                       htmlFor="customRadio2252343">800
                                                                    원</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group h-100">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(D)</span> 유심</label>
                                                    <div className="row pt-2">
                                                        <div className="col-6">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="item00131"
                                                                       name="step2.sim_fee"
                                                                       className="custom-control-input"
                                                                       value={0}
                                                                       checked={step2.sim_fee}
                                                                       component={renderRadioField}
                                                                       onChange={(e) => this.handleChangeInput(e,2)}
                                                                       />
                                                                <label className="custom-control-label"
                                                                       htmlFor="item00131">미적용</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="customRadio22523431"
                                                                       name="sim_fee"
                                                                       className="custom-control-input"
                                                                       value={7700}
                                                                       checked={step2.sim_fee}
                                                                       component={renderRadioField}
                                                                       onChange={(e) => this.handleChangeInput(e,2)}
                                                                       />
                                                                <label className="custom-control-label"
                                                                       htmlFor="customRadio22523431">7,700
                                                                    원</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(A+B+C+D)</span> 최종 정산금</label>
                                                    <NumberFormat type="text" className="form-control h-44"
                                                           value={+rbTotal + parseFloat(device_recharge) + +step2.mnp_fee + +step2.sim_fee}
                                                                  displayType={'input'}
                                                                  thousandSeparator={true}
                                                                  suffix={"원"}
                                                                  disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="title-box-line mt-3 mb-3">
                                                    <span>고객입금</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">현금</label>
                                                    <Field type="text" className="form-control h-44 "
                                                           name={"step2.cash_recharge"}
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">카드</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44 "
                                                           name={"step2.card_recharge"}
                                                           placeholder="0 원"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">카드 내역 메모</label>
                                                    <Field component={renderTextField} type="text" className="form-control h-44"
                                                           name={"step2.memo_content_recharge"}
                                                           placeholder="카드 내역 메모"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="title-box-line mt-3 mb-3">
                                                    <span>매장지원</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">현금지원</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44"
                                                           name={"step2.support_cash"}
                                                           placeholder="0 원"/>
                                                </div>
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">퀵비</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44"
                                                           name={"step2.quickbee"}
                                                           placeholder="0 원"/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">요금 대납</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44"
                                                           name={"step2.pay_instead_fee"}

                                                           placeholder="0 원"/>
                                                </div>
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">할부금/위약금 대납</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44"
                                                           name={"step2.pay_instead_installment_payment"}

                                                           placeholder="0 원"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">지원내용 상세</label>
                                                    <textarea className="form-control h-135" rows="6"
                                                              value={step2.detail_memo_support}
                                                              name={"detail_memo_support"} onChange={this.changeTextArea}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="title-box-line mt-3 mb-3">
                                                    <span>최종마진</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text color-reds">(A+B+C+D)
                                                        최종 정산금</label>
                                                    <NumberFormat type="text" className="form-control h-44"
                                                           value={settlememntFee}
                                                           displayType={'input'}
                                                           thousandSeparator={true}
                                                           suffix={"원"}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text color-reds">고객입금/
                                                        매장지원</label>
                                                    <Field type="text" className="form-control h-44" component={renderByFormatField}

                                                           placeholder="0 원" name={"step2.support_recharge"}/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">지원내역
                                                        메모</label>
                                                    <Field type="text" className="form-control h-44" component={renderTextField}
                                                           placeholder="지원내역 메모" name={"step2.memo_content_support"}/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group form-red">
                                                    <label htmlFor="" className="label-text color-reds">최종 실
                                                        마진</label>
                                                    <Field type="text"
                                                           component={renderByFormatField}
                                                           name={"step2.actual_profit"}

                                                           className="form-control color-reds text-bold h-44"
                                                           inputCLass="form-control color-reds text-bold h-44"
                                                           disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text mb-4">세금비율</label>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="item00133"
                                                                       name="step2.tax_rate"
                                                                       value={0}
                                                                       className="custom-control-input"
                                                                       component={renderRadioField}
                                                                       onChange={(e) => this.handleChangeInput(e,3)}
                                                                       checked={step2.tax_rate}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="item00133">0%</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="item00132"
                                                                       name="tax_rate"
                                                                       className="custom-control-input"
                                                                       value={10}
                                                                       component={renderRadioField}
                                                                       onChange={(e) => this.handleChangeInput(e,3)}
                                                                       checked={step2.tax_rate}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="item00132">10%</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="custom-control custom-radio mr-0">
                                                                <Field type="radio" id="item001326"
                                                                       name="tax_rate"
                                                                       className="custom-control-input"
                                                                       value={13}
                                                                       component={renderRadioField}
                                                                       onChange={(e) => this.handleChangeInput(e,3)}
                                                                       checked={step2.tax_rate}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="item001326">13%</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="main-btn-submit mt-5">
                                            <div className="group-button w-100">
                                                <button className="btn btn-button btn-gray mr-2 ml-2"
                                                        type="button" onClick={this.props.prev}>취소
                                                </button>
                                                <button type="submit"
                                                        className="btn btn-button btn-submit mr-2 ml-2" >판매일보
                                                    저장
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ReportSale: getFormValues('ReportSale')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};

const FORM_NAME = 'ReportSale';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
}, mapStateToProps);



const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(CreateReportSaleStep2);

