import * as firebase from 'firebase/app';
import "firebase/messaging";
import axios from "../utils/axios";

const firebaseConfig = {
    apiKey: "AIzaSyC3CGjOhZNuNzhmnVdQCaeAHmTddoKjJm8",
    authDomain: "tsc---telecom-sales-cons-349cd.firebaseapp.com",
    databaseURL: "https://tsc---telecom-sales-cons-349cd.firebaseio.com",
    projectId: "tsc---telecom-sales-cons-349cd",
    storageBucket: "tsc---telecom-sales-cons-349cd.appspot.com",
    messagingSenderId: "574419186807",
    appId: "1:574419186807:web:28194f10f3d5dc8a058272",
    measurementId: "G-0EMMG9B5CZ"
};

const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey("BBJ0_0mmZxUZD2ZIbit3DGfgY2QA9sfsjPX-6o7zW2uB0qBU4iQYtdOk1d0dcsQazKZiFkD-3UePYBAfSlrfghU");
}
export { messaging }

export const isTokenSentToServer = () => {
    return window.localStorage.getItem('sentToServer') == 1;
}

export const setTokenSentToServer = (sent) => {
    window.localStorage.setItem('sentToServer', sent ? 1 : 0);
}

export const getRegToken = (argument) => {
   
    messaging.getToken()
        .then(currentToken => {

            if (currentToken) {
                saveToken(currentToken);
            } else {
                setTokenSentToServer(false);
            }
        })
        .catch(err => {
            window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
            window.indexedDB.deleteDatabase("fcm_token_details_db");
            window.indexedDB.deleteDatabase("firebase-installations-database");
            window.indexedDB.deleteDatabase("fcm_vapid_details_db");
            messaging.getToken().then((refreshedToken) => {
                if (refreshedToken) {
                    saveToken(refreshedToken);
                } else {
                    setTokenSentToServer(false);
                }
            }).catch(err => {
            })
        });
}

export const saveToken = (currentToken) => {
    window.localStorage.setItem('fb_token', currentToken);
    // axios.post('save-token-firebase', {
    //     token: currentToken
    // }).then(res => {
    //     window.localStorage.setItem('fb_token', currentToken);
    //     setTokenSentToServer(true);
    // }).catch(err => {
    //     setTokenSentToServer(false);
    // });
}



