 
import { Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {caclWirelessStep3,optionalAgreementCT,priceFormat, priceFormatDiff, planEstimateCT, welfareDiscountCT} from "../../../utils/helper";
// import PlanInformationModal from './PlanInformationModal';
import PlanInformationModal from '../PackageFee/PlanInformationModal';
// import { OPTION_FEE, INSTALLMENT_PAYMENT_METHOD } from './../utils/constant'
class WirelessPackageFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModal : false,
      resultPackage : '',
      modalUnApply: false,
      activePlan: false
    }
  }

  /**
 * show modal plan
 */
  handleShowModalPlan = () => {
    this.setState({
        isShowModal : true
    })
  }

  /**
   * 
   */
  toggleModal = () => {
    this.setState({
        isShowModal : false,
        modalUnApply: false,
        activePlan: true
    })
  }

  planCustomResultPackage = (data) => {
    const { WirelessAdvice } = this.props;

    if(WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18){
      data.planCustom.disclosure_price_six_plan = data.disclosure_price_six_plan;
      data.planCustom.disclosure_price_basic_plan = data.disclosure_price_basic_plan;
    }

    this.props.change('WirelessAdviceStep3.namePlanNewDateChange', data.planCustom.plac_name)
    this.props.change('WirelessAdviceStep3.planNewDateChange', data.costDistplay)
    this.props.change('WirelessAdviceStep3.dataPlanNewDateChange', data.planCustom)
    this.props.change('WirelessAdviceStep3.newPlanData', data.planId);
    // this.optionalAgreementCT(data.costDistplay/1.1, data.costCalc/1.1, 999, data.planId);

    if(WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18){
      let disclosureSubsidyDifferenceDefault = 0;
      let disclosureSubsidy                  = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;

      if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType == "six"){
        disclosureSubsidyDifferenceDefault = data.disclosure_price_six_plan;
      }
      else{
        disclosureSubsidyDifferenceDefault = data.disclosure_price_basic_plan;
      }

      this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', disclosureSubsidyDifferenceDefault);
      this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidy - disclosureSubsidyDifferenceDefault);
    }

    console.log("planCustomResultPackage data : ", data);

    let cost_display = (+data.planId.cost_display+data.planId.cost_display*10/100);
    let cost_calc = (+data.planId.cost_calc+data.planId.cost_calc*10/100);
    let customPlan = 999;
    let dataPlan = data.planId;
    let commitmentRate ;
    if (data.planId.spec_cmm == 1) {
      commitmentRate = +data.planId.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }
    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;

    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);

    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreementPlanEstimated', 0);
    } else {
      this.props.change('WirelessAdviceStep3.optionalAgreementPlanEstimated', result.optionalAgreement);
    }
    
    /*
    let WirelessAdviceStep3 = {
      ...WirelessAdvice.WirelessAdviceStep3,
      dataPlanNewDateChange: data.planCustom,
      optionalAgreementPlanEstimated: result.optionalAgreement,
      rateAgreement: result.rateAgreement,
      basicCost: cost_display,
      basicCostCalc: cost_calc
    }
    let welfareDiscountCTResult = 0;

    if (WirelessAdvice.WirelessAdviceStep3.welfareDiscount) {
      welfareDiscountCTResult = welfareDiscountCT(WirelessAdviceStep3);
    }

    WirelessAdviceStep3 = {
      ...WirelessAdviceStep3,
      welfareDiscount:  welfareDiscountCTResult
    }

    let CT = planEstimateCT(0, WirelessAdviceStep3, 2, true);
    
    this.props.change('WirelessAdviceStep3.estimatedRatesChangingPlans', CT.monthlyFee + CT.installmentMonthly);
    //
    */
  } 

  planResultPackage = async (data) => {
    const { WirelessAdvice } = this.props;
    await this.props.change('WirelessAdviceStep3.namePlanNewDateChange', data.name)
    await this.props.change('WirelessAdviceStep3.planNewDateChange', +data.cost_display+data.cost_display*10/100)
    await this.props.change('WirelessAdviceStep3.dataPlanNewDateChange', data)
    await this.props.change('WirelessAdviceStep3.newPlanData', null);

    if(WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18){
      let disclosureSubsidyDifferenceDefault = 0;
      let disclosureSubsidy                  = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;

      if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType == "six"){
        disclosureSubsidyDifferenceDefault = data.disclosure_price_six_plan;
      }
      else{
        disclosureSubsidyDifferenceDefault = data.disclosure_price_basic_plan;
      }

      await this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', disclosureSubsidyDifferenceDefault);
      await this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidy - disclosureSubsidyDifferenceDefault);
    }

    console.log("planResultPackage data : ", data);
    
    let cost_display = (+data.cost_display+data.cost_display*10/100); 
    let cost_calc = (+data.cost_calc+data.cost_calc*10/100);
    let customPlan = 1;
    let dataPlan = data;
    let commitmentRate ;
    if (data.spec_cmm == 1) {
      commitmentRate = +data.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }
    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;
    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);
    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreementPlanEstimated', 0);
    } else {
      this.props.change('WirelessAdviceStep3.optionalAgreementPlanEstimated', result.optionalAgreement);
      //삭제 필요
      //console.log("요금제 변경 시 해당 값 변경 화면으로 진입 : ", result.optionalAgreement);
    }

    this.props.change('WirelessAdviceStep3.rateAgreementEstimate', result.rateAgreement)
    
    // let WirelessAdviceStep3 = {
    //   ...WirelessAdvice.WirelessAdviceStep3,
    //   dataPlanNewDateChange: data,
    //   optionalAgreementPlanEstimated: result.optionalAgreement,
    //   rateAgreement: result.rateAgreement,
    //   basicCost: cost_display,
    //   basicCostCalc: cost_calc
    // }
    // let welfareDiscountCTResult = 0;
    // if (WirelessAdvice.WirelessAdviceStep3.welfareDiscount) {
    //   welfareDiscountCTResult = welfareDiscountCT(WirelessAdviceStep3);
    // }
    
    // WirelessAdviceStep3 = {
    //   ...WirelessAdviceStep3,
    //   welfareDiscount:  welfareDiscountCTResult
    // }
    // let CT = planEstimateCT(0, WirelessAdviceStep3, 2, true);
    // this.props.change('WirelessAdviceStep3.estimatedRatesChangingPlans', +CT.monthlyFee + (+CT.installmentMonthly))
  }

  

  handleShowModalUnApplyPlan = () => {
    const { WirelessAdvice } = this.props;
    if (WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange == null) {
      this.handleShowModalPlan();
    } else {
      this.setState({
        modalUnApply: true
      })
    }
    
  }

  toggle = () => {
    this.setState({
      modalUnApply: !this.state.modalUnApply
    })
  }

  handleResetPlan = () => {
    this.props.change('WirelessAdviceStep3.namePlanNewDateChange', null)
    this.props.change('WirelessAdviceStep3.planNewDateChange', 0)
    this.props.change('WirelessAdviceStep3.dataPlanNewDateChange', null)
    this.props.change('WirelessAdviceStep3.newPlanData', null)
    this.props.change('WirelessAdviceStep3.optionalAgreementPlanEstimated', 0);
    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', null);
    this.setState({
      activePlan: false
    })
    this.toggle();
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let {isShowModal, resultPackage} = this.state;
    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">변경 요금제</label>
          <div className="col-sm-8">
            <input 
              type="button" 
              className="form-control text-left h-44" 
              value={
                WirelessAdvice.WirelessAdviceStep3.namePlanNewDateChange 
                ? WirelessAdvice.WirelessAdviceStep3.namePlanNewDateChange
                  ? WirelessAdvice.WirelessAdviceStep3.namePlanNewDateChange
                  : WirelessAdvice.WirelessAdviceStep3.namePlanNewDateChange
                : '변경할 요금제'
              }
              onClick={this.handleShowModalUnApplyPlan}
              // onClick={this.handleShowModalPlan}
            />
          </div>
        </div>
        <Modal 
            isOpen={this.state.modalUnApply} 
            toggle={this.toggle}
            // backdrop={"static"}
            className="modal-small modal-dialog-centered modal-additional"
            contentClassName="pl-4 pr-4"
            // onOpened={this.setValueOpen}
        >
          <div className="modal-header">
            <h5 className="modal-title">변경 요금제</h5>
            {/* <Button type="button" className="close close-modal" onClick={this.toggle}>
              <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </Button> */}
          </div>
          <div className="modal-body">
            <ul className="list-additional">
              <li className={`${!this.state.activePlan ? 'active' : ''}`} onClick={this.handleResetPlan}>
                미적용
              </li>
              <li className={`${this.state.activePlan ? 'active' : ''}`} onClick={this.handleShowModalPlan}>
                요금제 변경
              </li>
            </ul>
          </div>
        </Modal>
        
        <PlanInformationModal  
          toggleModal = {this.toggleModal} 
          isShowModal = {isShowModal}
          planCustomResultPackage = {this.planCustomResultPackage}
          planResultPackage = {this.planResultPackage}
          checkPlanNew = {'planSecond'}
        />
    </Fragment>
    );
  }
}

WirelessPackageFee.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessPackageFee);
