import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import api from "./../../utils/api";
import {
    getPricePackages
} from '../../store/actions/dataStore';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import Moment from 'react-moment';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import renderTextField from '../../components/FormHelper/TextField';
import renderRadioField from '../../components/FormHelper/RadioField';
import renderTextFields from '../../components/FormHelper/TextFieldArray';
import renderCheckboxField from '../../components/FormHelper/CheckboxField';
import renderSelectField from '../../components/FormHelper/SelectField';
import HyosungCMSMember from '../../components/Payment/HyosungCMSMember';
import HyosungCMSButton from '../../components/Payment/HyosungCMSButton';
import PaymentPackageList from '../../components/PaymentPackage/PaymentPackageList';
import * as helper from '../../utils/helper';
import * as routerName from '../../utils/routerName';
import moment from 'moment';
import { updateExpireDate, updateExpiredDate } from '../../store/actions/auth';
import * as constant from '../../utils/constant';
import { set } from 'lodash';
class PaymentManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coupons: {
                data: []
            },
            responseCoupStatus: false,
            pricePackageSelected: {},
            coupondSelected: {},
            applyCoupond: false,
            pricePackageSelected: {},
            extraService: {
                useAlarm: {
                    state: false,
                    price: 0
                },
            },
            error_payment_option_periodically: null
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pricePackages.data.length > 0 && prevState.responseCoupStatus == false) {
            this.setState({
                responseCoupStatus: true
            })
            this.onChangeGetListCoupon(1)
        }
    }


    convertSale = (data1, data2) => {
        return (data2 - data1) / data2 * 100;
    }


    onChangeGetListCoupon = (package_id) => {
        this.props.change('package_id', package_id)
        this.props.change('coupon_code', '')
        this.props.change('useAlarm', false)
        setTimeout(() => {
            let data = {
                package_id: this.props.PaymentManagement.package_id,
                per_page: 999
            }
            let pp_key = this.props.pricePackages.data.findIndex((item) => item.pp_id == this.props.PaymentManagement.package_id)
            this.setState({
                pricePackageSelected: this.props.pricePackages.data[pp_key],
                coupondSelected: {},
                extraService: {
                    ...this.state.extraService,
                    useAlarm: {
                        state: false,
                        price: 0
                    }
                }
            })
            api.profile.getListCoupon(data).then(res => {
                this.setState({
                    coupons: res.data.data.coupons
                })
            }).catch(err => {
            })
        })


    }

    onClickChangeMethodCoupond = (data) => {
        this.props.change('coupond_method', data);
        this.props.change('coupon_code', '')
        this.setState({
            applyCoupond: false
        })
    }

    onChangeSeletedCoupon = (e) => {
        if (e.target.value !== '') {
            let coup_key = e.target[e.target.selectedIndex].getAttribute('data-key');
            let coupondSelected = this.state.coupons.data[coup_key];
            this.setState({
                coupondSelected,
                applyCoupond: false
            })
        } else {
            this.setState({
                coupondSelected: {},
                applyCoupond: false
            })
        }
    }

    onChangeResetApply = (data) => {
        this.setState({
            applyCoupond: false,
            error_payment_option_periodically:null
        })
        if(this.props.PaymentManagement.payment_method == '0' && this.renderDay() == 0){
            if(data !== constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW) {
                this.props.change('payment_option_periodically', data)
            }
        } else {
            this.props.change('payment_option_periodically', data)
        }
    }

    onChangeResetMethod = () => {
        this.props.change('coupon_code', '')
        this.props.change('payment_option_periodically',  constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH)
        this.setState({
            coupondSelected: {},
            applyCoupond: false,
            error_payment_option_periodically:null
        })
    }




    applyCoupondFunc = (e) => {
        e.preventDefault();
        if(this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT && this.props.PaymentManagement?.payment_option_periodically == constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH) {
            this.setState({
                error_payment_option_periodically: '쿠폰 적용 유형을 선택해 주세요!'
            })
        } else {
            this.setState({
                applyCoupond: true,
                error_payment_option_periodically: null
            }, () => {
                if (this.props.PaymentManagement.coupond_method == constant.PAYMENT.METHOD_ENTER_COUPOND) {
                    let data = {
                        coup_code: this.props.PaymentManagement.coupon_code,
                        package_id: this.props.PaymentManagement.package_id
                    }
                    this.props.showLoading()
                    api.profile.getCouponDetail(data).then(res => {
                        this.props.hideLoading()
                        if (res.status == 200) {
                            console.log(res.data)
                            this.setState({
                                coupondSelected: res.data.data.coupon,
                                
                            })
                        } else {
                            this.popupShowMessageError('유효하지 않은 쿠폰번호입니다.<br/>다시 입력해 주세요!')
                            this.setState({
                                coupondSelected: {},
                                applyCoupond: false
                            })
                        }
                    }).catch(err => {
                        this.props.hideLoading()
                        this.popupShowMessageError('유효하지 않은 쿠폰번호입니다.<br/>다시 입력해 주세요!')
                        this.setState({
                            coupondSelected: {},
                            applyCoupond: false
                        })
                    })
                }
            })
            
        }
        
        
    }

    popupShowMessageError = (title) => {
        let xhtml = (
            <div className="content-register-modal content-payment-custom">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center" dangerouslySetInnerHTML={{ __html: title }}>
                </h4>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }
        this.props.showModal(dataModal);
    }

    onChangeInputTextCoupond = () => {
        this.setState({
            applyCoupond: false
        })
    }

    onChangeUseAlarm = () => {
        setTimeout(() => {
            this.setState({
                extraService: {
                    useAlarm: {
                        state: this.props.PaymentManagement.useAlarm,
                        price: this.props.PaymentManagement.useAlarm == true ? parseFloat(this.props.priceExtraService) : 0
                    },
                }
            })
        })
    }

    renderTotalPriceMonth = () => {
        /*let price_package = this.state.pricePackageSelected?.pp_price 
        ? this.state.pricePackageSelected?.pp_price 
        : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        */
        let price_package = (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        ? (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        : this.state.pricePackageSelected?.pp_price 
        
        let price_alarm = this.state.extraService?.useAlarm.price ? this.state.extraService?.useAlarm?.price : 0
        let total = parseFloat(price_package) + parseFloat(price_alarm)
        if (this.props.PaymentManagement) {
            if (this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) {
                return total * (this.props.PaymentManagement.useMonth);
            } else {
                return total
            }
        }
        return 0;
    }


    renderTotalPriceDay = () => {
        /*let price_package = this.state.pricePackageSelected?.pp_price 
        ? this.state.pricePackageSelected?.pp_price 
        : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        */
        let price_package = (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        ? (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        : this.state.pricePackageSelected?.pp_price

        let price_alarm = this.state.extraService.useAlarm.price ? this.state.extraService.useAlarm.price : 0
        let total = parseFloat(price_package) + parseFloat(price_alarm)
        var now = moment() > moment(this.props.auth.storePlanLast?.expired_date) ? moment() : moment(this.props.auth.storePlanLast?.expired_date);
        var mothNowToday = moment(now.format('YYYY-MM'), "YYYY-MM").daysInMonth()
        var numberDay = now.format('D');

        // if(this.props.auth.storePlanLast.isFree && this.props.auth.storePlanLast.isFree == '1') {
        //     return total / mothNowToday * (mothNowToday - numberDay);
        // } else {
        //     return 0;
        // }
        return total / mothNowToday * (this.renderDay());
    }

    renderPackagePriceDay = () => {
        /*let price_package = this.state.pricePackageSelected?.pp_price 
        ? this.state.pricePackageSelected?.pp_price 
        : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        */
        let price_package = (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        ? (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        : this.state.pricePackageSelected?.pp_price

        let price_alarm = this.state.extraService.useAlarm.price ? this.state.extraService.useAlarm.price : 0
        let total = parseFloat(price_package)
        var now = moment() > moment(this.props.auth.storePlanLast?.expired_date) ? moment() : moment(this.props.auth.storePlanLast?.expired_date);
        var mothNowToday = moment(now.format('YYYY-MM'), "YYYY-MM").daysInMonth()
        var numberDay = now.format('D');

        return total / mothNowToday * (this.renderDay());
    }

    renderAlarmPriceDay = () => {
        let price_package = this.state.pricePackageSelected.pp_price ? this.state.pricePackageSelected.pp_price : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        let price_alarm = this.state.extraService.useAlarm.price ? this.state.extraService.useAlarm.price : 0
        let total = parseFloat(price_alarm)
        var now = moment() > moment(this.props.auth.storePlanLast.expired_date) ? moment() : moment(this.props.auth.storePlanLast.expired_date);
        var mothNowToday = moment(now.format('YYYY-MM'), "YYYY-MM").daysInMonth()
        var numberDay = now.format('D');

        return total / mothNowToday * (this.renderDay());
    }

  
    

    renderDay = () => {
        var now = moment() > moment(this.props.auth.storePlanLast?.expired_date) ? moment() : moment(this.props.auth.storePlanLast?.expired_date);
        var mothNowToday = moment(now.format('YYYY-MM'), "YYYY-MM").daysInMonth()
        var numberDay = now.format('D')

        return mothNowToday - numberDay + 1;
    }

    getDateEndMonthNow = () => {
        var now = moment() > moment(this.props.auth.storePlanLast?.expired_date) ? moment() : moment(this.props.auth.storePlanLast?.expired_date);
        var mothNowToday = moment(now.format('YYYY-MM'), "YYYY-MM").endOf('month')

        return mothNowToday.format('YYYY-MM-DD');
    }

    getDateNow = () => {
        var now = moment() > moment(this.props.auth.storePlanLast?.expired_date) ? moment() : moment(this.props.auth.storePlanLast?.expired_date);

        return now.format('YYYY-MM-DD');
    }

    renderTotal = () => {
        if(this.props.PaymentManagement && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT) {
            return this.renderTotalPriceDay()
        }   
        return this.renderTotalPriceMonth() + this.renderTotalPriceDay()
    }

    renderPriceCouponDiscountMonth = () => {

        let total = this.renderTotalPriceMonth() | 0;
        let price_coupond = parseFloat(this.state.coupondSelected.coup_discount_price | this.state.coupondSelected.coup_discount_percent / 100 * total | 0);
        if (this.state.applyCoupond == true) {
            if (price_coupond > 0) {
                return price_coupond
            }
            return 0
        } else {
            return 0
        }

    }

    renderPriceCouponDiscountDay = () => {

        let total = this.renderTotalPriceDay() | 0;
        
        let price_coupond = parseFloat(this.state.coupondSelected.coup_discount_price | this.state.coupondSelected.coup_discount_percent / 100 * total | 0);
        if (this.state.applyCoupond == true) {
            if (price_coupond > 0) {
                
                return price_coupond
            }
            return 0
        }
        return 0
    }

    renderTotalDiscountMonth = () => {
        let total         = this.renderTotalPriceMonth() | 0.0;
        let price_coupond = this.renderPriceCouponDiscountMonth() | 0.0;

        if (this.state.applyCoupond && this.props.PaymentManagement.payment_option_periodically == constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT) {
            if (total - price_coupond > 0) {
                return Number((this.renderTotalPriceDay() - this.renderPriceCouponDiscountDay()).toFixed())
            }

            return 0
        }
        else {
            //return Number(this.renderTotalPriceDay().toFixed())
            return total;
        }

    }
    
    

    renderTotalDiscountDay = () => {
        let total = this.renderTotalPriceDay() | 0.0;
        let price_coupond = this.renderPriceCouponDiscountDay() | 0.0;

        if (this.state.applyCoupond == true && this.props.PaymentManagement.payment_option_periodically == constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW) {
            if (total - price_coupond > 0) {
                return Number((this.renderTotalPriceDay() - this.renderPriceCouponDiscountDay()).toFixed())
            }
            return 0
        } else {
            return Number(this.renderTotalPriceDay().toFixed())
        }

    }
    
    renderTotalDiscount  = () => {
        if(this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT) {
            return Math.floor(this.renderTotalDiscountDay() / 10 ) * 10;
        } else {
            return Math.floor((this.renderTotalPriceMonth() + this.renderTotalDiscountDay() - (this.renderPriceCouponDiscountMonth() + this.renderPriceCouponDiscountDay())) / 10) * 10
        }
    }

    onSave = (data) => {
        if (data.rulesChecked1 == true && data.rulesChecked2 == true) {
            let dataRequest = {
                'coupon_code': data.coupon_code,
                'package_id': data.package_id,
                'payment_method': data.payment_method,
                'payment_state': data.payment_state ? '1' : '0',
                'payment_status': '1',
                'useDay': data.useDay,
                'useMonth': data.useMonth,
                'useAlarm': data.useAlarm == true ? '1' : '0',
                'coupond_method': data.coupond_method,
                'discountPrice': data.discountPrice,
                'packagePrice': data.packagePrice,
                'accountErrStatus': this.props.auth.accountErrStatus,
                memberID: this.props.PaymentHyosungCMS.memberId,
                paymentKind: this.props.PaymentHyosungCMS.paymentKind,
                payment_option_periodically: data.payment_option_periodically,
                payment_month: this.renderTotalDiscountMonth(),
                transaction_id: data.transactionID,
                transaction_status: data.transaction_status,
            }
            if (data.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT) {
                dataRequest.useMonth = '1'
            } else {
                dataRequest.useMonth = data.useMonth
            }
            api.auth.packageRenewal(dataRequest).then(res => {
                if (res.status == 200) {
                    if (this.props.auth.accountErrStatus == 1) {
                        this.props.updateExpiredDate(res.data.data)
                    } else {
                        this.props.updateExpireDate(res.data.data)
                    }

                    if (data.payment_method == 1) {
                        this.props.history.push(routerName.PAYMENT_MANAGEMENT);
                        this.popupShowRegisterPlanMethodTwoSuccess();
                    } else {
                        this.props.history.push(routerName.PAYMENT_MANAGEMENT);
                        this.popupShowRegisterPlanMethodOneSuccess()
                    }
                }
            }).catch(err => {
                console.log(err)
                this.popupShowRegisterPlanFail();
            })
        } else {
            this.popupShowCheckedRules()
        }

    }

    popupShowCheckedRules = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5"></h4>
                <p className="text-center">
                    이용약관을 확인하여 체크해 주세요!
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok mt-5"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'customd_01',
        }
        this.props.showModal(dataModal);
    }

    popupShowRegisterPlanMethodTwoSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    {this.props.PaymentManagement.useMonth} 개월 선결제가 완료되었습니다.
                </h4>
                <p className="text-center">
                    모비셀 사용요금은 선불이며, 서비스 이용기간 종료 후 결제가 이루어지지 않은 경우 프로그램 사용이 정지됩니다.
                </p>
                <button type="button" className="btn btn-secondary btn-ok mt-5" onClick={() => { this.props.history.push(routerName.PAYMENT_HISTORY); this.props.hideModal() }}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal_payment_notifi customd_01',
        }
        this.props.showModal(dataModal);
    }

    popupShowRegisterPlanMethodOneSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    정기결제 등록이 완료되었습니다.
                </h4>
                <p className="text-center">
                    모비셀 사용요금은 선불이며, <span className="text-danger">매월 말일</span> 등록하신 정보로 <br /> 정기결제가 진행됩니다.
                </p>
                <p className="text-center">
                    결제가 이루어지지 않은 경우 정기결제가 해지되며,<br />프로그램 사용이 정지됩니다.
                </p>
                <button type="button" className="btn btn-secondary btn-ok mt-5" onClick={() => { this.props.history.push(routerName.PAYMENT_HISTORY); this.props.hideModal() }}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal_payment_notifi',
        }
        this.props.showModal(dataModal);
    }

    popupShowRegisterPlanFail = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    개월 선결제가 실패
                </h4>
                <p className="text-center">
                    다시 확인해 주세요!
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }
        this.props.showModal(dataModal);
    }

    paymentSuccess = (transactionID, transaction_status) => {
        this.onSave({
            ...this.props.PaymentManagement,
            transactionID,
            transaction_status,
            useDay: this.renderDay(),
            discountPrice: this.renderTotalDiscount(),
            packagePrice: this.renderTotal()
        })
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <section className="container">
                    <form className="form-register register-user payment-new">
                        <div className="payment-top mt-5">
                            <div className="left">
                                {/* 개월 로그인 단말기 무제한 - {helper.priceFormat(this.props.auth.storePlan.discount_price)}  won */}
                                {
                                    this.props.auth.storePlan ? (
                                        <React.Fragment>
                                            <p className="text-payment">
                                                - 동시 로그인 허용 개수:
                                                <span className="text-blue text-bold ml-1">
                                                    {moment(this.props.auth.storePlan?.expired_date).diff(moment(this.props.auth.storePlan?.purchase_date), 'months')}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 사용 중인 요금제:
                                                <span className="text-blue text-bold ml-1">
                                                    {this.props.auth?.storePlan?.price_package?.pp_name}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 등록일:
                                                <span className="text-blue text-bold">
                                                    <Moment format=" YYYY/MM/DD">
                                                        {this.props.auth.storePlan?.purchase_date}
                                                    </Moment>
                                                </span>
                                                <span className="border-pay"></span>
                                                종료일:
                                                <span className="color-orange text-bold">
                                                    <Moment format=" YYYY/MM/DD">
                                                        {this.props.auth.storePlan?.expired_date}
                                                    </Moment>
                                                </span>
                                            </p>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <p className="text-payment">
                                                - 동시 로그인 허용 개수:
                                                <span className="text-blue text-bold ml-1">
                                                    {moment(this.props.auth.storePlan?.expired_date).diff(moment(this.props.auth.storePlan?.purchase_date), 'months')}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 사용 중인 요금제:
                                                <span className="text-blue text-bold ml-1">
                                                    {this.props.auth?.storePlan?.price_package?.pp_name}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 등록일: ...
                                                <span className="border-pay"></span>
                                                종료일: ...
                                            </p>
                                        </React.Fragment>
                                    )
                                }

                                <p className="text-payment">아래에서 원하시는 요금제 선택 후 저장해주세요.<br />
                                선택하신 요금제는 현재 사용 중이신 요금제의 종료일 다음날부터 적용됩니다.</p>
                            </div>
                        </div>
                        <PaymentPackageList
                            pricePackages={this.props.pricePackages}
                            activePlan={this.props.PaymentManagement}
                            hover
                            onClickDetail={(item) => this.onChangeGetListCoupon(item.pp_id)}
                        />
                        {/*{!(this.state.pricePackageSelected && this.state.pricePackageSelected.isAlarm ==  constant.PAYMENT.CHECKED_ALARM) ? (
                            <Fragment>
                                <div className="title-form">
                                    <h2>
                                        부가서비스
                                </h2>
                                </div>
                                <div className="extra-service">
                                    <div className="extra-item bg-white">
                                        <div className="item">
                                            <h4>부가서비스명</h4>
                                        </div>
                                        <div className="item">
                                            <h4>이용요금 <span>(VAT포함) </span></h4>
                                        </div>
                                    </div>
                                    <div className="extra-item">
                                        <div className="item">
                                            <div className="form-check">
                                                <div className="custom-control custom-checkbox mr-sm-2">
                                                    <Field
                                                        name="useAlarm"
                                                        id="useAlarm"
                                                        component={renderCheckboxField}
                                                        checked={this.props.PaymentManagement && parseInt(this.props.PaymentManagement.useAlarm)}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                    />
                                                    <label className="custom-control-label text-extra" htmlFor="useAlarm" onClick={() => this.onChangeUseAlarm()}>일정알림</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <p className="text-extra">월 {helper.priceFormat(this.props.priceExtraService)} 원 </p>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : ('')}*/}
                        <div className="title-form">
                            <h2 className="px-5">
                                쿠폰적용
                            </h2>
                        </div>
                        <div className="content-form mt-5">
                            <ul className="nav nav-tabs list-tab border-0 justify-content-center mb-4" >
                                <li className="nav-item tab-item">
                                    <a className={"nav-link " + (this.props.PaymentManagement && this.props.PaymentManagement.coupond_method == constant.PAYMENT.METHOD_SELECTED_COUPOND ? 'active' : '')}
                                        onClick={() => this.onClickChangeMethodCoupond(constant.PAYMENT.METHOD_SELECTED_COUPOND)}>
                                        내 쿠폰 리스트에서 선택
                                    </a>
                                </li>
                                <li className="nav-item tab-item">
                                    <a className={"nav-link " + (this.props.PaymentManagement && this.props.PaymentManagement.coupond_method == constant.PAYMENT.METHOD_ENTER_COUPOND ? 'active' : '')}
                                        onClick={() => this.onClickChangeMethodCoupond(constant.PAYMENT.METHOD_ENTER_COUPOND)}>
                                        쿠폰 입력
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="one-tab">
                                    <form onSubmit={this.applyCoupondFunc}>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <label className="label-text">{this.props.PaymentManagement && this.props.PaymentManagement.coupond_method == '0' ? '내 쿠폰 리스트에서 선택' : '직접 입력'}</label>
                                        </div>
                                        {this.props.PaymentManagement && this.props.PaymentManagement.coupond_method == constant.PAYMENT.METHOD_SELECTED_COUPOND ? (
                                            <Fragment>
                                                <Field
                                                    name="coupon_code" // tên công ty thẻ
                                                    component={renderSelectField}
                                                    className={this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT ? "col-md-5 pl-0" : "col-md-9 mb-6 pl-0"}
                                                    onChange={this.onChangeSeletedCoupon}
                                                >
                                                    <option value=''>클릭 후 선택</option>
                                                    {this.state.coupons.data.map((item, i) =>
                                                        <option value={item.coup_code} key={i} data-key={i}>{item.coup_name}</option>
                                                    )}


                                                </Field>
                                                {this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT ? (
                                                    <div className="col-md-4 pr-0 pl-2">
                                                         <div className="dropdown box-custom-selected">
                                                                <button className="btn dropdown-toggle" type="button" id="payment_option_periodically"
                                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    {helper.getPeriodicallyName(this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically)}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="payment_option_periodically">
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH ? 'active' : '')} onClick={() => this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH)}>{helper.getPeriodicallyName('0')}</a>
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT ? 'active' : '')} onClick={() => this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT)}>{helper.getPeriodicallyName('1')}</a>
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW ? 'active' : '') + (this.renderDay() === 0 && ' disabled')} onClick={() =>this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW)}>{helper.getPeriodicallyName('2')}</a>
                                                                </div>
                                                            </div>
                                                    </div>
                                                ) : (<></>)}
                                            </Fragment>


                                        ) : (
                                                <Fragment>
                                                    <Field
                                                        placeholder="직접 입력하기"
                                                        name="coupon_code" // tên công ty thẻ
                                                        component={renderTextField}
                                                        className={this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT ? "col-md-5" : "col-md-9 mb-6"}
                                                        onChange={this.onChangeInputTextCoupond}
                                                    />
                                                    {this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT ? (
                                                        <div className="col-md-4 pr-0 pl-2">
                                                            <div className="dropdown box-custom-selected">
                                                                <button className="btn dropdown-toggle" type="button" id="payment_option_periodically"
                                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    {helper.getPeriodicallyName(this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically)}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="payment_option_periodically">
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH ? 'active' : '')} onClick={() => this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH)}>선택</a>
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT ? 'active' : '')} onClick={() => this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT)}>당월 결제 금액에 적용하기</a>
                                                                    <a  className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.payment_option_periodically === constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW ? 'active' : '')  + (this.renderDay() === 0 && ' disabled')} onClick={() =>this.onChangeResetApply(constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW)}>요금제 변경 예약에 적용하기</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (<></>)}

                                                </Fragment>
                                            )}
                                        <div className="col-md-3 mb-3 pl-2">
                                            <button className="btn btn-grey" type="button" type="submit" >적용</button>
                                        </div>
                                       
                                    </div>
                                    </form>
                                    <div className="form-row">
                                         {this.state.error_payment_option_periodically ? (
                                             <p className="invalid-feedback d-block">{this.state.error_payment_option_periodically}</p>
                                         ) : (<></>)}
                                    </div>
                                    <div className="title-form mt-4">
                                        <h2>
                                            결제수단
                                        </h2>
                                    </div>
                                    <div className="method-payment">
                                        <div className="top pl-30">
                                            <div className="item-top ml-3">
                                                <div className="custom-control custom-radio" >
                                                    <Field
                                                        id="payment_method_1"
                                                        name="payment_method"
                                                        component={renderRadioField}
                                                        checked={this.props.PaymentManagement && parseInt(this.props.PaymentManagement.payment_method)}
                                                        type="radio"
                                                        value={constant.PAYMENT.METHOD_SELECTED_COUPOND}
                                                        className="custom-control-input"
                                                        onClick={() => this.onChangeResetMethod()}
                                                    />
                                                    <label 
                                                        className="custom-control-label text-extra" 
                                                        htmlFor="payment_method_1" 
                                                        onClick={() => this.onChangeResetMethod()} >
                                                        정기결제
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="item-top justify-content-end">
                                                {/*
                                                <div className="custom-control custom-radio">
                                                    <Field
                                                        id="payment_method_2"
                                                        name="payment_method"
                                                        component={renderRadioField}
                                                        checked={this.props.PaymentManagement && parseInt(this.props.PaymentManagement.payment_method)}
                                                        type="radio"
                                                        value={constant.PAYMENT.METHOD_ENTER_COUPOND}
                                                        className="custom-control-input"
                                                        onClick={() => this.onChangeResetMethod()}
                                                    />
                                                    <label 
                                                        className="custom-control-label text-extra"
                                                        htmlFor="payment_method_2"  
                                                        onClick={() => this.onChangeResetMethod()}>
                                                        선결제
                                                    </label>
                                                </div>
                                                <div className="dropdown">
                                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {(this.props.PaymentManagement && this.props.PaymentManagement.useMonth ? this.props.PaymentManagement.useMonth : '12') + '개월'}
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {helper.getUsedMonth().map((item, i) =>
                                                            <a key={i} className={"dropdown-item " + (this.props.PaymentManagement && this.props.PaymentManagement.useMonth == item ? 'active' : '')} onClick={() => this.props.change('useMonth', item)}>{item}개월</a>
                                                        )}
                                                    </div>
                                                </div>
                                                 */}
                                            </div>
                                        </div>
                                        <Fragment>
                                            <div className="title-form mt-50">
                                                <h2>정기 결제 예약하기</h2>
                                            </div>
                                            <h5 className="text-payment-custom mt-50">선택 요금제 <span className="text-vatpay">(VAT 포함)</span></h5>
                                            <div className="prices-payment mt-0 pb-3">
                                                <ul className="list-payment">
                                                    <li>
                                                        <div className="left">
                                                            <p>1. 서비스 요금제</p>
                                                            <p className="text-bold">{this.state.pricePackageSelected?.pp_name}</p>
                                                        </div>
                                                        <div className="right">
                                                            {
                                                                this.props.pricePackages.data.length > 0 && helper.priceFormat(this.props.pricePackages.data[0].pp_price) ? (
                                                                    <p>월 {this.props.pricePackages.data.length > 0 && helper.priceFormat(this.props.pricePackages.data[0].pp_price)}원 {(this.props.PaymentManagement && this.props.PaymentManagement.useMonth && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) ? ('x ' + this.props.PaymentManagement.useMonth) : ('')}</p>
                                                                ):(
                                                                    <p>월 {helper.priceFormat(this.state.pricePackageSelected?.pp_price)}원 {(this.props.PaymentManagement && this.props.PaymentManagement.useMonth && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) ? ('x ' + this.props.PaymentManagement.useMonth) : ('')}</p>
                                                                )
                                                            }
                                                        {/*
                                                            {this.state.pricePackageSelected?.pp_price ? (
                                                                <p>월 {helper.priceFormat(this.state.pricePackageSelected?.pp_price)}원 {(this.props.PaymentManagement && this.props.PaymentManagement.useMonth && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) ? ('x ' + this.props.PaymentManagement.useMonth) : ('')}</p>
                                                            ) : (
                                                                    <p>월 {this.props.pricePackages.data.length > 0 && helper.priceFormat(this.props.pricePackages.data[0].pp_price)}원 {(this.props.PaymentManagement && this.props.PaymentManagement.useMonth && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) ? ('x ' + this.props.PaymentManagement.useMonth) : ('')}</p>
                                                                )}
                                                        */}
                                                        </div>
                                                    </li>
                                                    {this.state.extraService.useAlarm.state ? (
                                                        <li>
                                                            <div className="left">
                                                                <p className="font-14">2. 부가서비스</p>
                                                                <p className="text-bold font-14">일정알림</p>
                                                            </div>
                                                            <div className="right">
                                                                <p>월 {helper.priceFormat(this.state.extraService.useAlarm.price)}원 {(this.props.PaymentManagement.useMonth && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT) ? ('x ' + this.props.PaymentManagement.useMonth) : ('')}</p>
                                                            </div>
                                                        </li>
                                                    ) : ('')}
                                                        
                                                  
                                                </ul>
                                                <div className="total-payment">
                                                    <p className="text-bold">{this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT ? ('선결제') : ('월 청구 예정 금액')} </p>
                                                    <p className="text-reds">
                                                    {this.props.PaymentManagement?.payment_method !== constant.PAYMENT.METHOD_PRE_PAYMENT && ('월')} {helper.priceFormat(this.renderTotalPriceMonth())|| 0}원
                                                     </p>
                                                </div>
                                                <ul className="list-payment border-0 mb-0">
                                                {this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT 
                                                && this.props.PaymentManagement?.payment_option_periodically == constant.PAYMENT.OPTION_FERIODICALLY_MONTHLY_PAYMENT
                                                && this.state.coupondSelected?.coup_name
                                                                    && this.state.applyCoupond ? (
                                                                        <Fragment>  
                                                                            <li>
                                                                                <div className="left">
                                                                                    <p>할인쿠폰</p>
                                                                                <p className="text-bold">{this.state.coupondSelected?.coup_code}</p>
                                                                                </div>
                                                                                <div className="right">
                                                                                    <p>-  {helper.priceFormat(this.renderPriceCouponDiscountMonth())}원</p>
                                                                                </div>
                                                                            </li>
                                                                            <div className="total-payment">
                                                                                <p >결제 예정 금액 </p>
                                                                                <p className="text-bold">
                                                                              {helper.priceFormat(this.renderTotalDiscountMonth())|| 0}원
                                                                                </p>
                                                                            </div>
                                                                        </Fragment>
                                                                    ) : (<></>)
                                                }
                                                {this.props.PaymentManagement?.payment_method == constant.PAYMENT.METHOD_PRE_PAYMENT? (
                                                            <Fragment>
                                                              
                                                                    {this.renderDay() > 0 && (
                                                                        <div className="total-payment">
                                                                   
                                                                            <p className="text-bold">당월 예상 금액</p>
                                                                                <p className="text-reds">{helper.priceFormat(this.renderTotalDiscountDay())}원</p>
                                                                        </div>
              
                                                                    )}
                                                                   
                                                                    {this.state.coupondSelected?.coup_name && this.state.applyCoupond && (
                                                                        <Fragment>
                                                                        <li>
                                                                            <div className="left">
                                                                            <p>할인쿠폰</p>
                                                                            <p className="text-bold">{this.state.coupondSelected?.coup_code}</p>
                                                                            </div>
                                                                            <div className="right">
                                                                                <p >- {helper.priceFormat(this.renderPriceCouponDiscountMonth() + this.renderPriceCouponDiscountDay())}원</p>
                                                                            </div>
                                                                        </li>
                                                                        
                                                                        </Fragment>
                                                                    )}
                                                                    {/*  */}
                                                                    {(this.renderDay() > 0 || (this.state.applyCoupond && this.state.coupondSelected?.coup_name)) && (
                                                                    <li>
                                                                        <div className="left">
                                                                            <p className="text-bold">결제 예정 금액</p>
                                                                        </div>
                                                                        <div className="right">
                                                                            <p>{helper.priceFormat(this.renderTotalDiscount())}원</p>
                                                                        </div>
                                                                    </li>
                                                                    )}
                                                            </Fragment>
                                                        ) : (<></>)}
                                                    </ul>
                                                    
                                            </div>
                                        </Fragment>
                                        {this.renderDay() > 0 && this.props.PaymentManagement && this.props.PaymentManagement.payment_method == constant.PAYMENT.METHOD_MONTHLY_PAYMENT ? (
                                            <Fragment>
                                                <div className="title-form mt-50">
                                                    <h2>당월 결제 금액</h2>
                                                </div>
                                        <p className="text-payment-red">(※ {this.getDateNow()} ~ {this.getDateEndMonthNow()} 까지 일할 계산된 이용료입니다.)</p>
                                                <h5 className="text-vatpay">(VAT 포함)</h5>
                                                <div className="prices-payment mt-0 pb-3">
                                                    <ul className="list-payment">
                                                        <li>
                                                            <div className="left">
                                                                <p>서비스 요금제</p>
                                                                <p className="text-bold">{this.state.pricePackageSelected?.pp_name}</p>
                                                            </div>
                                                            <div className="right">
                                                                <p> {helper.priceFormat(this.renderPackagePriceDay())} 원</p>
                                                            </div>
                                                        </li>
                                                        {this.state.extraService.useAlarm.state ? (
                                                        <li>
                                                            <div className="left">
                                                                <p className="font-14">부가서비스</p>
                                                                <p className="text-bold font-14">일정알림</p>
                                                            </div>
                                                            <div className="right">
                                                                <p> {helper.priceFormat(this.renderAlarmPriceDay())} 원</p>
                                                            </div>
                                                        </li>
                                                        ) : ('')}
                                                        {this.state.extraService.useAlarm.state && this.state.coupondSelected?.coup_name && this.state.applyCoupond &&  (
                                                            <li>
                                                                <div className="left">
                                                                    <p>결제 예정 금액</p>
                                                                    {/* <p className="text-bold">#####</p> */}
                                                                </div>
                                                                <div className="right">
                                                                    <p>{helper.priceFormat(this.renderTotalPriceDay())}원</p>
                                                                </div>
                                                            </li>
                                                        ) }
                                                        
                                                        {this.props.PaymentManagement 
                                                        && this.props.PaymentManagement.payment_option_periodically == constant.PAYMENT.OPTION_FERIODICALLY_PAYMENT_NOW
                                                        && this.state.coupondSelected?.coup_name
                                                        && this.state.applyCoupond ? (
                                                            <li>
                                                                <div className="left">
                                                                    <p>할인쿠폰</p>
                                                                <p className="text-bold">{this.state.coupondSelected?.coup_code}</p>
                                                                </div>
                                                                <div className="right">
                                                                    <p>-  {helper.priceFormat(this.renderPriceCouponDiscountDay())}원</p>
                                                                </div>
                                                            </li>
                                                        ) : (<></>)}
                                                        
                                                    </ul>
                                                    <div className="total-payment">
                                                        <p className="text-bold">결제 예정 금액 </p>
                                                        <p className="text-reds">{helper.priceFormat(this.renderTotalDiscountDay())}원</p>
                                                    </div>
                                                </div>
                                            </Fragment>) : (<></>)}
                                        <div className="title-form mt-50" id="notice-id">
                                            <h2>
                                                Notice
                                            </h2>
                                        </div>
                                        <div className="row check-noticespay mt-35">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <div className="custom-control custom-checkbox mr-sm-2">
                                                        <Field
                                                            type="checkbox"
                                                            id="rulesChecked1"
                                                            name="rulesChecked1"
                                                            component={renderCheckboxField}
                                                            defaultChecked={false}
                                                        />
                                                        <label className="custom-control-label text-extra text-bold" htmlFor="rulesChecked1">[요금제
                                                변경 유의사항]을 확인하였으며, 동의합니다.</label>
                                                    </div>
                                                </div>
                                                <p className="text-check-noticespay">
                                                    [요금제 변경 유의사항] <br />
                                        - 요금제 변경은 변경 신청 일의 다음 달 1일부터 적용됩니다.<br />
                                        - 정기 결제 예정 금액은 지정된 결제 예정일에 자동 청구됩니다.<br />
                                        - 결제 예정일 : 전 월 말일 (선불 청구)<br />
                                        ex) 20년 12월 서비스 이용료 → 20년 11월 30일에 청구
                                    </p>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <div className="custom-control custom-checkbox mr-sm-2">
                                                        <Field
                                                            type="checkbox"
                                                            id="rulesChecked2"
                                                            name="rulesChecked2"
                                                            component={renderCheckboxField}
                                                            defaultChecked={false}
                                                        />
                                                        <label className="custom-control-label text-extra text-bold" htmlFor="rulesChecked2">[결제/환불
                                                유의사항]을 확인하였으며, 동의합니다. </label>
                                                    </div>
                                                </div>
                                                <p className="text-check-noticespay">
                                                    [결제/환불 유의사항]<br />
                                        - 결제 완료 시 다음달 말일 까지로 서비스 이용기간이 자동 연장됩니다.<br />
                                        - 정기 결제 해지를 원하시는 경우, 이용기간 만료 전 해지 예약을 신청해주시기 바랍니다.<br />
                                        - 서비스를 해지 예약 하실 경우 자동 결제가 해지되며, 기존 이용기간까지 서비스를 이용하실 수 있습니다.
                                    </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="title-form mt-5 pt-3 w-810">
                                        <h2>
                                            결제정보
                                         </h2>
                                    </div>
                                    <HyosungCMSMember
                                        discount={this.renderTotalDiscount()}
                                        paymentState={this.props.PaymentManagement && this.props.PaymentManagement.payment_state == true ? 1 : 0}
                                        onChangeVerifyPhoneClearCountDown={this.props.onChangeVerifyPhoneClearCountDown}
                                        price={this.renderDay() === 0 ? this.renderTotalDiscountMonth() : this.renderTotalDiscount()}
                                        paymentMethod={this.props.PaymentManagement?.payment_method}
                                        useMonth={this.props.PaymentManagement?.useMonth}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <button type="button" className="btn btn-gray pl-5 pr-5 mx-1" onClick={() => this.props.history.push(routerName.PAYMENT_HISTORY)}>취소</button>
                            <HyosungCMSButton 
                                callAmount={this.renderDay() === 0 ? this.renderTotalDiscountMonth() : this.renderTotalDiscount()}
                                vatAmount={0}
                                paymentSuccess={this.paymentSuccess}
                                popupShowCheckedRules={this.popupShowCheckedRules}
                                paymentMethod={this.props.PaymentManagement?.payment_method}
                            />
                        </div>
                    </form>
                </section>
            </Fragment>

        );
    }
}
const FORM_NAME = 'PaymentManagement';

const mapStateToProps = state => {
    let initValues;

    if (state.form.PaymentManagement && state.form.PaymentManagement.initial && state.dataStore.pricePackages.data.length > 0 && state.auth.storePlan && parseInt(state.auth.storePlan.packageID)) {
        initValues = {
            ...state.form.PaymentManagement.initial,
            'package_id': parseInt(1)
        };
    }
    return {
        initialValues: initValues,
        priceGroups: state.dataStore.priceGroups,
        pricePackages: state.dataStore.pricePackages,
        auth: state.auth,
        PaymentManagement: getFormValues(FORM_NAME)(state),
        PaymentManagementErrors: getFormSyncErrors(FORM_NAME)(state),
        priceExtraService: state.dataStore.priceExtraService,
        expired_date: state.auth.expired_date,
        PaymentHyosungCMS: getFormValues('PaymentHyosungCMS')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        getPricePackages: (data) => dispatch(getPricePackages(data)),
        updateExpireDate: (data) => dispatch(updateExpireDate(data)),
        updateExpiredDate: (data) => dispatch(updateExpiredDate(data)),
    };
};

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        'coupon_code': '',
        'package_id': '',
        'payment_method': constant.PAYMENT.METHOD_SELECTED_COUPOND,
        'payment_option_periodically': constant.PAYMENT.OPTION_FERIODICALLY_DEFAUTH,
        'payment_state': true,
        'useDay': '0',
        'useMonth': '12',
        'useAlarm': false,
        'coupond_method': '0',
        'discountPrice': '0',
        'packagePrice': '0',
        'rulesChecked1': false,
        'rulesChecked2': false
    },
    enableReinitialize: true,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PaymentManagement);

