import React, { Component, Fragment } from 'react'

export default class renderFileAttachmentField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: '',
            file_name: this.props.file_url ? this.props.file_url : '',
            change: false
        }
    }

    onChange = (e) => {
        if(e.target.files[0]){
            this.props.input.onChange(e.target.files[0])
            this.setState({
                file: e.target.files[0],
                file_name: e.target.files[0].name,
                change: true
            }, () => {
                if(typeof this.props.onChangeStateRemove == "function"){
                    this.props.onChangeStateRemove(false)
                }
            });
        } else {
            this.setState({
                file: '',
                file_name: '',
                change: true
            }, () => {
                if(typeof this.props.onChangeStateRemove == "function"){
                    this.props.onChangeStateRemove(false)
                }
            })
        }
    }

    onClickNull = (e) => {
        e.target.value = ''
    }

    upload() {
        document.getElementById("selectImage").click()
    }

    render() {
        const { input: { value, name } } = this.props
        const { input, required, meta: { touched, invalid, error }, file_url} = this.props  //whatever props you send to the component from redux-form Field
        let removeFile = this.props.removeFile || false;
        return (
            <div className="form-row"><div className="form-group col-md-12">
                <Fragment>
                    <div className={"file " + ((this.state.change || touched) && invalid ? 'is-invalid box-invalid' : '') }>
                        <div className="title">
                            { removeFile !== true && this.state.file_name ? this.state.file_name : file_url}
                        </div>
                        <input
                            name={name}
                            accept='.PDF, .doc, .docx, .xls, .xlsx, .ppt, .jpeg, .jpg, .png'
                            type='file'
                            onChange={this.onChange}
                            onClick={this.onClickNull}
                            className={'form-control ' + (invalid ? 'is-invalid' : '') + ' h-44'}
                            hidden
                            id='selectImage'
                        />
                        <a type="button" className="btn btn-add-img" onClick={this.upload}>
                            <img src="images/icon-notifi-attachment.svg"  />
                        </a>
                    </div>
                   
                    {error !== undefined ? (
                        <div className="invalid-feedback" style={{position:'relative', top:'25px'}}>
                            {error}
                        </div>
                    ) : ''}
                </Fragment>
            </div></div>


        )
    }
}