import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {reduxForm, getFormSyncErrors, getFormValues, reset, change} from 'redux-form';
import BookmarkModal from './Bookmark/BookmarkModal'
import HistoryConsultantWired from './HistoryConsultantWired/HistoryConsultantWiredModal'
import RecentConsultationModal from './RecentConsultation/RecentConsultationModal'
import ModalPrint from "./ModalPrint";
import ConfirmReportModal from "../Wired/ReportSale/ConfirmReportModal";
import ShareWebModal from "../ShareWebModal";
import ResetModal from './../Reset/ResetModal'
// import PrintModal from './Print/PrintModal'
import PotentialCustomersModal from './PotentialCustomers/PotentialCustomersModal'
import CreatePotentialCustomersModal from './PotentialCustomers/CreatePotentialCustomersModal'
import CheckSalerNamePopup from "../Wired/ReportSale/CheckSalerNamePopup";
import { CONSULTANT_WIRED } from "../../utils/constant";
import { checkBlockFeature } from "../../utils/helper";
import { ReactComponent as IconReset } from './../../assets/images/icon-reset.svg';
import { ReactComponent as IconShare } from './../../assets/images/share.svg';
import { ReactComponent as IconPrint } from './../../assets/images/print.svg';
import { ReactComponent as IconEdit } from './../../assets/images/edit.svg';
import { ReactComponent as IconQuery } from './../../assets/images/query.svg';
import { ReactComponent as IconDocument } from './../../assets/images/document.svg';
import { ReactComponent as Iconpeople } from './../../assets/images/people.svg';
import { ReactComponent as IconStar } from './../../assets/images/star.svg';
import PopupInactive from '../PopupInactive';
import Tooltip from "./../Tooltip";

class WiredFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalReset: false,
            isOpenModalBookmark: false,
            isOpenHistoryConsultantWired: false,
            isOpenModalPotentialCustomers: false,
            isOpenModalCreatePotentialCustomers: false,
            currentDevice: "web",
            isOpenModalRecentConsultation: false,
            isOpenModalPrint : false,
            isOpenModalReportSale: false,
            isOpenModalShareWeb : false,
            isOpenCheckSalerNamePopup : false,
            messErr : '',
            isDisableReport: false,
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.WiredAdvice?.id != prevProps.WiredAdvice?.id) {
            this.callResetForm()
        }
    }

    hideReport = () => {
        this.setState({
            isDisableReport: true
        });
        this.setState({ isOpenModalReportSale: !this.state.isOpenModalReportSale })
    }

    openPopupReportSale = () =>
        this.setState({ isOpenModalReportSale: !this.state.isOpenModalReportSale })

    openPopupopenPopupReset = () =>
        this.setState({isOpenModalReset: !this.state.isOpenModalReset})

    openPopupBookmark = () => {
        this.setState({isOpenModalBookmark: !this.state.isOpenModalBookmark})
    }
    openPopupHistoryConsultantWired = () => {
        this.setState({isOpenHistoryConsultantWired: !this.state.isOpenHistoryConsultantWired})
    }

    openPopupRecentConsultation = () =>
        this.setState({ isOpenModalRecentConsultation: !this.state.isOpenModalRecentConsultation })



    // openPopupPrint = () =>
    //     this.setState({isOpenModalPrint: !this.state.isOpenModalPrint})

    openPopupPotentialCustomers = () => {
        if (checkBlockFeature()) {
            this.showWaitingPopup()
        } else {
            this.setState({isOpenModalPotentialCustomers: !this.state.isOpenModalPotentialCustomers})
        }
    }

    openPopupCreatePotentialCustomers = () => {
        if (checkBlockFeature()) {
            this.showWaitingPopup()
        } else {
            this.setState({isOpenModalCreatePotentialCustomers: !this.state.isOpenModalCreatePotentialCustomers})
        }
    }

    openPopupCheckSalerNamePopup = () => {
        if (checkBlockFeature()) {
            this.showWaitingPopup()
        } else {
            let check = 0;
            for (let i=0; i < this.props.WiredAdvice.WiredAdviceStep2.classify.length; i++) {
                if (this.props.WiredAdvice.WiredAdviceStep2.classify[i].classify.length) {
                    check = 1;
                    break;
                }
            }

            if (!check) {
                this.setState({ messErr: '상품을 선택해 주세요!' })
                this.setState({ isOpenCheckSalerNamePopup: !this.state.isOpenCheckSalerNamePopup })
            } else if (!this.props.WiredAdvice.WiredAdviceStep2?.saler?.name) {
                this.setState({ messErr: '상담사를 선택해 주세요!' })
                this.setState({ isOpenCheckSalerNamePopup: !this.state.isOpenCheckSalerNamePopup })
            } else {
                this.setState({ messErr: '' })
                this.openPopupReportSale();
            }
        }
    }

    callResetForm = () => {
        let step2 = {
            ...CONSULTANT_WIRED,
            classify: [
                {
                    id: 1,
                    name: '인터넷',
                    icon: '/tsc_template/dist/images/globe.svg',
                    classify: []
                },
                {
                    id: 2,
                    name: 'TV',
                    icon: '/images/tivi.svg',
                    classify: []
                },
                {
                    id: 3,
                    name: '전화',
                    icon: '/images/phone.svg',
                    classify: []
                },
                {
                    id: 4,
                    name: 'IoT',
                    icon: '/images/home.svg',
                    classify: []
                },
            ],
        }
        this.props.change('WiredAdviceStep2', step2)
        this.props.change('CreatePotentialCustomerDefault',{
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate:false
        })
        this.setState({
            isDisableReport: false
        })
    }

    resetForm = () => {

        this.props.change('id', null)

    }

    onClickPrintModal = () => {
        let {WiredAdvice} = this.props;

        if(WiredAdvice.id) {
            this.setState({
                isOpenModalPrint : !this.state.isOpenModalPrint
            })
        }


    }

    openPopupDisable = (state) => {
        if (state) {
          this.setState({
            title: '실제 개통에 사용하지 마십시오.',
            content: `
              베타테스트 기간입니다.<br>
              테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
              서류 미비나 금액 오류 등이 발생할 수 있습니다.
            `
          })
        }
        this.setState({
          isOpenPopupDisable: true
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    iconLock: null
                })
            }, 500)
        })
    }

    modelPrintClose = () => {
        this.setState({
            title: null,
            content: null
        })
    }

    showWaitingPopup = () => {
        this.setState({
            isOpenPopupDisable: true,
            iconLock: 'iconLock',
        })
    }

    shareDeviceWeb = () => {
        //if (checkBlockFeature()) {
        //    this.showWaitingPopup()
        //} else {
            if(window.currentDevice === "web") {
                let {WiredAdvice} = this.props;
                let check = false;



                WiredAdvice.WiredAdviceStep2.classify.map(item => {
                    if(item.classify.length > 0)  {
                        item.classify.map(item1 => {
                            if(item1.device != null && Object.keys(item1.device).length > 0) {
                                check = true;
                            }

                        })
                    }
                })
                if(check && WiredAdvice.id) {
                    this.setState({
                        isOpenModalShareWeb : !this.state.isOpenModalShareWeb
                    })
                }else{
                    alert('선택된 상품이 없습니다.');
                }

            }
        //}
    }
    render() {
        const {WiredAdvice} = this.props;
        return (
            <Fragment>

                <div className="group-button-wireless mt-0">
                    <button className="btn btn-wireless" onClick={this.openPopupopenPopupReset}>
                    <IconReset className="mr-1" />
                        초기화
                    </button>
                    <button className="btn btn-wireless" onClick={this.openPopupRecentConsultation}>
                    {/* <i className="sprite sprite-time-line"></i> */}
                    <IconQuery className="mr-1" />
                        최근상담
                    </button>
                    <button className="btn btn-wireless" data-toggle="modal" data-target="#modal-75" onClick={this.openPopupBookmark}>
                    {/* <i className="sprite sprite-star"></i> */}
                    <IconStar className="mr-1" />
                        즐겨찾기
                    </button>
                    {/*
                    <button className="btn btn-wireless" data-toggle="modal" data-target="#modal-query" onClick={this.openPopupCreatePotentialCustomers}>
                        <IconDocument className="mr-1" />
                        가망고객등록
                    </button>
                    */}
                    <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
                        <button className="btn btn-wireless" data-toggle="modal" data-target="#modal-query">
                            <IconDocument className="mr-1" />
                            가망고객등록
                        </button>    
                    </Tooltip>
                    {/* 
                    <button className="btn btn-wireless" data-toggle="modal" data-target="#modal-85" onClick={this.openPopupPotentialCustomers}>
                        <Iconpeople className="mr-1" />
                        가망고객
                    </button>
                    */}
                    <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
                        <button className="btn btn-wireless" data-toggle="modal" data-target="#modal-85">
                            <Iconpeople className="mr-1" />
                            가망고객
                        </button>
                    </Tooltip>
                    
                    <button
                      className="btn btn-wireless"
                      id={WiredAdvice.id ? "btn-share-wired-product" : ''}
                      data-print-content={process.env.REACT_APP_API_URL+'wired-product-print'}
                      onClick={WiredAdvice.id ? this.shareDeviceWeb : null}
                    >
                        <IconShare className="mr-1" id={'btn-share-wired-product-img'} />
                        공유
                    </button>
                    
                    {/*
                    <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
                        <button
                            className="btn btn-wireless"
                            id={WiredAdvice.id ? "btn-share-wired-product" : ''}
                            data-print-content={process.env.REACT_APP_API_URL+'wired-product-print'}
                        >
                            <IconShare className="mr-1" id={'btn-share-wired-product-img'} />
                            공유
                        </button>
                    </Tooltip>
                    */}
                    <button
                      className="btn btn-wireless"
                      data-toggle="modal"
                      onClick={WiredAdvice.id ? this.onClickPrintModal : null}
                    >
                     <IconPrint className="mr-1" />
                        출력
                    </button>
                    {/* 
                    <button className="btn btn-wireless" onClick={this.openPopupCheckSalerNamePopup} disabled={this.state.isDisableReport ? true : false}>
                       <IconEdit className="mr-1" />
                        판매완료
                    </button>
                    */}
                    <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
                        <button className="btn btn-wireless" disabled={this.state.isDisableReport ? true : false}>
                            <IconEdit className="mr-1" />
                            판매완료
                        </button>
                    </Tooltip>
                </div>

                
                <ResetModal
                    openPopup={this.openPopupopenPopupReset}
                    isOpenModal={this.state.isOpenModalReset}
                    resetForm={this.resetForm}
                />
                <BookmarkModal
                    openPopupBookmark={this.openPopupBookmark}
                    isOpenModalBookmark={this.state.isOpenModalBookmark}
                />
                <HistoryConsultantWired
                    openPopup={this.openPopupHistoryConsultantWired}
                    isOpenModal={this.state.isOpenHistoryConsultantWired}
                />
                <PotentialCustomersModal
                    openPopup={this.openPopupPotentialCustomers}
                    isOpenModal={this.state.isOpenModalPotentialCustomers}
                    openPopupCreate={this.openPopupCreatePotentialCustomers}
                    isOpenModalCreate={this.state.isOpenModalCreatePotentialCustomers}
                />
                <CreatePotentialCustomersModal
                    openPopup={this.openPopupCreatePotentialCustomers}
                    isOpenModal={this.state.isOpenModalCreatePotentialCustomers}
                    openPopupList={this.openPopupPotentialCustomers}
                />

                <RecentConsultationModal
                    openPopup={this.openPopupRecentConsultation}
                    isOpenModal={this.state.isOpenModalRecentConsultation}
                />

                <ModalPrint
                    isOpen={this.state.isOpenModalPrint}
                    toggle={this.onClickPrintModal}
                    WiredAdvice={WiredAdvice}
                />

                <ConfirmReportModal
                    openPopup={this.openPopupReportSale}
                    isOpenModal={this.state.isOpenModalReportSale}
                    hideReport={this.hideReport}
                />

                <CheckSalerNamePopup
                    err={this.state.messErr}
                    openPopup={this.openPopupCheckSalerNamePopup}
                    isOpenModal={this.state.isOpenCheckSalerNamePopup}
                />

                <ShareWebModal
                  isOpenModal={this.state.isOpenModalShareWeb}
                  toggle={this.shareDeviceWeb}
                  sharelink={process.env.REACT_APP_HOSTNAME + '/share-wired?id='+WiredAdvice.id}
                />

                <PopupInactive 
                    isOpenModal={this.state.isOpenPopupDisable} 
                    closePopupDisable={this.closePopupDisableHandle}
                    title={this.state.title || null}
                    content={this.state.content || null}
                    callback={(WiredAdvice.id && this.state.title) ? this.onClickPrintModal : null}
                    onModelClosed={this.modelPrintClose}
                    iconLock={this.state.iconLock}
                />

            </Fragment>
        );
    }
}

WiredFilter.propTypes = {
    reset: PropTypes.func,
    change: PropTypes.func,
    setPage: PropTypes.func,
    WiredAdvice: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        reset: () => dispatch(reset()),
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WiredFilter);
