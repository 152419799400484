import React  from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";
import renderByFormatField from "./index";
import renderTextField from "../TextField";

const renderRB = ({ fields }) => (
     <div className="col-9">
        {fields.map((data, index) => (
            <div className="row">
                <div className="col-4">
                    <div className="form-group ">
                        <label htmlFor="" className="label-text">추가 R/B ({index + 1})</label>
                        <Field type="text" className="form-control h-44"
                               name={`${data}.price`}
                               component={renderByFormatField}
                               prefix={"원"}
                               placeholder=" 0 원" />
                    </div>
                </div>
                <div className="col-8">
                    <div className="form-group ">
                        <label htmlFor="" className="label-text">R/B 내역 메모</label>
                        <Field type="text" className="form-control h-44"
                               name={`${data}.memo`}
                               component={renderTextField}
                               prefix={"원"}
                               placeholder=" R/B 내역 메모 " />
                    </div>
                </div>
            </div>
        ))}
        <div className="row">
            <div className="col-4">
                <button className="btn btn-add" type={"button"} onClick={() => fields.push({})}><span>+</span>추가 R/B ({fields.length + 1})</button>
            </div>
            <div className="col-12">
                <div className="border-bottom-dashed w-100  pb-3"></div>
            </div>
        </div>
    </div>
);

renderRB.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderRB;
