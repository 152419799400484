import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {getCostDiplayPlan} from '../../store/actions/get_cost_diplay_plan';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {getFormValues} from 'redux-form';
import {numberWithCommas,priceFormat} from "../../utils/helper";
import { replace, isEmpty } from 'lodash';
import { hideLoading, showLoading } from '../../store/actions/ui';
class PlanModalComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: 22050,
            invalid: false,
            marksVoice : {
                0: <span>T끼리 무제한<br />그외 0분</span>,
            },
            marksData : {
                0: <span> 0MB</span>,
            },
            marksVoiceDefault : null,
            marksDataDefault : null,
            marksFee : {
                0: 0,
            },
            marksFeeDefault: {
                0:0
            },
            marksFeeDefaultVal: null,
            disable: false,
            disableFee: false,
            customChange: {
                data: 0,
                voice: 0,
                fee: 0
            },
            dotData: "", 
            dotVoice: "", 
            dotFee: "", 
            valFee: 0,
            costDistplay : 0,
            planId: '',
            setDefaulFee: 100,
            dataVoiceFeeValid: false,
        }
    }

    toggle = (data) => {
        this.setState({
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: 22050,
            invalid: false,
            marksVoice : {
                0: <span>T끼리 무제한<br />그외 0분</span>,
            },
            marksData : {
                0: <span> 0MB</span>,
            },
            marksVoiceDefault : null,
            marksDataDefault : null,
            marksFee : {
                0: 0,
            },
            marksFeeDefault: {
                0:0
            },
            marksFeeDefaultVal: null,
            disable: false,
            disableFee: false,
            customChange: {
                data: 0,
                voice: 0,
                fee: 0
            },
            dotData: "", 
            dotVoice: "", 
            dotFee: "", 
            valFee: 0,
            costDistplay : 0,
            planId: '',
            setDefaulFee: 100,
            dataVoiceFeeValid: false,
            dataVoiceValid: false,
            feeValid: false,
            oneValueFee: false,
            didMountModal: true,
        }, () => {
            this.props.hideModalPlanCustom(data);
        });
    }
    logVoice = (value) => {
        this.setState({
            ...this.state,
            dotVoice: value
        }, () => {
            let data = {};
            let customValue = JSON.parse(this.props.dataCustomValue.plac_custom_value);
            let dataObj = this.getUnique(customValue.data);
            let voiceObj = this.getUnique(customValue.voice);
            let valueData = this.state.marksData[this.state.customChange.data];
            let valueVoice = this.state.marksVoice[this.state.customChange.voice];
            if (dataObj && dataObj.length > 0) {
                Object.keys(this.state.marksData);
                let idx = Object.keys(this.state.marksData).indexOf('' + this.state.customChange.data);
                data.custom_data = idx;
            }
            if (voiceObj && voiceObj.length > 0) {
                Object.keys(this.state.marksVoice);
                let idx = Object.keys(this.state.marksVoice).indexOf('' + this.state.customChange.voice);
                data.custom_voice = idx
            }
            
            data.plac_id = this.props.dataCustomValue.plac_id;
            this.props.getCostDiplayPlan(data).then(res => {
                if (res.data != null) {
                    this.setState({
                        ...this.state,
                        planId: res.data,
                        costDistplay: res.data.cost_display ? res.data.cost_display : 0
                    })
                }else{
                    this.setState({
                        ...this.state,
                        costDistplay: 0
                    })
                }
            })
        })
    }

    logData = (value) => {
        this.setState({
            ...this.state,
            dotData: value
        }, () => {
            let data = {};
            let customValue = JSON.parse(this.props.dataCustomValue.plac_custom_value);
            let dataObj = this.getUnique(customValue.data);
            let voiceObj = this.getUnique(customValue.voice);
            let valueData = this.state.marksData[this.state.customChange.data];
            let valueVoice = this.state.marksVoice[this.state.customChange.voice];

            if (dataObj && dataObj.length > 0) {
                Object.keys(this.state.marksData);
                let idx = Object.keys(this.state.marksData).indexOf('' + this.state.customChange.data);
                data.custom_data = idx;
            }

            if (voiceObj && voiceObj.length > 0) {
                Object.keys(this.state.marksVoice);
                let idx = Object.keys(this.state.marksVoice).indexOf('' + this.state.customChange.voice);
                data.custom_voice = idx;
            }
            data.plac_id = this.props.dataCustomValue.plac_id;
            this.props.getCostDiplayPlan(data).then(res => {
                if (res.data != null) {
                    this.setState({
                        ...this.state,
                        planId: res.data,
                        costDistplay: res.data.cost_display ? res.data.cost_display : 0
                    })
                }else{
                    this.setState({
                        ...this.state,
                        costDistplay: 0
                    })
                }
            })
        })
    }

    logFee = (value => {
        let fee = this.state.customChange.fee;
        let idx = Object.keys(this.state.marksFeeDefault).indexOf(''+fee);
        this.setState({
            ...this.state,
            valFee: this.props.dataCustomValue.plan_custom_sms[idx] ? parseInt(this.props.dataCustomValue.plan_custom_sms[idx].fee) : 0,
        })
        
    })

    targetDragVoice = (val) => {
        this.setState({
            ...this.state,
            customChange: {
                ...this.state.customChange,
                voice : val
            },
            marksVoiceDefault: val
        })

    }

    targetDragData = (val) => {
        
        this.setState({
            ...this.state,
            customChange: {
                ...this.state.customChange,
                data : val
            },
            marksDataDefault: val
        }, () => {
            
            
        })
    }

    targetDragFee = (val) => {
        this.setState({
            ...this.state,
            customChange: {
                ...this.state.customChange,
                fee : val
            },
            marksFeeDefaultVal: val
        })
    }

    getUnique = (arr) => {
        const filteredArr = arr.reduce((acc, current) => {
            const x = acc.find(item => item.key === current.key);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        return filteredArr;
    }

    // if (consultant.planData) {
    //     this.setState({
    //         marksData: consultant.planData.custom_data,
    //         marksVoice: consultant.planData.custom_voice,
    //     })
    // }

    //set value open modal
    setMarks = () => {
        const { consultant } = this.props
        // let dataFrist = {};
        // if (consultant.planData) {
        //     let dataOld = {
        //         custom_data: consultant.planData.custom_data,
        //         custom_voice: consultant.planData.custom_voice,
        //         plac_id: consultant.planData.plac_id
        //     }
        //     dataFrist = dataOld
        // }else{
        //     let dataNew = {
        //         custom_data: 0,
        //         custom_voice: 0,
        //         plac_id: this.props.dataCustomValue.plac_id
        //     }
        //     dataFrist = dataNew
        // }

        let dataPlan = consultant.planData;
        let dataFrist = {};
        if (dataPlan) {
            if (dataPlan.plac_id && this.props.dataCustomValue.plac_id == dataPlan.plac_id) {
                dataFrist.custom_data = dataPlan.custom_data;
                dataFrist.custom_voice =  dataPlan.custom_voice;
            }else{
                dataFrist.custom_data = 0;
                dataFrist.custom_voice =  0;
            }
        }else{
            if (this.props.dataCustomValue && this.props.dataCustomValue.plan.length > 0) {
                this.props.dataCustomValue.plan.map((item, idx) => {
                    if (item.plac_basic == 1) {
                        dataFrist.custom_data = item.custom_data;
                        dataFrist.custom_voice =  item.custom_voice;
                    }
                })
            }else{
                dataFrist.custom_data = 0;
                dataFrist.custom_voice =  0;
            }
        }

        dataFrist.plac_id = this.props.dataCustomValue.plac_id;
        
        // this.props.getCostDiplayPlan(dataFrist).then(res => {
        //     if (res.data != null) {
        //         this.setState({
        //             ...this.state,
        //             planId: res.data,
        //             costDistplay: res.data.cost_display ? res.data.cost_display : 0
        //         }, () => {
        //             let {dataCustomValue} = this.props;
        //             let dataFee = this.props.dataCustomValue.plan_custom_sms.length > 0 ? this.props.dataCustomValue.plan_custom_sms : [];
        //             if (dataCustomValue) {
        //                 dataCustomValue = JSON.parse(dataCustomValue.plac_custom_value)
        //             }
        //             let data = {}, voice = {}, textFee = {}, textFeeVal = {};
                    
        //             if (dataFee && dataFee.length > 0) {
        //                 dataFee.map((fee, idx) => {
        //                     if ( dataFee.length == 1) {
        //                         textFee[100] = fee.name;
        //                         textFeeVal[100] = fee.fee;
        //                     }else{
        //                         textFee[idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1)] = fee.name;
        //                         textFeeVal[idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1)] = fee.fee;
        //                         var dataFeeText = Object.values(textFee).sort(function(a, b) {
        //                             return a - b;
        //                         });
        //                         var dataFeeTextVal = Object.values(textFeeVal).sort(function(a, b) {
        //                             return a - b;
        //                         });
        //                         dataFeeText.map((fee, idx) => {
        //                             textFee[idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1)] = fee;
        //                         })
        //                         dataFeeTextVal.map((fee, idx) => {
        //                             textFeeVal[idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1)] = fee;
        //                         })
        //                     }
        //                 })
                        
        //                 // if (consultant.planData) {
        //                 //     if (typeof consultant.planData.valFee !== null) {
        //                 //         this.setState({
        //                 //             ...this.state,
        //                 //             marksFeeDefaultVal: consultant.planData.valFee,
        //                 //         }, () => {
        //                 //         })
        //                 //     }
        //                 // }
        //                 this.setState({
        //                     ...this.state,
        //                     marksFee: textFee,
        //                     setDefaulFee: Object.keys(textFee).length == 1 ? 100 : 0,
        //                     valFee: textFeeVal[0] ? textFeeVal[0] : 0,
        //                     marksFeeDefault : textFeeVal,
        //                     marksFeeDefaultVal: consultant.planData && typeof consultant.planData.valFee !== null ? ''+consultant.planData.valFee : 0,
        //                     disableFee: false
        //                 }, () => {
        //                     if (dataCustomValue && dataCustomValue.data.length > 0) {
        //                         let uniqueVoice = this.getUnique(dataCustomValue.voice);
        //                         let uniqueData = this.getUnique(dataCustomValue.data);
        //                         // let uniqueFee = this.getUnique(dataCustomValue.data);
        //                         uniqueData.map((value, idx) => {
        //                             data[idx*100/(uniqueData.length -1)] = value.val ? value.val : 0;
        //                         })
        //                         uniqueVoice.map((value, idx) => {
        //                             let a = this.replaceALl(value.val, '+', '\r\n');
        //                             voice[idx*100/(uniqueVoice.length -1)] = value.val ? a : 0;
        //                         })
        //                         let resultData = null;
        //                         let resultVoice = null;
        //                         if (consultant.planData) {
        //                             let arrData = Object.keys(data);
        //                             resultData = arrData[consultant.planData.custom_data];
        //                             let arrVoice = Object.keys(voice);
        //                             resultVoice = arrVoice[consultant.planData.custom_voice];
        //                             this.setState({
        //                                 ...this.state,
        //                                 marksData:data,
        //                                 marksVoice: voice,
        //                                 marksDataDefault: resultData,
        //                                 marksVoiceDefault: resultVoice,
        //                                 marksFeeDefaultVal: typeof consultant.planData.valFee !== null ? ''+consultant.planData.valFee : 0, 
        //                                 valFee: textFeeVal[consultant.planData.valFee],
        //                                 disable: false
        //                             })
        //                         }else{
        //                             this.setState({
        //                                 ...this.state,
        //                                 marksData:data,
        //                                 marksVoice: voice,
        //                                 disable: false
        //                             })
        //                         }
        //                         // data.custom_voice = idxx;
                               
        //                     }else{
        //                         this.setState({
        //                             ...this.state,
        //                             marksData:data,
        //                             marksVoice: voice,
        //                             disable: true
        //                         })
        //                     }
        //                 })
        //             }else{
        //                 this.setState({
        //                     ...this.state,
        //                     valFee: 0,
        //                     disableFee: true
        //                 }, () => {
        //                     if (dataCustomValue && dataCustomValue.data.length > 0) {
        //                         let uniqueVoice = this.getUnique(dataCustomValue.voice);
        //                         let uniqueData = this.getUnique(dataCustomValue.data);
        //                         // let uniqueFee = this.getUnique(dataCustomValue.data);
        //                         uniqueData.map((value, idx) => {
        //                             data[idx*100/(uniqueData.length -1)] = value.val ? value.val : 0;
        //                         })
        //                         uniqueVoice.map((value, idx) => {
        //                             if (value.val != null) {
        //                                 let a = this.replaceALl(value.val, '+', '\r\n');
        //                                 voice[idx*100/(uniqueVoice.length -1)] = value.val ? a : 0;
        //                             }else{
        //                                 voice[idx*100/(uniqueVoice.length -1)] = value.val ? value.val : 0;
        //                             }
                                    
        //                         })
        //                         this.setState({
        //                             ...this.state,
        //                             marksData:data,
        //                             marksVoice: voice,
        //                             disable: false
        //                         })
        //                     }else{
        //                         this.setState({
        //                             ...this.state,
        //                             marksData:data,
        //                             marksVoice: voice,
        //                             disable: true
        //                         })
        //                     }
                            
        //                 })
        //             }
        //         })
        //     }
        // })
        this.props.showLoading();
        try {
            this.props.getCostDiplayPlan(dataFrist).then(res => {
                this.props.hideLoading();
                let dataFee = this.props.dataCustomValue.plan_custom_sms.length > 0 ? this.props.dataCustomValue.plan_custom_sms : [];
                let data = {}, voice = {}, textFee = {}, textFeeVal = {};
                if (res.data != null && dataFee.length > 0) {
                    this.setState({
                        ...this.state,
                        planId: res.data,
                        costDistplay: res.data.cost_display ? res.data.cost_display : 0,
                        costCalc: res.data.cost_calc ? res.data.cost_calc : 0,
                        didMountModal: false
                    }, () => {
                        let {dataCustomValue} = this.props;
                        if (dataCustomValue) {
                            dataCustomValue = JSON.parse(dataCustomValue.plac_custom_value)
                        }
                        dataFee.map((fee, idx) => {
                            if ( dataFee.length == 1) {
                                textFee[100] = fee.name;
                                textFeeVal[100] = fee.fee;
                            }else{
                                textFee[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee.name;
                                textFeeVal[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee.fee;
                                var dataFeeText = Object.values(textFee).sort(function(a, b) {
                                    return a - b;
                                });
                                var dataFeeTextVal = Object.values(textFeeVal).sort(function(a, b) {
                                    return a - b;
                                });
                                dataFeeText.map((fee, idx) => {
                                    textFee[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee;
                                })
                                dataFeeTextVal.map((fee, idx) => {
                                    textFeeVal[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee;
                                })
                            }
                        })
                        this.setState({
                            ...this.state,
                            marksFee: textFee,
                            setDefaulFee: Object.keys(textFee).length == 1 ? 100 : 0,
                            oneValueFee : Object.keys(textFee).length == 1 ? true : false,
                            valFee: textFeeVal[0] ? textFeeVal[0] : 0,
                            marksFeeDefault : textFeeVal,
                            marksFeeDefaultVal: dataPlan && typeof dataPlan.valFee !== null && (dataPlan.id == res.data.id)  ? ''+dataPlan.valFee : 0,
                        }, () => {
                            if (dataCustomValue && dataCustomValue.data.length > 0) {
                                let uniqueVoice = this.getUnique(dataCustomValue.voice);
                                let uniqueData = this.getUnique(dataCustomValue.data);
                                if (uniqueVoice.length == 1) {
                                    voice[100] = uniqueVoice[0].val;
                                }else{
                                    uniqueVoice.map((value, idx) => {
                                        let valueStr = value.val.replace(/\s/g, '');
                                        let a = this.replaceALl(valueStr, '+', '\r\n +');
                                        voice[Math.trunc(idx*100/(uniqueVoice.length -1))] = value.val ? a : 0;
                                    })
                                }
                                if (uniqueData.length == 1) {
                                    data[100] = uniqueData[0].val;
                                } else {
                                    uniqueData.map((value, idx) => {
                                        data[Math.trunc(idx*100/(uniqueData.length -1))] = value.val ? value.val : 0;
                                    })
                                }
                                let resultData = null;
                                let resultVoice = null;
                                if (dataPlan) {
                                    let arrData = Object.keys(data);
                                    resultData = arrData[dataPlan.id == res.data.id ? dataPlan.custom_data : 0];
                                    let arrVoice = Object.keys(voice);
                                    resultVoice = arrVoice[dataPlan.id == res.data.id ?  dataPlan.custom_voice : 0];
                                    this.setState({
                                        ...this.state,
                                        marksData:data,
                                        marksVoice: voice,
                                        marksDataDefault: resultData,
                                        marksVoiceDefault: resultVoice,
                                        marksFeeDefaultVal:  dataPlan && typeof dataPlan.valFee !== null && (dataPlan.id == res.data.id)  ? ''+dataPlan.valFee : 0,
                                        valFee: textFeeVal[dataPlan.valFee],
                                    })
                                }else{
                                    let arrData = Object.keys(data);
                                    resultData = arrData[dataFrist.custom_data];
                                    let arrVoice = Object.keys(voice);
                                    resultVoice = arrVoice[dataFrist.custom_voice];
                                    this.setState({
                                        ...this.state,
                                        oneValueData: uniqueData.length == 1 ? true : false,
                                        oneValueVoice: uniqueVoice.length == 1 ? true : false,
                                        marksData:data,
                                        marksVoice: voice,
                                        disable: false,
                                        dotVoice:voice,
                                        dotData:data,
                                        marksDataDefault: resultData,
                                        marksVoiceDefault: resultVoice,
                                        customChange: {
                                            ...this.state.customChange,
                                            data: resultData,
                                            voice: resultVoice,
                                        },
                                        // valFee: textFeeVal[dataPlan.valFee],
                                    })
                                }
                                // data.custom_voice = idxx;
                                
                            }else{
                                this.setState({
                                    ...this.state,
                                    marksData:data,
                                    marksVoice: voice,
                                    disable: true
                                })
                            }
                        })
                    })
                }else{
                    if (res.data == null && dataFee.length > 0) {
                        this.setState({
                            ...this.state,
                            dataVoiceValid : true
                        }, () => {
                            dataFee.map((fee, idx) => {
                                if ( dataFee.length == 1) {
                                    textFee[100] = fee.name;
                                    textFeeVal[100] = fee.fee;
                                }else{
                                    textFee[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee.name;
                                    textFeeVal[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee.fee;
                                    var dataFeeText = Object.values(textFee).sort(function(a, b) {
                                        return a - b;
                                    });
                                    var dataFeeTextVal = Object.values(textFeeVal).sort(function(a, b) {
                                        return a - b;
                                    });
                                    dataFeeText.map((fee, idx) => {
                                        textFee[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee;
                                    })
                                    dataFeeTextVal.map((fee, idx) => {
                                        textFeeVal[Math.trunc(idx*100/(dataFee.length > 1 ? dataFee.length -1 : 1))] = fee;
                                    })
                                }
                            });
                            this.setState({
                                ...this.state,
                                marksFee: textFee,
                                setDefaulFee: Object.keys(textFee).length == 1 ? 100 : 0,
                                oneValueFee : Object.keys(textFee).length == 1 ? true : false,
                                valFee: Object.keys(textFee).length == 1 ? textFeeVal[100] : textFeeVal[0],
                                marksFeeDefault : textFeeVal,
                                marksFeeDefaultVal: dataPlan && typeof dataPlan.valFee !== null ? ''+dataPlan.valFee : 0,
                            })
                        })
                    } else {
                        if (res.data != null && dataFee.length <= 0) {
                            this.setState({
                                ...this.state,
                                planId: res.data,
                                costDistplay: res.data.cost_display ? res.data.cost_display : 0,
                                costCalc: res.data.cost_calc ? res.data.cost_calc : 0,
                                feeValid: true
                            }, () => {
                                let {dataCustomValue} = this.props;
                                
                                if (dataCustomValue) {
                                    dataCustomValue = JSON.parse(dataCustomValue.plac_custom_value)
                                }
                                this.setState({
                                    ...this.state,
                                    valFee: 0,
                                    disableFee: true,
                                }, () => {
                                    if (dataCustomValue && dataCustomValue.data.length > 0) {
                                        let uniqueVoice = this.getUnique(dataCustomValue.voice);
                                        let uniqueData = this.getUnique(dataCustomValue.data);
                                        // let uniqueFee = this.getUnique(dataCustomValue.data);

                                        if (uniqueVoice.length == 1) {
                                            voice[100] = uniqueVoice[0].val;
                                        }else{
                                            uniqueVoice.map((value, idx) => {
                                                if (value.val != null) {
                                                    // let a = this.replaceALl(value.val, '+', '\r\n');
                                                    voice[Math.trunc(idx*100/(uniqueVoice.length -1))] = value.val ? value.val : 0;
                                                }else{
                                                    // let a = this.replaceALl(value.val, '+', '\r\n');
                                                    voice[Math.trunc(idx*100/(uniqueVoice.length -1))] = value.val ? value.val : 0;
                                                }
                                            })
                                        }
                                        if (uniqueData.length == 1) {
                                            data[100] = uniqueData[0].val;
                                        } else {
                                            uniqueData.map((value, idx) => {
                                                // let b = this.replaceALl(value.val, '+', '\r\n');
                                                data[Math.trunc(idx*100/(uniqueData.length -1))] = value.val ? value.val : 0;
                                            })
                                        }
                                        
                                        let resultData = null;
                                        let resultVoice = null;
                                        if (dataPlan) {
                                            let arrData = Object.keys(data);
                                            resultData = arrData[dataPlan.id == res.data.id ? dataPlan.custom_data : 0];
                                            let arrVoice = Object.keys(voice);
                                            resultVoice = arrVoice[dataPlan.id == res.data.id ? dataPlan.custom_voice : 0];
                                            this.setState({
                                                ...this.state,
                                                marksData:data,
                                                marksVoice: voice,
                                                marksDataDefault: resultData,
                                                marksVoiceDefault: resultVoice,
                                            })
                                        }else{
                                            let arrData = Object.keys(data);
                                            resultData = arrData[dataFrist.custom_data];
                                            let arrVoice = Object.keys(voice);
                                            resultVoice = arrVoice[dataFrist.custom_voice];
                                            this.setState({
                                                ...this.state,
                                                oneValueData: uniqueData.length == 1 ? true : false,
                                                oneValueVoice: uniqueVoice.length == 1 ? true : false,
                                                marksData:data,
                                                marksVoice: voice,
                                                dotVoice:voice,
                                                dotData:data,
                                                marksDataDefault: resultData,
                                                marksVoiceDefault: resultVoice,
                                                customChange: {
                                                    data: resultData,
                                                    voice: resultVoice,
                                                    fee: 0
                                                },
                                            },() => {
                                            })
                                        }
                                    }else{
                                        this.setState({
                                            ...this.state,
                                            marksData:data,
                                            marksVoice: voice,
                                        })
                                    }
                                })
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                dataVoiceFeeValid: true,
                            }, () => {
                                this.toggle()
                            })
                        }
                    }
                }
            }).catch(function(err){
                // this.props.hideLoading();
            })
        } catch (error) {
            this.props.hideLoading();
        }
    }
    replaceALl = (string, search, replace) => {
        return string.split(search).join(replace);
    }

    submitData = () => {
        let data = {};
        data.marksVoice = this.state.marksVoice;
        data.marksData = this.state.marksData;
        data.costDistplay = parseInt(this.state.costDistplay*10/100) + parseInt(this.state.costDistplay) + (this.state.valFee ? parseInt(this.state.valFee) + parseInt(this.state.valFee)*10/100 : 0);
        data.planCustom = this.props.dataCustomValue;
        data.planId = this.state.planId;
        data.planId.valFee = this.state.customChange.fee;
        this.props.planCustomResult(data);
        this.toggle();
    }
    render() {
        let retultDisplay = parseInt(this.state.costDistplay) + parseInt(this.state.costDistplay)*10/100 + (this.state.valFee ? parseInt(this.state.valFee) + parseInt(this.state.valFee)*10/100 : 0);
        let dataVoiceFreeShow;
        if (this.state.dataVoiceFeeValid == true) {
            dataVoiceFreeShow = <div className="text-center">데이터 없습니다</div>;
        }else{
            if (this.state.dataVoiceValid == true) {
                dataVoiceFreeShow = <div>
                                        <div className="progress-wrapper">
                                            <div className="progress-item">
                                                <h4>
                                                문자
                                                </h4>
                                                <div className="right">
                                                    <Slider dots 
                                                        step={100}
                                                        included={false}
                                                        marks={this.state.marksFee} 
                                                        value={this.state.marksFeeDefaultVal ? this.state.marksFeeDefaultVal : this.state.oneValueFee ? 100 : 0} 
                                                        onAfterChange={this.logFee.bind(this, this.state.marksFee)} 
                                                        onChange = {this.targetDragFee.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="total-progress">
                                            월 통신요금: <span>{priceFormat(retultDisplay)}</span> 원 (VAT 포함)
                                        </h5>
                                    </div>
            } else {
                if (this.state.feeValid == true) {
                    dataVoiceFreeShow = <div>
                                            <div className="progress-wrapper">
                                                <div className="progress-item">
                                                    <h4>
                                                    음성
                                                    </h4>
                                                    <div className="right">
                                                        <Slider dots 
                                                            included={false}
                                                            marks={this.state.marksVoice} 
                                                            min={0} 
                                                            step={100} 
                                                            value={this.state.marksVoiceDefault ? this.state.marksVoiceDefault : this.state.oneValueVoice ? 100 : 0}
                                                            onAfterChange={this.logVoice.bind(this, this.state.marksVoice)} 
                                                            onChange = {this.targetDragVoice.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="progress-item">
                                                    <h4>
                                                    데이터
                                                    </h4>
                                                    <div className="right">
                                                        <Slider dots 
                                                            step={null} 
                                                            included={false}
                                                            min={0}
                                                            marks={this.state.marksData} 
                                                            value={this.state.marksDataDefault ? this.state.marksDataDefault : this.state.oneValueData ? 100 : 0}
                                                            onAfterChange={this.logData.bind(this, this.state.marksData)} 
                                                            onChange = {this.targetDragData.bind(this)}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="total-progress">
                                                월 통신요금: <span>{priceFormat(retultDisplay)}</span> 원 (VAT 포함)
                                            </h5>
                                        </div>
                } else {
                    if (this.state.didMountModal) {
                        dataVoiceFreeShow = <div>
                                                    <div className="progress-wrapper">
                                                        <div className="progress-item">
                                                            <h4>
                                                            음성
                                                            </h4>
                                                            <div className="right">
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="progress-item">
                                                            <h4>
                                                            데이터
                                                            </h4>
                                                            <div className="right">
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="progress-item">
                                                        <h4>
                                                        문자
                                                        </h4>
                                                        <div className="right">
                                                            
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                    }else{
                        dataVoiceFreeShow = <div>
                                                <div className="progress-wrapper">
                                                    <div className="progress-item">
                                                        <h4>
                                                        음성
                                                        </h4>
                                                        <div className="right">
                                                            <Slider dots 
                                                                included={false}
                                                                marks={this.state.marksVoice} 
                                                                min={0} 
                                                                step={100} 
                                                                value={this.state.marksVoiceDefault ? this.state.marksVoiceDefault : this.state.oneValueVoice ? 100 : 0}
                                                                onAfterChange={this.logVoice.bind(this, this.state.marksVoice)} 
                                                                onChange = {this.targetDragVoice.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="progress-item">
                                                        <h4>
                                                        데이터
                                                        </h4>
                                                        <div className="right">
                                                            <Slider dots 
                                                                step={null} 
                                                                included={false}
                                                                min={0}
                                                                marks={this.state.marksData} 
                                                                value={this.state.marksDataDefault ? this.state.marksDataDefault : this.state.oneValueData ? 100 : 0}
                                                                onAfterChange={this.logData.bind(this, this.state.marksData)} 
                                                                onChange = {this.targetDragData.bind(this)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="progress-item">
                                                    <h4>
                                                    문자
                                                    </h4>
                                                    <div className="right">
                                                        <Slider dots 
                                                            step={100}
                                                            included={false}
                                                            min={0}
                                                            marks={this.state.marksFee} 
                                                            value={this.state.marksFeeDefaultVal ? this.state.marksFeeDefaultVal : this.state.oneValueFee ? 100 : 0} 
                                                            onAfterChange={this.logFee.bind(this, this.state.marksFee)} 
                                                            onChange = {this.targetDragFee.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                                <h5 className="total-progress">
                                                    월 통신요금: <span>{priceFormat(retultDisplay)}</span> 원 (VAT 포함)
                                                </h5>
                                            </div>
                    }
                }
            }
        }
        return (
            <Fragment>
                <Modal 
                    isOpen={this.props.showModalCustomPlan} 
                    toggle={this.toggle} 
                    className="modal-dialog-centered modal-dialog-scrollable modal-lg"
                    contentClassName="content pl-5 pr-5 abc"
                    onOpened={this.setMarks}
                    // backdrop="static"
                >
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">
                        {this.props.dataCustomValue.plac_name}
                        </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" onClick={() => this.toggle('modalCustomSubmit')} aria-label="Close">
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                        <div className="modal-body discount-card pl-0 pr-0">
                            {dataVoiceFreeShow}
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 mb-2">
                            {!this.state.dataVoiceFeeValid ? 
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={this.submitData} data-dismiss="modal">적용</button>
                            :<button type="button" className="btn btn-submit pl-5 pr-5" onClick={() => this.toggle('modalCustom')} data-dismiss="modal">적용</button>}
                        </div>
                    </div>
                </Modal> 
                </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getCostDiplayPlan: (dotData, customChange) => dispatch(getCostDiplayPlan(dotData, customChange)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(PlanModalComponent);

