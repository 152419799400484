import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import {
  getPlanInformation,
  getPlanClassifications,
  setBookMark,
} from "../../store/actions/plan_information";
// import CustomizeLoading from './CustomizeLoading';
import PlanModalComponent from "./PlanModalComponent";
import {
  numberWithCommas,
  priceFormat,
  roundUpNumber,
} from "../../utils/helper";
import { showLoading, hideLoading } from "./../../store/actions/ui";
import "./PlanInformationModal.css";
import InfiniteScroll from "react-infinite-scroll-component";
class PlanInformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      isLoading: false,
      firstLoad: true,
      dataSearch: {
        searchInput : null,
        currentId : 'last',
        isBookMark: null,
      },
      showModalCustomPlan: false,
      dataCustomValue: "",
      ificationPlan: [],
      bookMarkPlan: [],
      bookMarkPlanCustom: [],
      plans: {
        data: [],
      },
      dataFilterAll: {
        page: 1,
        currentId: "ALL",
        telcomID: null,
        matchID: null,
        planID: null,
      },
    };
  }

  /**
   * toggle modal
   */
  toggle = () => {
    this.props.toggleModal();
  };

  /**
   * close modal
   */
  closeModal = () => {
    let data = { ...this.state.dataSearch };
    data.currentId = "last";
    data.searchInput = null;
    this.setState({
        dataSearch: data,
        firstLoad: true,
        currentTab: 0,
        plans: {
            data:[]
        }
    });
  };

  /**
   * detect open modal
   */
  onOpened = () => {
    let dataNull = [];
    let propsPlan = this.props?.plan || {};
    this.props.showLoading();
    let telcomId = [];
        if (this.props.telcomID && this.props.telcomID.length > 0) {
          telcomId = this.props.telcomID;
        } else {
          telcomId.push(this.props.telcomID);
        }
        let dataClass = {
          telcomID: telcomId,
          matchID: this.props.matchID,
          // planID: propsPlan.id
        };
        this.props.getPlanClassifications(dataClass).then((data) => {
        //   this.setState({
        //     ificationPlan: this.props.ificationPlan,
        //   });
          if (data.data.length > 0) {
            this.plansClassIfication(data.data);
            let lastId = data.data[0].id;
            // let dataFilter = {
            //     'currentId' : lastId,
            //     'telcomID'  : telcomId[0],
            // };
            let planClassID = [];
            data.data.map((items, id) => {
                planClassID.push(items.id);
            })
            this.setState({
              ...this.state,
              currentTab: "ALL",
              dataSearch: {
                currentId: "ALL",
              },
            });
            let dataFilter = {
              currentId: "ALL",
              telcomID: telcomId[0],
              matchID: this.props.matchID,
              // planID: propsPlan.id,
              page: 1,
            };
            this.setState({
              dataFilterAll: {
                page: 1,
                currentId: "ALL",
                telcomID: telcomId[0],
                matchID: this.props.matchID,
                // planID: propsPlan.id
              },
            });
            this.props.getPlanInformation(dataFilter).then((res) => {
              // this.showDataPlanById(dataInfo)
              // this.setState({
              //     isLoading : false,
              // });
              // this.props.hideLoading()
              if (res.data.data.length > 0) {
                this.setState({
                  plans: {
                    data: res.data.data,
                  },
                  dataFilterAll: {
                    ...this.state.dataFilterAll,
                    page: res.data.current_page,
                  },
                });
                var bookMarkPlanCustomArr = [];
                var bookMarkPlanArr = [];
                res.data.data.map((item, idx) => {
                  if (item.plac_id) {
                    if (item.is_bookmark == true) {
                      bookMarkPlanCustomArr.push(item.plac_id);
                    }
                  }
                  if (item.id) {
                    if (item.is_bookmark == true) {
                      bookMarkPlanArr.push(item.id);
                    }
                  }
                });
                this.setState({
                  bookMarkPlanCustom: bookMarkPlanCustomArr,
                  bookMarkPlan: bookMarkPlanArr,
                });
              }
            });
          } else {
            this.setState({
              isLoading: false,
            });
            this.props.hideLoading();
          }
        });
  };

  /**
   * set current tab and filter by id
   * @param {*} id
   */
  setCurrentTab = (id) => {
    this.setState({
        ...this.state,
        plans: {
            data: []
        }
    }, () => {
        this.props.showLoading();
        let data = {...this.state.dataSearch};
        data.currentId = id;
        data.matchID = this.props.matchID;
        data.telcomID = this.props.telcomID;
        this.setState({
          dataFilterAll: {
              ...this.state.dataFilterAll,
              page: 1,
              currentId : id,
              searchInput : null,
          },
          firstLoad : false,
          currentTab : id,
          dataSearch: {
              ...this.state.dataSearch,
              searchInput : null,
              isBookMark: null
          }
      }, () => {
          this.props.showLoading();
          this.props.getPlanInformation(this.state.dataFilterAll).then((res) => {
              if (res.data.data.length > 0) {
                  this.setState({
                      plans: {
                          data: res.data.data
                      },
                      dataFilterAll: {
                          ...this.state.dataFilterAll,
                          page: res.data.current_page,
                      }
                  })
                  var bookMarkPlanCustomArr = [];
                  var bookMarkPlanArr = [];
                  res.data.data.map((item, idx) => {
                      if(item.plac_id){
                          if (item.is_bookmark == true) {
                              bookMarkPlanCustomArr.push(item.plac_id)
                          }
                      }
                      if (item.id) {
                          if (item.is_bookmark == true) {
                              bookMarkPlanArr.push(item.id)
                          }
                      }
                  })
                  this.setState({
                      bookMarkPlanCustom: bookMarkPlanCustomArr,
                      bookMarkPlan: bookMarkPlanArr,
                  })
              }
              this.props.hideLoading();
          });
      });
      this.props.hideLoading();
    })
        
  };

  /**
   * get className
   */
  getClassName = (index,id) => {
    if (this.state.firstLoad) {
        if (index == 'ALL') {
            return 'active';
        }
    } else {
        if (id == this.state.currentTab) {
            return 'active';
        }
    }
    return '';
}

  /**
   * render element data plans
   *
   */
  plansClassIfication = (data) => {
    if (data.data && data.data.length > 0) {
      return data.data.map((item, index) => {
        return (
          <li className="nav-item tab-item" key={index}>
            <a
              className={"nav-link " + this.getClassName(index,item.id)}
              id={item.id + "-tab"}
              data-toggle="tab"
              href={"#" + item.id}
              role="tab"
              aria-controls={item.id}
              aria-selected={index == 0 ? "true" : "false"}
              onClick={this.setCurrentTab.bind(this, item.id)}
            >
              {item.name}
            </a>
          </li>
        );
      });
    }

    return "";
  };

  /***
   * show custom data value
   *
   */
  showCustomData = (item) => {
    this.setState({
      ...this.state,
      dataCustomValue: item,
      showModalCustomPlan: !this.state.showModalCustomPlan,
    });
  };

  getPlanData = (data) => {
    this.props.planResultPackage(data);
    this.toggle();
  };
  /**
   * show plan by id
   */
  showDataPlanById = (data) => {
    if (!this.state.isLoading && data && data.length > 0) {
      let propsPlan = this.props?.plan || {};

      let element = data.map((item, index) => {
        return (
          <tr key = {index}
              className={propsPlan.id == item.id ? 'active' : ''}
          >
              <th scope="row" className="name" onClick={this.getPlanData.bind(this, item)} >{item.name}</th>
              <td onClick={this.getPlanData.bind(this, item)} >월정액 {priceFormat(roundUpNumber(+item.cost_display + item.cost_display*0.1))}원</td>
              <td onClick={this.getPlanData.bind(this, item)} >{item.call_time_desc}</td>
              <td onClick={this.getPlanData.bind(this, item)} >{item.data_detail}</td>
              <td onClick={this.getPlanData.bind(this, item)} >{item.sms_desc}</td>
              <td className={(this.state.bookMarkPlan.indexOf(item.id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                  <button className="btn p-0 mr-2">
                      <i className="far fa-star font-16"></i>
                  </button>
              </td>
          </tr>
        );
      });
      return element;
    } else {
      return "";
    }
  };

  showDataPlanCustomById = (data) => {
    var cost_displayA = {};
    var custom_value = {};
    var custom_value_data = [];
    var custom_value_voice = [];
    if (data && data.length > 0) {
      let element = data.map((item, index) => {
        if (!custom_value[item.plac_id]) {
          custom_value[item.plac_id] = [];
        }
        if (item.plac_custom_value) {
          custom_value[item.plac_id].push(JSON.parse(item.plac_custom_value));
        }
        if (custom_value[item.plac_id][0]) {
          if (
            custom_value[item.plac_id][0].data &&
            custom_value[item.plac_id][0].data.length > 0
          ) {
            custom_value[item.plac_id][0].data.map((val, idx) => {
              if (!custom_value_data[item.plac_id]) {
                custom_value_data[item.plac_id] = [];
              }
              custom_value_data[item.plac_id].push(parseInt(val.val));

              if (!custom_value_voice[item.plac_id]) {
                custom_value_voice[item.plac_id] = [];
              }
              custom_value_voice[item.plac_id].push(parseInt(val.val));
            });
          }
        }

        if (item.plan && item.plan.length > 0) {
          item.plan.map((cost, idx) => {
            if (!cost_displayA[item.plac_id]) {
              cost_displayA[item.plac_id] = [];
            }
            cost_displayA[item.plac_id].push(parseInt(cost.cost_display));
          });
        }
        let number = "";
        if (cost_displayA[item.plac_id]) {
          number = cost_displayA[item.plac_id].sort(function (a, b) {
            return b - a;
          });
        }

        let number_data = "";
        if (custom_value_data[item.plac_id]) {
          number_data = custom_value_data[item.plac_id].sort(function (a, b) {
            return b - a;
          });
        }

        //
        let number_voice = "";
        if (custom_value_voice[item.plac_id]) {
          number_voice = custom_value_voice[item.plac_id].sort(function (a, b) {
            return b - a;
          });
        }

        return (
          <tr key = {index}
                // className={activeID == item.plac_id ? 'active' : ''}
            >
                <th scope="row" className="name" onClick={this.showCustomData.bind(this, item)} >{item.plac_name}</th>
                <td onClick={this.showCustomData.bind(this, item)} >{number ? (priceFormat(number[number.length -1]) +'~'+ priceFormat(number[0]))+ '원' : ''} </td>
                {/* <td>
                    {number_data ? (number_data[number_data.length - 1]+'분~'+number_data[0]+'분') : ''} /
                    {number_voice ? (number_voice[number_voice.length - 1]+'~'+number_voice[0]) : ''}
                </td> */}
                <td onClick={this.showCustomData.bind(this, item)} >
                    {item.plac_use_call ? item.plac_use_call + '/ ' : ''}
                    {item.plac_use_data ? item.plac_use_data + '/ ' : ''}
                    {item.plac_use_sms}
                </td>
                <td className={(this.state.bookMarkPlanCustom.indexOf(item.plac_id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                    <button className="btn p-0 mr-2">
                        <i className="far font-16 fa-star"></i>
                    </button>
                </td>
            </tr>
        );
      });

      return element;
    } else {
      return "";
    }
  };

  showDataPlanCustomAll = (data) => {
    this.props.showLoading();
    const { WirelessAdvice, checkPlanNew } = this.props;
    let propsPlan = this.props?.plan || {};
    
    var cost_displayA = {};
    var custom_value = {};
    var custom_value_data = [];
    var custom_value_voice = [];
    if (data && data.length > 0) {
        let element = data.map((item,index) => {
            if (item.id) {
                return(
                    <tr key = {index}
                        className={propsPlan.id == item.id ? 'active' : ''}
                        ref={propsPlan.id == item.id ? this.myRef : ''}
                        id={propsPlan.id == item.id ? 'scroll' : 'no-scroll'+index}
                    >
                        <th scope="row" className="name" onClick={this.getPlanData.bind(this, item)}>{item.name}</th>
                        <td onClick={this.getPlanData.bind(this, item)} >월정액 {priceFormat(roundUpNumber(+item.cost_display + item.cost_display*0.1))}원</td>
                        <td onClick={this.getPlanData.bind(this, item)}>{item.call_time_desc}</td>
                        <td onClick={this.getPlanData.bind(this, item)}>{item.data_detail}</td>
                        <td onClick={this.getPlanData.bind(this, item)}>{item.sms_desc}</td>
                        <td className={(this.state.bookMarkPlan.indexOf(item.id) != -1) ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                            <button className="btn p-0 mr-2">
                                <i className="far font-16 fa-star"></i>
                            </button>
                        </td>
                    </tr>
                );
            }
            // || (item.plan_custom_sms && item.plan_custom_sms.length > 0)
            if (item.plac_id) {
                if (item.plan && item.plan.length > 0) { 
                    if (!custom_value[item.plac_id]) {
                        custom_value[item.plac_id] = []
                    }
                    if (item.plac_custom_value) {
                        custom_value[item.plac_id].push(JSON.parse(item.plac_custom_value))
                    }
                    if (custom_value[item.plac_id][0]) {
                        if (custom_value[item.plac_id][0].data && custom_value[item.plac_id][0].data.length > 0) {
                            custom_value[item.plac_id][0].data.map((val, idx) => {
                                if (!custom_value_data[item.plac_id]) {
                                    custom_value_data[item.plac_id] = []
                                }
                                custom_value_data[item.plac_id].push(parseInt(val.val));

                                if (!custom_value_voice[item.plac_id]) {
                                    custom_value_voice[item.plac_id] = []
                                }
                                custom_value_voice[item.plac_id].push(parseInt(val.val));
                            })
                        }

                    }

                    if (item.plan && item.plan.length > 0) {
                        item.plan.map((cost, idx) => {
                            if (!cost_displayA[item.plac_id]) {
                                cost_displayA[item.plac_id] = []
                            }
                            cost_displayA[item.plac_id].push(parseInt(cost.cost_display))
                        })
                    }
                    let number = '';
                    if (cost_displayA[item.plac_id]) {
                        number = cost_displayA[item.plac_id].sort(function(a, b){return b-a})
                    }

                    let number_data = '';
                    if (custom_value_data[item.plac_id]) {
                        number_data = custom_value_data[item.plac_id].sort(function(a, b){return b-a})
                    }

                    //
                    let number_voice = '';
                    if (custom_value_voice[item.plac_id]) {
                        number_voice = custom_value_voice[item.plac_id].sort(function(a, b){return b-a})
                    }
                    return(
                        <tr key = {index}
                            className={propsPlan.id == item.plac_id ? 'active' : ''}
                        >
                            <th scope="row" className="name" width="20" onClick={this.showCustomData.bind(this, item)}>{item.plac_name}</th>
                            <td  width="20" onClick={this.showCustomData.bind(this, item)}>
                                {number ? (priceFormat(number[number.length -1]) +'~'+ priceFormat(number[0]))+ '원' : ''}
                            </td>
                            {/* <td>
                                {number_data ? (number_data[number_data.length - 1]+'분~'+number_data[0]+'분') : ''} /
                                {number_voice ? (number_voice[number_voice.length - 1]+'~'+number_voice[0]) : ''}
                            </td> */}
                            <td  onClick={this.showCustomData.bind(this, item)} width="30">
                                {item.plac_use_call ? item.plac_use_call + '/ ' : ''}
                                {item.plac_use_data ? item.plac_use_data + '/ ' : ''}
                                {item.plac_use_sms}
                            </td>
                            <td  width="20" onClick={this.showCustomData.bind(this, item)}></td>
                            <td  width="20" onClick={this.showCustomData.bind(this, item)}></td>
                            <td  width="10" className={(this.state.bookMarkPlanCustom.indexOf(item.plac_id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                                <button className="btn p-0 mr-2">
                                    <i className="far font-16 fa-star"></i>
                                </button>
                            </td>
                        </tr>
                    );
                }
            }
        });
        this.props.hideLoading();
        // this.executeScroll();
        return element;
    }

}

  handleCheckBookMark = (data) => {
    const { WirelessAdvice, auth } = this.props;
    let dataObj = {
      model: WirelessAdvice.WirelessAdviceStep3.phone.modelID,
      uid: auth.user.id,
    };
    this.props.showLoading();
    if (data.plac_id) {
      dataObj.plac_id = data.plac_id;
      dataObj.type = 1; //plac_id
    } else {
      dataObj.id = data.id;
      dataObj.type = 0; //id
    }
    this.props
      .setBookMark(dataObj)
      .then((res) => {
        if (res.data.type && res.data.type == "unCheck") {
          let arrNewCus;
          let arrNewPlan;
          if (dataObj.type == 1) {
            arrNewCus = this.state.bookMarkPlanCustom.filter(
              (item) => item !== +res.data.plac_id
            );
          } else {
            arrNewPlan = this.state.bookMarkPlan.filter(
              (item) => item !== +res.data.plan
            );
          }
          this.setState({
            bookMarkPlanCustom: arrNewCus
              ? arrNewCus
              : this.state.bookMarkPlanCustom,
            bookMarkPlan: arrNewPlan ? arrNewPlan : this.state.bookMarkPlan,
          });
        } else {
          if (dataObj.type == 1) {
            this.setState({
              bookMarkPlanCustom: [
                ...this.state.bookMarkPlanCustom,
                res.data.plac_id,
              ],
            });
          } else {
            this.setState({
              bookMarkPlan: [...this.state.bookMarkPlan, res.data.plan],
            });
          }
        }
        this.props.hideLoading();
      })
      .catch((err) => {
        this.props.hideLoading();
      });
  };

  /**
   * handle change input
   */
  handleChange = (e) => {
    // let data = { ...this.state.dataSearch };
    // data.searchInput = e.target.value;
    // this.setState({
    //   dataSearch: data,
    // });
    this.setState({
      ...this.state,
      dataSearch : {
          ...this.state.dataSearch,
          searchInput : e.target.value,
      },
      dataFilterAll: {
          ...this.state.dataFilterAll,
          searchInput : e.target.value,
      }
  })
  };
  /**
   * enter KeyPressed
   * @param {*} e
   */
  enterPressed = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      this.handleSearch(e);
    }
  };
  /**
     * is book mark search
     *
     */
    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({
          ...this.state,
          dataSearch: {
              ...this.state.dataSearch,
              isBookMark: value ? value : null
          }
      }, () => {
          this.handleSearch();
      });
  }

  /**
   * handle Search
   */
  handleSearch = () => {
    this.props.showLoading();
    let data = {...this.state.dataSearch};
    // data.isBookMark = this.state.dataSearch.isBookMark;
    data.telcomID = this.props.telcomID;
    data.matchID = this.props.matchID;
    data.currentId = this.state.dataFilterAll.currentId;
    this.props.getPlanInformation(data).then((res) => {
        this.setState({
            plans: {
                data: res.data.data
            },
            dataFilterAll: {
                ...this.state.dataFilterAll,
                page: res.data.current_page,
            }

        }, () => {
            var bookMarkPlanCustomArr = [];
                var bookMarkPlanArr = [];
                res.data.data.map((item, idx) => {
                    if(item.plac_id){
                        if (item.is_bookmark == true) {
                            bookMarkPlanCustomArr.push(item.plac_id)
                        }
                    }
                    if (item.id) {
                        if (item.is_bookmark == true) {
                            bookMarkPlanArr.push(item.id)
                        }
                    }
                })
                this.setState({
                    bookMarkPlanCustom: bookMarkPlanCustomArr,
                    bookMarkPlan: bookMarkPlanArr,
                })
        })
        this.props.hideLoading();
    }).catch(() => {
        this.props.hideLoading();
    });
}
  /**
   * show loading
   */
  showLoadingScreen = (isLoading) => {
    if (isLoading) {
      // return(<CustomizeLoading/>);
    }
  };

  hideModalPlanCustom = () => {
    this.setState({
      ...this.state,
      showModalCustomPlan: false,
    });
  };

  planCustomResult = (data) => {
    this.props.planCustomResultPackage(data);
    this.toggle();
  };

  fetchMoreData = () => {
    this.props.showLoading();
    this.setState(
      {
        dataFilterAll: {
          ...this.state.dataFilterAll,
          page: this.state.dataFilterAll.page + 1,
        },
      },
      () => {
        this.props.getPlanInformation(this.state.dataFilterAll).then((res) => {
          if (res.data.data.length > 0) {
            this.setState(
              {
                plans: {
                  ...res.data.data,
                  data: [...this.state.plans.data, ...res.data.data],
                },
                dataFilterAll: {
                  ...this.state.dataFilterAll,
                  page: res.data.current_page,
                },
              },
              () => {
                var bookMarkPlanCustomArr = [];
                var bookMarkPlanArr = [];
                this.state.plans.data.map((item, idx) => {
                  if (item.plac_id) {
                    if (item.is_bookmark == true) {
                      bookMarkPlanCustomArr.push(item.plac_id);
                    }
                  }
                  if (item.id) {
                    if (item.is_bookmark == true) {
                      bookMarkPlanArr.push(item.id);
                    }
                  }
                });
                this.setState({
                  bookMarkPlanCustom: bookMarkPlanCustomArr,
                  bookMarkPlan: bookMarkPlanArr,
                });
              }
            );
          }
          this.props.hideLoading();
        })
        .catch(() => {
          this.props.hideLoading();
        });
      }
    );
    
  };

  componentWillUnmount = () => {
    this.setState({
        plans: {
            data:[]
        }
    })
}

  render() {
    let plans = this.state.plans.data;
    // let ificationPlan = this.state.ificationPlan || [];
    let ificationPlan = this.props.ificationPlan || [];
    let { isLoading } = this.state;

    return (
      <Fragment>
        {this.showLoadingScreen(isLoading)}
        <Modal
          isOpen={this.props.isShowModal}
          className="modal-xl modal-list-plan"
          contentClassName="pl-5 pr-5"
          toggle={this.toggle}
          onOpened={this.onOpened}
          onClosed={this.closeModal}
          // backdrop={"static"}
        >
          <div className="modal-header">
            <h5 className="modal-title text-left w-100">요금제 선택 </h5>
            <button
              type="button"
              className="close close-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggle}
            >
               <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>

          <div className="modal-body content-product mt-0 pl-0 pr-0">
            <div className="left-product">
                <div className="top" style={{minHeight: '46px'}}>
                {
                    ificationPlan.data && ificationPlan.data.length > 0 ? (
                    <ul className="nav nav-tabs list-tab w-100" id="myTab" role="tablist">
                        <li className="nav-item tab-item">
                            <a
                                className = {"nav-link cursor " + this.getClassName('ALL','ALL') }
                                role="tab"
                                onClick = {this.setCurrentTab.bind(this,'ALL')}
                            >
                                {'전체'}
                            </a>
                        </li>
                        {this.plansClassIfication(ificationPlan)}
                        <li className="nav-item tab-item">
                            <a
                                className = {"nav-link cursor " + this.getClassName('customized','customized') }
                                role="tab"
                                onClick = {this.setCurrentTab.bind(this,'customized')}
                            >
                                {'맞춤형'}
                            </a>
                        </li>
                    </ul>
                        ) : ''
                    }

                </div>

              <div className="row top-search-query">
                <div className="col-md-6">
                  <div className="form-search">
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      ref={(el) => (this.inputSearch = el)}
                      placeholder="검색어를 입력해 주세요"
                      onChange={this.handleChange}
                      onKeyPress={this.enterPressed}
                      value={this.state.dataSearch.searchInput ? this.state.dataSearch.searchInput : '' }
                      aria-label="Search"
                    />
                    <button className="btn my-2 my-sm-0" type="submit">
                      <img
                        src="/images/icon-search.svg"
                        alt=""
                        onClick={this.handleSearch}
                      />
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-check justify-content-end">
                    <div className="form-group form-check">
                      <div className="custom-control custom-checkbox mr-sm-2">
                      <input type="checkbox"
                              className="custom-control-input"
                              id="customControlAu122"
                              checked={this.state.dataSearch.isBookMark}
                              onChange={this.handleInputChange}/>
                      <label className="custom-control-label" htmlFor="customControlAu122">즐겨찾기 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-content" id="myTabContendt">
                <div className="fade show">
                  <article
                    className="table-form"
                    id="scrollableDiv"
                    style={{ maxHeight: "405px", overflowX: "auto" }}
                  >
                    {plans.length > 0 && (
                      <InfiniteScroll
                        dataLength={plans.length}
                        next={() => this.fetchMoreData()}
                        hasMore={plans.length > 0 || false}
                        loader={<></>}
                        scrollableTarget={`scrollableDiv`}
                      >
                        <table className="table table-striped">
                          <tbody>
                            {/* {this.state.currentTab == 'customized' ? this.showDataPlanCustomById(plans) : this.showDataPlanById(plans)} */}
                            {this.state.currentTab == "customized"
                              ? this.showDataPlanCustomById(plans)
                              : this.state.currentTab == "ALL"
                              ? this.showDataPlanCustomAll(plans)
                              : this.showDataPlanById(plans)}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    )}
                  </article>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <PlanModalComponent
          showModalCustomPlan={this.state.showModalCustomPlan}
          hideModalPlanCustom={this.hideModalPlanCustom}
          dataCustomValue={this.state.dataCustomValue}
          planCustomResult={this.planCustomResult}
          consultant={this.props.consultant}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plans: state.plan.dataPlans,
    ificationPlan: state.plan.ificationPlan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPlanInformation: (data) => dispatch(getPlanInformation(data)),
    getPlanClassifications: (data) => dispatch(getPlanClassifications(data)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    setBookMark: (data) => dispatch(setBookMark(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInformationModal);
