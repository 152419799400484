import {
    SIM
} from '../actions';

import api from "../../utils/api"; 

export const getSimSuccess = (data) => {
    return {
        type: SIM.GET_SIM_SUCCESS,
        data: data
    }
};

export const getSims = (data) => dispatch => {
    return api.sim.getSims(data)
        .then(res => {
            dispatch(getSimSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};