import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { priceFormat } from './../../../utils/helper'

const totlaFeeOption = (options = []) => options.reduce((prevFee, option) => {
  return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
}, 0)
const totlaOptionDiscountAdditional = (options = []) => options.reduce((prevAdditional, option) => {
  return prevAdditional + +(option.additional || 0)
}, 0)
const totlaOptionDiscountValue = (options = []) => options.reduce((prevValue, option) => {
  return prevValue + +(option.value || 0)
}, 0)
const totlaOptionDiscountCombine = (options = []) => options.reduce((prevValue, option) => {
  return prevValue + +(option.combine || 0)
}, 0)

const ProductClassificationItem = ({
  item,
  showModalProduct,
  showModalOption,
  showModalCost,
  addClassify,
  maxLengthClassify,
}) => (
  <Fragment>
    <tr style={{cursor:'default'}}>
      <td>
        <div className="d-flex flex-column text-center mt-cs3">
          <div>
            <img
             src={item?.icon}
             title={item?.name}
            />
          </div>
          <span className="title-table">
            {item?.name}
          </span>
        </div>
      </td>
      <td>
        <div className="w-170">
          {item?.classify.length === 0 && (
            <button
              className="btn add_line_blue w-100 mb-2"
              onClick={() => {addClassify(); showModalProduct(0)}}
            >
              <i className="fas fa-plus"></i> 대표회선
            </button>
          )}
          {item?.classify.map((clf, key) => (
            <button
              key={key}
              className="btn btn-input-form mb-1"
              type="button"
              onClick={() => showModalProduct(key)}
            >
              {clf?.device?.wd_name ? (clf?.device?.wd_name + (clf?.device?.wd_class == 91 ? ` (Ch.${clf?.device?.wd_channel_total})` : '')) : '미적용'}
            </button>
          ))}
          {item?.classify.length <= maxLengthClassify && (
            <button
              className="btn add_line w-100"
              onClick={() => {
                addClassify()
                showModalProduct(+item?.classify.length - 1)
              }}
            >
              <i className="fas fa-plus"/> 추가회선
            </button>
          )}
        </div>
      </td>
      <td>
        {item?.classify.map((clf, key) => (
          <button
            key={key}
            className="btn btn-input-form mb-1"
            type="button"
            onClick={() => showModalOption(key)}
          >
          {(clf?.options && clf.options.length > 0) ? (
            clf.options.length == 1 ? (
              (clf?.options[0]?.wdo_name && clf?.options[0]?.wdo_name != "")
                ? (clf?.options[0]?.wdo_name)
                : (clf?.options[0]?.wdo_model_name)
            ) : (
              (((clf?.options[0]?.wdo_name && clf?.options[0]?.wdo_name != "")
                ? clf?.options[0]?.wdo_name
                : clf?.options[0]?.wdo_model_name) + ' 외 ' + (clf?.options.length - 1) + '개')
            )
          ) : '미적용'}
          </button>
        ))}
      </td>
      <td>
        {item?.classify.map((clf, key) => (
          <input
            key={key}
            type="text"
            className="form-control w-90 h-44 mb-1"
            value={`${priceFormat(clf?.cost?.value)} 원`}
            disabled
          />
        ))}
      </td>
      <td>
        {item?.classify.map((clf, key) => (
          <button
            key={key}
            className="btn btn-input-form mb-1 text-bold"
            type="button"
            onClick={() => showModalCost(key)}
          >
            {`${priceFormat((totlaFeeOption(clf.options) - totlaOptionDiscountAdditional(clf?.discount?.options) - totlaOptionDiscountValue(clf?.discount?.options) - totlaOptionDiscountCombine(clf?.discount?.options)) + (+(clf.device?.wd_commitment_fee || 0) - clf?.discount?.device?.additional - clf?.discount?.device?.value))} 원`}
          </button>
        ))}
      </td>
    </tr>
  </Fragment>
);

ProductClassificationItem.defaultProps = {
  maxLengthClassify: 2
};

ProductClassificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  showModalProduct: PropTypes.func.isRequired,
  showModalOption: PropTypes.func.isRequired,
  showModalCost: PropTypes.func.isRequired,
  addClassify: PropTypes.func.isRequired,
  maxLengthClassify: PropTypes.number.isRequired,
};

export default ProductClassificationItem
