import React, { Component, Fragment } from 'react'

export default class renderFileField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: '',
            imagePreviewUrl: this.props.image_url ? this.props.image_url : ''
        }
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        const { input: { onChange } } = this.props
        onChange(e.target.files[0])

        let reader = new FileReader();
        let file = e.target.files[0];


        if (file) {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result
                });
            }
            reader.readAsDataURL(file);
        } else {
            this.setState({
                file: '',
                imagePreviewUrl: ''
            });
        }
        // reader.readAsDataURL(this.state.file);
    }

    upload() {
        document.getElementById("selectImage").click()
    }

    render() {
        const { input: { value, name } } = this.props
        const { input, required, meta: { touched, invalid, error }, image_url } = this.props  //whatever props you send to the component from redux-form Field
        return (
            <div className="info_profile">
                <div className="box_info">
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <Fragment>
                                <Fragment>

                                    {this.state.imagePreviewUrl ?
                                        <div className="avatar"> <img
                                            src={this.state.imagePreviewUrl ? this.state.imagePreviewUrl : image_url}
                                            alt=""
                                            style={{ 'width': '100%' }} /> </div> : (image_url) ?
                                            <div className="avatar"><img
                                                src={this.state.imagePreviewUrl ? this.state.imagePreviewUrl : image_url}
                                                alt=""
                                                style={{ 'width': '100%' }} /></div> :
                                            <div className="avatar">
                                        </div>
                                    }
                                </Fragment>
                                <input
                                    name={name}
                                    type='file'
                                    accept='.jpg, .png, .jpeg'
                                    onChange={this.onChange}
                                    className={'form-control ' + (invalid ? 'is-invalid' : '') + ' h-44'}
                                    hidden
                                    id='selectImage'
                                />
                                <button type="button" className="btn btn-add-img" onClick={this.upload}>
                                    <img src="images/Camera.svg" alt="" />
                                </button>
                                {error !== undefined ? (
                                    <div className="invalid-feedback" style={{ position: 'relative', top: '25px' }}>
                                        {error}
                                    </div>
                                ) : ''}
                            </Fragment>
                        </div></div>
                </div>
            </div>


        )
    }
}