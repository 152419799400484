 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {
    priceFormat,
    getDeviceDiscountWired,
    getOptionDiscountWired,
    calcPenaltyWired,
} from "../../../utils/helper";

const WD_FINES = {
    "WD_FINES_FEE_1": [{"month": 1,"scale": "100%"},{"month": 5,"scale": "100%"},{"month": 9,"scale": "70%"},{"month": 13,"scale": "35%"},{"month": 17,"scale": "25%"},{"month": 21,"scale": "0%"},{"month": 2,"scale": "100%"},{"month": 6,"scale": "100%"},{"month": 10,"scale": "70%"},{"month": 14,"scale": "35%"},{"month": 18,"scale": "25%"},{"month": 22,"scale": "0%"},{"month": 3,"scale": "100%"},{"month": 7,"scale": "70%"},{"month": 11,"scale": "70%"},{"month": 15,"scale": "35%"},{"month": 19,"scale": "25%"},{"month": 23,"scale": "0%"},{"month": 4,"scale": "100%"},{"month": 8,"scale": "70%"},{"month": 12,"scale": "70%"},{"month": 16,"scale": "35%"},{"month": 20,"scale": "25%"},{"month": 24,"scale": "0%"}],
    "WD_FINES_FEE_2": [{"month": 1,"scale": "100%"},{"month": 5,"scale": "100%"},{"month": 9,"scale": "90%"},{"month": 13,"scale": "35%"},{"month": 17,"scale": "10%"},{"month": 21,"scale": "0%"},{"month": 2,"scale": "100%"},{"month": 6,"scale": "100%"},{"month": 10,"scale": "90%"},{"month": 14,"scale": "35%"},{"month": 18,"scale": "10%"},{"month": 22,"scale": "0%"},{"month": 3,"scale": "100%"},{"month": 7,"scale": "100%"},{"month": 11,"scale": "90%"},{"month": 15,"scale": "35%"},{"month": 19,"scale": "10%"},{"month": 23,"scale": "0%"},{"month": 4,"scale": "100%"},{"month": 8,"scale": "100%"},{"month": 12,"scale": "90%"},{"month": 16,"scale": "35%"},{"month": 20,"scale": "10%"},{"month": 24,"scale": "0%"}],
    "WD_FINES_FEE_3": [{"month": 1,"scale": "85%"},{"month": 5,"scale": "85%"},{"month": 9,"scale": "85%"},{"month": 13,"scale": "30%"},{"month": 17,"scale": "30%"},{"month": 21,"scale": "25%"},{"month": 2,"scale": "85%"},{"month": 6,"scale": "85%"},{"month": 10,"scale": "80%"},{"month": 14,"scale": "30%"},{"month": 18,"scale": "30%"},{"month": 22,"scale": "0%"},{"month": 3,"scale": "85%"},{"month": 7,"scale": "85%"},{"month": 11,"scale": "80%"},{"month": 15,"scale": "30%"},{"month": 19,"scale": "25%"},{"month": 23,"scale": "0%"},{"month": 4,"scale": "85%"},{"month": 8,"scale": "85%"},{"month": 12,"scale": "80%"},{"month": 16,"scale": "30%"},{"month": 20,"scale": "25%"},{"month": 24,"scale": "0%"},{"month": 25,"scale": "-10%"},{"month": 26,"scale": "-10%"},{"month": 27,"scale": "-10%"},{"month": 28,"scale": "-10%"},{"month": 29,"scale": "-25%"},{"month": 30,"scale": "-25%"},{"month": 31,"scale": "-25%"},{"month": 32,"scale": "-25%"},{"month": 33,"scale": "-85%"},{"month": 34,"scale": "-85%"},{"month": 35,"scale": "-170%"},{"month": 36,"scale": "-170%"}],
    "WD_FINES_FEE_4": [{"month": 1,"scale": "0%"},{"month": 2,"scale": "0%"},{"month": 3,"scale": "0%"},{"month": 4,"scale": "0%"},{"month": 5,"scale": "0%"},{"month": 6,"scale": "0%"},{"month": 7,"scale": "30%"},{"month": 8,"scale": "30%"},{"month": 9,"scale": "30%"},{"month": 10,"scale": "30%"},{"month": 11,"scale": "30%"},{"month": 12,"scale": "30%"},{"month": 13,"scale": "65%"},{"month": 14,"scale": "65%"},{"month": 15,"scale": "65%"},{"month": 16,"scale": "65%"},{"month": 17,"scale": "75%"},{"month": 18,"scale": "75%"},{"month": 19,"scale": "75%"},{"month": 20,"scale": "75%"},{"month": 21,"scale": "100%"},{"month": 22,"scale": "100%"},{"month": 23,"scale": "100%"},{"month": 24,"scale": "100%"},{"month": 25,"scale": "110%"},{"month": 26,"scale": "110%"},{"month": 27,"scale": "110%"},{"month": 28,"scale": "110%"},{"month": 29,"scale": "125%"},{"month": 30,"scale": "125%"},{"month": 31,"scale": "125%"},{"month": 32,"scale": "125%"},{"month": 33,"scale": "170%"},{"month": 34,"scale": "170%"},{"month": 35,"scale": "280%"},{"month": 36,"scale": "280%"}]
}

class PenaltyInquiryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    totalDeviceOption = (classify) => {
        let totalMonthlyFee = 0;
        let totalBasicDiscount = 0;
        let totalCombinedDiscount = 0;
        let totalAfterDiscount = 0;

        classify.map((cls) => (
            cls.classify.map((item) => {
                totalMonthlyFee += +(item?.device?.wd_commitment_fee || 0)
                totalMonthlyFee += +(item?.option?.wdo_commitment_fee || 0)
                totalBasicDiscount += +(item?.discount?.device?.value || 0)
                totalBasicDiscount += +(item?.discount?.option?.value || 0)
                totalCombinedDiscount += +(item?.discount?.combineDiscount || 0)
            })
        ))
        totalMonthlyFee = totalMonthlyFee > 0 ? totalMonthlyFee : 0
        totalBasicDiscount = totalBasicDiscount > 0 ? totalBasicDiscount : 0
        // totalCombinedDiscount = totalCombinedDiscount > 0 ? totalCombinedDiscount : 0
        totalAfterDiscount = totalMonthlyFee - totalBasicDiscount - totalCombinedDiscount
        return totalAfterDiscount
    }

    renderPenatlyMoney = (month) => {
        const { WiredAdvice } = this.props;
        const { WiredAdviceStep2 } = WiredAdvice;
        let totalPennatlyMoney = 0;
        let classifyGroup = WiredAdviceStep2.classify

        classifyGroup.map((clsGroup) => {
            clsGroup.classify.map((item) => {
                let device = item.device
                let wd_non_commitment_fee = device.wd_non_commitment_fee
                // // Đối với sản phẩm điện thoại của LG U+ thì bỏ cả Chi phí thuê (không cam kết),
                // // Chi phí thuê (cam kết) ra khỏi công thức.
                // // -> tính theo tiền wd_commitment_fee
                // if (WiredAdvice.WiredAdviceStep1.telcomID == 18 && clsGroup.id === 3) {
                //     wd_non_commitment_fee = +device.wd_commitment_fee * 2
                // }

                // note vô code là task 7000, tiền phạt cả cụm telephone của LGU+ = 0won, sau ni KH có cua thì bốc ra lại
                if (WiredAdvice.WiredAdviceStep1.telcomID == 18 && clsGroup.id === 3) {
                    totalPennatlyMoney += 0
                } else {
                    let penalty = calcPenaltyWired(
                        device.wd_commitment_fee,
                        getDeviceDiscountWired(device, item.discount),
                        wd_non_commitment_fee,
                        device.wd_commitment_fee,
                        device.wd_non_commitment_rent_fee,
                        device.wd_commitment_rent_fee,
                        WD_FINES['WD_FINES_FEE_' + device.wd_fines_fee],
                        month,
                        device.wd_fines_fee == 4 ? 0 : 1
                    );
                    totalPennatlyMoney += penalty;

                    (item.options || []).map((option, keyOption) => {
                        let penalty = calcPenaltyWired(
                            option.wdo_commitment_fee,
                            getOptionDiscountWired(option, item.discount, keyOption),
                            option.wdo_non_commitment_fee,
                            option.wdo_commitment_fee,
                            option.wdo_non_commitment_rent_fee,
                            option.wdo_commitment_rent_fee,
                            WD_FINES['WD_FINES_FEE_' + option.wdo_fines_fee],
                            month,
                            option.wdo_fines_fee == 4 ? 0 : 1
                        );
                        totalPennatlyMoney += penalty;
                    })
                }
            })
        })

        this.props.change('WiredAdviceStep2.penalty' + month, totalPennatlyMoney)
        return priceFormat(totalPennatlyMoney)
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpenModal}
                   // backdrop={"static"}
                   toggle={this.props.openPopupPenatly} fade={true}
                   className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
                   contentClassName="modal-penalty content">
                <div className="modal-header">
                    <h5 className="modal-title">위약금 조회 </h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                            onClick={this.props.openPopupPenatly}>
                         <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody className="discount-card pl-0 pr-0 pt-0">
                    <form action="">

                        <ul className="list-item mt-0 list_custom_01">
                            {/*<li className="border-0 pt-0">*/}
                                {/*<div className="d-flex flex-column">*/}
                                    {/*<h6 className="modal-title">*/}
                                        {/*해지시 예상위약금 : 69,000 원*/}
                                    {/*</h6>*/}
                                    {/*<p className="text-notify-red">※상기 예상 위약금은 가입한 서비스에 한하며, 서비스 변경 및 해지 시점에 따라 위약금은 다르게 청구됩니다.*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}
                            <li className="border-0">
                                <span className="left">위약금 (12개월째)</span>
                                <div className="right">
                                    <h4 className="mb-0 title-page text-bold">{this.renderPenatlyMoney(12)} 원</h4>
                                </div>
                            </li>
                            <li>
                                <span className="left">위약금 (24개월째)</span>
                                <div className="right">
                                    <h4 className="mb-0 title-page text-bold">{this.renderPenatlyMoney(24)} 원</h4>
                                </div>
                            </li>
                        </ul>

                        <div className="modal-footer justify-content-center border-top-0">
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={this.props.openPopupPenatly}>확인</button>
                        </div>
                    </form>
                </ModalBody>


            </Modal>
        );
    }
}

PenaltyInquiryModal.propTypes = {
    WiredAdvice: PropTypes.object,
    change: PropTypes.func,
    openPopupPenatly: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PenaltyInquiryModal);
