import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
 

const renderTextFields = ({ input, meta: { touched, invalid, error }, type, placeholder, min, max, disabled
}) => {
    return (
        <Fragment>
            <input
                {...input}
                type={type}
                className={'form-control ' + (touched && invalid ? 'is-invalid' : '') + ' h-44'}
                placeholder={placeholder}
                min={min}
                max={max}
                disabled={disabled}
                 />
            {error !== undefined ? (
                <div className="invalid-feedback">
                    {error}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderTextFields.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderTextFields;
