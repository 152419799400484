import React, { Component, Fragment } from 'react';
import ChangeDatePlanModal from './ChangeDateModal';
import {connect} from "react-redux";
import {change, getFormSyncErrors, getFormValues, reduxForm} from "redux-form";
import {compose} from 'redux';
import PropTypes from 'prop-types';

class ChangeDateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value : '',
            isShowModal : false
        };
    }

    /**
     * show modal plan
     */
    handleShowModal = () => {
        this.setState({
            isShowModal : !this.state.isShowModal
        })
    }

    /**
     *
     */
    getValuesDate = (value) => {
        this.setState({
            value : value
        });
        this.props.change('WirelessAdviceStep3.planChangeDate',value)
    }

    getValuesDateSelect = (valueSelect) => {
        this.props.change('WirelessAdviceStep3.planChangeDateSelect',valueSelect)
    }

    render() {
        let {isShowModal} = this.state;
        const {isOpenModal, openPopup, handleSubmit,WirelessAdvice: {WirelessAdviceStep1, WirelessAdviceStep3, CreatePotentialCustomer}} = this.props;
        var checkClearPhone = Object.keys(WirelessAdviceStep3.phone).length
        return(
            <Fragment>
                <div className="form-group mb-1 row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">변경 가능일자</label>
                    <div className="col-sm-8">
                        <div className="form-date">
                        <button className="btn btn-input-form mb-0" type="button" onClick={this.handleShowModal}>

                        {checkClearPhone ? this.state.value ? this.state.value : (WirelessAdviceStep3.planChangeDate != '' && WirelessAdviceStep3.planChangeDate != null ? WirelessAdviceStep3.planChangeDate :'변경 가능일자') : '변경 가능일자'}

                            <img src="/images/icon-calender.svg" alt="" />
                        </button>
                        </div>
                    </div>
                </div>
                <ChangeDatePlanModal
                    key={isShowModal}
                    isShowModal={isShowModal}
                    toggleModal = {this.handleShowModal}
                    getValuesDate = {this.getValuesDate}
                    getValuesDateSelect = {this.getValuesDateSelect}
                    planChangeDateSelect={WirelessAdviceStep3.planChangeDateSelect}
                    planChangeDateSelectDateTime={WirelessAdviceStep3.planChangeDate}
                />
            </Fragment>
        );
    }
}

ChangeDateComponent.propTypes = {

};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(ChangeDateComponent);


