import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";

class ExtraServiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    chooseType = (e,val) => {
        this.props.setOpenType(val,e.target.textContent)
        this.props.openPopup();
    }

    renderExtraService = (data) => {

        return data.map((item,key) => {
            return <tr>
                <td className="text-wrap">{item.ets_name}</td>
                <td><div dangerouslySetInnerHTML={{__html: item.ets_desc}} /></td>
                <td className="text-bold text-wrap">{Math.ceil(item.ets_price)}원</td>
            </tr>
        })
    }

    render() {
        let htmlExtra = this.props.extraServices ? this.renderExtraService(this.props.extraServices) : ''
        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-dialog-centered modal-add-bt'}
            >
                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            부가서비스
                        </h5>
                        <button type="button" className="close close-modal" onClick={this.props.openPopup}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body pl-0 pr-0">
                        <article className="">
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr className="bg-purple">
                                        <th>서비스명</th>
                                        <th>상세설명</th>
                                        <th className="text-wrap">이용요금</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                                <table className="table table-striped">
                                    <tbody>
                                    {htmlExtra}
                                    </tbody>
                                </table>
                            </div>
                        </article>
                    </div>
            </Modal>
        );
    }
}

export default withRouter(ExtraServiceModal);
