
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat,chooseOption2G} from './../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";
import MutualServiceDetailImageModal from "./MutualServiceDetaiImagelModal"
import {detailMutualService, getListMutualService} from "../../../store/actions/dataStore";
import {hideLoading, showLoading} from "../../../store/actions/ui";
const bgGray={
    background:'#6c757d'
}

class MutualServiceDetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenServiceDetailModal:false,
            disnabledOpenImage:false,
            dataChoose:null
        }
    }



    componentDidMount() {

        setTimeout(() => {
            this.setState({
                dataChoose:null
            })
        }, 100)
    }

    renderData= () => {
        const {dataSelected,WirelessAdvice} = this.props;
        let mutualService=WirelessAdvice.WirelessAdviceStep3.mutualService
        let data=this.state.data
        let dataChoose=this.state.dataChoose
        if(dataChoose == null) {
            // dataChoose = mutualService != null ? mutualService : null
            if(mutualService != null) {
                let checkDataChoose=dataSelected.filter(item => item.mus_service_id == mutualService.mus_service_id)
                if(checkDataChoose) {
                    dataChoose = mutualService
                }else {
                    dataChoose = null
                }
            }else {
                dataChoose = null
            }

        }else {
                let checkDataChoose=dataSelected.filter(item => item.mus_service_id == dataChoose.mus_service_id)
                if(checkDataChoose.length == 0) {
                    this.setState({
                        dataChoose:null
                    })
                }
        }


        return dataSelected && dataSelected.length > 0 && dataSelected.map((item, key) => {
            return <li className={dataChoose && dataChoose != null && dataChoose.mus_service_id && dataChoose.mus_service_id == item.mus_service_id ? 'd-flex justify-content-between active align-items-center' :'d-flex justify-content-between align-items-center'}
                       key={key} onClick={() => this.dataChoose(item)}>
                {/*<span className="left">{item.total_discount} </span>*/}
                    <div className="left"><label className="font-weight-bold d-block">{item.name_service}</label><span >월 할인금액({priceFormat(item.price_month)}) x {item.month}개월</span></div>
                <div className="right">
                    <p className="mb-0 w-100">총 할인: {priceFormat(+item.total_price)}원 </p>
                </div>
            </li>
        })
    }

    dataChoose =(data) => {
        this.setState({
            dataChoose:data
        })
    }

    openPopupServiceDetail = () => {
        this.setState({isOpenServiceDetailModal: !this.state.isOpenServiceDetailModal})
    }
    openPopupDetailImage = () => {
        const {handleSubmit, WirelessAdvice,dataSelected} = this.props;
        let dataImageGet=dataSelected && dataSelected.length > 0 && dataSelected[0].image_url != null ? dataSelected[0].image_url : ''
        if(dataImageGet != '' && dataImageGet.length > 0) {
            this.openPopupServiceDetail()
        }


    }
    submitChoose = () => {
        const {dataChoose}=this.state
        if(dataChoose != null) {
            this.props.change('WirelessAdviceStep3.mutualService', dataChoose)
            this.props.openPopupServiceSub()
            this.props.closeFirstPopup()
        }

    }

    renderDescripton = () => {
        const {handleSubmit, WirelessAdvice,dataSelected} = this.props;
        let dataTitleRender=dataSelected && dataSelected.length > 0 ? dataSelected[0].mus_desc : ''
        if(dataTitleRender) {
            // let dataRender=dataTitleRender.split(/\r\n|\r|\n/g)
            // return dataRender.map((item, key) => {
            //     return (<p className=" text-16 mb-0 color-blue" key={key}>{item.replace(/<(.|\n)*?>/g, '')}</p>);
            // })
            return (<p dangerouslySetInnerHTML={{__html: dataTitleRender}} />);
        }

    }



    render() {
        const {handleSubmit, WirelessAdvice,dataSelected} = this.props;

        let dataTitle=dataSelected && dataSelected.length > 0 ? dataSelected[0].mus_desc : ''
        let dataImage=dataSelected && dataSelected.length > 0 ? dataSelected[0].image_url : ''
        let dataNameService=dataSelected && dataSelected.length > 0 ? dataSelected[0].mus_name : ''
        let dataImageGet=dataSelected && dataSelected.length > 0 && dataSelected[0].image_url != null ? dataSelected[0].image_url : ''
        let disnabledOpenImage= false
        if(dataImageGet != '' && dataImageGet.length > 0) {
        }else {
            disnabledOpenImage=true
        }
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpenServiceModal}
                       // backdrop={"static"}
                       toggle={this.props.openPopupServiceSub} fade={true}
                       className={'modal-dialog modal-dialog-centered modal-additional modal-custom-support'}
                       contentClassName="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            {dataNameService}
                            <button className="btn btn-wireless" style={disnabledOpenImage ? bgGray : {}} type="button" data-toggle="modal" onClick={this.openPopupDetailImage}
                                    data-target="#modal-21">상품상세</button>
                        </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" onClick={this.props.openPopupServiceSub} aria-label="Close">
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <ModalBody className="discount-card">
                        {/*<p className="mt-3 text-16 mb-0 color-blue">*/}
                            {/*{dataTitle}*/}
                        {/*</p>*/}
                         <div className="content-discount">
                        {this.renderDescripton()}
                        <ul className="list-additional mt-4">
                            {this.renderData()}

                        </ul>
                        </div>
                        <div className="d-flex justify-content-center modal-footer p-0 border-top-0 mt-2">
                            <button type="button" style={this.state.dataChoose == null ? bgGray : {}} className={'btn btn-submit'} onClick={() =>this.submitChoose()}>적용</button>
                        </div>
                    </ModalBody>


                </Modal>
                <MutualServiceDetailImageModal dataImage={dataImage} isOpenServiceDetailModal={this.state.isOpenServiceDetailModal} openPopupServiceDetail={this.openPopupServiceDetail}  />
            </Fragment>
        );
    }
}

MutualServiceDetailModal.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        detailMutualService: (data) => dispatch(detailMutualService(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(MutualServiceDetailModal);
