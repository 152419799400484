import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import _ from "lodash";
import {isArrayEqual, roundUpNumber, roundUpNumberPlan} from "../../../../utils/helper";
class KTPremiumDiscount2 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            isShowBaseLineModal: false,
            isShowBaseLineOptionModal: false,
            list: [], // list plan not null
            base_line: this.props.selected,
            active_list: [],
            // isApply: false
            disabled: true,
            wireCombineCondition: this.props?.wireCombineCondition || null
        };
    }


    componentDidMount(){
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        this.setState({
            list: plans_line_not_null,
        }, ()=> {
            this.checkActiveOptionDiscount();
        })

    }


    /**
     * close modal
     */
    onCloseModal = () => {
        this.props.onSelect(this.state.base_line, "kt_premium_discount");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        let plans_line_not_null  = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        let activeKTPremiumPlans = this.getActiveKTPremiumPlans();
        
        this.setState({
            list: plans_line_not_null,
            active_list: activeKTPremiumPlans,
            base_line: this.props.selected,
        }, () => {
            this.checkActiveOptionDiscount();
        })
    }

    getActiveKTPremiumPlans = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        let discount_condition = this.state.wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition;
        let line_discount_cnt = plans_line_not_null.length > this.state.wireCombineCondition.discount_line_cnt ? this.state.wireCombineCondition.discount_line_cnt : plans_line_not_null.length; // số line được áp dụng giảm giá
        let activeKTPremiumPlans = _.filter(_.take(plans_line_not_null,line_discount_cnt ), (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
            return discount_condition && !_.includes([2989, 2991, 2993, 2994, 2976 ], plan.plan.id)
                && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +discount_condition[planTypeName]
        });

        if(this.props.exceptSelected != null){
            let exceptSelectedLength = this.props.exceptSelected.length;
            let tempInt              = 0;
            let tempInt2             = 0;
            let plan                 = null;
            let planIdx              = null;

            for(tempInt = 0; tempInt < exceptSelectedLength; tempInt++){
                plan    = plans_line_not_null[this.props.exceptSelected[tempInt]];
                planIdx = (plan?.idx || -1);

                for(tempInt2 = 0; tempInt2 < activeKTPremiumPlans.length; tempInt2++){
                    if(planIdx == activeKTPremiumPlans[tempInt2].idx){
                        break;
                    }
                }

                if(tempInt2 == activeKTPremiumPlans.length){
                    activeKTPremiumPlans.push(plan);
                }
            }
        }

        activeKTPremiumPlans.sort(function(a, b) {
            return a.idx - b.idx;
        });
        
        return activeKTPremiumPlans;
    }

    onSelectBaseLine = (e, value) => {
        let selected               = this.state.base_line || this.props.selected;
        let baseLineOptionSelected = '';

        if(selected != null){
            baseLineOptionSelected = selected.baseLineOptionSelected || '';
        }

        if(baseLineOptionSelected == ''){
            baseLineOptionSelected = "each";
        }
        console.log("onSelectBaseLine의 value", value)
        value = {...value, baseLineOptionSelected : baseLineOptionSelected};
        console.log("onSelectBaseLine의 변경된 value", value)
        this.setState({
            base_line: value,
        }, () => {
            this.toggleBaseLineModal(e)
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }


    onBaseLineOpened = () => {
        let plans_line_not_null  = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        let activeKTPremiumPlans = this.getActiveKTPremiumPlans();
        this.setState({
            list: plans_line_not_null,
            base_line: this.props.selected,
            active_list: activeKTPremiumPlans
        })
    }

    toggleBaseLineModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowBaseLineModal: !this.state.isShowBaseLineModal
        })
    }

    toggleBaseLineOptionModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowBaseLineOptionModal: !this.state.isShowBaseLineOptionModal
        })
    }

    onSelectBaseLineOption = (e, value) => {
        this.setState({
            base_line: {...this.state.base_line, baseLineOptionSelected : value}
        }, () => {
            this.toggleBaseLineOptionModal(e)
        })

    }

    onCloseBaseLineModal = () => {
        this.props.onSelect(this.state.base_line, "kt_premium_discount"); 

        this.setState({
            base_line: null
        });
    }

    onApply = (e, isApply) => {
        const { active_list, list } = this.state
        this.setState({
            base_line: !isApply ? null : active_list[0],
            active_list: !isApply ? [] : active_list,
            list: !isApply ? [] : list,
        }, () => {
            this.toggleModal(e)
        })
    }

    componentDidUpdate(prevProps){
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn

        console.log("KT디스카운트2의 컴포넌트업데이트", this.state.base_line)

        if(JSON.stringify(plans_line_not_null) !== JSON.stringify(this.state.list )){
            let activeKTPremiumPlans = this.getActiveKTPremiumPlans();

            console.log("componentDidUpdate activeKTPremiumPlans", activeKTPremiumPlans);

            let base_line = this.state.base_line;
            if(activeKTPremiumPlans){
                if(base_line && !_.find(activeKTPremiumPlans, (plan) => { // nếu gói phí đã chọn base line bị remove, thì reset lại base line
                    if(plan){
                        return plan.idx == (base_line?.idx || -1)
                    }
                    else{
                        return false;
                    }
                })){
                    base_line = activeKTPremiumPlans[0]
                };
            }

            console.log("componentDidUpdate base_line", base_line);

            this.setState({
                list: plans_line_not_null,
                active_list: activeKTPremiumPlans,
                base_line
            }, () => {
                this.checkActiveOptionDiscount();
            })
        }

        if(this.props.wireCombineCondition && JSON.stringify(this.props.wireCombineCondition) !== JSON.stringify(this.state.wireCombineCondition)){
            let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
            let line_discount_cnt = plans_line_not_null.length > this.props.wireCombineCondition.discount_line_cnt ? this.props.wireCombineCondition.discount_line_cnt : plans_line_not_null.length; // số line được áp dụng giảm giá
            let discount_condition = this.props.wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition;
            let activeKTPremiumPlans = _.filter(_.take(plans_line_not_null,line_discount_cnt ), (plan, i) => {

                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);

                if(planTypeName){
                    return discount_condition && !_.includes([2989, 2991, 2993, 2994, 2976 ], plan.plan.id)
                        && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +discount_condition[planTypeName]
                }
            })
            this.setState({
                wireCombineCondition : this.props.wireCombineCondition,
                active_list: activeKTPremiumPlans
            }, () => {
                this.checkActiveOptionDiscount();
            })
        }

    }

    getPlanTypeNameById = (planType) => {
        let planTypeName = "";
        switch (+planType) {
            case 18:
                planTypeName = "conventional_commitment";
                break;
            case 19:
                planTypeName = "not_conventional_commitment";
                break;
            case 20:
                planTypeName = "fit_type_commitment";
                break;
            case 21:
                planTypeName = "not_fit_type_commitment";
                break;
            case 22:
                planTypeName = "not_3G_commitment";
                break;
            case 23:
                planTypeName = "other";
                break;
        }
        return planTypeName;
    }

    checkAddDiscountCondition (plans_line_not_null, wireless_add_discount_condition, line_discount_cnt){
        plans_line_not_null = _.filter(plans_line_not_null, (plan) => {
            if( !(plan?.plan?.name.substring(0,2) == "5G" && (+plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100) < 80000)) return  plan; // task 6748 flow
        })
        let all_wireless_number_line = wireless_add_discount_condition?.all_wireless_number_line;
        let uniform_plan_number_line = wireless_add_discount_condition?.uniform_plan_number_line;
        let result = false;
        let plan_apply_discount = _.take(plans_line_not_null,line_discount_cnt );
        let count = 0;

        if(this.props.exceptSelected != null){
            count = count + this.props.exceptSelected.length;
        }

        // check số line thỏa điều kiện
        if(plan_apply_discount.length > 0) _.map(plan_apply_discount, (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan?.plan?.discost_ad_cond);
            if(wireless_add_discount_condition
                && planTypeName
                && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +wireless_add_discount_condition[planTypeName]
            ) count++;

        })

        // check số line đồng nhất thỏa điều kiện
        let is_uniform_active = false;
        let count_group =  _(plan_apply_discount).map((value, key) => {
            return value.plan // task 6748 flow
        }).groupBy('discost_ad_cond').value();

        _.forEach(count_group, (val, i) => {
            let planTypeNameGroup = this.getPlanTypeNameById(parseInt(i));
            let same_plan_count = _(val).groupBy('id').map(
                (group) => {
                    return ({id: group[0].id, qty: group.length, price: +group[0].cost_display + group[0].cost_display*10/100})
                }
            ).value();
            let same_plan = _.maxBy(same_plan_count,  'qty');
            // nếu có số đường dây giống nhau thỏa 1 trong điều kiện
            if(wireless_add_discount_condition && same_plan.qty >= +uniform_plan_number_line && same_plan.price >= +wireless_add_discount_condition[planTypeNameGroup] ) is_uniform_active = true;
            if(is_uniform_active) return false;
        });

        if(all_wireless_number_line && count >= all_wireless_number_line || is_uniform_active) result = true; // nếu thỏa 1 trong 2
        return result;
    }


    checkActiveOptionDiscount = async () => {
        let plans = this.state.list;
        if(plans.length > 0){
            let wireCombineCondition =  this.state.wireCombineCondition;
            let KT_premium_1_discount_condition_group = wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group;
            let line_discount_cnt = plans.length > wireCombineCondition.discount_line_cnt ? wireCombineCondition.discount_line_cnt : plans.length; // số line được áp dụng giảm giá
            let checkWireBasicDiscountCondition = await this.checkAddDiscountCondition(plans, KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);
            let baseLine = this.state.base_line;

            if(checkWireBasicDiscountCondition == false){
                baseLine = null;
            }

            this.props.onSelect(baseLine, "kt_premium_discount");

            this.setState({
                disabled : checkWireBasicDiscountCondition ? false : true,
                base_line : baseLine
            });
        }
    }

    render() {
        let selected               = this.props.selected || this.state.base_line;
        let baseLineOptionSelected = '';

        console.log("this.props.selected", this.props.selected);
        console.log("this.state.base_line", this.state.base_line);
        console.log("KT프리미엄2의 selected", selected);
        //console.log("KT프리미엄2의 selected.baseLineOptionSelected", selected.baseLineOptionSelected);

        if(selected != null){
            baseLineOptionSelected = selected.baseLineOptionSelected || '';
            console.log("selected가 널이 아닐때", selected.baseLineOptionSelected)
        }

        if(baseLineOptionSelected == ''){
            baseLineOptionSelected = "each";
            console.log("baseLineOptionSelected가 '' 일때")
        }

        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">KT 프리미엄 할인<span
                        className="weight-normal">(회선 선택)</span>
                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                            disabled={this.state.disabled}
                    >
                        { !this.state.disabled && selected ? "프리미엄 할인 적용" : "미적용"}
                    </button>
                    {
                        !this.state.disabled && selected && (
                            <button className="btn btn-input-form h-32 mb-0 mt-3" type="button"
                                    onClick={(e) => this.toggleBaseLineModal(e)}
                            >
                                { selected ? `베이스 ${selected.idx}회선` : "미적용"}
                            </button>
                        )
                    }
                    {
                        !this.state.disabled && selected && (
                            <button className="btn btn-input-form h-32 mb-0 mt-3" type="button"
                                    onClick={(e) => this.toggleBaseLineOptionModal(e)}
                            >
                                {baseLineOptionSelected == 'each' ? "총액할인 (인터넷/모바일 각각)" : "총액할인 (모바일)"}
                                
                            </button>
                        )
                    }

                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">프리미엄 할인</h5>
                            <button type="button" className="close close-modal"
                                    onClick={(e) => this.toggleModal(e)}
                            >
                          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                <li className={ !selected ? "active" : "" }
                                    onClick={(e) => this.onApply(e, false)}
                                >
                                    미적용
                                </li>
                                <li className={selected ? "active" : "" }
                                    onClick={(e) => this.onApply(e, true)}
                                >
                                    적용
                                </li>

                            </ul>
                        </div>
                    </Modal>
                    <Modal isOpen={this.state.isShowBaseLineModal}
                           className="modal-dialog modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleBaseLineModal(e)}
                           onOpened = {this.onBaseLineOpened}
                           onClosed = {this.onCloseBaseLineModal}

                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">베이스 할인 회선</h5>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                {
                                    this.state.active_list.length > 0 && this.state.active_list.map( (value, key) => {
                                        return (
                                            <li key={key}
                                                className={selected && selected.idx == value?.idx ? "active" : ""}
                                                onClick={(e) => this.onSelectBaseLine(e, value)}
                                            >
                                                회선 { value?.idx}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Modal>
                    <Modal isOpen={this.state.isShowBaseLineOptionModal}
                           className="modal-dialog modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleBaseLineOptionModal(e)}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">베이스회선 할인방식</h5>
                            <button type="button" className="close close-modal"
                                    onClick={(e) => this.toggleBaseLineOptionModal(e)}
                            >
                          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                <li className={ baseLineOptionSelected == 'mobile' ? "active" : "" }
                                    onClick={(e) => this.onSelectBaseLineOption(e, "mobile")}
                                >
                                    총액할인 (모바일)
                                </li>
                                <li className={baseLineOptionSelected == 'each' ? "active" : "" }
                                    onClick={(e) => this.onSelectBaseLineOption(e, "each")}
                                >
                                    총액할인 (인터넷/모바일 각각)
                                </li>

                            </ul>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

KTPremiumDiscount2.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(KTPremiumDiscount2);
