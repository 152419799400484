import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
 

const renderRadioField = ({ input, label, type, checked, className, id }) => {
    return (
    <Fragment>
        <input
            {...input}
            type={type} 
            className={className}
            id={id}
            checked={checked==input.value ? true : false}
        />
    </Fragment>
  )};

renderRadioField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderRadioField;
