import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import {numberWithCommas,priceFormat} from "../../../utils/helper";

class DiscountPointModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            discountPoint : this.props.discountPoint||0,
            errMsg : null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.discountPoint != this.props.discountPoint) {
            this.setState({discountPoint: this.props.discountPoint})
        }
    }

    /**
     * mapping data toogle modal
     */
    toggle = () => {
        this.props.toggleModal();
    }

    /**
     * handle change input
     */
    handleChange = (e) => {
        e.preventDefault();
        let errMsg = null;
        const financialGoal = (e.target.validity.valid) ? e.target.value : this.state.discountPoint;
        let intNumber = financialGoal.split(',').join('');
        var regex=/^[0-9]+$/;
        if (financialGoal.length > 10) {
            errMsg = '금액은 10자를 초과하면 안됩니다!';
            this.setState({
                discountPoint: this.formatCurrency(financialGoal),
                errMsg : errMsg
            });
        }else if(financialGoal && !intNumber.match(regex)){
            errMsg = '숫자로 입력해야 됩니다!';
            this.setState({
                valueSelect:  this.formatCurrency(financialGoal),
                errMsg: errMsg,
                invalid: true
            })
        }else{
            this.setState({
                discountPoint: this.formatCurrency(financialGoal),
                errMsg : null
            });
        }
        
    }
    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(/,/g, '').replace(regex, separate);
        return res;
    }
    /**
     * handleSubmit
     */
    handleSubmit = (e) => {
        e.preventDefault();
        let errMsg = null;
        if (parseInt(this.state.discountPoint?.toString().split(',').join('')) > (parseInt(this.props.validPrice) + 1)) {
            errMsg = `금액은 ${priceFormat(this.props.validPrice)}원 이하로 입력해 주세요.`
            this.setState({
                //discountPoint: priceFormat(this.props.validPrice),
                errMsg : errMsg
            })
        }else{
            this.setState({
                discountPoint: priceFormat(this.props.validPrice),
                errMsg : null
            })
            this.props.setValueDiscountPoint(this.state.discountPoint ?  Number(this.state.discountPoint.replace(/[^0-9\.]+/g,"")) : 0 );
            this.closeModal();
            this.toggle();
        }
        
    }


    /**
     * show error message
     */
    showErrorMessage = (errMsg) => {
        return(<span className={'invalid-feedback'}>{errMsg}</span>); 
    }

    /**
     * handle when close modal
     */
    closeModal = () => {
       this.setState({
            discountPoint : this.props.discountPoint||0,
            errMsg : null
       })
    }

    render() {
        let modal = this.props.isShowModal;
        let {discountPoint,errMsg} = this.state;

        if(discountPoint == 0){
            discountPoint = "";
        }

        return(
            <Fragment>
                <Modal isOpen={modal} 
                    className="modal-dialog-centered modal-custom modal-additional"
                    contentClassName="pl-4 pr-4"
                    toggle = {this.toggle}
                   // backdrop={"static"}
                    onClosed = {this.closeModal}
                >
                    <form onSubmit={this.handleSubmit} className="ponts-discount">
                        <div className="modal-header">
                            <h5 className="modal-title">포인트 할인</h5>
                            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-sm-10 px-0">
                                    <input type="text" 
                                        value={discountPoint} 
                                        className={errMsg ? "form-control h-44 is-invalid" : "form-control h-44" } 
                                        //placeholder="0" 
                                        onChange={this.handleChange} 
                                    />
                                    {this.showErrorMessage(errMsg)}
                                </div>
                                <div className="col-sm-2 pl-2 pr-0">
                                    <span>포인트</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0">
                            <button type="button"  onClick={this.toggle} className="btn btn-gray" >취소</button>
                            <button type="submit" disabled = {errMsg ? 'disabled' : ''} className="btn btn-submit">확인</button>
                        </div>
                    </form>
                </Modal>
            </Fragment>

        );
    }
}
 
export default DiscountPointModal;
