import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import * as routerName from './../../utils/routerName';
import { showSidebar, hideSidebar } from '../../store/actions/ui';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import { hasRole } from '../../utils/entrust/hasrole';
import ModalHistoryAdvice from '../../containers/ModalHistoryAdvice/ModalHistoryAdvice';
import * as helper from '../../utils/helper';
import PopupPageEmpty from "../../containers/PopupPageEmpty";
import api from "../../utils/api";
import {openPopupMenu} from '../../store/actions/auth';
import { ReactComponent as IconMenu1 } from './../../assets/images/icon-thongbao-02.svg';
import { ReactComponent as IconMenu2 } from './../../assets/images/icon-tuva.svg';
import { ReactComponent as IconMenu3 } from './../../assets/images/icon-sosaanh.svg';
import { ReactComponent as IconMenu4 } from './../../assets/images/menu-01.svg';
import { ReactComponent as IconMenu5 } from './../../assets/images/icon-baocao.svg';
import { ReactComponent as IconMenu6 } from './../../assets/images/remember-icon.svg';
import { ReactComponent as IconMenu7 } from './../../assets/images/print-menu.svg';
import { ReactComponent as IconMenu8 } from './../../assets/images/ICON-TAILIEU.svg';
import { ReactComponent as LogoMenu } from './../../assets/images/Logo.svg';
import { ReactComponent as CloseMenu } from './../../assets/images/close-menu.svg';
import PopupInactive from '../PopupInactive';



class Mennu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            popupPageEmpty : false,
            telcom : [],
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
        }

        // this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        // document.addEventListener('mousedown', this.handleClickOutside);
        helper.loadingMenu();


        api.advice.getTelcomNotParent().then(res => {
            let telcom = [];
            Object.keys(res.data.data).map((items) => {
                telcom.push({ id: Object.keys(res.data.data[items])[0], name: res.data.data[items][Object.keys(res.data.data[items])[0]]})
            })
            this.setState({
                telcom : telcom
            })
        })


    }

    openPopupDisable = (state, value) => {
        if (state) {
          this.setState({
            title: '실제 개통에 사용하지 마십시오.',
            content: `
              베타테스트 기간입니다.<br>
              테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
              서류 미비나 금액 오류 등이 발생할 수 있습니다.
            `
          })
        }
        this.setState({
          isOpenPopupDisable: true,
          iconLock: value
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
          isOpenPopupDisable: false
        })
    }

    modelPrintClose = () => {
        this.setState({
          title: null,
          content: null
        })
    }

    componentWillUnmount() {
        // document.removeEventListener('mousedown', this.handleClickOutside);
        helper.loadingMenu();
    }

    // handleClickOutside = () => {
    //     if (this.props.ui.showSidebar) {
    //         this.props.hideSidebar()
    //     }
    // }

    onClickModalHistoryAdvice = () => {
        let xhtml = <ModalHistoryAdvice/>
        let html_title = `상담내역`
        let dataModal = {
            component:xhtml,
            close:true,
            html_title: html_title,
            class_n:'modal-dialog modal-dialog-centered modal-xl',
        }
        this.props.showModal(dataModal);
    }


    onClickModalPrint = () => {
        this.setState({
            popupPageEmpty : !this.state.popupPageEmpty
        })
    }

    openPopupMenu = (value) => {
        this.setState({
            isOpenPopupDisable: true,
            iconLock: value
        })
        // this.props.openPopupMenu(true);
    }

    showLockWireless = () => {
        this.setState({
            isShowLockWireless: !this.state.isShowLockWireless,
        });
    }

    render() {
        var role = hasRole(this.props.auth.user,'Member');
        var roleAdmin =  hasRole(this.props.auth.user,'Admin');
        return (
                <Fragment>
                    <nav className="menu">
                        <div className={`menu-wrapper`}>

                            <div className="button-close">
                                <Link to={routerName.HOME}>
                                    <LogoMenu />
                                </Link>
                                <button className="btn close-menu" onClick={this.props.hideSidebar}>
                                    <CloseMenu />
                                </button>
                            </div>
                            <ul className="list-menu" id={"list-menu"}>
                                <li className={`item-menu ` + helper.permissionsMenu(this.props.auth.role, ['Admin', 'Member'] ) + (this.props.path === `/${routerName.SMART_ADVICE}` ? 'active' : '') }>
                                    <Link to={routerName.MANAGER_NOTIFICATION} onClick={this.props.hideSidebar}><IconMenu1 className="mr-3" />공지사항</Link>
                                </li>


                                <li className={`item-menu ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Counselor', 'Admin'])}>
                                    <a > <IconMenu2 className="mr-3" />상담 </a>
                                    <ul className="sub-menu">
                                        {helper.checkBlockFeature() ? (
                                            <li className={`item-sub ${this.props.path === `/${routerName.SMART_ADVICE}` ? 'active' : ''}`}
                                                onClick={() => this.openPopupDisable(false, 'iconLock')}
                                            >
                                                <a>SMART 상담</a>
                                            </li>
                                        ) : (
                                            <li className={`item-sub ${this.props.path === `/${routerName.SMART_ADVICE}` ? 'active' : ''}`}>
                                                <Link to={routerName.SMART_ADVICE} onClick={this.props.hideSidebar}>SMART 상담</Link>
                                            </li>
                                        )}

                                        <li className={`item-sub ${this.props.path === `/${routerName.WIRELESS_ADVICE}` ? 'active' : ''}`}>
                                            <Link to={routerName.WIRELESS_ADVICE} onClick={this.props.hideSidebar}>무선상담</Link>
                                        </li>

                                        <li className={`item-sub ${this.props.path === `/${routerName.WIRED_ADVICE}` ? 'active' : ''}`}>
                                            <Link to={routerName.WIRED_ADVICE} onClick={this.props.hideSidebar}>유선상담</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`item-menu ${this.props.path === `/${routerName.COMPARE}` ? 'active' : ''} ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Counselor', 'Admin'])}>
                                    <Link to={routerName.COMPARE} onClick={this.props.hideSidebar}><IconMenu3 className="mr-3" />비교상담</Link>
                                </li>
                                <li className={`item-menu ${this.props.path === `/${routerName.HISTORY_CONSULTANT}` ? 'active' : ''} ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Counselor', 'Admin'])}>
                                    <Link to={routerName.HISTORY_CONSULTANT} onClick={this.props.hideSidebar}><IconMenu4 className="mr-3" />상담내역</Link>
                                </li>
                                {
                                    (this.props.auth.user?.roles[0]?.name == 'Member' || this.props.auth.user?.roles[0]?.name == 'Admin') && (
                                        <li className={`item-menu ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                            {/* <a  onClick={this.onClickModalHistoryAdvice}> <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/icon-baocao.svg"} alt="" />정산관리</a> */}
                                            <a> <IconMenu5 className="mr-3" />정산관리</a>
                                            {helper.checkBlockFeature() ? (
                                                <ul className="sub-menu">
                                                    <li className={`item-sub ${this.props.path === `/${routerName.HISTORY_BUY_PRODUCT}` ? 'active' : ''}`}
                                                         onClick={() => this.openPopupMenu('iconLock')}>
                                                        <a>판매현황</a>

                                                    </li>
                                                    <li className={`item-sub ${this.props.path === `/${routerName.SETTLEMENT}` ? 'active' : ''}`}
                                                        onClick={() => this.openPopupMenu('iconLock')}>
                                                        <a>판매 정산 현황</a>
                                                    </li>
                                                    <li className={`item-sub ${this.props.path === `/${routerName.TRANSACTION_POINT}` ? 'active' : ''}`}
                                                         onClick={() => this.openPopupMenu('iconLock')}>
                                                        <a>거래처</a>
                                                    </li>
                                                </ul>
                                            ) : (
                                                <ul className="sub-menu">
                                                    <li className={`item-sub ${this.props.path === `/${routerName.HISTORY_BUY_PRODUCT}` ? 'active' : ''}`}>
                                                        <Link to={{pathname: routerName.HISTORY_BUY_PRODUCT, state: { from: this.props.location.pathname }}}>판매현황</Link>
                                                    </li>
                                                    <li className={`item-sub ${this.props.path === `/${routerName.SETTLEMENT}` ? 'active' : ''}`}>
                                                        <Link to={{pathname: routerName.SETTLEMENT, state: { from: this.props.location.pathname }}}>판매 정산 현황</Link>
                                                    </li>
                                                    <li className={`item-sub ${this.props.path === `/${routerName.TRANSACTION_POINT}` ? 'active' : ''}`}>
                                                        <Link to={{pathname: routerName.TRANSACTION_POINT, state: { from: this.props.location.pathname }}}>거래처</Link>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>
                                    )
                                }


                                {/*//tạm đóng menu CALENDAR, khách hàng yêu cầu 8/5/2021 flow 6926*/}

                                {/*<li className={`item-menu ${this.props.path === `/${routerName.CALENDAR}` ? 'active' : ''} ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Admin'])} onClick={() => this.openPopupDisable()}>*/}
                                    {/*/!* <Link to={routerName.CALENDAR} onClick={this.props.hideSidebar}><IconMenu6 className="mr-3" />알람 일정</Link> *!/*/}
                                    {/*<a to={routerName.CALENDAR} onClick={this.props.hideSidebar}><IconMenu6 className="mr-3" />알람 일정</a>*/}

                                {/*</li>*/}
                                <li className={`item-menu ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Counselor', 'Admin'])}>
                                    <a onClick={this.onClickModalPrint}> <IconMenu7 className="mr-3" />빈서식지 출력</a>
                                </li>
                                <li className={`item-menu ${this.props.path === `/${routerName.SALES_DOCUMENT}` ? 'active' : ''} ` + helper.permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                    {helper.checkBlockFeature() ? (
                                        <a onClick={() => this.openPopupDisable(false, 'iconLock')}>
                                            <IconMenu8 className="mr-3" />판매 도우미 자료실
                                        </a>
                                    ) : (
                                        <Link to={routerName.SALES_DOCUMENT} onClick={this.props.hideSidebar}>
                                            <IconMenu8 className="mr-3" />판매 도우미 자료실
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </nav>

                    {this.props.auth.accountErrStatus == 0 && this.state.telcom.length > 0 && <PopupPageEmpty
                      toggle={this.onClickModalPrint}
                      isOpen={this.state.popupPageEmpty}
                      telcom={this.state.telcom}
                    />}
                    <PopupInactive
                        isOpenModal={this.state.isOpenPopupDisable}
                        closePopupDisable={this.closePopupDisableHandle}
                        title={this.state.title || null}
                        content={this.state.content || null}
                        callback={(this.state.title) ? this.onClickModalPrint : null}
                        onModelClosed={this.modelPrintClose}
                        iconLock={this.state.iconLock}
                    />
                </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth,
        ui: state.ui
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showSidebar: () => dispatch(showSidebar()),
        hideSidebar: () => dispatch(hideSidebar()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        openPopupMenu: (data) => dispatch(openPopupMenu(data)),
    };
};

Mennu.propTypes = {
    ui: PropTypes.object,
    auth: PropTypes.object,
    hideSidebar: PropTypes.func,
    showModal: PropTypes.func,
    openPopupMenu: PropTypes.func,
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(Mennu);

