import React, { Component, Fragment } from 'react';
import {
    Switch,
    withRouter,
    Route, Redirect
} from "react-router-dom";
import { connect } from 'react-redux';
import Page404 from './components/ErrorPage/page404';
import * as routerName from "./utils/routerName";
import * as constant from "./utils/constant";
import Home from "./containers/Home";
import SignIn from './containers/Auth/SignIn.js';

import SmartAdvice from './containers/Advice/SmartAdvice.js';
// import SmartAdvice_old from './containers/Advice/SmartAdvice_old.js';
import WiredAdvice from './containers/Advice/WiredAdvice.js';
import WirelessAdvice from './containers/Advice/WirelessAdvice.js';

import AdvisoryHistory from './containers/AdvisoryHistory/AdvisoryHistory.js';
import CreateReportSale from './containers/AdvisoryHistory/CreateReportSale.js';
import SalesSettlement from './containers/SalesSettlement/SalesSettlement.js';

import ForgotId from './containers/Auth/ForgotId.js';

import ForgotPassword from './containers/Auth/ForgotPassword.js';
import SignUp from './containers/Auth/SignUp.js';

import Compare from './containers/Compare/Compare.js';

import ManagerNotification from './containers/ManagerNotification/ManagerNotification.js';
import NotificationDetail from './containers/ManagerNotification/NotificationDetail.js';
import MoneySupportNotificationDetail from './containers/ManagerNotification/MoneySupportNotificationDetail.js';
import NotificationCreate from './containers/ManagerNotification/NotificationCreate.js';
import MoneySupportNotificationCreate from './containers/ManagerNotification/MoneySupportNotificationCreate.js';
import SalesDocument from './containers/ManagerNotification/SalesDocument.js';
import SalesDocumentDetail from './containers/ManagerNotification/SalesDocumentDetail.js';
import SalesDocumentCreate from './containers/ManagerNotification/SalesDocumentCreate.js';

import ManagerSales from './containers/MyProfile/ManagerSales.js';
import PaymentManagement from './containers/MyProfile/PaymentManagement.js';
import PaymentHistory from './containers/MyProfile/PaymentHistory.js';
import MyCoupon from './containers/MyProfile/MyCoupon.js';
import MyProfile from './containers/MyProfile/MyProfile.js';
import EditProfile from './containers/MyProfile/EditProfile.js';
import SaleCreate from './containers/MyProfile/SaleCreate.js';

import Sales from './containers/SalesReport/Sales.js';
import Statistical from './containers/SalesReport/Statistical.js';
import TransactionLocation from './containers/SalesReport/TransactionLocation.js';
import Calendar from './containers/Calendar/Calendar.js';
import GlobalLoading from './components/GlobalLoading';
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import Main from "./components/Layout/Main";
import Modal from "./components/Modal";
import $ from "jquery";
import PopupDiscountCard from "./components/Wireless/AdvancedDiscountCard/PopupDiscountCard";
import SupportMoney from './containers/SupportMoney/SupportMoney.js';
import PaperMoneyComponent from "./components/PaperMoney/PaperMoneyComponent";
import PlanInformationComponent from './components/PlanInformation/PlanInformationComponent';
import ChangePlanInformationComponent from './components/PlanInformation/ChangePlanInformationComponent';
import ChangeDatePlanComponent from './components/PlanInformation/ChangeDatePlanComponent';
import PopupWireLess from './containers/PopupWireLess';
import PopupListDetailDiscountCard from './containers/PopupListDetailDiscountCard';
import HisoryBuyProductComponent from './components/HisoryBuyProduct/HisoryBuyProductComponent';
import Settlement from './components/HisoryBuyProduct/Settlement';
import TransactionPoint from './components/HisoryBuyProduct/TransactionPoint';
import * as firebase from 'firebase/app';
import ReactNotifications from 'react-browser-notifications';
import HistoryConsultant from "./containers/HistoryConsultant/HistoryConsultant";
import Payment from "./containers/Payment/Payment";
import {getPricePackages, getPriceDefault, getNotifications, loadmoreDataNotification} from './store/actions/dataStore';
import { registerLocale } from "react-datepicker"
import ShareWireless from "./containers/ShareWireless";
import ShareWired from "./containers/ShareWired";
import { logoutAction } from './store/actions/auth';
import { showLoading, hideLoading, showSidebar, hideSidebar } from './store/actions/ui';
import queryString from "query-string";

// import ko from "date-fns/locale/ko";
import('date-fns/locale/' + process.env.REACT_APP_CONTENT_LANGUAGE)
    .then(lang => registerLocale(process.env.REACT_APP_CONTENT_LANGUAGE, lang.default))

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title:'',
            notificationShow:{
                title: "",
                message: "",
                click_action: "",
                type:"",
                ntf_table_id: "",
                ntf_table: ""
            },
            params : {}
        }
    }

    onClickLogout = () => {
        let fb_token = '';
        if (localStorage.getItem("device_token")) {
            fb_token = localStorage.getItem("device_token")
        } else {
            fb_token = localStorage.getItem("fb_token")
        }
        let data = {
            fb_token: fb_token
        }
        this.props.showLoading()
        this.props.logoutAction(data).then(res => {
            this.props.hideLoading()
        }).catch(err => {
            this.props.hideLoading()
        })
    }

    componentDidMount() {
        if(!localStorage.getItem('device_token')){
            if (firebase.messaging.isSupported()) {
                navigator.serviceWorker.addEventListener("message", (message) => {
                    if(!!this.props.auth){
                        let data = JSON.parse(message.data['firebase-messaging-msg-data'].data.payload);
                        let ntf_title = data.ntf_title;
                        let ntf_message = data.ntf_message;
                        let ntf_type = data.ntf_type;
                        let ntf_table_id = data.ntf_table_id;
                        let ntf_table = data.ntf_table;
                        if(ntf_type == '10') {
                            this.onClickLogout();
                        }
                        this.setState({
                            ...this.state,
                            notificationShow:{
                                ...this.state.notificationShow,
                                title: ntf_title,
                                message: ntf_message,
                                type: ntf_type,
                                ntf_table_id: ntf_table_id,
                                ntf_table: ntf_table,
                                click_action:'click_action',
                            }
                        }, () => {
                            let data = {
                                page: 1
                            }
                            this.props.getNotifications(data);
                            this.showNotifications();
                        });
                    }
                });
            }
        }
        const { location } = this.props;
        let params = queryString.parse(location.search);

        if(this.props.auth && this.props.auth.isAuthenticated && location.pathname == "/sign-in" && params.id){
            // this.props.history.push({
            //     pathname: '',
            //     search:Math.random(),
            //     // state: { notice: {nt_id:this.state.notificationShow.ntf_table_id} }
            // })
            // this.props.history('share-wireless?='+params.id)
            // return  <Redirect to={'/share-wireless?id='+params.id} />
        }
    }

    setTitle = (title) => {
        this.setState({
            title
        })
    }

    showNotifications() {
        if(this.n.supported()) this.n.show();
    }

    handleClickNotification = (event) => {
        if(this.state.notificationShow.type == constant.NTF_TYPE_COUPONS ) {
            this.props.history.push({
                pathname: routerName.MY_COUPON,
            })
        } else if(this.state.notificationShow.type == constant.NTF_TYPE_NOTICE ) {
            this.props.history.push({
                pathname: routerName.NOTIFICATION_DETAIL,
                key:Math.random(),
                state: { notice: {nt_id:this.state.notificationShow.ntf_table_id} }
            })
        } else if(this.state.notificationShow.type == constant.NTF_TYPE_DOCUMENT ) {
            this.props.history.push({
                pathname: routerName.SALES_DOCUMENT_DETAIL,
                key:Math.random(),
                state: { document: {d_id:this.state.notificationShow.ntf_table_id} }
            })
        }
        this.n.close(event.target.tag);
    }

    render() {
        const { location } = this.props;
        // let params = queryString.parse(location.search);
        //
        // if(this.props.auth && this.props.auth.isAuthenticated && location.pathname == "/sign-in" && params.id){
        //     return  <Redirect to={'/share-wireless?id='+params.id} />
        // }
        return (
            <Fragment>
                 <ReactNotifications
                    onRef={ref => (this.n = ref)} // Required
                    title={this.state.notificationShow.title} // Required
                    body={this.state.notificationShow.message}
                    icon="/favicon.ico"
                    tag={this.state.notificationShow.click_action}
                    timeout="5000"
                    onClick={event => this.handleClickNotification(event)}
                />
                <GlobalLoading/>
                <Switch>
                    {/* Auth */}
                    <GuestRoute
                        exact
                        path={`/${routerName.SIGN_IN}`}
                        component={SignIn}
                        location={location}
                        layout={Main}
                    />
                    <GuestRoute
                        exact
                        path={`/${routerName.FORGOT_ID}`}
                        location={location}
                        component={ForgotId}
                        layout={Main}
                    />
                    <GuestRoute
                        exact
                        path={`/${routerName.FORGOT_PASSWORD}`}
                        location={location}
                        component={ForgotPassword}
                        layout={Main}
                    />
                    <GuestRoute
                        exact
                        path={`/${routerName.SIGN_UP}`}
                        component={SignUp}
                        layout={Main}
                        title='회원가입'
                    />
                    {/* <GuestRoute
                        exact
                        path={`/${routerName.SIGN_UP_STEP_2}`}
                        location={location}
                        component={SignUpStep2}
                        layout={Main}
                    />
                    <GuestRoute
                        exact
                        path={`/${routerName.SIGN_UP_STEP_3}`}
                        location={location}
                        component={SignUpStep3}
                        layout={Main}
                    /> */}
                    {/*Close Auth */}

                    <UserRoute
                        exact
                        path={`/${routerName.HOME}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={Home}
                        footer={true}
                        // params={params}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.SMART_ADVICE}`}
                        location={location}
                        role={['member', 'counselor','admin']}
                        layout={Main}
                        component={SmartAdvice}
                        title={"SMART 상담"}
                        // component={SmartAdvice_old}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.WIRED_ADVICE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={WiredAdvice}
                        title='유선상담'
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.WIRELESS_ADVICE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={WirelessAdvice}
                        title='무선상담'
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.ADVISORY_HISTORY}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={AdvisoryHistory}
                        title={"판매현황"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.CREATE_REPORT_SALE}`}
                        location={location}
                        layout={Main}
                        isCreateReport={true}
                        role={['member', 'counselor','admin']}
                        component={CreateReportSale}
                        title="판매일보 작성"
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.SALES_SETTLEMENT}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={SalesSettlement}
                        title='판매 정산 현황'
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.COMPARE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={Compare}
                        title={"비교상담"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.MANAGER_NOTIFICATION}`}
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        title="공지사항"
                        component={ManagerNotification}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.NOTIFICATION_DETAIL}`}
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        component={NotificationDetail}
                        // setTitle={this.setTitle}
                        title="공지사항"
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.MONNEY_SUPPORT_DETAIL}`}
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        component={MoneySupportNotificationDetail}
                        title="공지사항"
                    />



                    <UserRoute
                        exact
                        path={`/${routerName.NOTIFICATION_CREATE}`}
                        location={location}
                        layout={Main}
                        role={['admin']}
                        component={NotificationCreate}
                        title="공지사항"
                    />

                     <UserRoute
                        exact
                        path={`/${routerName.CREATE_MONNEY_SUPPORT}`}
                        location={location}
                        layout={Main}
                        role={['admin']}
                        component={MoneySupportNotificationCreate}
                        title="공지사항"
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.SALES_DOCUMENT}`}
                        location={location}
                        layout={Main}
                        role={['member',  'admin']}
                        component={SalesDocument}
                        title="판매 도우미 자료실"
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.SALES_DOCUMENT_CREATE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'admin']}
                        component={SalesDocumentCreate}
                        title="판매 도우미 자료실"
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.SALES_DOCUMENT_DETAIL}`}
                        location={location}
                        layout={Main}
                        role={['member', 'admin']}
                        component={SalesDocumentDetail}
                        // setTitle={this.setTitle}
                        title="판매 도우미 자료실"
                    />


                    <UserRoute
                        exact
                        path={`/${routerName.MANAGER_SALES}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={ManagerSales}
                        title={"판매자 관리"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.PAYMENT_MANAGEMENT}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={PaymentManagement}
                        title={"결제관리"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.PAYMENT_HISTORY}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={PaymentHistory}
                        title={'결제관리'}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.MY_COUPON}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={MyCoupon}
                        title={'쿠폰 관리'}
                    />
                     <UserRoute
                        exact
                        path={`/${routerName.MY_PROFILE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={MyProfile}
                        title={'나의 프로필'}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.EDIT_PROFILE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={EditProfile}
                        title={'프로필 수정'}
                    />



                    <UserRoute
                        exact
                        path={`/${routerName.SALE_CREATE}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={SaleCreate}
                        title={"판매일보 작성"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.SALES}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={Sales}
                        title={"판매 정산 현황"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.STATISTICAL}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={Statistical}
                        title={"정산통계"}
                    />
                    <UserRoute
                        exact
                        path={`/${routerName.TRANSACTION_LOCATION}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        title='거래처 관리'
                        component={TransactionLocation}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.CALENDAR}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        title='알람 일정'
                        component={Calendar}
                    />

                    <UserRoute
                        exact
                        path={`/popup-discount-card`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={PopupDiscountCard}
                    />

                    <Route
                        path={`/${routerName.PAGE_404}`}
                        location={location}
                        component={Page404}
                    />

                    <Route
                        path={`/${routerName.SUPPORT_MONEY}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={SupportMoney}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.PAPERMONEY}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={PaperMoneyComponent}
                    />
                    {/* END ROUTE */}

                    <UserRoute
                        exact
                        path={`/${routerName.PLAN_INFORMATION}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={PlanInformationComponent}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.CHANGE_PLAN_INFORMATION}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={ChangePlanInformationComponent}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.CHANGE_DATE_PLAN_INFORMATION}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={ChangeDatePlanComponent}
                    />


                    <UserRoute
                        exact
                        path={`/${routerName.HISTORY_BUY_PRODUCT}`}
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        title='판매현황'
                        component={HisoryBuyProductComponent}
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.HISTORY_CONSULTANT}`}
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        component={HistoryConsultant}
                        title='상담내역'
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.SETTLEMENT}`}  
                        location={location}
                        layout={Main}
                        role={['member','admin']}
                        component={Settlement}
                        title='판매 정산 현황'
                    />

                    <UserRoute
                        exact
                        path={`/${routerName.TRANSACTION_POINT}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={TransactionPoint}
                        title='거래처 관리'

                    />

                    <UserRoute
                        exact
                        path={`/${routerName.PAYMENT}`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor','admin']}
                        component={Payment}
                    />
                    <Route
                        exact
                        path={`/popup-wirelees`}
                        location={location}
                        // role={['member', 'counselor']}
                        component={PopupWireLess}
                    />

                    <Route
                        exact
                        path={`/popup-list-detail-discount-card`}
                        location={location}
                        // role={['member', 'counselor']}
                        component={PopupListDetailDiscountCard}
                    />

                    <Route
                      exact
                      path={`/share-wireless`}
                      location={location}
                      // role={['member', 'counselor']}
                      component={ShareWireless}
                    />


                    <Route
                      exact
                      path={`/share-wired`}
                      location={location}
                      // role={['member', 'counselor']}
                      component={ShareWired}
                    />

                    {/* <UserRoute
                        exact
                        path={`/used-programs`}
                        location={location}
                        layout={Main}
                        role={['member', 'counselor']}
                        component={UsedProgram}
                        layout={Main}
                    /> */}

                </Switch>
                <Modal />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getNotifications:(data) => dispatch(getNotifications(data)),
        logoutAction: (data) => dispatch(logoutAction(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
