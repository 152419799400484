import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import {Modal} from 'reactstrap';
import CurrencyInput from 'react-currency-input';

const ModalCashPayment = ({isOpenModal, closeModal, onSelected, installmentPrincipal, cashPayment}) => {
  const [maxValue, setmaxValue] = useState(0)
  const [value, setValue] = useState(0)
  const [id, setId] = useState(0)
  const [isOpenModalMoney, setisOpenModalMoney] = useState(false)
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  useEffect(() => {
    setmaxValue(+installmentPrincipal + +value)
  }, [installmentPrincipal])

  useEffect(() => {
    setValue(+cashPayment)
  }, [cashPayment])

  const setResult = (resultId) => {
    if (resultId == 2) {
      if((+value) > maxValue){
        alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
        return;
      }
      setisOpenModalMoney(false)
      setTimeout(() => {
        onSelected(value, 2);
      }, 500)
      return
    }    
    if (resultId == 1) {
      onSelected(+installmentPrincipal + +value, 1);
      return
    }
    onSelected(0, 0);
  }

  const onsubmit = (e) => {
    e.preventDefault()
    closeModal()
    setResult(2)
  }

  return (
    <Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={closeModal}
        fade={true}
        // backdrop={"static"}
        className={'modal-dialog modal-dialog-centered modal-additional modal_price_pay'}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">현금납부액</h5>
            <button type="button" className="close close-modal" onClick={closeModal}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body">
            <ul className="list-additional">
              <li
                className={(id === 0 ? 'active' : '') + ' cursor'}
                onClick={() => {setId(0); setValue(0); setResult(0)}}
              >
                미적용
              </li>
              <li
                className={(id === 1 ? 'active' : '') + ' cursor'}
                onClick={() => {setId(1); setValue(+installmentPrincipal + +value); setResult(1)}}
              >
                현금 완납
              </li>
              <li
                className={(id === 2 ? 'active' : '') + ' cursor'}
                onClick={() => {setId(2); setisOpenModalMoney(true)}}
              >
                부분 납부
              </li>
            </ul>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalMoney}
        toggle={() => setisOpenModalMoney(!isOpenModalMoney)}
        fade={true}
        // backdrop={"static"}
        className={'modal-dialog modal-dialog-centered'}
      >
        <div className="modal-content pl-4 pr-4">
          <div className="modal-header">
            <h5 className="modal-title">현금납부액</h5>
            <button
              type="button"
              className="close close-modal"
              onClick={() => setisOpenModalMoney(false)}
            >
             <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body pl-4 pr-4">
            <form onSubmit={onsubmit}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 text-modal-gray">
                  할부원금 : {formatter.format(maxValue).replace('$','')} 원
                </p>
              </div>
              <div className="form-group row mt-4 align-items-center">
                <div className="col-10 pr-0">
                  <CurrencyInput
                    precision="0"
                    onChangeEvent={(event, maskedvalue, floatvalue) => setValue(floatvalue)}
                    className="form-control h-44"
                    value={value}
                  />
                </div>
                <div className="col-2">
                  <span className="text-wrapper-gray">원</span>
                </div>
              </div>
              <h5  className="text-wrapper-gray text-left color-orange">
                ※ 할부원금을 초과한 금액은 적용하실 수 없습니다.
              </h5>
              <h5 className="text-wrapper-gray text-left">
                입력한 현금 납부액을 적용하시겠습니까?
              </h5>
              <div className="d-flex justify-content-center border-top-0 mt-5">
                <button
                  type="button"
                  className="btn btn-gray pr-5 pl-5 mr-2"
                  onClick={() => setisOpenModalMoney(false)}
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="btn btn-submit pr-5 pl-5 ml-2"
                >
                  확인
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

ModalCashPayment.propTypes = {
  onSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  installmentPrincipal: PropTypes.number.isRequired,
  cashPayment: PropTypes.number.isRequired,
};

export default ModalCashPayment;

