
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector } from 'redux-form';
import validate from './validate-step-1.js';
import {showLoading, hideLoading} from '../../../store/actions/ui';

import * as routerName from '../../../utils/routerName';

class ForgotPasswordStep1Form extends Component {
    
    componentDidMount () {
    }
      
 

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <p className="text-wrapper">
                    비밀번호가 기억나지 않으시나요?
                </p>
                <form  autoComplete="on" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="" className="label-text">아이디</label>
                        <Field
                            id="username"
                            label="username"
                            name="username"
                            component={renderTextField}
                        />
                    </div>
                    <div className="submit-form mt-100">
                        <button className="btn btn-submit" type="submit" data-toggle="modal" data-target="#submit-modal">
                            임시비밀번호 받기
                        </button>
                    </div>
                    <p className="text-retrieval">아이디가 기억나지 않으시면  <a  onClick={() => this.props.history.push({ pathname: '/' + routerName.FORGOT_ID })} >아이디 찾기</a> 를 선택해주세요.</p>
                </form>
            </Fragment>
        );
    }
}

ForgotPasswordStep1Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        ForgotPasswordStep1: getFormValues('ForgotPassword')(state),
        ForgotPasswordStep1Errors: getFormSyncErrors('ForgotPassword')(state),
        ForgotPassword:state.ForgotPassword,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'ForgotPassword';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate, 
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(ForgotPasswordStep1Form);
