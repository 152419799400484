import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {change, Field, reduxForm} from 'redux-form';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from "moment/moment";
import api from "../../utils/api";
import {updateWiredAdviceStep1, updateWiredAdviceStep2} from "../../utils/helper";
import {hideLoading, showLoading} from "../../store/actions/ui";
import {INSTALLMENT_PAYMENT_METHOD} from "../../utils/constant";
import * as routeName from "../../utils/routerName";
import {withRouter} from "react-router";

const divStyle = {
    zIndex: '999'
};
const none = {
    display: 'none'
};

class HistoryConsultantWiredComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected:null,
            WiredAdviceStep1: {
                telcomID: '',
                status: false,
                successString: '',
                installmentPaymentMethod: INSTALLMENT_PAYMENT_METHOD.principalAndInteres
            },
            WiredAdviceStep2: {
                status:false,
                saler: null,
                note: '',
                customerId: null,
                customerNote: '',
                classifyString: '',
                bookmark:null,
                classify: [
                    {
                        id: 1,
                        name: '인터넷',
                        icon: '/tsc_template/dist/images/globe.svg',
                        classify: [
                            {
                                device: {},
                                option: {},
                                cost: { value: 0 },
                                discount: { value: 0 },
                            },
                        ]
                    },
                    {
                        id: 2,
                        name: 'TV',
                        icon: '/images/tivi.svg',
                        classify: [
                            {
                                device: {},
                                option: {},
                                cost: { value: 0 },
                                discount: { value: 0 },
                            }
                        ]
                    },
                    {
                        id: 3,
                        name: '전화',
                        icon: '/images/phone.svg',
                        classify: [
                            {
                                device: {},
                                option: {},
                                cost: { value: 0 },
                                discount: { value: 0 },
                            }
                        ]
                    },
                    {
                        id: 4,
                        name: 'IoT',
                        icon: '/images/home.svg',
                        classify: [
                            {
                                device: {},
                                option: {},
                                cost: { value: 0 },
                                discount: { value: 0 },
                            }
                        ]
                    },
                ],
            },
            currentPage: 1,
            consultantPerPage: 5
        }
        this.myRef = React.createRef();
    }

    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }

    inputRefs = [];
    setRef = (ref) => {
        if (ref && ref != null) {
            this.inputRefs.push(ref);
        }
    };

    triggerClickCheckBox = (id) => {

        let dataMapRef = []
        this.inputRefs.map((item) => {
            if (item && item.value && item.value != null && item.value == id) {
                dataMapRef.push(item)
            }
        })
        if (dataMapRef && dataMapRef.length > 0) {
            dataMapRef[0].click()
        }
    }

    handleSubmit = () => {
        const {selected} = this.state

        if(selected) {

            this.props.showLoading()
            api.advice.getConsultant(selected)
                .then(response => {
                    this.props.hideLoading()
                    const {data} = response.data
                    const {WiredAdviceStep1, WiredAdviceStep2} = this.state
                    let WiredAdviceStep1Clone = updateWiredAdviceStep1(WiredAdviceStep1, data)
                    let WiredAdviceStep2Clone = updateWiredAdviceStep2(WiredAdviceStep2, data)
                    this.props.history.push({
                        pathname: `/${routeName.WIRED_ADVICE}`,
                        state: {
                            id:data.id,
                            WiredAdviceStep1:WiredAdviceStep1Clone,
                            WiredAdviceStep2:WiredAdviceStep2Clone,
                        }
                    })
                    // this.props.change('id', data.id)
                    // this.props.change('WiredAdviceStep1', WiredAdviceStep1Clone)
                    // this.props.change('WiredAdviceStep2', WiredAdviceStep2Clone)

                })
                .catch(() => {
                    this.props.hideLoading()
                })

        }

    }

    handleClickPaginate = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    handleNext = () => {
        const lastPage = this.state.consultants.length / this.state.consultantPerPage;
        this.state.currentPage === lastPage ? this.setState({currentPage: lastPage}) : this.setState({currentPage: this.state.currentPage + 1})
    };

    handleBack = () => {
        this.state.currentPage === 1 ? this.setState({currentPage: 1}) : this.setState({currentPage: this.state.currentPage - 1})
    };



  render() {
        const {consultants}=this.props

      let {selected} = this.state;

      const {currentPage, consultantPerPage} = this.state

      const lastPage = currentPage * consultantPerPage;
      const firstPage = lastPage - consultantPerPage;
      const currentConsultants = consultants.slice(firstPage, lastPage);

      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(consultants.length / consultantPerPage); i++) {
          pageNumbers.push(i);
      }

      const renderPagination = pageNumbers.map(number => {
          return (
              <li className={(this.state.currentPage === number? 'active': '')+ ' page-item'} key={number} >
                  <a className="page-link" id={number} onClick={this.handleClickPaginate}>{number}</a>
              </li>
          );
      });

      // const html = consultants && consultants.length > 0 && consultants.map((consultant, key) => {
      const html = currentConsultants && currentConsultants.length > 0 && currentConsultants.map((consultant, key) => {

          return <tr key={key} onClick={() => this.triggerClickCheckBox(consultant.id)} style={divStyle}>
              <td >
                  <div className="d-flex">
                      <div className="custom-control custom-radio">
                          <input
                              type="radio"
                              style={none}
                              ref={this.setRef}
                              id={`check-00${consultant.type_name+key}`}
                              name="customRadio"
                              className="custom-control-input"
                              onChange={this.handleChangeRadio}
                              value={consultant.id}
                          />
                          <input type="radio" className="custom-control-input"
                                 value={consultant.id}

                                 onClick={() => this.triggerClickCheckBox(consultant.id)}

                          />
                          <label className="custom-control-label ml-3"
                                 htmlFor={`check-00${consultant.type_name+key}`}>
                              {consultant.telcom_name}
                          </label>
                      </div>
                  </div>
              </td>
              <td>{consultant.model_name_wired == null ? '인터넷' : consultant.model_name_wired}</td>
              {/*<td>{consultant.plan_name_wired == null ? '인터넷 요금제' : consultant.plan_name_wired}</td>*/}
              <td className="text-right">
                  <b>
                      {moment(consultant.updated_at).format('YYYY-MM-DD')}
                  </b>
                  <span className="color-sliver ml-1">
                        {moment(consultant.updated_at).format('HH:mm:ss')}
                      </span>
              </td>
          </tr>

      });

    return (
      <Fragment>

          <article className="article_counseling">
              <div className="table-responsive">
                  <table className="table table-header w-100">
                  <thead>
                      <tr className="bg-purple">
                          <th className="text-left">통신사</th>
                          {/*<th className="text-left">모델명</th>*/}
                          <th className="text-left">요금제</th>
                          <th className="text-right">상담날짜/시간</th>
                      </tr>
                      </thead>
                  </table>
                  <div className="table-scroll">
                      <table className="table">

                          <tbody>

                          {html}

                          {/*{consultants.map((consultant, key) => (*/}
                              {/*<tr key={key}>*/}
                                  {/*<td>*/}
                                      {/*<div className="d-flex">*/}
                                          {/*<div className="custom-control custom-radio">*/}
                                              {/*<input*/}
                                                  {/*type="radio"*/}
                                                  {/*id={`check-00${consultant.type_name+key}`}*/}
                                                  {/*name="customRadio"*/}
                                                  {/*className="custom-control-input"*/}
                                                  {/*onChange={this.handleChangeRadio}*/}
                                                  {/*value={consultant.id}*/}
                                              {/*/>*/}
                                              {/*<label className="custom-control-label ml-3"*/}
                                                     {/*htmlFor={`check-00${consultant.type_name+key}`}>*/}
                                                  {/*{consultant.telcom_name}*/}
                                              {/*</label>*/}
                                          {/*</div>*/}
                                      {/*</div>*/}
                                  {/*</td>*/}
                                  {/*<td>{consultant.model_name == null ? 'internet' : consultant.model_name}</td>*/}
                                  {/*<td>{consultant.plan_name == null ? 'packet internet' : consultant.plan_name}</td>*/}
                                  {/*<td className="text-right">*/}
                                      {/*<b>*/}
                                          {/*{moment(consultant.updated_at).format('YYYY-MM-DD')}*/}
                                      {/*</b>*/}
                                      {/*<span className="color-sliver ml-1">*/}
                            {/*{moment(consultant.updated_at).format('HH:mm:ss')}*/}
                          {/*</span>*/}
                                  {/*</td>*/}
                              {/*</tr>*/}
                          {/*))}*/}
                          </tbody>
                      </table>
                      <nav aria-label="Page navigation example" className="pagination-notifi">
                          <ul className="pagination">
                              <li className="page-item item-previous" >
                                  <a className="page-link" onClick={this.handleBack} aria-label="Previous">
                                            <span aria-hidden="true">
                                                <img src="/images/Left.svg" alt="" />
                                            </span>
                                  </a>
                              </li>
                              {renderPagination}
                              <li className="page-item item-next" >
                                  <a className="page-link" onClick={this.handleNext} aria-label="Next">
                                            <span aria-hidden="true">
                                                <img src="/images//Right.svg" alt="" />
                                            </span>
                                  </a>
                              </li>
                          </ul>
                      </nav>
                  </div>
              </div>

          </article>
          <div className="d-flex justify-content-center border-top-0 mt-3">
              <button type="button" className="btn btn-gray mx-2 pr-5 pl-5" data-dismiss="modal">취소</button>
              <button type="button" className="btn btn-submit mx-2 pl-4 pr-4" disabled={selected ? false : true} data-dismiss="modal" onClick={this.handleSubmit}>불러오기</button>
          </div>

      </Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
      showLoading: () => dispatch(showLoading()),
      hideLoading: () => dispatch(hideLoading()),
  };
};




const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withRouter(compose(
  withConnect,
)(HistoryConsultantWiredComponent));

