import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import EditProfileForm from './EditProfileComponent/EditProfileForm.js';
import api from '../../utils/api.js';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { showModal, changeModalTitle, changeModalContent, hideModal } from '../../store/actions/modal';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change } from 'redux-form';
import {
    updateProfileSuccess
} from '../../store/actions/auth';
import * as routerName from '../../utils/routerName';
import * as MessageJSON from '../../utils/message.json';
import DaumPostcode from 'react-daum-postcode';
import * as helper  from '../../utils/helper';
class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registration_number: {
                registration_verify_status: true,
                registration_verify_msg: '',
            },
            verify_phone: {
                verify_phone_status: true,
                verify_phone_msg: '',
            },
            verify_old_member: {
                verify_status: false,
                verify_msg_user_id: '',
                verify_msg_user_pw: '',
            },
            time: {},
            seconds: 300
        }
        this.timer = 0;
    }

    componentDidMount() {

    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }


    startTimer = () => {
        this.setState({
            seconds: 300
        }, () => {
            this.timer = setInterval(this.countDown, 1000);
        })
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    onChangeVerifyOldMember = (e) => {
        let value = e.target.value
        let name = e.target.name

        this.setState({
            verify_old_member: {
                ...this.state.verify_old_member,
                verify_status: false,
                ['verify_msg_' + name]: '',
            }
        })
    }

    onClickVerifyOldMember = () => {
        const { user_id, user_pw } = this.props.EditProfile

        if (!user_id || !user_pw) {
            this.setState({
                verify_old_member: {
                    verify_status: false,
                    verify_msg_user_id: !user_id ? '사용 중인 모비고 아이디를 입력해주세요' : '',
                    verify_msg_user_pw: !user_pw ? '사용 중인 모비고 비밀번호를 입력해주세요' : '',
                }
            })
            return;
        }

        return api.auth.checkOldMember({user_id, user_pw, except: true})
            .then(response => {
                console.log(response)
                this.setState({
                    verify_old_member: {
                        verify_status: true,
                        verify_msg_user_id: '모비고 아이디 확인되었습니다',
                        verify_msg_user_pw: '',
                    }
                })
                return
            })
            .catch(error => {
                error = error.response
                console.log(error)
                if (error.resultCode === 1002) {
                    this.setState({
                        verify_old_member: {
                            verify_status: false,
                            verify_msg_user_id: '휴면 상태인 모비고 아이디입니다',
                            verify_msg_user_pw: '',
                        }
                    })
                } else {
                    this.setState({
                        verify_old_member: {
                            verify_status: false,
                            verify_msg_user_id: '입력하신 모비고 아이디를 확인해주세요',
                            verify_msg_user_pw: '',
                        }
                    })
                }
            })
    }

    renderEditProfileComponent = () => {
        let xhtml = null;
        xhtml = <EditProfileForm
            history={this.props.history}
            onSubmit={this.onSubmit}
            redirectProfile={this.redirectProfile}
            onClickCreateVerifySMS={this.onClickCreateVerifySMS}
            onClickComfirmVerifySMS={this.onClickComfirmVerifySMS}
            onChangeVerifyPhone={this.onChangeVerifyPhone}
            onChangeVerifyPhoneCode={this.onChangeVerifyPhoneCode}
            onClickShowMap={this.onClickShowMap}
            verify_phone={this.state.verify_phone}
            time={this.state.time}
            seconds={this.state.seconds}
            onClickVerifyBussiness={this.onClickVerifyBussiness}
            registration_number={this.state.registration_number}
            onChangeVerifyRegistration={this.onChangeVerifyRegistration}
            verify_old_member={this.state.verify_old_member}
            onClickVerifyOldMember={this.onClickVerifyOldMember}
            onChangeVerifyOldMember={this.onChangeVerifyOldMember}
        />;
        return xhtml;
    }

    onSubmit = (data) => {
        if (this.state.verify_phone.verify_phone_status !== true  ) {
            this.setState({
                verify_phone: {
                    verify_phone_msg: MessageJSON.signup.verify_phone_check_submit,
                    verify_phone_status: false
                }
            })
            return
        }
        if (this.state.registration_number.registration_verify_status !== true) {
            this.setState({
                registration_number: {
                    registration_verify_msg: MessageJSON.signup.registration_business_check_submit,
                    registration_verify_status: false
                }
            })
            return
        }

        if (data.is_change_user && !this.state.verify_old_member.verify_status) {
            this.setState({
                verify_old_member: {
                    verify_status: false,
                    verify_msg_user_id: '모비고 아이디 인증하기를 진행해주세요',
                    verify_msg_user_pw: '',
                }
            })
            return
        }

        let dataRequest = {
            avatar: data.avatar,
            username: data.username,
            name: data.name,
            password: data.password,
            sto_business_name: data.sto_business_name,
            sto_name: data.sto_name,
            store_phone_code: data.store_phone_code,
            first_store_phone: data.first_store_phone,
            last_store_phone: data.last_store_phone,
            sto_phone_number: data.first_store_phone + '-' + data.last_store_phone,
            user_phone_number: data.first_user_phone + '-' + data.last_user_phone,
            email: data.email,
            sto_business_email: data.sto_business_email,
            sto_addr_code: data.sto_addr_code,
            sto_addr_line1: data.sto_addr_line1,
            sto_addr_line2: data.sto_addr_line2,
            sto_id: this.props.auth.store.sto_id,
            sto_business_no: data.registration_number.toString().replace(/,/g, '-'),
        }
        if (data.is_change_user) {
            dataRequest = Object.assign(dataRequest, {
                user_id: data.user_id,
                user_pw: data.user_pw,
            })
        }
        let id = this.props.auth.user.id;
        this.props.showLoading();
        api.profile.profileUpdate(dataRequest).then(res => {
            this.props.hideLoading();
            this.popupShowMessageSuccess();
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
            localStorage.setItem('storeTSC', JSON.stringify(res.data.data.store));
            localStorage.setItem('storePlan', JSON.stringify(res.data.data.storePlan));
            this.props.updateProfileSuccess(res.data.data);
        }).catch(err => {
            this.popupShowMessageError(err);
            this.props.hideLoading();
        })
    }

    popupShowMessageError = (title) => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    {title}
                </h4>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }
        this.props.showModal(dataModal);
    }

    popupShowMessageSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    프로필이 정상적으로 업데이트되었습니다.
                </h4>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.confirmRedirectProfile()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }

    confirmRedirectProfile = () => {
        this.props.hideModal();
        this.props.history.push({ pathname: '/' + routerName.MY_PROFILE })
    }

    redirectProfile = () => {
        this.props.history.push({ pathname: '/' + routerName.MY_PROFILE })
    }

    popupVerifyPhoneShowMessageSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    {MessageJSON.signup.title_check_phone}
                </h4>
                <p className="text-center" dangerouslySetInnerHTML={{__html:MessageJSON.signup.verify_phone_check_phone}}>
                </p>

                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }

    onChangeVerifyPhoneClearCountDown = () => {
        this.setState({
            verify_phone: {
                verify_phone_status: false
            },
            seconds: 0,
            time: this.secondsToTime(0),
        }, () => {
            clearInterval(this.timer);
        })

    }
    
    onClickCreateVerifySMS = () => {
        let data = {
            phone: this.props.EditProfile.first_user_phone + '-' + this.props.EditProfile.last_user_phone
        }

        if (data.phone.length >= 12) {
            this.props.showLoading();
            api.auth.createVerifySMS(data).then(res => {
                // console.log(res)
                this.onChangeVerifyPhoneClearCountDown();
                this.startTimer();
                this.props.hideLoading();
                this.popupVerifyPhoneShowMessageSuccess();
            }).catch(err => {
                this.props.hideLoading();
                // this.popupVerifyPhoneShowMessageSuccess(); check lỗi hệ thống y như find password
            })
        }
    }

    onClickComfirmVerifySMS = () => {
        let data = {
            phone:this.props.EditProfile.first_user_phone + '-' + this.props.EditProfile.last_user_phone,
            code:this.props.EditProfile.store_phone_code
        }

        if(data.phone.length == 13 && data.code.length == 6) {
            this.props.showLoading();
            api.auth.comfirmVerifySMS(data).then(res => {
                this.props.hideLoading();
                this.setState({
                    verify_phone:{
                        verify_phone_msg:MessageJSON.signup.verify_phone_code_success,
                        verify_phone_status:true
                    }
                })
            }).catch(err => {
                this.setState({
                    verify_phone:{
                        verify_phone_msg:MessageJSON.signup.verify_phone_code_fail,
                        verify_phone_status:false
                    }
                }, () => {
                })
                this.props.hideLoading();
            })
        } else{
            this.setState({
                verify_phone:{
                    verify_phone_msg: MessageJSON.signup.verify_phone_check_format,
                    verify_phone_status:false
                }
            })
        }
    }

    handleComplete = (data) => {
        let fullAddress = data.address;
        let zip_code = data.zonecode;
        let buildingName = data.buildingName;
        this.props.change('sto_addr_code', zip_code)
        this.props.change('sto_addr_line1', fullAddress)
        this.props.change('sto_addr_line2', buildingName)
        let extraAddress = '';
        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        this.props.hideModal()
    }

    onClickShowMap = () => {
        let xhtml = (
            <div className="content-register-modal">
                <DaumPostcode
                    onComplete={this.handleComplete}
                    {...this.props}
                />
                {/* hủy bỏ */}
                <div className="d-flex justify-content-center">
                    <button type="button" className="btn btn-secondary " data-dismiss="modal" onClick={() => this.props.hideModal()}>취소</button>
                </div>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }
    
    onChangeVerifyPhone = () => {
        setTimeout(()=>{
            if(this.props.EditProfile.phone_old  == (this.props.EditProfile.first_store_phone + '-' + this.props.EditProfile.last_store_phone)) {
                this.setState({
                    verify_phone:{
                        verify_phone_status:true,
                        verify_phone_msg:''
                    },
                    seconds: 0,
                    time: this.secondsToTime(0),
                }, () => {
                    clearInterval(this.timer);
                })
            } else {
                this.setState({
                    verify_phone:{
                        verify_phone_status:false
                    },
                    seconds: 0,
                    time: this.secondsToTime(0),
                }, () => {
                    clearInterval(this.timer);
                })
            }
        })

    }

    onChangeVerifyPhoneCode = () => {
        if(this.props.EditProfile.phone_old  != this.props.EditProfile.first_store_phone + '-' + this.props.EditProfile.last_store_phone) {
            this.setState({
                verify_phone:{
                    verify_phone_status:false
                }
            })
        }
    }

    onClickVerifyBussiness = () => {
            let data = {
                register_business_code: this.props.EditProfile.registration_number.toString().replace(/,/g, '-')
            }
            if (data.register_business_code.length == 12) {
                this.props.showLoading()
                return api.auth.verifyBussiness(data).then(res => {
                    // if (res.data.status == 101) {
                    //     this.setState({
                    //         registration_number: {
                    //             registration_verify_msg: MessageJSON.signup.registration_business_code_unique,
                    //             registration_verify_status: false
                    //         }
                    //     }, () => {
                    //         this.props.hideLoading()
                    //     })
                    // } else if (res.data.status == 1) {
                    //     this.setState({
                    //         registration_number: {
                    //             registration_verify_msg: MessageJSON.signup.registration_business_code_success,
                    //             registration_verify_status: true
                    //         }

                    //     }, () => {
                    //         this.props.hideLoading()
                    //     })
                    // }
                    this.setState({
                        registration_number: {
                            registration_verify_msg: MessageJSON.signup.registration_business_code_success,
                            registration_verify_status: true
                        }

                    }, () => {
                        this.props.hideLoading()
                    })
                }).catch(err => {
                    this.props.hideLoading()
                    if (err.response.data.status == 0) {
                        this.setState({
                            registration_number: {
                                registration_verify_msg: MessageJSON.signup.registration_business_code_fail,
                                registration_verify_status: false
                            }
                        })
                    }

                })
            } else {
                this.setState({
                    registration_number: {
                        registration_verify_msg: MessageJSON.signup.validate.registration_number_field_required,
                        registration_verify_status: false
                    }
                })
            }
    }

    onChangeVerifyRegistration = () => {
        this.setState({
            registration_number: {
                registration_verify_status: false
            }
        })
    }

    render() {
        return (
            <Fragment>
                <section className="page_profile">
                    <div className="container">
                        <div className="edit_form_profile form-register">
                            {this.renderEditProfileComponent()}
                        </div>
                    </div>
                </section>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    let initValues;
    if (!!state.auth && state.form.EditProfile) {
        let sto_phone_number = state.auth.store.sto_phone_number && state.auth.store.sto_phone_number.split('-')
        let first_store_phone = sto_phone_number[0]
        sto_phone_number.shift()
        let last_store_phone = sto_phone_number
        initValues = {
            ...state.form.EditProfile.initial,
            avatar: '',
            avatar_url: state.auth.user.avatar_url && state.auth.user.avatar_url.thumbnail ? state.auth.user.avatar_url.thumbnail : '',
            username: state.auth.user.username,
            name: state.auth.user.name,
            sto_business_name: state.auth.store.sto_business_name,
            sto_name: state.auth.store.sto_name,
            store_phone_code: '',
            registration_number: [],
            first_user_phone: state.auth.user.phone && state.auth.user.phone.slice(0, 3),
            last_user_phone: state.auth.user.phone && state.auth.user.phone.slice(4, state.auth.user.phone.length),
            first_store_phone: first_store_phone,
            last_store_phone: last_store_phone.join('-'),
            phone_old: state.auth.store.sto_phone_number,
            email: state.auth.user && state.auth.user.email,
            sto_addr_code: state.auth.store.sto_addr_code,
            sto_addr_line1: state.auth.store.sto_addr_line1,
            sto_addr_line2: state.auth.store.sto_addr_line2,
            user_id: state.auth.user.mobigo_user_id,
        };
    }
    if (!!state.auth && state.form.EditProfile && !!state.auth.store && state.auth.store.sto_business_type == '1') {
        initValues = {
            ...initValues,
            registration_number: [
                state.auth.store.sto_business_no ? state.auth.store.sto_business_no.slice(0, 3) : '',
                state.auth.store.sto_business_no ? state.auth.store.sto_business_no.slice(4, 6) : '',
                state.auth.store.sto_business_no ? state.auth.store.sto_business_no.slice(7, 12) : ''
            ],
            sto_business_email: state.auth.store.sto_business_email
        };
    }
    return {
        auth: state.auth,
        initialValues: initValues,
        EditProfile: getFormValues('EditProfile')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        updateProfileSuccess: (data) => dispatch(updateProfileSuccess(data))
    };
};

const FORM_NAME = 'EditProfile';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        avatar: '',
        avatar_url: '',
        username: '',
        // name:'',
        password: '',
        repassword: '',
        sto_business_name: '',
        sto_name: '',
        store_phone_code: '',
        first_store_phone: '',
        last_store_phone: '',
        first_user_phone: '',
        last_user_phone: '',
        phone_old: '',
        email: '',
        sto_business_email: '',
        sto_addr_code: '',
        sto_addr_line1: '',
        sto_addr_line2: '',
        registration_number: [],
        onChangeRepassword: false,
        user_id: '',
        user_pw: '',
        is_change_user: false,
    },
    enableReinitialize: true
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(EditProfile);

