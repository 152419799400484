import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import {priceFormat} from "../../../../utils/helper";
import _ from "lodash";

class KTTeenagers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            selected: [],
            line:[],
            error_modal: {
                isShowModal: false,
                message: ""
            }
        };
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }

    toggleErrorModal = (e, modal) => {
        e && e.preventDefault();
        this.setState({
            [modal]: {
                ...this.state[modal],
                isShowModal: !this.state[modal].isShowModal
            }
        })
    }

    onCloseModal = () => {
        this.props.onSelect(this.state.selected, "kt_teenagers_promotion");
    }

    /*
    componentDidUpdate(prevProps){
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn

        if(JSON.stringify(plans_line_not_null) !== JSON.stringify(this.state.list )){
            this.setState({
                selected: this.props.selected,
                line: plans_line_not_null
            })
        }
    }
    */

    onOpened = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});
        
        this.setState({
            selected: this.props.selected,
            line: plans_line_not_null
        });
    }
    

    onApply = (e) => {

        this.toggleModal(e);
    }

    cancelApply = (e) => {
        this.setState({
            selected: this.props.selected
        }, () => {
            this.toggleModal(e)
        })
    }

    getPlanTypeNameById = (planType) => {
        let planTypeName = "";
        switch (+planType) {
            case 18:
                planTypeName = "conventional_commitment";
                break;
            case 19:
                planTypeName = "not_conventional_commitment";
                break;
            case 20:
                planTypeName = "fit_type_commitment";
                break;
            case 21:
                planTypeName = "not_fit_type_commitment";
                break;
            case 22:
                planTypeName = "not_3G_commitment";
                break;
            case 23:
                planTypeName = "other";
                break;
        }
        return planTypeName;
    }

    getActiveKTPremiumPlans = () => {
        let plans_line_not_null  = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        let wireCombineCondition = this.props?.wireCombineCondition || null
        let discount_condition   = wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition;
        let line_discount_cnt    = plans_line_not_null.length > wireCombineCondition.discount_line_cnt ? wireCombineCondition.discount_line_cnt : plans_line_not_null.length; // số line được áp dụng giảm giá
        let activeKTPremiumPlans = _.filter(_.take(plans_line_not_null,line_discount_cnt ), (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
            return discount_condition && !_.includes([2989, 2991, 2993, 2994, 2976 ], plan.plan.id)
                && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +discount_condition[planTypeName]
        });

        if(this.props.exceptSelected != null){
            let exceptSelectedLength = this.props.exceptSelected.length;
            let tempInt              = 0;

            for(tempInt = 0; tempInt < exceptSelectedLength; tempInt++){
                activeKTPremiumPlans.push(plans_line_not_null[this.props.exceptSelected[tempInt]]);
            }
        }

        activeKTPremiumPlans.sort(function(a, b) {
            return a.idx - b.idx;
        });
        
        return activeKTPremiumPlans;
    }

    checkAddDiscountCondition (plans_line_not_null, wireless_add_discount_condition, line_discount_cnt){
        plans_line_not_null = _.filter(plans_line_not_null, (plan) => {
            if( !(plan?.plan?.name.substring(0,2) == "5G" && (+plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100) < 80000)) return  plan; // task 6748 flow
        })
        let all_wireless_number_line = wireless_add_discount_condition?.all_wireless_number_line;
        let uniform_plan_number_line = wireless_add_discount_condition?.uniform_plan_number_line;
        let result = false;
        let plan_apply_discount = _.take(plans_line_not_null,line_discount_cnt );
        let count = 0;

        if(this.props.exceptSelected != null){
            count = count + this.props.exceptSelected.length;
        }

        // check số line thỏa điều kiện
        if(plan_apply_discount.length > 0) _.map(plan_apply_discount, (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan?.plan?.discost_ad_cond);
            if(wireless_add_discount_condition
                && planTypeName
                && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +wireless_add_discount_condition[planTypeName]
            ) count++;

        })

        // check số line đồng nhất thỏa điều kiện
        let is_uniform_active = false;
        let count_group =  _(plan_apply_discount).map((value, key) => {
            return value.plan // task 6748 flow
        }).groupBy('discost_ad_cond').value();

        _.forEach(count_group, (val, i) => {
            let planTypeNameGroup = this.getPlanTypeNameById(parseInt(i));
            let same_plan_count = _(val).groupBy('id').map(
                (group) => {
                    return ({id: group[0].id, qty: group.length, price: +group[0].cost_display + group[0].cost_display*10/100})
                }
            ).value();
            let same_plan = _.maxBy(same_plan_count,  'qty');
            // nếu có số đường dây giống nhau thỏa 1 trong điều kiện
            if(wireless_add_discount_condition && same_plan.qty >= +uniform_plan_number_line && same_plan.price >= +wireless_add_discount_condition[planTypeNameGroup] ) is_uniform_active = true;
            if(is_uniform_active) return false;
        });

        if(all_wireless_number_line && count >= all_wireless_number_line || is_uniform_active) result = true; // nếu thỏa 1 trong 2
        return result;
    }

    isKtPremiumDisable = () => {
        let result               = false;
        let plans_line_not_null  = _.reject(this.props.plans, {plan: null});// s? plan đa ch?n
        let activeKTPremiumPlans = this.getActiveKTPremiumPlans();
        let plans                = plans_line_not_null;

        if(plans.length > 0){
            let wireCombineCondition                  =  this.props?.wireCombineCondition || null;
            let KT_premium_1_discount_condition_group = wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group;
            let line_discount_cnt                     = plans.length > wireCombineCondition.discount_line_cnt ? wireCombineCondition.discount_line_cnt : plans.length; // số line được áp dụng giảm giá
            result                                    = this.checkAddDiscountCondition(plans, KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);
        }

        return result;
    }

    disableCheck = () => {
        let result             = true;
        let stateLineLength    = this.props.plans.length;
        let tempInt            = 0;
        let lineInfo           = null;
        let plan               = null;
        let classId            = null;
        let count5g            = 0;
        let costDisplay        = 0;
        let isKtPremiumDisable = this.isKtPremiumDisable();

        if(isKtPremiumDisable && this.props.kt_premium_discount != null){
            if(stateLineLength > 0){
                for(tempInt = 0; tempInt < stateLineLength; tempInt++){
                    lineInfo = this.props.plans[tempInt];
                    plan     = lineInfo.plan;

                    if(plan != null){
                        classId     = plan.classID;
                        costDisplay = +plan.cost_display * 1.1;
    
                        if((classId == "93" || classId == "94" || classId == "160") && costDisplay >= 80000){
                            count5g++;
                        }
                    }
                }

                if(count5g >= 2){
                    result = false;
                }
            }
        }

        if(result && this.state.selected != null){
            this.props.onSelect(null, "kt_teenagers_promotion");

            this.setState({
                selected: null
            })
        }

        return result;
    }

    onSelect = (e, value) => {
        let selected = [...this.state.selected];
        if(value){
            if(this.state.selected && this.state.selected.length > 0){
                var index = _.findIndex(this.state.selected, {idx: value.idx});
                if(index < 0){
                    selected.push(value)
                }else{
                    selected.splice(index, 1);
                }
            }else{
                selected.push(value)
            }
        }else{
            selected = []
        }
        selected = _.orderBy(selected, ['idx'],['asc']);

        let count5g = 0;
        
        this.state.line && this.state.line.map( (value, key) => {
            let plan = value.plan;

            if(plan){
                let classId     = plan.classID;
                let costDisplay = +plan.cost_display * 1.1;
        
                if((classId == "93" || classId == "94" || classId == "160") && costDisplay >= 80000){
                    count5g++;
                }
            }
        });

        if(selected.length == count5g){
            this.setState({
                error_modal: {
                    isShowModal: true,
                    message: (<span className="notification_delete">부모회선 확인 불가</span>)
                }
            });
        }
        else{
            this.setState({
                selected: selected
            });
        }
    }

    render() {
        return(
            <Fragment>
                <div className="form-group mt-5">
                    <label className="text-name text-wrap text-bold">청소년추가할인</label>
                    <button
                        className="btn btn-input-form h-32 mb-0"
                        type="button"
                        disabled={this.disableCheck()}
                        onClick={(e) => this.toggleModal(e)}
                    >
                        { this.props.selected && this.props.selected.length > 0
                            ? `적용 ${this.props.selected.map(val => {
                                return `/ 회선${val.idx}`
                            }).join(" ")}`
                            : "미적용"
                        }
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered NanumDiscount-modal"
                           contentClassName="pr-5 pl-5"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">청소년할인 프로모션 (만18세이하)</h5>
                            <button type="button" className="close close-modal" onClick={(e) => this.toggleModal(e)}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body pr-0 pl-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="top-content h-44">
                                        <h5 className="mb-0">1. 가입자 구분</h5>
                                    </div>
                                    <ul className="list-item mt-0 max-auto">
                                        <li
                                            className={!this.state.selected ? "bg-item" : "" }
                                            onClick={() => this.setState({selected: null})}
                                        >
                                            <p>미적용</p>
                                        </li>
                                        <li
                                            className={this.state.selected ? "bg-item" : "" }
                                            onClick={() => this.setState({selected: []})}
                                        >
                                            <p>
                                                적용
                                                <br/>
                                                무선 -5,500원 할인
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="top-content  h-44">
                                        <h5 className="mb-0">2. 할인적용 회선</h5>
                                    </div>
                                    { /*this.renderLineOption()*/}
                                    <ul className="list-item mt-0 max-auto">
{
    this.state.line && this.state.line.map( (value, key) => {
        let plan = value.plan;

        if(plan){
            let classId     = plan.classID;
            let costDisplay = +plan.cost_display * 1.1;
    
            if((classId == "93" || classId == "94" || classId == "160") && costDisplay >= 80000){
                return (
                    <li
                        key={key}
                        className={
                            this.state.selected && this.state.selected.length > 0 && _.findIndex(this.state.selected, {idx: value.idx}) >= 0
                            ? "bg-item"
                            : ""
                        }
                        onClick={(e) => this.onSelect(e, value)}
                    >
                        회선 {value.idx}
                    </li>
                );
            }
            else{
                return null;
            }
        }
        else{
            return null;
        }
    })
}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 mb-4">
                            <button type="button" className="btn btn-gray pr-5 pl-5" onClick={(e) => this.cancelApply(e)}>취소</button>
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={(e) => this.onApply(e)}>확인</button>
                        </div>
                    </Modal>
                </div>
                <Modal isOpen={this.state.error_modal.isShowModal}
                       className="modal-dialog modal-dialog-centered"
                       contentClassName="pl-4 pr-4"
                       toggle={(e) => this.toggleErrorModal(e, 'error_modal')}
                    // onOpened = {this.onOpened}
                    // onClosed = {this.onCloseModal}
                    // backdrop={"static"}
                >
                    <div className="modal-body text-center p-5">
                        {
                            this.state.error_modal.message
                        }
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                onClick={(e) => this.toggleErrorModal(e, 'error_modal')}>확인
                        </button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

KTTeenagers.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(KTTeenagers);