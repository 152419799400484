import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import {numberWithCommas, priceFormat} from "../../../utils/helper";

class DisclosureFundModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disclosure : this.props.disclosure||0,
            errMsg : null,
            isChangeInput : false,
            disclosureStep2 : this.props.disclosureBase,
        };
        this.closeRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                disclosure: this.props.disclosure||0,
                isChangeInput : false,
                errMsg : null
            });
        }
    }
    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(/,/g, '').replace(regex, separate);
        return res;
    }
    /**
     * handle change input 
     * 
     */
    handleChangeDisclosure = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let intNumber = value.split(',').join('');
        let errMsg = null;
        var regex=/^[0-9]+$/;
        if (intNumber.length > 10) {
            errMsg = '금액은 10자를 초과하면 안됩니다!';
        } else if (value && !intNumber.match(regex)) {
            errMsg = '숫자로 입력해야 됩니다!';
        }else if (parseInt(intNumber) > parseInt(this.props.validPrice)) {
            errMsg = `금액은 ${priceFormat(this.props.validPrice)}원 이하로 입력해 주세요.`
        }
        this.setState({
            disclosure : this.formatCurrency(this.state.disclosure),
            isChangeInput : true,
            errMsg : errMsg
        });
        if (errMsg) {
            this.setState({
                disclosure : this.formatCurrency(this.state.disclosure),
                isChangeInput : true,
                errMsg : errMsg
            });
        }else{
            this.setState({
                disclosure : this.formatCurrency(intNumber),
                isChangeInput : false,
                errMsg : null
            });
        }
        
    }

    /**
     * format currency disclosure
     * 
     * @param (int) number
     * @param separate
     * 
     * @return currentcy formated
     */
    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(regex, separate);
        return res;
    }

    /**
     * submit value Disclosure
     */
    submitDisclosure = (e) => {
        e.preventDefault();
        let value = this.state.disclosure;
        let {errMsg} = this.state;
        if (!errMsg) {
            
            this.props.disclosureProps(value ? value : this.props.disclosure);
            this.setState({
                isChangeInput : false
            });
            this.closeRef.current.click()
        } else {
            return false;
        }
    }

    /**
     * reset value Disclosure
     */
    handleResetValue = (e) => {
        e.preventDefault();

        const { WirelessAdvice } = this.props;
        let   disclosure = 0;

        if(WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18){
            if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType == "six"){
                disclosure = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidySixPlan;
            }
            else{
                disclosure = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyBasicPlan;
            }

            disclosure = priceFormat(disclosure);
        }
        else{
            disclosure = this.props.disclosureBase;
        }

        this.setState({
            disclosure : disclosure,
            errMsg : null
        });
    }

    /**
     * get value for input disclosure
     */
    getValueInputDisclosure = () => {
        if (this.state.isChangeInput) {
            return this.state.disclosure ;
        } else {
            return this.state.disclosure ? this.state.disclosure : this.props.disclosure ;
        }
    }

    /**
     * show error message when input value
     */
    showErrorMessage = (errMsg) => {
        return(<span className={'invalid-feedback'}>{errMsg}</span>);
    }


    render() {
        const { WirelessAdvice } = this.props;
        let valueDisclosure  = this.state.disclosure;
        let {errMsg} = this.state;

        return(
            <Fragment>
                <div
                    className="modal fade"
                    data-keyboard="false"
                    id="modal-05"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-custom modal-dialog-centered" role="document">
                        <div className="modal-content pl-5 pr-5">
                            <div className="modal-header">
                                <h5 className="modal-title">지원금 변경 </h5>
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                </button>
                            </div>
                            <div className="modal-body px-0">
                                <form onSubmit={this.submitDisclosure} className="body-plans">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-modal-gray">
                                            공시 지원금 : &nbsp;
                                            {WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18 ?
                                                WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType == "six" ?
                                                priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidySixPlan) :
                                                priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyBasicPlan)
                                            : this.props.disclosureBase
                                            }
                                            원
                                        </p>
                                        <button type="button" className="btn btn-gray-white" onClick = {this.handleResetValue}>적용</button>
                                    </div>
                                    <div className="form-group row mt-4 align-items-center">
                                        <div className="col-10 pr-0">
                                            <input type="text" 
                                                className={errMsg ? "form-control h-44 is-invalid" : "form-control h-44" } 
                                                value={valueDisclosure} 
                                                onChange = {this.handleChangeDisclosure}
                                            />
                                            {this.showErrorMessage(errMsg)}
                                        </div>
                                        <div className="col-2">
                                            <span className="text-wrapper-gray">원</span>
                                        </div>
                                    </div>
                                    <h5 className="text-wrapper-gray pt-1 text-left">
                                        입력한 지원금을 적용하시겠습니까?
                                    </h5>
                                    <div className="d-flex justify-content-center footer-modal border-top-0">
                                        <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" data-dismiss="modal" ref={this.closeRef}>취소</button>
                                        <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">저장</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default  DisclosureFundModal