import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as helper from '../../utils/helper';
import renderSelectField from '../FormHelper/SelectField';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import renderTextField from '../FormHelper/TextField';
import renderRadioField from '../FormHelper/RadioField';
import renderTextFields from '../FormHelper/TextFieldArray';
import renderCheckboxField from '../FormHelper/CheckboxField';
import api from "./../../utils/api";
import { showLoading, hideLoading, showSidebar, hideSidebar } from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import { isToday } from 'date-fns';

//
import moment from 'moment'
import { logoutAction } from '../../store/actions/auth';
//

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms)); //api 송신 딜레이를 위해 추가

class PaymentHyosungCMSMember extends Component {
    constructor(props) {
        super(props)
        this.state = {
            button_click: true
        }
    }

    popupShowCMSFailCMS = (text = '') => {
        this.setState({ //추가
            button_click: true
        })
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    효성CMS
                </h4>
                <p className="text-center">
                    {text || '등록 양식에 작성해 주세요!'}
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal() }>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);

        
    }
    
    popupShowCMSFailCMS2 = (text = '') => {
        this.setState({ //추가
            button_click: true
        })
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    효성CMS
                </h4>
                <p className="text-center">
                    결제 실패했습니다.
                    <br/>
                    ({text})
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal() }>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);

        
    }

    popupShowRetryLogin = () => {
        this.setState({ //추가
            button_click: true
        })
        let xhtml = (
            <div className="content-register-modal">
                <h4 classname="text-center mb-5">
                    &nbsp;
                </h4>
                <p className="text-center">
                당월 이미 결제된 건이 있습니다. 재 로그인하여 이용 부탁드립니다.
                    <br/>
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.onClickLogout() }>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);
    }

    //
    onClickLogout = () => {

        this.props.hideModal();

        let fb_token = '';
        if (localStorage.getItem("device_token")) {
            fb_token = localStorage.getItem("device_token")
        } else {
            fb_token = localStorage.getItem("fb_token")
        }
        let data = {
            fb_token: fb_token
        }
        //this.props.showLoading()
        this.props.logoutAction(data).then(res => {
            this.props.hideLoading()
        }).catch(err => {
            this.props.hideLoading()
        })
    }
    // 로그아웃
    //


    payment = () => {
        
        // 만료일체크

        api.get_hyosung_expired_date.getHyosungExpiredDate().then((response) => {
        if (moment(response.data) >= moment()) { //새로 조회한 실제 만료일 >= 기존 쿠키의 만료일
            this.popupShowRetryLogin();
        } //중복 결제 가능으로 할 시 해당 조건문 주석
        else{ //만료일이 끝났으면 기존 진행

            const { PaymentHyosungCMS } = this.props
            if (!PaymentHyosungCMS.memberId) {
                this.popupShowCMSFailCMS(); //결제 양식이 제대로 입력되지 않았음
                return;
            }

            if (this.props.callAmount == 0) {
                this.props.paymentSuccess()
                return
            }

            let data = {
                // "transactionId": "TRANSACTION-01441",
                "paymentKind": PaymentHyosungCMS.paymentKind != 'CARD' && this.props.paymentMethod == 1 ? 'REALCMS' : PaymentHyosungCMS.paymentKind,
                "memberId": PaymentHyosungCMS.memberId,
                "cardPointFlag": "N",
                "callAmount": this.props.callAmount,
                "vatAmount": this.props.vatAmount
            }
            if (this.props.PaymentManagement.rulesChecked1 == true && this.props.PaymentManagement.rulesChecked2 == true) {
                api.payment.card(data)
                    .then((response) => {
                        if (this.props.paymentSuccess) {
                            this.props.paymentSuccess(response.data?.data?.payment?.transactionId, response.data?.data?.payment?.result?.code)
                        }
                    })
                    .catch(error => {
                        this.popupShowCMSFailCMS2(Object.values(error.response.data.data)[0]);
                    })
            } else {
                this.props.popupShowCheckedRules() //이용약관 확인하는 함수
            }
        }

        })
        //
        
        this.setState({
            button_click: false
        })
    }


    
    render() {
        return (
            <button 
                type="button"
                className="btn btn-submit pl-5 pr-5 mx-1"
                onClick={this.payment}
                disabled={this.state.button_click == true ? false : true} //클릭 딜레이 부여, payment 호출 시 state 값을 변경하여 버튼 활성, 비활성화 설정
            >
                다음
            </button>
        )
    }
}

PaymentHyosungCMSMember.propTypes = {
    PaymentHyosungCMS: PropTypes.object,
    paymentSuccess: PropTypes.func,
    vatAmount: PropTypes.number,
    callAmount: PropTypes.number,
}

const FORM_NAME = 'PaymentHyosungCMS';

const mapStateToProps = state => {
    return {
        // initialValues: initValues,
        // priceGroups: state.dataStore.priceGroups,
        // pricePackages: state.dataStore.pricePackages,
        // auth: state.auth,
        PaymentHyosungCMS: getFormValues(FORM_NAME)(state),
        PaymentManagement: getFormValues('PaymentManagement')(state),
        // PaymentHyosungCMSErrors: getFormSyncErrors(FORM_NAME)(state),
        // priceExtraService: state.dataStore.priceExtraService,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        // getPricePackages: (data) => dispatch(getPricePackages(data)),
        //
        logoutAction: (data) => dispatch(logoutAction(data)),
        //
    };
};

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        "memberId": "",
        // "memberName": "SOFTONE",
        // "smsFlag": "N",
        "firstPhone": "010",
        "phone": "",
        "email": "",
        // "receiptNumber": "",
        // "memberKind": "000",
        // "managerId": "",
        "paymentKind": "CARD",
        "paymentNumber": "",
        "payerName": "",
        "payerNumber": "",
        "validYear": helper.getYearCardPayment()[0],
        "validMonth": helper.getMonthCardPayment()[0],
        // "password": "11",
        "paymentCompany": "",
    },
    enableReinitialize: true,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PaymentHyosungCMSMember);
