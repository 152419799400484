import React from "react";
import ShareWirelessLeft from "./ShareWirelessLeft";
import ShareWireLessRight from "./ShareWireLessRight";
import {priceFormat} from "../../../utils/helper";
import moment from "moment";
import ResultListTable from "./ResultListTable";
import CombineResultList from './../../Combine/CombineResultList'


class ShareWirelessMain extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
      left: 0,
      width: 0,
    }
    this.myRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.changeWindow);
    window.addEventListener('resize', this.changeWindow);
  }


  changeWindow = () => {
    if (this.myRef && this.myRef.current) {
      let widthRight = this.myRef.current.offsetWidth - 30
      let widthBody = document.body.clientWidth
      let left = ((widthBody - 3 * widthRight) / 2) + 30 + 2 * widthRight;

      if (this.myRef.current.getBoundingClientRect().top <= 0) {
        this.setState({
          left: left,
          width: widthRight,
          isSticky: true
        })
      } else {
        this.setState({
          left: 0,
          isSticky: false
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.changeWindow);
    window.removeEventListener('resize', () => this.changeWindow);
  }

  getResultBlockC = () => {
    let {data}                  = this.props;
    let result                  = data?.amountMonthlyOption || 0;
    let moreFeeApplyUsedProgram = +data?.applyUsedProgram || 0;

    result += moreFeeApplyUsedProgram;

    return priceFormat(result);
  }

  getUsedPhoneExtraService = () => {
    let {data}                = this.props;
    let usedPhoneExtraService = '';

    if (data?.usedPhoneCompensation?.ets_id) {
      let {ets_name}                             = data.usedPhoneCompensation;
      let {tcm_id, tcm_option_name, unit, value} = (data.usedPhoneMemberShip || {});
      let moreFeeApplyUsedProgram                = data?.applyUsedProgram || 0;
      let itemMembership                         = '';

      if (unit == 1 ) {
        itemMembership = (`${value}% 할인`);
      }
      else if (unit == 0 ) {
        itemMembership = (`${priceFormat(parseFloat(value))}원 할인`);
      }
      else {
        itemMembership = (`${tcm_option_name}`);
      }
      
      usedPhoneExtraService = (
        <div className="row">
            <div className="col-md-5 mb-3 pr-0">
              <div className="form-group">
                <label className="col-form-label text_label_name label-text" style={{fontSize: "13px"}}>
                  {ets_name}
                  {tcm_id ? <span style={{paddingLeft: "7px", color: "#2f8bff", fontWeight: 400}}>{itemMembership}</span> : ''}
                </label>
                <input type="text" className="form-control h-44" value={`${new Intl.NumberFormat().format(moreFeeApplyUsedProgram)}원`} disabled="" readOnly />
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group">
                <label className="col-form-label label-text">해지가능일</label>
                <div className="row">
                  <div className="col-md-7 pr-0">
                    <div className="form-date">
                      <button className="btn btn-input-form mb-0" type="button" disabled>
                        <img src="/images/icon-calender.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <button className="btn btn-delete-wave" disabled>
                      <img src="/images/icon-wave.svg" alt="" />
                      삭제
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }

    return usedPhoneExtraService;
  }

  render() {
    let {data} = this.props
    const { left, width } = this.state
    let style = {}
    if (left) {
      style.left = left
    }
    if (width) {
      style.width = width
    }


    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    console.log("data", data);

    return (
      <article className="comparative-information">
        <div className="row">
          <div className="col-md-8">
            <ShareWirelessLeft
              data={data}
            />
          </div>
          <div className="col-md-4" ref={this.myRef}>
            <div className={`right-information total-number border-bottom-0${this.state.isSticky ? ' right-fixed' : ''}`} style={style} id="right-information">
              <ShareWireLessRight
                data={data}
              />
            </div>
          </div>
        </div>

        <div className="row grape-record">
          <div className="col-md-8">
            <h3 className=" title-insurrance d-flex align-items-center">
              <p className="mb-0"> 보험 / 유심 / 부가 서비스 (C)</p>
              <span style={{marginLeft: "5px", fontSize: "13px", color: "rgb(54, 60, 88)", fontWeight: "normal"}}>*별도청구금액</span>
              {/*<p className="mb-0 label-prices text-left">월별 청구금액 <br /> <span className="small-color">(VAT 포함)</span></p>
              <p className="text-right mb-0 prices-blue">{priceFormat(data?.amountMonthlyOption)} <span className="small-color">원</span></p>*/}
            </h3>
            <div className="row  mt-2">
              <div className="col-md-4">
                <h4 className="label-text text-bold text-16 color-blue mt-3">
                  보험 / 유심
                </h4>
                <div className="form-group">
                  <label className="col-form-label label-text">유심</label>
                  <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-30">
                    {(data.sim?formatter.format(data.sim.price).replace('$',''):0) +"원"}
                  </button>
                  <p className="text-right mb-0 small-color">{data.sim ?data.sim .name_sim:'미적용'}</p>
                </div>

                {data.subscriptionFee ? (<div className="form-group">
                  <label className="col-form-label label-text">가입비</label>
                  <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-95">
                    {(data.subscriptionFee?formatter.format(data.subscriptionFee.tmf_fee).replace('$',''):0) +"원"}
                  </button>
                  <p className="text-right mb-0 small-color">{data.subscriptionFee?data.subscriptionFee.tmf_name:'미적용'}</p>
                </div>) : ""}
                
                <div className="form-group">
                  <label className="col-form-label label-text">휴대폰 보험</label>
                  <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-14">
                    {data.insurrance && data.insurrance.ins_price ? `${priceFormat(parseFloat(data.insurrance.ins_price))}원` :  '0원'}
                  </button>
                  <p className="text-right mb-0 small-color">{data.insurrance && data.insurrance.ins_name ? data.insurrance.ins_name  : '미적용'}</p>
                </div>
                <div className="form-group">
                  <div className="form-date">
                    <label className="col-form-label text-indents">abc</label>
                    <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-80">
                      {data.insurranceCancelDate && data.insurranceCancelDate != "Invalid date" ? data.insurranceCancelDate : '해지가능일' }
                      <img src="/images/icon-calender.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <h4 className="label-text text-bold text-16 color-blue mt-3">
                  부가서비스
                </h4>
                {this.getUsedPhoneExtraService()}
                {data.extraServices.map((item,key) => {
                  return (<div className="row" key={key}>
                    <div className="col-md-5 mb-3 pr-0">
                      <div className="form-group">
                        <label className="col-form-label text_label_name label-text">{item.ets_name}</label>
                        <input type="text" className="form-control h-44" value={`${new Intl.NumberFormat().format(item.ets_price)}`} disabled="" readOnly />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="col-form-label label-text">해지가능일</label>
                        <div className="row">
                          <div className="col-md-7 pr-0">
                            <div className="form-date">
                              <button className="btn btn-input-form mb-0" type="button">
                                {item.pivot && item.pivot.date ? moment(item.pivot.date).format("YYYY-MM-DD") : '' }
                                <img src="/images/icon-calender.svg" alt="" />
                              </button>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <button className="btn btn-delete-wave" >
                              <img src="/images/icon-wave.svg" alt="" />
                              삭제
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
                })}
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3 mb-1">
              <div class="d-flex justify-content-start flex-column">
                <div>
                  <span class="text-bold">월 부가서비스 요금</span>:(VAT포함) &nbsp;<span class="prices-blue">{this.getResultBlockC()}</span><span class="text-bold">원 </span>
                </div>
                <span>*유심비, 가입비 제외</span>
              </div>
            </div>

            <div class="total-price-bottom">
              <div class="left-total">
                <h2>월 납부액 (A+B+C)</h2>
                <span class="small-color">(VAT 포함)</span>
              </div>
              <div class="right-total">
                <p class="total-big">{priceFormat(data?.amountMonthlyTotal)}<span>원</span></p>
              </div>
            </div>

            {data?.combinePlans ? ((data.combinePlans.length > 0) ? (<ResultListTable data={data} />) : null) : null}

            {/*<CombineResultList
              combinePlans={data.combinePlans || []}
              combineCondition={data.combineCondition || {}}
              // removeFirst
              telcomID={data.phone?.telcomID}
              WirelessAdvice={{WirelessAdviceStep3: data}}
              telcom={data.telcom}
            />*/}

            <div className="form-group line-top">
              <label htmlFor className="label-text">메모</label>
              <textarea className="form-control"  rows={6} placeholder="메모" value={data?.note} />
            </div>
          </div>
        </div>
      </article>
    )
  }
}

export default ShareWirelessMain;
