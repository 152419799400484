 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


class PopupInactive extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    closeModal = () => {
        if (this.props.callback) {
          this.props.callback()
        }
        this.props.closePopupDisable()
    }

    modelClosed = () => {
      if (this.props.onModelClosed) {
        this.props.onModelClosed()
      }
    }

    render() {
        return (
          <Modal isOpen={this.props.isOpenModal}
          // onClosed={this.modelClosed}
          toggle={this.props.closePopupDisable} 
          fade={true}
          className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
          contentClassName="modal-penalty content">
      <div className="modal-header" style={{border:'none'}}>
          <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                  onClick={this.props.closePopupDisable}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
      </div>
      <ModalBody className="discount-card pl-0 pr-0 pt-0">
          <div className="content-register-modal">
              <div className="img-check">
                  {
                    this.props.iconLock == 'iconLock' ? <img src="/images/icon2.svg" alt=""/> : <img src="/images/icon-rattiec.svg" alt=""/>
                  }
              </div>
              <h4 className="text-center">
                {this.props.title || '서비스 준비중입니다.'}
              </h4>
              {this.props.content ? (
                <div className="text-center" dangerouslySetInnerHTML={{ __html: this.props.content }} />
              ) : (
                <p className="text-center">
                  {'빠른 시일내에 준비하여 찾아뵙겠습니다.'}
                </p>
              )}
              <div className="d-flex justify-content-center border-top-0 mt-5 mb-5">
                  <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={this.closeModal}>확인</button>
              </div>
          </div>
      </ModalBody>


    </Modal>
        );
    }
}

export default PopupInactive;
