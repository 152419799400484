export const hasRole = (user, name, requireAll = false) => {
    // if (!user) {
    //     return false;
    // }
    
    if (name instanceof Array) {
        
        for (let roleName of name) {
            const isHasRole = hasRole(user, roleName);

            if (isHasRole && !requireAll) {
                return true;
            } else if (!isHasRole && requireAll) {
                return false;
            }
        }

        return requireAll;
    } else {
        
        if (user.toLowerCase() == name) {
            
            return true;
        }
    }
        

    return false;
}

