import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {reduxForm, Field, getFormValues} from 'redux-form';
import renderTextField from "../../components/FormHelper/TextField";
import OpenTypeModal from "../../components/ReportSale/OpenTypeModal";
import DatePicker from "react-datepicker";
import api from "../../utils/api";
import ExtraServiceModal from "../../components/ReportSale/ExtraserviceModal";
import moment from "moment";
import TimeField from 'react-simple-timefield';
import renderReportCode from "../../components/FormHelper/ReportCodeField";
import MessageJSON from "../../utils/message.json";

const SALE_TYPES = ['개통유형','기기변경','번호이동','신규가입','중고가입'];
class CreateReportSaleStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModalChooseType: false,
            isOpenModalExtraServices: false,
            locations: [],
            startDate: new Date(),
            index: 0,
            open_type_txt: '',
            time: '',
        }
    }

    changeMemo = (event) => {
        this.props.change('step1.memo_note',event.target.value);
    }

    openPopupExtraServices = () =>
        this.setState({ isOpenModalExtraServices: !this.state.isOpenModalExtraServices })

    openPopupChooseType = () =>
        this.setState({ isOpenModalChooseType: !this.state.isOpenModalChooseType })

    componentDidMount() {
        api.consultant.getLocations({
            sto_id: this.props.auth.store.sto_id,
            telcomID: this.props.telcomId
        })
            .then(res => {
                let data = res.data.data;
                if (data) {
                    if(this.props.ReportSale.step1.store_location_id){
                        this.props.change('step1.store_location_id',res.data.data[0].stl_id);
                    }
                    for (let i=0;i<data.length;i++){
                        if (data[i].stl_id == this.props.ReportSale.step1.store_location_id) {
                            this.setState({
                                index: i
                            })
                            break;
                        }
                    }
                }
                this.setState({
                    locations: data
                })
            });
        if (this.props.ReportSale.step1.open_type) {
            this.setState({
                open_type_txt: SALE_TYPES[this.props.ReportSale.step1.open_type - 1]
            })
        }
        document.body.classList.add("body_wireless_advice");
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    handleChange = date => {
        if (date) {
            this.setState({
                startDate: date
            });
            this.props.change('step1.open_day',date);
        }
    };

    handleChangeTime = (e,val) => {
        const newTime = val.replace(/-/g, ':');
        const time = newTime.substr(0, 5);
        this.props.change('step1.open_time',time);
    };

    getIndexLocation = (index) => {
        this.setState({
            index: index
        })
        this.props.change('step1.store_location_id',this.state.locations[this.state.index].stl_id);
    }

    setOpenType = (val,txt) => {
        this.setState({
            open_type_txt: txt
        })
        this.props.change('step1.open_type',val);
    }

    changeOpenCode = (val) => {
        let {step1} = this.props.ReportSale;
        this.props.change('step1.open_code',val.target.value);
        api.consultant.checkOpenCode({
            code: val.target.value,
            storeId: this.props.ReportSale.sales_report_id,
            type: this.props.ReportSale.type
        })
            .then((res) => {
                if (!res.data.data) {
                    this.props.change('checkReportCode',true);
                } else {
                    this.props.change('checkReportCode',false);
                }
            });

    }


    render() {
        const {consultant} = this.props;
        let {step1} = this.props.ReportSale;
        if (this.state.locations.length) {
            this.props.change('step1.store_location_id',this.state.locations[this.state.index].stl_id);
        }
        let extHtml = consultant.extra_services && consultant.extra_services.map((val,i) => {
            return <p className="mb-1">{val.ets_name}</p>
        });

        const locationHtml = this.state.locations.length ? this.state.locations.map((item,idx) => {
            return <p className="dropdown-item" onClick={() => this.getIndexLocation(idx)}>{item.stl_name}</p>
        }) : ''
        //
        return (
            <div className="container">
                <div className="report-sale-step main-step-box">
                    <ul className="step-wireless">
                        <li className="item-step active">
                            <div className="open-item">
                                <span className="step-name">Step 1</span>
                                <span>개통정보</span>
                            </div>
                        </li>
                        <li className="item-step">
                            <div className="open-item">
                                <span className="step-name">Step 2</span>
                                <span>차감정보</span>
                            </div>
                        </li>
                    </ul>
                    {this.props.ReportSale.sales_report_id && this.props.consultant.state == 1 ? <button className="btn btn-gray-white h-44 btn-edit-form">판매수정</button> : ''}
                </div>
                <section className="form-box">
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="content-form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-3 border-right-gray mt-5">

                                        <div className="form-group">
                                            <label htmlFor="" className="label-text">개통일</label>
                                            <div className="form-date">
                                                <img src="/images/icon-calender.svg" alt="" />
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    name="open_day"
                                                    className="form-control h-44 form-datepicker"
                                                    placeholder="개통일"
                                                    readonly
                                                    selected={moment(step1.open_day).toDate()}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group form-time-clock">
                                            <label htmlFor="" className="label-text">개통시간</label>
                                            <TimeField
                                                value={step1.open_time}
                                                style={{width:"100%"}}
                                                onChange={this.handleChangeTime}
                                                className="form-control h-44" placeholder="개통시간" name="step1.open_time"
                                                colon=":"
                                            />
                                            <div className="icon-clock">
                                            <i class="far fa-clock"></i>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="" className="label-text">거래처</label>
                                            <div className="dropdown custom-dropdown">
                                                <button className="btn dropdown-toggle w-100" type="button"
                                                        id="dropdownMenuButton" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    {this.state.locations.length ? this.state.locations[this.state.index].stl_name : '거래처'}
                                                </button>
                                                <Field type="hidden" className="form-control h-44"
                                                       name="step1.store_location_id"
                                                       component={renderTextField}/>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {locationHtml}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group ">
                                            <label htmlFor="" className="label-text">고객명</label>
                                            <Field type="text" className="form-control h-44" placeholder="고객명" name="step1.customer_name" component={renderTextField}/>
                                        </div>

                                        <div className="form-group">
                                            <label className="label-text"> YYMMDD-S </label>
                                            <Field type="text" className="form-control h-44" component={renderReportCode}
                                                   placeholder="000000-0" name={"step1.report_code"}/>
                                        </div>

                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-4 border-right-gray mt-5">
                                                <div className="form-group ">
                                                    <label className="label-text"> 개통번호 </label>
                                                    <input className="form-control h-44"
                                                           value={step1.open_code || ''}
                                                           placeholder="개통번호"
                                                           onChange={this.changeOpenCode}
                                                    />
                                                    <Field className="form-control h-44 "
                                                           name="step1.open_code"
                                                           value={step1.open_code}
                                                           type={"hidden"}
                                                           component={renderTextField}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="label-text"> 통신사 </label>
                                                    <input className="form-control h-44" disabled value={consultant ? consultant.telcom_name : ''}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="label-text"> 개통유형 </label>
                                                    <button className="btn btn-input-form mb-0" type="button" onClick={this.openPopupChooseType}
                                                            data-toggle="modal"
                                                            data-target="#modal-37">
                                                        {step1.open_type?SALE_TYPES[step1.open_type-1] : SALE_TYPES[step1.open_type]}
                                                    </button>
                                                    <Field className="form-control h-44 "
                                                           name="step1.open_type"
                                                           placeholder="개통번호"
                                                           type={"hidden"}
                                                           component={renderTextField}/>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="label-text"> 단말기 </label>
                                                    <input className="form-control h-44 " value={consultant ? consultant.model_name : ''}
                                                           disabled/>
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label className="label-text"> 일련번호 </label>
                                                    <Field className="form-control h-44" value={step1.seri} name="step1.seri" placeholder="일련번호" component={renderTextField}/>
                                                </div>

                                            </div>
                                            <div className="col-8 mt-5">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="label-text"> 할인방식 </label>
                                                            <input className="form-control h-44" value="공시지원금"
                                                                   disabled />
                                                        </div>

                                                        <div className="form-group ">
                                                            <label className="label-text"> 개통구분 </label>
                                                            <input className="form-control h-44 " value={consultant.wirelessAdviceStep3.installmentPrincipal? "할부개통" : "현금개통"}
                                                                   disabled />
                                                        </div>

                                                        <div className="form-group mb-0">
                                                            <label className="label-text"> 요금제 </label>
                                                            <input className="form-control h-44"
                                                                   value={consultant.plan_name} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 border-right-blue">
                                                        <div className="form-group">
                                                            <label htmlFor=""
                                                                   className="label-text d-flex align-items-center">
                                                                부가서비스
                                                                {consultant.extra_services.length ? <div className="flex-title mb-0">
                                                                    <button type="button" className="btn btn-wireless h-25" onClick={this.openPopupExtraServices}>
                                                                        <img src="/images/icon-12.svg" alt="" />
                                                                        상세보기
                                                                    </button>
                                                                </div> : ''}
                                                            </label>
                                                            <div rows="6"
                                                                 className="form-control flex-row-custom  h-130 bg_input"
                                                                 disabled>
                                                                {extHtml}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label htmlFor=""
                                                                   className="label-text">판매자 </label>
                                                            <input type="text" className="form-control h-44"
                                                                   value={consultant.saler_name} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0 mt-3">
                                                            <label htmlFor="" className="label-text">메모</label>
                                                            <textarea rows="6" onChange={this.changeMemo}
                                                                      className="form-control h-135" value={step1.memo_note}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="main-btn-submit">
                                            <div className="group-button w-100">
                                                <button className="btn btn-button btn-gray mr-2 ml-2" onClick={this.props.back}>취소
                                                </button>
                                                <button
                                                        className="btn btn-button btn-submit mr-2 ml-2"
                                                        data-link="/create-report-sale-step-2.html" >다음
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <OpenTypeModal
                    openPopup={this.openPopupChooseType}
                    isOpenModal={this.state.isOpenModalChooseType}
                    setOpenType={this.setOpenType}
                    arr={SALE_TYPES}
                    activeIndex={step1.open_type}
                />
                <ExtraServiceModal
                    openPopup={this.openPopupExtraServices}
                    isOpenModal={this.state.isOpenModalExtraServices}
                    setOpenType={this.setOpenType}
                    extraServices={consultant.extra_services}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ReportSale: getFormValues('ReportSale')(state),
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};

const FORM_NAME = 'ReportSale';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(CreateReportSaleStep1);

