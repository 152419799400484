 import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from "../../utils/api";
import { showLoading, hideLoading } from '../../store/actions/ui';

class ModalCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-payment-custom'}
          onOpened={this.modalOpened}
        >
          <div className="modal-body">
            <p className="notification_delete text-center">
                전화가 마치면 확인 버튼 클릭해주세요!<br />
                감사합니다.
            </p>
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button type="button" className="btn btn-submit" onClick={this.props.submit}>전화 확인</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ModalCall.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  selectItem: PropTypes.func,
  openPopup: PropTypes.func,
  submit: PropTypes.func,
  isOpenModal: PropTypes.bool,
};

const FORM_NAME = 'PaymentHyosungCMS';

const mapStateToProps = state => {
  return {
    PaymentHyosungCMS: getFormValues(FORM_NAME)(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCall);
