
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import MutualServiceGModal from "./MutualServiceModal"
import { priceFormat, checkBlockFeature } from "../../../utils/helper";
import PopupInactive from '../../PopupInactive';

class WirelessMutualService extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenModal:false,
      isOpenPopupDisable: false,
      iconLock: null,
    }
  }
  openPopup = () => {
    if (checkBlockFeature()) {
        this.setState({
            isOpenPopupDisable: true,
            iconLock: 'iconLock'
        })

    } else {
        this.setState({
            isOpenModal: true
        })
    }
  }
    closePopup = () => {
        if (checkBlockFeature()) {
            this.setState({
                isOpenPopupDisable: false,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        iconLock: null
                    })
                }, 500)
            })
        } else {
            this.setState({
                isOpenModal: false
            })
        }
    }

    componentDidUpdate(prevProps) {


        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee == null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {

        }
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee != null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null &&
            this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee.id) {
            // setTimeout(() => {
            //     this.props.change('WirelessAdviceStep3.mutualService',null)
            // }, 100)

        }

    }

  closePopupDisableHandle = () => {
    this.setState({
        isOpenPopupDisable: false
    })
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
      let mutualService=WirelessAdvice.WirelessAdviceStep3.mutualService;
      if(mutualService && mutualService.total_discount) {
          var costNumber=Number(mutualService.price_month)
      }
    let data=mutualService && mutualService != null ? (mutualService.mus_id != 0 ? ("-" + priceFormat(costNumber)+"원") : '미적용') : '미적용'
      let dataNameService=mutualService && mutualService != null && mutualService.mus_id != 0 ? mutualService.mus_name : ''

    return (
      <div className="form-group row align-items-start">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mt-3">상조서비스</label>
        <div className="col-sm-8">
          <button className={"btn btn-input-form mb-0" + (costNumber > 0 ? ' btn-prices-wp' : '')} type="button" data-toggle="modal" data-target="#modal-64" onClick={this.openPopup}>
            {costNumber > 0 ? data : <span className={`mr-2 ${data === '미적용' ? ' opacity-disabled ' : ''}`}>{data}</span>}
          </button>
          <p className="text-right mb-0 small-color">{dataNameService}</p>
        </div>
        <MutualServiceGModal isOpenModal={this.state.isOpenModal}
                             closePopup={this.closePopup}
                             openPopup={this.openPopup} />
        <PopupInactive 
            isOpenModal={this.state.isOpenPopupDisable} 
            closePopupDisable={this.closePopupDisableHandle}
            iconLock={this.state.iconLock}
        />
      </div>
    );
  }
}

WirelessMutualService.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessMutualService);
