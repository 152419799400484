import MessageJSON from "../../utils/message.json";

const contact_type = {
    AD_REQUEST: 1,
    PROPOSE_THE_COOPERATION: 2,
    CONTENT_COMPLAINTS: 3,
}

const validate = values => {
    const errors = {};
    const {
        type,
        title,
        content,
        company_name,
        proposer_name,
        contact_first_phone,
        contact_phone,
        email,
        email_domain,
        email_domain_select,
        company_file,
        homepage,
        fist_fax,
        fax,
        accept_term,
        request_file,
    } = values;

    switch (type) {
        default: {
            break;
        }
    }

    if (!title) {
        errors.title = MessageJSON.contact.validate.title.required;
    } else if (title.trim() && title.length > 128) {
        errors.title = MessageJSON.contact.validate.title.max;
    }

    if (!content) {
        errors.content = MessageJSON.contact.validate.content.required;
    }

    if (!company_name) {
        errors.company_name = MessageJSON.contact.validate.company_name.required;
    } else if (company_name.trim() && company_name.length > 64) {
        errors.company_name = MessageJSON.contact.validate.company_name.max;
    }

    if (!proposer_name) {
        errors.proposer_name = MessageJSON.contact.validate.proposer_name.required;
    } else if (proposer_name.trim() && proposer_name.length > 64) {
        errors.proposer_name = MessageJSON.contact.validate.proposer_name.max;
    }

    if (!contact_phone) {
        errors.contact_phone = MessageJSON.contact.validate.contact_phone.required;
    } else if (contact_phone.trim() && contact_phone.length > 9) {
        errors.contact_phone = MessageJSON.contact.validate.contact_phone.max;
    }

    if (!email) {
        errors.email = MessageJSON.contact.validate.email.required;
    } else if (email.trim() && email.length > 64) {
        errors.email = MessageJSON.contact.validate.email.max;
    }

    if (!email_domain) {
        errors.email_domain = MessageJSON.contact.validate.email.required;
    } else if (email_domain.trim() && email_domain.length > 64) {
        errors.email_domain = MessageJSON.contact.validate.email.max;
    }

    if (homepage && homepage.length > 256) {
        errors.homepage = MessageJSON.contact.validate.homepage.max;
    }

    if (!fax.length > (fist_fax.length == 2 ? 9 : 8)) {
        errors.fax = MessageJSON.contact.validate.fax.max;
    }

    if (request_file) {
        if ((request_file.size / 1024 / 1024) > 20) {
            errors.request_file = MessageJSON.contact.validate.file.max;
        } else if (!['PDF','doc','docx','ppt','jpeg','jpg','png','txt','zip'].includes(request_file.name.split('.').pop())) {
            errors.request_file = MessageJSON.contact.validate.file.format;
        }
    }

    if (company_file) {
        if ((company_file.size / 1024 / 1024) > 20) {
            errors.company_file = MessageJSON.contact.validate.file.max;
        } else if (!['PDF','doc','docx','ppt','jpeg','jpg','png','txt','zip'].includes(company_file.name.split('.').pop())) {
            errors.company_file = MessageJSON.contact.validate.file.format;
        }
    }

    return errors;
};

export default validate;
