import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import renderTextField from '../FormHelper/TextField';
import renderTextareaField from '../FormHelper/TextareaField';
import renderSelectField from '../FormHelper/SelectField';
import renderCheckboxField from '../FormHelper/CheckboxField';
import renderFileRequestField from '../FormHelper/FileRequestField';
import validate from './validate';
import {showLoading, hideLoading} from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';

const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4)
    }
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
}

const title = {
    AD_REQUEST: `
        <p className="text-add-request">
            당사에 광고를 희망하시거나, WIN-WIN전략을 이끌어 내고자 하는 기업 및 개인은<br />
            다음의 양식을 통하여 (주)아크에 다양한 제휴제안 및 광고문의를 하실수있습니다.
        </p>
    `,
    PROPOSE_THE_COOPERATION: `
        <p className="text-add-request">
            당사에 광고를 희망하시거나 WIN-WIN 전략을 이끌어 내고자 하는 기업 및 개인은<br />
            다음의 양식을 통하여 ㈜ 아크 에 다양한 제휴제안 및 광고문의를 하실 수 있습니다.
        </p>
    `,
    CONTENT_COMPLAINTS: `
        <p className="text-add-request">
            사이트 이용중 불편하신 부분이나 개선되어야 할 점 등이 있다면<br />
            다음의 양식을 통하여 접수 하실 수 있습니다.
        </p>
    `,
}

class ContactForm extends Component {
    componentDidUpdate(prevProp) {
        const { ContactForm } = this.props
        if (
            prevProp.ContactForm.email_domain_select != ContactForm.email_domain_select
            && ContactForm.email_domain_select
        ) {
            let email_domain = ContactForm.email_domain_select === '직접입력' ? '' : ContactForm.email_domain_select
            this.props.change('email_domain', email_domain)
        }
    }

    render() {
        const { handleSubmit, type, ContactFormErrors } = this.props;

        return (
            <section>
                <div  dangerouslySetInnerHTML={{ __html: title[type] }} />
                <form onSubmit={handleSubmit}>
                    {['AD_REQUEST', 'PROPOSE_THE_COOPERATION'].includes(type) && (
                        <Fragment>
                            <h3 className="title-form">
                                제휴제안 내용
                            </h3>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    제목 *
                                </label>
                                {/*<input type="text" className="form-control w-calc" />*/}
                                <div className="w-calc">
                                    <Field
                                        id="title"
                                        label="title"
                                        name="title"
                                        component={renderTextField}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 position-none">
                                <label htmlFor="" className="label-text">
                                    내용 *
                                </label>
                                <div className="form-input">
                                    <Field
                                        id="content"
                                        label="content"
                                        name="content"
                                        rows="10"
                                        component={renderTextareaField}
                                    />
                                </div>
                            </div>
                            <span className="notifi-form">
                                ※ 내용이 없거나, 담당자와의 면담 및 기타 다른문의일 경우 반려 사유가 됨을 양해 바랍니다.
                            </span>
                            {type == 'PROPOSE_THE_COOPERATION' && (
                                <Fragment>
                                    
                                    <div className="form-group mb-1 mt-3">
                                        <label htmlFor="" className="label-text m-0">
                                            제안서 첨부
                                        </label>
                                        <Field
                                            placeholder="아이디"
                                            name="request_file"
                                            type="text"
                                            component={renderFileRequestField}
                                            accept='.PDF, .doc, .docx, .ppt, .jpeg, .jpg, .png, .txt, .zip'
                                        />
                                    </div>
                                </Fragment>
                            )}
                            <h3 className="title-form">
                                업체정보
                            </h3>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    회사명 *
                                </label>
                                <div className="w-calc">
                                    <Field
                                        id="company_name"
                                        label="company_name"
                                        name="company_name"
                                        component={renderTextField}
                                    />
                                </div>
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    제안자명 *
                                </label>
                                <div className="w-calc">
                                    <Field
                                        id="proposer_name"
                                        label="proposer_name"
                                        name="proposer_name"
                                        component={renderTextField}
                                    />
                                </div>
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    연락처 *
                                </label>
                                <div className="form-input d-flex">
                                    <Field
                                        name="contact_first_phone"
                                        className="w_100"
                                        component={renderSelectField}>
                                        {this.props.getFirstNumberPhone.map((item) => 
                                            <option key={item} value={item}>{item}</option>
                                        )}
                                    </Field>
                                    <div className="w-calc-100">
                                        <Field
                                            id="contact_phone"
                                            label="contact_phone"
                                            name="contact_phone"
                                            component={renderTextField}
                                            normalize={normalizePhone}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    이메일 *
                                </label>
                                <div className="form-input d-flex">
                                    <div className="w-155">
                                        <Field
                                            id="email"
                                            label="email"
                                            name="email"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <span className="center-input">@</span>
                                    <div className="position-relative">
                                        <Field
                                            id="email_domain"
                                            label="email_domain"
                                            name="email_domain"
                                            component={renderTextField}
                                            className="mr-17 w-243"
                                            errorStyle={{left: 0}}
                                        />
                                    </div>
                                    <Field
                                        name="email_domain_select"
                                        className="w-155"
                                        component={renderSelectField}>
                                        {this.props.getEmailDomains.map((domain, key) => 
                                            <option value={domain} key={key}>{domain}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group mb-1">
                                <label htmlFor="" className="label-text m-0">
                                    첨부파일
                                </label>
                                <Field
                                    placeholder="아이디"
                                    name="company_file"
                                    type="text"
                                    component={renderFileRequestField}
                                    accept='.PDF, .doc, .docx, .ppt, .jpeg, .jpg, .png, .txt, .zip'
                                />
                            </div>
                            <span className="notifi-form">※ 파일형식 pdf, doc, ppt, hwp, jpg, png, txt, zip 등록 가능합니다. (용량 제한 20MB)</span>
                            <div className="form-group mt-3">
                                <label htmlFor="" className="label-text m-0">
                                    홈페이지 주소
                                </label>
                                <Field
                                    id="homepage"
                                    label="homepage"
                                    name="homepage"
                                    component={renderTextField}
                                    className="w-calc"
                                />
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    팩스번호
                                </label>
                                <div className="form-input d-flex">
                                    <Field
                                        name="fist_fax"
                                        className="w_100"
                                        component={renderSelectField}>
                                        {this.props.getFirstFax.map((domain, key) => 
                                            <option value={domain} key={key}>{domain}</option>
                                        )}
                                    </Field>
                                    <Field
                                        id="fax"
                                        label="fax"
                                        name="fax"
                                        component={renderTextField}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {['CONTENT_COMPLAINTS'].includes(type) && (
                        <Fragment>
                            <h3 className="title-form">
                                불만/오류 내용
                            </h3>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    연락처 *
                                </label>
                                <div className="form-input d-flex">
                                    <Field
                                        name="contact_first_phone"
                                        className="w_100"
                                        component={renderSelectField}>
                                        {this.props.getFirstNumberPhone.map((item) => 
                                            <option key={item} value={item}>{item}</option>
                                        )}
                                    </Field>
                                    <div className="w-calc-100">
                                        <Field
                                            id="contact_phone"
                                            label="contact_phone"
                                            name="contact_phone"
                                            component={renderTextField}
                                            normalize={normalizePhone}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    이메일 *
                                </label>
                                <div className="form-input d-flex">
                                    <div className="w-155">
                                        <Field
                                            id="email"
                                            label="email"
                                            name="email"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <span className="center-input">@</span>
                                    <div className="position-relative">
                                        <Field
                                            id="email_domain"
                                            label="email_domain"
                                            name="email_domain"
                                            component={renderTextField}
                                            className="mr-17 w-243"
                                            errorStyle={{left: 0}}
                                        />
                                    </div>
                                    <Field
                                        name="email_domain_select"
                                        className="w-155"
                                        component={renderSelectField}>
                                        {this.props.getEmailDomains.map((domain, key) => 
                                            <option value={domain} key={key}>{domain}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group align-items-center">
                                <label htmlFor="" className="label-text m-0">
                                    제목 *
                                </label>
                                {/*<input type="text" className="form-control w-calc" />*/}
                                <div className="w-calc">
                                    <Field
                                        id="title"
                                        label="title"
                                        name="title"
                                        component={renderTextField}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="label-text">
                                    내용 *
                                </label>
                                <div className="form-input">
                                    <Field
                                        id="content"
                                        label="content"
                                        name="content"
                                        rows="10"
                                        component={renderTextareaField}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1">
                                <label htmlFor="" className="label-text m-0">
                                    첨부파일
                                </label>
                                <Field
                                    placeholder="아이디"
                                    name="request_file"
                                    type="text"
                                    component={renderFileRequestField}
                                    accept='.PDF, .doc, .docx, .ppt, .jpeg, .jpg, .png, .txt, .zip'
                                />
                            </div>
                            <span className="notifi-form">※ 파일형식 pdf, doc, ppt, hwp, jpg, png, txt, zip 등록 가능합니다. (용량 제한 20MB)</span>
                        </Fragment>
                    )}
                    <h3 className="title-form d-flex justify-content-between">
                        개인정보 수집 / 이용동의
                        <span className="text-red-01">※ 아래 내용을 반드시 확인 후 동의해 주세요.</span>
                    </h3>
                    <p className="text-footer">
                        개인정보 수집 주체 : (주) 아크 <br />
                        개인정보 수집 이용목적 : 광고 및 제휴제안 등 고객문의 대응<br />
                        개인정보 수집 항목 : 회사명, 이름, 연락처, 이메일, 팩스번호<br />
                        개인정보 보유 및 이용기간 : 전자상거래 등에서의 소비자보호에 관한 법률에 의거하여 문의처리 후, 3년간 보관
                    </p>
                    <h3 className="title-form">
                    </h3>
                    <div className="custom-control custom-checkbox">
                        <Field
                            type="checkbox" 
                            id="accept_term" 
                            name="accept_term"
                            component={renderCheckboxField}
                        />
                        <label className="custom-control-label" htmlFor="accept_term">개인 정보 수집에 동의합니다.</label>
                    </div>
                </form>
            </section>

        );
    }
}

ContactForm.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getFirstNumberPhone: PropTypes.array,
    getEmailDomains: PropTypes.array,
    getFirstFax: PropTypes.array,
    type: PropTypes.string,
    ContactForm: PropTypes.object,
    change: PropTypes.func,
    ContactFormErrors: PropTypes.object,
};

const FORM_NAME = 'ContactForm';

const mapStateToProps = state => {
    return {
        [FORM_NAME]: getFormValues(FORM_NAME)(state),
        ContactFormErrors: getFormSyncErrors(FORM_NAME)(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
    }
};

const withReduxForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: false,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(ContactForm);
