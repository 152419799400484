
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import * as routerName from '../../../utils/routerName';
import { Modal } from 'reactstrap';
class CancelDatePopup extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-small modal-dialog-centered modal-custom-11"
                    contentClassName="pl-5 pr-5"
                       // backdrop={"static"}
                >
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">
                        휴대폰보험 해지가능 날짜
                        </h5>
                        {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                        <img src="/images/icon-close.svg" alt="" />
                        </button> */}
                    </div>
                    <div className="modal-body discount-card pl-0 pr-0 pt-0">
                        <form>
                        <ul className="list-item mt-0">
                            {this.props.children}
                        </ul>
                        </form>
                    </div>
                </div>      
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => () => dispatch(change()),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CancelDatePopup);
