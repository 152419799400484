import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class SalesSettlement extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                <section>
                    <div className="container">
                        <div className="sales_report">
                            <div className="fill_search">
                                <form action="" className="compare-terminals p-0 mt-0">
                                    <div className="row top d-flex justify-content-between align-items-center mb-4">
                                        <div className="col-md-4">
                                            <ul className="nav nav-tabs list-tab" id="myTab" role="tablist">
                                                <li className="nav-item tab-item">
                                                    <a className="nav-link pl-5 pr-5 active" id="mont-tab" data-toggle="tab" href="#mont" role="tab" aria-controls="mont" aria-selected="true"> 월별 </a>
                                                </li>
                                                <li className="nav-item tab-item">
                                                    <a className="nav-link pl-5 pr-5" id="my01-tab" data-toggle="tab" href="#my01" role="tab" aria-controls="my01" aria-selected="false">일별</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-date">
                                                <img src="/images/icon-calender.svg" alt="" />
                                                <input type="text" className="form-control h-44" placeholder="28 May - 3 Jun, 2020 " />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-content tab-content-consultancy" id="myTabContent">
                                <div className="tab-pane fade active show" id="mont" role="tabpanel" aria-labelledby="mont-tab">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-consultancy table_striped">
                                            <thead className="thead_bg">
                                                <tr>
                                                    <th className="text-center">No</th>
                                                    <th className="text-center">통신사</th>
                                                    <th className="text-center">거래처</th>
                                                    <th className="text-center">판매 월</th>
                                                    <th className="text-center">실적</th>
                                                    <th className="text-right">거래처 정산</th>
                                                    <th className="text-right">매장 마진</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-white"></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="5" className="label-text">정산정보</td>
                                                    <td className="text-right table-prices text-bold">- 24,800,000<span>원</span></td>
                                                    <td className="text-right table-prices text-bold">31,000,000<span>원</span></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="my01" role="tabpanel" aria-labelledby="my01-tab">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-consultancy table_striped">
                                            <thead className="thead_bg">
                                                <tr>
                                                    <th className="text-center">No</th>
                                                    <th className="text-center">통신사</th>
                                                    <th className="text-center">거래처</th>
                                                    <th className="text-center">판매 월</th>
                                                    <th className="text-center">실적</th>
                                                    <th className="text-right">거래처 정산</th>
                                                    <th className="text-right">매장 마진</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle text-center">1</td>
                                                    <td className="align-middle text-center">SKT</td>
                                                    <td className="align-middle text-center">다음</td>
                                                    <td className="align-middle text-center">2020/05</td>
                                                    <td className="align-middle text-center">150</td>
                                                    <td className="align-middle text-right">-13,950,000원</td>
                                                    <td className="align-middle text-right">15,000,000원</td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-white"></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="5" className="label-text">정산정보</td>
                                                    <td className="text-right table-prices text-bold">- 24,800,000<span>원</span></td>
                                                    <td className="text-right table-prices text-bold">31,000,000<span>원</span></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(SalesSettlement);

