import {
    userActions
} from '../actions';

const initalState = {
    token: null,
    isAuthenticated: false,
    storePlanLast: null,
    accountErrStatus: 0,
    user: null,
    role: null,
    store: null,
    storePlan: null,
    isAllowAccessMenu:false,
    isOpenPopupMenu:true,
};

const auth = (state = initalState, action) => {
    switch (action.type) {
        case userActions.LOGIN_SUCCEED: {
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user,
                role: action.payload.role,
                store: action.payload.store,
                storePlan: action.payload.storePlan,
                storePlanLast: action.payload.storePlanLast,
                accountErrStatus: action.payload.accountErrStatus,
            }
        }

        case userActions.PROFILE_SUCCEED: {
            return {
                ...state,
                user: action.payload.user.user,
                store: action.payload.user.store,
                storePlan: action.payload.user.storePlan,
            }
        }

        case userActions.LOGOUT_SUCCEED: {
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                user: null,
                isAllowAccessMenu: false,
                isOpenPopupMenu: true,
            }
        }
        case userActions.GET_CURRENT_USER_SUCCEED: {
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user,
                role: action.payload.role,
                store: action.payload.store,
                storePlan: action.payload.storePlan,
                isAllowAccessMenu: action.payload.isAllowAccessMenu,
                isOpenPopupMenu: action.payload.isOpenPopupMenu,
                storePlanLast: action.payload.storePlanLast,
                accountErrStatus: action.payload.accountErrStatus,
            }
        }
        case userActions.ALLOW_ACCESS_MENU: {
            return {
                ...state,
                isAllowAccessMenu: action.payload.data
            }
        }
        case userActions.OPEN_POPUP_MENU: {
            return {
                ...state,
                isOpenPopupMenu: action.payload.data
            }
        }
        case userActions.UPDATE_PASSWORD_MENU: {
            return {
                ...state,
                user: action.payload.data,
            }
        }
        case userActions.UPDATE_EXPIRE_DATE: {
            return {
                ...state,
                storePlanLast: action.payload.data,
                accountErrStatus: 0,
            }
        }
        case userActions.UPDATE_EXPIRED_DATE: {
            return {
                ...state,
                storePlanLast: action.payload.data,
                storePlan: action.payload.data,
                accountErrStatus: 0,
            }
        }
        case userActions.UPDATE_OPTION_PRINT: {
            let user = {
                ...state.user,
                option_print: action.payload.data,
            }
            localStorage.setItem('user', JSON.stringify(user));
            return {
                ...state,
                user: user,
            }
        }

        default:
            return state;
    }
};

export default auth;
