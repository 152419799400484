
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {  compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues } from 'redux-form';
import { priceFormat } from "../../../utils/helper";
import ConversionAddModal from "./ConversionAddModal";

class ConversionAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal      : false,
      disclosure : ""
    }
  }

  showModal = () => {
    this.setState({
        modal: true
    })
  }

  hideModal = () => {
    this.setState({
        modal: false
    })
  }

  getValue = (data, value) => {
    let number = Math.floor(data / 10) * 10;

    this.props.change('WirelessAdviceStep3.conversion_price_input_add', number);
    this.props.change('WirelessAdviceStep3.conversion_price_input_add_type', value);
  }

  render() {
    const { WirelessAdvice } = this.props;
    let conversionPriceInputAdd = WirelessAdvice.WirelessAdviceStep3.conversion_price_input_add;
    let manualConversionConfirm = WirelessAdvice.WirelessAdviceStep3.manual_conversion_confirm;
    let styleNumber             = "";
    let disabled                = "";
    let validPrice              = +(WirelessAdvice.WirelessAdviceStep3?.conversion_price_input || 0) * 0.15;
    
    if(WirelessAdvice.WirelessAdviceStep3.optionFee == 0) {
      conversionPriceInputAdd = 0;
      disabled                = "disabled";
    }
    else if(manualConversionConfirm == 1){
      conversionPriceInputAdd = 0;
      disabled                = "disabled";
    }
    
    if (conversionPriceInputAdd != 0) {
      styleNumber = "btn-prices-wp";
    }

    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전환추가지원금</label>
          <div className="col-sm-8">
            <button className={`btn btn-input-form mb-0 ${styleNumber}`} type="button" disabled={ disabled } onClick={this.showModal}>
              { (conversionPriceInputAdd != "0" ? '-' : '') + priceFormat(conversionPriceInputAdd) }
              <span>원</span>
            </button>
          </div>
        </div>
        <ConversionAddModal 
          showModal={this.state.modal}
          toggleEvent={this.hideModal}
          getValue={this.getValue}
          validPrice={validPrice}
          additionalGrants={conversionPriceInputAdd}
          wirelessAdvice={WirelessAdvice}
        />
      </Fragment>
    );
  }
}

ConversionAdd.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {}
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(ConversionAdd);
