import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ServiceMoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isGoing: false,
            checked: [],
        }
    }

    handleInputChange = (event) => {
        this.setState({isGoing: !this.state.isGoing})
        const target = event.target;
        const value = target.value;
        let checked = this.state.checked;

        if (checked.indexOf(value) > -1) {
            checked.splice(checked.indexOf(value),1)
        } else {
            checked.push(value);
        }
         checked = checked.filter(function (item, index) {
            return checked.indexOf(item) === index;
        });
    }

    /**
     * mapping data toogle modal
     */
    toggle = () => {
        this.props.toggleModal();
    }

    /**
     * handleSubmit
     */
    handleSubmit = (e) => {
        e.preventDefault();
    }


    render() {
        const { data = [] } = this.props
        let { checked } = this.state;
        const html = data.map((item, key) => {
            // check
            let check = false
            if (checked.indexOf(item.ets_id) > -1) {
                check = true;
            }
            return <tr key={key}>
                <td >
                    <div className="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" className="custom-control-input" name="isGoing"
                               id={`customControl${key}`}
                               checked={check}
                               onChange={this.handleInputChange}
                               value={item.ets_id}
                        />
                        <label className="custom-control-label"
                               htmlFor={`customControl${key}`}></label>
                    </div>
                </td>
                <td scope="row">
                    {item.ets_name}
                </td>
                <td>
                    {item.ets_desc}
                </td>
                <td className="text-wrap">{item.ets_price}원</td>
            </tr>

        });
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.modal}
                    className="modal-dialog-centered modal-xl modal-dialog-scrollable"
                    contentClassName="pl-5 pr-5"
                    // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">부가서비스 선택</h5>
                        <button onClick={this.toggle} type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body pl-0 pr-0">
                        <form action="">
                            <article className="">
                                <table className="table table-striped">
                                    <thead>
                                    <tr className="bg-purple">
                                        <th></th>
                                        <th>서비스명</th>
                                        <th>상세설명</th>
                                        <th>이용요금</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {html}
                                    </tbody>
                                </table>
                            </article>
                            <div className="modal-footer justify-content-center border-top-0 mt-4">
                                <button type="button" onClick={this.toggle} className="btn btn_close" data-dismiss="modal">취소</button>
                                <button type="button" onClick = {this.handleSubmit} className="btn btn-submit pl-5 pr-5">적용</button>
                            </div>
                        </form>
                    </div>
                </Modal>
           </Fragment>)
    }
}

export default withRouter(ServiceMoreModal)