import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {Modal} from 'reactstrap';
import ListDevice from './../Device/ListDevice'

const ModalSelectDevice = ({isOpenModal, toggleModal, telcomId, onSelectedDevice, model,WirelessAdvice,manufacturers}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggleModal}
      fade={true}
      // backdrop={"static"}
      contentClassName="pl-5 pr-5"
      className={'modal-dialog modal-xl'}
    >
        <div className="modal-header">
          <h5 className="modal-title text-left w-100">단말기 선택</h5>
          <button type="button" className="close close-modal" onClick={toggleModal}>
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
        </div>
        <div className="modal-body content-product mt-0 pl-0 pr-0">
          <ListDevice
            telcomId={telcomId}
            clickActiveDevice={onSelectedDevice}
            model={model}
            WirelessAdvice={WirelessAdvice}
            manufacturers={manufacturers}
          />
        </div>
    </Modal>
  );
}

ModalSelectDevice.propTypes = {
    toggleModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  telcomId: PropTypes.number.isRequired,
  onSelectedDevice: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
};

export default ModalSelectDevice;

