import PropTypes from 'prop-types';
import React from 'react';
const WirelessAdviceStepHeader = ({ WirelessAdvice, setPage }) => (
 
  <ul className="step-wireless a">
    <li 
      className={`item-step${WirelessAdvice.step == 1 ? ' active' : ''}`} 
      onClick={() => setPage(1)}
    >
      {WirelessAdvice.step <= 1 ? (
        <div className="open-item">
          <span className="step-name">Step 1</span>
          <span>통신사 선택</span>
        </div>
      ) : (
        <div className="content-complete">
          { (WirelessAdvice.WirelessAdviceStep1?.telcomCode == 'hello_kt' || WirelessAdvice.WirelessAdviceStep1?.telcomCode == 'hello_lg') ? (
            <span className="font-14">{WirelessAdvice.WirelessAdviceStep1?.telcomName}</span>
          ) : (WirelessAdvice.WirelessAdviceStep1?.status == true) ? (
            <img src={WirelessAdvice.WirelessAdviceStep1?.successString} />
          ) : ('')}
          
        </div>
      )}
    </li>
    <li 
      className={`item-step${WirelessAdvice.step == 2 ? ' active' : ''}`} 
      onClick={() => setPage(2)}
    >
      {(WirelessAdvice.WirelessAdviceStep3?.phone?.device_name || WirelessAdvice.WirelessAdviceStep3?.phone?.name) ? (
        <div className={`content-complete`}>
          <h4 className={WirelessAdvice.step == 2 ? 'text-white' : ''}>
            {WirelessAdvice.WirelessAdviceStep3?.phone?.device_name || WirelessAdvice.WirelessAdviceStep3?.phone?.name}
          </h4>
        </div>
      ) : (
        <div className="open-item">
          <span className="step-name">Step 2</span>
          <span>단말기 선택</span>
        </div>
      )}
    </li>
    <li 
      className={`item-step${WirelessAdvice.step == 3 ? ' active' : ''}`} 
      onClick={() => setPage(3)}
    >
      <div className={`open-item`}>
        <span className="step-name">Step 3</span><span>상담</span>
      </div>
    </li>
  </ul>
)

WirelessAdviceStepHeader.propTypes = {
  WirelessAdvice: PropTypes.object,
  setPage: PropTypes.func,
}

export default WirelessAdviceStepHeader;
