import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import {getDiscountCard} from '../../../store/actions/discount_card';
import OpenPopupDiscountCard from "./../../Wireless/AdvancedDiscountCard/OpenPopupDiscountCard";
import { priceFormat } from '../../../utils/helper';

class DiscountCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModalDiscountCart: false,
      discountCard: []
    }
  }

  componentDidMount() {
    let data = {
      wired_discount_card : true,
      filter_telcomID : this.props.WiredAdvice.WiredAdviceStep1.telcomID
    }
    this.props.discountCard(data).then(res => {
      this.setState({
        discountCard : res
      })
    })
  }

  showModalDiscountCart = (indexClassify) => {
      const {WiredAdvice: {WiredAdviceStep2}} = this.props
      let disnableCard=true
      WiredAdviceStep2.classify.map((value, key) => {
          let data=value.classify.filter(item=>item.device && item.device.wd_id)
          if(data.length > 0) {
              disnableCard=false
          }
      })
      if(disnableCard) {
        alert('선택된 유선상품이 없습니다!')
      }else {
          this.setState({
              indexClassify: indexClassify,
              isShowModalDiscountCart: true,
          })
      }

  }

  closeModalDiscountCart = () => {
    this.setState({
      indexClassify: null,
      isShowModalDiscountCart: false,
    })
  }

  isSubmit = (data) => {
      let itemData = {
          ...data.itemData,
          textCondition : data.textCondition
      }

    this.props.change('WiredAdviceStep2.discountCardPrice', (data.total || 0))
    this.props.change('WiredAdviceStep2.discountCard', (itemData || {}))
    this.closeModalDiscountCart();
  }

  render() {
    const {WiredAdvice: {WiredAdviceStep2}} = this.props

      let disnableCard=true
      WiredAdviceStep2.classify.map((value, key) => {
          let data=value.classify.filter(item=>item.device && item.device.wd_id)
          if(data.length > 0) {
              disnableCard=false
          }
      })

      let textCondition = '';
    if(WiredAdviceStep2.discountCard && WiredAdviceStep2.discountCard.dcc_id && WiredAdviceStep2.discountCard.textCondition && !disnableCard) {
        textCondition = WiredAdviceStep2.discountCard.textCondition
    }


    return (
      <Fragment>

        <div className="form-group mt-4">
          <label className="label-text">할인카드</label>
          <button
            className="btn btn-input-form mb-0"
            type="button"
            onClick={this.showModalDiscountCart}
            // disabled={WiredAdviceStep2.classify[0].classify.length > 0 && WiredAdviceStep2.classify[0].classify[0].device.wd_id ? false : true}
            style={disnableCard ? {opacity: '.65'} : {}}
            // disabled={disnableCard}
          >
            {(WiredAdviceStep2?.discountCard?.dcc_name || '미적용')}
          </button>
        </div>

        <div className="total-prices flex-form">
            <h4 className="title-form mb-0">
              <span>
                {textCondition ? `전월 실적 ${textCondition}` : ''}
              </span>
            </h4>
            <p className="prices-form mb-0">
              {WiredAdviceStep2.discountCardPrice ? '-'+priceFormat(WiredAdviceStep2.discountCardPrice) +'': '0'}
              <span className="price-sub">원</span>
            </p>
        </div>

        <OpenPopupDiscountCard
          isOpenDiscountCard={this.state.isShowModalDiscountCart}
          openPopupDiscountCard={this.closeModalDiscountCart}
          discountCard={this.state.discountCard}
          isSubmit={this.isSubmit}
          titleDetail={`${this.props.WiredAdvice.WiredAdviceStep1.code} (유선할인카드표)`}
          type={'wired_discount_card'}
          telcomID={this.props.WiredAdvice.WiredAdviceStep1.telcomID}
        />
      </Fragment>
    );
  }
}

DiscountCart.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  openPopup: PropTypes.func,
  isOpenModal: PropTypes.bool,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
  discountCard: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    discountCard: (data) => dispatch(getDiscountCard(data)),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(DiscountCart);
