import * as uiTypes from './../actions';
import ChannelService from './../../utils/ChannelService';

export const showLoading = () => ({
  type: uiTypes.SHOW_LOADING,
});

export const hideLoading = () => ({
  type: uiTypes.HIDE_LOADING,
});

export const showSidebar = () => {
    // window.$(".wrapper").addClass("body-menu");
    // window.$(".menu-wrapper").addClass("active-menu");
    window.$(".list-menu").scrollTop(0);
    // // window.$('body').on('touchstart touchmove scroll', function(e){
    // //     if (e.target.id !== 'list-menu') {
    // //         e.preventDefault();
    // //     }
    // // });
    return ({
        type: uiTypes.SHOW_SIDEBAR,
    })
};

export const hideSidebar = () => {
    // const targetElement = document.querySelector('#list-menu');
    window.bodyScrollLock.clearAllBodyScrollLocks();
  
    // window.$(".menu-wrapper").removeClass("active-menu");
  return ({
    type: uiTypes.HIDE_SIDEBAR,
  })
};

export const renderButtonChat = (user = null) => async dispatch => {
  ChannelService.shutdown(); // Shutdown Channel

  let pathName = window.location.pathname;

  if(pathName != "/share-wireless" && pathName != "/share-wired"){
    if (user == null) {
      // Boot Channel as an anonymous user
      /*
      ChannelService.boot({
        "pluginKey": process.env.REACT_APP_CHANNEL_IO_KEY //please fill with your plugin key
      });
      */
  
      const ipData = await fetch('https://geolocation-db.com/json/');
      const locationIp = await ipData.json();
  
      ChannelService.boot({
        "pluginKey": process.env.REACT_APP_CHANNEL_IO_KEY, //please fill with your plugin key
        "member": true,
        "memberId": locationIp.IPv4 + "_unlogin",  
        "profile": {
          "name": '', //fill with user name
          "email": '', //fill with user email
          "mobileNumber": '000-0000-0000' //fill with user phone number
        },
        "unsubscribed": false,
        "locale": "ko"
      });
    }
    else {
      // Boot Channel as a registered user
      ChannelService.boot({
        "pluginKey": process.env.REACT_APP_CHANNEL_IO_KEY, //please fill with your plugin key
        "member": user ? true : false,
        "memberId": user?.username ?? null,  
        "profile": {
          "name": user?.name ?? '', //fill with user name
          "email": user?.email ?? '', //fill with user email
          "mobileNumber": user?.phone ?? '' //fill with user phone number
        },
        "unsubscribed": false,
        "locale": "ko"
      });
    }
  }
  /*
  window.ChannelIO('onShowMessenger', function (){
    //크롬일 경우 isChrome에는 Chrome이라는 문자의 위치 값이 반환되고 크롬이 아닐경우는
    //-1이 반환된다. 나머지도 동일
    var userAgent = window.navigator.userAgent.toLowerCase();
    var isChrome  = userAgent.indexOf("chrome");
    var isIphone  = userAgent.indexOf("iphone");
    var isAndroid = userAgent.indexOf("android");
    var isEdge    = userAgent.indexOf("edge");
    var isIE      = userAgent.indexOf("trident");
    var url       = "https://mobisell3.channel.io/home?MID=";

    if (isIphone > -1) {
      window.open(url + process.env.REACT_APP_CHANNEL_IO_KEY, "channel", "width=373, height=748,status=yes,scrollbars=no,resizable=no");
    }
    else if (isAndroid > -1) {
      window.open(url + process.env.REACT_APP_CHANNEL_IO_KEY, "channel", "width=373, height=748,status=yes,scrollbars=no,resizable=no");
    }
    else if (isChrome > -1) {
      if (isEdge > -1) { //Edge는 Chrome과 Edge 모두의 값을 가지고 있기 때문에
        alert("IE 에서 채널톡 상담지원이 되지 않습니다.");
      }
      else {
        window.open(url + process.env.REACT_APP_CHANNEL_IO_KEY, "channel", "width=373, height=748,status=yes,scrollbars=no,resizable=no");
      }
    }
    else {
      alert("IE 에서 채널톡 상담지원이 되지 않습니다.");
    }

    var intervalId = setInterval(function (){
      var chPlugInScript = document.getElementById("ch-plugin-script");

      if(chPlugInScript != null){
        window.ChannelIO('hideMessenger');

        chPlugInScript.setAttribute('style', 'height:0;width:0;border:0;border:none;visibility:hidden;');

        clearInterval(intervalId);
      }
    }, 5);
  });
  */


  // // if (process.env.REACT_APP_BLOCK_FEATURE !== 'true' && !user) {
  // // if (!user) {
  // //   user = {
  // //     username: "mobifone",
  // //     name: "김곗돈",
  // //     email: "bada4na2@gmail.com",
  // //     phone: "010-2879-3258",
  // //   }
  // // }
  // console.log(process.env.REACT_APP_CHANNEL_IO_KEY)
  // window.ChannelIO('boot', {
  //   "pluginKey": process.env.REACT_APP_CHANNEL_IO_KEY,
  //   "member": user ? true : false,
  //   "memberId": user?.username ?? null,  
  //   // "locale": "ko",
  //   "profile": {
  //     "name": user?.name ?? '', //fill with user name
  //     "email": user?.email ?? '', //fill with user email
  //     "mobileNumber": user?.phone ?? '', //fill with user phone number
  //     //   "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
  //     //   "CUSTOM_VALUE_2": "VALUE_2"
  //   },
  // },
  //   function onBoot(error, user) {
  //     if (user) {
  //       window.ChannelIO('updateUser', {
  //         language: 'ko',
  //       }, function onUpdateUser(error, user) {
  //         if (error) {
  //         } else {
  //         }
  //       })
  //     }
  //   });
};
