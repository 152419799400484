import React from 'react';
import {Modal} from 'reactstrap';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
class OpenPopupTextDetail extends React.Component{
    render(){
        return(
            <Modal
                className={'modal-dialog-centered modal-custom-242'}
                // backdrop={"static"}
                isOpen={this.props.isOpenPopupTextDetail}
                toggle={this.props.openPopupTextDetail}
            >
                <div className="modal-header">
                    <h5 className="modal-title flex-header">
                        카드혜택
                    </h5>
                    <button
                     type="button"
                     className="close close-modal"
                      onClick={this.props.openPopupTextDetail}
                      >
                      <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <div className="modal-body px-0">
                    <article className="mb-4">
                        {this.props.note ? <p className="color-orange" style={{ fontSize : '15px' }}>*할인은 카드 청구금액에서 적용 됩니다.</p> : ''}
                        {/*<p>*/}
                        {/*</p><p className="label-text opacity-disabled text-left text-retrieval"> *통신요금 할인은 카드 값에서 할인이 됩니다.</p>*/}
                        <div dangerouslySetInnerHTML={{__html: this.props.dataTextDetail ? this.props.dataTextDetail :''}}/>
                        <p style={{ fontSize : '15px' }}>*위 내용은 카드사 및 제휴사에 따라 변경이 될 수 있으며 ,<br/>
                            할인 혜택 및 상세제공조건에대해서는 카드사 홈페이지 및 통신사 정보를 확인하셔야 됩니다.</p>
                    </article>
                </div>
            </Modal>
        )
    }
}

export default OpenPopupTextDetail;
