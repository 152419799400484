
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import UsedProgramMainPopup from './UsedProgramMainPopup';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    getListUsedPrograms,
} from './../../../store/actions/dataStore';


class WirelessUsedPhoneCompensation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenMain: false,
            isDisable: !(+props.WirelessAdvice?.WirelessAdviceStep3?.used_program_count)
        }
    }

    componentDidMount() {
        let { WirelessAdviceStep1, WirelessAdviceStep2, WirelessAdviceStep3 } = this.props.WirelessAdvice;
        let data = {
            telcomID: WirelessAdviceStep1.telcomID,
            modelID: WirelessAdviceStep3.phone.modelID ? WirelessAdviceStep3.phone.modelID : WirelessAdviceStep3.phone.id,
            //  telcomID: 18,
            //  modelID: 34
            // telcomID: 4,
            // modelID: 25
        }
        this.props.getListUsedPrograms(data).then((res) => {
            if (res && res.length > 0) {
                this.setState({
                    ...this.state,
                    isDisable: false,
                })
            } else {
                this.setState({
                    ...this.state,
                    isDisable: true,
                })
            }
        }).catch(err => {
        })

        setTimeout(() => {
            this.props.change('WirelessAdviceStep3.applyUsedProgram', null);
            this.props.change('WirelessAdviceStep3.usedPhoneCompensation', null);
            // this.props.change('WirelessAdviceStep3.optionFee',1);
            // setTimeout(() => {
            // })
        })
    }

    // componentDidUpdate(prevProps) {
    // }

    showPopupUsedProgramMain = () => {
        this.setState({
            isOpenMain: !this.state.isOpenMain,
        }, () => {
        })
    }


    onToggleMain = () => {
        this.setState({
            isOpenMain: !this.state.isOpenMain,
        })
    }

    render() {
        const { WirelessAdviceStep3 } = this.props.WirelessAdvice;

        return (
            <div className="form-group row form-group-mobile">
                <label style={{whiteSpace: 'break-spaces'}} htmlFor="staticEmail" className="col-sm-4 col-form-label label-mobile label-text" onClick={this.onChangeText}>
                    {WirelessAdviceStep3.phone?.telcom_code === 'lgu' ? '중고폰보상\n프로그램\n/유예할부' : '중고폰보상\n프로그램'}
                </label>
                <div className="col-sm-8 right-btn-mobile">
                    <button className={`btn ${this.state.isDisable ? 'form-control cursor-text color-bg-gray' : (WirelessAdviceStep3?.usedPhoneCompensation?.tup_id ? '' : 'opacity-disabled')} btn-input-form mb-0 btn-devices pr-2`}
                        type="button" disabled={this.state.isDisable ? 'disabled' : ''}
                        onClick={() => this.showPopupUsedProgramMain()}>
                        {WirelessAdviceStep3.usedPhoneCompensation && WirelessAdviceStep3.usedPhoneCompensation.tup_id ? WirelessAdviceStep3.usedPhoneCompensation.tup_name : '미적용'}
                    </button>
                    <UsedProgramMainPopup
                        isOpen={this.state.isOpenMain}
                        toggle={this.onToggleMain}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        usedPrograms: state.dataStore.usedProgram,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => () => dispatch(change()),
        getListUsedPrograms: (data) => dispatch(getListUsedPrograms(data)),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessUsedPhoneCompensation);
