
import PropTypes from 'prop-types';
import React, { Component, Fragment,useState  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WelfareDiscountnModal from "./WelfareDiscountModal"
import "./../Promotion/promotion.scss"
import {priceFormat} from "./../../../utils/helper"
import {chooseOption2G, numberMathFloor, optionWelfare, roundUpNumber, roundUpNumberPlan} from "../../../utils/helper";
import {TELCOM_ID} from "../../../utils/constant";

const colorPrice={color:'#2F8BFF',fontWeight:'bold'}

class WelfareDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false,
            stateChangeProp:false,
        }
    }

    componentDidUpdate(prevProps) {


        var combinedDiscount=0
        if(this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
            combinedDiscount = roundUpNumber(+this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price +
                +this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv)
        }else {
            combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
        }
        combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1))

        var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
        var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
        var valueBasicCost=0
        if(basicCostCalc != 0) {
            valueBasicCost=basicCostCalc
        }else {
            valueBasicCost=roundUpNumberPlan(basicCost)
        }
        var dataSkt = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        var telcom = this.props.WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
        var dataTelcom=""
        if(dataSkt) {
            dataTelcom=dataSkt.toUpperCase()
        }
        if(telcom != null) {
            dataTelcom=telcom.toUpperCase()
        }


        var promotionCost=this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
        var optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement
        var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
        var optionFee=this.props.WirelessAdvice.WirelessAdviceStep3.optionFee
        var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ?
            this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

        if(this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G == null && optionFee == 1) {
            optionalAgreement = 0;
        }

        var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2)
        if(parseInt(dataCostWelfareDiscount) > 0){
            dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount
        }else {
            dataCostWelfareDiscount=0
        }
        dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1))

        var id=prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
        let conversion2G=prevProps.WirelessAdvice.WirelessAdviceStep3?.conversion2G?.id

        var optionWelfareValue = optionWelfare(basicCost,valueBasicCost,id,optionalAgreement,rateAgreement,promotion,combinedDiscount,dataTelcom,optionFee,conversion2G);

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee?.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee?.id) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G != null &&
                this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6 && dataTelcom == 'LGU+') {
                    this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount})
                }else {
                    if(id){
                        this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                    }
                }
            }else {
                if(id){
                    this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                }
            }

        }
        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.promotion !== prevProps.WirelessAdvice.WirelessAdviceStep3.promotion) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G != null &&
                this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6 && dataTelcom == 'LGU+') {

                    this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount})
                }else {
                    if(id){
                        this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                    }
                }
            }else {
                if(id){
                    this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                }
            }
        }

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount !== prevProps.WirelessAdvice.WirelessAdviceStep3.combinedDiscount) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G != null &&
                this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6 && dataTelcom == 'LGU+') {

                    this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)',
                        cost:dataCostWelfareDiscount})
                }else {
                    if(id){
                        this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                    }
                }
            }else {
                if(id){
                    this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                }
            }
        }

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && this.props.WirelessAdvice.WirelessAdviceStep3.discostChange !==
            prevProps.WirelessAdvice.WirelessAdviceStep3.discostChange) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G != null && this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6 && dataTelcom == 'LGU+') {

                    var optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G ?
                        (this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G) : this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault // truong hop lgu cho option 6

                    let dataSkt = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
                    let telcom = this.props.WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
                    let nameTelcom=""
                    if(dataSkt) {
                        nameTelcom=dataSkt.toUpperCase()
                    }
                    if(telcom != null) {
                        nameTelcom=telcom.toUpperCase()
                    }
                    let data2g={id: 1, value: '적용'}
                    var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement) / 2)
                    dataCostWelfareDiscount= dataCostWelfareDiscount > 12100 ? 12100 : dataCostWelfareDiscount
                    dataCostWelfareDiscount = parseInt(dataCostWelfareDiscount) > 0 ? (dataCostWelfareDiscount) : 0
                    let dataChoose=chooseOption2G(data2g,{...this.props.WirelessAdvice.WirelessAdviceStep3},nameTelcom,{id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)',
                        cost:dataCostWelfareDiscount})
                    var dataCostWelfareDiscountAfter2G = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - dataChoose.optionalAgreement) / 2)
                    dataCostWelfareDiscountAfter2G= dataCostWelfareDiscountAfter2G > 12100 ? 12100 : dataCostWelfareDiscountAfter2G
                    dataCostWelfareDiscountAfter2G = parseInt(dataCostWelfareDiscountAfter2G) > 0 ? (dataCostWelfareDiscountAfter2G) : 0

                    this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscountAfter2G})
                }else {
                    if(id){
                        this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                    }
                }
            }else {
                if(id){
                    this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                }
            }
        }

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.optionFee !== prevProps.WirelessAdvice.WirelessAdviceStep3.optionFee){
            if(id){
                this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
            }
        }

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement !== prevProps.WirelessAdvice.WirelessAdviceStep3.rateAgreement){
            if(id){
                this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
            }
        }

        if(dataTelcom && this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement !== prevProps.WirelessAdvice.WirelessAdviceStep3.optionalAgreement){
            var id=prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
            if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6){
                if(id){
                    this.props.change('WirelessAdviceStep3.welfareDiscount',optionWelfareValue)
                }
            }
        }

        document.getElementById("btn-text-welfared").classList.remove("btn-secondary")
    }

    buttonOnClick = () => {
        this.openPopup(false);
    }

    openPopup = (isValue = true) => {
        if(isValue){
            this.setState({isOpenModal: false});
        }
        else{
            this.setState({isOpenModal: true});
        }
        //this.setState({isOpenModal: !this.state.isOpenModal})
    }

    componentDidMount(){
        document.getElementById("btn-text-welfared").classList.remove("btn-secondary")
    }

    render() {
        const { handleSubmit, WirelessAdvice } = this.props;
        
        let welfare=WirelessAdvice.WirelessAdviceStep3.welfareDiscount;

        var costNumber=0
        if(welfare && welfare.cost) {
             costNumber=Number(welfare.cost)
        }
        let dataTelcom = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        if(dataTelcom) {
            dataTelcom=dataTelcom.toUpperCase()
        }
        let telcom_id=WirelessAdvice.WirelessAdviceStep1.telcomID
        costNumber=parseInt(parseFloat(costNumber).toFixed(1))
        return (
            <div className="form-group row align-items-start">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mt-3">복지 할인
                </label>
                <div className="col-sm-8">
                    <Button
                        // disabled={dataTelcom == 'SKT' || dataTelcom == 'KT' || dataTelcom == 'LGU+' ? false : true}
                        disabled={telcom_id == TELCOM_ID.SKT || telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU ? false : true}
                        style={(welfare && welfare.cost ? colorPrice : {})}
                        id="btn-text-welfared"                        
                        className={'btn btn-input-form mb-0 ' + (welfare && welfare.cost ? 'btn-prices-wp' : (welfare?.id == 6 ? 'btn-prices-wp' : ''))}
                        //onClick={this.openPopup}
                        onClick={this.buttonOnClick}
                    >
                        {/*{welfare && welfare.cost ? ("-" + (parseInt(costNumber) < 0 ? priceFormat(Math.abs(parseInt(costNumber))) : priceFormat(costNumber))+"원") : "미적용"}*/}
                        {welfare && welfare.cost ? ("-" + (parseInt(costNumber) < 0 ? priceFormat(Math.abs(parseInt(costNumber))) : priceFormat(numberMathFloor(costNumber)))) : (welfare?.id == 6 ? 0 :'')}
                        {welfare && welfare.cost ? '원' : (welfare?.id == 6 ? '원':<span className='opacity-disabled'>미적용</span>)}
                    </Button>
                    <p className="text-right mb-0 small-color">{welfare && welfare.value ? welfare.value : ''}</p>
                    <WelfareDiscountnModal
                        stateChangeProp={this.state.stateChangeProp}
                        isOpenModal={this.state.isOpenModal}
                        openPopup={this.openPopup}
                    />
                </div>
            </div>
        );
    }
}

WelfareDiscount.propTypes = {
    WirelessAdvice: PropTypes.object,
    change: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
     withReduxForm,
)(WelfareDiscount);
