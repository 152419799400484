
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {  compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues } from 'redux-form';
import { priceFormat } from "../../../utils/helper";
import ConversionModal from "./ConversionModal";

class Conversion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disclosure : ""
    }
  }

  /**
   * 미적용버튼 클릭시 호출되는 메소드
   */
  UnappliedOnClick = () => {
    const { WirelessAdvice } = this.props;
    let conversionPrice      = +WirelessAdvice.WirelessAdviceStep3.conversion_price;
    let conversionPriceInput = +WirelessAdvice.WirelessAdviceStep3.conversion_price_input;

    if(conversionPriceInput != 0){
      this.disclosureProps(0);
    }
    else{
      this.disclosureProps(conversionPrice);
    }
  }

  handleResetValue = (e) => {
    e.preventDefault();
    const { WirelessAdvice } = this.props;
    let installmentPrincipal = +WirelessAdvice.WirelessAdviceStep3.installmentPrincipal;
    let conversionPrice      = +WirelessAdvice.WirelessAdviceStep3.conversion_price;
    let conversionPriceInput = +WirelessAdvice.WirelessAdviceStep3.conversion_price_input;

    installmentPrincipal = installmentPrincipal + conversionPriceInput;

    if(conversionPrice > installmentPrincipal){
      conversionPriceInput = installmentPrincipal;
    }
    else{
      conversionPriceInput = conversionPrice;
    }
  }

  disclosureProps = (conversion) => {
    const { WirelessAdvice }    = this.props;
    let conversionPriceInputAdd = WirelessAdvice.WirelessAdviceStep3.conversion_price_input_add

    this.props.change('WirelessAdviceStep3.conversion_price_input', conversion);

    if (WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange != null) {
      this.props.change('WirelessAdviceStep3.converseDifference',+conversion - +WirelessAdvice.WirelessAdviceStep3.converseDifferenceDefault);
    }

    if(+WirelessAdvice.WirelessAdviceStep3.conversion_price_input_add_type == 0){
      conversionPriceInputAdd = 0;
    }
    else if(+WirelessAdvice.WirelessAdviceStep3.conversion_price_input_add_type == 15){
      conversionPriceInputAdd = +conversion * 15 / 100;
    }
    else{
      if(conversionPriceInputAdd > (+conversion * 15 / 100)){
        conversionPriceInputAdd = 0;

        alert("공시지원금의 최대 15%까지만 적용가능합니다.\n전환추가지원금이 초기화 됩니다.");
      }
    }

    this.props.change('WirelessAdviceStep3.conversion_price_input_add', conversionPriceInputAdd);
  }

  render() {
    const { WirelessAdvice } = this.props;
    let conversionPriceInput    = WirelessAdvice.WirelessAdviceStep3.conversion_price_input;
    let manualConversionConfirm = WirelessAdvice.WirelessAdviceStep3.manual_conversion_confirm;
    let conversionApplyDate     = WirelessAdvice.WirelessAdviceStep3.conversion_apply_date;
    let styleNumber             = "";
    let disabled                = "";
    
    if(WirelessAdvice.WirelessAdviceStep3.optionFee == 0) {
      conversionPriceInput = 0;
      disabled             = "disabled";
    }
    else if(manualConversionConfirm == 1){
      conversionPriceInput = 0;
      disabled             = "disabled";
    }
    
    if (conversionPriceInput != 0) {
      styleNumber = "btn-prices-wp";
    }

    conversionApplyDate = conversionApplyDate ? conversionApplyDate : "";

    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전환지원금</label>
          <div className="col-sm-8">
            <table style={{width : '100%'}}>
              <tr>
                <td style={{width : '50%'}}>
                  <button className={`btn btn-input-form mb-0 ${styleNumber}`} type="button" disabled={ disabled } data-toggle="modal" data-target="#modal-conversion">
                    { manualConversionConfirm == 1 ?
                        '확인불가' : 
                        <Fragment>
                          { (conversionPriceInput != "0" ? '-' : '') + priceFormat(conversionPriceInput) }
                          <span>원</span>
                        </Fragment>
                        
                    }
                  </button>
                </td>
                <td className="d-flex justify-content-center">
                  <button
                    class="btn btn-gray-white"
                    style={{width : '95%', height : '44px'}}
                    type="button"
                    disabled={ disabled }
                    onClick={this.UnappliedOnClick}
                  >
                    { conversionPriceInput != "0" ? "미적용" : "적용" }
                  </button>
                </td>
              </tr>
            </table>
            {manualConversionConfirm == 1 ?
              "" :
              conversionApplyDate != "" ?
              (
                <p class="text-left mb-0 small-color">
                  {"(" + WirelessAdvice.WirelessAdviceStep3.conversion_apply_date + " 기준)"}
                </p>
              ) :
              ""
            }
          </div>
        </div>

        <ConversionModal 
          conversion = {conversionPriceInput}
          disclosureProps = {this.disclosureProps}
          WirelessAdvice = { WirelessAdvice }
        />
      </Fragment>
    );
  }
}

Conversion.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {}
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(Conversion);
