import React from 'react';
import {Modal} from 'reactstrap';
import {change, getFormSyncErrors, getFormValues, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {compose} from "redux";

import  ModalPrintRoom from './../ModalPrint'


import api from './../../utils/api'
import PrintPageWired from "./PrintPageWired";


class ModalPrint extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      modalPrint : false,
      modalPrintPage : false
    }
  }


  // typePrint = (type) => {
  //   if(this.props.auth.role == "Member" && this.props.WiredAdvice.id) {
  //     let data = {
  //       swec_consultant_id : this.props.WiredAdvice.id,
  //       swec_type : window.currentDevice == 'web' ? 0 : 1,
  //       swec_uid : this.props.auth.user.id,
  //       swec_type_export : type
  //     }
  //     api.consultant.createStatisticWiredConsultantExport(data)
  //   }
  // }

  print = (check = false) => {
    if(check){
      this.setState({
        modalPrint : !this.state.modalPrint
      },()=> {
        // if(this.state.modalPrint) {
          // this.typePrint(1)
        // }
      })
    }else{
      alert('선택된 상품이 없습니다.');
    }

  }

  printPage = () => {
    this.setState({
      modalPrintPage : !this.state.modalPrintPage
    },()=>{
      // if(this.state.modalPrint){
        // this.typePrint(0)
      // }
    })

  }


  render(){
    const {WiredAdvice} = this.props;

    let check = false;
    WiredAdvice.WiredAdviceStep2.classify.map(item => {
      if(item.classify.length > 0)  {
        item.classify.map(item1 => {
          if(item1.device != null && Object.keys(item1.device).length > 0) {
            check = true;
          }
        })
      }
    })

    return (
      <React.Fragment>
        <Modal
          className={'modal-dialog modal-dialog-centered modal-lg modal-print'}
          // backdrop={"static"}
          toggle={this.props.toggle}
          isOpen={this.props.isOpen}
        >

            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="close close-modal"
                // data-dismiss="modal"
                // aria-label="Close"
                onClick={this.props.toggle}
              >
             <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
              </button>
            </div>
            <div className="modal-body mt-4 mb-3">
              <h5 className="title">출력</h5>
              <div className="button-print">
                <button
                  className="btn btn-print"
                  onClick={this.printPage}
                >
                  <img src="/images/print-icon.svg" alt="" />
                  <span>서식지 출력하기</span>
                </button>
                <button
                  className="btn btn-print"
                  // id={'btn-print-wired-product'}
                  // data-print-content={process.env.REACT_APP_API_URL+'wired-product-print?id='+WiredAdvice.id}
                  onClick={()=>this.print(check)}
                  // data-check-content={check}
                >
                  <img
                    src="/images/print-icon.svg"
                    alt=""
                    // id={'btn-print-wired-product-img'}
                  />
                  <span>견적서 출력하기</span>
                </button>
              </div>
            </div>

        </Modal>

        <ModalPrintRoom
          isOpen={this.state.modalPrint}
          toggle={this.print}
          linkAdress={process.env.REACT_APP_API_URL+'wired-product-print?id='+WiredAdvice.id}
          discountCardID={WiredAdvice?.WiredAdviceStep2?.discountCard?.dcc_id}
          //discountCardID={WirelessAdvice.WirelessAdviceStep3.discountCardId}
          telcomID={WiredAdvice.WiredAdviceStep1.telcomID}
          idButton={'btn-print-wired-product'}
          check={check}
          popupOutSide={this.props.toggle}
        />


        <PrintPageWired
          isOpen={this.state.modalPrintPage}
          toggle={this.printPage}
          network={WiredAdvice.WiredAdviceStep1.telcomID}
          consultantId={WiredAdvice.id}
          toggleParent={this.props.toggle}
          
        />

      </React.Fragment>

    )
  }
}

const mapStateToProps = state => {
  return {
    auth : state.auth,
    WiredAdvice: getFormValues('WiredAdvice')(state),
    // WirelessAdvice: getFormValues('WirelessAdvice')(state),
    // WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default compose(
  withConnect,
  withReduxForm,
)(ModalPrint);
