import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import api from './../utils/api';
import { updateWirelessStep3, caclWirelessStep3} from './../utils/helper'
import { showLoading, hideLoading } from './../store/actions/ui';
import {connect} from "react-redux";
import {compose} from "redux";
import ShareWirelessLeft from "../components/Wireless/ShareWireless/ShareWirelessLeft";
import ShareWireLessRight from "../components/Wireless/ShareWireless/ShareWireLessRight";
import ShareWirelessMain from "../components/Wireless/ShareWireless/ShareWirelessMain";

class ShareWireless extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      data : {}
    }
  }

  componentDidMount() {


    //get API

    let list2g = [
      "미적용",
      '적용',
      '무약정',
    ]

    let url = this.props.location.search
    let params = queryString.parse(url);
    if(params && params.id){
      this.props.showLoading();

      api.advice.getConsultant(params.id).then(res => {
        this.props.hideLoading();
        const { data } = res.data;

        console.log("data getConsultant", data);

        let WirelessAdviceStep3Clone = updateWirelessStep3({}, data)
        WirelessAdviceStep3Clone.interestRate = data.telcom.installment_rate

        if(WirelessAdviceStep3Clone.soldierDiscount != null && +WirelessAdviceStep3Clone.soldierDiscount > 0){
          WirelessAdviceStep3Clone.soldierSkt = 1;
          WirelessAdviceStep3Clone.soldierLguplus = 1;
          WirelessAdviceStep3Clone.soldierKt = 1;  
        }

        let wirelessAdviceStep3 = caclWirelessStep3(data.telcom.installment_payment_method, WirelessAdviceStep3Clone, data.telcom.recipe_days_remaining)
        wirelessAdviceStep3.telcom = data.telcom
        wirelessAdviceStep3.color = data.consultant_wireless.device_color
        wirelessAdviceStep3.updated_at = data.updated_at
        wirelessAdviceStep3.conversion_2g_text = list2g[data.consultant_wireless.conversion_2g]
        wirelessAdviceStep3.estimated_rates_changing_plans = data.consultant_wireless.estimated_rates_changing_plans
        wirelessAdviceStep3.device_insurance = data.consultant_wireless.device_insurance
        wirelessAdviceStep3.plan_change = data.consultant_wireless.plan_change;
        wirelessAdviceStep3.disclosureSubsidyType = data.consultant_wireless.basic_cost_type;

        if (wirelessAdviceStep3?.usedPhoneCompensation?.tup_id) {
          wirelessAdviceStep3.installmentFee              = +data.consultant_wireless.installment_fee;
          wirelessAdviceStep3.installmentMonthlyFee       = +data.consultant_wireless.installment_monthly_fee;
          wirelessAdviceStep3.installmentMonthly          = +data.consultant_wireless.installment_monthly;
          wirelessAdviceStep3.installmentMonthlyPrincipal = +data.consultant_wireless.installment_principal;
          wirelessAdviceStep3.amountMonthlyDevice         = +data.consultant_wireless.amount_monthly_device;
          wirelessAdviceStep3.amountMonthlyTotal          = +data.consultant_wireless.amount_monthly_total;
          wirelessAdviceStep3.estimatedAmountMonth        = +data.consultant_wireless.estimated_amount_month;

          if (wirelessAdviceStep3?.usedPhoneCompensation?.ets_id == null) {
            wirelessAdviceStep3.installmentPrincipal        = 0;
            wirelessAdviceStep3.installmentFee              = +data.consultant_wireless.inst_monthly_defer_1 * 6;
            wirelessAdviceStep3.installmentMonthlyPrincipal = 0;
            wirelessAdviceStep3.installmentMonthlyFee       = +data.consultant_wireless.inst_monthly_defer_1;
          }
        }

        //let installmentMonth = +wirelessAdviceStep3?.installmentMonth;

        // 0221 할부 수수료 작업
        //if(+wirelessAdviceStep3?.phone?.telcomID == 18 && (installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3)){
        //  wirelessAdviceStep3.installmentFee = 0;
        //}
        //

        wirelessAdviceStep3.conversionDb = data.conversionDb;

        this.setState({
          data : wirelessAdviceStep3
        })

      }).catch(e => {
        console.log(e.response)
        this.props.hideLoading();
        this.props.history.push('/sign-in?id='+params.id)

      })
    }


  }



// no-cursor

  render(){

    return (
      <React.Fragment>
        <div className="main-wrapper">
          <section className="wireless-advice " id="wireless-advice">
            <div className="container">
              {Object.keys(this.state.data).length > 0 &&  <ShareWirelessMain
                data={this.state.data}
              /> }

            </div>
          </section>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    // ForgotId: getFormValues('ForgotId')(state),

  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);



export default compose(
  withConnect,
  // withReduxForm,
)(ShareWireless);

