 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { TELCOM_ID } from "../../../utils/constant";

class WirelessContractMonth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: 24
    }
  }
  
  changeMonth = (data) => {
    const { handleSubmit, WirelessAdvice } = this.props
    this.setState({active: data}, () => {
      this.props.change('WirelessAdviceStep3.contractMonth', data);
      // this.props.change('WirelessAdviceStep3.optionalAgreement', data*WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault);
    });
  }
  render() {
    const { WirelessAdvice } = this.props;

    let telcom_id = WirelessAdvice.WirelessAdviceStep1.telcomID;
      
    return (
      <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text"> </label>
        <div className="col-sm-8">
          <ul className="list-month">
            <li className={this.state.active == 12 ? 'active' : ''}>
              <button className="btn" type="button" onClick={this.changeMonth.bind(this, 12)}>12개월</button>
            </li>
            {telcom_id == TELCOM_ID.SKT || telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU ?
              <li className={this.state.active == 23 ? 'active' : ''}>
                <button className="btn" type="button" onClick={this.changeMonth.bind(this, 23)}>12개월 + 12개월</button>
              </li>
              :
              ""
            }
            <li className={this.state.active == 24 ? 'active' : ''}>
              <button className="btn" type="button" onClick={this.changeMonth.bind(this, 24)}>24개월</button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

WirelessContractMonth.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessContractMonth);
