 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import ExtraServiceModal from "./ExtraServiceModal";
import ExtraServiceList from "./ExtraServiceList";

class WirelessExtraServiceAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    }
  }

  showModal = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    return (
        <div>
          <div className="row">
            <div className="col-md-5 pr-0">
              <div className="form-group">
                {/* <label className="col-form-label label-text text-indents">유심</label> */}
                <button className="btn btn-service" data-toggle="modal"
                        data-target="#modal-15" onClick={this.showModal} disabled={this.state.hide}>
                  <span>+</span>
                  서비스 추가
                </button>
                <ExtraServiceModal modal={this.state.modal}  toggleModal = {this.showModal} />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

WirelessExtraServiceAdd.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessExtraServiceAdd);
