import MessageJSON from "../../../utils/message.json";

const validate = values => {
    const errors = {};
    const { username, password } = values;
    if (!username) {
        errors.username = MessageJSON.login.validate.username_required;
    } else if (username.trim() && username.length < 4) {
        errors.username = MessageJSON.login.validate.username_min;
    } else if (username.trim() && username.length > 10) {
        errors.username =  MessageJSON.login.validate.username_max;
    }

    if (!password) {
        errors.password = MessageJSON.login.validate.repassword_required;
    } else if (password.trim() && password.length < 6) {
        errors.password = MessageJSON.login.validate.repassword_min;
    } else if (password.trim() && password.length > 14) {
        errors.password = MessageJSON.login.validate.repassword_max;
    }
    return errors;
};

export default validate;
