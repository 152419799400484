import React from 'react';
import { ReactComponent as IconSkype } from './../../assets/images/skype.svg';

class Skype extends React.Component{

  componentDidMount() {
    let btn = document.getElementById('share-skype-button')
    btn.setAttribute('data-href', this.props.sharelink)
    window.skype(window, document, 'script');
  }


  clickShareSkype = () => {
    let btn = document.getElementById('share-skype-button')

    btn.click()
  }

  render() {
    return (
      <div className="box_social" onClick={this.clickShareSkype}>
        <a >
          <IconSkype />
        </a>
        <span>Skype</span>
      </div>
    )
  }
}


export default Skype;
