import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
 

const renderCheckboxField = ({ input, label, type, checked, className, id, placeholder, error, defaultChecked, touched, invalid }) => {
    let isChecked = checked ? true : (input.value == '1' ? true : false)
    return (
        <Fragment>
        <input
            {...input}
            type={type}
            className={'custom-control-input'}
            onChange={input.onChange} 
            placeholder={placeholder}
            value={input.value}
            id={id}
            checked={isChecked}
            />
    </Fragment>)
  };

renderCheckboxField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderCheckboxField;
