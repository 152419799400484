import MessageJSON from '../../../utils/message';

function ValidateEmail(mail) 
{
    if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

const validate = (values, props)  => {
    const errors = {};
    const { 
        avatar,
        username,
        password,
        repassword,
        sto_name,
        sto_addr_line1,
        sto_addr_line2,
        first_store_phone,
        last_store_phone,
        email,
        name,
        sto_addr_code,
        registration_number,
        oldpassword,
        onChangeRepassword
    } = values;

    if (avatar.size > 3*1024*1024) {
        errors.avatar = MessageJSON.edit_profile.validate.avatar_max;
    }
    if (!username) {
        errors.username = MessageJSON.edit_profile.validate.username_required;
    } else if (username.trim() && username.length < 4) {
        errors.username = MessageJSON.edit_profile.validate.username_min;
    } else if (username.trim() && username.length > 10) {
        errors.username =  MessageJSON.edit_profile.validate.username_max;
    }
    if (!name) {
        errors.name = MessageJSON.edit_profile.validate.name_required;
    } else if (name.trim() && name.length > 64) {
        errors.name = MessageJSON.edit_profile.validate.name_max;
    }
    if(password) {
        if (!password) {
            errors.password = MessageJSON.edit_profile.validate.repassword_required;
        } else if (password.trim() && password.length < 6) {
            errors.password = MessageJSON.edit_profile.validate.repassword_min;
        } else if (password.trim() && password.length > 14) {
            errors.password = MessageJSON.edit_profile.validate.repassword_max;
        }
        if (repassword && password !== repassword) {
            errors.repassword = MessageJSON.edit_profile.validate.repassword_same;
        }
        if (onChangeRepassword && !repassword) {
            errors.repassword = MessageJSON.edit_profile.validate.repassword_same;
        }
    }

    
    if (!sto_name) {
        errors.sto_name = MessageJSON.edit_profile.validate.sto_name_required;
    }  else if (sto_name.trim() && sto_name.length > 64) {
        errors.sto_name = MessageJSON.edit_profile.validate.sto_name_max;
    }
    if (!sto_addr_line1) {
        errors.sto_addr_line1 = MessageJSON.edit_profile.validate.sto_addr_line1_required;
    }if (sto_addr_line1.trim() && sto_addr_line1.length > 64) {
        errors.sto_addr_line1 = MessageJSON.edit_profile.validate.sto_addr_line1_max;
    }
    if (!sto_addr_line2) {
        errors.sto_addr_line2 = MessageJSON.edit_profile.validate.sto_addr_line2_required;
    }if (sto_addr_line2.trim() && sto_addr_line2.length > 64) {
        errors.sto_addr_line2 = MessageJSON.edit_profile.validate.sto_addr_line2_max;
    }
    
    if (!sto_addr_code) {
        errors.sto_addr_code = MessageJSON.edit_profile.validate.sto_addr_code_required;
    }else if (sto_addr_code.trim() && sto_addr_code.length > 64) {
        errors.sto_addr_code = MessageJSON.edit_profile.validate.sto_addr_code_max;
    }

    if (!first_store_phone) {
        errors.first_store_phone = MessageJSON.edit_profile.validate.first_store_phone_required;
    }
    if (!last_store_phone) {
        errors.last_store_phone = MessageJSON.edit_profile.validate.last_store_phone_required;
    } 
    // else if(last_store_phone.length < 9){
    //     errors.last_store_phone =  MessageJSON.signup.validate.verify_phone_number_check_format;
    // }

    if (!email) {
        errors.email = MessageJSON.edit_profile.validate.email_required;
    } else if (email.trim() && email.length > 32) {
        errors.email = MessageJSON.edit_profile.validate.email_max;
    } else if (!ValidateEmail(email)) {
        errors.email = MessageJSON.edit_profile.validate.email_email;
    }

    if (!oldpassword) {
        errors.oldpassword = MessageJSON.edit_profile.validate.oldpassword_required;
    } 

    errors.registration_number = [];
    if(registration_number.length > 0){
        if (registration_number && !registration_number[0]) {
            if (registration_number) {
                errors.registration_number[0] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
                // errors.registration_number[0] = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[0].length < 3) {
            if (registration_number) {
                errors.registration_number[0] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_min;
                // errors.registration_number[0] =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number && registration_number[0].length > 3) {
            if (registration_number) {
                errors.registration_number[0] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_max;
                // errors.registration_number[0] = MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }

        if ( registration_number && !registration_number[1])  {
            if (registration_number) {
                errors.registration_number[1] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[1].length < 2) {
            if (registration_number) {
                errors.registration_number[1] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_min;
            }
        }else if (registration_number && registration_number[1].length > 2) {
            if (registration_number) {
                errors.registration_number[1] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
        if (registration_number && !registration_number[2]) {
            if (registration_number) {
                errors.registration_number[2] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[2].length < 5) {
            if (registration_number) {
                errors.registration_number[2] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_min;
            }
        }else if (registration_number && registration_number[2].length > 5) {
            if (registration_number) {
                errors.registration_number[2] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
    }
    
    return errors;
  };
  
  export default validate;
  