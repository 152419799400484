import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    reduxForm,
    getFormSyncErrors,
    getFormValues,
    change,
} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import {showLoading, hideLoading} from '../../../store/actions/ui';
import moment from 'moment'
import {updateWirelessStep3} from './../../../utils/helper'
import {updateWiredAdviceStep1, updateWiredAdviceStep2} from "../../../utils/helper";

class BookmarkModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
            params: {
                type: 'wired',
                bookmarkWired:'bookmarkWired'
            }
        }
    }

    modalOpened = () => {
        this.props.showLoading()
        api.advice.getListConsultant(this.state.params)
            .then(response => {
                this.props.hideLoading()
                this.setState({
                    consultants: response.data.data
                })
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }

    handleSubmit = () => {
        const {selected} = this.state
        this.props.openPopupBookmark()
        this.props.showLoading()
        api.advice.getConsultant(selected)
            .then(response => {
                const {data} = response.data

                const {WiredAdvice: {WiredAdviceStep1, WiredAdviceStep2}} = this.props
                let WiredAdviceStep1Clone = updateWiredAdviceStep1(WiredAdviceStep1, data)
                let WiredAdviceStep2Clone = updateWiredAdviceStep2(WiredAdviceStep2, data)

                // let customer = {
                //     id: data?.customer_id,
                //     name: data?.customer?.name,
                //     phone: data?.customer?.phone,
                //     note: data?.customer_note,
                // }

                this.props.hideLoading()
                this.props.change('id', data.id)
                this.props.change('WiredAdviceStep1', WiredAdviceStep1Clone)
                this.props.change('WiredAdviceStep2', WiredAdviceStep2Clone)
                // this.props.change('CreatePotentialCustomer', customer)
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    bookmark = (id) => {
        const { handleSubmit, WiredAdvice } = this.props;
        this.props.showLoading()
        if(id) {
            api.advice.bookmarkWired(id)
                .then(response => {
                    this.props.hideLoading()
                    if(response && response.data && response.data.status) {
                        this.setState({
                            consultants: response.data.data
                        })
                        this.props.change('WiredAdviceStep2.bookmark', null)
                    }
                })
                .catch(() => {
                    this.props.hideLoading()
                })
        }

    }


    render() {
        const {consultants} = this.state

        return (
            <Modal
                isOpen={this.props.isOpenModalBookmark}
                toggle={this.props.openPopupBookmark}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-star-review'}
                onOpened={this.modalOpened}
                contentClassName=""
            >

                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            즐겨찾기
                        </h5>
                        <button type="button" className="close close-modal" aria-label="Close"
                                onClick={this.props.openPopupBookmark}>
                         <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body modal-counseling-history">
                        <article>
                            <table className="table table-header">
                            <thead>
                                <tr className="bg-purple">
                                    <th className="text-left">통신사</th>
                                    <th className="text-left">상품명</th>
                                    <th className="text-left">요금제</th>
                                    {/*<th className="text-left">상담날짜/시간</th>*/}
                                    <th className="text-right">즐겨찾기</th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                            <table className="table">
                                <tbody>
                                {consultants.map((consultant, key) => (
                                    <tr key={key}>
                                        <td style={{cursor:'pointer'}}>
                                            <div className="d-flex" style={{cursor:'pointer'}}>
                                                <div className="custom-control custom-radio" style={{cursor:'pointer'}}>
                                                    <input
                                                        type="radio"
                                                        id={`check-00${key}`}
                                                        name="customRadio"
                                                        className="custom-control-input"
                                                        onChange={this.handleChangeRadio}
                                                        value={consultant.id}
                                                    />
                                                    <label className="custom-control-label ml-4" style={{cursor:'pointer'}}
                                                           htmlFor={`check-00${key}`}>
                                                        {consultant.telcom_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{cursor:'default'}}>{consultant.model_name_wired == null ? '인터넷' : consultant.model_name_wired}</td>
                                        <td style={{cursor:'default'}} className="a">{consultant.plan_name_wired == null ? '인터넷 요금제' : consultant.plan_name_wired}</td>
                                        {/*<td >*/}
                                            {/*<b>*/}
                                                {/*{moment(consultant.updated_at).format('YYYY-MM-DD')}*/}
                                            {/*</b>*/}
                                            {/*<span className="color-sliver ml-1">*/}
                                                {/*{moment(consultant.updated_at).format('HH:mm:ss')}*/}
                                            {/*</span>*/}
                                        {/*</td>*/}
                                        <td className="text-right" onClick={() =>this.bookmark(consultant.id)}>
                                            <span>
                                                <i className="fas fa-star color-yellow font-18"></i>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </div>
                        </article>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn-gray pl-5 pr-5" onClick={this.props.openPopupBookmark}>
                            취소
                        </button>
                        <button type="button" className="btn btn-submit pl-4 pr-4" onClick={this.handleSubmit}>
                            불러오기
                        </button>
                    </div>

            </Modal>
        );
    }
}

BookmarkModal.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    openPopup: PropTypes.func,
    isOpenModal: PropTypes.bool,
    WiredAdvice: PropTypes.object,
    change: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        telcomParents: state.dataStore.telcomProviders.telcomParents,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(BookmarkModal);
