const validate = values => {
    const errors = {};
    const { 
      verify
    } = values;
    
    // if (!verify) {
    //   errors.verify = 'Vui lòng nhập verify';
    // }

    return errors;
  };
  
  export default validate;
  