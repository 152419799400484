import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 
import { data } from 'jquery';

import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
class SimModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item:{},
            active:true
        };
        
    }

    renderSim () {
        const { dataSims,itemSim } = this.props;
        let viewList = ''
       if(dataSims.data && dataSims.data.length > 0){
        return (dataSims.data.map((item, key) => {
      
            return (
                <Fragment key={key}>
                    <li  className={(itemSim&&item.id==itemSim.id?'active':'') + " cursor" }   onClick={this.chooseSim.bind(this, item)} data-dismiss="modal">
                        {item.name_sim}
                    </li>
                </Fragment>
            );
        }));
       }

    }

    
    chooseSim = (data) => {
        if(data==null)
        this.state.active = true
        else
        this.state.active = false
        const {WirelessAdvice} = this.props;
        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        this.props.change('WirelessAdviceStep3.sim', data)

    }
    // handleChange = (val) => {
    //     this.setState({
    //         item: val
    //     }, () => {
    //         this.props.setValuePay(this.state.item)

    //     })
    // }
    render() {
        const {
            active
        } = this.state;

        return (
            <Fragment>
                {/* modal */}
                <div className="modal fade" id="modal-30"
                     tabIndex={-1} role="dialog"
                     // data-backdrop="static"
                     data-keyboard="false"
                     aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-additional modal-custom-08" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">유심</h5>
                                {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src="/images/icon-close.svg" alt="" />
                                </button> */}
                            </div>
                            <div className="modal-body mt-2">
                                <ul className="list-additional" >
                                    <li  className={(this.state.active?'active':'') + " cursor" } onClick={this.chooseSim.bind(this,null)} data-dismiss="modal">
                                        미적용
                                    </li>
                                {this.renderSim()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* end modal */}
            </Fragment>

        );
    }
}

SimModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(SimModal);
