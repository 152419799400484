

import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import renderSelectField from '../../../components/FormHelper/SelectField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import validate from './validate-step-3';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    loginAction,
} from '../../../store/actions/auth';
import * as routerName from '../../../utils/routerName';
import * as helper from '../../../utils/helper';

const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 7)
}

class SignUpStep3Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricePackageSelected: {},
            extraService:{
                useAlarm:{
                    state:false,
                    price: 0
                },
            }
        }
    }

    componentDidMount() {
    }

    handleOnChangeTabType = (data) => {
        if (data) {
            this.props.change('package_type', data);
            this.props.getListPricePackages(data)
        }
    }

    convertSale = (data1, data2) => {
        return (data2 - data1) / data2 * 100;
    }

    selectedPricePackage = (item) => {
        this.setState({
            pricePackageSelected: item
        }, () => {
        })
        this.props.change('package_id', item.pp_id)
        this.props.change('useAlarm', '')
    }

    onChangeUseAlarm = () => {
        setTimeout(() => {
            this.setState({
                extraService:{
                    useAlarm: {
                        state:this.props.SignUpStep3.useAlarm,
                        price:this.props.SignUpStep3.useAlarm == true ? parseFloat(this.props.priceExtraService) : 0
                    },
                }
            })
        })
    }

    renderTotalPriceMonth = () => {
        let price_package = this.state.pricePackageSelected.pp_price ? this.state.pricePackageSelected.pp_price : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        let price_alarm = this.state.extraService.useAlarm.price ? this.state.extraService.useAlarm.price : 0
        let total = parseFloat(price_package) + parseFloat(price_alarm)
        return total * this.props.SignUpStep3.useMonth;
    }

    renderTotalPriceDay = () => {
        let price_package = this.state.pricePackageSelected.pp_price ? this.state.pricePackageSelected.pp_price : (this.props.pricePackages.data.length > 0 && this.props.pricePackages.data[0].pp_price)
        let price_alarm = this.state.extraService.useAlarm.price ? this.state.extraService.useAlarm.price : 0
        let total = parseFloat(price_package) + parseFloat(price_alarm)
        var now = new Date();
        var mothNowToday = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        var numberDay = now.getUTCDate()

        return total / mothNowToday * (mothNowToday - numberDay) ;
    }

    renderTotal = () => {
        return parseFloat(this.renderTotalPriceMonth()) +  parseFloat(this.renderTotalPriceDay())
    }

    onChangePaymentMethod = () => {
        // setTimeout(() => {
        //     this.props.change('useMonth', '')
        // })
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <section className="container">
                <form onSubmit={handleSubmit} autoComplete="on" className="form-register register-user">
                    <div className="title-form">
                        <h2>
                            서비스 요금제 선택
                        </h2>
                    </div>

                    <div className="box-register">
                        <div className="box-item">
                            <div className="total-top"></div>
                            <ul className="list-title">
                                <li>동시 로그인 허용 개수</li>
                                <li>정산관리 (판매일보)</li>
                                <li>일정알림</li>
                            </ul>
                        </div>
                        {this.props.pricePackages.data.map((item, i) =>
                            <Fragment key={i}>
                                <div className={"box-item " + (this.props.SignUpStep3 && parseInt(this.props.SignUpStep3.package_id) == item.pp_id ? "active" : "")} onClick={() => this.selectedPricePackage(item)} >
                                    <div className="total-top">
                                        <h4>프라임</h4>
                                        <h5 className="prices">
                                            <span>월</span>
                                            {helper.priceFormat(item.pp_price)}
                                            <span>원</span>
                                        </h5>
                                        <span className="vat">(VAT포함)</span>
                                    </div>
                                    <ul className="list-status">
                                        <li>{item.pp_use_time}</li>
                                        <li>
                                            {item.isReport == '1' ? (<img src="/images/true-01.svg" alt="" />) : (<img src="/images/fail-01.svg" alt="" />)}
                                        </li>
                                        <li>
                                            {item.isAlarm == '1' ? (<img src="/images/true-01.svg" alt="" />) : (<img src="/images/fail-01.svg" alt="" />)}

                                        </li>
                                    </ul>
                                    <div className="check-status">
                                        <div className="check">
                                            <div className="icon-check"></div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    
                    {!(this.state.pricePackageSelected && this.state.pricePackageSelected.isAlarm == '1') ? (
                        <Fragment>
                            <div className="title-form">
                                <h2>
                                    부가서비스
                                </h2>
                            </div>
                            <div className="extra-service">
                                <div className="extra-item bg-white">
                                    <div className="item">
                                        <h4>부가서비스명</h4>
                                    </div>
                                    <div className="item">
                                        <h4>이용요금 <span>(VAT포함) </span></h4>
                                    </div>
                                </div>
                                <div className="extra-item">
                                    <div className="item">
                                        <div className="form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <Field
                                                    name="useAlarm"
                                                    id="useAlarm"
                                                    component={renderCheckboxField}
                                                    checked={this.props.SignUpStep3 && parseInt(this.props.SignUpStep3.useAlarm)}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                />
                                                <label className="custom-control-label text-extra" htmlFor="useAlarm" onClick={() => this.onChangeUseAlarm()}>일정알림</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <p className="text-extra">월 {helper.priceFormat(this.props.priceExtraService)} 원 </p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : ('')}
                    
                    <div className="title-form">
                        <h2>
                            결제수단
                        </h2>
                    </div>
                    <div className="method-payment">
                        <div className="top">
                            <div className="item-top ml-3">
                                <div className="custom-control custom-radio">
                                    <Field
                                        id="payment_method_1"
                                        name="payment_method"
                                        component={renderRadioField}
                                        checked={this.props.SignUpStep3 && parseInt(this.props.SignUpStep3.payment_method)}
                                        type="radio"
                                        value="0"
                                        className="custom-control-input"
                                    />
                                    <label className="custom-control-label text-extra" htmlFor="payment_method_1" onClick={() => this.onChangePaymentMethod()} >정기결제</label>
                                </div>
                            </div>
                            <div className="item-top justify-content-end">
                                <div className="custom-control custom-radio">
                                    <Field
                                        id="payment_method_2"
                                        name="payment_method"
                                        component={renderRadioField}
                                        checked={this.props.SignUpStep3 && parseInt(this.props.SignUpStep3.payment_method)}
                                        type="radio"
                                        value="1"
                                        className="custom-control-input"
                                    />
                                    <label className="custom-control-label text-extra" htmlFor="payment_method_2" onClick={() => this.onChangePaymentMethod()}>선결제</label>
                                </div>
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {(this.props.SignUpStep3.useMonth ? this.props.SignUpStep3.useMonth : '12') + '개월'}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {helper.getUsedMonth().map((item, i) => 
                                           <a key={i} className={"dropdown-item " + (this.props.SignUpStep3.useMonth == item ? 'active' : '')} onClick={() => this.props.change('useMonth', item)}>{item}개월</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="prices-payment">
                            <ul className="list-payment">
                                <li>
                                    <div className="left">
                                        <p>1. 서비스 요금제</p>
                                        <p className="text-bold">스탠다드</p>
                                    </div>
                                    <div className="right">
                                        {this.state.pricePackageSelected.pp_price ? (
                                            <p>월 {helper.priceFormat(this.state.pricePackageSelected.pp_price)}원 {(this.props.SignUpStep3.useMonth & this.props.SignUpStep3.payment_method == '1') ? ('x '+ this.props.SignUpStep3.useMonth ) : ('')}</p>
                                        ): (
                                            <p>월 {this.props.pricePackages.data.length > 0 && helper.priceFormat(this.props.pricePackages.data[0].pp_price)}원 {(this.props.SignUpStep3.useMonth && this.props.SignUpStep3.payment_method == '1') ? ('x '+ this.props.SignUpStep3.useMonth ) : ('')}</p>
                                        )}
                                        
                                    </div>
                                </li>
                                {this.state.extraService.useAlarm.state ? (
                                    <li>
                                        <div className="left">
                                            <p>2. 부가서비스</p>
                                            <p className="text-bold">일정알림</p>
                                        </div>
                                        <div className="right">
                                            <p>월 {helper.priceFormat(this.state.extraService.useAlarm.price)}원 {(this.props.SignUpStep3.useMonth && this.props.SignUpStep3.payment_method == '1') ? ('x '+ this.props.SignUpStep3.useMonth ) : ('')}</p>
                                        </div>
                                    </li>
                                ) : ('')}
                                
                            </ul>
                            <div className="total-payment">
                                <p>월별 청구금액 <span>(VAT포함)</span> </p>
                                <p className="text-reds">{helper.priceFormat(this.renderTotalPriceMonth())}원</p>
                            </div>
                            <div className="total-payment sub-total">
                                <p>월별 청구금액 <span>(VAT포함)</span> </p>
                                <p className="text-reds">월 {helper.priceFormat(this.renderTotalPriceDay())}원</p>
                            </div>
                        </div>
                        <div className="text-payment">
                            <h4>※ 결제 조건</h4>
                            <p>모비셀 사용요금은 선불이며, 매월 말일 익월요금 결제가 이루어 집니다.</p>
                            <p>익월 결제금액은 15,400원 입니다</p>
                            <p>당월 결제액은 말일까지 일할계산된 금액입니다.</p>
                        </div>
                        <div className="text-payment pt-2">
                            <h4>※ 요금조건</h4>
                            <p>- 해당 월 환불 불가</p>
                            <p>- 하위 요금제 변경 시 차액 환불 불가</p>
                            <p>- 익월부터 요금제 변경 가능 </p>
                        </div>
                    </div>
                    <div className="title-form">
                        <h2>
                            결제정보
                        </h2>
                    </div>
                    <div className="payment-information">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group row">
                                    <label className="label-text mb-0 col-sm-4">결제방식</label>
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <Field
                                            name="payment_state"
                                            id="payment_state"
                                            component={renderCheckboxField}
                                            checked={this.props.SignUpStep3 && parseInt(this.props.SignUpStep3.payment_state)}
                                            type="checkbox"
                                            className="custom-control-input"
                                        />
                                        <label className="custom-control-label text-extra" htmlFor="payment_state">정기결제</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="label-text">결제번호</label>
                                    <input
                                        className='form-control  h-44'
                                        placeholder="결제번호"
                                        type="text"
                                        value={helper.priceFormat(this.renderTotal())}
                                        disabled={'disabled'}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="label-text">이름</label>
                                    <Field
                                        placeholder="이메일"
                                        name="name_payment"
                                        type="text"
                                        component={renderTextFieldAsync}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group row">
                                    <div className="col-6 pr-1">
                                        <label className="label-text">입금자 번호</label>
                                        <Field
                                            placeholder="입금자 번호"
                                            name="name_payment"
                                            type="text"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <label className="label-text">년, 월, 일</label>
                                        <div className="form-date">
                                            <Field
                                                placeholder="년, 월, 일"
                                                name="date_payment"
                                                type="text"
                                                component={renderTextField}
                                            />
                                            <img src="/images/icon-calender.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="label-text">이메일</label>
                                    <Field
                                        placeholder="이메일"
                                        name="email_payment"
                                        type="text"
                                        component={renderTextFieldAsync}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="label-text">유효기간</label>
                                <div className="form-group row">
                                    <div className="col-7 pr-1">
                                        <div className="dropdown">
                                            <Field
                                                placeholder="tháng"
                                                name="month" // tên công ty thẻ
                                                component={renderSelectField}
                                            >
                                                {helper.getMonthCardPayment().map((item, i) => 
                                                    <option value={item} key={i}>{'월' + item}</option>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-5 pl-1">
                                        <Field
                                            placeholder="년"
                                            name="year" // tên công ty thẻ
                                            component={renderSelectField}
                                        >
                                            {helper.getYearCardPayment().map((item, i) => 
                                                <option value={item} key={i}>{item}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="label-text">전화번</label>
                                <div className="form-group row">
                                    <div className="col-md-5 col-4">
                                        <Field
                                            name="first_payment_phone"
                                            component={renderSelectField}
                                        >

                                            {helper.getFirstNumberPhone().map((item) =>
                                                <option key={item} value={item}>{item}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-md-7 col-8">
                                        <Field
                                            placeholder="연락처"
                                            name="last_payment_phone"
                                            type="text"
                                            component={renderTextField}
                                            normalize={normalizePhone}
                                            onChange={this.props.onChangeVerifyPhoneClearCountDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button-information">
                            <button className="btn">신규 등록 </button>
                            <button className="btn btn-yellow">기존의 정보 적용</button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <button type="button" className="btn btn-gray pl-5 pr-5 mx-1" onClick={this.props.redirectLogin}>취소</button>
                        <button type="submit" className="btn btn-submit pl-5 pr-5 mx-1">다음</button>
                    </div>
                </form>
            </section>
        );
    }
}

SignUpStep3Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        SignUpStep3: getFormValues('SignUp')(state),
        SignUpStep3Errors: getFormSyncErrors('SignUp')(state),
        signUp: state.signUp,
        pricePackages: state.dataStore.pricePackages,
        priceExtraService: state.dataStore.priceExtraService,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        loginAction: (data) => dispatch(loginAction(data)),
        change: () => () => dispatch(change())
    }
};
const FORM_NAME = 'SignUp';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(SignUpStep3Form);
