import {
  modalActions
} from '../actions';


export const showModal = (data) => {
  return ({
  type: modalActions.SHOW_MODAL,
  payload: {
    data
  },
})};

export const hideModal = () => {
  window.history.pushState(null, '');
  return ({
    type: modalActions.HIDE_MODAL,
  })
};

export const clearModal = () => {
  window.history.pushState(null, '');
  return ({
    type: modalActions.CLEAR_MODAL,
  })
};

export const changeModalTitle = title => ({
  type: modalActions.CHANGE_MODAL_TITLE,
  payload: {
    title,
  },
});

export const changeModalContent = component => ({
  type: modalActions.CHANGE_MODAL_CONTENT,
  payload: {
    component,
  },
});
