 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import WirelessOptionFeeComponent from './OptionFee/OptionFee'
import WirelessDisclosureSubsidy from './DisclosureSubsidy/DisclosureSubsidy'
import WirelessAdditionalGrants from './AdditionalGrants/AdditionalGrants'
import WirelessOptionalAgreement from './OptionalAgreement/OptionalAgreement'
import WirelessContractMonth from './ContractMonth/ContractMonth'

class WirelessOptionFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    var classActive2G="conversion2G"
      if(WirelessAdvice.WirelessAdviceStep3.optionFee == 1) {
          if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null) {
              if(WirelessAdvice.WirelessAdviceStep3.conversion2G?.id != 0) {
                classActive2G="disable-custom"
              }
          }
      }
    
    return (
      <div className="tab-contract-prices">
        <WirelessOptionFeeComponent/>
        <div className="flex-wrapper">
          <div className={WirelessAdvice.WirelessAdviceStep3.optionFee == 1 ? "left border-0" : "left border-0 disabled-form"}>
            <WirelessDisclosureSubsidy/>
            <WirelessAdditionalGrants/>
          </div>
          <div className={WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? "right" : ("right disabled-form "+(classActive2G))}>
            <WirelessOptionalAgreement/>
            <WirelessContractMonth/>
          </div>
        </div>
      </div>
    );
  }
}

WirelessOptionFee.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessOptionFee);
