

import renderTextField from '../../../components/FormHelper/TextField';
import renderFileField from '../../../components/FormHelper/FileField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, 
    reduxForm, 
    FieldArray, 
    getFormSyncErrors, 
    getFormValues, 
    formValueSelector,
    isDirty,
    isPristine,
    isValid,
    isInvalid,
    getFormMeta,
    getFormAsyncErrors
 } from 'redux-form';
import validate from './validate';
// import warn from './warn';
import * as routerName from '../../../utils/routerName';
import { getFirstNumberPhone, getStoreFirstNumberPhone } from '../../../utils/helper';
import asyncValidate from './validate-async';
import { SubmissionError } from 'redux-form'

const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length < 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length == 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3,7)
    }
    if (onlyNums.length == 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4,8)
    }
}

const normalizeSixNumber = (value) => {
    if (!value) {
        return value
    }
    // const onlyNums = value.replace(/[^\d]/g, '')
    const onlyNums = value;
    if (onlyNums.length <= 6) {
        return onlyNums
    }
}
const normalize3Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
}
const normalize5Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 5) {
        return onlyNums
    }
}
const normalize2Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
}
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class EditProfileForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.inputs = []
    }

    componentDidMount() {
    }   
    


    renderSelectFirstPhoneNumber = () => {
        let xhtml = null;
        if (getFirstNumberPhone().length > 0) {
            xhtml = (
                <Field
                    className="form-control h-44"
                    type="text"
                    name="first_user_phone"
                    component={renderSelectField}
                    onChange={this.props.onChangeVerifyPhone}
                >
                    {getFirstNumberPhone().map((item) =>
                        <option key={item} value={item}>{item}</option>
                    )}
                </Field>
            );
        }
        return xhtml;
    }
    renderSelectStoreFirstPhoneNumber = () => {
        let xhtml = null;
        if (getStoreFirstNumberPhone().length > 0) {
            xhtml = (
                <Field
                    className="form-control h-44"
                    type="text"
                    name="first_store_phone"
                    component={renderSelectField}
                >
                    {getStoreFirstNumberPhone().map((item) =>
                        <option key={item} value={item}>{item}</option>
                    )}
                </Field>
            );
        }
        return xhtml;
    }

    checkScrollErr = (e) => {
        
        if(this.props.EditProfile.password.length > 0){
            this.props.change('onChangeRepassword', true)
        }
        let name = null;
        Object.keys(this.props.EditProfileErrors).map(function(key, index) {
            if(index == 0 && key == 'oldpassword') {
                name = key;
            }
        });
        if(name) {
            window.scrollTo(0,this.inputs[name].parentElement.offsetTop);
        }
    }

    onChangeRepassword = () => {
        setTimeout(() => {
            this.props.change('onChangeRepassword', true)
        })
    }

    onChangePassword = () => {
        setTimeout(() => {
            if(this.props.EditProfile.password.length == 0 ){
                this.props.change('onChangeRepassword', false)
            }
        })
    }

    onChangeUser = (e) => {
        setTimeout(() => {
            let change = false
            if (e.target.name == 'user_id') {
                if (e.target.value !== this.props.auth.user.mobigo_user_id || this.props.EditProfile.user_pw) {
                    change = true
                }
            }
            if (e.target.name == 'user_pw') {
                if (e.target.value !== '' || this.props.EditProfile.user_id !== this.props.auth.user.mobigo_user_id) {
                    change = true
                }
            }
            this.props.change('is_change_user', change)
        })
        this.props.onChangeVerifyOldMember(e)
    }

    render() {
        const { handleSubmit } = this.props;
        console.log(this.props)
        return (
            <form onSubmit={handleSubmit} autoComplete="on" >

                <Field
                    className="form-control h-44"
                    placeholder="아이디"
                    name="avatar"
                    type="text"
                    image_url={this.props.EditProfile  && this.props.EditProfile.avatar_url && this.props.auth.user.avatar ? this.props.EditProfile.avatar_url : '' }
                    component={renderFileField}
                />

                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="label-text">아이디</label>
                        <input
                            className="form-control h-44"
                            placeholder="아이디"
                            name="username"
                            type="text"
                            value={this.props.EditProfile && this.props.EditProfile.username}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <label htmlFor="" className="label-text" >이메일</label>
                            <Field
                                className="form-control h-44"
                                placeholder="아이디"
                                name="email"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                    </div>
                </div>
               
                <div className="form-row" >
                    <div className="form-group col-md-12" >
                        <label className="label-text" ref={(input) => { this.inputs['oldpassword'] = input }}>현재 비밀번호</label>
                        <Field
                            className="form-control h-44"
                            placeholder="현재 비밀번호 입력"
                            name="oldpassword"
                            id="oldpassword"
                            type="password"
                            component={renderTextField}
                        />
                    </div>
                </div>
                
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="label-text">신규 비밀번호</label>
                        <Field
                            className="form-control h-44"
                            placeholder="신규 비밀번호 입력"
                            name="password"
                            type="password"
                            component={renderTextField}
                            onChange={() => this.onChangePassword()}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="label-text">신규 비밀번호 확인</label>
                        <Field
                            className="form-control h-44"
                            placeholder="신규 비밀번호 확인 입력"
                            name="repassword"
                            type="password"
                            component={renderTextField}
                            onChange={() => this.onChangeRepassword()}
                        />
                    </div>
                </div>
                <div className="title-form mt-5">
                    <h2>
                        매장 정보
                    </h2>
                </div>
                <div className="form-group mt-4">
                    <label htmlFor="" className="label-text">매장명</label>
                    <Field
                        className="form-control h-44"
                        placeholder="이메일"
                        name="sto_name"
                        type="text"
                        component={renderTextField}
                    />
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-text">매장연락처</label>
                    <div className="row">
                        <div className="col-md-3 col-4 pr-0">
                            {this.renderSelectStoreFirstPhoneNumber()}
                        </div>
                        <div className="col-md-9 col-8 pr-0">
                            <Field
                                placeholder="연락처"
                                name="last_store_phone"
                                type="text"
                                component={renderTextField}
                                normalize={normalizePhone}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-text">매장 주소</label>
                    <div className="row">
                        <div className="col-md-8 col-7 pr-0">
                            <Field
                                className="form-control h-44"
                                placeholder="이메일"
                                name="sto_addr_code"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div className="col-md-4 col-5">
                            <button className="btn btn-verify h-44" type='button' onClick={() => this.props.onClickShowMap()}>
                                주소찾기
                                </button>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-2">
                    <Field
                        className="form-control h-44"
                        placeholder=""
                        name="sto_addr_line1"
                        type="text"
                        component={renderTextField}
                    />
                </div>
                <div className="form-group">
                    <Field
                        className="form-control h-44"
                        placeholder=""
                        name="sto_addr_line2"
                        type="text"
                        component={renderTextField}
                    />
                </div>
                <div className={"form-group " + (this.props.auth.store && (this.props.auth.store.sto_business_type == '1' )   ? "d-show" : "d-none" )}>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <label htmlFor="" className="label-text" >계산서 발행 이메일 주소</label>
                            <Field
                                className="form-control h-44"
                                placeholder="아이디"
                                name="sto_business_email"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="" className="label-text">담당자 이름</label>
                    <Field
                        className="form-control h-44"
                        placeholder="담당자 이름"
                        name="name"
                        type="text"
                        component={renderTextField}
                    />
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-text">판매자</label>
                    <div className="row">
                        <div className="col-md-3 col-4 pr-0">
                            {this.renderSelectFirstPhoneNumber()}
                        </div>
                        <div className="col-md-5 col-8 pr-0">
                            <Field
                                placeholder="연락처"
                                name="last_user_phone"
                                type="text"
                                component={renderTextField}
                                normalize={normalizePhone}
                                onChange={this.props.onChangeVerifyPhone}
                            />
                        </div>
                        <div className="col-md-4 col-12">
                            <button className="btn btn-verify h-44 mt-10" onClick={() => this.props.onClickCreateVerifySMS()} type="button" 
                            disabled={this.props.asyncValidating !== false || (this.props.EditProfileAsyncErrors && !!this.props.EditProfileAsyncErrors.last_user_phone) ?true:false}
                            >
                                인증하기
                            </button>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-8 col-7 pr-0">
                            <Field
                                placeholder="인증번호 입력"
                                name="store_phone_code"
                                type="text"
                                normalize={normalizeSixNumber}
                                component={renderTextField}
                            />
                        </div>
                        <div className="col-md-4 col-5">
                            <button className="btn btn-verify h-44" onClick={() => this.props.onClickComfirmVerifySMS()} type="button" >
                                확인
                            </button>
                        </div>
                    </div>
                    { this.props.verify_phone.verify_phone_status == false && this.props.time.s ? ( <p style={{color:'green', fontSize:'80%'}}>인증코드의 유효시간은 {this.props.time.m +':'+ this.props.time.s}  입니다. </p> ): ''  } 
                    {this.props.verify_phone.verify_phone_status == true ? 
                        <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_phone.verify_phone_msg}</p>
                    :
                        <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_phone.verify_phone_msg}</p>
                    }
                </div>
                <div className={"form-group " + (this.props.auth.store && (this.props.auth.store.sto_business_type == '1' )   ? "d-show" : "d-none" )}>
                    <label htmlFor="" className="label-text" >사업자 등록번호</label>
                    <div className={"row verify"}>
                        <div className="col-md-3 col-3 pr-0">
                            <Field
                                className="form-control h-44" 
                                name="registration_number[0]"
                                type="text"
                                component={renderTextFields}
                                normalize={normalize3Number}
                                onChange={this.props.onChangeVerifyRegistration}
                            />
                            {/* <input type="text" className="form-control h-44" /> */}
                        </div>
                        <div className="col-md-3 col-3 pr-0">
                            <Field
                                className="form-control h-44" 
                                name="registration_number[1]"
                                type="text"
                                component={renderTextFields}
                                normalize={normalize2Number}
                                onChange={this.props.onChangeVerifyRegistration}
                            />
                            {/* <input type="text" className="form-control h-44" /> */}
                        </div>
                        <div className="col-md-3 col-3 pr-0">
                            <Field
                                className="form-control h-44" 
                                name="registration_number[2]"
                                type="text"
                                component={renderTextFields}
                                normalize={normalize5Number}
                                onChange={this.props.onChangeVerifyRegistration}
                            />
                            {/* <input type="text" className="form-control h-44" /> */}
                        </div>
                        <div className="col-md-3 col-6 mt-10">
                            <button className="btn btn-verify h-44" type='button' onClick={() => this.props.onClickVerifyBussiness()}>
                                사업자 확인
                            </button>
                        </div>
                    </div>
                    {  this.props.EditProfileGetFormMeta && this.props.EditProfileGetFormMeta.registration_number && this.props.EditProfileErrors && this.props.EditProfileErrors.registration_number_msg ? (
                        <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.EditProfileErrors.registration_number_msg}</p>
                    ) : ''}
                    {this.props.EditProfileErrors.registration_number && this.props.EditProfileErrors.registration_number.length == 0 ? 
                    (this.props.registration_number.registration_verify_status == true ? 
                        <p style={{color:'green', fontSize:'80%'}}>{this.props.registration_number.registration_verify_msg}</p>
                    :
                        <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.registration_number.registration_verify_msg}</p>
                    ): ('')}
                    
                </div>

                <div className="title-form mt-5">
                    <h2>
                        기타 정보
                    </h2>
                </div>
                <div className="form-group mt-4">
                    <label htmlFor="" className="label-text">
                        모비고 아이디 (선택)
                        <small className="ml-1" style={{ color: "#6c757d" }}>
                           모비고 유료 이용 고객님께 다양한 혜택이 제공됩니다
                        </small>
                    </label>
                    <div className="row">
                        <div className="col-md-8 col-7 pr-0">
                            <Field
                                placeholder="사용 중인 모비고 아이디 입력"
                                name="user_id"
                                type="text"
                                component={renderTextField}
                                onChange={this.onChangeUser}
                            />
                        </div>
                    </div>
                    {this.props.verify_old_member.verify_status === true ? 
                        <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_id}</p>
                    :
                        <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_id}</p>
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="" className="label-text">모비고 비밀번호</label>
                    <div className="row">
                        <div className="col-md-8 col-7 pr-0">
                            <Field
                                placeholder="사용 중인 모비고 비밀번호 입력"
                                name="user_pw"
                                type="password"
                                component={renderTextField}
                                onChange={this.onChangeUser}
                            />
                        </div>
                        <div className="col-md-4 col-5">
                            <button className="btn btn-verify h-44" onClick={this.props.onClickVerifyOldMember} type="button" >
                                확인
                            </button>
                        </div>
                    </div>
                    {
                        this.props.verify_old_member.verify_status === true ? 
                            <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_pw}</p>
                        :
                            <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_pw}</p>
                    }
                </div>

                <div className="group-button">
                    <button type="button" className="btn btn-close-register" onClick={() => this.props.redirectProfile()}>취소</button>
                    <button type="submit" className="btn btn-next" onClick={(e) => this.checkScrollErr(e)}>저장</button>
                </div>
            </form>
        );
    }
}

EditProfileForm.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        EditProfile: getFormValues('EditProfile')(state),
        EditProfileAsyncErrors: getFormAsyncErrors('EditProfile')(state),
        EditProfileErrors: getFormSyncErrors('EditProfile')(state),
        EditProfileGetFormMeta: getFormMeta('EditProfile')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
    }
};
const FORM_NAME = 'EditProfile';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    validate,
    // warn,
    asyncValidate,
    asyncBlurFields: ['oldpassword']
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(EditProfileForm);
