import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../store/actions/ui';
import { priceFormat } from '../../utils/helper';

const cussorDefault = {
  cussor:'default'
}

const TEXT = {
  2: 'TV 플러스',
  // 2: 'TV 플러', // TASK 7226
}

const totlaFeeOption = (options = []) => options.reduce((prevFee, option) => {
  return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
}, 0)

const InstallationFeeList = ({classify, combineCondition}) => {
  let totalMonthlyFee = 0;
  let totalBasicDiscount = 0;
  let totalCombinedDiscount = 0;
  let totalAfterDiscount = 0;

  //console.log("InstallationFeeList classify");
  //console.log(classify);

  classify.map((cls) => (
    cls.classify.map((item) => {
      totalMonthlyFee += +(item?.device?.wd_commitment_fee || 0)
      totalMonthlyFee += totlaFeeOption(item?.options || [])
      totalBasicDiscount += +(item?.discount?.device?.value || 0) + +(item?.discount?.device?.additional || 0);
      (item?.discount?.options || []).map((option) => {
        totalBasicDiscount += +(option?.value || 0) + +(option?.additional || 0)
      })
      totalCombinedDiscount += +(item?.discount?.combineDiscount || 0);
      (item?.discount?.options || []).map((option) => {
        totalCombinedDiscount = totalCombinedDiscount + +(option?.combine || 0)
      })
    })
  ))
  totalMonthlyFee = totalMonthlyFee > 0 ? totalMonthlyFee : 0
  totalBasicDiscount = totalBasicDiscount > 0 ? totalBasicDiscount : 0
  // totalCombinedDiscount = totalCombinedDiscount > 0 ? totalCombinedDiscount : 0
  totalAfterDiscount = totalMonthlyFee - totalBasicDiscount - totalCombinedDiscount
  totalAfterDiscount = totalAfterDiscount > 0 ? totalAfterDiscount : 0;

  return (
    <Fragment>
    <table className="table billing-fee table-product-fee">
      <thead className="thead-table-blue">
        <tr>
          <th className="text-left" >가입상품</th>
          <th></th>
          <th className="text-right">월 이용료</th>
          <th className="text-right">기본할인</th>
          <th className="text-right">유무선 결합할인</th>
          <th className="text-right">할인 후 이용료</th>
        </tr>
      </thead>
      <tbody>
        {classify.map((cls) => (
          cls.classify.map((item, itemKey) => (
            item.device && item.device.wd_name && (
              <Fragment key={itemKey}>
                <tr className="border-none">
                  <td rowSpan={1 + item.options.length} className="td-count">
                    <div className="flex-table">
                      <div className="bordered-right-blue">
                        <div>
                          <img src={cls?.icon} />
                        </div>
                        <span className="title-table">{cls?.name}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="product">{item?.device?.wd_name}</span>
                  </td>
                  <td className="text-right">
                    <p className="mb-2">
                      {priceFormat(item?.device?.wd_commitment_fee || 0)}원
                    </p>
                  </td>
                  <td className="text-right">
                    {item?.discount?.device?.value ? (
                      <Fragment>
                        <div className="sale-prices">
                          <p>
                            <span className="prices-form">
                              -{priceFormat(item?.discount?.device?.value)}
                            </span>
                            <span className="price-sub">원</span>
                          </p>
                          <p className="font-12">
                            {item?.discount?.device?.name}
                          </p>
                        </div>
                      </Fragment>
                    ) : null}
                    {item?.discount?.device?.additional ? (
                      <Fragment>
                        <div className="sale-prices">
                          <p>
                            <span className="prices-form">
                              -{priceFormat(item?.discount?.device?.additional)}
                            </span>
                            <span className="price-sub">원</span>
                          </p>
                          <p className="font-12">
                            프로모션 추가할인
                          </p>
                        </div>
                      </Fragment>
                    ) : null}
                    {/*<div className="sale-prices">
                      <p><span className="prices-form">-5,500</span> <span className="price-sub">원</span></p>
                      <p className="font-12">전화 할인 (인터넷 + Btv 결합시)</p>
                    </div>*/}
                  </td>
                  <td className="text-right">
                    {item?.discount?.combineDiscount ? (
                      <Fragment>
                          {

                              item.discount_name && item.discount_name.length > 0 ? (
                                  <Fragment>
                                      {item.discount_name.map(discount => (
                                          <div className="sale-prices">
                                              <p>
                                              <span className="prices-form">
                                                -{priceFormat(+discount.discount)}
                                              </span>
                                                  <span className="price-sub">원</span>
                                              </p>
                                              <p className="font-12">
                                                  {discount.name}
                                              </p>
                                          </div>
                                      ))}
                                  </Fragment>
                              ) : (
                                  <Fragment>
                                      <div className="sale-prices">
                                          <p>
                                              <span className="prices-form">
                                                -{priceFormat(item?.discount?.combineDiscount)}
                                              </span>
                                              <span className="price-sub">원</span>
                                          </p>
                                          <p className="font-12">
                                              {TEXT[cls.id] || combineCondition?.name}
                                          </p>
                                      </div>
                                  </Fragment>
                              )
                          }

                      </Fragment>
                    ) : null}
                  </td>
                  <td className="text-right">
                    <p className="mb-2">
                      <span className="total-price">
                        {priceFormat(
                          (item?.device?.wd_commitment_fee || 0) - (item?.discount?.device?.value || 0) - (item?.discount?.combineDiscount || 0) - (item?.discount?.device?.additional || 0) > 0
                          ? (item?.device?.wd_commitment_fee || 0) - (item?.discount?.device?.value || 0) - (item?.discount?.combineDiscount || 0) - (item?.discount?.device?.additional || 0)
                          : 0
                        )}
                      </span>
                      <span className="price-sub">원</span>
                    </p>
                  </td>
                </tr>
                {item?.options.map((option, keyOption) => (
                  <Fragment key={itemKey + '-' + keyOption}>
                    <tr style={{border: 'none'}}>
                      <td>
                        <span className={`promotion${option.type == 2 ? ' pl-3' : ''}`}>
                          {
                            (option) ? (
                              (option?.wdo_name && option?.wdo_name != "")
                              ? (option?.wdo_name)
                              : (option?.wdo_model_name || '미적용')
                            ) : '미적용'
                          }
                        </span>
                      </td>
                      <td className="text-right">
                        <p className="mb-2">
                          {priceFormat(+option.wdo_commitment_fee * (+option.qty || 1))}원
                        </p>
                      </td>
                      <td className="text-right">
                        {item?.discount?.options && item?.discount?.options[keyOption]?.value ? (
                          <Fragment>
                            <div className="sale-prices">
                              <p>
                                <span className="prices-form">
                                  -{priceFormat(item?.discount?.options[keyOption]?.value)}
                                </span>
                                <span className="price-sub">원</span>
                              </p>
                              <p className="ont-12">
                                {item?.discount?.options[keyOption]?.name}
                              </p>
                            </div>
                          </Fragment>
                        ) : null}
                        {item?.discount?.options && item?.discount?.options[keyOption]?.additional ? (
                          <Fragment>
                            <div className="sale-prices">
                              <p>
                                <span className="prices-form">
                                  -{priceFormat(item?.discount?.options[keyOption]?.additional)}
                                </span>
                                <span className="price-sub">원</span>
                              </p>
                              <p className="font-12">
                                프로모션 추가할인
                              </p>
                            </div>
                          </Fragment>
                        ) : null}
                      </td>
                      <td className="text-right">
                        {item?.discount?.options && item?.discount?.options[keyOption]?.combine ? (
                          <Fragment>
                            <div className="sale-prices">
                              <p>
                                <span className="prices-form">
                                  -{priceFormat(item?.discount?.options[keyOption]?.combine)}
                                </span>
                                <span className="price-sub">원</span>
                              </p>
                              <p className="font-12">
                                {item?.discount?.options[keyOption]?.name}
                              </p>
                            </div>
                          </Fragment>
                        ) : null}
                      </td>
                      <td className="text-right">
                        <p className="mb-2">
                          <span className="total-price">
                            {priceFormat(
                              (+option.wdo_commitment_fee * (+option.qty || 1))
                              - (item?.discount?.options ? (item?.discount?.options[keyOption]?.value || 0) : 0)
                              - (item?.discount?.options ? (item?.discount?.options[keyOption]?.additional || 0) : 0)
                              - (item?.discount?.options ? (item?.discount?.options[keyOption]?.combine || 0) : 0)
                            )}
                          </span>
                          <span className="price-sub">원</span>
                        </p>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            )
          ))
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">
            <span className="font-weight-bold">요금총액 </span>
            <small className="text-muted">(VAT 포함)</small>
          </td>
          <td className="text-right">
            <span className="font-weight-bold">
              {priceFormat(totalMonthlyFee)}원
            </span>
          </td>
          <td className="text-right">
            <p className="mb-2">
              <span className="prices-form">
                {totalBasicDiscount ? '-' + priceFormat(totalBasicDiscount) : 0}
              </span>
              <span className="price-sub">원</span>
            </p>
          </td>
          <td className="text-right">
            <p className="mb-2">
              <span className="prices-form">
                {totalCombinedDiscount ? '-' + priceFormat(totalCombinedDiscount) : 0}
              </span>
              <span className="price-sub">원</span>
            </p>
          </td>
          <td className="text-right">
            <small className="text-muted mr-1">(VAT 포함)</small>
            <span className="px-2">
              <span className="total-price">
                {priceFormat(totalAfterDiscount)}
              </span>
              <span className="price-sub">원</span>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
    </Fragment>
  );
}

InstallationFeeList.propTypes = {
  classify: PropTypes.array,
  combineCondition: PropTypes.object,
};

export default InstallationFeeList;
