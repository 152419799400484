 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import InstallmentMonthModal from "./InstallmentMonthModal";

class WirelessInstallmentMonth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      numberMonth:24
    }
  }  

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice;
    // let monthVal= WirelessAdviceStep3.usedPhoneCompensation && WirelessAdviceStep3.usedPhoneCompensation.tup_id && WirelessAdviceStep3.installmentMonth 
    // ? WirelessAdviceStep3.installmentMonth
    // : 24
    let monthVal= WirelessAdviceStep3.installmentMonth;
    return (
      <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-4  col-form-label label-text ">할부개월</label>
        <div className="col-sm-8">
          <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-29">
            {monthVal?monthVal:24}개월
          </button>
        </div>
        <InstallmentMonthModal monthV={monthVal?monthVal:24} />
      </div>
    );
  }
}

WirelessInstallmentMonth.propTypes = {
};



const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize : true
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessInstallmentMonth);
