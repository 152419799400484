

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import MessageJSON from "../../utils/message.json";

class GuidePrintIEPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    handleConfirm = (e) => {
        e.preventDefault();
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-dialog modal-dialog-centered modal-print-03"
                // backdrop={"static"}
                >
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-left w-100">익스플로러 인쇄옵션 설정 안내 </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body p-0 instructions-explorer">
                        <p className="text-explorer">
                            Internet Explorer에서는 아래와 같이 페이지 설정을 변경해주셔야,
                            정상적인 서식 출력이 가능합니다. 설정을 변경하지 않은 상태에서 출력 시
                            <span className="color-orange"> 서류 미비</span>
                            가 발생할 수 있으니 반드시 확인해 주세요.
                        </p>
                        <div className="flex-explorer">
                            <div className="step-1">
                                <h6>[Step 1]</h6>
                                <div className="flex-alt">
                                    <button className="btn">alt</button>
                                를 누른 후
                                </div>
                                <p>파일 메뉴 &gt; 페이지 설정 클릭</p>
                            </div>
                            <div className="step-2">
                                <h6>[Step 2]</h6>
                                <p>
                                    페이지 설정 창에서 모든 머리 글/바닥 글 옵션을
                                <span>“비어있음”</span> 으로 변경 후, 확인 클릭
                                </p>
                            </div>
                        </div>
                        <div className="img">
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/print-icon-02.svg"} alt="" />
                        </div>
                    </div>

                    <div className="modal-footer justify-content-center border-top-0">
                        <button className="btn btn-submit pl-5 pr-5" onClick={this.props.toggle}>확인</button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        // auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        // openPopupMenu: (data)  => dispatch(openPopupMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(GuidePrintIEPopup);
