
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customizeDatepicker.css';
import moment from 'moment';
import api from "../../../utils/api";
import PhoneInsurancePopup from "./PhoneInsurancePopup";
import CancelDatePopup from "./CancelDatePopup";
import { priceFormat } from '../../../utils/helper';
// import { registerLocale } from  "react-datepicker";
// import ko from 'date-fns/locale/ko';
// registerLocale('ko', ko)


class PhoneInsurance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenPhoneInsurance: false,
            isOpenCancelDate: false,
            // isDisable: true,
            cancelDate: '',
            classInsurances: [],
            isActiveDeviceInsuranceButton: +props.WirelessAdvice.WirelessAdviceStep3?.used_program_count > 0,
            phoneInsurances: {
                dataList: [],
                itemSelected: {
                    // "tup_name": "미적용",
                    ins_id: 0,
                    ins_name: '',
                    ins_price: 0,
                }
            },
        }
    }

    componentDidMount() {
        const { WirelessAdvice } = this.props;
        // let insurranceRedux = WirelessAdvice.WirelessAdviceStep3.insurrance;

        let data = {
            telcomID: WirelessAdvice.WirelessAdviceStep1.telcomID,
            os_type: WirelessAdvice.WirelessAdviceStep3?.phone?.device?.os_type
        }

        Promise.all([
            api.advice.getListClassInsurances(data),
            api.advice.getListDeviceInsurances(data),
        ])
        .then(response => {
            this.setState({
                ...this.state,
                classInsurances: response[0].data.data,
                phoneInsurances: {
                    dataList: response[1].data.data,
                    // itemSelected: {...this.state.phoneInsurances.itemSelected}
                },
                isActiveDeviceInsuranceButton: response[1].data.data.length > 0,
            }, () => {
            })
        })
        .catch(err => {
        });

        // setTimeout(() => {
        //     this.props.change('WirelessAdviceStep3.insurrance', null);
        //     this.props.change('WirelessAdviceStep3.insurranceCancelDate', null);
        // })
    }

    componentDidUpdate(prevProps, nextProps) {
    }

   /**
    * manage PhoneInsurances
    */
    showPopupPhoneInsurance = () => {
        this.setState({
            ...this.state,
            isOpenPhoneInsurance: !this.state.isOpenPhoneInsurance,
        }, () => {
        })
    }

    onTogglePhoneInsurance = () => {
        this.setState({
            isOpenPhoneInsurance: !this.state.isOpenPhoneInsurance,
        })
    }

    getPhoneInsuranceValue = (data) => {
        this.setState({
            ...this.state,
            isOpenPhoneInsurance: !this.state.isOpenPhoneInsurance,
            phoneInsurances: {
                dataList: [...this.state.phoneInsurances.dataList],
                itemSelected: data
            }
        }, () => {
            this.props.change('WirelessAdviceStep3.insurrance', data);
            if (!data.ins_id) {
                this.props.change('WirelessAdviceStep3.insurranceCancelDate', null);
            }
            // setTimeout(() => {
            // })
        })
    }



   /**
    * manage Cancel Date
    */
    showPopupCancelDate = () => {
        this.setState({
            ...this.state,
            isOpenCancelDate: !this.state.isOpenCancelDate,
        }, () => {
        })
    }

    onToggleCancelDate = () => {
        this.setState({
            isOpenCancelDate: !this.state.isOpenCancelDate,
        })
    }

    showElementDatePicker = (is) => {
        if (is) {
            return(<DatePicker/>)
        }
    }

    elementIcon = () => {
        return (
            <div className="d-flex w-100">
                <span className="left">직접 선택</span>
                    <div className="right">
                    <div className="btn p-0">
                        <img src="../../images/icon-calender.svg" alt=""/>
                    </div>
                </div>
            </div>
        )
    }

    setCustomeDate = (date) => {
        let customDate = moment(date).format("DD/MM/YYYY");
        this.setState({
            ...this.state,
            isOpenCancelDate: !this.state.isOpenCancelDate,
            cancelDate: customDate,
        }, () => {
            // this.props.onSelectDate(data)
        })
    }

    renderCancelDates = () => {
        let xhtml = (
            <Fragment>
                <li onClick={() => this.getCancelDateValue('none')}>
                    <span className="left">미적용</span>
                </li>
                <li  onClick={() => this.getCancelDateValue('maintain')}>
                    <span className="left">서비스 유지</span>
                </li>
                <li>
                    <DatePicker
                        locale={process.env.REACT_APP_CONTENT_LANGUAGE}
                        customInput={this.elementIcon()}
                        onChange={date => this.getCancelDateValue(date)}
                        // onChange={date => this.setCustomeDate(date)}
                    />
                </li>
                <li onClick={() => this.getCancelDateValue('endMonth')}>
                    <span className="left">당월말</span>
                </li>
                <li onClick={() => this.getCancelDateValue('31day')}>
                    <span className="left">31일</span>
                </li>
                <li onClick={() => this.getCancelDateValue('91day')}>
                    <span className="left">91일</span>
                </li>
                <li onClick={() => this.getCancelDateValue('93day')}>
                    <span className="left">93일</span>
                </li>
                <li onClick={() => this.getCancelDateValue('95day')}>
                    <span className="left">95일(당일포함)</span>
                </li>
                <li onClick={() => this.getCancelDateValue('125day')}>
                    <span className="left">125일</span>
                </li>
                <li onClick={() => this.getCancelDateValue('183day')}>
                    <span className="left">183일</span>
                </li>
                <li onClick={() => this.getCancelDateValue('1month')}>
                    <span className="left">익월말(M+1)</span>
                </li>
                <li onClick={() => this.getCancelDateValue('2month')}>
                    <span className="left">M+2</span>
                </li>
                <li onClick={() => this.getCancelDateValue('3month')}>
                    <span className="left">M+3</span>
                </li>
                <li onClick={() => this.getCancelDateValue('4month')}>
                    <span className="left">M+4</span>
                </li>
                <li onClick={() => this.getCancelDateValue('5month')}>
                    <span className="left">M+5</span>
                </li>
                <li onClick={() => this.getCancelDateValue('6month')}>
                    <span className="left">M+6</span>
                </li>
            </Fragment>
        );
        return xhtml;
    }

    getCancelDateValueCalMonth = (value) => {
        return moment().add(value, 'M').startOf('month').format("YYYY-MM-DD");
    }

    getCancelDateValueCalDay = (value) => {
        let plusDay = moment(new Date()).add(value, 'days').toDate();
        return moment(plusDay).format("YYYY-MM-DD");
    }

    getCancelDateValue = (data) => {
        switch (data) {
            case 'none':
                var date = '';
                break;
            case 'maintain':
                var date = '서비스 유지';
                break;
            case 'endMonth':
                date = this.getCancelDateValueCalMonth(1);
                break;
            case '1month':
                date = this.getCancelDateValueCalMonth(2);
                break;
            case '2month':
                date = this.getCancelDateValueCalMonth(3);
                break;
            case '3month':
                date = this.getCancelDateValueCalMonth(4);
                break;
            case '4month':
                date = this.getCancelDateValueCalMonth(5);
                break;
            case '5month':
                date = this.getCancelDateValueCalMonth(6);
                break;
            case '6month':
                date = this.getCancelDateValueCalMonth(7);
                break;
            case '31day':
                date = this.getCancelDateValueCalDay(31);
                break;
            case '91day':
                date = this.getCancelDateValueCalDay(91);
                break;
            case '93day':
                date = this.getCancelDateValueCalDay(93);
                break;
            case '95day':
                date = this.getCancelDateValueCalDay(95);
                break;
            case '125day':
                date = this.getCancelDateValueCalDay(125);
                break;
            case '183day':
                date = this.getCancelDateValueCalDay(183);
                break;
            default:
                var date = moment(data).format("YYYY-MM-DD");
                break;
        }

        this.props.change('WirelessAdviceStep3.insurranceCancelDate', date);
        this.setState({
            ...this.state,
            isOpenCancelDate: !this.state.isOpenCancelDate,
            // cancelDate: data,
        }, () => {
        })
    }



  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let insurranceRedux = WirelessAdvice.WirelessAdviceStep3.insurrance;
    let insurranceCancelDateRedux = WirelessAdvice.WirelessAdviceStep3.insurranceCancelDate;

    return (
     <Fragment>
        <div className="form-group">
            <label className="col-form-label label-text">휴대폰 보험</label>
            {
                !this.state.isActiveDeviceInsuranceButton ? (
                    <button className="btn btn-input-form mb-0 form-control cursor-text opacity-disabled" type="button" disabled>미적용</button>
                    // <input type="text" className="form-control h-44" readOnly
                    // value={'0원'}
                    // data-toggle="modal"/>

                ) : (
                    <button className={`btn btn-input-form mb-0 ${insurranceRedux && insurranceRedux.ins_price ? '' : ' opacity-disabled'}`} type="button" onClick={() => this.showPopupPhoneInsurance()}>
                        {insurranceRedux && insurranceRedux.ins_price ? `${priceFormat(parseFloat(insurranceRedux.ins_price))}원` :  '미적용'}
                    </button>

                    // <input type="text" className="form-control h-44"
                    // value={insurranceRedux && insurranceRedux.ins_price ? `${priceFormat(parseFloat(insurranceRedux.ins_price))}원` :  '0원'}
                    // data-toggle="modal" onClick={() => this.showPopupPhoneInsurance()}/>
                )
            }

          {/* <input type="text" className="form-control h-44"
                value={insurranceRedux && insurranceRedux.ins_price ? `${priceFormat(parseFloat(insurranceRedux.ins_price))}원` :  '0원'}
                data-toggle="modal" onClick={() => this.showPopupPhoneInsurance()}/> */}
          <p className="text-right mb-0 small-color">{insurranceRedux && insurranceRedux.ins_id ? insurranceRedux.ins_name  : ''}</p>
        </div>

        <PhoneInsurancePopup
            isOpen = {this.state.isOpenPhoneInsurance}
            toggle = {this.onTogglePhoneInsurance}
            classInsurances = {this.state.classInsurances}
            phoneInsurances = {this.state.phoneInsurances.dataList}
            getPhoneInsuranceValue = {this.getPhoneInsuranceValue}
        />

        <div className="form-group">
          <div className="form-date">
            <label className="col-form-label label-text text-indents">유심</label>
            {
                insurranceRedux ? (
                    <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" onClick={() => this.showPopupCancelDate()}>
                        {insurranceCancelDateRedux ? insurranceCancelDateRedux : '해지가능일' }
                        <img src="/images/icon-calender.svg" alt="" />
                    </button>

                ) : (
                    <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" disabled>
                        해지가능일
                        <img src="/images/icon-calender.svg" alt="" />
                    </button>
                )
            }



            <CancelDatePopup
                isOpen = {this.state.isOpenCancelDate}
                toggle = {this.onToggleCancelDate}
            >
                {this.renderCancelDates()}
            </CancelDatePopup>
          </div>
        </div>
     </Fragment>
    );
  }
}

PhoneInsurance.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),

  };
};

const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PhoneInsurance);
