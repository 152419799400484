import React, { Fragment } from 'react'
import {priceFormat, roundUpNumber, getOptionalAgreementHardcording} from "../../../utils/helper";
import First from "./Left/First";
import Second from "./Left/Second";


class ShareWirelessLeft extends React.Component{

  formatCurrency = (number, separate = ",") => {
    var string = number.toString();
    var regex = /\B(?=(\d{3})+(?!\d))/g;
    var res = string.replace(/,/g, '').replace(regex, separate);
    return res;
  }

  formatNumberShow = (number) => {
    if(Number(number) == number && number % 1 === 0){
      return this.formatCurrency(Number(number).toFixed(0));
    } else {
      return number;
    }
  }

  getTotal = () => {
    let {data} = this.props;

    let result              = null;
    let wirelessAdviceStep3 = data;
    let contractMonth       = +wirelessAdviceStep3.contractMonth;
    let optionFee           = +wirelessAdviceStep3.optionFee;
    let optionalAgreement   = +(wirelessAdviceStep3?.optionalAgreement || 0);
    let conversion2G        = wirelessAdviceStep3?.conversion2G;
    let conversion2GId      = +(conversion2G?.id || 0);
    let monthFee            = 0;
    let month               = "";
    
    if(conversion2GId == 0){
      if(contractMonth == 23){
        month = "12개월 + 12개월";
      }
      else{
        month = contractMonth + "개월";
      } 

      if(optionFee == 0){
        if(contractMonth == 23){
          contractMonth = 24;
        }

        optionalAgreement = priceFormat(optionalAgreement).toString().split(',').join('');
        monthFee          = contractMonth * optionalAgreement;
        monthFee          = priceFormat(monthFee);
      }

      result = "총 할인금액(" + month + ")" + monthFee + "원";
    }
    else{
      result = "2G전환 요금할인";
    }

    return result;
  }

  render(){
    let {data} = this.props;
    //let optionalAgreement= data.optionalAgreement;
    let optionalAgreement = getOptionalAgreementHardcording(data.packageFee.id, +data.optionalAgreement);
    let optionalAgreementDefault=data.optionalAgreementDefault;
    let optionalAgreementWith2G=data.optionalAgreementWith2G;
    let monthShow = data.contractMonth;
    let monthFee = 0;
    let conversion2G = data.conversion2G;

    if (data.optionFee == 0) {
      //monthFee = priceFormat(data.contractMonth * data.optionalAgreementDefault);
      monthFee = priceFormat(optionalAgreement).toString().split(',').join('');
      monthFee = priceFormat(data.contractMonth * monthFee);
    }else{
      monthFee = 0;
      if ([1, 2, '1', '2'].includes(conversion2G?.id)) {
        optionalAgreement = '-' + priceFormat(10000)
      } else {
        optionalAgreement = 0
      }
    }

    optionalAgreement= optionalAgreement ? '-'+priceFormat(optionalAgreement) : 0;
    
    return (
      <div className="left-information total-number">
        <div className="name-product">
          <div className="name-item justify-content-between">
            <h2>단말기 정보 (A)</h2>
            <button type="button" className="btn" >단말기 비교</button>
          </div>
          <div className="name-item justify-content-between">
            <h2>요금제 정보 (B)</h2>
            <button type="button" className="btn" >요금제비교</button>
          </div>
        </div>
        <div className="flex-wrapper">
          <div className="left">
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">단말기</label>
              <div className="col-sm-8">
                <button className="btn btn-input-form btn-devices mb-0" >
                  {this.props.data?.phone?.name}
                  <span className="color-product" style={{backgroundColor: `${this.props.data?.color?.code}`}}> </span>
                </button>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">출고가</label>
              <div className="col-sm-8">
                <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-26">
                  {this.formatNumberShow(this.props.data?.startingPrice)}원
                </button>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금제</label>
              <div className="col-sm-8">
                <input type="button" className="form-control text-left h-44" value={
                  this.props.data.packageFee
                    ? this.props.data.packageFee.plac_name
                    ? this.props.data.packageFee.plac_name
                    : this.props.data.packageFee.name
                    : 'T플랜 에센스'
                } data-toggle="modal" data-target="#exampleModal12" />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">
                기본료 <br />
                <small>(vat 포함) </small>
              </label>
              <div className="col-sm-8">
                <input type="text" className="form-control" disabled value={this.props.data.basicCost ? priceFormat(this.props.data.basicCost) + '원' : "0원" } />
              </div>
            </div>
          </div>
        </div>
        <div className="tab-contract-prices">
          <ul className="list-tab">

            <li className={`tab-item ${this.props.data.optionFee == 1 ? 'active' : ''}`}>
              <img src="/images/icon-notifi.svg" alt="" />
              공시지원금
            </li>
            <li className={`tab-item ${this.props.data.optionFee == 0 ? 'active' : ''}`}>
              <img src="/images/Icon-fill.svg" alt="" />
              선택약정할인
            </li>
          </ul>
          <div className="flex-wrapper">

            <div className={`left ${this.props.data.optionFee == 1 ? 'border-0' : 'border-0 disabled-form'}`}>
              <div className="form-group row align-items-center">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mb-3">공시지원금</label>
                <div className="col-sm-8 pl-2">
                  {this.props.data.phone.telcomID == 18 ?
                    (<table style={{width : "100%"}}>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="disclosureSubsidy"
                              style={{height : "22px", verticalAlign: "middle"}}
                              {...(this.props.data.disclosureSubsidyType == "six" && {checked : true})}
                              disabled
                              id="disclosureSubsidySix"
                            />
                            <label class="col-sm-4 col-form-label label-text mb-3" for="disclosureSubsidySix">식스플랜</label>
                          </div>
                        </td>
                        <td rowSpan={2}>
                          <button className={`btn btn-prices-wp mb-0`} type="button">
                            {this.props.data.optionFee == 0 ? 0 : (this.props.data.disclosureSubsidy ? '-'+priceFormat(this.props.data.disclosureSubsidy) : 0)}
                            <span>원</span>
                          </button>
                          {(this.props.data.displayApplyDate == null || this.props.data == 'invalid date') ?
                            (<p className="small-color mb-3 pl-2"></p>) : 
                            (<p className="small-color mb-0 pl-2">
                              {this.props.data.displayApplyDate + ' 기준'}
                            </p>)
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="disclosureSubsidy"
                              style={{height : "22px", verticalAlign: "middle"}}
                              {...(this.props.data.disclosureSubsidyType == "basic" && {checked : true})}
                              disabled
                              id="disclosureSubsidyBasic"
                            />
                            <label class="col-sm-4 col-form-label label-text mb-3" for="disclosureSubsidyBasic">베이직플랜</label>
                          </div>
                        </td>
                      </tr>
                    </table>) :
                    (<Fragment>
                      <button className="btn btn-prices-wp mb-0" type="button" data-toggle="modal" data-target="#modal-05">
                        {this.props.data.optionFee == 0 ? 0 : (this.props.data.disclosureSubsidy ? '-'+priceFormat(this.props.data.disclosureSubsidy) : 0)}
                        <span>원</span>
                      </button>
                      <p className="small-color mb-0 pl-2">({this.props.data.displayApplyDate} 기준)</p>
                    </Fragment>)
                  }
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">공시추가지원</label>
                <div className="col-sm-8 pl-2">
                  <button className="btn btn-prices-wp mb-0" type="button" data-toggle="modal" data-target="#modal-06">
                    {this.props.data.optionFee == 0 ? '0' : (this.props.data.additionalGrants ? ((this.props.data.additionalGrants > 0) ?'-'+ priceFormat(this.props.data.additionalGrants) : '0') : '0') }
                    <span>원</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={`right ${this.props.data.optionFee == 0 ? '' : 'disabled-form'}`}>
              <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mb-3">선택약정 </label>
                <div className="col-sm-8">
                  <button className={"mb-0 btn" + optionalAgreement ? ' btn-prices-wp' : ''} type="button">
                    { data.optionFee ==1 ?
                      optionalAgreement :
                      (data.optionalAgreement ? optionalAgreement : 0)
                    }
                    <span>원</span></button>
                  <p className="small-color mb-0">
                    { this.getTotal() }
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">&nbsp; </label>
                <div className="col-sm-8">
                  <ul className="list-month">
                    <li className={`btn ${data.contractMonth == 12 ? 'active' : ''} `}>
                      <button className="btn" style={{paddingLeft: "0px", paddingRight: "0px", paddingTop : "0px", paddingBottom : "0px"}} type="button">12개월</button>
                    </li>
                    <li className={`btn ${data.contractMonth == 23 ? 'active' : ''} `}>
                      <button className="btn" style={{paddingLeft: "0px", paddingRight: "0px", paddingTop : "0px", paddingBottom : "0px"}} type="button">12개월 + 12개월</button>
                    </li>
                    <li className={`btn ${data.contractMonth == 24 ? 'active' : ''} `}>
                      <button className="btn" style={{paddingLeft: "0px", paddingRight: "0px", paddingTop : "0px", paddingBottom : "0px"}} type="button">24개월</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-wrapper">
          <First
            data={data}
          />

         <Second
          data={data}
         />
        </div>
      </div>
    )
  }
}

export default ShareWirelessLeft;
