 
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import { Redirect } from 'react-router-dom'
import * as routeName from './../../utils/routerName'

class WirelessCompareProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isRedirect       : false,
      type             : 1,
      model            : null,
      plan             : null,
      color            : null,
      optionFee        : null,
      installmentMonth : null,
      other            : null,
      cashPayment      : null,
      cashPaymentId    : null
    }
  }

  conpareDevice = (type) => {
    const { WirelessAdvice } = this.props;
    
    this.setState({
      isRedirect: true,
      type,
      model            : WirelessAdvice.WirelessAdviceStep3.phone.modelID || WirelessAdvice.WirelessAdviceStep3.phone.id,
      plan             : WirelessAdvice.WirelessAdviceStep3.packageFee.id,
      color            : WirelessAdvice.WirelessAdviceStep2.color.id,
      optionFee        : WirelessAdvice.WirelessAdviceStep3.optionFee,
      installmentMonth : WirelessAdvice.WirelessAdviceStep3.installmentMonth,
      other            : WirelessAdvice.WirelessAdviceStep3.other,
      cashPayment      : WirelessAdvice.WirelessAdviceStep3.cashPayment,
      cashPaymentId    : WirelessAdvice.WirelessAdviceStep3.cashPaymentId
    });
  }

  render() {
    const {
      model,            type,  plan,        color,        optionFee,
      installmentMonth, other, cashPayment, cashPaymentId
    } = this.state

    if (this.state.isRedirect) {
      return <Redirect
        push
        to={{
          pathname: routeName.COMPARE,
          search: `?model=${model}&${type == 1 ? 'plan' : 'packageFee'}=${plan}&type=${type}&referrer=1&color=${color}&option=${optionFee}&installmentMonth=${installmentMonth}&other=${other}&cashPayment=${cashPayment}&cashPaymentId=${cashPaymentId}`,
        }}
      />;
    }

    return (
      <div className="name-product">
        <div className="name-item justify-content-between">
          <h2>단말기 정보 (A)</h2>
          <button type="button" className="btn" onClick={() => this.conpareDevice(1)}>단말기 비교</button>
        </div>
        <div className="name-item justify-content-between">
          <h2>요금제 정보 (B)</h2>
          <button type="button" className="btn" onClick={() => this.conpareDevice(2)}>요금제비교</button>
        </div>
      </div>
    );
  }
}

WirelessCompareProduct.propTypes = {
  WirelessAdvice: PropTypes.object
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessCompareProduct);
