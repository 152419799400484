 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import Conversion2GModal from "./Conversion2GModal"

class WirelessConversion2G extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpenModal:false
    }
  }


    openPopup = () => this.setState({isOpenModal: !this.state.isOpenModal})

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

      let data2G = WirelessAdvice.WirelessAdviceStep3.conversion2G

      let showText="미적용"
      if(data2G != null) {
          if(data2G?.id != 0) {
              if(WirelessAdvice.WirelessAdviceStep3.optionFee == 1) {

                  if(data2G?.id ==2) {
                      showText=data2G?.value
                  }else {
                      showText="공시지원금 할인"
                  }
              }else {
                  showText="70%할인"
              }
          }
      }

    return (
      <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">2G전환</label>
        <div className="col-sm-8">
          <button className='btn btn-input-form mb-0' type="button" data-toggle="modal" data-target="#modal-28 " onClick={this.openPopup}>
            {/*{data2G && data2G.value ? data2G.value : "미적용 "}*/}
            {showText === '미적용' ? <span className='opacity-disabled'>미적용</span> : showText}
          </button>
          <Conversion2GModal isOpenModal={this.state.isOpenModal} openPopup={this.openPopup} />
        </div>
      </div>
    );
  }
}

WirelessConversion2G.propTypes = {
};

const mapStateToProps = state => {
  return {
    // WirelessAdvice: state.form.WirelessAdvice.values,
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

// const FORM_NAME = 'WirelessAdvice';
// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

// const withConnect = connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(WirelessConversion2G);

// export default reduxForm({
//     form: FORM_NAME,
//     destroyOnUnmount: false,
// })(withConnect);

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default compose(
  withConnect,
  // withReduxForm,
)(WirelessConversion2G);
