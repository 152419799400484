import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types'
import Tooltip from "../../components/Tooltip";
import api from "../../utils/api";
import { useRef, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import {
    getAdviceWireless
} from './../../store/actions/dataStore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {allowAccessMenu, openPopupMenu} from './../../store/actions/auth';
import RegisterAccessPopup from './../AccessMenu/RegisterAccessPopup';
import AuthAccessPopup from './../AccessMenu/AuthAccessPopup';
import * as helper from './../../utils/helper';
import { hideLoading, showLoading } from "../../store/actions/ui";
import * as routerName from './../../utils/routerName';


class TransactionPoint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdd: 0,
            data: [],
            telcom: {},
            valTelcom: [],
            valTelcomId: [],
            valCus: '',
            valPhone: '',
            valMana: '',
            storeId: '',
            rowData: {
                stl_deputy_name: "",
                stl_deputy_phone: "",
                stl_id: '',
                stl_name: '',
                telcom_name: ""
            },
            dataTrans: {},
            checkedTel: 'checked',
            errForm: '',
            actionForm: 'false',
            // isOpenPopup: props.auth.isOpenPopupMenu,
            isOpenAuthPopup: false,
            isOpenRegisterPopup: false,
        };
        this.myRef = React.createRef();
        this.myNoti = React.createRef();
    }
      
    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    componentDidMount() { 
        document.body.classList.add("body_wireless_advice");
        let {
            user,
            isAllowAccessMenu,
        } = this.props.auth;
        if (window._prePath) {
            // đang f5 lại page đó
            // alert('f5')
            var preURL = `/${routerName.HISTORY_BUY_PRODUCT}`;
            window._prePath = '';
        } else {
            // navigate from route
            // alert('notf5');
            var preURL = this.props.location.state?.from; 
        }        

        let checkMatchURL = helper.checkMatchURL(preURL);
        let checkAllow = checkMatchURL && isAllowAccessMenu ? true : false;

        this.props.allowAccessMenu(checkAllow);
        if (!checkAllow) {
            this.props.openPopupMenu(true);
        }


        const { auth } = this.props;
        const { telcomProviders } = this.props;
        this.props.showLoading();
        this.props.getAdviceWireless().then(res => {
            this.setState({
                telcom: res,
                storeId: auth.store.sto_id,
            });
        }).catch(() => {

        });
        let data = {
            page: 1,
            store_id: auth.store.sto_id
        }
        api.transaction_point.getList(data)
            .then(response => {
                this.setState({
                    data: response.data.data.data ? response.data.data.data : [],
                    dataTrans: response.data.data ? response.data.data : {},
                });
                this.props.hideLoading();
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    onToggleMainPopup = () => {
        this.props.openPopupMenu(false)
    }

    allowAccessMenu = () => {
        this.props.allowAccessMenu(true);
        this.setState({
           ...this.state,
           isOpenAuthPopup: false,
           isOpenRegisterPopup: false,
        })
    }

    search = (current_page = 1) => {

        let data = {
            page: current_page,
            store_id: this.state.storeId
        }
        api.transaction_point.getList(data)
            .then(response => {
                this.setState({
                    data: response.data.data.data ? response.data.data.data : [],
                    dataTrans: response.data.data ? response.data.data : {},
                });

            })

    }
    getTransPoint(id) {
        api.transaction_point.getTransPoint(id)
            .then(response => {

                this.setState({
                    isAdd: 0,
                    rowData: response.data.data ? response.data.data : [],
                    valCus: response.data.data.stl_name,
                    valMana: response.data.data.stl_deputy_name,
                    valPhone: response.data.data.stl_deputy_phone,
                    valTelcom: response.data.data.telcom_name,
                    valTelcomId: response.data.data.telcom_id,
                    actionForm: 'false',
                    errForm:''
                });


                const checkeds = document.getElementsByTagName('input');
                for (let i = 0; i < checkeds.length; i++) {
                    if (response.data.data.telcom_id.search(checkeds[i].value) != -1) {
                        checkeds[i].checked = true
                    } else {
                        checkeds[i].checked = false
                    }
                }

            })
    }

    resetForm() {
        this.setState({
            isAdd: 1,
            valCus: '',
            valPhone: '',
            valMana: '',
            rowData: {
                stl_deputy_name: "",
                stl_deputy_phone: "",
                stl_id: '',
                stl_name: '',
                telcom_name: ""
            },
            valTelcomId: [],
            actionForm: 'false',
            errForm: '',
        })
        const checkeds = document.getElementsByTagName('input');
        for (let i = 0; i < checkeds.length; i++) {
            checkeds[i].checked = false
        }
    }
    renderTransactionPoint(data) {
        // return0
        // let data = this.state ? this.state : [];
        if (data.length > 0) {
            return (data.map((item, key) => {
                return (
                    <Fragment key={key}>

                        <tr>
                            <td className="text-left">{this.state.dataTrans && this.state.dataTrans.current_page == 1 ? key : ((this.state.dataTrans.current_page - 1) * 10 + key)}</td>
                            <td className="text-left">{item.stl_name}</td>
                            <td className="text-left">{item.telcom_name}</td>
                            <td className="text-left">{item.stl_deputy_name}</td>
                            <td className="text-left">{item.stl_deputy_phone}</td>
                            <td className="d-flex float-right">
                            <Tooltip message={"수정"}
                                    position={'top'}
                            >
                                <a onClick={() => this.getTransPoint(item.stl_id)} data-toggle="modal" data-target="#modal-41" className="pr-3">
                                    <i className="far fa-edit" />
                                </a>
                            </Tooltip>
                            <Tooltip message={"삭제"}
                                    position={'top'}
                            >
                                <a onClick={() => this.setId(item.stl_id)} data-toggle="modal" data-target="#delete_coupon">
                                    <i className="far fa-trash-alt" />
                                </a>
                            </Tooltip>
                                
                               
                            </td>
                        </tr>
                    </Fragment>
                );
            }));

        } else {
            if(data.length==0){
                return (
                    <Fragment >
                        <tr>
                            <td className="align-middle text-center" colSpan="6" >데이터가 없습니다!</td>
                        </tr>
    
                    </Fragment>
                );
            }
        }

    }

    setId(id) {
        this.setState({
            id: id
        });
    }


    submitForm() {
        this.setState({
            actionForm: 'true'
        })
        let data = {
            telcom_id: this.state.valTelcomId,
            telcom_name: this.state.valTelcom,
            stl_name: this.state.valCus,
            stl_deputy_name: this.state.valMana,
            stl_deputy_phone: this.state.valPhone,
            store_id: this.state.storeId,
        }

        // if (data.telcom_id.length == 0) {
        //     // toast.error('xin chon nha mang', {
        //     //     position: "top-right",
        //     //     autoClose: 5000,
        //     //     hideProgressBar: false,
        //     //     closeOnClick: true,
        //     //     pauseOnHover: true,
        //     //     draggable: true,
        //     //     progress: undefined,
        //     //     });
        //     return;
        // }

        api.transaction_point.create(data)
            .then(response => {
                // toast.success('tao moi thanh cong', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
                // this.myNoti.current.click();
                this.myRef.current.click();
                this.myNoti.current.click();
                const { auth } = this.props;
                let data = {
                    page: 1,
                    store_id: auth.store.sto_id
                }
                api.transaction_point.getList(data)
                    .then(response => {
                        this.setState({
                            data: response.data.data.data ? response.data.data.data : [],
                            dataTrans: response.data.data ? response.data.data : {},
                        });
                        this.props.hideLoading();
                    })
                // this.componentDidMount()


            },
            ).catch(err => {
                // what now?
                this.setState({
                    errForm: err.response || ''
                })
                // if(err.response.data.errors.stl_deputy_name){
                //     toast.error(err.response.data.errors.stl_deputy_name[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }

                // if(err.response.data.errors.stl_name){
                //     toast.error(err.response.data.errors.stl_name[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }

                // if(err.response.data.errors.stl_deputy_phone){
                //     toast.error(err.response.data.errors.stl_deputy_phone[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }
            })
    }

    submitFormUpdate() {
        this.setState({
            actionForm: 'true'
        })
        let data = {
            telcom_id: typeof this.state.valTelcomId == "string" ? this.state.valTelcomId.split(',') : this.state.valTelcomId,
            telcom_name: this.state.valTelcom,
            stl_name: this.state.valCus,
            stl_deputy_name: this.state.valMana,
            stl_deputy_phone: this.state.valPhone,
            store_id: this.state.storeId,
            stl_id: this.state.rowData.stl_id,
        }
        if (data.telcom_id.length == 0) {
            // toast.error('xin chon nha mang', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
            return;
        }
        api.transaction_point.update(data)
            .then(response => {

                // toast.success('cap nhat thanh cong', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
                this.myRef.current.click();
                this.myNoti.current.click();
                const { auth } = this.props;
                let data = {
                    page: 1,
                    store_id: auth.store.sto_id
                }
                api.transaction_point.getList(data)
                    .then(response => {
                        this.setState({
                            data: response.data.data.data ? response.data.data.data : [],
                            dataTrans: response.data.data ? response.data.data : {},
                        });
                        this.props.hideLoading();
                    })

                // this.componentDidMount()
              

            }).catch(err => {
                // what now?
                this.setState({
                    errForm: err.response
                })
                // if(err.response.data.errors.stl_deputy_name){
                //     toast.error(err.response.data.errors.stl_deputy_name[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }

                // if(err.response.data.errors.stl_name){
                //     toast.error(err.response.data.errors.stl_name[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }

                // if(err.response.data.errors.stl_deputy_phone){
                //     toast.error(err.response.data.errors.stl_deputy_phone[0], {
                //         position: "top-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         });
                // }
            })
    }

    onSubmmitFromData = (e) => { 
        if(this.state.rowData && this.state.rowData.stl_id != '') {
            this.submitFormUpdate() 
        } else {
            this.submitForm()
        }
        e.preventDefault();
    }
    renderForm() {
        const errLabel = {
            color: 'red',
        };

        let data = this.state.telcom
        let arrChil = data.telcomChildren ? data.telcomChildren : []
        let arrParent = data.telcomParents ? (data.telcomParents[0].id==17 ? data.telcomParents.reverse() : data.telcomParents) : []

        let arr = arrChil.concat(arrParent)

        return (
            <Fragment>

                <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" id="modal-41" aria-labelledby="add_seller" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-transactionpoint" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0">
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                </button>
                            </div>
                            <div className="modal-body p-0">
                            <form onSubmit={(e) => this.onSubmmitFromData(e)}>
                                <div className="container p-0">
                                    <div className="title_header mt-3">
                                        <h5>{this.state.isAdd ? '거래처 추가' : '거래처 수정'}</h5>
                                    </div>
                                    <div className="form_add_seller p-0">
                                       
                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label className="label-text">거래처명</label>
                                                    <input type="text" className="form-control h-44" value={this.state.valCus} onChange={(e) => { this.setState({ valCus: e.target.value }) }} placeholder="거래처명" />
                                                    <div className="valid-feedback">
                                                        Looks good!
                                                    </div>
                                                    {/* <p style={errLabel} hidden={this.state.errForm.data || (this.state.valCus != '') || this.state.actionForm != 'true' ? true : false}><small>해당 필드를 입력해 주세요!</small></p> */}
                                                    <p style={errLabel} hidden={this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_name ? false : true}><small>{this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_name ? this.state.errForm.data.errors.stl_name[0] : ''}</small></p>
                                                </div>
                                            </div>
                                            <div className="form-row mt-2">
                                                <div className="col-12">
                                                    <label className="label-text mb-3">사용 통신사</label>
                                                </div>


                                                {this.renderTelcom(arr.reverse())}
                                                <p style={errLabel} hidden={this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.telcom_id ? false : true}><small>{this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.telcom_id ? this.state.errForm.data.errors.telcom_id[0] : ''}</small></p>
                                            </div>
                                            <div className="form-row mt-2">
                                                <div className="col-md-6 mb-3">
                                                    <label className="label-text">담당자</label>
                                                    <input type="text" value={this.state.valMana} onChange={(e) => { this.setState({ valMana: e.target.value }) }} className="form-control h-44" placeholder="담당자" />
                                                    <div className="valid-feedback">
                                                        Looks good!
                                                    </div>
                                                    <p style={errLabel} hidden={this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_deputy_name ? false : true}><small>{this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_deputy_name ? this.state.errForm.data.errors.stl_deputy_name[0] : ''}</small></p>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="label-text">전화번호</label>
                                                    <input type="text" value={this.state.valPhone} onChange={(e) => { this.setState({ valPhone: e.target.value }) }} className="form-control h-44" placeholder="전화번호" />
                                                    <div className="valid-feedback">
                                                        Looks good!
                                                    </div>
                                                    <p style={errLabel} hidden={this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_deputy_phone ? false : true}><small>{this.state.errForm && this.state.errForm.data && this.state.errForm.data.errors && this.state.errForm.data.errors.stl_deputy_phone ? this.state.errForm.data.errors.stl_deputy_phone[0] : ''}</small></p>
                                                    {/* <p style={errLabel} hidden={(this.state.valPhone!='')||this.state.actionForm!='true'?true:false}><small>해당 필드를 입력해 주세요!</small></p> */}
                                                    {/* <p style={errLabel} hidden={(this.state.valPhone==''||this.state.actionForm!='true')||(!isNaN(Number(this.state.valPhone)))?true:false}><small>숫자로 입력해 주세요!</small></p> */}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="w-100 text-center footer-transactionpoint">
                                                    <button type="button" className="btn btn_close mr-2" ref={this.myRef} data-dismiss="modal">취소</button>
                                                    <button className="btn btn_save ml-2"
                                                        type="submit"
                                                        // data-dismiss={!isNaN(Number(this.state.valPhone)) && this.state.valTelcomId.length != 0 && this.state.valCus != '' && this.state.valPhone != '' && this.state.valMana != '' ? "" : ''}
                                                    >{this.state.rowData && this.state.rowData.stl_id != '' ? '저장' : '추가'}</button>
                                                </div>
                                            </div>
                                       
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    renderTelcom(data) {
        let val = data?data:'';
        return (val.map((item, key) => {
            return (
                <Fragment key={key}>

                    <div className="col-md-3 d-flex justify-content-between">
                        <div className="form-check form-check-inline " >
                            <input className="form-check-input" type="checkbox" name="telcom[]" onChange={this.getValueTelcom.bind(this, item.id)} value={item.id} id={item.name} />
                            <label className="form-check-label label_title" htmlFor={item.name}>{item.name}</label>
                        </div>
                    </div>
                </Fragment>
            );
        }));

    }
    delete() {
        api.transaction_point.delete(this.state.id)
            .then(response => {
                const { auth } = this.props;
                let data = {
                    page: 1,
                    store_id: auth.store.sto_id
                }
                api.transaction_point.getList(data)
                    .then(response => {
                        this.setState({
                            data: response.data.data.data ? response.data.data.data : [],
                            dataTrans: response.data.data ? response.data.data : {},
                        });
                        this.props.hideLoading();
                    })
                // this.componentDidMount();
            })
    }
    getValueTelcom(index, event) {
        const { name } = event.target;
        const checkedArr = [];
        let value;
        if (event.target.type !== 'checkbox') {
            value = event.target.value;
        } else {
            const checkeds = document.getElementsByTagName('input');
            for (let i = 0; i < checkeds.length; i++) {
                if (checkeds[i].checked) {
                    checkedArr.push(checkeds[i].value);
                }
            }
            value = checkedArr;
        }

        // const { valTelcom } = this.state;
        // valTelcom[name] = value;

        this.setState({
            valTelcomId: value,

        });
    }
    renderPaging() {
        // return

        let data = this.state.dataTrans && this.state.dataTrans.data ? this.state.dataTrans : '';
        if (data.data != '') {
            if (data.last_page - data.current_page > 3)
                if(data.current_page==1)
                    return (
                        <Fragment >
                            <li className="page-item item-previous" >
                                <a className="page-link" onClick={() => data.current_page > 1 ? this.search(data.current_page - 1) : ''} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item active"><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 1)}>{data.current_page + 1}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 2)}>{data.current_page + 2}</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" >
                                <a className="page-link" onClick={() => this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
                else
                    return (
                        <Fragment >
                            <li className="page-item item-previous" >
                                <a className="page-link" onClick={() => data.current_page > 1 ? this.search(data.current_page - 1) : ''} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item "><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item" hidden={data.current_page==2?true:false}><a className="page-link" href="#">...</a></li>
                            <li className="page-item active"><a className="page-link" onClick={() => this.search(data.current_page)}>{data.current_page}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 1)}>{data.current_page + 1}</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" >
                                <a className="page-link" onClick={() => this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    ); 
            else
                if(data.last_page>4)
                    return (
                        <Fragment >
                            <li className="page-item item-previous" hidden={data.last_page && data.last_page == 1 ? true : false}>
                                <a className="page-link" onClick={() => data.current_page == 1 ? '' : this.search(data.current_page - 1)} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item "><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li  hidden={data.last_page-data.current_page < 3 ? true : false} className={(data.last_page - 3) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 3)}>{data.last_page ? (data.last_page - 3) : ''}</a></li>
                            <li  className={(data.last_page - 2) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 2)}>{data.last_page ? (data.last_page - 2) : ''}</a></li>
                            
                            <li hidden={data.last_page-data.current_page > 2 ? true : false} className={(data.last_page - 1) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 1)}>{data.last_page ? (data.last_page - 1) : ''}</a></li>
                            <li className="page-item" hidden={data.last_page-data.current_page < 3 ? true : false} ><a className="page-link" href="#">...</a></li>
                            <li hidden={data.last_page == 1 ? true : false} className={(data.last_page - 0) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" hidden={data.last_page && data.last_page == 1 ? true : false} >
                                <a className="page-link" onClick={() => data.last_page == data.current_page ? '' : this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
                else
                    return (
                        <Fragment >
                            <li className="page-item item-previous" hidden={data.last_page && data.last_page == 1 ? true : false}>
                                <a className="page-link" onClick={() => data.current_page == 1 ? '' : this.search(data.current_page - 1)} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li hidden={data.last_page < 4 ? true : false} className={(data.last_page - 3) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 3)}>{data.last_page ? (data.last_page - 3) : ''}</a></li>
                            <li hidden={data.last_page < 3 ? true : false} className={(data.last_page - 2) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 2)}>{data.last_page ? (data.last_page - 2) : ''}</a></li>
                            <li hidden={data.last_page < 2 ? true : false} className={(data.last_page - 1) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 1)}>{data.last_page ? (data.last_page - 1) : ''}</a></li>
                            <li hidden={data.last_page == 1 ? true : false} className={(data.last_page - 0) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" hidden={data.last_page && data.last_page == 1 ? true : false} >
                                <a className="page-link" onClick={() => data.last_page == data.current_page ? '' : this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
                    
        }

    }
    render() {
        const {
            user,
            isOpenPopupMenu,
            isAllowAccessMenu,
        } = this.props.auth;

        if (this.props.auth.isAllowAccessMenu) {
        const {
            data
        } = this.state;
        const notify = () => toast("Wow so easy !");
        return (
            <Fragment>

                <section className="wrapper_coupon">
                    <div className="container">
                        <div className="seller_manager">
                            <div className="box_header justify-content-end pb-4">
                                <button className="btn btn_create btn_point" onClick={() => this.resetForm()} data-toggle="modal" data-target="#modal-41">추가</button>
                            </div>
                            <div>
                                <table className="table table-borderless table_striped">
                                    <thead className="thead_bg">
                                        <tr><th className="text-left">NO</th>
                                            <th className="text-left">거래처</th>
                                            <th className="text-left">사용 통신사</th>
                                            <th className="text-left">담당자</th>
                                            <th className="text-left">전화번호</th>
                                            <th className="text-right"></th>
                                        </tr></thead>
                                    <tbody>
                                        {this.renderTransactionPoint(data)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>


                {this.renderForm()}


                {/* model delete */}
                <div className="modal fade" id="delete_coupon" tabindex="-1" role="dialog" aria-labelledby="delete_coupon" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-reset-product" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center px-2">
                                <span className="notification_delete">선택한 쿠폰 삭제하시겠습니까?</span>
                            </div>
                            <div className="modal-footer justify-content-center border-top-0">
                                <button type="button" className="btn btn_close" data-dismiss="modal">취소</button>
                                <button type="button" className="btn btn-submit" onClick={() => this.delete()} data-dismiss="modal">확인</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* model thong bao */}
                <button hidden ref={this.myNoti}  data-toggle="modal" data-target="#modal-103">
                    <i className="far fa-trash-alt" />
                </button>
                <div className="modal fade"  id="modal-103" tabIndex={-12} role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="content-register-modal">
                                    <div className="img-check">
                                        <img src="/images/Icon-modal-01.svg" alt="" />
                                    </div>
                                    <h4 className="text-center">
                                    {this.state.rowData && this.state.rowData.stl_id != '' ?'업데이트 되었습니다!':'등록되었습니다!'}  
                                    </h4>
                                    <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal">확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav aria-label="Page navigation example" className="pagination-notifi">
                    <ul className="pagination">

                        {this.renderPaging()}

                    </ul>
                </nav>


                <ToastContainer />

            </Fragment>

        );
    } else {
        return (
            <Fragment>
                <RegisterAccessPopup 
                    // isOpen = {this.state.isOpenRegisterPopup}
                    isOpen = {isOpenPopupMenu && !isAllowAccessMenu && !user.password_menu}
                    toggle = {this.onToggleMainPopup}
                    allowAccessMenu = {this.allowAccessMenu}
                />

                <AuthAccessPopup 
                    // isOpen = {this.state.isOpenAuthPopup}
                    isOpen = {isOpenPopupMenu && !isAllowAccessMenu && user.password_menu}
                    toggle = {this.onToggleMainPopup}
                    allowAccessMenu = {this.allowAccessMenu}
                />
            </Fragment>
        )
    }
    }
}


TransactionPoint.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getAdviceWireless: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        telcomProviders: state.dataStore.telcomProviders,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getAdviceWireless: () => dispatch(getAdviceWireless()),
        allowAccessMenu: (status) => dispatch(allowAccessMenu(status)),
        openPopupMenu: (status) => dispatch(openPopupMenu(status)),
    }
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
// export default TransactionPoint;
export default compose(
    withConnect,
    // withReduxForm,
)(TransactionPoint);
