 
import PropTypes from 'prop-types';
import React, { Component, Fragment,useState  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PenaltyInquiryModal from "./PenaltyInquiryModal"
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {checkBlockFeature} from "../../../utils/helper";
import PopupInactive from '../../PopupInactive';

class PenaltyInquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false,
            isOpenPopupDisable: false,
            iconLock: null,
        }
    }

    openPopupPenatly = () => this.setState({isOpenModal: !this.state.isOpenModal})

    openPopupDisable = () => {
        this.setState({
            isOpenPopupDisable: true,
            iconLock: 'iconLock',
        })
    }
    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    iconLock: null
                })
            }, 500)
        })
    }

    render() {
        return (

            <Fragment>
                <button
                    className="btn btn-gray-white btn-review"
                    type="button" data-toggle="modal"
                    data-target="#modal-10 "
                    onClick={checkBlockFeature() ? (this.openPopupDisable) : (this.openPopupPenatly)}>
                    해지시 예상 위약금
                </button>
                <PenaltyInquiryModal  isOpenModal={this.state.isOpenModal}  openPopupPenatly={this.openPopupPenatly} />

                <PopupInactive 
                    isOpenModal={this.state.isOpenPopupDisable} 
                    closePopupDisable={this.closePopupDisableHandle}
                    iconLock={this.state.iconLock}
                />
            </Fragment>
        );
    }
}

PenaltyInquiry.propTypes = {
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    // withReduxForm,
)(PenaltyInquiry);
