
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Link} from 'react-router-dom';
import api from "../../../utils/api";
import ModalPrint from "../../ModalPrint";
import ModalPrintPageWireless from "../ModalPrintPageWireless";
import MoveToPCPopup  from './../../../components/Print/MoveToPCPopup';
import GuidePrintPopup  from './../../../components/Print/GuidePrintPopup';
import PopupInactive from './../../../components/PopupInactive';
import {numberMathFloor, priceFormat} from "../../../utils/helper";
import {FORM_BUILDER_TYPE} from './../../../utils/constant';

class PrintModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalPrint : false,
      modalPrintPage : false,
      isOpenGuide: false,
      isOpenPopupDisable: false,
      title: null,
      content: null,
      iconLock: null,
    }
  }

  componentDidMount () {

  }

  // typePrint = (type) => {
  //   if(this.props.auth.role == "Member" && this.props.WirelessAdvice.id) {
  //     let data = {
  //       sec_consultant_id : this.props.WirelessAdvice.id,
  //       sec_type : window.currentDevice == 'web' ? 0 : 1,
  //       sec_uid : this.props.auth.user.id,
  //       sec_type_export : type
  //     }
  //     api.consultant.createStatisticConsultantExport(data)
  //   }
  // }

  print = () => {
    this.setState({
      modalPrint : !this.state.modalPrint
    },()=>{
      // if(this.state.modalPrint){
      //   this.typePrint(1)
      // }
    })

  }

  printPage = () => {
    this.setState({
      modalPrintPage : !this.state.modalPrintPage
    },()=>{
      // if(this.state.modalPrintPage) {
      //   this.typePrint(0)
      // }
    })
  }

  onClickPrintPageElectronic = () => {
    const { WirelessAdvice,dataStore} = this.props;
    if(WirelessAdvice.WirelessAdviceStep1.telcomID == 17 || WirelessAdvice.WirelessAdviceStep1.telcomID == 18 || WirelessAdvice.WirelessAdviceStep1.telcomID ==  19) {
      let advancedDiscountCardID = WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardID ? WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardID : false;
      let discountCardID = WirelessAdvice.WirelessAdviceStep3.discountCardId ? WirelessAdvice.WirelessAdviceStep3.discountCardId : false;
      var costNumber = 0;
      let welfare=WirelessAdvice.WirelessAdviceStep3.welfareDiscount
      if(welfare && welfare.cost) {
        costNumber =  Number(welfare.cost);
      }
        costNumber=parseInt(parseFloat(costNumber).toFixed(1))

      let href = process.env.REACT_APP_API_URL+'print-page-electronic?id='+this.props.WirelessAdvice.id+'&activeAdvancedDiscountCard='+advancedDiscountCardID+'&activeDiscountCard='+discountCardID+'&welfare='+priceFormat(numberMathFloor(costNumber))
      if (window.currentDevice == 'web') {
        window.shareOnWeb(href);
        this.props.openPopup();
      } else {
        // this.setState({
        //   ...this.state,
        //   isOpenMoveToPC: true
        // })
        // debugger
        // return 

        let telcomID = WirelessAdvice.WirelessAdviceStep1?.telcom;
        let packageFee = WirelessAdvice.WirelessAdviceStep3?.packageFee?.plac_name || WirelessAdvice.WirelessAdviceStep3?.packageFee?.name
        let device = WirelessAdvice.WirelessAdviceStep3?.phone?.device_name || WirelessAdvice.WirelessAdviceStep3?.phone?.name
        let info = `${device} </br> ${packageFee}`
        let more_info = `${device} ${packageFee}`
        let total = WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyDevice;
  
  
        let data = {
          'uid': this.props.auth?.user?.id,
          'print_type': FORM_BUILDER_TYPE.ELECTRONIC_WIRELESS,
          'telcomID': telcomID,
          'info': info,
          'more_info': more_info,
          'total': total,
          'link': href,
        }
  
        api.appPrint.create(data).then(res => {
          if (res?.data?.data) {
            this.setState({
              ...this.state,
              isOpenMoveToPC: true
            })
          }
        })
      }
    }else{
      alert(WirelessAdvice.WirelessAdviceStep1.telcomName+' 통신사는 전자청약 서식지가 출력되지 않습니다.')
    }

  }

  onToggleMoveToPC = () => {
    this.setState({
      ...this.state,
      isOpenMoveToPC: !this.state.isOpenMoveToPC,
    })
  }

  onToggleGuide = () => {
    this.setState({
        isOpenGuide: !this.state.isOpenGuide,
    })
  }

  showGuidePrint = () => {
    //get data .....
    this.onToggleGuide();
  }

  openPopupDisable = (state) => {
    if (state) {
      this.setState({
        title: '실제 개통에 사용하지 마십시오.',
        content: `
          베타테스트 기간입니다.<br>
          테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
          서류 미비나 금액 오류 등이 발생할 수 있습니다.
        `
      })
    }
    this.setState({
      isOpenPopupDisable: true
    })
  }

  closePopupDisableHandle = () => {
    this.setState({
      isOpenPopupDisable: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          iconLock: null
        })
      }, 500)
    })
  }

  modelPrintClose = () => {
    this.setState({
      title: null,
      content: null
    })
  }

  render() {
    const { WirelessAdvice,dataStore} = this.props;
    let networks = [];
    let currentNetwork = WirelessAdvice.WirelessAdviceStep1.telcomID;
    if(dataStore && dataStore.telcomProviders && dataStore.telcomProviders.telcomParents.length > 0) {
      dataStore.telcomProviders.telcomParents.map(item => {
        if(item.id != currentNetwork){
          networks.push(item)
        }
      })
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-print'}
          onOpened={this.modalOpened}
        >
          <div className="modal-header border-bottom-0">
            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopup}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body mt-4 mb-3">
            <h5 className="title">출력</h5>
            <div className="button-print">
              <button
                className="btn btn-print"
                onClick={this.printPage}
              >
                <img src="/images/print-icon.svg" alt="" />
                <span>서식지 출력하기 </span>
              </button>
              <button
                className="btn btn-print "
                onClick={this.print}
                // id={'popup-print'}
                // data-print-content={process.env.REACT_APP_HOSTNAME+'/popup-wirelees?id='+this.props.WirelessAdvice.id}
              >
                {/*<Link to={'/popup-wirelless'} />*/}
                <img
                  src="/images/print-icon.svg" alt="" />
                <span>견적서 출력하기 </span>
              </button>

              <button
                  className="btn btn-print"
                  // onClick={() => this.openPopupDisable(true)}
                  onClick={this.onClickPrintPageElectronic}
              >
                <img src="/images/print-icon.svg" alt=""/>
                  <span>전자청약서식지 출력하기</span>
              </button>

            </div>
            <div className="check-footer-print">
              {
                window.currentDevice !== 'web' && (
                  <button className="btn" type="button" onClick={this.showGuidePrint}>
                    PC에서 출력안내
                  </button>
                )
              } 
            </div>

          </div>
          <iframe id="ifmcontentstoprint" style={{height: '0px', width: '0px', position: 'absolute'}}></iframe>
        </Modal>


      <ModalPrint
        isOpen={this.state.modalPrint}
        toggle={this.print}
        linkAdress={process.env.REACT_APP_API_URL_DEV+'print-wireless?id='+this.props.WirelessAdvice.id}
        advancedDiscountCardID={
          +(WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 192 &&
          WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ?
          null : WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardID
        }
        discountCardID={WirelessAdvice.WirelessAdviceStep3.discountCardId}
        telcomID={WirelessAdvice.WirelessAdviceStep1.telcomID}
        idButton={'popup-print'}
        popupOutSide={this.props.openPopup}
      />

      <ModalPrintPageWireless
        isOpen={this.state.modalPrintPage}
        toggle={this.printPage}
        networks={networks}
        currentNetwork={currentNetwork}
        consultantId={WirelessAdvice.id}
        toggleParent={this.props.openPopup}
      />

      <MoveToPCPopup
        isOpen = {this.state.isOpenMoveToPC}
        toggle = {this.onToggleMoveToPC}
      />

      <GuidePrintPopup
        isOpen = {this.state.isOpenGuide}
        toggle = {this.onToggleGuide}
        toggleParent={this.props.openPopup}
      />

      <PopupInactive 
        isOpenModal={this.state.isOpenPopupDisable} 
        closePopupDisable={this.closePopupDisableHandle}
        title={this.state.title || null}
        content={this.state.content || null}
        callback={this.onClickPrintPageElectronic}
        onModelClosed={this.modelPrintClose}
        iconLock={this.state.iconLock}
      />

      </React.Fragment>

    );
  }
}

PrintModal.propTypes = {
};

const mapStateToProps = state => {
  return {
    auth : state.auth,
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    dataStore : state.dataStore
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(PrintModal);
