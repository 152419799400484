import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import TelcomList from './../Telcom/ListTelcom'
import {Modal} from 'reactstrap';

const ModalSelectTelcom = ({isOpenModal, toggleModal, telcomProviders, onSelectedTelcom}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggleModal}
      fade={true}
      // backdrop={"static"}
      contentClassName=""
      className={'modal-dialog modal-dialog-centered modal-select-telecom'}
    >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">통신사 선택</h5>
          <button type="button" className="close close-modal" onClick={toggleModal}>
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
        </div>
        <div className="modal-body px-0">
          <div className="mt-2">
            <TelcomList
              telcomProviders={telcomProviders}
              onSelectedTelcom={onSelectedTelcom}
            />
          </div>
          </div>
    </Modal>
  );
}

ModalSelectTelcom.propTypes = {
  telcomProviders: PropTypes.object.isRequired,
  onSelectedTelcom: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
};

export default ModalSelectTelcom;

