import { OPTION_CHOOSE_2G, KT_S23_SBS_INS_GRC_VAL, KT_S23_SBS_PRM_Y_EDT } from "./constant";
import { OPTION_FEE, INSTALLMENT_PAYMENT_METHOD } from './../utils/constant'
import moment from 'moment';
import * as $ from 'jquery';
import _ from "lodash";
import * as routerName from "./routerName";

export function fileToBase64(inputFile) {
    return new Promise(resolve => {
        var file = inputFile;
        var reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result);
        };

        reader.readAsDataURL(file);
    });
}

/******** convert number with format , *********/
export function numberWithCommas(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

export function getFirstNumberPhone() {
    return ['010', '011', '016', '017', '018', '019'];
}

export function getStoreFirstNumberPhone() {
    return [
        '02',
        '010',
        '031',
        '032',
        '033',
        '041',
        '042',
        '043',
        '044',
        '051',
        '052',
        '053',
        '054',
        '055',
        '061',
        '062',
        '063',
        '064',
        '0502',
        '0505',
        '070',
        '0303',
    ];
}

export function getEmailDomains() {
    let email = '직접입력, Gmail.com, chol.com, dreamwiz.com, spanpal.com, freechal.com, hanafos.com, hanmail.net, hanmir.com, hotmail.com, korea.com, nate.com, naver.com, netian.com, paran.com, sayclub.com, yahoo.co.kr, yahoo.com'.toLocaleLowerCase()
    return email.split(', ')
}

export function getFirstFax() {
    let email = '02, 051, 053, 032, 062, 042, 052, 044, 031, 033, 043, 041, 063, 061, 054, 055, 064'
    return email.split(', ')
}

export function getYearCardPayment() {
    let currentYear = (new Date()).getFullYear()
    return _.range(+currentYear, +currentYear + 10, 1)
    // return ['2021', '2022', '2023', '2024', '2025', '2026'];
}

export function getMonthCardPayment() {
    return _.range(1, 13, 1)
    // return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
}

export function getUsedMonth() {
    return ['3', '6', '12'];
}

export function getPaymentCompany() {
    return [
        { code: '002', name: '산업은행' },
        { code: '003', name: '기업은행' },
        { code: '004', name: '국민은행' },
        { code: '007', name: '수협중앙회' },
        { code: '011', name: '농협은행' },
        { code: '020', name: '우리은행' },
        { code: '023', name: 'SC은행' },
        { code: '027', name: '한국씨티은행' },
        { code: '031', name: '대구은행' },
        { code: '032', name: '부산은행' },
        { code: '034', name: '광주은행' },
        { code: '035', name: '제주은행' },
        { code: '037', name: '전북은행' },
        { code: '039', name: '경남은행' },
        { code: '045', name: '새마을금고' },
        { code: '048', name: '신협중앙회' },
        { code: '071', name: '우체국' },
        { code: '081', name: 'KEB하나은행' },
        { code: '088', name: '신한은행' },
        { code: '089', name: '케이뱅크' },
        { code: '209', name: '유안타증권' },
        { code: '240', name: '삼성증권' },
    ];
}

export function isIssetArray(dataArray) {
    if (typeof dataArray !== 'undefined' && dataArray.length > 0) {
        return true;
    } else {
        return false;
    }
}


export function addOrRemoveToArray(array, item) {
    return array.indexOf(item) === -1 ? array.push(item) : array.splice(array.indexOf(item), 1);
}



export function priceFormat(price, n = 0, x = 3, s = ',', c = '.') {
    if (price) {
        price = +price
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
        let num = price.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    }
    return 0
}

export function priceFormatDiff(price, n = 0, x = 3, s = ',', c = '.') {
    price = +price
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
    let num = price.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function priceFormatRound(price, n = 0, x = 3, s = ',', c = '.') {
    if (price) {
        price = +price
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
        let num = price.toFixed(Math.max(0, ~~n));

        return num;
    }
    return 0
}

/**
 * tinh chi phi hang thang
 * @param  {number} total     [description]
 * @param  {Number} interest_rate [description]
 * @param  {Number} duration    [description]
 * @param  {Number} delay_period  [description]
 * @return {array}        [description]
 */
export function calcInstallmentFeePrincipalAndInterestByMonth(total, interest_rate = 0, duration = 0, delay_period = 0) {
    interest_rate = +((interest_rate / 100).toFixed(4))
    let rest = total
    let range = [...Array(duration).keys()];
    let loan_interest = 0
    let monthly_payment = 0
    let arr = []
    range.map((index) => {
        loan_interest = rest * (interest_rate / 12)
        monthly_payment = (total * (interest_rate / 12) * (Math.pow((1 + (interest_rate / 12)), (duration - delay_period)))) / ((Math.pow((1 + (interest_rate / 12)), (duration - delay_period))) - 1)
        if (arr[index - 1]) {
            rest = arr[index - 1].rest - monthly_payment + loan_interest
        } else {
            rest = rest - monthly_payment + loan_interest
        }
        if (rest < 0) {
            rest = 0
        }
        arr.push({
            loan_interest: loan_interest,
            monthly_payment: monthly_payment,
            rest: rest,
        })
    })
    return arr
}

/**
 * tinh chi phi hang thang
 * @param  {number} total     [description]
 * @param  {Number} interest_rate [description]
 * @param  {Number} duration    [description]
 * @param  {Number} delay_period  [description]
 * @return {array}        [description]
 */
export function calcInstallmentFeePrincipalByMonth(total, interest_rate = 0, duration = 0, delay_period = 0) {
    interest_rate = +((interest_rate / 100).toFixed(4))
    let rest = total
    let range = [...Array(duration).keys()];
    let loan_interest = 0
    let t = total / (duration - delay_period)
    let monthly_payment = 0
    let arr = []
    range.map((index) => {
        loan_interest = rest * (interest_rate / 12)
        monthly_payment = t + loan_interest
        if (arr[index - 1]) {
            rest = arr[index - 1].rest - monthly_payment + loan_interest
        } else {
            rest = rest - monthly_payment + loan_interest
        }
        if (rest < 0) {
            rest = 0
        }
        arr.push({
            loan_interest: loan_interest,
            monthly_payment: monthly_payment,
            rest: rest,
        })
    })
    return arr
}

/**
 * tinh chi phi theo nam
 * @param  {number} total     [description]
 * @param  {Number} interest_rate [description]
 * @param  {Number} duration    [description]
 * @param  {Number} delay_period  [description]
 * @return {array}        [description]
 */
export function calcInstallmentFeePrincipalAndInterestByYear(total, interest_rate = 0, duration = 0, delay_period = 0) {
    interest_rate = +((interest_rate / 100).toFixed(4))
    let rest = total
    let range = [...Array(duration * 12).keys()];
    let loan_interest = 0
    let monthly_payment = 0
    let arr = []
    range.map((index) => {
        loan_interest = rest * (interest_rate / 12)
        // monthly_payment = (total * (interest_rate / 12) * (Math.pow((1 + (interest_rate / 12)), (duration - delay_period)))) / ((Math.pow((1 + (interest_rate / 12)), (duration - delay_period))) - 1)
        monthly_payment = (total * (interest_rate / 12) * (Math.pow((1 + (interest_rate / 12)), (duration * 12 - delay_period)))) / ((Math.pow((1 + (interest_rate / 12)), (duration * 12 - delay_period))) - 1)
        if (arr[index - 1]) {
            rest = arr[index - 1].rest - monthly_payment + loan_interest
        } else {
            rest = rest - monthly_payment + loan_interest
        }
        if (rest < 0) {
            rest = 0
        }
        arr.push({
            loan_interest: loan_interest,
            monthly_payment: monthly_payment,
            rest: rest,
        })
    })
    return arr
}

/**
 * get list Welfare Discount
 * @param  {number} basicCost [description]
 * @return {array}           [description]
 */
export const getWelfareDiscountList = (basicCost) => {
    return [
        { id: 0, value: "미적용", cost: 0 },
        {
            id: 1,
            value: '장애인 (35% 할인)',
            cost: basicCost != null ? basicCost * 0.35 : 69000 * 0.35
        },
        {
            id: 2,
            value: '국가유공자 (35% 할인)',
            cost: basicCost != null ? basicCost * 0.35 : 69000 * 0.35
        },
        { id: 3, value: '기초생활수급자 생계/의료(28,600원 할인)', cost: 28600 },
        { id: 4, value: '기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost: 23650 },
        { id: 5, value: '차상위계층(12,100원 할인, 최대 23,650원 할인)', cost: 23650 },
        { id: 6, value: '만 65세 이상 기초연금수급자(최대 12,100원)', cost: 12100 },
    ]
}

export const roundUpNumber = (number) => {
    number = +numberDownFloor(number)
    let a = number % 10
    if (a > 5 && a < 6) {
        number += 1
    }
    number = Math.floor(number)
    if (number % 10 != 0) {
        number -= 1
    }
    return Math.round(number / 10) * 10
}

export const roundUpNumberPlan = (number) => {
    if (number % 10 != 0 && number % 10 != 5) {
        number -= 1
        return Math.round(number / 10) * 10
    }else{
        return number
    }
}

export const numberMathFloor = (number) => {
    return Math.floor(number)
}

export const numberDownFloor = (number) => {
    return parseFloat(number).toFixed(1)
}

/**
 * update wireless step3 data from api
 * @param  {object} WirelessAdviceStep3 old wireless step3 data
 * @param  {object} data                data response
 * @return {object}                     new wireless step3 data
 */
export const updateWirelessStep3 = (WirelessAdviceStep3, data) => {
    let WirelessAdviceStep3Clone = {
        ...WirelessAdviceStep3,
        phone: {
            ...data?.consultant_wireless?.model,
            short_name: data?.telcom?.code,
        },
        promotion: data?.consultant_wireless?.promotion_id ? data?.consultant_wireless?.plan_promotion : (data?.consultant_wireless?.promo_cost === null ? {} : {
            planID: "input_live",
            promo_name: "직접 입력",
            promo_cost: data?.consultant_wireless?.promo_cost
        }),
        advancedDiscountCard: +data?.consultant_wireless?.advanced_discount_card,
        advancedDiscountCardObject: data?.consultant_wireless?.adcc ?? null,
        basicCost: data?.consultant_wireless?.basic_cost,
        additionalGrants: data?.consultant_wireless?.additional_grants,
        cashPayment: data?.consultant_wireless?.cash_payment,
        contractMonth: data?.consultant_wireless?.contract_month,
        disclosureSubsidy: data?.consultant_wireless?.disclosure_subsidy,
        discountCard: data?.consultant_wireless?.discount_card,
        discountCardObject: {...(data?.consultant_wireless?.dcc ?? {}), discount_card_option: data.consultant_wireless?.discount_card_option?.split(',')[0]},
        extraServices: data?.extra_services,
        installmentMonth: data?.consultant_wireless?.installment_month,
        note: data?.note,
        optionFee: data?.consultant_wireless?.option_fee,
        // optionalAgreementWith2G: 0,
        optionalAgreement: data?.consultant_wireless?.optional_agreement,
        other: data?.consultant_wireless?.other,
        pointDiscount: data?.consultant_wireless?.point_discount,
        startingPrice: data?.consultant_wireless?.starting_price,
        applyUsedProgram: data?.consultant_wireless?.apply_used_program,
        usedPhoneMemberShip: data?.consultant_wireless?.used_phone_member_ship,
        insurrance: data?.consultant_wireless?.device_insurance,
        packageFee: data?.consultant_wireless?.plan,
        saler: data?.saler,
        welfareDiscount: data?.consultant_wireless?.welfare_discount,
        customerId: data?.customer_id,
        customerNote: data?.customer_note,
        displayApplyDate: moment(data?.consultant_wireless?.apply_date).format('YYYY-MM-DD') != 'Invalid date' ? moment(data?.consultant_wireless?.apply_date).format('YYYY-MM-DD') : null, // ngày tham gia (기준): data?.customer_note,
        rateAgreement: data?.consultant_wireless?.rate_agreement,
        discostChange: data?.consultant_wireless?.discost_change,
        // promotionDurationDiscountOne: null,
        // promotionDurationDiscountTwo: null,
        interestRate: data?.telcom?.installment_rate,
        dataPlanNewDateChange: data?.consultant_wireless?.plan_change,
        namePlanNewDateChange: data?.consultant_wireless?.plan_change?.name,
        stateLoadPotential: data?.stateLoadPotential,
        telcomPotentialCustomer: data?.telcomPotentialCustomer,
        insurranceCancelDate: data?.consultant_wireless?.insurrance_cancel_date ? moment(data?.consultant_wireless?.insurrance_cancel_date).format('YYYY-MM-DD') : null,
        subscriptionFee: data?.consultant_wireless?.member_ship_fee,
        planChangeDate: data?.consultant_wireless?.plan_change_date,
        // conversion2G: data?.conversion_2g,
        // block A
        installmentPrincipal: data?.consultant_wireless?.installment_principal, // Nguyên giá trả góp (할부원금)
        installmentFee: data?.consultant_wireless?.installment_fee, // Tổng lãi suất trả góp (할부수수료)
        installmentMonthly: data?.consultant_wireless?.installment_monthly, // Tiền trả góp thiết bị theo tháng (월 단말 할부금) = installment_monthly_principal + installment_monthly_fee
        installmentMonthlyPrincipal: data?.consultant_wireless?.installment_monthly_principal, // Tiền trả góp thiết bị theo tháng Nguyên giá trả góp
        installmentMonthlyFee: data?.consultant_wireless?.installment_monthly_fee, // Tiền trả góp thiết bị theo tháng lãi suất trả góp
        // block B
        monthlyFee: data?.consultant_wireless?.monthly_fee, // chi phi theo thang (월 요금)
        // block A + B
        estimatedAmountMonth: data?.consultant_wireless?.estimated_amount_month, // Số tiền dự kiến tháng này (당월 예상 금액) = installment_monthly + money_remaining_month
        moneyRemainingMonth: data?.consultant_wireless?.money_remaining_month, // Số tiền dự kiến tháng này (요금)
        amountMonthlyDevice: data?.consultant_wireless?.amount_monthly_device, // Tiền yêu cầu theo tháng A + B (월별 청구금액)
        // block C
        amountMonthlyOption: data?.consultant_wireless?.amount_monthly_option, // Tiền yêu cầu theo tháng C (월별 청구금액)
        // clock A + B + C
        amountMonthlyTotal: data?.consultant_wireless?.amount_monthly_total, // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
        reportCreated: +data?.sale_report_count, // đã tạo báo cáo bán hàng hay chưa
        combineCondition: data?.combine_discount_condition,
        combineDevice: data?.combine_wired_device,
        combineOption: data?.combine_wired_device_option,
        combinePlans: data?.consultant_combine_plans,
        usedPhoneCompensation: data?.consultant_wireless?.used_program,
        mutualService: data?.consultant_wireless?.mutual_service,
        isRateAgreement: [4, 5].includes(+data?.combine_discount_condition?.id),
        promotionDurationDiscountOne: data?.consultant_wireless?.promotion_one,
        promotionDurationDiscountTow: data?.consultant_wireless?.promotion_two,
        soldierDiscount : data?.consultant_wireless?.soldier_discount,
        conversion_price : data?.conversionDb?.conversion_price,
        conversion_apply_date : data?.conversionDb?.conversion_apply_date,
        manual_conversion_confirm : data?.conversionDb?.manual_conversion_confirm,
        conversion_price_input : data?.consultant_wireless?.conversion_price,
        conversion_price_input_add : data?.consultant_wireless?.conversion_price_add,
    }
    if (data?.consultant_wireless?.telcom_sim) {
        let sim = {
            ...data.consultant_wireless.telcom_sim,
            name_sim: data.consultant_wireless.telcom_sim.name
        }
        WirelessAdviceStep3Clone.sim = sim
    }
    if (data?.consultant_wireless?.mutual_service == null) {
        WirelessAdviceStep3Clone.mutualService = { mus_id: 0, mus_name: '미적용' }
    }
    //load
    // if ((data?.consultant_wireless?.conversion_2g == '1' && data?.consultant_wireless?.optionFee == '1') ||
    //     (data?.consultant_wireless?.conversion_2g == '2' && data?.consultant_wireless?.optionFee == '1')
    // ) {
    //   WirelessAdviceStep3Clone.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;
    // }else{
    //   WirelessAdviceStep3Clone.optionalAgreement = data?.consultant_wireless?.optional_agreement;
    // }
    // data?.consultant_wireless?.conversion_2g

    let data2G = [
        { id: 0, value: "미적용" },
        { id: 1, value: '적용' },
        { id: 2, value: '무약정' },
    ]
    let conversion2G = null
    data2G.map((item) => {
        if (item.id == data?.consultant_wireless?.conversion_2g) {
            conversion2G = item
        }
    })
    WirelessAdviceStep3Clone.conversion2G = conversion2G

    let basicCost = WirelessAdviceStep3Clone.basicCost
    let id = +WirelessAdviceStep3Clone.welfareDiscount
    let optionalAgreement = WirelessAdviceStep3Clone.optionalAgreement;

    if(WirelessAdviceStep3Clone.conversion2G?.id == null && optionFee == 1) {
        optionalAgreement = 0;
    }

    let rateAgreement=(WirelessAdviceStep3Clone.rateAgreement && !WirelessAdviceStep3Clone.isRateAgreement) ? WirelessAdviceStep3Clone.rateAgreement : 0
    let promotion=WirelessAdviceStep3Clone.promotion && WirelessAdviceStep3Clone.promotion?.promo_cost ?
            WirelessAdviceStep3Clone.promotion?.promo_cost : 0
    //let combinedDiscount = WirelessAdviceStep3Clone.combinedDiscount ? WirelessAdviceStep3Clone.combinedDiscount : 0
    let combinedDiscount = 0;

    if(WirelessAdviceStep3Clone.combinePlans.length > 0) {
        combinedDiscount = roundUpNumber(+WirelessAdviceStep3Clone.combinePlans[0].discount_wireless_price +
            +WirelessAdviceStep3Clone.combinePlans[0].discount_tv)
    }else {
        combinedDiscount=WirelessAdviceStep3Clone.combinedDiscount ? WirelessAdviceStep3Clone.combinedDiscount : 0
    }

    combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1))

    let dataTelcom = data?.telcom?.short_name?.toUpperCase()
    let optionFee = WirelessAdviceStep3Clone.optionFee
    conversion2G=WirelessAdviceStep3Clone.conversion2G?.id
    let object = optionWelfare(basicCost,roundUpNumber(basicCost),{id},optionalAgreement,rateAgreement,promotion,combinedDiscount,dataTelcom,optionFee,conversion2G)
    WirelessAdviceStep3Clone.welfareDiscount = object

    WirelessAdviceStep3Clone.extraServices = WirelessAdviceStep3Clone.extraServices.map((e) => ({
        ...e,
        cancel_date: e.pivot.date
    }))

    return WirelessAdviceStep3Clone
}

/**
 * 프로모션 값을 찾아서 반환한다.
 * 
 * @param {*} WirelessAdviceStep3 
 */
export function caclWirelessStep3PromotionValue(wirelessAdviceStep3) {
    let optionFee = +(wirelessAdviceStep3.optionFee || 0) // 1 = tiền hỗ trợ thêm (추가지원금) | 0 = giảm giá cam kết lựa chọn (선택약정할인)
    let promotion = 0;

    if(optionFee == 1){
        promotion = +(wirelessAdviceStep3.promotion?.promo_cost || 0) // promotion (프로모션)
    }
    else{
        promotion = +(wirelessAdviceStep3.promotion?.optional_agreement || 0) // promotion (프로모션)

        if(wirelessAdviceStep3.promotion?.planID && wirelessAdviceStep3.promotion?.planID == "input_live") {
            promotion = +(wirelessAdviceStep3.promotion?.promo_cost || 0) // promotion (프로모션)
        }
    }

    return promotion;
}

/**
 * cacl wireless step 3
 * @param  {number} installmentPaymentMethod [description]
 * @param  {object} WirelessAdviceStep3      [description]
 * @return {object}                          [description]
 */
export const caclWirelessStep3 = (installmentPaymentMethod = 0, WirelessAdviceStep3, recipe_days_remaining = 2, changePlan = false) => {
    let wirelessAdviceStep3 = { ...WirelessAdviceStep3 };

    //삭제 필요
    //console.log("caclWirelessStep3의 wirelessAdviceStep3 값 : ", wirelessAdviceStep3)    
    if (WirelessAdviceStep3.reportCreated) {
        return wirelessAdviceStep3
    }

    let startingPrice = +(WirelessAdviceStep3.startingPrice || 0) // Giá khởi điểm (출고가)
    // let packageFee = 0 // gói phí (요금제)

    let basicCost = 0
    let rateAgreement = 0

    if (!changePlan) {
        basicCost = +(WirelessAdviceStep3.basicCost || 0) // chi phí cơ bản (기본료)
        rateAgreement = +(WirelessAdviceStep3.rateAgreement || 0) // cam kết gói phí (요금약정)
    }
    else {
        if (WirelessAdviceStep3.dataPlanNewDateChange && WirelessAdviceStep3.dataPlanNewDateChange.cost_display) {
            basicCost = +(Math.floor(WirelessAdviceStep3.dataPlanNewDateChange.cost_display * 1.1) || 0) // chi phí cơ bản (기본료)
            rateAgreement = +(WirelessAdviceStep3.rateAgreement || 0) // cam kết gói phí (요금약정)
        }
        else {
            basicCost = +(WirelessAdviceStep3.basicCost || 0) // chi phí cơ bản (기본료)
            rateAgreement = +(WirelessAdviceStep3.rateAgreement || 0) // cam kết gói phí (요금약정)
        }
    }

    // basicCost = roundUpNumber(basicCost)
    wirelessAdviceStep3.basicCost = basicCost
    let optionFee = +(WirelessAdviceStep3.optionFee || 0) // 1 = tiền hỗ trợ thêm (추가지원금) | 0 = giảm giá cam kết lựa chọn (선택약정할인)
    let disclosureSubsidy = +(WirelessAdviceStep3.disclosureSubsidy || 0) // tiền hỗ trợ thông báo (공시지원금)
    let additionalGrants = +(WirelessAdviceStep3.additionalGrants || 0) // tiền hỗ trợ thêm (추가지원금)

    if((wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190 && optionFee == OPTION_FEE.disclosureSubsidy){ // KT KT S23 FE 폰 구독이고 공시지원금이면
        if((startingPrice - disclosureSubsidy - additionalGrants - KT_S23_SBS_INS_GRC_VAL) < 0){
            additionalGrants                     = startingPrice - disclosureSubsidy - KT_S23_SBS_INS_GRC_VAL;
            wirelessAdviceStep3.additionalGrants = additionalGrants;
        }
    }

    let optionalAgreement = +(WirelessAdviceStep3.optionalAgreement || 0) // cam kết lựa chọn (선택약정)
    // let contractMonth = 0 // số tháng cam kết (약정개월)

    let pointDiscount = +(WirelessAdviceStep3.pointDiscount || 0) // giảm giá point (포인트 할인)
    let conversionPriceInput = +(WirelessAdviceStep3.conversion_price_input || 0) // 전환지원금
    let conversionPriceInputAdd = +(WirelessAdviceStep3.conversion_price_input_add || 0) // 전환지원금

    if (optionFee != OPTION_FEE.disclosureSubsidy) { // 공시지원금이 아니라면
        conversionPriceInput    = 0;
        conversionPriceInputAdd = 0;
    }

    let advancedDiscountCard = +(WirelessAdviceStep3.advancedDiscountCard || 0) // thẻ giảm giá trước (선할인 카드)
    // let usedPhoneCompensation = 0 // bồi thường điện thoại cũ (중고폰보상 프로그램)
    let other = +(WirelessAdviceStep3.other || 0) // khác (기타)
    let cashPayment = +(WirelessAdviceStep3.cashPayment || 0) // nạp tiền mặt (현금납부)

    if((wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
        cashPayment = startingPrice - (pointDiscount + advancedDiscountCard + other + KT_S23_SBS_INS_GRC_VAL);

        if (optionFee == OPTION_FEE.disclosureSubsidy) { // 공시지원금이면
            cashPayment = cashPayment - disclosureSubsidy - additionalGrants;
        }
        
        if(cashPayment < 0){
            cashPayment = 0;
        }
        
        wirelessAdviceStep3.cashPayment   = cashPayment;
        wirelessAdviceStep3.cashPaymentId = 2;
    }
    
    let installmentMonth = +(WirelessAdviceStep3.installmentMonth || 0) // số tháng trả góp (할부개월)
    let promotion = caclWirelessStep3PromotionValue(wirelessAdviceStep3); // 프로모션
    let promotionDurationDiscountOne = +(WirelessAdviceStep3.promotionDurationDiscountOne || 0) // promotion (프로모션)
    let promotionDurationDiscountTwo = +(WirelessAdviceStep3.promotionDurationDiscountTwo || 0) // promotion (프로모션)
    let combinedDiscount = roundUpNumber(+( +(WirelessAdviceStep3.combinePlans[0]?.discount_wireless_price || 0) + +(WirelessAdviceStep3.combinePlans[0]?.discount_tv || 0))) // giảm giá kết hợp (결합 할인)
    // WirelessAdviceStep3.combinedDiscount = combinedDiscount;

//삭제 필요
//console.log("요금제 변경 시 무선상담 초기 combinedDiscount 값 : ",combinedDiscount) 
    wirelessAdviceStep3 = {
        ...wirelessAdviceStep3,
        combinedDiscount
    }

    let welfareDiscount = +(WirelessAdviceStep3.welfareDiscount?.cost || 0) // giảm giá phúc lợi (복지 할인)
    let discountCard = +(WirelessAdviceStep3.discountCard || 0) // thẻ giảm giá (할인카드)
    let mutualService = +(WirelessAdviceStep3.mutualService?.price_month || 0) // dịch vụ tương trợ (상조서비스)
    let conversion2G = WirelessAdviceStep3.conversion2G // chuyển đổi 2G (2G전환)

    // let sim = +(WirelessAdviceStep3.sim?.price || 0) // SIM (유심)
    let insurrance = +(WirelessAdviceStep3.insurrance?.ins_price || 0) // Bảo hiểm thiết bị (휴대폰 보험)
    // let insurranceCancelDate = 0 // Ngày có thể hủy (휴대폰 보험)

    let extraServices = 0 // Dịch vụ bổ sung
    WirelessAdviceStep3.extraServices.map((service) => {
        extraServices += +service.ets_price
    })
    let interestRate = +(WirelessAdviceStep3.interestRate || 0) // Ngày có thể hủy (휴대폰 보험)

    let applyUsedProgram = +(WirelessAdviceStep3.applyUsedProgram || 0) // Tiền yêu cầu theo tháng sau khi apply bồi thường DT cũ

    // Nguyên giá trả góp (할부원금)
    let installmentPrincipal = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + cashPayment + other + conversionPriceInput + conversionPriceInputAdd);
    // 0 = giảm giá cam kết lựa chọn (선택약정할인)

    if(advancedDiscountCard){ // 카드할인이 있는 경우 다시 계산
        installmentPrincipal = installmentPrincipal + advancedDiscountCard;
        advancedDiscountCard = Number(wirelessAdviceStep3.advancedDiscountCardObject.total) || advancedDiscountCard; 
    
        if(wirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                let instPrincipalUsedPhone2 = Number(wirelessAdviceStep3.startingPrice) * Number(wirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate) / 100; // 2구간 할부원금

                if(Math.round(installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1) < advancedDiscountCard){
                    advancedDiscountCard = Math.round(installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1);

                    wirelessAdviceStep3.advancedDiscountCard = advancedDiscountCard;
                }
            }
        }

        installmentPrincipal = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + cashPayment + other + conversionPriceInput + conversionPriceInputAdd);
    }
  
    if (optionFee === OPTION_FEE.optionalAgreement) {
        installmentPrincipal += disclosureSubsidy + additionalGrants;
    }

    if (wirelessAdviceStep3.cashPaymentId == 1) {
        /*
        if (optionFee === OPTION_FEE.optionalAgreement) {
            cashPayment += installmentPrincipal;
        }
        else {
            cashPayment = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other);
        }
        */

        if(wirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                installmentPrincipal = installmentPrincipal + cashPayment;

                let instPrincipalUsedPhone2 = wirelessAdviceStep3.startingPrice * wirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

                cashPayment = installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35);
                cashPayment = Math.round(cashPayment - 1);
            }
            else{
                if (optionFee === OPTION_FEE.optionalAgreement) {
                    cashPayment += installmentPrincipal;
                }
                else {
                    cashPayment = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other + conversionPriceInput + conversionPriceInputAdd);
                }
            }
        }
        else{
            if (optionFee === OPTION_FEE.optionalAgreement) {
                cashPayment += installmentPrincipal;
            }
            else {
                cashPayment = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other + conversionPriceInput + conversionPriceInputAdd);
            }
        }

        if (cashPayment < 0) {
            cashPayment = 0
        }

        wirelessAdviceStep3.cashPayment = cashPayment;

        //nstallmentPrincipal = 0;

        if(wirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                installmentPrincipal = installmentPrincipal - cashPayment;
            }
            else{
                installmentPrincipal = 0;
            }
        }
        else{
            installmentPrincipal = 0;
        }
    }

    if((wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
        installmentPrincipal = installmentPrincipal - KT_S23_SBS_INS_GRC_VAL;
    }
    else if((wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 192){ // KT S23 프리미엄 Y에디션
        installmentPrincipal = installmentPrincipal - KT_S23_SBS_PRM_Y_EDT;
    }

    if (installmentPrincipal < 0) {
        installmentPrincipal = 0
    }

    wirelessAdviceStep3.installmentPrincipal = installmentPrincipal;

    let installmentFeeArray = [];

    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
        installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(installmentPrincipal, interestRate, installmentMonth)
    }

    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
        installmentFeeArray = calcInstallmentFeePrincipalByMonth(installmentPrincipal, interestRate, installmentMonth)
    }

    // Tổng lãi suất trả góp (할부수수료)
    let installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

    if(+(wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentFee = roundUpNumber(Math.round(installmentPrincipal * 0.118));
    }

    if (installmentFee < 0) {
        installmentFee = 0
    }

    installmentFee = Math.round(installmentFee)
    wirelessAdviceStep3.installmentFee = installmentFee;

    let telcomID = wirelessAdviceStep3.phone.telcomID;

    // 0221. LG 할부 수수료 10개월 이하일 때 0원인 값을 다른 개월 수와 동일하게 적용
    //if(telcomID == 18 && (installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3)){
    //    installmentFee = 0;
    //    wirelessAdviceStep3.installmentFee = 0;
    //}
    //

    let soldierDiscount = wirelessAdviceStep3.soldierDiscount ? +wirelessAdviceStep3.soldierDiscount : 0;

    if(telcomID == 17){
        soldierDiscount = wirelessAdviceStep3.soldierSkt;

        if(soldierDiscount != null){
            if((wirelessAdviceStep3.welfareDiscount?.id || 0) != 6){ // 만 65세 이상 기초연금수급자(최대 12,100원)
                soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount)  * 0.2;
            }
            else{
                soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount)  * 0.2;
            }
        }
        else{
            soldierDiscount = 0;
        }
    }

    if(telcomID == 18){
        soldierDiscount = wirelessAdviceStep3.soldierLguplus;

        if(soldierDiscount != null){
            if((wirelessAdviceStep3.welfareDiscount?.id || 0) != 6){ // 만 65세 이상 기초연금수급자(최대 12,100원)
                soldierDiscount = (basicCost - rateAgreement - promotion - combinedDiscount - welfareDiscount)  * 0.2;
            }
            else{
                soldierDiscount = (basicCost - rateAgreement - promotion - combinedDiscount)  * 0.2;
            }
        }
        else{
            soldierDiscount = 0;
        }
    }

    if(telcomID == 19){
        soldierDiscount = wirelessAdviceStep3.soldierKt;

        if(soldierDiscount != null){
            if((wirelessAdviceStep3.welfareDiscount?.id || 0) != 6){ // 만 65세 이상 기초연금수급자(최대 12,100원)
                soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount)  * 0.2;
            }
            else{
                soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount)  * 0.2;
            }
        }
        else{
            soldierDiscount = 0;
        }
    }
    
    wirelessAdviceStep3.soldierDiscount = soldierDiscount;

    // Nguyên giá trả góp/số tháng trả góp
    let installmentMonthlyPrincipal = installmentPrincipal / installmentMonth;

    if(+(wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentMonthlyPrincipal = 0;
    }

    // Tổng lãi suất trả góp/số tháng trả góp
    let installmentMonthlyFee = Math.round(installmentFee / installmentMonth);

    if(+(wirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentMonthlyFee = installmentFee / 24;
    }

    // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
    let installmentMonthly = installmentMonthlyPrincipal + installmentMonthlyFee

    if (installmentMonthly < 0) {
        installmentMonthly = 0
    }

//삭제 필요
//console.log("무선상담 내의 installmentMonthlyPrincipal 값 계산", installmentMonthlyPrincipal)
//console.log("무선상담 내의 installmentMonthlyFee 값 계산", installmentMonthlyFee)  


    // let roundd = roundUpNumber(Math.ceil(installmentMonthly))
    let roundd = ((installmentMonthly))  // sai số CT BTDTC
    installmentMonthlyPrincipal = Math.round(installmentMonthlyPrincipal)
    installmentMonthlyFee = roundd - installmentMonthlyPrincipal
    // installmentMonthly = roundd
    installmentMonthly = roundUpNumber(Math.ceil(roundd))
    wirelessAdviceStep3.installmentMonthlyPrincipal = installmentMonthlyPrincipal
    wirelessAdviceStep3.installmentMonthlyFee = installmentMonthlyFee
    wirelessAdviceStep3.installmentMonthly = installmentMonthly
    // wirelessAdviceStep3.installmentMonthly = roundUpNumber(Math.ceil(installmentMonthly))
    installmentMonthly = roundd

    //Nếu có chọn Trả góp tạm hoãn LG  || instMonthlyDefer_1: tiền trả góp hàng tháng đợt 1
    if (wirelessAdviceStep3?.instMonthlyDefer_1) {
        installmentMonthly = wirelessAdviceStep3.instMonthlyDefer_1
        wirelessAdviceStep3.installmentMonthly = installmentMonthly
    }

    // chi phí theo tháng (월 요금)
    let monthlyFee = basicCost - (rateAgreement + optionalAgreement + promotion + promotionDurationDiscountOne + promotionDurationDiscountTwo + combinedDiscount + welfareDiscount + discountCard + mutualService + soldierDiscount)
//삭제 필요
//console.log("무선상담 내의 installmentmonthly 값 계산", installmentMonthly)  
//console.log("무선상담 내의 monthlyFee 값 계산 항목 : ", basicCost, rateAgreement, optionalAgreement, promotion, promotionDurationDiscountOne, promotionDurationDiscountTwo, combinedDiscount, welfareDiscount, discountCard, mutualService, soldierDiscount)


    // 1 = tiền hỗ trợ thêm(HTTB) (추가지원금)
    if (optionFee === OPTION_FEE.disclosureSubsidy) {
        monthlyFee += optionalAgreement
    }

    if (optionFee === OPTION_FEE.disclosureSubsidy && [1, 2, '1', '2'].includes(conversion2G?.id)) {
        monthlyFee -= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB
    }

    if (monthlyFee < 0) {
        monthlyFee = 0
    }

    // monthlyFee += applyUsedProgram // check new
    monthlyFee = roundUpNumber(monthlyFee)
    wirelessAdviceStep3.monthlyFee = monthlyFee

    // Tiền yêu cầu theo tháng A + B (월별 청구금액)
    // let amountMonthlyDevice = installmentMonthly + monthlyFee  //old
    let amountMonthlyDevice = wirelessAdviceStep3.installmentMonthly + wirelessAdviceStep3.monthlyFee + applyUsedProgram
    wirelessAdviceStep3.amountMonthlyDevice = amountMonthlyDevice

//삭제 필요
//console.log("wirelessAdviceStep3.installmentMonthly 값, 무선상담 시 출력되는 값 : ", wirelessAdviceStep3.installmentMonthly)
//console.log("wirelessAdviceStep3.monthlyFee 값, 무선상담 시 출력되는 값 : ", wirelessAdviceStep3.monthlyFee)


    // (Số tiền thanh toán hàng tháng/tổng số ngày tháng hiện tại) X số ngày còn lại tháng hiện tại
    let day = moment().date()
    let lastDay = moment().endOf('month').date()
    // let moneyRemainingMonth = ((monthlyFee / lastDay) * (lastDay - day + 1)) + installmentMonthly + applyUsedProgram // tien con lai tinh theo cong thuc = B / ... + A  OLD
    let moneyRemainingMonth = (( (monthlyFee + applyUsedProgram) / lastDay) * (lastDay - day + 1)) + (installmentMonthly)   //new: installmentMonthly (must get origin value)
    if (recipe_days_remaining == 1) {
        moneyRemainingMonth = (amountMonthlyDevice / lastDay) * (lastDay - day + 1) // tien con lai == 1 thi tinh theo cong thuc la (A+B) / ...
    }
    // Số tiền dự kiến tháng này
    let estimatedAmountMonth = roundUpNumber(moneyRemainingMonth)
    // moneyRemainingMonth = estimatedAmountMonth - installmentMonthly
    wirelessAdviceStep3.estimatedAmountMonth = estimatedAmountMonth
    wirelessAdviceStep3.moneyRemainingMonth = moneyRemainingMonth
    // Gói phí muốn đổi
    // let planNewDateChange = +(WirelessAdviceStep3.planNewDateChange || 0)
    // chi phí dự kiến khi thay đổi gói phí
    // let estimatedRatesChangingPlans = amountMonthlyDevice - basicCost + planNewDateChange
    // Tiền yêu cầu theo tháng C (월별 청구금액)
    let amountMonthlyOption = roundUpNumber(insurrance + extraServices)

    // //Nếu có chọn ctrinh BTDT cũ (not tạm hoãn LG) => cộng thêm DV thêm tương ứng tới C (amountMonthlyOption)
    // if (wirelessAdviceStep3?.usedPhoneCompensation?.ets_id) {
    //     amountMonthlyOption = roundUpNumber(amountMonthlyOption + parseFloat(wirelessAdviceStep3.usedPhoneCompensation.ets_price))
    // }

    wirelessAdviceStep3.amountMonthlyOption = amountMonthlyOption

    // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
    let amountMonthlyTotal = amountMonthlyDevice + amountMonthlyOption
    wirelessAdviceStep3.amountMonthlyTotal = amountMonthlyTotal;

    if(wirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
        if(wirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
            let instType = wirelessAdviceStep3.usedPhoneCompensation.inst_type;

            if(instType == 83 || instType ==84){ // 구간할부/공시지원금 || 구간할부/선택약정
                let instPrincipalUsedPhone2 = wirelessAdviceStep3.startingPrice * wirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금
                let instPrincipalUsedPhone1 = wirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2; // 1구간 할부원금
    
                if(instPrincipalUsedPhone1 < 0){
                    instPrincipalUsedPhone1 = 0;
                }
    
                if(instPrincipalUsedPhone1 < (instPrincipalUsedPhone2 / 65 * 35)){ // 최소 금액 미만
                    wirelessAdviceStep3.usedPhoneCompensation = {"tup_id": "", "tup_name": "미적용", "instType": 0};
                }
                else{
                    installmentMonth =Number(wirelessAdviceStep3.usedPhoneCompensation.tup_inst_circle_mo);

                    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
                        installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(instPrincipalUsedPhone1, interestRate, installmentMonth);
                    }

                    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
                        installmentFeeArray = calcInstallmentFeePrincipalByMonth(instPrincipalUsedPhone1, interestRate, installmentMonth);
                    }

                    installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

                    if (installmentFee < 0) {
                        installmentFee = 0;
                    }

                    installmentFee = Math.round(installmentFee);

                    if(installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3){
                        installmentFee  = 0;
                    }

                    installmentFee = installmentFee + (instPrincipalUsedPhone2 * interestRate / 100 / 12 * installmentMonth);

                    let installmentMonthlyFee = installmentFee / installmentMonth;
                    let installmentMonthly    = (instPrincipalUsedPhone1 / installmentMonth) + installmentMonthlyFee;
                    let amountMonthlyDevice   = wirelessAdviceStep3.monthlyFee + installmentMonthly + Number(wirelessAdviceStep3.usedPhoneCompensation.ets_price);
                    let moneyRemainingMonth   = roundUpNumber((( (wirelessAdviceStep3.monthlyFee + wirelessAdviceStep3.applyUsedProgram) / lastDay) * (lastDay - day + 1)) + (installmentMonthly));

                    if(wirelessAdviceStep3.usedPhoneMemberShip){
                        if(wirelessAdviceStep3.usedPhoneMemberShip.unit == 1){
                            amountMonthlyDevice = amountMonthlyDevice - (amountMonthlyDevice * parseFloat(wirelessAdviceStep3.usedPhoneMemberShip.value) / 100);
                        }
                        else{
                            amountMonthlyDevice = amountMonthlyDevice - parseFloat(wirelessAdviceStep3.usedPhoneMemberShip.value ?? 0);
                        }
                    }

                    wirelessAdviceStep3.installmentFee              = Math.round(installmentFee);
                    wirelessAdviceStep3.installmentMonthlyFee       = installmentMonthlyFee;
                    wirelessAdviceStep3.installmentMonthly          = roundUpNumber(installmentMonthly);
                    wirelessAdviceStep3.installmentMonthlyPrincipal = installmentMonthly - installmentMonthlyFee;
                    wirelessAdviceStep3.amountMonthlyDevice         = roundUpNumber(amountMonthlyDevice);
                    wirelessAdviceStep3.amountMonthlyTotal          = roundUpNumber(amountMonthlyDevice + wirelessAdviceStep3.amountMonthlyOption);
                    wirelessAdviceStep3.estimatedAmountMonth        = Math.round(moneyRemainingMonth);
                }
            }
        }
    }

    return wirelessAdviceStep3;
}

var galleryThumbs = undefined;
var galleryTop = undefined;
export function loadingSwiperSlider() {

    // galleryThumbs = new window.Swiper('.gallery-thumbs', {
    //     observeParents:true,
    //     observeSlideChildren:true,
    //     simulateTouch:false
    // });
    // galleryTop = new window.Swiper('.gallery-top', {
    //     observeParents:true,
    //     observeSlideChildren:true,
    //     thumbs: {
    //         swiper: galleryThumbs
    //     },
    // });
}

export function removeSwiperSlider() {
    // if(galleryThumbs !== undefined){
    //   galleryThumbs.destroy(true, true)
    //   galleryTop.destroy(true, true)
    // }
}

export function loadingMenu() {


    $(".btn-input-form").on('click', function () {
        $(".wrapper.modal-open").css('padding', '0 15px')
    });

    $(".close-menu").click(function () {
        $(".menu-wrapper").removeClass("active-menu");
    });
    $(".item-menu a").click(function () {
        if (!$(this).attr('href')) {
            return
        }
        $(".menu-wrapper").removeClass("active-menu");
    });

    $(".btn-setting").click(function () {
        $(".list_event_item .item").toggleClass("active-setting");
    });

    $(".list-setting li").click(function () {
        $(".list_event_item .item").removeClass("active-setting");
    });

    $(".btn-paint").click(function () {
        $(".btn-paint").css('display', 'none')
        $(".content-paint").css('display', 'block');

    });
    $(".close-paint").click(function () {
        $(".btn-paint").css('display', 'block')
        $(".content-paint").css('display', 'none');
    });
    $("a").click(function () {
        $(".btn-paint").css('display', 'block')
        $(".content-paint").css('display', 'none');
    });
    // $('body .menu-wrapper').on('click', function (e) {
    //     if (e.target.className == 'menu-wrapper active-menu') {
    //         $(".menu-wrapper").removeClass("active-menu");
    //         const targetElement = document.querySelector('#list-menu');
    //         window.bodyScrollLock.enableBodyScroll(targetElement)

    //     }
    // })
}

export function chooseOption2G(data, WirelessAdviceStep3, telcom = null,welfareDiscountObj = null) {
    var welfareDiscount=welfareDiscountObj ? welfareDiscountObj.cost : (WirelessAdviceStep3.welfareDiscount && WirelessAdviceStep3.welfareDiscount?.cost ? WirelessAdviceStep3.welfareDiscount?.cost : 0)
    let codeLimit = OPTION_CHOOSE_2G.code_limit
    if(telcom && telcom == "LGU+") {
        codeLimit=OPTION_CHOOSE_2G.code_limit_LGU
    }
    let dataCheckLimit = codeLimit.includes(WirelessAdviceStep3.phone.model_name)
    let disclosureSubsidy = WirelessAdviceStep3.disclosureSubsidy
    let disclosureSubsidyDefault = WirelessAdviceStep3.disclosureSubsidyDefault // || WirelessAdviceStep3.disclosureSubsidy
    let additionalGrantsType = WirelessAdviceStep3.additionalGrantsType
    if (data && data.value) {
        let optionalAgreement = WirelessAdviceStep3.optionalAgreement
        let optionalAgreementWith2G = WirelessAdviceStep3.optionalAgreementWith2G
        if (WirelessAdviceStep3.optionFee == 1) {
            let numberDisclosureSubsidy = WirelessAdviceStep3.disclosureSubsidy
            let startingPrice = +WirelessAdviceStep3.startingPrice
            numberDisclosureSubsidy = +numberDisclosureSubsidy
            let disclosureSubsidyNumber = numberDisclosureSubsidy
            if (dataCheckLimit) {
                if (data.id != 0) {
                    let optionAgree = WirelessAdviceStep3.optionalAgreement
                    if (WirelessAdviceStep3.optionalAgreementWith2G == null) {
                        WirelessAdviceStep3.optionalAgreementWith2G = optionAgree != null ? optionAgree : null
                    }
                    WirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB
                    if (WirelessAdviceStep3.disclosureSubsidyDefault == null) {
                        WirelessAdviceStep3.disclosureSubsidyDefault = disclosureSubsidy
                    }
                    let dataDisclosureSubsidyChange=WirelessAdviceStep3.startingPrice
                    if(WirelessAdviceStep3.startingPrice >= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit){
                        dataDisclosureSubsidyChange = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit
                    }
                    WirelessAdviceStep3.disclosureSubsidy = dataDisclosureSubsidyChange
                } else {
                    WirelessAdviceStep3.optionalAgreement = optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement
                    WirelessAdviceStep3.disclosureSubsidy = disclosureSubsidyDefault
                }
            } else {
                if (data.id == 1) {

                    if(startingPrice < OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                        if (WirelessAdviceStep3.disclosureSubsidyDefault == null) {
                            WirelessAdviceStep3.disclosureSubsidyDefault = numberDisclosureSubsidy
                        }
                        WirelessAdviceStep3.disclosureSubsidy = WirelessAdviceStep3.startingPrice
                    }else {
                        if (disclosureSubsidyNumber <= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                            if (WirelessAdviceStep3.disclosureSubsidyDefault == null) {
                                WirelessAdviceStep3.disclosureSubsidyDefault = numberDisclosureSubsidy
                            }
                            if (OPTION_CHOOSE_2G.code_limit_except.includes(WirelessAdviceStep3.phone.model_name)) {
                                WirelessAdviceStep3.disclosureSubsidy = WirelessAdviceStep3.startingPrice
                            } else {
                                WirelessAdviceStep3.disclosureSubsidy = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit
                            }
                        }else {
                            if (WirelessAdviceStep3.disclosureSubsidyDefault == null) {
                                WirelessAdviceStep3.disclosureSubsidyDefault = numberDisclosureSubsidy
                            }
                        }
                    }


                    let optionAgree = WirelessAdviceStep3.optionalAgreement
                    if (WirelessAdviceStep3.optionalAgreementWith2G == null) {
                        WirelessAdviceStep3.optionalAgreementWith2G = optionAgree != null ? optionAgree : null
                    }
                    WirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB

                } else {
                    WirelessAdviceStep3.optionalAgreement = optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement
                    WirelessAdviceStep3.disclosureSubsidy = disclosureSubsidyDefault
                }
            }

        } else {
            if (dataCheckLimit && data.id == 2) {
                let optionAgree = WirelessAdviceStep3.optionalAgreement
                if (WirelessAdviceStep3.optionalAgreementWith2G == null) {
                    WirelessAdviceStep3.optionalAgreementWith2G = optionAgree != null ? optionAgree : null
                }
                WirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB
                WirelessAdviceStep3.optionFee = 1
                if (WirelessAdviceStep3.disclosureSubsidyDefault == null) {
                    WirelessAdviceStep3.disclosureSubsidyDefault = disclosureSubsidy
                }
                let dataDisclosureSubsidyChange=WirelessAdviceStep3.startingPrice
                if(WirelessAdviceStep3.startingPrice >= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit){
                    dataDisclosureSubsidyChange = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit
                }
                WirelessAdviceStep3.disclosureSubsidy = dataDisclosureSubsidyChange
            } else {
                if (data.id == 1) {

                    var basicCostCalc=WirelessAdviceStep3.basicCostCalc
                    var basicCost=WirelessAdviceStep3.basicCost

                    var valueBasicCost=0
                    if(basicCostCalc != 0) {
                        valueBasicCost=basicCostCalc
                    }else {
                        valueBasicCost=roundUpNumberPlan(basicCost)
                    }

                    var rateAgreement=(WirelessAdviceStep3.rateAgreement) ? WirelessAdviceStep3.rateAgreement : 0
                    if(WirelessAdviceStep3.rateAgreementDefault != 0 && WirelessAdviceStep3.rateAgreement == 0) {
                        rateAgreement=WirelessAdviceStep3.rateAgreementDefault
                    }
                    // var basicCostValue=WirelessAdviceStep3.basicCostCalc != 0 ? WirelessAdviceStep3.basicCostCalc : (WirelessAdviceStep3.basicCost != null ? WirelessAdviceStep3.basicCost : 0)
                    var moneyResult = (valueBasicCost - rateAgreement) * OPTION_CHOOSE_2G.percent_optionalAgreement

                    if(telcom && telcom == "LGU+") {

                        var promotionDurationDiscountOne=WirelessAdviceStep3.promotionDurationDiscountOne ? WirelessAdviceStep3.promotionDurationDiscountOne : 0
                        var promotionDurationDiscountTwo=WirelessAdviceStep3.promotionDurationDiscountTwo ? WirelessAdviceStep3.promotionDurationDiscountTwo : 0
                        var promotionDuration= +promotionDurationDiscountOne + +promotionDurationDiscountTwo

                        var combinedDiscount=WirelessAdviceStep3.combinedDiscount ? WirelessAdviceStep3.combinedDiscount : 0
                        var promotion=WirelessAdviceStep3.promotion && WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdviceStep3.promotion?.promo_cost : 0

                        moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement

                        // if((welfareDiscountObj && welfareDiscountObj?.id == 6) || (WirelessAdviceStep3?.welfareDiscount?.id && WirelessAdviceStep3?.welfareDiscount?.id == 6)) {
                        //     let optionAgreeBeforeWelfare=(valueBasicCost - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
                        //     let optionAgreeBeforeAfter=(valueBasicCost - rateAgreement - promotion - promotionDuration - combinedDiscount) - optionAgreeBeforeWelfare
                        //     moneyResult = optionAgreeBeforeAfter
                        // }

                        if(welfareDiscountObj && welfareDiscountObj?.id == 6) {
                            moneyResult=(valueBasicCost - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
                        }
                        if(!welfareDiscountObj && WirelessAdviceStep3.welfareDiscount?.id &&
                            WirelessAdviceStep3.welfareDiscount?.id == 6) {
                            moneyResult=(valueBasicCost - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
                        }

                    }
                    let optionAgree = WirelessAdviceStep3.optionalAgreement
                    if (WirelessAdviceStep3.optionalAgreementWith2G == null) {
                        WirelessAdviceStep3.optionalAgreementWith2G = optionAgree != null ? optionAgree : null
                    }
                    WirelessAdviceStep3.optionalAgreement = moneyResult < 0 ? 0 : Math.round(moneyResult)
                } else {
                    WirelessAdviceStep3.optionalAgreement = optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement
                }
            }
        }
        WirelessAdviceStep3.conversion2G = data
    } else {
        WirelessAdviceStep3.conversion2G = null
    }
    WirelessAdviceStep3.additionalGrants = calcAdditionalGrantsPrice(additionalGrantsType, WirelessAdviceStep3)
    // WirelessAdviceStep3.optionalAgreement = roundUpNumberPlan(WirelessAdviceStep3.optionalAgreement)
    return WirelessAdviceStep3;
}

export const calcAdditionalGrantsPrice = (type, WirelessAdviceStep3) => {
    switch (type) {
        case '0':
            return 0;
        case '15':
            return parseInt(WirelessAdviceStep3.disclosureSubsidy) * 15 / 100;
        case '15-MNP':
            return parseInt(WirelessAdviceStep3.disclosureSubsidy) * 15 / 100 - 800;
        default:
            const additionalGrantsPrice = +WirelessAdviceStep3.disclosureSubsidy + +WirelessAdviceStep3.additionalGrants;
            if (additionalGrantsPrice > WirelessAdviceStep3.phone.dl_price) {
                return WirelessAdviceStep3.phone.dl_price - WirelessAdviceStep3.disclosureSubsidy;
            }
            return WirelessAdviceStep3.additionalGrants;
    }
}


export function permissionsMenu(role, data) {
    let result = data.indexOf(role);
    if (result !== -1) {
        return 'd-show';
    } else {
        return 'd-none';
    }
}

/**
 * tranform data from api to WiredAdviceStep1
 * @param  {[type]} WiredAdviceStep1 [description]
 * @param  {[type]} data             [description]
 * @return {[type]}                  [description]
 */
export function updateWiredAdviceStep1(WiredAdviceStep1, data) {
    let wirelessAdviceStep1 = {
        telcomID: data?.telcom.id,
        status: true,
        successString: data?.telcom?.image_url?.original,
        installmentPaymentMethod: data?.telcom?.installment_payment_method,
    }
    return wirelessAdviceStep1
}

/**
 * tranform data from api to WiredAdviceStep2
 * @param  {object} WiredAdviceStep2 [description]
 * @param  {object} data             [description]
 * @return {[type]}                  [description]
 */
export function updateWiredAdviceStep2(WiredAdviceStep2, data) {
    let wiredAdviceStep2 = {
        ...WiredAdviceStep2,
        bookmark: data.bookmak_wired.length > 0 ? data.id : null,
        discountCard: data?.consultant_wired?.discount_card_relation,
        discountCardPrice: data?.consultant_wired?.discount_card,
        combineCondition: data?.combine_discount_condition,
        combineDevice: data?.combine_wired_device,
        combineOption: data?.combine_wired_device_option,
        combinePlans: data?.consultant_combine_plans,
        note: data?.note,
    }
    let classify = [...wiredAdviceStep2.classify].map((cls) => {
        let cl = { ...cls, classify: [] }
        data.consultant_device_options.map((item) => {
            let discountOptions = item.options.map((option) => ({
                name: option.discount_basic_name,
                value: +option.discount_basic_value,
                additional: +option.discount_additional,
            }))
            if (cls.id == item.type) {
                cl.classify.push({
                    device: item.device,
                    options: item.options.map(e => ({...e.option, ...e})),
                    cost: {
                        value: +item.cost
                    },
                    discount: {
                        device: {
                            name: item.basic_device_discount_name,
                            value: +item.basic_device_discount,
                            additional: +item.discount_device,
                        },
                        options: discountOptions,
                        value: item.discount,
                        combineDiscount: +item.combine_discount,
                    },
                })
            }
        })
        return cl
    })
    wiredAdviceStep2.classify = classify

    return wiredAdviceStep2
}

const totlaOptionDiscountAdditional = (options = []) => options.reduce((prevAdditional, option) => {
  return prevAdditional + +(option.additional || 0)
}, 0)

export const caclWiredAdvice = (WiredAdviceStep2) => {
    let wiredAdviceStep2 = { ...WiredAdviceStep2 }
    let monthlyCosts = 0
    let discountCardPrice = +wiredAdviceStep2.discountCardPrice
    let totalCombinedDiscount = 0
    totalCombinedDiscount += discountCardPrice
    
    wiredAdviceStep2.classify.map((cls) => {
        cls.classify.map((item) => {
            monthlyCosts += +item.discount.value - +(item?.discount?.device?.additional || 0) - totlaOptionDiscountAdditional(item?.discount?.options);
            
            (item?.discount?.options || []).map((option) => {
                monthlyCosts = monthlyCosts - +(option?.value || 0) - +(option?.combine || 0);
            })
            totalCombinedDiscount += +(item?.discount?.combineDiscount || 0)
        })
    })
    wiredAdviceStep2 = {
        ...wiredAdviceStep2,
        monthlyCosts,
        totalCombinedDiscount: totalCombinedDiscount > 0 ? totalCombinedDiscount : 0,
    }

    return wiredAdviceStep2
}

export function limitString(string, number) {
    if (string.length <= number) {
        return string;
    } else {
        return string.substr(0, number) + '...';
    }
}

export function isValid(str) {
    return !/[~`!#$%\^&@*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

export function isValidFile(str) {
    return !/[~`!#$%\^&@*+=\\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

export function isValidDate(str) {
    return !/[~`!#$%\^&@*+=\\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

export function isValidTitle(str) {
    return !/[À-ÖØ-öø-ÿ]/g.test(str);
}

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export function convertIframe(data) {
    const embed = data;

    const stringToHTML = function (str) {
        const domContainer = document.createElement("span");
        domContainer.innerHTML = str;
        return domContainer;
    };

    const parentEmbed = stringToHTML(embed);


    let oldIframe = parentEmbed.querySelectorAll("oembed");
    oldIframe = Array.from(oldIframe);

    for (const i in oldIframe) {
        //Get the url from oembed tag
        let url = oldIframe[i].getAttribute("url");
        //Replace 'watch?v' with 'embed/'
        url = url.replace("watch?v=", "embed/");

        //Create a iframe tag
        const newIframe = document.createElement("iframe");
        newIframe.setAttribute("style", " width: 100%; min-height:300px; top: 0; left: 0;");
        newIframe.setAttribute("allowFullScreen", "");
        newIframe.setAttribute("frameBorder", 0);
        if (url) {
            newIframe.setAttribute("src", url);
        }
        // replace oldIframe with newIframe
        oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
    }

    return parentEmbed.outerHTML;
}

export function checkMatchURL(url) {
    let arrMenu = [
        `/${routerName.HISTORY_BUY_PRODUCT}`,
        `/${routerName.SETTLEMENT}`,
        `/${routerName.TRANSACTION_POINT}`,
    ];

    if (arrMenu.includes(url))
        return true
    return false
}

export const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }

    return newUrl;
};

export function loadingSliderBootstrap() {
    $('document').ready(function () {
        // $('#carouselExampleIndicators').carousel({
        //   touch: true,
        //   interval: 3000,
        //   cycle: true
        // })
    });
}


export function getPeriodicallyName(name) {
    switch(name){
        case '0':
            return "선택하기"
        case '1':
            return "정기 결제 예약에 적용하기"
        case '2':
            return "당월 결제 금액에 적용하기"
    }

}

export function optionWelfare(basicCost,valueBasicCost,id,optionalAgreement,rateAgreement,promotion,combinedDiscount,dataTelcom,optionFee,conversion2G = null) {
    id=id != null ? id?.id : id

    if(optionFee == 1 && ![1, 2].includes(+conversion2G)) { // tiền httb và ko áp dụng 2g
        optionalAgreement=0
    }
    let optionID6=((valueBasicCost - optionalAgreement - rateAgreement - promotion - combinedDiscount)*0.5);
    let optionID3= (valueBasicCost- promotion) > 28600 ? 28600 : (valueBasicCost- promotion)
    let optionID45=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
    optionID6=Math.round(optionID6)

    let dataWelfareDiscount=[
        {id:0, value:"미적용", cost:0},
        {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? ((valueBasicCost - rateAgreement - combinedDiscount)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount)*0.35 : 0) : 69000*0.35},
        {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? (valueBasicCost - rateAgreement - combinedDiscount)*0.35 : 69000*0.35},
        {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
        {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:(valueBasicCost > 45100 ? 23650:
                optionID45)},
        {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:(valueBasicCost > 45100 ? 23650:
                optionID45)},
        {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 : optionID6))},
    ]

    if(dataTelcom == "SKT") {
        let optionID45SKT=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
        let optionID12SKT=  ((valueBasicCost - rateAgreement - combinedDiscount)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount)*0.35 : 0)
        dataWelfareDiscount=[
            {id:0, value:"미적용", cost:0},
            {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
            {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
            {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
            {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost-12100)*0.35)) > 23650 ? 23650:
                    optionID45SKT)},
            {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost-12100)*0.35)) > 23650 ? 23650:
                    optionID45SKT)},
            {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
        ]
    }

    if(dataTelcom == "KT") {
        let optionID45KT=  (12100+((valueBasicCost-12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100 - promotion)*0.35))
        let optionID45KTMainCondition=  (valueBasicCost - 12100 - promotion) < 0 ? ((12100 - promotion) > 0 ? (12100 - promotion) : 0) :((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
            optionID45KT)
        let optionID12KT=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0)
        dataWelfareDiscount=[
            {id:0, value:"미적용", cost:0},
            {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
            {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
            {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
            {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                    optionID45KT)},
            {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:optionID45KTMainCondition},
            {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
        ]
    }

    if(dataTelcom == "LGU+") {
        let optionID45LGU=  (12100+((valueBasicCost - 12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost - 12100 - promotion)*0.35))
        let optionID12LGU=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0)
        dataWelfareDiscount=[
            {id:0, value:"미적용", cost:0},
            {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
            {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
            {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
            {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                    optionID45LGU)},
            {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                    optionID45LGU)},
            {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
        ]
    }


    var dataFilter=dataWelfareDiscount.filter(item =>item.id == id)
    if(dataFilter.length > 0) {
        var dataMap={id:dataFilter[0].id,value:dataFilter[0].value, cost:dataFilter[0].cost};
        return dataMap
    }else {
        return null
    }
}

export function optionalAgreementCT (cost_display, cost_calc, customPlan, data, commitmentRate, discostChange, telcomID) {
    let optionalAgreement = 0;
    let rateAgreement = 0
    let monthDcPrice = 0;
    let optionalAgreementFix = 0;
    let optionalAgreementFix2 = 0;
    let optionalAgreementArr;
    let optionalAgreementArr2;

    //TH1: not LGU
    let arrPlan = [2959, 2968, 2994, 2989, 2985, 3011, 2979,2976, 2965, 3072, 3079, 2961, 2957, 2956, 2893, 2951, 3303, 3302, 2998, 3000, 3055, 3058, 3060, 2991, 2986, 3002, 3013, 3065, 3602];
    if (arrPlan.indexOf(+data.id) != -1 ) {
      if (discostChange == 12) {
        rateAgreement = +data.discost_12 + data.discost_12*10/100;
        if (priceFormat(cost_calc) != 0) {
          optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
        }else{
          optionalAgreement = ((+cost_display +cost_display*10/100) - (+data.discost_12 + data.discost_12*10/100)) * (commitmentRate);
        }
      }else{
          rateAgreement = +data.discost_24 + data.discost_24*10/100;
        if (priceFormat(cost_calc) != 0) {
          optionalAgreement = ((cost_calc+cost_calc*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
        }else{
          optionalAgreement = ((cost_display+cost_display*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
        }
      }
    }else{
      if (telcomID != 18) {
        if (discostChange == 12) {
            rateAgreement = +data.discost_12 + data.discost_12*10/100;
            // this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_12 + data.discost_12*10/100) ;
          if (priceFormat(cost_calc) != 0) {
            optionalAgreement = ((cost_calc) - (+data.discost_12))*(commitmentRate);
            monthDcPrice = cost_calc % 10;
            optionalAgreementFix = priceFormatDiff(optionalAgreement, 1);
            optionalAgreementArr = optionalAgreementFix.split('.');
            if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
              optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
              optionalAgreement = Math.round(optionalAgreement);
            } else {
              optionalAgreementFix2 = priceFormatDiff(optionalAgreement/100, 2);
              optionalAgreementArr2 = optionalAgreementFix2.split('.');
              if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
                optionalAgreement = Math.round(optionalAgreement);
              } else if(optionalAgreementArr2[1] < 50){
                let selDcPrice = parseInt(+optionalAgreementArr2[0] + parseFloat(0.5));
                optionalAgreement = ((selDcPrice*100)*1.1);
              }else{
                optionalAgreement = Math.round(optionalAgreement);
                optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
              }
            }
          } else {
            optionalAgreement = ((+cost_display) - (+data.discost_12))*(commitmentRate);
            monthDcPrice = +cost_display % 10;
            optionalAgreementFix = priceFormatDiff(optionalAgreement, 1);
            optionalAgreementArr = optionalAgreementFix.split('.');
            if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
                optionalAgreement = ((+cost_display +cost_display*10/100) - (+data.discost_12 + data.discost_12*10/100)) * (commitmentRate);
              optionalAgreement = Math.round(optionalAgreement);
            } else {
              optionalAgreementFix2 = priceFormatDiff(optionalAgreement/100, 2);
              optionalAgreementArr2 = optionalAgreementFix2.split('.');
              if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                optionalAgreement = ((+cost_display+cost_display*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
                optionalAgreement = Math.round(optionalAgreement);
              } else if(optionalAgreementArr2[1] < 50){
                let selDcPrice = parseInt(optionalAgreementArr2[0]) + parseFloat(0.5);
                optionalAgreement = ((selDcPrice*100)*1.1);
              }else{
                optionalAgreement = Math.round(optionalAgreement);
                optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
              }
            }
          }
        }else{
            // this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_24 + data.discost_24*10/100) ;
            rateAgreement = +data.discost_24 + (+data.discost_24*10/100);
          if (priceFormat(cost_calc) != 0) {
            optionalAgreement = (cost_calc - (+data.discost_24))*(commitmentRate);
            monthDcPrice = cost_calc % 10;
            optionalAgreementFix = priceFormatDiff(optionalAgreement, 1);
            optionalAgreementArr = optionalAgreementFix.split('.');
            if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
              optionalAgreement = ((cost_calc+cost_calc*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
              optionalAgreement = Math.round(optionalAgreement);
            } else {
              optionalAgreementFix2 = priceFormatDiff(optionalAgreement/100, 2);
              optionalAgreementArr2 = optionalAgreementFix2.split('.');
              if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                optionalAgreement = ((cost_calc+cost_calc*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
                optionalAgreement = Math.round(optionalAgreement);
              } else if(optionalAgreementArr2[1] < 50){
                let selDcPrice = parseInt(+optionalAgreementArr2[0]) + parseFloat(0.5);
                optionalAgreement = ((selDcPrice*100)*1.1);
              }else{
                optionalAgreement = Math.round(optionalAgreement);
                optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
              }
            }
          } else {
            //LTE 실속 데이터
            optionalAgreement = (cost_display - (+data.discost_24))*(commitmentRate);
            monthDcPrice = cost_display % 10;
            optionalAgreementFix = priceFormatDiff(optionalAgreement, 1);
            optionalAgreementArr = optionalAgreementFix.split('.');
            if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
              optionalAgreement = ((cost_display+cost_display*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
              optionalAgreement = Math.round(optionalAgreement);
            } else {
              optionalAgreementFix2 = priceFormatDiff(optionalAgreement/100, 2);
              optionalAgreementArr2 = optionalAgreementFix2.split('.');
              if (+optionalAgreementArr2[1] == 50 || +optionalAgreementArr2[1] == 5 || +optionalAgreementArr2[1] == 0) {
                optionalAgreement = ((cost_display+cost_display*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
                optionalAgreement = Math.round(optionalAgreement);
              } else if(+optionalAgreementArr2[1] < 50){
                let selDcPrice = parseInt(+optionalAgreementArr2[0]) + parseFloat(0.5);
                optionalAgreement = ((selDcPrice*100)*1.1);
              }else{
                optionalAgreement = Math.round(optionalAgreement);
                optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
              }
            }
          }
        }
      }else{
        if (data.discount_full == 1) {
          //TH3: Giảm giá cam kết theo gói LGU+
          if (discostChange == 12) {
            // this.props.change('WirelessAdviceStep3.rateAgreement', 0) ;
            rateAgreement = 0;
          }else{
            // this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_24 + data.discost_24*10/100) ;
            rateAgreement = +data.discost_24 + data.discost_24*10/100;
          }
          if (priceFormat(data.cost_calc) != 0) {
            optionalAgreement = (+data.cost_calc+data.cost_calc*10/100)*(commitmentRate);
          } else {
            optionalAgreement = (+data.cost_display+data.cost_display*10/100)*(commitmentRate);
          }

        } else {
          //TH2: is LGU
          if (discostChange == 12) {
            // this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_12 + data.discost_12*10/100) ;
            // rateAgreement = +data.discost_12 + data.discost_12*10/100;
            rateAgreement = 0;
            if (priceFormat(data.cost_calc) != 0) {
              optionalAgreement = ((+data.cost_calc+data.cost_calc*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
            } else {
              optionalAgreement = ((+data.cost_display+data.cost_display*10/100) - (+data.discost_12 + data.discost_12*10/100))*(commitmentRate);
            }
          }else{
            // this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_24 + data.discost_24*10/100) ;
            rateAgreement = +data.discost_24 + data.discost_24*10/100;
            if (priceFormat(data.cost_calc) != 0) {
              optionalAgreement = ((+data.cost_calc+data.cost_calc*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
            } else {
              optionalAgreement = ((+data.cost_display+data.cost_display*10/100) - (+data.discost_24 + data.discost_24*10/100))*(commitmentRate);
            }
          }
        }
      }
    }
    let result = {
      optionalAgreement: optionalAgreement < 0 ? 0 : ([3688].includes(data.id) ? 20000:   optionalAgreement),
      //optionalAgreement: optionalAgreement < 0 ? 0 : getOptionalAgreementHardcording(+data?.id || -1, optionalAgreement),
      rateAgreement: rateAgreement
    }
    return result;
  }

  export function getPrintType(type)  {
      switch (type) {
          case 1:
            return '빈서식지'
          case 2:
            return '무선 서식지'
          case 3:
            return '유선 서식지'
          case 4:
            return '무선 견적서'
          case 5:
            return '유선 견적서'
          case 6:
            return '무선 전자청약서'
          default:
            return '유선 전자청약서'
      }
  }

  export function totalFeeOption (options = []) {
    return options.reduce((prevFee, option) => {
        return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
    }, 0)
  }

  export function disableScroll () {
    const $body = document.querySelector('body');
    let scrollPosition = 0;
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  }

  export function enableScroll() {
    const $body = document.querySelector('body');
    let scrollPosition = 0;
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }

export function sortObject (unordered) {
    return Object.keys(unordered).sort().reduce(
        (obj, key) => {
            obj[key] = unordered[key];
            return obj;
        },
        {}
    );
}

function getTextReset (keyCheck, object) {
    let text = [];
    Object.keys(object).map((key) => {
        if (Object.keys(keyCheck).includes(key) && object[key]) {
            if (typeof object[key] === 'object') {
                if (Object.keys(object[key]).length > 0) {
                    text.push(keyCheck[key])
                }
            } else {
                text.push(keyCheck[key])
            }
        }
    })

    return text
}

export function getTextWirelessStep3Discount (WirelessAdviceStep3) {
    let keyCheck = {
        'additionalGrants': "추가지원금",
        'pointDiscount': "포인트 할인",
        'usedPhoneCompensation': "중고폰보상 프로그램",
        'cashPayment': "현금납부액",
        'other': "기타할인",
        'installmentMonth': "할부개월",
        'promotion': "프로모션할인",
        'conversion2G': "2G전화고객 ",
    }

    return getTextReset(keyCheck, WirelessAdviceStep3)
}

export function getTextWirelessStep3Promotion (WirelessAdviceStep3) {
    let keyCheck = {
        'promotion': "프로모션",
    }

    return getTextReset(keyCheck, WirelessAdviceStep3)
}


export const planEstimateCT = (installmentPaymentMethod = 0, WirelessAdviceStep3, recipe_days_remaining = 2, changePlan = true) => {
    let wirelessAdviceStep3Estimate = { ...WirelessAdviceStep3 }
    let startingPrice               = +(wirelessAdviceStep3Estimate.startingPrice || 0) // Giá khởi điểm (출고가)
    let basicCost                   = 0
    let rateAgreement               = +(wirelessAdviceStep3Estimate.rateAgreement || 0);
//삭제 필요
//console.log("planEstimateCT의 wirelessAdviceStep3Estimate 값 : ", wirelessAdviceStep3Estimate)
    console.log("planEstimateCT wirelessAdviceStep3Estimate", wirelessAdviceStep3Estimate);

    if (!changePlan) {
        basicCost     = +(wirelessAdviceStep3Estimate.basicCost || 0) // chi phí cơ bản (기본료)
        rateAgreement = +(wirelessAdviceStep3Estimate.rateAgreement || 0) // cam kết gói phí (요금약정)
    }
    else {
        if (wirelessAdviceStep3Estimate.dataPlanNewDateChange && wirelessAdviceStep3Estimate.dataPlanNewDateChange.cost_display) {
            basicCost     = +(Math.floor(wirelessAdviceStep3Estimate.dataPlanNewDateChange.cost_display * 1.1) || 0) // chi phí cơ bản (기본료)
            rateAgreement = +(wirelessAdviceStep3Estimate.rateAgreement || 0) // cam kết gói phí (요금약정)
        }
        else {
            basicCost     = +(wirelessAdviceStep3Estimate.basicCost || 0) // chi phí cơ bản (기본료)
            rateAgreement = +(wirelessAdviceStep3Estimate.rateAgreement || 0) // cam kết gói phí (요금약정)
        }
    }
    
    wirelessAdviceStep3Estimate.basicCost = basicCost;

    let optionFee            = +(wirelessAdviceStep3Estimate.optionFee || 0) // 1 = tiền hỗ trợ thêm (추가지원금) | 0 = giảm giá cam kết lựa chọn (선택약정할인)
    let disclosureSubsidy    = +(wirelessAdviceStep3Estimate.disclosureSubsidy || 0) // tiền hỗ trợ thông báo (공시지원금)
    let additionalGrants     = +(wirelessAdviceStep3Estimate.additionalGrants || 0) // tiền hỗ trợ thêm (추가지원금)
    
    if((wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 190 && optionFee == OPTION_FEE.disclosureSubsidy){ // KT KT S23 FE 폰 구독이고 공시지원금이면
        if((startingPrice - disclosureSubsidy - additionalGrants - KT_S23_SBS_INS_GRC_VAL) < 0){
            additionalGrants                             = startingPrice - disclosureSubsidy - KT_S23_SBS_INS_GRC_VAL;
            wirelessAdviceStep3Estimate.additionalGrants = additionalGrants;
        }
    }

    //let optionalAgreement = +(wirelessAdviceStep3Estimate.optionalAgreementPlanEstimated || 0) // cam kết lựa chọn (선택약정)
    let optionalAgreement = getOptionalAgreementHardcording(+wirelessAdviceStep3Estimate.dataPlanNewDateChange.id, +(wirelessAdviceStep3Estimate.optionalAgreementPlanEstimated || 0))
    //삭제 필요
    //console.log("CT의 optionalAgreement와 optionalAgreementPlanEstimated", optionalAgreement, wirelessAdviceStep3Estimate.optionalAgreementPlanEstimated)
    // let contractMonth = 0 // số tháng cam kết (약정개월)

    let pointDiscount               = +(wirelessAdviceStep3Estimate.pointDiscount || 0) // giảm giá point (포인트 할인)
    let conversionPriceInput        = +(wirelessAdviceStep3Estimate.conversion_price_input || 0) // 전환지원금
    let conversionPriceInputAdd     = +(wirelessAdviceStep3Estimate.conversion_price_input_add || 0) // 전환지원금
    
    if (optionFee != OPTION_FEE.disclosureSubsidy) { // 공시지원금이 아니라면
        conversionPriceInput = 0;
        conversionPriceInputAdd = 0;
    }

    let advancedDiscountCard = +(wirelessAdviceStep3Estimate.advancedDiscountCard || 0) // thẻ giảm giá trước (선할인 카드)
    let other                = +(wirelessAdviceStep3Estimate.other || 0) // khác (기타)
    let cashPayment          = +(wirelessAdviceStep3Estimate.cashPayment || 0) // nạp tiền mặt (현금납부)
    let cashPaymentId        = +(wirelessAdviceStep3Estimate.cashPaymentId || 0); // 1 전액 2 부분 0 미적용

    if(cashPaymentId == 1){
        cashPaymentId = 0;
    }

    if((wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
        cashPayment = startingPrice - (pointDiscount + advancedDiscountCard + other + KT_S23_SBS_INS_GRC_VAL);

        if (optionFee == OPTION_FEE.disclosureSubsidy) { // 공시지원금이면
            cashPayment = cashPayment - disclosureSubsidy - additionalGrants;
        }
        
        if(cashPayment < 0){
            cashPayment = 0;
        }
        
        wirelessAdviceStep3Estimate.cashPayment   = cashPayment;
        wirelessAdviceStep3Estimate.cashPaymentId = 2;
    }
    
    let installmentMonth = +(wirelessAdviceStep3Estimate.installmentMonth || 0) // số tháng trả góp (할부개월)
    let promotionDurationDiscountOne;
    let promotionDurationDiscountTwo;
    let promotion = +(wirelessAdviceStep3Estimate.promotion?.promo_cost || 0) // promotion (프로모션)
    // if (wirelessAdviceStep3Estimate.dataPlanNewDateChange.discount_full) {
    //     promotionDurationDiscountOne = 0 // promotion (프로모션)
    //     promotionDurationDiscountTwo = 0 // promotion (프로모션)
    //     rateAgreement = 0
    // }else{

    // }
    promotionDurationDiscountOne = +(wirelessAdviceStep3Estimate.promotionDurationDiscountOne || 0) // promotion (프로모션)
    promotionDurationDiscountTwo = +(wirelessAdviceStep3Estimate.promotionDurationDiscountTwo || 0) // promotion (프로모션)

    //let combinedDiscount = roundUpNumber(+wirelessAdviceStep3Estimate?.discountWireLessPriceExpectedPlan?.discount_wireless_price || 0) // giảm giá kết hợp (결합 할인)
    let combinedDiscount = +wirelessAdviceStep3Estimate?.discountWireLessPriceExpectedPlan?.discount_wireless_price || 0; // giảm giá kết hợp (결합 할인) //원본
    //let combinedDiscount = roundUpNumber(+( +(+wirelessAdviceStep3Estimate?.discountWireLessPriceExpectedPlan?.discount_wireless_price || 0))); // giảm giá kết hợp (결합 할인)
    //let combinedDiscount = roundUpNumber(+( +(wirelessAdviceStep3Estimate.combinePlans[0]?.discount_wireless_price || 0) + +(WirelessAdviceStep3.combinePlans[0]?.discount_tv || 0))) // giảm giá kết hợp (결합 할인)

//삭제 필요
//console.log("요금제 변경 시 초기 combinedDiscount 값 : ",combinedDiscount)    
    
    wirelessAdviceStep3Estimate = {
        ...wirelessAdviceStep3Estimate,
        combinedDiscount
    }

    let welfareDiscount = +(wirelessAdviceStep3Estimate.welfareDiscount || 0) // giảm giá phúc lợi (복지 할인)
    let discountCard = +(wirelessAdviceStep3Estimate.discountCard || 0) // thẻ giảm giá (할인카드)
    let mutualService = +(wirelessAdviceStep3Estimate.mutualService?.price_month || 0) // dịch vụ tương trợ (상조서비스)
    let conversion2G = wirelessAdviceStep3Estimate.conversion2G // chuyển đổi 2G (2G전환)
    
    // let sim = +(wirelessAdviceStep3Estimate.sim?.price || 0) // SIM (유심)
    let insurrance = +(wirelessAdviceStep3Estimate.insurrance?.ins_price || 0) // Bảo hiểm thiết bị (휴대폰 보험)
    // let insurranceCancelDate = 0 // Ngày có thể hủy (휴대폰 보험)

    let extraServices = 0 // Dịch vụ bổ sung
    wirelessAdviceStep3Estimate.extraServices.map((service) => {
        extraServices += +service.ets_price
    })
    let interestRate = +(wirelessAdviceStep3Estimate.interestRate || 0) // Ngày có thể hủy (휴대폰 보험)

    let applyUsedProgram = +(wirelessAdviceStep3Estimate.applyUsedProgram || 0) // Tiền yêu cầu theo tháng sau khi apply bồi thường DT cũ

    // Nguyên giá trả góp (할부원금)
    let installmentPrincipal = startingPrice - (+disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + cashPayment + other + conversionPriceInput + conversionPriceInputAdd)
    // 0 = giảm giá cam kết lựa chọn (선택약정할인)

    if(advancedDiscountCard){ // 카드할인이 있는 경우 다시 계산
        installmentPrincipal = installmentPrincipal + advancedDiscountCard;
        advancedDiscountCard = Number(wirelessAdviceStep3Estimate.advancedDiscountCardObject.total) || advancedDiscountCard; 
    
        if(wirelessAdviceStep3Estimate.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3Estimate.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                let instPrincipalUsedPhone2 = Number(wirelessAdviceStep3Estimate.startingPrice) * Number(wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_w2nd_rate) / 100; // 2구간 할부원금

                if(Math.round(installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1) < advancedDiscountCard){
                    advancedDiscountCard = Math.round(installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1);

                    wirelessAdviceStep3Estimate.advancedDiscountCard = advancedDiscountCard;
                }
            }
        }

        installmentPrincipal = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + cashPayment + other + conversionPriceInput + conversionPriceInputAdd);
    }

    if (optionFee === OPTION_FEE.optionalAgreement) {
        installmentPrincipal += +disclosureSubsidy + additionalGrants;
    }

    if (wirelessAdviceStep3Estimate.cashPaymentId == 1) {
        /*
        if (optionFee === OPTION_FEE.optionalAgreement) {
            cashPayment += installmentPrincipal;
        } else {
            cashPayment = startingPrice - (+disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other)
        }
        */

        if(wirelessAdviceStep3Estimate.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3Estimate.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                installmentPrincipal = installmentPrincipal + cashPayment;

                let instPrincipalUsedPhone2 = wirelessAdviceStep3Estimate.startingPrice * wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

                cashPayment = installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35);
                cashPayment = Math.round(cashPayment - 1);
            }
            else{
                if (optionFee === OPTION_FEE.optionalAgreement) {
                    cashPayment += installmentPrincipal;
                }
                else {
                    cashPayment = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other + conversionPriceInput + conversionPriceInputAdd);
                }
            }
        }
        else{
            if (optionFee === OPTION_FEE.optionalAgreement) {
                cashPayment += installmentPrincipal;
            }
            else {
                cashPayment = startingPrice - (disclosureSubsidy + additionalGrants + pointDiscount + advancedDiscountCard + other + conversionPriceInput + conversionPriceInputAdd);
            }
        }

        if (cashPayment < 0) {
            cashPayment = 0
        }

        wirelessAdviceStep3Estimate.cashPayment = cashPayment;
        //installmentPrincipal = 0;
        if(wirelessAdviceStep3Estimate.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(wirelessAdviceStep3Estimate.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                installmentPrincipal = installmentPrincipal - cashPayment;
            }
            else{
                installmentPrincipal = 0;
            }
        }
        else{
            installmentPrincipal = 0;
        }
    }

    if((wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
        installmentPrincipal = installmentPrincipal - KT_S23_SBS_INS_GRC_VAL;
    }

    if((wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 192){ // KT KT S23 FE 폰 구독 
        installmentPrincipal = installmentPrincipal - KT_S23_SBS_PRM_Y_EDT;
    }

    if (installmentPrincipal < 0) {
        installmentPrincipal = 0
    }

    if(cashPaymentId == 1){
        cashPaymentId        = installmentPrincipal;
        installmentPrincipal = 0;
    }
    
    wirelessAdviceStep3Estimate.installmentPrincipal = installmentPrincipal

    let installmentFeeArray = [];
    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
        installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(installmentPrincipal, interestRate, installmentMonth)
    }
    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
        installmentFeeArray = calcInstallmentFeePrincipalByMonth(installmentPrincipal, interestRate, installmentMonth)
    }

    // Tổng lãi suất trả góp (할부수수료)
    let installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

    if(+(wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentFee = roundUpNumber(Math.round(installmentPrincipal * 0.118));
    }

    if (installmentFee < 0) {
        installmentFee = 0
    }
    installmentFee = Math.round(installmentFee)
    wirelessAdviceStep3Estimate.installmentFee = installmentFee

    // Nguyên giá trả góp/số tháng trả góp
    let installmentMonthlyPrincipal = installmentPrincipal / installmentMonth;

    if(+(wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentMonthlyPrincipal = 0;
    }

    // Tổng lãi suất trả góp/số tháng trả góp
    let installmentMonthlyFee = Math.round(installmentFee / installmentMonth);

    if(+(wirelessAdviceStep3Estimate?.usedPhoneCompensation?.tup_id || 0) == 191){ // 갤럭시S23 중고폰가격보장프로그램
        installmentMonthlyFee = installmentFee / 24;
    }

    // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
    let installmentMonthly = installmentMonthlyPrincipal + installmentMonthlyFee
    if (installmentMonthly < 0) {
        installmentMonthly = 0
    }
    // let roundd = roundUpNumber(Math.ceil(installmentMonthly))
    let roundd = ((installmentMonthly))  // sai số CT BTDTC
    installmentMonthlyPrincipal = Math.round(installmentMonthlyPrincipal)
    installmentMonthlyFee = roundd - installmentMonthlyPrincipal
    // installmentMonthly = roundd
    installmentMonthly = roundUpNumber(Math.ceil(roundd))
    wirelessAdviceStep3Estimate.installmentMonthlyPrincipal = installmentMonthlyPrincipal
    wirelessAdviceStep3Estimate.installmentMonthlyFee = installmentMonthlyFee
    wirelessAdviceStep3Estimate.installmentMonthly = installmentMonthly
    // wirelessAdviceStep3Estimate.installmentMonthly = roundUpNumber(Math.ceil(installmentMonthly))
    installmentMonthly = roundd

//삭제 필요
//console.log("planEstimateCT 함수 내의 installmentMonthlyPrincipal 값 계산", installmentMonthlyPrincipal) 
//console.log("planEstimateCT 함수 내의 installmentMonthlyFee 값 계산", installmentMonthlyFee) 

    //Nếu có chọn Trả góp tạm hoãn LG  || instMonthlyDefer_1: tiền trả góp hàng tháng đợt 1
    if (wirelessAdviceStep3Estimate?.instMonthlyDefer_1) {
        installmentMonthly = wirelessAdviceStep3Estimate.instMonthlyDefer_1
        wirelessAdviceStep3Estimate.installmentMonthly = installmentMonthly
    }

    let telcomID          = wirelessAdviceStep3Estimate.phone.telcomID;
    let soldierDiscount   = wirelessAdviceStep3Estimate.soldierDiscount ? +wirelessAdviceStep3Estimate.soldierDiscount : 0;
    let packageFee        = wirelessAdviceStep3Estimate.dataPlanNewDateChange;
    let packageFeeClassID = packageFee?.classID || 0;
    let packageFeeId      = packageFee?.id || 0;
    let packageFeePlacId  = packageFee?.plac_id || 0;

    if(telcomID == 17){
        if(
            packageFeeClassID == "81" || packageFeeClassID == "84" || packageFeeClassID == "85" || packageFeeClassID == "86" || packageFeeClassID == "88" || // 5G 청소년, LTE 청소년, LTE 어르신, 3G 요금제, 태블릿
            packageFeeClassID == "91" || packageFeeClassID == "87" || packageFeeClassID == "159" || packageFeeClassID == "82" // 신규가입불가, 웨어러블, 5G 어르신, 5G 태블릿
        ){ 
            soldierDiscount = 0;
        }
        else if(packageFeeId == 3082 || packageFeeId == 3085){ // 0플랜 히어로, 0플랜 슈퍼히어로
            soldierDiscount = 0;
        }
        else if(packageFeePlacId == 76 || packageFeePlacId == 70){ // T끼리 맞춤형 (신규가입불가), LTE 맞춤형 요금제 (신규가입불가)
            soldierDiscount = 0;
        }
        else{
            soldierDiscount = wirelessAdviceStep3Estimate.soldierSkt;
            
            if(soldierDiscount != null){
                if((wirelessAdviceStep3Estimate?.welfareDiscountExpectedPlan?.id || 0) != 6){
                    soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount)  * 0.2;
                }
                else{
                    soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount)  * 0.2;
                }
            }
            else{
                soldierDiscount = 0;
            }
        }
    }

    if(telcomID == 18){
        let combinePlans          = wirelessAdviceStep3Estimate.combinePlans;
        let combinePlansInfo      = null;
        let discountConditionName = null;

        if(
            packageFeeClassID == "106" || packageFeeClassID == "108" || packageFeeClassID == "110" || packageFeeClassID == "111" || packageFeeClassID == "148" || // 5G 청소년, 5G 어르신, LTE 청소년, LTE 어르신, 2G
            packageFeeClassID == "115" || packageFeeClassID == "117" || packageFeeClassID == "107" || packageFeeClassID == "112" || packageFeeClassID == "113" || // 키즈, 신규가입불가, 5G 태블릿, 3G 요금제, 태블릿
            packageFeeClassID == "115" || packageFeeClassID == "116"  // 웨어러블, 라우터 전용
        ){ 
            soldierDiscount = 0;
        }
        else if(
            packageFeeId == 2834 || packageFeeId == 2828 || packageFeeId == 3628 || packageFeeId == 3629 || packageFeeId == 2840 || // 현역병사 데이터 55, 현역병사 데이터 33, LTE 복지 33, LTE 복지 49, LTE 표준요금제
            packageFeeId == 3655 || packageFeeId == 3656 // 5G 복지 55, 5G 복지 75
        ){ 
            soldierDiscount = 0;
        }
        else{
            soldierDiscount = wirelessAdviceStep3Estimate.soldierLguplus;

            if(soldierDiscount != null){
                if((wirelessAdviceStep3Estimate?.welfareDiscountExpectedPlan?.id || 0) != 6){
                    soldierDiscount = (basicCost - rateAgreement - promotion - combinedDiscount - welfareDiscount)  * 0.2;
                }
                else{
                    soldierDiscount = (basicCost - rateAgreement - promotion - combinedDiscount)  * 0.2;
                }
            }
            else{
                soldierDiscount = 0;
            }
        }

        if(combinePlans != null && combinePlans.length > 0){
            combinePlansInfo      = combinePlans[0];
            discountConditionName = combinePlansInfo.discount_condition_name;

            if(discountConditionName != null){
                discountConditionName.map((item) => {
                    if(item.code == "TEENAGERS_PROMOTION"){
                        soldierDiscount = 0;
                    }
                });
            }
        }
    }

    if(telcomID == 19){
        if(
            packageFeeClassID == "94" || packageFeeClassID == "96" || packageFeeClassID == "97" || packageFeeClassID == "98" || packageFeeClassID == "99" || // 5G 청소년, LTE 청소년, LTE 어르신, 3G 요금제, 태블릿
            packageFeeClassID == "100" || packageFeeClassID == "101" || packageFeeClassID == "102" || packageFeeClassID == "103" || packageFeeClassID == "104" || // 5G 태블릿, 웨어러블, 키즈, 에그 전용, 신규가입불가
            packageFeeClassID == "160" // 5G 어르신
        ){ 
            soldierDiscount = 0;
        }
        else if(packageFeeId == 2952 || packageFeeId == 2951 || packageFeeId == 2955 || packageFeeId == 2954 || packageFeeId == 2953 ){ // 나눔 베이직, 데이터 ON 나눔, Y군인 33, Y군인 55 Plus, Y군인 77 Plus
            soldierDiscount = 0;
        }
        else{
            soldierDiscount = wirelessAdviceStep3Estimate.soldierKt;

            if(soldierDiscount != null){
                if((wirelessAdviceStep3Estimate?.welfareDiscountExpectedPlan?.id || 0) != 6){
                    soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount)  * 0.2;
                }
                else{
                    soldierDiscount = (basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount)  * 0.2;
                }
            }
            else{
                soldierDiscount = 0;
            }
        }
    }
    
    wirelessAdviceStep3Estimate.soldierDiscount = soldierDiscount;

    // chi phí theo tháng (월 요금)
    let monthlyFee = basicCost - (rateAgreement + optionalAgreement + promotion + promotionDurationDiscountOne + promotionDurationDiscountTwo + combinedDiscount + welfareDiscount + discountCard + mutualService + soldierDiscount);
    // 1 = tiền hỗ trợ thêm(HTTB) (추가지원금)
//삭제 필요
//console.log("planEstimateCT 함수 내의 installmentMonthly 값 계산", installmentMonthly)    
//console.log("planEstimateCT 내의 monthlyFee 값 계산 항목 : ", basicCost, rateAgreement, optionalAgreement, promotion, promotionDurationDiscountOne, promotionDurationDiscountTwo, combinedDiscount, welfareDiscount, discountCard, mutualService, soldierDiscount)

    if (optionFee === OPTION_FEE.disclosureSubsidy) {
        monthlyFee += optionalAgreement
    }
    if (optionFee === OPTION_FEE.disclosureSubsidy && [1, 2, '1', '2'].includes(conversion2G?.id)) {
        monthlyFee -= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB
    }
    if (monthlyFee < 0) {
        monthlyFee = 0
    }
    
    // monthlyFee += applyUsedProgram // check new
    monthlyFee = roundUpNumber(monthlyFee)
    wirelessAdviceStep3Estimate.monthlyFee = monthlyFee;

        // Tiền yêu cầu theo tháng A + B (월별 청구금액)
    // let amountMonthlyDevice = installmentMonthly + monthlyFee  //old
    let amountMonthlyDevice = wirelessAdviceStep3Estimate.installmentMonthly + wirelessAdviceStep3Estimate.monthlyFee + applyUsedProgram
    wirelessAdviceStep3Estimate.amountMonthlyDevice = amountMonthlyDevice

//삭제 필요
//console.log("wirelessAdviceStep3Estimate.installmentMonthly 값, 요금제 변경 시 출력되는 값 : ", wirelessAdviceStep3Estimate.installmentMonthly)
//console.log("wirelessAdviceStep3Estimate.monthlyFee 값, 요금제 변경 시 출력되는 값 : ", wirelessAdviceStep3Estimate.monthlyFee)

    // (Số tiền thanh toán hàng tháng/tổng số ngày tháng hiện tại) X số ngày còn lại tháng hiện tại
    let day = moment().date()
    let lastDay = moment().endOf('month').date()
    // let moneyRemainingMonth = ((monthlyFee / lastDay) * (lastDay - day + 1)) + installmentMonthly + applyUsedProgram // tien con lai tinh theo cong thuc = B / ... + A  OLD
    let moneyRemainingMonth = (( (monthlyFee + applyUsedProgram) / lastDay) * (lastDay - day + 1)) + (installmentMonthly)   //new: installmentMonthly (must get origin value)
    if (recipe_days_remaining == 1) {
        moneyRemainingMonth = (amountMonthlyDevice / lastDay) * (lastDay - day + 1) // tien con lai == 1 thi tinh theo cong thuc la (A+B) / ...
    }
    // Số tiền dự kiến tháng này
    let estimatedAmountMonth = roundUpNumber(moneyRemainingMonth)
    // moneyRemainingMonth = estimatedAmountMonth - installmentMonthly
    wirelessAdviceStep3Estimate.estimatedAmountMonth = estimatedAmountMonth
    wirelessAdviceStep3Estimate.moneyRemainingMonth = moneyRemainingMonth
    // Gói phí muốn đổi
    // let planNewDateChange = +(wirelessAdviceStep3Estimate.planNewDateChange || 0)
    // chi phí dự kiến khi thay đổi gói phí
    // let estimatedRatesChangingPlans = amountMonthlyDevice - basicCost + planNewDateChange
    // Tiền yêu cầu theo tháng C (월별 청구금액)
    let amountMonthlyOption = roundUpNumber(insurrance + extraServices)

    // //Nếu có chọn ctrinh BTDT cũ (not tạm hoãn LG) => cộng thêm DV thêm tương ứng tới C (amountMonthlyOption)
    // if (wirelessAdviceStep3?.usedPhoneCompensation?.ets_id) {
    //     amountMonthlyOption = roundUpNumber(amountMonthlyOption + parseFloat(wirelessAdviceStep3Estimate.usedPhoneCompensation.ets_price))
    // }

    wirelessAdviceStep3Estimate.amountMonthlyOption = amountMonthlyOption

    // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
    let amountMonthlyTotal = amountMonthlyDevice + amountMonthlyOption
    wirelessAdviceStep3Estimate.amountMonthlyTotal = amountMonthlyTotal;
//삭제 필요
//console.log("amountMonthlyDevice 값, 요금제 변경 시 출력되는 값", amountMonthlyDevice)
    if(wirelessAdviceStep3Estimate.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
        if(wirelessAdviceStep3Estimate.usedPhoneCompensation.telcomID == 18 && wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
            let instType = wirelessAdviceStep3Estimate.usedPhoneCompensation.inst_type;

            if(instType == 83 || instType ==84){ // 구간할부/공시지원금 || 구간할부/선택약정
                let instPrincipalUsedPhone2 = wirelessAdviceStep3Estimate.startingPrice * wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금
                let instPrincipalUsedPhone1 = wirelessAdviceStep3Estimate.installmentPrincipal - instPrincipalUsedPhone2; // 1구간 할부원금
    
                if(instPrincipalUsedPhone1 < 0){
                    instPrincipalUsedPhone1 = 0;
                }
    
                if(instPrincipalUsedPhone1 < (instPrincipalUsedPhone2 / 65 * 35)){ // 최소 금액 미만
                    wirelessAdviceStep3Estimate.usedPhoneCompensation = {"tup_id": "", "tup_name": "미적용", "instType": 0};
                }
                else{
                    installmentMonth =Number(wirelessAdviceStep3Estimate.usedPhoneCompensation.tup_inst_circle_mo);

                    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
                        installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(instPrincipalUsedPhone1, interestRate, installmentMonth);
                    }

                    if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
                        installmentFeeArray = calcInstallmentFeePrincipalByMonth(instPrincipalUsedPhone1, interestRate, installmentMonth);
                    }

                    installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

                    if (installmentFee < 0) {
                        installmentFee = 0;
                    }

                    installmentFee = Math.round(installmentFee);

                    if(installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3){
                        installmentFee  = 0;
                    }

                    installmentFee = installmentFee + (instPrincipalUsedPhone2 * interestRate / 100 / 12 * installmentMonth);

                    let installmentMonthlyFee = installmentFee / installmentMonth;
                    let installmentMonthly    = (instPrincipalUsedPhone1 / installmentMonth) + installmentMonthlyFee;
                    let amountMonthlyDevice   = wirelessAdviceStep3Estimate.monthlyFee + installmentMonthly + Number(wirelessAdviceStep3Estimate.usedPhoneCompensation.ets_price);
                    let moneyRemainingMonth   = roundUpNumber((( (wirelessAdviceStep3Estimate.monthlyFee + wirelessAdviceStep3Estimate.applyUsedProgram) / lastDay) * (lastDay - day + 1)) + (installmentMonthly));

                    if(wirelessAdviceStep3Estimate.usedPhoneMemberShip){
                        if(wirelessAdviceStep3Estimate.usedPhoneMemberShip.unit == 1){
                            amountMonthlyDevice = amountMonthlyDevice - (amountMonthlyDevice * parseFloat(wirelessAdviceStep3Estimate.usedPhoneMemberShip.value) / 100);
                        }
                        else{
                            amountMonthlyDevice = amountMonthlyDevice - parseFloat(wirelessAdviceStep3Estimate.usedPhoneMemberShip.value ?? 0);
                        }
                    }

                    wirelessAdviceStep3Estimate.installmentFee              = Math.round(installmentFee);
                    wirelessAdviceStep3Estimate.installmentMonthlyFee       = installmentMonthlyFee;
                    wirelessAdviceStep3Estimate.installmentMonthly          = roundUpNumber(installmentMonthly);
                    wirelessAdviceStep3Estimate.installmentMonthlyPrincipal = installmentMonthly - installmentMonthlyFee;
                    wirelessAdviceStep3Estimate.amountMonthlyDevice         = roundUpNumber(amountMonthlyDevice);
                    wirelessAdviceStep3Estimate.amountMonthlyTotal          = roundUpNumber(amountMonthlyDevice + wirelessAdviceStep3Estimate.amountMonthlyOption);
                    wirelessAdviceStep3Estimate.estimatedAmountMonth        = Math.round(moneyRemainingMonth);
                }
            }
        }
    }

    return wirelessAdviceStep3Estimate;
}

export const welfareDiscountCT = (WirelessAdviceStep3) => {
    // let choosePromotion = choosePromotionCT(WirelessAdviceStep3);
    
     if(WirelessAdviceStep3.basicCost != null) {
        // var combinedDiscount=0
        // if(WirelessAdviceStep3.combinePlans.length > 0) {
        //     combinedDiscount = roundUpNumber(WirelessAdviceStep3.discountWireLessPriceExpectedPlan.discount_wireless_price)

        // }else {
        //     combinedDiscount=WirelessAdviceStep3.combinedDiscount ? WirelessAdviceStep3.combinedDiscount : 0
        // }
        var combinedDiscount = roundUpNumber(WirelessAdviceStep3?.discountWireLessPriceExpectedPlan?.discount_wireless_price || 0)
        // combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1))
        // combinedDiscount= 6750
        let combinationId    = WirelessAdviceStep3?.combineCondition?.id || 0;
        let planId           = WirelessAdviceStep3?.dataPlanNewDateChange?.id || 0;
        let planTogether    = [
            3634, 3669, 3671, 2684, 3667, // 5G 시그니처, 5G 시그니처(넷플릭스), 5G 시그니처 (유튜브 프리미엄), 5G 프리미어 슈퍼, 5G 프리미어 슈퍼(넷플릭스)
            3670, 2683, 3666, 3668, 2682, // 5G 프리미어 슈퍼(유튜브 프리미엄), 5G 프리미어 플러스, 5G 프리미어 플러스(넷플릭스), 5G 프리미어 플러스 (유튜브 프리미엄), 5G 프리미어 레귤러
            3633, 3631, 3701, 3630, 3641, // 5G 프리미어 에센셜, LTE 프리미어 플러스, LTE 프리미어 플러스 (유튜브 프리미엄), LTE 프리미어 에센셜, 5G 슈퍼 플래티넘 (신규가입불가)
            3640, 3639, 3760, 3761, 3759, // 5G 플래티넘 (신규가입불가), 5G 프리미엄 (신규가입불가), 5G 프리미어 레귤러 (디즈니+), 5G 프리미어 플러스 (디즈니+), 5G 프리미어 슈퍼 (디즈니+)
            3758, 3800, 3802, 3801, 3803, // 5G 시그니처 (디즈니+), 5G 프리미어 플러스 (헬로렌탈구독), 5G 프리미어 슈퍼 (헬로렌탈구독), 5G 시그니처 (헬로렌탈구독), 5G 프리미어 플러스 (일리커피구독)
            3804, 3805, 3820, 3819, 3817, // 5G 프리미어 슈퍼 (일리커피구독), 5G 시그니처 (일리커피구독), 5G 프리미어 레귤러 (티빙), 5G 프리미어 플러스 (티빙), 5G 프리미어 슈퍼 (티빙)
            3818, 3834, 3835, 3839, 3841, // 5G 시그니처 (티빙), 5G 프리미어 슈퍼 (넷플릭스+유튜브 프리미엄), 5G 시그니처 (넷플릭스+유튜브 프리미엄), 5G 프리미어 플러스 (갤럭시버즈2프로), 5G 시그니처 (갤럭시버즈2프로)
            3837, 3838, 3842, 3843, 3844, // 5G 프리미어 슈퍼 (갤럭시워치5), 5G 시그니처 (갤럭시워치5), 5G 프리미어 플러스 (갤럭시버즈), 5G 프리미어 슈퍼 (갤럭시버즈), 5G 시그니처 (갤럭시버즈)
            3762, 3816, 3807, 3806, 3737, // LTE 프리미어 플러스 (디즈니+), LTE 프리미어 플러스 (티빙), LTE 프리미어 플러스 (헬로렌탈구독), LTE 프리미어 플러스 (일리커피구독), 5G 프리미어 플러스 (에어팟) (신규가입불가)
            3738, 3739                    // 5G 프리미어 슈퍼 (에어팟) (신규가입불가), 5G 시그니처 (에어팟) (신규가입불가)
        ];

        if(combinationId == 17 || combinationId == 18){ // 투게더결합
            if(planTogether.includes(planId) == false){ // 결합 대상이 아닌 경우
                combinedDiscount = 0;
            }
        }

        var rateAgreement=(WirelessAdviceStep3.rateAgreement && !WirelessAdviceStep3.isRateAgreement) ? WirelessAdviceStep3.rateAgreement : 0
        var optionalAgreement=WirelessAdviceStep3.optionalAgreementPlanEstimated ? WirelessAdviceStep3.optionalAgreementPlanEstimated : 0
        var optionFee=WirelessAdviceStep3.optionFee
        if(optionFee == 1) {
            optionalAgreement=0
        }
        var promotion=WirelessAdviceStep3.promotion && WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdviceStep3.promotion?.promo_cost : 0

        var basicCostCalc=WirelessAdviceStep3.basicCostCalc
        var basicCost=WirelessAdviceStep3.basicCost
        var valueBasicCost=0
        if(basicCostCalc != 0) {
            valueBasicCost=basicCostCalc
        }else {
            valueBasicCost=roundUpNumberPlan(basicCost)
        }

        let dataTelcom = WirelessAdviceStep3?.phone?.short_name
        if(dataTelcom) {
            dataTelcom=dataTelcom.toUpperCase()
        }

        // optionalAgreement = roundUpNumberPlan(optionalAgreement)
        let optionID6=((valueBasicCost - optionalAgreement - rateAgreement - promotion - combinedDiscount)*0.5)
        let optionID3= (valueBasicCost - promotion) > 28600 ? 28600 : (valueBasicCost - promotion)
        let optionID45=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
        let dataWelfareDiscount=[
            {id:0, value:"미적용", cost:0},
            {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 69000*0.35},
            {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 69000*0.35},
            {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
            {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:(valueBasicCost > 45100 ? 23650: optionID45)},
            {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:(valueBasicCost > 45100 ? 23650: optionID45)},
            {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
        ]

        if(dataTelcom == "SKT") {
            let optionID45SKT=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
            let optionID12SKT=  ((valueBasicCost - rateAgreement - combinedDiscount)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount)*0.35 : 0)

            dataWelfareDiscount=[
                {id:0, value:"미적용", cost:0},
                {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
                {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
                {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost - 12100)*0.35)) > 23650 ? 23650 : optionID45SKT)},
                {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost - 12100)*0.35)) > 23650 ? 23650 : optionID45SKT)},
                {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
            ]
        }

        if(dataTelcom == "KT") {
            let optionID45KT=  (12100+((valueBasicCost-12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100 - promotion)*0.35))
            let optionID45KTMainCondition=  (valueBasicCost - 12100 - promotion) < 0 ? ((12100 - promotion) > 0 ? (12100 - promotion) : 0) :((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                optionID45KT)
            let optionID12KT=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0)

            dataWelfareDiscount=[
                {id:0, value:"미적용", cost:0},
                {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
                {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
                {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost-12100 - promotion)*0.35)) > 23650 ? 23650:
                        optionID45KT)},
                {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:optionID45KTMainCondition},
                {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
            ]
        }

        if(dataTelcom == "LGU+") {
            let optionID45LGU=  (12100+((valueBasicCost - 12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost - 12100 - promotion)*0.35))
            let optionID12LGU=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0)
            dataWelfareDiscount=[
                {id:0, value:"미적용", cost:0},
                {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
                {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
                {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:(valueBasicCost > 45100 ? 23650:
                        optionID45LGU)},
                {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                        optionID45LGU)},
                {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
            ]
        }

        let welfareDiscountCost;

        if (dataWelfareDiscount && WirelessAdviceStep3.welfareDiscount) {
            dataWelfareDiscount.map((item, index) => {
                if (item.id == WirelessAdviceStep3.welfareDiscount.id) {
                    welfareDiscountCost = item.cost
                }
            })
        }else{
            welfareDiscountCost = 0
        }

        return welfareDiscountCost;
    }else {
        return null;
    }

}

export function checkBlockFeature() {
    const { auth } = window.store.getState()
    return process.env.REACT_APP_BLOCK_FEATURE === 'true' && auth.role !== 'Admin'
}

export const getDeviceDiscountWired = (device, discount) => {
    return (device?.wd_commitment_fee || 0) - (discount?.device?.value || 0) - (discount?.combineDiscount || 0) - (discount?.device?.additional || 0)
}

export const getOptionDiscountWired = (option, discount, keyOption) => {
    return (+option.wdo_commitment_fee * (+option.qty || 1)) - (discount?.options ? (discount?.options[keyOption]?.value || 0) : 0) - (discount?.options ? (discount?.options[keyOption]?.additional || 0) : 0)
}

export const calcPenaltyWired = (monthlyFee, monthlyFeeAfterDiscount, non_commitment_fee, commitment_fee, non_commitment_rent_fee, commitment_rent_fee, fines, month, percentType = 1) => {
    monthlyFee = +monthlyFee;
    monthlyFeeAfterDiscount = +monthlyFeeAfterDiscount;
    non_commitment_fee = +non_commitment_fee;
    commitment_fee = +commitment_fee;
    non_commitment_rent_fee = +non_commitment_rent_fee;
    let penalty = 0;
    [...Array(+month).keys()].map((index) => {
        let percent = 0;
        (fines || []).map(item => {
            if (item.month == (index + 1)) {
                percent = +item.scale.replace(/[^0-9]/g,'');
            }
        })
        if (!percentType) {
            percent = 100 - percent
        }
        penalty += ((monthlyFee - monthlyFeeAfterDiscount) + (non_commitment_fee - commitment_fee) + (non_commitment_rent_fee - commitment_rent_fee)) * (percent / 100)
    })
    return penalty
}

export const getOptionalAgreementHardcording = (packageFeeId, optionalAgreement) => {
    let result = optionalAgreement;

    switch(packageFeeId){
        case 3945 : //5G 슬림 4GB
        case 3950 : //5G 슬림(이월) 4GB
            result = 9250;
            break;
        case 3961 : //0청년 37
            result = 9300;
            break;
        case 3966 : //5G 웰컴 1
            result = 9750;
            break;
        case 3898 : // 5G 시니어 C형(KT)
            result = 10250;
            break;
        case 3879 : // 5G 시니어 C형(SK)
        case 3897 : // 5G 시니어 B형 
            result = 10500;
            break;
        case 3906 : // 5G 시니어 B형 
            result = 10780;
            break;
        case 3949 : //5G 슬림(이월) 7GB
            result = 11250;
            break;
        case 3877 : // 5G 시니어 A형
        case 3960 : //5G 컴팩트플러스
            result = 11270;
            break;
        case 3899 : // 5G 시니어 베이직
        case 3967 : //5G 웰컴 3
            result = 12250;
            break;
        case 3905 : // 0 청년 49 
        case 3832 : // 베이직
            result = 12270;
            break;
        case 3944 : //5G 슬림 10GB
        case 3948 : //5G 슬림(이월) 10GB
            result = 12500;
            break;
        case 3946 : //5G 슬림(이월) 21GB
        case 3943 : //5G 슬림 21GB
            result = 14500;
            break;
        case 3968 : //5G 웰컴 5
            result = 14750;
            break;
        case 3904 : // 0 청년 59 
        case 3833 : // 베이직 플러스
            result = 14800;
            break;
        case 3836 : // 5G 슬림 플러스
            result = 15250;
            break;
        case 3907 : // 5G 심플 50GB
            result = 15750;
            break;
        case 3889 : // 베이직플러스 30GB업
        case 3915 : // 0 청년 59 36GB업
            result = 16000;
            break;
        case 3908 : // 5G 심플 70GB 
            result = 16250;
            break;
        case 3909 : // 5G 심플 90GB
            result = 16750;
            break;
        case 3887 : // 베이직플러스 75GB업
            result = 17000;
            break;
        case 3926 : //5G 프리미어 에센셜
        case 3927 : //LTE 프리미어 에센셜
            result = 21250;
            break;
        case 3901 : // 0 청년 89 
            result = 22270;
            break;
        case 3697 :
        case 3719 : // Số tiền "cam kết lựa chọn" của gói "슈퍼플랜 베이직 초이스 (바디프랜드) " trước khi thay đổi là 22,550won → Đổi lại thành 22,500 won
        case 3790 : // 초이스 베이직 (유튜브 프리미엄)
        case 3785 : // 디바이스 초이스 베이직
        case 3809 : // 초이스 베이직 (티빙/지니)
        case 3821 : // 초이스 베이직 (BC바로 카드)
        case 3849 : // 초이스 베이직(코웨이)
        case 3871 : // 초이스 베이직 (티빙/밀리)
        case 3774 : //초이스 베이직 (우리카드)
        case 3771 : //초이스 베이직 (삼성)
        case 3755 : //초이스 베이직 (디즈니+)
            result = 22500;
            break;
        case 3759 : // 5G 프리미어 슈퍼(디즈니+)
            result = 28750;
            break;
        case 3698 :
        case 3717 : // Số tiền "cam kết lựa chọn" của gói "슈퍼플랜 프리미엄 초이스 (바디프랜드)" trước khi thay đổi là 32,505won → Đổi lại thành 32,500 won
        case 3788 : // 초이스 프리미엄 (유튜브 프리미엄)
        case 3787 : // 초이스 프리미엄(디바이스)
        case 3811 : // 초이스 프리미엄(티빙/지니)
        case 3823 : // 초이스 프리미엄 (BC바로카드)
        case 3758 : // 5G 시그니처 (디즈니+)
        case 3851 : // 초이스 프리미엄(코웨이)
        case 3873 : // 초이스 프리미엄(티빙/밀리)
        case 3776 : //초이스 프리미엄 (우리카드)
        case 3773 : //초이스 프리미엄 (삼성)
        case 3757 : //초이스 프리미엄 (디즈니+)
            result = 32500;
            break;
        case 3688 : // 기존 5G 인터넷 베이직 1695부분
            result = 20000;
            break;    
      }
      
      return result;
}