 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { priceFormat, numberMathFloor, roundUpNumber } from './../../../utils/helper';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const colorPrice={color:'#2F8BFF',fontWeight:'bold'}

class KtSoldier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModal:false
        }
    }

    openPopup = () => this.setState({isOpenModal: !this.state.isOpenModal})

    componentDidUpdate(prevProps) {
        let disabled = this.disableCheck();

        if(disabled){
            this.fnCalWirelessAdviceStep3({id : 0});
        }
    }

    chooseItem(data) {
        this.fnCalWirelessAdviceStep3(data);
        this.openPopup();
    }

    fnCalWirelessAdviceStep3(data){
        if (data.id == 0) {
            data = null;
        }

        this.props.change('WirelessAdviceStep3.soldierKt', data);
    }

    disableCheck = () => { 
        let result              = false;
        let wirelessAdviceStep3 = this.props.WirelessAdvice.WirelessAdviceStep3;
        let packageFee          = wirelessAdviceStep3.packageFee;
        let classID             = packageFee?.classID || 0;
        let id                  = packageFee?.id || 0;

        if(
            classID == "94" || classID == "96" || classID == "97" || classID == "98" || classID == "99" || // 5G 청소년, LTE 청소년, LTE 어르신, 3G 요금제, 태블릿
            classID == "100" || classID == "101" || classID == "102" || classID == "103" || classID == "104" || // 5G 태블릿, 웨어러블, 키즈, 에그 전용, 신규가입불가
            classID == "160"  // 5G어르신
        ){ 
            result = true;
        }
        else if(id == 2952 || id == 2951 || id == 2955 || id == 2954 || id == 2953 ){ // 나눔 베이직, 데이터 ON 나눔, Y군인 33, Y군인 55 Plus, Y군인 77 Plus
            result = true;
        }
        
        return result;
    }

    renderLi() {
        let data = [
            {id: 0, value: "미적용"},
            {id: 1, value: "월정액 20% 요금할인"}
        ];

        let activeData  = this.props.WirelessAdvice.WirelessAdviceStep3?.soldierKt || 0; 

        return (data.map((item, key) => {
            return (
                <li className={`cursor ${activeData != null && activeData.value && activeData.value == item.value ? "active" : (activeData == null && item.id == 0 ? "active" : "")}`}
                    key={key} onClick={() => this.chooseItem(item)}>
                    {item.value}
                </li>
            );
        }));
    }


    render() {
        let wirelessAdviceStep3 = this.props.WirelessAdvice.WirelessAdviceStep3;
        let soldier             = wirelessAdviceStep3.soldierKt;
        let showText            = "";

        if(soldier == null) {
            showText = "미적용";
        }
        else{
            let basicCost = +(wirelessAdviceStep3.basicCost || 0) // chi phí cơ bản (기본료)
            let promotion = +(wirelessAdviceStep3.promotion?.promo_cost || 0) // promotion (프로모션)
            //let promotionDurationDiscountOne = +(wirelessAdviceStep3.promotionDurationDiscountOne || 0) // promotion (프로모션)
            //let promotionDurationDiscountTwo = +(wirelessAdviceStep3.promotionDurationDiscountTwo || 0) // promotion (프로모션)
            let rateAgreement = +(wirelessAdviceStep3.rateAgreement || 0) // cam k?t goi phi (요금약정)
            let promotionDurationDiscountOne = 0;
            let promotionDurationDiscountTwo = 0;
            let combinedDiscount = roundUpNumber(+( +(wirelessAdviceStep3.combinePlans[0]?.discount_wireless_price || 0) + +(wirelessAdviceStep3.combinePlans[0]?.discount_tv || 0))) // gi?m gia k?t h?p (결합 할인)
            let welfareDiscount = 0;

            if((wirelessAdviceStep3.welfareDiscount?.id || 0) != 6){ // 만 65세 이상 기초연금수급자(최대 12,100원)
                welfareDiscount = +(wirelessAdviceStep3.welfareDiscount?.cost || 0) // gi?m gia phuc l?i (복지 할인)
            }

            showText =  "-" + priceFormat(numberMathFloor((basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount) * 0.2)) + "원";
        }

        return (
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">Y군인<br/>요금할인</label>
                <div className="col-sm-8">
                    <button
                        className={`${this.disableCheck() ? 'form-control cursor-text color-bg-gray' : ''} btn-input-form mb-0 btn-devices pr-2`}
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-28 "
                        onClick={this.openPopup}
                        style={(soldier ? colorPrice : {})}
                        disabled={this.disableCheck()}
                    >
                        {showText === '미적용' ? <span className='opacity-disabled'>미적용</span> : showText}
                    </button>
                    <Modal
                        isOpen={this.state.isOpenModal}
                        contentClassName={'modal-content pl-4 pr-4'}
                        toggle={this.openPopup} 
                        className={'modal-dialog modal-dialog modal-dialog-centered modal-additional '}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Y군인 요금할인
                                <span className="text-content font-13">
                                    ~ 입대일로부터 24개월동안 20% 할인 제공
                                </span>
                                <br />
                                <span className="text-content font-13">
                                    ※ 복무중인 현역 병사만 가입 가능
                                </span>
                                <br />
                                <span className="text-content font-13">
                                    Y군인, 키즈/청소년/스마트기기, 선불, 기업 요금제 가입 불가
                                </span>
                            </h5>
                            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.openPopup}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <ModalBody>
                            <ul className="list-additional list-sale-01">
                                {this.renderLi()}
                            </ul>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

KtSoldier.propTypes = {WirelessAdvice: PropTypes.object,
    change: PropTypes.func
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm
)(KtSoldier);
