import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import api from "./../../utils/api";
import * as helper from "./../../utils/helper";
import { showLoading, hideLoading } from '../../store/actions/ui';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import {getNotifications, loadmoreDataNotification} from '../../store/actions/dataStore';
import moment from 'moment';
import Moment from 'react-moment';
import Skype from "./../../components/Share/Skype";
import Kakao from"./../../components/Share/Kakao";
import Tooltip from "../../components/Tooltip";
import { ReactComponent as IconGmail } from './../../assets/images/gmail.svg';
class MyCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSearch: {
                page: 1,
                per_page: 10,
                keyword: '',
            },
            coupons: {
                data: []
            }
        }
    }

    componentDidMount() {
        this.onPaginateRequest();
    }

    onPaginateRequest = () => {
        
        let dataSearch = {
            ...this.state.dataSearch,
            storeID: this.props.auth.store.sto_id,
        }
        this.props.showLoading();
        api.profile.getManagementCoupon(dataSearch).then(res => {
            this.setState({
                coupons: res.data.data.coupons
            })
            this.props.getNotifications();
            this.props.hideLoading();
        }).catch((e) => {
            console.log(e)
            this.props.hideLoading();
        })
    }

    onPagination = (data) => {
        var arrayNumber = [];
        for (let i = 1; i <= Math.ceil(data.total / data.per_page); i++) {

            if (data.current_page == i) {
                if ([1, 2].indexOf(data.current_page) == -1) {
                    if (i !== 1) {
                        arrayNumber.push(<li className="page-item" key={1}><a className="page-link" onClick={() => this.onClickPaginationHandle(1)}>1</a></li>);

                    } else {
                        arrayNumber.push(<li className="page-item" key={i - 2}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 2)}>{i - 2}</a></li>);
                    }
                }

                if (i - 2 > 1) {
                    arrayNumber.push(<li className="page-item" key={'...'}><a className="page-link">...</a></li>);
                }

                if ([1].indexOf(data.current_page) == -1) {
                    arrayNumber.push(<li className="page-item" key={i - 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 1)}>{i - 1}</a></li>);
                }

                arrayNumber.push(<li className="page-item active" key={i}><a className="page-link" onClick={() => this.onClickPaginationHandle(i)}>{i}</a></li>);

                if ((i + 1) < Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={i + 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i + 1)}>{i + 1}</a></li>);
                }

                if ([Math.ceil(data.total / data.per_page) - 1, Math.ceil(data.total / data.per_page) - 2].indexOf(i) == -1 && i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={'....'}><a className="page-link">...</a></li>);
                }


                if (i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={Math.ceil(data.total / data.per_page)}><a className="page-link" onClick={() => this.onClickPaginationHandle(Math.ceil(data.total / data.per_page))}>{Math.ceil(data.total / data.per_page)}</a></li>);
                }
            } else {
                //arrayNumber.push(<li className="page-item"><a className="page-link" >{i}</a></li>);
            }
        }
        if (Math.ceil(data.total / data.per_page) > 1) {
            return (
                <nav aria-label="Page navigation example" className="pagination-notifi">
                    <ul className="pagination">


                        <li className={`page-item item-previous ${this.state.dataSearch.page === 1 ? 'disabled' : ''}`}>
                            <a className="page-link"  aria-label="Previous" onClick={() => this.prevPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images/Left.svg" alt="" />
                                </span>
                            </a>
                        </li>
                        {arrayNumber.map((item, i) =>
                            item
                        )}
                        <li className={`page-item item-next ${this.state.dataSearch.page === this.state.coupons.last_page ? 'disabled' : ''}`}>
                            <a className="page-link"  aria-label="Next" onClick={() => this.nextPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images//Right.svg" alt="" />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>

            )
        }

    }

    prevPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page - 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    nextPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page + 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    onClickPaginationHandle = (number) => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: number
            }
        }, () => {
            this.onPaginateRequest()
        })

    }

    handleDelCoupon = (couponID) => {
        let data = {
            couponID: parseInt(couponID),
        }
        api.profile.delCoupon(data).then(res => {
            this.props.hideModal();
            this.onPaginateRequest();
            
        }).catch((e) => {
            this.props.hideModal();
            console.log(e)
        })

    }

    popupDelCoupon = (coup_id) => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center">
                    <span className="notification_delete"> 쿠폰번호 삭제하시겠습니까?</span>
                </h4>
                <div className="form-row">
                    <div className="w-100 modal-footer text-center">
                        <button className="btn btn_close mr-2" onClick={() => this.props.hideModal()}>취소</button>
                        <button className="btn btn_save  ml-2" onClick={() => this.handleDelCoupon(coup_id)}>확인</button>
                    </div>
                </div>
            </div>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal-dialog-centered modal-my-coupont',
        }
        this.props.showModal(dataModal);
    }

    onClickEmail = (coup_code) => {
        window.shareOnWeb('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body='+coup_code+'&ui=2&tf=1&pli=1')
    }

    onClickWhatApp = (coup_code) => {
        window.shareOnWeb('https://web.whatsapp.com/send?text='+coup_code)
    }

    popupShareCoupon = (coup_code) => {
        let xhtml = (
                <Fragment>
                    <div className="box_modal_sharing" id={'social-share'}>
                        <Skype
                            sharelink={coup_code}
                        />

                        {/*<div className="box_social">*/}
                        {/*  <a ><img src="/images/driver.svg" alt="" /></a>*/}
                        {/*  <span>Google Drive</span>*/}
                        {/*</div>*/}


                        <div className="box_social" onClick={this.onClickEmail}>
                            <a >
                            <IconGmail />
                            </a>
                            <span>Gmail</span>
                        </div>


                        <div className="box_social" onClick={this.onClickWhatApp}>
                            <a><i className="sprite sprite-whatapp"></i></a>
                            <span>WhatsApp</span>
                        </div>

                        <Kakao
                            sharelink={coup_code}
                        />
                    </div>
                    <div className="box_modal_sharing">
                        {/* <div className="box_social">
                            <a href=""><img src="/images/bluetooth.svg" alt=""/></a>
                            <span>Bluetooth</span>
                        </div>
                        <div className="box_social">
                            <a href=""><img src="/images/Evernote.svg" alt=""/></a>
                            <span>Evernote</span>
                        </div>
                        <div className="box_social">
                            <a href=""><img src="/images/Hangout.svg" alt=""/></a>
                            <span>Hangout</span>
                        </div>
                        <div className="box_social">
                            <a href=""><img src="/images/OneDrive.svg" alt=""/></a>
                            <span>OneDrive</span>
                        </div>
                        <div className="box_social">
                            <a href=""><img src="/images/more.svg" alt=""/></a>
                        </div> */}
                    </div>
                </Fragment>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal-lg modal-dialog-centered',
            close:true,
            title:'공유'
        }
        this.props.showModal(dataModal);
    }

    render() {
        return (
            <Fragment>
                <section className="wrapper_coupon">
                    <div className="container">
                        <div className="my_coupon pt-3">
                            <div className="table-responsive">
                                <table className="table table-borderless table_striped">
                                    <thead className="thead_bg">
                                        <tr>
                                            <th width={"5%"}>No</th>
                                            <th width={"20%"}>쿠폰명</th>
                                            <th width={"35%"}>쿠폰내역(금액 또는 내용)</th>
                                            <th width={"15%"}>쿠폰번호</th>
                                            <th width={"15%"}>종료일</th>
                                            <th width={"10%"} className="text-right">관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.coupons.data.map((item, key) =>
                                            <Fragment key={key}>
                                                <tr style={{cursor:'default'}}>
                                                    <td>{(this.state.dataSearch.page -1 )* this.state.dataSearch.per_page + key + 1}</td>
                                                    <td>{helper.limitString(item.coup_name, 50)}</td>
                                                    <td dangerouslySetInnerHTML={{__html:helper.limitString(item.coup_description, 100)}} ></td>
                                                    <td>{item.coup_code}</td>
                                                    <td>
                                                        {
                                                            moment(item.coup_end_date).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD') ? 
                                                            (
                                                                <span  style={{color:'green'}}>
                                                                    {moment(item.coup_end_date).format('YYYY-MM-DD')}
                                                                </span>
                                                            ): (
                                                                <span style={{color:'red'}}>
                                                                    {moment(item.coup_end_date).format('YYYY-MM-DD')}
                                                                </span>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="d-flex justify-content-end">
                                                        <Tooltip message={"공유"}
                                                                position={'top'}
                                                                className={"mr-4"}
                                                        ><a  onClick={() => this.popupShareCoupon(item.coup_code)}>
                                                            <i className="fas fa-share-alt"></i>
                                                        </a>
                                                        </Tooltip>
                                                        <Tooltip message={"삭제"}
                                                            position={'top'}
                                                        >
                                                              <a onClick={() => this.popupDelCoupon(item.coup_id)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </a>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {this.onPagination(this.state.coupons)}
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}




const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        getNotifications: () => dispatch(getNotifications()),
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(MyCoupon);

