import {
    POST_BOOKMARKS_FORM
} from '../actions';

import api from "../../utils/api";

export const postBookmarksFormSuccess = (data) => {
    return {
        type: POST_BOOKMARKS_FORM.POST_BOOKMARKS_FORM_SUCCESS,
        data: data
    }
};

export const postBookmarksFormRegist = (data) => dispatch => {
    return api.post_bookmarks_form.postBookmarksFormRegist(data)
        .then(res => {
            //dispatch(postBookmarksFormSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};



export const postBookmarksFormDelete = (data) => dispatch => {
    return api.post_bookmarks_form.postBookmarksFormDelete(data)
        .then(res => {
            dispatch(postBookmarksFormSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};
