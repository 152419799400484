
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component,Fragment  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector } from 'redux-form';
import validate from './validate-step-1.js';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import { getFirstNumberPhone } from '../../../utils/helper';
import * as routerName from '../../../utils/routerName';
import Moment from 'react-moment';
const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length < 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length == 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3,7)
    }
    if (onlyNums.length == 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4,8)
    }
}

class ForgotIdStep1Form extends Component {
    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    componentDidMount () {
        document.body.classList.add("body_wireless_advice");
    }
      
    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <p className="text-wrapper">
                    아이디가 기억나지 않으시나요? 아이디 찾기 방법을 선택해 주세요.
                </p>
                <form autoComplete="on" onSubmit={handleSubmit}>
                    <div className="accordion" id="accordionExample">
                        <div className="card card-search">
                            <div className="card-header"  id="phonenumber" onClick={() => this.props.onChangTab(1)}  data-toggle="collapse"
                                        data-target="#collphone" aria-expanded="true" aria-controls="collphone" >
                                <h2 className="mb-0">
                                    <div className="btn btn-collapsed">
                                        <span>
                                            <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/phoneid.svg"} alt="" />
                                                가입된 휴대폰 번호로 찾기
                                            </span>
                                        <div className="custom-control custom-radio">
                                            <Field
                                                id="forgot_type_1"
                                                name="forgot_type"
                                                component={renderRadioField}
                                                type="radio"
                                                value="1"
                                                checked={this.props.ForgotIdStep1 && parseInt(this.props.ForgotIdStep1.forgot_type)}
                                                onChange={this.props.changeFieldVerify}
                                                className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="forgot_type_1"></label>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div id="collphone" className="collapse" aria-labelledby="phonenumber"
                                data-parent="#accordionExample" >
                                <div className="card-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="" className="label-text">휴대폰번호</label>
                                        <div className="row">
                                            <div className="col-4 pr-0">
                                                <Field
                                                    placeholder="선택"
                                                    name="first_phone"
                                                    component={renderSelectField}
                                                >
                                                    
                                                    <option value=''>선택</option>
                                                    {getFirstNumberPhone().map((item) =>
                                                        <option key={item} value={item}>{item}</option>
                                                    )}

                                                </Field>

                                            </div>
                                            <div className="col-8">
                                                <Field
                                                    id="last_phone"
                                                    label="last_phone"
                                                    name="last_phone"
                                                    component={renderTextField}
                                                    placeholder="휴대폰번호"
                                                    normalize={normalizePhone}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-search">
                            <div className="card-header" id="emailName" onClick={() => this.props.onChangTab(2)} data-toggle="collapse"
                                        data-target="#collemail" aria-expanded="false" aria-controls="collemail">
                                <h2 className="mb-0">
                                    <div className="btn btn-collapsed collapsed" >
                                        <span>
                                            <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/mailid.svg"} alt="" />
                                                가입된 이메일로 찾기
                                            </span>
                                        <div className="custom-control custom-radio">
                                            <Field
                                                id="forgot_type_2"
                                                name="forgot_type"
                                                component={renderRadioField}
                                                type="radio"
                                                value="2"
                                                checked={this.props.ForgotIdStep1 && parseInt(this.props.ForgotIdStep1.forgot_type)}
                                                onChange={this.props.changeFieldVerify}
                                                className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="forgot_type_2"></label>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div id="collemail" className="collapse" aria-labelledby="emailName"
                                data-parent="#accordionExample" >
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="" className="label-text">이메일</label>
                                        <Field
                                            id="email"
                                            label="email"
                                            name="email"
                                            component={renderTextField}
                                            placeholder="이메일"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="submit-form">
                        <button type="submit" className="btn btn-submit" >
                            아이디 찾기
                            </button>
                    </div>
                    <p className="text-retrieval">비밀번호가 기억나지 않으시면 <a onClick={() => this.props.history.push({ pathname: '/' + routerName.FORGOT_PASSWORD })} >비밀번호찾기</a> 를 선택해주세요</p>
                </form>

            </Fragment>

        );
    }
}

ForgotIdStep1Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        ForgotIdStep1: getFormValues('ForgotId')(state),
        ForgotIdStep1Errors: getFormSyncErrors('ForgotId')(state),
        ForgotId: state.ForgotId,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'ForgotId';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotIdStep1Form);
