import PropTypes from 'prop-types';
import * as routerName from '../../utils/routerName';
import renderTextField from '../../components/FormHelper/TextField';
import renderRadioField from '../../components/FormHelper/RadioField';
import renderTextFields from '../../components/FormHelper/TextFieldArray';
import renderSelectField from '../../components/FormHelper/SelectField';
import renderCheckboxField from '../../components/FormHelper/CheckboxField';
import renderDatePickerField from '../../components/FormHelper/DatePickerField';
import renderCkeditorField from '../../components/FormHelper/CkeditorField';
import renderFileField from '../../components/FormHelper/FileField';
import renderFileAttachmentField from '../../components/FormHelper/FileAttachmentField';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {showLoading, hideLoading} from '../../store/actions/ui';
import api from '../../utils/api';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    formValueSelector,
    change,
    SubmissionError,
    getFormAsyncErrors
} from 'redux-form';
import validate from './validate-notification.js';
import moment from 'moment';
import * as helper from '../../utils/helper';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import {addMilliseconds, isThisSecond} from 'date-fns';
import {isBuffer} from 'lodash';
import {stringify} from 'query-string';
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";

class MoneySupportNotificationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            announcements: {},
            telcoms: [],
            dataList: [],
            announcement_models_default: [],
            announcement_models_last: [],
            announcement_models: [],
            state_announcement_models: false,
            announcement_selected: {
                announcement: {},
                i: null,
                k: null
            },
            announcement_model_selected: {},
            announcement_plans: [],
            announcement_plans_default: [],
            announcement_plans_last: [],
            state_announcement_plans: false,
            price_model: null,
            price_model_old: null,
            price_plan: null,
            price_plan_old: null,
            dataSeachPlan: {
                page: 1,
                paginate: 50,
                keyword_plan: '',
            },
            dataSeachModel: {
                page: 1,
                paginate: 50,
                keyword_model: '',
            },
            toggleChangePriceModel: false,
            toggleChangePriceModelOld: false,
            toggleChangePricePlan: false,
            toggleChangePricePlanOld: false,
            toggleShowModalChosePlanName: false,
            popupShowModalChoseModelName: false,
            detail: this.props.location?.state?.notice,
            callback: this.props.location?.state?.callback,
            telcomID: null,
            modelID: null,
            statusDataChangeList: false,
            titlePopup: '',
            statusData:false

        }
    }

    componentDidMount() {
        this.getListTelcom()
    }

    getListTelcom = () => {
        this.props.showLoading()

        api.global.getTelcom().then(res => {
            this.setState({
                telcoms: res.data.data
            }, () => {
                this.getAnnouncementChangList()
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    comfirmRedirect = () => {
        this.props.hideModal();
        // this.props.history.push({ pathname: '/' + routerName.MANAGER_NOTIFICATION })
        this.props.history.push({
            pathname: this.state.callback?.routerName || routerName.MANAGER_NOTIFICATION,
            state: {callback: this.state.callback, isNew: true}
        })
    }


    caseTelcolChang = (data) => {
        let statusListChange = false
        if (data[17] != undefined) {
            if (data[17]?.length > 0) {
                statusListChange = true
            }
            if (data[18]?.length > 0) {
                statusListChange = true
            }
            if (data[19]?.length > 0) {
                statusListChange = true
            }
            if (data[20]?.length > 0) {
                statusListChange = true
            }
            if (data[21]?.length > 0) {
                statusListChange = true
            }
            if (data[22]?.length > 0) {
                statusListChange = true
            }
            if (data[23]?.length > 0) {
                statusListChange = true
            }
            if (data[24]?.length > 0) {
                statusListChange = true
            }
        }
        return statusListChange
    }

    getAnnouncementChangList = () => {
        if (this.state.detail?.nt_id) {
            this.props.hideLoading();
            this.setState({
                announcements: JSON.parse(this.state.detail.nt_content)
            }, () => {
                this.setDataList()
            })
        } else {
            api.notification.announcementChangList().then(res => {
                this.props.hideLoading();
                let statusChangeList = false
                statusChangeList = this.caseTelcolChang(res.data.data)
                this.setState({
                    announcements: res.data.data,
                    statusDataChangeList: statusChangeList
                }, () => {
                    this.setDataList()
                })
            }).catch(err => {
            })
        }

    }

    setDataList = () => {
        let dataList = []
        Object.keys(this.state.announcements).map((key, i) => {
            let index = this.state.telcoms.findIndex((item) => item.id == key)
            if (index !== -1) {
                let rowData = {
                    telcom: this.state.telcoms[index],
                    dataRow: this.state.announcements[key],
                }
                dataList.push(rowData)
            }

        })
        this.setState({
            dataList: dataList,
            statusData: true
        })
    }

    newRowInTelcom = (data, i, k) => {
        let newDataRow = [...data.dataRow]
        newDataRow.push({
            code_plan: null,
            crw_factory_price: null,
            crw_subsidy_date: null,
            crw_subsidy_price: null,
            factory_price: null,
            modelID: null,
            model_factory_price: null,
            crw_factory_price_old: null,
            model_name: null,
            device_name: null,
            model_subsidy_date: null,
            model_subsidy_price: null,
            planID: null,
            plan_name: null,
            price_apply_date: null,
        })
        // thêm mới một row
        let objectDataList = data;
        objectDataList.dataRow = [...newDataRow]
        // update object
        let newDataList = [...this.state.dataList]
        newDataList[i] = objectDataList
        // update lại dataList
        this.setState({
            dataList: newDataList
        })
    }

    newRowTelcom = (item) => {
        let newDataRow = []
        newDataRow.push({
            code_plan: null,
            crw_factory_price: null,
            crw_subsidy_date: null,
            crw_subsidy_price: null,
            factory_price: null,
            modelID: null,
            model_factory_price: null,
            crw_factory_price_old: null,
            model_name: null,
            device_name: null,
            model_subsidy_date: null,
            model_subsidy_price: null,
            planID: null,
            plan_name: null,
            price_apply_date: null,
        })

        let objectDataList = {
            telcom: item
        };
        objectDataList.dataRow = [...newDataRow]

        let newDataList = [...this.state.dataList]
        newDataList.push(objectDataList)

        this.setState({
            dataList: newDataList,
            statusDataChangeList: true
        })
        this.props.hideModal()
    }

    onClickShowPopupChoseTelcom = () => {
        let xhtml = (
            <Fragment>
                <div className="modal-body">
                    <div className="list-box modal-list-network mt-2">
                        <div className="box-main">
                            {this.state.telcoms.map((item, i) =>
                                <Fragment key={i}>
                                    {item.parent == '0' && (
                                        <div className="box-item" onClick={() => this.newRowTelcom(item)}>
                                            <img src={item?.image_url?.thumbnail} alt=""/>
                                            <span>{item.name}</span>
                                        </div>
                                    )}

                                </Fragment>
                            )}
                        </div>
                        <div className="box-extra">
                            {this.state.telcoms.map((item, i) =>
                                <Fragment key={i}>
                                    {item.parent !== '0' && (
                                        <div className="box-item" onClick={() => this.newRowTelcom(item)}>
                                            <img src={item.image_url.thumbnail} alt={item.name}/>
                                        </div>
                                    )}
                                </Fragment>
                            )}

                        </div>
                    </div>
                </div>
            </Fragment>
        );

        let dataModal = {
            toggle: true,
            close: true,
            class_n: 'modal-dialog modal-dialog-centered modal-xl modal-images',
            component: xhtml,
            html_title: '<h5 className="modal-title">통신사 선택</h5>'
        }
        this.props.showModal(dataModal)
    }

    removeItem = (i, k) => {
        let newDataList = [...this.state.dataList]
        newDataList[i].dataRow.splice(k, 1);
        this.setState({
            dataList: newDataList,
            statusDataChangeList: false
        })
    }

    onClickShowListModel = (item, i, k) => {
        let data = {
            telcomID: item.telcom.id
        }
        this.props.showLoading()
        api.notification.announcementGetModel(data).then(res => {
            this.setState({
                announcement_models_default: res.data,
                announcement_models_last: res.data,
                announcement_models: res.data,
                announcement_selected: {
                    announcement: item,
                    i,
                    k
                },
                telcomID: data.telcomID,
                statusDataChangeList: true
            }, () => {
                this.props.hideLoading()
                this.toggleShowModalChoseModelName()
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    onClickShowListPlan = (item, i, k) => {
        if (item.dataRow[k]?.modelID) {
            this.props.showLoading()
            let data = {
                telcomID: item.telcom.id,
                modelID: item.dataRow[k].modelID
            }
            api.notification.announcementGetPlan(data).then(res => {
                this.setState({
                    announcement_plans: res.data,
                    announcement_plans_default: res.data,
                    announcement_plans_last: res.data,
                    announcement_selected: {
                        announcement: item,
                        i,
                        k
                    },
                    telcomID: data.telcomID,
                    modelID: data.modelID,
                }, () => {
                    this.props.hideLoading()
                    this.toggleShowModalChosePlanName()
                })
            }).catch(err => {
                this.props.hideLoading();
            })
        }

    }


    toggleShowModalChoseModelName = () => {
        this.setState({
            dataSeachModel: {
                page: 1,
                paginate: 50,
                keyword_model: '',
            },
            announcement_models: this.state.announcement_models_default,
            toggleShowModalChoseModelName: !this.state.toggleShowModalChoseModelName
        })
    }

    showModalChoseModelName = () => {
        return (
            <Modal
                isOpen={this.state.toggleShowModalChoseModelName}
                toggle={this.toggleShowModalChoseModelName}
                className={'modal-dialog modal-dialog-centered modal-additional'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            모델 선택
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleShowModalChoseModelName()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <Fragment>
                        <div className="modal-body add-devices-notifi pl-4 pr-4">
                            <ul className="list-additional" id="scrollableModelDiv">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="모델 검색"
                                    value={this.state.dataSeachModel.keyword_model}
                                    onChange={(e) => this.onChangeSearchModel(e)}
                                    onBlur={() => {
                                        if (!this.state.state_announcement_models) {
                                            this.filterModel()
                                        }
                                    }}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter' && !this.state.state_announcement_models) {
                                            this.filterModel()
                                        }
                                    }}
                                />
                                <InfiniteScroll
                                    dataLength={this.state.announcement_models.length}
                                    next={() => this.nextFilterModel()}
                                    hasMore={this.state.announcement_models_last.length > 0 || false}
                                    loader={<></>}
                                    scrollableTarget={`scrollableModelDiv`}
                                >
                                    {this.state.announcement_models.map((item, i) =>
                                        <li onClick={() => this.onClickEditModel(item)} key={i}>
                                            {item.model_name}
                                        </li>
                                    )}
                                </InfiniteScroll>

                            </ul>
                        </div>
                    </Fragment>
                </ModalBody>
            </Modal>)
    }

    toggleShowModalChosePlanName = () => {
        this.setState({
            dataSeachPlan: {
                page: 1,
                paginate: 50,
                keyword_plan: '',
            },
            announcement_plans: this.state.announcement_plans_default,
            toggleShowModalChosePlanName: !this.state.toggleShowModalChosePlanName
        })
    }


    onClickEditModel = (item) => {
        let {i} = this.state.announcement_selected
        let {k} = this.state.announcement_selected
        let newDataList = [...this.state.dataList]
        newDataList[i].dataRow[k].factory_price = item?.factory_price || null
        newDataList[i].dataRow[k].crw_factory_price = item?.model_factory_price || null
        newDataList[i].dataRow[k].modelID = item?.modelID || null
        newDataList[i].dataRow[k].model_name = item?.model_name || null
        newDataList[i].dataRow[k].device_name = item?.device_name || null
        newDataList[i].dataRow[k].model_subsidy_price = item?.model_subsidy_price || null
        newDataList[i].dataRow[k].crw_subsidy_price = item?.crw_subsidy_price || null
        newDataList[i].dataRow[k].planID = item?.planID || null
        newDataList[i].dataRow[k].plan_name = item?.plan_name || null
        newDataList[i].dataRow[k].crw_subsidy_price_old = item.crw_subsidy_price_old || 0
        newDataList[i].dataRow[k].crw_subsidy_price_change_first = false
        newDataList[i].dataRow[k].crw_factory_price_change_first = false
        newDataList[i].dataRow[k].crw_factory_price_old = item.crw_factory_price_old || 0
        this.setState({
            dataList: newDataList,
            announcement_model_selected: item
        })
        this.toggleShowModalChoseModelName()
    }

    onClickEditPlan = (item) => {
        let {i} = this.state.announcement_selected
        let {k} = this.state.announcement_selected
        let newDataList = [...this.state.dataList]


        newDataList[i].dataRow[k].model_subsidy_price = item.model_subsidy_price ? item.model_subsidy_price : 0

        newDataList[i].dataRow[k].disclosure_price = item.model_subsidy_price ? item.model_subsidy_price : 0
        newDataList[i].dataRow[k].crw_subsidy_price = item.crw_subsidy_price ? item.crw_subsidy_price : (item.model_subsidy_price || 0)
        newDataList[i].dataRow[k].crw_subsidy_price_old = item.crw_subsidy_price_old ? item.crw_subsidy_price_old : 0
        newDataList[i].dataRow[k].crw_subsidy_price_change_first = item.crw_subsidy_price_change_first
        newDataList[i].dataRow[k].crw_factory_price_change_first = item.crw_factory_price_change_first
        newDataList[i].dataRow[k].crw_factory_price_old = item.crw_factory_price_old ? item.crw_factory_price_old : 0
        newDataList[i].dataRow[k].planID = item?.planID || null
        newDataList[i].dataRow[k].plan_name = item?.plan_name || null
        this.setState({
            dataList: newDataList,
            announcement_model_selected: item
        })
        this.toggleShowModalChosePlanName()
    }

    onClickShowChangePriceModel = (item, i, k, type = null, title) => {
        if (item.dataRow[k].modelID !== null) {
            if (type && type == 'old') {
                this.setState({
                    // price_model_old: helper.priceFormat(item.dataRow[k].crw_factory_price_old),
                    price_model_old: helper.priceFormat(this.props.MoneySupportNotificationCreate.nt_id ? item.dataRow[k].factory_price : item.dataRow[k].crw_factory_price_old),
                    announcement_selected: {
                        announcement: item,
                        i,
                        k
                    },
                    titlePopup: title
                }, () => {
                    this.toggleChangePriceModelOld()
                })
            } else {
                this.setState({
                    // price_model: (item.dataRow[k].crw_factory_price ? helper.priceFormat(item.dataRow[k].crw_factory_price) : helper.priceFormat(item.dataRow[k].factory_price)), // change crw_factory_price => factory_price
                    price_model: helper.priceFormat(this.props.MoneySupportNotificationCreate.nt_id ? item.dataRow[k].factory_price_change : item.dataRow[k].crw_factory_price_old), // change crw_factory_price => factory_price
                    announcement_selected: {
                        announcement: item,
                        i,
                        k
                    },
                    titlePopup: title
                }, () => {
                    this.toggleChangePriceModel()
                })
            }
        }

    }

    toggleChangePriceModel = () => {
        this.setState({
            toggleChangePriceModel: !this.state.toggleChangePriceModel
        })
    }
    showChangePriceModel = () => {
        return (
            <Modal
                isOpen={this.state.toggleChangePriceModel}
                toggle={this.toggleChangePriceModel}
                className={'modal-dialog modal-dialog  modal-dialog-centered'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            {/*모델 출고가 변경*/}
                            {this.state.titlePopup}
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleChangePriceModel()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body pl-4 pr-4">
                        <div className="form-group row mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <input
                                    className="form-control h-44"
                                    type="text"
                                    value={this.state.price_model}
                                    onChange={(e) => this.onChangeStatePriceModel(e.target.value)}/>
                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center border-top-0 mt-5">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mr-2"
                                    onClick={() => this.toggleChangePriceModel()}>취소
                            </button>
                            <button type="button" className="btn btn-submit pr-5 pl-5 ml-2"
                                    onClick={() => this.comfirmPriceModel()}>확인
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    toggleChangePriceModelOld = () => {
        this.setState({
            toggleChangePriceModelOld: !this.state.toggleChangePriceModelOld
        })
    }
    showChangePriceModelOld = () => {
        return (
            <Modal
                isOpen={this.state.toggleChangePriceModelOld}
                toggle={this.toggleChangePriceModelOld}
                className={'modal-dialog modal-dialog  modal-dialog-centered'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            {/*모델 출고가 변경*/}
                            {this.state.titlePopup}
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleChangePriceModelOld()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body pl-4 pr-4">
                        <div className="form-group row mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <input
                                    className="form-control h-44"
                                    type="text"
                                    value={this.state.price_model_old}
                                    onChange={(e) => this.onChangeStatePriceModel(e.target.value, 'old')}/>
                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center border-top-0 mt-5">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mr-2"
                                    onClick={() => this.toggleChangePriceModelOld()}>취소
                            </button>
                            <button type="button" className="btn btn-submit pr-5 pl-5 ml-2"
                                    onClick={() => this.comfirmPriceModel('old')}>확인
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )

    }


    comfirmPriceModel = (type = null) => {
        let {i} = this.state.announcement_selected
        let {k} = this.state.announcement_selected
        let newDataList = [...this.state.dataList]

        // newDataList[i].dataRow[k].crw_factory_price = parseFloat(this.state.price_model.split(',').join(''))
        // this.setState({
        //     dataList: newDataList,
        // })
        // this.toggleChangePriceModel()


        if (type) {
            newDataList[i].dataRow[k].crw_factory_price_old = parseFloat(this.state.price_model_old.split(',').join(''))
        } else {
            if (newDataList[i].dataRow[k].crw_factory_price_change_first == false) {
                // let priceOld = newDataList[i].dataRow[k].crw_factory_price_old ? newDataList[i].dataRow[k].crw_factory_price_old : (this.props.MoneySupportNotificationCreate.nt_id ? (item?.factory_price || 0) : 0);
                // newDataList[i].dataRow[k].crw_factory_price_old = newDataList[i].dataRow[k].crw_factory_price ? newDataList[i].dataRow[k].crw_factory_price : parseFloat(newDataList[i].dataRow[k].factory_price.split(',').join(''))
                // task 6844
                newDataList[i].dataRow[k].crw_factory_price_old = newDataList[i].dataRow[k].crw_factory_price_old ? newDataList[i].dataRow[k].crw_factory_price_old : 0;
            }
            if (newDataList[i].dataRow[k].crw_factory_price == null) {
                newDataList[i].dataRow[k].crw_factory_price_change_first = true
            }
            newDataList[i].dataRow[k].crw_factory_price = parseFloat(this.state.price_model.split(',').join(''))
        }
        this.setState({
            dataList: newDataList,
        })
        type ? this.toggleChangePriceModelOld() : this.toggleChangePriceModel()

    }


    onClickShowChangePricePlan = (item, i, k, type = null, title) => {
        if (item.dataRow[k].planID !== null) {
            if (type && type == 'old') {

                this.setState({
                    price_plan_old: helper.priceFormat(this.props.MoneySupportNotificationCreate.nt_id ? item.dataRow[k].subsidy_price : item.dataRow[k].crw_subsidy_price_old),
                    announcement_selected: {
                        announcement: item,
                        i,
                        k
                    },
                    titlePopup: title
                }, () => {
                    this.toggleChangePricePlanOld()
                })
            } else {
                this.setState({
                    price_plan: helper.priceFormat(this.props.MoneySupportNotificationCreate.nt_id ? item.dataRow[k].subsidy_price_change : item.dataRow[k].crw_subsidy_price),
                    announcement_selected: {
                        announcement: item,
                        i,
                        k
                    },
                    titlePopup: title
                }, () => {
                    this.toggleChangePricePlan()
                })
            }


        }

    }
    toggleChangePricePlan = () => {
        this.setState({
            toggleChangePricePlan: !this.state.toggleChangePricePlan
        })
    }
    toggleChangePricePlanOld = () => {
        this.setState({
            toggleChangePricePlanOld: !this.state.toggleChangePricePlanOld
        })
    }

    showChangePricePlanOld = () => {
        return (
            <Modal
                isOpen={this.state.toggleChangePricePlanOld}
                toggle={this.toggleChangePricePlanOld}
                className={'modal-dialog modal-dialog  modal-dialog-centered'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            {/*요금제 지원금 변경*/}
                            {this.state.titlePopup}
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleChangePricePlanOld()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body pl-4 pr-4">
                        <div className="form-group row mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <input
                                    className="form-control h-44"
                                    type="text"
                                    value={this.state.price_plan_old}
                                    onChange={(e) => this.onChangeStatePricePlan(e.target.value, 'old')}/>
                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center border-top-0 mt-5">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mr-2"
                                    onClick={() => this.toggleChangePricePlanOld()}>취소
                            </button>
                            <button type="button" className="btn btn-submit pr-5 pl-5 ml-2"
                                    onClick={() => this.comfirmPricePlan('old')}>확인
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )

    }

    showChangePricePlan = () => {
        return (
            <Modal
                isOpen={this.state.toggleChangePricePlan}
                toggle={this.toggleChangePricePlan}
                className={'modal-dialog modal-dialog  modal-dialog-centered'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            {/*요금제 지원금 변경*/}
                            {this.state.titlePopup}
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleChangePricePlan()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body pl-4 pr-4">
                        <div className="form-group row mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <input
                                    className="form-control h-44"
                                    type="text"
                                    value={this.state.price_plan}
                                    onChange={(e) => this.onChangeStatePricePlan(e.target.value)}/>
                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center border-top-0 mt-5">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mr-2"
                                    onClick={() => this.toggleChangePricePlan()}>취소
                            </button>
                            <button type="button" className="btn btn-submit pr-5 pl-5 ml-2"
                                    onClick={() => this.comfirmPricePlan()}>확인
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )

    }

    comfirmPricePlan = (type = null) => {
        let {i} = this.state.announcement_selected
        let {k} = this.state.announcement_selected
        let newDataList = [...this.state.dataList]

        if (type) {
            newDataList[i].dataRow[k].crw_subsidy_price_old = parseFloat(this.state.price_plan_old.split(',').join(''))
        } else {

            if (newDataList[i].dataRow[k].crw_subsidy_price_change_first == false) {
                newDataList[i].dataRow[k].crw_subsidy_price_old = newDataList[i].dataRow[k].crw_subsidy_price
            }
            if (newDataList[i].dataRow[k].model_subsidy_price == newDataList[i].dataRow[k].crw_subsidy_price) {
                newDataList[i].dataRow[k].crw_subsidy_price_change_first = true
            }
            newDataList[i].dataRow[k].crw_subsidy_price = parseFloat(this.state.price_plan.split(',').join(''))
        }
        this.setState({
            dataList: newDataList,
        })
        type ? this.toggleChangePricePlanOld() : this.toggleChangePricePlan()
    }


    onChangeStatePriceModel = (value, type = null) => {
        const currentValue = value.replace(/[^\d]/g, '');
        if (type) {
            this.setState({
                price_model_old: helper.priceFormat(currentValue)
            })
        } else {
            this.setState({
                price_model: helper.priceFormat(currentValue)
            })
        }

    }

    onChangeStatePricePlan = (value, type = null) => {
        const currentValue = value.replace(/[^\d]/g, '');
        if (type) {
            this.setState({
                price_plan_old: helper.priceFormat(currentValue)
            })
        } else {
            this.setState({
                price_plan: helper.priceFormat(currentValue)
            })
        }

    }

    onChangeSearchModel = (e) => {
        this.setState({
            dataSeachModel: {
                ...this.state.dataSeachModel,
                keyword_model: e.target.value,
                page: 1
            },
            state_announcement_models: false

        })
    }

    filterModel = () => {
        let data = {
            keyword: this.state.dataSeachModel.keyword_model,
            page: this.state.dataSeachModel.page,
            paginate: this.state.dataSeachModel.paginate,
            telcomID: this.state.telcomID
        };
        this.props.showLoading()
        api.notification.announcementGetModel(data).then(res => {
            this.setState({
                announcement_models_default: res.data,
                announcement_models_last: res.data,
                announcement_models: res.data,
                state_announcement_models: true
            }, () => {
                this.props.hideLoading()
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    nextFilterModel = () => {
        this.setState({
            dataSeachModel: {
                ...this.state.dataSeachModel,
                page: this.state.dataSeachModel.page + 1
            }
        }, () => {
            let data = {
                keyword: this.state.dataSeachModel.keyword_model,
                page: this.state.dataSeachModel.page,
                paginate: this.state.dataSeachModel.paginate,
                telcomID: this.state.telcomID
            };
            this.props.showLoading()
            api.notification.announcementGetModel(data).then(res => {
                this.setState({
                    announcement_models_default: res.data,
                    announcement_models_last: res.data,
                    announcement_models: [...res.data, ...this.state.announcement_models],
                }, () => {
                    this.props.hideLoading()
                })
            }).catch(err => {
                this.props.hideLoading();
            })
        })
    }

    showModalChosePlanName = () => {
        return (
            <Modal
                isOpen={this.state.toggleShowModalChosePlanName}
                toggle={this.toggleShowModalChosePlanName}
                className={'modal-dialog modal-dialog-centered modal-additional'}
            >
                <div className="pl-4 pr-4">
                    <ModalHeader>
                        <Fragment>
                            표준요금제 선택
                            <button type="button" className="close close-modal"
                                    onClick={() => this.toggleShowModalChosePlanName()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                            </button>
                        </Fragment>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body add-devices-notifi pl-4 pr-4">
                        <ul className="list-additional" id="scrollablePlanDiv">
                            <input type="text"
                                   className="form-control"
                                   placeholder="요금제 검색"
                                   value={this.state.dataSeachPlan.keyword_plan}
                                   onChange={(e) => this.onChangeSearchPlan(e)}
                                   onBlur={() => {
                                       if (!this.state.state_announcement_plans) {
                                           this.filterPlan()
                                       }
                                   }}
                                   onKeyPress={event => {
                                       if (event.key === 'Enter' && !this.state.state_announcement_plans) {
                                           this.filterPlan()
                                       }
                                   }}
                                   onClick={(e) => this.filterPlan()}
                            />
                            <InfiniteScroll
                                dataLength={this.state.announcement_plans.length}
                                next={() => this.nextFilterPlan()}
                                hasMore={this.state.announcement_plans_last.length > 0 || false}
                                loader={<></>}
                                scrollableTarget={`scrollablePlanDiv`}
                            >
                                {this.state.announcement_plans.map((item, i) =>
                                    <li onClick={() => this.onClickEditPlan(item)} key={i}>
                                        {item.plan_name}
                                    </li>
                                )}
                            </InfiniteScroll>

                        </ul>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    onChangeSearchPlan = (e) => {
        this.setState({
            dataSeachPlan: {
                ...this.state.dataSeachPlan,
                keyword_plan: e.target.value,
                page: 1,
            },
            state_announcement_plans: false
        })
    }

    filterPlan = () => {
        let data = {
            keyword: this.state.dataSeachPlan.keyword_plan,
            page: this.state.dataSeachPlan.page,
            paginate: this.state.dataSeachPlan.paginate,
            telcomID: this.state.telcomID,
            modelID: this.state.modelID,
        };
        this.props.showLoading()
        api.notification.announcementGetPlan(data).then(res => {
            this.setState({
                announcement_plans_default: res.data,
                announcement_plans_last: res.data,
                announcement_plans: res.data,
                state_announcement_plans: true
            }, () => {
                this.props.hideLoading()
            })
        }).catch(err => {
            this.props.hideLoading();
        })

    }

    nextFilterPlan = () => {
        this.setState({
            dataSeachPlan: {
                ...this.state.dataSeachPlan,
                page: this.state.dataSeachPlan.page + 1
            }
        }, () => {
            let data = {
                keyword: this.state.dataSeachPlan.keyword_plan,
                page: this.state.dataSeachPlan.page,
                paginate: this.state.dataSeachPlan.paginate,
                telcomID: this.state.telcomID,
                modelID: this.state.modelID,
            };
            this.props.showLoading()
            api.notification.announcementGetPlan(data).then(res => {
                this.setState({
                    announcement_plans_default: res.data,
                    announcement_plans_last: res.data,
                    announcement_plans: [...res.data, ...this.state.announcement_plans],
                }, () => {
                    this.props.hideLoading()
                })
            }).catch(err => {
                this.props.hideLoading();
            })
        })
    }


    onSave = (data) => {
        let newArr = {}
        this.state.dataList.map((item, i) => {
            newArr[item.telcom.id] = newArr[item.telcom.id] ? [...newArr[item.telcom.id]] : []
            item.dataRow.map((row, k) => {
                let dataRow = {
                    modelID: row.modelID || null,
                    planID: row.planID || null,
                    model_name: row.model_name || null,
                    device_name: row.device_name || null,
                    plan_name: row.plan_name || null,
                    // factory_price: this.renderPriceStatusModel(row),
                    factory_price: this.renderPriceStatusModelOld(row),
                    factory_price_change: this.renderPriceChangeModel(row),
                    // subsidy_price: this.renderPriceStatusPlan(row),
                    subsidy_price: this.renderPriceStatusPlanOld(row),
                    subsidy_price_change: this.renderPriceChangePlan(row)
                }
                if (dataRow.modelID) {
                    newArr[item.telcom.id].push(dataRow)
                }
            })
        })

        let dataRequest = {
            nt_title: data.title,
            nt_content: data.content,
            nt_att_file: data.attachment ? data.attachment : '',
            nt_state_hide: data.nt_state_hide ? '1' : '0',
            nt_date_hide: data.end_date,
            nt_date_show: data.start_date,
            nt_state_show: data.nt_state_show ? '1' : '0',
            cat_id: data.cat_id,
            nt_state_setting: data.stateSetting ? '1' : '0',
            nt_setting_type_1: data.stateSettingType1 ? '1' : '0',
            nt_setting_type_2: data.stateSettingType2 ? '1' : '0',
            announcement: JSON.stringify(newArr)
        }

        if (data.nt_id) {
            this.props.showLoading();
            api.notification.update(dataRequest, data.nt_id).then(res => {
                this.props.hideLoading();
                this.popupMsgSuccess()
            }).catch(err => {
                this.props.hideLoading();
                this.popupMsgFail()
            })
        } else {
            this.props.showLoading();
            api.notification.save(dataRequest).then(res => {
                this.props.hideLoading();
                this.popupMsgSuccess()

            }).catch(err => {
                this.props.hideLoading();
                this.popupMsgFail()
            })
        }
    }

    popupMsgSuccess = () => {
        let xhtml = (
            <Fragment>
                <div className="content">
                    <div className="modal-body text-center p-5">
                        <span
                            className="notification">{this.props.MoneySupportNotificationCreate.nt_id ? ('공시지원금 등록 성공') : ('공시지원금 업데이트 성공')}</span>
                    </div>
                </div>
                <div className="modal-footer justify-content-center border-top-0">
                    <button type="button" className="btn btn_save" onClick={() => this.comfirmRedirect()}>확인</button>
                </div>
            </Fragment>

        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }

    popupMsgFail = () => {
        let xhtml = (
            <Fragment>
                <div className="content">
                    <div className="modal-body text-center p-5">
                        <span
                            className="notification_delete">{this.props.MoneySupportNotificationCreate.nt_id ? ('공시지원금 등록 실패') : ('공시지원금 등록 실패')}</span>
                    </div>
                </div>
                <div className="modal-footer justify-content-center border-top-0">
                    <button type="button" className="btn btn_save" onClick={() => this.props.hideModal()}>확인</button>
                </div>
            </Fragment>

        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }

    renderPriceStatusModelOld = (item) => {
        // let priceModel =  item.crw_factory_price_old ? item.crw_factory_price_old : 0
        let priceModel = item.crw_factory_price_old ? +item.crw_factory_price_old : (this.props.MoneySupportNotificationCreate.nt_id ? (item?.factory_price || 0) : 0);
        return priceModel;
    }

    renderPriceStatusModel = (item) => {
        let priceModel = item.model_factory_price ? item.model_factory_price : item.factory_price || 0
        return priceModel;
    }

    renderPriceChangeModel = (item) => {
        // let priceModelChange =  item.crw_factory_price ? item.crw_factory_price : item.factory_price || 0
        let priceModelChange = item.crw_factory_price ? +item.crw_factory_price : (this.props.MoneySupportNotificationCreate.nt_id ? (item?.factory_price_change || 0) : (item?.factory_price || 0))
        return priceModelChange;
    }


    renderPriceStatusPlanOld = (item) => {
        // let pricePlan =  item.crw_subsidy_price_old ? item.crw_subsidy_price_old : 0;
        let pricePlan = item.crw_subsidy_price_old ? +item.crw_subsidy_price_old : (this.props.MoneySupportNotificationCreate.nt_id ? (item.subsidy_price || 0) : 0);
        return pricePlan;
    }
    renderPriceStatusPlan = (item) => {
        // let pricePlan =  item.crw_subsidy_price ? item.crw_subsidy_price : 0
        let pricePlan = +item?.crw_subsidy_price || 0
        return pricePlan;
    }

    renderPriceChangePlan = (item) => {
        // let pricePlanChange =  item.crw_subsidy_price ? item.crw_subsidy_price : item.model_subsidy_price || 0
        let pricePlanChange = item.crw_subsidy_price ? +item.crw_subsidy_price : (this.props.MoneySupportNotificationCreate.nt_id ? (item?.subsidy_price_change || 0) : (item?.model_subsidy_price || 0))
        return pricePlanChange;
    }

    hanldeCancel = () => {
        this.props.history.push({pathname: '/' + routerName.MANAGER_NOTIFICATION})
    }

    onClickCallback = () => {
        this.props.history.push({
            pathname: this.state.callback?.routerName || routerName.MANAGER_NOTIFICATION,
            state: {callback: this.state.callback}
        })
    }

    render() {
        // const {handleSubmit} = this.props;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <form autoComplete="off">
                                <div className="add-notification add-notification-02">
                                    <div className="top">
                                        <div className="group-button">
                                            <button className="btn btn-left p-0 mr-3" type="button"
                                                    onClick={() => this.onClickCallback()}>
                                                취소
                                            </button>
                                            {this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.nt_id ? (
                                                <Fragment>
                                                    <span>|</span>
                                                    <button className="btn btn-left p-0 ml-3 mr-3" type="button"
                                                            onClick={() => this.comfirmDel(this.props.MoneySupportNotificationCreate.nt_id)}>
                                                        삭제
                                                    </button>
                                                </Fragment>
                                            ) : ('')}
                                            <button className={"btn btn-submit"}
                                                    onClick={this.props.handleSubmit(values =>
                                                        this.onSave({
                                                            ...values,
                                                            nt_state: '1'
                                                        }))}
                                            >
                                                게시
                                            </button>
                                        </div>
                                    </div>
                                    <article className="content-add">
                                        <div className="row">
                                            <div className="col-9 ">
                                                <div className="left">
                                                    <div className="form-group box_title">
                                                        <Field
                                                            id="title"
                                                            label="title"
                                                            name="title"
                                                            component={renderTextField}
                                                        />
                                                    </div>
                                                    <div className="form-group box_content">
                                                        <table className="table mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th>통신사</th>
                                                                <th>모델명</th>
                                                                <th>출고가</th>
                                                                <th>표준 요금제</th>
                                                                <th>공시지원금</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.dataList.map((item, i) =>
                                                                <Fragment key={i}>
                                                                    {item.dataRow.map((it, k) =>
                                                                        <tr key={k}>
                                                                            {k == 0 && (
                                                                                <td rowSpan={item.dataRow.length}>
                                                                                    <div className="logo-network">
                                                                                        <img
                                                                                            src={item?.telcom?.image_url?.thumbnail}
                                                                                            alt=""/>
                                                                                        <button
                                                                                            className="btn add-devices"
                                                                                            type="button"
                                                                                            onClick={() => this.newRowInTelcom(item, i, k)}>
                                                                                            +
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            )}
                                                                            <td className="td-add-product">
                                                                                <div
                                                                                    onClick={() => this.onClickShowListModel(item, i, k)}>
                                                                                        <span className="name-devices">
                                                                                            {it.model_name + (it.device_name ? ` (${it?.device_name})` : null) || null}
                                                                                        </span>
                                                                                </div>
                                                                            </td>

                                                                            {this.renderPriceChangeModel(it) === this.renderPriceStatusModelOld(it) || this.renderPriceStatusModelOld(it) === 0 ? (
                                                                                <td className="status-down" style={{backgroundColor: 'white'}}>
                                                                                    <div>
                                                                                        <div className="prices-devices" style={{cursor: 'default'}}>
                                                                                            <span className={'mb-2 hover-underline'}
                                                                                                    onClick={(e) => {this.onClickShowChangePriceModel(item, i, k, 'old', '변경 전 출고가 ')}}>
                                                                                                    {helper.priceFormat(this.renderPriceStatusModelOld(it)) + " won"}
                                                                                            </span>
                                                                                            <div
                                                                                                className="status-prices justify-content-end hover-underline"
                                                                                                onClick={(e) => {this.onClickShowChangePriceModel(item, i, k, 'pre', '변경 후 출고가')}}
                                                                                                style={{color: 'black', fontWeight: 'normal'}}>
                                                                                                <label className={'m-0'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangeModel(it))}
                                                                                                    <strong style={{fontSize:'12px', fontWeight: 400}}>{" won" }</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            ) : (
                                                                                <td className={this.renderPriceChangeModel(it) > this.renderPriceStatusModelOld(it) ? "status-up" : "status-down"}>
                                                                                    <div>
                                                                                        <div className="prices-devices"
                                                                                             style={{cursor: 'default'}}>
                                                                                            <span className={'mb-2 hover-underline'}
                                                                                                onClick={() => this.onClickShowChangePriceModel(item, i, k, 'old', '변경 전 출고가')}>
                                                                                                {helper.priceFormat(this.renderPriceStatusModelOld(it)) + " won"}
                                                                                            </span>
                                                                                            <div className="status-prices hover-underline"
                                                                                                 onClick={() => this.onClickShowChangePriceModel(item, i, k, 'pre', '변경 후 출고가')}>
                                                                                                <div className="status">
                                                                                                    {this.renderPriceChangeModel(it) > this.renderPriceStatusModelOld(it) ? <img src="/images/status-up.svg" alt=""/> : <img src="/images/status-down.svg" alt=""/>}
                                                                                                </div>
                                                                                                <label className={'m-0 cursor-pointer'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangeModel(it))}
                                                                                                    <strong style={{fontSize:'12px', fontWeight: 400}}>{" won"}</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )}


                                                                            <td className="td-add-product">
                                                                                <div
                                                                                    onClick={() => this.onClickShowListPlan(item, i, k)}>
                                                                                        <span className="name-devices">
                                                                                            {it.plan_name}
                                                                                        </span>
                                                                                </div>
                                                                            </td>

                                                                            {this.renderPriceChangePlan(it) === this.renderPriceStatusPlanOld(it) || this.renderPriceStatusPlanOld(it) === 0 ? (
                                                                                <td className="status-down" style={{backgroundColor: 'white'}}>
                                                                                    <div>
                                                                                        <div className="prices-devices" style={{cursor: 'default'}}> {/* remove class prices-equal*/}
                                                                                            <span
                                                                                                className={'mb-2 hover-underline'}
                                                                                                onClick={(e) => {this.onClickShowChangePricePlan(item, i, k, 'old', '변경 전 공시지원금')}}>
                                                                                                    {helper.priceFormat(this.renderPriceStatusPlanOld(it)) + " won"}
                                                                                            </span>
                                                                                            <div className="status-prices justify-content-end hover-underline" style={{color: 'black', fontWeight: 'normal'}}
                                                                                                onClick={(e) => {this.onClickShowChangePricePlan(item, i, k, 'pre', '변경 후 공시지원금')}}>
                                                                                                <label className={'m-0'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangePlan(it))}
                                                                                                    <strong style={{fontSize:'12px', fontWeight: 400}}>{" won"}</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <button className="btn delete-network" type="button"
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    this.removeItem(i, k)
                                                                                                }}>
                                                                                                <i className="far fa-trash-alt"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            ) : (
                                                                                <td className={this.renderPriceChangePlan(it) > this.renderPriceStatusPlanOld(it) ? "status-up" : "status-down"}>
                                                                                    <div>
                                                                                        <div className="prices-devices" style={{cursor: 'default'}}>
                                                                                            <span className={'mb-2 hover-underline'}
                                                                                                onClick={(e) => {this.onClickShowChangePricePlan(item, i, k, 'old', '변경 전 공시지원금')}}>
                                                                                                {helper.priceFormat(this.renderPriceStatusPlanOld(it)) + " won"}
                                                                                            </span>
                                                                                            <div className="status-prices" onClick={(e) => {this.onClickShowChangePricePlan(item, i, k, 'pre', '변경 후 공시지원금')}}>
                                                                                                <div className="status">
                                                                                                    {this.renderPriceChangePlan(it) > this.renderPriceStatusPlanOld(it) ? <img src="/images/status-up.svg" alt=""/> : <img src="/images/status-down.svg" alt=""/>}
                                                                                                </div>
                                                                                                <label className={'m-0 cursor-pointer'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangePlan(it))}
                                                                                                    <strong style={{fontSize:'12px', fontWeight: 400}}>{" won"}</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <button className="btn delete-network" type="button"
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    this.removeItem(i, k)
                                                                                                }}>
                                                                                                <i className="far fa-trash-alt"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )}

                                                                        </tr>
                                                                    )}
                                                                </Fragment>
                                                            )}

                                                            {this.state.statusData && _.filter(this.state.dataList, row => row.dataRow.length > 0).length == 0 ? <tr>
                                                                <td colSpan={5}><label
                                                                    className={'m-0 w-100 text-center'}>금일 변동사항
                                                                    없습니다.</label></td>
                                                            </tr> : ""

                                                            }
                                                            </tbody>
                                                        </table>


                                                    </div>
                                                    <button className="btn add-network" type="button"
                                                            onClick={() => this.onClickShowPopupChoseTelcom()}>통신사 추가
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-3 pr-2">
                                                <div className="right">
                                                    <h4 className="title">
                                                        노출여부 설정
                                                    </h4>
                                                    <div className="form-group">
                                                        <label
                                                            className="col-form-label label-text text-gray-white">노출</label>
                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="form-date">
                                                                    <Field
                                                                        className="form-control h-44"
                                                                        placeholder="사업자명(단체명)"
                                                                        name="start_date"
                                                                        type="text"
                                                                        component={renderDatePickerField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-0">
                                                                <div className="toggle-custom justify-content-end">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="nt_state_show"
                                                                        name="nt_state_show"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.nt_state_show}
                                                                    />
                                                                    <label htmlFor="nt_state_show">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="col-form-label label-text text-gray-white">숨김</label>
                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="form-date">
                                                                    <Field
                                                                        className="form-control h-44"
                                                                        placeholder="사업자명(단체명)"
                                                                        name="end_date"
                                                                        type="text"
                                                                        component={renderDatePickerField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-0">
                                                                <div className="toggle-custom justify-content-end">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="nt_state_hide"
                                                                        name="nt_state_hide"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.nt_state_hide}
                                                                    />
                                                                    <label htmlFor="nt_state_hide">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label label-text">팝업설정</label>
                                                        <div className="toggle-custom mb-3">
                                                            <Field
                                                                type="checkbox"
                                                                id="stateSetting"
                                                                name="stateSetting"
                                                                component={renderCheckboxField}
                                                                checked={this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.stateSetting}
                                                            />
                                                            <label htmlFor="stateSetting">
                                                            </label>
                                                            <span style={{opacity: '1', color: "#1e2532"}}>팝업설정</span>
                                                        </div>
                                                        <div className="box-setting">
                                                            <div
                                                                className={"box-radio " + (this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.stateSetting == true ? "d-show" : "d-none")}>
                                                                <div
                                                                    className="custom-control custom-checkbox d-flex align-items-center mr-sm-2 mb-3">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="setting_type_time_not"
                                                                        name="stateSettingType1"
                                                                        value='0'
                                                                        className="custom-control-input"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.stateSettingType1}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="setting_type_time_not">
                                                                        다시보지않기
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    className="custom-control custom-checkbox d-flex align-items-center mr-sm-2">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="setting_type_time_one_day"
                                                                        name="stateSettingType2"
                                                                        value='0'
                                                                        className="custom-control-input"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.MoneySupportNotificationCreate && this.props.MoneySupportNotificationCreate.stateSettingType2}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="setting_type_time_one_day">
                                                                        하루동안 보지않기
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
                {this.showChangePriceModel()}
                {this.showChangePriceModelOld()}
                {this.showChangePricePlan()}
                {this.showChangePricePlanOld()}
                {this.showModalChosePlanName()}
                {this.showModalChoseModelName()}
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    let initValues;
    if (state.dataStore.notification.select_cat_notice_childs.length > 0 && state.form.MoneySupportNotificationCreate) {
        initValues = {
            ...state.form.MoneySupportNotificationCreate.initial,
        };
    }
    if (props.location.state && props.location.state.notice) {
        let notice = props.location.state.notice;
        initValues = {
            ...initValues,
            'nt_id': notice.nt_id,
            'title': notice.nt_title,
            'start_date': notice.nt_date_show && moment(notice.nt_date_show).isValid() ? moment(notice.nt_date_show).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            'end_date': notice.nt_date_hide && moment(notice.nt_date_hide).isValid() ? moment(notice.nt_date_hide).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            'stateSetting': notice.nt_state_setting == '1' ? true : false,
            'stateSettingType1': notice.nt_setting_type_1 == '1' ? true : false,
            'stateSettingType2': notice.nt_setting_type_2 == '1' ? true : false,
            'nt_state_show': notice.nt_state_show == '1' ? true : false,
            'nt_state_hide': notice.nt_state_hide == '1' ? true : false,
            'nt_state': notice.nt_state == '1' ? true : false,
            'nt_content': notice.nt_content
        };
    }
    return {
        initialValues: initValues,
        MoneySupportNotificationCreate: getFormValues('MoneySupportNotificationCreate')(state),
        select_cat_notice_childs: state.dataStore.notification.select_cat_notice_childs,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    };
};

const withReduxForm = reduxForm({
    form: 'MoneySupportNotificationCreate',
    initialValues: {
        'nt_id': null,
        'title': '',
        'start_date': moment(new Date()).format('YYYY-MM-DD'),
        'end_date': moment(new Date()).format('YYYY-MM-DD'),
        'stateSetting': false,
        'stateSettingType1': false,
        'stateSettingType2': false,
        'nt_state_show': false,
        'nt_state_hide': false,
        'nt_state': false,
    },
    enableReinitialize: true,
    validate
}, mapStateToProps);

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(MoneySupportNotificationCreate);

