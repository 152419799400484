import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

class renderReportCode extends React.Component {
    // beforeMaskedValueChange = (newState, oldState, userInput) => {
    //     var { value } = newState;
    //     var selection = newState.selection;
    //     let arr = value.split('-');
    //     if (arr.length > 1) {
    //     }
    //
    //     return {
    //         value,
    //         selection
    //     };
    // }

    render() {
        const { input, meta: { touched, invalid, error }, type, placeholder, min, max, disabled, errorStyle
        } = this.props;
        return <Fragment>
            <InputMask {...input}
                       type={type}
                       className={'form-control ' + (touched && invalid ? 'is-invalid' : '') + ' h-44'}
                       placeholder={placeholder}
                       min={min}
                       max={max}
                       disabled={disabled} mask="999999-****"
                       maskChar={null}
                       beforeMaskedValueChange={this.beforeMaskedValueChange}
            />
            {error !== undefined ? (
                <div className="invalid-feedback" style={errorStyle}>
                    {error}
                </div>
            ) : ''}
        </Fragment>
    }
}

renderReportCode.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderReportCode;
