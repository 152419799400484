import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as routerName from '../../utils/routerName';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
class PlanModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: 22050,
            invalid: false,
            marks : {
                0: <span>T끼리 무제한<br />그외 0분</span>,
                20: <span>T끼리 무제한<br />그외 50분</span>,
                40: <span>T끼리 무제한<br />그외 100분</span>,
                60: <span>T끼리 무제한<br />그외 150분</span>,
                80: {
                  label: <span>T끼리 무제한<br />그외 200분</span>,
                },
                100: {
                    label: <span>T끼리 무제한<br />그외 250분</span>,
                },
            }
        }
    }

    toggle = () => {
        this.props.toggleEventPlan()
    }
    log = (value) => {
        console.log(value); //eslint-disable-line
    }
    render() {
        return (
            <Fragment>
                <Modal 
                    isOpen={this.props.showModal} 
                    toggle={this.toggle}
                    // backdrop={"static"}
                    className="modal-dialog-centered modal-dialog-scrollable modal-lg"
                    contentClassName="content pl-5 pr-5"
                >
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">
                        뉴 T 끼리 맞춤형
                        </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                        <div className="modal-body discount-card pl-0 pr-0">
                            <div className="progress-wrapper">
                                {/* <div style={style}>
                                    <p>Basic Slider，`step=20, dots`</p>
                                    <Slider dots step={50} defaultValue={100} onAfterChange={this.log} />
                                </div> */}
                                <div className="progress-item">
                                    <h4>
                                    음성
                                    </h4>
                                    <div className="right">
                                        <Slider dots  marks={this.state.marks} min={0} step={20} defaultValue={0} onChange={this.log} />
                                    </div>
                                </div>
                                <div className="progress-item">
                                    <h4>
                                    데이터
                                    </h4>
                                    <div className="right">
                                        <Slider dots step={20} defaultValue={0} onAfterChange={this.log} />
                                    </div>
                                </div>
                                <div className="progress-item">
                                <h4>
                                문자
                                </h4>
                                <div className="right">
                                    <Slider dots step={50} defaultValue={0} onAfterChange={this.log} />
                                </div>
                            </div>
                            </div>
                            <h5 className="total-progress">
                            월 통신요금: <span>41,030</span> 원 (VAT 포함)
                            </h5>
                        </div>
                    </div>
                </Modal> 
                </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(PlanModal);

