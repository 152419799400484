
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    chooseOption2G, numberMathFloor, priceFormat, roundDownNumber, roundUpNumber,
    roundUpNumberPlan
} from "../../../utils/helper";

class WelfareDiscountModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
    renderWelfareDiscount () {
        const { dataPromotion, WirelessAdvice,stateChangeProp } = this.props;

        if(WirelessAdvice.WirelessAdviceStep3.basicCost != null) {
            var combinedDiscount=0;

            if(WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
                combinedDiscount = roundUpNumber(+WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price + +WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv);
            }else {
                combinedDiscount=WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
            }

            combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1));

            var rateAgreement=(WirelessAdvice.WirelessAdviceStep3.rateAgreement && !WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
            var optionalAgreement=WirelessAdvice.WirelessAdviceStep3.optionalAgreement ? WirelessAdvice.WirelessAdviceStep3.optionalAgreement : 0
            var optionFee=WirelessAdvice.WirelessAdviceStep3.optionFee
            if(optionFee == 1) {
                optionalAgreement=0
            }
            var promotion=WirelessAdvice.WirelessAdviceStep3.promotion && WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

            var basicCostCalc=WirelessAdvice.WirelessAdviceStep3.basicCostCalc
            var basicCost=WirelessAdvice.WirelessAdviceStep3.basicCost
            var valueBasicCost=0
            if(basicCostCalc != 0) {
                valueBasicCost=basicCostCalc
            }else {
                valueBasicCost=roundUpNumberPlan(basicCost)
            }

            let dataTelcom = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
            if(dataTelcom) {
                dataTelcom=dataTelcom.toUpperCase()
            }

            // optionalAgreement = roundUpNumberPlan(optionalAgreement)
            let optionID6=((valueBasicCost - optionalAgreement - rateAgreement - promotion - combinedDiscount)*0.5)
            let optionID3= (valueBasicCost - promotion) > 28600 ? 28600 : (valueBasicCost - promotion)
            let optionID45=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
            optionID6=Math.round(optionID6)
            let dataWelfareDiscount=[
                {id:0, value:"미적용", cost:0},
                {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 69000*0.35},
                {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 69000*0.35},
                {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:(valueBasicCost > 45100 ? 23650: optionID45)},
                {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:(valueBasicCost > 45100 ? 23650: optionID45)},
                {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
            ]
            if(dataTelcom == "SKT") {
                let optionID45SKT=  (12100+((valueBasicCost-12100)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100)*0.35))
                let optionID12SKT=  ((valueBasicCost - rateAgreement - combinedDiscount)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount)*0.35 : 0)
                dataWelfareDiscount=[
                    {id:0, value:"미적용", cost:0},
                    {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
                    {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12SKT : 69000*0.35},
                    {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                    {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost - 12100)*0.35)) > 23650 ? 23650 : optionID45SKT)},
                    {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost - 12100)*0.35)) > 23650 ? 23650 : optionID45SKT)},
                    {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
                ]
            }

            if(dataTelcom == "KT") {
                let optionID45KT=  (12100+((valueBasicCost-12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost-12100 - promotion)*0.35))
                let optionID45KTMainCondition=  (valueBasicCost - 12100 - promotion) < 0 ? ((12100 - promotion) > 0 ? (12100 - promotion) : 0) :((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                    optionID45KT)
                let optionID12KT=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0)

                dataWelfareDiscount=[
                    {id:0, value:"미적용", cost:0},
                    {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
                    {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12KT : 69000*0.35},
                    {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                    {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:((12100+((valueBasicCost-12100 - promotion)*0.35)) > 23650 ? 23650:
                            optionID45KT)},
                    {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:optionID45KTMainCondition},
                    {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
                ]
            }

            if(dataTelcom == "LGU+") {
                let optionID45LGU=  (12100+((valueBasicCost - 12100 - promotion)*0.35)) > basicCost ? basicCost : (12100+((valueBasicCost - 12100 - promotion)*0.35))
                let optionID12LGU=  ((valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 > 0 ? (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : 0);
                //console.log("valueBasicCost : " + valueBasicCost);
                //console.log("rateAgreement : " + rateAgreement);
                //console.log("combinedDiscount : " + combinedDiscount);
                //console.log("promotion : " + promotion);
                //console.log("(valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35 : " + (valueBasicCost - rateAgreement - combinedDiscount - promotion)*0.35);
                //console.log("optionID12LGU" + optionID12LGU);
                dataWelfareDiscount=[
                    {id:0, value:"미적용", cost:0},
                    {id:1, value:'장애인 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
                    {id:2, value:'국가유공자 (35% 할인)',cost:valueBasicCost != null ? optionID12LGU : 69000*0.35},
                    {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:optionID3},
                    {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:(valueBasicCost > 45100 ? 23650:
                            optionID45LGU)},
                    {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)', cost:((12100+((valueBasicCost - 12100 - promotion)*0.35)) > 23650 ? 23650:
                            optionID45LGU)},
                    {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:(optionID6 > 12100 ? 12100 : (optionID6 < 0 ? 0 :optionID6))},
                ]
            }

            let activeData=WirelessAdvice.WirelessAdviceStep3.welfareDiscount;

            if(dataWelfareDiscount &&  dataWelfareDiscount.length > 0) {
                return (dataWelfareDiscount.map((item, key) => {
                    return (
                        <li className={`cursor ${activeData != null && activeData.value && activeData.value == item.value ? "active" : (activeData == null && item.id == 0 ? "active" : "")}`} key={key} onClick={() => this.choosePromotion(item)}>
                            {item.value}
                        </li>
                    );
                }));
            }else {
                return null
            }
        }else {
            return null;
        }

    }

    choosePromotion = (data) => {
        const {WirelessAdvice} = this.props;

        if (data.id !== 0) {
            // if(WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && data.id == 6) {
            if (data.id === 6) {
                if (WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                    var basicCostCalc = WirelessAdvice.WirelessAdviceStep3.basicCostCalc
                    var basicCost = WirelessAdvice.WirelessAdviceStep3.basicCost
                    var valueBasicCost = 0
                    if (basicCostCalc != 0) {
                        valueBasicCost = basicCostCalc
                    } else {
                        valueBasicCost = roundUpNumberPlan(basicCost)
                    }
                    var combinedDiscount = WirelessAdvice.WirelessAdviceStep3.combinedDiscount
                    var promotionCost = WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
                    var optionalAgreement = WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G ? (WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G) : WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault // truong hop lgu cho option 6

                    let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
                    let telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
                    let nameTelcom = ""
                    if (dataSkt) {
                        nameTelcom = dataSkt.toUpperCase()
                    }
                    if (telcom != null) {
                        nameTelcom = telcom.toUpperCase()
                    }

                    let data2g = {id: 1, value: '적용'}
                    var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement) / 2)
                    dataCostWelfareDiscount = dataCostWelfareDiscount > 12100 ? 12100 : dataCostWelfareDiscount
                    data.cost = parseInt(dataCostWelfareDiscount) > 0 ? (dataCostWelfareDiscount) : 0
                    let dataChoose = chooseOption2G(data2g, {...WirelessAdvice.WirelessAdviceStep3}, nameTelcom, data)
                    var dataCostWelfareDiscountAfter2G = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - dataChoose.optionalAgreement) / 2)
                    dataCostWelfareDiscountAfter2G = dataCostWelfareDiscountAfter2G > 12100 ? 12100 : dataCostWelfareDiscountAfter2G
                    data.cost = parseInt(dataCostWelfareDiscountAfter2G) > 0 ? (dataCostWelfareDiscountAfter2G) : 0

                }
            }
            this.props.change('WirelessAdviceStep3.welfareDiscount', data)
        } else {
            this.props.change('WirelessAdviceStep3.welfareDiscount', null)
        }

        this.props.openPopup()

    }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
        <Modal isOpen={this.props.isOpenModal}
               // backdrop={"static"}
               toggle={this.props.openPopup} fade={true} className={'modal-dialog modal-dialog-centered modal-additional modal-custom-04'} contentClassName="modal-content">


                <div className="modal-header">
                    <h5 className="modal-title">복지할인 </h5>
                    {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopup}>
                        <img src="/images/icon-close.svg" alt="" />
                    </button> */}
                </div>
                <ModalBody>
                    <ul className="list-additional">
                        {this.renderWelfareDiscount()}


                    </ul>
                </ModalBody>
                {/*<ModalFooter>*/}
                {/*<Button color="primary" onClick={this.props.openPopup}>Do Something</Button>{' '}*/}
                {/*<Button color="secondary" onClick={this.props.openPopup}>Cancel</Button>*/}
                {/*</ModalFooter>*/}

        </Modal>
    );
  }
}

WelfareDiscountModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
   withReduxForm,
)(WelfareDiscountModal);
