import React, { Component } from 'react';

class Tooltip extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayTooltip: false
        }
        this.hideTooltip = this.hideTooltip.bind(this)
        this.showTooltip = this.showTooltip.bind(this)
    }

    hideTooltip () {
        this.setState({displayTooltip: false})

    }
    showTooltip () {
        this.setState({displayTooltip: true})
    }

    render() {
        let message            = this.props.message;
        let position           = this.props.position;
        let className          = this.props.className;
        let tooltipBubbleSmall = this.props.tooltipBubbleSmall;

        if(tooltipBubbleSmall == undefined) {
            tooltipBubbleSmall = "tooltip-bubble-small";
        }
        else{
            tooltipBubbleSmall = "";
        }

        return (
            <span className={`custom-tooltip  ${className}`}
                  onMouseLeave={this.hideTooltip}
            >
        {this.state.displayTooltip &&
        <div className={`tooltip-bubble ${tooltipBubbleSmall} tooltip-${position}`}>
            <div className='tooltip-message px-1'>{message}</div>
        </div>
        }
                <span
                    className='tooltip-trigger'
                    onMouseOver={this.showTooltip}
                >
          {this.props.children}
        </span>
      </span>
        )
    }
}

export default  Tooltip;