import MessageJSON from "../../utils/message.json";
import * as helper from "../../utils/helper";

const validate = values => {
    const errors = {};
    const { title, content, d_state_show, start_date, d_state_hide, end_date, attachment,cat_id} = values;
    if (!title) {
        errors.title = MessageJSON.document.validate.title_required;
    }  else if (title.trim() && title.length > 191) {
        errors.title = MessageJSON.document.validate.title_max;
    } 
    // else if(!helper.isValid(title)) {
    //     errors.title = MessageJSON.document.validate.title_regex;
    // }

    if (!content) {
        errors.content = MessageJSON.document.validate.content_required;
    } 
    if(d_state_show == true && !start_date) {
        errors.start_date = MessageJSON.document.validate.start_date_required;
    }
    if(d_state_hide == true && !end_date) {
        errors.end_date = MessageJSON.document.validate.end_date_required;
    }
    if ( attachment && attachment.size > 10*1024*1024) {
        errors.attachment = MessageJSON.document.validate.attachment_size;
    } else if( attachment && !helper.isValidFile(attachment.name)){
        errors.attachment = MessageJSON.document.validate.attachment_regex;
    } else if( attachment && typeof attachment != 'string' && ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'jpeg', 'jpg', 'png'].indexOf(/[^.]+$/.exec(attachment.name)[0]) == -1) {
        errors.attachment = MessageJSON.document.validate.attachment_extensions_regex;
    }
    if(!cat_id){
        errors.cat_id = MessageJSON.document.validate.cat_id_required;
    }
    return errors;
};

export default validate;
