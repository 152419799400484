

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    calcInstallmentFeePrincipalAndInterestByMonth,
    calcInstallmentFeePrincipalByMonth,
    priceFormat,
    roundUpNumber,
    updateWirelessStep3,
    caclWirelessStep3,
} from '../../../utils/helper'
import { Modal } from 'reactstrap';
import ProgramPopup from './ProgramPopup';
import MembershipDiscountPopup from './MembershipDiscountPopup';
import InstallmentMonthPopup from './InstallmentMonthPopup';
import InstallmentTypePopup from './InstallmentTypePopup';
import TableInfo from './TableInfo';
import renderHTML from 'react-render-html';
import {
    getListUsedPrograms,
    getListMembershipDiscounts,
    applyUsedProgram
} from './../../../store/actions/dataStore';
import _ from 'lodash';
import { OPTION_FEE, INSTALLMENT_PAYMENT_METHOD, KT_S23_SBS_PRM_Y_EDT } from './../../../utils/constant';
import moment from 'moment';

class UsedProgramMainPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tinh tien
            interestRate: 5.9, // tiền lãi trên 1 năm
            installmentPrincipal: 0, // Nguyên giá trả góp (할부원금)
            installmentFee: 0, // Tổng lãi suất trả góp (할부수수료)
            installmentMonthly : 0, // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
            installmentMonthlyPrincipal : 0, // Tiền trả góp thiết bị theo tháng Nguyên giá trả góp
            installmentMonthlyFee : 0, // Tiền trả góp thiết bị theo tháng lãi suất trả góp
            monthlyFee: 0, // chi phi theo thang

            isOpenProgram: false,
            isOpenMembership: false,
            isOpenInstallmentMonth: false,
            isOpenInstallmentType: false,
            programs: {
                dataList: [
                    {
                        "tup_id": "",
                        "ets_id": "",
                        "tup_name": "미적용",
                    }
                ],
                itemSelected:{
                    "tup_id": "",
                    "tup_name": "미적용"
                }
            },
            membershipDiscounts: {
                dataList: [
                    {
                        "tcm_id":'',
                        "value":0,
                        "tcm_option_name": "미적용",
                    }
                ],
                itemSelected: {
                    "tcm_id":'',
                    "tcm_option_name": "미적용"
                }
            },
            installmentType: {             
                itemSelected:''
            },
            installmentSubType: {
                // dataList: [
                //     {
                //         "tcm_option_name": "선택불가"
                //     }
                // ],
                itemSelected:''
            },
            installmentMonthSelect:'',
            optionFee:'',
            isSelectOptionFee: false,
        }
    }

    onOpened = () => {
        let { WirelessAdviceStep3 } = this.props.WirelessAdvice;
        let installmentMonthRedux = WirelessAdviceStep3.installmentMonth;  

        if (WirelessAdviceStep3.usedPhoneCompensation && WirelessAdviceStep3.usedPhoneCompensation.tup_id) {
            //check It is vprogram_types Or vprogram_install_types
            let data     = this.state.programs.itemSelected;
            let isSelect = false;
            if (data.vprogram_types && data.vprogram_types.length > 0) {
                data.vprogram_types.forEach((item, index) => {
                    if (item.code_group == WirelessAdviceStep3.optionFee) {
                        isSelect = true;
                    }
                })
            } else if (data.vprogram_install_types && data.vprogram_install_types.length > 0) {
                let installmentType = this.state.installmentType.itemSelected;
                let installmentTypeName = installmentType ? installmentType.typeName : '';
                let arrSubType = [];
                data.vprogram_install_types.forEach((item, index) => {
                    let tmp = item.name.split('/');
                    if (tmp[0] == installmentTypeName) {
                        arrSubType.push({
                            ...item,
                            'subTypeName': tmp[1]
                        })
                    }
                });


                arrSubType.forEach((item, index) => {
                    if (item.code_group == WirelessAdviceStep3.optionFee) {
                        isSelect = true;
                    }
                })
            }

            let arrMonth = data.tup_inst_device_mo;
                arrMonth = (arrMonth || []).map(item => {
                    return parseInt(item);
                })


            this.setState({
                ...this.state,
                // installmentMonthSelect: WirelessAdviceStep3.installmentMonth,   // old
                // installmentMonthSelect: this.state.installmentMonthSelect ? this.state.installmentMonthSelect : WirelessAdviceStep3.installmentMonth,
                installmentMonthSelect: arrMonth.includes(installmentMonthRedux) ? installmentMonthRedux : _.min(data.tup_inst_device_mo),
                optionFee: WirelessAdviceStep3.optionFee,
                isSelectOptionFee: isSelect,
            }, () => {

            })
        } else {
            this.setState({
                ...this.state,
                optionFee: WirelessAdviceStep3.optionFee,
                programs: {
                    dataList: [...this.state.programs.dataList],
                    itemSelected: {
                        "tup_id":'',
                        "tup_name": "미적용"
                    }
                },
                installmentType: { 
                    itemSelected:''
                },
                installmentSubType: {
                    // dataList: [
                    //     {
                    //         "tcm_option_name": "선택불가"
                    //     }
                    // ],
                    itemSelected:''
                },
                membershipDiscounts: {
                    dataList: [
                        {
                            "tcm_id":'',
                            "value":0,
                            "tcm_option_name": "미적용",
                        }
                    ],
                    itemSelected: {
                        "tcm_id":'',
                        "tcm_option_name": "미적용"
                    }
                },                
                // optionFee: WirelessAdviceStep3?.optionFee || 0

            }, () => {
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { WirelessAdvice: { WirelessAdviceStep3 } } = this.props;
        //console.log("중고폰 업데이트")
        //console.log("중고폰 업데이트 this.props.WiredAdvice?.id : ", WirelessAdviceStep3.optionFee)
        if(WirelessAdviceStep3.requestForMonth != null && WirelessAdviceStep3.requestForMonth != 0){
            if(WirelessAdviceStep3.usedPhoneOptionFee != WirelessAdviceStep3.optionFee){
                this.props.change('WirelessAdviceStep3.usedPhoneOptionFee', WirelessAdviceStep3.optionFee);    
                //console.log("this.getPayForMonthAfter_lguplus_2()", this.onApply_lguplus())
                this.onApply_lguplus();
                //alert("공시지원금과 선택약정 선택이 올바른지 확인해주세요")
                this.props.change('WirelessAdviceStep3.requestForMonth', this.getPayForMonthAfter_lguplus_2()); // LG 2구간
            }
            //this.props.change('WirelessAdviceStep3.requestForMonth', this.getPayForMonthAfter_lguplus_2()); // LG 2구간
            //this.props.change('WirelessAdviceStep3.usedPhoneOptionFee', this.state.optionFee);
        }
    }


    componentDidMount() {
    }

    updateCalculator = (installmentMonthSelect=null, instMonthlyDefer_1=null, beforeCalculator = false) => {
        const { WirelessAdvice: { WirelessAdviceStep1, WirelessAdviceStep3 } } = this.props;
        
        let wirelessAdviceStep3  = {...WirelessAdviceStep3};
        let programSelected      = this.state.programs.itemSelected;
        let programSelectedTupId = +programSelected.tup_id;

        // 1 = tiền hỗ trợ thêm (추가지원금) | 0 = giảm giá cam kết lựa chọn (선택약정할인)
        wirelessAdviceStep3.optionFee =  _.isNumber(this.state.optionFee) ? this.state.optionFee : WirelessAdviceStep3.optionFee;       //get the first state
        
        // số tháng trả góp (할부개월)
        if (installmentMonthSelect) {
            wirelessAdviceStep3.installmentMonth = installmentMonthSelect;
        }
        else {
            wirelessAdviceStep3.installmentMonth = this.state?.installmentMonthSelect || WirelessAdviceStep3.installmentMonth;    //get the first state
        }

        wirelessAdviceStep3.applyUsedProgram   = 0;
        wirelessAdviceStep3.instMonthlyDefer_1 = instMonthlyDefer_1;

        if(programSelectedTupId == 192){
            if(beforeCalculator){
                wirelessAdviceStep3.usedPhoneCompensation = null;
            }
            else{
                wirelessAdviceStep3.usedPhoneCompensation = new Object();

                wirelessAdviceStep3.usedPhoneCompensation.telcomID = wirelessAdviceStep3.phone.telcomID;
                wirelessAdviceStep3.usedPhoneCompensation.tup_id   = programSelectedTupId;
            }
        }

        let result = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);

        return {
            installmentPrincipal: result.installmentPrincipal,    // Nguyên trả góp (원래할부결제)
            installmentMonthly: result.installmentMonthly,      // Tiền trả góp theo tháng (월할부금)
            amountMonthlyDevice:result.amountMonthlyDevice,    // Tiền yêu cầu theo tháng  (A + B) (월별소요금액)
            amountMonthlyTotal:result.amountMonthlyTotal,    //  Tiền yêu cầu theo tháng A + B + C (월별소요금액)
            installmentFee:result.installmentFee,    // Tổng lãi suất trả góp (할부수수료, 총할부이자) hay Phí trả góp (또는 할부수수료)
            monthlyFee:result.monthlyFee,    // Phí hàng tháng (월사용료)
        }
    }


    /**
     * manage popup used programs
     */
    showPopupPrograms = () => {
        this.props.showLoading();
        let { WirelessAdviceStep1, WirelessAdviceStep2, WirelessAdviceStep3 } = this.props.WirelessAdvice;
        let data = {
            telcomID: WirelessAdviceStep1.telcomID,
            modelID: WirelessAdviceStep3.phone.modelID ? WirelessAdviceStep3.phone.modelID : WirelessAdviceStep3.phone.id,
            //  telcomID: 18,
            //  modelID: 34
        }

        this.props.getListUsedPrograms(data).then((res) => {
            this.props.hideLoading();
            this.setState({
                ...this.state,
                isOpenProgram: !this.state.isOpenProgram,
                programs: {
                    dataList: [this.state.programs.dataList[0], ...res],
                    itemSelected: {...this.state.programs.itemSelected}
                }
            }, () => {
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    onToggleProgram = () => {
        this.setState({
            isOpenProgram: !this.state.isOpenProgram,
        })
    }

    renderPrograms = (data) => {
        let xhtml = data && data.map((item, index) => {
            let active = this.state.programs.itemSelected?.tup_id == item.tup_id ? 'active' : '';
            return (
                <li 
                    key={index}
                    onClick={() => this.getProgramValue(item)}
                    // className={active}
                >
                    {item.tup_name}
                </li>
            )
        })
        return xhtml;
    }

    getProgramValue = (data) => {
        if(!this.state.isOpenProgram) return;

        console.log("data", data);
 
        if (data.tup_id) {
            if(data.tup_id == 190){ // KT KT S23 FE 폰 구독
                this.setState({
                    ...this.state,
                    isOpenProgram: !this.state.isOpenProgram,
                    programs: {
                        dataList: [...this.state.programs.dataList],
                        itemSelected: data
                    }
                }, () => {
                    let moreFee = this.getPayForMonthAfter().moreFee; 
                    this.props.change('WirelessAdviceStep3.applyUsedProgram', moreFee);
                });

                this.props.change('WirelessAdviceStep3.usedPhoneCompensation', data);
                this.props.change('WirelessAdviceStep3.pointDiscount', 0); //포인트할인 해제
                this.props.change('WirelessAdviceStep3.advancedDiscountCard', 0); // 선할인카드 해제
                this.props.change('WirelessAdviceStep3.advancedDiscountCardID', null); // 선할인카드 해제
                this.props.change('WirelessAdviceStep3.other', 0); // 기타할인 해제
                this.props.toggle();
            }
            else{
                let { WirelessAdviceStep3 } = this.props.WirelessAdvice;
                let installmentMonthRedux = WirelessAdviceStep3.installmentMonth;
                let arrMonth = data.tup_inst_device_mo;
                    arrMonth = (arrMonth || []).map(item => {
                        return parseInt(item);
                    })            
                let resultCalc = this.updateCalculator();
    
                //check It is vprogram_types Or vprogram_install_types
                let isSelect = false;
                if (data.vprogram_types.length > 0) {
                    data.vprogram_types.forEach((item, index) => {
                        if (item.code_group == this.state.optionFee) {
                            isSelect = true;
                        }
                    })
                }
    
                this.setState({
                    ...this.state,
                    isOpenProgram: !this.state.isOpenProgram,
                    programs: {
                        dataList: [...this.state.programs.dataList],
                        itemSelected: data
                    },
                    ...resultCalc,
                    // installmentType: {
                    //     itemSelected:''
                    // },
                    // installmentSubType: {
                    //     itemSelected:''
                    // },
                    membershipDiscounts: {
                        dataList: [...this.state.membershipDiscounts.dataList],                  
                        itemSelected: {
                            "tcm_id":'',
                            "tcm_option_name": "미적용"
                        }
                    },
                    isSelectOptionFee: isSelect,
                    // installmentMonthSelect: _.min(data.tup_inst_device_mo),
                    installmentMonthSelect: arrMonth.includes(installmentMonthRedux) ? installmentMonthRedux : _.min(data.tup_inst_device_mo),
                }, () => {
                    if(data.tup_id == 191 && data.vprogram_types.length == 0){
                        let item = data.vprogram_install_types[0];

                        item = {...item, typeName : item.name.split('/')[0]};

                        this.getInstallmentTypeValue(item);
                    }
                });
            }
        } else {
            this.setState({
                ...this.state,
                isOpenProgram: !this.state.isOpenProgram,
                programs: {
                    dataList: [...this.state.programs.dataList],
                    itemSelected: {
                        "tup_id":'',
                        "tup_name": "미적용"
                    }
                },
                installmentMonthSelect:'',
                installmentType: {
                    itemSelected:''
                },
                installmentSubType: {
                    itemSelected:''
                },
                membershipDiscounts: {
                    dataList: [...this.state.membershipDiscounts.dataList],                  
                    itemSelected: {
                        "tcm_id":'',
                        "tcm_option_name": "미적용"
                    }
                },
            }, () => {
            })
        }
    }



    /**
     *  manage popup membership discountf
     * @param {*} data
     */
    showPopupMembership = () => {
        let { WirelessAdviceStep1, WirelessAdviceStep2, WirelessAdviceStep3 } = this.props.WirelessAdvice;
        let data = {
            telcomID: WirelessAdviceStep1.telcomID,
            ets_id: this.state.programs.itemSelected.ets_id,
            //  telcomID: 18,
            //  modelID: 34
            // telcomID: 4,
            // modelID: 25
        }
        this.props.getListMembershipDiscounts(data).then((res) => {
            this.setState({
                ...this.state,
                isOpenMembership: !this.state.isOpenMembership,
                membershipDiscounts: {
                    dataList: [this.state.membershipDiscounts.dataList[0], ...res],
                    itemSelected: {...this.state.membershipDiscounts.itemSelected}
                }
            }, () => {
            })
        }).catch(err => {
        })
    }

    onToggleMembership = () => {
        this.setState({
            isOpenMembership: !this.state.isOpenMembership,
        })
    }

    renderMembershipDiscount = (data) => {
        let xhtml = data && data.map((item, index) => {
            let active = this.state.membershipDiscounts.itemSelected?.tcm_id == item.tcm_id ? 'active' : '';
            let itemMembership = '';
            if (item.unit == 1 ) {
                itemMembership = (
                    `${item.tcm_option_name} (${item.value}% 할인)` 
                )
            } else if (item.unit == 0 ) {
                itemMembership = (
                    `${item.tcm_option_name} (${priceFormat(parseFloat(item.value))}원 할인)` 
                )
            } else {
                itemMembership = (
                    `${item.tcm_option_name}` 
                )
            }
            return (
                <li 
                    key={index}
                    onClick={() => this.getMembershipDiscountValue(item)}
                    // className={active}
                >
                    {
                        itemMembership
                    }
                    {/* {
                        item.unit == 1 
                        ? `${item.tcm_option_name} (${item.value}% 할인)` 
                        : item.tcm_option_name
                    } */}
                </li>
            )
        })
        return xhtml;
    }

    getMembershipDiscountValue = (data) => {
        if (data.tcm_id) {
            let resultCalc = this.updateCalculator();
            this.setState({
                ...this.state,
                isOpenMembership: !this.state.isOpenMembership,
                membershipDiscounts: {
                    dataList: [...this.state.membershipDiscounts.dataList],
                    itemSelected: data
                },
                ...resultCalc,
            }, () => {
            })
        } else {
            this.setState({
                ...this.state,
                isOpenMembership: !this.state.isOpenMembership,
                membershipDiscounts: {
                    dataList: [...this.state.membershipDiscounts.dataList],
                    itemSelected: {
                        "tcm_id":'',
                        "tcm_option_name": "미적용"
                    }
                },
            }, () => {
            })
        }
    }



    /**
     * manage popup installment month
     */
    showPopupInstallmentMonth = () => {
        this.setState({
            isOpenInstallmentMonth: !this.state.isOpenInstallmentMonth,
        })
    }
    onToggleInstallmentMonth = () => {
        this.showPopupInstallmentMonth();
    }

    renderInstallmentMonth = (data) => {
        let tup_inst_circle_mo = parseInt(this.state.programs.itemSelected.tup_inst_circle_mo);
        let xhtml = data && data.map((item, index) => {
            let active = this.state.installmentMonthSelect == parseInt(item) ? 'active' : ''; 
            
            return (
                <li 
                    key={index}
                    className={
                        parseInt(item) < tup_inst_circle_mo ? 'disable' : ''
                        // active
                    }
                    onClick={() => this.getInstallmentMonthValue(parseInt(item))} >
                    {item} 개월
                </li>
            )
        })
        return xhtml;
    }

    getInstallmentMonthValue = (data) => {
        this.setState({
            ...this.state,
            isOpenInstallmentMonth: !this.state.isOpenInstallmentMonth,
            installmentMonthSelect: data
        }, () => {
            // this.props.change('WirelessAdviceStep3.installmentMonth', data)
            this.updateCalculator()
        })
    }



    checkExist = (arr, typeName) => {
        return arr.some(function(el) {
          return el.typeName === typeName;
        });
    }
    /**
     * manage popup installment TYPE
     */
    showPopupInstallmentType = () => {
        this.setState({
            isOpenInstallmentType: !this.state.isOpenInstallmentType,
        })
    }
    onToggleInstallmentType = () => {
        this.setState({
            isOpenInstallmentType: !this.state.isOpenInstallmentType,
        })
    }

    renderInstallmentType = (vprogram_types=[], vprogram_install_types=[]) => {
        // debugger
        let arrType = [];
        if (vprogram_types.length == 0) {
            vprogram_install_types.forEach((item, index) => {
                let tmp = item.name.split('/');
                // if (!arrType.includes( tmp[0])) {
                //     arrType.push(tmp[0])
                // }

                if (!this.checkExist(arrType, tmp[0])) {
                    arrType.push({...item, typeName: tmp[0]});
                }
            });
        }

        let xhtml = arrType && arrType.map((item, index) => {
            return (
                <li key={index} onClick={() => this.getInstallmentTypeValue(item)} >
                    {item.typeName}
                </li>
            )
        });

        return xhtml;
    }

    getInstallmentTypeValue = (data) => {
        this.setState({
            ...this.state,
            //isOpenInstallmentType: !this.state.isOpenInstallmentType,
            isOpenInstallmentType: false,
            installmentType: {
                itemSelected: data
            },
            // isSelectOptionFee: true     // watching....
        }, () => {
            let check = this.checkMoreSelectOptionFee();
            this.setState({
                ...this.state,
                isSelectOptionFee: check
            }, () =>{
                if(this.state.programs.itemSelected.vprogram_types.length == 0 && this.state.installmentType.itemSelected) {
                    let arrSubType = [];
                    let isChange   = false;
        
                    this.state.programs.itemSelected.vprogram_install_types.forEach((item, index) => {
                        let tmp = item.name.split('/');
        
                        if (tmp[0] == this.state.installmentType.itemSelected.typeName) {
                            arrSubType.push({
                                ...item,
                                'subTypeName': tmp[1]
                            })
                        }
                    });
        
                    arrSubType.map((subItem, index) => {
                        let optionFee = _.isNumber(this.state.optionFee) ? this.state.optionFee : this.props.WirelessAdvice.WirelessAdviceStep3.optionFee;
                        
                        if (optionFee == subItem.code_group) {
                            this.getInstallmentSubTypeValue(subItem);
        
                            isChange = true;
                        }
                    });
        
                    if(isChange === false){
                        this.getInstallmentSubTypeValue(arrSubType[0]);
                    }
                }
            })
        })
    }


    /**
     * manage installment sub TYPE
     */
    renderInstallmentSubTypes = (vprogram_types=[], vprogram_install_types=[], installmentType='') => {
        // debugger
        let arrSubType = [];
        // case: BTDT cũ => Nhà mạng LGU
        if (vprogram_types.length == 0 && installmentType) {
            // if (this.state.isSelectOptionFee) {

                // convert to new array with more property(subTypeName)
                vprogram_install_types.forEach((item, index) => {
                    let tmp = item.name.split('/');
                    if (tmp[0] == installmentType.typeName) {
                        arrSubType.push({
                            ...item,
                            'subTypeName': tmp[1]
                        })
                    }
                });

                var xhtml = arrSubType.map((subItem, index) => {
                    let { WirelessAdviceStep3 } = this.props.WirelessAdvice;
                    let optionFee =  _.isNumber(this.state.optionFee) ? this.state.optionFee : WirelessAdviceStep3.optionFee;
                    
                    if (optionFee == subItem.code_group) {
                        var checked = 'checked';
                    }
                    else {
                        var checked = '';
                    }

                    return (
                        <div className="col-12" key={index}>
                            <div className="custom-control custom-radio">
                                <input type="radio" id={subItem.inst_type} name="customRadio" className="custom-control-input" value={subItem}
                                    checked={ checked } onClick={() => this.getInstallmentSubTypeValue(subItem)}
                                    />
                                <label className="custom-control-label" htmlFor={subItem.inst_type}>{subItem.subTypeName}</label>
                            </div>
                        </div>
                    )
                })
            // }
        } else {
            // case: BTDT cũ => Nhà mạng khác LGU
            let { WirelessAdviceStep3 } = this.props.WirelessAdvice;
            var xhtml = vprogram_types.map((item, index) => {
                let optionFee =  _.isNumber(this.state.optionFee) ? this.state.optionFee : WirelessAdviceStep3.optionFee;
                if (optionFee == item.code_group) {
                    var checked = 'checked';
                } else {
                    var checked = '';
                }

                return (
                    <div className="col-12" key={index}>
                        <div className="custom-control custom-radio">
                            <input type="radio" id={item.type} name="customRadio" className="custom-control-input"
                             value={item.type}  checked={checked} onClick={() => this.getInstallmentSubTypeValue(item)}/>
                            <label className="custom-control-label" htmlFor={item.type}>{item.name}</label>
                        </div>
                    </div>
                )
            })
        }
        return xhtml;
    }

    getInstallmentSubTypeValue = (item) => {
        console.log(item, "item");
        //console.log(this.props.WirelessAdvice.WirelessAdviceStep1.telcomID, "his.props.WirelessAdvice.WirelessAdviceStep1.telcomID");

        this.setState({
            ...this.state,
            installmentSubType: {
                itemSelected: item
            },
            optionFee: parseInt(item.code_group),
            isSelectOptionFee: true     // watching....
        }, () => {
            if(this.props.WirelessAdvice.WirelessAdviceStep1.telcomID === 18){
                let instType = Number(item.inst_type);
                let tupId    = +item.tup_id;

                if(instType == 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정
                    let instPrincipalUsedPhone1 = this.getInstPrincipalUsedPhone_loguplus_1(); // 1구간 할부원금
                    
                    instPrincipalUsedPhone1 = this.replaceAll(instPrincipalUsedPhone1, ",", "");
                    instPrincipalUsedPhone1 = this.replaceAll(instPrincipalUsedPhone1, "원", "");
                    instPrincipalUsedPhone1 = Number(instPrincipalUsedPhone1);

                    let instPrincipalUsedPhone2 = this.getInstPrincipalUsedPhone_loguplus_2(); // 2구간 할부원금

                    instPrincipalUsedPhone2 = this.replaceAll(instPrincipalUsedPhone2, ",", "");
                    instPrincipalUsedPhone2 = this.replaceAll(instPrincipalUsedPhone2, "원", "");
                    instPrincipalUsedPhone2 = Number(instPrincipalUsedPhone2);

                    let limit = instPrincipalUsedPhone2 / 65 * 35;

                    if(tupId != 191 && instPrincipalUsedPhone1 < limit){ // 갤럭시S23 중고폰가격보장프로그램이 아닌경우
                        if(instType == 83){ // 구간할부/공시지원금
                            alert("공시지원금 적용 후 1구간 금액이 기준금액(" + priceFormat(limit) + "원) 미만입니다.\n일반할부 또는 선택약정으로 가입하시기 바랍니다.\n다른 할인항목 금액을 수정해주세요");
                        }
                        else{ // 구간할부/선택약정
                            alert("선택약정 적용 후 1구간 금액이 기준금액(" + priceFormat(limit) + "원) 미만입니다.\n일반할부 또는 공시지원금으로 가입하시기 바랍니다.\n다른 할인항목 금액을 수정해주세요");
                        }
                        
                        return;
                    }
                }
            }
        })
    }


    /***
     *  apply used program
    */
    onApply = () => {
        // console.log(this.state.isSelectOptionFee, 'isSelectOptionFee2');
        let programSelected     = this.state.programs.itemSelected;
        let {isSelectOptionFee} = this.state;

        // case: full choose or don't choose any option
        if (programSelected.tup_id && isSelectOptionFee || !programSelected.tup_id ) {
            this.props.toggle();
            let installmentType = programSelected?.vprogram_install_types && programSelected.vprogram_install_types.length > 0 ?
            this.state.installmentType.itemSelected : '';            
    
            programSelected?.tup_id && this.props.change('WirelessAdviceStep3.installmentMonth', this.state.installmentMonthSelect);
            this.props.change('WirelessAdviceStep3.optionFee', this.state.optionFee);
            this.props.change('WirelessAdviceStep3.usedPhoneCompensation', programSelected);
            this.props.change('WirelessAdviceStep3.installmentType', installmentType);
            this.props.change('WirelessAdviceStep3.usedPhoneOptionFee', this.state.optionFee);
            this.props.change('WirelessAdviceStep3.usedPhoneInstallmentMonth', this.state.installmentMonthSelect);
    
            if (programSelected.tup_id) {  
                if (programSelected.ets_id) {   //chọn BTDT cũ thường
                    this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', null);       // Trả góp hằng tháng đợt 1 tạm hoãn LG
                    setTimeout(() => {
                        let moreFee = this.getPayForMonthAfter().moreFee; 
                        this.props.change('WirelessAdviceStep3.applyUsedProgram', moreFee);
                        this.props.change('WirelessAdviceStep3.usedPhoneMemberShip', this.state.membershipDiscounts.itemSelected);
                    });  
    
                } else {    //chọn BTDT LG tạm hoãn   
                    let instMonthlyDefer_1 = this.getInstMonthlyDefer_1();
                    this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', instMonthlyDefer_1);
                    setTimeout(() => {
                       let result = this.updateCalculator(null, instMonthlyDefer_1);
                       this.props.change('WirelessAdviceStep3.installmentMonthly', result.installmentMonthly); 
                    //    this.props.change('WirelessAdviceStep3.installmentMonthly', result.instMonthlyDefer_1); 
                       this.props.change('WirelessAdviceStep3.amountMonthlyDevice', result.amountMonthlyDevice);
                       this.props.change('WirelessAdviceStep3.amountMonthlyTotal', result.amountMonthlyTotal);
                       this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
                   });
                }
            } else {  //ko chọn BTDT cũ nào
                // debugger
                this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', null);
                setTimeout(() => {
                     //must re-set some value below
                    let result = this.updateCalculator();
                    this.props.change('WirelessAdviceStep3.installmentMonthly', result.installmentMonthly); 
                    this.props.change('WirelessAdviceStep3.amountMonthlyDevice', result.amountMonthlyDevice);
                    this.props.change('WirelessAdviceStep3.amountMonthlyTotal', result.amountMonthlyTotal);
                    this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
                });
            }

            if (programSelected.tup_id == 192){
                this.props.change('WirelessAdviceStep3.pointDiscount', null);
                this.props.change('WirelessAdviceStep3.advancedDiscountCard', null);
                this.props.change('WirelessAdviceStep3.advancedDiscountCardID', null);
                this.props.change('WirelessAdviceStep3.advancedDiscountCardOption', null);
                this.props.change('WirelessAdviceStep3.cashPayment', null);
                this.props.change('WirelessAdviceStep3.cashPaymentId', 0);
                this.props.change('WirelessAdviceStep3.other', null);
            }
        } 
        // else if (programSelected.tup_id && programSelected?.vprogram_types?.length > 0 && !this.checkMoreSelectOptionFee()) {
        //     alert('not select sub type - notLGU');
        // } else if (programSelected.tup_id && programSelected?.vprogram_install_types) {
        //     let checker = this.checkMoreSelectOptionFee();

        //     if (!this.state.installmentType?.itemSelected?.typeName) {
        //         alert('not select MainType LGU');
        //     } else if (!checker) {
        //         alert('not select sub type LGU');
        //     }
        // }

        // case: miss choose option required
        else {
            // alert('not select MainType ALL');
            alert('할부금 유형을 선택해 주세요!');
        }
      
    }

    // lguplus 구간1 전체 할부수수료 계산
    getInstallmentFee_lguplus_1 = () => {
        let installmentPaymentMethod    = this.props.WirelessAdvice.WirelessAdviceStep1.installmentPaymentMethod;
        let installmentFeeArray         = [];
        let installmentPrincipal        = this.getInstPrincipalUsedPhone_loguplus_1(); // 1구간 할부원금 계산
        let installmentPrincipal2       = this.getInstPrincipalUsedPhone_loguplus_2(); // 2구간 할부원금 계산
        let installmentMonth            = Number(this.state.programs.itemSelected.tup_inst_circle_mo); // 구간할부개월
        let installmentFee              = null;
        let installmentFee2             = null;

        installmentPrincipal  = this.replaceAll(installmentPrincipal, ",", "");
        installmentPrincipal  = this.replaceAll(installmentPrincipal, "원", "");
        installmentPrincipal  = Number(installmentPrincipal);

        installmentPrincipal2 = this.replaceAll(installmentPrincipal2, ",", "");
        installmentPrincipal2 = this.replaceAll(installmentPrincipal2, "원", "");
        installmentPrincipal2 = Number(installmentPrincipal2);

        if(installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
            installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(installmentPrincipal,  this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
        }

        if(installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
            installmentFeeArray = calcInstallmentFeePrincipalByMonth(installmentPrincipal, this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
        }

        installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

        if (installmentFee < 0) {
            installmentFee = 0
        }

        installmentFee = Math.round(installmentFee);

        if(installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3){
            installmentFee  = 0;
        }

        installmentFee2 = installmentPrincipal2 * this.props.WirelessAdvice.WirelessAdviceStep3.interestRate / 100 / 12 * installmentMonth;
        installmentFee  = installmentFee + installmentFee2; 
        installmentFee  = Math.round(installmentFee);

        return installmentFee;
    }

    getEstimatedAmountMonth_lguplus_1 = (applyUsedProgram, installmentMonthly, instType) => {
        let wirelessAdviceStep3 = {...this.props.WirelessAdvice.WirelessAdviceStep3};
        let WirelessAdviceStep1 = this.props.WirelessAdvice.WirelessAdviceStep1;

        wirelessAdviceStep3.applyUsedProgram = 0;

        if(instType === 81 || instType == 83){ // 일반할부 / 공시지원금, 구간할부 / 공시지원금
            wirelessAdviceStep3.optionFee = 1;
        }
        else{
            wirelessAdviceStep3.optionFee = 0;
        }

        let calResult            = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);
        let day                  = moment().date();
        let lastDay              = moment().endOf('month').date();
        let moneyRemainingMonth  = (( (calResult.monthlyFee + applyUsedProgram) / lastDay) * (lastDay - day + 1)) + (installmentMonthly);
        let estimatedAmountMonth = roundUpNumber(moneyRemainingMonth);

        return estimatedAmountMonth;
    }

    // lguplus 적용 버튼 클릭시 호출되는 메소드
    onApply_lguplus = () => {
        let programSelected     = this.state.programs.itemSelected;
        let {isSelectOptionFee} = this.state;
        let instType            = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

        if (programSelected.tup_id && isSelectOptionFee || !programSelected.tup_id ) {
            if (programSelected.tup_id) {
                if(instType == 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정
                    let instPrincipalUsedPhone1 = this.getInstPrincipalUsedPhone_loguplus_1(); // 1구간 할부원금
                    
                    instPrincipalUsedPhone1 = this.replaceAll(instPrincipalUsedPhone1, ",", "");
                    instPrincipalUsedPhone1 = this.replaceAll(instPrincipalUsedPhone1, "원", "");
                    instPrincipalUsedPhone1 = Number(instPrincipalUsedPhone1);

                    let instPrincipalUsedPhone2 = this.getInstPrincipalUsedPhone_loguplus_2(); // 2구간 할부원금

                    instPrincipalUsedPhone2 = this.replaceAll(instPrincipalUsedPhone2, ",", "");
                    instPrincipalUsedPhone2 = this.replaceAll(instPrincipalUsedPhone2, "원", "");
                    instPrincipalUsedPhone2 = Number(instPrincipalUsedPhone2);

                    let limit = instPrincipalUsedPhone2 / 65 * 35;

                    if(programSelected.tup_id != 191 && instPrincipalUsedPhone1 < limit){ // 갤럭시S23 중고폰가격보장프로그램 아닌 경우
                        if(instType == 83){ // 구간할부/공시지원금
                            alert("공시지원금 적용 후 1구간 금액이 기준금액(" + priceFormat(limit) + "원) 미만입니다.\n일반할부 또는 선택약정으로 가입하시기 바랍니다.\n다른 할인항목 금액을 수정해주세요");
                        }
                        else{ // 구간할부/선택약정
                            alert("선택약정 적용 후 1구간 금액이 기준금액(" + priceFormat(limit) + "원) 미만입니다.\n일반할부 또는 공시지원금으로 가입하시기 바랍니다.\n다른 할인항목 금액을 수정해주세요");
                        }
                        
                        return;
                    }
                }
            }

            this.props.toggle();
            let installmentType = programSelected?.vprogram_install_types && programSelected.vprogram_install_types.length > 0 ?
            this.state.installmentType.itemSelected : '';            
    
            programSelected?.tup_id && this.props.change('WirelessAdviceStep3.installmentMonth', this.state.installmentMonthSelect); 

            programSelected.inst_type = instType;

            this.props.change('WirelessAdviceStep3.optionFee', this.state.optionFee); // 1 = 추가지원금, 0 = 선택약정할인
            this.props.change('WirelessAdviceStep3.usedPhoneCompensation', programSelected); // 중고폰보상 프로그램
            this.props.change('WirelessAdviceStep3.installmentType', installmentType);
            this.props.change('WirelessAdviceStep3.usedPhoneOptionFee', this.state.optionFee);
            this.props.change('WirelessAdviceStep3.usedPhoneInstallmentMonth', this.state.installmentMonthSelect);
            

            if (programSelected.tup_id) {  
                if(programSelected.ets_id) {
                    this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', null);

                    setTimeout(() => {
                        let moreFee = this.getPayForMonthAfter().moreFee; 

                        this.props.change('WirelessAdviceStep3.applyUsedProgram',    moreFee);
                        this.props.change('WirelessAdviceStep3.usedPhoneMemberShip', this.state.membershipDiscounts.itemSelected);
                    });    
                }
                else{
                    let instMonthlyDefer_1 = this.getInstMonthlyDefer_1();
                    this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', instMonthlyDefer_1);

                    setTimeout(() => {
                       let result = this.updateCalculator(null, instMonthlyDefer_1);
                       this.props.change('WirelessAdviceStep3.installmentMonthly', result.installmentMonthly); 
                       this.props.change('WirelessAdviceStep3.amountMonthlyDevice', result.amountMonthlyDevice);
                       this.props.change('WirelessAdviceStep3.amountMonthlyTotal', result.amountMonthlyTotal);
                       this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
                   });
                }
            }
            else{
                this.props.change('WirelessAdviceStep3.instMonthlyDefer_1', null);

                setTimeout(() => {
                    let result = this.updateCalculator();

                    this.props.change('WirelessAdviceStep3.installmentMonthly', result.installmentMonthly); 
                    this.props.change('WirelessAdviceStep3.amountMonthlyDevice', result.amountMonthlyDevice);
                    this.props.change('WirelessAdviceStep3.amountMonthlyTotal', result.amountMonthlyTotal);
                    this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
                    this.props.change('WirelessAdviceStep3.installmentMonth', 24);
                });
            }
            this.props.change('WirelessAdviceStep3.requestForMonth', this.getPayForMonthAfter_lguplus_2()); // LG 2구간
        } 
        else {
            alert('할부금 유형을 선택해 주세요!');
        }
      
    }

    checkMoreSelectOptionFee = () => {
        var checkFlag = false;
        let programSelected = this.state.programs.itemSelected;

        // case: all nhà mạng (BTDT thường)
        if (programSelected.vprogram_types && programSelected.vprogram_types.length > 0) {
            let dataCheck = [...programSelected.vprogram_types];
            dataCheck.map(item=> {
                if (item.code_group == this.state.optionFee) {
                    return checkFlag = true;
                }
            })
            // debugger
            // checkFlag = true;
        } else if (programSelected.vprogram_install_types) {        // case: nhà mạng LGU (BTDT thường)
            let initData = [...programSelected.vprogram_install_types];
            let dataCheck = [];
            initData.forEach((item, index) => {
                let tmp = item.name.split('/');
                if (tmp[0] ==this.state.installmentType.itemSelected.typeName) {
                    dataCheck.push({
                        ...item,
                        'subTypeName': tmp[1]
                    })
                }
            });
            dataCheck.forEach((item, index) => {
                if (item.code_group == this.state.optionFee) {
                    return checkFlag = true;
                }
            })
        }
        return checkFlag
    }

    // Nguyên trả góp BTDT cũ
    getInstPrincipalUsedPhone = () => {
        let programSelected = this.state.programs.itemSelected;
        let instPrincipal   = 0;

        if (programSelected.tup_id) {
            let result = this.updateCalculator();

            if(+programSelected.tup_id != 192){
                instPrincipal = +result.installmentPrincipal / 2;    
            }
            else{
                instPrincipal = +result.installmentPrincipal;
            }
        }

        return priceFormat(instPrincipal) + "원" ;
    }

    // lguplus 1구간 할부원금 계산하는 메소드
    getInstPrincipalUsedPhone_loguplus_1 = () => {
        let programSelected        = this.state.programs.itemSelected;
        let tupId                  = programSelected.tup_id;
        let tupW2ndRate            = null;
        let tupInstCircleMo        = null;
        let instType               = null;
        let result                 = `0원`;
        let startingPrice          = null;
        let calResult              = null;
        let installmentPrincipal   = null;
        let installmentMonthSelect = null;

        if(tupId) {
            calResult            = this.updateCalculator();
            installmentPrincipal = parseFloat(calResult.installmentPrincipal); // 할부원금
            instType             = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

            if(instType === 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정
                startingPrice = Number(this.props.WirelessAdvice.WirelessAdviceStep3.startingPrice); // 출고가
                tupW2ndRate   = Number(programSelected.tup_w2nd_rate); // 2구간 반납 보정율
                result        = startingPrice * tupW2ndRate / 100;
                result        = installmentPrincipal - result;

                if(result < 0){
                    result = 0;
                }

                result = priceFormat(result) + "원";
            }
            else if(instType === 81 || instType == 82){ // 일반할부/공시지원금 || 일반할부/선택약정
                tupInstCircleMo        = Number(programSelected.tup_inst_circle_mo); // 구간할부개월
                installmentMonthSelect = Number(this.state.installmentMonthSelect); // 단말기 할부개월
                result                 = `${priceFormat(installmentPrincipal * tupInstCircleMo / installmentMonthSelect)}원`
            }
        }
        
        return result;
    }

    // lguplus 2구간 할부원금 계산하는 메소드
    getInstPrincipalUsedPhone_loguplus_2 = () => {
        let programSelected        = this.state.programs.itemSelected;
        let tupId                  = programSelected.tup_id;
        let tupW2ndRate            = null;
        let tupInstCircleMo        = null;
        let instType               = null;
        let result                 = `0원`;
        let startingPrice          = null;
        let calResult              = null;
        let installmentPrincipal   = null;
        let installmentMonthSelect = null;
        let additionalGrants       = null;
        let pointDiscount          = null;
        let advancedDiscountCard   = null;
        let other                  = null;
        let disclosureSubsidy      = null;
        let cashPayment            = null;
        let wirelessAdviceStep3    = this.props.WirelessAdvice.WirelessAdviceStep3;
        let optionFee              =  _.isNumber(this.state.optionFee) ? this.state.optionFee : wirelessAdviceStep3.optionFee;

        if(tupId) {
            instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

            if(instType === 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정
                startingPrice    = Number(wirelessAdviceStep3.startingPrice); // 출고가
                tupW2ndRate      = Number(programSelected.tup_w2nd_rate); // 2구간 반납 보정율
                result           = startingPrice * tupW2ndRate / 100;

                if(tupId == 191){ // 갤럭시S23 중고폰가격보장프로그램
                    additionalGrants     = +(wirelessAdviceStep3.additionalGrants || 0); // 추가지원금
                    pointDiscount        = +(wirelessAdviceStep3.pointDiscount || 0); // 포인트 할인
                    advancedDiscountCard = +(wirelessAdviceStep3.advancedDiscountCard || 0); // 선할인 카드
                    other                = +(wirelessAdviceStep3.other || 0); // (기타)
                    cashPayment          = +(wirelessAdviceStep3.cashPayment || 0); // 현금납부
                    result               = startingPrice - (additionalGrants + pointDiscount + advancedDiscountCard + other + cashPayment);

                    if(optionFee !== 0){ // 구간할부/공시지원금
                        disclosureSubsidy = wirelessAdviceStep3.disclosureSubsidy;
                        result            = result - disclosureSubsidy;
                    }
                }

                result = priceFormat(result) + "원";
            }
            else if(instType === 81 || instType == 82){ // 일반할부/공시지원금 || 일반할부/선택약정
                calResult              = this.updateCalculator();
                installmentPrincipal   = parseFloat(calResult.installmentPrincipal); // 할부원금
                tupInstCircleMo        = Number(programSelected.tup_inst_circle_mo); // 구간할부개월
                installmentMonthSelect = Number(this.state.installmentMonthSelect); // 단말기 할부개월
                result                 = `${priceFormat(installmentPrincipal * ((installmentMonthSelect - tupInstCircleMo) / installmentMonthSelect))}원`
            }
        }
        
        return result;
    }

    // Nguyên trả góp tạm hoãn LG đợt 1
    getInstPrincipalDefer_1 = () => {
        return `0원`
    }

    // Nguyên trả góp tạm hoãn LG đợt 2
    getInstPrincipalDefer_2 = () => {
        let programSelected = this.state.programs.itemSelected;
        if (programSelected.tup_id && !programSelected.ets_id) {
            let result = this.updateCalculator(24);
            return `${priceFormat(parseFloat(result.installmentPrincipal))}원`
        }
        return `0원`
    }



    // Trả góp hằng tháng BTDT cũ
    getInstMonthlyUsedPhone = () => {
        let programSelected = this.state.programs.itemSelected;
        if (programSelected?.tup_id) {
            let result = this.updateCalculator();
            return `${priceFormat(parseFloat(result.installmentMonthly))}원`
        }
        return `0원`
    }

    trim(target){
        var result = "";
        
        result = target.replace(/^\s+|\s+$/gm,'');
        
        return result;
    }

    replaceAll(value, str1, str2) {
        let tmpStr = this.trim(value);
    
        if ((tmpStr != "") && (str1 != str2)) {
            while (tmpStr.indexOf(str1) > -1) {
                tmpStr = tmpStr.replace(str1, str2);
            }
        }
    
        return  tmpStr;
    }

    // lguplus 구간1 월할부금 계산
    getInstMonthlyUsedPhone_lguplus_1= () => {
        let result                      = `0원`;
        let installmentPrincipal        = null;
        let installmentPrincipal2       = null;
        let installmentMonthly          = null;
        let installmentMonthlyPrincipal = null;
        let instType                    = null;
        let installmentMonth            = null;
        let installmentMonthlyFee       = null;
        let installmentMonthlyFee2      = null;
        let installmentFeeArray         = [];
        let installmentPaymentMethod    = this.props.WirelessAdvice.WirelessAdviceStep1.installmentPaymentMethod;
        let installmentFee              = null;
        let programSelected             = this.state.programs.itemSelected;
        let tupId                       = programSelected.tup_id;
        let roundd                      = null;
        let tupInstCircleMo             = null;
        let calResult                   = null;
        
        if(tupId) {
            instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

            if(instType === 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정       
                installmentPrincipal  = this.getInstPrincipalUsedPhone_loguplus_1(); // 1구간 할부원금 계산
                installmentPrincipal  = this.replaceAll(installmentPrincipal, ",", "");
                installmentPrincipal  = this.replaceAll(installmentPrincipal, "원", "");
                installmentPrincipal  = Number(installmentPrincipal);
                installmentPrincipal2 = this.getInstPrincipalUsedPhone_loguplus_2(); // 2구간 할부원금 계산
                installmentPrincipal2 = this.replaceAll(installmentPrincipal2, ",", "");
                installmentPrincipal2 = this.replaceAll(installmentPrincipal2, "원", "");
                installmentPrincipal2 = Number(installmentPrincipal2);
                tupInstCircleMo       = Number(programSelected.tup_inst_circle_mo); // 구간할부개월
                installmentMonth      = tupInstCircleMo; // 구간할부개월

                if(installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
                    installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(installmentPrincipal,  this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
                }

                if(installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
                    installmentFeeArray = calcInstallmentFeePrincipalByMonth(installmentPrincipal, this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
                }

                installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

                if (installmentFee < 0) {
                    installmentFee = 0
                }

                installmentFee = Math.round(installmentFee);

                if(installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3){
                    installmentFee  = 0;
                }

                installmentMonthlyFee       = Math.round(installmentFee / installmentMonth);
                installmentMonthlyFee2      = installmentPrincipal2 * this.props.WirelessAdvice.WirelessAdviceStep3.interestRate / 100 / 12 ;
                installmentMonthlyFee2      = Math.round(installmentMonthlyFee2);
                installmentMonthlyPrincipal = installmentPrincipal / installmentMonth;
                installmentMonthly          = installmentMonthlyPrincipal + installmentMonthlyFee + installmentMonthlyFee2;

                if (installmentMonthly < 0) {
                    installmentMonthly = 0
                }

                roundd = ((installmentMonthly));
                installmentMonthly = roundUpNumber(Math.ceil(roundd));
                
                if (this.props.WirelessAdvice.WirelessAdviceStep3?.instMonthlyDefer_1) {
                    installmentMonthly = this.props.WirelessAdvice.WirelessAdviceStep3.instMonthlyDefer_1;
                }

                if(tupId == 191){ // 갤럭시S23 중고폰가격보장프로그램
                    installmentMonthly = 0;
                }

                result = priceFormat(installmentMonthly) + "원";     
            }
            else if(instType === 81 || instType == 82){ // 일반할부/공시지원금 || 일반할부/선택약정
                calResult          = this.updateCalculator();
                installmentMonthly = parseFloat(calResult.installmentMonthly); // 월할부금
                result             = priceFormat(installmentMonthly) + "원";
            }
        }
        
        return result;
    }

    // lguplus 구간2 월할부금 계산
    getInstMonthlyUsedPhone_lguplus_2 = (isInterest = true) => {
        let result                      = `0원`;
        let installmentPrincipal        = null;
        let installmentMonthly          = null;
        let installmentMonthlyPrincipal = null;
        let instType                    = null;
        let installmentMonth            = null;
        let installmentMonthlyFee       = null;
        let installmentFeeArray         = [];
        let installmentPaymentMethod    = this.props.WirelessAdvice.WirelessAdviceStep1.installmentPaymentMethod;
        let installmentFee              = null;
        let programSelected             = this.state.programs.itemSelected;
        let tupId                       = programSelected.tup_id;
        let roundd                      = null;
        let tupInstCircleMo             = null;
        let calResult                   = null;
        
        if(tupId) {
            instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

            if(instType === 83 || instType == 84){ // 구간할부/공시지원금 || 구간할부/선택약정       
                installmentPrincipal = this.getInstPrincipalUsedPhone_loguplus_2(); // 할부원금 계산
                installmentPrincipal = this.replaceAll(installmentPrincipal, ",", "");
                installmentPrincipal = this.replaceAll(installmentPrincipal, "원", "");
                installmentPrincipal = Number(installmentPrincipal);
                installmentMonth     = Number(this.state.installmentMonthSelect); // 단말기 할부개월
                tupInstCircleMo      = Number(programSelected.tup_inst_circle_mo); // 구간할부개월
                installmentMonth     = installmentMonth - tupInstCircleMo; // 단말기 할부개월 - 구간할부개월

                if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principalAndInteres) {
                    installmentFeeArray = calcInstallmentFeePrincipalAndInterestByMonth(installmentPrincipal, this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
                }

                if (installmentPaymentMethod == INSTALLMENT_PAYMENT_METHOD.principal) {
                    installmentFeeArray = calcInstallmentFeePrincipalByMonth(installmentPrincipal, this.props.WirelessAdvice.WirelessAdviceStep3.interestRate, installmentMonth);
                }
            
                installmentFee = installmentFeeArray.reduce((a, b) => a + (b['loan_interest'] || 0), 0);

                if (installmentFee < 0) {
                    installmentFee = 0
                }

                installmentFee = Math.round(installmentFee)

                if(installmentMonth == 10 || installmentMonth == 9 || installmentMonth == 6 || installmentMonth == 3){
                    installmentFee = 0;
                }

                installmentMonthlyFee       = Math.round(installmentFee / installmentMonth);
                installmentMonthlyPrincipal = installmentPrincipal / installmentMonth;
                installmentMonthly          = installmentMonthlyPrincipal + installmentMonthlyFee;

                if (installmentMonthly < 0) {
                    installmentMonthly = 0
                }

                roundd = ((installmentMonthly));
                installmentMonthly = roundUpNumber(Math.ceil(roundd));

                if (this.props.WirelessAdvice.WirelessAdviceStep3?.instMonthlyDefer_1) {
                    installmentMonthly = this.props.WirelessAdvice.WirelessAdviceStep3.instMonthlyDefer_1;
                }

                result = priceFormat(installmentMonthly) + "원";

                if(tupId == 191 && isInterest == false){
                    result = priceFormat(installmentMonthlyPrincipal) + "원";
                }
            }
            else if(instType === 81 || instType == 82){ // 일반할부/공시지원금 || 일반할부/선택약정
                calResult          = this.updateCalculator();
                installmentMonthly = parseFloat(calResult.installmentMonthly); // 월할부금
                result             = priceFormat(installmentMonthly) + "원";
            }
        }
        
        return result;
    }

    // Trả góp hằng tháng đợt 1 tạm hoãn LG
    getInstMonthlyDefer_1 = () => {
        let programSelected = this.state.programs.itemSelected;
        let interestRate = this.props.WirelessAdvice?.WirelessAdviceStep3?.interestRate || 1;
        if (programSelected?.tup_id && !programSelected.ets_id) {
            let result = this.updateCalculator();
            let value =  roundUpNumber(parseFloat(result.installmentPrincipal) * (interestRate/100) / 12);
            return value
        }
        return 0
    }

    // Trả góp hằng tháng đợt 2 tạm hoãn LG
    getInstMonthlyDefer_2 = () => {
        let programSelected = this.state.programs.itemSelected;
        if (programSelected?.tup_id && !programSelected.ets_id) {
            let result = this.updateCalculator(24);
            let value = parseFloat(result.installmentMonthly)             //installmentMonthly: Tiền trả góp theo tháng
            return value
        }
        return 0
    }

    // Chi phí trả góp đợt 1 tạm hoãn LG
    getCostInst_1 = () => {
        let instDeferMonthly = this.getInstMonthlyDefer_1();
        let value = instDeferMonthly * 6;
        return `${priceFormat(value)}원`
    }

    // Chi phí trả góp đợt 2 tạm hoãn LG
    getCostInst_2 = () => {
        let programSelected = this.state.programs.itemSelected;
        if (programSelected?.tup_id && !programSelected.ets_id) {
            let result = this.updateCalculator(24);
            let value = parseFloat(result.installmentFee)
            return `${priceFormat(parseFloat(value))}원`
        }
        return `0원`;
    }


    // Tiền yêu cầu theo tháng (thanh toán hàng tháng) trước khi áp dụng
    getPayForMonthBefore = () => {
        if (this.state.programs.itemSelected.tup_id) {
            let result = this.updateCalculator();
            let requestForMonth = result.amountMonthlyDevice;
            return parseFloat(requestForMonth);
        }
        return 0;
    }

    // lguplus 프로그램 미적용시 금액 표시
    getPayForMonthBefore_lguplus = () => {
        let programSelected     = this.state.programs.itemSelected;
        let tupId               = programSelected.tup_id;
        let instType            = null;
        let WirelessAdviceStep1 = this.props.WirelessAdvice.WirelessAdviceStep1;
        let wirelessAdviceStep3 = {...this.props.WirelessAdvice.WirelessAdviceStep3};
        let calResult           = null;

        if(tupId) {
            instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;
            
            wirelessAdviceStep3.applyUsedProgram = 0;

            if(instType === 81 || instType == 83){ // 일반할부 / 공시지원금, 구간할부 / 공시지원금
                wirelessAdviceStep3.optionFee = 1;
            }
            else{
                wirelessAdviceStep3.optionFee = 0;
            }

            calResult = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);

            return parseFloat(calResult.amountMonthlyDevice);
        }

        return 0;
    }

    // Tiền yêu cầu theo tháng (thanh toán hàng tháng) sau khi áp dụng của UsedPhone
    getPayForMonthAfter = () => {
        let initTotal = this.getPayForMonthBefore();
        let finalTotal = initTotal;
        let moreFee = 0;

        if (this.state.programs?.itemSelected?.ets_price) {
            let priceFee = parseFloat(this.state.programs.itemSelected.ets_price);
                finalTotal += priceFee;
                moreFee    += priceFee;

            if (this.state.membershipDiscounts?.itemSelected?.value) {
                let {unit}  = this.state.membershipDiscounts.itemSelected;
                let {value} = this.state.membershipDiscounts.itemSelected;
                if (unit == 1) {
                    var discountMember = parseFloat(priceFee)*parseFloat(value)/100;
                } else {
                    var discountMember = parseFloat(value);
                }
                finalTotal -= discountMember;
                moreFee    -= discountMember;

                return {finalTotal, moreFee};
            } else {
                return {finalTotal, moreFee};
            }

        } else {
            return {finalTotal, moreFee};
        }
    }

    // 구간1 월별 청구금액
    getPayForMonthAfter_lguplus_1 = () => {
        let programSelected     = this.state.programs.itemSelected;
        let tupId               = programSelected.tup_id;
        let finalTotal          = this.getPayForMonthBefore();
        let moreFee             = 0;
        let instType            = null;
        let installmentMonthly  = null;
        let WirelessAdviceStep1 = this.props.WirelessAdvice.WirelessAdviceStep1;
        let wirelessAdviceStep3 = {...this.props.WirelessAdvice.WirelessAdviceStep3};
        let monthlyFee          = null;
        let priceFee            = null;
        
        if(programSelected?.ets_price) {
            priceFee = parseFloat(programSelected.ets_price);
            
            finalTotal += priceFee;
            moreFee    += priceFee;

            if(tupId){
                instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

                if(instType === 83 || instType == 84){
                    installmentMonthly = this.getInstMonthlyUsedPhone_lguplus_1(); // 월할부금
                    installmentMonthly = this.replaceAll(installmentMonthly, ",", "");
                    installmentMonthly = this.replaceAll(installmentMonthly, "원", "");
                    installmentMonthly = Number(installmentMonthly);

                    wirelessAdviceStep3.applyUsedProgram = 0;

                    if(instType === 83){ // 구간할부 / 공시지원금
                        wirelessAdviceStep3.optionFee = 1;
                    }
                    else if(instType == 84){ // 구간할부/선택약정
                        wirelessAdviceStep3.optionFee = 0;
                    }

                    wirelessAdviceStep3 = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);
                    monthlyFee          = wirelessAdviceStep3.monthlyFee;
                    finalTotal          = monthlyFee + installmentMonthly + priceFee;

                }
            }

            if (this.state.membershipDiscounts?.itemSelected?.value) {
                let {unit}  = this.state.membershipDiscounts.itemSelected;
                let {value} = this.state.membershipDiscounts.itemSelected;
                
                if (unit == 1) {
                    var discountMember = parseFloat(priceFee)*parseFloat(value)/100;
                }
                else {
                    var discountMember = parseFloat(value);
                }

                finalTotal -= discountMember;
                moreFee    -= discountMember;

                return {finalTotal, moreFee};
            }
            else {
                if(instType === 0){
                    return {finalTotal : 0, moreFee : 0};
                }
                else{
                    return {finalTotal, moreFee};
                }
            }
        }
        else {
            return {finalTotal, moreFee};
        }
    }

    // 구간2 월별 청구금액
    getPayForMonthAfter_lguplus_2 = () => {       
        let programSelected     = this.state.programs.itemSelected;
        let tupId               = programSelected.tup_id;
        let instType            = null;
        let WirelessAdviceStep1 = this.props.WirelessAdvice.WirelessAdviceStep1;
        let wirelessAdviceStep3 = {...this.props.WirelessAdvice.WirelessAdviceStep3};
        let monthlyFee          = null;
        let installmentMonthly  = null;
        let requestForMonth     = 0;

        if (tupId) {
            if(this.state.installmentMonthSelect > programSelected.tup_inst_circle_mo){
                instType = this.state.installmentSubType.itemSelected?.inst_type ? Number(this.state.installmentSubType.itemSelected.inst_type) : 0;

                if(instType === 83 || instType == 84){
                    installmentMonthly = this.getInstMonthlyUsedPhone_lguplus_2(true); // 월할부금
                    installmentMonthly = this.replaceAll(installmentMonthly, ",", "");
                    installmentMonthly = this.replaceAll(installmentMonthly, "원", "");
                    installmentMonthly = Number(installmentMonthly);

                    if(tupId == 191){
                        return parseFloat(installmentMonthly);
                    }

                    wirelessAdviceStep3.applyUsedProgram = 0;

                    if(instType === 83){ // 구간할부 / 공시지원금
                        wirelessAdviceStep3.optionFee = 1;
                    }
                    else if(instType == 84){ // 구간할부/선택약정
                        wirelessAdviceStep3.optionFee = 0;
                    }

                    wirelessAdviceStep3 = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);
                    monthlyFee          = wirelessAdviceStep3.monthlyFee;
                    requestForMonth     = monthlyFee + installmentMonthly;

                }
                else if(instType === 81 || instType == 82){ // 일반할부/공시지원금 || 일반할부/선택약정
                    requestForMonth = this.updateCalculator().amountMonthlyDevice;
                    
                }

                return parseFloat(requestForMonth);
            }
            else{
                return 0;
            }                

        }

        return 0;
    }

    // Tiền yêu cầu theo tháng (thanh toán hàng tháng) đợt 1 LG 
    getPayForMonthDefer_1 = () => {
        let instDeferMonthly_1 = this.getInstMonthlyDefer_1();
        let result = this.updateCalculator();
        let monthlyFee_B = result.monthlyFee;
        let total = instDeferMonthly_1 + monthlyFee_B;
        return total
    }

    // Tiền yêu cầu theo tháng (thanh toán hàng tháng) đợt 2 LG 
    getPayForMonthDefer_2= () => {
        let instDeferMonthly_2 = this.getInstMonthlyDefer_2();
        let result = this.updateCalculator();
        let monthlyFee_B = result.monthlyFee;
        let total = instDeferMonthly_2 + monthlyFee_B;
        return total
    }


    renderMoreInfo = () => {
        let dataRender = this.state.programs.itemSelected && this.state.programs.itemSelected.tup_info;
        if(dataRender != undefined) {
            return (<div dangerouslySetInnerHTML={{__html: dataRender}} />);
        }
    }

    renderTableRates = () => {
        if (this.state.programs.itemSelected && this.state.programs.itemSelected.tup_info) {
            return (
                <React.Fragment>
                    <span className="font-14"> ※보장률</span>
                    <TableInfo
                        programRates = {this.state.programs.itemSelected.program_rates}
                    />
                </React.Fragment>
            )
        
        }
    }

    renderTime2nd = () => {
        let programSelected = this.state.programs.itemSelected;
        let telcomID        = this.props.WirelessAdvice.WirelessAdviceStep1.telcomID;
        if (programSelected?.tup_id) {
            if (programSelected.ets_id) {   //là CTBT DT cũ bình thường
                if(telcomID === 18){
                    if(programSelected.tup_inst_circle_mo >= this.state.installmentMonthSelect){
                        return (`${Number(this.state.installmentMonthSelect) + 1} 개월  ~ `);
                    }
                    else{
                        return (`${Number(programSelected.tup_inst_circle_mo) + 1 }  ~ ${this.state.installmentMonthSelect}  개월`);
                    }
                }
                else{
                    return (`${programSelected.tup_inst_circle_mo}  ~ ${this.state.installmentMonthSelect}  개월`);
                }
            }
            else {    //là CT trả góp tạm hoãn LG
                return (`7 ~ ${this.state.installmentMonthSelect}  개월`);
            }
        }
    }


    render() {
        const { WirelessAdviceStep3, WirelessAdviceStep1 } = this.props.WirelessAdvice;
        const programSelected = this.state.programs.itemSelected;

        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                       // backdrop={'static'}
                    toggle={this.props.toggle}
                    className="modal-dialog-centered modal-used-phone"
                    contentClassName="pl-5 pr-5"
                    onOpened = {this.onOpened}
                >
                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            중고폰 보상 프로그램
                        </h5>
                        <button type="button" className="close close-modal" aria-label="Close" onClick={this.props.toggle} >
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body wireless-connection pt-1 pl-0 pr-0">
                        <form>
                            <div className="top">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <label className="label-title col-5 mb-0">프로그램 선택 </label>
                                            <div className="col-7">
                                                <button className="btn btn-input-form mb-0" type="button" onClick={() => this.showPopupPrograms()}>
                                                    { programSelected.tup_name}
                                                </button> 

                                                <ProgramPopup
                                                    isOpen = {this.state.isOpenProgram}
                                                    toggle = {this.onToggleProgram}
                                                >
                                                    {this.renderPrograms(this.state.programs.dataList)}
                                                </ProgramPopup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <label className="label-title text-right col-5 mb-0">멤버십 할인</label>
                                            <div className="col-7">
                                                <button className="btn btn-input-form mb-0" type="button"
                                                    onClick={() => this.showPopupMembership()}
                                                    disabled = {( (programSelected && programSelected.tup_id && programSelected.ets_id) ) ? '' : 'disabled' }
                                                >
                                                    { this.state.membershipDiscounts.itemSelected && this.state.membershipDiscounts.itemSelected.tcm_option_name}
                                                </button>

                                                <MembershipDiscountPopup
                                                    isOpen = {this.state.isOpenMembership}
                                                    toggle = {this.onToggleMembership}
                                                    telcom = {WirelessAdviceStep1.telcomID}
                                                >
                                                    {this.renderMembershipDiscount(this.state.membershipDiscounts.dataList)}
                                                </MembershipDiscountPopup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <article>
                                <h4 className="label-text mt-3"> 혜택 정보 </h4>
                                <div className="content-article">
                                    { this.renderMoreInfo()}
                                    
                                    {/* Nếu ko phải là trả góp tạm hoãn LG thì ẩn đi */}
                                    { programSelected?.ets_id &&this.renderTableRates()}
                                </div>
                                <table className="table table-mobile mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                할부금 구간
                                            </th>
                                            <th>
                                                <span className="color-sliver">1 구간 </span>
                                                {
                                                    programSelected.tup_inst_circle_mo && (
                                                        ` 1 ~  ${programSelected.tup_inst_circle_mo } 개월`
                                                    )
                                                }
                                            </th>
                                            <th className="bg-white">
                                                <span className="color-sliver"> 2 구간 </span>
                                                {   +programSelected.tup_id == 192 ?
                                                    "25~26개월"
                                                    :
                                                    this.renderTime2nd()
                                                }                                           
                                                <span className="small-color"> (미반납시 청구금액)</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="row align-items-center">
                                                    {/* Chọn hình thức trả góp: chỉ LGU mới có gồm: Trả góp từng phần VS Trả góp theo đợt */}
                                                    <label className="label-title col-5 mb-0">할부금유형</label>
                                                    <div className="col-7">
                                                        <button className="btn btn-input-form mb-0" type="button"
                                                            disabled = {programSelected.vprogram_install_types && programSelected.vprogram_install_types.length > 0 ? '' : 'disabled' }
                                                            onClick={() => this.showPopupInstallmentType()}>
                                                          { programSelected.tup_id && this.state.installmentType.itemSelected.typeName}
                                                        </button>
                                                        <InstallmentTypePopup
                                                            isOpen = {this.state.isOpenInstallmentType}
                                                            toggle = {this.onToggleInstallmentType}
                                                            telcom = {WirelessAdviceStep1.telcomID}
                                                        >
                                                            {this.renderInstallmentType(programSelected.vprogram_types, programSelected.vprogram_install_types)}
                                                        </InstallmentTypePopup>
                                                    </div>
                                                </div>
                                                <div className="row align-items-start mt-4">
                                                     {/* Chọn loại hình hổ trợ : HTTB or Camm kết lựa chọn */}
                                                    <label className="label-title col-5 mb-0">지원금 유형</label>
                                                    <div className="col-7">
                                                        <div className="row row_mt">
                                                            {this.renderInstallmentSubTypes(programSelected.vprogram_types, programSelected.vprogram_install_types, this.state.installmentType.itemSelected)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-3">
                                                    <label className="label-title col-5 mb-0">할부개월 수</label>
                                                    <div className="col-7">
                                                        <button className="btn btn-input-form mb-0" type="button"
                                                            disabled = {programSelected && programSelected.tup_id && programSelected.ets_id ? '' : 'disabled'}
                                                            onClick={() => this.showPopupInstallmentMonth()}>
                                                            {
                                                                programSelected.tup_id && this.state.installmentMonthSelect
                                                                ? `${this.state.installmentMonthSelect} 개월`
                                                                : `미적용`
                                                            }
                                                        </button>
                                                        <InstallmentMonthPopup
                                                            isOpen = {this.state.isOpenInstallmentMonth}
                                                            toggle = {this.onToggleInstallmentMonth}
                                                        >
                                                            {this.renderInstallmentMonth(programSelected.tup_inst_device_mo)}
                                                        </InstallmentMonthPopup>
                                                    </div>
                                                </div>
                                            </td>



                                            {/* check and render match CT BTDT or trả góp tạm hoãn LG  */}
                                            {
                                                !programSelected.tup_id || programSelected.ets_id ? (
                                                    //case :  CT BTDT cũ
                                                    <Fragment>
                                                          <td>
                                                            <div className="row align-items-center">
                                                                {/* Nguyên trả góp Usedphone*/}
                                                                <label className="label-title col-5 mb-0">할부 원금</label>
                                                                <div className="col-7">
                                                                    {
                                                                        WirelessAdviceStep1.telcomID !== 18 ?
                                                                        (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstPrincipalUsedPhone()} />)
                                                                        :
                                                                        (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstPrincipalUsedPhone_loguplus_1()} />)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mt-3">
                                                                {/* Tiền trả góp hằng tháng Usedphone*/}
                                                                <label className="label-title col-5 mb-0">월 할부금</label>
                                                                <div className="col-7">
                                                                    {
                                                                        WirelessAdviceStep1.telcomID !== 18 ?
                                                                        (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstMonthlyUsedPhone()}/>)
                                                                        :
                                                                        (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstMonthlyUsedPhone_lguplus_1()}/>)
                                                                    }
                                                                </div>
                                                            </div>                                                      

                                                            <div className="row align-items-center mt-3">
                                                                {/* Tiền yêu cầu theo tháng (thanh toán hàng tháng) Trước khi áp dụng  Usedphone*/}
                                                                <label className="label-title color-sliver col-5 mb-0 pr-0 fontw-nomarl">
                                                                    프로그램 미적용 시
                                                                </label>
                                                                <div className="col-7">
                                                                    <p className="color-sliver text-line pl-12">
                                                                        {
                                                                            WirelessAdviceStep1.telcomID !== 18 ?
                                                                                +programSelected.tup_id == 192 ?
                                                                                priceFormat(+(this.updateCalculator(null, null, true).amountMonthlyDevice))
                                                                                :    
                                                                                priceFormat(this.getPayForMonthBefore())
                                                                            :
                                                                            priceFormat(this.getPayForMonthBefore_lguplus())
                                                                        } 원
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-4">
                                                                {/* Tiền yêu cầu theo tháng (thanh toán hàng tháng) Sau khi áp dụng Usedphone*/}
                                                                <label className="label-title col-5 mb-0">월별 청구금액</label>
                                                                <div className="col-7">
                                                                    {/* 
                                                                    <p className="mb-0 color-reds text-bold pl-12">
                                                                        {
                                                                            WirelessAdviceStep1.telcomID !== 18 ?
                                                                            _.isNumber(this.getPayForMonthAfter().finalTotal) && priceFormat(this.getPayForMonthAfter().finalTotal)
                                                                            :
                                                                            priceFormat(this.getPayForMonthAfter_lguplus_1().finalTotal)
                                                                        }
                                                                        <span className="label-title">원</span>
                                                                    </p>
                                                                    */}
                                                                    {+(this.state.programs.itemSelected?.tup_id || 0) == 191
                                                                        ? (<span className="label-title">{priceFormat(roundUpNumber(+this.replaceAll(this.replaceAll(this.getInstPrincipalUsedPhone_loguplus_2(), ",", ""), "원", "") * 0.118 / 24)) + "원"}</span>)
                                                                        : WirelessAdviceStep1.telcomID !== 18
                                                                            ? (<Fragment>
                                                                                {_.isNumber(this.getPayForMonthAfter().finalTotal) && priceFormat(this.getPayForMonthAfter().finalTotal)}
                                                                                <span className="label-title">원</span>
                                                                            </Fragment>)
                                                                            : (<Fragment>
                                                                                {priceFormat(this.getPayForMonthAfter_lguplus_1().finalTotal)}
                                                                                <span className="label-title">원</span>
                                                                            </Fragment>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="row align-items-center">
                                                                {/* Nguyên trả góp Usedphone*/}
                                                                <label className="label-title col-5 mb-0">
                                                                    {
                                                                        +programSelected.tup_id == 192 ?
                                                                        "할부 유예금"
                                                                        :
                                                                        "할부 원금"
                                                                    }
                                                                    
                                                                </label>
                                                                <div className="col-7">
                                                                    {
                                                                        WirelessAdviceStep1.telcomID !== 18 ?
                                                                            +programSelected.tup_id == 192 ?
                                                                            (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={priceFormat(KT_S23_SBS_PRM_Y_EDT)} />)
                                                                            :
                                                                            (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstPrincipalUsedPhone()} />)
                                                                        :
                                                                        (<input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstPrincipalUsedPhone_loguplus_2()} />)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mt-3">
                                                                  {/* Tiền trả góp hằng tháng Usedphone*/}
                                                                <label className="label-title col-5 mb-0">
                                                                    {
                                                                        +programSelected.tup_id == 192 ?
                                                                        ""
                                                                        :
                                                                        "월 할부금"
                                                                    }
                                                                </label>
                                                                <div className="col-7">
                                                                    {
                                                                        +programSelected.tup_id == 192 ?
                                                                        ""
                                                                        :
                                                                            WirelessAdviceStep1.telcomID !== 18?
                                                                            (<input type="text" className="form-control w-165 h-36 color-distable" readOnly value={this.getInstMonthlyUsedPhone()}/>)
                                                                            :
                                                                            (<input type="text" className="form-control w-165 h-36 color-distable" readOnly value={this.getInstMonthlyUsedPhone_lguplus_2(false)}/>)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mt-40">
                                                                {
                                                                    +programSelected.tup_id == 192 ?
                                                                    <label class="label-title col-5 mb-0">※ 반납 시 0원 미반납 시 할부유예금<br/>&nbsp;&nbsp;&nbsp;&nbsp;30만원 즉납</label>
                                                                    :
                                                                    ""
                                                                }
                                                                {/* Tiền thanh toán hằng tháng Usedphone*/}
                                                                <label className="label-title col-5 pt-4 mb-0">
                                                                    {
                                                                        +programSelected.tup_id == 192 ?
                                                                        ""
                                                                        :
                                                                        "월별 청구금액"
                                                                    }
                                                                </label>
                                                                <div className="col-7">
                                                                    <p className="mb-0 color-reds pt-4 text-bold pl-12">
                                                                    {
                                                                        +programSelected.tup_id == 192 ?
                                                                        ""
                                                                        :
                                                                            WirelessAdviceStep1.telcomID !== 18 ?
                                                                            (priceFormat(this.getPayForMonthBefore()))
                                                                            :
                                                                            (priceFormat(this.getPayForMonthAfter_lguplus_2()))
                                                                    }
                                                                    <span className="label-title">
                                                                        {
                                                                            +programSelected.tup_id == 192 ?
                                                                            ""
                                                                            :
                                                                            "원"
                                                                        }
                                                                    </span> </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </Fragment>
                                                ) : (
                                                     //case :  CT tạm hoãn LG (Defer)
                                                    <Fragment>
                                                          <td>
                                                            <div className="row align-items-center">
                                                                {/* Nguyên trả góp đợt 1 LG */}
                                                                <label className="label-title col-5 mb-0">할부 원금</label>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getInstPrincipalDefer_1()} />
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-3">
                                                                {/* Chi phí trả đợt 1 LG */}
                                                                <label className="label-title col-5 mb-0">할부수수료</label>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control w-165 color-distable h-36" readOnly value={this.getCostInst_1()}/>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-3">
                                                                {/* Tiền trả góp hàng tháng LG*/}
                                                                <label className="label-title color-sliver col-5 mb-0 pr-0 fontw-nomarl">
                                                                    월 할부 수수료
                                                                </label>
                                                                <div className="col-7">
                                                                    <p className="color-sliver mb-0 pl-12"> {priceFormat(this.getInstMonthlyDefer_1())} 원 </p>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-4">
                                                                {/* Tiền yêu cầu theo tháng (thanh toán hàng tháng) Sau khi áp dụng LG */}
                                                                <label className="label-title col-5 mb-0">월별 청구금액</label>
                                                                <div className="col-7">
                                                                    <p className="mb-0 color-reds text-bold pl-12">
                                                                        { priceFormat(this.getPayForMonthDefer_1()) }
                                                                        <span className="label-title">원</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="row align-items-center">
                                                               {/* Nguyên trả góp đợt 2 LG */}
                                                                <label className="label-title col-5 mb-0">할부 원금</label>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control w-165 h-36 color-distable" readOnly value={this.getInstPrincipalDefer_2()} />
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-3">
                                                               {/* Chi phí trả đợt 2 LG */}
                                                                <label className="label-title col-5 mb-0">할부수수료</label>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control w-165 h-36 color-distable" readOnly value={this.getCostInst_2()}/>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-3">
                                                                {/* Tiền trả góp hàng tháng LG*/}
                                                                <label className="label-title color-sliver col-5 mb-0 pr-0 fontw-nomarl">
                                                                    월 할부 수수료
                                                                </label>
                                                                <div className="col-7">
                                                                    <p className="color-sliver pl-12 mb-0"> {priceFormat(this.getInstMonthlyDefer_2())} 원 </p>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mt-4">
                                                                {/* Tiền thanh toán hằng tháng LG*/}
                                                                <label className="label-title col-5 mb-0">월별 청구금액</label>
                                                                <div className="col-7">
                                                                    <p className="mb-0 color-reds text-bold pl-12">
                                                                        { priceFormat(this.getPayForMonthDefer_2()) }
                                                                        <span className="label-title">원</span> 
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </Fragment>
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </article>
                            <div className="modal-footer justify-content-center border-top-0">
                                <button type="button" className="btn btn-gray" onClick={this.props.toggle}>취소</button>
                                {/* <button type="button" className="btn btn-gray" onClick={this.props.onClose}>취소</button> */}
                           
                                {/* <button type="button"
                                    disabled = { ( (programSelected.tup_id && !this.state.isSelectOptionFee)
                                                    // || (this.state.programs.itemSelected.tup_id && !this.checkMoreSelectOptionFee())
                                                    ) ? 'disabled' : ''}
                                    className="btn btn-submit"
                                    onClick={() => this.onApply()} >적용
                                </button> */}
                                {
                                    WirelessAdviceStep1.telcomID !== 18 ?
                                    (<button type="button" className="btn btn-submit" onClick={() => this.onApply()} >적용</button>)
                                    :
                                    (<button type="button" className="btn btn-submit" onClick={() => this.onApply_lguplus()} >적용</button>)
                                }
                                
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        usedPrograms: state.dataStore.usedProgram,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => () => dispatch(change()),
        getListUsedPrograms: (data) => dispatch(getListUsedPrograms(data)),
        getListMembershipDiscounts: (data) => dispatch(getListMembershipDiscounts(data)),
        // applyUsedProgram: (data) => dispatch(applyUsedProgram(data)),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(UsedProgramMainPopup);
