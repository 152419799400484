import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Skype from "./Share/Skype";
import Kakao from "./Share/Kakao";

function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        
        navigator.clipboard.writeText(textToCopy);

        alert("클립보드에 복사 되었습니다");
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });

        alert("클립보드에 복사 되었습니다");
    }
}

class ShareWebModal extends React.Component {

  onClickEmail = () => {
    window.shareOnWeb('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body='+encodeURIComponent(this.props.sharelink))
    // window.shareOnWeb('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body='+encodeURI(this.props.sharelink)+'&ui=2&tf=1&pli=1')
  }

  onClickWhatApp = () => {
    window.shareOnWeb('https://web.whatsapp.com/send?text='+this.props.sharelink)
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpenModal}
        toggle={this.props.toggle}
        className={'modal-lg modal-dialog-centered modal-share-social'}
        onOpened={this.onOpened}
        onClosed={this.onClosed}

      >

          <div className="modal-header border-bottom-0">
            <div className="header_modal">
              <h5 className="modal-title">공유</h5>
            </div>
            <button
              type="button"
              className="close close-modal"
              onClick={this.props.toggle}
            >
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body">
            <div className="box_modal_sharing" id={'social-share'}>
              {/* 
              <Skype
                sharelink={this.props.sharelink}
              />
              */}
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/driver.svg" alt="" /></a>*/}
              {/*  <span>Google Drive</span>*/}
              {/*</div>*/}


              <div className="box_social" onClick={this.onClickEmail}>
                <a >
                <img src={process.env.REACT_APP_HOSTNAME + "/images/gmail.svg"} alt=""/>
                </a>
                <span>Gmail</span>
              </div>

{/* 
              <div className="box_social" onClick={this.onClickWhatApp}>
                <a>
                <img src={process.env.REACT_APP_HOSTNAME + "/images/whatapp.svg"} alt=""/>
                </a>
                <span>WhatsApp</span>
              </div>
*/}
             <Kakao
               sharelink={this.props.sharelink}
             />
            </div>
            <div className="box_modal_sharing">
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/bluetooth.svg" alt="" /></a>*/}
              {/*  <span>Bluetooth</span>*/}
              {/*</div>*/}
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/Evernote.svg" alt="" /></a>*/}
              {/*  <span>Evernote</span>*/}
              {/*</div>*/}
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/Hangout.svg" alt="" /></a>*/}
              {/*  <span>Hangout</span>*/}
              {/*</div>*/}
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/OneDrive.svg" alt="" /></a>*/}
              {/*  <span>OneDrive</span>*/}
              {/*</div>*/}
              {/*<div className="box_social">*/}
              {/*  <a ><img src="/images/more.svg" alt="" /></a>*/}
              {/*</div>*/}
              <div className="d-flex">
                <button style={{borderRadius: 0, height: 40, width: 400}} disabled className="btn btn-input-form mb-0">{this.props.sharelink}</button>
                <button style={{width: 140, borderRadius: 0, height: 40}} className="btn btn-submit pl-3 pr-3 no-wrap" onClick={() => {copyToClipboard(this.props.sharelink)}}>URL 복사</button>
              </div>
            </div>
           
          </div>

      </Modal>
    )
  }
}

export default ShareWebModal;
