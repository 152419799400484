import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import SignUpStep1Form from './SingUpComponent/SignUpStep1Form';
import SignUpStep2Form from './SingUpComponent/SignUpStep2Form';
import SignUpStep3Form from './SingUpComponent/SignUpStep3Form';
import MessageWarningModal from './SingUpComponent/MessageWarningModal';
import * as routerName from '../../utils/routerName';
import api from "./../../utils/api";
import { showModal, changeModalTitle, changeModalContent, hideModal } from '../../store/actions/modal';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import {
    getPricePackages
} from '../../store/actions/dataStore';
import { getFirstNumberPhone, getStoreFirstNumberPhone } from '../../utils/helper';
import MessageJSON from "../../utils/message.json";
import ContentRule from "./SingUpComponent/content-rules";
import DaumPostcode from 'react-daum-postcode';
import moment from "moment";
import * as constant from '../../utils/constant';

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            registration_number: {
                registration_verify_status: false,
                registration_verify_msg: '',
            },
            verify_phone: {
                verify_phone_status: false,
                verify_phone_msg: '',
            },
            verify_old_member: {
                verify_status: false,
                verify_msg_user_id: '',
                verify_msg_user_pw: '',
            },
            time: {},
            seconds: 300,
            modalMessage: false,
            typeInfo:'',
            titleInfo:'',
            descriptionInfo:'',
            oldMember: null,
            isLimitAccount: false,
        }
        this.timer = 0;
    }

    redirectLogin = () => {
        // debugger
        // return
        this.props.hideModal();
        this.props.history.push({ pathname: '/' + routerName.SIGN_IN });
    }

    handleComplete = (data) => {
        let fullAddress = data.address;
        let sto_addr_code = data.zonecode;
        let buildingName = data.buildingName;
        this.props.change('sto_addr_code', sto_addr_code)
        this.props.change('sto_addr_line1', fullAddress)
        this.props.change('sto_addr_line2', buildingName)
        let extraAddress = '';
        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        this.props.hideModal()
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer = () => {
        this.setState({
            seconds: 300
        }, () => {
            this.timer = setInterval(this.countDown, 1000);
        })
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    componentDidMount() {
        // let data = {
        //     per_page: 10000
        // }
        // this.props.getPricePackages(data).then(res => {
        // }).catch((e) => {
        // })
    }


    setPage = (page) => {
        if (this.props.SignUp.form1 == true && this.props.SignUp.form2 == true) {
            this.setState({ page: page })
        } else if (this.props.SignUp.form1 == true && (page == 1 || page == 2)) {
            this.setState({ page: page })
        }
    }

    popupShowMessageSuccess = () => {
        const { oldMember, isLimitAccount } = this.state
        const { SignUp } = this.props
        let start = moment(new Date()).format('YYYY-MM-DD')
        let end = moment(new Date()).add(1, 'M').format('YYYY-MM-DD')
        let month = 1
        if (oldMember?.payment_status === 'LIVE') {
            if (oldMember.pay_type === 'UNLIMIT') {
                end = moment(oldMember.live_end_date).format('YYYY-MM-DD')
            } else {
                let days = 30
                if (
                    moment('2021-09-01').isBefore(moment(oldMember.live_start_date))
                    && moment('2021-09-30').isAfter(moment(oldMember.live_start_date))
                ) {
                    days = 90;
                    month = 3
                } else {
                    days = 30;
                    month = 1
                }
                end = moment(new Date()).add(days, 'd').format('YYYY-MM-DD')
            }
        }
        // // popup notice main
        // let xhtml = (
        //     <div className="content-register-modal modal-signup-complete">
        //         <div className="img-check">
        //             <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
        //         </div>
        //         <h4 className="text-center">
        //             회원가입이 완료되었습니다!
        //         </h4>
        //         <p className="text-center">
        //             한 달 무료 체험이 제공되었습니다! <br/>
        //             판매상담 No.1 모비셀과 함께하세요.
        //         </p>
        //         <p className="text-center">
        //             무료 체험 기간: <strong>{moment(new Date()).format('YYYY-MM-DD')} ~ {moment(new Date()).add(1, 'M').format('YYYY-MM-DD')}</strong> 까지
        //         </p>
        //         <button type="button" className="btn btn-secondary btn-ok"  onClick={() => this.redirectLogin()}>로그인</button>
        //     </div>
          
        // );


        // // popup notice BETA
        let xhtml = !isLimitAccount ? (
            <div className="content-register-modal modal-signup-complete">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    회원가입이 완료되었습니다!
                </h4>
                <p className="text-center">
                    ID: {SignUp.username}
                </p>
                <p className="text-center">
                    한 달 무료 체험 기간이 제공 되었습니다.<br/>
                    로그인 후 서비스를 이용해주세요!
                </p>
                <p className="text-center">
                    무료 체험 기간: <strong>{start} ~ {end}</strong> 까지
                </p>
                <button type="button" className="btn btn-secondary btn-ok"  onClick={() => this.redirectLogin()}>로그인</button>
            </div>
        ) : (
            <div className="content-register-modal modal-signup-complete">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    회원가입이 완료되었습니다!
                </h4>
                <p className="text-center">
                    ID: {SignUp.username}
                </p>
                <p className="text-center">
                    로그인 후 서비스를 이용해주세요!
                </p>
                <button type="button" className="btn btn-secondary btn-ok"  onClick={() => this.redirectLogin()}>로그인</button>
            </div>
        )

        if (oldMember?.payment_status === 'LIVE' && oldMember?.pay_type === 'UNLIMIT') {
            xhtml = <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    회원 가입이 완료되었습니다
                </h4>
                <p className="text-center">
                    모비고 정액제 이용기간 동안 무료 이용 가능합니다.
                </p>
                <p className="text-center">
                    판매상담 NO.1 모비셀과 함께 하세요.
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.redirectLogin}>
                    확인
                </button>
            </div>
        }

        if (oldMember?.payment_status === 'LIVE' && oldMember?.pay_type === 'LIMIT') {
            xhtml = <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    회원가인이 완료되었습니다!
                </h4>
                <p className="text-center">
                    ID: {oldMember.user_id}
                </p>
                <p className="text-center mb-0">
                    고객님께서는 모비고 유료 이용자로
                </p>
                <p className="text-center mb-0">
                    무료체험기간이 총 {month} 개월 증정 되었습니다.
                </p>
                <p className="text-center">
                    로그인 후 서비스를 이용해주세요!
                </p>
                <p className="text-center">
                    무료체험기간: <strong>{start} ~ {end}</strong> 까지
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.redirectLogin}>
                    확인
                </button>
            </div>
        }

        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered modal-signup-success'
        }

        this.props.showModal(dataModal);
    }

    popupShowPaymentMonthsMessageSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    X 개월 선결제가 완료되었습니다.
                </h4>
                <p className="text-center">
                    모비셀 사용요금은 선불이며, 서비스 이용기간 종료 후 결제가 이루어지지 않은 경우 프로그램 사용이 정지됩니다.
                </p>
                <button type="button" className="btn btn-secondary btn-ok"  onClick={() => this.redirectLogin()}>로그인</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }


    popupVerifyPhoneShowMessageSuccess = () => {
        let data = {
            phone: this.props.SignUp.first_user_phone + '-' + this.props.SignUp.last_user_phone
        }
        if(process.env.REACT_APP_BLOCK_FEATURE !== 'true' && this.props.SignUp.business_type == '0'){
            api.auth.createVerifySMS(data)
        }

        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    {MessageJSON.signup.title_check_phone}
                </h4>
                <p className="text-center" dangerouslySetInnerHTML={{__html:MessageJSON.signup.verify_phone_check_phone}}>
                </p>
                {/* xác nhận */}
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.confirmPhone()}>확인</button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n:'modal_notifi_custom',
        }

        this.props.showModal(dataModal);
    }

    confirmPhone = () => {
        this.onChangeVerifyPhoneClearCountDown();
        this.startTimer();
        this.props.hideModal()
    }


    popupShowServiceRules = (data) => {
        let xhtml = (
            <Fragment>
                <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title">정보 및 약관</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.hideModal}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className=" content-rules mt-1 content-product modal-mobile-insurance " >
                        <ContentRule 
                        data={data}/>
                    </div>
                </div>

            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-rules-custom'
        }

        this.props.showModal(dataModal);
    }

    getListPricePackages = (ppg_id) => {
        let data = {
            ppg_id: ppg_id
        }
        this.props.getPricePackages(data).then(res => {
            if (res.price_packages.data.length > 0) {
                this.props.change('package_id', res.price_packages.data[0].pp_id)
            } else {
                this.props.change('package_id', '')
            }
        }).catch((e) => {
        })
    }



    onSubmit = (data) => {
        console.log(123123123)
        if (this.state.page == 2 && this.props.SignUp.member_type == constant.MEMBER_TYPE.OLD_MEMBER && this.props.SignUp.business_type != constant.BUSINESS_ORTHER) {
            if (!(this.state.registration_number.registration_verify_status == true && (this.props.SignUp.business_type == '1'))) {
                this.setState({
                    registration_number: {
                        registration_verify_msg: MessageJSON.signup.registration_business_check_submit,
                        registration_verify_status: false
                    }
                })

                return;
            }
        }
        if (this.state.page == 2 && this.props.SignUp.member_type == constant.MEMBER_TYPE.NEW_MEMBER && (data.user_id || data.user_pw) && !this.state.verify_old_member.verify_status) {
            this.setState({
                verify_old_member: {
                    verify_status: false,
                    verify_msg_user_id: '모비고 아이디 인증하기를 진행해주세요',
                    verify_msg_user_pw: '',
                }
            })

            return;
        }
        
        let dataRequest = {
            sto_business_type: data.business_type,
            sto_business_name: data.business_name,
            sto_business_email: data.business_email || data.business_email1,
            sto_business_no: data.registration_number[0] ? data.registration_number.toString().replace(/,/g, '-') : data.registration_number1.toString().replace(/,/g, '-'),
            username: data.username,
            password: data.password,
            sto_name: data.store_name,
            sto_addr_line1: data.sto_addr_line1,
            sto_addr_line2: data.sto_addr_line2,
            sto_addr_code: data.sto_addr_code,
            sto_phone_number: data.first_store_phone + '-' + data.last_store_phone,
            sto_mobile_number: data.first_store_phone + '-' + data.last_store_phone,
            user_phone_number: data.first_user_phone + '-' + data.last_user_phone,
            sto_phone_code: data.store_phone_code,
            email: data.email,
            name: data.name,
            group_type: data.group_type,
            group_code: data.group_code,
            old_member: this.state.oldMember,
            user_id: data.user_id,
            user_pw: data.user_pw,
            // card_number: data.card_number,
            // sto_bank_account: data.card_stock_name,
            // card_stock_number: data.card_stock_number,
            // company_card_id: data.company_card_id,
            // organization_type: data.organization_type,
            // packageID: data.package_id,
            // useAlarm: data.useAlarm,
            // package_type: data.package_type,
            // payment_method: data.payment_method,
            // security_number:data.security_number,
            // expiration_date:data.month + '/' + data.year  
        }
        if(data.business_type == '0'){
            dataRequest.sto_business_no = ''
        }
        // if(data.payment_method == '0') {
        //     dataRequest.useMonth = '1'
        // } else {
        //     dataRequest.useMonth = data.useMonth
        // }
        // dataRequest.payment_status = true;

        return api.auth.register(dataRequest)
            .then(response => {
                if (response.status == 200) {
                    this.popupShowMessageSuccess();
                }
            }).catch(error => {
                let title = {
                    sto_business_no_exist: "이미 가입된 사업자번호입니다",
                    phone_exist: "이미 가입된 휴대폰번호입니다",
                    username_exist: "이미 사용중인 아이디입니다"
                }
                let message = {
                    sto_business_no_exist: `모비셀에 10개 이상 가입되어 있는 사업자번호입니다.<br>
                        자세한 내용은 채팅상담으로 문의해주세요.`,
                    phone_exist: `모비셀에 10개 이상 가입되어 있는 판매자 연락처입니다.<br>
                        자세한 내용은 채팅상담으로 문의해주세요.`,
                    username_exist: `모비셀에서 이미 사용중인 아이디입니다.<br>
                        다른 아이디를 입력해주세요.`
                }
                if (title[error.response.data.mess]) {
                    this.setState({
                        typeInfo : error.response.data.mess,
                        titleInfo: title[error.response.data.mess],
                        descriptionInfo: message[error.response.data.mess]
                    })
                    this.openModalWarning()
                } else {
                    if (error.response.data?.errors?.username[0]) {
                        throw new SubmissionError({'username': error.response.data?.errors?.username[0]})
                    }
                }
            });
    }

    onClickShowMap = () => {
        let xhtml = (
            <div className="content-register-modal">
                <DaumPostcode
                    onComplete={this.handleComplete}
                    {...this.props}
                />
                {/* hủy bỏ */}
                <div className="d-flex justify-content-center">
                    <button type="button" className="btn btn-secondary " data-dismiss="modal" onClick={() => this.props.hideModal()}>취소</button>
                </div>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);

    }

    popupShowCheckOldMemberSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    인증 완료되었습니다!
                </h4>
                <p className="text-center">
                    자동으로 표시되는 회원정보가 정확한지 확인 후 
                </p>
                <p className="text-center">
                    가입을 계속 진행해주세요
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.onNextStepOldMember}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            backdrop: 'static',
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable'
        }

        this.props.showModal(dataModal);
    }

    popupShowCheckOldMemberFail = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    아이디 또는 패스워드를 확인해주세요
                </h4>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    popupShowOldMemberExpired = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center mb-3">
                    모비고 계정이 휴면 상태입니다.
                </h4>
                <p className="text-center">
                    휴면 해제 후 다시 시도해주세요
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    popupShowOldMemberExist30 = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    이미 모비셀에 가입된 아이디입니다
                </h4>
                <p className="text-center">
                    입력하신 모비고 ID는 이미 모비셀에 등록되어 있습니다
                </p>
                <p className="text-center">
                    다른 ID를 입력해주세요
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    popupShowOldMemberBlock = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    본사 요청으로 서비스가 일시 중단되었습니다
                </h4>
                <p className="text-center">
                    모비고 서비스 사용을 원하시는 경우
                </p>
                <p className="text-center">
                    본사 (IM 영업팀)으로 연락해주시기 바랍니다
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    popupShowCreateNewMemberFrom20 = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    회원 가입이 완료되었습니다
                </h4>
                <p className="text-center">
                    모비고 정액제 이용기간 동안 무료 이용 가능합니다.
                </p>
                <p className="text-center">
                    판매상담 NO.1 모비셀과 함께 하세요.
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.onNextStepOldMember}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    checkOldMember = () => {
        const { user_id, user_pw } = this.props.SignUp

        api.auth.checkOldMember({user_id, user_pw})
            .then(response => {
                console.log(response)
                this.setState({oldMember: response?.data})
                // if (response.data.payment_status === 'LIVE') {
                //     this.popupShowCreateNewMemberFrom20()
                // } else {
                    this.popupShowCheckOldMemberSuccess()
                // }
            })
            .catch(error => {
                error = error.response
                if (error.status === 422 && error.data.user) {
                    if (error.data.user === 'exist') {
                        this.popupShowOldMemberExist30()
                    } else if (error.data.user === 'block') {
                        this.popupShowOldMemberBlock()
                    }
                } else if (error.status === 400 && error.data.resultCode == 1002) {
                    this.popupShowOldMemberExpired()
                } else {
                    this.popupShowCheckOldMemberFail()
                }
            })
    }

    onNextStepOldMember = () => {
        const { SignUp } = this.props
        const { oldMember } = this.state

        if (!oldMember) {
            let error = {
                user_id: '모비고 인증을 완료해주세요',
                user_pw: '모비고 인증을 완료해주세요'
            }
            throw new SubmissionError(error)
            return
        }

        this.props.hideModal()

        let registration_number1 = [];
        let sto_business_no = (oldMember.agent_no || '').split('-').join('')
        registration_number1.push(sto_business_no.slice(0, 3))
        registration_number1.push(sto_business_no.slice(3, 5))
        registration_number1.push(sto_business_no.slice(5))

        let agent_type = +['A', 'B'].includes(oldMember.agent_type)
        this.props.change('business_type', agent_type)
        this.props.change('business_name', oldMember.agent_name)
        this.props.change('store_name', oldMember.agent_name)
        this.props.change('username', oldMember.user_id)
        this.props.change('name', oldMember.emp_name)
        this.props.change('first_user_phone', oldMember.mobile.slice(0, 3))
        this.props.change('last_user_phone', oldMember.mobile.slice(4))
        this.props.change('sto_addr_code', oldMember.zipcode.split('-').join(''))
        this.props.change('sto_addr_line1', oldMember.address)
        this.props.change('sto_addr_line2', oldMember.address_detail)
        this.props.change('email', oldMember.email)
        this.props.change('group_type', oldMember.group_type)
        this.props.change('group_code', oldMember.group_code)
        this.props.change('registration_number1', registration_number1);
        if (oldMember.tax_email) {
            this.props.change('business_email1', oldMember.tax_email)
        }

        if (agent_type) {
            this.setState({
                registration_number: {
                    ...this.state.registration_number,
                    registration_verify_status: true,
                },
            }, () => {
                this.nextPage()
            })
        } else {
            setTimeout(() => {
                this.nextPage()
            }, 10)
        }
    }

    renderSignUpComponent() {
        let xhtml = null;
        if (this.state.page == 1) {
            xhtml = <SignUpStep1Form
                history={this.props.history}
                onSubmit={this.props.SignUp?.member_type == constant.MEMBER_TYPE.OLD_MEMBER ? this.checkOldMember : this.nextPage}
                redirectLogin={this.redirectLogin}
                onClickVerifyBussiness={this.onClickVerifyBussiness}
                registration_number={this.state.registration_number}
                onChangeVerifyRegistration={this.onChangeVerifyRegistration}
                onChangeBussinessTypeNull={this.onChangeBussinessTypeNull}
                checkOldMember={this.checkOldMember}
                onNextStepOldMember={this.onNextStepOldMember}
            />;

        } else if (this.state.page == 2) {
            xhtml = <SignUpStep2Form
                history={this.props.history}
                props={this.props}
                previousPage={this.previousPage}
                redirectLogin={this.redirectLogin}
                onClickCreateVerifySMS={this.onClickCreateVerifySMS}
                onClickComfirmVerifySMS={this.onClickComfirmVerifySMS}
                onSubmit={this.onSubmit}
                verify_phone={this.state.verify_phone}
                onChangeVerifyPhoneClearCountDown={this.onChangeVerifyPhoneClearCountDown}
                onChangeVerifyPhoneCode={this.onChangeVerifyPhoneCode}
                popupShowServiceRules={this.popupShowServiceRules}
                onClickShowMap={this.onClickShowMap}
                time={this.state.time}
                oldMember={this.state.oldMember}
                registration_number={this.state.registration_number}
                onClickVerifyBussiness={this.onClickVerifyBussiness}
                onChangeVerifyRegistration={this.onChangeVerifyRegistration}
                onChangeBussinessTypeNull={this.onChangeBussinessTypeNull}
                verify_old_member={this.state.verify_old_member}
                onChangeVerifyOldMember={this.onChangeVerifyOldMember}
                onClickVerifyOldMember={this.onClickVerifyOldMember}
                seconds={this.state.seconds} />;
        } else if (this.state.page == 3) {
            xhtml = <SignUpStep3Form
                history={this.props.history}
                onSubmit={this.onSubmit}
                // pricePackages={this.props.pricePackages}
                // priceExtraService={this.props.priceExtraService}
                // priceGroups={this.props.priceGroups}
                redirectLogin={this.redirectLogin}
                getListPricePackages={this.getListPricePackages} />;
        }

        return xhtml;
    }

    nextPage = () => {
        if (this.state.page == 1) {
            if (this.state.registration_number.registration_verify_status == true && (this.props.SignUp.business_type == '1')) {
                this.setState({ page: this.state.page + 1 }, () => {
                    if (this.state.page == 2) {
                        this.props.change('form1', true);
                    }
                })
            } else if (this.props.SignUp.business_type == '0') {
                this.setState({ page: this.state.page + 1 }, () => {
                    if (this.state.page == 2) {
                        this.props.change('form1', true);
                    }
                })
            } else {
                this.setState({
                    registration_number: {
                        registration_verify_msg: MessageJSON.signup.registration_business_check_submit,
                        registration_verify_status: false
                    }
                })
            }
        } else if (this.state.page == 2) {
            if (this.state.verify_phone.verify_phone_status == true) {
                this.setState({ page: this.state.page + 1 }, () => {
                    if (this.state.page == 2) {
                        this.props.change('form1', true);
                    }
                })
            } else {
                this.setState({
                    verify_phone: {
                        verify_phone_msg: MessageJSON.signup.verify_phone_check_submit,
                        verify_phone_status: false
                    }
                })
            }
        } else {
            this.setState({ page: this.state.page + 1 }, () => {
                if (this.state.page == 2) {
                    this.props.change('form1', true);
                } else if (this.state.page == 3) {
                    this.props.change('form2', true);
                }

            })
        }

    }

    previousPage = () => {
        this.setState({ page: this.state.page - 1 })
    }


    onChangeBussinessTypeNull = () => {
        this.setState({
            registration_number: {
                registration_verify_status: false,
                registration_verify_msg: '',
            }
        })
        setTimeout(() => {
            if (this.props.SignUp.business_type == '0') {
                this.props.change('business_name', '');
                this.props.change('business_email', '');
                this.props.change('registration_number[0]', '');
                this.props.change('registration_number[1]', '');
                this.props.change('registration_number[2]', '');
            }
        })
    }

    checkInfoRegister = (data,type = null) => {
        let dataInfo={
            type:type ? type : 'business',
            data:data
        }
        this.setState({
            typeInfo:type ? type : 'business',
            titleInfo:type ? '이미 가입된 판매자 연락처입니다.' : '이미 가입된 사업자번호입니다.',
            descriptionInfo:type ? '중복 가입 시에는 무료체험기간이 제공되지 않습니다.<br/> 계속 진행하시겠습니까?' :
                '중복 가입 시에는 무료체험기간이 제공되지 않습니다.<br/>계속 진행하시겠습니까?'
        })
        if(this.props.SignUp.business_type == '1' && type != 'phone'){
            return api.auth.verifyInfoRegister(dataInfo).then(res => {
                this.props.hideLoading()
                if(res.data.statusResult) {
                    this.openModalWarning()
                    this.setState({
                        isLimitAccount: true,
                    })
                } else {
                    this.setState({
                        isLimitAccount: false,
                    })
                }
            }).catch(err => {
                this.props.hideLoading()
            })
        }else {
            if(this.props.SignUp.business_type != '1' && type == 'phone'){
                return api.auth.verifyInfoRegister(dataInfo).then(res => {
                    this.props.hideLoading()
                    if(res.data.statusResult) {
                        this.openModalWarning()
                        this.setState({
                            isLimitAccount: true,
                        })
                    }else {
                        if(type && type == 'phone') {
                            this.popupVerifyPhoneShowMessageSuccess();
                        }
                        this.setState({
                            isLimitAccount: false,
                        })
                    }
                }).catch(err => {
                })
            }else {
                this.props.hideLoading()
                this.popupVerifyPhoneShowMessageSuccess();
            }
        }


    }

    onClickVerifyBussiness = () => {
        if (this.props.SignUp.business_type != '0') {
            let data = {
                register_business_code: this.state.page == 1 ? this.props.SignUp.registration_number.toString().replace(/,/g, '-') : this.props.SignUp.registration_number1.toString().replace(/,/g, '-')
            }
            if (data.register_business_code.length == 12) {
                this.props.showLoading()
                return api.auth.verifyBussiness(data).then(res => {
                    // if (res.data.status == 101) {
                    //     this.setState({
                    //         registration_number: {
                    //             registration_verify_msg: MessageJSON.signup.registration_business_code_unique,
                    //             registration_verify_status: false
                    //         }
                    //     }, () => {
                    //         this.props.hideLoading()
                    //     })
                    // } else if (res.data.status == 1) {
                    //     this.setState({
                    //         registration_number: {
                    //             registration_verify_msg: MessageJSON.signup.registration_business_code_success,
                    //             registration_verify_status: true
                    //         }

                    //     }, () => {
                    //         this.props.hideLoading()
                    //     })
                    // }
                    this.setState({
                        registration_number: {
                            registration_verify_msg: MessageJSON.signup.registration_business_code_success,
                            registration_verify_status: true
                        }

                    }, () => {
                        this.props.hideLoading()
                        if (this.props.SignUp.member_type == constant.MEMBER_TYPE.NEW_MEMBER) {
                            if (process.env.REACT_APP_BLOCK_FEATURE !== 'true') {
                                this.checkInfoRegister(data.register_business_code) //tạm ẩn trong quá trình beta, kIểm tra mã kinh doanh có bị trùng lặp và hiện cảnh báo
                            }
                        }
                    })
                }).catch(err => {
                    this.props.hideLoading();

                    let check = this.ckBisNo(data.register_business_code);

                    this.setState({
                        registration_number: {
                            registration_verify_msg: check ? MessageJSON.signup.registration_business_code_success : MessageJSON.signup.registration_business_code_fail,
                            registration_verify_status: check
                        }

                    }, () => {
                        if (check && this.props.SignUp.member_type == constant.MEMBER_TYPE.NEW_MEMBER) {
                            if (process.env.REACT_APP_BLOCK_FEATURE !== 'true') {
                                this.checkInfoRegister(data.register_business_code) //tạm ẩn trong quá trình beta, kIểm tra mã kinh doanh có bị trùng lặp và hiện cảnh báo
                            }
                        }
                    });

                    /*
                    if (err.response.data.status == 0) {
                        this.setState({
                            registration_number: {
                                registration_verify_msg: MessageJSON.signup.registration_business_code_fail,
                                registration_verify_status: false
                            }
                        })
                        this.props.change('form1', false);
                    }
                    */
                })
            } else {
                this.setState({
                    registration_number: {
                        registration_verify_msg: MessageJSON.signup.validate.registration_number_field_required,
                        registration_verify_status: false
                    }
                })
            }
        }
    }

    ckBisNo = (bisNo) => {
        // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
        if ((bisNo = (bisNo+'').match(/\d{1}/g)).length != 10) {
            return false;
        }
        
        // 합 / 체크키
        var sum = 0, key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

        // 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에더합니다.
        for (var i = 0 ; i < 9 ; i++) {
            sum += (key[i] * Number(bisNo[i]));
        }

        // 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
        // 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.

        // 체크섬구함
        var chkSum = Math.floor(key[8] * Number(bisNo[8]) / 10);

        // 체크섬 합계에 더해줌
        sum +=chkSum;

        var reminder = (10 - (sum % 10)) % 10;

        //값 비교

        if(reminder==Number(bisNo[9])){
            return true;
        }
        else{
            return false;
        }
    }

    onClickCreateVerifySMS = () => {
        let data = {
            phone: this.props.SignUp.first_user_phone + '-' + this.props.SignUp.last_user_phone
        }

        if (this.props.asyncValidating !== false || (this.props.SignUpAsyncErrors && !!this.props.SignUpAsyncErrors.last_user_phone)) {
            // check không được bấm

        } else {
            if (data.phone.length >= 12) {
                this.props.showLoading();
                if (process.env.REACT_APP_BLOCK_FEATURE !== 'true') {
                    this.checkInfoRegister(data.phone,'phone') // //tạm ẩn trong quá trình beta, kIểm tra sdt có bị trùng lặp và hiện cảnh báo
                } else {
                    return api.auth.createVerifySMS(data).then(res => {
                        this.props.hideLoading();

                        // this.onChangeVerifyPhoneClearCountDown();
                        // this.startTimer();
                        // this.props.hideLoading();
                        if (process.env.REACT_APP_BLOCK_FEATURE === 'true') {
                            this.popupVerifyPhoneShowMessageSuccess(); // tạm dùng trong quá trình chạy beta, hết beta bỏ đi
                        }

                    }).catch(err => {
                        this.props.hideLoading();
                        if (process.env.REACT_APP_BLOCK_FEATURE !== 'true') {
                            this.popupVerifyPhoneShowMessageSuccess(); // check lỗi hệ thống y như find password
                        }
                    })
                }
            }
        }

    }

    onClickComfirmVerifySMS = () => {
        let data = {
            phone: this.props.SignUp.first_user_phone + '-' + this.props.SignUp.last_user_phone,
            code: this.props.SignUp.store_phone_code
        }
        if (data.phone.length >= 12 && data.code.length == 6) {
            this.props.showLoading();
            api.auth.comfirmVerifySMS(data).then(res => {
                this.props.hideLoading();
                this.setState({
                    verify_phone: {
                        verify_phone_msg: MessageJSON.signup.verify_phone_code_success,
                        verify_phone_status: true
                    }
                })
            }).catch(err => {
                this.setState({
                    verify_phone: {
                        verify_phone_msg: MessageJSON.signup.verify_phone_code_fail,
                        verify_phone_status: false
                    }
                })
                this.props.hideLoading();
            })
        } else {
            this.setState({
                verify_phone: {
                    verify_phone_msg: MessageJSON.signup.verify_phone_check_format,
                    verify_phone_status: false
                }
            })
        }
    }


    onChangeVerifyRegistration = () => {
        this.setState({
            registration_number: {
                registration_verify_status: false
            }
        })
    }

    onChangeVerifyPhoneClearCountDown = () => {
        this.setState({
            verify_phone: {
                verify_phone_status: false
            },
            seconds: 0,
            time: this.secondsToTime(0),
        }, () => {
            clearInterval(this.timer);
        })

    }

    onChangeVerifyPhoneCode = () => {
        this.setState({
            verify_phone: {
                verify_phone_status: false
            },
        })

    }

    onChangeVerifyOldMember = (e) => {
        const { SignUp } = this.props
        let value = e.target.value
        let name = e.target.name

        this.setState({
            verify_old_member: {
                ...this.state.verify_old_member,
                verify_status: false,
                ['verify_msg_' + name]: '',
            }
        })
    }

    onClickVerifyOldMember = () => {
        const { user_id, user_pw } = this.props.SignUp

        if (!user_id || !user_pw) {
            this.setState({
                verify_old_member: {
                    verify_status: false,
                    verify_msg_user_id: !user_id ? '사용 중인 모비고 아이디를 입력해주세요' : '',
                    verify_msg_user_pw: !user_pw ? '사용 중인 모비고 비밀번호를 입력해주세요' : '',
                }
            })
            return;
        }

        return api.auth.checkOldMember({user_id, user_pw, except: true})
            .then(response => {
                console.log(response)
                this.setState({
                    verify_old_member: {
                        verify_status: true,
                        verify_msg_user_id: '모비고 아이디 확인되었습니다',
                        verify_msg_user_pw: '',
                    }
                })
                return
            })
            .catch(error => {
                error = error.response
                console.log(error)
                if (error.resultCode === 1002) {
                    this.setState({
                        verify_old_member: {
                            verify_status: false,
                            verify_msg_user_id: '휴면 상태인 모비고 아이디입니다',
                            verify_msg_user_pw: '',
                        }
                    })
                } else {
                    this.setState({
                        verify_old_member: {
                            verify_status: false,
                            verify_msg_user_id: '입력하신 모비고 아이디를 확인해주세요',
                            verify_msg_user_pw: '',
                        }
                    })
                }
            })
    }

    openModalWarning = (phone = null) => {
        const state=this.state.modalMessage
        this.setState({
            modalMessage: !state,
        })
        if(phone && phone == 'phone') {
            // this.onChangeVerifyPhoneClearCountDown();
            // this.startTimer();
            this.popupVerifyPhoneShowMessageSuccess();
        }
    }

    redireactFindID= () => {
        this.props.history.push({ pathname: '/' + routerName.FORGOT_ID });
    }

    render() {
        return (
            <Fragment>
                <section className="status-register">
                    <div className="container">
                        <ul className="list-status">
                            <li className={(this.state.page == 1) ? " item in-process" : "" + (this.state.page) > 1 ? "item in-process item-finish" : "item"} onClick={() => this.setPage(1)}>
                                <div className="top">
                                    <span className="border-status"></span>
                                    <span className="number">1</span>
                                    <span className="border-status"></span>
                                </div>
                                <p>사업자 인증</p>
                            </li>
                            <li className={(this.state.page == 2) ? " item in-process" : "" + (this.state.page) > 2 ? "item in-process item-finish" : "item"} onClick={() => this.setPage(2)}>
                                <div className="top" >
                                    <span className="border-status"></span>
                                    <span className="number">2</span>
                                    <span className="border-status"></span>
                                </div>
                                <p>계정정보</p>
                            </li>
                            {/* <li className={(this.state.page == 3) ? " item in-process" : "" + (this.state.page) > 3 ? "item in-process item-finish" : "item"} onClick={() => this.setPage(3)}>
                                <div className="top">
                                    <span className="border-status"></span>
                                    <span className="number">3</span>
                                    <span className="border-status"></span>
                                </div>
                                <p>서비스 요금제 선택 및 완성</p>
                            </li> */}
                        </ul>
                    </div>
                </section>
                {this.renderSignUpComponent()}
                <MessageWarningModal
                    isOpenModal={this.state.modalMessage}
                    redireactFindID={this.redireactFindID}
                    openModalWarning={this.openModalWarning}
                    description={this.state.descriptionInfo}
                    title={this.state.titleInfo}
                    typeInfo={this.state.typeInfo}
                />
            </Fragment >

        );
    }
}

const mapStateToProps = state => {
    let initValues;
    // if (state.dataStore.pricePackages.data.length > 0) {
    //     initValues = {
    //         ...state.form.SignUp.initial,
    //         'package_id': state.dataStore.pricePackages.data[0].pp_id,
    //     };
    // }
    // if (state.dataStore.priceGroups.data.length > 0) {
    //     initValues = {
    //         ...state.form.SignUp.initial,
    //         'package_type': state.dataStore.priceGroups.data[0].ppg_id,
    //     };
    // }

    return {
        SignUp: getFormValues('SignUp')(state),
        pricePackages: state.dataStore.pricePackages,
        priceGroups: state.dataStore.priceGroups,
        priceExtraService: state.dataStore.priceExtraService,
        initialValues: initValues,
        SignUpErrors: getFormSyncErrors('SignUp')(state),
        SignUpAsyncErrors: getFormAsyncErrors('SignUp')(state),

    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        getPricePackages: (data) => dispatch(getPricePackages(data)),
    };
};

const FORM_NAME = 'SignUp';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        'business_type': '1',
        'business_email': '',
        'registration_number': [],
        'business_name': '',
        'username': '',
        'name': '',
        'password': '',
        'repassword': '',
        'store_name': '',
        'sto_addr_line1': '',
        'sto_addr_line2': '',
        'sto_addr_code': '',
        'first_store_phone': getStoreFirstNumberPhone()[0],
        'last_store_phone': '',
        'first_user_phone': getFirstNumberPhone()[0],
        'last_user_phone': '',
        'store_phone_code': '',
        'first_fax_number': getFirstNumberPhone()[0],
        'last_fax_number': '',
        'email': '',
        'rulesChecked': false,
        'rulesOption1': false,
        'rulesOption2': false,
        // 'package_type': '',
        // 'package_id': '1',
        // 'payment_method': '1',
        // 'payment_state': '',
        // 'organization_type': '1',
        // 'company_card_id': '1',
        // 'month': '1',
        // 'year': '2001',
        // 'card_number': '',
        // 'date_payment': '',
        // new
        // 'useMonth': '12',
        // 'email_payment': '',
        // 'name_payment': '',
        // 'first_payment_phone': '',
        // 'last_payment_phone': '',
        'useAlarm': false,
        'form1': false,
        'form2': false,
        'member_type': '1',
        // 'user_id': 'testes3',
        // 'user_pw': '123456',
        'user_id': '',
        'user_pw': '',
        'registration_number1': [],
        'business_email1': '',
        // 'group_type': '',
        // 'group_code': '',
        // 'form3': false
    },
    asyncBlurFields: ['email', 'username'],
    asyncChangeFields: ['first_store_phone', 'last_store_phone'],
    enableReinitialize: true
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default compose(
    withConnect,
    withReduxForm,
)(SignUp);

