import {
    SUBSCRIPTIONFEE
} from '../actions';

import api from "../../utils/api"; 

export const getSubscriptionFeeSuccess = (data) => {
    return {
        type: SUBSCRIPTIONFEE.GET_SUBSCRIPTIONFEE_SUCCESS,
        data: data
    }
};

export const getSubscriptionFees = (data) => dispatch => {
    return api.subscription_fee.getSubscriptionFee(data)
        .then(res => {
            dispatch(getSubscriptionFeeSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};