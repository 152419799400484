 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {getSims} from './../../../store/actions/sim';
// import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import SimModal from "./SimModal";
import {showLoading, hideLoading} from '../../../store/actions/ui';
class WirelessSim extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: {},
      hide: false
    }
  }

  componentDidMount() {
      const { WirelessAdvice } = this.props;
      this.props.showLoading()
      this.props.getSims(WirelessAdvice.WirelessAdviceStep1.telcomID).then(res => {
          this.setState({
            result : res,
            hide: res.data.length == 0 ? true:false
          })
          this.props.hideLoading();
      })
      .catch(() => {
          this.props.hideLoading();
      })
  }


  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let item=WirelessAdvice.WirelessAdviceStep3.sim
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
    return (
      <div className="form-group">
        <label className="col-form-label label-text">유심</label>
        <button disabled={this.state.hide} className={`btn btn-input-form mb-0 ${item ? '' : ' opacity-disabled'}`} type="button" data-toggle="modal" data-target="#modal-30 " >
        {(item ? formatter.format(item.price).replace('$','')+ "원" : '미적용')}
        </button>
        <p className="text-right mb-0 small-color">{item?item.name_sim : ''}</p>
        <SimModal dataSims={this.state.result} itemSim={item?item:null}  />
      </div>
    );
  }
}


WirelessSim.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func
};
const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
    return {
        getSims: (id) => dispatch(getSims(id)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
  };
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  
  export default compose(
    withConnect,
    // withReduxForm,
  )(WirelessSim);
