export const OPTION_FEE = {
  optionalAgreement: 0, // cam kết lựa chọn (선택약정)
  disclosureSubsidy: 1, // tiền hỗ trợ thông báo (공시지원금)
}

export const INSTALLMENT_PAYMENT_METHOD = {
  principalAndInteres: 0,
  principal: 1
}

export const OPTION_CHOOSE_2G = {
  code_limit:["SM-G160N", "SM-J250", "SM-J737S", "LM-Y110S", "LGM-X100S", "LGM-K121S", "SM-A205S", "SM-A102", "LM-X420N", "LM-220N","SM-G160N_32GB","SM-J250N","LM-Y110","SM-J737","LM-X410",
      "SM-A205","SM-A102N","LM-X220N","SM-G160N_32G","LM-X410S","LM-X220N_32G","LM-X420N_32G","SM-A217N","LM-Y120N","LM-Q310N","SM-G525N","LM-Q520N","SM-A315N","SM-A325N","SM-G160N_2021","SM-A125N"],// LM-Q510N 제외, SM-G525N 추가 (업무번호 1895)
  code_limit_except:["LM-Q520N","SM-A315N","SM-A325N"], // LM-Q510N 제외 (업무번호 1895)
  //code_limit_LGU:["SM-A217N", "SM-A102N","SM-G160N32","SM-J330L","SM-J415N","SM-A102N-KA","SM-J415N-KA","SM-G160N","LM-Y120L","LM-X420N","LM-Y110L","LM-Q310N"],
  code_limit_LGU:[
    "SM-A325N",   "SM-A217N",    "LM-Y120L",    "LM-X420N",     "SM-A102N",
    "SM-G160N32", "SM-J330L",    "SM-J415N",    "SM-A102N-KA",  "LM-Y110L",
    "LM-Q310N",   "SM-J415N-KA", "SM-G160N",    "SM-G160N2021", "SM-A102N-KF4",
    "SM-A125N",   "SM-G525N",    "SM-G525N-UK", "SM-A235N"
  ],
  price_optionalAgreement_HTTB:10000,
  price_optionalAgreement_HTTB_not_limit:300000,
  percent_optionalAgreement:0.7,
};

export const POTENTIAL_CUSTOMER = {
    code_phone:["010", "011", "016","017","018","018","019"]
};

export const TELCOM_ID = {
    SKT: 17,
    LGU: 18,
    KT:19,
}

export const CONSULTANT_WIRELESS = {
  saler: null,

  phone: {}, // THIẾT BỊ ĐẦU CUỐI (단말기)
  startingPrice: null, // Giá khởi điểm (출고가)
  packageFee: null, // gói phí (요금제)
  planData: null, // gói phí (요금제)
  newPlanData: null, // gói phí mới (요금제)
  namePlanNewDateChange: null,
  basicCost: null, // chi phí cơ bản (기본료)
  basicCostCalc: null, // chi phí để tính toán (기본료)

  optionFee: OPTION_FEE.disclosureSubsidy, // 0: giảm giá cam kết lựa chọn (선택약정할인) | 1: tiền hỗ trợ thêm (추가지원금)
  disclosureSubsidy: null, // tiền hỗ trợ thông báo (공시지원금)
  displayApplyDate: null, // ngày tham gia (기준)
  disclosureSubsidyDefault: null,
  disclosureSubsidyDifference: null,
  disclosureSubsidyDifferenceDefault: null,
  promotionDuration: null,
  promotionDurationDiscountOne: null,
  promotionDurationDiscountTwo: null,
  additionalGrants: null, // tiền hỗ trợ thêm (추가지원금)
  additionalGrantsType: null,
  optionalAgreement: null, // cam kết lựa chọn (선택약정)
  optionalAgreementDefault: null,
  contractMonth: null, // số tháng cam kết (약정개월)
  optionalAgreementWith2G: null,
  pointDiscount: null, // giảm giá point (포인트 할인)
  advancedDiscountCardID : null,
  advancedDiscountCard: null, // thẻ giảm giá trước (선할인 카드)
  advancedDiscountCardOption: null,
  usedPhoneCompensation: null, // bồi thường điện thoại cũ (중고폰보상 프로그램)
  applyUsedProgram: null, // Tiền yêu cầu theo tháng sau khi apply bồi thường DT cũ
  installmentType: null, // Loại hình tiền hổ trợ (chỉ LGU mới có)
  usedPhoneMemberShip: null,
  usedPhoneOptionFee: null,
  usedPhoneInstallmentMonth: null,

  cashPayment: null, // nạp tiền mặt (현금납부)
  cashPaymentId: 0, // nạp tiền mặt (현금납부)
  other: null, // khác (기타할인)
  installmentMonth: 24, // số tháng trả góp (할부개월)

  rateAgreement: null, // cam kết gói phí (요금약정)
  rateAgreementDefault: null,
  rateAgreementEstimate: null,
  discost12: null, // cam kết gói phí (요금약정)
  discost24: null, // cam kết gói phí (요금약정)
  discostChange: 12, // cam kết gói phí (요금약정)
  promotion: {}, // promotion (프로모션)
  combinedDiscount: null, // giảm giá kết hợp (결합 할인)
  welfareDiscount: null, // giảm giá phúc lợi (복지 할인)
  discountCard: null, // thẻ giảm giá (할인카드)
  discountCardId: null, // thẻ giảm giá (할인카드)
  mutualService: null, // dịch vụ tương trợ (상조서비스)
  conversion2G: null, // chuyển đổi 2G (2G전환)


  sim: null, // SIM (유심)
  subscriptionFee: null, // Phí đăng ký (가입비)
  insurrance: null, // Bảo hiểm thiết bị (휴대폰 보험)
  insurranceCancelDate: null, // Ngày có thể hủy (휴대폰 보험)

  extraServices: [], // Dịch vụ bổ sung
    cancel_date: [], //Ngay  Dịch vụ bổ sung

  // tinh tien
  interestRate: 5.9, // tiền lãi trên 1 năm
  // block A
  installmentPrincipal: 0, // Nguyên giá trả góp (할부원금)
  installmentFee: 0, // Tổng lãi suất trả góp (할부수수료)
  installmentMonthly : 0, // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
  installmentMonthlyPrincipal : 0, // Tiền trả góp thiết bị theo tháng Nguyên giá trả góp
  installmentMonthlyFee : 0, // Tiền trả góp thiết bị theo tháng lãi suất trả góp
  // block B
  monthlyFee: 0, // chi phi theo thang (월 요금)
  // block A + B
  moneyRemainingMonth: 0, // Số tiền dự kiến tháng này (요금)
  estimatedAmountMonth: 0, // Số tiền dự kiến tháng này (당월 예상 금액)
  amountMonthlyDevice: 0, // Tiền yêu cầu theo tháng A + B (월별 청구금액)
  // block C
  amountMonthlyOption: 0, // Tiền yêu cầu theo tháng C (월별 청구금액)
  // clock A + B + C
  amountMonthlyTotal: 0, // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
  status: false,
  planNewDateChange: 0,  // giá gói phí muốn đổi (요금제)
  dataPlanNewDateChange: null,  // gói phí muốn đổi (요금제)
  estimatedRatesChangingPlans: 0, // chi phí dự kiến khi thay đổi gói phí (요금제 변경시 예상요금 ),
  optionalAgreementPlanEstimated: 0,
  note: '',
  planChangeDate:'' , // 변경 가능일자
  customerId: null,
  customerNote: '',
  stateLoadPotential: false,
  telcomPotentialCustomer: null,
  reportCreated: 0, // trạng thá báo cáo đã tạo hay chưa
  combineCondition: null, // điều kiện kết hợp
  combineDevice: null, // kết hợp thiết bị có dây
  combineOption: null, // kết hợp option thiết bị có dây
  combinePlans: [], // kết hợp gói phí
  combinePlansSring: '', // kết hợp gói phí
  discountFullPlan: '', //Giảm gía cam kết y nguyên theo gói LTE cuả LGU+
  // monthFee: '',


  advancedDiscountCardMonth : '', // luu tháng discount card
  isRateAgreement: false
}

export const NTF_TYPE_DOCUMENT = '2'; // type messgae notification document
export const NTF_TYPE_NOTICE = '1';// type messgae notification notice
export const NTF_TYPE_COUPONS = '0';// type messgae notification notice

export const BUSINESS_ORTHER = '0';// type messgae notification notice

export const CLASSIFY = {
  INTERNET: 1,
  TV: 2,
  PHONE: 3,
  IOT: 4,
}

export const WD_CLASS = {
  0: 90,
  1: 91,
  2: 92,
  3: 101,
}

export const LGU_PLUS_ID = 18
export const ADVICE_TYPE = {
  WIRE: "wire-advice",
  WIRELESS: "wireless-advice",
}

export const LIST_2G = [
  "미적용",
  '적용',
  '무약정',
]

export const CONSULTANT_WIRED = {
  status:false,
  saler: null,
  note: '',
  customerId: null,
  customerNote: '',
  bookmark:null,
  monthlyCosts: 0,
  discountCard: {},
  discountCardPrice: 0,
  totalCombinedDiscount: 0,
  classifyString: '',
  classify: [
    {
      id: 1,
      name: '인터넷',
      icon: '/tsc_template/dist/images/globe.svg',
      classify: []
    },
    {
      id: 2,
      name: 'TV',
      icon: '/images/tivi.svg',
      classify: []
    },
    {
      id: 3,
      name: '전화',
      icon: '/images/phone.svg',
      classify: []
    },
    {
      id: 4,
      name: 'IoT',
      icon: '/images/home.svg',
      classify: []
    },
  ],
  combineCondition: null, // điều kiện kết hợp
  combineDevice: null, // kết hợp thiết bị có dây
  combineOption: null, // kết hợp option thiết bị có dây
  combinePlans: [], // kết hợp gói phí
  combinePlansSring: '', // kết hợp gói phí
  optionDiscount: '', // kết hợp gói phí
  setupFee: [], // Phí lắp đặt
  setupFeeString: '', // Phí lắp đặt
  totalAfterDiscount: 0, // Tổng tiền thiết bị đã chọn
  penalty12: 0, // Tiền phạt 12 tháng
  penalty24: 0, // tiền phạt 18 tháng
}

export const DEFAULT_CLASSIFY = {
  device: {},
  options: [],
  cost: {
    value: 0
  },
  discount: {
    device: {
      name: '',
      value: 0,
      additional: 0,
    },
    options: [],
    value: 0,
    combineDiscount: 0,
  },
}

export const COMPARE_TYPE = {
  DEVICE: '1',
  CONSULTANT: '2'
}

export const PAYMENT = {
  METHOD_PRE_PAYMENT: '1', //Lựa chọn Thanh toán trước
  METHOD_MONTHLY_PAYMENT: '0', //Lựa chọn Thanh toán hàng thắng
  OPTION_FERIODICALLY_MONTHLY_PAYMENT: '1', //Lựa chọn giảm giá thanh toán tháng đầu tiên
  OPTION_FERIODICALLY_PAYMENT_NOW: '2', //Lựa chọn giả giá cho tính tiền hiện tại
  OPTION_FERIODICALLY_DEFAUTH: '0', //Không lựa chọn giảm giá,
  METHOD_SELECTED_COUPOND: '0', //Chọn cách nhập từ có sẵn,
  METHOD_ENTER_COUPOND: '1', //Chọn cách nhập từ có sẵn
  CHECKED_ALARM: '1', //Chọn cách nhập từ có sẵn
  CHECKED_REPORT: '1', //Chọn cách nhập từ có sẵn
}

export const ACCOUNT = {
  ACCOUNT_SUCCESS: '0', //Lựa chọn Thanh toán trước
  ERROR_STATUS_EXPIRES_DATE: '1', //Lựa chọn Thanh toán trước
}

export const FORM_BUILDER_TYPE = {
  BUILDER_BLANK: 1,
  BUILDER_WIRELESS: 2,
  BUILDER_WIRED: 3,
  QUOTATION_WIRELESS: 4,
  QUOTATION_WIRED: 5,
  ELECTRONIC_WIRELESS: 6,
  ELECTRONIC_WIRED: 7,
}

export const MEMBER_TYPE = {
  NEW_MEMBER: 1,
  OLD_MEMBER: 0,
}

export const KT_S23_SBS_INS_GRC_VAL = 423000; // KT S23 FE 폰 구독 할부 유예금
export const KT_S23_SBS_PRM_Y_EDT   = 300000; // KT S23 프리미엄 Y에디션