import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import {priceFormat} from "../../../../utils/helper";
import _ from "lodash";

class NanumDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            selected: false,
            line:[],
            discount:[]
        };
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }

    onCloseModal = () => {}

    onOpened = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});
        let discountArr         = [...this.props.selected];

        plans_line_not_null.map((plans) => {
            var index = _.findIndex(discountArr, {idx: plans.idx});

            if(index < 0){
                discountArr.push({idx : plans.idx, value :0});
            }
        });
        
        this.setState({
            selected: this.props.selected,
            line: plans_line_not_null,
            discount: discountArr
        });
    }

    onApply = (e) => {
        let discountArr   = [...this.state.discount];
        let discountTotal = 0;
        let limit         = 0;
        let tempInt       = 0;

        if(this.state.selected){
            for(tempInt = 0; tempInt < discountArr.length; tempInt++){
                let info = discountArr[tempInt];

                if(info.value < 1000){
                    alert('최소 1,000원부터 입력이 가능합니다.');
                    
                    return false;
                }
    
                if((info.value % 1000) != 0 ){
                    alert('1,000원 단위로 적용 가능합니다.');
                    
                    return false;
                }
    
                discountTotal = discountTotal + Number(info.value);
            }
    
            if(this.state.line.length == 2){
                limit = 20000;
            }
            else if(this.state.line.length == 3){
                limit = 42000;
            }
            else if(this.state.line.length == 4){
                limit = 80000;
            }
            else if(this.state.line.length == 5){
                limit = 100000;
            }
    
            if(discountTotal < limit){
                alert('적용가능한 무선 할인 금액이 남아있습니다.');
    
                return false;
            }
            else if(discountTotal > limit){
                alert('적용가능한 무선 할인 금액을 초과하였습니다.');
                
                return false;
            }

            this.props.onApply(this.state.discount, "nanum_discount");
            this.toggleModal(e);
        }
        else{
            this.props.onApply([], "nanum_discount")
            this.toggleModal(e)
        }
    }

    cancelApply = (e) => {
        this.setState({
            selected: this.props.selected
        }, () => {
            this.toggleModal(e)
        })
    }

    onChange = (e, value) => {
        let targetValue = this.numberCheck(e);
        let discountArr = [...this.state.discount];
        let index       = _.findIndex(discountArr, {idx: value.idx});

        discountArr.splice(index, 1, {idx : value.idx, value : targetValue});

        this.setState({
            discount: discountArr
        })
    }

    numberCheck = (v) => {
        let num = v.target.value || 0

        if(!isFinite(num)) {
            v.target.value = ''
        }
        else{
            num = num.toString()

            if(num !== '0' && !num.includes('.')){
                num = num.replace(/^0+/, '')
            }
    
            v.target.value = num
        }

        return v.target.value
    }

    render() {
        return(
            <Fragment>
                <div className="form-group mt-5">
                    <label className="text-name text-wrap text-bold">나눔할인</label>
                    <button
                        className="btn btn-input-form h-32 mb-0"
                        type="button"
                        disabled={_.reject(this.props.plans, {plan: null}).length > 0 ? false : true}
                        onClick={(e) => this.toggleModal(e)}
                    >
{
    this.props.selected?.length || 0 ? "적용" : "미적용"
}
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered NanumDiscount-modal"
                           contentClassName="pr-5 pl-5"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">나눔할인</h5>
                            <button type="button" className="close close-modal" onClick={(e) => this.toggleModal(e)}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body pr-0 pl-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="top-content h-44">
                                        <h5 className="mb-0">1. 가입자 구분</h5>
                                    </div>
                                    <ul className="list-item mt-0 max-auto">
                                        <li
                                            className={!this.state.selected ? "bg-item" : "" }
                                            onClick={() => this.setState({selected: false})}
                                        >
                                            <p>미적용</p>
                                        </li>
                                        <li
                                            className={this.state.selected ? "bg-item" : "" }
                                            onClick={() => this.setState({selected: true})}
                                        >
                                            <p>
                                                적용
                                                <br/>
{
    this.state.line.length == 1 ? '' :
    this.state.line.length == 2 ? '무선 20,000원 할인' : 
    this.state.line.length == 3 ? '무선 42,000원 할인' : 
    this.state.line.length == 4 ? '무선 80,000원 할인' : '무선 100,000원 할인'
}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="top-content  h-44">
                                        <h5 className="mb-0">2. 할인적용 회선</h5>
                                    </div>
                                    { /*this.renderLineOption()*/}
                                    <ul className="list-item mt-0 max-auto">
{
    this.state.line && this.state.line.map( (value, key) => {return (
                                        <li className="">
                                            회선 { value.idx}
                                            <input type='text' onChange={(e) => this.onChange(e, value)}  className={`form-control`} style={{left:"0",width:"100px",height:"1.25rem"}} value={this.state.discount[_.findIndex(this.state.discount, {idx: value.idx})].value}/>
                                        </li>
    )})
}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 mb-4">
                            <button type="button" className="btn btn-gray pr-5 pl-5" onClick={(e) => this.cancelApply(e)}>취소</button>
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={(e) => this.onApply(e)}>확인</button>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

NanumDiscount.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(NanumDiscount);