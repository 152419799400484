import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, reduxForm} from 'redux-form';
import {hideLoading, showLoading} from '../../store/actions/ui';
import * as routerName from './../../utils/routerName';
import {Modal} from 'reactstrap';
import ForgotAccessPopup from './ForgotAccessPopup';
import ChangePasswordPopup from './ChangePasswordPopup';
import PopupError from './../AccessMenu/PopupError';
import {allowAccessMenu, openPopupMenu} from '../../store/actions/auth';
import MessageJSON from "./../../utils/message.json";

const initDirty = {
    old_password: false,
    password: false,
    password_confirmation: false,
}

class AuthAccessPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            password: '',
            errors: {
                password: MessageJSON.access_menu.validate.old_password.required
            },
            // focus: {
            //     password: false,
            // },
            isOpenForgotPopup: false,
            isOpenChangePwPopup: false,
            isOpenPopupError: false,
        };

        this.openModalPopup = this.openModalPopup.bind(this);
    }

    componentDidMount() {
    }


    handlePasswordChange = (event) => {
        const value = event.target.value;
        if (value.length < 1) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    password: MessageJSON.access_menu.validate.old_password.required
                }
            })
        } else {
            this.setState({
                ...this.state,
                password: value,
                errors: {
                    ...this.state.errors,
                    password: ''
                }
            }, () => {
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.password == this.props.auth.user.password_menu) {
            this.props.allowAccessMenu(true);
            this.props.openPopupMenu(false);
            this.props.hideLoading();
            this.onClose();
        } else {
            this.setState({
                ...this.state,
                isOpenPopupError: true,
            })
        }
    }

    onClose = () => {
        this.props.toggle()
    }

    onCloseWithGoto = () => {
        // this.props.history.push(`/${routerName.HOME}`);
        window.location.href = `/${routerName.HOME}`;
    }

    handleInputFocus = (event) => {
        const value = event.target.value;
        const inputName = event.target.name;

        this.setState({
            ...this.state,
            focus: {
                ...this.state.focus,
                [inputName]: true,
            }
        })
    }


    showForgotPopup = () => {
        this.setState({
            ...this.state,
            isOpenForgotPopup: true
        }, () => {
        })
    }
    showChangePwPopup = () => {
        this.setState({
            ...this.state,
            isOpenChangePwPopup: true
        }, () => {
        })
    }

    onToggleForgotPopup = () => {
        this.setState({
            isOpenForgotPopup: !this.state.isOpenForgotPopup,
        })
    }
    onToggleChangePwPopup = () => {
        this.setState({
            isOpenChangePwPopup: !this.state.isOpenChangePwPopup,
        })
    }
    onTogglePopupError = () => {
        this.setState({
            isOpenPopupError: !this.state.isOpenPopupError,
        })
    }

    initDirty = () => {
    }

    openModalPopup() {
        setTimeout(() => {
            window.$("body").css('overflow', 'auto');
        }, 500);
        return this.props.isOpen;
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.openModalPopup()} className="modal-dialog modal-dialog-centered modal-custom-21">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-center w-100">비밀번호 입력</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={() => this.onCloseWithGoto()}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="form_password" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label className="col-form-label label-text">비밀번호</label>
                                <input
                                    type="password" className="form-control h-44" placeholder="비밀번호"
                                    name="password"
                                    onChange={(e) => this.handlePasswordChange(e)}
                                    // onFocus={(e) => this.handleInputFocus(e)}
                                />
                                {/* {
                                   this.state.focus.password && this.state.errors.password && (<p class="small text-danger mt-1">{this.state.errors.password}</p>)
                                } */}

                            </div>
                            <div className="form-group d-flex justify-content-center">
                                <a href className="pr-4" onClick={this.showForgotPopup}>
                                    비밀번호 찾기
                                </a>
                                <ForgotAccessPopup
                                    isOpen={this.state.isOpenForgotPopup}
                                    toggle={this.onToggleForgotPopup}/>

                                <a href className="pl-4" onClick={this.showChangePwPopup}>
                                    비밀번호 변경
                                </a>
                                <ChangePasswordPopup
                                    isOpen={this.state.isOpenChangePwPopup}
                                    toggle={this.onToggleChangePwPopup}/>
                            </div>

                            <div className="w-100 mt-5 text-center modal-footer">
                                <button type="button" className="btn btn_close" data-dismiss="modal" aria-label="Close" onClick={() => this.onCloseWithGoto()}>
                                    취소
                                </button>
                                <button type="submit" className="btn btn_save">
                                    확인
                                </button>
                                <PopupError
                                    isOpen={this.state.isOpenPopupError}
                                    toggle={this.onTogglePopupError}/>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        allowAccessMenu: (data) => dispatch(allowAccessMenu(data)),
        openPopupMenu: (data) => dispatch(openPopupMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(AuthAccessPopup);
