import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import api from '../../utils/api';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import React, { Component, Fragment } from 'react';
import * as routerName from '../../utils/routerName';
import * as helper from '../../utils/helper';
import {permissionsMenu} from '../../utils/helper';
import {showLoading, hideLoading} from '../../store/actions/ui';
import { getGlobalDataAuth, updateDataNotices } from '../../store/actions/dataStore';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import { isBuffer } from 'lodash';
import Pagination from '../../components/Pagination';
import MenuDocuments from './Menu/MenuDocuments';
import {COMPARE_TYPE} from "../../utils/constant";
import queryString from "query-string";

let clickHoldTimer = null;
class ManagerNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSearch: {
                page: 1,
                per_page: 10,
                cat_id: 0,
                cat_child_id:''
            },
            popupPreview:false,
            itemPreview:{},
            cat_parents:[],
            notices:{
                data: []
            },
            cat_childs:[],
        }
        
    }

    componentDidMount() {
        if(this.props.location?.state?.callback) {
            this.setState({
                ...this.state,
                ...this.props.location.state.callback
            }, () => {
                if(this.props.location?.state?.isNew == true){
                    this.loaddingData()
                }
            })
            
        } else {
            let searchObj = queryString.parse(this.props.history.location.search);
            console.log(searchObj)
            api.notification.getNoticeCats({}).then(res => {
                this.setState({
                    dataSearch: {
                        page: 1,
                        per_page: 10,
                        cat_id: searchObj?.cat_id || (res.data.data.cat_parents[0]?.cat_id || 0),
                        cat_child_id:searchObj?.cat_child_id || (res.data.data.cat_parents[0]?.cat_childs?.[0]?.cat_id || 0),
                    },
                    cat_parents:res.data.data.cat_parents,
                }, () => {
                    this.loaddingData();
                })
            }).then(err => {
                console.log(err)
            })
        }
        
        
    }

    loaddingData = () => {
        this.props.showLoading();
        let dataSearch = {
            ...this.state.dataSearch,
            cat_child_id: this.state.dataSearch.cat_id,
        }
        
        api.notification.getNotices(dataSearch).then(res => {
            this.setState({
                notices:res.data.data.notices
            })
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    onClickChangeTab = (id) => {
        let key = this.state.cat_parents.findIndex((item) => item.cat_id == id)
        let params = {
            ...this.state.dataSearch,
        }
        if(id == 0){
            params.cat_id = 0;
            params.cat_child_id = 0;
            this.setState({
                dataSearch:{
                    ...this.state.dataSearch,
                    cat_id:0,
                    cat_child_id:0
                },
            }, () => {
                this.onPaginateRequest()
            })
        } else if(key !== -1 ){
            params.cat_id = id;
            params.cat_child_id = this.state.cat_parents[key]?.cat_childs?.[0]?.cat_id || null;
            this.setState({
                dataSearch:{
                    ...this.state.dataSearch,
                    cat_id:id,
                    cat_child_id:this.state.cat_parents[key]?.cat_childs?.[0]?.cat_id || null
                },
            }, () => {
                this.onPaginateRequest()
            })
        }
        params = queryString.stringify(params)
        window.history.pushState('', '', '/manager-notification?' + params)
    }

    onClickChangeTabChild = (id) => {
        this.setState({
            dataSearch:{
                ...this.state.dataSearch,
                cat_child_id:id
            }
        }, () => {
            this.onPaginateRequest()
        })
    }

    onPaginateRequest = () => {
        this.props.showLoading();
        let dataSearch = {
            ...this.state.dataSearch,
            cat_child_id: this.state.dataSearch.cat_id,
        }
        
        api.notification.getNotices(dataSearch).then(res => {
            this.setState({
                notices:res.data.data.notices
            })
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
        
    }

    onClickPaginationHandle = (number) => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: number
            }
        }, () => {
            this.onPaginateRequest()
        })
    }

    comfirmDel = (id) => {
        let xhtml = (
            <Fragment>
                <div className="content-register-modal">
                    <h4 className="text-center">
                        <span className="notification_delete"> 삭제하시겠습니까?</span>
                    </h4>
                    <div className="form-row">
                        <div className="w-100 text-center">
                            <button className="btn btn_close mr-2" onClick={() => this.props.hideModal()}>취소</button>
                            <button className="btn btn_save  ml-2" onClick={() => this.handleDel(id)}>확인</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: "modal-dialog-scrollable modal-lg modal-dialog-centered",
        }

        this.props.showModal(dataModal);
    }

    handleDel = (id) => {
        this.props.showLoading();
        api.notification.delNotice(id).then(res => {
            this.props.hideLoading();
            this.props.hideModal();
            this.props.getGlobalDataAuth();
            this.loaddingData();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                isFormSubmitted: false,
            })
        }
    }
    
    onClickRedrictDetail = (item) => {
        let callback = {
            routerName:routerName.MANAGER_NOTIFICATION,
            ...this.state
        }
        this.props.history.push({
            pathname:  this.state.dataSearch.cat_id == 0 ? routerName.MONNEY_SUPPORT_DETAIL : routerName.NOTIFICATION_DETAIL,
            state: { notice: item, callback:callback }
        })
    }

    onClickRedrictCreateMonneySupport = () => {
        let callback = {
            routerName:routerName.MANAGER_NOTIFICATION,
            ...this.state
        }
        this.props.history.push({
            pathname:  routerName.CREATE_MONNEY_SUPPORT,
            state: { callback:callback }
        })
    }

    onClickRedrictNotificationCreate = () => {
        let callback = {
            routerName:routerName.MANAGER_NOTIFICATION,
            ...this.state
        }
        this.props.history.push({
            pathname:  routerName.NOTIFICATION_CREATE,
            state: { callback:callback }
        })
    }


    onClickRedrictNotificationUpdate = (item) => {
        let callback = {
            routerName:routerName.MANAGER_NOTIFICATION,
            ...this.state
        }
        this.props.history.push({
            pathname:  this.state.dataSearch.cat_id == 0 ? routerName.CREATE_MONNEY_SUPPORT : routerName.NOTIFICATION_CREATE,
            state: { callback:callback, notice:item }
        })
    }
    
   
    render() {
        return (
            <Fragment>
                <div className="main-wrapper" onClick={this.handleMouseUp}>
                    <section className={"sales-guide "  + (this.props.auth.role == 'Admin' ? 'sales-admin' : '') }>
                        <div className="container">
                            {this.state.cat_parents.length > 4 ? (
                                    <div className="listmenu-slide">
                                        <MenuDocuments
                                            menus={this.state.cat_parents}
                                            onClickChangeTab={this.onClickChangeTab}
                                            cat_id={this.state.dataSearch.cat_id}
                                            isNotice={true}
                                        />
                                        <div className={"button-add-notifi " + permissionsMenu(this.props.auth.role, ['Admin'] )}>
                                            {this.state.dataSearch.cat_id == 0 ? (
                                            <a className="btn btn-submit px-4" to={routerName.CREATE_MONNEY_SUPPORT} onClick={() => this.onClickRedrictCreateMonneySupport()}>
                                                공지사항 작성
                                            </a>) : (<a className="btn btn-submit px-4" onClick={() => this.onClickRedrictNotificationCreate()}>
                                                공지사항 작성
                                            </a>)}
                                        </div>
                                    </div>
                                ) : (
                                    <ul className={"nav nav-tabs list-tabs "}>
                                        <li className="nav-item" key={0} onClick={() => this.onClickChangeTab(0)} >
                                            <a className={`nav-link ` + (this.state.dataSearch.cat_id == 0 ? 'active' : '') } >공시지원금 변동</a>
                                        </li>
                                        {this.state.cat_parents.map((item, i) =>
                                            <li className="nav-item" key={i} onClick={() => this.onClickChangeTab(item.cat_id)}>
                                                <a className={`nav-link ` + (item.cat_id == this.state.dataSearch.cat_id ? 'active' : '' ) } >{item.cat_name}</a>
                                            </li>
                                        )}
                                        <div className={"button-add-notifi " + permissionsMenu(this.props.auth.role, ['Admin'] )}>
                                            {this.state.dataSearch.cat_id == 0 ? (
                                            <a href="#" className="btn btn-submit px-4" onClick={() => this.onClickRedrictCreateMonneySupport()}>
                                                공지사항 작성
                                            </a>) : (<a href="#" className="btn btn-submit px-4" onClick={() => this.onClickRedrictNotificationCreate()}>
                                                공지사항 작성
                                            </a>)}
                                        </div>
                                    </ul>
                                )}
                         
                            <div className="tab-content" >
                                <div className="tab-pane fade show active" >
                                    <article className="content-notification" onMouseLeave={this.handleMouseUp}>
                                    {this.state.notices.data.map((item, i) => 
                                        <div className="item-notification" 
                                        key={i} 
                                        >
                                            <div className="left" onClick={() =>this.onClickRedrictDetail(item) } >
                                                <h2 >
                                                    {item.nt_title}
                                                    {item.nt_state == 0 ? (<span className="color-orange">(초안)</span>) : ('')}
                                                </h2>
                                                <div className="date-like">
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/time.svg"} alt="" />
                                                        <span>
                                                            <Moment format="YYYY.MM.DD">
                                                                {item.created_at}
                                                            </Moment>
                                                        </span>
                                                    </div>
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/View.svg"} alt="" />
                                                        <span>{item.nt_viewer ? item.nt_viewer : 0}</span>
                                                    </div>
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/like.svg"} alt="" />
                                                        <span>{item.count_like ? item.count_like : 0}</span>
                                                    </div>
                                                </div>
                                                <div 
                                                    
                                                    className={`pop-detail ${(this.state.popupPreview == true && item.nt_id == this.state.itemPreview.nt_id) ? "d-block" : "d-none"}`}>
                                                    <div className="modal-content content px-2 popup-home">
                                                        <div className="modal-body discount-card pl-0 pr-0">
                                                        <div className="container_nofitication">
                                                        <div className="box_title">
                                                            <h1>{this.state.itemPreview &&this.state.itemPreview.nt_title}</h1>
                                                            <div className="d-flex flex-row justify-content-between">
                                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                                    <div className="sub mr-4">
                                                                        <i className="far fa-clock"></i> 
                                                                        <span>
                                                                            <Moment format="YYYY.MM.DD">
                                                                                {this.state.itemPreview &&this.state.itemPreview.created_at}
                                                                            </Moment>
                                                                        </span>
                                                                    </div>
                                                                    <div className="sub mr-4">
                                                                        <i className="far fa-eye"></i> <span>{this.state.itemPreview &&this.state.itemPreview.nt_viewer ? this.state.itemPreview.nt_viewer : 0}</span>
                                                                    </div>
                                                                    <div className="sub"  style={{cursor:'pointer'}}>
                                                                        <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{this.state.itemPreview &&this.state.itemPreview.count_like ?this.state.itemPreview.count_like : 0}</span>
                                                                    </div>
                                                                </div>
                                                                {this.state.itemPreview && !!this.state.itemPreview.att_file ? (<div className="sub d-flex">
                                                                <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({this.state.itemPreview && (this.state.itemPreview.att_size / (1024) ).toFixed(2)} KB)</span></p>
                                                                    <a ><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"}  alt=""/></a>
                                                                </div>) : ('')}
                                                            </div>
                                                        </div>
                                                        <div className="box_content ck-content" dangerouslySetInnerHTML={{__html:helper.convertIframe(this.state.itemPreview &&this.state.itemPreview.nt_content) }} >
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>                              
                                            </div>
                                            <div className={"right "}>
                                                {/*{this.state.dataSearch.cat_id != 0 ? (*/}
                                                    {/*<button className={"btn p-0 " + permissionsMenu(this.props.auth.role, ['Admin'] )} onClick={(e) => {e.stopPropagation(); this.onClickRedrictNotificationUpdate(item)} }>*/}
                                                        {/*<i className="far fa-edit"></i>*/}
                                                    {/*</button>*/}
                                                {/*) : null}*/}
                                                <button className={"btn p-0 " + permissionsMenu(this.props.auth.role, ['Admin'] )} onClick={(e) => {e.stopPropagation(); this.onClickRedrictNotificationUpdate(item)} }>
                                                    <i className="far fa-edit"></i>
                                                </button>
                                                <button className={"btn p-0 ml-2 " + permissionsMenu(this.props.auth.role, ['Admin'] )} onClick={(e) => {e.stopPropagation(); this.comfirmDel(item.nt_id)}}>
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                                {!!item.att_file ? (<button className={"btn p-0 ml-2 " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'] )}>
                                                    <img src={process.env.REACT_APP_HOSTNAME + "/images/Paperclip.svg"} alt=""/>
                                                </button>) : ('')}
                                            </div>
                                        </div>
                                    )}
                                        
                                    </article>
                                    <Pagination
                                        data={this.state.notices}
                                        onClickPaginationHandle={this.onClickPaginationHandle}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                          
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getGlobalDataAuth: () => dispatch(getGlobalDataAuth()),
        updateDataNotices: (data) => dispatch(updateDataNotices(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(ManagerNotification);

