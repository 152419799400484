import MessageJSON from '../../../utils/message';

function ValidateEmail(mail) 
{
    if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

const validate = values => {
    const errors = {};
    if (values.forgot_type && values.forgot_type == 1) {
        if (!values.last_phone) {
            errors.last_phone = MessageJSON.forgot_id.validate.last_phone_required;
        } else if(values.last_phone.length < 8){
            errors.last_phone = MessageJSON.signup.validate.verify_phone_number_check_format;
        }
        if(!values.first_phone){
            errors.first_phone = MessageJSON.forgot_id.validate.first_phone_required;
        }
    }

    if (values.forgot_type && values.forgot_type == 2) {
        if (!values.email) {
            errors.email = MessageJSON.forgot_id.validate.email_required;
        } else if (values.email.trim() && values.email.length > 32) {
            errors.email = MessageJSON.forgot_id.validate.email_max;
        } else if (!ValidateEmail(values.email)) {
            errors.email = MessageJSON.forgot_id.validate.email_email;
        }
    }

    return errors;
};

export default validate;
