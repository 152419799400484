

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import MessageJSON from "../../utils/message.json";

class GuidePrintPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    handleConfirm = (e) => {
        e.preventDefault();
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-dialog modal-dialog-centered modal-print-02 guide_print"
                    // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title text-left w-100">인쇄방법 설정 안내</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className="modal-body p-0 instructions-setting">
                        <div className="content-setting">
                            <div className="text-content">
                                <h4>모비셀 홈 전송 후 <span className="text-blue">PC에서 출력</span></h4>
                                <p>
                                - 앱에서 인쇄 후<br />
                                - PC에서 모비셀 홈페이지 로그인 후<br />
                                <span>MAIN에서 [APP 전송서식 출력] 버튼을 선택해주세요.</span>
                                </p>
                                <p>
                                ※주의사항 <br />    
                                모비셀 APP과 PC 홈페이지에 동일한 ID로 로그인하셔야 <br />
                                APP에서 전송한 양식을 확인하실 수 있습니다.
                                </p>
                            </div>
                        <div className="img-content">
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/print-icon-07.svg"} />                    
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button className="btn btn-submit pl-5 pr-5" onClick={this.props.toggle}>확인</button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        // auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        // openPopupMenu: (data)  => dispatch(openPopupMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(GuidePrintPopup);
