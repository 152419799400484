import React, {Fragment} from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import {numberWithCommas} from '../../../utils/helper'
import OpenPopupDiscountCardDetail from "./OpenPopupDiscountCardDetail";
import OpenPopupDiscountCardDetailChildren from "./OpenPopupDiscountCardDetailChildren";
import { connect } from 'react-redux';
import {change, getFormSyncErrors, getFormValues, reduxForm} from "redux-form";
import {compose} from "redux";

class OpenPopupDiscountCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPopupDiscountCardDetail: false,
            isPopupDiscountCardDetailChildren: false,
            itemData : false,
            textCondition: '', // state khi click condition se setstate
            advanced_discount_card_option : '', 
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.WirelessAdviceStep3?.discountCardId && prevProps.WirelessAdviceStep3?.discountCardId != this.props.WirelessAdviceStep3?.discountCardId) {
            this.setState({
                itemData: false
            })
        }
    }

    renderPriceDiscountCard = (data) => {
        if (data) {
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return numberWithCommas(parseJson[0].money_condition)
            }
        }
        return 0;
    }

    openPopupDiscountCardDetail = () => this.setState({isPopupDiscountCardDetail: !this.state.isPopupDiscountCardDetail})

    openPopupDiscountCardDetailChildren = (item) => {
        this.setState({
            isPopupDiscountCardDetailChildren: !this.state.isPopupDiscountCardDetailChildren
        },()=>{
            if(this.state.isPopupDiscountCardDetailChildren){
                this.setState({
                    ...this.state,
                    itemData : item
                })
            }
            // else{
            //     this.setState({
            //         ...this.state,
            //         itemData : null
            //     })
            // }
        })
    }

    isSubmit = (data) => {
        let dataSubmit = {
            ...data,
            textCondition: this.state.textCondition ,
            advanced_discount_card_option : this.state.advanced_discount_card_option
        }
        this.props.isSubmit(dataSubmit);
        // this.props.openPopupDiscountCard();
    }

    onClickNotDiscountCard = () => {
        if (this.props.type === 'wired_discount_card') {
            return this.props.isSubmit({
                itemData: {},
                total: 0,
            });
        }
        if(this.props.type == "billing_discount_card"){
            this.props.change('WirelessAdviceStep3.discountCard', 0)
            this.props.change('WirelessAdviceStep3.discountCardId', null)
        }else if(this.props.type == 'light_and_save_card'){
            this.props.change('WirelessAdviceStep3.advancedDiscountCard', 0)
            this.props.change('WirelessAdviceStep3.advancedDiscountCardID', null)
        }
        this.props.openPopupDiscountCard();
    }

    onChangeTextCondition = text => {
        if(text) {
            this.setState({
                textCondition : text
            })
        }
    }


    render() {
        let {discountCard} = this.props;

        let textTitle = '';
        if(this.props.type == "billing_discount_card"){
            textTitle = '청구할인카드 선택';
        }else if(this.props.type == "wired_discount_card"){
            textTitle = '할인카드 선택';
        }else{
            textTitle = '선할인카드 선택'
        }

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpenDiscountCard}
                    toggle={this.props.openPopupDiscountCard}
                    className={' modal-dialog-scrollable modal-xl modal-sale-prices'}
                    contentClassName={'content pl-5 pr-5'}
                    // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title flex-header">
                            {textTitle}
                            <button
                                className="btn btn-wireless"
                                type="button"
                                //data-toggle="modal"
                                //data-target="#modal-16"
                                onClick={this.openPopupDiscountCardDetail}
                            >
                                전체보기
                            </button>
                        </h5>
                        <button
                            type="button"
                            className="close close-modal"
                            // data-dismiss="modal"
                            // aria-label="Close"
                            onClick={this.props.openPopupDiscountCard}
                        >
                      <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body discount-card pl-0 pr-0">
                        <form>
                            {/*{this.props.type == "wired_discount_card"*/}
                            {/*  ? ''*/}
                            {/*  : <h6 className="modal-title">*/}
                            {/*      라이트할부카드*/}
                            {/*  </h6>*/}
                            {/*}*/}

                            <ul className="list-item mt-0">
                                <li onClick={this.onClickNotDiscountCard}>
                                    <span className="left">미적용</span>
                                    <div className="right">
                                        <p className="mb-0">0원</p>
                                    </div>
                                </li>

                                {discountCard.length > 0 && discountCard.map((item, index) => {
                                    return (
                                        <li
                                            //data-toggle="modal"
                                            //data-target="#modal-17"
                                            key={index}
                                            onClick={() => this.openPopupDiscountCardDetailChildren(item)}
                                        >
                                            <span className="left">{item.dcc_name}</span>
                                            <div className="right">
                                                {item.dcc_auto_transac == 1 ?
                                                    <button className="btn btn-wireless" type="button">
                                                        <img src="/images/mail-icon.svg" alt=""/>
                                                        이체
                                                    </button> : ''}

                                                <p className="mb-0 text-wrap">월 {this.renderPriceDiscountCard(item.dcc_discount)}원
                                                    ~</p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </form>
                    </div>
                </Modal>

                <OpenPopupDiscountCardDetail
                    isPopupDiscountCardDetail={this.state.isPopupDiscountCardDetail}
                    openPopupDiscountCardDetail={this.openPopupDiscountCardDetail}
                    discountCard={discountCard}
                    titleDetail={this.props.titleDetail}
                    type={this.props.type}
                    telcomID={this.props.telcomID}
                />

                <OpenPopupDiscountCardDetailChildren
                    isPopupDiscountCardDetailChildren={this.state.isPopupDiscountCardDetailChildren}
                    openPopupDiscountCardDetailChildren={this.openPopupDiscountCardDetailChildren}
                    itemData={this.state.itemData}
                    discountCard={discountCard}
                    isSubmit={this.isSubmit}
                    type={this.props.type}
                    telcomID={this.props.telcomID}
                    textCondition={this.onChangeTextCondition}
                />


            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // discountCard: (data) => dispatch(getDiscountCard(data)),
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(OpenPopupDiscountCard);
