import React, {Fragment} from 'react';
import api from "../utils/api";
import {priceFormat, updateWiredAdviceStep1, updateWiredAdviceStep2} from "../utils/helper";
import {CLASSIFY, CONSULTANT_WIRED, LGU_PLUS_ID} from "../utils/constant";
import queryString from "query-string";
import ProductClassificationItem from "../components/Wired/ProductClassification/ProductClassificationItem";
import InstallationFeeList from './../components/InstallationFee/InstallationFeeList'
import CombinedResultList from "../components/Combine/CombineResultList";
import CombineResultList from "../components/Combine/CombineResultList";
const TEXT = {
  1: '인터넷 할인',
  2: 'TV 플러스',
}
class ShareWired extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      data : {}
    }
  }

  componentDidMount() {
    let url = this.props.location.search
    let params = queryString.parse(url);
    if(params && params.id){
      api.advice.getConsultant(params.id)
        .then(response => {
          const {data} = response.data;

          console.log("api data", response.data);

          let WiredAdviceStep1Clone = updateWiredAdviceStep1({}, data);
          let WiredAdviceStep2Clone = updateWiredAdviceStep2(CONSULTANT_WIRED, data);

          WiredAdviceStep2Clone =  {
            ...WiredAdviceStep2Clone,
            telcom                : data.telcom,
            note                  : data.note,
            consultant_wired      : data.consultant_wired,
            totalCombinedDiscount : data.consultant_wired.total_combined_discount,
            saler                 : data.saler,
            setupFee              : data.consultant_setup_fee
          };

          let combinePlans = WiredAdviceStep2Clone.combinePlans;

          if(combinePlans){
            let combinePlansLength = combinePlans.length;
            let tempInt            = 0;
            let combinePlansInfo   = null;

            for(tempInt = 0; tempInt < combinePlansLength; tempInt++){
              combinePlansInfo = combinePlans[tempInt];

              let isCommitment = combinePlansInfo.is_commitment;

              if(isCommitment && isCommitment == "0"){
                combinePlansInfo.is_commitment = null;
              }
            }
          }

          this.setState({
            data : WiredAdviceStep2Clone
          },()=>console.log(this.state.data,'data'))
        }).catch(e => {
      })
    }
  }

  sumReduce = (accumulator, currentValue) => accumulator + currentValue;

  sumDiscount = (array) => {
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    // if (removeFirst) {
    //   array.shift()
    // }
    let sum = array.map(e => +e.discount_wireless_price)
    sum = sum.reduce(this.sumReduce)
    return sum;
  }

  sumPlanPrice = (array) => {
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    // if (removeFirst) {
    //   array.shift()
    // }
    let sum = array.map(e => +(e.plan?.cost_display || 0))
    sum = sum.reduce(this.sumReduce)
    return sum;
  }

  totlaFeeOption = (options = []) => options.reduce((prevFee, option) => {
    return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
  }, 0);

  totlaOptionDiscountAdditional = (options = []) => options.reduce((prevAdditional, option) => {
    return prevAdditional + +(option.additional || 0)
  }, 0);

  totlaOptionDiscountValue = (options = []) => options.reduce((prevValue, option) => {
    return prevValue + +(option.value || 0)
  }, 0);

  totlaOptionDiscountCombine = (options = []) => options.reduce((prevValue, option) => {
    return prevValue + +(option.combine || 0)
  }, 0);

  // 할인 카드 조건 반환
  getDiscountCardTextCondition = () => {
    let textCondition   = '';
    let {data}          = this.state;
    let consultantWired = data.consultant_wired;

    if(consultantWired){
      let discountCardOption = consultantWired.discount_card_option;

      if(discountCardOption){
        textCondition = "전월 실적 " + discountCardOption;
      }
    }
    
    /*
    let disnableCard  = true;

    data.classify.map((value, key) => {
      let deviceData = value.classify.filter(item=>item.device && item.device.wd_id)

      if(deviceData.length > 0) {
        disnableCard = false;
      }
    });

    if(data.discountCard && data.discountCard.dcc_id && data.discountCard.textCondition && !disnableCard) {
        textCondition = data.discountCard.textCondition;
    }
    */

    return textCondition;
  }

  // 설치비 정보 반환
  getSetupFeeHtml = () => {
    let total    = 0;
    let {data}   = this.state;
    let setupFee = data.setupFee || [];
    let setupFee_night = data.setupFee || [] //야간 설치 비용

    //console.log("통신사 값 확인", data.telcom.id);
    let telcom = data.telcom.id;

    setupFee.map((e) => {total += +((e.fee + '').split(',').join(''))});

    return (
      <Fragment>
        <div className="installation-cost-social">

        <table>
          <tr>
            <td>
        {setupFee.map((item, key) => (
          <span className="list-cost-social" title={item.name} key={key}>
          {/**
            {item.icon.split('+').map((icon, iKey) => (
              <img
                key={iKey}
                src={icon}
                alt={item.name}
                className="ml-1 mr-1"
              />
            )).reduce((prev, curr) => [prev, '+', curr])}
          */}
            <span>(주간)</span>
            <span>{item.name} {priceFormat((item.fee + '').split(',').join(''))}원</span>
          </span>
        ))}
            </td>
            <td>
        {setupFee_night.map((item, key) => (
          <span className="list-cost-social" title={item.name} key={key}>
            <span>(야간)</span>
            <span>{item.name} {priceFormat((item.fee_night + '').split(',').join(''))}원</span>
          </span>
        ))}
            </td>
          </tr>
        </table>

          
        </div>
        <div className="installation-cost">
          <small className="text-muted">(VAT 포함)</small>
          <Fragment>
          <span className="px-2">
          <span className="total-price">(주간기준){priceFormat(total)}</span>
          <span className="price-sub">원</span>
          </span>

          </Fragment>
        </div>
      </Fragment>
    );
  }

  render() {
    let {data} = this.state;
    let totalMonthlyFee = 0;
    let totalBasicDiscount = 0;
    let totalCombinedDiscount = 0;
    let totalAfterDiscount = 0;
    if(data.classify && data.classify.length > 0) {
      data.classify.map((cls) => (
        cls.classify.map((item) => {
          totalMonthlyFee += +(item?.device?.wd_commitment_fee || 0)
          totalMonthlyFee += +(item?.option?.wdo_commitment_fee || 0)
          totalBasicDiscount += +(item?.discount?.device?.value || 0)
          totalBasicDiscount += +(item?.discount?.option?.value || 0)
          totalCombinedDiscount += 0
        })
      ))
    }

    totalMonthlyFee = totalMonthlyFee > 0 ? totalMonthlyFee : 0;
    totalBasicDiscount = totalBasicDiscount > 0 ? totalBasicDiscount : 0;
    totalCombinedDiscount = totalCombinedDiscount > 0 ? totalCombinedDiscount : 0;
    totalAfterDiscount = totalMonthlyFee - totalBasicDiscount - totalCombinedDiscount;

    let sumDiscountResult = []
    let sumPlanPriceResult = []

    if(data && data.combinePlans){
      sumDiscountResult = this.sumDiscount((data.combinePlans || []))
      sumPlanPriceResult = this.sumPlanPrice((data.combinePlans || []))
    }

    var monthly_costs = 0;
    var total_combined_discount = 0;

    if(data.consultant_wired){
      monthly_costs = +data.consultant_wired.monthly_costs
      total_combined_discount = +data.consultant_wired.total_combined_discount
    }

    /*
    if(data.combineCondition && data.combineCondition.id == 19){
      if(data.classify[1].classify.length > 0){
        if(data.classify[1].classify[0]?.discount?.combineDiscount){
          data.classify[1].classify[0].discount_name = [{
            "discount" : data.classify[1].classify[0].discount.combineDiscount,
            "name": "요즘가족결합"
          }];
        }
      }
    }
    */

    if(data.combineCondition){
      if(data.combineCondition.id == 19){
        if(data.classify[1].classify.length > 0){
          if(data.classify[1].classify[0]?.discount?.combineDiscount){
            data.classify[1].classify[0].discount_name = [{
              "discount" : data.classify[1].classify[0].discount.combineDiscount,
              "name": "요즘가족결합"
            }];
          }
        }
      }
      else if(data.combineCondition.id == 13){ // 참쉬운 가족결함인 경우
        if(data.classify[0].classify.length > 0){
          let wdId = data.classify[0].classify[0]?.device?.wd_id || 0;

          if(wdId == 306 || wdId == 307 || wdId == 391){
            if(data.combinePlans && data.combinePlans.length > 0){
              let isLteDataSenior33 = false;

              data.combinePlans.map((planInfo) => {
                let planId = planInfo?.plan?.id || 0;

                if(
                  planId == 2869 || planId == 2872 || planId == 2875 || planId == 2878 || 
                  planId == 3891 || planId == 3892 || planId == 3893
                ){
                  isLteDataSenior33 = true;
                }
              });

              //if(data.combinePlans[0].plan.id == 2869){
              if(isLteDataSenior33){
                let combineDiscount = data.classify[0].classify[0]?.discount?.combineDiscount || 0;

                data.classify[0].classify[0].discount_name = [
                  {discount : (combineDiscount - 3100), name : '참쉬운가족결합'},
                  {discount : 3100, name : '시니어플러스요금할인'}
                ];
              }
            }
          }
        }
      }
    }

    if(Object.keys(data).length === 0){
      return (<Fragment></Fragment>);
    }

    console.log("data", data);

    return (
      <section className="wired-consultation">
        <div className="container">
          <article>
            <div className="row">
              <div className="col-md-12 mb-3">
{/* 
                <div className="button-expected">
                  <div className="star-comment">
                    <span>즐겨찾기</span>
                    <button className="btn btn-star p-0 active">
                    </button>
                  </div>
                  <button className="btn btn-gray-white" type="button" data-toggle="modal" data-target="#modal-10 ">
                    해지시 예상 위약금
                  </button>
                </div>
*/}
              </div>
              <div className="col-md-8">
                <div className="table-responsive table-wired table-wired-custom">
                  <table className="table h-100">
                    <thead className="thead-table-blue">
                      <tr>
                        <th className="text-center" style={{width: '10%'}}>구분</th>
                        <th>상품</th>
                        <th>부가옵션</th>
                        <th>월 이용료</th>
                        <th>할인 적용</th>
                      </tr>
                    </thead>
                    <tbody>
{(data?.classify || []).map((cls, key) => {
  if (
    //data.telcom.id != LGU_PLUS_ID && cls.id == CLASSIFY.IOT ||
    cls.id == CLASSIFY.IOT
  ) {
    return null;
  }

  return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex flex-column text-center mt-cs3">
                            <div>
                              <img src={cls?.icon} title={cls?.name} />
                            </div>
                            <span className="title-table">
                              {cls?.name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="w-170">
    {cls?.classify.length === 0 && (
                            <button className="btn add_line_blue w-100 mb-2">
                              <i className="fas fa-plus"></i> 대표회선
                            </button>
    )}
    {cls?.classify.map((clf, key) => (
                            <button key={key} className="btn btn-input-form mb-1" type="button">
                              {clf?.device?.wd_name ? (clf?.device?.wd_name + (clf?.device?.wd_class == 91 ? ` (Ch.${clf?.device?.wd_channel_total})` : '')) : '미적용'}
                            </button>
    ))}
    {cls?.classify.length <= 2 && (
                            <button className="btn add_line w-100" >
                              <i className="fas fa-plus"/> 추가회선
                            </button>
    )}
                          </div>
                        </td>
                        <td>
    {cls?.classify.map((clf, key) => (
                          <button key={key} className="btn btn-input-form mb-1" type="button" >
      {
        (clf?.options && clf.options.length > 0) ?
        (
          clf.options.length == 1 ?
            (
              (clf?.options[0]?.wdo_name && clf?.options[0]?.wdo_name != "") ?
              (clf?.options[0]?.wdo_name) :
              (clf?.options[0]?.wdo_model_name)
            ) :
            (
              ((clf?.options[0]?.wdo_name && clf?.options[0]?.wdo_name != "") ?
              clf?.options[0]?.wdo_name :
              clf?.options[0]?.wdo_model_name) + ' 외 ' + (clf?.options.length - 1) + '개'
            )
        ) : '미적용'
      }
                          </button>
    ))}
                        </td>
                        <td>
    {cls?.classify.map((clf, key) => (
                          <input key={key} type="text" className="form-control w-90 h-44 mb-1" value={`${priceFormat(clf?.cost?.value)} 원`} disabled />
    ))}
                          </td>
                          <td>
    {cls?.classify.map((clf, key) => (
                              <button key={key} className="btn btn-input-form mb-1 text-bold" type="button">
                                {`${priceFormat((this.totlaFeeOption(clf.options) - this.totlaOptionDiscountAdditional(clf?.discount?.options) - this.totlaOptionDiscountValue(clf?.discount?.options) - this.totlaOptionDiscountCombine(clf?.discount?.options)) + (+(clf.device?.wd_commitment_fee || 0) - clf?.discount?.device?.additional - clf?.discount?.device?.value))} 원`}
                              </button>
    ))}
                          </td>
                        </tr>
  )
})}
                      </tbody>
                    </table>
                </div>
                <div className="title-total">
                  <div className="d-flex justify-content-between align-items-center">
                    <div colSpan={3}>
                      <span className="font-weight-bold">월 납부 요금 </span><small className="text-muted">[3년약정]</small>
                    </div>
                    <div colSpan={2} className="text-right">
                      <small className="text-muted">(VAT 포함)</small><span className="px-2">
                      <span className="total-price">{priceFormat(data?.consultant_wired?.monthly_costs)}</span>
                      <span className="price-sub">원</span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="right-article">
                  <div className="item-right">
                    <h4 className="title-form border-bottom-title pb-3 mb-3">할인 선택</h4>
                    <form>
                      <div>
                        <div className="form-group">
                          <label className="label-text">유무선 결합</label>
                          <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-58">
                            {data.classify[0].classify.length > 0 && data.classify[0].classify[0].device.wd_id && data.combineCondition?.name || '미적용'}
                          </button>
                        </div>
{(data?.classify || []).map((cls, key) => (
  cls.classify.map((item, ikey) => {
    let isLteDataSenior33  = false;
    let combineConditionId = data.combineCondition?.id || 0;

    if(key == 0 && ikey == 0 && combineConditionId == 13){
      let deviceWdId = +item.device.wd_id;
      
      if(deviceWdId == 306 || deviceWdId == 307 || deviceWdId == 391){
        data.combinePlans.map((plans) => {
          let planId = +plans?.plan_id || 0;

          if(
            planId == 2869 || planId == 2872 || planId == 2875 || planId == 2878 || 
            planId == 3891 || planId == 3892 || planId == 3893
          ){
            isLteDataSenior33 = true;
          }
        });
      }
    }

    return item.discount.combineDiscount ? 
      isLteDataSenior33 ?
      (<Fragment>
                          <h5 className="title-form flex-form mt-4" key={key*ikey}>
                            <span>{TEXT[cls.id]} (회선 {ikey + 1})</span>
                            <p className="prices-form mb-0">
                              -{priceFormat(+item.discount.combineDiscount - 3100)}
                              <span className="price-sub">원</span>
                            </p>
                          </h5>
                          <h5 className="title-form flex-form mt-4" key={key*ikey}>
                            <span>시니어플러스요금할인</span>
                            <p className="prices-form mb-0">
                              -{priceFormat(3100)}
                              <span className="price-sub">원</span>
                            </p>
                          </h5>
      </Fragment>) :
      (
                          <h5 className="title-form flex-form mt-4" key={key*ikey}>
                            <span>{TEXT[cls.id]} (회선 {ikey + 1})</span>
                            <p className="prices-form mb-0">
                              -{priceFormat(item.discount.combineDiscount)}
                              <span className="price-sub">원</span>
                            </p>
                          </h5>
      ) :
    null
  })
))}
                        <div className="form-group mt-4">
                          <label className="label-text">할인카드</label>
                          <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-08">
                            {(data?.discountCard?.dcc_name || '미적용')}
                          </button>
                        </div>
                        <div className="total-prices flex-form">
                          <h4 className="title-form mb-0">
                            <span>
                              {this.getDiscountCardTextCondition()} {/* 할인카드 조건 */}
                            </span>
                          </h4>
                          <p className="prices-form mb-0">
                            {data.discountCardPrice ? data.discountCardPrice != 0 ? '-'+priceFormat(data.discountCardPrice) +'원' : '0원' : '0원'}
                            {/*<span className="price-sub">원</span>*/}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="mb-3">
                          <p className="text-note">※ 청구금액은, 고객님의 사용내역에 따라 실제 청구금액과 상이 할 수 있습니다.</p>
                        </div>
                        <div className="d-flex justify-content-between border-head-blue border-bottom-0 py-2 pt-3">
                          <span className="font-weight-bold">결합 총할인 </span>
                          <p className="prices-form mb-0">
                            -{priceFormat(data.totalCombinedDiscount)}
                            <span className="price-sub">원</span>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-payment">
              <div>
                <span className="font-weight-bold">결합 후 월 납부 요금</span><small className="text-muted">(VAT 포함)</small>
              </div>
              <div>
                <small className="text-muted">(VAT 포함)</small>
                <span className="px-2">
                  <span className="total-price">{priceFormat(monthly_costs - total_combined_discount)}</span>
                  <span className="price-sub">원</span>
                </span>
              </div>
            </div>
            <div className="box-payment">
              <div>
                <span className="font-weight-bold">설치비(별도청구금액)</span>
              </div>
              {this.getSetupFeeHtml()}{/* 설치비 */}
{/*
              <div className="installation-cost-social">
                <span className="list-cost-social">
                  <img src="/images/globe.svg" alt="" /> <span>28,100원</span>
                </span>
                <span className="list-cost-social">
                  <img src="/images/tivi.svg" alt="" /> <span>13,000원</span>
                </span>
                <span className="list-cost-social">
                  <img src="/images/phone.svg" alt="" /> <span>7,000원</span>
                </span>
              </div>

              <div class="installation-cost-social">
                <span class="list-cost-social">
                  <img src="/images/globe.svg" alt=""> <span>28,100원</span>
                </span>
                <span class="list-cost-social">
                  <img src="/images/tivi.svg" alt="">
                  <img src="/images/phone.svg" alt=""> <span>7,000원</span>
                </span>
              </div>


              <div className="installation-cost">
                <small className="text-muted">(VAT 포함)</small>
                <span className="px-2">
                  <span className="total-price">38,100</span>
                  <span className="price-sub">원</span>
                </span>
              </div>
*/}
            </div>
            <div className="row justify-content-end text-right mt-3">
              <div className="col-md-6">
                <p className="text-note">
                  ※설치비는 1회만 청구되며, 회선추가 시 설치비가 추가 청구될수 있습니다.
                  <br />
                  ※설치비는 신규 설치비이며, 이전/변경 설치비와는 다릅니다.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <h3 className="title-form font-16">월 청구 요금 내역</h3>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <InstallationFeeList
                    classify={data.classify || []}
                    combineCondition={data.combineCondition || {}}
                  />
                </div>
              </div>
            </div>
            <CombinedResultList
              combinePlans={data.combinePlans || []}
              combineCondition={data.combineCondition || {}}
              telcomID={data.telcom.id}
              telcom={data.telcom || {}}
            />
            <div className="row grape-record mt-2">
              <div className="col-md-8">
                <div className="form-group">
                  <label htmlFor className="label-text">메모</label>
                  <textarea className="form-control" rows="6" placeholder="메모" name="note" value={data?.note}></textarea>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="form-group row align-items-center">
                  <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">매장명</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control h-44" defaultValue={data?.saler?.store[0]?.sto_name} disabled />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">상담사</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control h-44" defaultValue={data?.saler?.name} disabled />
                  </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                  <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전화번호</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control h-44" defaultValue={data?.saler?.store[0].sto_phone_number} disabled />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    )
  }
}

export default ShareWired;
