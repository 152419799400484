
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, FormSection } from 'redux-form';
import validate from './validate-step-1.js';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../../store/actions/modal';
import * as routerName from '../../../utils/routerName';

class WirelessStep1 extends Component {
    
    componentDidMount () {
        document.body.classList.add("body_wireless_advice");
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }
      
    popupShowSelectedTelcomChild = (item) => {
        if(item.childrens && item.childrens.length > 0) {
            let xhtml = (
                <div className="pl-3 pr-3 ">
                    <div className="modal-header pt-4">
                        <h5 className="modal-title">하위 통신사 </h5>
                        <button type="button" className="close close-modal" onClick={() => this.props.hideModal()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-additional">
                            {item.childrens.map((item, i) =>
                                <li className={i == 0 ? "" : ""} key={i} onClick={()=> {this.props.onSelectedTelcom(item); this.props.hideModal()}}>
                                    {item.name}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            );
            let dataModal = {
                toggle:true,
                // close:true,
                class_n:'modal-dialog modal-dialog-centered modal-additional',
                component:xhtml
            }
            this.props.showModal(dataModal);
        } else{
            this.props.onSelectedTelcom(item)
        }
    }


    render() {
        const { handleSubmit, WirelessAdvice } = this.props;
        return (
            <FormSection name="WirelessAdviceStep1">
                <div className="list-box">
                    {/* process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/box-01.svg" */}
                    <div className="box-main">
                        { this.props.telcomProviders && this.props.telcomProviders.telcomParents.map((item, i) => 
                            <Fragment key={i}>
                                {i <= 2 ? (
                                     <div className="box-item " onClick={()=>this.popupShowSelectedTelcomChild(item)}>
                                        <img src={item.image_url.original} alt="" />
                                        <span>{item.name}</span>
                                    </div>
                                ): ('')}
                               
                            </Fragment>
                            
                        )}
                    </div>
                    <div className="box-extra">
                        { this.props.telcomProviders && this.props.telcomProviders.telcomChildren.map((item, i) => 
                            <Fragment key={i}>
                                {i <= 5 ? (
                                    <div className="box-item" onClick={()=>this.popupShowSelectedTelcomChild(item)}>
                                        <img src={item.image_url.original} alt="" />
                                    </div>
                                ): ('')}
                                
                            </Fragment>
                        )}
                    </div>
                </div>
            </FormSection>
        );
    }
}

WirelessStep1.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    }
};
const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(WirelessStep1);
