import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {Field, getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import HistoryConsultantComponent from "./../../components/HistoryConsultant/HistoryConsultant"
import HistoryConsultantWiredComponent from "./../../components/HistoryConsultant/HistoryConsultantWired"
import api from "../../utils/api";
import {hideLoading, showLoading} from "../../store/actions/ui";
import {INSTALLMENT_PAYMENT_METHOD, OPTION_FEE} from "../../utils/constant";
import {getAdviceWireless} from "../../store/actions/dataStore";
class HistoryConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs:'1',
            consultants: [],
            params: {
                type: 'wireless',
            }
        }
    }

     toggle = tab => {
        if(this.state.tabs !== tab) {
            let data= {}
            if(tab == 2) {
                this.setState({
                    tabs:tab,
                    type: 'wired',
                })
                data= {
                    type: 'wired',
                }
            }else {
                this.setState({
                    tabs:tab,
                    type: 'wireless',
                })
                data= {
                    type: 'wireless',
                }
            }
            this.fetchData(data)
        }
    }

    fetchData = (data) => {
        this.props.showLoading()
        api.advice.getListConsultant(data)
            .then(response => {
                this.props.hideLoading()
                this.setState({
                    consultants: response.data.data
                })
            })
            .catch(() => {
                this.props.hideLoading();
            })
    }

    componentDidMount () {
        this.fetchData(this.state.params)
    }

  render() {
    return (
      <Fragment>
          <section className="wrapper_coupon page_counseling_history">
              <div className="container">
                  <div className="seller_manager page_seller_manager ">
                      <div className="content-product modal-counseling-history">
                          <div className="left-product">
                              <div className="top">
                                  <Nav tabs className={'nav nav-tabs list-tab justify-content-center'}>
                                      <NavItem className={'nav-item tab-item'}>
                                          <NavLink
                                              className={classnames({ active: this.state.tabs === '1' })}
                                              onClick={() => { this.toggle('1'); }}
                                          >
                                              무선상담내역
                                          </NavLink>
                                      </NavItem>
                                      <NavItem className={'nav-item tab-item'}>
                                          <NavLink
                                              className={ classnames({ active: this.state.tabs === '2' })}
                                              onClick={() => { this.toggle('2'); }}
                                          >
                                              유선상담내역
                                          </NavLink>
                                      </NavItem>
                                  </Nav>
                              </div>
                          </div>
                          <div className="text-content" style={{fontSize:"13px", marginTop:"10px"}}> ※최근 상담내역은 최대 20건까지 조회할 수 있습니다. </div>  
                          <TabContent activeTab={this.state.tabs}>
                              <TabPane tabId="1" >
                                  {this.state.tabs == 1 ? <HistoryConsultantComponent consultants={this.state.consultants} /> : ''}
                              </TabPane>
                              <TabPane tabId="2" >
                                  {this.state.tabs == 2 ? <HistoryConsultantWiredComponent consultants={this.state.consultants} />  : ''}
                              </TabPane>
                          </TabContent>

                      </div>
                  </div>
              </div>
          </section>

         
      </Fragment>
    );
  }
}

HistoryConsultant.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    WirelessAdvice: PropTypes.object,
    change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      showLoading: () => dispatch(showLoading()),
      hideLoading: () => dispatch(hideLoading()),
      getAdviceWireless: () => dispatch(getAdviceWireless())
  };
};


const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    key: FORM_NAME,
    initialValues: {
        step: 1,
        id: null,
        WirelessAdviceStep1: {
            telcomID: '',
            status: false,
            successString: '',
            installmentPaymentMethod: INSTALLMENT_PAYMENT_METHOD.principalAndInteres
        },
        WirelessAdviceStep2: {
            mfID: '0', //bằng tất cả default
            keyword: '',
            is_new: false,
            is_consulted: false,
            is_bookmarked: false,
            sortBy: '',
            sortDirection: 'asc',
            sortTableFieldBy: '',
            sortTableFieldDirection: 'asc',
            status:false,
            color: '',
        },
        WirelessAdviceStep3: {
            saler: null, // THIẾT BỊ ĐẦU CUỐI (단말기)

            phone: {}, // THIẾT BỊ ĐẦU CUỐI (단말기)
            startingPrice: null, // Giá khởi điểm (출고가)
            packageFee: null, // gói phí (요금제)
            planData: null, // gói phí (요금제)
            newPlanData: null, // gói phí mới (요금제)
            namePlanNewDateChange: null,
            basicCost: null, // chi phí cơ bản (기본료)

            optionFee: OPTION_FEE.disclosureSubsidy, // 0: giảm giá cam kết lựa chọn (선택약정할인) | 1: tiền hỗ trợ thêm (추가지원금)
            disclosureSubsidy: null, // tiền hỗ trợ thông báo (공시지원금)
            displayApplyDate: null, // ngày tham gia (기준)
            disclosureSubsidyDefault: null,
            disclosureSubsidyDifference: null,
            disclosureSubsidyDifferenceDefault: null,
            additionalGrants: null, // tiền hỗ trợ thêm (추가지원금)
            additionalGrantsType: null,
            optionalAgreement: null, // cam kết lựa chọn (선택약정)
            optionalAgreementDefault: null,
            contractMonth: null, // số tháng cam kết (약정개월)
            optionalAgreementWith2G: null,
            pointDiscount: null, // giảm giá point (포인트 할인)
            advancedDiscountCard: null, // thẻ giảm giá trước (선할인 카드)
            usedPhoneCompensation: null, // bồi thường điện thoại cũ (중고폰보상 프로그램)
            applyUsedProgram: null, // Tiền yêu cầu theo tháng sau khi apply bồi thường DT cũ
            installmentType: null, // Loại hình tiền hổ trợ (chỉ LGU mới có)

            cashPayment: null, // nạp tiền mặt (현금납부)
            other: null, // khác (기타)
            installmentMonth: 24, // số tháng trả góp (할부개월)

            rateAgreement: null, // cam kết gói phí (요금약정)
            rateAgreementDefault: null, // cam kết gói phí dèault (요금약정)
            rateAgreementEstimate: null,
            discost12: null, // cam kết gói phí (요금약정)
            discost24: null, // cam kết gói phí (요금약정)
            discostChange: 12, // cam kết gói phí (요금약정)
            promotion: {}, // promotion (프로모션)
            combinedDiscount: null, // giảm giá kết hợp (결합 할인)
            welfareDiscount: null, // giảm giá phúc lợi (복지 할인)
            discountCard: null, // thẻ giảm giá (할인카드)
            mutualService: null, // dịch vụ tương trợ (상조서비스)
            conversion2G: null, // chuyển đổi 2G (2G전환)


            sim: null, // SIM (유심)
            subscriptionFee: null, // Phí đăng ký (가입비)
            insurrance: null, // Bảo hiểm thiết bị (휴대폰 보험)
            insurranceCancelDate: null, // Ngày có thể hủy (휴대폰 보험)

            extraServices: [], // Dịch vụ bổ sung

            // tinh tien
            interestRate: 5.9, // tiền lãi trên 1 năm
            installmentPrincipal: 0, // Nguyên giá trả góp (할부원금)
            installmentFee: 0, // Tổng lãi suất trả góp (할부수수료)
            installmentMonthly : 0, // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
            installmentMonthlyPrincipal : 0, // Tiền trả góp thiết bị theo tháng Nguyên giá trả góp
            installmentMonthlyFee : 0, // Tiền trả góp thiết bị theo tháng lãi suất trả góp
            monthlyFee: 0, // chi phi theo thang (월 요금)
            moneyRemainingMonth: 0, // Số tiền dự kiến tháng này (당월 예상 금액)
            estimatedAmountMonth: 0, // Số tiền dự kiến tháng này (당월 예상 금액)
            amountMonthlyDevice: 0, // Tiền yêu cầu theo tháng A + B (월별 청구금액)
            amountMonthlyOption: 0, // Tiền yêu cầu theo tháng C (월별 청구금액)
            amountMonthlyTotal: 0, // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
            status: false,
            planNewDateChange: 0,  // giá gói phí muốn đổi (요금제)
            dataPlanNewDateChange: null,  // gói phí muốn đổi (요금제)
            estimatedRatesChangingPlans: 0, // chi phí dự kiến khi thay đổi gói phí (요금제 변경시 예상요금 )
            optionalAgreementPlanEstimated: 0,
            note: '',
            planChangeDate:'' , // 변경 가능일자
            customerId: null,
            customerNote: '',
            stateLoadPotential:false,
            telcomPotentialCustomer:null,
        },
        WirelessAdviceStep3Default:{
            startingPrice: null, // Giá khởi điểm (출고가)
            welfareDiscount: null, // giảm giá phúc lợi (복지 할인)
            promotion: {}, // promotion (프로모션)
            pointDiscount: null, // giảm giá point (포인트 할인)
        },
        CreatePotentialCustomer: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
        }
    },
    enableReinitialize: true,
    destroyOnUnmount: false,
}, mapStateToProps);
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
    withReduxForm
)(HistoryConsultant);

