import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
 

const renderTextField = ({ input, meta: { touched, invalid, error }, type, placeholder, min, max
}) => {
    return (
        
        <Fragment>
            <input
                {...input}
                type={type}
                className={'form-control ' + (touched && invalid ? 'is-invalid' : '') + ' h-44'}
                placeholder={placeholder}
                min={min}
                max={max}
                // autoComplete="new-password"
                 />
            {error !== undefined ? (
                <div className="invalid-feedback">
                    {error}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderTextField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderTextField;
