import React, { Fragment } from 'react';
import PropTypes from 'prop-types';



const renderTextField = ({ input, meta: { touched, invalid, error, warning }, type, placeholder, min, max, disabled, errorStyle, className
}) => {
    return (
        
        <Fragment>
            <input
                {...input}
                type={type}
                className={'form-control '+ className  + (touched && invalid ? ' is-invalid' : '') + ' h-44'}
                placeholder={placeholder}
                min={min}
                max={max}
                autoComplete="new-password"
                disabled={disabled}
                 />
            {error !== undefined ? (
                <div className="invalid-feedback custom-position-absolute" style={errorStyle}>
                    {error}
                </div>
            ) : ''}
             {warning !== undefined ? (
                <div className="iinvalid-feedback custom-position-absolute warn">
                    {warning}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderTextField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderTextField;
