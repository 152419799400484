//GUEST
export const HOME = '';
export const PAGE_404 = 'page-notfound';//update information student

// Counselor
export const SMART_ADVICE = 'smart-advice';
export const WIRED_ADVICE = 'wired-advice';
export const WIRELESS_ADVICE = 'wireless-advice';

export const ADVISORY_HISTORY = 'advisory-history';
export const CREATE_REPORT_SALE = 'create-report-sale';
export const SALES_SETTLEMENT = 'sales-settlement';
export const CALENDAR = 'calendar';

export const FORGOT_ID = 'forgot-id';
export const RETURN_FORGOT_ID = 'return-forgot-id';
export const FORGOT_PASSWORD = 'forgot-password';
export const RETURN_FORGOT_PASSWORD = 'return-forgot-password';
export const SIGN_IN = 'sign-in';
export const SIGN_UP = 'sign-up';
// export const SIGN_UP_STEP_1 = 'sign-up/step-1';
// export const SIGN_UP_STEP_2 = 'sign-up/step-2';
// export const SIGN_UP_STEP_3 = 'sign-up/step-3';

export const COMPARE = 'compare';

export const MANAGER_NOTIFICATION = 'manager-notification';
export const NOTIFICATION_DETAIL = 'notification-detail';
export const NOTIFICATION_CREATE = 'notification-create';
export const SALES_DOCUMENT = 'sales-document';
export const SALES_DOCUMENT_CREATE = 'sales-document-create';
export const SALES_DOCUMENT_DETAIL = 'sales-document-detail';


export const MANAGER_SALES = 'manager-sales';
export const PAYMENT_MANAGEMENT = 'payment-management';
export const PAYMENT_HISTORY = 'payment-history';
export const MY_COUPON = 'my-coupon';
export const MY_PROFILE = 'my-profile';
export const SALE_CREATE = 'sale-create';
export const EDIT_PROFILE = 'edit-profile';

export const SALES = 'sales';
export const STATISTICAL = 'statistical';
export const TRANSACTION_LOCATION = 'transaction-location';


export const SMART_CONSULTANT = "smart-consultant";
// support money
export const SUPPORT_MONEY = 'support-money';
// ROUTE FOR KD-003-4
export const DISCLOSURE_FUND = 'disclosure-fund';
export const PAPERMONEY = 'paper-money';

// ROUTE FOR KD-003-5
export const DISCOUNT_POINT = 'discount-point';

export const PLAN_INFORMATION = 'plan-information';
export const CHANGE_PLAN_INFORMATION = 'change-plan-information';
export const CHANGE_DATE_PLAN_INFORMATION = 'change-date-plan-information';
export const HISTORY_BUY_PRODUCT = 'history-buy-product';
export const SETTLEMENT = 'settlement';

export const TRANSACTION_POINT = 'transaction-point';

export const HISTORY_CONSULTANT = 'history-consultant';

// payment
export const PAYMENT = 'payment';

// ROUTE FOR KD-003-4
export const CREATE_MONNEY_SUPPORT = 'create-monney-support';
export const MONNEY_SUPPORT_DETAIL = 'monney-support-detail';
