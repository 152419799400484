import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class Calendar extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                <section>
                <div className="container">
            <div className="calendar_container">
                <div className="calendar_header">
                    <div className="box_left">
                        <span className="title_calendar">2020년 5월</span>
                        <a href="" className="arrow_calendar"><img src="/images/arrow-pre.svg" alt=""/></a>
                        <a href="" className="arrow_calendar"><img src="/images/arrow-next.svg" alt=""/></a>
                    </div>
                    <div className="box_right">
                        <button className="btn btn_calendar mr-3"><span>오늘</span></button>
                        <ul className="nav nav-tabs list-tab" id="myTab" role="tablist">
                            <li className="nav-item tab-item">
                                <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">일간</a>
                            </li>
                            <li className="nav-item tab-item">
                                <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">주간</a>
                            </li>
                            <li className="nav-item tab-item">
                                <a className="nav-link " id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">월간</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                        <div className="row">
                            <div className="col-5">
                                <div className="table_calendar">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>월</th>
                                                <th>화</th>
                                                <th>수</th>
                                                <th>목</th>
                                                <th>금</th>
                                                <th>토</th>
                                                <th>일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            31
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            1
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            2
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            3
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item grey"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number active">
                                                            4
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            5
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            6
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            7
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            8
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            9
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item blue"></span>
                                                            <span className="icon-item grey"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            10
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            11
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            12
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            13
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item yellow"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            14
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            15
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item red"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            16
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            17
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            18
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item blue"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            19
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            20
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            21
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            22
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            23
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            24
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            25
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            26
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            27
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item blue"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            28
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            29
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            30
                                                        </span>
                                                        <div className="icon-event">
                                                            <span className="icon-item grey"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            31
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            1
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            2
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="number-day">
                                                        <span className="number">
                                                            3
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="box_event">
                                    <div className="header_box">
                                        <span>태그</span>
                                        <a href="" data-toggle="modal" data-target="#modal-79">
                                            <span><i className="fas fa-plus"></i></span>
                                        </a>
                                    </div>
                                    <ul className="list_event_item list_event_setting">
                                        <li className="item blue">
                                           <div className="d-flex">
                                                <span className="tag"></span><span className="border_tag"></span><span className="tag_name">계약</span>
                                           </div>
                                            <button className="btn btn-setting p-0">
                                                <img src="../../images/icon-setting.svg" alt=""/>
                                            </button>
                                            <ul className="list-setting">
                                                <li className="active" data-toggle="modal" data-target="#modal-92"><i className="far fa-edit"></i> 태그 수정</li>
                                                <li data-toggle="modal" data-target="#modal-35"><i className="far fa-trash-alt"></i> 태그 삭제</li>
                                            </ul>
                                        </li>
                                        <li className="item green">
                                            <div className="d-flex">
                                                <span className="tag"></span><span className="border_tag"></span><span className="tag_name">계약</span>
                                            </div>
                                            <button className="btn btn-setting p-0">
                                                <img src="../../images/icon-setting.svg" alt=""/>
                                            </button>
                                        </li>
                                        <li className="item grey">
                                            <div className="d-flex">
                                                <span className="tag"></span><span className="border_tag"></span><span className="tag_name">계약</span>
                                            </div>
                                            <button className="btn btn-setting p-0">
                                                <img src="../../images/icon-setting.svg" alt=""/>
                                            </button>
                                        </li>
                                        <li className="item red">
                                            <div className="d-flex">
                                                <span className="tag"></span><span className="border_tag"></span><span className="tag_name">계약</span>
                                            </div>
                                            <button className="btn btn-setting p-0">
                                                <img src="../../images/icon-setting.svg" alt=""/>
                                            </button>
                                        </li>
                                        <li className="item yellow">
                                            <div className="d-flex">
                                                <span className="tag"></span><span className="border_tag"></span><span className="tag_name">계약</span>
                                            </div>
                                            <button className="btn btn-setting p-0">
                                                <img src="../../images/icon-setting.svg" alt=""/>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="box_calendar calendar_form">
                                    <table className="table table-head mb-1">
                                        <thead>
                                            <tr><th><span className="text-number">월</span><br/><span className="number">31</span></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr></thead>
                                    </table>
                                   <div className="table-scroll">
                                    <table className="calendar month mont-event">

                                        <tbody>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 7시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 8시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7">
                                                    <div className="item-note">
                                                        <div className="content-note blue" data-toggle="modal" data-target="#modal-34">
                                                            <span>이민호 고객님 약속</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 9시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 7시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 10시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7">
                                                    <div className="item-note">
                                                        <div className="content-note red" data-toggle="modal" data-target="#modal-34">
                                                            <span>이민호 고객님 약속</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 11시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7">
                                                    <div className="item-note">
                                                        <div className="content-note grey" data-toggle="modal" data-target="#modal-34">
                                                            <span>이민호 고객님 약속</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 12시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 1시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 2시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 3시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 7시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                            <tr>
                                                <th>
                                                   <div className="text-mont">
                                                        <span>오전 7시</span>
                                                   </div>
                                                </th>
                                                <td colSpan="7"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span className="btn_add_event" data-toggle="modal" data-target="#modal-33"></span>
                                   </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div className="box_calendar">
                            <table className="table table-head mb-1">
                                <thead>
                                    <tr><th></th>
                                    <th> <span className="text-number">월</span><br/><span className="number">31</span></th>
                                    <th><span className="text-number">화</span><br/><span className="number">1</span></th>
                                    <th><span className="text-number">수</span><br/><span className="number">2</span></th>
                                    <th><span className="text-number">목</span><br/><span className="number">3</span></th>
                                    <th><span className="text-number">금</span><br/><span className="number">4</span></th>
                                    <th><span className="text-number">토</span><br/><span className="number">5</span></th>
                                    <th><span className="text-number">일</span><br/><span className="number">6</span></th>
                                </tr></thead>
                            </table>
                           <div className="table-scroll">
                            <table className="calendar month">

                                <tbody>
                                    <tr>
                                        <th>
                                           <div className="text-mont">
                                                <span>오전 7시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td>
                                            <div className="item-note">
                                                <div className="content-note blue" data-toggle="modal" data-target="#modal-34">
                                                    <span>이민호 고객님 약속</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 8시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <div className="item-note">
                                                <div className="content-note yellow" data-toggle="modal" data-target="#modal-34">
                                                    <span>이민호 고객님 약속</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 9시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <div className="item-note">
                                                <div className="content-note red" data-toggle="modal" data-target="#modal-34">
                                                    <span>이민호 고객님 약속</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 10시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 11시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td>
                                            <div className="item-note">
                                                <div className="content-note blue" data-toggle="modal" data-target="#modal-34">
                                                    <span>이민호 고객님 약속</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <div className="item-note w220">
                                                <div className="content-note green" data-toggle="modal" data-target="#modal-34">
                                                    <span>이민호 고객님 약속</span>
                                                </div>
                                            </div>
                                            <div className="item-note item-note-two mt-4">
                                                <div className="content-note box-none">
                                                    <span>3개 더보기</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 12시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 1시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="text-mont">
                                                <span>오전 2시</span>
                                           </div>
                                        </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                       <th>
                                        <div className="text-mont">
                                            <span>오전 3시</span>
                                       </div>
                                       </th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className="btn_add_event" data-toggle="modal" data-target="#modal-33"></span>
                           </div>
                           
                        </div>
                    </div>
                    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                        <div className="box_calendar week-day-form">
                            <div className="table-scroll">
                                <table className="calendar">
                                    <thead>
                                        <tr><th>월</th>
                                        <th>화</th>
                                        <th>수</th>
                                        <th>목</th>
                                        <th>금</th>
                                        <th>토</th>
                                        <th>일</th>
                                    </tr></thead>
                                    <tbody>
                                        <tr>
                                            <td className="disable">31</td>
                                            <td>
                                                1
                                            </td>
                                            <td>
                                                2
                                                <div className="item-note">
                                                    <div className="content-note blue" data-toggle="modal" data-target="#modal-34">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                                <div className="item-note item-note-two">
                                                    <div className="content-note box-none">
                                                        <span>3개 더보기</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>5</td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>8</td>
                                            <td>9</td>
                                            <td>10</td>
                                            <td>
                                                11
                                                <div className="item-note">
                                                    <div className="content-note grey" data-toggle="modal" data-target="#modal-34">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                                <div className="item-note item-note-two">
                                                    <div className="content-note red" data-toggle="modal" data-target="#modal-34">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>12</td>
                                            <td>13</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>15</td>
                                            <td>16</td>
                                            <td>17</td>
                                            <td>18</td>
                                            <td>19</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>21</td>
                                            <td>22</td>
                                            <td>
                                                23
                                                <div className="item-note">
                                                    <div className="content-note green" data-toggle="modal" data-target="#modal-34">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                                <div className="item-note item-note-two">
                                                    <div className="content-note green">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>24</td>
                                            <td>25</td>
                                            <td>
                                                26
                                                <div className="item-note">
                                                    <div className="content-note blue" data-toggle="modal" data-target="#modal-34">
                                                        <span>이민호 고객님 약속</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>27</td>
                                        </tr>
                                        <tr>
                                            <td>28</td>
                                            <td>29</td>
                                            <td>30</td>
                                            <td>31</td>
                                            <td className="disable">1</td>
                                            <td className="disable">2</td>
                                            <td className="disable">3</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span className="btn_add_event" data-toggle="modal" data-target="#modal-33"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(Calendar);

