import React from "react";
import { Modal, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse } from 'reactstrap';
import ModalPrintChooseNetwork from "./ModalPrintChooseNetwork";
import api from "../../utils/api";
import queryString from 'query-string';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, getFormValues, change } from 'redux-form';
import { updateOptionPrint } from './../../store/actions/auth';
import { isBuffer } from "lodash";
import MoveToPCPopup  from './../../components/Print/MoveToPCPopup';
import PopupInactive from './../../components/PopupInactive';
import {FORM_BUILDER_TYPE} from './../../utils/constant';
import WirelessAdvice from "../../containers/Advice/WirelessAdvice";

// sk - 프리미엄패스
// kt - 심플코스
// lg - 식스플랜

class ModalPrintPageWireless extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalChooseNetwork: false,
            modalChooseNetworkChildren: false,
            data: {
                deviceSerialNumber: '',
                simSerialNumber: '',
                autoFill: true,
                cardDiscount: true,
                seller: true,
                customer: true,
                contactPlan: '0',
                storeName: false,
                sellerName: false,
                contact: false,
                address: false,
                network: {
                    id: 0,
                    name: '미설정',
                    idCode: 0,
                    nameCode: '인증방법 선택',
                    nameGiro: ''
                },
            },
            codeSerial: [],
            codePlan: [],
            codePlanResult: [],
            formBuilderByTelcom: [],
            result: {},
            collapse: {},
            'target_classification': '0',
            'classification_of_subscriptions': '0',
            isOpenMoveToPC: false,
            loading: false,
            printState: false,
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
        }
    }

    componentDidMount() {
        let data = {
            code: 'OPTION_VALIDATE_CONVERSION_NUMBER'
        }
        api.advice.getListCode(data).then(res => {
            this.setState({
                codeSerial: res.data.data
            })
        })
        
        api.advice.getListCode({ code: 'PLAN_COMMITMENT' }).then(response => {
            this.setState({
                codePlan: response.data.data
            })
        })
    }

    chooseNetwork = () => {
        this.setState({
            modalChooseNetwork: !this.state.modalChooseNetwork
        })
    }

    chooseNetworkChildren = () => {
        this.setState({
            modalChooseNetworkChildren: !this.state.modalChooseNetworkChildren
        })
    }

    valueNetwork = (value) => {
        if (value.type == 'network') {
            this.setState({
                data: {
                    ...this.state.data,
                    network: {
                        id: value.id,
                        name: value.name,
                        idCode: 0,
                        nameCode: '인증방법 선택',
                        nameGiro: ''
                    }
                }
            }, () => {
                this.chooseNetwork();
            })
        } else if (value.type == 'code') {
            this.setState({
                data: {
                    ...this.state.data,
                    network: {
                        ...this.state.data.network,
                        idCode: value.id,
                        nameCode: value.name
                    }
                }
            }, () => {
                this.chooseNetworkChildren();
            })
        }
    }

    getFromBuilder = (data) => {
        return api.consultant.getFormBuilder(data);
    }

    modalOpened = () => {
        //3477 플랜 요금제 변경 수정
        let { WirelessAdviceStep3 } = this.props.WirelessAdvice;//3477 플랜 요금제 변경 수정

        if (this.props.currentNetwork) {
            if (this.state.codePlan.length > 0) {
                let result = [];
                this.state.codePlan.map(item => {
                    if (this.props.currentNetwork == 17) {
                        if (item.id == 110) {
                            result.push(item)
                        }
                    } else if (this.props.currentNetwork == 18) {
                        if (item.id == 113 || item.id == 114) {
                            result.push(item)
                        }
                    } else if (this.props.currentNetwork == 19) {
                        if (item.id == 111 || item.id == 112) {
                            result.push(item)
                        }
                    }
                })
                
                this.setState({
                    codePlanResult: result,
                    data: {
                        ...this.state.data,
                        //contactPlan: result.length > 0 ? result[0].id.toString() : '0'
                        contactPlan: this.props.currentNetwork == 18 ? (result.length > 0 ? (WirelessAdviceStep3.disclosureSubsidyType == "six" ? '113' : '114' ) : '0') : (result.length > 0 ? result[0].id.toString() : '0') //서식지 수정사항
                    }
                }, () => {
                    if(this.props.auth.user?.option_print?.print_page_wireless){
                        let index =  this.props.auth.user?.option_print?.print_page_wireless.findIndex((item) => item.telcom == 17)
                        if(index !== -1){
                            this.setStateOptionPrint({
                                ...this.props.auth.user?.option_print?.print_page_wireless[index],
                                //contactPlan: result.length > 0 ? result[0].id.toString() : '0'
                                contactPlan: this.props.currentNetwork == 18 ? (result.length > 0 ? (WirelessAdviceStep3.disclosureSubsidyType == "six" ? '113' : '114' ) : '0') : (result.length > 0 ? result[0].id.toString() : '0')
                            })
                        }
                    }
                    let data = {
                        filter_telcom: this.props.currentNetwork,
                        fhb_type: 1,
                        fhb_wd_type: 'wireless',
                        consultant: this.props.WirelessAdvice.id,
                    }
                    console.log("무선상품 서식지 data", data)
                    this.getFromBuilder(data).then(res => {
                        this.setState({
                            formBuilderByTelcom: res.data.data
                        }, () => {
                            let { formBuilderByTelcom } = this.state;
                            if (formBuilderByTelcom.length > 0) {
                                let result = {};
                                formBuilderByTelcom.map(item => {
                                    let arrayChild = [];
                                    item.form_habitat_version.map(child => {
                                        arrayChild.push({
                                            fhv_id: child.fhv_id,
                                            fhv_name: child.fhv_name
                                        })
                                        result = {
                                            ...result,
                                            [`${item.fhb_sort}-${item.fhb_id}`]: arrayChild
                                        }
                                    })
                                });
                                this.setState({
                                    result
                                })
                            }
                        })
                    })
                })
            }
        }
    }

    onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name == 'nameGiro') {
            return this.setState({
                data: {
                    ...this.state.data,
                    network: {
                        ...this.state.data.network,
                        [name]: value
                    }
                }
            });
        }

        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    }

    onChangeBasic = (event) => {
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onChangeRadioData = (event) => {
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            data:{
                ...this.state.data,
                [name]: value
            }
        });
    }

    onClickCollapse = (e, item) => {
        e.stopPropagation();
        this.setState({
            collapse: {
                ...this.state.collapse,
                [`${item.fhb_sort}-${item.fhb_id}`]: !this.state.collapse[`${item.fhb_sort}-${item.fhb_id}`]
            }
        })
    }

    getResultById = (id, child) => {
        let { result } = this.state;
        if (result[id]) {
            let check = true;
            if (result[id].length > 0) {
                result[id].map((item, index) => {
                    if (item.fhv_id == child.fhv_id) {
                        check = false
                        result[id].splice(index, 1)
                    }
                })

                if (result[id].length == 0) {
                    delete result[id];
                }


                if (check) {
                    result[id].push({ fhv_id: child.fhv_id, fhv_name: child.fhv_name })
                }


            }
        } else {
            result = {
                ...result,
                [id]: [
                    { fhv_id: child.fhv_id, fhv_name: child.fhv_name }
                ]
            }
        }
        this.setState({
            result
        })


        // if(this.state.result[id]){
        //   delete this.state.result[id];
        //   return this.setState({
        //     result : this.state.result
        //   })
        // }
        //
        // this.setState({
        //   result : {
        //     ...this.state.result,
        //     [id] : {
        //       fhv_id : child.fhv_id,
        //       fhv_name : child.fhv_name
        //     }
        //   }
        // })
    }

    onClickCollapseGroup = (e, item) => {
        e.stopPropagation();
        let { result } = this.state;
        if (result[`${item.fhb_sort}-${item.fhb_id}`]) {
            delete result[`${item.fhb_sort}-${item.fhb_id}`];
            this.setState({
                result
            })
        } else {
            let arrayResult = [];
            if (item.form_habitat_version.length > 0) {
                item.form_habitat_version.map(child => {
                    arrayResult.push({
                        fhv_id: child.fhv_id, fhv_name: child.fhv_name
                    })
                })
            }

            if (arrayResult.length > 0) {
                result = {
                    ...result,
                    [`${item.fhb_sort}-${item.fhb_id}`]: arrayResult
                }
            }

            this.setState({
                result
            })
        }
    }

    checkActive = (keyResult, id) => {
        let { result } = this.state;
        let check = false;
        if (result[keyResult] && result[keyResult].length > 0) {
            result[keyResult].map(item => {
                if (item.fhv_id == id) {
                    check = true;
                }
            })
            return check;
        }
        return check;
    }

    typePrint = (type) => {
        if (this.props.auth.role == "Member" && this.props.WirelessAdvice.id) {
            let data = {
                sec_consultant_id: this.props.WirelessAdvice.id,
                sec_type: window.currentDevice == 'web' ? 0 : 1,
                sec_uid: this.props.auth.user.id,
                sec_type_export: type
            }
            api.consultant.createStatisticConsultantExport(data)
        }
    }

    onClickResultPage = () => {
        this.onClickClosePopup()
        let { data } = this.state;
        var ids = [];
        let result_mapped = this.mapResult()
        if (Object.keys(result_mapped).length > 0) {
            Object.keys(result_mapped).map(item => {
                result_mapped[item].map(child => {
                    ids.push(child.fhv_id)
                })
            })
        }

        let params = {
            'id[]': ids,
            deviceSerialNumber: data.deviceSerialNumber,
            simSerialNumber: data.simSerialNumber,
            consultantId: this.props.consultantId,
            autoFill: data.autoFill,
            cardDiscount: data.cardDiscount,
            division: (!!data.seller || !!data.customer),
            divisionSeller: data.seller,
            divisionCustomer: data.customer,
            contactPlan: data.contactPlan,
            storeName: data.storeName,
            sellerName: data.sellerName,
            contact: data.contact,
            address: data.address,
            telcom: data.network.id,
            numberType: data.network.idCode,
            number: data.network.nameGiro
        }
        var href = process.env.REACT_APP_API_URL + 'print-page-wireless?' + queryString.stringify(params);
        if (ids.length > 0) {
          if (window.currentDevice == 'web') {
            window.shareOnWeb(href);
            
            setTimeout(() => {
                this.props.toggleParent();
            }, 200)
          } else {
            let { WirelessAdvice } = this.props;    

            let telcomID = WirelessAdvice.WirelessAdviceStep1?.telcom;
            let packageFee = WirelessAdvice.WirelessAdviceStep3?.packageFee?.plac_name || WirelessAdvice.WirelessAdviceStep3?.packageFee?.name
            let device = WirelessAdvice.WirelessAdviceStep3?.phone?.device_name || WirelessAdvice.WirelessAdviceStep3?.phone?.name
            let info = `${device} </br> ${packageFee}`
            let more_info = `${device} ${packageFee}`
            let total = WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyDevice;
            // debugger
            // return 
    
            let dataPrint = {
              'uid': this.props.auth?.user?.id,
              'print_type': FORM_BUILDER_TYPE.BUILDER_WIRELESS,
              'telcomID': telcomID,
              'info': info,
              'more_info': more_info,
              'total': total,
              'link': href,
            }
            
            this.setState({
                ...this.state,
                loading: true
            }, () => {
                api.appPrint.create(dataPrint).then(res => {
                    if (res?.data?.data) {
                      this.setState({
                        ...this.state,
                        isOpenMoveToPC: true,
                        loading: false
                      })
                    }
                  })
            })
          }

          this.typePrint(0);
        } else {
            alert('출력할 서식지를 1개 이상 선택해 주세요.')
        }

        
    }

    mapResult = () => {
        const { result, formBuilderByTelcom } = this.state
        let ids = [];
        formBuilderByTelcom.length > 0 && formBuilderByTelcom.map((item, index) => {
            if (item.condition) {
                if (
                    item.condition.classification_of_subscriptions && !item.condition.classification_of_subscriptions.includes(this.state.classification_of_subscriptions)
                ) {
                    return null;
                }
                if (
                    item.condition.target_classification && !item.condition.target_classification.includes(this.state.target_classification)
                ) {
                    return null;
                }
            }
            ids.push(`${item.fhb_sort}-${item.fhb_id}`)
        })
        let a = {}
        Object.keys(result).map((id) => {
            if (ids.includes(id)) {
                a[id] = result[id]
            }
        })
        return a
    }

    onClickClosePopup = (e) => {
        let data = null;
        let object= null;
        object = {
            telcom: 17,
            classification_of_subscriptions: this.state.classification_of_subscriptions,
            target_classification: this.state.target_classification,
            autoFill: this.state.data.autoFill,
            cardDiscount: this.state.data.cardDiscount,
            seller: this.state.data.seller,
            customer: this.state.data.customer,
            storeName: this.state.data.storeName,
            sellerName:this.state.data.sellerName,
            contact:this.state.data.contact,
            address:this.state.data.address,
            contactPlan:this.state.data.contactPlan,
        }
        if(this.props.auth?.user?.option_print?.print_page_wireless) {
            data = {
                ...this.props.auth?.user?.option_print,
                print_page_wireless:[
                    ...this.props.auth?.user?.option_print?.print_page_wireless,
                ]
            }
            let index = data.print_page_wireless.findIndex((item) => item.telcom == 17)
            if(index !== -1) {
                data.print_page_wireless[index] = object;
            } else {
                data.print_page_wireless.push(object);
            }
        } else {
            data = {
                ...this.props.auth?.user?.option_print,
                print_page_wireless:[object]
            }
        }
        api.global.saveOptionPrint(data).then(res => {
            this.props.updateOptionPrint(res.data?.data?.option_print)
            this.setState({
                printState:false
            })
        }).catch(err => {
        })
        this.props.toggle();
    }

    componentWillReceiveProps = (newProps) => {    
        if(newProps.auth.user?.option_print?.print_page_wireless && !this.state.printState){
            let index =  newProps.auth.user?.option_print?.print_page_wireless.findIndex((item) => item.telcom == 17)
            if(index !== -1){
                this.setStateOptionPrint(newProps.auth.user?.option_print?.print_page_wireless[index])
            }
        }
        
    }

    setStateOptionPrint = (data) => {
        if(data) {
            this.setState({
                data:{  
                    ...this.state.data,
                    autoFill: data.autoFill,
                    cardDiscount: data.cardDiscount,
                    seller: data.seller,
                    customer: data.customer,
                    storeName: data.storeName,
                    sellerName: data.sellerName,
                    contact: data.contact,
                    address: data.address,
                    deviceSerialNumber: '',
                    simSerialNumber: '',
                    network: {
                        id: 0,
                        name: '미설정',
                        idCode: 0,
                        nameCode: '인증방법 선택',
                        nameGiro: ''
                    },
                    contactPlan: data.contactPlan,
                },
                'target_classification': '0',
                'classification_of_subscriptions': '0',
                printState:true
            })
        }
    }

    onToggleMoveToPC = () => {
      this.setState({
        ...this.state,
        isOpenMoveToPC: !this.state.isOpenMoveToPC,
      })
    }

    openPopupDisable = (state) => {
        var ids = [];
        let result_mapped = this.mapResult()
        if (Object.keys(result_mapped).length > 0) {
            Object.keys(result_mapped).map(item => {
                result_mapped[item].map(child => {
                    ids.push(child.fhv_id)
                })
            })
        }

        if (ids.length == 0) {
            alert('출력할 서식지를 1개 이상 선택해 주세요.')
            return;
        }

        if (state) {
          this.setState({
            title: '실제 개통에 사용하지 마십시오.',
            content: `
              베타테스트 기간입니다.<br>
              테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
              서류 미비나 금액 오류 등이 발생할 수 있습니다.
            `
          })
        }
        this.setState({
          isOpenPopupDisable: true
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    iconLock: null
                })
            }, 500)
        })
    }

    modelPrintClose = () => {
        this.setState({
          title: null,
          content: null
        })
    }

    render() {
        let { data, codePlanResult, formBuilderByTelcom, result, collapse } = this.state;

        let arrayValueResult = [];
        if (Object.keys(result).length > 0) {
            Object.keys(result).map(item => {
                if (result[item].length > 0) {
                    result[item].map(child => {
                        arrayValueResult.push(child)
                    })
                }
            })
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.onClickClosePopup}
                    className={'modal-dialog-centered modal-xl modal-output modal_customd_10'}
                    onOpened={this.modalOpened}
                    contentClassName={'pl-5 pr-5'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">서식지 출력</h5>
                        <button
                            type="button"
                            className="close close-modal"
                            onClick={(e) => this.onClickClosePopup(e)}
                        >
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <form className="form-modal-output">
                            <h2>기본설정</h2>
                            <div className="flex-output">
                                <div className="flex-item">
                                    <div className="item" style={{ "height": "128px" }}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 label-text col-form-label">가입유형</label>
                                            <div className="col-sm-9">
                                                <div className="group-radio">
                                                    <div className="custom-control custom-radio content_center ">
                                                        <input
                                                            type="radio"
                                                            id="classification_of_subscriptions0"
                                                            name="classification_of_subscriptions"
                                                            className="custom-control-input"
                                                            value={'0'}
                                                            checked={this.state.classification_of_subscriptions === '0' ? true : false}
                                                            onChange={this.onChangeBasic} />
                                                        <label className="custom-control-label" htmlFor="classification_of_subscriptions0">신규</label>
                                                    </div>
                                                    <div className="custom-control custom-radio content_center">
                                                        <input
                                                            type="radio"
                                                            id="classification_of_subscriptions1"
                                                            name="classification_of_subscriptions"
                                                            className="custom-control-input"
                                                            value={'1'}
                                                            checked={this.state.classification_of_subscriptions === '1' ? true : false}
                                                            onChange={this.onChangeBasic} />
                                                        <label className="custom-control-label" htmlFor="classification_of_subscriptions1">번호이동</label>
                                                    </div>
                                                    <div className="custom-control custom-radio content_center">
                                                        <input
                                                            type="radio"
                                                            id="classification_of_subscriptions2"
                                                            name="classification_of_subscriptions"
                                                            className="custom-control-input"
                                                            value={'2'}
                                                            checked={this.state.classification_of_subscriptions === '2' ? true : false}
                                                            onChange={this.onChangeBasic} />
                                                        <label className="custom-control-label" htmlFor="classification_of_subscriptions2">기기변경</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-0">
                                            <label className="col-sm-3 label-text col-form-label">출력양식</label>
                                            <div className="col-sm-9">
                                                <div className="group-radio">
                                                    <div className="custom-control custom-radio content_center">
                                                        <input
                                                            type="radio"
                                                            id="target_classification0"
                                                            name="target_classification"
                                                            className="custom-control-input"
                                                            value={'0'}
                                                            checked={this.state.target_classification === '0' ? true : false}
                                                            onChange={this.onChangeBasic} />
                                                        <label className="custom-control-label" htmlFor="target_classification0">성인</label>
                                                    </div>
                                                    <div className="custom-control custom-radio content_center">
                                                        <input
                                                            type="radio"
                                                            id="target_classification1"
                                                            name="target_classification"
                                                            className="custom-control-input"
                                                            value={'1'}
                                                            checked={this.state.target_classification === '1' ? true : false}
                                                            onChange={this.onChangeBasic} />
                                                        <label className="custom-control-label" htmlFor="target_classification1">청소년</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item pr-3" style={{ "height": "128px" }}>
                                        <div className="form-group row">
                                            <label className="col-sm-4 label-text col-form-label">기기 일련번호</label>
                                            <div className="col-sm-8 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={data.deviceSerialNumber}
                                                    onChange={this.onChange}
                                                    name={'deviceSerialNumber'}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-0">
                                            <label className="col-sm-4 label-text col-form-label">유심 일련번호</label>
                                            <div className="col-sm-8 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={data.simSerialNumber}
                                                    onChange={this.onChange}
                                                    name={'simSerialNumber'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-item accordion-status">
                                    <div className="accordion" id="accordionExample">

                                        {formBuilderByTelcom.length > 0 && formBuilderByTelcom.map((item, index) => {
                                            if (item.condition) {
                                                if (
                                                    item.condition.classification_of_subscriptions && !item.condition.classification_of_subscriptions.includes(this.state.classification_of_subscriptions)
                                                ) {
                                                    return null;
                                                }
                                                if (
                                                    item.condition.target_classification && !item.condition.target_classification.includes(this.state.target_classification)
                                                ) {
                                                    return null;
                                                }
                                            }

                                            return (
                                                <div className={`card ${result[`${item.fhb_sort}-${item.fhb_id}`] ? 'active-card' : ''}`} key={index}>
                                                    <div className="card-header p-0">
                                                        <h2 className="mb-0 p-0">
                                                            <button className={`btn btn-block text-left ${result[`${item.fhb_sort}-${item.fhb_id}`] ? '' : 'collapsed'}`}   //
                                                                type="button"
                                                                data-toggle="collapse"
                                                                data-target="#collapseOne1"
                                                                aria-expanded="false"
                                                                aria-controls="collapseOne1"
                                                                onClick={(e) => this.onClickCollapseGroup(e, item)}
                                                            //onClick={()=>this.onClickCollapse(item.fhb_id)}
                                                            >
                                                                <i className="fas fa-check"
                                                                    onClick={(e) => this.onClickCollapseGroup(e, item)}
                                                                />
                                                                {item.fhb_name}
                                                                <span className="img-collapse"
                                                                    onClick={(e) => this.onClickCollapse(e, item)}
                                                                >
                                                                    {collapse[`${item.fhb_sort}-${item.fhb_id}`]
                                                                        ? <img
                                                                            src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close-01.svg`}

                                                                        />
                                                                        : <img
                                                                            src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close.svg`}
                                                                        //onClick={(e)=>this.onClickCollapse(e,item.fhb_id)}
                                                                        />}

                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    {item.form_habitat_version.length > 0 &&
                                                        <Collapse isOpen={collapse[`${item.fhb_sort}-${item.fhb_id}`]}>
                                                            <div className="card-body p-0">
                                                                <ul className="list-status bg-white">

                                                                    {item.form_habitat_version.map((child, i) => {
                                                                        return (
                                                                            <li
                                                                                className={`item-status ${result[`${item.fhb_sort}-${item.fhb_id}`] && this.checkActive(`${item.fhb_sort}-${item.fhb_id}`, child.fhv_id) ? 'active' : ''}`}
                                                                                key={i}
                                                                                onClick={() => this.getResultById(`${item.fhb_sort}-${item.fhb_id}`, child)}
                                                                            >
                                                                                <i className="fas fa-check" />
                                                                                {child.fhv_name}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </Collapse>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-output mt-4">
                                <div className="flex-item pr-3">
                                    <div className="form-group h-32 mb-1">
                                        <label className="label-text">번호이동 옵션 설정</label>
                                    </div>
                                    <div className="form-group h-44 row">
                                        <label className="label-text mb-0 col-sm-4">이전 통신사</label>
                                        <div className="col-sm-4 pl-0">
                                            <button
                                                className="btn btn-input-form mb-0 w-113"
                                                type="button"
                                                onClick={this.chooseNetwork}
                                            >
                                                {data.network.name}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group h-44 row">
                                        <label className="label-text mb-0 col-sm-4">번호이동 인증</label>
                                        <div className="col-sm-4 pl-0">
                                            <button
                                                className="btn btn-input-form mb-0 w-113"
                                                type="button"
                                                onClick={data.network.id != 0 ? this.chooseNetworkChildren : null}
                                                disabled={(data.network.id == 0) ? true : false}
                                                style={{backgroundColor: (data.network.id == 0) ? '#F0F1F5' : 'initial'}}
                                            >
                                                {data.network.nameCode}
                                            </button>
                                        </div>
                                        <div className="col-sm-4 pl-0">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={'인증번호 4자리'}
                                                disabled={data.network.idCode == 0 || data.network.idCode == 'giro' ? true : false}
                                                value={data.network.nameGiro}
                                                name={'nameGiro'}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-item bg-white">
                                    <div className="form-group h-32 mb-1">
                                        <div className="group-check justify-content-between">
                                            <div className="form-check">
                                                <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customControlAu1226"
                                                        name={'autoFill'}
                                                        onChange={this.onChange}
                                                        checked={data.autoFill}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customControlAu1226">자동기입</label>
                                                </div>
                                            </div>

                                            <div className="form-check mr-0">
                                                <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customContro2112"
                                                        name={'cardDiscount'}
                                                        onChange={this.onChange}
                                                        checked={data.cardDiscount}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customContro2112"><span className="mr-1">카드할인</span> <span>|</span> </label>
                                                </div>
                                            </div>



                                            <label className="label-text mr-3">형광펜</label>
                                            <div className="form-check">
                                                <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customContro12312"
                                                        name={'seller'}
                                                        onChange={this.onChange}
                                                        checked={data.seller}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customContro12312">판매자 </label>
                                                </div>
                                            </div>


                                            <div className="form-check">
                                                <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customContro12412"
                                                        name={'customer'}
                                                        onChange={this.onChange}
                                                        checked={data.customer}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customContro12412">고객 </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.props.currentNetwork == 17 || this.props.currentNetwork == 19
                                        ?
                                        <div className="form-group h-44 row">
                                            <label className="label-text mb-0 col-sm-3">약정플랜</label>
                                            <div className="col-sm-9 pl-0">
                                                <div className="group-radio">
                                                    {codePlanResult.length > 0 && codePlanResult.map((item, index) => {
                                                        return (
                                                            <div className="custom-control custom-radio content_center" key={index}>
                                                                <input
                                                                    type="radio"
                                                                    id={`contact_plan_${index}`}
                                                                    name="contactPlan"
                                                                    className="custom-control-input"
                                                                    checked={data.contactPlan == item.id ? true : false}
                                                                    value={item.id.toString()}
                                                                    onChange={this.onChangeRadioData}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`contact_plan_${index}`}>{item.name}</label>
                                                            </div>
                                                        )
                                                    })}

                                                    <div className="custom-control custom-radio content_center" >
                                                        <input
                                                            type="radio"
                                                            id={`contact_plan_10`}
                                                            name="contactPlan"
                                                            className="custom-control-input"
                                                            checked={data.contactPlan == '0' ? true : false}
                                                            value={'0'}
                                                            onChange={this.onChangeRadioData}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`contact_plan_10`}>미표기</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}


                                    <div className="form-group h-44 row">
                                        <label className="label-text mb-0 col-sm-3">매장정보</label>
                                        <div className="col-sm-9 pl-0">
                                            <div className="group-check">
                                                <div className="form-check mr-1">
                                                    <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customControlAu1227"
                                                            name={'storeName'}
                                                            checked={data.storeName}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customControlAu1227">매장명</label>
                                                    </div>
                                                </div>
                                                <div className="form-check mr-1">
                                                    <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customContro2118"
                                                            name={'sellerName'}
                                                            checked={data.sellerName}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customContro2118">판매자</label>
                                                    </div>
                                                </div>
                                                <div className="form-check mr-1">
                                                    <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customContro12319"
                                                            name={'contact'}
                                                            checked={data.contact}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customContro12319">연락처
                                                    </label>
                                                    </div>
                                                </div>
                                                <div className="form-check mr-1">
                                                    <div className="custom-control custom-checkbox mr-sm-2 content_center">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customContro1245"
                                                            name={'address'}
                                                            value={data.address}
                                                            onChange={this.onChange}
                                                            checked={data.address}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customContro1245">주소
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer justify-content-center mt-3 pt-4 pb-4">
                        <button
                            type="button"
                            className="btn btn-gray pl-5 pr-5"
                            onClick={(e) => this.onClickClosePopup(e)}
                        >
                            취소</button>
                        <button
                            type="button"
                            className="btn btn-submit pl-5 pr-5"
                            // onClick={() => this.openPopupDisable(true)}
                            onClick={this.onClickResultPage}
                            disabled={this.state.loading}
                        >출력</button>
                    </div>
                </Modal>

                <ModalPrintChooseNetwork
                    isOpen={this.state.modalChooseNetwork}
                    toggle={this.chooseNetwork}
                    networks={this.props.networks}
                    valueNetwork={this.valueNetwork}
                    type={'network'}
                />

                <ModalPrintChooseNetwork
                    isOpen={this.state.modalChooseNetworkChildren}
                    toggle={this.chooseNetworkChildren}
                    networks={this.state.codeSerial}
                    valueNetwork={this.valueNetwork}
                    type={'code'}
                />

                <MoveToPCPopup
                  isOpen = {this.state.isOpenMoveToPC}
                  toggle = {this.onToggleMoveToPC}
                />
                <PopupInactive 
                    isOpenModal={this.state.isOpenPopupDisable} 
                    closePopupDisable={this.closePopupDisableHandle}
                    title={this.state.title || null}
                    content={this.state.content || null}
                    callback={this.onClickResultPage}
                    onModelClosed={this.modelPrintClose}
                    iconLock={this.state.iconLock}
                />

            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateOptionPrint: (data) => dispatch(updateOptionPrint(data)),
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);



export default compose(
    withConnect,
)(ModalPrintPageWireless);
