import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';
import validate from './validate-step-2';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import WiredFilter from "../../../components/Wired/WiredFilter"
import WiredProductClassification from './../../../components/Wired/ProductClassification/ProductClassification'
import WirelessAndWiredCombination from './../../../components/Wired/WirelessAndWiredCombination/WirelessAndWiredCombination'
import WiredDiscountCart from './../../../components/Wired/DiscountCart/DiscountCart'
import WiredInstallationFeeInformation from './../../../components/Wired/InstallationFeeInformation/InstallationFeeInformation'
import SetupFee from './../../../components/Wired/SetupFee/SetupFee'
import WiredEstimatedDiscount from './../../../components/Wired/EstimatedDiscount/EstimatedDiscount'
import WiredNote from './../../../components/Wired/Note/Note'
import Saler from './../../../components/Wired/Saler/Saler'
import api from "../../../utils/api";
import { priceFormat } from '../../../utils/helper';
import PenaltyInquiry from './../../../components/Wired/PenaltyInquiry/PenaltyInquiry'

class WiredStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookmark:false
    }
  }

  // componentDidMount() {
  //   WirelessAdviceStep3.saler.name = this.props.auth.role == 'Member' ? '' : this.props.auth.user.name
  // }

  bookmark = () => {
    const { WiredAdvice: { WiredAdviceStep2 }  } = this.props;

      let disnableCard=false
      WiredAdviceStep2.classify.map((value, key) => {
          let data=value.classify.filter(item=>item.device && item.device.wd_id)
          if(data.length > 0) {
              disnableCard=true
          }
      })
      if(disnableCard) {
          if(this.props.WiredAdvice.id != null) {
              api.advice.bookmarkWired(this.props.WiredAdvice.id)
                  .then(response => {
                      let res=response.data
                      if(res && res.data) {
                          this.setState({
                              bookmark:true
                          })
                          if(res.data && res.data.status) {
                              this.props.change('WiredAdviceStep2.bookmark', this.props.WiredAdvice.id)
                          }else {
                              this.props.change('WiredAdviceStep2.bookmark', null)
                          }
                      }
                  })
          }
      } else {
        alert('선택된 상품이 없습니다.');
      }


  }

  render() {
    let bookmark=false
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props;
    if(WiredAdviceStep2.bookmark != null) {
      bookmark=true
    }

    return (
      <Fragment>
        <div className="main-wrapper">
          <section className="wired-consultation">
            <div className="container">
              <div className="button-consultation">
                <WiredFilter />
              </div>
              <article>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div style={{"textAlign" : "right", "position": "absolute", "top" :"3px"}}>
                      <span class="text-content font-13">모비셀서비스는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.</span>
                    </div>
                    <div className="button-expected">
                      <div className="star-comment">
                        <span>{bookmark ? '즐겨찾기 해지' : '즐겨찾기'}</span>
                        <button
                          className={ bookmark ? 'btn btn-star p-0  active' : 'btn btn-star p-0 '}
                          onClick={() =>this.bookmark()}
                        >
                        </button>
                      </div>
                      {/*<button className="btn btn-gray-white" type="button" data-toggle="modal" data-target="#modal-10 ">*/}
                        {/*해지시 예상 위약금*/}
                      {/*</button>*/}
                      <PenaltyInquiry />
                    </div>
                  </div>

                  <div className="col-md-8">
                    <WiredProductClassification />
                  </div>
                  <div className="col-md-4">
                    <div className="right-article">
                      <div className="item-right">
                        <h4 className="title-form border-bottom-title pb-3 mb-3">
                          할인 선택
                        </h4>
                        <form>
                          <div>
                            <WirelessAndWiredCombination /> 
                            <WiredDiscountCart />
                          </div>
                          <div>
                            <div className="mb-3">
                              <p className="text-note">※ 청구금액은, 고객님의 사용내역에 따라 실제 청구금액과 상이
                                할 수 있습니다.</p>
                            </div>
                            <div
                              className="bottom_right">
                              <span className="font-weight-bold mt-3 font-14">결합 총할인 </span>
                              <p className="prices-form mb-0 pt-2">
                                {WiredAdviceStep2.totalCombinedDiscount ? '-' + priceFormat(WiredAdviceStep2.totalCombinedDiscount) : 0}
                                <span className="price-sub">원</span>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-payment">
                  <div>
                    <span className="font-weight-bold font-14">결합 후 월 납부 요금</span>
                    <small className="text-muted">(VAT 포함)</small>
                  </div>
                  <div>
                    <small className="text-muted">(VAT 포함)</small>
                    <span className="px-2">
                      <span className="total-price">
                        {priceFormat(
                          +WiredAdviceStep2.totalCombinedDiscount < +WiredAdviceStep2.monthlyCosts
                          ? +WiredAdviceStep2.monthlyCosts - +WiredAdviceStep2.totalCombinedDiscount
                          : 0
                        )}
                      </span>
                      <span className="price-sub">원</span></span>
                  </div>
                </div>
                <div className="box-payment">
                  <SetupFee />
                </div>
                <div className="row justify-content-end text-right mt-3">
                  <div className="col-md-6">
                    <p className="text-note">※설치비는 1회만 청구되며, 회선추가 시 설치비가 추가 청구될수 있습니다.</p>
                    <p className="text-note">※설치비는 신규 설치비이며, 이전/변경 설치비와는 다릅니다. </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h3 className="title-form font-16">월 청구 요금 내역</h3>
                  </div>
                  <div className="col-md-12">
                    <WiredInstallationFeeInformation />
                  </div>
                </div>
                <WiredEstimatedDiscount />
                <div className="row grape-record mt-2">
                  <div className="col-md-8">
                    <WiredNote />
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="form-group row align-items-center">
                      <label htmlFor="staticEmail"
                           className="col-sm-4 col-form-label label-text">매장명</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control h-44"
                             defaultValue={this.props.auth?.store?.sto_name || ''} disabled/>
                      </div>
                    </div>
                    <Saler />
                    <div className="form-group row align-items-center mb-0">
                      <label htmlFor="staticEmail"
                           className="col-sm-4 col-form-label label-text">전화번호</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control h-44"
                             value={this.props.auth?.user?.store[0]?.sto_phone_number || ""  } disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

WiredStep2.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  WiredAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WiredStep2);
