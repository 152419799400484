import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ContentRule = (props) => {
    return (
        <div className="left-product">
            <div className="top">
                <ul className="nav nav-tabs list-tab w-100 justify-content-between" id="myTabsa" role="tablist">
                    <li className="nav-item tab-item">
                        <a className={"nav-link " + ((props.data == undefined || props.data == 1) ? "active" : "")} id="rules-tab" data-toggle="tab" href="#rules" role="tab"
                            aria-controls="rules" aria-selected="true">전체 이용약관</a>
                    </li>
                    <li className="nav-item tab-item">
                        <a className={"nav-link " + (props.data == 2 ? "active" : "")} id="rules2-tab" data-toggle="tab" href="#rules2" role="tab"
                            aria-controls="rules2" aria-selected="false">개인정보취급방침</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="myTabCoantendt">
                <div className={"tab-pane fade " + ((props.data == undefined || props.data == 1) ? "show active" : "")} id="rules" role="tabpanel"
                    aria-labelledby="rules-tab">
                    <h4>
                        모비고 이용약관
</h4>
<article>
                                    <p><span>모비셀 이용약관 (이하 “본 약관”이라 합니다) 은 주식회사 아크가
                                            제공하는 모비셀 서비스의 이용과 관련하여 회원과 회사 사이에 필요한
                                            사항을 규정하며 아래와 같은 내용을 담고 있습니다.</span></p>
                                    <p className="mt-5"><strong>제1장 총칙</strong></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제1조 (목적)</strong></p>
                                    <p><span>본 약관은 주식회사 아크(이하 “회사”라 합니다)가 제공하는 모비셀
                                            서비스(이하 “서비스”라 합니다)의 이용과
                                            관련하여 회사와 이용 고객(이하 “회원”이라 합니다)간의 권리와 의무, 책임사항, 서비스 가입조건 기타 필요한
                                            제반사항을 구체적으로 규정함을 목적으로
                                            합니다&nbsp;&nbsp;</span></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제2조 (용어의 정의)</strong></p>
                                    <p className="mt-4"></p>
                                    <ul>
                                        <li><strong><span>본 약관에서 사용하는 용어의 정의는 다음과
                                                    같습니다.&nbsp;</span></strong></li>
                                    </ul>
                                    <p className="mt-4"></p>
                                    <ol className="ml-3">
                                        <li><span>회사: 회원이 이용하는 휴대폰
                                                판매 컨설팅 프로그램 모비셀 서비스를 운영 및 제공하는 &nbsp;
                                                주식회사 아크를 말합니다.&nbsp;</span></li>
                                        <li><span>회원: 본 약관에 동의하고
                                                서비스를 제공받기 위하여 회사와 이용 계약을 체결하여, ID를 발급받은
                                                자를 말합니다.</span></li>
                                        <li><span>이용계약: 서비스를 제공받기
                                                위하여 회원과 회사가 체결하는 계약을 말합니다.</span></li>
                                        <li><span>아이디(ID): 회원의 식별과
                                                회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하여 회사에
                                                등록된 영문과 숫자의 조합을 말합니다.&nbsp;</span></li>
                                        <li><span>비밀번호: 회원인지를 확인하고
                                                비밀을 보호하기 위해 회원 스스로가 설정해 등록한 문자와 숫자의
                                                조합을 말합니다.&nbsp;</span></li>
                                        <li><span>해지: 회사 또는 회원이
                                                이용계약을 해약하는 것을 말합니다.</span></li>
                                        <li><span>앱: 서비스를 제공받는 휴대폰에
                                                설치되는 어플리케이션(프로그램)을 말합니다.</span></li>
                                        <li><span>운영자: 서비스의 전반적인 관리와
                                                운영을 담당하는 회사의 직원을 말합니다.</span></li>
                                        <li><span>결제: 회원이 서비스 이용을
                                                위하여 회사가 제공하는 별도의 결제수단을 통하여 유료서비스에 대한
                                                이용요금을 회사에 지불하는 것을 말합니다.</span></li>
                                        <li><span>정기결제: 매월 자동으로 과금하는
                                                결제방식을 말합니다.</span></li>
                                    </ol>
                                    <ul>
                                        <li><span>본 약관에서 사용하는 용어의
                                                정의는 전항에서 정하는 것을 제외하고는 관계 법령 및 서비스별
                                                안내에서 정하는 바에 따르며, 그 외에는 일반적인 상관례에 따릅니다.</span></li>
                                    </ul>
                                    <p className="mt-4"></p>
                                    <p><strong>제3조 (약관의 명시·개정 및 효력)</strong></p>
                                    <ol>
                                        <li><span>회사는 본 약관의 내용을 회원이
                                                쉽게 알 수 있도록 모비셀 웹사이트 및 앱 초기화면에 게시하는
                                                방법으로 공지합니다.&nbsp;</span></li>
                                        <li><span>회사는 정보통신망이용촉진 및
                                                정보보호 등에 관한 법률, 약관의 규제에 관한 법률 등 관련법을
                                                위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.&nbsp;</span></li>
                                        <li><span>회사가 본 약관을 개정할 경우에는
                                                적용일자 및 개정사유를 명시하여 현행약관과 함께 회사 사이트의
                                                초기화면이나 연결화면에 그 개정약관을 적용일자 7일 전에 공지합니다. 다만, 회원에게 불리한 약관 걔정 또는 회원의 권리 또는
                                                의무에 관한 중요한 규정의 개정의 경우에는 적용일자 30일 이전에
                                                공지합니다.</span></li>
                                        <li><span>회사가 전항에 따라 개정약관을
                                                공지 또는 통지하면서 적용 예정일까지 거부의 의사표시를 하지 않으면
                                                동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도, 회원이 적용 예정일까지 명시적으로 거부의사를 표명하지 아니한 경우
                                                회원이 개정약관에 동의한 것으로 봅니다.&nbsp;</span>
                                        </li>
                                        <li><span>회원이 개정약관의 적용에 동의하지
                                                않는 경우 회사는 해당 회원에 대하여 개정약관의 내용을 적용할
                                                수 없으며, 이 경우 회원은 개정된 약관이 공지된 때로부터 30일 이내에 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
                                                다만, 회사가 개정약관 적용에 부동의 한 회원에게 기존 약관을
                                                적용할 수 없는 특별한 사정이 있는 경우에는 회사는 해당 회원과의 이용계약을 해지할 수 있습니다.&nbsp;</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제4조 (약관 외 준칙)&nbsp;</strong></p>
                                    <p><span>본 약관에 명시되지 아니한 사항과 본 약관의 해석에 관하여는 약관의 규제 등에 관한
                                            법률, 전기통신기본법, 전기통신사업법, 개인정보보호법, 정보통신망
                                            이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관계법령 또는 상관례, 회사가 정한
                                            서비스의 세부이용지침 등의 규정에 따릅니다.&nbsp;</span>
                                    </p>
                                    <p className="mt-4"></p>
                                    <p><strong>제2장 서비스 이용</strong></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제5조 (회원가입 및 이용 계약의 성립)</strong></p>
                                    <ol>
                                        <li><span>이용계약은 이용신청자가 회사가
                                                정한 가입절차상 신청양식을 작성하고 본 약관 및
                                                “개인정보 수집·이용 동의서”에 “동의”를 선택함으로써 서비스 이용계약
                                                체결을 신청하고, 회사가 이러한 신청을 승낙함으로써
                                                성립합니다.&nbsp;</span></li>
                                        <li><span>이용신청자가 전항의
                                                “동의”를 선택하는 것은, 본 약관 및
                                                “개인정보 수집·이용 동의서”의 내용을 숙지하고, 회사가 서비스 이용을 위해 운영하는 각종
                                                정책(개인정보처리방침, 저작권정책, 스팸정책 등)과 수시로 공지하는
                                                사항을 준수하는 것에 대해 동의하는 것을 의미합니다.</span></li>
                                        <li><span>회사는 제1항의 이용신청고객에
                                                대하여 업무상·기술상 지장이 없는 경우에는 원칙적으로
                                                접수순서에 따라 서비스 이용을 승낙합니다. 회사가 승낙한 경우, 회사는 회원ID와 기타 회사가 필요하다고 인정하는 내용을
                                                이용자에게 통지합니다.&nbsp;</span></li>
                                        <li><span>회사는 다음 각 호에 해당하는
                                                이용신청에 대하여는 승낙을 하지 않을 수
                                                있습니다.&nbsp;</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>실명이 아니거나 타인의 명의를
                                                도용하여 신청한 경우</span></li>
                                        <li><span>이용계약 신청서의 내용을 허위로
                                                기재한 경우</span></li>
                                        <li><span>사회의 안녕질서 또는 미풍양속을
                                                저해하거나 저해할 목적으로 신청한 경우</span></li>
                                        <li><span>부정한 용도로 본 서비스를
                                                이용하고자 하는 경우</span></li>
                                        <li><span>본 서비스와 경쟁관계에 있는
                                                회원이 신청한 경우</span></li>
                                        <li><span>기타 규정한 제반 사항을 위반하여
                                                신청하는 경우</span></li>
                                    </ol>
                                    <ol>
                                        <li><span>회사는 서비스 이용신청이 전항 각
                                                호에 해당하는 경우 그 신청에 대하여 승낙제한 사유가 해소될
                                                때까지는 승낙을 유보할 수 있습니다.&nbsp;</span></li>
                                        <li><span>회사는 이용신청고객이 개인 고객일
                                                경우에는 서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수
                                                있습니다.</span></li>
                                        <li><span>회사는 회원 가입절차 완료 이후
                                                제4항 각호에 따른 사유가 발견된 경우 이용 승낙을 철회할 수
                                                있습니다.</span></li>
                                        <li><span>회사의 서비스를 이용하기 위하여,
                                                이용신청자가 회사가 홈페이지 및 앱을 통해 게시하는 해당
                                                서비스의 사용료 정책상 월사용료를 지불함으로써 서비스가 개시되며, 최초 결제일이 정산 기준일자가 됩니다.</span></li>
                                        <li><span>서비스 만료일이 도래하여 서비스가
                                                종료한 경우, 회사에서 제공하는 서비스를 유지하고자 하는 회원은
                                                해당 서비스의 사용료 정책에 제시된 월사용료를 지불하고 회사가 이에 대하여 승낙함으로써 만료일을 연장할 수
                                                있습니다.&nbsp;</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제6조 (서비스 이용계약의 해지, 회원 퇴 및 자격 상실 등)&nbsp;</strong></p>
                                    <ol>
                                        <li><span>회원은 회사에 언제든지 탈퇴를
                                                요청할 수 있으며 회사는 즉시 탈퇴를 처리합니다.</span>
                                        </li>
                                        <li><span>회원이 다음 각 호의 사유에
                                                해당하는 경우, 회사는 회원 자격을 제한 및 정지시킬 수
                                                있습니다.</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>가입 신청 시에 허위 내용을
                                                등록한 경우</span></li>
                                        <li><span>다른 회원의 서비스 이용을
                                                방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는
                                                경우</span></li>
                                        <li><span>서비스를 이용하여 법령 또는 이
                                                약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span>
                                        </li>
                                    </ol>
                                    <ol>
                                        <li><span>회사가 회원 자격을
                                                제한</span><span>·</span><span>정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에
                                                그 사유가 시정되지 아니하는 경우 회사는 회원 자격을 상실시킬 수 있습니다.</span></li>
                                        <li><span>회사가 회원 자격을 상실시키는
                                                경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
                                                회원 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제7조 (회원에 대한 통지)&nbsp;</strong></p>
                                    <ol>
                                        <li><span>회사가 특정 회원에 대한 통지를
                                                하는 경우, 회원이 서비스 가입시 등록한 전화 또는 메일로
                                                서비스에 대한 통지를 할 수 있습니다.</span></li>
                                        <li><span>회사가 불특정 다수 회원에게
                                                통지를 하는 경우, 7일 이상 서비스 페이지에 게시함으로써 개별
                                                통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를
                                                합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제8조 (회원 정보의 제공 및 변경)</strong></p>
                                    <ol>
                                        <li><span>회원으로 가입하여 본 서비스를
                                                이용하고자 하는 이용신청자는 회사에서 요청하는 제반정보 (이름,
                                                이메일, 연락처 등)를 제공하여야 합니다.</span></li>
                                        <li><span>회원이 신청양식에 기재하는
                                                회원정보는 이용신청자의 실제정보인 것으로 간주되고, 실제정보를 입력하지
                                                않은 회원은 법적인 보호를 받을 수 없으며 서비스 이용에 제한을 받을 수 있습니다.</span></li>
                                        <li><span>타인의 개인정보를 도용하는 등
                                                부정한 목적과 방법으로 이용신청을 한 회원의 ID는 사전동지 없이
                                                삭제될 수 있으며, 당해 회원은 관계 법령에 따라 처벌을 받을 수 있습니다.</span></li>
                                        <li><span>회사는 회원의 서비스 이용계약
                                                체결 이후 필요한 경우 회원의 동의 하에 회원의 정보를 추가로
                                                수집할 수 있습니다.</span></li>
                                        <li><span>회원은 서비스 이용계약 체결 및
                                                이용을 위해 제공한 정보를, 회원 본인이 온라인으로 열람하고
                                                수정할 수 있습니다.&nbsp;</span></li>
                                        <li><span>회원이 기재한 사항이나 서비스
                                                이용과정에서 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는
                                                책임지지 않습니다.&nbsp;</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제9조 (회원 ID 변경 등)</strong></p>
                                    <ol>
                                        <li><span>회원ID는 원칙적으로 변경이
                                                불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를
                                                해지하고 재가입해야 합니다.&nbsp;</span></li>
                                        <li><span>회원 ID 및 비밀번호의 관리
                                                책임은 회원에게 있습니다. 이를 소홀이 관리하여 발생하는 서비스
                                                이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 모든 책임은 회원에게 있습니다.&nbsp;</span></li>
                                        <li><span>기타 회원 개인정보 관리 및 변경
                                                등에 관한 사항은 서비스 별 안내에 정하는 바에
                                                의합니다.&nbsp;</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제10조 (서비스의 이용 및 제공)</strong></p>
                                    <ol>
                                        <li><span>회사가 제공하는 모비셀 서비스는
                                                휴대폰 매장에서 단말기 및 유무선 통신 판매 컨설팅을 지원하는
                                                서비스입니다.&nbsp;</span></li>
                                        <li><span>모비셀 서비스를 이용하는 휴대폰
                                                매장은, 컨설팅 후 고객의 이름, 고객의 휴대폰번호, 휴대폰판매
                                                정보를 입력하여 판매 내역을 관리할 수 있습니다.</span></li>
                                        <li><span>서비스는 연중무휴 1일 24시간
                                                제공함을 원칙으로 합니다. 단, 회사는 서비스 제공에 필요한 경우
                                                정기점검을 실시할 수 있으며, 정기점검 시간은 회사가 사전에 통지한 바에 따릅니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제11조 (대금납부)</strong></p>
                                    <ol>
                                        <li><span>서비스에 대한 대금납부방법은 다음
                                                각 호의 하나로 할 수 있습니다.</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>계좌 자동이체</span>
                                        </li>
                                        <li><span>카드 자동이체</span>
                                        </li>
                                    </ol>
                                    <ol>
                                        <li><span>회사가 제공하는 모든 소프트웨어의
                                                사용 요금은 당사의 라이센스 가격표를 기준으로
                                                합니다.</span></li>
                                        <li><span>회사가 전항의 라이선스 가격표상
                                                요금제의 요금을 변경하는 경우, 변경된 날로부터 30일 내에는
                                                추가 요금의 변경이 불가능합니다. 요금제의 요금이 하향되는 경우 회원에게 차액 환불은 되지 않습니다.</span></li>
                                        <li><span>서비스 이용계약기간은 가입비 및
                                                월 사용료 지급과 동시에 시작되며, 계약기간 만료시 사용료 결제가
                                                이루어지면 기간이 연장됩니다. 서비스 사용료는 선불을 원칙으로 합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제12조 (결제방식)</strong></p>
                                    <ol>
                                        <li><span>결제 방식은 정기결제 방식으로
                                                이루어 지며, 결제수단은 관련 업체와의 협의에 따라 변경될 수
                                                있습니다.&nbsp;</span></li>
                                        <li><span>서비스 정기결제 상품은 지속적
                                                이용의 필요성이 있는 서비스 특성에 의한 것으로, 매월
                                                1일~말일까지의 1개월의 기간을 서비스 정기결제의 “서비스 이용기간” 이라 말합니다.</span></li>
                                        <li><span>서비스 정기결제 방식은, 회원이
                                                신청한 일정한 결제수단으로, 1개월 동안의 서비스 이용요금을
                                                서비스 이용기간 개시 전에 자동으로 결제를 진행하고, 결제가 완료된 서비스 이용기간 동안 서비스를 이용할 수 있는 방식입니다.
                                                서비스 정기결제 상품의 경우 회원이 정해진 절차에 따라 회사에
                                                이용중지의사를 밝히지 않을 경우 매월 계약이 갱신되는 것으로 간주되어 지속적으로 요금이 청구됩니다.</span></li>
                                        <li><span>회사는 매월 말일 서비스
                                                이용계약의 자동갱신 및 이용요금 청구를 위해 서비스 이용기간 및 서비스
                                                이용기간 종료 후 일정 기간 동안 회원의 결제 관련 정보를 보유할 수 있습니다.</span></li>
                                        <li><span>회원이 정기결제를 신청하면 별도의
                                                해지 신청을 하지 않을 경우 1개월(매월말일)마다 자동으로
                                                서비스 이용기간에 대한 서비스 이용요금이 결제됩니다. 회사는 결제가 이루어지기 전에 회원에게 결제될 내역에 대하여 고객 휴대폰
                                                또는 E-mail 등으로 고지합니다.</span></li>
                                        <li><span>정기결제 해지로 인한 서비스
                                                이용기간 만료일은 당월 말일까지입니다. 정기결제해지 시 회원은 서비스
                                                이용기간에서 이미 이용한 기간을 제외한 나머지 기간 동안 서비스를 이용할 수 있으며, 남은 서비스이용기간 만료일 후 결제가
                                                자동으로 진행되지 않습니다.</span></li>
                                        <li><span>회원이 타인의 결제정보를 동의
                                                없이 사용할 경우 민·형사상 책임을 지게 되며, 관계
                                                법령에 의해 처벌될 수 있습니다.</span></li>
                                        <li><span>결제 정보 변경, 신용카드
                                                분실/만료, 기타의 사유로 정기결제가 이루어 지지 않을 경우 자동결제가
                                                해지되며, 마지막 정기결제의 서비스 이용기간 만료일이 지난 후 서비스 이용이 자동 정지됩니다.&nbsp;</span></li>
                                        <li><span>회원의 사용료 미납 등 귀책사유로
                                                인하여, 정기 결제 중지 및 이에 따른 서비스 이용정지에 따른
                                                손해가 발생하는 경우 회사는 책임 지지 않습니다.&nbsp;</span></li>
                                        <li><span>정기결제 기간 중 요금상품
                                                변경으로 인한 차액발생 시, 회사는 변경된 가격으로 대금지급이 이루어
                                                지는 시점을 기준으로 일할 계산하여 회원에게 대금결제를 청구할 수 있습니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제13조 (환불)</strong></p>
                                    <ol>
                                        <li><span>회사는 회원이 청약철회를 하는
                                                경우 회원이 납부한 월 사용료에서 회원의 서비스 이용기간의 금액을
                                                일할 계산하여 공제하고 환불할 수 있습니다.&nbsp;</span></li>
                                        <li><span>회사는 환불할 경우 회원이
                                                신용카드 또는 전자화폐 등의 결제수단으로 이용요금을 지불한 경우 지체
                                                없이 당해 결제수단을 제공한 사업자로 하여금 이용요금의 청구를 정지 또는 취소하도록 요청합니다.&nbsp;</span></li>
                                        <li><span>회원이 직접 결제하지 않은 경우와
                                                이벤트 및 쿠폰 등으로 받은 유료 서비스를 이용하고 있던
                                                경우에는 환불이 불가합니다.&nbsp;</span></li>
                                        <li><span>회사는 회원이 청약철회를 한
                                                날로부터 3영업일 이내에 이미 지급받은 대금을 환급하여야
                                                합니다.</span></li>
                                        <li><span>개인정보 도용 및 부정결제 등으로
                                                인한 경우에는 환불되지 않으며, 해당 경우의 결제자 개인정보
                                                요구 및 확인은 관계법령에 근거한 수사기관의 정당한 요청을 통해서만 확인이 가능합니다.&nbsp;</span></li>
                                        <li><span>회원탈퇴 및 청약철회시 회원의
                                                오기(誤記)등 귀책사유로 인해 발생하는 불이익에 대해서 회사는
                                                책임을 지지 않습니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제14조 (권리의 양도 금지)</strong></p>
                                    <p><span>회원은 회사의 서면 동의 없이 본 약관상의 권리와 의무를 제3자에게 양도할 수 없으며,
                                            또한 서비스에 관하여 임대 또는 대여, 이용허락을 할 수
                                            없습니다.</span></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제3장 회사 및 회원의 의무 등</strong></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제15조 (회사의 의무)</strong></p>
                                    <ol>
                                        <li><span>회사는 회원이 희망하는 서비스
                                                제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록
                                                합니다.&nbsp;</span></li>
                                        <li><span>회사는 계속적이고 안정적인
                                                서비스의 제공을 위하여 설비에 장애가 생기거나 멸실 된 때에는 부득이한
                                                사유가 없는 한 지체 없이 이를 수리 또는 복구 합니다.&nbsp;</span></li>
                                        <li><span>회사는 회원의 회원정보를 보호하기
                                                위해 보안시스템을 구축 운영하며,
                                                “개인정보처리방침”을 공지하고 준수합니다. 또한, 회사는 “개인정보처리방침” 따라
                                                회원정보를 처리함에 있어 안정성 확보에 필요한 기술적 및 관리적
                                                대책을 수립.운영합니다.</span></li>
                                        <li><span>회사는 회원으로부터 제기되는
                                                의견이나 불만이 정당하다고 객관적으로 인정 될 경우에는 적절한 절차를
                                                거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리일정을 통지
                                                합니다.&nbsp;</span></li>
                                        <li><span>회사가 제공하는 서비스로 인하여
                                                회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에
                                                기해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제16조 (회원의 의무)</strong></p>
                                    <ol className="ml-3">
                                        <li><span>회원은 회원가입 신청 또는
                                                회원정보 변경 시 실제정보로 작성하여야 하며, 허위 또는 타인의 정보를
                                                이용한 사실이 발견된 경우 회원은 서비스 이용과 관련한 일체의 권리를 주장할 수 없습니다.</span></li>
                                        <li><span>회원은 관계법령, 약관 서비스
                                                이용안내 및 서비스상에 공지한 주의사항, 회사가 서비스 이용과
                                                관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.</span>
                                        </li>
                                        <li><span>회원은 주소 및 연락처,
                                                E-mail 등 이용계약사항이 변경 된 경우에는 이를 회사에 즉시 알려야
                                                합니다.&nbsp;</span></li>
                                        <li><span>회원은 회원 ID 및 비밀번호를
                                                철저히 관리하여야 하며, 관리소홀, 부정사용 등에 의하여 발생하는
                                                모든 결과에 대한 책임은 회원 본인이 부담하며, 회사는 고의 또는 과실이 없는 한 이에 대한 어떠한 책임도 부담하지
                                                않습니다.</span></li>
                                        <li><span>회원은 회사의 사전 승낙 없이
                                                서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에
                                                대해 회사는 책임을 지지 않습니다. 특히 해킹 및 광고를 통한 수익, 음란사이트를 통한 영리 추구 행위, 타인의 저작물 또는
                                                사용소프트웨어의 무단 전송 행위 등을 할 수 없습니다. 이와 같은
                                                활동으로 회사가 유무형의 손해를 입은 경우, 회원은 회사에 대해 손해배상 의무를 지며, 회사는 해당 회원에 대해 서비스 이용제한
                                                및 적법한 절차를 거쳐 손해배상 등을 청구할 수
                                                있습니다.&nbsp;</span></li>
                                        <li><span>회원은 회사 및 제3자의 지적
                                                재산권을 침해해서는 안됩니다.</span></li>
                                        <li><span>회원은 다음 각 호에 해당하는
                                                행위를 하여서는 아니 됩니다.</span></li>
                                    </ol>
                                    <ol>
                                        <li><span>회원가입 신청 또는 회원정보 변경
                                                시 허위 내용을 등록하거나, 다른 회원의 ID 및 비밀번호를
                                                도용, 부정하게 사용하는 행위</span></li>
                                        <li><span>회사의 운영진, 직원, 관계자 및
                                                기타 타인을 가장, 사칭하거나 명의를 도용하는 행위</span>
                                        </li>
                                        <li><span>서비스 운영을 고의로 방해하거나
                                                서비스의 안정적 운영을 방해할 수 있는 정보 자료를 게시 및
                                                저장하거나 E-mail로 발송하는 행위</span></li>
                                        <li><span>비정상적으로 과다한 트래픽을
                                                유발하는 행위</span></li>
                                        <li><span>범죄와 결부된다고 객관적으로
                                                판단되는 행위</span></li>
                                        <li><span>본 약관을 포함하여 기타 회사가
                                                정한 제반 규정 또는 이용 조건을 위반하는 행위</span>
                                        </li>
                                        <li><span>기타 불법적이거나 관계법령에
                                                위배되는 행위</span></li>
                                    </ol>
                                    <ol>
                                        <li><span>회원이 제7항 각 호에 명시된
                                                행위를 한 경우에 회사는 부가적으로 제공한 혜택의 일부 또는 전부의
                                                회수, 특정 서비스의 이용제한, 이용계약의 해지, 손해배상의 청구 등의 조치를 할 수 있습니다.</span></li>
                                        <li><span>회사가 전항의 조치를 할 경우
                                                회사는 사전에 회원에게 유선 또는 E-mail로 통보하여, 회원의
                                                연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우, 선 조치 후 통보할 수 있습니다.</span></li>
                                        <li><span>회원은 회원 ID 및 비밀번호를
                                                제 3자에게 이용하게 해서는 안되며 회원ID 및 비밀번호를
                                                도난당하거나 제 3자가 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야
                                                합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제4장 개인정보보호 등</strong></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제17조 (개인정보보호 등)</strong></p>
                                    <ol>
                                        <li><span>회사는 관련 법령이 정하는 바에
                                                따라 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위해
                                                노력하며, 회원 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.&nbsp;</span>
                                        </li>
                                        <li><span>회원의 개인정보는 서비스의 원활한
                                                제공을 위하여 회원이 동의한 목적과 범위 내에서만
                                                수집·이용됩니다. 회사는 법령에 의하거나 회원이 별도로 동의하지 아니하는 한, 회원의 개인정보를 제3자에게 제공하지
                                                아니합니다.&nbsp;</span></li>
                                        <li><span>회사는 회원이 안전하게 서비스를
                                                이용할 수 있도록 회원의 개인정보보호를 위하여 개인정보처리방침을
                                                정하며, 이에 따라 회원의 개인정보를 보호하여야 할 의무가 있습니다. 회사의 개인정보처리방침은 웹사이트 또는 앱에 링크된 화면을
                                                통하여 확인할 수 있습니다.&nbsp;</span></li>
                                        <li><span>회원의 귀책사유로 인해 노출된
                                                정보에 대해 회사는 일체의 책임을 지지
                                                않습니다.&nbsp;</span></li>
                                        <li><span>회원이 이용계약 체결과정에서
                                                회사에 제공한 회원정보에 대한 수집·이용에 대한 동의를
                                                철회하고자 하는 경우에는 “개인정보처리방침”에서 정한 바에 따르며, 회원이 이러한 동의를 철회하는 경우
                                                서비스 이용계약은 자동으로 해지됩니다.&nbsp;</span>
                                        </li>
                                        <li><span>본 약관에 따른 회원의 정보보호
                                                및 관리 관련 권리와 의무는 다음 각 호와 같습니다.</span>
                                        </li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>서비스 이용 중 발생하는 모든
                                                정보의 관리, 삭제, 등록 등에 대한 권리와 책임은 회원에게
                                                있습니다.&nbsp;</span></li>
                                        <li><span>서비스 이용 중 발생한 모든
                                                데이터의 보존 기간은 회사에서 정한 방침에 따릅니다. 단, 회원은
                                                별도로 회사에 보존기간을 연장 요청할 수 있습니다.</span></li>
                                        <li><span>서비스 이용 중 회원의 고의 또는
                                                과실로 인하여 발생한 모든 데이터의 인위적인 정보 유출 및
                                                정보보안의 책임은 회원에게 있으며, 회원은 정보 유출 및 정보보안의 방법에 대해 회사에 요청할 수 있습니다.</span></li>
                                        <li><span>회원이 회원의 고객 또는 제3자의
                                                개인정보를, 회사의 서비스 사용 시 이용하고자 하는 경우에는
                                                회원과 고객 또는 제3자의 사이에서 회사를 수탁자로 하는 개인정보 처리위탁에 관한 동의를 받아야 합니다. 회원이 고객 또는
                                                제3자로부터 동의를 받지 않고 임의로 서비스를 사용하는 경우 회사는
                                                이에 대하여 어떠한 책임이 없으며, 회사는 합리적 판단에 따라 회원의 서비스 사용 제한 및 관련 법률에 따른 기타 조치를 취할 수
                                                있습니다.</span></li>
                                    </ol>
                                    <ol>
                                        <li><span>본 약관에 따른 회사의 정보보호
                                                및 관리 관련 권리와 의무는 다음 각 호와 같습니다.</span>
                                        </li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>회사는 회원이 서비스의 이용 중
                                                등록한 정보를 편리하게 관리 및 백업을 할 수 있도록 엑셀형태의
                                                파일다운로드 기능을 제공합니다.&nbsp;</span></li>
                                        <li><span>회사는 서비스에서 회원이 사용료를
                                                지급하는 동안 서비스에서 관리되는 데이터에 대하여 소프트웨어에서
                                                조회가 가능하도록 데이터를 보존할 책임이 있습니다.</span></li>
                                        <li><span>회원이 서비스 이용 중 발생한
                                                모든 데이터에 대해서 회원이 정상적인 사용료를 납부하여 이용하는
                                                경우 회사는 데이터 보관의 책임이 있습니다.&nbsp;</span></li>
                                        <li><span>회사는 회원에게 정보 유출 및
                                                정보보안에 대한 관리방법을 제공합니다. 또한 회원의 모든 데이터를
                                                관리, 보호할 의무가 있습니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제5장 손해배상 및 면책</strong></p>
                                    <p className="mt-4"></p>
                                    <p><strong>제18조 (손해배상)</strong></p>
                                    <ol>
                                        <li><span>회사는 법령상 허용되는 한도
                                                내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인
                                                사항에 대하여 약정이나 보증을 하지 않습니다. 또한 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을
                                                하지 않으며 이로 인해 발생한 회원의 손해에 대하여 책임을 지지
                                                않습니다.</span></li>
                                        <li><span>회사의 책임 있는 사유로 인하여
                                                회원에게 손해가 발생한 경우 회사의 손해배상 범위는 민법에서
                                                정하고 있는 통상손해를 포함하고, 특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수 있었을 때에 한하여 배상책임이
                                                있습니다.</span></li>
                                        <li><span>전항에도 불구하고 다음 각 호의
                                                하나에 해당하는 경우에는 회원이 그 책임의 전부 또는 일부를
                                                부담할 수 있습니다.</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li><span>회원이 손해 발생의 원인 또는
                                                손해 발생 사실을 알았음에도 불구하고 회사에 통지하지 않은
                                                경우</span></li>
                                        <li><span>그 외 손해 발생에 있어서 회원의
                                                고의나 과실이 있는 경우</span></li>
                                    </ol>
                                    <ol>
                                        <li><span>회사는 회사의 책임 없는 사유로
                                                인하여 회원에게 발생한 손해에 대하여는 배상책임이
                                                없습니다.</span></li>
                                        <li><span>회원이 회사에게 제공한 정보가
                                                사실과 다를 경우, 회사는 언제든지 서비스의 제공을 중단하고 본
                                                약관에 의해 계약 전체 또는 일부를 해지할 수 있으며, 이로 인하여 회사에게 손해가 발생한 경우, 회원에게 그 손해의 배상을
                                                청구할 수 있습니다.</span></li>
                                        <li><span>회원이 회사의 시스템 운영을
                                                방해하는 등 고의 또는 과실로 법령, 본 약관, 공서양속에 위배하는
                                                행위 등을 통하여 회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제19조 (면책)</strong></p>
                                    <ol>
                                        <li><span>회사는 천재지변 또는 침해사고
                                                그리고 이에 준하는 불가항력으로 서비스를 제공할 수 없는 경우
                                                서비스의 효율적인 제공을 위한 시스템 개선, 장비증설 등 계획된 서비스 중지 일정을 고객에게 사전에 공지한 경우 책임을
                                                면합니다.</span></li>
                                        <li><span>회사는 서비스 및 서비스에 게재된
                                                정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지
                                                않으며, 회사는 회원이 정보 또는 자료를 자신의 임의대로 사용하는 과정에서 발생하는 손해에 대하여 책임을 지지
                                                않습니다.</span></li>
                                        <li><span>회사는 회원이 서비스를 이용하거나
                                                이용할 것으로부터 발생하거나 기대하는 손익 등에 대하여 어떠한
                                                보장도 하지 않습니다.</span></li>
                                        <li><span>회사는 회원이 게시 또는 전송한
                                                자료의 내용에 대하여는 책임을 면합니다.</span></li>
                                        <li><span>회원은 서비스의 사용과정에서
                                                자신의 책임하에 자료를 입력, 게시, 출력, 전송하며, 회사는 회원이
                                                입력, 게시, 출력, 전송한 자료의 내용에 대하여는 책임을 면합니다.</span></li>
                                        <li><span>회사는 회원 상호간 또는 회원과
                                                제3자 상호간에 서비스를 매개로 하는 물품거래와 관련하여 어떠한
                                                책임도 지지 않습니다.</span></li>
                                        <li><span>회사는 무료로 제공하는 서비스에
                                                대하여 회사의 귀책사유와 무관하게 책임을 지지
                                                않습니다.</span></li>
                                        <li><span>회사는 회원의 귀책사유로 인하여
                                                발생하는 제 3자와의 문제에 관하여 책임을 지지
                                                않습니다.</span></li>
                                    </ol>
                                    <p className="mt-4"></p>
                                    <p><strong>제20조 (준거법 및 관할법원)</strong></p>
                                    <ol>
                                        <li><span>서비스와 관련하여 회사와 회원
                                                간에 분쟁이 발생할 경우 양 당사자 간의 합의에 의해 원만히
                                                해결함을 원칙으로 합니다.</span></li>
                                        <li><span>본 약관과 관련된 사항에 대하여는
                                                대한민국 법률을 준거법으로 합니다.&nbsp;</span>
                                        </li>
                                        <li><span>당사자 간에 합의할 수 없거나
                                                합의가 이루어지지 않아 분쟁과 관련하여 소송이 제기되는 경우,
                                                회사의 소재지를 관할하는 법원을 관할법원으로 합니다.</span></li>
                                    </ol>
                                    <p><br/><br/></p>
                                    <p><strong>부칙&nbsp;</strong></p>
                                    <p><span>본 약관은 년 월 일부터 적용됩니다.</span></p>
                                    <p><span>본 약관에 대하여 의문이 있거나, 주식회사 아크에 연락을 원하실 때에는 본사로 연락을
                                            주시기 바랍니다.&nbsp;</span></p>
                                    <p className="mt-4"></p>
                                    <p><span>문의전화: 1588-9234</span></p>
                                    <p><span>웹사이트:&nbsp;</span></p>
                                    <p><span>주소: 서울특별시 금천구 벚꽃로 234, 12층 1201호 (가산동,에이스하이엔드타워6차)</span>
                                    </p>
                                </article>
                </div>
                <div className={"tab-pane fade " + (props.data == 2 ? "show active" : "")} id="rules2" role="tabpanel" aria-labelledby="rules2-tab">
                <h4>
                                    개인정보 보호 취급방침
                                </h4>
                                <article>
                                    <p><span >주식회사 아크(이하 “회사”)는 관련 제반 서비스를 이용하는 회원의
                                            개인정보 보호를 소중하게 생각하고 회원의 개인정보를 보호하기
                                            위하여 항상 최선을 다해 노력하고 있습니다.&nbsp;</span></p>
                                    <p><span >회사는 [정보통신망 이용촉진 및 정보보호등에 관한 법률] 등에 따라 이용자의 개인정보 및
                                            권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게
                                            처리하기 위해 다음과 같은 처리 방침을 두고 있습니다.&nbsp;</span></p>
                                    <p><span >회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할
                                            것입니다. 회사는 본 개인정보처리방침을 웹사이트
                                            (</span><a href="http://www.mobisell.kr/"><span >http://www.mobisell.kr</span></a><span >)의 화면 하단에 공개함으로써 이용자께서 언제나 용이하게 보실 수 있도록 조치하고
                                            있습니다.</span></p>
                                   <p className="mt-4"></p>
                                    <p><strong>제 1 조 (처리하는 개인정보 항목 및 수집방법)</strong></p>
                                    <ol>
                                        <li ><span >수집항목</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                            <li ><span >사업자정보: 사업자명,
                                                    대표자명, 사업자등록번호, 사업자주소, 대표자전화번호,
                                                    사업자전화번호, 업태, 업종, ID</span></li>
                                            <li ><span >담당자정보: 담당자이름,
                                                    담당자전화번호, 담당자 E-mail,
                                                    계산서E-mail</span></li>
                                            <li ><span >서비스 이용 시 수집항목:
                                                    최근접속일시, 서비스이용기록, 접속로그, 쿠키, 접속IP정보,
                                                    휴대폰정보</span></li>
                                            <li ><span >사용료 결제 시 수집항목:
                                                    회원ID, 사업자명, 은행계좌정보, 신용카드정보,
                                                    결제기록</span></li>
                                    </ol>
                                    <ol>
                                        <li ><span >수집방법</span></li>
                                    </ol>
                                    <p className="ml-3"><span >홈페이지에서 제공하는 서비스 이용과정에서 이용자가 직접 입력하거나 서비스 실행 시
                                            자동수집</span></p>
                                   <p className="mt-4"></p>
                                    <p><strong>제 2 조 (개인정보의 수집 및 이용목적)</strong></p>
                                    <ol>
                                        <li ><span >회원의 관리</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >수집이용 정보목적
                                            및 상세내역</span></li>
                                        <li ><span >서비스이용에 따른
                                                본인식별 : ID, 비밀번호</span></li>
                                        <li ><span >불만처리 등
                                                민원처리 : ID, 사업자명, 통신사구분, 매장주소, 담당자이름,
                                                접속일시, IP주소, 쿠키, 사용디바이스정보, 서비스이용기록</span></li>
                                        <li ><span >고지사항의 전달 등
                                                의사소통 경로 확보: 전화번호, 휴대전화번호, 팩스번호,
                                                이메일, 담당자이름</span></li>
                                        <li ><span >분쟁조정, 해결을
                                                위한 기록 보존 : ID, 사업자명, 매장주소, 담당자이름,
                                                전화번호, 휴대전화번호, 팩스번호, 이메일, 사업자번호, 업종, 업태, 최종접속일시, 서비스이용기록, 은행계좌정보,
                                                신용카드정보</span></li>
                                    </ol>
                                    <ol>
                                        <li ><span >서비스 제공에 관한 계약의 이행
                                                및 서비스제공에 따른 요금의 정산</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >수집 이용 정보
                                            목적 및 상세내역</span></li>
                                        <li ><span >구매 및 요금결제
                                                : 아이디, 사업자명, 은행계좌정보, 신용카드정보, 휴대전화번호,
                                                이메일</span></li>
                                        <li ><span >물품배송:
                                                사업자명, 우편번호, 매장주소, 전화번호, 휴대전화번호</span>
                                        </li>
                                    </ol>
                                    <ol>
                                        <li ><span >신규서비스 및 마케팅에의
                                                활용</span></li>
                                    </ol>

                                        <ol className="ml-3">
                                            <li ><span >수집이용정보 목적
                                                및 상세내역</span></li>
                                            <li ><span >새로운 신규서비스
                                                    개발 및 맞춤 서비스 제공</span></li>
                                            <li ><span >이벤트 등 광고성
                                                    정보 전달</span></li>
                                        </ol>

                                   <p className="mt-4"></p>
                                    <p><strong>제 3 조 (개인정보의 보유 및 이용기간)</strong></p>
                                    <p><span >원칙적으로, 개인정보 수집 및 이용목적이 달성 된 후에는 해당정보를 지체 없이
                                            파기합니다. 단, 관계법력의 규정에 의하여 보존할 필요가 있는 경우 회사는
                                            제 2항과 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.&nbsp;</span></p>
                                    <ol>
                                        <li ><span >상법, 전자상거래 등에서의
                                                소비자보호에 관련 법률 등 관계 법령의 규정에 의해 보존할 필요성이
                                                있는 경우 회사는 관계법령에서 정한 일정한 기간이상 이용자의 개인정보를 보관할 수 있습니다. 이 경우 회사는 보관하는 정보를 그
                                                보관의 목적으로만 이용하며, “보존근거”에서
                                                명시한 근거에 따라 :보존기간” 동안 보존합니다.</span></li>
                                    </ol>
                                    <ol>
                                        <li ><span >계약 또는 청약철회 등에 관한
                                                기록</span></li>
                                    </ol>

                                            <ul className="ml-3">
                                                <li ><span >보존근거 :
                                                        전자상거래 등에서의 소비자보호에 관한 법률</span></li>
                                                <li ><span >보존기간 :
                                                        5년</span></li>
                                            </ul>

                                    <ol>
                                        <li ><span >대금결제 및 재화 등 공급에 관한
                                                기록</span></li>
                                    </ol>

                                            <ul className="ml-3">
                                                <li ><span >보존근거 :
                                                        전자상거래 등에서의 소비자보호에 관한 법률</span></li>
                                                <li ><span >보존기간 :
                                                        5년</span></li>
                                            </ul>
                                    <ol>
                                        <li ><span >소비자의 불만 또는 분쟁처리에
                                                관한 기록</span></li>
                                    </ol>
                                            <ul className="ml-3">
                                                <li ><span >보존근거 :
                                                        전자상거래 등에서의 소비자보호에 관한 법률</span></li>
                                                <li ><span >보존기간 :
                                                        3년</span></li>
                                            </ul>
                                    <ol>
                                        <li ><span >웹사이트 방문기록</span>
                                        </li>
                                    </ol>

                                            <ul className="ml-3">
                                                <li ><span >보존근거 :
                                                        통신비밀보호법</span></li>
                                                <li ><span >보존기간 :
                                                        3년</span></li>
                                            </ul>

                                    <ol>
                                        <li ><span >회사는 6개월 동안 회사의
                                                서비스를 이용하지 않은 이용자의 개인정보는 [정보통신망이용촉진 및
                                                정보보호등에 관한 법률 제 29조]에 근거하여 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장 관리 합니다.
                                                이용자의 요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다.
                                                단, [통신비밀버호법], [전자상거래 등에서의 소비자보호에 관한 법률] 등의 관계법령의 규정에 의하여 보존할 필요가 있는 경우
                                                관계법령에서 규정한 일정한 기간 동안 이용자의 개인정보를
                                                보관합니다.&nbsp;</span></li>
                                        <li ><span >회사는 3항의 기간만료 30일
                                                전까지 개인정보가 파기 되거나 분리되어 저장관리되는 사실과 기간
                                                만료일 및 해당 개인정보의 항목을 공지사항, 전자우편 등의 방법으로 이용자에게 알립니다. 이를 위해 이용자는 회사에 정확한 연락처
                                                정보를 제공 및 수정하여야 합니다.</span></li>
                                    </ol>
                                   <p className="mt-4"></p>
                                    <p><strong>제 4 조 (개인정보의 파기절차 및 방법)</strong></p>
                                    <p><span >회사는 원칙적으로 개인정보 수집 및 이용목적이 달성 된 후에는 해당정보를 지체 없이
                                            파기합니다. 파기절차 및 방법은 다음과
                                            같습니다.&nbsp;</span></p>
                                    <ol>
                                        <li ><span >파기절차</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >회사는 이용자가 일정기간 동안
                                                서비스를 이용하지 않거나 계약해지를 신청하는 경우 소비자의 불만 및
                                                분쟁해결 등을 위한 목적으로 30일간 개인정보를 보관한 후, 재생이 불가능한 방법으로 파기 합니다.&nbsp;</span>
                                        </li>
                                    </ol>
                                    <ol>
                                        <li ><span >파기방법</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >전자적 파일형태로 저장 된
                                                개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                                                삭제합니다.&nbsp;</span></li>
                                        <li ><span >종이에 출력 된 개인정보는
                                                분쇄기로 분쇄하거나 소각을 통하여 파기 합니다.&nbsp;</span>
                                        </li>
                                    </ol>
                                    <p><strong>제 5 조 (개인정보의 제3자제공)</strong></p>
                                    <p><span >회사는 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로
                                            합니다.&nbsp;</span></p>
                                    <p><span >이용자들이 사전에 동의한 경우 및 법령의 규정에 의거하여 관계기간의 요청이 있는
                                            경우</span></p>
                                    <p><span >수사 목적으로 법령에 정해진 절차와 방법에 따라 수사 기관의 요구가 있는
                                            경우</span></p>
                                    <p><span >서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로
                                            통상의 동의를 받는 것이 현저히 곤란한 경우</span></p>
                                   <p className="mt-4"></p>
                                    <p><strong>제6조 (개인정보 처리 위탁)</strong></p>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p><strong>수탁업체</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>위탁업무 내용</strong></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span >효성 FMS</span></p>
                                                </td>
                                                <td>
                                                    <p><span >유료서비스 이용에 따른 결제처리 및 요금정산</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span >한국전자금융㈜</span></p>
                                                </td>
                                                <td>
                                                    <p><span >유료서비스 이용에 따른 결제처리 및 요금정산</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   <p className="mt-4"></p>
                                    <p><strong>제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법)</strong></p>
                                    <ol>
                                        <li ><span >이용자(만14세 미만인 경우에는
                                                법정대리인을 말함)는 언제든지 등록 되어 있는 이용자의 개인정보를
                                                열람하거나 정정, 삭제, 처리요구를 하실 수 있습니다. 개인정보열람 및 정정을 하고자 할 경우에는 “나의
                                                프로필” 클릭하여 직접 열람 또는 정정할 수 있으며, 회사에 대해
                                                [정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행규칙] 별지 제8호 서식(개인정보 열람,정정,삭제, 처리정지요구서)에 따라
                                                서면, 전자우편, 모사전송 등을 통하여 할 수
                                                있습니다.&nbsp;</span></li>
                                        <li ><span >이용자가 개인정보의 오류 등에
                                                대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를
                                                완료할 때 까지 당해 개인정보를 이용하거나 제공하지 않습니다.</span></li>
                                        <li ><span >“제1항”에
                                                따른 권리행사는 이용자의 법정대리인이나 위임을 받는 자 등
                                                대리인을 통하여 할 수 있습니다. 이 경우 [정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행규칙] 별지 제 11호 서식에
                                                따른 위임장을 제출하셔야 합니다.&nbsp;</span></li>
                                        <li ><span >회사는 이용자가 개인정보에 대한
                                                열람증명 또는 정정을 요청하는 경우에는 고객의 요청에 성실하게
                                                대응하며, 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체없이 조치
                                                합니다. 회사는 이용자의 개인정보의 전부 또는 일부에 대하여
                                                열람 또는 정정요구를 거절할 정당한 이유가 있는 경우에 회원에게 이를 지체없이 통지하고 그 이유를
                                                설명합니다.&nbsp;</span></li>
                                    </ol>
                                   <p className="mt-4"></p>
                                    <p><strong>제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)</strong></p>
                                    <ol>
                                        <li ><span >쿠키란?</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >회사는 개인화되고 맞춤화 된
                                                서비스를 제공하기 위하여 이용자의 정보를 저장하고 수시로 불러오는
                                                ‘쿠키(cookie)”를 사용합니다.</span></li>
                                        <li ><span >쿠기는 웹사이트를 운영하는데
                                                이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로
                                                이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드 디스크에 저장되어
                                                있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화 된
                                                서비스를 제공하기 위해 이용됩니다.&nbsp;</span></li>
                                        <li ><span >쿠키는 개인을 식별하는 정보를
                                                자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의
                                                저장을 거부하거나 삭제할 수 있습니다.&nbsp;</span></li>
                                    </ol>
                                    <ol>
                                        <li ><span >회사의 쿠키사용 목적</span>
                                        </li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >이용자가 동일한 정보를 반복하여
                                                입력 할 필요를 제거하거나 시간을 절약하고 웹사이트에 로그인해
                                                있는 동안 개인화 된 콘텐츠를 서비스 하기 위하여 컴퓨터에 이용자의 선택 사항 및 기타정보를 저장하는 데 쿠키를
                                                사용합니다.</span></li>
                                        <li ><span >이용자들의 방문한 각 서비스와
                                                웹사이트들에 대한 방문 및 이용형태, 보안접속여부, 이용자 규모
                                                등을 파악하여 이용자에게 광고를 포함한 최적화 된 맞춤형 정보 및 서비스를 제공하기 위해 사업합니다.&nbsp;</span>
                                        </li>
                                    </ol>
                                    <ol>
                                        <li ><span >쿠키의 설치 /운영 및
                                                거부</span></li>
                                    </ol>
                                    <ol className="ml-3">
                                        <li ><span >이용자가 동일한 정보를 반복하여
                                                입력할 필요를 제거하여 시간을 절약하고 웹사이트에 로그인해 있는
                                                동안 개인화 된 콘텐츠를 서비스하기 위하여 컴퓨터에 이용자의 선택 사항 및 기타정보를 저장하는 데 쿠키를 사용합니다. 이용자들이
                                                방문한 각 서비스와 웹사이트들에 대한 방법 및 이용형태,
                                                보안접속여부, 이용자규모 등을 파악하여 이용자에게 광고를 포함한 최적화 된 맞춤형 정보 및 서비스를 제공하기 위해
                                                사용합니다.&nbsp;</span></li>
                                        <li ><span >이용자는 쿠키 설치에 대한
                                                선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
                                                설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장 될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
                                                있습니다.&nbsp;</span></li>
                                        <li ><span >이용자가 모든 쿠키의 저장을
                                                거부로 설정한 경우 쿠키를 통해 제공되는 서비스는 이용할 수
                                                없습니다.</span></li>
                                    </ol>
                                   <p className="mt-4"></p>
                                    <p><strong>제 9 조 (개인정보의 기술적/관리적/물리적보호대책)</strong></p>
                                    <p><span >회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
                                            훼손되지 않도록 안전성 확보를 위해 기술적, 관리적 대책을 마련하고
                                            있습니다. 다만 정보주체는 공공장소에서의 인터넷 사용 당의 과정에서 타인에게 의도하지 않게 개인정보를 분실하거나 타인에게 도난 또는
                                            유출되지 않도록 관리하셔야 합니다.&nbsp;</span></p>
                                    <ol>
                                        <li ><span >기술적대책</span></li>
                                    </ol>

                                            <ul className="ml-3">
                                                <li ><span >이용자의 개인정보는
                                                        암호화되어 있어 이용자 본인 외에는 알 수 없으며, 개인정보
                                                        확인 및 변경도 본인의 요청 혹은 본인이 직접 홈페이지를 통해서만 가능합니다.</span></li>
                                                <li ><span >회사는 암호화
                                                        통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록
                                                        하고 있습니다.&nbsp;</span></li>
                                                <li ><span >회산느 해킹이나
                                                        컴퓨터바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을
                                                        막기 위해 최선을 다하고 있습니다.</span></li>
                                                <li ><span >회사는 만약의
                                                        사태를 대비하여 개인정보를 주기적으로 백업하고 있으며, 백신프로그램을
                                                        이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.&nbsp;</span></li>
                                                <li ><span >회사는 시스템에
                                                        대한 접근통제, 권한관리, 취약점 점검 등의 조치를 통해 보안성이
                                                        강화 될 수 있도록 지속적으로 노력하고 있습니다.&nbsp;</span></li>
                                            </ul>

                                    <ol>
                                        <li ><span >관리적대책</span></li>
                                    </ol>

                                            <ul className="ml-3">
                                                <li ><span >회사는 이용자의
                                                        개인정보에 대한 접근권한을 최소한 인원으로 제한하고 있으며, 그
                                                        최소한의 인원에 해당하는 자는 다음과 같습니다.&nbsp;</span></li>
                                            </ul>

                                                <ol className="ml-5">
                                                    <li ><span >이용자를
                                                            직접 상대로 하여 서비스운영, 고객지원, 배송업무를
                                                            수행하는자(위탁,협력업체의 직원포함)</span></li>
                                                    <li ><span >개인정보관리책임자 등 개인정보관리업무를 수행하는자</span>
                                                    </li>
                                                    <li ><span >기타업무상
                                                            개인정보의 취급이 불가피한자</span></li>
                                                </ol>
                                            <ul className="ml-3">
                                                <li ><span >회사는 개인정보
                                                        취급자를 대상으로 개인정보 보호의무등에 관해 정기적인 교육을
                                                        실시하고 있습니다.</span></li>
                                                <li ><span >회사는 개인정보보호
                                                        업무를 전담하는 부서에서 개인정보처리방침 및 내부 규정준수여부를
                                                        확인하여 문제가 발견 될 경우, 즉시 바로 잡을 수 있도록 노력하고 있습니다.</span></li>
                                                <li ><span >이용자 본인의
                                                        부주의나 인터넷상이 문제로 아이디, 비밀번호 등의 개인정보가 유출되어
                                                        발생한 문제에 대해서는 회사가 일체 책임을 지지 않습니다.</span></li>
                                            </ul>
                                    <ol>
                                        <li ><span >물리적대책</span></li>
                                    </ol>
                                            <ul className="ml-3">
                                                <li ><span >회사는 전산실,
                                                        자료보관실 등 개인정보를 보관하고 있는 물리적 보관장소에 대하여
                                                        출입통제 절차를 수립하여 운영하고 있습니다.&nbsp;</span></li>
                                                <li ><span >회사는 개인정보가
                                                        포함 된 서류, 보조자장매체 등을 잠금장치가 있는 안전한 장소에
                                                        보관하고 있습니다.</span></li>
                                                <li ><span >회사는 개인정보가
                                                        포함 된 보조저장매체의 반출입 통제를 위한 보안대책을 마련하고
                                                        있습니다. 다만, 별도의 개인정보처리시스템을 운영하지 아니하고 업무용 컴퓨터 또는 모바일 기기를 이용하여 개인정보를
                                                        처리하는 경우에는 이를 적용하지 아니할 수
                                                        있습니다.</span></li>
                                            </ul>
                                   <p className="mt-4"></p>
                                    <p><strong>제 10 조 (개인정보에 관한 민원서비스)</strong></p>
                                    <ol>
                                        <li ><span >회사는 고객의 개인정보를 보고하고
                                                개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서
                                                및 개인정보관리 책임자를 지정하고 있습니다.</span></li>
                                    </ol>
                                    <p><span >고객서비스 담당부서: 모비셀 운영팀</span></p>
                                    <p><span >전화번호: 010-4042-4914</span></p>
                                    <p><span >이메일: </span><a href="mailto:toogi71@arc-m.co.kr"><span >toogi71@arc-m.co.kr</span></a></p>
                                    <p><span >개인정보보호책임자 성명: 염성훈</span></p>
                                    <p><span >전화번호: 010-4243-3305</span></p>
                                    <p><span >귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
                                            개인정보책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의
                                            신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.&nbsp;</span></p>
                                    <ol>
                                        <li ><span >기타 개인정보침해에 대한 신고나
                                                상담이 필요하신 경우에는 아래 기관에 문의하시기
                                                바랍니다.&nbsp;</span></li>
                                    </ol>
                                    <p><span >개인분쟁조정위원회 (www.1336.or.kr/1336)</span><span ><br/></span><span >정보보호마크인증위원회
                                            (www.eprivacy.or.kr/02-580-0533~4)</span><span ><br/></span><span >대검찰청
                                            인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)</span><span ><br/></span><span >경찰청
                                            사이버테러대응센터 (</span><a href="http://www.ctrc.go.kr/02-392-0330"><span >www.ctrc.go.kr/02-392-0330</span></a><span >)</span></p>
                                   <p className="mt-4"></p>
                                    <p><strong>제 11 조 (링크사이트)</strong></p>
                                    <p><span >회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수
                                            있습니다.</span></p>
                                    <p><span >이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는
                                            서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수
                                            없습니다.</span></p>
                                    <p><span >회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈
                                            경우 해당 사이트의 개인정보취급방침은 회사와 무관하므로 새로
                                            방문한 사이트의 정책을 확인하시기 바랍니다.&nbsp;</span></p>
                                   <p className="mt-4"></p>
                                    <p><strong>제 12 조 (기타)</strong></p>
                                    <p><span >본 개인정보처리방침은 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
                                            있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일전에
                                            홈페이지(</span><a href="http://www.mobisell.kr/"><span >http://www.mobisell.kr</span></a><span >)을 통해 변경이유 및 내용등을
                                            공지하도록 하겠습니다.&nbsp;</span></p>
                                    <p><span >-공고일자: 2020.12.01</span></p>
                                    <p><span >-시행일자: 2020.12.01</span></p>
                                </article>
               
                </div>
            </div>
        </div>
    );
}

ContentRule.propTypes = {
};

export default ContentRule;