 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {numberWithCommas,priceFormat, roundUpNumber, roundUpNumberPlan} from "../../../utils/helper";
class WirelessBasicCost extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
      <div className="form-group row align-items-start">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">
          기본료 <br />
          <small>(vat 포함) </small>
        </label>
        <div className="col-sm-8">
          <input type="text" 
            className="form-control" 
            disabled="" 
            value={WirelessAdvice.WirelessAdviceStep3.basicCost ? (priceFormat(roundUpNumberPlan(WirelessAdvice.WirelessAdviceStep3.basicCost))) + '원' : "0원" }
            readOnly/>
        </div>
      </div>
    );
  }
}

WirelessBasicCost.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessBasicCost);
