import PropTypes from 'prop-types';
import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';
import SelerModal from "./SelerModal"

class WirelessSeler extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenModal:false,
      stateChangeProp:false,
    }
  }

  openPopup = () => this.setState({isOpenModal: !this.state.isOpenModal})

  render() {
    const { WirelessAdvice } = this.props;

    let saler = WirelessAdvice.WirelessAdviceStep3?.saler

    return (
      <Fragment>
        <div className="form-group row align-items-center">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">상담사</label>
          <div className="col-sm-8">
            <button className="btn btn-input-form justify-content-between dropdown-toggle mb-0 d-flex" type="button" onClick={this.openPopup}>
              {saler?.name}
            </button>
          </div>
        </div>
        <SelerModal isOpenModal={this.state.isOpenModal} openPopup={this.openPopup} />
      </Fragment>
    );
  }
}

WirelessSeler.propTypes = {
  WirelessAdvice: PropTypes.object
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessSeler);
