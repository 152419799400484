import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class TransactionLocation extends Component {
  componentDidMount() {
    
  }


  render() {
    return (
        <Fragment>
            <section className="wrapper_coupon">
                <div className="container">
                    <div className="seller_manager">
                        <div className="box_header justify-content-end">
                            <button className="btn btn_create" data-toggle="modal" data-target="#add_seller">추가</button>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table_striped">
                                <thead className="thead_bg">
                                    <th>No</th>
                                    <th>거래처</th>
                                    <th>사용 통신사</th>
                                    <th>담당자</th>
                                    <th>전화번호</th>
                                    <th className="text-right">Action</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>Facebook</td>
                                        <td>M Mobile</td>
                                        <td>Aubrey Murphy</td>
                                        <td>(702) 555-0122</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td>eBay</td>
                                        <td>SKT,SK 7</td>
                                        <td>Jorge Howard</td>
                                        <td>(219) 555-0114</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td>L'Oréal</td>
                                        <td>SKT, Mobile Hello</td>
                                        <td>Tyrone Jones</td>
                                        <td>(843) 555-0130</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td>Pizza Hut</td>
                                        <td>SK 7, LG U+</td>
                                        <td>Dwight Edwards</td>
                                        <td>(203) 555-0106</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td>IBM</td>
                                        <td>LG U+</td>
                                        <td>Stella Hawkins</td>
                                        <td>(671) 555-0110</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>06</td>
                                        <td>Louis Vuitton</td>
                                        <td>SKT, LG U+</td>
                                        <td>Brandon Simmmons</td>
                                        <td>(270) 555-0117</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>07</td>
                                        <td>Ferrari</td>
                                        <td>SKT</td>
                                        <td>Soham Henry</td>
                                        <td>(319) 555-0115</td>
                                        <td className="d-flex justify-content-end">
                                            <a href="" data-toggle="modal" data-target="#edit_seller" className="pr-3">
                                                <i className="far fa-edit"></i>
                                            </a>
                                            <a href="" data-toggle="modal" data-target="#delete_coupon">
                                                <i className="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
  )(TransactionLocation);

