import {
    dataActions
} from '../actions';
import MessageJSON from '../../utils/message';
import api from "../../utils/api";
import setAuthorizationHeader from "../../utils/setAuthorizationHeader";

export const dataPricePackageSuccess = (data) => {
    return {
        type: dataActions.GET_PRICE_PACKAGES,
        data: data
    }
};

export const dataPriceSuccess = (data) => {
    return {
        type: dataActions.GET_PRICES,
        data: data
    }
};

export const dataTelcomSuccess = (data) => {
    return {
        type: dataActions.GET_TELCOM_PROVIDERS,
        data: data
    }
};

export const dataBannersSuccess = (data) => {
    return {
        type: dataActions.GET_BANNERS,
        data: data
    }
};

export const dataManufactureSuccess = (data) => {
    return {
        type: dataActions.GET_MANUFACTURERS,
        data: data
    }
};

export const dataWirelessAdviceSuccess = (data) => {
    return {
        type: dataActions.GET_WIRELESSADVICES,
        data: data
    }
};

export const globalDataAuthSuccess = (data) => {
    return {
        type: dataActions.GET_GLOBAL_DATA_AUTH,
        data: data
    }
};

export const updateDataNotices = (data) => {
    return {
        type: dataActions.UPDATE_DATA_NOTICES,
    data: data
    }
};

export const updateDataDocuments = (data) => {
    return {
        type: dataActions.UPDATE_DATA_DOCUMENTS,
        data: data
    }
};

export const refreshDataNotification = (data) => {
    return {
        type: dataActions.GET_NOTIFICATIONS,
        data: data
    }
};

export const loadmoreDataNotification = (data) => {
    return {
        type: dataActions.LOADMORE_NOTIFICATIONS,
        data: data
    }
};

export const closePopupAdsDocument = (data) => {
    return {
        type: dataActions.CLOSE_POPUP_ADS_DOCUMENT,
        data: data
    }
};

export const closePopupAdsNotice = (data) => {
    return {
        type: dataActions.CLOSE_POPUP_ADS_NOTICE,
        data: data
    }
};


export const getGlobalDataAuth = () => dispatch => {
    return api.global.getGlobalDataAuth()
        .then(response => {
            dispatch(globalDataAuthSuccess(response.data.data));
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getPriceDefault = () => dispatch => {
    return api.global.getPriceDefault()
        .then(response => {
            dispatch(dataPriceSuccess(response.data.data));
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};


export const getPricePackages = (data) => dispatch => {
    return api.auth.getPricePackages(data)
        .then(response => {
            dispatch(dataPricePackageSuccess(response.data.data.price_packages));
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getAdviceWireless = () => dispatch => {
    let data = {
        per_page:1000
    }
    return api.advice.getDataWirelessAdvice(data)
        .then(response => {
            dispatch(dataWirelessAdviceSuccess(response.data.data));
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};


export const getListUsedPrograms = (data) => dispatch => {
    return api.advice.getListUsedPrograms(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getListMembershipDiscounts = (data) => dispatch => {
    return api.advice.getListMembershipDiscounts(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const applyUsedProgram = (data) => {
    return {
        type: dataActions.APPLY_USED_PROGRAM,
        data: data
    }
};

export const getListMutualService = (data) => dispatch => {
    return api.advice.getMutualService(data)
        .then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const detailMutualService = (data) => dispatch => {
    return api.advice.detailMutualService(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getNotifications = (data) => dispatch => {
    return api.global.getNotifications(data)
        .then(response => {
            dispatch(refreshDataNotification(response.data.data));
            return Promise.resolve(response.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};
