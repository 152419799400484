 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import * as routerName from "../../../utils/routerName";


class MessageWarningModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    openModal = () => {
        this.props.openModalWarning(this.props.typeInfo)
    }

    openModalChat11 = () => {
        this.props.openModalWarning(this.props.typeInfo)
        if (document.querySelector('div[data-ch-testid="launcher"]')) {
            document.querySelector('div[data-ch-testid="launcher"]').click()
        }
    }

    render() {
        const {handleSubmit, WirelessAdvice} = this.props;

        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={['sto_business_no_exist', 'phone_exist', 'username_exist'].includes(this.props.typeInfo) ? null : this.props.openModalWarning}
                fade={true}
                className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
                contentClassName="modal-penalty content">
                <div className="modal-header" style={{border:'none'}}>
                    {!['sto_business_no_exist', 'phone_exist', 'username_exist'].includes(this.props.typeInfo) && (
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                            onClick={this.props.openModalWarning}>
                             <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    )}
                </div>
                <ModalBody className="discount-card pl-0 pr-0 pt-0">
                    <div className="content-register-modal">
                        <div className="img-check">
                            <img src="/images/icon-rattiec.svg" alt=""/>
                        </div>
                        <h4 className="text-center">{this.props.title}</h4>
                        {/*<p className="text-center">{this.props.description}</p>*/}
                        <p className="text-center" dangerouslySetInnerHTML={{ __html: this.props.description }} />
                        {!['sto_business_no_exist', 'phone_exist', 'username_exist'].includes(this.props.typeInfo) && (
                            <div className="d-flex justify-content-center border-top-0 mt-5 mb-5">
                                <button type="button" className="btn btn-gray " onClick={this.props.redireactFindID} style={{padding:'6px 25px'}}>아이디 찾기</button>
                                <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={()=>this.openModal()}>확인</button>
                            </div>
                        )}
                        {['sto_business_no_exist', 'phone_exist'].includes(this.props.typeInfo) && (
                            <div className="d-flex justify-content-center border-top-0 mt-5 mb-5">
                                <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={()=>this.openModalChat11()}>1대1 상담</button>
                            </div>
                        )}
                        {['username_exist'].includes(this.props.typeInfo) && (
                            <div className="d-flex justify-content-center border-top-0 mt-5 mb-5">
                                <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={()=>this.openModal()}>확인</button>
                            </div>
                        )}
                    </div>
                </ModalBody>


            </Modal>
        );
    }
}

MessageWarningModal.propTypes = {};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(MessageWarningModal);
