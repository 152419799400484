import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import {getFormSyncErrors, getFormValues} from "redux-form";
import {connect} from "react-redux";
import {compose} from "redux";
import api from "../../../utils/api";

class ConfirmPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
            isCreated: false
        }
    }

    handleSubmit = () => {
        let id = this.props.WirelessAdvice.id;
        api.consultant.find(id)
        this.setState({
            isCreated: true
        });
        // this.props.history.push("/create-report-sale?id=" + id);
    }


    render() {
        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-dialog-centered modal-custom-23'}
                onOpened={this.modalOpened}
            >
                {this.state.isCreated ? <div className="">
                    
                    <div className="modal-body text-center p-5">
                        <span className="notification_delete text-center">판매일보가 등록 성공되었습니다!</span>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                onClick={this.props.hide}>확인
                        </button>
                    </div>
                </div>: <div className="">
                    
                    <div className="modal-body text-center py-5 px-4">
                        <span className="notification_delete text-center">“판매현황에 입력대기로 저장 <br/> 되었습니다! <br/>판매일보 등록이 가능합니다.”</span>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 p-0 mb-4">
                    <button type="button" className="btn btn-gray pl-5 pr-5"
                                onClick={this.props.openPopup}>취소
                        </button>
                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                onClick={this.handleSubmit}>확인
                        </button>
                       
                    </div>
                </div>}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const withConnect = connect(
    mapStateToProps,
);

export default compose(
    withConnect
)(withRouter(ConfirmPopup));

