import React from 'react';
import {Modal} from 'reactstrap';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
import OpenPopupPrint from "./OpenPopupPrint";
import moment from 'moment'
const PUBLIC_FOLDER = process.env.REACT_APP_API_PUBLIC_FOLDER;

class OpenPopupDiscountCardDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenPopupPrint : false
        }
    }

    openPopupPrint = () => this.setState({ isOpenPopupPrint : !this.state.isOpenPopupPrint })

    renderDCC_Discount = data => {
        if(data){
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    return (
                        <div className={'item-discount-card'} key={index}>
                            <div className={'flex-discount h-100'} >
                                <p>{item.discount_condition}</p>
                                <h6>{priceFormat(item.money_condition)}<span>할인</span></h6>
                            </div>
                        </div>
                    )
                })
            }
        }

        return '';
    }

    renderDCC_DiscountPromotion = (data) => {
        if(data){
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    if (moment().diff(moment(item.end_date + ' 23:59:59')) > 0) {
                        return null
                    }
                    return (
                        <div className={'item-discount-card'} key={index}>
                          <div className={'flex-discount h-100'} >
                            <p>[Promotion]</p>
                            <p>{item.condition}</p>
                            {/*<p>{item.name}</p>*/}
                            <h6>{priceFormat(item.money)}<span>할인</span></h6>
                            </div>
                        </div>
                    )
                })
            }
        }

        return '';
    }

    limitFont = (font) => {
        return font;
        if(font.length <= 80) {
            return font;
        }else{
            return font.substr(0,80) + '...';
        }
    }

    isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    getHref =  (href) => {
        var l = document.createElement("a");
        if(this.isValidHttpUrl(href)){
            l.href = href;
            let result = l.host
            return result;
        }else{
            return href.split("/")[0]
        }


    }


    render() {
        let {discountCard} = this.props;

        return (
            <Modal
                className={'modal-dialog-centered modal-dialog-scrollable modal-opend-discountcard'}
                contentClassName={'content pl-5 pr-5'}
                // backdrop={"static"}
                isOpen={this.props.isPopupDiscountCardDetail}
                toggle={this.props.openPopupDiscountCardDetail}
            >

                <div className="modal-header">
                    <h5 className="modal-title flex-header">
                        {this.props.titleDetail ? this.props.titleDetail : ''}
                        <button
                            className="btn btn-wireless"
                            // data-toggle="modal"
                            // data-target="#modal-print"
                            // onClick={this.openPopupPrint}
                            id={'print-list-discount-card-1'}
                            data-print-content={process.env.REACT_APP_API_URL_DEV+'discount-card-print-list?type='+this.props.type+'&telcomID='+this.props.telcomID}
                        >
                            인쇄하기
                        </button>
                    </h5>
                    <button
                        type="button"
                        className="close close-modal"
                        //data-dismiss="modal"
                        //aria-label="Close"
                        onClick={this.props.openPopupDiscountCardDetail}
                    >
                       <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <div className="modal-body installment-card pl-0 pr-0">
                    <table className="table">
                        <thead>
                        <tr className="bg-purple">
                            <th>
                                카드명
                            </th>
                            <th>
                                기본할인
                            </th>
                            <th>
                                추가혜택 (프로모션)
                            </th>
                            <th>
                                전월실적 포함/미포함 내역
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {discountCard && discountCard.map((item,index) => {

                            let href = '';
                            if(item.dcc_center_online){
                                href = this.getHref(item.dcc_center_online);
                            }

                            return(
                                <tr
                                    key={index}
                                >
                                    <td>
                                        <h5>
                                            {item.dcc_auto_transac == 1 ? '자동이체 필수' :'자동이체 미필수'}
                                        </h5>
                                        <img src={item.dcc_image ? PUBLIC_FOLDER+'/storage/discount_card/original/'+item.dcc_image : "/images/card-01.png"} alt="" />
                                        <p>
                                            {item.dcc_name}
                                        </p>
                                        <h6 className="mt-3">
                                            발급센터
                                        </h6>
                                        {item.dcc_center_ars ? <p>
                                            {item.dcc_center_ars}
                                        </p> : ''}
                                        {item.dcc_center_online ?  <h6>
                                            <a href={item.dcc_center_online} target={'_blank'}>{href}</a>
                                        </h6> : ''}

                                        {
                                            // item.dcc_center_qr
                                            // ?
                                            // <img src={PUBLIC_FOLDER+'/storage/discount_card/original/'+item.dcc_center_qr} alt="" />
                                            // :''
                                        }


                                    </td>
                                    <td className={"p-0 td-discount"}>
                                        <div className={'list-discount-card'}>
                                            {this.renderDCC_Discount(item.dcc_discount)}
                                            {this.renderDCC_DiscountPromotion(item.dcc_promotion)}
                                        </div>
                                    </td>
                                    <td>
                                        <h6 className="color-orange mb-4">
                                            *할인은 카드 청구금액 에서 적용 됩니다.<br/>
                                        </h6>
                                        <div dangerouslySetInnerHTML={{__html: item.dcc_benefit}}/>
                                        <h6 className="mt-4">연회비</h6>
                                        {item.dcc_anual_fee_foreign ? <p>국내 {priceFormat(item.dcc_anual_fee_foreign)} 원</p> : ''}
                                        {item.dcc_anual_fee_internal ? <p>국내 {priceFormat(item.dcc_anual_fee_internal)} 원</p> : ''}
                                    </td>
                                    <td className={'p-0 position-table'}>
                                        <div className={'list-include-price'}>
                                            <div className={'include-price'}>
                                                {item.dcc_include ?
                                                    <h6>
                                                        포함
                                                    </h6>
                                                    : "카드사에 문의해 주세요."}
                                                {item.dcc_include ? <div className="text-11" dangerouslySetInnerHTML={{__html: this.limitFont(item.dcc_include)}}/> : ''}

                                            </div>

                                            <div className={'include-price'}>
                                                {item.dcc_not_include ?
                                                    <h6>
                                                        미포함
                                                    </h6>
                                                    : "카드사에 문의해 주세요."}
                                                {item.dcc_not_include ? <div className="text-11" dangerouslySetInnerHTML={{__html: this.limitFont(item.dcc_not_include)}} /> : ''}
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            )
                        })}

                        </tbody>

                    </table>
                    <p className="text-footer-modal">
                        ※ 위 내용은 카드사 및 제휴사에 따라 변경이 될 수 있으며, 할인 혜택 및 상세 제공 조건에 대해서는 카드사 홈페이지 및 통신사 정보를 확인하셔야 됩니다
                    </p>
                </div>


                {/*<OpenPopupPrint*/}
                {/*    openPopupPrint={this.openPopupPrint}*/}
                {/*    isOpenPopupPrint={this.state.isOpenPopupPrint}*/}
                {/*/>*/}


            </Modal>
        )
    }
}

export default OpenPopupDiscountCardDetail;
