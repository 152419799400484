import {
    modalActions
} from '../actions';

const initialState = {
    showModal: false,
    title: '',
    html_title: null,
    html_footer: null,
    component: null,
    toggle: false,
    class_n: '',
    close: false,
    class_footer: '',
    backdrop: '',
    center: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case modalActions.SHOW_MODAL: {
            const { data } = action.payload;
            return {
                ...state,
                showModal: true,
                toggle: data && data.toggle ? data.toggle : false,
                class_n: data && data.class_n ? data.class_n : '',
                close: data && data.close ? data.close : '',
                component: data && data.component ? data.component : null,
                title: data && data.title ? data.title : '',
                html_title:data && data.html_title ? data.html_title : null,
                html_footer:data && data.html_footer ? data.html_footer : null,
                class_footer:data && data.class_footer ? data.class_footer : null,
                backdrop:data && data.backdrop ? data.backdrop : null,
                center: data && data.center ? data.center : false,
            };
        }
        case modalActions.HIDE_MODAL: {
            return {
                ...state,
                showModal: false,
            };
        }
        case modalActions.CLEAR_MODAL: {
            return {
                ...state,
                showModal: false,
                title: '',
                component: null,
            };
        }
        case modalActions.CHANGE_MODAL_TITLE: {
            const { title } = action.payload;
            return {
                ...state,
                title,
            };
        }
        case modalActions.CHANGE_MODAL_CONTENT: {
            const { component } = action.payload;
            return {
                ...state,
                component,
            };
        }
        default:
            return state;
    }
};

export default reducer;
