
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues } from 'redux-form';
import PlanDetailsShortDescription from "./PlanDetails/PlanDetailsShortDescription"
import ChangeDateComponent from './ChangeableDate/ChangeDateComponent';
import PackageFee from './PackageFeeRight/PackageFee';
import WirelessAdviceInformation from './WirelessAdviceInformation'
import {caclWirelessStep3,priceFormat, chooseOption2G, roundUpNumber, planEstimateCT, welfareDiscountCT} from "../../utils/helper";
import _ from 'lodash'
import ComparisonSubsidies from './ComparisonSubsidies/ComparisonSubsidies';
class WirelessTotalPerMonth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenComparisonSubsidies : false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.WirelessAdvice && prevProps.WirelessAdvice) {
      const { WirelessAdvice: { WirelessAdviceStep1, WirelessAdviceStep3 } } = this.props;
      let wirelessAdviceStep3 = {...WirelessAdviceStep3};

      let keysToCheckDiff = [
        'startingPrice',
        'basicCost',
        'optionFee',
        'disclosureSubsidy',
        'additionalGrants',
        'optionalAgreement',
        'pointDiscount',
        'advancedDiscountCard',
        'cashPayment',
        'other',
        'installmentMonth',
        'promotion',
        'combinedDiscount',
        'welfareDiscount',
        'discountCard',
        'insurrance',
        'extraServices',
        'interestRate',
        'applyUsedProgram',
        'planNewDateChange',
        'dataPlanNewDateChange',
        'rateAgreement',
        'disCost12',
        'disCost24'
      ]
      var checkWirelessAdviceStep3OldObj = _.pick(prevProps.WirelessAdvice.wirelessAdviceStep3, ...keysToCheckDiff)
      var checkWirelessAdviceStep3NewObj = _.pick(wirelessAdviceStep3, ...keysToCheckDiff)
      var checkEqual = _.isEqual(checkWirelessAdviceStep3OldObj, checkWirelessAdviceStep3NewObj)
      if (!checkEqual) {
        let optionalAgreementNewPlan = 0;
        if (wirelessAdviceStep3.dataPlanNewDateChange) {
          if (wirelessAdviceStep3.optionFee == 0) {
            if (wirelessAdviceStep3.dataPlanNewDateChange.spec_cmm == 1) {
              optionalAgreementNewPlan = +(wirelessAdviceStep3.dataPlanNewDateChange.spec_cmm_value * wirelessAdviceStep3.planNewDateChange/100)
            }else{
              optionalAgreementNewPlan = +(wirelessAdviceStep3.phone.commitment_rate * wirelessAdviceStep3.planNewDateChange/100)
            }
          }else{
            optionalAgreementNewPlan = 0;
          }
          // if (wirelessAdviceStep3.conversion2G == 1 && wirelessAdviceStep3.optionFee == 0) {
          //   wirelessAdviceStep3.optionalAgreement = 0.7*wirelessAdviceStep3.planNewDateChange
          // }else{
          //   wirelessAdviceStep3.optionalAgreement = optionalAgreementNewPlan
          // }
          let discost12 = +wirelessAdviceStep3.dataPlanNewDateChange.discost_12 + wirelessAdviceStep3.dataPlanNewDateChange.discost_12*10/100;
          let discost24 = +wirelessAdviceStep3.dataPlanNewDateChange.discost_24 + wirelessAdviceStep3.dataPlanNewDateChange.discost_24*10/100;
          wirelessAdviceStep3.discost12 = discost12;
          wirelessAdviceStep3.discost24 = discost24;
          if (wirelessAdviceStep3.discostChange == 12) {
            wirelessAdviceStep3.rateAgreement = discost12;
          } else {
            wirelessAdviceStep3.rateAgreement = discost24;
          }
          let option2G = chooseOption2G(wirelessAdviceStep3.conversion2G, wirelessAdviceStep3);
          wirelessAdviceStep3.optionalAgreement = wirelessAdviceStep3.optionalAgreementPlanEstimated;
          wirelessAdviceStep3.basicCost = wirelessAdviceStep3.planNewDateChange;
          
        }

        // let WirelessAdviceStep3 = {
        //   ...WirelessAdvice.WirelessAdviceStep3,
        //   dataPlanNewDateChange: data,
        //   optionalAgreementPlanEstimated: result.optionalAgreement,
        //   rateAgreement: result.rateAgreement,
        //   basicCost: cost_display,
        //   basicCostCalc: cost_calc
        // }
        // let welfareDiscountCTResult = 0;
        // if (WirelessAdvice.WirelessAdviceStep3.welfareDiscount) {
        //   welfareDiscountCTResult = welfareDiscountCT(WirelessAdviceStep3);
        // }
        
        // console.log(welfareDiscountCTResult, 'welfareDiscountCTResult');
        // WirelessAdviceStep3 = {
        //   ...WirelessAdviceStep3,
        //   welfareDiscount:  welfareDiscountCTResult
        // }
        // let CT = planEstimateCT(0, WirelessAdviceStep3, 2, true);




        if (wirelessAdviceStep3.dataPlanNewDateChange) {
          let WirelessAdviceStep3Clone = null;

          if(wirelessAdviceStep3.dataPlanNewDateChange.cost_display){
            WirelessAdviceStep3Clone = {
              ...WirelessAdviceStep3,
              optionalAgreementPlanEstimated: +WirelessAdviceStep3.optionalAgreementPlanEstimated,
              rateAgreement: +WirelessAdviceStep3.rateAgreementEstimate,
              basicCost: +WirelessAdviceStep3.dataPlanNewDateChange.cost_display + +WirelessAdviceStep3.dataPlanNewDateChange.cost_display*10/100,
              basicCostCalc: +WirelessAdviceStep3.dataPlanNewDateChange.cost_calc + +WirelessAdviceStep3.dataPlanNewDateChange.cost_calc*10/100
            }
          }
          else{
            WirelessAdviceStep3Clone = {
              ...WirelessAdviceStep3,
              optionalAgreementPlanEstimated: +WirelessAdviceStep3.optionalAgreementPlanEstimated,
              rateAgreement: +WirelessAdviceStep3.rateAgreementEstimate,
              basicCost: +WirelessAdviceStep3.planNewDateChange,
              basicCostCalc: +WirelessAdviceStep3.planNewDateChange
            }
          }
          
          let welfareDiscountCTResult = 0;
          
          if (WirelessAdviceStep3.welfareDiscount) {
            welfareDiscountCTResult = welfareDiscountCT(WirelessAdviceStep3Clone);
          }

          WirelessAdviceStep3Clone = {
            ...WirelessAdviceStep3Clone,
            welfareDiscount:  welfareDiscountCTResult,
            // rateAgreement: +WirelessAdviceStep3.rateAgreementEstimate
          }
          /*
          if (WirelessAdviceStep1.telcomCode === 'lgu') {
            WirelessAdviceStep3Clone.promotion = {planID: 0, promo_name: '미적용', promo_cost: 0}
            WirelessAdviceStep3Clone.promotionDurationDiscountOne = 0
            WirelessAdviceStep3Clone.promotionDurationDiscountTwo = 0
          }
          */

          let CT = planEstimateCT(0, WirelessAdviceStep3Clone, 2, true);
          let estimatedRatesChangingPlans = +CT.monthlyFee + (+CT.installmentMonthly);

          if((WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
            estimatedRatesChangingPlans = estimatedRatesChangingPlans + 2000;
          }
          else if((WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 192){ // KT S23 프리미엄 Y 에디션
            estimatedRatesChangingPlans = estimatedRatesChangingPlans + 1000;
          }

          this.props.change('WirelessAdviceStep3.estimatedRatesChangingPlans', estimatedRatesChangingPlans);
          this.props.change('WirelessAdviceStep3.welfareDiscountExpectedPlan', {
              ...WirelessAdviceStep3.welfareDiscount,
              cost: welfareDiscountCTResult
          })
        } else {
           this.props.change('WirelessAdviceStep3.estimatedRatesChangingPlans', 0)
          this.props.change('WirelessAdviceStep3.welfareDiscountExpectedPlan', null)
        }
      }
    }
  }

  render() {
    const { WirelessAdvice } = this.props;
    // let disclosureSubsidyDifference;
    // if (WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange != null) {
    //   disclosureSubsidyDifference = priceFormat((+WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - (+WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifference));
    // } else {
    //   disclosureSubsidyDifference = 0
    // }
    let stypeNumber = false;
    if (WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference != null && WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference >0) {
      stypeNumber = true
    } else {
      stypeNumber = false;
    }

    let conversionStypeNumber = false;
    if (
      WirelessAdvice.WirelessAdviceStep3?.converseDifference != null &&
      WirelessAdvice.WirelessAdviceStep3.converseDifference > 0 &&
      +WirelessAdvice.WirelessAdviceStep3.conversion_price_input > 0
    ) {
      conversionStypeNumber = true
    } else {
      conversionStypeNumber = false;
    }

    let is_2g_lgu_skt = (['skt', 'lgu'].includes(WirelessAdvice.WirelessAdviceStep1.telcomCode) && (WirelessAdvice.WirelessAdviceStep3.conversion2G?.id || 0) == 0)
      || !['skt', 'lgu'].includes(WirelessAdvice.WirelessAdviceStep1.telcomCode)

    return (
      <Fragment>
        <div className={'top-right'}>
          <div className="name-item border-bottom-blue justify-content-between">
            <h2>월 납부 요금정보 (A+B)</h2>
            <button type="button" class="btn" style={{fontSize : "14px"}} onClick={() => {this.setState({ isOpenComparisonSubsidies : true })}}>지원금비교</button>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 pr-0">
              <p className="label-prices mb-1">당월 예상 금액</p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-md-8">
              <p className="text-right mb-0 prices-number">
                {priceFormat(WirelessAdvice?.WirelessAdviceStep3?.estimatedAmountMonth)}
                <span>원</span>
              </p>
              {/*<p className="text-right mb-0 text-content">
                (단말 {priceFormat(WirelessAdvice?.WirelessAdviceStep3?.installmentMonthly)}원 + 요금{priceFormat(WirelessAdvice?.WirelessAdviceStep3?.moneyRemainingMonth)}<span>원</span>)
              </p>*/}
            </div>
          </div>
          <div className="row mt-2 align-items-center justify-content-between">
            <div className="col-auto pr-0">
              <p className="label-prices mb-1">월별 청구금액</p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-auto pl-0">
              <p className="text-right mb-0 text-wrap prices-number total-big">
                {/* {
                  WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id && !WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_id
                  ? priceFormat( roundUpNumber(WirelessAdvice?.WirelessAdviceStep3?.instMonthlyDefer_1  + WirelessAdvice?.WirelessAdviceStep3?.monthlyFee) )
                  : priceFormat(WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyDevice)
                } */}
                { 
                  priceFormat(WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyDevice) 
                }
                <span>원</span>
              </p>
              {
                WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id && WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_id
                && (<p className="text-right mb-0 text-content">중고폰 부가서비스 금액 포함 </p>)
              }
            </div>
          </div>

          <PlanDetailsShortDescription />
          <div className="row">
            <div className="col-md-7 mt-2 pr-0">
              <p className="label-prices mb-1 text-wrap">요금제 변경시 예상요금</p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-md-5">
              <p className="text-right mb-0 prices-number s rate-change-plan">{priceFormat(WirelessAdvice?.WirelessAdviceStep3?.estimatedRatesChangingPlans)}<span>원</span></p>
            </div>
          </div>
          {/* 요금제 */}
          <PackageFee /> 
          {/* <div className="form-group row mt-2">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금제</label>
          <div className="col-sm-8">
            <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#exampleModal12">
              변경할 요금제
            </button>
          </div>
        </div> */}
          <ChangeDateComponent />
          {/* <div className="form-group mb-1 row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">변경 가능일자</label>
          <div className="col-sm-8">
            <div className="form-date">
              <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-81">
                변경 가능일자
                <img src="/images/icon-calender.svg" alt="" />
              </button>
            </div>
          </div>
        </div> */}
          <div className="form-group mt-3 mb-3 row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">공시차액정산금</label>
            <div className="col-sm-8">
                <div className="form-date">
                  <button className={`btn btn-input-form mb-0 ${stypeNumber ? 'btn-prices-wp' : ''}`} type="button" >
                    {!is_2g_lgu_skt ? 0 : (WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference != null && WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference >=0 ? priceFormat(WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference) : '')}
                    <span>
                    {!is_2g_lgu_skt ? '원' : (WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference != null && WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifference >=0 ? '원' : '')}
                    </span>
                  </button>
                </div>
            </div>
          </div>
          <div className="form-group mt-3 mb-3 row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">전환차액정산금</label>
            <div className="col-sm-8">
                <div className="form-date">
                  <button className={`btn btn-input-form mb-0 ${conversionStypeNumber ? 'btn-prices-wp' : ''}`} type="button" >
                    {
                      WirelessAdvice.WirelessAdviceStep3?.converseDifference != null &&
                      WirelessAdvice.WirelessAdviceStep3?.converseDifference >=0 &&
                      +WirelessAdvice.WirelessAdviceStep3.conversion_price_input > 0 ?
                      priceFormat(WirelessAdvice.WirelessAdviceStep3?.converseDifference)
                      : ''
                    }
                    <span>
                    {
                      WirelessAdvice.WirelessAdviceStep3?.converseDifference != null &&
                      WirelessAdvice.WirelessAdviceStep3?.converseDifference >=0 &&
                      +WirelessAdvice.WirelessAdviceStep3.conversion_price_input > 0 ?
                      '원' : ''
                    }
                    </span>
                  </button>
                </div>
            </div>
          </div>
          {/*<div className="total-number pb-2">
            <p className="text-content mb-0" style={{fontSize:13}}>심플코스 충족 시 차액정산 미부과</p>
            <p className="text-content mb-0" style={{fontSize:13}}> (단, 월정액이 0원인 요금제로 변경시 위약금 발생)</p>
            <p className="text-content mb-0" style={{fontSize:13}}>-180일 경과 후, 면제 예외적용 > 5G: 월정액 4만7천원  </p>
            <p className="text-content mb-0" style={{fontSize:13}}>미만/LTE, 3G: 월정액 2만원 미만 변경시 180일 이후</p>
            <p className="text-content mb-0" style={{fontSize:13}}>더라도 차액정산금 발생</p>
          </div>*/}

          {(function() {
            switch (WirelessAdvice.WirelessAdviceStep1.telcomCode) {
              case 'skt':
                return <div className="total-number pb-2">
                  <p className="text-content mb-0" style={{fontSize:13}}>프리미엄 패스 충족 시 차액정산 미부과</p>
                  <p className="text-content mb-0" style={{fontSize:13}}> (단, 월정액이 0원인 요금제로 변경 시 위약금 발생)</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>181일 이후 프패 면제 예외적용 > 5G: 월정액 4만2천원  </p>
                  <p className="text-content mb-0" style={{fontSize:13}}>미만 /3G,LTE :월정액 2만원 미만 변경시, 181일 이후더</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>라도, 차액정산금 발생</p>
                </div>;
              case 'kt':
                return <div className="total-number pb-2">
                  <p className="text-content mb-0" style={{fontSize:13}}>심플코스 충족 시 차액정산 미부과</p>
                  <p className="text-content mb-0" style={{fontSize:13}}> (단, 월정액이 0원인 요금제로 변경시 위약금 발생)</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>-180일 경과 후, 면제 예외적용 > 5G: 월정액 4만7천원  </p>
                  <p className="text-content mb-0" style={{fontSize:13}}>미만/LTE, 3G: 월정액 2만원 미만 변경시 180일 이후</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>더라도 차액정산금 발생</p>
                </div>;
              case 'lgu':
                return <div className="total-number pb-2">
                  <p className="text-content mb-0" style={{fontSize:13}}>식스플랜 충족 시 차액정산 미부과</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>  (단, 월정액이 0원인 요금제로 변경시 위약금 발생)</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>-180일 경과 후, 면제 예외적용 > 5G : 월정액 45,000원미만</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>/LTE:월정액26,400원 미만 변경시 180일 이후더라도</p>
                  <p className="text-content mb-0" style={{fontSize:13}}>차액정산금 발생</p>
                  <p className="text-content mb-0" style={{fontSize:11}}>*변경 후 요금제가 시니어/키즈 대상요금제인 경우 위약금 면제 </p>
                  <p className="text-content mb-0" style={{fontSize:11}}>대상요금제 : ①시니어 : 5G시니어B형/C형,LTE시니어33/34</p>
                  <p className="text-content mb-0" style={{fontSize:11}}>②키즈:5G키즈29/39 LTE키즈22/29/39</p>
                  <p className="text-content mb-0" style={{fontSize:11}}>단, 전환지원금 없는 요금제로 변경 시 전환차액정산금 부과</p>
                </div>;
              default:
                return null;
            }
          })()}

          
        </div>

        <WirelessAdviceInformation />
        <ComparisonSubsidies
          isOpenModal={this.state.isOpenComparisonSubsidies}
          openPopup={() => {this.setState({ isOpenComparisonSubsidies : false });}}
        />
      </Fragment>
    );
  }
}

WirelessTotalPerMonth.propTypes = {
  WirelessAdvice: PropTypes.object
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessTotalPerMonth);
