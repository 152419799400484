import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as routerName from '../../utils/routerName';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class SupportMoneyModalOne extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: 22050,
            invalid: false
        }
    }

    toggle = () => {
        this.props.toggleEvent()
    }

    toggleTwo = () => {
        this.setState({
            modalTwo: !this.state.modalTwo,
            invalid: false
        }, () => {
            if (this.state.modalTwo) {
                this.props.toggleEvent()
            }
        })
    }

    getValueMoney = (value) => {
        let {moneyDefault} = this.props;
        switch (value) {
            case '0':
                this.setState({
                    resultMoney: 0
                }, () => {
                    this.props.getValue(this.state.resultMoney);
                })
                break;
            case '15':
                this.setState({
                    resultMoney: moneyDefault*0.15
                }, () => {
                    this.props.getValue(this.state.resultMoney);
                })
                break;
            case '15-MNP':
                this.setState({
                    resultMoney: moneyDefault*0.15 - 800
                }, () => {
                    this.props.getValue(this.state.resultMoney);
                })
                break;
            default:
                break;
        }
        this.props.toggleEvent();
    }

    handleChange = (e) => {
        this.setState({valueSelect:e.target.value})
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.valueSelect < this.props.installmentMoney) {
            this.setState({
                resultMoney: this.state.valueSelect
            }, () => {
                this.props.getValue(this.state.resultMoney);
            })
            this.toggleTwo()
        }else{
            this.setState({
                modalTwo: true,
                invalid: true
            })
        }
    }
    resetValue = () => {
        this.setState({
            valueSelect: 22050
        })
    }
    render() {
        let {valueSelect} = this.state;
        return (
            <Fragment>
                <Modal 
                    isOpen={this.props.showModal} 
                    toggle={this.toggle}
                    // backdrop={"static"}
                    className="modal-dialog-centered modal-additional"
                    contentClassName="pl-4 pr-4"
                >
                     <div className="modal-header">
                        <h5 className="modal-title">추가지원금 </h5>
                        <Button type="button" className="close close-modal" onClick={this.toggle}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </Button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-additional">
                            <li className="active" onClick={this.getValueMoney.bind(this, "0")}>
                                미적용
                            </li>
                            <li onClick={this.getValueMoney.bind(this, "15")}>
                                15%
                            </li>
                            <li onClick={this.getValueMoney.bind(this, '15-MNP')}>
                                15% - MNP
                            </li>
                            <li className="cursor" data-toggle="modal" onClick={this.toggleTwo}>
                                직접 입력
                            </li>
                        </ul>
                    </div>
                </Modal>
                <Modal 
                    isOpen={this.state.modalTwo} 
                    toggle={this.toggleTwo}
                    // backdrop={"static"}
                    className="modal-dialog-centered "
                    contentClassName="pl-4 pr-4"
                >
                     <div className="modal-header">
                        <h5 className="modal-title">추가지원금 </h5>
                        <Button type="button" className="close close-modal" onClick={this.toggleTwo}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </Button>
                    </div>
                    <div className="modal-body pl-4 pr-4">
                        <form onSubmit={this.handleSubmit}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 text-modal-gray">지원금 15% 금액 : 22,050 원</p>
                                <button type="button" className="btn btn-gray-white" onClick={this.resetValue}>적용</button>
                            </div>
                            <div className="form-group row mt-4 align-items-center">
                                <div className="col-10 pr-0">
                                    <input 
                                        type="text" 
                                        className={this.state.invalid ? 'form-control h-44 is-invalid' : 'form-control h-44'} 
                                        value={valueSelect} 
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-2">
                                    <span className="text-wrapper-gray">원</span>
                                </div>
                            </div>
                            {
                            this.state.invalid ? (
                                <h5 className="text-wrapper-gray text-left color-orange">
                                    ※ 할부원금을 초과한 금액은 적용하실 수 없습니다.
                                </h5>
                            ) : ''
                            }
                            
                            <h5 className="text-wrapper-gray text-left">
                                직접 입력한 추가지원금은 계약서 출력 시 적용되지 않습니다.
                                입력한 추가지원금을 적용하시겠습니까?
                            </h5>
                            <div className="d-flex justify-content-center border-top-0 mt-5">
                                <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" onClick={this.toggleTwo}>취소</button>
                                <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(SupportMoneyModalOne);

