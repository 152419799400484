import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import InstallationFeeList from './../../InstallationFee/InstallationFeeList'
import {showLoading, hideLoading} from '../../../store/actions/ui';
import { priceFormat } from '../../../utils/helper';

const cussorDefault = {
  cussor:'default'
}

const TEXT = {
  2: 'TV 플러스',
}

const InstallationFeeInformation = ({WiredAdvice}) => {
  return (
    <Fragment>
      <div className="table-responsive">
        <InstallationFeeList
          classify={WiredAdvice.WiredAdviceStep2?.classifyString ? JSON.parse(WiredAdvice.WiredAdviceStep2?.classifyString) : []}
          combineCondition={WiredAdvice.WiredAdviceStep2.combineCondition || {}}
          WiredAdvice={WiredAdvice || {}}
        />
      </div>
    </Fragment>
  );
}

InstallationFeeInformation.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  openPopup: PropTypes.func,
  isOpenModal: PropTypes.bool,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(InstallationFeeInformation);
