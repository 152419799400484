import React, { Component, Fragment } from 'react'; 
import { Modal} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customizeDatepicker.css';
import moment from 'moment';
import {PACKAGE_DEFAULT, LABEL_DEFAULT_DATE , ALIAS_FOR_DATEPICKER} from './PackageDateChange';

class ChangeDatePlanModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value : PACKAGE_DEFAULT,
            labelDate : LABEL_DEFAULT_DATE,
            isClick : false,
        };
    }


    /**
     * 
     */
    toggleModal = () => {
        this.props.toggleModal();
    }

 
    /**
     * 
     */
    elementIconDatePicker = () => {
        return (
            <div className="d-flex w-100">
                <span className="left">{this.state.labelDate}</span>
                    <div className="right">
                    <div className="btn p-0">
                        <img src="../../images/icon-calender.svg" alt=""/>
                    </div>
                </div>
            </div>       
        )
    }

    /**
     * handle change
     */
    handleChange = (value) => {
        if (value != 'date') {
            this.setState({
                labelDate : LABEL_DEFAULT_DATE
            });
        }
        this.setState({
            isClick : true,
            value : value
        });
    }

    
    /**
     * closeModal
     */
    closeModal = () => {
        this.setState({
            isClick : false,
            value : PACKAGE_DEFAULT,
            labelDate : LABEL_DEFAULT_DATE,
        })
    }

    /**
     * handle select datepicker
     */
    handleSelectDatePicker = (val) => {
       let dataFormat = moment(val).format("DD/MM/YYYY");
       this.setState({
           value : dataFormat,
           labelDate : dataFormat
       });
    }

    /**
     * get classname
     */
    getClassName = (tabIndex) => {
        if (this.state.isClick) {
            if (tabIndex == this.state.value) {
                return 'active';
            }
        }
        return '';
    }

    /**
     * handle submit form 
     */
    handleSubmit = () => {
        let value = this.state.value;

        // check user click datepicker and choose date
        if (this.state.labelDate != LABEL_DEFAULT_DATE) {
            value = this.state.labelDate;
        }
        
        // check user click datepicker and not choose date
        if (value == ALIAS_FOR_DATEPICKER) {
            value = PACKAGE_DEFAULT;
        }

        // plus day
        if (!value.includes("/")){
            let date = value.replace(/\D/g, "");
            if (date) {
                let plusDay = moment(new Date()).add(date, 'days').toDate();
                value = moment(plusDay).format("DD/MM/YYYY");
            }
        }
        
        this.props.getValuesDate(value);
        this.toggleModal();
    }
    

    render() {
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal} 
                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    contentClassName="pl-5 pr-5" 
                    toggle = {this.toggleModal}
                       // backdrop={"static"}
                    onClosed = {this.closeModal}

                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            요금제 변경가능 날짜
                        </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.toggleModal}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className="modal-body discount-card pl-0 pr-0">
                        <form action="">
                            <ul className="list-item">
                                <li className={this.getClassName('미적용')} onClick={this.handleChange.bind(this,'미적용')} >
                                    <span className="left">미적용</span>
                                </li>
                                <li className = {this.getClassName(ALIAS_FOR_DATEPICKER)} onClick={this.handleChange.bind(this,ALIAS_FOR_DATEPICKER)}>
                                    <DatePicker 
                                        customInput={this.elementIconDatePicker()}
                                        onSelect={this.handleSelectDatePicker}
                                    />
                                </li>
                                
                                <li className = {this.getClassName('서비스 유지')} onClick={this.handleChange.bind(this,'서비스 유지')} >
                                    <span className="left">서비스 유지</span>
                                </li>
                                <li className = {this.getClassName('31일')} onClick={this.handleChange.bind(this,'31일')}>
                                    <span className="left">31일</span>
                                </li>
                                <li className = {this.getClassName('91일')} onClick={this.handleChange.bind(this,'91일')}>
                                    <span className="left">91일</span>
                                </li>
                                <li className = {this.getClassName('93일')} onClick={this.handleChange.bind(this,'93일')}>
                                    <span className="left">93일</span>
                                </li>
                                <li className = {this.getClassName('95일(당일포함)')} onClick={this.handleChange.bind(this,'95일(당일포함)')}>
                                    <span className="left">95일(당일포함)</span>
                                </li>
                                <li className = {this.getClassName('125일')} onClick={this.handleChange.bind(this,'125일')}>
                                    <span className="left">125일</span>
                                </li>
                                <li className = {this.getClassName('183일')} onClick={this.handleChange.bind(this,'183일')}>
                                    <span className="left">183일</span>
                                </li>
                            </ul>

                            <div className="modal-footer justify-content-center border-top-0 mb-2">
                                <button type="button" className="btn btn-submit pl-5 pr-5" onClick = {this.handleSubmit} >확인</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                
            </Fragment>
        );
    }
}


 
export default ChangeDatePlanModal;

