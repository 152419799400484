import React from "react";

class Kakao extends React.Component{


  componentDidMount() {
    let btn = document.getElementById('kakaostory-share-button')
    btn.setAttribute('data-href', this.props.sharelink)
    window.kakaoTalk();
  }

  onClickKakao = () => {
    let btn = document.getElementById('kakaostory-share-button')
    btn.click();
  }


  render() {
    return (
      <div className="box_social"  id="kakao-link-btn" onClick={this.onClickKakao}>
        <a >
        <img src={process.env.REACT_APP_HOSTNAME + "/images/kakao.svg"} alt=""/>
        </a>
        <span>KakaoTalk</span>
      </div>
    )
  }
}

export default Kakao;
