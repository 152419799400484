import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import WireProductDetailModal from "./WireProductDetailModal";
import DiscountConditionModal from "./DiscountConditionModal";
import api from "../../../utils/api";
import PropTypes from 'prop-types';
import {priceFormat} from "../../../utils/helper";
import _ from 'lodash';

class WireProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wire_product_detail_modal:{
                isShowModal: false
            },
            wire_devices:{
                list: [],
                selected: null
            },
            options:{
                list: [],
                selected: null
            }

        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {

    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.props.showLoading();
        let conditionID = this.props.combineCondition &&  this.props.combineCondition.id;
        switch (conditionID) {
            case 4:
                conditionID = 16
                break;
            case 11:
                conditionID = 10
                break;
            case 12:
                conditionID = 13
                break;
            case 17:
                conditionID = 18
                break;

        }

        api.advice.getListWireDevice(conditionID).then(res => {
            this.setState({
                wire_devices: {
                    ...this.state.wire_devices,
                    list: res.data.data,
                    selected: this.props.selected.wire_device
                },
                options: {
                    ...this.state.wire_devices,
                    selected: this.props.selected.option
                }
            }, () => {
                this.props.hideLoading();
            })

        }).catch(err => {
            this.props.hideLoading();
        })
    }

    toggleModal = (e, modal) => {
        e.preventDefault();
        this.setState({
            [modal]:{
                ...this.state[modal],
                isShowModal: !this.state[modal].isShowModal
            }
        })
    }

    onApply = (e) => {
        let dataSelected = {
            wire_device: this.state.wire_devices.selected,
            option: this.state.options.selected,
            wire_devices_list: this.state.wire_devices.list
        }
        this.props.onApply(e, dataSelected);
    }
    renderWireDevices = () => {
        return (
            <ul className="list-item  list_wireless  mt-0">
                <li className={!this.state.wire_devices.selected ? "bg-item" : "" }
                    onClick={() => this.setState({
                        wire_devices: {
                            ...this.state.wire_devices,
                            selected: null
                        },
                        options: {
                            ...this.state.options,
                            selected: null
                        }
                    })}>
                    <span>미적용</span>
                    <span>0 원</span>
                </li>
                {
                    this.state.wire_devices.list.map((item, key) => {
                        return (
                            <li key={key}
                                className={this.state.wire_devices.selected && this.state.wire_devices.selected.id == item.id ? "bg-item" : ""}
                                onClick={() => this.onSelectWireProduct(item)}>
                                <span>{ item.wire_device && item.wire_device.wd_name }</span>
                                <span> { item.wire_device && item.wire_device.wd_commitment_fee ? priceFormat(item.wire_device.wd_commitment_fee) : 0} 원</span>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
    renderOptions = () => {
        let wiredDevicesApply =  this.state.wire_devices.selected;
        let optionsApplied = wiredDevicesApply
            && wiredDevicesApply.wire_device
            && wiredDevicesApply.wire_device.wired_device_option_apply
            && wiredDevicesApply.wire_device.wired_device_option_apply.length > 0 ? wiredDevicesApply.wire_device.wired_device_option_apply : [];
        return (
            <ul className="list-item list_wireless mt-0">
                {
                    !this.state.wire_devices.selected && (
                        <li  className={!this.state.options.selected ? "bg-item" : "" }
                            onClick={() => this.setState({
                                options: {
                                    ...this.state.options,
                                    selected: null
                                }
                            })}
                        >
                            <span>미적용</span>
                            <span>0 원</span>
                        </li>
                    )
                }
                
                {
                    optionsApplied && optionsApplied.length > 0 && optionsApplied.map((item, key) => {
                        return (
                            <li key={key}
                                className={this.state.options.selected && this.state.options.selected.wdo_id == item.wdo_id ? "bg-item" : ""}
                                onClick={() => this.setState({
                                options: {
                                    ...this.state.options,
                                    selected: item
                                }
                            })}>
                                <span>{ item.wdo_name }</span>
                                <span> { item.wdo_commitment_fee  ? priceFormat(item.wdo_commitment_fee) : 0} 원</span>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }


    onSelectWireProduct = (data) => {
        let optionDefault = data.wire_device
                            && data.wire_device.wired_device_option_basic
                            && data.wire_device.wired_device_option_basic.length > 0
                                ? data.wire_device.wired_device_option_basic[0]
                                : null;
        this.setState({
            wire_devices:{
                ...this.state.wire_devices,
                selected: data
            },
            options:{
                ...this.state.options,
                selected: optionDefault ? optionDefault : null
            }
        })
    }


    render() {
        const isShowWireProductDetailModal = this.state.wire_product_detail_modal.isShowModal;
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-dialog-centered modal-custom-02"
                       contentClassName="pr-5 pl-5"
                       toggle = {(e) => this.props.toggleModal(e,'wire_product')}
                       onOpened = {this.onOpened}
                       onClosed = {this.onCloseModal}
                       // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title text-left w-100">인터넷 상품 선택</h5>
                        <button type="button" className="close close-modal"
                               onClick={(e) => this.props.toggleModal(e, 'wire_product')}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body pr-0 pl-0">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="top-content h-44">
                                    <h5 className="mb-0">1.상품선택</h5>
                                    <button className="btn btn-wireless"
                                            type="button"
                                            onClick={(e) => this.toggleModal(e,'wire_product_detail_modal')}
                                           >
                                        상품 정보 보기
                                    </button>
                                </div>
                                { this.renderWireDevices()}
                            </div>
                            <div className="col-md-6">
                                <div className="top-content  h-44">
                                    <h5 className="mb-0">2.옵션선택</h5>
                                </div>
                                { this.renderOptions()}
                            </div>
                            <div className="col-12">
                                <div className="total-modal">
                                    <h4>
                                        월 이용료
                                    </h4>
                                    <span>
                                        { priceFormat(+(this.state.wire_devices.selected && this.state.wire_devices.selected.wire_device && this.state.wire_devices.selected.wire_device.wd_commitment_fee ? this.state.wire_devices.selected.wire_device.wd_commitment_fee : 0)
                                        + +(this.state.options.selected  && this.state.options.selected.wdo_commitment_fee ? this.state.options.selected.wdo_commitment_fee : 0))}  원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                        <button type="button" className="btn btn-gray pr-5 pl-5"
                                onClick={(e) => this.props.toggleModal(e,'wire_product')}
                        >취소</button>
                        <button type="button"
                                className="btn btn-submit pl-5 pr-5"
                                onClick={(e) => this.onApply(e)}
                        >확인</button>
                    </div>
                </Modal>
                <WireProductDetailModal
                    wire_devices = {this.state.wire_devices.list}
                    onSelectWireProduct={this.onSelectWireProduct}
                    toggleModal={this.toggleModal}
                    isShowModal={isShowWireProductDetailModal}
                />
            </Fragment>
        );
    }
}

WireProductModal.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    combineCondition: PropTypes.object,
    selected: PropTypes.object,
    onApply: PropTypes.func,
};

const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(WireProductModal);
