import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {change, Field, getFormMeta,  getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';
import renderTextField from "../../components/FormHelper/TextField";
import api from "../../utils/api";
import {hideLoading, showLoading} from "../../store/actions/ui";
import { SubmissionError , reset} from "redux-form";
import {isIssetArray} from "../../utils/helper";
import Moment from "react-moment";
import MessageJSON from "../../utils/message";
import $ from "jquery";
import {hideModal, showModal} from "../../store/actions/modal";
import moment from "moment";
import Tooltip from "../../components/Tooltip";



const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = MessageJSON.manager_sales.validate.name.required
    } else if (values.name.length > 64) {
        errors.name = MessageJSON.manager_sales.validate.name.max
    }

    // if (!values.username) {
    //     errors.username = MessageJSON.manager_sales.validate.username.required
    // } else if (values.username.length < 4 || values.username.length > 10) {
    //     errors.username = MessageJSON.manager_sales.validate.username.invalid
    // }
    return errors
}

class ManagerSales extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sellers: null,
            dataSearch: {
                page: 1,
                paginate: 10,
                keyword: '',
            },
            sellerDetail: null,
            modalDelete:{
                show: false,
                seller: {},
            },
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    componentDidMount() {
        document.body.classList.add("body_wireless_advice");   
        this.getSellers();
    }

    getSellers(){
        this.props.showLoading();
        let data = {
            ...this.state.dataSearch,
        }

        api.auth.getSellers(data).then(res => {
            const sellers  = res.data.data ? res.data.data : [];
            this.setState({
                sellers: sellers,
            }, () => {
                this.props.hideLoading();
            })
        }).catch(err => {
            this.props.hideLoading();
        });
    }

    onPagination = (data) => {
        var arrayNumber = [];
        for (let i = 1; i <= Math.ceil(data.total / data.per_page); i++) {

            if (data.current_page == i) {
                if ([1, 2].indexOf(data.current_page) == -1) {
                    if (i !== 1) {
                        arrayNumber.push(<li className="page-item" key={1}><a className="page-link" onClick={() => this.onClickPaginationHandle(1)}>1</a></li>);

                    } else {
                        arrayNumber.push(<li className="page-item" key={i - 2}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 2)}>{i - 2}</a></li>);
                    }
                }

                if (i - 2 > 1) {
                    arrayNumber.push(<li className="page-item" key={'...'}><a className="page-link">...</a></li>);
                }

                if ([1].indexOf(data.current_page) == -1) {
                    arrayNumber.push(<li className="page-item" key={i - 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 1)}>{i - 1}</a></li>);
                }

                arrayNumber.push(<li className="page-item active" key={i}><a className="page-link" onClick={() => this.onClickPaginationHandle(i)}>{i}</a></li>);

                if ((i + 1) < Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={i + 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i + 1)}>{i + 1}</a></li>);
                }

                if ([Math.ceil(data.total / data.per_page) - 1, Math.ceil(data.total / data.per_page) - 2].indexOf(i) == -1 && i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={'....'}><a className="page-link">...</a></li>);
                }


                if (i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={Math.ceil(data.total / data.per_page)}><a className="page-link" onClick={() => this.onClickPaginationHandle(Math.ceil(data.total / data.per_page))}>{Math.ceil(data.total / data.per_page)}</a></li>);
                }
            } else {
                //arrayNumber.push(<li className="page-item"><a className="page-link" >{i}</a></li>);
            }
        }
        if (Math.ceil(data.total / data.per_page) > 1) {
            return (
                <nav aria-label="Page navigation example" className="pagination-notifi">
                    <ul className="pagination">


                        <li className={`page-item item-previous ${this.state.dataSearch.page === 1 ? 'disabled' : ''}`}>
                            <a className="page-link"  aria-label="Previous" onClick={() => this.prevPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images/Left.svg" alt="" />
                                </span>
                            </a>
                        </li>
                        {arrayNumber.map((item, i) =>
                            item
                        )}
                        <li className={`page-item item-next ${this.state.dataSearch.page === this.state.sellers.last_page ? 'disabled' : ''}`}>
                            <a className="page-link"  aria-label="Next" onClick={() => this.nextPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images//Right.svg" alt="" />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>

            )
        }

    }

    prevPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page - 1
            }
        }, () => {
            this.getSellers()
        })
    }

    nextPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page + 1
            }
        }, () => {
            this.getSellers()
        })
    }

    onClickPaginationHandle = (number) => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: number
            }
        }, () => {
            this.getSellers()
        })

    }


    handleCreateSeller = (values) => {
        return api.auth.createSeller(values).then(res => {
            this.getSellers();
            this.props.initialize(initialValues);
            this.props.hideLoading();
            $('.btn_close_create').click();
        }).catch(err => {
            this.props.hideLoading();
            if(err.response.status == 422 &&  err.response.data &&
                err.response.data.errors){
                throw  new SubmissionError(err.response.data.errors);
            }else{
            }
        })
    }

    showSellerDetail = (seller) => {

        this.setState({
            sellerDetail:seller,
        }, () => {

            this.props.initialize({
                'id' : seller.uID,
                'name': seller.user && seller.user.name,
                // 'username': seller.user && seller.user.username,
            })
        })
    }

    showFormCreateSeller =() =>{
        this.props.initialize(initialValues);
    }

    renderSellers = (sellers) => {
        if(sellers && sellers.data.length > 0){
            console.log(this.state.sellers);
            return (
                <Fragment>
                    {
                        sellers.data.map((seller, i) => {
                            return (
                                <tr key={i }>
                                    <td>{ (this.state.sellers.current_page > 1 ? ((+this.state.sellers.current_page - 1)  * +this.state.sellers.per_page) : 0) + i + 1 }</td>
                                    {/*<td className="text-center">{ seller.user.username }</td>*/}
                                    <td>{ seller.user.name }</td>
                                    {/*<td>*/}
                                        {/*<Moment format="YYYY/MM/DD">*/}
                                        {/*{ seller.created_at }*/}
                                        {/*</Moment>*/}
                                    {/*</td>*/}
                                    <td >
                                        <div className="d-flex justify-content-end">
                                            <Tooltip message={"수정"}
                                                     position={'top'}
                                                     className={"mr-4"}
                                            >
                                                <a href="" data-toggle="modal"
                                                   data-target="#form_seller"
                                                   onClick={() => this.showSellerDetail(seller)}
                                                >
                                                    <i className="far fa-edit"></i>
                                                </a>
                                            </Tooltip>
                                            <Tooltip message={"삭제"}
                                                     position={'top'}
                                            >
                                                <a href=""
                                                   data-target="#delete_seller"
                                                   data-toggle="modal"
                                                   onClick={(e) => this.showPopupDeleteSeller(e,seller)}>
                                                    <i className="far fa-trash-alt"></i>
                                                </a>
                                            </Tooltip>

                                        </div>
                                    </td>

                                </tr>
                            )
                        })
                    }
                </Fragment>
            )

        }else{
            return (
                <Fragment>
                    <tr>
                        <td colSpan={4}>No sellers</td>
                    </tr>
                </Fragment>
            )
        }

    }

    showPopupDeleteSeller = (e, seller) => {
        e.preventDefault();
        this.setState({
            modalDelete: {
                ...this.state.modalDelete,
                show: true,
                seller
            }
        })
    };
    closePopupDeleteSeller = () => {
        this.setState({
            modalDelete: {
                ...this.state.modalDelete,
                show: false,
            }
        }, () => {
            $('.btn_close').click();
        })
    }
    deleteSeller = (seller) => {
        this.props.showLoading();
        this.closePopupDeleteSeller();
        api.auth.deleteSeller(seller.uID).then(res => {
            this.getSellers();
            this.props.hideLoading();
        }).catch(err => {
            this.closePopupDeleteSeller();
            this.props.hideLoading();
        })
    }

    render() {
        const {handleSubmit, pristine, reset, submitting, invalid, formErrors, formValues, metaForm} = this.props;
        const { sellers,timeServer } = this.state;
        const pp_limit_user = this.props.auth && this.props.auth.storePlan  && this.props.auth.storePlan.price_package  && this.props.auth.storePlan.price_package.pp_limit_user
            ? this.props.auth.storePlan.price_package.pp_limit_user : 0;
        return (
            <Fragment>
                <section className="wrapper_coupon">
                    <div className="container">
                        <div className="seller_manager seller_custom">
                            <div className="box_header pt-0">
                                <div className="d-flex flex-column">
                                </div>
                                <button className="btn btn-submit px-4" data-toggle="modal" data-target="#form_seller" onClick={() => this.showFormCreateSeller()}>
                                    판매자 등록
                                </button>
                            </div>
                            <div className="table-seller-custom">
                                <table className="table table-borderless table_striped">
                                    <thead className="thead_bg">
                                    <th width="25%">No</th>
                                    {/*<th className="text-center">이름</th>*/}
                                    <th  width="40%">성명</th>
                                    {/*<th>등록날짜</th>*/}
                                    {/*<th>상태</th>*/}
                                    <th   width="35%" className="text-right">관리</th>
                                    </thead>
                                    <tbody>

                                        { this.renderSellers(sellers)}
                                    </tbody>
                                </table>
                            </div>
                            {sellers && this.onPagination(sellers)}
                        </div>
                    </div>

                    {/*modal create seller*/}
                    <div className="modal fade" tabIndex="-1"
                         role="dialog" id="form_seller"
                         aria-labelledby="form_seller"
                         // data-backdrop="static"
                         data-keyboard="false"
                         aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modall-add-seller" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-0">
                                    <div className="header_modal">
                                        <h5 className="modal-title font-18">{ formValues?.id ? "판매자 정보 수정" : "판매자 추가"}</h5>
                                    </div>
                                    <button type="button" className="close btn"
                                            data-dismiss="modal"
                                            aria-label="Close">
                                  <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                    </button>
                                </div>
                                <div className="modal-body px-0">
                                    <form onSubmit={handleSubmit(this.handleCreateSeller)}>
                                        {/*<div className="form-group">*/}
                                            {/*<label className="label-text">이름</label>*/}
                                            {/*<Field*/}
                                                {/*id="username"*/}
                                                {/*label="username"*/}
                                                {/*name="username"*/}
                                                {/*placeholder="이름"*/}
                                                {/*className={"form-control h-44"}*/}
                                                {/*component={renderTextField}/>*/}
                                        {/*</div>*/}
                                        <div className="form-row">
                                            <div className="col-md-12 mb-3">
                                                <label className="label-text">성명</label>
                                                <Field
                                                    id="name"
                                                    label="name"
                                                    name="name"
                                                    placeholder="성명"
                                                    className={"form-control h-44"}
                                                    component={renderTextField}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center border-top-0">
                                            <button className="btn btn-gray btn_close_create"
                                                    data-dismiss="modal">취소</button>
                                            <button className="btn btn-submit"
                                                // disabled={ submitting || pristine }
                                                    type="submit">저장</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade`}
                         id={"delete_seller"} tabIndex="-1"
                         role="dialog"
                         aria-labelledby="delete_seller"
                        // data-backdrop="static"
                         data-keyboard="false"
                          aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-reset-product" role="document">
                            <div className="modal-content">
                                <div className="modal-body text-center px-2">
                                    <span className="notification_delete"> <strong>{ this.state.modalDelete.seller && this.state.modalDelete.seller.user && this.state.modalDelete.seller.user.name ? this.state.modalDelete.seller.user.name : "" }</strong> 판매자를 삭제하시겠습니까?</span>
                                </div>
                                <div className="modal-footer justify-content-center border-top-0">
                                    <button type="button" data-dismiss="modal" className="btn btn_close">취소</button>
                                    <button type="button" className="btn btn-submit" onClick={() => this.deleteSeller(this.state.modalDelete.seller)}>확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('create-seller')(state),
        formErrors: getFormSyncErrors ('create-seller')(state),
        metaForm: getFormMeta ('create-seller')(state),
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
    };
};
const initialValues = {
    'name': '',
    // 'username': '',
}

const withReduxForm = reduxForm({
    form: "create-seller",
    initialValues: initialValues,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ManagerSales);

