import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import api from '../../utils/api';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import React, { Component, Fragment } from 'react';
import * as routerName from '../../utils/routerName';
import {permissionsMenu} from '../../utils/helper';
import * as helper from '../../utils/helper';
import {showLoading, hideLoading} from '../../store/actions/ui';
import { getGlobalDataAuth, updateDataDocuments } from '../../store/actions/dataStore';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import MenuDocuments from './Menu/MenuDocuments';

let clickHoldTimer = null;
class SalesDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSearch: {
                page: 1,
                per_page: 10,
                cat_id: '',
                cat_child_id: ''
            },
            popupPreview:false,
            itemPreview:{},
            cat_parents:[],
            documents:{
                data: []
            },
            cat_childs:[]
        }
        
    }

    componentDidMount() {
        if(this.props.location?.state?.callback) {
            this.setState({
                ...this.state,
                ...this.props.location.state.callback
            })
        } else {
            api.notification.getDocumentCats({}).then(res => {
                this.setState({
                    dataSearch: {
                        page: 1,
                        per_page: 10,
                        cat_id: res.data.data.cat_parents[0]?.cat_id,
                        cat_child_id:res.data.data.cat_parents[0]?.cat_childs?.[0]?.cat_id
                    },
                    cat_parents:res.data.data.cat_parents,
                    cat_childs:res.data.data.cat_parents[0]?.cat_childs
                }, () => {
                    this.loaddingData();
                })
            }).then(err => {
                console.log(err)
            })
        }
    }
    

    loaddingData = () => {
        this.props.showLoading();
        let dataSearch = {
            ...this.state.dataSearch,
        }
        
        api.notification.getDocuments(dataSearch).then(res => {
            // this.props.updateDataNotices(res.data.data)
            this.setState({
                documents:res.data.data.documents
            })
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    onClickChangeTab = (id) => {
        let key = this.state.cat_parents.findIndex((item) => item.cat_id == id)
        if(key !== -1 ){
            this.setState({
                dataSearch:{
                    ...this.state.dataSearch,
                    cat_id:id,
                    cat_child_id:this.state.cat_parents[key]?.cat_childs?.[0]?.cat_id || null
                },
                cat_childs:this.state.cat_parents[key]?.cat_childs
            }, () => {
                this.onPaginateRequest()
            })
        }
    }


    onClickChangeTabChild = (id) => {
        this.setState({
            dataSearch:{
                ...this.state.dataSearch,
                cat_child_id:id
            }
        }, () => {
            this.onPaginateRequest()
        })
    }

    onPaginateRequest = () => {
        this.props.showLoading();
        let dataSearch = {
            ...this.state.dataSearch,
            cat_id: this.state.dataSearch.cat_id,
        }
        
        api.notification.getDocuments(dataSearch).then(res => {
            this.setState({
                // cat_childs:res.data.data.cat_childs,
                documents:res.data.data.documents
            })
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    onPagination = (data) => {
        var arrayNumber = [];
        for (let i = 1; i <= Math.ceil(data.total / data.per_page); i++) {

            if (data.current_page == i) {
                if ([1, 2].indexOf(data.current_page) == -1) {
                    if (i !== 1) {
                        arrayNumber.push(<li className="page-item" key={1}><a className="page-link" onClick={() => this.onClickPaginationHandle(1)}>1</a></li>);

                    } else {
                        arrayNumber.push(<li className="page-item" key={i - 2}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 2)}>{i - 2}</a></li>);
                    }
                }

                if (i - 2 > 1) {
                    arrayNumber.push(<li className="page-item" key={'...'}><a className="page-link">...</a></li>);
                }

                if ([1].indexOf(data.current_page) == -1) {
                    arrayNumber.push(<li className="page-item" key={i - 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 1)}>{i - 1}</a></li>);
                }

                arrayNumber.push(<li className="page-item active" key={i}><a className="page-link" onClick={() => this.onClickPaginationHandle(i)}>{i}</a></li>);

                if ((i + 1) < Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={i + 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i + 1)}>{i + 1}</a></li>);
                }

                if ([Math.ceil(data.total / data.per_page) - 1, Math.ceil(data.total / data.per_page) - 2].indexOf(i) == -1 && i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={'....'}><a className="page-link">...</a></li>);
                }


                if (i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={Math.ceil(data.total / data.per_page)}><a className="page-link" onClick={() => this.onClickPaginationHandle(Math.ceil(data.total / data.per_page))}>{Math.ceil(data.total / data.per_page)}</a></li>);
                }
            } else {
                //arrayNumber.push(<li className="page-item"><a className="page-link" >{i}</a></li>);
            }
        }
        if (Math.ceil(data.total / data.per_page) > 1) {
            return (
                <nav aria-label="Page navigation example" className="pagination-notifi">
                    <ul className="pagination">
                        <li className={`page-item item-previous ${this.state.dataSearch.page === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" aria-label="Previous" onClick={() => this.prevPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images/Left.svg" alt="" />
                                </span>
                            </a>
                        </li>
                        {arrayNumber.map((item, i) =>
                            item
                        )}
                        <li className={`page-item item-next ${this.state.dataSearch.page === this.state.documents.last_page ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" aria-label="Next" onClick={() => this.nextPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images//Right.svg" alt="" />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>

            )
        }

    }

    prevPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page - 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    nextPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page + 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    onClickPaginationHandle = (number) => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: number
            }
        }, () => {
            this.onPaginateRequest()
        })
    }

    comfirmDel = (id) => {
        let xhtml = (
            <Fragment>
                <div className="content-register-modal">
                    <h4 className="text-center">
                        <span className="notification_delete"> 삭제하시겠습니까?</span>
                    </h4>
                    <div className="form-row">
                        <div className="w-100 text-center">
                            <button className="btn btn_close mr-2" onClick={() => this.props.hideModal()}>취소</button>
                            <button className="btn btn_save  ml-2" onClick={() => this.handleDel(id)}>확인</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }

    handleDel = (id) => {
        this.props.showLoading();
        api.notification.delDocument(id).then(res => {
            this.props.hideLoading()
            this.props.hideModal();
            this.loaddingData();
            this.props.getGlobalDataAuth();
        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    popupShowImage = (item) => {
        let xhtml = (
            <Fragment>
                <div className="content pl-5 pr-5">
                    <form className="modal-notification">
                        <div className="img-form">
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/noti-img.png"} alt=""/>
                        </div>
                        <div className="group-check">
                            {item.d_setting_type_1 == '1'? (
                                <div className="form-group form-check">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="stateSettingType1"  onChange={() => {}}/>
                                        <label className="custom-control-label" htmlFor="stateSettingType1">하루동안 보지않기</label>
                                    </div>
                                </div>
                            ) : ('')}
                            {item.d_setting_type_2 == '1'? (
                            <div className="form-group form-check ml-5">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="stateSettingType2"  onChange={() => {}}/>
                                    <label className="custom-control-label" htmlFor="stateSettingType2">다시보지않기</label>
                                </div>
                            </div>
                            ) : ('')}
                        </div>
                    </form>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: "modal-dialog-scrollable modal-lg modal-dialog-centered",
            close: true
        }

        this.props.showModal(dataModal);
    }

 
    
    onClickRedrictDetail = (item) => {
        let callback = {
            routerName:routerName.SALES_DOCUMENT,
            ...this.state
        }
        this.props.history.push({
            pathname:   routerName.SALES_DOCUMENT_DETAIL,
            state: { document: item, callback:callback }
        })
    }

    onClickRedrictCreate = () => {
        let callback = {
            routerName:routerName.SALES_DOCUMENT,
            ...this.state
        }
        this.props.history.push({
            pathname:   routerName.SALES_DOCUMENT_CREATE,
            state: { callback:callback }
        })
    }

    onClickRedrictUpdate = (item) => {
        let callback = {
            routerName:routerName.SALES_DOCUMENT,
            ...this.state
        }
        this.props.history.push({
            pathname:   routerName.SALES_DOCUMENT_CREATE,
            state: { callback:callback, document: item }
        })
    }


    render() {
        return (
            <Fragment>
                <div className="main-wrapper">
                    <section className={"sales-guide "  + (this.props.auth.role == 'Admin' ? 'sales-admin' : '') }>
                        <div className="container">
                            {this.state.cat_parents.length > 4 ? (
                                <div className="listmenu-slide">
                                    <MenuDocuments
                                        menus={this.state.cat_parents}
                                        onClickChangeTab={this.onClickChangeTab}
                                        cat_id={this.state.dataSearch.cat_id}
                                    />
                                    <div className={"button-add-notifi " + permissionsMenu(this.props.auth.role, ['Admin'] )}>
                                        <a className="btn btn-submit px-4" onClick={() => this.onClickRedrictCreate()}>
                                            도우미 자료 작성
                                        </a>
                                    </div>
                                </div>
                                
                            ) : (
                                <ul className={"nav nav-tabs list-tabs "}>
                                    {this.state.cat_parents.map((item, i) =>
                                        <li className="nav-item" key={i} onClick={() => this.onClickChangeTab(item.cat_id)}>
                                            <a className={`nav-link ` + (item.cat_id == this.state.dataSearch.cat_id ? 'active' : (i == 0 && this.state.dataSearch.cat_id == '') ? 'active' : '') } >{item.cat_name}</a>
                                        </li>
                                        
                                    )}
                                    <li className={"button-add-notifi " + permissionsMenu(this.props.auth.role, ['Admin'] )}>
                                        <a href="#" className="btn btn-submit px-4" onClick={() => this.onClickRedrictCreate()}>
                                            도우미 자료 작성
                                        </a>
                                    </li>
                                </ul>
                            )}
                            
                            
                            <ul className="list-category">
                                {this.state.cat_childs.map((item, i) =>
                                    <li key={i} onClick={() => this.onClickChangeTabChild(item.cat_id)}  className={item.cat_id == this.state.dataSearch.cat_child_id ? 'active' : (i == 0 && this.state.dataSearch.cat_child_id == '') ? 'active' : ''}>
                                        {/* className="active" */}
                                        <span>{item.cat_name}</span>
                                    </li>
                                )}
                            </ul>
                            <div className="tab-content" >
                                <div className="tab-pane fade show active" >
                                <article className="content-notification">
                                    {this.state.documents.data.map((item, i) => 
                                        <div className="item-notification" 
                                            key={i} 
                                        >
                                            <div className="left" onClick={() =>this.onClickRedrictDetail(item) }>
                                                <h2  >
                                                    {item.d_title}
                                                    {item.d_state == 0 ? (<span className="color-orange">(Draft)</span>) : ('')}
                                                </h2>
                                                <div className="date-like">
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "tsc_template/dist/images/time.svg"} alt="" />
                                                        <span>
                                                            <Moment format="YYYY.MM.DD">
                                                                {item.created_at}
                                                            </Moment>
                                                        </span>
                                                    </div>
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "tsc_template/dist/images/View.svg"} alt="" />
                                                        <span>{item.d_viewer ? item.d_viewer : 0}</span>
                                                    </div>
                                                    <div className="item">
                                                        <img src={process.env.REACT_APP_HOSTNAME + "tsc_template/dist/images/like.svg"} alt="" />
                                                        <span>{item.count_like ? item.count_like : 0}</span>
                                                    </div>
                                                </div>
                                                <div className={`pop-detail ${(this.state.popupPreview == true && item.d_id == this.state.itemPreview.d_id) ? "d-block" : "d-none"}`}>
                                                    <div className="modal-content content px-2 popup-home">
                                                        <div className="modal-body discount-card pl-0 pr-0">
                                                        <div className="container_nofitication">
                                                        <div className="box_title">
                                                            <h1>{this.state.itemPreview &&this.state.itemPreview.d_title}</h1>
                                                            <div className="d-flex flex-row justify-content-between">
                                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                                    <div className="sub mr-4">
                                                                        <i className="far fa-clock"></i> 
                                                                        <span>
                                                                            <Moment format="YYYY.MM.DD">
                                                                                {this.state.itemPreview &&this.state.itemPreview.created_at}
                                                                            </Moment>
                                                                        </span>
                                                                    </div>
                                                                    <div className="sub mr-4">
                                                                        <i className="far fa-eye"></i> <span>{this.state.itemPreview &&this.state.itemPreview.d_viewer ? this.state.itemPreview.d_viewer : 0}</span>
                                                                    </div>
                                                                    <div className="sub"  style={{cursor:'pointer'}}>
                                                                        <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{this.state.itemPreview &&this.state.itemPreview.count_like ?this.state.itemPreview.count_like : 0}</span>
                                                                    </div>
                                                                </div>
                                                                {this.state.itemPreview && !!this.state.itemPreview.att_file ? (<div className="sub d-flex">
                                                                <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({this.state.itemPreview && (this.state.itemPreview.att_size / (1024) ).toFixed(2)} KB)</span></p>
                                                                    <a ><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"}  alt=""/></a>
                                                                </div>) : ('')}
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="box_content ck-content" dangerouslySetInnerHTML={{__html:helper.convertIframe(this.state.itemPreview &&this.state.itemPreview.d_content)}} >
                                                        
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div className={"right " }>
                                                <button className={"btn p-0 " + permissionsMenu(this.props.auth.role, ['Admin'] )} onClick={(e) => {e.stopPropagation();this.onClickRedrictUpdate(item) }}>
                                                    <i className="far fa-edit"></i>
                                                </button>
                                                <button className={"btn p-0 ml-2 " + permissionsMenu(this.props.auth.role, ['Admin'] )} onClick={(e) => {e.stopPropagation();this.comfirmDel(item.d_id)}}>
                                                    <i className="far fa-trash-alt"></i>
                                                </button>

                                                {!!item.att_file ? (<button className={"btn p-0 ml-2 " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'] )}>
                                                    <img src={process.env.REACT_APP_HOSTNAME + "/images/Paperclip.svg"} alt=""/>
                                                </button>) : ('')}
                                            </div>
                                        </div>
                                    )}
                                        
                                    </article>
                                    {this.onPagination(this.state.documents)}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getGlobalDataAuth: () => dispatch(getGlobalDataAuth()),
        updateDataDocuments: (data) => dispatch(updateDataDocuments(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    };
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(SalesDocument);

