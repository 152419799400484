import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import queryString from 'query-string'
import api from './../../utils/api'
import { withRouter } from "react-router";
import {
  getAdviceWireless
} from './../../store/actions/dataStore';
import {showLoading, hideLoading} from './../../store/actions/ui';
import ModalSelectTelcom from './../../components/Compare/ModalSelectTelcom'
import ModalSelectDevice from './../../components/Compare/ModalSelectDevice'
import ModalSelectInstallmentMonth from './../../components/Compare/ModalSelectInstallmentMonth'
import ModalOther from './../../components/Compare/ModalOther'
import ModalCashPayment from './../../components/Compare/ModalCashPayment'
import ModalPlanInformation from './../../components/Compare/PlanInformationModal'
import { priceFormat, caclWirelessStep3, roundUpNumber, priceFormatDiff, optionalAgreementCT, getOptionalAgreementHardcording } from '../../utils/helper';
import { CONSULTANT_WIRELESS, OPTION_FEE, COMPARE_TYPE } from './../../utils/constant'
import * as routeName from '../../utils/routerName';
import validate from "../Advice/WirelessAdviceComponent/validate-step-2";
import SwiperSlideComponent from "./SwiperSlideComponent";
import { getDisclosureSubsidy } from './../../store/actions/plan_information';

class CompareConsultant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      devices: [],
      params: {
        calc: true,
        calc_inst_month: 24,
        calc_subsidy_type: 79,
        distinct: true,
        manufactor: '',
        price_type: '',
        published: true,
        relationship: ['colors', 'device|deviceSpecialFeature', 'telcom'],
        sort: ['plan_sort|asc'],
        standard_type: '',
      },
      deviceSelectedIndex: 0,
      isOpenModalSelectTelcom: false,
      isOpenModalSelectDevice: false,
      isOpenModalSelectDeviace: false,
      isOpenModalSelectInstallmentMonth: false,
      isOpenModalOther: false,
      isOpenModalCashPayment: false,
      isOpenModalPlanInformation: false,
      urlParams : {}
    }
  }

  fnIsNull = (obj) => {
    var result = false;
    if(typeof obj == "undefined" || obj == null || obj == "" || obj == "null") {
      result = true;
    }
    
    return result;
  }

  fnNullCheck = (val, initVal) => {
    var result = null;
    var isNull = this.fnIsNull(val);
    
    if(isNull){
      result = initVal;
    }
    else{
      result = val;
    }
    
    return result;
  }

  fnStringOrArray = (val, index, initVal) => {
    var result = null;
    var isNull = this.fnIsNull(val);
    
    if(isNull){
      result = initVal;
    }
    else if(typeof val == 'string'){
      result = val;
    }
    else{
      result = val[index];
    }

    return result;
  }

  componentDidMount() {
    this.props.showLoading()
    const { history } = this.props
    let searchObj = queryString.parse(history.location.search)
    if (searchObj.model && searchObj.type == COMPARE_TYPE.CONSULTANT) {
      let models         = searchObj.model;
      let searchObjColor = searchObj.color;
      let request        = [];

      if (typeof models === 'string') {
        let params = this.state.params
        if (searchObj.packageFee) {
          params.plan = searchObj.packageFee
          // params.model = searchObj.model[index]
        }
        request.push(api.advice.getModel(models, params))
      } else {
        models.map((id, index) => {
          let params = this.state.params
          if (searchObj.packageFee[index]) {
            params.plan = searchObj.packageFee[index]
            // params.model = searchObj.model[index]
          }
          request.push(api.advice.getModel(id, params))
        })
      }

      Promise.all(request)
        .then(response => {
          let devices = response.map((res, index) => res.data.data && ({
            telcom: res.data.data.telcom,
            info: res.data.data,
            consultant: {
              ...CONSULTANT_WIRELESS,
              optionFee        : this.fnStringOrArray(searchObj.option,           index, OPTION_FEE.optionalAgreement),
              installmentMonth : this.fnStringOrArray(searchObj.installmentMonth, index, 24),
              other            : this.fnStringOrArray(searchObj.other,            index, 0),
              cashPayment      : this.fnStringOrArray(searchObj.cashPayment,      index, 0),
              cashPaymentId    : this.fnStringOrArray(searchObj.cashPaymentId,    index, null)
            }
          })).filter(e => e != null).map((item => this.initConsultant(item)));

          // this.clickDevice(devices,0)
          let models            = devices.map(e => e.info.id);
          let optionFees        = devices.map(e => e.consultant.optionFee);
          let installmentMonths = devices.map(e => e.consultant.installmentMonth);
          let others            = devices.map(e => e.consultant.other);
          let cashPayment       = devices.map(e => e.consultant.cashPayment);
          let cashPaymentId     = devices.map(e => e.consultant.cashPaymentId);
          let packageFee        = devices.map(e => e.consultant.packageFee?.id);
          let color             = devices.map(e => this.fnNullCheck(searchObjColor, e.info.colors[0].colorID));

          let params = {
            type: COMPARE_TYPE.CONSULTANT,
            model: models,
            option: optionFees,
            installmentMonth: installmentMonths,
            other: others,
            cashPayment: cashPayment,
            cashPaymentId: cashPaymentId,
            packageFee: packageFee,
            colors:color
          }

          this.setState({
            devices : devices,
            urlParams : params
          });

          params = queryString.stringify(params); 
          
          this.props.getParams(params);
        })
    }
    this.props.getAdviceWireless()
    this.props.hideLoading()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isReset === false && this.props.isReset === true) {
      this.setState({
        devices: []
      })
    }
    
    if (this.state.devices.length > 0 && !this.props.hasData) {
      this.props.setHasData(true)
    }
    //if (this.state.devices.length == 0 && this.props.hasData) {
    //  this.props.setHasData(false)
    //}
    if (this.state.devices.length == 0) {
      if(this.props.hasData){
        this.props.setHasData(false);
      }
      else{
        this.componentDidMount();
      }
    }
  }

  initConsultant = (device) => {
    const {plan} = device.info;
    let commitmentRate ;
    if (+plan?.spec_cmm == 1) {
      commitmentRate = +plan?.spec_cmm_value/100;
    } else {
      commitmentRate = +device.telcom?.commitment_rate/100;
    }
    let discostChange = 24;
    let telcomeID = device.telcom.id + '';
    let packageFee = {
        // ...this.state.consultantActive,
        // ...this.state.consultantActive.plan,
        id: plan?.id,
        classID: plan?.classID,
        name: plan?.name,
        cost_display: plan?.cost_display,
        class: plan?.class,
        discost_ad_cond: plan?.discost_ad_cond,
        spec_cmm: plan?.spec_cmm,
        spec_cmm_value: plan?.spec_cmm_value,
        discost_12: plan?.discost_12,
        discost_24: plan?.discost_24,
        discount_full: plan?.discount_full,
        plan_classification : {
          class: plan?.class
        }
    }
    let dataOption = optionalAgreementCT(+plan?.cost_display, +plan?.cost_calc, 1, packageFee, commitmentRate, discostChange, telcomeID);
    let consultant = {
      ...device.consultant,
      phone: {
        ...device.info,
        model_name: device.info.name,
        commitment_rate: device.telcom.commitment_rate,
      },
      startingPrice: device.info.factory_price,
      packageFee: packageFee,
      basicCost: Math.floor(+plan?.cost_display+(plan?.cost_display*10/100)),
      disclosureSubsidy: device.info.device_model_price?.disclosure_price || 0,
      disclosureSubsidySixPlan: +(device.info.device_model_price?.disclosure_price_six_plan || 0),
      disclosureSubsidyBasicPlan: +(device.info.device_model_price?.disclosure_price_basic_plan || 0),
      conversion_price: +(device.info.device_model_price?.conversion_price || 0),
      conversion_apply_date: device.info.device_model_price?.conversion_apply_date,
      manual_conversion_confirm: +(device.info.device_model_price?.manual_conversion_confirm || 0),
      conversion_price_input : 0,
      conversion_price_input_add : 0,
      disclosureSubsidyType : "six",
      optionalAgreement: plan?.id== 3688 ? 20000 : dataOption.optionalAgreement,
      interestRate: device.info.telcom.installment_rate,
      conversion2G: null,
      optionalAgreementWith2G: null,
      disclosureSubsidyDefault: null,
      disclosureSubsidyDifference: null,
      disclosureSubsidyDifferenceDefault: null,
      promotionDuration: null,
      promotionDurationDiscountOne: null,
      promotionDurationDiscountTwo: null,
      welfareDiscount: null,
      insurrance: null,
      insurranceCancelDate: null,
      optionalAgreementDefault: device.info.spec_cmm == 1
        ? +(+plan?.spec_cmm_value * +plan?.cost_display/100)+(+plan?.spec_cmm_value * +plan?.cost_display/100)*10/100
        : +(+device.telcom.commitment_rate * +plan?.cost_display/100)+(+device.telcom.commitment_rate * +plan?.cost_display/100)*10/100,

      discost12: plan?.discost_12 + plan?.discost_12*0.1,
      discost24: plan?.discost_24 + plan?.discost_24*0.1,
      discostChange: discostChange,
      displayApplyDate: device.info.price_apply_date,
      rateAgreement: +plan?.discost_24 + plan?.discost_24*10/100,
    }

    //console.log("caclWirelessStep3", caclWirelessStep3(device.telcom.installment_payment_method, consultant, device.telcom.recipe_days_remaining));

    return {
      ...device,
      consultant: caclWirelessStep3(device.telcom.installment_payment_method, consultant, device.telcom.recipe_days_remaining)
    }
  }

  clickOpenModalPlanInformation = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalPlanInformation: true,
    })
  }

  closeModalPlanInformation = () => {
    this.setState({
      isOpenModalPlanInformation: false,
    })
  }

  getPlanCustomResultPackage = (data) => {
    let { deviceSelectedIndex, devices } = this.state

    // let optionalAgreement = 0;
    // if (data.planId.spec_cmm == 1) {
    //   optionalAgreement = +(data.planId.spec_cmm_value * data.costDistplay/100)
    // }else{
    //   optionalAgreement = +(devices[deviceSelectedIndex].consultant.phone.commitment_rate * data.costDistplay/100)
    // }
    let discost12 = +data.planId.discost_12 + data.planId.discost_12*10/100;
    let discost24 = +data.planId.discost_24 + data.planId.discost_24*10/100;
    // let rateAgreement = 0
    // if (devices[deviceSelectedIndex].consultant.discostChange == 12) {
    //   rateAgreement = discost12;
    // }else{
    //   rateAgreement = discost24;
    // }
    let costCalc = data.planId.cost_calc ? data.planId.cost_calc : 0;
    let commitmentRate ;
    if (data.planId.spec_cmm == 1) {
      commitmentRate = +data.planId.spec_cmm_value/100;
    } else {
      commitmentRate = devices[deviceSelectedIndex].consultant.phone.telcom.commitment_rate/100;
    }
    let discostChange = devices[deviceSelectedIndex].consultant.discostChange;
    let telcomID = devices[deviceSelectedIndex].consultant.phone.telcomID;
    let dataOption = optionalAgreementCT(data.costDistplay/1.1, costCalc/1.1, 999, data.planId, commitmentRate, discostChange,telcomID);

    let disclosureSubsidyData = {
      'planID' : data.planId?.id ? data.planId.id : null,
      'modelID' : devices[deviceSelectedIndex].info.id || null,
    }
    this.props.getDisclosureSubsidy(disclosureSubsidyData).then(res => {
      let device = {
        ...devices[deviceSelectedIndex],
        consultant: {
          ...devices[deviceSelectedIndex].consultant,
          packageFee: data.planId,
          basicCost: data.costDistplay,
          planData: data.planId,
          discost12: discost12,
          discost24: discost24,
          rateAgreement: dataOption.rateAgreement,
          optionalAgreement: data.planId?.id == 3688 ? 20000 : dataOption.optionalAgreement,
          disclosureSubsidy: res.data?.disclosure_price || 0,
          displayApplyDate: res.data?.apply_date || '',
        }
      }
      device = {
        ...device,
        consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
      }
      devices[deviceSelectedIndex] = device
      this.setState({
        devices
      }, () => {
        this.updateUrl()
      })
    })
  }

  getPlanResultPackage = (data) => {
    let { deviceSelectedIndex, devices } = this.state

    // let optionalAgreement = 0;
    //CT Old
    // if (data.spec_cmm == 1) {
    //   optionalAgreement = +(data.spec_cmm_value * (+data.cost_display+data.cost_display*10/100)/100)
    // }else{
    //   optionalAgreement = +(devices[deviceSelectedIndex].consultant.phone.commitment_rate * (+data.cost_display+data.cost_display*10/100)/100)
    // }
    let discost12 = +data.discost_12 + data.discost_12*10/100;
    let discost24 = +data.discost_24 + data.discost_24*10/100;

    // let rateAgreement = 0
    // if (devices[deviceSelectedIndex].consultant.discostChange == 12) {
    //   rateAgreement = discost12;
    // }else{
    //   rateAgreement = discost24;
    // }
    //CT Mới
    let commitmentRate ;
    if (data.spec_cmm == 1) {
      commitmentRate = +data.spec_cmm_value/100;
    } else {
      commitmentRate = devices[deviceSelectedIndex].consultant.phone.telcom.commitment_rate/100;
    }
    let discostChange = devices[deviceSelectedIndex].consultant.discostChange;
    let telcomID = devices[deviceSelectedIndex].consultant.phone.telcomID;
    let dataOption = optionalAgreementCT((+data.cost_display+data.cost_display*10/100)/1.1, (+data.cost_calc+data.cost_calc*10/100)/1.1, 1, data, commitmentRate, discostChange, telcomID);

    let disclosureSubsidyData = {
      'planID' : data.id ? data.id : null,
      'modelID' : devices[deviceSelectedIndex].info.id || null,
    }
    this.props.getDisclosureSubsidy(disclosureSubsidyData).then(res => {
      let device = {
        ...devices[deviceSelectedIndex],
        consultant: {
          ...devices[deviceSelectedIndex].consultant,
          packageFee: data,
          basicCost: Math.floor(+data.cost_display+data.cost_display*10/100),
          discost12: discost12,
          discost24: discost24,
          rateAgreement: dataOption.rateAgreement,
          optionalAgreement: data.id == 3688 ? 20000 : dataOption.optionalAgreement,
          disclosureSubsidy: res.data?.disclosure_price || 0,
          displayApplyDate: res.data?.apply_date || ''
        }
      }
      device = {
        ...device,
        consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
      }
      devices[deviceSelectedIndex] = device
      this.setState({
        devices
      }, () => {
        this.updateUrl()
      })
    })
  }

  clickOpenModalCashPayment = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalCashPayment: true,
    })
  }

  closeModalCashPayment = () => {
    this.setState({
      isOpenModalCashPayment: false,
    })
  }

  onSelectedCashPayment = (cashPayment, cashPaymentId) => {
    let { deviceSelectedIndex, devices } = this.state
    let device = {
      ...devices[deviceSelectedIndex],
      consultant: {
        ...devices[deviceSelectedIndex].consultant,
        cashPayment,
        cashPaymentId,
      }
    }
    device = {
      ...device,
      consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
    }
    devices[deviceSelectedIndex] = device
    this.setState({
      devices
    }, () => {
      this.updateUrl()
    })
    this.closeModalCashPayment()
  }

  clickOpenModalOther = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalOther: true,
    })
  }

  closeModalOther = () => {
    this.setState({
      isOpenModalOther: false,
    })
  }

  onSelectedOther = (other) => {
    let { deviceSelectedIndex, devices } = this.state
    let device = {
      ...devices[deviceSelectedIndex],
      consultant: {
        ...devices[deviceSelectedIndex].consultant,
        other
      }
    }
    device = {
      ...device,
      consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
    }
    devices[deviceSelectedIndex] = device
    this.setState({
      devices
    }, () => {
      this.updateUrl()
    })
    this.closeModalOther()
  }

  clickChooseInstallmentMonth = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalSelectInstallmentMonth: true,
    })
  }

  closeModalSelecInstallmentMonth = () => {
    this.setState({
      isOpenModalSelectInstallmentMonth: false,
    })
  }

  onSelectedInstallmentMonth = (installmentMonth) => {
    let { deviceSelectedIndex, devices } = this.state
    let device = {
      ...devices[deviceSelectedIndex],
      consultant: {
        ...devices[deviceSelectedIndex].consultant,
        installmentMonth
      }
    }
    device = {
      ...device,
      consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
    }
    devices[deviceSelectedIndex] = device
    this.setState({
      devices
    }, () => {
      this.updateUrl()
    })

    // this.closeModalSelectelcom() 
    this.closeModalSelecInstallmentMonth()
  }

  selectOptionFee = (index, optionFee) => {
    let { devices } = this.state
    let device = {
      ...devices[index],
      consultant: {
        ...devices[index].consultant,
        optionFee
      }
    }
    device = {
      ...device,
      consultant: caclWirelessStep3(device.telcom.installment_payment_method, device.consultant, device.telcom.recipe_days_remaining)
    }
    devices[index] = device
    this.setState({
      devices
    }, () => {
      this.updateUrl()
    })
  }

  clickChooseTelcom = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalSelectTelcom: true,
    })
  }

  closeModalSelectelcom = () => {
    this.setState({
      isOpenModalSelectTelcom: false,
    })
  }

  onSelectedTelcom = (telcom) => {
    let { deviceSelectedIndex, devices } = this.state
    let device = {
      telcom,
      info: {},
      consultant: CONSULTANT_WIRELESS,
    }
    devices[deviceSelectedIndex] = device
    this.setState({
      devices
    })
    this.closeModalSelectelcom()
    this.updateUrl()
  }

  clickChooseDevice = (deviceSelectedIndex) => {
    const {devices} = this.state
    if (
      devices[deviceSelectedIndex]
      && devices[deviceSelectedIndex].telcom
      && Object.keys(devices[deviceSelectedIndex].telcom).length > 0
    ) {
      this.setState({
        deviceSelectedIndex,
        isOpenModalSelectDevice: true,
      })
    } else {
      alert('통신사를 선택해주세요')
    }
  }

  closeModalSelecDevice = () => {
    this.setState({
      isOpenModalSelectDevice: false,
    })
  }

  clickDevice = (info,deviceSelectedIndex) => {
      const {devices} = this.state

      if(info.length > 0) {
          if(info[0].info && Object.keys(info[0].info).length > 0) {

              // this.props.history.push({
              //     pathname: `/${routeName.COMPARE}`,
              //     search: '?type=2'+'&model='+info[0].info.modelID,
              //     state: {
              //         modelID:info[0].info.modelID
              //     }})
          }
      }else {
          if (
              info
              && Object.keys(info).length > 0
          ) {

              // this.props.history.push({
              //     pathname: `/${routeName.COMPARE}`,
              //     search: '?type=2'+'&model='+info.modelID,
              //     state: {
              //         modelID:info.modelID
              //     }})
          }
      }

  }

  onSelectedDevice = (info) => {
    let { deviceSelectedIndex, devices } = this.state
    this.clickDevice(info,deviceSelectedIndex)

    const { history } = this.props
    // let searchObj = queryString.parse(history.location.search)
    let params = this.state.params
    params.plan = info.planID
    // if (searchObj.packageFee[deviceSelectedIndex]) {
    //   params.plan = searchObj.packageFee[deviceSelectedIndex]
    //   // params.model = searchObj.model[index]
    // }
    this.closeModalSelecDevice()
    this.updateUrl()
    api.advice.getModel(info.modelID, params)
      .then((res) => {
        let device = {
          telcom: devices[deviceSelectedIndex].telcom,
          info: res.data.data,
          consultant: CONSULTANT_WIRELESS,
        }
        device = this.initConsultant(device)
        devices[deviceSelectedIndex] = device
        this.setState({
          devices
        }, () => {
          this.updateUrl()
        })
      })
  }

  updateUrl = () => {
    const {devices} = this.state
    let models = devices.map(e => e.info.id)
    let optionFees = devices.map(e => e.consultant.optionFee)
    let installmentMonths = devices.map(e => e.consultant.installmentMonth)
    let others = devices.map(e => e.consultant.other)
    let cashPayment = devices.map(e => e.consultant.cashPayment)
    let cashPaymentId = devices.map(e => e.consultant.cashPaymentId)
    let packageFee = devices.map(e => e.consultant.packageFee?.id);
    let color  = this.state.devices.map(e => {
      let index   = models.indexOf(e.info.id);
      let colorId = '';

      if(e.info.colors){
        if(index == -1){
          colorId = e.info.colors[0].colorID;
        }
        else if(this.state.urlParams.colors.length > index){
            colorId = this.state.urlParams.colors[index];
        }
        else{
          colorId = e.info.colors[0].colorID;
        }
      }
      
      return colorId;
    });
    let params = {
      type: COMPARE_TYPE.CONSULTANT,
      model: models,
      option: optionFees,
      installmentMonth: installmentMonths,
      other: others,
      cashPayment: cashPayment,
      cashPaymentId: cashPaymentId,
      packageFee: packageFee,
      colors:color
    }
    this.setState({
      urlParams: params
    });
    params = queryString.stringify(params)
    // this.props.history.replace({
    //   pathname: '/compare',
    //   search: '?' + params
    // })
    window.history.pushState('', '', '/compare?' + params)
    this.props.getParams(params)
  }

  getActiveColorIndex = (deviceIndex) => {
    let index             = 0;
    let colorId           = 0;
    let devices           = this.state.devices;
    let color             = this.state.urlParams.colors;
    let colorLength       = color.length;
    let colors            = [];
    let tempInt           = 0;
    let colorsLength      = 0;
    let colorsInfo        = 0;
    let colorsInfoColorId = 0;
    
    if(colorLength > deviceIndex){
      colorId      = this.state.urlParams.colors[deviceIndex];
      colors       = devices[deviceIndex].info.colors;
      colorsLength = colors.length;

      for(tempInt = 0; tempInt < colorsLength; tempInt++){
        colorsInfo        = colors[tempInt];
        colorsInfoColorId = colorsInfo.colorID;

        if(Number(colorId) == Number(colorsInfoColorId)){
          index = tempInt;

          break;
        }
      }
    }

    return index;
  }

  onClickSelectedColorActive = (colorInfo) => {
    let params = this.state.urlParams;
    let models = params.model;
    let color  = params.colors;
    let index  = models.indexOf(Number(colorInfo.modelID));

    color[index] = colorInfo.colorID;

    params.colors = color;

    this.setState({
      urlParams: params
    });
    
    params = queryString.stringify(params);

    window.history.pushState('', '', '/compare?' + params);

    this.props.getParams(params);
  }

  redirectWireLessAdvice = (index) => {
    let { devices, urlParams} = this.state;
    let telcomeID = devices[index].telcom.id
    let model = devices[index].info
    let telcom = devices[index].telcom
    let stateColor = "";
    
    if (!model || (model && Object.keys(model).length === 0)) {
      alert('단말기를 선택해주세요.')
      return;
    }

    if(model.colors){
      let colorIndex = 0;
      
      model.colors.map((info, i) => {
        if(info.colorID == urlParams.colors[index]){
          colorIndex = i;
        }
      });

      stateColor = model.colors[colorIndex];
    }

    this.props.history.push({
      pathname: `/${routeName.WIRELESS_ADVICE}`,
      state: {
        WirelessAdviceStep3: {
          ...devices[index].consultant,
          telcomeID,
          model: {
            ...model,
            model_name: model.name,
            commitment_rate: devices[index].telcom?.commitment_rate,
          },
          successString: telcom.image_url ? telcom.image_url.original : "",
          saler: this.props.auth.user,
          contractMonth: 24,
          disclosureSubsidyDefault: devices[index].consultant.disclosureSubsidy,
          displayApplyDate: devices[index].consultant.displayApplyDate ? (devices[index].consultant.displayApplyDate).split(" ")[0] : null,
          packageFee: {
            ...devices[index].consultant.packageFee,
            class: devices[index].consultant.phone.plan.class,
            discost_ad_cond: devices[index].consultant.phone.plan.discost_ad_cond,
            spec_cmm: devices[index].consultant.phone.plan.spec_cmm,
            spec_cmm_value: devices[index].consultant.phone.plan.spec_cmm_value,
            discost_12: devices[index].consultant.phone.plan.discost_12,
            discost_24: devices[index].consultant.phone.plan.discost_24,
            discount_full: devices[index].consultant.phone.plan.discount_full
          },
        },
        color: stateColor,
        referrer: '/compare' + window.location.search,
      }
    });
  }

  removeItem = (index) => {
    let { devices } = this.state;
    let params      = this.state.urlParams;
    let color       = params.colors;

    color.splice(index, 1);

    params.colors = color;
    devices[index] = null
    devices = devices.filter(e => e !== null)
    this.setState({
      devices   : devices,
      urlParams : params
    }, () => {
      this.updateUrl()
    })
  }

  render() {
    const { devices, deviceSelectedIndex } = this.state;
    const { telcomProviders } = this.props

    return (
      <Fragment>
        <table className="table table-striped remove-cursor">
          <tbody>
            <tr>
              <th className="text-center">
                통신사 선택
              </th>
              {[...Array(3).keys()].map(index => (
                <td key={index} className="cursor-pointer" onClick={() => this.clickChooseTelcom(index)}>
                  <div className="provider-choose-table">
                    {devices[index] ? (
                      <img src={devices[index].telcom?.image_url?.original} alt={devices[index].telcom?.code} />
                    ) : (
                      <h5 className="mb-0 label-prices">
                        통신사
                      </h5>
                    )}
                    <a href="#"></a>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <th className="py-0 bg-white">단말기</th>
              {[...Array(3).keys()].map(index => (
                (devices[index] && devices[index].info && Object.keys(devices[index].info).length > 0) ? (
                  <td className="py-0 bg-white" key={index}>
                    <h2 className=" name">
                      <a onClick={() => this.clickChooseDevice(index)}>
                        <span className="model-code">{devices[index].info.name}</span>
                        {devices[index].info.v_device.name}</a>
                      <a
                         className="btn reset-table" onClick={() => this.clickChooseDevice(index)}
                      >
                        <img src="/images/reset-table.svg" alt="" />
                      </a>
                    </h2>
                    <div className="slide-color-product">
                      <SwiperSlideComponent
                        devices={devices[index].info?.colors || []}
                        onClickSelectedColorActive={this.onClickSelectedColorActive}
                        indexActive={this.getActiveColorIndex(index)}
                      />
                  </div>
                  </td>
                ) : (
                  <td className="py-0 bg-white" key={index}>
                    <button className="btn gallary-table" onClick={() => this.clickChooseDevice(index)}>
                      <img src="/images/img-07.png" alt="" />
                    </button>
                  </td>
                )
              ))}
            </tr>
            <tr>
              <th>선택</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    <div className="group-select">
                      <ul className="check-table">
                        <li
                          className={
                            devices[index].consultant.optionFee == OPTION_FEE.disclosureSubsidy
                            ? "check-item active"
                            : 'check-item'
                          }
                          onClick={() => this.selectOptionFee(index, OPTION_FEE.disclosureSubsidy)}
                        >
                          공시
                        </li>
                        <li
                          className={
                            devices[index].consultant.optionFee == OPTION_FEE.optionalAgreement
                            ? "check-item active"
                            : 'check-item'
                          }
                          onClick={() => this.selectOptionFee(index, OPTION_FEE.optionalAgreement)}
                        >
                          선택
                        </li>
                      </ul>
                      <span
                        className="form-control"
                        onClick={() => this.clickOpenModalPlanInformation(index)}>
                        {devices[index].consultant.packageFee
                        ? (devices[index].consultant.packageFee.plac_name
                          ? devices[index].consultant.packageFee.plac_name
                          : devices[index].consultant.packageFee.name)
                        : 'T플랜 에센스'}
                      </span>
                      <span
                        className="form-control"
                        onClick={() => this.clickChooseInstallmentMonth(index)}>
                        {devices[index].consultant?.installmentMonth} 개월
                      </span>
                    </div>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>출고가</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    `${priceFormat(devices[index].consultant?.startingPrice)} 원`
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>요금제 기본료 </th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    <Fragment>
                      {priceFormat(roundUpNumber(devices[index].consultant?.basicCost))} 원
                      {' '}
                      <span className="text-blue">
                      ({devices[index].consultant.packageFee
                        ? devices[index].consultant.packageFee.plac_name
                          ? devices[index].consultant.packageFee.plac_name
                          : devices[index].consultant.packageFee.name
                        : 'T플랜 에센스'})
                      </span>
                    </Fragment>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>공시지원금</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    `${devices[index].consultant.optionFee == 1 ? priceFormat(devices[index].consultant?.disclosureSubsidy) : 0} 원`
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>선택약정할인</th> 
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    `${devices[index].consultant.optionFee == 0 ? priceFormat(getOptionalAgreementHardcording(+devices[index].consultant.packageFee?.id, devices[index].consultant?.optionalAgreement)): 0} 원`
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>기타</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    <button
                      className="btn btn-input-form mb-0 h-35"
                      type="button"
                      onClick={() => this.clickOpenModalOther(index)}
                    >
                      {priceFormat(devices[index].consultant?.other)} 원
                    </button>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>현금납부</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    <button
                      className="btn btn-input-form mb-0 h-35"
                      type="button"
                      onClick={() => this.clickOpenModalCashPayment(index)}
                    >
                      {priceFormat(devices[index].consultant?.cashPayment)} 원
                    </button>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>할부원금</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    `${priceFormat(devices[index].consultant?.installmentPrincipal)} 원`
                  )}
                </td>
              ))}
            </tr>
            <tr className="total">
              <th>월 납부액</th>
              {[...Array(3).keys()].map(index => (
                <td className="prices-pink" key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    `${priceFormat(devices[index].consultant?.amountMonthlyDevice)} 원`
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="group-table-button ">
          {[...Array(3).keys()].map(index => (
            devices[index] ? (
              <div className="item" key={index}>
                <button
                  className="btn btn-submit mx-1 px-3"
                  type="button"
                  onClick={() => this.redirectWireLessAdvice(index)}
                >
                  상세상담
                </button>
                <button
                  className="btn btn-gray mx-1 px-4"
                  type="button"
                  onClick={() => this.removeItem(index)}
                >
                  초기화
                </button>
              </div>
            ) : (
              <div className="item opaity-5" key={index}>
                <button className="btn btn-submit mx-1 px-3" type="button">
                  상세상담
                </button>
                <button className="btn btn-gray mx-1 px-4" type="button">
                  초기화
                </button>
              </div>
            )
          ))}
        </div>

        <ModalSelectTelcom
          isOpenModal={this.state.isOpenModalSelectTelcom}
          toggleModal={this.closeModalSelectelcom}
          telcomProviders={telcomProviders}
          onSelectedTelcom={this.onSelectedTelcom}
        />

        <ModalSelectDevice
          isOpenModal={this.state.isOpenModalSelectDevice}
          toggleModal={this.closeModalSelecDevice}
          onSelectedDevice={this.onSelectedDevice}
          telcomId={(this.state.devices[deviceSelectedIndex]?.telcom?.id || 0)}
          model={(this.state.devices[deviceSelectedIndex]?.info || {})}
          manufacturers={this.props.manufacturers}
          WirelessAdvice={this.props.WirelessAdvice}
        />

        <ModalSelectInstallmentMonth
          isOpenModal={this.state.isOpenModalSelectInstallmentMonth}
          toggle={this.closeModalSelecInstallmentMonth}
          onSelected={this.onSelectedInstallmentMonth}
          installmentMonth={+(this.state.devices[deviceSelectedIndex]?.consultant?.installmentMonth || 0)}
        />

        <ModalOther
          isOpenModal={this.state.isOpenModalOther}
          toggle={this.closeModalOther}
          onSelected={this.onSelectedOther}
          installmentPrincipal={this.state.devices[deviceSelectedIndex]?.consultant?.installmentPrincipal || 0}
          other={this.state.devices[deviceSelectedIndex]?.consultant?.other || 0}
        />

        <ModalCashPayment
          isOpenModal={this.state.isOpenModalCashPayment}
          closeModal={this.closeModalCashPayment}
          onSelected={this.onSelectedCashPayment}
          installmentPrincipal={this.state.devices[deviceSelectedIndex]?.consultant?.installmentPrincipal || 0}
          cashPayment={this.state.devices[deviceSelectedIndex]?.consultant?.cashPayment || 0}
        />

        <ModalPlanInformation
          toggleModal = {this.closeModalPlanInformation}
          isShowModal = {this.state.isOpenModalPlanInformation}
          planCustomResultPackage = {this.getPlanCustomResultPackage}
          planResultPackage = {this.getPlanResultPackage}
          telcomID={this.state.devices[deviceSelectedIndex]?.telcom?.id || 0}
          consultant={this.state.devices[deviceSelectedIndex]?.consultant || {}}
          plan={this.state.devices[deviceSelectedIndex]?.consultant?.packageFee || {}}
          matchID={this.state.devices[deviceSelectedIndex]?.info?.v_device?.matchID || null}
        />
      </Fragment>
    );
  }
}

CompareConsultant.propTypes = {
  history: PropTypes.object,
  telcomProviders: PropTypes.object,
  getAdviceWireless: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  isReset: PropTypes.bool,
  auth: PropTypes.object,
  hasData: PropTypes.bool,
  setHasData: PropTypes.func,
  getParams: PropTypes.func,
  getDisclosureSubsidy: PropTypes.func,
};


const FORM_NAME = 'WirelessAdvice';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
});

const mapStateToProps = state => {
  return {
    telcomProviders: state.dataStore.telcomProviders,
    auth: state.auth,
    manufacturers: state.dataStore.manufacturers,
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAdviceWireless: () => dispatch(getAdviceWireless()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    getDisclosureSubsidy: (data) => dispatch(getDisclosureSubsidy(data)),
  };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm
)(withRouter(CompareConsultant));
