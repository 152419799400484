import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import { priceFormat, roundUpNumber, optionalAgreementCT, numberDownFloor } from '../../../utils/helper';
import './../CombinedDiscount/ResultList.css';

class ResultListTable extends Component {
  sum = (accumulator, currentValue) => accumulator + currentValue;

  sumReduce = (accumulator, currentValue) => accumulator + roundUpNumber(currentValue);

  sumDiscount = (array) => {
    /*
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    // array.shift()
    let sum = array.map(e => +e.discount_wireless_price)
    sum = sum.reduce(this.sum)
    return sum;
*/
    let removeFirst = false;
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    if (removeFirst) {
      array.shift()
    }
    let sum = array.map(e => +e.discount_wireless_price)
    let sumdiscount_tv = array.map(e => +e.discount_tv)
    sum = [...sum, ...sumdiscount_tv]
    sum = sum.reduce(this.sumReduce)
    return sum;
  }

  sumPlanPrice = (array, telcomID) => {
    /*
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    // array.shift()
    let sum = array.map(e => +(e.plan?.cost_display || 0))
    sum = sum.reduce(this.sum)
    return sum;
    */
    let removeFirst = false;
    array = [...array]
    if (array.length <= 1) {
      return 0
    }
    if (removeFirst) {
      array.shift()
    }
    let sum;
    if (telcomID == 17){
      sum = array.map(e => 
        roundUpNumber(((+e?.plan?.cost_display - roundUpNumber(+e?.plan?.discost_24 || 0)) * 1.1) - ((e?.is_commitment && e?.plan) ? this.calcOptionalContractDiscount(e) : 0))
      )
    } {
      sum = array.map((e, key) => roundUpNumber(e.is_commitment ? (
        (key !== 0 && e?.plan?.discount_full == 1) ? (
          ((roundUpNumber((+(e.plan?.cost_display || 0) - roundUpNumber(+(e.plan?.discost_24 || 0))) * 1.1) - this.calcOptionalContractDiscount(e)) / (key == 0 ? 1 : 0.75)) * 0.75
        ) : (
          (key === 0 && e?.is_commitment) ? (
            roundUpNumber((+(e.plan?.cost_display || 0) - roundUpNumber(+(e.plan?.discost_24 || 0))) * 1.1) - this.calcOptionalContractDiscount(e)
          ) : (
            roundUpNumber((+(e.plan?.cost_display || 0) - roundUpNumber(+(e.plan?.discost_24 || 0))) * 1.1) * 0.75
          )
        )
      ) : (
        ((+(e.plan?.cost_display || 0) - +(e.plan?.discost_24 || 0)) * 1.1)
      )))
    }
    sum = sum.reduce(this.sumReduce)
    return sum;
  }

  calcOptionalContractDiscount = (combinePlan) => {
    const {data } = this.props;
    let cost_display = +combinePlan.plan?.cost_display + (+combinePlan.plan?.cost_display*0.1);
    let cost_calc = (+combinePlan.plan?.cost_calc + (+combinePlan.plan?.cost_calc*0.1)) || (combinePlan.plan?.planCustom ? cost_display : 0);
    let customPlan = combinePlan.plan?.planCustom ? 999 : 1;
    let dataPlan = combinePlan.plan;
    let commitmentRate = 0;
    if (combinePlan.plan?.spec_cmm == 1) {
      commitmentRate = +combinePlan.plan?.spec_cmm_value/100;
    } else {
      if (data?.phone?.commitment_rate) {
        commitmentRate = data.phone.commitment_rate/100;
      } else {
        commitmentRate = data.telcom.commitment_rate / 100
      }
    }
    let discostChange = data?.discostChange || 24;
    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, data?.phone?.telcomID);
    return +numberDownFloor(result?.optionalAgreement)
  }

  sumPromotion = (array, telcomID, promotion) => {
    let promotionResult          = 0;
    let sumPromotionResult       = 0;
    let cost                     = 0;
    let basic                    = 0;
    let optionalContractDiscount = 0;
    let plan_24                  = 0;

    array.map((plan, key) => {
      plan_24 = roundUpNumber(+plan?.plan?.discost_24 || 0);
      basic   = (+plan?.plan?.cost_display - plan_24) * 1.1;

      if (telcomID == 17) {// SKT
        if(plan?.is_commitment && plan?.plan){
          optionalContractDiscount = this.calcOptionalContractDiscount(plan);
        }
        else{
          optionalContractDiscount = 0;
        }

        cost = basic - optionalContractDiscount;
      }
      else if (plan?.plan?.discount_full == 1 && plan?.is_commitment) {
        cost = (basic - this.calcOptionalContractDiscount(plan));
      }
      else {
        if(plan?.is_commitment && plan?.plan){
          optionalContractDiscount = this.calcOptionalContractDiscount(plan);
        }
        else{
          optionalContractDiscount = 0;
        }

        cost = basic - optionalContractDiscount;
      }

      promotionResult = (roundUpNumber(roundUpNumber(cost) - roundUpNumber(+plan.discount_wireless_price) - +plan.discount_tv)) - (key === 0 ? promotion : 0);

      if(isNaN(promotionResult)){
        promotionResult = 0;
      }
      else if(promotionResult < 0){
        promotionResult = 0;
      }

      sumPromotionResult = sumPromotionResult + promotionResult;
    });

    return sumPromotionResult;
  }

  render() {
    const {data } = this.props;
    data.combinePlans = (data?.combinePlans || []).map((plan, key) => {
      if(plan?.is_commitment == "0"){
        plan.is_commitment = false;
      }

      return plan;
    });
    let sumDiscountResult = this.sumDiscount((data?.combinePlans || []));
    let sumPlanPriceResult = this.sumPlanPrice((data?.combinePlans || []), data?.phone?.telcomID);
    let sumPromotionResult = this.sumPromotion((data?.combinePlans || []), data?.phone?.telcomID, (data.promotion?.promo_cost ? +data.promotion?.promo_cost : 0));
    
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-insurrance border-0 text-gray">
            결합 후 무선상품 예상 할인액
          </h3>
          <div className="table-responsive table-left-wireless">
            <table className="table billing-fee">
              <thead className="thead-table-blue">
              <tr>
                <th className="text-left">결합회선</th>
                <th className="text-right">사용 요금제</th>
                <th className="text-right">월 이용료</th>
                <th className="text-right">유무선 결합할인</th>
                <th className="text-right">할인 후 이용료</th>
              </tr>
              </thead>
              <tbody>
              {/*(data?.combinePlans || []).map((plan, key) => (
                plan.plan && (
                  <tr key={key}>
                    <td><span className="font-weight-bold">회선 {plan.idx}</span></td>
                    <td className="text-right">
                      <p className="mb-2">{plan?.plan?.name}</p>
                    </td>
                    <td className="text-right">
                      <p className="mb-2">
                        {priceFormat(plan?.plan?.cost_display)}원
                      </p>
                    </td>
                    <td className="text-right">
                      <p className="mb-2 color-blue font-weight-bold">
                          <span className="prices-form">
                            -{priceFormat(plan.discount_wireless_price)}
                          </span>
                        <span>원</span>
                      </p>
                    </td>
                    <td className="text-right">
                      <p className="mb-2">
                          <span className="total-price">
                            {priceFormat(+plan?.plan?.cost_display - +plan.discount_wireless_price)}
                          </span>
                        <span className="price-sub">원</span></p>
                    </td>
                  </tr>
                )
                ))*/}
                {(data?.combinePlans || []).map((plan, key) => {
                  let removeFirst = false;
                  let promotion   = data.promotion?.promo_cost ? +data.promotion?.promo_cost : 0;

                  if (key === 0 && removeFirst) {
                    return null
                  }

                  let plan_24 = roundUpNumber(+plan?.plan?.discost_24 || 0);
                  let cost;
                  let basic = (+plan?.plan?.cost_display - plan_24) * 1.1 

                  if (!plan?.plan?.planCustom) {
                    basic = roundUpNumber(basic)
                  }

                  if (data?.phone?.telcomID == 17) {// SKT
                    let optionalContractDiscount = ((plan?.is_commitment && plan?.plan) ? this.calcOptionalContractDiscount(plan) : 0);
                    
                    cost = basic - optionalContractDiscount;

                  } else if (plan?.plan?.discount_full == 1 && plan?.is_commitment) {
                    cost = (basic - this.calcOptionalContractDiscount(plan))
                    // if (key != 0) {
                    //   cost = cost / 0.75
                    // }
                  } else { // conf laij
                    //cost = basic - ((plan?.is_commitment && plan?.plan) ? this.calcOptionalContractDiscount(plan) : 0)
                    let optionalContractDiscount = ((plan?.is_commitment && plan?.plan) ? this.calcOptionalContractDiscount(plan) : 0);
                  
                    cost = basic - optionalContractDiscount;
                  }

                  let combine_name = "";

                  // n?u co đ?t đi?u ki?n thi hi?n ten đi?u ki?n, khong thi hi?n ten m?u đi?u ki?n, khong hi?n ten cac option discount
                  if((plan.discount_condition_name || []).filter(e => e.type !== "OPTION").length > 0){
                    combine_name = ((plan.discount_condition_name || []).filter(e => e.type !== "OPTION") || []).map(e => e.name ).join('/') || data?.combineCondition.name
                  }else{
                    combine_name = data?.combineCondition.name
                  }

                  return (
                    plan.plan && (
                      <tr key={key} style={{cursor:'default'}}>
                        <td><span className="font-weight-bold">회선 {plan.idx}</span></td>
                        <td className="text-right">
                          <p className="mb-2">{plan?.plan?.name}</p>
                        </td>
                        <td className="text-right">
                          {(key === 0 && plan?.is_commitment) ? (
                            <div className="sale-prices">
                              <p>
                                <span>
                                  {priceFormat(roundUpNumber(cost - (key === 0 ? promotion : 0)))}
                                </span>
                                <span >원</span>
                              </p>
                              <p className="ont-12" style={{ whiteSpace: 'break-spaces' }}>
                              {
                                +plan?.is_commitment && plan_24
                                ? '약정할인(24개월)\n\n선택약정할인'
                                : (
                                  plan_24
                                  ? '약정할인(24개월)'
                                  : (
                                    +plan.is_commitment
                                    ? '선택약정할인'
                                    : ((key === 0 && promotion) ? '프로모션할인' : '')
                                  )
                                )
                              }
                              </p>
                            </div>
                          ) : (
                            <div className="sale-prices">
                              <p>
                                <span>
                                  {priceFormat(roundUpNumber(cost - (key === 0 ? promotion : 0)))}
                                </span>
                                <span>원</span>
                              </p>
                              <p className="ont-12" style={{ whiteSpace: 'break-spaces' }}>
                              {
                                +plan?.is_commitment && plan_24
                                ? '약정할인(24개월)\n\n선택약정할인' 
                                : (
                                  plan_24 
                                  ? '약정할인(24개월)' 
                                  : (
                                    +plan.is_commitment
                                    ? '선택약정할인'
                                    : ((key === 0 && promotion) ? '프로모션할인' : '')
                                  )
                                )
                              }
                              </p>
                            </div>
                          )}
                        </td>
                        <td className="text-right">
                          {
                              plan.discount_wireless && plan.discount_wireless.length > 0 ? (
                                  <Fragment>
                                      {plan.discount_wireless.map((val, discount_wireless_key) => (
                                              <div className="sale-prices" key={discount_wireless_key}>
                                                  <p>
                                                  <span className={`prices-form ${val.discount > 0 ? 'color-blue' : ''}`}>
                                                    -{priceFormat(roundUpNumber(val.discount))}
                                                  </span>
                                                      <span className="price-sub">원</span>
                                                  </p>
                                                  <div dangerouslySetInnerHTML={{ __html: `<p className="ont-12" style="line-height: 1.2">${val.name.split('(').join('<br>(')}</p>`}} />
                                              </div>
                                        ))}
                                  </Fragment>
                              ) : (
                                  <Fragment>
                                      {
                                          plan.discount_wireless_price ? (
                                              <Fragment>
                                                  {plan.discount_wireless_price != "0.00" ? (
                                                      <div className="sale-prices">
                                                          <p>
                                                          <span
                                                              className={`prices-form ${plan.discount_wireless_price > 0 ? 'color-blue' : ''}`}>
                                                            -{priceFormat(roundUpNumber(plan.discount_wireless_price))}
                                                          </span>
                                                              <span className="price-sub">원</span>
                                                          </p>

                                                          <div dangerouslySetInnerHTML={{ __html: `<p className="ont-12" style="line-height: 1.2">${combine_name.split('(').join('<br>(')}</p>`}} />
                                                      </div>
                                                  ) : ''}
                                              </Fragment>
                                          ) : null
                                      }
                                  </Fragment>
                              )

                          }

                          {plan.discount_tv && plan.discount_tv != "0" ?  (
                            <Fragment>
                              <div className="sale-prices">
                                <p>
                                  <span className="prices-form color-blue">
                                    -{priceFormat(roundUpNumber(plan.discount_tv))}
                                  </span>
                                  <span className="price-sub">원</span>
                                </p>
                                <p className="ont-12">
                                  TV 플러스
                                </p>
                              </div>
                            </Fragment>
                          ) : null}
                        </td>
                        <td className="text-right">
                          <p className="mb-2">
                            <span className="total-price ">
                            {
                              (roundUpNumber(roundUpNumber(cost) - roundUpNumber(+plan.discount_wireless_price) - +plan.discount_tv)) - (key === 0 ? promotion : 0) > 0 ?
                                priceFormat((roundUpNumber(roundUpNumber(cost) - roundUpNumber(+plan.discount_wireless_price) - +plan.discount_tv)) - (key === 0 ? promotion : 0))
                                :
                                0
                            }
                            </span>
                            <span>원</span></p>
                        </td>
                      </tr>
                  )
                )
              })}
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <span className="font-weight-bold">요금총액</span><small className="text-muted">(VAT 포함)</small>
                </td>
                <td className="text-right">
                </td>
                <td className="text-right">
                  <p className="mb-2">{priceFormat(sumPlanPriceResult)}원</p>
                </td>
                <td className="text-right">
                  <p className="mb-2"><span className="prices-form text-blue text-bold">-{priceFormat(sumDiscountResult)}원</span></p>
                </td>
                <td className="text-right">
                  <span className="px-2"><span className="total-price">{priceFormat(sumPromotionResult)}</span><span className="price-sub">원</span></span>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}


export default ResultListTable;
