import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import CombineResultList from './../../Combine/CombineResultList'

//해당 js파일은 결합할인 적용 시 무선상담 페이지에서 이전 결합의 내역까지 출력되는 부분과는 연관없음

class WiredEstimatedDiscount extends Component {
  render() {
    const { WiredAdvice, telcomParents } = this.props
    let telcom = null;
    telcomParents.map((telcomParent) => {
      if (WiredAdvice?.WiredAdviceStep1?.telcomID == telcomParent.id) {
        telcom = telcomParent
      }
      return null
    })
    
    return (
      <CombineResultList
        combinePlans={WiredAdvice?.WiredAdviceStep2?.combinePlans || []}
        combineCondition={WiredAdvice?.WiredAdviceStep2?.combineCondition || {}}
        WiredAdvice={WiredAdvice}
        telcomID={WiredAdvice?.WiredAdviceStep1?.telcomID}
        telcom={telcom}
      />
    );
  }
}

WiredEstimatedDiscount.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  openPopup: PropTypes.func,
  isOpenModal: PropTypes.bool,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
  telcomParents: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WiredEstimatedDiscount);
