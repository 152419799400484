import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Modal} from 'reactstrap';
import moment from "moment";

class SignInAlertPopupPaidVersion2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_check: false,
        };

        this.setupDontShowInDay = this.setupDontShowInDay.bind(this);
        this.isShowPopup = this.isShowPopup.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
    }

    setupDontShowInDay(event) {
        this.setState({
            is_check: !this.state.is_check
        });
    }

    onClose(event) {
        if (this.state.is_check !== false) {
            localStorage.setItem("show_signin_alert_popup_paid_version2", moment().format('YYYY-MM-DD 00:00'));
        } else {
            localStorage.removeItem("show_signin_alert_popup_paid_version2");
        }
    }

    isShowPopup() {
        if (this.props.isOpen === true) {
            if (!localStorage.getItem("show_signin_alert_popup_paid_version2")) {
                return true;
            }

            const checkisNewDay = moment(localStorage.getItem("show_signin_alert_popup_paid_version2"));
            if (checkisNewDay.diff(moment(), 'day') > 0) {
                return true;
            }
        }

        return false;
    }

    redirectToSignUp = () => {
        this.props.history.push('sign-up')
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.isShowPopup()}
                       id={"modal-mobicell-06"}
                       className="modal-dialog-centered modal-mobicell-06"
                       // backdrop={"static"}
                       toggle={this.props.toggle}
                       onClosed={this.onClose}
                >
                    <div className="modal-body">
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src="/images/icon-close.svg" alt=""/>
                        </button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        // showLoading: () => dispatch(showLoading()),
        // hideLoading: () => dispatch(hideLoading()),
        // change: () => () => dispatch(change())
    }
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SignInAlertPopupPaidVersion2);
