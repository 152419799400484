import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 

class PaperMoneyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            part: 0,
        };

    }

    handleChange = (val) => {
        this.setState({
            total: val
        }, () => {
            this.props.setValuePay(this.state.total)

        })
    }

    setPart = (val) => {
        this.setState({
            part: val
        }, () => {
            this.props.setValuePay(this.state.part)

        })
    }


    handleChangePart(event) {
        this.setState({part: event.target.value});
    }
    render() {
        const {
            total
        } = this.state;
        return (
            <Fragment>
                {/* modal */}
                <div className="modal fade"
                     id="modal-24" tabIndex="-1"
                     role="dialog"
                     // data-backdrop="static"
                     data-keyboard="false"
                     aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-additional modal_price_pay" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">현금납부액 </h5>
                                {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src="/images/icon-close.svg" alt="" />
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <ul className="list-additional">
                                    <li className="active cursor" onClick={this.handleChange.bind(this, 0)} data-dismiss="modal">
                                        미적용
                                    </li>
                                    <li className="cursor" onClick={this.handleChange.bind(this, 500)} data-dismiss="modal">
                                        현금 완납
                                    </li>
                                    <li className="cursor" data-toggle="modal" data-target="#modal-78" className="cursor">
                                        부분 납부
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modal-78"
                     tabIndex={-1} role="dialog"
                     // data-backdrop="static"
                     data-keyboard="false"
                     aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content pl-4 pr-4">
                            <div className="modal-header">
                                <h5 className="modal-title">현금 납부액 입력 </h5>
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src="/images/icon-close.svg" alt="" />
                                </button>
                            </div>
                            <div className="modal-body pl-4 pr-4">
                                <form >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-modal-gray">할부원금 : 973,000 원</p>
                                    </div>
                                    <div className="form-group row mt-4 align-items-center">
                                        <div className="col-10 pr-0">
                                            <input type="text" onChange={this.handleChangePart.bind(this)} className="form-control h-44" />
                                        </div>
                                        <div className="col-2">
                                            <span className="text-wrapper-gray">원</span>
                                        </div>
                                    </div>
                                    <h5 className="text-wrapper-gray text-left color-orange">
                                        ※ 할부원금을 초과한 금액은 적용하실 수 없습니다.
                                    </h5>
                                    <h5 className="text-wrapper-gray text-left">
                                        입력한 현금 납부액을 적용하시겠습니까?
                                    </h5>
                                    <div className="d-flex justify-content-center border-top-0 mt-5">
                                        <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" data-dismiss="modal">취소</button>
                                        <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={this.setPart.bind(this, this.state.part)} data-dismiss="modal">확인</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                {/* end modal */}
            </Fragment>

        );
    }
}

export default PaperMoneyModal;
