
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat} from "../../../utils/helper";
import renderTextField from "../../FormHelper/TextField";
import renderTextareaField from "../../FormHelper/TextareaField";
import renderTextFieldCustom from "../../FormHelper/TextField/PhonePotentialCustomer";
const mainSelectPhone = {
    display: "flex",
    alignItems: "center",
};
const selectForm = {
    width: "auto",
    marginRight: "5px"
};
const width100 = {
    width: "100%"
};
const errorStyle = {
    position: "absolute"
};
class DetailCustomerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    openModal = () => {
        this.props.openMessageCreateCustomer(true)
    }
    loadCustomerDetail = () => {
        this.props.loadCustomer()
    }

    render() {

        const {isOpenModal,customersObjChoose, openModalPopup} = this.props
        const {error}=this.state

        return (
            <Modal isOpen={this.props.isOpenModal}
                   toggle={this.props.openModalPopup} fade={true}
                   className={'modal-dialog modal-dialog-centered modal-lg modal-query'}>
                <form >
                    <div className="modal-header border-bottom-0">
                        <div className="header_modal">
                            <h5 className="modal-title">가망고객 상세보기 </h5>
                        </div>
                        <button type="button" className="close close-modal" onClick={openModalPopup}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body mb-3">
                        {/*<div className="form-group">*/}
                            {/*<label className="label-text">고객명</label>*/}
                            {/*<Field*/}
                                {/*disabled={true}*/}
                                {/*type="text"*/}
                                {/*className="form-control h-44"*/}
                                {/*name="customersObjChoose.name"*/}
                                {/*placeholder="고객명"*/}
                                {/*component={renderTextField}*/}
                            {/*/>*/}
                            <div className="form-group">
                                <label className="label-text">고객명</label>
                                <input name="customersObjChoose.name" type="text" className="form-control form-control h-44 h-44" placeholder="고객명" autoComplete="new-password" disabled={true} value={customersObjChoose.name} />
                            </div>
                        {/*</div>*/}
                        <div className="form-group">
                            <label className="label-text">연락처</label>
                            <div className="main-select-phone" style={mainSelectPhone}>
                                <select className="form-control h-44" style={selectForm} disabled={true}
                                        value={customersObjChoose.topPhone != 0 ? customersObjChoose.topPhone : customersObjChoose.phone.substring(0, 3)} onChange={this.handleChange} name="customersObjChoose.topPhone"
                                        component={renderTextField}>

                                    <option value="010">010</option>
                                    <option value="011">011</option>
                                    <option value="017">017</option>
                                    <option value="018">018</option>
                                    <option value="019">019</option>
                                </select>
                                <div className="fill_input">
                                    <input name="customersObjChoose.phoneFormat" type="text" className="form-control  h-44" placeholder="연락처" autoComplete="new-password" disabled={true}
                                           value={customersObjChoose.phoneFormat} />
                                    {/*<Field*/}
                                        {/*disabled={true}*/}
                                        {/*type="text"*/}
                                        {/*className="form-control h-44"*/}
                                        {/*name="customersObjChoose.phoneFormat"*/}
                                        {/*placeholder="연락처"*/}
                                        {/*// errorStyle={errorStyle}*/}
                                        {/*component={renderTextFieldCustom}*/}
                                        {/*// onChange={this.handleChangeInput}*/}
                                        {/*// onBlur={this.notActive}*/}
                                        {/*// errorPhone={error}*/}
                                    {/*/>*/}

                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="label-text">메모</label>
                            <textarea name="customersObjChoose.note" className="form-control " placeholder="메모" rows="3" autoComplete="new-password" disabled={true}>{customersObjChoose.note}</textarea>
                        </div>
                        {/*<div className="form-group">*/}
                            {/*<label className="label-text">메모</label>*/}
                            {/*<Field*/}
                                {/*disabled={true}*/}
                                {/*className="form-control"*/}
                                {/*name="customersObjChoose.note"*/}
                                {/*placeholder="메모"*/}
                                {/*rows="3"*/}
                                {/*component={renderTextareaField}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button
                            // ref={this.refCloseModal}
                            type="button"
                            className="btn btn-gray"
                            onClick={openModalPopup}
                        >
                            닫기
                        </button>
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="btn btn-submit"*/}
                        {/*    onClick={()=>this.loadCustomerDetail()}*/}
                        {/*>*/}
                        {/*    저장*/}
                        {/*</button>*/}
                    </div>
                </form>


            </Modal>
        );
    }
}

DetailCustomerModal.propTypes = {};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(DetailCustomerModal);
