import React from 'react';
import './PopupWireLess.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import api from './../utils/api';
import { updateWirelessStep3, caclWirelessStep3, priceFormat } from './../utils/helper'
import { showLoading, hideLoading } from './../store/actions/ui';
import {connect} from "react-redux";
import {compose} from "redux";
import DiscountCardPrint from "./DiscountCardPrint";
import {getDiscountCard} from '../store/actions/discount_card';
class PopupWireLess extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      data: {},
      activeAdvancedDiscountCard : {},
      activeDiscountCard : {}
    }
  }

  componentDidMount() {
    let list2g = [
      "미적용",
      '적용',
      '무약정',
    ]
    let url = this.props.location.search
    let params = queryString.parse(url);
    if(params && params.id){
      this.props.showLoading();

      api.advice.getConsultant(params.id).then(res => {
        this.props.hideLoading();
        const { data } = res.data;
        let WirelessAdviceStep3Clone = updateWirelessStep3({}, data)
        WirelessAdviceStep3Clone.interestRate = data.telcom.installment_rate
        let wirelessAdviceStep3 = caclWirelessStep3(data.telcom.installment_payment_method, WirelessAdviceStep3Clone, data.telcom.recipe_days_remaining);
        wirelessAdviceStep3.telcom = data.telcom
        wirelessAdviceStep3.color = data.consultant_wireless.device_color
        wirelessAdviceStep3.updated_at = data.updated_at
        wirelessAdviceStep3.conversion_2g_text = list2g[data.consultant_wireless.conversion_2g]
        wirelessAdviceStep3.estimated_rates_changing_plans = data.consultant_wireless.estimated_rates_changing_plans
        wirelessAdviceStep3.device_insurance = data.consultant_wireless.device_insurance
        wirelessAdviceStep3.plan_change = data.consultant_wireless.plan_change


        this.setState({
          data : wirelessAdviceStep3
        },()=>{
          if(window.currentDevice === "web") {
            if(params && !params.share){
              setTimeout(
                () => {
                  window.focus();
                  window.print();
                },
                1500
              );
            }
          }

        })
      }).catch(e => {
        this.props.hideLoading();
      })


      if(params.activeAdvancedDiscountCard && params.activeAdvancedDiscountCard !== "false"){
        api.discount_card.getDiscountCardById(params.activeAdvancedDiscountCard).then(res => {
          this.setState({
            ...this.state,
            activeAdvancedDiscountCard : res.data.data
          })
        });
      }


      if(params.activeDiscountCard && params.activeDiscountCard !== "false"){
        api.discount_card.getDiscountCardById(params.activeDiscountCard).then(res => {
          this.setState({
            ...this.state,
            activeDiscountCard : res.data.data
          })
        });
      }
    }
  }

  render() {
    let { data } = this.state
    let result = 0;
    if(data.plan_change){
      let dataFull = data.installmentMonthly;
      let goiPhi = (data.plan_change.cost_display * 0.1) + (+data.plan_change.cost_display);

      let result_a = 0;
      if (data.optionFee == 1) {
          result_a =  0
      }else{
          if (data.plan_change.spec_cmm == 1) {
            result_a = (+data.plan_change.spec_cmm_value * (+data.plan_change.cost_display/100)) + (+data.plan_change.spec_cmm_value * (+data.plan_change.cost_display/100))*0.1;
          }else{
            result_a = (+data.telcom.installment_rate * (+data.plan_change.cost_display/100)) + (+data.telcom.installment_rate * (+data.plan_change.cost_display/100))*0.1;
          }
      }

      let result_b = 0;
      if (data.discostChange == 12){
          result_b = +data.plan_change.discost_12 + (+data.plan_change.discost_12*0.1)
      }else{
          result_b = +data.plan_change.discost_24 + (+data.plan_change.discost_24*0.1)

      }

      let result_c = data.promotion?.promo_cost ? data.promotion?.promo_cost : 0;

      let result_d = data.combinedDiscount ? data.combinedDiscount : 0;

      let result_f = data.welfareDiscount?.cost
      let result_e = data.discountCard ? data.discountCard : 0;
      // let result_g = 40000;

      let result_goi_phi = goiPhi - result_a -result_b - result_c - result_d -result_f - result_e;
      result = result_goi_phi + dataFull;
    }
    let dataNote = '';
    if(data.note){
      dataNote = data.note.replace(/\r?\n/g, '<br />');
    }

    let date = '';
    if(data.planChangeDate){
      if(moment(data.planChangeDate,'YYYY-MM-DD').format('YYYY-MM-DD') == "Invalid date"){
        date = data.planChangeDate+' 변경가능';
      }else {
        date = data.planChangeDate.split("-")[0]+'년 '+ data.planChangeDate.split("-")[1]+'월' + data.planChangeDate.split("-")[2]+'일 변경가능'
      }
    }


    let url = this.props.location.search
    let params = queryString.parse(url);



    return (
      <div id="estimate-wrap_1">
        {params.active && params.active !== "false"
        &&
        <section className="estimate-wireless">
          <header>
            <div className="telecom-logo" style={{width: '27px', height: '30px'}}>
              <img
                src={data.telcom?.image_url?.original}
                alt={data.telcom?.name}
                style={{height: '30px', marginTop: '-0px'}}
              />
            </div>
            <h1>서비스 가입 견적서</h1>
            <span className="info-date">{moment().format('YYYY-MM-DD')}</span>
          </header>
          <article className="info-purchase-device-plan-wrap">
            <table className="tbl-device-plan">
              <caption/>
              <colgroup>
                <col style={{width: '50%'}}/>
                <col style={{width: '50%'}}/>
              </colgroup>
              <thead>
              <tr>
                <th>단말기 구매정보 (A)</th>
                <th>요금 정보 (B)</th>
              </tr>
              </thead>
              <tbody>
              <tr className="info-device-plan-sec">
                <td className="info-purchase-device">
                  <div className="device-image">
                    <img src={data?.color?.files_url[0]?.original} alt={data.phone?.name}/>
                    <p className="device-color info-type">
                      <span style={{backgroundColor: data.color?.code, '-webkit-print-color-adjust': 'exact'}}
                            data-bg-color="#272727"/>
                      {data.color?.name}
                    </p>
                    {/*<p className="device-color info-type"><span style={{backgroundColor: "#05060a"}} data-bg-color="#272727"></span>프리즘 크러시 블랙</p>*/}
                  </div>
                  <dl className="device-info">
                    <dt>{data.phone?.name}</dt>
                    <dd>
                      <table className="tbl-device">
                        <caption/>
                        <colgroup>
                          <col style={{width: '15%'}}/>
                          <col style={{width: '80%'}}/>
                        </colgroup>
                        <tbody>
                        <tr>
                          <th>출고가</th>
                          <td>
                            {priceFormat(data.startingPrice)} 원
                          </td>
                        </tr>
                        <tr>
                          <th>CPU</th>
                          <td>{data.phone?.v_device?.cpu}</td>
                        </tr>
                        <tr>
                          <th>RAM</th>
                          <td>{data.phone?.v_device?.memory} GB</td>
                        </tr>
                        <tr>
                          <th>화면</th>
                          <td>
                            {data.phone?.v_device?.screen_size}인치 {data.phone?.v_device?.disp_info}</td>
                        </tr>
                        <tr>
                          <th>카메라</th>
                          {/*modelCamera*/}
                          <td>
                            <p>전면 {data.phone?.v_device?.front_cam} </p>
                            <p> 후면 {data.phone?.v_device?.back_cam}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>기타</th>
                          <td>{data.phone?.v_device?.battery}, {data.phone?.v_device?.usim_name}</td>
                        </tr>
                        </tbody>
                      </table>
                    </dd>
                  </dl>
                </td>
                <td className="info-purchase-plan" style={{whiteSpace: 'initial'}}>
                  <dl>
                    <dt>{data.packageFee?.name}</dt>
                    <dd>
                      {/*<div*/}
                      {/*  className="text-muted"*/}
                      {/*  dangerouslySetInnerHTML={{ __html: data.packageFee?.description.split('\n').join('<br />') }}*/}
                      {/*/>*/}
                      <p className={'text-muted'}>
                        {data.packageFee && data.packageFee.call_time_desc ? data.packageFee.call_time_desc + '/ ' : ''}
                        {data.packageFee && data.packageFee.data_detail ? data.packageFee.data_detail + '/ ' : ''}
                        {data.packageFee && data.packageFee.sms_desc ? data.packageFee.sms_desc : ''}
                      </p>

                      {/*<div></div>*/}
                      <div
                        className="text-muted"
                        dangerouslySetInnerHTML={{__html: data.packageFee?.extra_info}}
                      />
                    </dd>
                  </dl>
                </td>
              </tr>
              <tr className="info-device-plan-price-sec">
                <td>
                  <div>
                    <p>
                      <strong>출고가</strong>
                      <span className="price">{priceFormat(data.startingPrice)}</span>
                    </p>
                    <p className={data.optionFee != 1 ? `info-light` : ''}>
                      <strong>공시지원금<em className="desc desc-import">{data.displayApplyDate && data.displayApplyDate != "Invalid date" ? data.displayApplyDate +'기준'  : '' } </em></strong>
                      <span className="price">
                          {data.optionFee == 1 ? (data.disclosureSubsidy ? '-' + priceFormat(data.disclosureSubsidy) : 0) : 0}

                        </span>
                    </p>
                    <p className={data.optionFee != 1 ? `info-light` : ''}>
                      <strong>추가지원금</strong>
                      <span className="price">
                          {data.optionFee == 1 ? (data.additionalGrants ? '-' + priceFormat(data.additionalGrants) : 0) : 0}
                        </span>
                    </p>
                    <p>
                      <strong>포인트 할인</strong>
                      <span className="price">
                          {data.pointDiscount ? '-' + priceFormat(data.pointDiscount) : 0}
                        </span>
                    </p>
                    <p>
                      <strong>기타할인</strong>
                      <span className="price">
                          {data.other ? '-' + priceFormat(data.other) : 0}
                        </span>
                    </p>
                    <p>
                      <strong>선할인카드<em className="desc desc-info"/></strong>
                      <span className="price ">
                          {data.advancedDiscountCard ? '-' + priceFormat(data.advancedDiscountCard) : 0}
                        </span>
                    </p>
                    <p>
                      <strong>현금구입가<em className="desc desc-import"/></strong>
                      <span className="price">
                          {data.cashPayment ? '-' + priceFormat(data.cashPayment) : 0}
                        </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>
                        할부원금
                        {/*<em className="desc desc-import">월<span className="price dc-price">0</span></em>*/}
                      </strong>
                      <span className="price">{priceFormat(data.installmentPrincipal)}</span>
                    </p>
                    <p>
                      <strong>
                        할부수수료
                        {/*<em className="desc desc-import">월<span className="price dc-price">0</span></em>*/}
                        <em className="desc desc-info desc-warning">
                          원리금균등 {priceFormat(data.interestRate, 1)}%
                        </em>
                      </strong>
                      <span className="price">{priceFormat(data.installmentFee)}</span>
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      <strong>기본료</strong>
                      <span className="price">{priceFormat(data.basicCost)}</span>
                    </p>
                    <p className={data.optionFee != 0 ? `info-light` : ''}>
                      <strong>선택약정</strong>
                      <span className="price">
                          {data.optionFee == 0 ? '-' + priceFormat(data.optionalAgreement) : 0}
                        </span>
                    </p>
                    <p>
                      <strong>요금약정 </strong>
                      <span className="price">
                          {data.rateAgreement ? '-' + priceFormat(data.rateAgreement) : 0}
                        </span>
                    </p>
                    {/*<p>*/}
                    {/*  <strong>요금약정</strong>*/}
                    {/*  <span style={{ float: 'right' }}>*/}
                    {/*    {data.discostChange} 개월*/}
                    {/*  </span>*/}
                    {/*</p>*/}
                    <p>
                      <strong>약정할인</strong>
                      <span
                        className="price">{priceFormat(data.promotion?.promo_cost) ? '-' + priceFormat(data.promotion?.promo_cost) : priceFormat(data.promotion?.promo_cost)}</span>
                    </p>
                    <p>
                      <strong>프로모션할인</strong>
                      <span className="price">0</span>
                    </p>
                    <p>
                      <strong>결합할인<em className="desc desc-info"/></strong>
                      <span
                        className="price">{data.combinedDiscount ? '-' + priceFormat(data.combinedDiscount) : 0}</span>
                    </p>
                    <p>
                      <strong>복지할인<em className="desc desc-info"/></strong>
                      <span
                        className="price">{priceFormat(data.welfareDiscount?.cost) ? '-' + priceFormat(data.welfareDiscount?.cost) : priceFormat(data.welfareDiscount?.cost)}</span>
                    </p>
                    <p>
                      <strong>청구할인카드<em className="desc desc-info"/></strong>
                      <span
                        className="price">{priceFormat(data.discountCard) ? '-' + priceFormat(data.discountCard) : priceFormat(data.discountCard)}</span>
                    </p>
                    <p>
                      <strong>2G전환<em className="desc desc-info"/></strong>
                      <span
                        style={{float: 'right'}}>{data.conversion_2g_text ? '-' + data.conversion_2g_text : data.conversion_2g_text}</span>
                    </p>
                  </div>
                  <div>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot className="info-device-plan-price-sec">
              <tr>
                <td>
                  <strong>월 단말기 할부금<em className="desc">{data.installmentMonth}개월</em></strong>
                  <span className="price dc-price total-dc-price">{priceFormat(data.installmentMonthly)}</span>
                </td>
                <td>
                  <strong>월 납부요금</strong>
                  <span className="price dc-price total-dc-price">{priceFormat(data.monthlyFee)}</span>
                </td>
              </tr>
              </tfoot>
            </table>
          </article>
          <article className="info-purchase-device-plan-month-wrap estimate-total-price price-calc-all">
            <div className="price-calc-import">
              <strong>월 청구금액 (A+B)</strong>
              <p className="price-box">
                <span className="extra">(VAT 포함)</span>
                <span className="price dc-price total-dc-price">{priceFormat(data.amountMonthlyDevice)}</span>
              </p>
            </div>
            <div>
              <strong>당월 납부액</strong>
              <p className="price-box">
                <span className="price">{priceFormat(data.moneyRemainingMonth)}</span>
              </p>
            </div>
          </article>
          <article className="info-change-device-plan-month-wrap estimate-total-price price-change-all">
            <div className="price-change-origin">
              <strong>
                요금제 변경 시 청구금액
                <em className="desc">{date ? date : ''}</em>
              </strong>
              <p className="price-box">
                <span className="price">{priceFormat(result)}</span>
              </p>
            </div>
            {/*<div>*/}
            {/*  <strong>*/}
            {/*    변경 요금제*/}
            {/*    <em className="desc"> </em>*/}
            {/*  </strong>*/}
            {/*  <p className="price-box">*/}
            {/*    <span className="extra">공시 차액 정산금</span>*/}
            {/*    <span className="price"><em>0</em></span>*/}
            {/*  </p>*/}
            {/*</div>*/}
          </article>
          <article className="info-list-usim-addon-wrap">
            <h3>USIM 및 부가기능 (C)</h3>
            <table>
              <caption/>
              <colgroup>
                <col style={{width: '60px'}}/>
                <col style={{width: '105px'}}/>
                <col style={{width: '255px'}}/>
                <col style={{width: 'auto'}}/>
              </colgroup>
              <tbody>
              <tr>
                <td>
                  <strong>
                    USIM
                    {/*{data.sim?.name}*/}
                  </strong>
                </td>
                <td>
                  <span className="price">{priceFormat(data.sim?.price)}</span>
                  <p className="desc desc-info">{data.sim?.name}</p>
                </td>
                <td rowSpan={2}>
                  <strong className="addon-title">휴대폰 보험</strong>
                  <span className="price">{priceFormat(data.insurrance?.ins_price)}</span>
                  <dl className="addon-service-info">
                    {data.device_insurance ? '' : <dt><br/> <br/><br/><br/></dt>}
                    {/*<dt>{data.device_insurance ?  data.device_insurance.ins_name :''}</dt>*/}
                    <dd className="desc">
                      {data.device_insurance ? data.device_insurance.ins_name : ''}
                    </dd>
                  </dl>
                </td>
                <td rowSpan={2}>
                  <strong className="addon-title">부가서비스</strong>
                  <span className="price">
                      {priceFormat(data.amountMonthlyOption - (data.insurrance?.ins_price || 0))}
                    </span>
                  <span className="membership-txt"/>
                  <ul className="addon-service-info">
                    {data?.extraServices?.map((extra, key) => (
                      <li key={key}>
                        <strong>{extra.ets_name}<br/></strong>
                        <span>{priceFormat(extra.ets_price)}원</span>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
              {data.subscriptionFee
                ? <tr>
                  <td><strong>가입비</strong></td>
                  <td>
                    <span className="price">{priceFormat(data.subscriptionFee.tmf_fee)}</span>
                    <p className="desc desc-info">{data.subscriptionFee.tmf_name}</p>
                  </td>
                </tr>
                : ''}


              </tbody>
              <tfoot>
              <tr>
                <td colSpan={2}><strong>월 부가서비스 요금</strong></td>
                <td colSpan={2} className="info-price">
                  <span className="extra">(VAT 포함, 유심비, 가입비 제외)</span>
                  <span className="price">{priceFormat(data.amountMonthlyOption)}</span>
                </td>
              </tr>
              </tfoot>
            </table>
          </article>
          <article className="info-all-device-plan-month-wrap estimate-total-price price-calc-all">
            <h3>월 청구 금액 합계 (A+B+C)</h3>
            <div className="price-box">
              <span className="extra">(VAT 포함)</span>
              <span className="price dc-price total-dc-pric">
                {priceFormat(data.amountMonthlyTotal)}
              </span>
            </div>
          </article>

          <article className="estimate-memo-box">
            <h4>Memo</h4>
            <div className="estimate-memo">
              {/*<div dangerouslySetInnerHTML={{ __html: data.note.split('\n').join('<br />') }} />*/}
              {dataNote ? <div dangerouslySetInnerHTML={{__html: dataNote}}/> : ''}

              {/*<textarea>dsad</textarea>*/}
            </div>
          </article>
          <footer className="estimate-guide-box estimate-guide-box-1">
            <div className="agent-info">
              <div
                className="info-item">{params.seller && params.seller !== "false" ? '상담사 :' + data?.saler?.name + '/' : ''} 상담일
                : {moment(data?.updated_at).format('YYYY-MM-DD')}</div>
              <div
                className="info-item">{params.storeName && params.storeName !== "false" ? `매장명 : ${data?.saler?.store.length > 0 ? data.saler.store[0].sto_name : ''} ${params.address && params.address !== "false" ? '/' : ''}` : ''}
                {params.address && params.address !== "false" ? `전화 : ${data?.saler?.store.length > 0 ? data.saler.store[0].sto_addr_line1 : ''} ` : ''}
              </div>
              {params.contact && params.contact != "false" ?
                <div className="info-item"> 전화 : {data?.saler?.phone}</div> : ''}
            </div>
            <div className="estimate-common-guide">
              <ul>
                <li>실 납부 금액은 통신사 및 제조사의 정책 변경에 따라 변동이 있을 수 있으며, 금액산정 방식에 따라 다소간의 차이가 있을 수 있습니다.</li>
                <li>약정기간 이내 해지 및 요금제 변경/명의변경 시 공시지원금, 추가할인, 선택약정할인에 대한 위약금이 발생할 수 있습니다.</li>
                <li>단말기 교품은 제조사 A/S센터에서 불량확인증을 발급 받으신 후 14일 이내 매장에 방문 주셔야 합니다. (단, 고객 단순변심으로 인한 교품 및 개통취소는 불가능 합니다.</li>
                <li>개통 후 241일 이내 해지, 명의변경, 전산기기변경, 이용정지 및 분실정지는 불가합니다.</li>
                <li>개통 후 241일 동안 매월 15건 이상 15분 이상의 실 통화량이 발생하여야 합니다.</li>
              </ul>
            </div>
          </footer>
        </section>
        }

        {params.activeAdvancedDiscountCard && params.activeAdvancedDiscountCard !== "false"
        && <DiscountCardPrint
          discountCard={this.state.activeAdvancedDiscountCard}
          imgTecom={data.telcom?.image_url?.original}
        />}

        {params.activeDiscountCard && params.activeDiscountCard !== "false"
        && <DiscountCardPrint
          discountCard={this.state.activeDiscountCard}
          imgTecom={data.telcom?.image_url?.original}
        />}


      </div>
    )
  }
}

PopupWireLess.propTypes = {
  location: PropTypes.object
}

const mapStateToProps = state => {
  return {
    // ForgotId: getFormValues('ForgotId')(state),

  };
};
const mapDispatchToProps = dispatch => {
  return {
    discountCard: (data) => dispatch(getDiscountCard(data)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);



export default compose(
  withConnect,
  // withReduxForm,
)(PopupWireLess);
