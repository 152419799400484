import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

class ScrollToTopPages extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {

      if(isIE || isSafari ){
        document.documentElement.scrollTop = 0
      }else{
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTopPages)