import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../store/actions/ui";

class ImageSliderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false
        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.props.showLoading();
        this.props.hideLoading();
    }

    render() {
        let images = this.props.images;
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-dialog modal-dialog-centered modal-images modal-images-custom"
                       toggle = {(e) => this.props.toggleModal(e,'images_slider_modal')}
                       onOpened = {this.onOpened}
                       onClosed = {this.onCloseModal}
                       // backdrop={"static"}
                >
                    <div className="modal-header border-0">
                        <button type="button" className="close close-modal"
                                onClick={(e) => this.props.toggleModal(e,'images_slider_modal')}
                        >
                          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="carouselExampleIndicators" className="carousel slide slider-images"
                             data-ride="carousel">
                            <ol className="carousel-indicators dots-carousel">
                                {
                                    images && images.map((image, key) => {
                                        return (
                                            <li data-target="#carouselExampleIndicators"
                                                data-slide-to={key}
                                                key={key}
                                                className={key == 0 && "active"}>
                                                <span></span>
                                            </li>
                                        )
                                    })
                                }
                            </ol>
                            <div className="carousel-inner">
                                {
                                    images && images.map((image, key) => {
                                        return (
                                            <div className={`carousel-item ${key == 0 && "active"}`} key={key}>
                                                <img src={image.images} className="d-block" alt="..."/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                               data-slide="prev">
                                <span></span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                               data-slide="next">
                                <span></span>
                            </a>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(ImageSliderModal);
