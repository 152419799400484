
 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { Modal } from 'reactstrap';
import _ from 'lodash';
class TableInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    } 

    renderMonth = (programRates) => {
        let arrSample = [...Array(programRates.length).keys()];
        let xhtml = arrSample.map((item, index) => {
            return (
                <td className="program_rate" key={index} style={{backgroundColor: "#f5f5f5"}}>
                    <div className="input-group-cs">
                        {/* <input type="number" className="form-control-cs pr-0" placeholder="개월수" disabled
                        value={index < programRates.length && programRates[index].month ? programRates[index].month : '' }/> */}
                        <span className="month_custom">
                            {index < programRates.length && programRates[index].month ? programRates[index].month : '개월수' }
                        </span>
                        <span className="attach">개월</span>
                    </div>
                </td> 
            )
        })

        return xhtml;
    }

    renderRate = (programRates) => {
        let arrSample = [...Array(programRates.length).keys()];
        let xhtml = arrSample.map((item, index) => {
            return (
                <td className="program_rate" key={index} >
                    <div className="input-group-cs pr-0">
                        {/* <input className="form-control-cs" placeholder="비율" disabled
                         value={index < programRates.length && programRates[index].rate ? programRates[index].rate : '' }/> */}                        
                        <span className="rate_custom">
                            {index < programRates.length && programRates[index].rate ? programRates[index].rate : '비율' }
                        </span>
                        <span className="attach">%</span>
                    </div>
                </td>  
            )
        })

        return xhtml;
    }

    render() {  
        const {programRates}  = this.props;
        console.log(programRates, 'programRatesprogramRatesprogramRatesprogramRates')
        return (
            <Fragment>   
                <table className="table table-bordered program_rates">                        
                    <tbody>
                        <tr>
                            {/* <th scope="row">개월수</th> */}
                            {this.renderMonth(programRates)}
                        </tr>


                        <tr>
                            {/* <th scope="row">보장률</th> */}
                            {this.renderRate(programRates)}                                                 
                        </tr>                             
                    </tbody>
                </table>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        usedPrograms: state.dataStore.usedProgram,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // showLoading: () => dispatch(showLoading()),
        // hideLoading: () => dispatch(hideLoading()),
        // change: () => () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(TableInfo);
