 
import PropTypes from 'prop-types';
import React, { Component, Fragment,useState  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PlanDetailsModal from "./PlanDetailsModal"
import {getDetailPlan} from "../../../store/actions/discount_card";

class PlanDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false,
            data:{}
        }
    }

    // componentDidMount() {
    //     let idPlan=11
    //     if(this.props.WirelessAdvice.packageFee != null) {
    //         idPlan=this.props.WirelessAdvice.packageFee.id
    //     }
    //     this.props.getDetailPlan(idPlan).then(res => {
    //         this.setState({
    //             data : res
    //         })
    //     })
    // }

    openPopupPlanDetails = () => this.setState({isOpenModal: !this.state.isOpenModal})



    render() {
        const { handleSubmit, WirelessAdvice,dataPlanDetail } = this.props;
        let welfare=WirelessAdvice.WirelessAdviceStep3.welfareDiscount

        return (

            <div className="col-md-5 pl-0">
                    <div className="d-flex justify-content-end">
                    <button className="btn btn-gray-white w-96 text-right mt-20 " type="button" data-toggle="modal" data-target="#modal-10 " onClick={this.openPopupPlanDetails}>
                            요금제 상세
                    </button>
                    </div>
                <PlanDetailsModal dataPlanDetail={dataPlanDetail}  isOpenModal={this.state.isOpenModal}  openPopupPlanDetails={this.openPopupPlanDetails} />
            </div>
        );
    }
}

PlanDetails.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getDetailPlan: (data) => dispatch(getDetailPlan(data)),
        change: () => dispatch(change()),
    }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    // withReduxForm,
)(PlanDetails);
