import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import renderTextField from '../../components/FormHelper/TextField';
import renderRadioField from '../../components/FormHelper/RadioField';
import renderTextFields from '../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../components/FormHelper/CheckboxField"
import renderSelectField from '../../components/FormHelper/SelectField';
import PaymentPackageList from '../../components/PaymentPackage/PaymentPackageList';
import api from "./../../utils/api";
import {
    getPricePackages
} from '../../store/actions/dataStore';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import Moment from 'react-moment';
import moment from 'moment';
import { Router } from 'react-router';
import * as routerName from '../../utils/routerName';
import * as helper from '../../utils/helper';

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSearch: {
                page: 1,
                per_page: 3,
                keyword: '',
            },
            histories: {
                data: []
            }
        }
    }

    componentDidMount() {
        this.onPaginateRequest();
    }

    convertSale = (data1, data2) => {
        return (data2 - data1) / data2 * 100;
    }

    onPaginateRequest = () => {
        this.props.showLoading();
        api.profile.getHistoryUserPayment(this.state.dataSearch).then(res => {
            this.setState({
                histories: res.data.data.histories
            })
            this.props.hideLoading();
        }).catch((e) => {
            this.props.hideLoading();
            console.log(e)
        })
    }

    onPagination = (data) => {
        var arrayNumber = [];
        for (let i = 1; i <= Math.ceil(data.total / data.per_page); i++) {

            if (data.current_page == i) {
                if ([1, 2].indexOf(data.current_page) == -1) {
                    if (i !== 1) {
                        arrayNumber.push(<li className="page-item" key={1}><a className="page-link" onClick={() => this.onClickPaginationHandle(1)}>1</a></li>);

                    } else {
                        arrayNumber.push(<li className="page-item" key={i - 2}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 2)}>{i - 2}</a></li>);
                    }
                }

                if (i - 2 > 1) {
                    arrayNumber.push(<li className="page-item" key={'...'}><a className="page-link">...</a></li>);
                }


                if ([1].indexOf(data.current_page) == -1) {
                    arrayNumber.push(<li className="page-item" key={i - 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i - 1)}>{i - 1}</a></li>);
                }

                arrayNumber.push(<li className="page-item active" key={i}><a className="page-link" onClick={() => this.onClickPaginationHandle(i)}>{i}</a></li>);

                if ((i + 1) < Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={i + 1}><a className="page-link" onClick={() => this.onClickPaginationHandle(i + 1)}>{i + 1}</a></li>);
                }

                if ([Math.ceil(data.total / data.per_page) - 1, Math.ceil(data.total / data.per_page) - 2].indexOf(i) == -1 && i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={'....'}><a className="page-link">...</a></li>);
                }


                if (i !== Math.ceil(data.total / data.per_page)) {
                    arrayNumber.push(<li className="page-item" key={Math.ceil(data.total / data.per_page)}><a className="page-link" onClick={() => this.onClickPaginationHandle(Math.ceil(data.total / data.per_page))}>{Math.ceil(data.total / data.per_page)}</a></li>);
                }
            } else {
                //arrayNumber.push(<li className="page-item"><a className="page-link" >{i}</a></li>);
            }
        }
        if (Math.ceil(data.total / data.per_page) > 1) {
            return (
                <nav aria-label="Page navigation example" className="pagination-notifi">
                    <ul className="pagination">


                        <li className={`page-item item-previous ${this.state.dataSearch.page === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" aria-label="Previous" onClick={() => this.prevPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images/Left.svg" alt="" />
                                </span>
                            </a>
                        </li>
                        {arrayNumber.map((item, i) =>
                            item
                        )}
                        <li className={`page-item item-next ${this.state.dataSearch.page === this.state.histories.last_page ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" aria-label="Next" onClick={() => this.nextPagination()}>
                                <span aria-hidden="true">
                                    <img src="/images//Right.svg" alt="" />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>

            )
        }

    }

    prevPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page - 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    nextPagination = () => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: this.state.dataSearch.page + 1
            }
        }, () => {
            //console.log(this.state.dataSearch.page)
            this.onPaginateRequest()
        })
    }

    onClickPaginationHandle = (number) => {
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                page: number
            }
        }, () => {
            this.onPaginateRequest()
        })

    }

    render() {
        console.log("this.props");
        console.log(this.props);
        console.log("this.props.auth.storePlan");
        console.log(this.props.auth.storePlan);
        return (
            <Fragment>
                <section className="container">
                    <form className="form-register register-user">
                        <div className="payment-top mt-5">
                            <div className="left">
                                {
                                    this.props.auth.storePlan ? (
                                        <React.Fragment>
                                            <p className="text-payment">
                                                - 동시 로그인 허용 개수:
                                                <span className="text-blue text-bold ml-1">
                                                    {moment(this.props.auth.storePlan?.expired_date).diff(moment(this.props.auth.storePlan?.purchase_date), 'months')}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 사용 중인 요금제:
                                                <span className="text-blue text-bold ml-1">
                                                    {this.props.auth?.storePlan?.price_package?.pp_name}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 등록일:
                                                <span className="text-blue text-bold">
                                                    <Moment format=" YYYY/MM/DD">
                                                        {this.props.auth.storePlan?.purchase_date}
                                                    </Moment>
                                                </span>
                                                <span className="border-pay"></span>
                                                종료일:
                                                <span className="color-orange text-bold">
                                                    <Moment format=" YYYY/MM/DD">
                                                        {this.props.auth.storePlan?.expired_date}
                                                    </Moment>
                                                </span>
                                            </p>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <p className="text-payment">
                                                - 동시 로그인 허용 개수:
                                                <span className="text-blue text-bold ml-1">
                                                    {moment(this.props.auth.storePlan?.expired_date).diff(moment(this.props.auth.storePlan?.purchase_date), 'months')}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 사용 중인 요금제:
                                                <span className="text-blue text-bold ml-1">
                                                    {this.props.auth?.storePlan?.price_package?.pp_name}
                                                </span>
                                            </p>    
                                            <p className="text-payment">
                                                - 등록일: ...
                                                <span className="border-pay"></span>
                                                종료일: ...
                                            </p>
                                        </React.Fragment>
                                    )
                                } 
                            </div>
                         
                            <button type="button" className="btn btn-submit px-4" onClick={() => {
                                this.props.history.push({
                                    pathname: routerName.PAYMENT_MANAGEMENT,
                                })
                            }} >월정액상품 구매</button>
                        </div>
                        <PaymentPackageList
                            pricePackages={this.props.pricePackages}
                            activePlan={this.props.pricePackages?.data[0]}
                        />
                        {this.props.auth.storePlan.useAlarm == 1 ? (
                            <Fragment>
                                <div className="title-form">
                                    <h2>
                                        부가서비스
                                    </h2>
                                </div>
                                <div className="extra-service">
                                    <div className="extra-item bg-white">
                                        <div className="item">
                                            <h4>부가서비스명</h4>
                                        </div>
                                        <div className="item">
                                            <h4>이용요금 <span>(VAT포함) </span></h4>
                                        </div>
                                    </div>
                                    <div className="extra-item">
                                        <div className="item">
                                            <div className="form-check">
                                                <div className="custom-control custom-checkbox mr-sm-2">
                                                    <input type="checkbox" className="custom-control-input" checked={this.props.auth.storePlan.useAlarm == 1 ? true : false} readOnly />
                                                    <label className="custom-control-label text-extra" >일정알림</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <p className="text-extra">월 {helper.priceFormat(this.props.priceExtraService)} 원 </p>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : ('')}
                        <div className="title-form">
                            <h2 className="px-5">
                                결제내역
                            </h2>
                        </div>
                        <div className="table-payment">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{"width":"25%"}}>
                                            <div>요금제명</div>
                                        </th>
                                        <th style={{"width":"15%"}}>
                                            <div>등록일</div>
                                        </th>
                                        <th style={{"width":"15%"}}>
                                            <div>종료일</div>
                                        </th>
                                        <th style={{"width":"15%"}}>
                                            <div>결제일</div>
                                        </th>
                                        <th style={{"width":"15%"}}>
                                            <div>금액</div>
                                        </th>
                                        <th style={{"width":"15%"}} className="pl-4">
                                            <div>상태</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.histories.data.map((item, key) =>
                                        <Fragment key={key}>
                                            <tr>
                                                <td>
                                                    {item.pp_name}
                                                    {/* pp_id: {item.pp_id} */}
                                                </td>
                                                
                                                <td>
                                                    <Moment format="YYYY-MM-DD">
                                                        {item.purchase_date}
                                                    </Moment>
                                                </td>
                                                
                                                <td>
                                                    <Moment format="YYYY-MM-DD">
                                                        {item.expired_date}
                                                    </Moment>
                                                </td>
                                                
                                                <td>
                                                    <Moment format="YYYY-MM-DD">
                                                        {item.created_at}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {helper.priceFormat(item.discount_price)} won
                                                </td>
                                                {item.transaction_status == '-1' && (
                                                    <td className="pl-4">
                                                        취소
                                                    </td>
                                                )}
                                                {item.transaction_status != '-1' && (
                                                    <td className="pl-4">
                                                        {this.props.auth.storePlan.id == item.sto_pid ? 
                                                            ('사용중')
                                                        :(moment(item.expired_date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) ?
                                                            ('사용완료') 
                                                        :(item.payment_status == 1) ?
                                                            ('사용대기') 
                                                        :
                                                        ('미결제')}
                                                    </td>
                                                )}
                                            </tr>
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                            {this.onPagination(this.state.histories)}
                        </div>
                    </form>
                </section>

            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {
        pricePackages: state.dataStore.pricePackages,
        auth: state.auth,
        priceExtraService: state.dataStore.priceExtraService,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        getPricePackages: (data) => dispatch(getPricePackages(data)),
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(PaymentHistory);

