import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";
import { hasRole } from '../../utils/entrust/entrust';
import * as routerName from '../../utils/routerName';
import Page403 from '../ErrorPage/page403';
import PaymentManagement from '../../containers/MyProfile/PaymentManagement.js';
import queryString from "query-string";

const UserRoute = ({isAuthenticated, auth, component: Component, layout: Layout,  ...rest}) => {
    if (!isAuthenticated) {
        return <Route
            {...rest}
            render={props => <Redirect to={routerName.SIGN_IN} />}
        />
    };


    let params = queryString.parse(rest.location.search);



    // let params = queryString.parse(location.search);


    if(isAuthenticated && params.id) {
        return <Route
            {...rest}
            render={props => <Redirect to={'share-wireless?id='+params.id} />}
        />
    }

    if (auth.accountErrStatus == 1) { 
        return (
            <Route
                {...rest}
                render={props => <Layout {...props} {...rest}>
                    <PaymentManagement {...props} />
                </Layout>}
            />
           
        )
    };
   
    const role = !rest.role || (rest.role && hasRole(auth.role, rest.role));
    
    if (!role) {
        return (
            <Route
                {...rest}
                render   ={props => <Page403 {...props} />}
            />
        )
    }

    // If have layout
    if(Layout) {

        return (
            <Route
                {...rest}
                render={props => <Layout {...props} {...rest}>
                    <Component {...props} />
                </Layout>}
            />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    )

};


UserRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRoute);
