import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues, change, Field } from 'redux-form';

class WiredNote extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleChange = e => {
      this.props.change('WiredAdviceStep2.note', e.target.value);
  }

  render() {
    const { WiredAdvice } = this.props

    return (
      <div className="form-group">
        <label htmlFor="" className="label-text">메모</label>
        <Field
          name="note" 
          component={() =>
              <textarea
                className="form-control"
                rows="6"
                placeholder="메모"
                name="note"
                onBlur={this.handleChange}
                style={{cursor: 'auto'}}
                defaultValue={WiredAdvice?.WiredAdviceStep2?.note}
              ></textarea>
          }
        />
      </div>
    );
  }
}

WiredNote.propTypes = {
  change: PropTypes.func,
  WiredAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: dispatch(change())
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WiredNote);
