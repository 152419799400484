import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import ConfirmPopup from "../Wireless/ReportSale/ConfirmPopup";
import ResetModal from "../Wireless/Reset/ResetModal";

class OpenTypeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    chooseType = (e,val) => {
        this.props.setOpenType(val,e.target.textContent)
        this.props.openPopup();
    }

    render() {
        let types = this.props.arr.map((item,i) => {
            return i  ? <li className={i+1 == this.props.activeIndex ? "active" : '' } onClick={(e)=>this.chooseType(e,i+1)}>
                {item}
            </li> : ''
        });
        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                contentClassName={''}
                // backdrop={"static"}
                className={'modal-custom-12 modal-dialog-centered modal-additional modal-small'}
            >
                    <div className="modal-header">
                        <h5 className="modal-title">개통유형 </h5>
                        {/* <button type="button" className="close close-modal" onClick={this.props.openPopup}>
                            <img src="/images/icon-close.svg" alt=""/>
                        </button> */}
                    </div>
                    <div className="modal-body mt-1">
                        <ul className="list-additional max-auto">
                            {types}
                        </ul>
                    </div>
            </Modal>
        );
    }
}

export default withRouter(OpenTypeModal);
