import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {priceFormat} from "../../../utils/helper";
import PlanDetails from "./Right/PlanDetails";
import { getDisclosureSubsidy } from '../../../store/actions/plan_information';

class ShareWireLessRight extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      disclosure_price: 0 ,
      disclosure_price_basic_plan : 0,
      disclosure_price_six_plan : 0,
      conversion_price : 0,
      manual_conversion_confirm : 0
    };
  }

  componentDidMount() {
    if (this.props.data.dataPlanNewDateChange) {
      let data = {
        'planID' : this.props.data?.dataPlanNewDateChange?.id ?? null,
        'modelID' : this.props.data?.phone?.id ?? null,
      }        
      this.props.getDisclosureSubsidy(data)
        .then((res) => {
          let disclosurePrice          = 0;
          let disclosurePriceBasicPlan = 0;
          let disclosurePriceSixPlan   = 0;
          let conversionPrice          = 0;
          let manualConversionConfirm  = 0;

          if(res.data != null){
            disclosurePrice          = res.data?.disclosure_price ? res.data.disclosure_price : 0;
            disclosurePriceBasicPlan = res.data?.disclosure_price_basic_plan ? res.data.disclosure_price_basic_plan : 0;
            disclosurePriceSixPlan   = res.data?.disclosure_price_six_plan ? res.data.disclosure_price_six_plan : 0;
            conversionPrice          = res.data?.conversion_price ? res.data.conversion_price : 0;
            manualConversionConfirm  = res.data?.manual_conversion_confirm ? res.data.manual_conversion_confirm : 0;
          }
          
          this.setState({
            disclosure_price            : disclosurePrice,
            disclosure_price_basic_plan : disclosurePriceBasicPlan,
            disclosure_price_six_plan   : disclosurePriceSixPlan,
            conversion_price            : conversionPrice,
            manual_conversion_confirm   : manualConversionConfirm
          })
        })
    }
  }

  render(){
    let {data}                      = this.props;
    let disclosureSubsidyDifference = 0;
    let conversionDefference        = 0;

    if(data.phone.telcomID == 18){
      if(data.disclosureSubsidyType == "six"){
        disclosureSubsidyDifference = this.state.disclosure_price_six_plan ? (+data.disclosureSubsidy - +this.state.disclosure_price_six_plan) : 0;
      }
      else{
        disclosureSubsidyDifference = this.state.disclosure_price_basic_plan ? (+data.disclosureSubsidy - +this.state.disclosure_price_basic_plan) : 0;
      }
    }
    else{
      disclosureSubsidyDifference = +data.disclosureSubsidy - +this.state.disclosure_price;
    }

    disclosureSubsidyDifference = disclosureSubsidyDifference >=0 ? disclosureSubsidyDifference : '';

    if(data.optionFee == 0){
      disclosureSubsidyDifference = '';
      conversionDefference        = '';
    }

    conversionDefference = +data.conversion_price_input - (+this.state.manual_conversion_confirm ? 0 : +this.state.conversion_price);

    return (
      <React.Fragment>
        <div className="top-right">
          <div className="name-item border-bottom-blue">
            <h2>월 납부 요금정보 (A+B)</h2>
          </div>
          <div className="row mt-2 align-items-center">
            <div className="col-md-4 pr-0">
              <p className="label-prices mb-1 text-wrap">당월 예상 금액</p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-md-8">
              <p className="text-right mb-0 prices-number">
                {priceFormat(data?.estimatedAmountMonth)}
                <span>원</span>
              </p>
              {/*<p className="text-right mb-0 text-content">
                (단말 {priceFormat(data?.installmentMonthly)}원 + 요금{priceFormat(data?.moneyRemainingMonth)}<span>원</span>)
              </p>*/}
            </div>
          </div>
          <div className="row mt-2 justify-content-between">
            <div className="col-auto pr-0">
              <p className="label-prices mb-1">월별 청구금액</p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-auto pl-0">
              <p className="text-right mb-0 text-wrap prices-number total-big">
                {priceFormat(data?.amountMonthlyDevice)}
                <span>원</span></p>
            </div>
          </div>

          <PlanDetails
            data={data}
          />

          <div className="row">
            <div className="col-md-7 mt-2 pr-0">
              <p className="label-prices mb-1 text-wrap">요금제 변경시 예상요금 </p>
              <span className="text-content">(VAT 포함)</span>
            </div>
            <div className="col-md-5">
              <p className="text-right mb-0 prices-number">{priceFormat(data?.estimated_rates_changing_plans)}<span>원</span></p>
            </div>
          </div>
          <div className="form-group row mt-2">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금제</label>
            <div className="col-sm-8">
              <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#exampleModal12">
                {
                  data.namePlanNewDateChange
                    ? data.namePlanNewDateChange
                    ? data.namePlanNewDateChange
                    : data.namePlanNewDateChange
                    : '변경할 요금제'
                }
              </button>
            </div>
          </div>
          <div className="form-group mb-1 row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">변경 가능일자</label>
            <div className="col-sm-8">
              <div className="form-date">
                <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-81">
                  {data.planChangeDate != '' && data.planChangeDate != null ? data.planChangeDate :'변경 가능일자'}
                  <img src="/images/icon-calender.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="form-group mt-3 mb-3 row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">공시차액정산금</label>
            <div className="col-sm-8">
              <div className="form-date">
                <button className={"btn btn-input-form mb-0" + (this.state.disclosure_price ? ' btn-prices-wp' : '')} type="button">
                  {/*this.state.disclosure_price ? <React.Fragment>{priceFormat(+data.disclosureSubsidy - +this.state.disclosure_price)}<span>원</span></React.Fragment> : ''*/}
                  {disclosureSubsidyDifference != '' ? <React.Fragment>{priceFormat(disclosureSubsidyDifference)}<span>원</span></React.Fragment> : ''}
                </button>
              </div>
            </div>
          </div>
          <div className="form-group mt-3 mb-3 row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-wrap label-text">전환차액정산금</label>
            <div className="col-sm-8">
              <div className="form-date">
                <button className={"btn btn-input-form mb-0" + (this.state.conversion_price ? ' btn-prices-wp' : '')} type="button">
                  {conversionDefference != '' && conversionDefference >= 0 && +data.conversion_price_input > 0 ? <React.Fragment>{priceFormat(conversionDefference)}<span>원</span></React.Fragment> : ''}
                </button>
              </div>
            </div>
          </div>
          {data.phone.telcomID == 17 && (<div className="total-number pb-2">
            <p className="text-content mb-0" style={{fontSize:13}}>프리미엄 패스 충족 시 차액정산 미부과</p>
            <p className="text-content mb-0" style={{fontSize:13}}> (단, 월정액이 0원인 요금제로 변경 시 위약금 발생)</p>
            <p className="text-content mb-0" style={{fontSize:13}}>181일 이후 프패 면제 예외적용 > 5G: 월정액 4만2천원  </p>
            <p className="text-content mb-0" style={{fontSize:13}}>미만 /3G,LTE :월정액 2만원 미만 변경시, 181일 이후더</p>
            <p className="text-content mb-0" style={{fontSize:13}}>라도, 차액정산금 발생</p>
          </div>)}
          {data.phone.telcomID == 19 && (<div className="total-number pb-2">
            <p className="text-content mb-0" style={{fontSize:13}}>심플코스 충족 시 차액정산 미부과</p>
            <p className="text-content mb-0" style={{fontSize:13}}> (단, 월정액이 0원인 요금제로 변경시 위약금 발생)</p>
            <p className="text-content mb-0" style={{fontSize:13}}>-180일 경과 후, 면제 예외적용 > 5G: 월정액 4만7천원  </p>
            <p className="text-content mb-0" style={{fontSize:13}}>미만/LTE, 3G: 월정액 2만원 미만 변경시 180일 이후</p>
            <p className="text-content mb-0" style={{fontSize:13}}>더라도 차액정산금 발생</p>
          </div>)}
          {data.phone.telcomID == 18 && (<div className="total-number pb-2">
            <p className="text-content mb-0" style={{fontSize:13}}>식스플랜 충족 시 차액정산 미부과</p>
            <p className="text-content mb-0" style={{fontSize:13}}>  (단, 월정액이 0원인 요금제로 변경시 위약금 발생)</p>
            <p className="text-content mb-0" style={{fontSize:13}}>-180일 경과 후, 면제 예외적용 > 5G : 월정액 45,000원미만</p>
            <p className="text-content mb-0" style={{fontSize:13}}>/LTE:월정액26,400원 미만 변경시 180일 이후더라도</p>
            <p className="text-content mb-0" style={{fontSize:13}}>차액정산금 발생</p>
            <p className="text-content mb-0" style={{fontSize:11}}>*변경 후 요금제가 시니어/키즈 대상요금제인 경우 위약금 면제 </p>
            <p className="text-content mb-0" style={{fontSize:11}}>대상요금제 : ①시니어 : 5G시니어B형/C형,LTE시니어33/34</p>
            <p className="text-content mb-0" style={{fontSize:11}}>②키즈:5G키즈29/39 LTE키즈22/29/39</p>
            <p className="text-content mb-0" style={{fontSize:11}}>단, 전환지원금 없는 요금제로 변경 시 전환차액정산금 부과</p>
          </div>)}
          {/*<div className="row align-items-center justify-content-between pt-2 pb-2">
            <div className="col-md-12">
              <div className="name-item">
                <h2>월 납부액 (A+B+C)</h2>
              </div>
            </div>
            <div className="col-auto mt-2 pr-0">
              <p className="label-prices mb-1">월별 청구금액 </p>
              <span className="small-color">(VAT 포함)</span>
            </div>
            <div className="col-auto pl-0">
              <p className="text-right mb-0 prices-number text-wrap total-big">{priceFormat(data?.amountMonthlyTotal)}<span>원</span></p>
            </div>
          </div>*/}
        </div>
        <div className="bottom-right">
          <div className="pt-2">
            <div className="form-group row align-items-center">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">매장명</label>
              <div className="col-sm-8">
                <input type="text" className="form-control h-44" value={data?.saler?.store[0].sto_name} disabled />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">상담사</label>
              <div className="col-sm-8">
                <button className="btn btn-input-form justify-content-between dropdown-toggle mb-0 d-flex" type="button" data-toggle="modal" data-target="#modal-93">
                  {data?.saler?.name}
                </button>
              </div>
            </div>
            <div className="form-group row align-items-center mb-0">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">전화번호</label>
              <div className="col-sm-8">
                <input type="text" className="form-control h-44" value={data?.saler?.store[0].sto_phone_number} disabled />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getDisclosureSubsidy: (data) => dispatch(getDisclosureSubsidy(data)),
    };
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    );

export default compose(
    withConnect,
  )(ShareWireLessRight);
