import React from 'react';
import './PopupListDetailDiscountCard.css';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form'
import { getDiscountCard } from './../store/actions/discount_card';
import {numberWithCommas,priceFormat} from "./../utils/helper";
import queryString from "query-string";
// import $ from 'jquery';
const PUBLIC_FOLDER = process.env.REACT_APP_API_PUBLIC_FOLDER;
class PopupListDetailDiscountCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            discountCard: [],
        }
    }




    componentDidMount() {

        let url = this.props.location.search
        let params = queryString.parse(url);


        let data = {};

        if(params && params.type == "billing_discount_card") {
            data = {
                billing_discount_card: true,
                filter_telcomID : params.telcomID
            }
        }else{
            data = {
                light_and_save_card: true,
                filter_telcomID : params.telcomID
            }
        }


        this.props.discountCard(data).then(res => {
            this.setState({
                discountCard: res
            }, () => console.log(this.state.discountCard, 'discountCard'))
        })
    }

    renderDCC_Discount = data => {
        if(data){
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    return (
                         <div className="card-dc-list-item" key={index}>
                            <p>{item.discount_condition}<br /><b>{item.money_condition}</b> 할인</p>
                        </div>
                    )
                })
            }
        }

        return '';
    }


    renderDCC_DiscountPromotion = (data) => {
        if(data){
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    return (
                        <div className="card-dc-list-item" key={index}>
                            <p>[프로모션] <br/> {item.condition} <br /><b>{item.money}</b> 할인</p>
                        </div>
                    )
                })
            }
        }

        return '';
    }

    limitFont = (font) => {

        if(font.length <= 25) {
            return font;
        }else{
            return font.substr(0,25) + '...';
        }
    }



    render() {
        return (
            <div id="estimate-wrap">
              <section className="estimate-card">
                  <table id="all-card-list" className="info-tb3">
                      <caption>카드명, 기본할인, 추가혜택, 전월실적 포함/미포함 내역, 연회비, 자동이체, 발급센터 관한 KT 라이트 카드 할부 표</caption>
                      <colgroup>
                          <col style={{width: '160px'}} />
                          <col style={{width: '150px'}} />
                          <col style={{width: 'auto'}} />
                          <col style={{width: '220px'}} />
                      </colgroup>
                      <thead>
                          <tr>
                              <th colSpan={4}>
                                  <header>
                                      <div className="estimate-logo-wrap">
                                          <strong><img src="http://mobisell.mobigo.co.kr/img/print/bi.png" alt="MOBISELL" /></strong>
                                          <span><em>Ver</em>2020.06</span>
                                      </div>
                                      <div className="estimate-info-wrap">
                                          <div className="telecom-logo" style={{width: '27px', height: '30px'}}>
                                              <img src="http://mobisell.mobigo.co.kr/img/consult/print_logo/estimate_print_telecom_skt.png" alt="SKT" style={{height: '30px', /*marginLeft: '-0px', */ marginTop: '-0px'}} />
                                          </div>
                                          <h1> 청구할인카드 표</h1>
                                          <span className="desc desc-warning">아래 내용은 카드사 및 제휴사에 따라 변경이 될 수 있으며, <br />할인 혜택 및 상세 제공 조건에 대해서는 카드사 홈페이지 및 통신사 정보를 확인하셔야 됩니다</span>
                                      </div>
                                  </header>
                              </th>
                          </tr>
                          <tr id="all-card-head">
                              <th>카드명</th>
                              <th>기본할인</th>
                              <th>추가혜택 (프로모션)</th>
                              <th>전월실적 포함/미포함 내역</th>
                          </tr>
                      </thead>
                      <tbody id="all-card-list-tbody">
                          {this.state.discountCard.map((item,index) => {
                            return (
                                 <tr className="content-break">
                                  <td colSpan={4}>
                                  <table>
                                      <colgroup>
                                          <col style={{width: '160px'}} />
                                          <col style={{width: '150px'}} />
                                          <col style={{width: 'auto'}} />
                                          <col style={{width: '220px'}} />
                                      </colgroup>
                                      <tbody>
                                          <tr>
                                              <td className="card-img-wrap">
                                                  <p><span className="ic-auto-trans strong">{item.dcc_auto_transac == 1 ? '이체' :'자동이체 미필수'}</span></p>
                                                  <p className="card-img"><img src={item.dcc_image ? PUBLIC_FOLDER+'/storage/discount_card/original/'+item.dcc_image : "/images/card-01.png"} alt="롯데카드 텔로(TELLO) SE" /></p>
                                                  <p><strong> {item.dcc_name}</strong></p>
                                                  <p><br /></p>
                                                  <p><b className="sub-tit">발급센터</b></p>
                                                {item.dcc_center_ars ? <p className="join-info">{item.dcc_center_ars}</p> : ''}
                                                {item.dcc_center_online ?  <p className="join-info link">{item.dcc_center_online}</p> : ''}
                                              </td>
                                              <td className="card-dc-list-wrap">
                                                  <div className="card-dc-list">
                                                    {this.renderDCC_Discount(item.dcc_discount)}
                                                    {this.renderDCC_DiscountPromotion(item.dcc_promotion)}
                                                  </div>
                                              </td>
                                              <td className="text-left">
                                                  <div>*할인은 카드 청구금액 에서 적용 됩니다.</div>
                                                  <div dangerouslySetInnerHTML={{__html: item.dcc_benefit}}/>
                                                  <br/>
                                                  <b className="sub-tit">연회비</b>
                                                    {item.dcc_anual_fee_foreign ? <p>국내 {priceFormat(item.dcc_anual_fee_foreign)} 원</p> : ''}
                                        {item.dcc_anual_fee_internal ? <p>국내 {priceFormat(item.dcc_anual_fee_internal)} 원</p> : ''}
                                              </td>
                                              <td className="card-dc-list-wrap text-left">
                                                  <div className="card-dc-list">
                                                      <div className="card-dc-list-item">
                                                         <p><b className="sub-tit sub-tit-gap"></b> {item.dcc_include ? <div className="text-11" dangerouslySetInnerHTML={{__html: this.limitFont(item.dcc_include)}}/> : '카드사에 문의해 주세요.'}</p>

                                                      </div>
                                                      <div className="card-dc-list-item">
                                                          <p><b className="sub-tit sub-tit-gap">
                                                              미포함
                                                          </b>{item.dcc_not_include ? <div className="text-11" dangerouslySetInnerHTML={{__html: this.limitFont(item.dcc_not_include)}} /> : ''}</p>

                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                              )
                          })}

                      </tbody>
                  </table>
              </section>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        discountCard: (data) => dispatch(getDiscountCard(data)),
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default compose(
    withConnect,
    withReduxForm,
)(PopupListDetailDiscountCard);
