import PropTypes from 'prop-types';
import Moment from 'react-moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import api from '../../utils/api';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { getNotifications, loadmoreDataNotification } from '../../store/actions/dataStore';
import * as routerName from '../../utils/routerName';
import * as helper from '../../utils/helper';
import { isBuffer } from 'lodash';
class NotificationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notice: props.location.state.notice,
            isLike: false,
            nt_id: props.location.state && props.location.state.notice && props.location.state.notice.nt_id || "",
            callback:this.props.location?.state?.callback
        }

    }



    onClickLike = () => {
        this.props.showLoading()
        api.notification.likeNotice(this.state.nt_id).then(res => {
            if (res.data.status == 1) {
                this.setState({
                    notice: {
                        ...this.state.notice,
                        count_like: (this.state.notice.count_like ? parseInt(this.state.notice.count_like) : 0) + 1
                    },
                    isLike: true
                })
            } else {
                this.setState({
                    notice: {
                        ...this.state.notice,
                        count_like: parseInt(this.state.notice.count_like) - 1
                    },
                    isLike: false
                })
            }
            this.props.hideLoading()


        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    onClickDownloadFile = (att_file) => {
        // let data = {
        //     att_file:att_file
        // }
        // api.notification.downloadFile(data).then(res => {
        //     console.log(res)
        // }).catch(err => {
        //     console.log(err)
        // })
    }

    componentDidMount = () => {
        this.props.showLoading()
        api.notification.noticeIncreaseViewer(this.state.nt_id).then(res => {
            if (!!res.data.data.notice && res.data.status == 1) {
                this.setState({
                    notice: res.data.data.notice,
                    isLike: res.data.data.isLike
                })

                this.props.getNotifications();
                this.props.hideLoading();
                // this.props.setTitle(res.data.data.notice.nt_title)
            } else if (res.data.status == 0) {
                this.props.history.push({ pathname: '/' + routerName.HOME })
            }
        }).catch(err => {
            this.props.hideLoading()
            this.props.history.push({ pathname: '/' + routerName.HOME })
            console.log(err)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.props.showLoading()
            let nt_id = this.props.location.state && this.props.location.state.notice && this.props.location.state.notice.nt_id;
            nt_id && api.notification.noticeIncreaseViewer(nt_id).then(res => {
                if (!!res.data.data.notice && res.data.status == 1) {
                    this.props.hideLoading()
                    this.setState({
                        notice: res.data.data.notice,
                        isLike: res.data.data.isLike
                    })
                } else if (res.data.status == 0) {
                    this.props.history.push({ pathname: '/' + routerName.HOME })
                }
            }).catch(err => {
                this.props.hideLoading()
                this.props.history.push({ pathname: '/' + routerName.HOME })
                console.log(err)
            })
        }
    }
    
    onClickCallback = () => {
        if(this.state.callback?.routerName == "") {
            this.props.history.push({
                pathname: routerName.MANAGER_NOTIFICATION,
            })
        } else {
            this.props.history.push({
                pathname: routerName.MANAGER_NOTIFICATION,
                state: { callback:this.state.callback }
            })
        }
        
    }
    

    render() {
        //'embed' code gotten from ckeditor

        return (
            <Fragment >
                <div className="main-wrapper">
                    <section>
                        <div className={"container_nofitication " + (this.state.notice && this.state.notice.nt_title ? 'd-block' :'d-none')}>
                            <div className="box_title">
                                <button className="btn btn-back" onClick={() => this.onClickCallback()}>
                                    <img src={process.env.REACT_APP_API_URL + "images/icon-back.svg"} />
                                </button>
                                <h1>{this.state.notice && this.state.notice.nt_title}</h1>
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                        <div className="sub mr-4">
                                            <i className="far fa-clock"></i>
                                            <span>
                                                <Moment format="YYYY.MM.DD">
                                                    {this.state.notice && this.state.notice.created_at}
                                                </Moment>
                                            </span>
                                        </div>
                                        <div className="sub mr-4">
                                            <i className="far fa-eye"></i> <span>{this.state.notice && this.state.notice.nt_viewer ? this.state.notice.nt_viewer : 0}</span>
                                        </div>
                                        <div className="sub" onClick={() => this.onClickLike()} style={{ cursor: 'pointer' }}>
                                            <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{this.state.notice && this.state.notice.count_like ? this.state.notice.count_like : 0}</span>
                                        </div>
                                    </div>
                                    {this.state.notice && !!this.state.notice.att_file ? (<div className="sub d-flex">
                                        <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({this.state.notice && (this.state.notice.att_size / (1024)).toFixed(2)} KB)</span></p>
                                        <a href={process.env.REACT_APP_API_URL + "api/v1/attachments/download-attachment?att_file=" + this.state.notice.att_file} target="_blank"><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"} alt="" /></a>
                                    </div>) : ('')}
                                </div>
                                
                            </div>
                            <div className="box_content ck-content ck-media__wrapper"
                                dangerouslySetInnerHTML={{ __html: helper.convertIframe(this.state.notice && this.state.notice.nt_content ? this.state.notice.nt_content : '') }}  >
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getNotifications: () => dispatch(getNotifications())
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(NotificationDetail);

