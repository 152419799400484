import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    reduxForm,
    getFormSyncErrors,
    getFormValues,
    change,
} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import {showLoading, hideLoading} from '../../../store/actions/ui';
import moment from 'moment'
import {updateWirelessStep3} from './../../../utils/helper'

class HistoryConsultantWired extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
        }
    }

    modalOpened = () => {
        this.props.showLoading()
        api.advice.getListWirelessConsultant()
            .then(response => {
                this.props.hideLoading()
                this.setState({
                    consultants: response.data.data
                })
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }

    handleSubmit = () => {
        const {selected} = this.state
        this.props.openPopup()
        this.props.showLoading()
        api.advice.getWirelessConsultant(selected)
            .then(response => {
                const {data} = response.data
                const {WiredAdvice: {WiredAdviceStep3}} = this.props
                let WiredAdviceStep3Clone = updateWirelessStep3(WiredAdviceStep3, data)
                let customer = {
                    id: data?.customer_id,
                    name: data?.customer?.name,
                    phone: data?.customer?.phone,
                    note: data?.customer_note,
                }
                let WiredAdviceStep3Default = {
                    welfareDiscount: WiredAdviceStep3Clone.welfareDiscount,
                    promotion: data.plan_promotion || {}
                }

                let WiredAdviceStep1 = {
                    telcomID: data?.telcom.id,
                    status: true,
                    successString: data?.telcom?.image_url?.original,
                    installmentPaymentMethod: data?.telcom?.installment_payment_method
                }
                this.props.change('WiredAdviceStep1', WiredAdviceStep1)

                this.props.hideLoading()
                this.props.change('WiredAdviceStep3Default', WiredAdviceStep3Default)
                this.props.change('id', data.id)
                this.props.change('WiredAdviceStep2.color', data.device_color)
                this.props.change('WiredAdviceStep3', WiredAdviceStep3Clone)
                this.props.change('CreatePotentialCustomer', customer)
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    render() {
        const {consultants} = this.state

        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-xl modal-kh'}
                onOpened={this.modalOpened}
            >
                <div className="modal-content pl-4 pr-4">
                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            최근 상담 이력
                        </h5>
                        <button type="button" className="close close-modal" aria-label="Close"
                                onClick={this.props.openPopup}>
                             <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body modal-counseling-history">
                        <article>
                            <table className="table table-header">
                                <thead>
                                <tr className="bg-purple">
                                    <th className="text-left">통신사</th>
                                    <th className="text-left">상품명</th>
                                    <th className="text-left">요금제</th>
                                    <th className="text-right">즐겨찾기</th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                                <table className="table">
                                <tbody>
                                {consultants.map((consultant, key) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="d-flex">
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        type="radio"
                                                        id={`check-00${key}`}
                                                        name="customRadio"
                                                        className="custom-control-input"
                                                        onChange={this.handleChangeRadio}
                                                        value={consultant.id}
                                                    />
                                                    <label className="custom-control-label ml-3"
                                                           htmlFor={`check-00${key}`}>
                                                        {consultant.telcom_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{consultant.model_name}</td>
                                        <td>{consultant.plan_name}</td>
                                        <td className="text-right">
                                            <b>
                                                {moment(consultant.updated_at).format('YYYY-MM-DD')}
                                            </b>
                                            <span className="color-sliver ml-1">
                        {moment(consultant.updated_at).format('HH:mm:ss')}
                      </span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                </table>
                            </div>
                        </article>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mt-3">
                        <button type="button" className="btn btn-gray pl-5 pr-5" onClick={this.props.openPopup}>
                            취소
                        </button>
                        <button type="button" className="btn btn-submit pl-4 pr-4" onClick={this.handleSubmit}>
                            불러오기
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

HistoryConsultantWired.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    openPopup: PropTypes.func,
    isOpenModal: PropTypes.bool,
    WiredAdvice: PropTypes.object,
    change: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        telcomParents: state.dataStore.telcomProviders.telcomParents,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(HistoryConsultantWired);
