import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";

const Pagination = ({data, onClickPaginationHandle}) => {
    var arrayNumber = [];
    var arrayNumber = [];
    for (let i = 1; i <= Math.ceil(data.total / data.per_page); i++) {
        if (data.current_page == i) {
            if ([1, 2].indexOf(data.current_page) == -1) {
                if (i !== 1) {
                    arrayNumber.push(<li className="page-item" key={1}><a className="page-link" onClick={() => onClickPaginationHandle(1)}>1</a></li>);

                } else {
                    arrayNumber.push(<li className="page-item" key={i - 2}><a className="page-link" onClick={() => onClickPaginationHandle(i - 2)}>{i - 2}</a></li>);
                }
            }

            if (i - 2 > 1) {
                arrayNumber.push(<li className="page-item" key={'...'}><a className="page-link">...</a></li>);
            }


            if ([1].indexOf(data.current_page) == -1) {
                arrayNumber.push(<li className="page-item" key={i - 1}><a className="page-link" onClick={() => onClickPaginationHandle(i - 1)}>{i - 1}</a></li>);
            }

            arrayNumber.push(<li className="page-item active" key={i}><a className="page-link" onClick={() => onClickPaginationHandle(i)}>{i}</a></li>);

            if ((i + 1) < Math.ceil(data.total / data.per_page)) {
                arrayNumber.push(<li className="page-item" key={i + 1}><a className="page-link" onClick={() => onClickPaginationHandle(i + 1)}>{i + 1}</a></li>);
            }

            if ([Math.ceil(data.total / data.per_page) - 1, Math.ceil(data.total / data.per_page) - 2].indexOf(i) == -1 && i !== Math.ceil(data.total / data.per_page)) {
                arrayNumber.push(<li className="page-item" key={'....'}><a className="page-link">...</a></li>);
            }


            if (i !== Math.ceil(data.total / data.per_page)) {
                arrayNumber.push(<li className="page-item" key={Math.ceil(data.total / data.per_page)}><a className="page-link" onClick={() => onClickPaginationHandle(Math.ceil(data.total / data.per_page))}>{Math.ceil(data.total / data.per_page)}</a></li>);
            }
        } else {
            //arrayNumber.push(<li className="page-item"><a className="page-link" >{i}</a></li>);
        }
    }
    if (Math.ceil(data.total / data.per_page) > 1) {
        return (
             <nav aria-label="Page navigation example" className="pagination-notifi">
                   <ul className="pagination justify-content-center">
                            <li className={`page-item ${(data.current_page <= 1)?'disabled':''}`}><a className="page-link" onClick={() => onClickPaginationHandle(Math.ceil(data.current_page - 1))}><i className="fas fa-angle-left"></i></a></li>
                            {arrayNumber.map((item, i) =>
                                item
                            )}
                            <li className={`page-item ${(data.current_page == data.total)?'disabled':''}`}>
                                <a className="page-link" onClick={() => onClickPaginationHandle(Math.ceil(data.current_page + 1))}><i className="fas fa-angle-right"></i></a></li>
                        </ul>
                </nav>
          
        )
    } else {
    	return(<></>);
    }


};

Pagination.propTypes = {
  data: PropTypes.object,
  onClickPaginationHandle: PropTypes.func,
};

export default Pagination;
