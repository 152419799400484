 
import PropTypes from 'prop-types';
import React, { Component, Fragment,useState  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PenaltyInquiryModal from "./PenaltyInquiryModal"

class PenaltyInquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false
        }
    }

    openPopupPenatly = () => this.setState({isOpenModal: !this.state.isOpenModal})



    render() {
        const { handleSubmit, WirelessAdvice } = this.props;

        let welfare=WirelessAdvice.WirelessAdviceStep3.welfareDiscount
        return (

            <div className="col-md-5 pr-0">
                    <button className="btn btn-gray-white w-96 mt-20" type="button" data-toggle="modal" data-target="#modal-10 " onClick={this.openPopupPenatly}>
                    위약금 조회
                </button>
                <PenaltyInquiryModal  isOpenModal={this.state.isOpenModal}  openPopupPenatly={this.openPopupPenatly} />
            </div>
        );
    }
}

PenaltyInquiry.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {

    }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    // withReduxForm,
)(PenaltyInquiry);
