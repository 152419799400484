import React from "react";
import {  Modal,Collapse } from 'reactstrap';
import { updateOptionPrint } from '../../store/actions/auth';
import api from "../../utils/api";
import queryString from "query-string";
import {connect} from "react-redux";
import {compose} from "redux";
import { reduxForm, getFormValues, change } from 'redux-form';
import { FORM_BUILDER_TYPE, WD_CLASS } from './../../utils/constant';
import {totalFeeOption} from './../../utils/helper';
import MoveToPCPopup  from './../../components/Print/MoveToPCPopup';
import PopupInactive from './../../components/PopupInactive';

class PrintPageWired extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      formBuilderByTelcom : [],
      result : {},
      collapse : {},
      data : {
        autoFill : true,
        cardDiscount : true,
        seller : true,
        customer : true,
        storeName : false,
        sellerName : false,
        contact : false,
        address : false,
      },
      target_classification: '0',
      isOpenMoveToPC: false,
      loading: false,
      isOpenPopupDisable: false,
      title: null,
      content: null,
      iconLock: null,
    }
  }

  onToggleMoveToPC = () => {
    this.setState({
      ...this.state,
      isOpenMoveToPC: !this.state.isOpenMoveToPC,
    })
  }


  getFromBuilder = (data) => {
    return api.consultant.getFormBuilder(data);
  }

  modalOpened = () => {
    let data = {
      // filter_telcom : this.props.network,
      // fhb_type : 1,
      // fhb_wd_type : 'wired',


      filter_telcom : this.props.network,
      fhb_type : 1,
      fhb_wd_type : 'wired',
      consultant : this.props.WiredAdvice.id,
    }

    this.getFromBuilder(data).then(res => {
      let formBuilderByTelcom = []
      res.data.data.map((item) => {
        let itemClone = {...item}
        if (!itemClone.condition) {
          formBuilderByTelcom.push(itemClone)
          return null;
        }
        if (itemClone.condition.print_set && itemClone.condition.print_set_device_type) {
          let { classify } = this.props.WiredAdvice.WiredAdviceStep2
          let count = 1

//console.log("이 값은 무엇일까요", classify); //각 회선의 선택 정보 나열
//console.log("이 값은 itemCLone 값이지요", itemClone);

          classify.map((cls, index) => {
            if (
              (itemClone.condition.print_set_device_type.includes(WD_CLASS[index])
                || itemClone.condition.print_set_device_type.includes(WD_CLASS[index] + ''))
              && count < cls.classify.length
            ) { //  && cls.id !== 2
              count = cls.classify.length
            }
          });
          [...Array(count).keys()].map((index) => {
            if (index > 0) {
              itemClone.fhb_name = item.fhb_name + `(${index + 1})`
            }
            formBuilderByTelcom.push({...itemClone})
          })
        } else {
          formBuilderByTelcom.push(itemClone)
        }
        return null;
      })

      this.setState({
        formBuilderByTelcom: formBuilderByTelcom
      },()=>{
        let {formBuilderByTelcom} = this.state;
        if(formBuilderByTelcom.length > 0) {
          let result = {};
          formBuilderByTelcom.map(item => {
            let arrayChild = [];
            item.form_habitat_version.map(child => {
              arrayChild.push({
                fhv_id : child.fhv_id,
                fhv_name : child.fhv_name
              })
              result = {
                ...result,
                [`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name]: arrayChild
              }
            })
          });
          this.setState({
            result
          })
//console.log("데이터 확인을 위한 값",result);          
        }
      })
    })
  }

  onClickCollapse = (e,id) => {
    e.stopPropagation();
    this.setState({
      collapse : {
        ...this.state.collapse,
        [id] : !this.state.collapse[id]
      }
    })
  }

  getResultById = (id,child) => {
    let {result} = this.state;
    if(result[id]){
      let check = true;
      if(result[id].length > 0 ){
        result[id].map((item,index) =>{
          if(item.fhv_id == child.fhv_id){
            check = false
            result[id].splice(index,1)
          }
        })

        if(result[id].length == 0) {
          delete result[id];
        }


        if(check){
          result[id].push({fhv_id : child.fhv_id, fhv_name : child.fhv_name})
        }


      }
    }else{
      result = {
        ...result,
        [id] : [
          {fhv_id : child.fhv_id, fhv_name : child.fhv_name}
        ]
      }
    }
    this.setState({
      result
    })


  }

  onClickCollapseGroup = (e,item) => {
    e.stopPropagation();
    let {result} = this.state;
    if(result[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name]){
      delete result[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name];
      this.setState({
        result
      })
    }else{
      let arrayResult = [];
      if(item.form_habitat_version.length > 0){
        item.form_habitat_version.map(child => {
          arrayResult.push({
            fhv_id : child.fhv_id, fhv_name : child.fhv_name
          })
        })
      }

      if(arrayResult.length > 0) {
        result = {
          ...result,
          [`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name] : arrayResult
        }
      }

      this.setState({
        result
      })
    }
  }

  checkActive = (keyResult,id) => {
    let {result} = this.state;
    let check = false;
    if(result[keyResult] && result[keyResult].length > 0) {
      result[keyResult].map(item => {
        if(item.fhv_id == id) {
          check = true;
        }
      })
      return check;
    }
    return check;
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      data : {
        ...this.state.data,
        [name]: value
      }
    });
  }

  onChangeBasic = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  typePrint = (type) => {
    if(this.props.auth.role == "Member" && this.props.WiredAdvice.id) {
      let data = {
        swec_consultant_id : this.props.WiredAdvice.id,
        swec_type : window.currentDevice == 'web' ? 0 : 1,
        swec_uid : this.props.auth.user.id,
        swec_type_export : type
      }
      api.consultant.createStatisticWiredConsultantExport(data)
    }
  }


  onClickResultPage = () => {
    // data : {
    //     deviceSerialNumber : '',
    //     simSerialNumber : '',
    //     autoFill : false,
    //     cardDiscount : false,
    //     seller : false,
    //     customer : false,
    //     contactPlan : 0,
    //     storeName : false,
    //     sellerName : false,
    //     contact : false,
    //     address : false,
    //     network : {
    //       id : 0,
    //       name : '미설정',
    //       idCode : 0,
    //       nameCode : '인증방법 선택',
    //       nameGiro : ''
    //   },
    // },
    let {data}= this.state;
    var ids = [];
    let result_mapped = this.mapResult()
    if(Object.keys(result_mapped).length > 0) {
      Object.keys(result_mapped).map(item => {
        result_mapped[item].map(child => {
          ids.push(child.fhv_id)
        })
        // ids.push(result_mapped[item].fhv_id)
      })
    }
    let params = {
      'id[]': ids,
      deviceSerialNumber: data.deviceSerialNumber,
      simSerialNumber: data.simSerialNumber,
      consultantId: this.props.consultantId,
      autoFill: data.autoFill,
      cardDiscount: data.cardDiscount,
      division: (!!data.seller || !!data.customer),
      divisionSeller: data.seller,
      divisionCustomer: data.customer,
      contactPlan: data.contactPlan,
      storeName: data.storeName,
      sellerName: data.sellerName,
      contact: data.contact,
      address: data.address
    }

    var href = process.env.REACT_APP_API_URL+'print-page-wireless?' + queryString.stringify(params);

    if(ids.length > 0) {
      this.onClickClosePopup();

      if (window.currentDevice == 'web') {
        window.shareOnWeb(href);
        this.props.toggle();
        setTimeout(() => {
          this.props.toggleParent();
        },200)
      } else {   

        let { WiredAdvice } = this.props;
        let WiredAdviceStep2 = WiredAdvice?.WiredAdviceStep2 || {};
        // debugger
        // return

        let allClassify = [];
        let totalMonthlyFee = 0;
        let deviceNumb = WiredAdviceStep2?.classify?.map((item, index)  => {
          (item?.classify || []).map(subItem => {
            allClassify.push(subItem);

            totalMonthlyFee += +(subItem?.device?.wd_commitment_fee || 0)
            totalMonthlyFee += totalFeeOption(subItem?.options || [])
          })
        })
        totalMonthlyFee = totalMonthlyFee > 0 ? totalMonthlyFee : 0

        let telcomID = WiredAdvice?.WiredAdviceStep1?.telcomID;      
        let info = '';
        let more_info = '';
        if (allClassify.length == 1) {
          info = `${allClassify[0]?.device?.wd_name}`        
          more_info = `${allClassify[0]?.device?.wd_name}`        
        } 
        if (allClassify.length > 1) {
          info = `${allClassify[0]?.device?.wd_name} </br> 외 ${allClassify.length - 1 } 건 `
          more_info = `${allClassify[0]?.device?.wd_name} </br> 외 ${allClassify.length - 1 } 건 `
        } 

        let dataPrint = {
          'uid': this.props.auth?.user?.id,
          'print_type': FORM_BUILDER_TYPE.BUILDER_WIRED,
          'telcomID': telcomID,
          'info': info,
          'more_info': more_info,
          'total': totalMonthlyFee,
          'link': href,
        }
        // debugger
        // return
        
        this.setState({
          ...this.state,
          loading: true
        }, () => {
          api.appPrint.create(dataPrint).then(res => {
            if (res?.data?.data) {
              // this.onToggleMoveToPC();
              this.setState({
                ...this.state,
                isOpenMoveToPC: true,
                loading: false
              }, () => {
              })
            }
          })
        })


      }

      this.typePrint(0);
    }else{
      alert('출력할 서식지를 1개 이상 선택해 주세요.')
    }
  }

  mapResult = () => {
    const {result, formBuilderByTelcom} = this.state
    let ids = [];
    formBuilderByTelcom.length > 0 && formBuilderByTelcom.map((item,index) => {
      if (item.condition) {
        if (
          item.condition.target_classification && !item.condition.target_classification.includes(this.state.target_classification)
        ) {
          return null;
        }
      }
      ids.push(`${item.fhb_sort}-${item.fhb_id}`)
    })
    let a = {}
    Object.keys(result).map((id) => {
      if (ids.includes(id.split('_')[0])) {
        a[id] = result[id]
      }
    })
    return a
  }

  onClickClosePopup = (e) => {
      let data = {
          ...this.props.auth.user?.option_print,
          print_page_wired: {
            target_classification:this.state.target_classification,
            autoFill: this.state.data.autoFill,
            cardDiscount:this.state.data.cardDiscount,
            seller:this.state.data.seller,
            customer:this.state.data.customer, 
            storeName:this.state.data.storeName,
            sellerName:this.state.data.sellerName,
            contact:this.state.data.contact,
            address:this.state.data.address,
          }
      }

      api.global.saveOptionPrint(data).then(res => {
          this.props.updateOptionPrint(res.data?.data?.option_print)
      }).catch(err => {
      })
      this.props.toggle();
  }

  componentWillReceiveProps = (newProps) => {    
      this.setStateOptionPrint(newProps.auth.user?.option_print?.print_page_wired)
  }

  setStateOptionPrint = (data) => {
      if(data) {
          this.setState({
              target_classification:data.target_classification,
              data:{
                ...this.data, 
                autoFill: data.autoFill,
                cardDiscount:data.cardDiscount,
                seller:data.seller,
                customer:data.customer, 
                storeName:data.storeName,
                sellerName:data.sellerName,
                contact:data.contact,
                address:data.address,
              }
          })
      }
  }

  openPopupDisable = (state) => {
    var ids = [];
    let result_mapped = this.mapResult()
    if(Object.keys(result_mapped).length > 0) {
      Object.keys(result_mapped).map(item => {
        result_mapped[item].map(child => {
          ids.push(child.fhv_id)
        })
        // ids.push(result_mapped[item].fhv_id)
      })
    }

    if (ids.length == 0) {
      alert('출력할 서식지를 1개 이상 선택해 주세요.')
      return
    }

    if (state) {
      this.setState({
        title: '실제 개통에 사용하지 마십시오.',
        content: `
          베타테스트 기간입니다.<br>
          테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
          서류 미비나 금액 오류 등이 발생할 수 있습니다.
        `
      })
    }
    this.setState({
      isOpenPopupDisable: true
    })
  }

  closePopupDisableHandle = () => {
    this.setState({
      isOpenPopupDisable: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          iconLock: null
        })
      }, 500)
    })
  }

  modelPrintClose = () => {
    this.setState({
      title: null,
      content: null
    })
  }

  render() {
    let {formBuilderByTelcom,result,collapse,data} = this.state
    return(
      <React.Fragment>

      <Modal
        isOpen={this.props.isOpen}
        toggle={this.onClickClosePopup}
        className={'modal-dialog-centered modal_customd_09'}
        onOpened={this.modalOpened}
      >
        <div className="px-5">
          <div className="modal-header">
            <h5 className="modal-title">서식지 출력</h5>
            <button type="button" className="close close-modal" onClick={this.onClickClosePopup}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body p-0">
            <div className="form-modal-output">
              <div className="form-group row mb-0 form_group_custom">
                <label className="label-text mb-0 col-sm-3">출력유형</label>
                <div className="col-sm-8">
                  <div className="group-radio">
                    <div className="custom-control custom-radio content_center">
                      <input
                        type="radio"
                        id="target_classification0"
                        name="target_classification"
                        className="custom-control-input"
                        value={'0'}
                        checked={this.state.target_classification === '0' ? true : false}
                        onChange={this.onChangeBasic} />
                      <label className="custom-control-label" htmlFor="target_classification0">성인</label>
                    </div>
                    <div className="custom-control custom-radio content_center">
                      <input
                        type="radio"
                        id="target_classification1"
                        name="target_classification"
                        className="custom-control-input"
                        value={'1'}
                        checked={this.state.target_classification === '1' ? true : false}
                        onChange={this.onChangeBasic} />
                      <label className="custom-control-label" htmlFor="target_classification1">청소년</label>
                    </div>
                  </div>
                </div>
              </div>
              <h2>
                출력 상세 설정 및 선택
              </h2>

              <div className="accordion-status h-202">
                <div className="accordion" id="accordionExample">
                  {formBuilderByTelcom.length > 0 && formBuilderByTelcom.map((item,index) => {
                    if (item.condition) {
                      if (
                        item.condition.target_classification && !item.condition.target_classification.includes(this.state.target_classification)
                      ) {
                        return null;
                      }
                    }

                    return(
                      <div className={`card ${result[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name] ? 'active-card' :''}`} key={index}>
                        <div className="card-header p-0">
                          <h2 className="mb-0 p-0">
                            <button className={`btn btn-block text-left ${result[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name] ? '' :'collapsed'}`}   //
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne1"
                                    aria-expanded="false"
                                    aria-controls="collapseOne1"
                                    //onClick={()=>this.onClickCollapse(`${item.fhb_sort}-${item.fhb_id}`)}
                                    onClick={(e)=>this.onClickCollapseGroup(e,item)}
                            >
                              <i className="fas fa-check"
                                 onClick={(e)=>this.onClickCollapseGroup(e,item)}

                              />
                              {item.fhb_name}
                              <span className="img-collapse"
                                    onClick={(e)=>this.onClickCollapse(e,`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name)}
                              >
                                        {collapse[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name]
                                          ? <img
                                            src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close-01.svg`}
                                            alt=""

                                          />
                                          : <img
                                            src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close.svg`}
                                            alt=""
                                            // onClick={(e)=>this.onClickCollapse(e,`${item.fhb_sort}-${item.fhb_id}`)}
                                          />}


                                      </span>
                            </button>
                          </h2>
                        </div>

                        {item.form_habitat_version.length > 0 &&
                        <Collapse isOpen={collapse[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name]}>
                          <div className="card-body p-0">
                            <ul className="list-status bg-white">

                              {item.form_habitat_version.map((child, i) => {
                                return (
                                  <li
                                    className={`item-status ${result[`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name] && this.checkActive(`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name,child.fhv_id) ? 'active' : ''}`}
                                    key={i}
                                    onClick={()=>this.getResultById(`${item.fhb_sort}-${item.fhb_id}` + '_' + item.fhb_name,child)}
                                  >
                                    <i className="fas fa-check"/>
                                    {child.fhv_name}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </Collapse>
                        }
                      </div>
                    )
                  })}
                </div>
              </div>




              <div className="group-check group_check_custom">
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAu1226"
                      name={'autoFill'}
                      onChange={this.onChange}
                      checked={data.autoFill}
                    />
                    <label className="custom-control-label" htmlFor="customControlAu1226">자동기입</label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro2112"
                      name={'cardDiscount'}
                      onChange={this.onChange}
                      checked={data.cardDiscount}
                    />
                    <label className="custom-control-label" htmlFor="customContro2112"><span>카드할인 | </span> 형광펜</label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro12312"
                      name={'seller'}
                      onChange={this.onChange}
                      checked={data.seller}
                    />
                    <label className="custom-control-label" htmlFor="customContro12312">판매자 </label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro12412"
                      name={'customer'}
                      onChange={this.onChange}
                      checked={data.customer}
                    />
                    <label className="custom-control-label" htmlFor="customContro12412">고객 </label>
                  </div>
                </div>
              </div>
              <h2>
                매장정보
              </h2>
              <div className="group-check group_check_2">
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAu1227"
                      name={'storeName'}
                      checked={data.storeName}
                      onChange={this.onChange}
                    />
                    <label className="custom-control-label" htmlFor="customControlAu1227">매장명</label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro2118"
                      name={'sellerName'}
                      checked={data.sellerName}
                      onChange={this.onChange}
                    />
                    <label className="custom-control-label" htmlFor="customContro2118">판매자</label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro12319"
                      name={'contact'}
                      checked={data.contact}
                      onChange={this.onChange}
                    />
                    <label className="custom-control-label" htmlFor="customContro12319">연락처
                    </label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customContro1245"
                      name={'address'}
                      value={data.address}
                      onChange={this.onChange}
                      checked={data.address}
                    />
                    <label className="custom-control-label" htmlFor="customContro1245">주소
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button
              type="button"
              className="btn btn-gray pl-5 pr-5"
              onClick={this.onClickClosePopup}
            >취소</button>
            <button 
              type="button"
              className="btn btn-submit pl-5 pr-5"
              // onClick={() => this.openPopupDisable(true)}
              onClick={this.onClickResultPage}
              disabled={this.state.loading}
            >
                출력
              </button>
          </div>
        </div>
      </Modal>

      <MoveToPCPopup
        isOpen = {this.state.isOpenMoveToPC}
        toggle = {this.onToggleMoveToPC}
      />

      <PopupInactive 
        isOpenModal={this.state.isOpenPopupDisable} 
        closePopupDisable={this.closePopupDisableHandle}
        title={this.state.title || null}
        content={this.state.content || null}
        callback={this.onClickResultPage}
        onModelClosed={this.modelPrintClose}
        iconLock={this.state.iconLock}
      />

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth : state.auth,
    WiredAdvice: getFormValues('WiredAdvice')(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
      updateOptionPrint: (data) => dispatch(updateOptionPrint(data)),
  };
};


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
)(PrintPageWired);
