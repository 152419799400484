import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ContentRule from "../../containers/Auth/SingUpComponent/content-rules";
import { showModal, changeModalTitle, changeModalContent, hideModal } from '../../store/actions/modal';
import Contact from './../../components/Contact/Contact'
import AppPrintListPopup from './../Print/AppPrintListPopup';
import ConfirmDeletePopup from './../Print/ConfirmDeletePopup';
import {priceFormat, getPrintType, checkBlockFeature} from './../../utils/helper';
import api from "../../utils/api";
import PopupInactive from '../PopupInactive';


const title = {
    AD_REQUEST: '광고문의',
    // PROPOSE_THE_COOPERATION: '제휴제안',
    PROPOSE_THE_COOPERATION: '제휴제안 문의 ',
    CONTENT_COMPLAINTS: '불만/오류 문의',
    PRINT_FROM_APP: 'APP 전송서식 출력',
}

class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenPopupDisable: false,
            isOpenPrintList: false,
            isOpenConfirm: false,
            printList: [],
            itemDelete:{}
        }
    }

    openPopupDisable = (e) => {
        e.preventDefault();
        this.setState({
            isOpenPopupDisable: true
        })
    }
    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false
        })
    }

    popupShowServiceRules = (data) => {
        let xhtml = (
            <Fragment>
                <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title">정보 및 약관</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.hideModal}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className=" content-rules mt-1 content-product modal-mobile-insurance " >
                        <ContentRule 
                        data={data}/>
                    </div>
                </div>

            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-rules-custom'
        }

        this.props.showModal(dataModal);
    }

    openPopupContact = (titleKey) => {
        let xhtml = (
            <Fragment>
                <Contact
                    component="form"
                    type={titleKey}
                />
            </Fragment>
        );

        let html_footer = (
            <Fragment>
                <Contact
                    component="submit"
                    type={titleKey}
                />
            </Fragment>
        );

        let dataModal = {
            component: xhtml,
            html_footer: html_footer,
            class_n: 'modal-dialog-centered modal-dialog-scrollable add-request',
            html_title: title[titleKey],
            close: true,
            backdrop: 'static',
        }

        this.props.showModal(dataModal);
    }

    onTogglePrintList = () => {
        this.setState({
            isOpenPrintList: !this.state.isOpenPrintList,
        })
    }

    onToggleConfirm = () => {
        this.setState({
            isOpenConfirm: !this.state.isOpenConfirm,
        })
    }

    getAppPrintList = () => {
        return api.appPrint.getList().then(res => {
            if (res?.data?.data) {
                this.setState({
                    ...this.state,
                    printList: res.data.data
                }, () => {
                })
            }
        })
    }

    showAppPrintList = () => {
        // this.getAppPrintList().then(res => {
        //     this.onTogglePrintList();
        // })
        this.setState({
            ...this.state,
            printList: [],
        }, () => {
            this.getAppPrintList();
            this.onTogglePrintList();
        })
    }

    renderAppPrintList = () => {   
        let xhtml = (this.state.printList || []).map((item, index) => {
            return (
                <tr key={index}>
                    <td width="12%">
                        {item.created_at}
                    </td>
                    <td width="15%">
                        {getPrintType(parseInt(item.print_type))}
                    </td>
                    <td width="18%">
                        {item.telcom_name}
                    </td>
                    {/* <td width="30%"  dangerouslySetInnerHTML={{__html: item.more_info }}>
                    </td> */}
                    <td width="30%"  dangerouslySetInnerHTML={{__html: item.info }}>
                    </td>
                    <td width="15%">
                        {item.total && `${ priceFormat(item.total)} won`} 
                    </td>
                    <td width="15%">
                        <div className="group-button">
                            <button className="btn" onClick={()=> this.printPage(item)}>
                                <img src="/images/icoin-print04.svg" alt="" />
                            </button>
                            <button className="btn" type="button" onClick={()=> this.showConfirmDelete(item)}>
                                <img src="/images/delete-black.svg" alt="" />
                            </button>
                        </div>
                    </td>
                </tr>
            )
        });
        return xhtml
    }

    showConfirmDelete = (item) => {
        this.setState({
            ...this.state,
            isOpenConfirm: true,
            itemDelete: item
        }, () => {
        })
    }

    deletePrint = () => {
        this.onToggleConfirm();
        // call api del
        api.appPrint.delete(this.state.itemDelete?.id).then(res => {
            if (res?.data?.data) {
                this.getAppPrintList();
            }
        })        
    }

    printPage = (data) => {
        let { WirelessAdvice} = this.props;
        WirelessAdvice = {
            'WirelessAdviceStep1' : {'telcomID': 17 , 'telcomName': 'Test'}
        }

        console.log(data, 'data_link');
        window.shareOnWeb(data.link);
    }

    deleteAppPrintNotToday = (ids) => {
        if(ids.length > 0) {
            api.appPrint.deleteNotToday(ids).catch((err) => {
                console.log(err)
            })
        }
    }

    componentDidMount() {
        const {user} = this.props;

        if(user) {
            api.appPrint.getListNotToday({userID: user.id}).then(res => {
                if (res?.data?.data) {
                    let ids = res?.data?.data.map(i => i.id)
                    this.deleteAppPrintNotToday(ids)
                }

            })
        }
    }


    render() {
        return (
            <Fragment>
                <footer className="pt-4 pb-4">
                    <ul className="list-footer">
                        <li>
                            <a onClick={() => this.popupShowServiceRules(1)}>
                                이용약관 <span>|</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => this.popupShowServiceRules(2)}>
                                개인정보취급방침 <span>|</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={checkBlockFeature()
                                ? this.openPopupDisable
                                : (() => this.openPopupContact('AD_REQUEST'))}>
                                {title.AD_REQUEST} <span>|</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={checkBlockFeature()
                                ? this.openPopupDisable
                                : (() => this.openPopupContact('PROPOSE_THE_COOPERATION'))}>
                                {title.PROPOSE_THE_COOPERATION}
                                <span>|</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={checkBlockFeature()
                                ? this.openPopupDisable
                                : (() => this.openPopupContact('CONTENT_COMPLAINTS'))}>
                                {title.CONTENT_COMPLAINTS}
                            </a>
                        </li>
                        {
                            window.currentDevice == 'web' && (
                                <li className="button-app">
                                <a className="btn" onClick={() => this.showAppPrintList()}>
                                    {title.PRINT_FROM_APP}
                                </a>
                            </li>
                            )
                        }
                    </ul>
                </footer>

                <AppPrintListPopup
                    isOpen = {this.state.isOpenPrintList}
                    toggle = {this.onTogglePrintList}
                    reUpdatePrintList = {this.getAppPrintList}
                    printList ={this.state.printList}
                >
                    {this.renderAppPrintList()}
                </AppPrintListPopup>


                <ConfirmDeletePopup
                    isOpen = {this.state.isOpenConfirm}
                    toggle = {this.onToggleConfirm}
                    deletePrint = {this.deletePrint}
                />

                <PopupInactive 
                    isOpenModal={this.state.isOpenPopupDisable} 
                    closePopupDisable={this.closePopupDisableHandle}
                    content={`빠른 시일내에 준비하여 찾아뵙겠습니다.<br />각종 문의는 채팅으로 접수해주세요.`}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    
    return {
        user: state.auth.user
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(Footer);

