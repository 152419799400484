import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { bindActionCreators, compose } from 'redux';
import * as routerName from '../../utils/routerName';
import * as constant from '../../utils/constant';
import Menu from './Menu';
import { Link } from "react-router-dom";
import { logoutAction } from '../../store/actions/auth';
import { getPricePackages, getPriceDefault, getNotifications, loadmoreDataNotification } from '../../store/actions/dataStore';
import { showLoading, hideLoading, showSidebar, hideSidebar } from '../../store/actions/ui';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import { permissionsMenu } from '../../utils/helper';
import * as helper from '../../utils/helper';
import api from '../../utils/api.js';
import {isMobile} from 'react-device-detect';

import { ReactComponent as Logo } from './../../assets/images/Logo.svg';
import { ReactComponent as MenuIcon } from './../../assets/images/menu.svg';
import { ReactComponent as Notifi } from './../../assets/images/Notification.svg';
import { ReactComponent as Profile1 } from './../../assets/images/profile.svg';
import { ReactComponent as Profile2 } from './../../assets/images/thanhtoan.svg';
import { ReactComponent as Profile3 } from './../../assets/images/seller.svg';
import { ReactComponent as Profile4 } from './../../assets/images/coution.svg';
import { ReactComponent as Profile5 } from './../../assets/images/logout.svg';
import PopupUserBetaFree from '../PopupUserBetaFree';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSearchNotification: {
                page: 1
            },
            mouseEnterUser: false,
            mouseEnterNotification: false,
            isModalUseBetaFree: false,
        }
        this.wrapperUserRef = React.createRef();
        this.wrapperNotificationRef = React.createRef();
    }
    componentDidMount() {
        // let data = {
        //     per_page: 10000
        // }
        // this.props.showLoading();
        this.props.getPriceDefault().then(res => {
            this.props.hideLoading();

        }).catch((e) => {
        })
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onClickLogout = () => {
        let fb_token = '';
        if (localStorage.getItem("device_token")) {
            fb_token = localStorage.getItem("device_token")
        } else {
            fb_token = localStorage.getItem("fb_token")
        }
        let data = {
            fb_token: fb_token
        }
        this.props.showLoading()
        this.props.logoutAction(data).then(res => {
            (function l() {
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
                s.charset = 'UTF-8';
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                const mesBoxEl = document.querySelectorAll('[data-ch-testid]')
                for(const el of mesBoxEl){
                    el.remove()
                }
              })()
            this.props.hideLoading()
        }).catch(err => {
            (function l() {
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
                s.charset = 'UTF-8';
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                const mesBoxEl = document.querySelectorAll('[data-ch-testid]')
                for(const el of mesBoxEl){
                    el.remove()
                }
              })()
            this.props.hideLoading()
        })
    }

    loadMoreNotifications = () => {
        this.setState({
            dataSearchNotification: {
                page: this.state.dataSearchNotification.page + 1
            }
        }, () => {
            let data = {
                page: this.state.dataSearchNotification.page
            }
            api.global.getNotifications(data).then(res => {
                this.props.loadmoreDataNotification(res.data.data)
            }).catch(err => {
            })
        })
    }

    handleToggleUser = (type, mouseEnterUser) => {
        // neu mobile thi an su kien click
        if (isMobile && type === 'click') {
            this.setState({
                mouseEnterUser
            })
            return
        }
        // neu desktop thi an su kien hover
        if (!isMobile && type === 'mouse') {
            this.setState({
                mouseEnterUser
            })
            return
        }
    }

    closeToggleUser = () => {
        this.setState({
            mouseEnterUser: false
        })
    }

    handleClickOutside = (event) => {
        if (this.wrapperUserRef && this.wrapperUserRef.current && !this.wrapperUserRef.current.contains(event.target)) {
            this.setState({
                mouseEnterUser: false
            })
        }
        if (this.wrapperNotificationRef && this.wrapperNotificationRef.current && !this.wrapperNotificationRef.current.contains(event.target)) {
            this.setState({
                mouseEnterNotification: false
            })
        }
    }

    handleToggleNotification = (type, mouseEnterNotification) => {
        // neu mobile thi an su kien click
        if (isMobile && type === 'click') {
            this.setState({
                mouseEnterNotification
            })
            return
        }
        // neu desktop thi an su kien hover
        if (!isMobile && type === 'mouse') {
            this.setState({
                mouseEnterNotification
            })
            return
        }
    }

    closeToggleNotification = () => {
        this.setState({
            mouseEnterNotification: false
        })
    }

    onClickRedrictDetail = (item, url) => {
        let data = {
            ...item,
            callback: {
                routerName: routerName.HOME,
                ...this.state
            }
        }
        this.props.history.push({
            pathname: url,
            key: Math.random(),
            state: data
        })
    }

    popupUseBetaFree = () => {
        this.setState({
            isModalUseBetaFree: true
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
            isModalUseBetaFree: false
        })
    }

    render() {
        return (
            <header className="header-container">
                <div className="top-header row">
                    <div className="left-header col-4">
                        {this.props.isAuthenticated && (
                            <div className="button-menu">
                                <button className="btn btn-menu" onClick={this.props.showSidebar}>
                                <MenuIcon />
                                </button>
                            </div>
                        )}

                        <div className="logo">
                            <a onClick={() => this.props.history.push({ pathname: '/' + routerName.HOME })}>
                                <Logo />
                            </a>
                        </div>
                    </div>
                    {/* title page */}
                    <div className="title-header col-4">
                        <h1 className="title-page">
                            {this.props?.title || ""}
                            {/*{this.props.match.path=="/history-buy-product"?*/}
                            {/*'판매현황':(this.props.match.path=="/settlement"?'판매 정산 현황':*/}
                            {/*(this.props.match.path=="/transaction-point"?'거래처 관리': (this.props.match.path=="/create-report-sale"?'판매일보 작성': '')))}*/}
                        </h1>
                    </div>
                    <div className="right-header col-4">
                        {/*<p className={'mb-0 mr-4'} style={{color:'red',fontSize:'14px'}}>시범 운영 중입니다</p>*/}
                        {/*<p className={'mb-0 mr-1 custom-alert-beta'} style={{color:'red',fontSize:'13px',textAlign:'right'}}>베타 테스트 기간입니다.<br/>
                            요금상담 및 서식지에 오류가 있을 수 있으니<br/>
                            실제 판매상담용으로는 이용 자제 부탁드립니다.</p>*/}
                        {this.props.isAuthenticated && (this.props.auth.role != 'Counselor') && (
                            <div
                                className={`notification${this.state.mouseEnterNotification ? ' active' : ''}`}
                                onMouseLeave={() => this.handleToggleNotification('mouse', false)}
                                ref={this.wrapperNotificationRef}>
                                <div
                                    className="btn bt-notification"
                                    onMouseEnter={() => this.handleToggleNotification('mouse', true)}
                                    onClick={() => this.handleToggleNotification('click', !this.state.mouseEnterNotification)}>
                                    <Notifi />
                                    <span>{this.props.count_ntf ? this.props.count_ntf : 0}</span>
                                </div>
                                <ul className="list-notification">
                                    <h4 className="title">
                                        공지 알림
                                    </h4>

                                    {this.props.notifications && this.props.notifications.data.map((item, i) =>
                                        <Fragment key={i}>
                                            {item.ntf_type == constant.NTF_TYPE_NOTICE ? (
                                                <li
                                                    className={item.pivot.status == 0 ? 'not-read' : 'readed'}
                                                    onClick={() => {
                                                        this.handleToggleNotification('click', false);
                                                        this.onClickRedrictDetail({ notice: { nt_id: item.ntf_table_id } }, routerName.NOTIFICATION_DETAIL)
                                                        // return this.props.history.push({
                                                        //     pathname: routerName.NOTIFICATION_DETAIL,
                                                        //     key: Math.random(),
                                                        //     state: { notice: { nt_id: item.ntf_table_id } }
                                                        // })
                                                    }}
                                                >
                                                    <a>
                                                        <div className="icon">
                                                            <img src={process.env.REACT_APP_HOSTNAME +  "/images/icon-thongbao-02.svg"} alt=""/>
                                                        </div>
                                                        {item.ntf_title}
                                                    </a>
                                                    <div className="time">
                                                        <p>
                                                            <span>공지사항</span> | <span>
                                                                <Moment format="YYYY-MM-DD HH:mm">
                                                                    {item.created_at}
                                                                </Moment> </span>
                                                        </p>
                                                    </div>
                                                </li>
                                            ) : (item.ntf_type == constant.NTF_TYPE_DOCUMENT) ?
                                                    (<li
                                                        className={item.pivot.status == 0 ? 'not-read' : 'readed'}
                                                        onClick={() => {
                                                            this.handleToggleNotification('click', false);
                                                            this.onClickRedrictDetail({ document: { d_id: item.ntf_table_id } }, routerName.SALES_DOCUMENT_DETAIL)
                                                            // return this.props.history.push({
                                                            //     pathname: routerName.SALES_DOCUMENT_DETAIL,
                                                            //     key: Math.random(),
                                                            //     state: { document: { d_id: item.ntf_table_id } }
                                                            // })
                                                        }}
                                                    >
                                                        <a >
                                                            <div className="icon">
                                                                <img src={process.env.REACT_APP_HOSTNAME +  "/images/icon-note.svg"} alt=""/>
                                                            </div>
                                                            {item.ntf_title}
                                                        </a>
                                                        <div className="time">
                                                            <p>
                                                                <span>판매 도우미 자료 </span> | <span>
                                                                    <Moment format="YYYY-MM-DD HH:mm">
                                                                        {item.created_at}
                                                                    </Moment> </span>
                                                            </p>
                                                        </div>
                                                    </li>)
                                                    : (<li
                                                        className={item.pivot.status == 0 ? 'not-read' : 'readed'}
                                                        onClick={() => {
                                                            this.handleToggleNotification('click', false);
                                                            this.props.history.push({
                                                            pathname: routerName.MY_COUPON
                                                            })
                                                        }}
                                                    >
                                                        <a>
                                                            <div className="icon">
                                                                <img src={process.env.REACT_APP_HOSTNAME +  "/images/happy.svg"} alt=""/>
                                                            </div>
                                                            할인쿠폰이 발급되었습니다!
                                                        </a>
                                                        <div className="time">
                                                            <p>
                                                                <span>쿠폰</span> | <span>
                                                                    <Moment format="YYYY-MM-DD HH:mm">
                                                                        {item.created_at}
                                                                    </Moment> </span>
                                                            </p>
                                                        </div>
                                                    </li>)}
                                       </Fragment>
                                    )}

                                    <div className="view-all">
                                        <a onClick={() => {
                                            this.loadMoreNotifications()
                                            this.handleToggleNotification('mouse', true)
                                        }}>더 보기</a>
                                    </div>
                                </ul>
                            </div>
                        )}

                        {this.props.isAuthenticated && (
                            <div
                                className={`user-info${this.state.mouseEnterUser ? ' active' : ''}`}
                                onMouseLeave={() => this.handleToggleUser('mouse', false)}
                                ref={this.wrapperUserRef}>
                                <div
                                    className="avatar-user"
                                    onMouseEnter={() => this.handleToggleUser('mouse', true)}
                                    onClick={() => this.handleToggleUser('click', !this.state.mouseEnterUser)}>
                                    {/*{!this.props.auth.user.avatar ? (*/}
                                        {/*<div className="info_profile">*/}
                                            {/*<div className="box_info">*/}
                                                {/*<div className="avatar">*/}

                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*) : (<img src={this.props.auth.user.avatar_url && this.props.auth.user.avatar_url.thumbnail ? this.props.auth.user.avatar_url.thumbnail : '/images/avatar-01.png'}*/}
                                              {/*onError={(e) => {*/}
                                                  {/*e.target.src = '/images/avatar-01.png' // some replacement image*/}
                                              {/*}}*/}
                                              {/*alt="" />)}*/}
                                    <img src={this.props.auth.user.avatar_url && this.props.auth.user.avatar_url.thumbnail ? this.props.auth.user.avatar_url.thumbnail : '/images/avatar-01.png'}
                                         onError={(e) => {
                                             e.target.src = '/images/avatar-01.png' // some replacement image
                                         }}
                                         alt="" />
                                </div>
                                {/*  */}

                                <div className="list-info">
                                    <div className="top">
                                        <div className="top-left">
                                            <img src={this.props.auth.user.avatar_url && this.props.auth.user.avatar_url.thumbnail ? this.props.auth.user.avatar_url.thumbnail : '/images/avatar-01.png'}
                                                 alt=""
                                                 onError={(e) => {
                                                     e.target.src = '/images/avatar-01.png' // some replacement image 
                                                 }}
                                            />
                                        </div>
                                        <div className="top-right">
                                            <h2>{this.props.auth ? this.props.auth.user.name : ''}</h2>
                                            {
                                                this.props.auth.role != 'Admin' && (
                                                    <Fragment>
                                                        <p
                                                            className={"cursor-pointer"}
                                                            onClick={() => {
                                                                if (process.env.REACT_APP_BLOCK_FEATURE === 'true') {
                                                                    this.popupUseBetaFree()
                                                                } else {
                                                                    this.props.history.push({ pathname: '/' + routerName.PAYMENT_HISTORY })
                                                                    this.handleToggleUser('click', false)
                                                                }
                                                            }}>
                                                            {this.props.auth?.storePlan?.price_package?.pp_name}
                                                            {/*(this.props.auth.storePlan?.add_amount_free_time !== null || this.props.auth.storePlan?.add_amount_free_time != 0) ? ' (사용료 면제)' : (this.props.auth.storePlan.isFree !== '0') ? ' (사용료 면제)' : ''*/}
                                                        </p>

                                                        {/*flow 6936 : hide*/}
                                                        {/*<p*/}
                                                            {/*className={"cursor-pointer"}*/}
                                                            {/*onClick={() => {*/}
                                                                {/*// this.props.history.push({ pathname: '/' + routerName.PAYMENT_HISTORY })*/}
                                                                {/*// this.handleToggleUser('click', false)*/}
                                                                {/*this.popupUseBetaFree()*/}
                                                            {/*}}>*/}
                                                            {/*(<Moment format=" YYYY-MM-DD ~">*/}
                                                                {/*{this.props.auth?.storePlan?.purchase_date}*/}
                                                            {/*</Moment>*/}
                                                            {/**/}
                                                            {/*<Moment format=" YYYY-MM-DD ">*/}
                                                                {/*{this.props.auth?.storePlan?.expired_date}*/}
                                                            {/*</Moment>)*/}
                                                        {/*</p>*/}
                                                    </Fragment>
                                                )
                                            }

                                            <span>ID {this.props.auth && this.props.auth.user.username}</span>
                                        </div>
                                    </div>
                                    <ul className={"list-item "} onClick={() => {this.handleToggleUser('click', false); this.closeToggleUser()}}>
                                        <li className={"li-item " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                            <a onClick={() => this.props.history.push({ pathname: '/' + routerName.MY_PROFILE })}>
                                                <span className="icon">
                                                    <Profile1 />
                                                </span>
                                                나의 프로필
                                            </a>
                                        </li>
                                        <li className={"li-item " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                            <a onClick={() => this.props.history.push({ pathname: '/' + routerName.PAYMENT_HISTORY })}>
                                                <span className="icon">
                                                    <Profile2 />
                                                </span>
                                                결제관리
                                            </a>
                                            {/*process.env.REACT_APP_BLOCK_FEATURE === 'true' && this.props.auth.role !== 'Admin' ? (
                                                <a onClick={() => this.popupUseBetaFree()}>
                                                    <span className="icon">
                                                    <Profile2 />
                                                    </span>
                                                    결제관리
                                                </a>
                                            ) : (
                                                <a onClick={() => this.props.history.push({ pathname: '/' + routerName.PAYMENT_HISTORY })}>
                                                    <span className="icon">
                                                    <Profile2 />
                                                    </span>
                                                    결제관리
                                                </a>
                                            )*/}
                                        </li>
                                        <li className={"li-item " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                            <Link to={`/${routerName.MANAGER_SALES}`}>
                                                <span className="icon">
                                                <Profile3 />
                                                </span>
                                                판매자 관리
                                            </Link>
                                        </li>
                                        <li className={"li-item " + permissionsMenu(this.props.auth.role, ['Member', 'Admin'])}>
                                            <a onClick={() => this.props.history.push({ pathname: '/' + routerName.MY_COUPON })}>
                                                <span className="icon">
                                                <Profile4 />
                                                </span>
                                                나의 쿠폰
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onClickLogout()}>
                                                <span className="icon">
                                                <Profile5 />
                                                </span>
                                                로그아웃
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {!this.props.isAuthenticated && (
                            <div className="login-register">
                                {/* <a href="">로그인</a> */}
                                {(this.props.match.path === `/${routerName.FORGOT_ID}` || this.props.match.path === `/${routerName.FORGOT_PASSWORD}`) ?
                                    (
                                        <Fragment>
                                            <a className="" href={`/${routerName.SIGN_IN}`}>로그인</a>
                                            <a className="btn btn-login" href={`/${routerName.SIGN_UP}`}>회원가입</a>
                                        </Fragment>
                                    )
                                    : ('')}
                            </div>
                        )}
                    </div>
                </div>
                {this.props.isAuthenticated && (
                    <Menu  {...this.props} />
                )}
                <PopupUserBetaFree
                    isOpenModal={this.state.isModalUseBetaFree}
                    closePopupDisable={this.closePopupDisableHandle}
                />
            </header>
        );
    }
}

Header.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    match: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        auth: state.auth,
        notifications: state.dataStore.notification.notifications,
        count_ntf: state.dataStore.notification.count_ntf
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        showSidebar: () => dispatch(showSidebar()),
        hideSidebar: () => dispatch(hideSidebar()),
        logoutAction: (data) => dispatch(logoutAction(data)),
        getPricePackages: (data) => dispatch(getPricePackages(data)),
        getPriceDefault: () => dispatch(getPriceDefault()),
        getNotifications: (data) => dispatch(getNotifications(data)),
        loadmoreDataNotification: (data) => dispatch(loadmoreDataNotification(data)),
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(Header);

