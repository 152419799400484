import React from 'react';
import {getFormSyncErrors, getFormValues} from "redux-form";
import {getDetailPlan} from "../../../../store/actions/discount_card";
import {connect} from "react-redux";
import {compose} from "redux";


class PlanDetails extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      isOpenModal:false,
      data:{}
    }
  }

  componentDidMount () {
    if(this.props.data.packageFee != null) {
      this.fetchData(this.props.data.packageFee.id);
    }
  }

  fetchData = (id, typePlan=false) => {
    if(typePlan) {
      let dataPlanCus={}
      dataPlanCus.plancustom=1

      this.props.getDetailPlan(id,dataPlanCus).then(res => {
        if(res.data) {
          res.data.call_time_desc=res.data.plac_use_call
          res.data.sms_desc=res.data.plac_use_sms
          res.data.data_detail=res.data.plac_use_data
          res.data.call_time_adt=0
          res.data.extra_info=res.data.plac_use_extra
          res.data.description=res.data.plac_use_extra
          this.setState({
            data : res.data
          })
        }
      })
    }else {
      this.props.getDetailPlan(id).then(res => {
        if(res.data) {
          this.setState({
            data : res.data
          })
        }
      })
    }
  }

  renderDescripton = () => {
    const {data}=this.state
    if(data && data.description) {
      let dataRender = data.description.split(/\r\n|\r|\n/g)
      return dataRender.map((item, key) => {
        return (<li key={key}><p>{item}</p></li>);
      })
    }
  }

  render() {
    const {data}=this.state;

    return(
      <React.Fragment>
        <div className="row mt-2 justify-content-between">
          <div className="col-md-5 pr-0">
            <button className="btn btn-gray-white w-100 pl-1 pr-1" type="button" data-toggle="modal" data-target="#modal-100 ">
              위약금 조회
            </button>
          </div>
          <div className="col-md-1">
            <span className="border-line" />
          </div>
          <div className="col-md-5 pl-0">
            <button className="btn btn-gray-white w-100 pl-1 pr-1" type="button" data-toggle="modal" data-target="#modal-11">
              요금제 상세
            </button>
          </div>
        </div>
        <ul className="list-detail-product">
          <li>
            <a style={{cursor:'default'}}>
              <img src="/images/ic_settings_phone.svg" alt="" />
              <span>{data.call_time_desc}</span>
            </a>
          </li>
          <li>
            <a style={{cursor:'default'}}>
              <img src="/images/icon-gmail.svg" alt="" />
              <span>{data.sms_desc}</span>
            </a>
          </li>
          <li>
            <a style={{cursor:'default'}}>
              <img src="/images/icon-teth.svg" alt="" />
              <span>{data.data_detail}</span>
            </a>
          </li>
          <li>
            <a style={{cursor:'default'}}>
              <img src="/images/ic_videocam.svg" alt="" />
              <span>{data.call_time_adt}분</span>
            </a>
          </li>
        </ul>
        <ul className="list-text-content">
          {this.renderDescripton()}
        </ul>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getDetailPlan: (data,params) => dispatch(getDetailPlan(data,params)),
  }
};



const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  //withReduxForm,
)(PlanDetails);
