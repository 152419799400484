import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';
import { Modal } from 'reactstrap';
import _ from "lodash";
import {Fragment} from "react";
import {priceFormat, optionalAgreementCT, roundUpNumberPlan, caclWirelessStep3} from '../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";

class ComparisonSubsidies extends Component {
  constructor(props) {
    super(props);
  }

  changeMonth = (value, discost) => {
    const { WirelessAdvice } = this.props;
    let   customPlan;

    this.props.change('WirelessAdviceStep3.rateAgreement', discost + discost*0.1);
    this.props.change('WirelessAdviceStep3.discostChange', value);

    if ((discost + discost*0.1) != 0) {
        if (WirelessAdvice.WirelessAdviceStep3.planData == null) {
            customPlan = 1;
        }
        else{
            customPlan = 999;
        }
    }

    optionalAgreementCT(
      WirelessAdvice.WirelessAdviceStep3.basicCost / 1.1,
      WirelessAdvice.WirelessAdviceStep3.basicCostCalc / 1.1,
      customPlan,
      WirelessAdvice.WirelessAdviceStep3.packageFee,
      discost,
      value
    );
  }

  activeFollowChoose2G=(value) => {
    const { WirelessAdvice }           = this.props;
    var   dataSkt                      = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name;
    var   telcom                       = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer;
    var   rateAgreement                = (WirelessAdvice.WirelessAdviceStep3.rateAgreement) ? WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0;
    var   basicCostCalc                = WirelessAdvice.WirelessAdviceStep3.basicCostCalc;
    var   basicCost                    = WirelessAdvice.WirelessAdviceStep3.basicCost;
    var   combinedDiscount             = WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0;
    var   welfareDiscount              = WirelessAdvice.WirelessAdviceStep3.welfareDiscount && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost ? WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost : 0;
    var   promotion                    = WirelessAdvice.WirelessAdviceStep3.promotion && WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0;
    var   promotionDurationDiscountOne = WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne : 0;
    var   promotionDurationDiscountTwo = WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo : 0;
    var   promotionDuration            = +promotionDurationDiscountOne + +promotionDurationDiscountTwo;
    var   nameTelcom                   = "";
    var   valueBasicCost               = 0;

    if(dataSkt) {
      nameTelcom = dataSkt.toUpperCase();
    }

    if(telcom != null) {
      nameTelcom = telcom.toUpperCase();
    }

    if(basicCostCalc != 0) {
      valueBasicCost = basicCostCalc;
    }
    else {
      valueBasicCost = roundUpNumberPlan(basicCost);
    }

    if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 2 && value == 0) {
      alert(`2G전환고객을 "무약정"으로 설정하신 경우 선택약정으로 변경하실 수 없습니다.`);
    }
    else {
      this.props.change('WirelessAdviceStep3.optionFee', value);
      
      let dataCheckLimit = (nameTelcom && nameTelcom == "LGU+") ? OPTION_CHOOSE_2G.code_limit_LGU.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name) : OPTION_CHOOSE_2G.code_limit.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name);

      if(value != 1) {
        if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
          if(WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {
            if(WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G == null){
              this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
            }

            let moneyResult = (WirelessAdvice.WirelessAdviceStep3.basicCost != null ? roundUpNumberPlan(WirelessAdvice.WirelessAdviceStep3.basicCost) : 0) * OPTION_CHOOSE_2G.percent_optionalAgreement

            if(nameTelcom && nameTelcom == "LGU+") {
              moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
            }

            this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult);
          }
        }
        else {
          if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {
            var basicCostValue = WirelessAdvice.WirelessAdviceStep3.basicCostCalc != 0 ? WirelessAdvice.WirelessAdviceStep3.basicCostCalc : (WirelessAdvice.WirelessAdviceStep3.basicCost != null ? WirelessAdvice.WirelessAdviceStep3.basicCost : 0);
            let moneyResult    = (roundUpNumberPlan(basicCostValue) - rateAgreement) * OPTION_CHOOSE_2G.percent_optionalAgreement;

            if(nameTelcom && nameTelcom == "LGU+") {
              moneyResult = (valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount) * OPTION_CHOOSE_2G.percent_optionalAgreement;
            }

            this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : Math.round(moneyResult));

            if(WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6) {
              var discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
              var discost       = 0;

              if(discostChange == 12) {
                discost = +WirelessAdvice.WirelessAdviceStep3.discost12;
              }
              else {
                discost = +WirelessAdvice.WirelessAdviceStep3.discost24;
              }

              if(dataSkt == "LGU+" &&  WirelessAdvice.WirelessAdviceStep3.optionFee == 0 && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1) {
                if(discostChange == 12) {
                  discost = 0;
                }
                else {
                  discost = +WirelessAdvice.WirelessAdviceStep3.discost24;
                }
              }

              this.changeMonth(discostChange, discost);

              var promotionCost           = WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0;
              var optionalAgreement       = moneyResult;
              var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2);

              if(parseInt(dataCostWelfareDiscount) > 0){
                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount;
              }
              else {
                dataCostWelfareDiscount = 0;
              }

              dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1));

              this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount});
            }
          }
          else {
            this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
          }
        }
      }
      else {
        if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
          if(WirelessAdvice.WirelessAdviceStep3.conversion2G.id != 0) {
            this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB);

            if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
              this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
            }

            this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.startingPrice != null ? WirelessAdvice.WirelessAdviceStep3.startingPrice : 0);
          }
          else {
            if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null) {
              let optionalAgreement        = WirelessAdvice.WirelessAdviceStep3.optionalAgreement;
              let optionalAgreementWith2G  = WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G;
              let disclosureSubsidyDefault = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault;

              this.props.change('WirelessAdviceStep3.optionalAgreement', optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement);
              this.props.change('WirelessAdviceStep3.disclosureSubsidy', disclosureSubsidyDefault);
            }
          }
        }
        else {
          if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {
            this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB);

            let numberDisclosureSubsidy = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;
            let startingPrice           = +WirelessAdvice.WirelessAdviceStep3.startingPrice;

            numberDisclosureSubsidy=+numberDisclosureSubsidy;
                    
            let disclosureSubsidy=numberDisclosureSubsidy;

            if(startingPrice <  OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
              if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
              }

              this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.startingPrice);
            }
            else {
              if (disclosureSubsidy <= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                  this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
                }

                this.props.change('WirelessAdviceStep3.disclosureSubsidy', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit);
              }
              else {
                if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                  this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
                }
              }
            }

            let optionAgree = WirelessAdvice.WirelessAdviceStep3.optionalAgreement;

            if(WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G == null){
              this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', optionAgree != null ? optionAgree : null);
            }

            this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB);

            if(WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6) {
              var discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
              var discost       = 0;

              if(discostChange == 12) {
                discost = +WirelessAdvice.WirelessAdviceStep3.discost12;
              }
              else {
                discost = +WirelessAdvice.WirelessAdviceStep3.discost24;
              }

              if(dataSkt == "LGU+" &&  WirelessAdvice.WirelessAdviceStep3.optionFee == 0 && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1) {
                if(discostChange == 12) {
                  discost = 0;
                }
                else {
                  discost = +WirelessAdvice.WirelessAdviceStep3.discost24;
                }
              }

              this.changeMonth(discostChange,discost);

              var promotionCost           = WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0;
              var optionalAgreement       = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;
              var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2);

              if(parseInt(dataCostWelfareDiscount) > 0){
                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount;
              }
              else {
                dataCostWelfareDiscount=0;
              }

              dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1));
                        
              this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount});
            }
          }
          else {
            let optionalAgreement       = WirelessAdvice.WirelessAdviceStep3.optionalAgreement;
            let optionalAgreementWith2G = WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G;

            this.props.change('WirelessAdviceStep3.optionalAgreement', optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement);

            if (WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault !== null)  {
              if(WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18){
                if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType == "six"){
                  this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidySixPlan + "");
                }
                else{
                  this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyBasicPlan + "");
                }
              }
              else{
                this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault);  
              }
            }
          }
        }
      }
    }
  }

  active = (value) => {
    const { WirelessAdviceStep3 }     = this.props.WirelessAdvice;
    let   disclosureSubsidyDifference = null;
    let   check                       = false;
    let   usedPhone                   = WirelessAdviceStep3.usedPhoneCompensation;

    if (value == 0) {
        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
    }
    else{
        if (WirelessAdviceStep3.dataPlanNewDateChange != null) {
            disclosureSubsidyDifference = (+WirelessAdviceStep3.disclosureSubsidy) - (+WirelessAdviceStep3.disclosureSubsidyDifferenceDefault);

            this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference',disclosureSubsidyDifference);
        }
    }

    if (usedPhone) {
      if (usedPhone.vprogram_install_types && usedPhone.vprogram_install_types.length > 0) {
        let {installmentType} = WirelessAdviceStep3;
        let arrSubType        = [];

        usedPhone.vprogram_install_types.forEach((item, index) => {
          let tmp = item.name.split('/');

          if (tmp[0] == installmentType.typeName) {
            arrSubType.push({...item, 'subTypeName': tmp[1]})
          }
        });

        arrSubType.forEach((item, index) => {
          if (item.code_group == value) {
            check = true;
          }
        })
      }
      else if (usedPhone.vprogram_types && usedPhone.vprogram_types.length > 0) {    //các nhà mạng còn lại
        usedPhone.vprogram_types.forEach((item, index) => {
          if (item.code_group == value) {
            check = true;
          }
        })
      }
    }

    if (!check) {
        this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
        this.props.change('WirelessAdviceStep3.usedPhoneCompensation', {"tup_id": '', "tup_name": "미적용"});
    }

    this.activeFollowChoose2G(value);

    this.props.openPopup();
  }

  getMonthPrice(value){
    const { WirelessAdviceStep1, WirelessAdviceStep3 } = this.props.WirelessAdvice;
    let   wirelessAdviceStep3                          = {...WirelessAdviceStep3};
    let   disclosureSubsidyDifference                  = null;
    let   check                                        = false;
    let   usedPhone                                    = WirelessAdviceStep3.usedPhoneCompensation;

    if (value == 0) {
      wirelessAdviceStep3.disclosureSubsidyDifference = null;
    }
    else{
      if (WirelessAdviceStep3.dataPlanNewDateChange != null) {
        disclosureSubsidyDifference                     = (+wirelessAdviceStep3.disclosureSubsidy) - (+wirelessAdviceStep3.disclosureSubsidyDifferenceDefault);
        wirelessAdviceStep3.disclosureSubsidyDifference = disclosureSubsidyDifference;
      }
    }

    if (usedPhone) {
      if (usedPhone.vprogram_install_types && usedPhone.vprogram_install_types.length > 0) {
        let {installmentType} = wirelessAdviceStep3;
        let arrSubType        = [];

        usedPhone.vprogram_install_types.forEach((item, index) => {
          let tmp = item.name.split('/');

          if (tmp[0] == installmentType.typeName) {
            arrSubType.push({...item, 'subTypeName': tmp[1]})
          }
        });

        arrSubType.forEach((item, index) => {
          if (item.code_group == value) {
            check = true;
          }
        })
      }
      else if (usedPhone.vprogram_types && usedPhone.vprogram_types.length > 0) {    //các nhà mạng còn lại
        usedPhone.vprogram_types.forEach((item, index) => {
          if (item.code_group == value) {
            check = true;
          }
        })
      }
    }

    if (!check) {
      wirelessAdviceStep3.applyUsedProgram = 0;
      wirelessAdviceStep3.usedPhoneCompensation = {"tup_id": '', "tup_name": "미적용"};
    }

    var   dataSkt                      = wirelessAdviceStep3?.phone?.short_name;
    var   telcom                       = wirelessAdviceStep3?.telcomPotentialCustomer;
    var   rateAgreement                = wirelessAdviceStep3.rateAgreement ? wirelessAdviceStep3.rateAgreement : 0;
    var   basicCostCalc                = wirelessAdviceStep3.basicCostCalc;
    var   basicCost                    = wirelessAdviceStep3.basicCost;
    var   combinedDiscount             = wirelessAdviceStep3.combinedDiscount ? wirelessAdviceStep3.combinedDiscount : 0;
    var   welfareDiscount              = wirelessAdviceStep3.welfareDiscount && wirelessAdviceStep3.welfareDiscount?.cost ? wirelessAdviceStep3.welfareDiscount?.cost : 0;
    var   promotion                    = wirelessAdviceStep3.promotion && wirelessAdviceStep3.promotion?.promo_cost ? wirelessAdviceStep3.promotion?.promo_cost : 0;
    var   promotionDurationDiscountOne = wirelessAdviceStep3.promotionDurationDiscountOne ? wirelessAdviceStep3.promotionDurationDiscountOne : 0;
    var   promotionDurationDiscountTwo = wirelessAdviceStep3.promotionDurationDiscountTwo ? wirelessAdviceStep3.promotionDurationDiscountTwo : 0;
    var   promotionDuration            = +promotionDurationDiscountOne + +promotionDurationDiscountTwo;
    var   nameTelcom                   = "";
    var   valueBasicCost               = 0;

    if(dataSkt) {
      nameTelcom = dataSkt.toUpperCase();
    }

    if(telcom != null) {
      nameTelcom = telcom.toUpperCase();
    }

    if(basicCostCalc != 0) {
      valueBasicCost = basicCostCalc;
    }
    else {
      valueBasicCost = roundUpNumberPlan(basicCost);
    }

    if(wirelessAdviceStep3.conversion2G != null && wirelessAdviceStep3.conversion2G.id == 2 && value == 0) {
      alert(`2G전환고객을 "무약정"으로 설정하신 경우 선택약정으로 변경하실 수 없습니다.`);
    }
    else {
      wirelessAdviceStep3.optionFee = value;
      
      let dataCheckLimit = (nameTelcom && nameTelcom == "LGU+") ? OPTION_CHOOSE_2G.code_limit_LGU.includes(wirelessAdviceStep3.phone.model_name) : OPTION_CHOOSE_2G.code_limit.includes(wirelessAdviceStep3.phone.model_name);

      if(value != 1) {
        if(wirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
          if(wirelessAdviceStep3.conversion2G.id == 1) {
            if(wirelessAdviceStep3.optionalAgreementWith2G == null){
              wirelessAdviceStep3.optionalAgreementWith2G = wirelessAdviceStep3.disclosureSubsidy;
            }

            let moneyResult = (wirelessAdviceStep3.basicCost != null ? roundUpNumberPlan(wirelessAdviceStep3.basicCost) : 0) * OPTION_CHOOSE_2G.percent_optionalAgreement

            if(nameTelcom && nameTelcom == "LGU+") {
              moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
            }

            wirelessAdviceStep3.optionalAgreement = moneyResult < 0 ? 0 : moneyResult;
          }
        }
        else {
          if(wirelessAdviceStep3.conversion2G != null && wirelessAdviceStep3.conversion2G.id == 1) {
            var basicCostValue = wirelessAdviceStep3.basicCostCalc != 0 ? wirelessAdviceStep3.basicCostCalc : (wirelessAdviceStep3.basicCost != null ? wirelessAdviceStep3.basicCost : 0);
            let moneyResult    = (roundUpNumberPlan(basicCostValue) - rateAgreement) * OPTION_CHOOSE_2G.percent_optionalAgreement;

            if(nameTelcom && nameTelcom == "LGU+") {
              moneyResult = (valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount) * OPTION_CHOOSE_2G.percent_optionalAgreement;
            }

            wirelessAdviceStep3.optionalAgreement = moneyResult < 0 ? 0 : Math.round(moneyResult);

            if(wirelessAdviceStep3.welfareDiscount != null && wirelessAdviceStep3.welfareDiscount?.id == 6) {
              var discostChange = wirelessAdviceStep3.discostChange;
              var discost       = 0;

              if(discostChange == 12) {
                discost = +wirelessAdviceStep3.discost12;
              }
              else {
                discost = +wirelessAdviceStep3.discost24;
              }

              if(dataSkt == "LGU+" &&  wirelessAdviceStep3.optionFee == 0 && wirelessAdviceStep3.discountFullPlan == 1) {
                if(discostChange == 12) {
                  discost = 0;
                }
                else {
                  discost = +wirelessAdviceStep3.discost24;
                }
              }

              wirelessAdviceStep3.rateAgreement = discost + discost*0.1;
              wirelessAdviceStep3.discostChange = discostChange;

              let customPlan;

              if ((discost + discost*0.1) != 0) {
                if (wirelessAdviceStep3.planData == null) {
                    customPlan = 1;
                }
                else{
                    customPlan = 999;
                }
              }

              optionalAgreementCT(
                wirelessAdviceStep3.basicCost / 1.1,
                wirelessAdviceStep3.basicCostCalc / 1.1,
                customPlan,
                wirelessAdviceStep3.packageFee,
                discost,
                discostChange
              );


              var promotionCost           = wirelessAdviceStep3.promotion?.promo_cost ? wirelessAdviceStep3.promotion?.promo_cost : 0;
              var optionalAgreement       = moneyResult;
              var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2);

              if(parseInt(dataCostWelfareDiscount) > 0){
                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount;
              }
              else {
                dataCostWelfareDiscount = 0;
              }

              dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1));

              wirelessAdviceStep3.welfareDiscount = {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount};
            }
          }
          else {
            wirelessAdviceStep3.disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidy;
          }
        }
      }
      else {
        if(wirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
          if(wirelessAdviceStep3.conversion2G.id != 0) {
            wirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;

            if(wirelessAdviceStep3.disclosureSubsidyDefault == null){
              wirelessAdviceStep3.disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidy;
            }

            wirelessAdviceStep3.disclosureSubsidy = wirelessAdviceStep3.startingPrice != null ? wirelessAdviceStep3.startingPrice : 0;
          }
          else {
            if(wirelessAdviceStep3.conversion2G != null) {
              let optionalAgreement        = wirelessAdviceStep3.optionalAgreement;
              let optionalAgreementWith2G  = wirelessAdviceStep3.optionalAgreementWith2G;
              let disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidyDefault;

              wirelessAdviceStep3.optionalAgreement = optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement;
              wirelessAdviceStep3.disclosureSubsidy = disclosureSubsidyDefault;
            }
          }
        }
        else {
          if(wirelessAdviceStep3.conversion2G != null && wirelessAdviceStep3.conversion2G.id == 1) {
            wirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;
            
            let numberDisclosureSubsidy = wirelessAdviceStep3.disclosureSubsidy;
            let startingPrice           = +wirelessAdviceStep3.startingPrice;

            numberDisclosureSubsidy=+numberDisclosureSubsidy;
                    
            let disclosureSubsidy=numberDisclosureSubsidy;

            if(startingPrice <  OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
              if(wirelessAdviceStep3.disclosureSubsidyDefault == null){
                wirelessAdviceStep3.disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidy;
              }

              wirelessAdviceStep3.disclosureSubsidy = wirelessAdviceStep3.startingPrice;
            }
            else {
              if (disclosureSubsidy <= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                if(wirelessAdviceStep3.disclosureSubsidyDefault == null){
                  wirelessAdviceStep3.disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidy;
                }

                wirelessAdviceStep3.disclosureSubsidy = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit;
              }
              else {
                if(wirelessAdviceStep3.disclosureSubsidyDefault == null){
                  wirelessAdviceStep3.disclosureSubsidyDefault = wirelessAdviceStep3.disclosureSubsidy;
                }
              }
            }

            let optionAgree = wirelessAdviceStep3.optionalAgreement;

            if(wirelessAdviceStep3.optionalAgreementWith2G == null){
              wirelessAdviceStep3.optionalAgreementWith2G = optionAgree != null ? optionAgree : null;
            }

            wirelessAdviceStep3.optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;

            if(wirelessAdviceStep3.welfareDiscount != null && wirelessAdviceStep3.welfareDiscount?.id == 6) {
              var discostChange = wirelessAdviceStep3.discostChange;
              var discost       = 0;

              if(discostChange == 12) {
                discost = +wirelessAdviceStep3.discost12;
              }
              else {
                discost = +wirelessAdviceStep3.discost24;
              }

              if(dataSkt == "LGU+" && wirelessAdviceStep3.optionFee == 0 && wirelessAdviceStep3.discountFullPlan == 1) {
                if(discostChange == 12) {
                  discost = 0;
                }
                else {
                  discost = +wirelessAdviceStep3.discost24;
                }
              }

              let   customPlan;

              wirelessAdviceStep3.rateAgreement = discost + discost*0.1;
              wirelessAdviceStep3.discostChange = discostChange;

              if ((discost + discost*0.1) != 0) {
                if (wirelessAdviceStep3.planData == null) {
                    customPlan = 1;
                }
                else{
                    customPlan = 999;
                }
              }
        
              optionalAgreementCT(
                wirelessAdviceStep3.basicCost / 1.1,
                wirelessAdviceStep3.basicCostCalc / 1.1,
                customPlan,
                wirelessAdviceStep3.packageFee,
                discost,
                discostChange
              );

              var promotionCost           = wirelessAdviceStep3.promotion?.promo_cost ? wirelessAdviceStep3.promotion?.promo_cost : 0;
              var optionalAgreement       = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB;
              var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2);

              if(parseInt(dataCostWelfareDiscount) > 0){
                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount;
              }
              else {
                dataCostWelfareDiscount=0;
              }

              dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1));

              wirelessAdviceStep3.welfareDiscount = {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount};
            }
          }
          else {
            let optionalAgreement       = wirelessAdviceStep3.optionalAgreement;
            let optionalAgreementWith2G = wirelessAdviceStep3.optionalAgreementWith2G;

            wirelessAdviceStep3.optionalAgreement = optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement;

            if (wirelessAdviceStep3.disclosureSubsidyDefault !== null)  {
              wirelessAdviceStep3.disclosureSubsidy = wirelessAdviceStep3.disclosureSubsidyDefault;
            }
          }
        }
      }
    }

    wirelessAdviceStep3 = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, wirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining);

    return wirelessAdviceStep3.amountMonthlyDevice;
  }

  getOptionFeeTotal = () => {
    const { WirelessAdvice } = this.props;

    let result              = "";
    let wirelessAdviceStep3 = WirelessAdvice.WirelessAdviceStep3;
    let optionalAgreement   = +(wirelessAdviceStep3?.optionalAgreement || 0);
    let contractMonth       = +wirelessAdviceStep3.contractMonth;

    if(contractMonth == 23){
      contractMonth = 24;
    }

    optionalAgreement = priceFormat(optionalAgreement).toString().split(',').join('');
    result            = contractMonth * +optionalAgreement;
    result            = priceFormat(result) + " 원";

    return result;
  }

  render() {
    const { WirelessAdvice } = this.props;

    return (
        <Fragment>
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                //onOpened = {this.onOpened}
                fade={true}
                className={'modal-dialog modal-dialog-centered modal-additional'}
                contentClassName="pl-4 pr-4"
            >
                <div className="modal-header pt-4 pb-1">
                    <div className={'custom-create-seller'}>
                        <h5 className="modal-title">지원금 비교</h5>
                    </div>
                    <button
                        type="button"
                        className="close close-modal"
                        onClick={this.props.openPopup}
                    >
                        <img src="/images/icon-close.svg" alt="" />
                    </button>
                </div>
                <div className="modal-body mt-1">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "none", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>지원금 선택<br/>(24개월 기준)</td>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "1px solid #dcdcdc", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>공시지원금</td>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "1px solid #dcdcdc", borderRight : "none", borderBottom : "1px solid #dcdcdc"}}>선택약정</td>
                            </tr>
                            <tr>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "none", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>단말기<br/>구매비용 지원</td>
                                <td className='text-center align-middle' style={{color : "#292929", backgroundColor : "#ffffff", borderLeft : "1px solid #dcdcdc", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>{priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)} 원</td>
                                <td className='text-center align-middle' style={{color : "#292929", backgroundColor : "#ffffff", borderLeft : "1px solid #dcdcdc", borderRight : "none", borderBottom : "1px solid #dcdcdc"}}>-</td>
                            </tr>
                            <tr>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "none", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>요금할인</td>
                                <td className='text-center align-middle' style={{color : "#292929", backgroundColor : "#ffffff", borderLeft : "1px solid #dcdcdc", borderRight : "1px solid #dcdcdc"}}>-</td>
                                <td className='text-center align-middle' style={{color : "#292929", backgroundColor : "#ffffff", borderLeft : "1px solid #dcdcdc", borderRight : "none"}}>월 {priceFormat(WirelessAdvice.WirelessAdviceStep3.optionalAgreement)} 원</td>
                            </tr>
                            <tr>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "none", borderBottom : "1px solid #dcdcdc"}}>총할인금액<br/>(월 할인금액 x 약정기간)</td>
                                <td className='text-center align-middle' style={{color : "#2f8bff", borderTop : "1px solid #2f8bff", borderLeft : "1px solid #2f8bff", backgroundColor : "#ffffff", borderRight : "1px solid #dcdcdc", borderBottom : "1px solid #dcdcdc"}}>{priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)} 원</td>
                                <td className='text-center align-middle' style={{color : "#2f8bff", borderTop : "1px solid #2f8bff", borderRight : "1px solid #2f8bff", backgroundColor : "#ffffff", borderBottom : "1px solid #dcdcdc"}}>{ this.getOptionFeeTotal() }</td>
                            </tr>
                            <tr>
                                <td className='text-center align-middle' style={{color : "#696969", backgroundColor : "#f5f5f5", borderLeft : "none", borderBottom : "1px solid #dcdcdc"}}>월 납부액</td>
                                <td className='text-center align-middle' style={{color : "#2f8bff", borderBottom : "1px solid #2f8bff", borderLeft : "1px solid #2f8bff", backgroundColor : "#ffffff", borderRight : "1px solid #dcdcdc"}}>{priceFormat(this.getMonthPrice(1))} 원</td>
                                <td className='text-center align-middle' style={{color : "#2f8bff", borderBottom : "1px solid #2f8bff", borderRight : "1px solid #2f8bff", backgroundColor : "#ffffff"}}>{priceFormat(this.getMonthPrice(0))} 원</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer justify-content-center border-top-0">
                    <button type="button" className="btn btn-submit" onClick={this.active.bind(this, 1)}>공시지원금 적용</button>
                    <button type="button" className="btn btn-gray" onClick={this.active.bind(this, 0)}>선택약정 적용</button>
                </div>
            </Modal>
        </Fragment>
    );
  }
}

ComparisonSubsidies.propTypes = {
  openPopup: PropTypes.func,
  auth: PropTypes.object,
  WirelessAdvice: PropTypes.object,
  isOpenModal: PropTypes.any,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(ComparisonSubsidies);
