
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import DisclosureFundModal from './DisclosureFundModal';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
import { replace } from "lodash";

class WirelessDisclosureSubsidy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disclosure : "69,000"
    }
  }

  formatNumberTens = (n) => {
    let number =  n.toFixed(2).replace(/(\d)(?=(\d{2})+\.)/g, '$1,');
    let arr = number.split(',');
    arr[(arr.length - 1)] = '00';
    let result = arr.join().split(",").join("");
    return +result;
  }
  
  /**
   * mapping props
   */
  disclosureProps = (value, type = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType) => {
    let data=value.split(',').join('');

    this.props.change('WirelessAdviceStep3.disclosureSubsidyType', type);
    this.props.change('WirelessAdviceStep3.disclosureSubsidy', data);

    let disclosureSubsidyDifference;
    if (this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange != null) {
      let disclosureSubsidyDifferenceDefault = +this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault;
      let dataPlanNewDateChange              = this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange;

      if (dataPlanNewDateChange != null) {
        if(type == "six"){
          disclosureSubsidyDifferenceDefault = dataPlanNewDateChange.disclosure_price_six_plan;
        }
        else{
          disclosureSubsidyDifferenceDefault = dataPlanNewDateChange.disclosure_price_basic_plan;
        }
  
        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', disclosureSubsidyDifferenceDefault);
      }

      disclosureSubsidyDifference = (+data) - disclosureSubsidyDifferenceDefault;
      
      this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
    }
    let resultMoney;
    let number;
    //set additionalGrants

    switch (this.props.WirelessAdvice.WirelessAdviceStep3.additionalGrantsType) {
      case '0':
          this.props.change('WirelessAdviceStep3.additionalGrants', 0)
          break;
      case '15':
        resultMoney =  parseInt(data)*15/100;
        // number = this.formatNumberTens(resultMoney);
        number = Math.floor(resultMoney / 10) * 10;
        this.props.change('WirelessAdviceStep3.additionalGrants', number)
        break;
      case '15-MNP':
        resultMoney =  parseInt(data)*15/100 - 800;
        // number = this.formatNumberTens(resultMoney);
        number = Math.floor(resultMoney / 10) * 10;
        this.props.change('WirelessAdviceStep3.additionalGrants', number)
        break;
      default:
        resultMoney = this.props.WirelessAdvice.WirelessAdviceStep3.additionalGrants;

        if(resultMoney > (+data * 0.15)){
          resultMoney = 0;

          alert("공시지원금의 최대 15%까지만 적용가능합니다.\n추가지원금이 초기화 됩니다.");
        }

        this.props.change('WirelessAdviceStep3.additionalGrants', resultMoney);

        break;
    }
  }

  disclosureSubsidy = (type) => {
    const { WirelessAdvice } = this.props;
    let disclosureProps      = "";

    if(type == "six"){
      disclosureProps = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidySixPlan + "";
    }
    else{
      disclosureProps = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyBasicPlan + "";
    }

    this.disclosureProps(disclosureProps, type);
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let validPrice = WirelessAdvice.WirelessAdviceStep3.startingPrice;
    let displayApplyDate = replace(WirelessAdvice.WirelessAdviceStep3.displayApplyDate, "-", '.');
    let disclosureSubsidy;
    let styleNumber;

    if (WirelessAdvice.WirelessAdviceStep3.optionFee == 0) {
      disclosureSubsidy = 0;
    } else {
      if (WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy && WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy != 0) {
        disclosureSubsidy = '-'+priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
      } else {
        disclosureSubsidy = 0;
      }
    }

    //console.log("disclosureSubsidy : " + disclosureSubsidy);

    if (disclosureSubsidy != 0) {
      styleNumber = "btn-prices-wp";
    } else {
      styleNumber = "";
    }
    return (
      
      <Fragment>
        <div className="form-group row align-items-center">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mb-3">공시지원금</label>
          <div className="col-sm-8 m-left">
          {WirelessAdvice.WirelessAdviceStep3.phone.telcomID == 18 ?
            (<table style={{width : "100%"}}>
              <tr>
                <td>
                  <div>
                    <input
                      type="radio"
                      name="disclosureSubsidy"
                      style={{height : "22px", verticalAlign: "middle"}}
                      defaultChecked
                      onClick={() => {this.disclosureSubsidy("six")}}
                      id="disclosureSubsidySix"
                    />
                    <label class="col-sm-4 col-form-label label-text mb-3" for="disclosureSubsidySix">식스플랜</label>
                  </div>
                </td>
                <td rowSpan={2}>
                  <button className={`btn btn-input-form mb-0 ${styleNumber}`} type="button" data-toggle="modal" data-target="#modal-05">
                    {disclosureSubsidy}
                    <span>원</span>
                  </button>
                  {(WirelessAdvice.WirelessAdviceStep3.displayApplyDate == null || WirelessAdvice.WirelessAdviceStep3.displayApplyDate == 'invalid date') ?
                    (<p className="small-color mb-3 pl-2"></p>) : 
                    (<p className="small-color mb-0 pl-2">
                      {WirelessAdvice.WirelessAdviceStep3.displayApplyDate + ' 기준'}
                    </p>)
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <input
                      type="radio"
                      name="disclosureSubsidy"
                      style={{height : "22px", verticalAlign: "middle"}}
                      onClick={() => {this.disclosureSubsidy("basic")}}
                      id="disclosureSubsidyBasic"
                    />
                    <label class="col-sm-4 col-form-label label-text mb-3" for="disclosureSubsidyBasic">베이직플랜</label>
                  </div>
                </td>
              </tr>
            </table>) :
            (<Fragment>
              <button className={`btn btn-input-form mb-0 ${styleNumber}`} type="button" data-toggle="modal" data-target="#modal-05">
                {disclosureSubsidy}
                <span>원</span>
              </button>
              {
                (WirelessAdvice.WirelessAdviceStep3.displayApplyDate == null ||
                WirelessAdvice.WirelessAdviceStep3.displayApplyDate == 'invalid date') ? (
                  <p className="small-color mb-3 pl-2"></p>
                ) : 
                (<p className="small-color mb-0 pl-2">
                  ({WirelessAdvice.WirelessAdviceStep3.displayApplyDate + ' 기준'})
                </p>)
                
              }
            </Fragment>)
          }
          </div>
        </div>
        <DisclosureFundModal 
          disclosure = {priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)}
          disclosureProps = {this.disclosureProps}
          validPrice={validPrice}
          disclosureBase = {priceFormat(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy_base)}
          WirelessAdvice = { WirelessAdvice }
        />
      </Fragment>
    );
  }
}

WirelessDisclosureSubsidy.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessDisclosureSubsidy);
