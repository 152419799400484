import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import ImageSliderModal from "./ImageSliderModal";
import {priceFormat} from "../../../utils/helper";
import {getFormValues} from "redux-form";

class WireProductDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images_slider_modal: {
                isShowModal: false,
                images: [],
            },
        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {

    }


    /**
     * detect open modal
     */
    onOpened = () => {
    }


    toggleModal = (e, modal, images) => {
        e.preventDefault();
        this.setState({
            [modal]:{
                ...this.state[modal],
                isShowModal: !this.state[modal].isShowModal,
                images: images
            }
        })
    }

    onSelectWireProduct = (e, value) => {
        this.props.onSelectWireProduct(value);
        this.props.toggleModal(e,'wire_product_detail_modal');
    }

    render() {
        const isShowImageSliderModal = this.state.images_slider_modal.isShowModal;
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-dialog modal-dialog-centered modal-custom-03"
                       contentClassName="pl-5 pr-5"
                       toggle = {(e) => this.props.toggleModal(e,'wire_product_detail_modal')}
                       onOpened = {this.onOpened}
                       onClosed = {this.onCloseModal}
                       // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">[{this.props?.WirelessAdvice?.WirelessAdviceStep1?.telcomName}] 인터넷 상품 상세정보</h5>
                        <button type="button" className="close close-modal"
                                onClick={(e) => this.props.toggleModal(e,'wire_product_detail_modal')}>
                         <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body pl-0 pr-0">
                        <ul className="list-detail-internet">
                            {
                                this.props.wire_devices && this.props.wire_devices.map((wire, i) => {
                                   return (
                                       <li key={i}>
                                           <div className="left">
                                               <div className="top-detail">
                                                   <h6>{ wire.wire_device && wire.wire_device.wd_name }</h6>
                                                   <span>월  { wire.wire_device && wire.wire_device.wd_commitment_fee ? priceFormat(wire.wire_device.wd_commitment_fee) : 0}원</span>

                                                   <a href="#"
                                                      className={`btn btn-wireless h-25 ${wire.wire_device && wire.wire_device.wd_image_url && wire.wire_device.wd_image_url.length > 0 ? "" : "disabled" }`}
                                                      onClick={(e) => this.toggleModal(e,'images_slider_modal', wire.wire_device && wire.wire_device.wd_image_url)}
                                                   >
                                                       <img src="/images/icon-12.svg" alt=""/>
                                                       상세보기
                                                   </a>
                                               </div>
                                               <p>{wire.wire_device && wire.wire_device.wd_description}</p>
                                           </div>
                                           <div className="right">
                                               <button className="btn btn-submit h-32"
                                                       onClick={(e) => this.onSelectWireProduct(e, wire)}
                                                       >
                                                   선택
                                               </button>
                                           </div>
                                       </li>
                                   )
                                })
                            }
                        </ul>
                    </div>
                </Modal>
                <ImageSliderModal
                    images={this.state.images_slider_modal.images}
                    toggleModal={this.toggleModal}
                    isShowModal={isShowImageSliderModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(WireProductDetailModal);