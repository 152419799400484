import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import {priceFormat} from "../../../utils/helper";

class ConversionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conversion : this.props.conversion||0,
            errMsg : null,
            isChangeInput : false
        };
        this.closeRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                conversion    : this.props.conversion||0,
                errMsg        : null,
                isChangeInput : false
            });
        }
    }
    
    /**
     * handle change input 
     * 
     */
    handleChangeDisclosure = (e) => {
        e.preventDefault();
        
        let value     = e.target.value;
        let intNumber = value.split(',').join('');
        let errMsg    = null;
        let maxValue  = this.getApplyMax();
        var regex     = /^[0-9]+$/;

        if (intNumber.length > 10) {
            errMsg = '금액은 10자를 초과하면 안됩니다!';
        }
        else if (value && !intNumber.match(regex)) {
            errMsg = '숫자로 입력해야 됩니다!';
        }
        else if (parseInt(intNumber) > parseInt(maxValue)) {
            errMsg = `할부 원금을 초과하였습니다.`;
        }

        if (errMsg) {
            this.setState({
                conversion : this.props.conversion||0,
                isChangeInput : true,
                errMsg : errMsg
            });
        }
        else{
            this.setState({
                conversion : intNumber,
                isChangeInput : false,
                errMsg : null
            });
        }
    }

    /**
     * submit value Disclosure
     */
    submitDisclosure = (e) => {
        e.preventDefault();
        
        let value  = this.state.conversion;
        let errMsg = this.state.errMsg;

        if (!errMsg) {
            this.props.disclosureProps(value ? value : this.props.disclosure);

            this.setState({
                isChangeInput : false
            });

            this.closeRef.current.click()
        }
        else {
            return false;
        }
    }

    /**
     * 최대 적용 가능 금액 반환하는 메소드
     */
    getApplyMax = () => {
        const { WirelessAdvice } = this.props;
        let installmentPrincipal = +WirelessAdvice.WirelessAdviceStep3.installmentPrincipal;
        let conversionPriceInput = +WirelessAdvice.WirelessAdviceStep3.conversion_price_input;

        installmentPrincipal = installmentPrincipal + conversionPriceInput;

        if(installmentPrincipal > 500000){
            installmentPrincipal = 500000;
        }

        return installmentPrincipal;
    }

    /**
     * reset value Disclosure
     */
    handleResetValue = (e) => {
        e.preventDefault();

        const { WirelessAdvice } = this.props;
        let conversionPriceInput = this.getApplyMax();
        let conversionPrice      = +WirelessAdvice.WirelessAdviceStep3.conversion_price;

        if(conversionPrice > conversionPriceInput){
            conversionPriceInput = conversionPriceInput;
        }
        else{
            conversionPriceInput = conversionPrice;
        }

        this.setState({
            conversion : conversionPriceInput,
            errMsg     : null
        });
    }

    /**
     * show error message when input value
     */
    showErrorMessage = (errMsg) => {
        return(<span className={'invalid-feedback'}>{errMsg}</span>);
    }


    render() {
        const { WirelessAdvice } = this.props;
        let conversion = this.state.conversion;
        let errMsg     = this.state.errMsg;
        
        return(
            <Fragment>
        <div className="modal fade" data-keyboard="false" id="modal-conversion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-custom modal-dialog-centered" role="document">
            <div className="modal-content pl-5 pr-5">
              <div className="modal-header">
                <h5 className="modal-title">전환 지원금 변경</h5>
                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                  <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                </button>
              </div>
              <div className="modal-body px-0">
                <form onSubmit={this.submitDisclosure} className="body-plans">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 text-modal-gray">
                      전환 지원금 : &nbsp;{ priceFormat(WirelessAdvice?.WirelessAdviceStep3.conversion_price) }원
                    </p>
                    <button type="button" className="btn btn-gray-white" onClick = {this.handleResetValue}>적용</button>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 text-modal-gray">
                        최대 적용 가능 금액 : &nbsp;{ priceFormat(this.getApplyMax()) }원
                    </p>
                  </div>
                  <div className="form-group row mt-4 align-items-center">
                    <div className="col-10 pr-0">
                      <input
                        type="text"
                        className={errMsg ? "form-control h-44 is-invalid" : "form-control h-44" } 
                        value={priceFormat(conversion)} 
                        onChange = {this.handleChangeDisclosure}
                      />
                      {this.showErrorMessage(errMsg)}
                    </div>
                    <div className="col-2">
                      <span className="text-wrapper-gray">원</span>
                    </div>
                  </div>
                  <h5 className="text-wrapper-gray pt-1 text-left">입력한 전환 지원금을 적용하시겠습니까?</h5>
                  <div className="d-flex justify-content-center footer-modal border-top-0">
                    <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" data-dismiss="modal" ref={this.closeRef}>취소</button>
                    <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">저장</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
            </Fragment>
        );
    }
}

export default  ConversionModal;