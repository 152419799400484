import React, { Fragment } from 'react'
import { priceFormat } from '../../utils/helper';
import * as constant from '../../utils/constant';
import './PaymentPackageList.scss'

const PaymentPackageList = ({pricePackages, activePlan, hover = false, onClickDetail = null}) => (
    <Fragment>
        <div className="title-form mt-5">
            <h2>
                서비스 요금제 정보
            </h2>
        </div>
        <div className="payment-package-list">
            {pricePackages.data.map((item, i) =>
                <Fragment key={i}>
                    <div
                        className={`payment-package-item text-center${hover ? ' hover' : ''}` + (activePlan && parseInt(activePlan.packageID ?? activePlan.package_id ?? activePlan.pp_id) == item.pp_id ? " active" : "")}
                        onClick={() => onClickDetail ? onClickDetail(item) : null}
                        >
                        <div className="total-top">
                            <h4>{item.pp_name}</h4>
                            <h5 className="prices">
                                <span className="pr-1">월</span>
                                {priceFormat(item.pp_price)}
                                <span className="pl-1">원</span>
                            </h5>
                            <span className="vat">(VAT포함)</span>
                        </div>
                        <ul className="list-status">
                            <li>동시 로그인 허용 개수 : {item.pp_use_time}</li>
                        </ul>
                    </div>
                </Fragment>
            )}
            {/*<div className="box-item">
                <div className="total-top"></div>
                <ul className="list-title">
                    <li>동시 로그인 허용 개수</li>
                    <li>정산관리 (판매일보)</li>
                    <li>일정알림</li>
                </ul>
            </div>
            {pricePackages.data.map((item, i) =>
                <Fragment key={i}>
                    <div className={"box-item " + (auth.storePlan && parseInt(auth.storePlan.packageID) == item.pp_id ? "active" : "")}  >
                        <div className="total-top">
                            <h4>{item.pp_name}</h4>
                            <h5 className="prices">
                                <span>월</span>
                                {priceFormat(item.pp_price)}
                                <span>원</span>
                            </h5>
                            <span className="vat">(VAT포함)</span>
                        </div>
                        <ul className="list-status">
                            <li>{item.pp_use_time}</li>
                            <li>
                                {item.isReport == constant.PAYMENT.CHECKED_REPORT ? (<img src="/images/true-01.svg" alt="" />) : (<img src="/images/fail-01.svg" alt="" />)}
                            </li>
                            <li>
                                {item.isAlarm == constant.PAYMENT.CHECKED_ALARM ? (<img src="/images/true-01.svg" alt="" />) : (<img src="/images/fail-01.svg" alt="" />)}
                            </li>
                        </ul>
                        <div className="check-status">
                            <div className="check">
                                <div className="icon-check"></div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}*/}
        </div>
    </Fragment>
)

export default PaymentPackageList
