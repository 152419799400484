import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, getFormAsyncErrors } from 'redux-form';
import validate from './validate-step-2';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    loginAction,
} from '../../../store/actions/auth';
import * as routerName from '../../../utils/routerName';
import api from "../../../utils/api";
import { SubmissionError } from 'redux-form';
import asyncValidate from './validate-step-2-async';
import { getFirstNumberPhone, getStoreFirstNumberPhone } from '../../../utils/helper';
import * as constant from '../../../utils/constant';
import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import renderSelectField from '../../../components/FormHelper/SelectField';

const normalize3Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
}
const normalize5Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 5) {
        return onlyNums
    }
}
const normalize2Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
}

const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length < 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length == 7) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3,7)
    }
    if (onlyNums.length == 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4,8)
    }
}

const normalizeZip = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 10) {
        return onlyNums
    }
}
const normalizePassword = (value) => {
    if (!value) {
        return value
    }
    if (value.length <= 14) {
        return value
    }
}
const normalizeSixNumber = (value) => {
    if (!value) {
        return value
    }
    // const onlyNums = value.replace(/[^\d]/g, '')
    const onlyNums = value;
    if (onlyNums.length <= 6) {
        return onlyNums
    }
}
class SignUpStep2Form extends Component {

    componentDidMount() {
    }

    onClickSelectedOptionRule = () => {
        setTimeout(() => {
            if(this.props.SignUpStep2.rulesChecked == true) {
                this.props.change('ruleOption2', true)
                this.props.change('ruleOption1', true)
            } else {
                this.props.change('ruleOption2', false)
                this.props.change('ruleOption1', false)
            }
        });
    }

    onClickUnCheckRule = () => {
        setTimeout(() => {
            if(this.props.SignUpStep2.ruleOption2 == true && this.props.SignUpStep2.ruleOption1 == true ) {
                this.props.change('rulesChecked', true)
            } else {
                this.props.change('rulesChecked', false)
            }
            
        });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <section className="form-register">
            <form  autoComplete="on" onSubmit={handleSubmit}>
                <div className="content-form form-register">
                    <div className="container">
                        <div className="title-form">
                            <h2>
                                기본 정보
                            </h2>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="" className="label-text">아이디</label>
                            <Field
                                placeholder="아이디"
                                name="username"
                                type="text"
                                component={renderTextField}
                            />
                            <small className="form-text text-muted small-text">4 ~10 자</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="" className="label-text">비밀번호</label>
                            <Field
                                placeholder="비밀번호"
                                name="password"
                                type="password"
                                normalize={normalizePassword}
                                component={renderTextField}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="" className="label-text">비밀번호 확인</label>
                            <Field
                                placeholder="비밀번호 확인"
                                name="repassword"
                                type="password"
                                normalize={normalizePassword}
                                component={renderTextField}
                            />
                        </div>

                        <div className="title-form mt-5">
                            <h2>
                                매장 정보
                            </h2>
                        </div>

                        {this.props.oldMember && this.props.SignUpStep2?.business_type == 1 && <div className={"form-group " + (this.props.SignUpStep2.business_type == constant.BUSINESS_ORTHER ? "d-none" : "")}>
                            <label htmlFor="" className="label-text">사업자 등록번호</label>
                            <div className={"row verify"}>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number1[0]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize3Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep2.business_type == constant.BUSINESS_ORTHER }
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number1[1]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize2Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep2.business_type == constant.BUSINESS_ORTHER }
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number1[2]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize5Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep2.business_type == constant.BUSINESS_ORTHER }
                                       
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-6 mt-10">
                                    <button className="btn btn-verify h-44" type='button' onClick={() => this.props.onClickVerifyBussiness()}>
                                        사업자 확인
                                    </button>
                                </div>
                            </div>
                            {  this.props.SignUpStep2Meta && this.props.SignUpStep2Meta.registration_number1 && this.props.SignUpStep2Errors && this.props.SignUpStep2Errors.registration_number1_msg ? (
                                <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.SignUpStep2Errors.registration_number1_msg}</p>
                            ) : ''}
                            {this.props.SignUpStep2Errors.registration_number1 && this.props.SignUpStep2Errors.registration_number1.length == 0 ? 
                            (this.props.registration_number.registration_verify_status == true ? 
                                <p style={{color:'green'}}>{this.props.registration_number.registration_verify_msg}</p>
                            :
                                <p style={{color:'#dc3545'}}>{this.props.registration_number.registration_verify_msg}</p>
                            ): ('')}
                            
                        </div>}

                        <div className="form-group mt-4">
                            <label htmlFor="" className="label-text">매장명</label>
                            <Field
                                placeholder="매장명"
                                name="store_name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="" className="label-text">매장 주소</label>
                            <div className="row">
                                <div className="col-md-8 col-7">
                                    <Field
                                        placeholder="우편번호"
                                        name="sto_addr_code"
                                        type="text"
                                        component={renderTextField}
                                        normalize={normalizeZip}
                                    />

                                </div>
                                <div className="col-md-4 col-5 pl-2">
                                    <button className="btn btn-verify h-44" type={'button'} onClick={() => this.props.onClickShowMap()}>
                                        주소찾기    
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-2">
                            <Field
                                placeholder="주소"
                                name="sto_addr_line1"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className="form-group">
                            <Field
                                placeholder="상세주소"
                                name="sto_addr_line2"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="" className="label-text">매장연락처</label>
                            <div className="row">
                                <div className="col-md-3 col-4 pr-2">
                                    <Field
                                        name="first_store_phone"
                                        component={renderSelectField}
                                    >
                                        {getStoreFirstNumberPhone().map((item) => 
                                            <option key={item} value={item}>{item}</option>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-md-9 col-8">
                                    <Field
                                        placeholder="연락처"
                                        name="last_store_phone"
                                        type="text"
                                        component={renderTextField}
                                        normalize={normalizePhone}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="" className="label-text">담당자 이름</label>
                            <Field
                                placeholder="담당자 이름"
                                name="name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="" className="label-text">판매자</label>
                            <div className="row">
                                <div className="col-md-3 col-4 pr-2">
                                    <Field
                                        name="first_user_phone"
                                        component={renderSelectField}
                                    >
                                        {getFirstNumberPhone().map((item) => 
                                            <option key={item} value={item}>{item}</option>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-md-5 col-8">
                                    <Field
                                        placeholder="연락처"
                                        name="last_user_phone"
                                        type="text"
                                        component={renderTextField}
                                        normalize={normalizePhone}
                                        onChange={this.props.onChangeVerifyPhoneClearCountDown}
                                    />
                                </div>
                                <div className="col-md-4 col-12 pl-2">
                                    {/* disabled={this.props.asyncValidating != false ? true : false} */}
                                    <button type="button" className="btn btn-verify h-44 mt-10" onClick={() =>  this.props.onClickCreateVerifySMS() } 
                                    disabled={this.props.asyncValidating !== false || (this.props.SignUpStep2AsyncErrors && !!this.props.SignUpStep2AsyncErrors.last_store_phone)?true:false}>
                                        인증하기
                                        
                                    </button>
                                </div>
                            </div>
                           
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-8 col-7">
                                    <Field
                                        placeholder="인증번호 입력"
                                        name="store_phone_code"
                                        type="text"
                                        component={renderTextField}
                                        normalize={normalizeSixNumber}
                                        onChange={this.props.onChangeVerifyPhoneCode}
                                    />
                                </div>
                                <div className="col-md-4 col-5 pl-2">
                                    <button type="button" className="btn btn-verify h-44" onClick={() =>  this.props.onClickComfirmVerifySMS() } disabled={this.props.verify_phone.verify_phone_status == true}>
                                        확인
                                    </button>
                                </div>
                            </div>
                            { this.props.verify_phone.verify_phone_status == false && this.props.time.s ? ( <p style={{color:'green', fontSize:'80%'}}>인증코드의 유효시간은 {this.props.time.m +':'+ this.props.time.s}  입니다. </p> ): ''  } 
                            {this.props.verify_phone.verify_phone_status == true ? 
                                <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_phone.verify_phone_msg}</p>
                            :
                                <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_phone.verify_phone_msg}</p>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="label-text">이메일</label>
                            <Field
                                placeholder="이메일"
                                name="email"
                                type="text"
                                component={renderTextFieldAsync}
                            />
                        </div>
                        {this.props.oldMember && this.props.SignUpStep2?.business_type == 1 && <div className="form-group">
                            <label htmlFor="" className="label-text">계산서 발행 이메일 주소</label>
                            <Field
                                placeholder="계산서 발행 이메일 주소"
                                name="business_email1"
                                type="text"
                                component={renderTextField}
                            />
                        </div>}
                        {!this.props.oldMember && <Fragment>
                            <div className="title-form mt-5">
                                <h2>기타 정보</h2>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="" className="label-text">모비고 아이디 (선택)</label>
                                <div className="row">
                                    <div className="col-md-8 col-7">
                                        <Field
                                            placeholder="사용 중인 모비고 아이디 입력"
                                            name="user_id"
                                            type="text"
                                            component={renderTextField}
                                            onChange={this.props.onChangeVerifyOldMember}
                                        />
                                    </div>
                                </div>
                                {this.props.verify_old_member.verify_status === true ? 
                                    <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_id}</p>
                                :
                                    <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_id}</p>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="label-text">모비고 비밀번호 (선택)</label>
                                <div className="row">
                                    <div className="col-md-8 col-7">
                                        <Field
                                            placeholder="사용 중인 모비고 비밀번호 입력"
                                            name="user_pw"
                                            type="password"
                                            component={renderTextField}
                                            onChange={this.props.onChangeVerifyOldMember}
                                        />
                                    </div>
                                    <div className="col-md-4 col-5 pl-2">
                                        <button
                                            type="button"
                                            className="btn btn-verify h-44"
                                            onClick={this.props.onClickVerifyOldMember}>
                                            {/*disabled={this.props.verify_old_member.verify_status}>*/}
                                            인증하기
                                        </button>
                                    </div>
                                </div>
                                {
                                    this.props.verify_old_member.verify_msg_user_pw && (
                                        this.props.verify_old_member.verify_status === true ? 
                                            <p style={{color:'green', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_pw}</p>
                                        :
                                            <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.verify_old_member.verify_msg_user_pw}</p>
                                    )
                                }
                                <p style={{fontSize:'80%'}} className="pl-1 pt-1">모비고 유료 이용중인 고객님께 다양한 혜택이 제공됩니다.</p>
                            </div>
                        </Fragment>}

                        <div className="form-group form-check pl-0 mt-3 mb-2">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <Field
                                    type="checkbox"
                                    id="rulesChecked"
                                    name="rulesChecked"
                                    component={renderCheckboxField}
                                    defaultChecked={false}
                                    onChange={() => this.onClickSelectedOptionRule()}
                                />
                                <label className="custom-control-label" htmlFor="rulesChecked">
                                전체 약관동의
                                </label>
                            </div>
                        </div>
                        <div className="form-group form-check pl-0 mb-2">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <Field
                                    type="checkbox"
                                    id="ruleOption1"
                                    name="ruleOption1"
                                    component={renderCheckboxField}
                                    defaultChecked={false}
                                    onChange={() => this.onClickUnCheckRule()}
                                />
                                <label className="custom-control-label" htmlFor="ruleOption1"></label>
                                <a onClick={() => this.props.popupShowServiceRules(1)} className="rulehref">이용약관 </a>
                                <label className={'font-14'} htmlFor="ruleOption1">
                                     동의
                                </label>
                            </div>
                        </div>
                        <div className="form-group form-check pl-0 mb-2">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <Field
                                    type="checkbox"
                                    id="ruleOption2"
                                    name="ruleOption2"
                                    component={renderCheckboxField}
                                    defaultChecked={false}
                                    onChange={() => this.onClickUnCheckRule()}
                                />
                                <label className="custom-control-label" htmlFor="ruleOption2"></label>
                                <a onClick={() => this.props.popupShowServiceRules(2)} className="rulehref">개인정보취급방침 </a>
                                <label className={'font-14'} htmlFor="ruleOption2">
                                    동의
                                </label>
                                {
                                    this.props.SignUpStep2Meta.rulesChecked && this.props.SignUpStep2Meta.rulesChecked.touched == true && this.props.SignUpStep2Errors.rulesChecked !== undefined ?
                                        (<div className="invalid-feedback d-block">{this.props.SignUpStep2Errors.rulesChecked}</div>)
                                        : ''
                                }

                            </div>
                        </div>

                        <div className="group-button">
                            <button className="btn btn-close-register" type="button" onClick={this.props.redirectLogin}>취소</button>
                            <button className="btn btn-next" type="submit">가입완료</button>
                        </div>
                    </div>
                </div>
            </form>
            </section>
        );
    }
}

SignUpStep2Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        SignUpStep2: getFormValues('SignUp')(state),
        SignUpStep2Errors: getFormSyncErrors('SignUp')(state),
        SignUpStep2Meta: getFormMeta('SignUp')(state),
        SignUpStep2AsyncErrors: getFormAsyncErrors('SignUp')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'SignUp';


const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
    asyncValidate,
    asyncBlurFields: ['username'],
    // asyncChangeFields: ['first_store_phone', 'last_store_phone']
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(SignUpStep2Form);
