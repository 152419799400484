import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {reduxForm, getFormSyncErrors, getFormValues, change, SubmissionError,Field} from 'redux-form';
import { Modal } from 'reactstrap';
import _ from "lodash";
import api from "../../../utils/api";
import renderTextField from "../../FormHelper/TextField";
import $ from "jquery";


class SelerCreateModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sellers: {}
    }

  }

    handleCreateSeller = (values) => {
        let nameValue = {name:values.name}
        return api.auth.createSeller(nameValue).then(res => {
            this.props.onOpened()
            this.props.openPopup()
        }).catch(err => {

        })
    }

  onOpened = () => {
      let data = {
          paginate: 999999,
      }

      if(this.props.WiredAdvice?.name){
          this.props.change('name','')
      }

  }

  render() {
      const {handleSubmit, pristine, reset, submitting, invalid, formErrors, formValues, metaForm} = this.props;

    return (
      <Modal
        isOpen={this.props.isOpenModal}
        // backdrop={"static"}
        toggle={this.props.openPopup}
        onOpened = {this.onOpened}
        fade={true}
        className={'modal-dialog modal-dialog-centered modall-add-seller'}
        contentClassName=""
      >
          <div className="modal-header border-bottom-0">
              <div className="header_modal">
                  <h5 className="modal-title font-18">{"판매자 추가"}</h5>
              </div>
              <button type="button" className="close btn" onClick={this.props.openPopup}
                      data-dismiss="modal"
                      aria-label="Close">
                  <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
              </button>
          </div>
          <div className="modal-body px-0">
              <form onSubmit={handleSubmit(this.handleCreateSeller)}>
                  <div className="form-row">
                      <div className="col-md-12 mb-3">
                          <label className="label-text">성명</label>
                          <Field
                              id="name"
                              label="name"
                              name="name"
                              placeholder="성명"
                              className={"form-control h-44"}
                              component={renderTextField}
                          />
                      </div>
                  </div>
                  <div className="modal-footer justify-content-center border-top-0">
                      <button className="btn btn-gray btn_close_create" onClick={this.props.openPopup}
                              data-dismiss="modal">취소</button>
                      <button className="btn btn-submit"
                              type="submit">저장</button>
                  </div>
              </form>
          </div>
      </Modal>
    );
  }
}

SelerCreateModal.propTypes = {
  openPopup: PropTypes.func,
  auth: PropTypes.object,
  WirelessAdvice: PropTypes.object,
  isOpenModal: PropTypes.any,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
      WiredAdvice: getFormValues('WiredAdvice')(state),
      WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WiredAdvice';

const initialValues = {
    'name': '',
}

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
    initialValues: initialValues,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(SelerCreateModal);
