import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {reduxForm, getFormSyncErrors, getFormValues, reset, change} from 'redux-form';
import RecentConsultationModal from './RecentConsultation/RecentConsultationModal'
import ResetModal from './Reset/ResetModal'
import PrintModal from './Print/PrintModal'
import CheckSalerNamePopup from "./ReportSale/CheckSalerNamePopup";
import PotentialCustomersModal from './PotentialCustomers/PotentialCustomersModal'
import CreatePotentialCustomersModal from './PotentialCustomers/CreatePotentialCustomersModal'
import ConfirmReportModal from "./ReportSale/ConfirmReportModal";
import ShareWebModal from "../ShareWebModal";
import { CONSULTANT_WIRELESS } from "../../utils/constant";
import { checkBlockFeature } from "../../utils/helper";
import _ from "lodash";
import { ReactComponent as IconReset } from './../../assets/images/icon-reset.svg';
import { ReactComponent as IconShare } from './../../assets/images/share.svg';
import { ReactComponent as IconPrint } from './../../assets/images/print.svg';
import { ReactComponent as IconEdit } from './../../assets/images/edit.svg';
import { ReactComponent as IconQuery } from './../../assets/images/query.svg';
import { ReactComponent as IconDocument } from './../../assets/images/document.svg';
import { ReactComponent as Iconpeople } from './../../assets/images/people.svg';
import PopupInactive from '../PopupInactive';
import CheckInfoConsultantPopup from "./ReportSale/CheckInfoConsultantPopup";
import Tooltip from "./../Tooltip";   
import { getGlobalDataAuth } from '../../store/actions/dataStore'
import ComparisonSubsidies from './ComparisonSubsidies/ComparisonSubsidies';

class WirelessFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenModalReset: false,
      isOpenModalRecentConsultation: false,
      isOpenModalPrint: false,
      isOpenModalPotentialCustomers: false,
      isOpenModalCreatePotentialCustomers: false,
      isOpenModalReportSale: false,
      currentDevice  : "web",
      isOpenModalShareWeb : false,
      isOpenCheckSalerNamePopup : false,
      isOpenCheckInfoConsultantPopup : false,
      isDisableReport: false,
      isOpenPopupDisable: false,
      isOpenComparisonSubsidies : false,
      title: null,
      content: null,
      iconLock: null,
    }
  }


  componentDidUpdate(prevProps) {
    const {WirelessAdvice: prevWirelessAdvice} = prevProps
    const {WirelessAdvice} = this.props
    if(WirelessAdvice?.id != prevWirelessAdvice?.id && WirelessAdvice.id == null) {
      this.calResetForm()
    }
  }

  hideReport = () => {
    this.setState({
      isDisableReport: true,
      isOpenModalReportSale: !this.state.isOpenModalReportSale
    });
  }

  openPopupopenPopupReset = () =>
    this.setState({ isOpenModalReset: !this.state.isOpenModalReset })

  openPopupRecentConsultation = () =>
    this.setState({ isOpenModalRecentConsultation: !this.state.isOpenModalRecentConsultation })

  openPopupPrint = () =>
    this.setState({ isOpenModalPrint: !this.state.isOpenModalPrint })

  openPopupPotentialCustomers = () => {
    if (checkBlockFeature()) {
      this.showWaitingPopup()
    } else {
      this.setState({ isOpenModalPotentialCustomers: !this.state.isOpenModalPotentialCustomers })
    }
  }

  openPopupCreatePotentialCustomers = () => {
    if (checkBlockFeature()) {
      this.showWaitingPopup()
    } else {
      this.setState({ isOpenModalCreatePotentialCustomers: !this.state.isOpenModalCreatePotentialCustomers })
    }
  }

  openPopupReportSale = () => {
    if (checkBlockFeature()) {
      this.showWaitingPopup()
    } else {
      this.setState({ isOpenModalReportSale: !this.state.isOpenModalReportSale })
    }
  }
    openPopupCheckInfoConsultantPopup = () => {
        if (checkBlockFeature()) {
            this.showWaitingPopup()
        } else {
            this.setState({ isOpenCheckInfoConsultantPopup: !this.state.isOpenCheckInfoConsultantPopup })
        }
    }

  openPopupCheckSalerNamePopup = () => {
    if (checkBlockFeature()) {
      this.showWaitingPopup()
    } else {
      this.setState({ isOpenCheckSalerNamePopup: !this.state.isOpenCheckSalerNamePopup })
    }
  }

  calResetForm = () => {
      let keys = [
          'phone',
          'startingPrice',
          'packageFee',
          'basicCost',
          'disclosureSubsidy',
          'optionalAgreement',
          'interestRate',
          'conversion2G',
          'optionalAgreementWith2G',
          'disclosureSubsidyDefault',
          'welfareDiscount',
          'insurrance',
          'insurranceCancelDate',
          'optionalAgreementDefault',
          'discost12',
          'discost24',
          'discostChange',
          'displayApplyDate',
          'rateAgreement',
          'contractMonth',
          'saler',
          'customerId',
          'customerNote',
      ]
      let WirelessStep3 = _.pick(this.props.WirelessAdvice.WirelessAdviceStep3, ...keys)
      let step3 = {
          ...CONSULTANT_WIRELESS,
      }
      this.props.change('WirelessAdviceStep3', step3)
      this.props.change('WirelessAdviceStep3Default.startingPrice', 0) // bo sung khi reset 5/5/2021
      this.props.change('CreatePotentialCustomerDefault',{
          id: '',
          name: '',
          phone: '',
          phoneFormat: '',
          note: '',
          topPhone: '010',
          statusCreate:false
      })
      // this.props.reset('WirelessAdvice');
      // this.props.setPage(1)
      this.setState({
          isDisableReport: false
      })
  }

  resetForm = () => {
    this.props.change('id', null)

  }

  showWaitingPopup = () => {
    this.setState({
      isOpenPopupDisable: true,
      iconLock: 'iconLock',
    })
  }

  shareDeviceWeb = () => {
    //if (checkBlockFeature()) {
    //  this.showWaitingPopup()
    //} else {
      if(window.currentDevice === "web") {
        this.setState({
          isOpenModalShareWeb : !this.state.isOpenModalShareWeb
        })
      }
    //}
  }

  openPopupDisable = (state) => {
    if (state) {
      this.setState({
        title: '실제 개통에 사용하지 마십시오.',
        content: `
          베타테스트 기간입니다.<br>
          테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
          서류 미비나 금액 오류 등이 발생할 수 있습니다.
        `
      })
    }
    this.setState({
      isOpenPopupDisable: true
    })
  }

  closePopupDisableHandle = () => {
    this.setState({
      isOpenPopupDisable: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          iconLock: null
        })
      }, 500)
    })
  }

  modelPrintClose = () => {
    this.setState({
      title: null,
      content: null
    })
  }

  render() {
    const { WirelessAdvice } = this.props;
    return (
      <Fragment>
        <div className="group-button-wireless">
          <button className="btn btn-wireless" onClick={this.openPopupopenPopupReset}>
            <IconReset className="mr-1" />
            초기화
          </button>
          <button className="btn btn-wireless" onClick={this.openPopupRecentConsultation}>
          {/* <i className="sprite sprite-time-line"></i> */}
          <IconQuery className="mr-1" />
            최근상담
          </button>
          {/*
          <button className="btn btn-wireless" onClick={this.openPopupCreatePotentialCustomers}>
            <IconDocument className="mr-1" />
            가망고객등록
          </button>
          <button className="btn btn-wireless"  onClick={this.openPopupPotentialCustomers}>
            <Iconpeople className="mr-1" />
            가망고객
          </button>
          */}
          <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
            <button className="btn btn-wireless">
              <IconDocument className="mr-1" />
              가망고객등록
            </button>
          </Tooltip>
          <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
            <button className="btn btn-wireless">
              <Iconpeople className="mr-1" />
              가망고객
            </button>
          </Tooltip>
{/*
          <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
            <button className="btn btn-wireless">
              <IconShare className="mr-1" id={'nativeShareBtnIMG'} />
              공유
            </button>
          </Tooltip>
          ['Admin'].indexOf(this.props.auth.role) !== -1
 */}
          <button
            className="btn btn-wireless"
            id={WirelessAdvice.id ? "nativeShareBtn" : ''}
            data-share-content={process.env.REACT_APP_HOSTNAME+'/popup-wirelees?id='+WirelessAdvice.id+'&share=1'}
            onClick={WirelessAdvice.id ? this.shareDeviceWeb : null}  
          >
            <IconShare className="mr-1" id={'nativeShareBtnIMG'} />
            공유
          </button>
          <button
            className="btn btn-wireless"
            // onClick={() => this.openPopupDisable(true)}
            onClick={WirelessAdvice.id ? this.openPopupPrint : null}
          >
           {/* <i className="sprite sprite-icon-16"></i> */}
           <IconPrint className="mr-1" />
            출력
          </button>
          {/*
          <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
            <button className="btn btn-wireless">
              <IconEdit className="mr-1"   />
              지원금비교
            </button>
          </Tooltip>
          
          <button className="btn btn-wireless"  onClick={() => {this.setState({ isOpenComparisonSubsidies : true })}}>
            <Iconpeople className="mr-1" />
            지원금비교
          </button>
          */}
          {/*
          <button className="btn btn-wireless"
                  onClick={_.isEmpty(WirelessAdvice.WirelessAdviceStep3.phone) || _.isEmpty(WirelessAdvice.WirelessAdviceStep3.packageFee)
                      ? this.openPopupCheckInfoConsultantPopup
                      :  (
                          WirelessAdvice.WirelessAdviceStep3?.saler?.name
                          ? this.openPopupReportSale
                          : this.openPopupCheckSalerNamePopup )}
                  disabled={this.state.isDisableReport ? true : false}>
          <IconEdit className="mr-1"   />
            판매완료
          </button>
          */}
          <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
            <button className="btn btn-wireless">
              <IconEdit className="mr-1"   />
              판매완료
            </button>
          </Tooltip>
        </div>
        <ResetModal
          openPopup={this.openPopupopenPopupReset}
          isOpenModal={this.state.isOpenModalReset}
          resetForm={this.resetForm}
        />
        <RecentConsultationModal
          openPopup={this.openPopupRecentConsultation}
          isOpenModal={this.state.isOpenModalRecentConsultation}
        />
        <PrintModal
          openPopup={this.openPopupPrint}
          isOpenModal={this.state.isOpenModalPrint}
        />
        <PotentialCustomersModal
          openPopup={this.openPopupPotentialCustomers}
          isOpenModal={this.state.isOpenModalPotentialCustomers}
          openPopupCreate={this.openPopupCreatePotentialCustomers}
          isOpenModalCreate={this.state.isOpenModalCreatePotentialCustomers}
        />
        <CreatePotentialCustomersModal
          openPopup={this.openPopupCreatePotentialCustomers}
          isOpenModal={this.state.isOpenModalCreatePotentialCustomers}
          openPopupList={this.openPopupPotentialCustomers}
        />
        <ConfirmReportModal
            openPopup={this.openPopupReportSale}
            isOpenModal={this.state.isOpenModalReportSale}
            hideReport={this.hideReport}
        />

        <CheckSalerNamePopup
            openPopup={this.openPopupCheckSalerNamePopup}
            isOpenModal={this.state.isOpenCheckSalerNamePopup}
        />
      <CheckInfoConsultantPopup
          openPopup={this.openPopupCheckInfoConsultantPopup}
          isOpenModal={this.state.isOpenCheckInfoConsultantPopup}
      />
        <ShareWebModal
          isOpenModal={this.state.isOpenModalShareWeb}
          toggle={this.shareDeviceWeb}
          sharelink={process.env.REACT_APP_HOSTNAME + '/share-wireless?id=' +WirelessAdvice.id}
        />
        <PopupInactive 
            isOpenModal={this.state.isOpenPopupDisable} 
            closePopupDisable={this.closePopupDisableHandle}
            title={this.state.title || null}
            content={this.state.content || null}
            callback={(WirelessAdvice.id && this.state.title) ? this.openPopupPrint : null}
            onModelClosed={this.modelPrintClose}
            iconLock={this.state.iconLock}
        />
        {/* 
        <ComparisonSubsidies
          isOpenModal={this.state.isOpenComparisonSubsidies}
          openPopup={() => {this.setState({ isOpenComparisonSubsidies : false });}}
        />
        */}
      </Fragment>
    );
  }
}

WirelessFilter.propTypes = {
  reset: PropTypes.func,
  setPage: PropTypes.func,
  WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGlobalDataAuth: () => dispatch(getGlobalDataAuth()),
    reset: () => dispatch(reset()),
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessFilter);
