import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {reduxForm, getFormSyncErrors, getFormValues, change, Field, SubmissionError} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldCustom from '../../../components/FormHelper/TextField/PhonePotentialCustomer';
import renderTextareaField from '../../../components/FormHelper/TextareaField';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {hideModal, showModal} from "../../../store/actions/modal";
import MessageCreateCustomerModal from './MessageCreateCustomerModal'


const mainSelectPhone = {
    display: "flex",
    alignItems: "center",
};
const selectForm = {
    width: "auto",
    marginRight: "5px"
};
const width100 = {
    width: "100%"
};
const errorStyle = {
    position: "absolute"
};

const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

class CreatePotentialCustomersModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valueSelectForm: '',
            phone: "",
            error:'',
            idCreate:'',
            noteCreate:'',
            modalMessageCreateCustomer:false
        }
        this.refCloseModal = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if(this.props.WiredAdvice?.id != prevProps.WiredAdvice?.id) {
            if(this.props.WiredAdvice?.CreatePotentialCustomer?.statusCreate) {
                change('WiredAdviceStep2.customerId', this.state.idCreate)
                change('CreatePotentialCustomer.id', this.state.idCreate)
                // this.props.change('CreatePotentialCustomer.id', this.state.idCreate)
                // this.props.change('WirelessAdviceStep3.customerId', this.state.idCreate)
                // this.props.change('WirelessAdviceStep3.customerNote', this.state.noteCreate)
            }
        }
    }

    openMessageCreateCustomer = (closeAll = null) => {
        const state=this.state.modalMessageCreateCustomer
        this.setState({
            modalMessageCreateCustomer: !state,
        })
        if(closeAll){
            this.refCloseModal.current.click()
        }
    }

    sendRequest = (data) => {
        const {WiredAdvice: {CreatePotentialCustomer}} = this.props
        if (CreatePotentialCustomer.id) {
            return api.customer.update(CreatePotentialCustomer.id, data)
        }
        return api.customer.create(data)
    }

    submitForm = () => {
        // this.props.change('id', 'response.data.id')
        let {data} = this.state
        const {
            WiredAdvice: {WiredAdviceStep1, CreatePotentialCustomer,CreatePotentialCustomerDefault},
            change,
            // openPopup,
            openPopupList,
        } = this.props

        if(CreatePotentialCustomer?.statusCreate || CreatePotentialCustomerDefault?.statusCreate){
            change('id', null)
        }

        let model_id = 0
        let plan_id = 0
        let telcom_id = WiredAdviceStep1.telcomID
        const currentValue = CreatePotentialCustomer.phone.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if(cvLength <= 9) {
            let phoneData=this.normalizeGetPhone(CreatePotentialCustomer.phone)

            CreatePotentialCustomer.phone = CreatePotentialCustomer.phone != '' ? (this.state.valueSelectForm != '' ? this.state.valueSelectForm : CreatePotentialCustomer.topPhone)  + phoneData : ''

        }else {
            if(cvLength == 11) {
                CreatePotentialCustomer.phone=CreatePotentialCustomer.phone
            }else {
                CreatePotentialCustomer.phone=CreatePotentialCustomer.topPhone != null ? CreatePotentialCustomer.topPhone : '' +CreatePotentialCustomer.phone.substring(0,8)
            }
        }

        data = {
            ...CreatePotentialCustomer,
            model_id,
            plan_id,
            telcom_id,
            typewire:'wired',
            type_action:(CreatePotentialCustomer?.id ? 'edit' : 'create')
        }
        this.props.showLoading()

        return this.sendRequest(data)
            .then(response => {

                if(response?.data?.data?.error){
                    this.popupShowMessageTooMoreCustomer()
                    this.props.hideLoading()
                }else {
                    this.props.hideLoading()
                    let id = response?.data?.data?.id
                    let customerId = response?.data?.data?.user_id
                    change('CreatePotentialCustomerDefault.id', id)
                    change('CreatePotentialCustomerDefault.note', CreatePotentialCustomer.note)
                    // change('CreatePotentialCustomer.customerId', customerId)
                    this.props.change('CreatePotentialCustomerDefault.note', CreatePotentialCustomer.note)
                    change('WiredAdviceStep2.customerId', customerId)

                    // openPopup()
                    setTimeout(() => {
                        // openPopupList()
                        this.openMessageCreateCustomer()
                        this.props.hideLoading()

                        if(CreatePotentialCustomer?.statusCreate){
                            change('WiredAdviceStep2.customerId', id)
                            this.setState({
                                idCreate: id,
                                noteCreate: CreatePotentialCustomer.note,
                            })
                        }
                        this.props.change('CreatePotentialCustomerDefault', {
                            id: id,
                            name: CreatePotentialCustomer?.name,
                            phone: CreatePotentialCustomer?.phone,
                            phoneFormat: CreatePotentialCustomer?.phoneFormat,
                            note: CreatePotentialCustomer?.note,
                            topPhone: CreatePotentialCustomer?.topPhone,
                            statusCreate: false,
                        });
                        this.props.change('CreatePotentialCustomer', {
                            id: '',
                            name: '',
                            phone: '',
                            phoneFormat: '',
                            note: '',
                            topPhone: '010',
                            statusCreate: true,
                        });

                    }, 900)
                }

            })
            .catch(e => {
                this.props.hideLoading()
                if (e.response) {
                    let errors = e.response.data.errors
                    this.setState({
                        error:e.response.data.errors
                    })
                    throw new SubmissionError({CreatePotentialCustomer: errors})

                }
            })
    }

    popupShowMessageTooMoreCustomer = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    죄송합니다.
                </h4>
                <p className="text-center mt-3">
                    가망고객은 최대 20명까지 등록 가능합니다.
                </p>
                <p className="text-center">
                    기등록된 '가망고객' 목록을 정리하신 후 추가 등록해주세요.
                </p>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.closeModalMessage()}>확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }

    closeModalMessage = () => {

        this.props.hideModal()
        this.props.openPopup()

        this.props.change('CreatePotentialCustomer', {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate: false,
        });
        this.props.change('CreatePotentialCustomerDefault', {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate:false
        });
    }

    handleChange = (event) => {
        const {WiredAdvice: { CreatePotentialCustomer}} = this.props
        change('CreatePotentialCustomer.topPhone', event.target.value)
        this.props.change('CreatePotentialCustomer.topPhone', event.target.value)
        this.setState({
            valueSelectForm: event.target.value
        }, () => {

        })
    }

     normalizeInputs = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (value.length > 0) {
            if (cvLength <= 4) return currentValue;
            if (cvLength > 4 && cvLength <= 8 ) return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}`;
            if (cvLength > 4 && cvLength > 8) return 'error';
        }
    };

    normalizeGetPhone = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (value.length > 0) {
             return `${currentValue.slice(0, 8)}`;
        }
    };

    normalizePhone = (value) => {
        if (!value) {
            return value
        }
        const onlyNums = value.replace(/[^\d]/g, '')
        if (onlyNums.length <= 3) {
            return onlyNums
        }
        if (onlyNums.length < 7) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
        }
        if (onlyNums.length == 7) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3,7)
        }
        if (onlyNums.length == 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4,8)
        }
        if (onlyNums.length > 4 && onlyNums.length > 8) return 'error';
    }

    handleChangeInput = (event) => {
        event.preventDefault()
        let value=event.target.value
        const {WiredAdvice: { CreatePotentialCustomer}} = this.props
        let data=this.normalizePhone(value)
        if(data != 'error') {
            const currentValue = value.replace(/[^\d]/g, '');
            this.props.change('CreatePotentialCustomer.phoneFormat', data)
            this.props.change('CreatePotentialCustomer.phone', currentValue)
            this.setState({
                error:''
            })
        }else {
            this.setState({
                error:{phone:'전화번호는 8자만 입력 가능합니다'}
            })
        }

    }

    notActive =(event) => {
        event.preventDefault()
    }



    callSetState = (data) => {
        this.setState({
            valueSelectForm: data
        }, () => {

        })
    }

    render() {
        const {isOpenModal, openPopup, handleSubmit,WiredAdvice: { CreatePotentialCustomer}} = this.props
        const {error}=this.state
        return (
            <Fragment>
                <Modal
                    isOpen={isOpenModal}
                    toggle={openPopup}
                    fade={true}
                    // backdrop={"static"}
                    className={'modal-dialog modal-dialog-centered modal-lg modal-query'}
                >

                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className="modal-header border-bottom-0">
                            <div className="header_modal">
                                <h5 className="modal-title">가망고객 등록 </h5>
                            </div>
                            <button type="button" className="close close-modal" onClick={openPopup}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body mb-3">
                            <div className="form-group">
                                <label className="label-text">고객명</label>
                                <Field
                                    type="text"
                                    className="form-control h-44"
                                    name="CreatePotentialCustomer.name"
                                    placeholder="고객명"
                                    component={renderTextField}
                                />
                            </div>
                            <div className="form-group">
                                <label className="label-text">연락처</label>
                                <div className="main-select-phone" style={mainSelectPhone}>
                                    <select className="form-control h-44" style={selectForm}
                                            value={CreatePotentialCustomer.topPhone != 0 ? CreatePotentialCustomer.topPhone : CreatePotentialCustomer.phone.substring(0, 3)} onChange={this.handleChange} name="CreatePotentialCustomer.topPhone" component={renderTextField}>

                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select>
                                    <div className="fill_input">
                                        <Field
                                            type="text"
                                            className="form-control h-44"
                                            name="CreatePotentialCustomer.phoneFormat"
                                            placeholder="연락처"
                                            errorStyle={errorStyle}
                                            component={renderTextFieldCustom}
                                            onChange={this.handleChangeInput}
                                            onBlur={this.notActive}
                                            errorPhone={error}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="label-text">메모</label>
                                <Field
                                    className="form-control"
                                    name="CreatePotentialCustomer.note"
                                    placeholder="메모"
                                    rows="3"
                                    component={renderTextareaField}
                                />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top-">
                            <button
                                ref={this.refCloseModal}
                                type="button"
                                className="btn btn-gray"
                                onClick={openPopup}
                            >
                                취소
                            </button>
                            <button
                                type="submit"
                                className="btn btn-submit"
                            >
                                저장
                            </button>
                        </div>
                    </form>

                </Modal>
                <MessageCreateCustomerModal isOpenModal={this.state.modalMessageCreateCustomer} openMessageCreateCustomer={this.openMessageCreateCustomer} />
            </Fragment>
        );
    }
}

CreatePotentialCustomersModal.propTypes = {
    isOpenModal: PropTypes.bool,
    openPopup: PropTypes.func,
    WiredAdvice: PropTypes.object,
    CreatePotentialCustomer: PropTypes.object,
    change: PropTypes.func,
    handleSubmit: PropTypes.func,
    openPopupList: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        telcomParents: state.dataStore.telcomProviders.telcomParents,
        CreatePotentialCustomer: getFormValues('CreatePotentialCustomer')(state),
        CreatePotentialCustomerErrors: getFormSyncErrors('CreatePotentialCustomer')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CreatePotentialCustomersModal);
