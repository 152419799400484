
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from './../../../store/actions/ui';
import * as routerName from './../../../utils/routerName';
import { Modal } from 'reactstrap';
import renderHTML from 'react-render-html';
import _ from 'lodash';
import { 

    priceFormat 
} from '../../../utils/helper';

class PhoneInsurancePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemChecked: {
                ins_id: 0,
                ins_name: '미적용',
                ins_price: 0,
            },
            // checked: false,
        }
    }

    componentDidMount() {
    }

    renderClassInsurances = (data) => {
        let xhtml = data && data.map((item, index) => {
            return (
                <li className="nav-item tab-item" key={index}>
                    <a className={`nav-link ${index == 0 && 'active'}`} id={`tab_${item.id}`}  data-toggle="tab" href={`#tab-${item.id}`}
                        role="tab" aria-controls="all12" aria-selected={true}> {item.name}
                    </a>
                </li>       
            )
        })
        return xhtml;
    }

    renderPhoneInsurances = (classInsurances, phoneInsurances) => {       
        let xhtml = classInsurances && classInsurances.map((itemClass, indexClass) => {
            var newList = [];
            phoneInsurances.forEach((itemPhone, indexPhone) => {
                if (itemClass.id == itemPhone.ins_classID) {
                    newList.push(itemPhone)
                }
            }) 
            newList.unshift({
                ins_id: 0,
                ins_name: '미적용',
                ins_price: 0,
                ins_classID: itemClass.id,
            })

            return (
                <div key={itemClass.id} className={`tab-pane fade ${indexClass == 0 && 'show active'}`} id={`tab-${itemClass.id}`}
                    role="tabpanel" aria-labelledby={`tab_${itemClass.id}`}>
                    <article className="form-insurance">
                        <table className={"table table-header"}>
                            <thead>
                                <tr className="bg-purple">
                                    <th>
                                        <div className="col-td">보험명</div>
                                    </th>
                                    <th>
                                        <div className="col-td">상세설명</div>
                                    </th>
                                    {/* <th>
                                        <div className="col-td">대상 단말</div>
                                    </th>
                                    <th>
                                        <div className="col-td">보상한도</div>
                                    </th>
                                    <th>
                                        <div className="col-td">자기부담금</div>
                                    </th>
                                    <th>
                                        <div className="col-td">보상횟수</div>
                                    </th>
                                    <th>
                                        <div className="col-td">기타 설명</div>
                                    </th> */}
                                    <th>
                                        <div className="col-td">서비스 요금</div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className={"table-scroll"}>
                            <table className="table table-striped">
                                <tbody>    
                                    {  this.renderItemPhoneInsurance(newList)}
                                </tbody>
                            </table>
                        </div>
                    </article>
                </div>    
            )
        })
        return xhtml;
    }

    renderItemPhoneInsurance = (data) => {
  
        let xhtml = data && data.map((item, index) => {
            return (
                <tr key={item.ins_id} onClick={() => this.onCheckItem(item)}>
                    <td>
                        <div className="d-flex col-td">
                            <div className="custom-control custom-radio mr-0">
                            <input type="radio" id={`${item.ins_id}_${item.ins_classID}`} name="customRadio" className="custom-control-input" 
                                checked={ `${item.ins_id}_${item.ins_classID}` == `${this.state.itemChecked.ins_id}_${this.state.itemChecked.ins_classID}` ? 'checked' : ''}
                                // onClick={(e) => this.onCheckItem2(e, item)}
                                // onChange={e => {}}
                                />
                                <label className="custom-control-label" for="item00111"></label>
                            </div>
                            <span>{item.ins_name}</span>
                        </div>
                    </td>
                    {
                        item.ins_id ? (
                            <td scope="row">
                                <div className="col-td">
                                    <p>
                                        <strong>대상 단말</strong>
                                        <span> : {item.ins_device_desc} </span>
                                    </p>
                                    <p>
                                        <strong>보상한도</strong>
                                        <span> : {item.ins_limit} </span>
                                    </p>
                                    <p>
                                        <strong>자기부담금</strong>
                                        <span> : {item.ins_self_responsibility} </span>
                                    </p>
                                    <p>
                                        <strong>보상횟수</strong>
                                        <span> : {item.ins_numb} </span>
                                    </p>
                                    <p>
                                        <strong>기타정보</strong>
                                        : { renderHTML(item.ins_extra ? item.ins_extra : '')}
                                    </p>
                                </div>
                            </td>
                        ) : (
                            <td scope="row">
                                <div className="col-td">미적용</div>
                            </td>
                        )
                    }
              
                    <td>
                        <div className="col-td">
                            { priceFormat(parseFloat(item.ins_price)) } 원
                        </div>
                    </td>    
                       
                </tr>     
            )
        })
        return xhtml;
    }

    onCheckItem = (data) => {
        // if (_.isEqual(this.state.itemChecked, data)) {
        //     debugger
        //     this.setState({
        //         itemChecked: {},
        //         checked: false,
        //     }, () => {
        //     })
        // } else {
        //     debugger
        //     this.setState({
        //         itemChecked: data,
        //         checked: true,
        //     }, () => {
        //     })
        // }
        this.setState({
            itemChecked: data,
        }, () => {
        })  
    }

    onAply = () => {       
        this.props.getPhoneInsuranceValue(this.state.itemChecked)      
    }

    onClose = () => {       
        this.props.toggle()
    }

    render() {
        const {classInsurances, phoneInsurances} = this.props
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-dialog-centered table-custom-insurance"
                    contentClassName="pl-5 pr-5"
                       toggle={this.props.toggle}
                       // backdrop={"static"}
                >
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">휴대폰 보험</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={() => this.onClose()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                        </div>
                        <div className="modal-body content-product modal-mobile-insurance pl-0 pr-0 pb-2">
                        <form>
                            <div className="left-product">
                            <div className="top">
                                <ul className="nav nav-tabs list-tab w-100 justify-content-between" id="myTabsa" role="tablist">
                                    {this.renderClassInsurances(classInsurances)}                                  
                                </ul>
                            </div>                           

                            <div className="tab-content" id="myTabCoantendt">
                                {this.renderPhoneInsurances(classInsurances, phoneInsurances)}
                            </div>                            
                            </div>
                            <div className="modal-footer justify-content-center border-top-0 mt-4">
                            <button type="button" className="btn btn_close" data-dismiss="modal" onClick={() => this.onClose()}>취소</button>
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={this.onAply} >완료</button>
                            </div>
                        </form>
                    </div>
                </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
  };
  
  const FORM_NAME = 'WirelessAdvice';
  
  const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
  });
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  
  export default compose(
    withConnect,
    withReduxForm,
  )(PhoneInsurancePopup);
