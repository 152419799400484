
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta } from 'redux-form';
import validate from './validate-step-2';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    loginAction,
} from '../../../store/actions/auth';
import * as routerName from '../../../utils/routerName';
import api from "../../../utils/api";
import { SubmissionError } from 'redux-form';
import { getFirstNumberPhone } from '../../../utils/helper';
import Moment from 'react-moment';

class ForgotIdStep2Form extends Component {

    componentDidMount() {
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <form >
                    <div className="result-search">
                        {this.props.users.map((item, i) => 
                            <div className="row item-result" key={i} onClick={() => this.props.onChangeChecked(item)}>
                                <div className="col-5 pl-0">
                                    <div className="left">
                                        <div className="custom-control custom-radio mr-0">
                                            <input type="radio" checked={this.props.userChecked.id == item.id ? true : false} id={'radio-forgot'+i} className="custom-control-input" onChange={() => {}}/>
                                            <label className="custom-control-label" htmlFor={'radio-forgot'+i}></label>
                                        </div>
                                        <span>ID</span>
                                        <span className="id-number">{item.username}</span>
                                    </div>
                                </div>
                                <div className="col-7 pr-0">
                                    <p className="right">등록날짜 <span> <Moment format="YYYY.MM.DD">
                                                {item.created_at}
                                            </Moment></span></p>
                                </div>
                            </div>
                        )}
                        
                    </div>
                    <div className="submit-form">
                        <button className="btn btn-submit pr-5 pl-5" type="button"  onClick={() => this.props.history.push({ pathname: '/' + routerName.SIGN_IN, state: { username: this.props.userChecked.username }})}>
                            로그인
                        </button>
                        <button className="btn btn-submit pr-5 pl-5" type="button"  onClick={() => this.props.history.push({ pathname: '/' + routerName.FORGOT_PASSWORD, state: { username: this.props.userChecked.username }})}>
                            비밀번호 찾기
                        </button>
                    </div>
                    <p className="text-retrieval">비밀번호가 기억나지 않으시면 <a onClick={() => this.props.history.push({ pathname: '/' + routerName.FORGOT_PASSWORD, state: { username: this.props.userChecked.username }})}>비밀번호찾기</a> 를 선택해주세요.</p>
                </form>
            </Fragment>
        );
    }
}

ForgotIdStep2Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        ForgotIdStep2: getFormValues('ForgotId')(state),
        ForgotIdStep2Errors: getFormSyncErrors('ForgotId')(state),
        ForgotIdStep2Meta: getFormMeta('ForgotId')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'ForgotId';


const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotIdStep2Form);
