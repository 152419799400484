import React from 'react';
import {Modal} from 'reactstrap';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
import OpenPopupTextDetail from "./OpenPopupTextDetail";
import OpenPopupCondition from "./OpenPopupCondition";
import OpenPopupChangeMonth from "./OpenPopupChangeMonth";
import OpenPopupDiscountCardDetail from "./OpenPopupDiscountCardDetail";
import OpenPopUpDiscountCardChangeMoney from "./OpenPopUpDiscountCardChangeMoney";
import OpenPopupPrint from "./OpenPopupPrint";
import OpenPopupDiscountCard from "./OpenPopupDiscountCard";
const PUBLIC_FOLDER = process.env.REACT_APP_API_PUBLIC_FOLDER;

class OpenPopupDiscountCardDetailChildren extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenPopupTextDetail : false,
            dataTextDetail : '',
            isOpenPopupCondition : false,
            dataTextCondition  : '',
            moneyCondition : 0,
            //
            isOpenPopupChangeMonth : false,
            dataTextMonth : 1,
            moneyExcept : null,


            // modal change money
            isPopupDiscountCardChangeMoney : false,
            moneyTotal : '',

            // modal detail
            isPopupDiscountCardDetail : false,

            //open popup print
            isOpenPopupPrint : false,

            note: false, // hien thi chu do modal
            advanced_discount_card_option : '',
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.itemData && prevProps.itemData.dcc_id != this.props.itemData.dcc_id) {
            this.setState({
                moneyTotal: ''
            })
        }
    }

    openPopupPrint = () => this.setState({ isOpenPopupPrint : !this.state.isOpenPopupPrint })

    openPopupDiscountCardDetail = () => this.setState({isPopupDiscountCardDetail: !this.state.isPopupDiscountCardDetail})

    openPopupTextDetail = (text, note) => {
        this.setState({
            isOpenPopupTextDetail : !this.state.isOpenPopupTextDetail,
            note
        },()=>{
            if(this.state.isOpenPopupTextDetail){
                this.setState({
                    dataTextDetail : text
                })
            }
        })
    }

    openPopupCondition = () => {
        this.setState({
            isOpenPopupCondition : !this.state.isOpenPopupCondition
        })
    }

    changeStateDataCondition = (data) => {
        if(data.state == 'condition_discount'){
            this.setState({
                dataTextCondition : data.condition+`, 월 ${priceFormat(data.money)} 원 할인`,
                moneyCondition : data.money,
                moneyTotal : '',
                moneyExcept : null
            })
        }else if (data.state == 'condition_promotion'){
            let moneyCondition = null;
            let moneyTotal     = '';
            let moneyExcept    = null;

            if(data.except){
                if(this.state.dataTextMonth == 36){
                    moneyTotal = (Number(data.money) * 24) + (Number(data.except) * 12);
                }
                else{
                    moneyTotal = '';
                }
                
                moneyCondition = data.money;
                moneyExcept    = data.except;
            }
            else{
                moneyCondition = data.money;
                moneyTotal     = '';
                moneyExcept    = null;
            }

            this.setState({
                dataTextCondition : '[프로모션] '+data.condition+`, 월 ${priceFormat(data.money)} 원 할인`,
                moneyCondition : moneyCondition,
                moneyTotal : moneyTotal,
                moneyExcept : moneyExcept
            })
        }else{
            this.setState({
                dataTextCondition : '',
                moneyCondition : 0,
                moneyTotal : '',
                moneyExcept : null
            })
        }

        if (this.props.type == 'wired_discount_card') {
            if (data.state == 'condition_discount') {
                this.props.textCondition(data && data.condition ? data.condition : '');
            }
            else if (data.state == 'condition_promotion') {
                this.props.textCondition(data && data.condition ? '[프로모션] '+data.condition : '');
            }
            else this.props.textCondition('');
        }
        this.setState({
            advanced_discount_card_option : data
        })

    }

    openPopupChangeMonth= () => {
        this.setState({
            isOpenPopupChangeMonth : !this.state.isOpenPopupChangeMonth
        })
    }

    changeStateDataMonth = (month) => {
        let moneyTotal = '';

        if(month == 36 && this.state.moneyExcept){
            moneyTotal = (Number(this.state.moneyCondition) * 24) + (Number(this.state.moneyExcept) * 12);
        }

        this.setState({
            dataTextMonth : month,
            moneyTotal : moneyTotal
        })
    }

    openPopupDiscountCardChangeMoney = () => {
        this.setState({
            isPopupDiscountCardChangeMoney : !this.state.isPopupDiscountCardChangeMoney
        })
    }

    isSubmitChangeMoney = (money) => {
        this.setState({
            moneyTotal : money
        })
    }

    onOpenedPopup = (itemData) => {
        let {type} = this.props;


        if(itemData.dcc_discount || itemData.dcc_promotion){
            if(itemData.dcc_discount){
                var parseJson = JSON.parse(itemData.dcc_discount)
                if(parseJson.length > 0){

                    if(this.props.type == 'wired_discount_card'){
                        this.props.textCondition(parseJson[0].discount_condition);
                    }


                    this.setState({
                        ...this.state,
                        dataTextCondition : parseJson[0].discount_condition+`, 월 ${priceFormat(parseJson[0].money_condition)} 원 할인`,
                        moneyCondition : parseJson[0].money_condition
                    },()=>{
                        // data text month
                        if(itemData.dcc_installment_month){
                            var parseJsonMonth = JSON.parse(itemData.dcc_installment_month);
                            var monthSelected = parseJsonMonth[0];
                            parseJsonMonth.map(item =>{
                                if(item == 24) {
                                    monthSelected = item
                                }
                            })
                            if(parseJsonMonth.length > 0){
                                this.setState({
                                    ...this.state,
                                    dataTextMonth : type === 'light_and_save_card' ? monthSelected : 1
                                })
                            }
                        }
                    })
                }
            }else if (itemData.dcc_promotion){
                var parseJsonPromotion = JSON.parse(itemData.dcc_promotion)
                if(parseJsonPromotion.length > 0){

                    if(this.props.type == 'wired_discount_card'){
                        this.props.textCondition('[프로모션] '+parseJsonPromotion[0].condition);
                    }

                    this.setState({
                        ...this.state,
                        dataTextCondition : '[프로모션] '+parseJsonPromotion[0].condition+`, 월 ${priceFormat(parseJsonPromotion[0].money)} 원 할인`,
                        moneyCondition : parseJsonPromotion[0].money
                    },()=>{
                        // data text month
                        if(itemData.dcc_installment_month){
                            var parseJsonMonth = JSON.parse(itemData.dcc_installment_month);
                            var monthSelected = parseJsonMonth[0];
                            parseJsonMonth.map(item =>{
                                if(item == 24) {
                                    monthSelected = item
                                }
                            })
                            if(parseJsonMonth.length > 0){
                                this.setState({
                                    ...this.state,
                                    dataTextMonth : type === 'light_and_save_card' ? monthSelected : 1
                                })
                            }
                        }
                    })
                }
            }else{

                if(this.props.type == 'wired_discount_card'){
                    this.props.textCondition('');
                }

                this.setState({
                    ...this.state,
                    dataTextCondition : '',
                    moneyCondition : 0
                },()=>{
                    // data text month
                    if(itemData.dcc_installment_month){
                        var parseJsonMonth = JSON.parse(itemData.dcc_installment_month);
                        var monthSelected = parseJsonMonth[0];
                        parseJsonMonth.map(item =>{
                            if(item == 24) {
                                monthSelected = item
                            }
                        })
                        if(parseJsonMonth.length > 0){
                            this.setState({
                                ...this.state,
                                dataTextMonth :type === 'light_and_save_card' ? monthSelected : 1
                            })
                        }
                    }
                })
            }
        }

    }

    isSubmit = () => {
        let data = {
            itemData : this.props.itemData,
            total : this.state.moneyTotal ? this.state.moneyTotal : this.state.moneyCondition * this.state.dataTextMonth,
            month : this.state.dataTextMonth,
            dataTextCondition: this.state.dataTextCondition,
            advanced_discount_card_option : this.state.advanced_discount_card_option,
        }

        setTimeout(()=>{
            this.props.isSubmit(data);
        },200)
        this.props.openPopupDiscountCardDetailChildren();
    }

    isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    getHref =  (href) => {
        var l = document.createElement("a");
        if(this.isValidHttpUrl(href)){
            l.href = href;
            let result = l.host
            return result;
        }else{
            return href.split("/")[0]
        }


    }

    render() {
        let {itemData,discountCard} = this.props
        let  money = this.state.moneyTotal ? priceFormat(this.state.moneyTotal) :priceFormat(this.state.moneyCondition * this.state.dataTextMonth);
        let href = '';
        if(itemData.dcc_center_online){
            href = this.getHref(itemData.dcc_center_online);
        }

        return (
            <React.Fragment>
                <Modal
                    className={'modal-dialog-centered modal-detail-discountcard'}
                    contentClassName={'content'}
                    // backdrop={"static"}
                    isOpen={this.props.isPopupDiscountCardDetailChildren}
                    toggle={this.props.openPopupDiscountCardDetailChildren}
                    onOpened={()=>this.onOpenedPopup(itemData)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            {itemData.dcc_name}
                            <div className="d-flex">
                                <button
                                    className="btn btn-wireless mx-1"
                                    // data-toggle="modal"
                                    // data-target="#modal-print"
                                    // onClick={this.openPopupPrint}
                                    id={'print-list-discount-card'}
                                    data-print-content={process.env.REACT_APP_API_URL+'discount-card-print/'+itemData.dcc_id+'?condition='+this.state.dataTextCondition+'&month='+this.state.dataTextMonth+'&money='+money}
                                  // onClick={this.printDetail}
                                >
                                    인쇄하기
                                </button>
                                <button
                                    className="btn btn-wireless mx-1"
                                   // data-toggle="modal"
                                   // data-target="#modal-16"
                                    onClick={this.openPopupDiscountCardDetail}
                                >전체보기
                                </button>
                            </div>
                        </h5>
                        <button
                            type="button"
                            className="close close-modal"
                            //data-dismiss="modal"
                            //aria-label="Close"
                            onClick={this.props.openPopupDiscountCardDetailChildren}
                        >
                           <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body installment-height pl-0 pr-0">
                        <div className="top-installment">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <img src={itemData.dcc_image ? PUBLIC_FOLDER+'/storage/discount_card/original/'+itemData.dcc_image : "/images/card-01.png"} alt="" />
                                </div>
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-4">
                                        <span className="label-modal">
                                            카드구분
                                        </span>
                                        </div>
                                        <div className="col-8">
                                            <span className="text-name">{itemData.code ? itemData.code.name : ''}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                        <span className="label-modal">
                                            결제구분
                                        </span>
                                        </div>
                                        <div className="col-8">
                                            <span className="text-name">{itemData.dcc_auto_transac == 1 ? '자동이체 필수' : '자동이체 미필수'}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                        <span className="label-modal">
                                            연회비
                                        </span>
                                        </div>
                                        <div className="col-8">
                                            <span className="text-name">국내 {itemData.dcc_anual_fee_foreign ? priceFormat(itemData.dcc_anual_fee_foreign) : 0} 원 / 국외 {itemData.dcc_anual_fee_internal ? priceFormat(itemData.dcc_anual_fee_internal) : 0} 원</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                        <span className="label-modal">
                                            발급센터
                                        </span>
                                        </div>
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-3">
                                                    <span className="text-name">ARS</span>
                                                </div>
                                                <div className="col-6 px-0">
                                                    <strong className="text-name">
                                                        <p  className="blue-white">{itemData.dcc_center_ars}</p>
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <span className="text-name">온라인</span>
                                                </div>
                                                <div className="col-9 px-0">

                                                    <div className="qr-code">
                                                         {itemData.dcc_center_online
                                                             ?
                                                                 <strong className="text-name blue-white ">
                                                                    <a href={itemData.dcc_center_online} target="_blank" className="d-flex align-items-center">
                                                                        {href}
                                                                        <img src="/images/Icon-11.svg" alt=""/>
                                                                    </a>
                                                                </strong>
                                                             : ""}

                                                        {itemData.dcc_center_qr ? (
                                                            <img
                                                                className="qr-code-pop"
                                                                src={PUBLIC_FOLDER+'/storage/discount_card/original/'+itemData.dcc_center_qr}
                                                                alt=""
                                                            />
                                                        ) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <article>
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="flex-title">
                                            <h4 className="title  mb-0">
                                                카드혜택
                                            </h4>
                                            <a
                                                className="btn btn-wireless h-25"
                                                // data-toggle="modal"
                                                // data-target="#modal-68"
                                                onClick={()=>this.openPopupTextDetail(itemData.dcc_benefit,true)}
                                                // href="#"
                                            >
                                                <img src="/images/icon-12.svg" alt=""/>
                                                상세보기
                                            </a>
                                        </div>
                                        <div className="scroll-content">
                                            <p className="color-orange" style={{ fontSize : '12px' }}>*할인은 카드 청구금액에서 적용 됩니다.</p>
                                            <div dangerouslySetInnerHTML={{__html: itemData.dcc_benefit}}/>
                                            <br/>
                                            <p style={{ fontSize : '12px' }}>*위 내용은 카드사 및 제휴사에 따라 변경이 될 수 있으며 ,<br/>
                                                할인 혜택 및 상세제공조건에대해서는 카드사 홈페이지 및 통신사 정보를 확인하셔야 됩니다.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                       <div className="row">
                                       <div className="form-group mb-0 col-6">
                                            <div className="flex-title">
                                                <h4 className="title  mb-0">
                                                    전월실적 포함
                                                </h4>
                                                <a
                                                    className="btn btn-wireless h-25"
                                                    //data-toggle="modal"
                                                   //data-target="#modal-68"
                                                    onClick={()=>this.openPopupTextDetail(itemData.dcc_include,false)}
                                                >
                                                    <img src="/images/icon-12.svg" alt=""/>
                                                    상세보기
                                                </a>
                                            </div>
                                            <div className="scroll-content scroll-content-right">
                                                <div dangerouslySetInnerHTML={{__html: itemData.dcc_include ? itemData.dcc_include.replace(/\r?\n/g, '<br />') : '카드사로 문의해 주세요'}}/>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 col-6">
                                            <div className="flex-title">
                                                <h4 className="title mb-0">
                                                    전월실적 미포함
                                                </h4>
                                                <a
                                                    className="btn btn-wireless h-25"
                                                    //data-toggle="modal"
                                                   //data-target="#modal-68"
                                                    onClick={()=>this.openPopupTextDetail(itemData.dcc_not_include,false)}
                                                >
                                                    <img src="/images/icon-12.svg" alt=""/>
                                                    상세보기
                                                </a>
                                            </div>
                                            <div className="scroll-content scroll-content-right">
                                                <div dangerouslySetInnerHTML={{__html: itemData.dcc_not_include ? itemData.dcc_not_include.replace(/\r?\n/g, '<br />') : '카드사로 문의해 주세요' }}/>
                                            </div>
                                        </div>
                                       </div>
                                    </div>
                                </div>
                                <div className="row mt-1 align-items-end">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <div className="flex-title">
                                                <h4 className="title mb-0">
                                                    월 사용액
                                                </h4>
                                            </div>
                                            <button
                                                className="btn btn-input-form mb-0"
                                                type="button"
                                                //data-toggle="modal"
                                                //data-target="#modal-87"
                                                // onClick={}.
                                                onClick={()=>this.openPopupCondition()}
                                            >
                                                {/*30만원 이상, 월 10,000 원 할인*/}
                                                {this.state.dataTextCondition}
                                            </button>
                                        </div>
                                    </div>
                                    {/* truong hop co thang*/}
                                    <div className={`col-6 ${this.props.type === 'light_and_save_card' ? '' : 'd-none'} `}>
                                        <div className="form-group">
                                            <div className="flex-title">
                                                <h4 className="title mb-0">
                                                    할부기간
                                                </h4>
                                            </div>
                                            <button
                                                className="btn btn-input-form mb-0"
                                                type="button"
                                                // data-toggle="modal"
                                                // data-target="#modal-29"
                                                onClick={this.openPopupChangeMonth}
                                            >
                                                {this.state.dataTextMonth}개월
                                            </button>
                                        </div>
                                    </div>
                                    {/* truong hop khong co thang*/}
                                    <div className={`col-6 ${this.props.type === 'light_and_save_card' ? 'd-none' : ''}`}>
                                        <div className="form-group d-flex price_devices mb-4">
                                            <div className="flex-title">
                                                <h4 className="title mb-0">
                                                    월 할인액 :
                                                </h4>
                                            </div>
                                            <span className="prices-devices">  {this.state.moneyTotal ? priceFormat(this.state.moneyTotal) :priceFormat(this.state.moneyCondition * this.state.dataTextMonth)} 원</span>
                                            {/* <button
                                                className="btn btn-input-form mb-0"
                                                type="button"
                                                //data-toggle="modal"
                                                //data-target="#modal-88"
                                                onClick={this.openPopupDiscountCardChangeMoney}
                                            >
                                               
                                            </button> */}
                                        </div>
                                    </div>

                                </div>


                                {/* truong hop co thang*/}
                                <div className={`row mt-2 justify-content-center ${this.props.type === 'light_and_save_card' ? '' : 'd-none'}`}>
                                    <div className="col-7">
                                        <div className="form-group row">
                                            <div className="flex-title mb-0 col-3">
                                                <h4 className="title mb-0">
                                                    할인 총액
                                                </h4>
                                            </div>
                                            <button
                                                className="btn btn-input-form mb-0 col-9 col-count-custom"
                                                type="button"
                                                //data-toggle="modal"
                                                //data-target="#modal-88"
                                                onClick={this.openPopupDiscountCardChangeMoney}
                                            >
                                                {this.state.moneyTotal ? priceFormat(this.state.moneyTotal) :priceFormat(this.state.moneyCondition * this.state.dataTextMonth)} 원
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row mt-1 justify-content-center light_and_save_card`}>
                                    <div className="col-14">
                                        <div className="row align-items-center" style={{color : "#545252", margin : "0px", fontSize : "14px"}}>
                                            모비셀 제휴카드는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="modal-footer justify-content-center border-top-0">
                                <button
                                    type="button"
                                    className="btn btn-gray pl-5 pr-5"
                                    //data-dismiss="modal"
                                    onClick={this.props.openPopupDiscountCardDetailChildren}

                                >취소</button>
                                <button
                                    type="button"
                                    className="btn btn-submit pl-5 pr-5"
                                    onClick={this.isSubmit}
                                >
                                    확인
                                </button>
                            </div>
                        </article>
                    </div>
                </Modal>
                <OpenPopupTextDetail
                    isOpenPopupTextDetail={this.state.isOpenPopupTextDetail}
                    openPopupTextDetail={this.openPopupTextDetail}
                    dataTextDetail={this.state.dataTextDetail}
                    note={this.state.note}
                />

                <OpenPopupCondition
                    openPopupCondition={this.openPopupCondition}
                    isOpenPopupCondition={this.state.isOpenPopupCondition}
                    itemData={itemData}
                    changeStateDataCondition={this.changeStateDataCondition}
                />

                <OpenPopupChangeMonth
                    openPopupChangeMonth={this.openPopupChangeMonth}
                    isOpenPopupChangeMonth={this.state.isOpenPopupChangeMonth}
                    itemData={itemData}
                    changeStateDataMonth={this.changeStateDataMonth}
                />

                <OpenPopupDiscountCardDetail
                    discountCard={discountCard}
                    isPopupDiscountCardDetail={this.state.isPopupDiscountCardDetail}
                    openPopupDiscountCardDetail={this.openPopupDiscountCardDetail}
                    type={this.props.type}
                    telcomID={this.props.telcomID}
                    titleDetail={"라이트 할인카드"}
                />

                <OpenPopUpDiscountCardChangeMoney
                    openPopupDiscountCardChangeMoney={this.openPopupDiscountCardChangeMoney}
                    isPopupDiscountCardChangeMoney={this.state.isPopupDiscountCardChangeMoney}
                    processMoney={this.state.moneyCondition * this.state.dataTextMonth}
                    isSubmitChangeMoney={this.isSubmitChangeMoney}
                    moneyTotal={this.state.moneyTotal}
                />

                {/*<OpenPopupPrint*/}
                {/*    openPopupPrint={this.openPopupPrint}*/}
                {/*    isOpenPopupPrint={this.state.isOpenPopupPrint}*/}
                {/*/>*/}




            </React.Fragment>

        )
    }
}

export default OpenPopupDiscountCardDetailChildren;
