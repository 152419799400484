import { withStyles } from '@material-ui/styles';
 
import React, { Component } from 'react';
 
import { compose } from 'redux';
import LoadingIcon from './../../assets/images/loading.gif';
import styles from './styles';

class CustomizeLoading extends Component {
  render() {
    const { classes } = this.props;
     return(
        <div className={classes.globalLoading}>
          <img src={LoadingIcon} alt="loading" className={classes.icon} />
        </div>
      );
  }
}
 
 

export default compose(
  withStyles(styles),
)(CustomizeLoading);
