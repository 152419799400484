 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {getDetailPlan} from "../../../store/actions/discount_card";

class StartingPriceModelForm extends Component {
    changeWirelessAdviceStep3 = (event) => {
        if(!this.props.errMsg) {
            this.props.change('WirelessAdviceStep3.startingPrice', this.props.startingPrice.split(',').join(''))
            this.props.popupShowChangeStartingPrice()
        }
        event.preventDefault();
    }

    toggleModal = () => {
        this.props.popupShowChangeStartingPrice()
    }

    render() {

        const { handleSubmit, WirelessAdvice } = this.props;
        return (
            
            <Modal isOpen={this.props.isOpenModal}
                   // backdrop={"static"}
                toggle={this.toggleModal} fade={true} className={'modal-dialog modal-dialog-centered modal-custom'} contentClassName="modal-content content pl-5 pr-5">
                <ModalHeader>
                <h5 className="modal-title">출고가 변경</h5><button type="button" className="close close-modal" onClick={() => this.props.popupShowChangeStartingPrice()}><img src="/images/icon-close.svg" /></button>
                </ModalHeader>
                <ModalBody className="modal-body essence-plan pl-0 pr-0">
                
                    <div className="modal-body body-plans mb-2">
                        <form onSubmit={this.changeWirelessAdviceStep3}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 text-modal-gray">공시 출고가 : {this.props.startingPriceDefault} 원</p>
                                <button type="button" className="btn btn-gray-white" onClick={this.props.resetStartingPrice}>적용</button>
                            </div>
                            <div className="form-group row mt-4 align-items-center">
                                <div className="col-10 pr-0">
                                    <input type="text" className="form-control h-44" onChange={this.props.onChangeInput} value={this.props.getValueInpuStartingPrice()}/>
                                    {this.props.showErrorMessage(this.props.errMsg)}
                                </div>
                                <div className="col-2 mb-1">
                                    <span className="text-wrapper-gray">원</span>
                                </div>
                            </div>
                            <h5 className="text-wrapper-gray text-left">
                                변경한 출고가를 적용하시겠습니까?
                            </h5>
                            <div className="d-flex footer-modal justify-content-center border-top-0">
                                <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" onClick={() => this.props.popupShowChangeStartingPrice()}>취소</button>
                                <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">저장</button>
                            </div>
                        </form>
                    </div>
                </ModalBody>

            </Modal>

        );
    }
}

StartingPriceModelForm.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const withReduxForm = reduxForm({
    form: 'WirelessAdvice',
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(StartingPriceModelForm);
