 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChangeDateExtraServiceModal from "../ChangeableDate/ChangeDateExtraServiceModal";
import moment from "moment";
import { priceFormat } from '../../../utils/helper'

class WirelessExtraServiceList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date : {},
      isShowModal: false,
      index: null,
    }
  }

  // handleChange = (date,index) => {
  //   const { WirelessAdvice } = this.props;
  //   let arr = WirelessAdvice.WirelessAdviceStep3.extraServices;
  //   arr[index].cancel_date = date;
  //   this.setState({
  //    date : {
  //      ...this.state.date,
  //      ['date_'+index] : date
  //    }
  //   });
  //   this.props.change('WirelessAdviceStep3.extraServices',arr);
  // };

  deleteExService(item){
    const { WirelessAdvice } = this.props;
    let arr = WirelessAdvice.WirelessAdviceStep3.extraServices;
    arr = arr.filter(i => i.ets_id !== item.ets_id);
    this.props.change('WirelessAdviceStep3.extraServices',arr);
  }

  /**
   * show modal plan
   */
  handleShowModal = (index = null) => {
    this.setState({
      isShowModal : !this.state.isShowModal,
      index
    })
  }

  getValuesDate = (value) => {
    const { WirelessAdvice } = this.props;
    let arr = WirelessAdvice.WirelessAdviceStep3.extraServices;
    arr[this.state.index].cancel_date = value;
    this.setState({
      date : {
        ...this.state.date,
        ['date_'+this.state.index] : value,
      },
      isShowModal: !this.state.isShowModal,
    });
    this.props.change('WirelessAdviceStep3.extraServices',arr);
    this.props.change('WirelessAdviceStep3.cancel_date',value);
  }

  renderCancelDates = () => {
    let xhtml = (
        <Fragment>
          <li onClick={() => this.getCancelDateValue('none')}>
            <span className="left">미적용</span>
          </li>
          <li  onClick={() => this.getCancelDateValue('maintain')}>
            <span className="left">서비스 유지</span> 
          </li>
          <li>
            <DatePicker
                locale={process.env.REACT_APP_CONTENT_LANGUAGE}
                customInput={this.elementIcon()}
                onChange={date => this.getCancelDateValue(date)}
                // onChange={date => this.setCustomeDate(date)}
            />
          </li>
          <li onClick={() => this.getCancelDateValue('endMonth')}>
            <span className="left">당월말</span>
          </li>
          <li onClick={() => this.getCancelDateValue('31day')}>
            <span className="left">31일</span>
          </li>
          <li onClick={() => this.getCancelDateValue('91day')}>
            <span className="left">91일</span>
          </li>
          <li onClick={() => this.getCancelDateValue('93day')}>
            <span className="left">93일</span>
          </li>
          <li onClick={() => this.getCancelDateValue('95day')}>
            <span className="left">95일(당일포함)</span>
          </li>
          <li onClick={() => this.getCancelDateValue('125day')}>
            <span className="left">125일</span>
          </li>
          <li onClick={() => this.getCancelDateValue('183day')}>
            <span className="left">183일</span>
          </li>
          <li onClick={() => this.getCancelDateValue('1month')}>
            <span className="left">익월말(M+1)</span>
          </li>
          <li onClick={() => this.getCancelDateValue('2month')}>
            <span className="left">M+2</span>
          </li>
          <li onClick={() => this.getCancelDateValue('3month')}>
            <span className="left">M+3</span>
          </li>
          <li onClick={() => this.getCancelDateValue('4month')}>
            <span className="left">M+4</span>
          </li>
          <li onClick={() => this.getCancelDateValue('5month')}>
            <span className="left">M+5</span>
          </li>
          <li onClick={() => this.getCancelDateValue('6month')}>
            <span className="left">M+6</span>
          </li>
        </Fragment>
    );
    return xhtml;
  }

  getCancelDateValueCalMonth = (value) => {
    return moment().add(value, 'M').startOf('month').format("YYYY-MM-DD");
  }

  getCancelDateValueCalDay = (value) => {
    let plusDay = moment(new Date()).add(value, 'days').toDate();
    return moment(plusDay).format("YYYY-MM-DD");
  }

  getCancelDateValue = (data) => {
    let date = '';

    switch (data) {
      case 'none':
        date = '';
        break;
      case 'maintain':
        date = '서비스 유지';
        break;
      case 'endMonth':
        date = this.getCancelDateValueCalMonth(1);
        break;
      case '1month':
        date = this.getCancelDateValueCalMonth(2);
        break;
      case '2month':
        date = this.getCancelDateValueCalMonth(3);
        break;
      case '3month':
        date = this.getCancelDateValueCalMonth(4);
        break;
      case '4month':
        date = this.getCancelDateValueCalMonth(5);
        break;
      case '5month':
        date = this.getCancelDateValueCalMonth(6);
        break;
      case '6month':
        date = this.getCancelDateValueCalMonth(7);
        break;
      case '31day':
        date = this.getCancelDateValueCalDay(31);
        break;
      case '91day':
        date = this.getCancelDateValueCalDay(91);
        break;
      case '93day':
        date = this.getCancelDateValueCalDay(93);
        break;
      case '95day':
        date = this.getCancelDateValueCalDay(95);
        break;
      case '125day':
        date = this.getCancelDateValueCalDay(125);
        break;
      case '183day':
        date = this.getCancelDateValueCalDay(183);
        break;
      default:
        date = moment(data).format("YYYY-MM-DD");
        break;
    }

    this.getValuesDate(date)
  }

  elementIcon = () => {
    return (
        <div className="d-flex w-100">
          <span className="left">직접 선택</span>
          <div className="right">
            <div className="btn p-0">
              <img src="../../images/icon-calender.svg" alt=""/>
            </div>
          </div>
        </div>
    )
  }

  render() {
    let {isShowModal} = this.state;
    const { WirelessAdvice } = this.props;
    const html = WirelessAdvice.WirelessAdviceStep3.extraServices.map((item, key) => {
        return    <div className="row" key={key}>
                    <div className="col-md-5 mb-3 pr-0">
                      <div className="form-group">
                        <label className="col-form-label text_label_name label-text">{item.ets_name}</label>
                        <input type="text" className="form-control h-44" value={`${new Intl.NumberFormat().format(item.ets_price)}원`} disabled="" readOnly />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="col-form-label label-text">해지가능일</label>
                        <div className="row">
                          <div className="col-md-7 pr-0">
                            <div className="form-date">
                              <button className="btn btn-input-form mb-0" type="button" onClick={() => this.handleShowModal(key)}>
                                {/*{this.state.date['date_'+key] ? this.state.date['date_'+key] : ''}*/}
                                  {(this.state.date['date_'+key] ? this.state.date['date_'+key] : item.pivot?.date ? item.pivot?.date : '')}
                                <img src="/images/icon-calender.svg" alt="" />
                              </button>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <button className="btn btn-delete-wave" onClick={this.deleteExService.bind(this, item)}>
                              <img src="/images/icon-wave.svg" alt="" />
                              삭제
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    });

    // if selected usedphone => get extra service matching
    let usedPhoneExtraService = '';
    if (WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_id) {
      let {ets_price, ets_name} = WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation;
      let {tcm_id, tcm_option_name, unit, value} = (WirelessAdvice.WirelessAdviceStep3.usedPhoneMemberShip || {});
      let moreFeeApplyUsedProgram = WirelessAdvice?.WirelessAdviceStep3?.applyUsedProgram || 0;

      let itemMembership = '';
      if (unit == 1 ) {
          itemMembership = (
            `${value}% 할인` 
          )
        } else if (unit == 0 ) {
          itemMembership = (
            `${priceFormat(parseFloat(value))}원 할인` 
          )
        } else {
          itemMembership = (
            `${tcm_option_name}` 
          )
        }
      
      usedPhoneExtraService = (
        <div className="row">
            <div className="col-md-5 mb-3 pr-0">
              <div className="form-group">
                <label className="col-form-label text_label_name label-text" style={{fontSize: "13px"}}>
                  {ets_name}
                  {tcm_id ? <span style={{paddingLeft: "7px", color: "#2f8bff", fontWeight: 400}}>{itemMembership}</span> : ''}
                </label>
                <input type="text" className="form-control h-44" value={`${new Intl.NumberFormat().format(moreFeeApplyUsedProgram)}원`} disabled="" readOnly />
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group">
                <label className="col-form-label label-text">해지가능일</label>
                <div className="row">
                  <div className="col-md-7 pr-0">
                    <div className="form-date">
                      <button className="btn btn-input-form mb-0" type="button" disabled>
                        <img src="/images/icon-calender.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <button className="btn btn-delete-wave" disabled>
                      <img src="/images/icon-wave.svg" alt="" />
                      삭제
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }
    

    return (
      <Fragment>
        {usedPhoneExtraService}
        {html}
        <ChangeDateExtraServiceModal
            isOpen={isShowModal}
            toggle = {this.handleShowModal}
            getValuesDate = {this.getValuesDate}
            title='부가서비스 해지가능 날짜'
        >
          {this.renderCancelDates()}
        </ChangeDateExtraServiceModal>
      </Fragment>
    );
  }
}

WirelessExtraServiceList.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessExtraServiceList);
