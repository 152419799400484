 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { priceFormat, numberMathFloor, roundUpNumber } from './../../../utils/helper';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const colorPrice={color:'#2F8BFF',fontWeight:'bold'}

class LguplusSoldier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModal:false
        }
    }

    fnCalWirelessAdviceStep3(data){
        if (data.id == 0) {
            data = null;
        }

        this.props.change('WirelessAdviceStep3.soldierLguplus', data);
    }

    componentDidUpdate(prevProps) {
        let disabled = this.disableCheck();

        if(disabled){
            this.fnCalWirelessAdviceStep3({id : 0});
        }
    }

    openPopup = () => this.setState({isOpenModal: !this.state.isOpenModal})

    chooseItem(data) {
        this.fnCalWirelessAdviceStep3(data);
        this.openPopup();
    }

    renderLi() {
        let data = [
            {id: 0, value: "미적용"},
            {id: 1, value: "20%요금할인"}
        ];

        let activeData = this.props.WirelessAdvice.WirelessAdviceStep3.soldierLguplus;

        return (data.map((item, key) => {
            return (
                <li className={`cursor ${activeData != null && activeData.id == item.id ? "active" : (activeData == null && item.id == 0 ? "active" : "")}`}
                    key={key} onClick={() => this.chooseItem(item)}>
                    {item.value}
                </li>
            );
        }));
    }

    disableCheck = () => { 
        let result                = false;
        let wirelessAdviceStep3   = this.props.WirelessAdvice.WirelessAdviceStep3;
        let combinePlans          = wirelessAdviceStep3.combinePlans;
        let planInfo              = null;
        let discountConditionName = null;
        let packageFee            = wirelessAdviceStep3.packageFee;
        let classID               = packageFee?.classID || 0;
        let id                    = packageFee?.id || 0;

        if(combinePlans != null && combinePlans.length > 0){
            planInfo = combinePlans[0];
            discountConditionName = planInfo.discount_condition_name;

            if(discountConditionName != null){
                discountConditionName.map((item) => {
                    if(item.code == "TEENAGERS_PROMOTION"){
                        result = true;
                    }
                });
            }
        }

        if(result == false){
            if(
                classID == "106" || classID == "108" || classID == "110" || classID == "111" || classID == "148" || // 5G 청소년, 5G 어르신, LTE 청소년, LTE 어르신, 2G
                classID == "115" || classID == "117" || classID == "107" || classID == "112" || classID == "113" || // 키즈, 신규가입불가, 5G 태블릿, 3G 요금제, 태블릿
                classID == "115" || classID == "116"  // 웨어러블, 라우터 전용
            ){ 
                result = true;
            }
            else if(
                id == 2834 || id == 2828 || id == 3628 || id == 3629 || id == 2840 || // 현역병사 데이터 55, 현역병사 데이터 33, LTE 복지 33, LTE 복지 49, LTE 표준요금제
                id == 3655 || id == 3656 // 5G 복지 55, 5G 복지 75
            ){ 
                result = true;
            }
        }

        return result;
    }

    render() {
        let wirelessAdviceStep3 = this.props.WirelessAdvice.WirelessAdviceStep3;
        let soldier             = wirelessAdviceStep3.soldierLguplus;
        let showText            = "";

        if(soldier == null) {
            showText = "미적용";
        }
        else{
            let basicCost = +(wirelessAdviceStep3.basicCost || 0) // chi phí cơ bản (기본료)
            let promotion = +(wirelessAdviceStep3.promotion?.promo_cost || 0) // promotion (프로모션)
            //let promotionDurationDiscountOne = +(wirelessAdviceStep3.promotionDurationDiscountOne || 0) // promotion (프로모션)
            //let promotionDurationDiscountTwo = +(wirelessAdviceStep3.promotionDurationDiscountTwo || 0) // promotion (프로모션)
            let rateAgreement = +(wirelessAdviceStep3.rateAgreement || 0) // cam k?t goi phi (요금약정)
            let promotionDurationDiscountOne = 0;
            let promotionDurationDiscountTwo = 0;
            let combinedDiscount = roundUpNumber(+( +(wirelessAdviceStep3.combinePlans[0]?.discount_wireless_price || 0) + +(wirelessAdviceStep3.combinePlans[0]?.discount_tv || 0))) // gi?m gia k?t h?p (결합 할인)
            let welfareDiscount = 0;

            if((wirelessAdviceStep3.welfareDiscount?.id || 0) != 6){ // 만 65세 이상 기초연금수급자(최대 12,100원)
                welfareDiscount = +(wirelessAdviceStep3.welfareDiscount?.cost || 0) // gi?m gia phuc l?i (복지 할인)
            }
            
            showText =  "-" + priceFormat(numberMathFloor((basicCost - rateAgreement - promotion - promotionDurationDiscountOne - promotionDurationDiscountTwo - combinedDiscount - welfareDiscount) * 0.2)) + "원";
        }

        return (
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">현역병사<br/>요금할인</label>
                <div className="col-sm-8 right-btn-mobile">
                    <button
                        className={`${this.disableCheck() ? 'form-control cursor-text color-bg-gray' : ''} btn-input-form mb-0 btn-devices pr-2`}
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-28 "
                        onClick={this.openPopup}
                        style={(soldier ? colorPrice : {})}
                        disabled={this.disableCheck()}
                    >
                        {showText === '미적용' ? <span className='opacity-disabled'>미적용</span> : showText}
                    </button>
                    <Modal
                        isOpen={this.state.isOpenModal}
                        contentClassName={'modal-content pl-4 pr-4'}
                        toggle={this.openPopup} 
                        className={'modal-dialog modal-dialog modal-dialog-centered modal-additional '}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">
                                현역병사 요금할인
                                <span className="text-content font-13">
                                    ~ 전역일의 다음달까지 할인제공
                                </span>
                                <br />
                                <span className="text-content font-13">
                                    ※ 직업군인 및 대체복무(사회복무/산업기능/전문연구 등) 적용불가
                                </span>
                                <br />
                                <span className="text-content font-13">
                                    모든 복지할인 차감 후 적용 / 투게더 청소년 1만원 할인과 중복할인 불가
                                </span>
                            </h5>
                            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.openPopup}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <ModalBody>
                            <ul className="list-additional list-sale-01">
                                {this.renderLi()}
                            </ul>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

LguplusSoldier.propTypes = {
    WirelessAdvice: PropTypes.object,
    change: PropTypes.func
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm
)(LguplusSoldier);
