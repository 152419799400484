import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CompareDevice from './CompareDevice'
import CompareConsultant from './CompareConsultant'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import ModalPrintCompare from "../../components/Compare/ModalPrintCompare";
import ModalReset from "../../components/Compare/ModalReset";
import queryString from 'query-string'
import {COMPARE_TYPE} from './../../utils/constant'
import ShareWebModal from "../../components/ShareWebModal";
import { getFormValues } from 'redux-form';
import './Compare.css';
import { ReactComponent as IconReset } from './../../assets/images/icon-reset.svg';
import { ReactComponent as IconShare } from './../../assets/images/share.svg';
import { ReactComponent as IconPrint } from './../../assets/images/print.svg';
import Tooltip from "./../../components/Tooltip";


class Compare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: COMPARE_TYPE.DEVICE,
      model:0,
      isOpenModalPrint : false,
      isOpenModalReset : false,
      isReset: false,
      hasDataDevice: false,
      hasDataConsultant: false,
      isOpenModalShareWeb: false,
      redirect: {
        1: 'type=1',
        2: 'type=2',
      },
      referrer: '',
    }
  }

  componentDidMount() {
    let q = queryString.parse(this.props.history.location.search)
    if (q.type) {
      this.setState({
        type: q.type,
        model:q.model,
        redirect: {
          ...this.state.redirect,
          [q.type]: queryString.stringify(q)
        }
      })
    }

    if (q.referrer) {
      this.setState({
        referrer: '/wireless-advice'
      })
    } else {
      this.setState({
        referrer: ''
      })
    }

    const { history } = this.props;

    this.hashHistoryListener = history.listen((newLocation, action) => {
      if (this.state.referrer === '/wireless-advice') {
        if (action === "POP") {
          this.setState({
            referrer: ''
          })
          // history.go(1);
          // Send user back if they try to navigate back
          
          this.props.history.push({
            pathname: `/wireless-advice`,
            /*
            state: {
              WirelessAdviceStep3:{
                ...this.props.WirelessAdvice.WirelessAdviceStep3,
                telcomeID: this.props.WirelessAdvice.WirelessAdviceStep1.telcomID,
                model: this.props.WirelessAdvice.WirelessAdviceStep3.phone,
                successString: this.props.WirelessAdvice.WirelessAdviceStep1.successString,
              },
              color: this.props.WirelessAdvice.WirelessAdviceStep1.successString,
              installment_payment_method: this.props.WirelessAdvice.WirelessAdviceStep1.installmentPaymentMethod,
            }
            */
          });
        }
      }
      if (newLocation.search == '') {
        if (action === "POP") {
          this.props.history.goBack()
        }
      }
    });
  }

  componentWillUnmount() {
    this.hashHistoryListener();
  }

  toggle = type => {
    this.props.history.push("/compare?" + this.state.redirect[type]);

    this.setState({type});
  }

  onClickPrintModal = () => {
    this.setState({
      isOpenModalPrint : !this.state.isOpenModalPrint
    })
  }

  onClickPrintCompare = () => {
    console.log("this.state", this.state);
    console.log("this.state.type", this.state.type);
    console.log("this.state.redirect[this.state.type]", this.state.redirect[this.state.type]);
    const url = process.env.REACT_APP_API_URL + 'wired-product-compare-tab?print=1&' + queryString.stringify(queryString.parse('?' + this.state.redirect[this.state.type]), {arrayFormat: 'bracket'});
    
    window.open(url, '_blank', 'width=1015,height=799').focus();
  }

  openModalReset = () => {
    this.setState({
      isOpenModalReset : true
    })
  }

  closeModalReset = () => {
    this.setState({
      isOpenModalReset : !this.state.isOpenModalReset
    })
  }

  onSelectedReset = () => {
    this.setState({
      isReset: true,
      redirect: {
        ...this.state.redirect,
        [this.state.type]: 'type=' + this.state.type
      }
    }, () => {
      this.setState({
        isReset: false
      })
    })
    this.closeModalReset()
    this.props.history.push({
      pathname: '/compare',
      search: '?type=' + this.state.type
    })
  }

  setHasDataDevice = (hasDataDevice) => {
    this.setState({hasDataDevice})
  }

  setHasDataConsultant = (hasDataConsultant) => {
    this.setState({hasDataConsultant})
  }

  getParams = async (type, params) => {
    this.setState({
      redirect: {
        ...this.state.redirect,
        [type]: params
      }
    })
    
  }

  getParamTotal = (param1, param2) => {
    this.setState({
      redirect: {
        [COMPARE_TYPE.DEVICE]: param1,
        [COMPARE_TYPE.CONSULTANT]: param2
      }
    })
  }

  handleClickShowEmpty = () => {
    alert('단말기 최소 한가지를 선택해주세요')
  }

  shareDeviceWeb = () => {
    if(
      (this.state.hasDataDevice && this.state.type == COMPARE_TYPE.DEVICE && this.state.redirect[this.state.type].includes('plan=') && this.state.redirect[this.state.type].includes('type='))
      || (this.state.hasDataConsultant && this.state.type == COMPARE_TYPE.CONSULTANT)
    ) {
      if(window.currentDevice === "web") {
        this.setState({
          isOpenModalShareWeb : !this.state.isOpenModalShareWeb
        })
      }
    }else{
      alert('단말기 최소 한가지를 선택해주세요')
    }
  }

  render() {
    return (
      <Fragment>
        <section className="compare-terminals">
          <div className="container">
            <div className="top">
              <Nav tabs className="list-tab">
                <NavItem className="tab-item">
                  <NavLink
                    className={`nav-link${this.state.type == COMPARE_TYPE.DEVICE ? ' active' : ''}`}
                    onClick={() => { this.toggle(COMPARE_TYPE.DEVICE); }}
                  >
                    단말기 비교
                  </NavLink>
                </NavItem>
                <NavItem className="tab-item">
                  <NavLink
                    className={`nav-link${this.state.type == COMPARE_TYPE.CONSULTANT ? ' active' : ''}`}
                    onClick={() => { this.toggle(COMPARE_TYPE.CONSULTANT); }}
                  >
                    가격비교
                  </NavLink>
                </NavItem>
              </Nav>
              <div style={{"textAlign" : "right"}}>
                <span class="text-content font-12">모비셀서비스는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.</span>
              </div>
              <div className="group-button">
                <button className="btn btn-wireless" onClick={this.openModalReset}>
                <IconReset className="mr-1" />
                  초기화
                </button>
                <Tooltip message={'서비스 준비중 입니다'} position={'top'} className ={''} tooltipBubbleSmall={'false'}>
                  <button className="btn btn-wireless">
                      <IconShare className="mr-1"  id={'btn-share-compare-img'}/>
                    공유
                  </button>
                </Tooltip>
                {/*(
                  this.state.hasDataDevice && this.state.type == COMPARE_TYPE.DEVICE
                  || (this.state.hasDataConsultant && this.state.type == COMPARE_TYPE.CONSULTANT)
                ) ? (
                  <button
                    className="btn btn-wireless"
                    id={'btn-share-compare'}
                    onClick={this.shareDeviceWeb}
                    data-print-content={
                      process.env.REACT_APP_API_URL+'wired-product-compare-tab?'
                      + queryString.stringify(queryString.parse('?' + this.state.redirect[this.state.type]), {arrayFormat: 'bracket'})
                    }
                  >
                    <IconShare className="mr-1" id={'btn-share-compare-img'}/>
                    공유
                  </button>
                ) : (
                  <button className="btn btn-wireless" onClick={this.handleClickShowEmpty}>
                      <IconShare className="mr-1"  id={'btn-share-compare-img'}/>
                    공유
                  </button>
                )*/}
                {(
                  this.state.hasDataDevice && this.state.type == COMPARE_TYPE.DEVICE
                  || (this.state.hasDataConsultant && this.state.type == COMPARE_TYPE.CONSULTANT)
                ) ? (
                  <button
                    className="btn btn-wireless"
                    id={'btn-print-wired-product'}
                    onClick={this.onClickPrintCompare}
                    data-print-content={
                      process.env.REACT_APP_API_URL+'wired-product-compare-tab?print=1&'
                      + queryString.stringify(queryString.parse('?' + this.state.redirect[this.state.type]), {arrayFormat: 'bracket'})
                    }
                  >
                    {/* <i className="sprite sprite-icon-16"  id="btn-print-wired-product-img"></i> */}
                    <IconPrint className="mr-1"  id="btn-print-wired-product-img"/>
                    {/* // <img
                    //   src="/images/print.svg"
                    //   id={'btn-print-wired-product-img'}
                    // /> */}
                    인쇄
                  </button>
                ) : (
                  <button className="btn btn-wireless" onClick={this.handleClickShowEmpty}>
                        <IconPrint className="mr-1"  id={'btn-print-wired-product-img'}/>
                    {/* <i className="sprite sprite-icon-16"  id={'btn-print-wired-product-img'}></i> */}
                    {/* <img
                      src="/images/print.svg"
                      id={'btn-print-wired-product-img'}
                    /> */}
                    인쇄
                  </button>
                )}
                {/*<button
                  className="btn btn-wireless"
                  // onClick={this.onClickPrintModal}
                  id={'btn-print-wired-product'}
                  data-print-content={process.env.REACT_APP_API_URL+'/wired-product-compare-tab?tab='+this.state.type}
                >
                  <img src="/images/print.svg" alt="" id={'btn-print-wired-product-img'} />
                  출력
                </button>*/}
              </div>
            </div>
            <TabContent activeTab={this.state.type}>
              <TabPane tabId="1">
                <CompareDevice
                  isReset={this.state.isReset}
                  hasData={this.state.hasDataDevice}
                  setHasData={this.setHasDataDevice}
                  /*getParams={params => this.getParams(COMPARE_TYPE.DEVICE, params)} */
                  getParams={(type, params) => this.getParams(type, params)}
                  getParamTotal={(param1, param2) => this.getParamTotal(param1, param2)}
                />
              </TabPane>
              <TabPane tabId="2" className="compare-tab-two">
                <CompareConsultant
                  isReset={this.state.isReset}
                  hasData={this.state.hasDataConsultant}
                  setHasData={this.setHasDataConsultant}
                  getParams={params => this.getParams(COMPARE_TYPE.CONSULTANT, params)}
                />
              </TabPane>
            </TabContent>
            {/*<div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                <CompareDevice />
              </div>
              <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                <CompareConsultant />
              </div>
            </div>*/}
            <ModalPrintCompare
              isOpen={this.state.isOpenModalPrint}
              toggle={this.onClickPrintModal}
              type={this.state.type}
            />
            <ModalReset
              isOpenModal={this.state.isOpenModalReset}
              closeModal={this.closeModalReset}
              onSelected={this.onSelectedReset}
            />

            <ShareWebModal
              isOpenModal={this.state.isOpenModalShareWeb}
              toggle={this.shareDeviceWeb}
              sharelink={
                process.env.REACT_APP_API_URL+'wired-product-compare-tab?'
                + queryString.stringify(queryString.parse('?' + this.state.redirect[this.state.type]), {arrayFormat: 'bracket'})
              }
            />
          </div>
        </section>
      </Fragment>
    );
  }
}

Compare.propTypes = {
  history: PropTypes.object 
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(Compare);

