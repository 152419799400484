import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 
import { data } from 'jquery';

import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
class SubscriptionFeeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {},
            active: true
        };

    }

    renderSubscriptionFee() {
        const { dataFee, itemFee } = this.props;
        let viewList = ''
        if (dataFee.data && dataFee.data.length > 0) {
            return (dataFee.data.map((item, key) => {

                return (
                    <Fragment key={key}>
                        <li className={(itemFee && item.id == itemFee.id ? 'active' : '') + " cursor"} onClick={this.chooseSubscriptionFee.bind(this, item)} data-dismiss="modal">
                            {item.tmf_name}
                        </li>
                    </Fragment>
                );
            }));
        }

    }


    chooseSubscriptionFee = (data) => {
        if (data == null)
            this.state.active = true
        else
            this.state.active = false
        const { WirelessAdvice } = this.props;
        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        this.props.change('WirelessAdviceStep3.subscriptionFee', data)

    }
    // handleChange = (val) => {
    //     this.setState({
    //         item: val
    //     }, () => {
    //         this.props.setValuePay(this.state.item)

    //     })
    // }
    render() {
        const {
            active
        } = this.state;

        return (
            <Fragment>
                {/* modal */}
                <div className="modal fade"
                     // data-backdrop="static"
                     data-keyboard="false"
                     id="modal-95" tabIndex={-1} role="dialog"
                     aria-labelledby="exampleModalLabel" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-additional modal-custom-09" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">가입비</h5>
                                {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src="/images/icon-close.svg" alt="" />
                                </button> */}
                            </div>
                            <div className="modal-body mt-2">
                                <ul className="list-additional">
                                    <li  className={(this.state.active?'active':'') + " cursor" } onClick={this.chooseSubscriptionFee.bind(this,null)} data-dismiss="modal">
                                        미적용
                                    </li>
                                 {this.renderSubscriptionFee()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* end modal */}
            </Fragment>

        );
    }
}

SubscriptionFeeModal.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(SubscriptionFeeModal);
