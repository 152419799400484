import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import PropTypes from 'prop-types'
import api from "../../utils/api";
import { useRef, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import moment from 'moment';
import {
    getAdviceWireless
} from './../../store/actions/dataStore';
import { hideLoading, showLoading } from "../../store/actions/ui";
import { hideModal, showModal } from "../../store/actions/modal";
import {allowAccessMenu, openPopupMenu} from './../../store/actions/auth';
import RegisterAccessPopup from './../AccessMenu/RegisterAccessPopup';
import AuthAccessPopup from './../AccessMenu/AuthAccessPopup';
import * as helper from './../../utils/helper';
import * as routerName from './../../utils/routerName';
import ko from "date-fns/locale/ko"; // the locale you want
registerLocale("ko", ko); // register it with the name you want


class Settlement extends Component {
    constructor(props) {
        super(props)
        var date = new Date();
        this.state = {
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            startMonth: new Date(date.getFullYear(), date.getMonth(), 1),
            endMonth: new Date(date.getFullYear(), date.getMonth(), 1),
            isOpen: false,
            selectD: true,
            allData: [],
            stl: [],
            user: [],
            stl_id: '',
            seller_id: '',
            sellers: [],

            // isOpenPopup: props.auth.isOpenPopupMenu,
            isOpenAuthPopup: false,
            isOpenRegisterPopup: false,
            tabMonth:false
        };
        this.myRefStart = React.createRef();
        this.myRefEnd = React.createRef();
    }
    


    componentDidMount() {
        let {
            user,
            isAllowAccessMenu,
        } = this.props.auth;
        if (window._prePath) {
            // đang f5 lại page đó
            // alert('f5')
            var preURL = `/${routerName.HISTORY_BUY_PRODUCT}`;
            window._prePath = '';
        } else {
            // navigate from route
            // alert('notf5');
            var preURL = this.props.location.state?.from; 
        }        

        let checkMatchURL = helper.checkMatchURL(preURL);
        let checkAllow = checkMatchURL && isAllowAccessMenu ? true : false;

        this.props.allowAccessMenu(checkAllow);
        if (!checkAllow) {
            this.props.openPopupMenu(true);
        }
                

        var moment = require('moment');
        const { telcomProviders, auth } = this.props;
        this.props.getAdviceWireless().then(res => {
            this.setState({
                telcom: res,
                storeId: auth.store.sto_id
            });
        }).catch(() => {

        });

        let data = {
            'date': this.state.selectD,
            'start': moment(this.state.startDate).format('YYYY-MM-DD'),
            'end': moment(this.state.endDate).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': this.state.stl_id
        }
        this.props.showLoading();

        api.settlement.getList(data)
            .then(response => {
                this.setState({
                    data: response.data
                });
            })
        api.settlement.getListAll(data)
            .then(response => {
                this.setState({
                    allData: response.data
                });
            })

        api.transaction_point.getListAll({ 'store_id': auth.store.sto_id })
            .then(response => {
                this.setState({
                    stl: response.data.data ? response.data.data : []
                });
            })

        api.settlement.getAllUser()
            .then(response => {
                this.setState({
                    user: response.data
                });
                this.props.hideLoading();
            })
            .catch(() => {
                this.props.hideLoading()
            })
        // get sellers
        this.getSellers()

    }

    onToggleMainPopup = () => {
        this.props.openPopupMenu(false)
    }

    allowAccessMenu = () => {
        this.props.allowAccessMenu(true);
        this.setState({
           ...this.state,
           isOpenAuthPopup: false,
           isOpenRegisterPopup: false,
        })
    }


    getSellers() {
        // this.props.showLoading();
        api.auth.getSellers().then(res => {
            const sellers = res.data.data ? res.data.data.data : [];
            this.setState({
                sellers: sellers,
            }, () => {
                // this.props.hideLoading();
            })
        }).catch(err => {
            // this.props.hideLoading();
        });
    }
    renderSettlement() {
        // return
        const formatter = new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
        // let data = this.state.data && this.state.data.data.data ? this.state.data.data.data : [];
        let data = this.state.allData && this.state.allData.data ? this.state.allData.data : [];
        if(data.length==0){
            return (
                <Fragment >
                    <tr>
                        <td className="align-middle text-center" colSpan="8" >데이터가 없습니다!</td>
                    </tr>

                </Fragment>
            );
        }
        else
            return (data.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <tr>
                            <td className="align-middle text-center">
                                <div>{key + 1}</div>
                            </td>
                            <td className="align-middle text-right">
                                <div>{item.name}</div>
                            </td>
                            <td className="align-middle text-center">
                                <div>{item.telcom_name}</div>
                            </td>
                            <td className="align-middle text-center">
                                <div>{item.stl_name}</div>
                            </td>
                            <td className="align-middle text-center">
                                <div>
                                    {moment(item.open_day).format('YYYY-MM-DD')=='Invalid date'?'':moment(item.open_day).format(this.state.selectD ? 'YYYY-MM' : 'YYYY-MM-DD')}
                                </div>
                            </td>
                            {/* <td className="align-middle text-center"><div>{item.open_day}</div></td> */}
                            <td className="align-middle text-center">
                                <div>{item.total_report}</div>
                            </td>
                            <td className="align-middle text-right">
                                <div>{item.total_rb == null ? 0 : formatter.format(item.total_rb).replace('$', '')}원</div>
                            </td>
                            <td className="align-middle text-right">
                                <div>{item.actual_profit == null ? 0 : formatter.format(item.actual_profit).replace('$', '')}원</div>
                            </td>
                        </tr>

                    </Fragment>
                );

            }));


    }

    renderSTL() {
        // return

        let data = this.state.stl ? this.state.stl : [];

        return (data.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.stl_id} >{item.stl_name}</option>
                </Fragment>
            );

        }));


    }

    onChangeSTL = (e) => {
        this.props.showLoading();
        let data = {
            'date': this.state.selectD,
            'start': moment(this.state.startDate).format('YYYY-MM-DD'),
            'end': moment(this.state.endDate).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': e.target.value
        }

        this.setState({
            'stl_id': e.target.value
        })
        api.settlement.getList(data)
            .then(response => {
                this.props.showLoading()
                this.setState({
                    data: response.data
                },() =>{
                    this.props.hideLoading()
                });
            })
        api.settlement.getListAll(data)
            .then(response => {
                this.setState({
                    allData: response.data
                });
                this.props.hideLoading();
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    onChangeSeller = (e) => {
        this.props.showLoading();
        let data = {
            'date': this.state.selectD,
            'start': moment(this.state.startDate).format('YYYY-MM-DD'),
            'end': moment(this.state.endDate).format('YYYY-MM-DD'),
            'seller_id': e.target.value,
            'stl_id': this.state.stl_id
        }
        this.setState({
            'seller_id': e.target.value
        })
        api.settlement.getList(data)
            .then(response => {
                this.setState({
                    data: response.data
                });
            })
        api.settlement.getListAll(data)
            .then(response => {
                this.setState({
                    allData: response.data
                });
                this.props.hideLoading();
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }
    renderSellers() {
        // return

        let data = this.state.sellers ? this.state.sellers : [];

        return (data.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.uID}>{item.user.name}</option>
                </Fragment>
            );

        }));


    }
    renderFooter = () => {
        const formatter = new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
        let data = this.state.allData && this.state.allData.data ? this.state.allData.data : [];
        let total_rb = 0;
        let acturl_profit = 0;
        data.forEach(e => {
            if (e.total_rb != null) { total_rb += Number(e.total_rb) }
            if (e.actual_profit != null) { acturl_profit += Number(e.actual_profit) }
        });
        return (
            <Fragment>
                <tfoot>
                    <tr>
                        <td colSpan={6} className="label-text"><div>정산정보</div></td>
                        <td className="text-right table-prices text-bold"><div>{formatter.format(total_rb).replace('$', '')}<span>원</span></div></td>
                        <td className="text-right table-prices text-bold"><div>{formatter.format(acturl_profit).replace('$', '')}<span>원</span></div></td>
                    </tr>
                </tfoot>
            </Fragment>
        );

    }

     convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    handleChangeMonth = dates => {

        let firstStart = new Date(dates.getFullYear(), dates.getMonth(), 1);
        let endMonth = new Date(this.state.endMonth.getFullYear(), this.state.endMonth.getMonth() + 1, 0);
        let data = {
            'date': this.state.selectD,
            'start': moment(firstStart).format('YYYY-MM-DD'),
            'end': moment(endMonth).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': this.state.stl_id
        }
        this.setState({
            startMonth: firstStart,
            // endMonth: moment(firstStart).format('YYYY-MM-DD')
        });

        let start = moment(firstStart).format('YYYY-MM-DD')
        let end = moment(this.state.endMonth).format('YYYY-MM-DD')
        let stardateCheck = moment(start);
        let enddateCheck = moment(end);

        if(stardateCheck > enddateCheck) {
            alert('시작달은 종료달보다 이하이어야 합니다.')
        }else {
            api.settlement.getList(data)
                .then(response => {
                    this.setState({
                        data: response.data
                    });

                })
            this.props.showLoading()
            api.settlement.getListAll(data)
                .then(response => {
                    this.setState({
                        allData: response.data
                    });
                    this.props.hideLoading()
                })
                .catch(() => {
                    this.props.hideLoading()
                })
        }

    }
    handleChangeMonthEnd = dates => {
        let endMonth = new Date(dates.getFullYear(), dates.getMonth()+ 1, 0);
        let startMonth = new Date(dates.getFullYear(), dates.getMonth(), 1);

        let data = {
            'date': this.state.selectD,
            'start': moment(this.state.startMonth).format('YYYY-MM-DD'),
            'end': moment(endMonth).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': this.state.stl_id
        }

        this.setState({
            endMonth: endMonth,
        });

        let start = moment(this.state.startMonth).format('YYYY-MM-DD')
        let end = moment(endMonth).format('YYYY-MM-DD')
        let stardateCheck = moment(start);
        let enddateCheck = moment(end);


        if(stardateCheck > enddateCheck) {
            alert('시작달은 종료달보다 이하이어야 합니다.')
        }else {
            api.settlement.getList(data)
                .then(response => {
                    this.setState({
                        data: response.data
                    });

                })
            this.props.showLoading()
            api.settlement.getListAll(data)
                .then(response => {
                    this.setState({
                        allData: response.data
                    });
                    this.props.hideLoading()
                })
                .catch(() => {
                    
                })
        }

    }

    handleChange = dates => {
        const [start, end] = dates;
        let startDate = start
        let endDate = end
        let firstStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        let lastStart = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        startDate = firstStart
        if (moment(end).format('YYYY-MM-DD') == "Invalid date") {
            endDate = lastStart;
        }
        else {
            endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

        }

        let data = {
            'date': this.state.selectD,
            'start': moment(this.state.selectD ? startDate : start).format('YYYY-MM-DD'),
            'end': moment(this.state.selectD ? endDate : end).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': this.state.stl_id
        }
        if (this.state.selectD)
            this.setState({
                startDate: startDate,
                endDate: moment(end).format('YYYY-MM-DD') == "Invalid date" ? end : endDate
            });
        else
            this.setState({
                startDate: start,
                endDate: end
            });
        api.settlement.getList(data)
            .then(response => {
                this.setState({
                    data: response.data
                });
            })
        api.settlement.getListAll(data)
            .then(response => {
                this.setState({
                    allData: response.data
                });
            })
    };

    openDatepicker = () => {
        this._calendar.setOpen(true)
        // this.setState({
        //     isOpen: !this.state.isOpen
        // }, () => {
        //     this._calendar.setOpen(this.state.isOpen)

        // })
    }
    selectDate(val) {
        if(val) {
            var dateCurrent = new Date();
            this.setState({
                startMonth: new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1),
                endMonth: new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1),
            })
        }
        let data = {
            'date': val,
            'start': moment(this.state.startDate).format('YYYY-MM-DD'),
            'end': moment(this.state.endDate).format('YYYY-MM-DD'),
            'seller_id': this.state.seller_id,
            'stl_id': this.state.stl_id
        }
        api.settlement.getList(data)

            .then(response => {
                this.setState({
                    data: response.data
                });
            })
        this.setState({
            selectD: val
        })

        api.settlement.getListAll(data)
            .then(response => {
                this.setState({
                    allData: response.data
                });
            })
    }

    coverLanguage(str) {
        switch (str) {
            case  "January":
                str = "1월"
                break;
            case  "February":
                str = "2월"
                break;
            case  "March":
                str = "3월"
                break;
            case  "April":
                str = "4월"
                break;
            case "May":
                str = "5월"
                break;
            case  "June":
                str = "6월"
                break;
            case  "July":
                str = "7월"
                break;
            case  "August":
                str = "8월"
                break;
            case  "September":
                str = "9월"
                break;
            case  "October":
                str = "10월"
                break;
            case  "November":
                str = "11월"
                break;
            case  "December":
                str = "12월"
                break;
        }
        if(this.state.selectD)
            return this.state.startDate.getFullYear()+'년  '+ str 
        else
            return this.state.startDate.getFullYear()+'년  '+ str + '  ' + this.state.startDate.getDate()+'일'   
    }

    coverLanguageEndDate(str) {
        switch (str) {
            case  "January":
                str = "1월"
                break;
            case  "February":
                str = "2월"
                break;
            case  "March":
                str = "3월"
                break;
            case  "April":
                str = "4월"
                break;
            case "May":
                str = "5월"
                break;
            case  "June":
                str = "6월"
                break;
            case  "July":
                str = "7월"
                break;
            case  "August":
                str = "8월"
                break;
            case  "September":
                str = "9월"
                break;
            case  "October":
                str = "10월"
                break;
            case  "November":
                str = "11월"
                break;
            case  "December":
                str = "12월"
                break;
        }
        if(this.state.endDate==null){
            return 'Invalid date'
        }
        else
            if(this.state.selectD)
                return this.state.endDate.getFullYear()+'년' + '  ' + str 
            else
                return `${this.state.endDate.getFullYear()}년  ${str} ${this.state.endDate.getDate()} 일`
    }

    coverLanguageMonth = (str, status) => {

        switch (str) {
            case  "January":
                str = "1월"
                break;
            case  "February":
                str = "2월"
                break;
            case  "March":
                str = "3월"
                break;
            case  "April":
                str = "4월"
                break;
            case "May":
                str = "5월"
                break;
            case  "June":
                str = "6월"
                break;
            case  "July":
                str = "7월"
                break;
            case  "August":
                str = "8월"
                break;
            case  "September":
                str = "9월"
                break;
            case  "October":
                str = "10월"
                break;
            case  "November":
                str = "11월"
                break;
            case  "December":
                str = "12월"
                break;
        }
        if(status) {
            if(this.state.selectD)
                return this.state.startDate.getFullYear()+'년  '+ str
            else
                return this.state.startDate.getFullYear()+'년  '+ str + '  ' + this.state.startDate.getDate()+'일'
        }else {
            if(this.state.endMonth==null){
                return 'Invalid date'
            } else {

                if(this.state.selectD)
                    return this.state.endMonth.getFullYear()+'년' + '  ' + str
                else
                    return `${this.state.endMonth.getFullYear()}년  ${str} ${this.state.endMonth.getDate()} 일`
            }

        }


    }
    toggleStart = () => {
        this.myRefStart.current.onInputClick()
    }
    toggleEnd = () => {
        this.myRefEnd.current.onInputClick()
    }

    render() {
        const {
            user,
            isOpenPopupMenu,
            isAllowAccessMenu,  
        } = this.props.auth;

        if (this.props.auth.isAllowAccessMenu) {

        const {
            price
        } = this.state;
        const ExampleCustomInput = () => (

            <input readOnly onClick={this.openDatepicker} style={{ 'width': '300px', 'margin-left': "-47px", 'cursor': 'grab' }} type="text" className="form-control h-44"
                placeholder={(this.coverLanguage(moment(this.state.startDate).format('MMMM')) + ' - ' +
                this.coverLanguageEndDate(moment(this.state.endDate).format('MMMM'))).replace('Invalid date', this.state.selectD ?
                        '종료달을 지정해 주세요!' : '종료일자를 지정해 주세요!')} />
        );
        return (
            <Fragment>




                <section>
                    <div className="container">
                        <div className="sales_report table-sale-report">
                            <div className="fill_search">
                                <form action className="compare-terminals p-0 mt-0">
                                    <div className="row top d-flex justify-content-between align-items-center mb-4">
                                        <div className="col-md-4">
                                            <ul className="nav nav-tabs list-tab" id="myTab" role="tablist">
                                                <li className="nav-item tab-item" onClick={() => this.selectDate(true)}>
                                                    <a className="nav-link active pl-5 pr-5" id="mont-tab" data-toggle="tab" href="#mont" role="tab" aria-controls="mont" aria-selected="false"> 월별 </a>
                                                </li>
                                                <li className="nav-item tab-item" onClick={() => this.selectDate(false)}>
                                                    <a className="nav-link pl-5 pr-5" id="my01-tab" data-toggle="tab" href="#my01" role="tab" aria-controls="my01" aria-selected="true">일별</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-2">
                                            <select class="form-control form-control-lg" onChange={this.onChangeSTL.bind(this)} >
                                                <option value="">전체 거래처</option>
                                                {this.renderSTL()}
                                            </select>
                                        </div>
                                        <div className="col-md-2 " style={{ 'margin-right': '50px' }}>
                                            <select class="form-control form-control-lg" onChange={this.onChangeSeller.bind(this)} >
                                                <option value="">전체 판매자</option>
                                                {this.renderSellers()}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            {this.state.selectD == true ? <div className="form-date d-flex">
                                                <div style={{ 'position': 'relative', 'margin-right':'10px' }}>
                                                    <img src="/images/icon-calender.svg" alt=""  style={{ 'z-index': '999', 'cursor': 'grab','right':'2px' }} onClick={this.toggleStart} />
                                                    <DatePicker
                                                        locale="ko"
                                                        ref={this.myRefStart}
                                                        className="form-control h-44"
                                                        selected={this.state.startMonth}
                                                        onChange={this.handleChangeMonth}
                                                        selectsStart
                                                        startDate={this.state.startMonth}
                                                        // endDate={this.state.endMonth}
                                                        dateFormat="MM/yyyy"
                                                        value={this.coverLanguageMonth(moment(this.state.startMonth).format('MMMM'), true)}
                                                        showMonthYearPicker

                                                    />
                                                </div>
                                                <div style={{ 'position': 'relative' }}>
                                                    <img src="/images/icon-calender.svg" alt=""  style={{ 'z-index': '999', 'cursor': 'grab', 'right':'2px'}} onClick={this.toggleEnd} />
                                                    <DatePicker
                                                        locale="ko"
                                                        ref={this.myRefEnd}
                                                        className="form-control h-44"
                                                        selected={this.state.endMonth}
                                                        onChange={this.handleChangeMonthEnd}
                                                        selectsEnd
                                                        // startDate={this.state.startMonth}
                                                        endDate={this.state.endMonth}
                                                        dateFormat="MM/yyyy"
                                                        value={this.coverLanguageMonth(moment(this.state.endMonth).format('MMMM'), false)}
                                                        showMonthYearPicker
                                                    />
                                                </div>

                                            </div> : <div className="form-date">
                                                <img src="/images/icon-calender.svg" alt="" onClick={this.openDatepicker} style={{ 'z-index': '999', 'cursor': 'grab' }} />
                                                {/* <DatePicker ref={(c) => this._calendar = c} className="form-control h-44" selected={this.state.startDate}
                                                    onChange={this.handleChange} customInput={<ExampleCustomInput />} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange value={moment(this.state.startDate).format('D MMMM') + ' - ' + moment(this.state.endDate).format('D MMMM,YYYY')}
                                                    showMonthYearPicker={this.state.selectD} showFullMonthYearPicker={this.state.selectD}/> */}
                                                <DatePicker locale="ko" ref={(c) => this._calendar = c} className="form-control h-44" selected={this.state.startDate}
                                                            onChange={this.handleChange} customInput={<ExampleCustomInput />} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange value={moment(this.state.startDate).format('D MMMM') + ' - ' + moment(this.state.endDate).format('D MMMM,YYYY')}
                                                            monthsShown={2} />

                                            </div>}

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-content tab-content-consultancy" id="myTabContent">
                                <div className="tab-pane fade show active" id="mont" role="tabpanel" aria-labelledby="mont-tab">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-consultancy table_striped mb-0">
                                            <thead className="thead_bg">
                                                <tr>
                                                    <th className="text-center">
                                                        <div>NO</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>판매자 이름</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>통신사</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>거래처</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>판매 월</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>실적</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>거래처 정산</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>매장 마진</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <div className="scroll-sale">
                                            <table className="table table-borderless table-consultancy mb-0 table_striped">
                                                <tbody>
                                                    {this.renderSettlement()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <table className="table table-borderless table-consultancy table_striped">
                                            {this.renderFooter()}
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="my01" role="tabpanel" aria-labelledby="my01-tab">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-consultancy table_striped mb-0">
                                            <thead className="thead_bg">
                                                <tr>
                                                    <th className="text-center">
                                                        <div>NO</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>판매자 이름</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>통신사</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>거래처</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>판매 월</div>
                                                    </th>
                                                    <th className="text-center">
                                                        <div>실적</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>거래처 정산</div>
                                                    </th>
                                                    <th className="text-right">
                                                        <div>매장 마진</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <div className="scroll-sale ">
                                            <table className="table table-borderless table-consultancy mb-0 table_striped">
                                                <tbody>
                                                    {this.renderSettlement()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <table className="table table-borderless table-consultancy table_striped mt-4">
                                            {this.renderFooter()}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment >

        );
    } else {
        return (
            <Fragment>
                <RegisterAccessPopup 
                    // isOpen = {this.state.isOpenRegisterPopup}
                    isOpen = {isOpenPopupMenu && !isAllowAccessMenu && !user.password_menu}
                    toggle = {this.onToggleMainPopup}
                    allowAccessMenu = {this.allowAccessMenu}
                />

                <AuthAccessPopup 
                    // isOpen = {this.state.isOpenAuthPopup}
                    isOpen = {isOpenPopupMenu && !isAllowAccessMenu && user.password_menu}
                    toggle = {this.onToggleMainPopup}
                    allowAccessMenu = {this.allowAccessMenu}
                />
            </Fragment>
        )
    }
    }
}


Settlement.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getAdviceWireless: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        telcomProviders: state.dataStore.telcomProviders,
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         showLoading: () => dispatch(showLoading()),
//         hideLoading: () => dispatch(hideLoading()),
//         // showModal: (data) => dispatch(showModal(data)),
//         // hideModal: () => dispatch(hideModal()),
//         change: () => dispatch(change()),
//         // getAdviceWireless: () => dispatch(getAdviceWireless()),
//         allowAccessMenu: (status) => dispatch(allowAccessMenu(status)),
//         openPopupMenu: (status) => dispatch(openPopupMenu(status)),
//         showLoading: () => dispatch(showLoading()),
//         hideLoading: () => dispatch(hideLoading()),
//     };
// };

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getAdviceWireless: () => dispatch(getAdviceWireless()),
        allowAccessMenu: (status) => dispatch(allowAccessMenu(status)),
        openPopupMenu: (status) => dispatch(openPopupMenu(status)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
// export default TransactionPoint;
export default compose(
    withConnect,
    // withReduxForm,
)(Settlement);
