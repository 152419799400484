import React from 'react';
import {Modal} from 'reactstrap';
import {priceFormat} from "../../../utils/helper";
class OpenPopUpDiscountCardChangeMoney extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            money : 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.moneyTotal != this.props.moneyTotal) {
            this.setState({money: this.props.moneyTotal})
        }
    }


    handleChange = (event) => {
        //if(event.target.value <= this.props.processMoney){
            this.setState({
                money: event.target.value
            });
        //}
    }

    applyMoney = () => {
        this.setState({
            money : this.props.processMoney
        })
    }

    onSubmitChangeMoney = (e) => {
        e.preventDefault()
        this.props.isSubmitChangeMoney(this.state.money)
        this.props.openPopupDiscountCardChangeMoney();
    }

    render() {
        return(
            <Modal
                className={'modal-dialog-centered modal-custom'}
                contentClassName={'pl-4 pr-4'}
                // backdrop={"static"}
                isOpen={this.props.isPopupDiscountCardChangeMoney}
                toggle={this.props.openPopupDiscountCardChangeMoney}
            >
                <div className="modal-header">
                    <h5 className="modal-title">할인 총액 입력</h5>
                    <button
                        type="button"
                        className="close close-modal"
                        // data-dismiss="modal" aria-label="Close"
                        onClick={this.props.openPopupDiscountCardChangeMoney}
                    >
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <div className="modal-body px-0">
                    <form onSubmit={this.onSubmitChangeMoney} className="body-plans">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0 text-modal-gray">기본할인 : {priceFormat(this.props.processMoney)} 원</p>
                            <button
                                className="btn btn-gray-white"
                                type={'button'}

                                onClick={this.applyMoney}
                            >적용</button>
                        </div>
                        <div className="form-group row mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <input
                                    type="number"
                                    className="form-control h-44"
                                    value={this.state.money}
                                    name={'money'}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <h5 className="text-wrapper-gray pt-1 text-left">
                            입력한 할인 금액을 적용하시겠습니까?
                        </h5>
                        <div className="d-flex justify-content-center footer-modal border-top-0">
                            <button
                                type="button"
                                className="btn btn-gray mx-2"
                                // data-dismiss="modal"
                                onClick={this.props.openPopupDiscountCardChangeMoney}
                            >취소</button>
                            <button
                                type="submit"
                                className="btn btn-submit mx-2"
                            >확인</button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default OpenPopUpDiscountCardChangeMoney;
