import React, {Component, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {connect} from "react-redux";
import {compose} from "redux";
import api from "../../../utils/api";
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {priceFormat} from "../../../utils/helper";

const divStyle = {
    zIndex: '999'
};
const none = {
    display: 'none'
};
class ServiceMoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isGoing: false,
            checked: [],
            arrayDefaut: [],
            data: [],
            dataSearch: {
                searchInput : null,
                telcomID: null,
            },
        }
        this.myRef = React.createRef();
    }

    onOpened = () => {
        this.setState({
            ...this.state,
            dataSearch : {
                ...this.state.dataSearch,
                searchInput : '',
            }
        })

        this.props.showLoading();
        let telcomID = this.props.WirelessAdvice.WirelessAdviceStep1.telcomID;
        api.service.getListService({telcomID : telcomID})
            .then(res => { 
                const {WirelessAdvice} = this.props;
                this.setState({
                    data: res.data.data,
                    modal: !this.state.modal,
                    checked: WirelessAdvice.WirelessAdviceStep3.extraServices.map(x => x.ets_id)
                })
                this.props.hideLoading();
            }).catch(error => {
                this.props.hideLoading();
            }); 
    }
    inputRefs = [];
    setRef = (ref) => {
        if (ref && ref != null) {
            this.inputRefs.push(ref);
        }
    };
    handleInputChange = (event) => {
        this.setState({isGoing: !this.state.isGoing})
        const target = event.target;
        const value = target.value;
        let checked = this.state.checked;
        if (checked.indexOf(value) > -1) {
            checked.splice(checked.indexOf(value), 1)
        } else {
            checked.push(value);
        }
        /*
        checked = checked.filter(function (item, index) {
            return checked.indexOf(item) === index;
        });
        */
    }
    triggerClickCheckBox = (id) => {
        let dataMapRef = []
        this.inputRefs.map((item) => {
            if (item && item.value && item.value != null && item.value == id) {
                dataMapRef.push(item)
            }
        })
        if (dataMapRef && dataMapRef.length > 0) {
            dataMapRef[0].click()
        }
    }

    /**
     * mapping data toogle modal
     */
    toggle = () => {
        this.props.toggleModal();
        this.inputRefs=[]
    }

    /**
     * handleSubmit
     */
    handleSubmit = (e) => {
        e.preventDefault();
        const {WirelessAdvice} = this.props;
        const checked = this.state.checked;
        const extraServicesExist = WirelessAdvice.WirelessAdviceStep3.extraServices;

        let arr = [];

        if(extraServicesExist){
            for(let i = 0; i < extraServicesExist.length; i++){
                let j = 0;

                for (j = 0; j < this.state.data.length; j++) {
                    if (this.state.data[j].ets_id == extraServicesExist[i].ets_id) {
                        break;
                    }
                }

                if(j == this.state.data.length){
                    arr.push(extraServicesExist[i]);
                }
            }
        }
        
        for (let i = 0; i < checked.length; i++) {
            for (let j = 0; j < this.state.data.length; j++) {
                if (this.state.data[j].ets_id == checked[i]) {
                    let itemExist=(extraServicesExist || []).filter(item=>item?.ets_id == checked[i])
                    if(itemExist.length > 0) {
                        arr.push(itemExist[0])
                    }else {
                        this.state.data[j].cancel_date=null
                        arr.push(this.state.data[j])
                    }

                }
            }
        }
        
        this.inputRefs=[]
        this.props.change('WirelessAdviceStep3.extraServices', arr)
        this.props.toggleModal();
    }

    searchInputChange = (e) => {
        this.setState({
          ...this.state,
          dataSearch : {
              ...this.state.dataSearch,
              searchInput : e.target.value,
          }
      })
    };

    /**
     * enter KeyPressed
     * @param {*} e
     */
    enterPressed = (e) => {
        var code = e.keyCode || e.which;
        if (code === 13) {
        this.handleSearch(e);
        }
    };

    handleSearch = (e) => {
        e.preventDefault();
        let telcomID = this.props.WirelessAdvice.WirelessAdviceStep1.telcomID;
        api.service.getListService({telcomID : telcomID, searchInput : this.state.dataSearch.searchInput})
        .then(res => { 
            const {WirelessAdvice} = this.props;
            this.setState({
                ...this.state,
                data: res.data.data
            })
        }).catch(error => {
            console.log("error");
            console.log(error);
        }); 
    };

    render() {
        const { WirelessAdvice} = this.props;
        let data = this.state.data;
        let {checked} = this.state;
        const html = data.map((item, key) => {
            // check
            let check = false
            if (checked.indexOf(item.ets_id) > -1) {
                check = true;
            }
            return <tr key={key} onClick={() => this.triggerClickCheckBox(item.ets_id)} style={divStyle}>
                <td className="abc">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="isGoing" style={none}
                               id={`customControl${key}`}
                               checked={check}
                               onChange={this.handleInputChange}
                               value={item.ets_id}
                               defaultValue={item.ets_id}
                               ref={this.setRef}
                               defaultChecked={check}
                        />
                        <input type="checkbox" className="custom-control-input"
                               checked={check}
                               value={item.ets_id}
                               defaultValue={item.ets_id}
                               onClick={() => this.triggerClickCheckBox(item.ets_id)}
                               defaultChecked={check}
                        />
                        <label className="custom-control-label" onClick={() => this.triggerClickCheckBox(item.ets_id)}
                               htmlFor={`customControl${key}`}></label>
                    </div>
                </td>
                <td scope="row">
                    {item.ets_name}
                </td>
                <td>
                    <div dangerouslySetInnerHTML={{__html: item.ets_desc}} />

                </td>
                <td className="text-wrap">{priceFormat(Math.ceil(item.ets_price))}원</td>
            </tr>

        });
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.modal}
                    className="modal-dialog-centered modal-custom-10"
                    contentClassName="modal-add-service"
                    onOpened={this.onOpened}
                    toggle={this.toggle}
                    // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">부가서비스 선택</h5>
                        <button onClick={this.toggle} type="button" className="close close-modal" data-dismiss="modal"
                                aria-label="Close">
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body pl-0 pr-0">
                        <form action="">
                            <article className="">
                                <div className="row top-search-query">
                                    <div className="col-md-10">
                                        <div className="form-search">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search"
                                                ref={(el) => (this.inputSearch = el)}
                                                placeholder="검색어를 입력해 주세요"
                                                onChange={this.searchInputChange}
                                                onKeyPress={this.enterPressed}
                                                value={this.state.dataSearch.searchInput ? this.state.dataSearch.searchInput : '' }
                                                aria-label="Search"
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn my-2 my-sm-0" type="submit" onClick={this.handleSearch}>
                                            <img src="/images/icon-search.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-header">
                                <thead>
                                    <tr className="bg-purple">
                                        <th></th>
                                        <th>서비스명</th>
                                        <th>상세설명</th>
                                        <th className="text-wrap">이용요금</th>
                                    </tr>
                                    </thead>
                                </table>
                               <div className="table-scroll">
                               <table className="table table-striped">
                                    <tbody>
                                    {html}
                                    </tbody>
                                </table>
                               </div>
                            </article>
                            <div className="modal-footer justify-content-center border-top-0 mt-4">
                                <button type="button" onClick={this.toggle} className="btn btn_close"
                                        data-dismiss="modal">취소
                                </button>
                                <button type="button" onClick={this.handleSubmit}
                                        className="btn btn-submit pl-5 pr-5">적용
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>)
    }
}

ServiceMoreModal.propTypes = {};
const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default compose(
    withConnect,
    withReduxForm,
)(ServiceMoreModal);
