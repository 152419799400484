 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import DiscountPointModal from './DiscountPointModal';
import {numberWithCommas,priceFormat} from "../../../utils/helper";

class WirelessPointDiscount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModal : false,
      discountPoint : 0,
    }
  }

  /**
     * show modal discount 
     */
    showModalDiscount = () => {
      let {WirelessAdvice} = this.props;
      let additionalGrants = parseInt(WirelessAdvice.WirelessAdviceStep3.additionalGrants);
      if (isNaN(additionalGrants)){
        additionalGrants = 0;
      }
      let validPrice = 0;
      if (WirelessAdvice.WirelessAdviceStep3.optionFee == 1) {
        validPrice = parseInt(WirelessAdvice.WirelessAdviceStep3.startingPrice) - parseInt(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - parseInt(WirelessAdvice.WirelessAdviceStep3.additionalGrants ? WirelessAdvice.WirelessAdviceStep3.additionalGrants : 0);
      }else{
        validPrice = parseInt(WirelessAdvice.WirelessAdviceStep3.startingPrice);
      }

      if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
        if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
          let instPrincipalUsedPhone2 = WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금
  
          validPrice = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35);
        }
      }

      if (validPrice <= 0) { 
        alert('금액은 원 0원 이하로 입력해 주세요.')
        this.props.change('WirelessAdviceStep3.pointDiscount', 0)
      }
      else{
        if(+(WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
          alert("더이상 할인옵션을 추가하실 수 없습니다.");
        }
        else{
          this.setState({
            isShowModal : !this.state.isShowModal
          })
        }
      }
  }

  /**
   * mapping props value discount point
   */
  setValueDiscountPoint = (discountPoint) => {
      this.props.change('WirelessAdviceStep3.pointDiscount', discountPoint)
  }

  render() {
    const { WirelessAdvice } = this.props;
    let {isShowModal}        = this.state;
    let wirelessAdviceStep3  = WirelessAdvice?.WirelessAdviceStep3;
    let optionFee            = wirelessAdviceStep3.optionFee;
    let validPrice           = 0;
    let disabled             = "";
    let styleNumber;
    let pointDiscount;

    if (optionFee == 1) {
      validPrice = parseInt(wirelessAdviceStep3.startingPrice) - parseInt(wirelessAdviceStep3.disclosureSubsidy) - parseInt(wirelessAdviceStep3.additionalGrants ? wirelessAdviceStep3.additionalGrants : 0);
    }
    else{
      validPrice = parseInt(wirelessAdviceStep3.startingPrice);
    }

    if (wirelessAdviceStep3.pointDiscount) {
      pointDiscount = "-" + priceFormat(wirelessAdviceStep3.pointDiscount);
    }
    else {
      pointDiscount = 0;
    }

    if(wirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
      let usedPhoneCompensation = wirelessAdviceStep3.usedPhoneCompensation;
      let tupId                 = +(usedPhoneCompensation.tup_id || 0);

      if(usedPhoneCompensation.telcomID == 18){ // lguplus만 적용
        if(tupId != 191){
          let instPrincipalUsedPhone2 = wirelessAdviceStep3.startingPrice * usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

          validPrice = wirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1;
        }
      }
      else if(tupId == 192){ // KT S23 프리미엄 Y 에디션
        disabled      = "disabled";
      }
    }
    
    if (pointDiscount != 0) {
      styleNumber = "btn-prices-wp";
    }
    else {
      styleNumber = "";
    }

    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">포인트 할인
          </label>
          <div className="col-sm-8">
            <button className={`btn btn-input-form mb-0 ${styleNumber}`} type="button" disabled={ disabled } onClick = {this.showModalDiscount}>
             {pointDiscount} 
             <span>원</span> 
            </button>
          </div>
        </div>
        <DiscountPointModal 
          key={isShowModal}
          discountPoint = {priceFormat(WirelessAdvice.WirelessAdviceStep3.pointDiscount)} 
          isShowModal={isShowModal} 
          toggleModal = {this.showModalDiscount}
          setValueDiscountPoint = {this.setValueDiscountPoint}
          validPrice={validPrice}
          optionFee={optionFee}
        />
      </Fragment>
    );
  }
}

WirelessPointDiscount.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessPointDiscount);
