import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';
import { Modal } from 'reactstrap';
import api from "../../../utils/api";
import SelerCreateModal from './SelerCreateModal'
import {Fragment} from "react";

class SalerModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        sellers: {},
        isOpenModalCreate:false
    }
  }

    openPopupCreateSeller = () => this.setState({isOpenModalCreate: !this.state.isOpenModalCreate})

  selecteSaler = (saler) => {
    this.props.change('WiredAdviceStep2.saler', saler.user)
    this.props.openPopup()
  }

    onOpened = () => {
        let data = {
            paginate: 999999,
        }
        api.auth.getSellers(data).then(res => {
            const sellers  = res.data.data ? res.data.data : [];
            this.setState({
                sellers: sellers,
            })
        }).catch(err => {
        });
    }
  render() {
    const { WiredAdvice, auth } = this.props;
    const { store, user } = auth

      let sellers = this.state.sellers?.data || [];
      if (sellers && !sellers.map(e => e.id).includes(user.id)) {
          if (!this.props.auth.role == 'Member' || !this.props.auth.role == 'Admin') {
              sellers = [user, ...sellers]
          }
      }
    return (
      <Fragment>
          <Modal
              isOpen={this.props.isOpenModal}
              // backdrop={"static"}
              toggle={this.props.openPopup}
              fade={true}
              onOpened = {this.onOpened}
              className={'modal-dialog modal-dialog-centered modal-additional modal-seller-01'}
          >
              <div className="modal-header pt-4 pb-1">
                  <div className={'custom-create-seller'}>
                      <h5 className="modal-title">상담사</h5>
                      <button className="btn btn-wireless" onClick={()=>this.openPopupCreateSeller()}>판매자 등록</button>
                  </div>
                  <button
                      type="button"
                      className="close close-modal"
                      onClick={this.props.openPopup}
                  >
                      <img src="/images/icon-close.svg" alt="" />
                  </button>
              </div>
              <div className="modal-body mt-1">
                  <ul className="list-additional">
                      {sellers && sellers.map((saler, key) => (
                          <li
                              key={key}
                              onClick={() => this.selecteSaler(saler)}
                              className={WiredAdvice?.WiredAdviceStep2?.saler?.id === saler.user.id ? 'active' : ''}
                          >
                              {saler?.user?.name}
                          </li>
                      ))}
                  </ul>
              </div>
          </Modal>
          <SelerCreateModal isOpenModal={this.state.isOpenModalCreate} openPopup={this.openPopupCreateSeller} onOpened={this.onOpened} />
      </Fragment>
    );
  }
}

SalerModal.propTypes = {
  openPopup: PropTypes.func,
  auth: PropTypes.object,
  WiredAdvice: PropTypes.object,
  isOpenModal: PropTypes.any,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(SalerModal);
