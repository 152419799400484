import { useMediaQuery } from '@material-ui/core';
import axios from './axios';
import { constant } from 'lodash';
const queryString = require('query-string');

export default {
    global: {
        getPriceDefault: () => axios.get(`api/v1/global/get-default-price`),
        getGlobalDataAuth: () => axios.get(`api/v1/global/get-global-data-auth`),
        getNotifications: (data) => axios.get(`api/v1/global/get-notifications?${queryString.stringify(data)}`),
        getBanners: () => axios.get(`api/v1/global/get-banners`),
        getTelcom: (data) => axios.get(`api/v1/telcoms/get-list?${queryString.stringify(data)}`),
        saveOptionPrint: (data) => axios.post(`api/v1/auth/save-option-print`, data),
    },
    auth: {
        verifyInfoRegister: (data) => axios.get(`api/v1/auth/check-info-resgister?${queryString.stringify(data)}` ),
        verifyBussiness: (data) => axios.post(`api/v1/auth/verify-bussiness`, data ),
        login: data => axios.post("api/v1/auth/login", data),
        getCurrentUser: data => axios.get("api/v1/auth/getCurrentUser", data),
        getPricePackages: (data) => axios.get(`api/v1/price-packages/get-list-package-price?${queryString.stringify(data)}`),
        register: data => axios.post("api/v1/auth/register", data),
        packageRenewal: data => axios.post("api/v1/auth/package-renewal", data),
        checkUniqueEmail: data => axios.get(`api/v1/auth/check-unique-email?${queryString.stringify(data)}`),
        checkUniqueUsername: data => axios.get(`api/v1/auth/check-unique-username?${queryString.stringify(data)}`),
        checkUniquePhone: data => axios.get(`api/v1/auth/check-unique-phone?${queryString.stringify(data)}`),
        checkSamePassword: data => axios.get(`api/v1/auth/check-same-password?${queryString.stringify(data)}`),
        checkExistUsername: data => axios.get(`api/v1/auth/check-exist-username?${queryString.stringify(data)}`),

        getForgotId: data => axios.get(`api/v1/auth/get-forgot-id?${queryString.stringify(data)}`),
        findPasswordEmail: data => axios.post(`api/v1/auth/find-password-email`, data),
        findPasswordPhone: data => axios.post(`api/v1/auth/find-password-phone`, data),
        createSeller: data => axios.post("api/v1/auth/create-seller", data),
        deleteSeller: id => axios.get("api/v1/auth/delete-seller/" + id),
        getSellers: data => axios.get(`api/v1/auth/get-sellers?${queryString.stringify(data)}`),
        createVerifySMS: data => axios.post("api/v1/auth/create-verify-sms", data),
        comfirmVerifySMS: data => axios.post("api/v1/auth/comfirm-verify-sms", data),
        logout: data => axios.post("api/v1/auth/logout", data),
        logoutDevice: data => axios.post("api/v1/auth/logout-device", data),
        updateLoginLog: (data) => axios.post("api/v1/auth/update-login-log", data),
        registerPasswordMenu: (data) => axios.post("api/v1/auth/register-password-menu", data),
        changePasswordMenu: (data) => axios.post("api/v1/auth/change-password-menu", data),
        resetPasswordMenu: (data) => axios.post("api/v1/auth/reset-password-menu", data),

        checkOldMember: (data) => axios.post("api/v1/auth/check-old-member", data),
    },
    
    smart_consultant: {
        getFilters: () => axios.get(`api/v1/consultant/get-filter`),
        getModelPlan: (query) => axios.get(`api/v1/consultant/model-plans?${query}`)
    },
    advice:{
        addBookmark: (data) => axios.post(`api/v1/consultant/bookmarks`,data),
        getDataWirelessAdvice: (data) => axios.get(`api/v1/global/get-data-wireless-advice?${queryString.stringify(data)}`),
        getManufacturers: (data) => axios.get(`api/v1/manufacturers/get-list-manufacturer?${queryString.stringify(data)}`),
        getTelcomProviders: (data) => axios.get(`api/v1/telcoms/get-list-telcom?${queryString.stringify(data)}`),
        getTelcomNotParent: () => axios.get(`api/v1/telcoms/get-telcom-not-parent`),
        getDetailDevice: (id) => axios.get(`api/v1/devices/get-detail/${id}`),

        getListConsultants: (data) => axios.get(`api/v1/consultant/model-plans?${queryString.stringify(data)}`),
        getDetailPromotion: (data) => axios.get(`api/v1/promotions/get-promotion/${data}`),
        getDetailPlan: (data,params) => axios.get(`api/v1/plans/detail-plans/${data}?${queryString.stringify(params)}`),
        getMutualService: (data) => axios.get(`api/v1/mutual-services/get-list-mutual-service?telcomID=${data}`),
        detailMutualService: (data) => axios.get(`api/v1/mutual-services/detail-mutual-service/${data}`),

        // consultant
        getListConsultant: (data) => axios.get(`api/v1/consultant?${queryString.stringify(data)}`),
        getConsultant: (id) => axios.get(`api/v1/consultant/${id}`),
        bookmarkWired: (id) => axios.post(`api/v1/consultant/bookmark-wired/${id}`),

        createWirelessConsultant: (data) => axios.post(`api/v1/consultant/wireless`, data),
        updateWirelessConsultant: (id, data) => axios.post(`api/v1/consultant/wireless/${id}`, data),

        createWiredConsultant: (data) => axios.post(`api/v1/consultant/wired`, data),
        updateWiredConsultant: (id, data) => axios.post(`api/v1/consultant/wired/${id}`, data),

        getListUsedPrograms: (data) => axios.get(`api/v1/used-programs/list-used-program?${queryString.stringify(data)}`),
        getListMembershipDiscounts: (data) => axios.get(`api/v1/memberships/list-membership-discount?${queryString.stringify(data)}`),
        getListClassInsurances: (data) => axios.get(`api/v1/devices/list-class-insurances?${queryString.stringify(data)}`),
        getListDeviceInsurances: (data) => axios.get(`api/v1/devices/list-devide-insurances?${queryString.stringify(data)}`),

        getListWired: data => axios.get(`api/v1/devices/wired?${queryString.stringify(data)}`),
        getListCode: data => axios.get(`api/v1/devices/get-code?${queryString.stringify(data)}`),
        getModel: (id, params) => axios.get(`api/v1/devices/model/${id}?${queryString.stringify(params, {arrayFormat: 'bracket'})}`),
        getListCombineCondition: (params) => axios.get(`api/v1/combined-discount?${queryString.stringify(params)}`),
        getListWireDevice: (id) =>  axios.get(`api/v1/combined-discount/get-wire-devive/${id}`),
        getWiredDiscountCondsItems: (data) =>  axios.post(`api/v1/devices/wired-discount-conds-items`, data),
    },
    profile:{
        profileUpdate: (data) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                formData.append('_method', 'PUT');
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/accounts/profile-update`, formData, config);
        },
        getHistoryUserPayment: (data) => axios.get(`api/v1/global/get-history-user-payment?${queryString.stringify(data)}`),
        getManagementCoupon: (data) => axios.get(`api/v1/coupons/get-management-coupon?${queryString.stringify(data)}`),
        getListCoupon: (data) => axios.get(`api/v1/coupons/get-list-coupon?${queryString.stringify(data)}`),
        getCouponDetail: (data) => axios.get(`api/v1/coupons/get-detail?${queryString.stringify(data)}`),
        delCoupon: (data) => axios.post(`api/v1/coupons/del-coupon`, data),
    },
    discount_card : {
        getDiscountCard : (data) => axios.get(`api/v1/discount-cards/get-discount-card?${queryString.stringify(data)}`),
        getDiscountCardById : (id) => axios.get(`api/v1/discount-cards/get-discount-card/${id}`)
    },

    sim : {
        getSims : (id) => axios.get(`api/v1/sims?telcomID=${id}`)
    },

    subscription_fee : {
        getSubscriptionFee : (id) => axios.get(`api/v1/subscription-fee?telcomID=${id}`)
    },
    plan_information : {
        getPlanInformation : (data) =>  axios.get(`api/v1/plans/get-list-plan-by-classification?${queryString.stringify(data)}`),
        getPlanClassifications : (data) =>  axios.get(`api/v1/plans/get-list-classification?${queryString.stringify(data)}`),
        setBookMark : (data) => axios.post(`api/v1/plans/set-book-mark`, data),
        getDisclosureSubsidy : (data) =>  axios.get(`api/v1/plans/get-disclosure-subsidy?${queryString.stringify(data)}`),
    },
    //추가
    plan_classifications_information : {
        getPlanClassificationsInformation : (data) =>  axios.get(`api/v1/plans/get-telcom-plan-classification?${queryString.stringify(data)}`),
        //getPlanInformation : (data) =>  axios.get(`api/v1/plans/get-list-plan-by-classification?${queryString.stringify(data)}`),
        getPlanInformation2 : (data) =>  axios.get(`api/v1/plans/get-telcom-plan-classification?${queryString.stringify(data)}`),
        getMatchPlanId : (data) =>  axios.get(`api/v1/plans/get-match-plan-user?${queryString.stringify(data)}`),
        //setBookMark : (data) => axios.post(`api/v1/plans/set-book-mark`, data),
        getDisclosureSubsidy : (data) =>  axios.get(`api/v1/plans/get-disclosure-subsidy?${queryString.stringify(data)}`),
    },

    post_apply_fee : {
        postApplyFee : data => axios.post(`api/v1/plans/set-match-plan-user`, data)
    },

    //
    service : {
        //getListService : (data) => axios.get(`api/v1/extra-services?telcomID=${data}`),
        getListService : (data) => axios.get(`api/v1/extra-services?${queryString.stringify(data)}`),
    },
    get_cost_display_plan: {
        getCostDiplayPlan : (data) => axios.post(`api/v1/plans/get-cost-display-plan`, data)
    },
    customer: {
        getList : (params) => axios.get(`api/v1/customer?${queryString.stringify(params)}`),
        create : (data) => axios.post(`api/v1/customer`, data),
        update : (id, data) => axios.post(`api/v1/customer/${id}`, data),
        delete : (id,data) => axios.get(`api/v1/customer/${id}?${queryString.stringify(data)}`),
    },

    notification: {
        getNoticeCats: (data) => axios.get(`api/v1/notices/get-list-cat?${queryString.stringify(data)}`),
        getDocumentCats: (data) => axios.get(`api/v1/documents/get-list-cat?${queryString.stringify(data)}`),
        createNotice: (data) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/notices/create-notice`, formData, config);
        },
        updateNotice: (data, id) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                formData.append('_method', 'PUT');
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/notices/update-notice/${id}`, formData, config);
        },
        getNotices: (data) => axios.get(`api/v1/notices/get-notice?${queryString.stringify(data)}`),
        getNoticeDetail: (id) => axios.get(`api/v1/notices/get-notice/${id}`),
        noticeIncreaseViewer: (id) => axios.get(`api/v1/notices/increase-viewer/${id}`),
        delNotice: (id) => axios.delete(`api/v1/notices/del-notice/${id}`),
        likeNotice: (id) => axios.post(`api/v1/notices/like-notice/${id}`),
        followPopupAdsNotice: (data) => axios.post(`api/v1/notices/follow-popup-ads`, data),
        createDocument: (data) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/documents/create-document`, formData, config);
        },
        updateDocument: (data, id) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                formData.append('_method', 'PUT');
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/documents/update-document/${id}`, formData, config);
        },
        getDocuments: (data) => axios.get(`api/v1/documents/get-document?${queryString.stringify(data)}`),
        documentIncreaseViewer: (id) => axios.get(`api/v1/documents/increase-viewer/${id}`),
        delDocument: (id) => axios.delete(`api/v1/documents/del-document/${id}`),
        likeDocument: (id) => axios.post(`api/v1/documents/like-document/${id}`),
        followPopupAdsDocument: (data) => axios.post(`api/v1/documents/follow-popup-ads`, data),
        downloadFile: (data, token) => axios.get(`api/attachments/download-attachment?${queryString.stringify(data)}`, { headers: { 'Authorization': token } }),
        uploadFileCkeditor: (data) =>
        {
                const formData = new FormData();
                for ( var key in data ) {
                    formData.append(key, data[key]);
                }
                formData.append('_method', 'POST');
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                return axios.post(`api/v1/attachments/upload-file-ckeditor`, formData, config);
        },
        announcementChangList: (data) => axios.get(`api/v1/announcement/change-list?${queryString.stringify(data)}`),
        announcementGetModel: (data) => axios.get(`api/v1/announcement/get-model?${queryString.stringify(data)}`),
        announcementGetPlan: (data) => axios.get(`api/v1/announcement/get-plan?${queryString.stringify(data)}`),
        save: (data) => {
            const formData = new FormData();
            for ( var key in data ) {
                formData.append(key, data[key]);
            }
            
            formData.append('_method', 'POST');
            return axios.post(`api/v1/announcement/save`, formData);
        },
        update: (data, id) => {
            const formData = new FormData();
            for ( var key in data ) {
                formData.append(key, data[key]);
            }
            formData.append('_method', 'PUT');
            return axios.post(`api/v1/announcement/save/${id}`, formData);
        },

    },
    consultant: {
        find : (params) => axios.get(`api/v1/consultant/find-consultant/${params}`),
        getLocations: (params) => axios.get(`api/v1/consultant/get-list-location?${queryString.stringify(params)}`),
        checkOpenCode: (params) => axios.get(`api/v1/consultant/check-open-code?${queryString.stringify(params)}`),
        createReport: (data) => axios.post(`api/v1/consultant/report-sale`, data),
        getList : (params) => axios.get(`api/v1/list-consultant?${queryString.stringify(params)}`),
        getAllModel : (params) => axios.get(`api/v1/list-all-model?${queryString.stringify(params)}`),
        delete : (params) => axios.get(`api/v1/list-consultant/delete?${queryString.stringify(params)}`),
        updateType : (params) => axios.get(`api/v1/list-consultant/update-type?${queryString.stringify(params)}`),
        createStatisticConsultantExport: (data) => axios.post(`api/v1/consultant/statistic-consultant-export`, data),
        createStatisticWiredConsultantExport: (data) => axios.post(`api/v1/consultant/statistic-wired-consultant-export`, data),
        getFormBuilder : params => axios.get(`api/v1/consultant/get-form-builder?${queryString.stringify(params)}`),
    },

    settlement: {
        getList : (params) => axios.get(`api/v1/settlement?${queryString.stringify(params)}`),
        getListAll : (params) => axios.get(`api/v1/settlement-all?${queryString.stringify(params)}`),
        getAllUser : (params) => axios.get(`api/v1/user-all?${queryString.stringify(params)}`),
    },

    transaction_point: {
        getList : (params) => axios.get(`api/v1/transaction-point?${queryString.stringify(params)}`),
        getListAll : (params) => axios.get(`api/v1/transaction-point-all?${queryString.stringify(params)}`),
        getTransPoint : (id) => axios.get(`api/v1/transaction-point/${id}`),
        create : (data) => axios.post(`api/v1/transaction-point`,data),
        update : (data) => axios.post(`api/v1/transaction-point-update`,data),
        delete : (id) => axios.post(`api/v1/transaction-point/${id}`),
    },

    payment: {
        registerMemberPayment : (params) => axios.post(`api/v1/payment/register-member`, params),
        card : (params) => axios.post(`api/v1/payment/card`, params),
        getListMember : (kind) => axios.get(`api/v1/payment/members?payment_kind=${kind}`),
        getMember : (id) => axios.get(`api/v1/payment/member/${id}`),
        removeMember : (id) => axios.post(`api/v1/payment/member/destroy`, {id: id}),
        ARS : (data) => axios.post(`api/v1/payment/member/ars`, data),
    },

    contact: {
        store : (data) => {
            const formData = new FormData();
            for ( var key in data ) {
                console.log(key, data[key]);
                formData.append(key, data[key]);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            return axios.post(`api/v1/contact`, formData, config);
        },
    },

    appPrint: {
        getList : (params={}) => axios.get(`api/v1/print/app-prints?${queryString.stringify(params)}`),
        create : (data) => axios.post(`api/v1/print/app-prints`,data),
        delete: (id) => axios.delete(`api/v1/print/app-prints/${id}`),
        deleteAll : () => axios.post(`api/v1/print/del-all-app-prints`),
        // delete: (id, ids) => axios.delete(`api/v1/print/app-prints/${id}`, ids),
        getListNotToday : (params={}) => axios.get(`api/v1/print/list-app-prints-not-today?${queryString.stringify(params)}`),
        deleteNotToday: (ids) => axios.delete(`api/v1/print/app-prints/${JSON.stringify(ids)}`),
    },
    //
    get_hyosung_expired_date: {
        getHyosungExpiredDate : (data) => axios.get('api/get-expired-date', data)
    },

    post_bookmarks_form: {
        
        //postBookmarksFormRegist : (data) => axios.post(`api/v1/consultant/bookmarks-form-regist`, new URLSearchParams(data)),
        //postBookmarksFormRegist : (data) => axios.post(`api/v1/consultant/bookmarks-form-regist`, queryString.stringify(new URLSearchParams(data))),

        /*
        postBookmarksFormRegist : (data) => {
            const formData = new FormData();
            for ( var key in data ) {
                console.log(key, data[key]);
                formData.append(key, data[key]);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            
            return axios.post(`api/v1/consultant/bookmarks-form-regist`, formData, config);
        },*/

        //postBookmarksFormRegist : (data) => axios.post(`api/v1/consultant/bookmarks-form-regist`,data),
        postBookmarksFormRegist : (data) => axios.post(`api/v1/consultant/bookmarks-form-regist`, new URLSearchParams(data)),
        postBookmarksFormDelete : (data) => axios.post(`api/v1/consultant/bookmarks-form-delete`, data),
    },

    //
}
