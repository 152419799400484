 import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from "../../utils/api";
import { showLoading, hideLoading } from '../../store/actions/ui';
 import * as helper from "../../utils/helper";

class ModalListMember extends Component {
  constructor(props) {
    super(props);

    let banks = [];
    helper.getPaymentCompany().map(item => banks[item.code] = item.name);

    this.state = {
      members: [],
      selected: null,
      isOpenConfirmModal: false,
      removeId: null,
        banks: banks,
    }
  }

  modalOpened = () => {
    this.props.showLoading()
    let paymentKind = this.props.PaymentHyosungCMS.paymentKind
    if (paymentKind != 'CARD' && this.props.paymentMethod == 1) {
      paymentKind = 'REALCMS'
    }
    api.payment.getListMember(paymentKind).then(async (response) => {

        const accounts = response.data.data;
        for (let account of accounts) {
           const apiRes =  await api.payment.getMember(account.cms_member_id);
           account['detail'] = apiRes.data.data.member;
        }

        this.props.hideLoading();
        this.setState({
          members: accounts
        })
      })
      .catch(() => {
        this.props.hideLoading()
      })
  }

  changeMember = (e) => {
    this.setState({
      selected: e.target.value
    })
  }

  handleSubmit = () => {
    if (this.state.selected) {
      this.props.openPopup()
      this.props.selectItem(this.state.selected)
    }
  }

  removeMember = (id) => {
    this.setState({
      isOpenConfirmModal: true,
      removeId: id,
    })
  }

  closeConfirmModal = () => {
    this.setState({
      isOpenConfirmModal: !this.state.isOpenConfirmModal,
      removeId: null,
    })
  }

  confirmDelete = () => {
    const { members, removeId } = this.state
    if (removeId) {

      this.props.showLoading()
      api.payment.removeMember(removeId)
        .then((response) => {
          this.props.hideLoading()
          let mems = members.filter(e => e.cms_member_id != removeId)
          this.setState({
            members: mems,
            isOpenConfirmModal: false,
            removeId: null,
          })
        })
        .catch(() => {
          this.props.hideLoading()
        })
    }
  }

  censorPaymentNumber = (number) => {
      const strNumber = number.toString();
      const firstNumber = strNumber.toString().substr(0, 4);
      return firstNumber + '************';
  }

  render() {
    const { members } = this.state

    return (
      <Fragment>
        <Modal isOpen={this.props.isOpenModal} toggle={this.props.openPopup}
          fade={true}
          className={'modal-dialog modal-lg modal-dialog-centered modal-additional modal-payment-check'}
          onOpened={this.modalOpened}
        >
            <div className="modal-header">
              <h5 className="modal-title">멤버아이디 선택</h5>
              <button type="button" className="close close-modal" onClick={this.props.openPopup}>
              <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-additional">
                {members.map((member, key) => (
                  <li className={'pt-0 pb-0'} key={key}>
                    <div className="form-check form-check-inline w-100">
                      <input className="form-check-input" type="radio"
                        id={member.cms_member_id}
                        checked={this.state.selected == member.cms_member_id}
                        value={member.cms_member_id}
                        onChange={this.changeMember}
                      />
                      <label className="form-check-label label_title ml-4 row" htmlFor={member.cms_member_id} style={{width: '100%', height: '100%', paddingTop: '19.5px', paddingBottom: '19.5px'}}>
                        <span className="col-2">{this.props.PaymentHyosungCMS.paymentKind === 'CARD' ? "카드" : '계좌이체'}</span>
                        <span className="col-4">{this.props.PaymentHyosungCMS.paymentKind === 'CARD' ? member.detail.payerName : this.state.banks[member.detail.paymentCompany]}</span>
                        <span className="col-6">{this.censorPaymentNumber(member.detail.paymentNumber)}</span>
                      </label>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.removeMember(member.cms_member_id)}>
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer justify-content-center border-top-0 mb-4">
              <button type="button" className="btn btn-gray" onClick={this.props.openPopup}>취소</button>
              <button type="button" className="btn btn-submit" onClick={this.handleSubmit}>확인</button>
            </div>
        </Modal>
        <Modal
          isOpen={this.state.isOpenConfirmModal}
          toggle={this.closeConfirmModal}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-additional modal-payment-check'}
        >
          <div className="">
            <div className="modal-header">
              <h5 className="modal-title">확인</h5>
              <button type="button" className="close close-modal" onClick={this.closeConfirmModal}>
              <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
              </button>
            </div>
            <div className="modal-body">
              해당 계좌를 삭제하시겠습니까?
            </div>
            <div className="modal-footer justify-content-center border-top-0">
              <button type="button" className="btn btn-gray" onClick={this.closeConfirmModal}>취소</button>
              <button type="button" className="btn btn-submit" onClick={this.confirmDelete}>확인</button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ModalListMember.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  selectItem: PropTypes.func,
  openPopup: PropTypes.func,
  isOpenModal: PropTypes.bool,
};

const FORM_NAME = 'PaymentHyosungCMS';

const mapStateToProps = state => {
  return {
    PaymentHyosungCMS: getFormValues(FORM_NAME)(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalListMember);
