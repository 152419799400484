import React from "react";
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import api from './../utils/api';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, getFormValues, change } from 'redux-form';
import { updateOptionPrint } from './../store/actions/auth';
import MoveToPCPopup  from './../components/Print/MoveToPCPopup';
import PopupInactive from './../components/PopupInactive';
import { FORM_BUILDER_TYPE } from './../utils/constant';
import { totalFeeOption } from './../utils/helper';

class ModalPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            activeAdvancedDiscountCard: false,
            activeDiscountCard: false,
            //checkbox
            storeName: true,
            seller: true,
            contact: true,
            address: true,
            isOpenMoveToPC: false,
            loading: false,
            printState:false,
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
            insurance : false,
        }
    }

    componentDidMount() {


    }

    checkActive = (check) => {
        if (check == "active") {
            this.setState({
                ...this.state,
                active: !this.state.active
            })
        } else if (check == "activeAdvancedDiscountCard") {
            this.setState({
                ...this.state,
                activeAdvancedDiscountCard: !this.state.activeAdvancedDiscountCard
            })
        } else if (check == "activeDiscountCard") {
            this.setState({
                ...this.state,
                activeDiscountCard: !this.state.activeDiscountCard
            })
        }

    }

    onChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            ...this.state,
            [e.target.name]: value
        })
    }

    typePrintWireless = (type) => {
        if (this.props.auth.role == "Member" && this.props.WirelessAdvice.id) {
            let data = {
                sec_consultant_id: this.props.WirelessAdvice.id,
                sec_type: window.currentDevice == 'web' ? 0 : 1,
                sec_uid: this.props.auth.user.id,
                sec_type_export: type
            }
            api.consultant.createStatisticConsultantExport(data)
        }
    }
    typePrintWired = (type) => {
        if (this.props.auth.role == "Member" && this.props.WiredAdvice.id) {
            let data = {
                swec_consultant_id: this.props.WiredAdvice.id,
                swec_type: window.currentDevice == 'web' ? 0 : 1,
                swec_uid: this.props.auth.user.id,
                swec_type_export: type
            }
            api.consultant.createStatisticWiredConsultantExport(data)
        }
    }

    onClickPrint = (link) => {
        if (this.state.active || this.state.activeAdvancedDiscountCard || this.state.activeDiscountCard) {
          if (window.currentDevice == 'web') {
            setTimeout(() => {window.shareOnWeb(link);},4500);
            this.onSaveOptionPrint();
            this.props.toggle(this.props.check);
            setTimeout(() => {
              this.props.popupOutSide();
            },200)

          } else {
            let { WirelessAdvice, WiredAdvice, telcomID } = this.props;        

            let dataPrint = {};
            let checker = link.includes('wired-product-print');
            if (checker) {  // case: print wired quotation 

                this.typePrintWired(1);
                let WiredAdviceStep2 = WiredAdvice?.WiredAdviceStep2 || {};

                let allClassify = [];
                let totalMonthlyFee = 0;
                let deviceNumb = WiredAdviceStep2?.classify?.map((item, index)  => {
                  (item?.classify || []).map(subItem => {
                    allClassify.push(subItem);
        
                    totalMonthlyFee += +(subItem?.device?.wd_commitment_fee || 0)
                    totalMonthlyFee += totalFeeOption(subItem?.options || [])
                  })
                })
                totalMonthlyFee = totalMonthlyFee > 0 ? totalMonthlyFee : 0
        
                let info = '';
                let more_info = '';
                if (allClassify.length == 1) {
                    info = `${allClassify[0]?.device?.wd_name}`
                    more_info = `${allClassify[0]?.device?.wd_name}`
                } 
                if (allClassify.length > 1) {
                    info = `${allClassify[0]?.device?.wd_name} </br> 외 ${allClassify.length - 1 } 건 `
                    more_info = `${allClassify[0]?.device?.wd_name}  외 ${allClassify.length - 1 } 건 `
                }

                dataPrint = {
                  'uid': this.props.auth?.user?.id,
                  'print_type': FORM_BUILDER_TYPE.QUOTATION_WIRED,
                  'telcomID': telcomID,
                  'info': info,
                  'more_info': more_info,
                  'total': totalMonthlyFee,
                  'link': link,
                }

            } else {    //case: print wireless quotation    

                this.typePrintWireless(1);
                var packageFee = WirelessAdvice.WirelessAdviceStep3?.packageFee?.plac_name || WirelessAdvice.WirelessAdviceStep3?.packageFee?.name
                var device = WirelessAdvice.WirelessAdviceStep3?.phone?.device_name || WirelessAdvice.WirelessAdviceStep3?.phone?.name
                var info = `${device} </br> ${packageFee}`
                var more_info = `${device}  ${packageFee}`
                var total = WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyDevice;

                dataPrint = {
                    'uid': this.props.auth?.user?.id,
                    'print_type': FORM_BUILDER_TYPE.QUOTATION_WIRELESS,
                    'telcomID': telcomID,
                    'info': info,
                    'more_info': more_info,
                    'total': total,
                    'link': link,
                  }
            }
            // debugger
            // return
            this.setState({
                ...this.state,
                loading: true,
            }, () => {
                api.appPrint.create(dataPrint).then(res => {
                    if (res?.data?.data) {
                      this.setState({
                        ...this.state,
                        isOpenMoveToPC: true,
                        loading: false
                      }, () => {
                          this.props.toggle(this.props.check);
                          // setTimeout(() => {
                          //   this.props.popupOutSide();
                          // },200)
                      })
                    }
                  })
            })
     
          }

        //   this.props.WirelessAdvice ? this.typePrintWireless(1) : this.typePrintWired(1);
        }
    }

    onOpened = () => {
        if (this.props.advancedDiscountCardID || this.props.discountCardID) {
            this.setState({
                ...this.state,
                activeAdvancedDiscountCard: this.props.advancedDiscountCardID ? true : false,
                activeDiscountCard: this.props.discountCardID ? true : false
            })
        }
    }

    onSaveOptionPrint = () => {
        let data = {
            ...this.props.auth.user?.option_print,
            model_print: {
                storeName:this.state.storeName,
                contact: this.state.contact,
                seller: this.state.seller,
                address: this.state.address,
            }
        }

        api.global.saveOptionPrint(data).then(res => {
            this.props.updateOptionPrint(res.data?.data?.option_print)
        }).catch(err => {
        })
    }

    onClickClosePopup = (e) => {
        let data = {
            ...this.props.auth.user?.option_print,
            model_print: {
                storeName:this.state.storeName,
                contact: this.state.contact,
                seller: this.state.seller,
                address: this.state.address,
            }
        }

        api.global.saveOptionPrint(data).then(res => {
            this.props.updateOptionPrint(res.data?.data?.option_print)
            this.setState({
                printState:false
            })
        }).catch(err => {
        })
        this.props.toggle(true);
    }

    componentWillReceiveProps = (newProps) => {
        if(newProps.auth.user?.option_print?.model_print && !this.state.printState) {
            this.setStateOptionPrint(newProps.auth.user?.option_print?.model_print)
        }
    }

    setStateOptionPrint = (data) => {
        if(data) {
            this.setState({
                storeName: data?.storeName,
                contact: data?.contact,
                seller: data?.seller,
                address: data?.address,
                printState:true
            })
        }
    }

    onToggleMoveToPC = () => {
      this.setState({
        ...this.state,
        isOpenMoveToPC: !this.state.isOpenMoveToPC,
      })
    }

    openPopupDisable = (state) => {
        if (state) {
          this.setState({
            title: '실제 개통에 사용하지 마십시오.',
            content: `
              베타테스트 기간입니다.<br>
              테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
              서류 미비나 금액 오류 등이 발생할 수 있습니다.
            `
          })
        }
        this.setState({
          isOpenPopupDisable: true
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    iconLock: null
                })
            }, 500)
        })
    }

    modelPrintClose = () => {
        this.setState({
          title: null,
          content: null
        })
    }

    render() {
        var { active, activeAdvancedDiscountCard, activeDiscountCard, storeName, seller, contact, address, insurance, elecsubscription } = this.state;
        var { advancedDiscountCardID, discountCardID, telcomID, WirelessAdvice } = this.props
        var string = `&active=${active}&activeAdvancedDiscountCard=${activeAdvancedDiscountCard || false}&activeDiscountCard=${activeDiscountCard ? discountCardID : false}&storeName=${storeName}&seller=${seller}&contact=${contact}&address=${address}&telcomID=${telcomID}&insurance=${insurance || false}`;

        if(WirelessAdvice?.WirelessAdviceStep3){
            if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){
                if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                    let instType = WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.inst_type || 0;
    
                    if(instType == 83 || instType ==84){
                        string = string + "&instType=" + instType;
                    }
                }
            }
        }
        
        return (
        <React.Fragment>

            <Modal
                isOpen={this.props.isOpen}
                toggle={this.onClickClosePopup}
                //className={'modal-dialog-centered modal_custom_print'}
                className={'modal-dialog-centered modal-custom-print'}
                onOpened={this.onOpened}
            >
                <div className="modal-content pl-5 pr-5">
                    <div className="modal-header">
                        <h5 className="modal-title">견적서 출력 </h5>
                        <button
                            type="button"
                            className="close close-modal"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e) => this.onClickClosePopup(e)}
                        >
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="form-modal-output">
                            <h2>
                                출력 상세 설정 및 선택
                            </h2>
                            <ul className="list-status mb-2 h-270 list_sp">
                                <li
                                    className={`item-status h-32 ${this.state.active ? 'active' : ''}`}
                                    onClick={() => this.checkActive('active')}
                                >
                                    <i className="fas fa-check" />
                                    견적서
                                </li>

                                {this.props.advancedDiscountCardID && <li
                                    className={`item-status ${this.state.activeAdvancedDiscountCard ? 'active' : ''}`}
                                    onClick={() => this.checkActive('activeAdvancedDiscountCard')}
                                >
                                    <i className="fas fa-check" />
                                    선할인카드
                                </li>}



                                {this.props.discountCardID && <li
                                    className={`item-status ${this.state.activeDiscountCard ? 'active' : ''}`}
                                    onClick={() => this.checkActive('activeDiscountCard')}
                                >
                                    <i className="fas fa-check" />
                                    청구할인카드
                                </li>}




                            </ul>
                            <h2 className="pt-2">
                                매장정보
                            </h2>
                            <div className="group-check">
                                <div className="form-check">
                                    <div
                                        className="custom-control custom-checkbox mr-sm-2"
                                    >
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customControlAu1261"
                                            name={'storeName'}
                                            onChange={this.onChange}
                                            checked={storeName}
                                        />
                                        <label className="custom-control-label" htmlFor="customControlAu1261">매장명</label>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customContro271"
                                            name={'seller'}
                                            onChange={this.onChange}
                                            checked={seller}
                                        />
                                        <label className="custom-control-label" htmlFor="customContro271">판매자</label>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customContro1281"
                                            name={'contact'}
                                            onChange={this.onChange}
                                            checked={contact}
                                        />
                                        <label className="custom-control-label" htmlFor="customContro1281">연락처 </label>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customContro1291"
                                            name={'address'}
                                            onChange={this.onChange}
                                            checked={address}
                                        />
                                        <label className="custom-control-label" htmlFor="customContro1291">주소 </label>
                                    </div>
                                </div>
                            </div>
                            <h2 className="pt-2">
                                보험정보
                            </h2>
                            <div className="group-check">
                                <div className="form-check">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="insurance"
                                            name={'insurance'}
                                            onChange={this.onChange}
                                            checked={insurance}
                                        />
                                        <label className="custom-control-label" htmlFor="insurance">보험 상세내용 표시 (2장으로 출력) </label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button
                            type="button"
                            className="btn btn-gray w-120"
                            onClick={(e) => this.onClickClosePopup(e)}
                        >
                            취소
                        </button>
                        <button
                            type="button"
                            className="btn btn-submit w-120"
                            id={this.props.idButton}
                            data-print-content={this.props.linkAdress + string}
                            // onClick={() => this.openPopupDisable(true)}
                            onClick={() => this.onClickPrint(this.props.linkAdress + string)}
                            disabled={this.state.loading}
                        >출력</button>
                    </div>
                </div>
            </Modal>

            <MoveToPCPopup
                isOpen = {this.state.isOpenMoveToPC}
                toggle = {this.onToggleMoveToPC}
            />

            <PopupInactive 
                isOpenModal={this.state.isOpenPopupDisable} 
                closePopupDisable={this.closePopupDisableHandle}
                title={this.state.title || null}
                content={this.state.content || null}
                callback={() => this.onClickPrint(this.props.linkAdress + string)}
                onModelClosed={this.modelPrintClose}
                iconLock={this.state.iconLock}
            />

        </React.Fragment>
        )
    }
}

ModalPrint.defaultProps = {
    advancedDiscountCardID: null,
    discountCardID: null,
    telcomID: null,
    check: false,
    popupOutSide: () => { },
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WiredAdvice: getFormValues('WiredAdvice')(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateOptionPrint: (data) => dispatch(updateOptionPrint(data)),
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);


export default compose(
    withConnect,
)(ModalPrint);
