import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    reduxForm,
    getFormSyncErrors,
    getFormValues,
    change,
} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import {showLoading, hideLoading} from '../../../store/actions/ui';
import moment from 'moment'
import {updateWiredAdviceStep1, updateWiredAdviceStep2} from './../../../utils/helper'

class RecentConsultationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
            params: {
                type: 'wired'
            },
            currentPage: 1,
            consultantPerPage: 5
        }
        this.refTriggerClick = React.createRef();
    }
    inputRefs = [];

    handleClickPaginate = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    handleNext = () => {
        const lastPage = this.state.consultants.length / this.state.consultantPerPage;
        this.state.currentPage === lastPage ? this.setState({currentPage: lastPage}) : this.setState({currentPage: this.state.currentPage + 1})
    };

    handleBack = () => {
        this.state.currentPage === 1 ? this.setState({currentPage: 1}) : this.setState({currentPage: this.state.currentPage - 1})
    };


    modalOpened = () => {
        this.props.showLoading()
        this.inputRefs=[]
        this.setState({
            consultants: []
        })
        api.advice.getListConsultant(this.state.params)
            .then(response => {
                this.props.hideLoading()
                this.setState({
                    consultants: response.data.data
                })
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }


    setRef = (ref) => {
        if (ref && ref != null) {
            this.inputRefs.push(ref);
        }
    };

    triggerClickCheckBox = (id) => {
        let dataMapRef = []
        this.inputRefs.map((item) => {
            if (item && item.value && item.value != null && item.value == id) {
                dataMapRef.push(item)
            }
        })
        if (dataMapRef && dataMapRef.length > 0) {
            dataMapRef[0].click()
        }
    }

    openPopup = () => {
        this.props.openPopup()
        this.inputRefs=[]
    }



    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }

    handleSubmit = () => {
        const {selected} = this.state

        this.props.showLoading()
        api.advice.getConsultant(selected)
            .then(response => {
                const {data} = response.data
                const {WiredAdvice: {WiredAdviceStep1, WiredAdviceStep2}} = this.props
                let WiredAdviceStep1Clone = updateWiredAdviceStep1(WiredAdviceStep1, data)
                let WiredAdviceStep2Clone = updateWiredAdviceStep2(WiredAdviceStep2, data)

                // let customer = {
                //     id: data?.customer_id,
                //     name: data?.customer?.name,
                //     phone: data?.customer?.phone,
                //     note: data?.customer_note,
                // }
                this.openPopup()
                this.props.hideLoading()
                this.props.change('id', data.id)
                this.props.change('WiredAdviceStep1', WiredAdviceStep1Clone)
                this.props.change('WiredAdviceStep2', WiredAdviceStep2Clone)
                // this.props.change('CreatePotentialCustomer', customer)
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }



    render() {
        // const {consultants} = this.state

        const {consultants, currentPage, consultantPerPage} = this.state

        const lastPage = currentPage * consultantPerPage;
        const firstPage = lastPage - consultantPerPage;
        const currentConsultants = consultants.slice(firstPage, lastPage);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(consultants.length / consultantPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPagination = pageNumbers.map(number => {
            return (
                <li className={(this.state.currentPage === number? 'active': '')+ ' page-item'} key={number} >
                    <a className="page-link" id={number} onClick={this.handleClickPaginate}>{number}</a>
                </li>
            );
        });

        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-xl /*modal-history-review*/ '}
                onOpened={this.modalOpened}
                contentClassName=""
            >

                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            유선상담 최근 상담내역 <span style={{fontWeight: 'normal', fontSize:13}}>※최근 상담내역은 최대 20건까지 조회할 수 있습니다</span>
                        </h5>
                        <button type="button" className="close close-modal" aria-label="Close"
                                onClick={this.openPopup}>
                          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body modal-counseling-history">
                        <article>
                            <table className="table table-header">
                            <thead>
                                <tr className="bg-purple">
                                    <th className="text-left">통신사</th>
                                    <th className="text-left">모델명</th>
                                    <th className="text-left">요금제</th>
                                    <th className="text-right">상담날짜/시간</th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                            <table className="table">
                                <tbody>
                                {/*{consultants.map((consultant, key) => (*/}
                                {currentConsultants.map((consultant, key) => (
                                    <tr key={key} onClick={ () =>this.triggerClickCheckBox(consultant.id)}>
                                        <td>
                                            <div className="d-flex">
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        ref={this.setRef}
                                                        type="radio"
                                                        id={`check-00${key}`}
                                                        name="customRadio"
                                                        className="custom-control-input"
                                                        onChange={this.handleChangeRadio}
                                                        value={consultant.id}
                                                    />
                                                    <label
                                                        className="custom-control-label ml-3"
                                                        htmlFor={`check-00${key}`}
                                                    >
                                                        {consultant.telcom_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{consultant.model_name_wired == null ? '인터넷' : consultant.model_name_wired}</td>
                                        <td>{consultant.plan_name_wired == null ? '인터넷 요금제' : consultant.plan_name_wired}</td>
                                        <td className="text-right font-14">
                                            <b>
                                                {moment(consultant.updated_at).format('YYYY-MM-DD')}
                                            </b>
                                            <span className="color-sliver ml-1">
                                                {moment(consultant.updated_at).format('HH:mm:ss')}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </div>
                        </article>

                        <nav aria-label="Page navigation example" className="pagination-notifi">
                            <ul className="pagination">
                                <li className="page-item item-previous" >
                                    <a className="page-link" onClick={this.handleBack} aria-label="Previous">
                                            <span aria-hidden="true">
                                                <img src="/images/Left.svg" alt="" />
                                            </span>
                                    </a>
                                </li>
                                {renderPagination}
                                <li className="page-item item-next" >
                                    <a className="page-link" onClick={this.handleNext} aria-label="Next">
                                            <span aria-hidden="true">
                                                <img src="/images//Right.svg" alt="" />
                                            </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn-gray" onClick={this.openPopup}>
                            취소
                        </button>
                        <button type="button" className="btn btn-submit" onClick={this.handleSubmit}>
                            불러오기
                        </button>
                    </div>

            </Modal>
        );
    }
}

RecentConsultationModal.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    openPopup: PropTypes.func,
    isOpenModal: PropTypes.bool,
    WiredAdvice: PropTypes.object,
    change: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        telcomParents: state.dataStore.telcomProviders.telcomParents,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(RecentConsultationModal);
