import React from 'react';
import ModalPrint from "../components/ModalPrint";
import moment from "moment";
import {priceFormat} from "../utils/helper";
const PUBLIC_FOLDER = process.env.REACT_APP_API_PUBLIC_FOLDER;
class DiscountCardPrint extends React.Component{



  renderDCC = (data, type = 'basic') => {
    if(data) {
      var parseJson = JSON.parse(data)
      if (parseJson && parseJson.length > 0) {
        return parseJson.map((item, index) => {
          return (
            <li
              // className="dc-list-import"
              key={index}>- {item.discount_condition} {item.money_condition} 할인 </li>
          )
        })
      }
    }
  }

  renderDCCPromotion = (data) => {
    if(data) {
      var parseJson = JSON.parse(data)
      if (parseJson && parseJson.length > 0) {
        return parseJson.map((item, index) => {
          return (
            <li
              // className="dc-list-import"
              // key={index}>- [신규회원 프로모션] {item.condition} {item.name} {item.money} 할인 </li>
              key={index}>- [신규회원 프로모션] {item.condition} {item.money} 할인 </li>
          )
        })
      }
    }
  }

  render() {
    let {discountCard} = this.props;
    return(
      <section className="estimate-card-info">
        <header>
          <div className="telecom-logo" style={{width: '27px', height: '30px'}}>
            <img src={this.props.imgTecom} alt="SKT" style={{height: '30px', /* marginLeft: '-0px', */ marginTop: '-0px'}} />
          </div>
          <h1>제휴카드 정보 (라이트할부카드)</h1>
          <span className="info-date">{moment().format('YYYY-MM-DD')}</span>
        </header>
        {/* 카드 기본 정보 */}
        <article className="info-card-default-wrap">
          <div className="card-dc-sec">
            <div className="card-dc-image">
              <img src={PUBLIC_FOLDER+'/storage/discount_card/original/'+discountCard.dcc_image} alt="" />
            </div>
            <div className="card-dc-detail">
              <p className="dc-detail-title"><strong> {discountCard.dcc_name}</strong><span>자동이체 미필수</span></p>
              <div className="dc-detail-list">
                <ul>
                  {this.renderDCC(discountCard.dcc_discount)}
                  {this.renderDCCPromotion(discountCard.dcc_promotion)}
                </ul>
              </div>
            </div>
          </div>
          <table className="card-annualfee-center-sec">
            <caption>제휴카드의 연회비,발급센터에 관한 표</caption>
            <colgroup>
              <col style={{width: '100px'}} />
              <col style={{width: '52px'}} />
              <col style={{width: 'auto'}} />
              <col style={{width: '100px'}} />
              <col style={{width: '64px'}} />
              <col style={{width: 'auto'}} />
            </colgroup>
            <tbody>
            <tr>
              <th rowSpan={2}>연회비</th>
              <td className="annualfee-center-scope">국내</td>
              <td>{discountCard.dcc_anual_fee_foreign ? priceFormat(discountCard.dcc_anual_fee_foreign) : 0}원</td>
              <th rowSpan={2}>발급센터</th>
              <td className="annualfee-center-scope">ars</td>
              <td>{discountCard.dcc_center_ars}</td>
            </tr>
            <tr>
              <td className="annualfee-center-scope">해외</td>
              <td>{discountCard.dcc_anual_fee_internal ? priceFormat(discountCard.dcc_anual_fee_internal) : 0}원</td>
              <td className="annualfee-center-scope">온라인</td>
              <td>{discountCard.dcc_center_online}</td>
            </tr>
            </tbody>
          </table>
        </article>
        {/* 카드 혜택 정보 */}
        <article className="info-card-benefit-wrap">
          <table className="card-promotion-sec">
            <caption>제휴카드의 카드혜택에 관한 표</caption>
            <colgroup>
              <col style={{width: '100px'}} />
              <col style={{width: 'auto'}} />
            </colgroup>
            <tbody>
            <tr>
              <th>카드혜택</th>
              <td>
                <div>
                  <div dangerouslySetInnerHTML={{__html: discountCard.dcc_benefit }}/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </article>
        {/* 카드 전월 실적 정보 */}
        <article className="info-card-result-wrap">
          <table className="card-month-sec">
            <caption>제휴카드의 전월실적에 관한 표</caption>
            <colgroup>
              <col style={{width: '100px'}} />
              <col style={{width: '85px'}} />
              <col style={{width: 'auto'}} />
            </colgroup>
            <tbody>
            <tr>
              <th rowSpan={2}>
                전월실적
              </th>
              <td>포함 대상</td>
              <td>{discountCard.dcc_include ?    <div dangerouslySetInnerHTML={{__html: discountCard.dcc_include.replace(/\r?\n/g, '<br />') }}/> : ''}</td>
            </tr>
            <tr>
              <td>미포함 대상</td>
              <td>{discountCard.dcc_not_include ?    <div dangerouslySetInnerHTML={{__html: discountCard.dcc_not_include.replace(/\r?\n/g, '<br />') }}/> : ''}</td>
            </tr>
            </tbody>
          </table>
          <p className="card-result-warn">
            <span>※</span>위 카드내용은 카드사 및 제휴사에 따라 변경이 될 수 있으며, 할인 혜택 및 상세 제공 조건에 대해서는 카드사 홈페이지 및 통신사 정보를 확인하셔야 합니다.
          </p>
        </article>
      </section>
    )
  }
}

DiscountCardPrint.defaultProps = {
  imgTecom : null,
  discountCard : {}
};


export default DiscountCardPrint;
