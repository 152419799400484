import React, {Fragment} from 'react';
import OpenPopupDiscountCard from "./OpenPopupDiscountCard";
import {getDiscountCard} from '../../../store/actions/discount_card';
import {connect} from 'react-redux';

class PopupDiscountCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenDiscountCard: false,
            discountCard : []
        }
    }

    componentDidMount() {
        this.props.discountCard().then(res => {
            this.setState({
                discountCard : res
            })
        })
    }


    openPopupDiscountCard = () => this.setState({isOpenDiscountCard: !this.state.isOpenDiscountCard})


    render() {
        return (
            <Fragment>
                <button
                    className={'btn btn-success'}
                    onClick={this.openPopupDiscountCard}
                >
                    discount card
                </button>
                <OpenPopupDiscountCard
                    isOpenDiscountCard={this.state.isOpenDiscountCard}
                    openPopupDiscountCard={this.openPopupDiscountCard}
                    discountCard={this.state.discountCard}
                />


            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        discountCard: () => dispatch(getDiscountCard())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupDiscountCard);
