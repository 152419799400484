
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector } from 'redux-form';
import validate from './validate-step-1.js';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import * as routerName from '../../../utils/routerName';

class CreateReportStep1 extends Component {

    componentDidMount() {
    }



    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <section className="form-box">
                    <form action>
                        <div className="content-form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-3 border-right-gray mt-4">
                                        <div className="form-group ">
                                            <label htmlFor className="label-text">고객명</label>
                                            <input type="text" className="form-control h-44" placeholder="고객명" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor className="label-text">개통번호</label>
                                            <input type="text" className="form-control h-44" placeholder="개통번호" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor className="label-text">개통일</label>
                                            <div className="form-date">
                                                <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-calender.svg"} alt="" />
                                                <input type="text" className="form-control h-44" placeholder="개통일" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor className="label-text">거래처</label>
                                            <input type="text" className="form-control h-44" placeholder="거래처" />
                                        </div>
                                        <div className="form-group">
                                            <label className="label-text"> 판매자 </label>
                                            <input className="form-control h-44 bg_input" defaultValue="Ann Boo" />
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-4 border-right-gray mt-4">
                                                <div className="form-group ">
                                                    <label className="label-text"> 통신사 </label>
                                                    <input className="form-control h-44 bg_input" defaultValue="SKT" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-text"> 개통유형 </label>
                                                    <input className="form-control h-44" defaultValue="신규가입" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-text"> 할인방식 </label>
                                                    <input className="form-control h-44 bg_input" defaultValue="공시지원금" />
                                                </div>
                                            </div>
                                            <div className="col-4 border-right-gray mt-4">
                                                <div className="form-group ">
                                                    <label className="label-text"> 단말기 </label>
                                                    <input className="form-control h-44 bg_input" defaultValue="아이폰 11 (64GB)" />
                                                </div>
                                                <div className="form-group ">
                                                    <label className="label-text"> 일련번호 </label>
                                                    <input className="form-control h-44" defaultValue="일련번호" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-text"> 개통구분 </label>
                                                    <input className="form-control h-44 bg_input" defaultValue="할부개통" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label htmlFor className="label-text">요금제</label>
                                                    <input type="text" className="form-control h-44" placeholder="5GX Standard" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor className="label-text d-flex">
                                                        부가서비스
                                                        <div className="flex-title mb-0">
                                                            <a href>
                                                                상세보기
                                                                <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-12.svg"} alt="" />
                                                            </a>
                                                        </div>
                                                    </label>
                                                    <textarea rows={7} className="form-control bg_input" defaultValue={"- wavve 앤 데이터 \n- VR 팩 기어 (3개월 분납)"} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor className="label-text">메모</label>
                                                    <textarea rows={6} className="form-control" defaultValue={"메모"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="main-btn-submit">
                                            <div className="group-button w-100">
                                                <button className="btn btn-button btn-gray mr-2 ml-2" type="button" data-toggle="modal" data-target="#register-modal">취소</button>
                                                <button type="button" className="btn btn-button btn-submit mr-2 ml-2" data-link="/create-report-sale-step-2.html">다음</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </Fragment>
        );
    }
}

CreateReportStep1.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
    initialValues: {

    },

});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CreateReportStep1);
