import React from 'react';
import {Modal} from 'reactstrap';

class OpenPopupPrint extends React.Component{
    render(){
        return (
            <Modal
                className={'modal-dialog-centered modal-lg modal-print'}
                isOpen={this.props.isOpenPopupPrint}
                toggle={this.props.openPopupPrint}
                // backdrop={"static"}
            >
                <div className="modal-header border-bottom-0">
                    <button
                        type="button"
                        className="close close-modal"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        onClick={this.props.openPopupPrint}
                    >
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <div className="modal-body mt-4 mb-3">
                    <h5 className="title">출력</h5>
                    <div className="button-print">
                        <button className="btn btn-print">
                            <img src="/images/print-icon.svg" alt="" />
                            <span>서식지 출력하기</span>
                        </button>
                        <button
                          className="btn btn-print print-active"
                          id={'print-list-discount-card'}
                          data-print-content={process.env.REACT_APP_HOSTNAME+'/popup-list-detail-discount-card'}
                        >
                            <img
                              src="/images/print-icon.svg"
                              alt=""
                              id={'print-list-discount-card-img'}
                            />
                            <span>견적서 출력하기</span>
                        </button>
                    </div>
                </div>

            </Modal>
        )
    }
}

export default OpenPopupPrint;
