import React from 'react';
import { Modal, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse } from 'reactstrap';
import api from "../utils/api";
import { hideLoading, showLoading } from "../store/actions/ui";
import { connect } from "react-redux";
import { compose } from "redux";
import { updateOptionPrint } from '../store/actions/auth';
import { isBuffer } from 'lodash';
import MoveToPCPopup from './../components/Print/MoveToPCPopup';
import PopupInactive from './../components/PopupInactive';
import {FORM_BUILDER_TYPE} from './../utils/constant';
import * as helper from './../utils/helper';

//
import {setBookMark} from '../store/actions/plan_information';
import {postBookmarksFormRegist, postBookmarksFormDelete} from '../store/actions/post_bookmarks_form';

//

class PopupPageEmpty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: {},
            collapse: false,
            formBuilderByTelcom: [],
            fhb_wd_type: 'wireless',
            filter_name: '',
            result: {},
            seller: false,
            customer: false,
            isOpenMoveToPC: false,
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
            //
            staractive: null, //즐겨찾기 활성화, 비활성화를 위해 추가
            checkbook: null,
            bookMarkPlan: [],
            bookMarkPlanCustom: [],
            model: '',
            plan: '',
            consultant:{
                data:[]
            },
            star : []

            //
        }
    }

    componentDidMount() {
        if (this.props.telcom.length > 0) {

            this.setState({
                activeTab: {
                    ...this.state.activeTab,
                    [this.props.telcom[0].id]: true
                }
            }, () => {
                let data = {
                    filter_telcom: this.props.telcom[0].id,
                    fhb_type: 0,
                    fhb_wd_type: this.state.fhb_wd_type,
                    filter_name: this.state.filter_name
                }
                // this.props.showLoading();

                this.getFromBuilder(data).then(res => {
                    // this.props.hideLoading();
                    this.setState({
                        formBuilderByTelcom: res.data.data
                    })
                })
            })
        }
    }

    getFromBuilder = (data) => {
        return api.consultant.getFormBuilder(data);
    }

    toggleActiveTab = (active) => {

        //className={`${planFirstActiveId == item.id && checkPlanNew == 'planSecond' ? 'disabled' : activeID == item.id ? 'active' : ''}`}

        this.setState({
            filter_name: '',
            activeTab: {
                [active]: true
            }
        }, () => {
            let tabActive = this.getKeyByValue(this.state.activeTab, true)
            let data = {
                filter_telcom: tabActive,
                fhb_type: 0,
                fhb_wd_type: this.state.fhb_wd_type,
                filter_name: this.state.filter_name
            }
            this.props.showLoading();
            this.getFromBuilder(data).then(res => {
                this.props.hideLoading();
                this.setState({
                    formBuilderByTelcom: res.data.data,
                    //
                    checkbook : data.filter_telcom
                })
            })
                .catch(() => {
                    this.props.hideLoading();
                })

        })

    }

    onClickCollapse = (e, id) => {

        e.stopPropagation();
        this.setState({
            collapse: {
                ...this.state.collapse,
                [id]: !this.state.collapse[id]
            }
        })
    }

    onClickCollapseGroup = (e, item) => {
        e.stopPropagation();
        let { result } = this.state;
        if (result[`${item.fhb_sort}-${item.fhb_id}`]) {
            delete result[`${item.fhb_sort}-${item.fhb_id}`];

            this.setState({
                result
            })

        } else {
            let arrayResult = [];
            if (item.form_habitat_version.length > 0) {
                item.form_habitat_version.map(child => {
                    arrayResult.push({
                        fhv_id: child.fhv_id, 
                        fhv_name: child.fhv_name, 
                        image: item.telcom.image_url.original
                    })
                })

            }

            if (arrayResult.length > 0) {
                result = {
                    ...result,
                    [`${item.fhb_sort}-${item.fhb_id}`]: arrayResult
                }

            }

            // result = helper.sortObject(result)

            this.setState({
                result
            })
        }
    }

    getResultById = (id, child, image) => {
        let { result } = this.state;
        // xoa khi click van la item do

        if (result[id] && result[id].length > 0) {
            let check = true;
            result[id].map((item, index) => {
                if (item.fhv_id == child.fhv_id) {
                    check = false
                    delete result[id].splice(index, 1);
                }
            })
            if (check) {
                result[id].push({ fhv_id: child.fhv_id, fhv_name: child.fhv_name, image })
            }
        } else {
            result = {
                ...result,
                [id]: [
                    { fhv_id: child.fhv_id, fhv_name: child.fhv_name, image }
                ]
            }
        }

        if (result[id].length === 0) {
            delete result[id];
        }

        result = helper.sortObject(result)

        this.setState({
            result
        })
    }

    getKeyByValue(object, value) {
        for (var prop in object) {
            if (object.hasOwnProperty(prop)) {
                if (object[prop] === value)
                    return prop;
            }
        }
    }

    onChangeWire = (e, wire) => {
        // e.preventDefault();
        this.setState({
            fhb_wd_type: wire,
            filter_name: '',
        }, () => {
            let tabActive = this.getKeyByValue(this.state.activeTab, true)
            let data = {
                filter_telcom: tabActive,
                fhb_type: 0,
                fhb_wd_type: this.state.fhb_wd_type,
                filter_name: this.state.filter_name
            }
            this.props.showLoading();
            this.getFromBuilder(data).then(res => {
                this.props.hideLoading();
                this.setState({
                    formBuilderByTelcom: res.data.data
                })
            })
                .catch(() => {
                    this.props.hideLoading();
                })
        })
    }

    removeResultId = id => {
        let { result } = this.state;
        let key = '';
        Object.keys(result).map(item => {
            result[item].map((child, index) => {
                if (child.fhv_id == id.fhv_id) {
                    //key = item
                    result[item].splice(index, 1)
                }
            })

            if (result[item].length == 0) {
                delete result[item];
            }

        })

        this.setState({
            result
        })
    }

    onClickSearch = (e) => {
        e.preventDefault();
        let tabActive = this.getKeyByValue(this.state.activeTab, true)
        let data = {
            filter_telcom: tabActive,
            fhb_type: 0,
            fhb_wd_type: this.state.fhb_wd_type,
            filter_name: this.state.filter_name
        }
        this.props.showLoading();
        this.getFromBuilder(data).then(res => {
            this.props.hideLoading();
            this.setState({
                formBuilderByTelcom: res.data.data
            })
        })
            .catch(() => {
                this.props.hideLoading();
            })
    }

    onChangeCheckbox = (event) => {
        // event.preventDefault();
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onChangeInput = e => {
        e.preventDefault();
        this.setState({
            filter_name: e.target.value
        }, () => {
            let tabActive = this.getKeyByValue(this.state.activeTab, true)
            let data = {
                filter_telcom: tabActive,
                fhb_type: 0,
                fhb_wd_type: this.state.fhb_wd_type,
                filter_name: this.state.filter_name
            }
            // this.props.showLoading();
            this.getFromBuilder(data).then(res => {
                // this.props.hideLoading();
                this.setState({
                    formBuilderByTelcom: res.data.data
                })
            })
        })
    }

    checkActive = (keyResult, id) => {
        let { result } = this.state;
        let check = false;
        if (result[keyResult] && result[keyResult].length > 0) {
            result[keyResult].map(item => {
                if (item.fhv_id == id) {
                    check = true;
                }
            })
            return check;
        }
        return check;
    }

    onClickResultPage = (arrayValueResult) => {        
        // e.preventDefault();
        var ids = [];
        let { result } = this.state;

        if (Object.keys(result).length > 0) {
            Object.keys(result).map(item => {
                result[item].map(child => {
                    ids.push(child.fhv_id)
                })
                // ids.push(result[item].fhv_id)
            })
        }
        var href = process.env.REACT_APP_API_URL + 'print-page-empty?id=' + ids + '&seller=' + this.state.seller + '&customer=' + this.state.customer;
        if (ids.length > 0) {
            this.onClickClosePopup();
            let info = '';
            let more_info = '';
            if (arrayValueResult.length == 1) {
                info = `${arrayValueResult[0]?.fhv_name}`        
                more_info = `${arrayValueResult[0]?.fhv_name}`        
            } 
            if (arrayValueResult.length > 1) {
                info = `${arrayValueResult[0]?.fhv_name} </br> 외 ${arrayValueResult.length - 1 } 건 `
                more_info = `${arrayValueResult[0]?.fhv_name} 외 ${arrayValueResult.length - 1 } 건 `
            } 
            // this.setState({
            //     ...this.state,
            //     isOpenMoveToPC: true
            // }, () => {
            // })
            // debugger
            // return

            if (window.currentDevice == 'web') {
                window.shareOnWeb(href);
                this.props.toggle();
            } else {
              let dataPrint = {
                'uid': this.props.auth?.user?.id,
                'print_type': FORM_BUILDER_TYPE.BUILDER_BLANK,
                'telcomID': Object.keys(this.state.activeTab)[0],
                'info': info,
                'more_info': more_info,
                'total': '',
                'link': href,
              }
        
            api.appPrint.create(dataPrint).then(res => {
                if (res?.data?.data) {
                this.setState({
                    ...this.state,
                    isOpenMoveToPC: true
                }, () => {
                })
                }
            })
          }
        } else {
            alert('출력할 서식지를 1개 이상 선택해 주세요.')
        }
    }

    onClickClosePopup = (e) => {
        let data = {
            ...this.props.auth.user?.option_print,
            home: {
                seller:this.state.seller,
                customer: this.state.customer,
                fhb_wd_type: this.state.fhb_wd_type,
            }
        }

        api.global.saveOptionPrint(data).then(res => {
            this.props.updateOptionPrint(res.data?.data?.option_print)
        }).catch(err => {
        })
        this.props.toggle();
    }

    componentWillReceiveProps = (newProps) => {    
        this.setStateOptionPrint(newProps.auth.user?.option_print?.home)
    }

    setStateOptionPrint = (data) => {
        if(data) {
            this.setState({
                customer:data.customer,
                fhb_wd_type:data.fhb_wd_type,
                seller:data.seller
            })
        }
    }

    onToggleMoveToPC = () => {
      this.setState({
        ...this.state,
        isOpenMoveToPC: !this.state.isOpenMoveToPC,
      })
    }

    modalClosed = () => {
      this.setState({
        result: {}
      })
    }

    openPopupDisable = (e, state, value) => {
        e.preventDefault();
        var ids = [];
        let { result } = this.state;

        if (Object.keys(result).length > 0) {
            Object.keys(result).map(item => {
                result[item].map(child => {
                    ids.push(child.fhv_id)
                })
                // ids.push(result[item].fhv_id)
            })
        }

        if (ids.length == 0) {
            alert('출력할 서식지를 1개 이상 선택해 주세요.')
            return;
        }

        if (state) {
            this.setState({
                title: '실제 개통에 사용하지 마십시오.',
                content: `
                    베타테스트 기간입니다.<br>
                    테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
                    서류 미비나 금액 오류 등이 발생할 수 있습니다.
                `
            })
        }
        this.setState({
            isOpenPopupDisable: true,
            iconLock: value
        })
    }

    modelPrintClose = () => {
        this.setState({
            title: null,
            content: null
        })
    }

    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false
        })
    }

    //
    
    onClickAddBookmark = (item) => {

        let dataClass = {
            fhb_id :item.fhb_id
        }

        let staractive = [...this.state.formBuilderByTelcom];

        for(let i=0; i<staractive.length;i++){
            if(staractive[i].fhb_id == item.fhb_id){
                if(staractive[i].bookmark_form_habitats == null){
                    api.post_bookmarks_form.postBookmarksFormRegist(dataClass).then(response => {
                        
                            if(response.data){
                                let check = [...this.state.formBuilderByTelcom]; 
                                for(let j=0;j<check.length; j++){ 
                                    if(check[j].fhb_id == item.fhb_id){ 
                                        check[j].bookmark_form_habitats = {}; 
                                    }
                                }
 
                                this.setState({
                                    ...this.state,
                                    formBuilderByTelcom : check
                                })
                                alert("즐겨찾기 등록에 성공했습니다.");
                            }
                            else{
                                console.log("등록 실패", response.data);
                            }
                        })
                }
                else{
                    this.props.postBookmarksFormDelete(dataClass).then((response2) => {
                        if(response2.data){
                            let check2 = [...this.state.formBuilderByTelcom]; 
                            for(let j=0;j<check2.length; j++){ 
                                if(check2[j].fhb_id == item.fhb_id){ 
                                    check2[j].bookmark_form_habitats = null; 
                                }
                            }
                            
                            alert("즐겨찾기에서 해제되었습니다.");
                            this.setState({
                                ...this.state,
                                formBuilderByTelcom : check2
                            })

                            let checkbook = this.state.checkbook;
                            if(checkbook == '-1'){
                                
                                this.toggleActiveTab("-1");
                            }
                        }
                        else {
                            console.log("삭제 실패");
                        }
                    })
                }
            }
        }
 

    }

    //

    render() {
        let { activeTab, collapse, formBuilderByTelcom, fhb_wd_type, result } = this.state;
        let tabActive = '';
        
        if (Object.keys(activeTab).length > 0) {
            tabActive = this.getKeyByValue(activeTab, true)
        }

        let arrayValueResult = [];
        if (Object.keys(result).length > 0) {
            Object.keys(result).map(item => {
                if (result[item].length > 0) {
                    result[item].map(child => {
                        arrayValueResult.push(child)
                    })
                }
            })
        }

        return (
        <React.Fragment>
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.onClickClosePopup}
                className={'modal-dialog-centered modal-xl modal-habitat-output modal-customd_05'}
                contentClassName={'pl-5 pr-5'}
                onClosed={this.modalClosed}
            >

                <div className="modal-header">
                    <h5 className="modal-title">빈 서식지 출력</h5>
                    <button type="button" className="close close-modal" onClick={(e) => this.onClickClosePopup(e)}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <div className="modal-body p-0">
                    <div className={'top'}>
                        <h2>통신사 선택 </h2>
                        <Nav tabs className={'list-tab w-100'}>

                            {this.props.telcom.map((item, index) => {
                                
                                return (
                                    <NavItem className={'tab-item'} key={index}>
                                        <NavLink
                                            className={activeTab[item.id] ? 'active' : ''}
                                            onClick={() => { this.toggleActiveTab(item.id); }}
                                        >
                                            {item.name}
                                        </NavLink>   
                                
                                    </NavItem>
                                    
                                )
                            })}
                            <NavItem className={'tab-item'}>
                                <NavLink className={activeTab['-1'] ? 'active' : ''}
                                    onClick={() => { this.toggleActiveTab("-1"); }}  
                                >  
                                {"즐겨찾기"}
                                </NavLink>                                   
                            </NavItem>


                        </Nav>
                    </div>


                    <div className="tab-content" id="myTabContendt">
                        <div className="tab-pane fade active show" id="all1" role="tabpanel" aria-labelledby="all1-tab">
                            <div className="form-modal-output">
                                <div className="flex-row">
                                    <div className="item-row">
                                        <div className="form-group row mb-0">
                                            <label className="text-output mb-0 col-sm-5">서식지 선택</label>
                                            <div className="col-sm-7 pl-0">
                                                <div className="group-radio">
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            type="radio"
                                                            id="customRadio31"
                                                            name="wire"
                                                            className="custom-control-input"
                                                            onChange={(e) => this.onChangeWire(e, 'wireless')}
                                                            checked={fhb_wd_type == 'wireless' ? true : false}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customRadio31">무선</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            type="radio"
                                                            id="customRadio32"
                                                            name="wire"
                                                            className="custom-control-input"
                                                            onChange={(e) => this.onChangeWire(e, 'wired')}
                                                            checked={fhb_wd_type == 'wired' ? true : false}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customRadio32">유선</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-row pl-3">
                                        <div className="form-group row mb-0">
                                            <label className="text-output mb-0 col-sm-5">출력되는 서식지</label>
                                            <div className="col-sm-7 pl-0">
                                                <div className="group-check justify-content-between">
                                                    <label className="label-text mr-4">형광펜</label>
                                                    <div className="form-check">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="customCont12"
                                                                name={'seller'}
                                                                checked={this.state.seller}
                                                                onChange={this.onChangeCheckbox}
                                                            />
                                                            <label className="custom-control-label" htmlFor="customCont12">판매자</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-check">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="customCo13"
                                                                name={'customer'}
                                                                onChange={this.onChangeCheckbox}
                                                                checked={this.state.customer}
                                                            />
                                                            <label className="custom-control-label" htmlFor="customCo13">고객</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="item-row p-0">
                                        <div className="form-search mr-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="검색"
                                                onChange={this.onChangeInput}
                                            />
                                            <button
                                                className="btn"
                                                onClick={this.onClickSearch}
                                                type={'button'}
                                            >

                                                <img
                                                    src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/fill-01.svg"}
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                        <div className="accordion-status bg-white pt-0 mt-3 pl-0 pr-4"> 
                                            <div className="accordion" >
                                                {formBuilderByTelcom.length > 0 && formBuilderByTelcom.map((item, index) => {
                                                    return (
                                                        <div className={`card ${result[`${item.fhb_sort}-${item.fhb_id}`] ? 'active-card' : ''}`} key={index}>
                                                            <div className="card-header p-0">
                                                                <h2 className="mb-0 p-0">
                                                                    <button className={`btn btn-block text-left ${result[`${item.fhb_sort}-${item.fhb_id}`] ? '' : 'collapsed'}`}
                                                                        type="button"
                                                                        data-toggle="collapse"
                                                                        data-target="#collapseOne1"
                                                                        aria-expanded="false"
                                                                        aria-controls="collapseOne1"
                                                                        onClick={(e) => this.onClickCollapseGroup(e, item)}
                                                                        
                                                                    >
                                                                        <i
                                                                            className="fas fa-check"
                                                                            onClick={(e) => this.onClickCollapseGroup(e, item)}
                                                                        />
                                                                        {item.fhb_name}
                                                                        
                                                                        <span
                                                                            className="img-collapse"
                                                                            onClick={(e) => this.onClickCollapse(e, `${item.fhb_sort}-${item.fhb_id}`)}
                                                                        >
                                                                            {collapse[`${item.fhb_sort}-${item.fhb_id}`]
                                                                                ? <img
                                                                                    src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close-01.svg`}
                                                                                    alt=""
                                                                                
                                                                                />
                                                                                : <img
                                                                                    src={process.env.REACT_APP_HOSTNAME + `/tsc_template/src/images/pop-close.svg`}
                                                                                    alt=""

                                                                                />}

                                                                        </span>
                                                                        <span onClick={() => this.onClickAddBookmark(item)} style={{padding : 15}}>
                                                                            {item.bookmark_form_habitats ? 
                                                                                <i className="far fa-star fa-lg" style={{color : "#f8ba34"}}></i> : 
                                                                                <i className="far fa-star fa-lg" style={{color : "#9496ab"}}></i>}
                                                                                
                                                                            
                                                                        </span>
                                                                    </button>
                                                                    
                                                                </h2>

                                                            </div>

                                                            {item.form_habitat_version.length > 0 &&
                                                                <Collapse isOpen={collapse[`${item.fhb_sort}-${item.fhb_id}`]}>
                                                                    <div className="card-body p-0">
                                                                        <ul className="list-status bg-white">

                                                                            {item.form_habitat_version.map((child, i) => {
                                                                                return (
                                                                                    <li
                                                                                        className={`item-status ${result[`${item.fhb_sort}-${item.fhb_id}`] && this.checkActive(`${item.fhb_sort}-${item.fhb_id}`, child.fhv_id) ? 'active' : ''}`}
                                                                                        key={i}
                                                                                        onClick={() => this.getResultById(`${item.fhb_sort}-${item.fhb_id}`, child, item.telcom.image_url.original)}
                                                                                    >
                                                                                        <i className="fas fa-check" />
                                                                                        {child.fhv_name}
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </Collapse>
                                                            }

                                                        </div>
                                                        
                                                    )
                                                    
                                                })}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="item-row list-status px-4">
                                        {arrayValueResult.length > 0 && arrayValueResult.map((item, index) => {
                                            return (
                                                <li className="item-status active" key={index}>
                                                    <div className="logo-img">
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                    <span className="text_left">
                                                        {item.fhv_name}
                                                    </span>
                                                    <button
                                                        className="btn btn-delete"
                                                        type={'button'}
                                                        onClick={() => this.removeResultId(item)}
                                                    >
                                                        <img src="../../images/icon-close.svg" alt="" />
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-4">
                    <button
                        type="button" className="btn btn-gray pl-5 pr-5"
                        onClick={(e) => this.onClickClosePopup(e)}
                    >
                        취소
                    </button>
                    <button
                        type="button"
                        className="btn btn-submit pl-5 pr-5"
                        onClick={(e) => this.onClickResultPage(arrayValueResult)}
                        // onClick={(e) => this.openPopupDisable(e, true)}
                    >
                        출력
                    </button>
                </div>

             
            </Modal>
            <MoveToPCPopup
                isOpen = {this.state.isOpenMoveToPC}
                toggle = {this.onToggleMoveToPC}
            />
            <PopupInactive
                isOpenModal={this.state.isOpenPopupDisable}
                closePopupDisable={this.closePopupDisableHandle}
                title={this.state.title || null}
                content={this.state.content || null}
                callback={this.state.title ? (() => this.onClickResultPage(arrayValueResult)) : null}
                onModelClosed={this.modelPrintClose}
                iconLock={this.state.iconLock}
            />
        </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()), 
        updateOptionPrint: (data) => dispatch(updateOptionPrint(data)),
        setBookMark: (data) => dispatch(setBookMark(data)),
        postBookmarksFormRegist: (data) => dispatch(postBookmarksFormRegist(data)),
        postBookmarksFormDelete: (data) => dispatch(postBookmarksFormDelete(data)),
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(PopupPageEmpty);
