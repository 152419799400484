import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import _ from "lodash";
class LGUPatron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            list: [
                {
                    idx: 1,
                    value: "10_15_years",
                    name: "15년 ~ 30년 미만",
                    type: "OPTION",
                    code: "LOYAL_YEARS",
                },
                {
                    idx: 2,
                    value: "over_30_years",
                    name: "30년 이상",
                    type: "OPTION",
                    code: "LOYAL_YEARS"
                },
            ],
            selected: null
        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {
        this.props.onSelect(this.state.selected, "lgu_patron");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.setState({
            selected: this.props.selected
        })
    }

    onSelect = (e, value) => {
        this.setState({
            selected: value
        }, () => {
            this.toggleModal(e)
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }

    render() {
        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">장기고객 할인

                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                    >
                        { this.props.selected ? `${this.props.selected.name}`  : `미적용`}
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">무선요금제 가입년수 합산</h5>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                <li className={!this.state.selected ? "active" : "" }
                                    onClick={(e) =>  this.setState({
                                        selected: null
                                    }, () => {
                                        this.toggleModal(e)
                                    })}
                                >
                                    미적용
                                </li>
                                {
                                     _.map(this.state.list,  (value, key) => {
                                        return (
                                            <li key={key}
                                                className={this.state.selected && this.state.selected.idx == value.idx ? "active" : ""}
                                                onClick={(e) => this.onSelect(e, value)}
                                            >
                                                 { value.name}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

LGUPatron.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(LGUPatron);