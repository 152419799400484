 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { priceFormat, roundUpNumber } from './../../utils/helper'

class WirelessSubTotalPerMonth extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
      <h3 className=" title-insurrance d-flex align-items-center">
        <p className="mb-0"> 보험 / 유심 / 부가 서비스 (C)</p>
        <span style={{marginLeft: '5px', fontSize: 13, color: '#363C58', fontWeight: 'normal'}}>*별도청구금액</span>
        {/* <p className="mb-0 label-prices text-left">월별 청구금액 <br /> <span className="small-color">(VAT 포함)</span></p>
        <p className="text-right mb-0 prices-blue">
            {priceFormat(WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyOption)}
          <span className="small-color">원</span>
        </p>  */}
      </h3>
    );
  }
}

WirelessSubTotalPerMonth.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessSubTotalPerMonth);
