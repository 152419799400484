
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat} from "../../../utils/helper";

class MessageCreateCustomerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    openModal = () => {
        this.props.openMessageCreateCustomer(true)
    }

    render() {
        const {handleSubmit, WirelessAdvice} = this.props;


        return (
            <Modal isOpen={this.props.isOpenModal}
                   toggle={this.props.openMessageCreateCustomer} fade={true}
                   className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
                   contentClassName="modal-penalty content">
                <div className="modal-header">
                    <h5 className="modal-title">가망고객 등록 </h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                            onClick={this.props.openMessageCreateCustomer}>
                     <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody className="discount-card pl-0 pr-0 pt-0">
                    <div className={'text-center m-3'}>
                        <img src="/images/Icon-modal-01.svg" alt="icon" className="mb-4" />
                        <h4 className={'text-center m-2'}>등록되었습니다!</h4>
                        <p className={'text-center m-2'}>등록된 정보는 가망고객에서 확인 가능합니다.</p>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-center border-top-0 m-4">
                    <button type="button" className="btn btn-submit pl-5 pr-5" onClick={()=>this.openModal()}>확인</button>
                </ModalFooter>
            </Modal>
        );
    }
}

MessageCreateCustomerModal.propTypes = {};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(MessageCreateCustomerModal);
