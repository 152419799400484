import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class Sales extends Component {
  componentDidMount() {
    
  }


  render() {
    return (
      <section>
        <div className="container">
            <div className="sales_report">
                <div className="fill_search">
                    <form action="">
                        <div className="row d-flex justify-content-between align-items-center mb-4">
                            <div className="col-md-4 d-flex align-items-center">
                                <a href="" className="previous"><i className="fas fa-angle-left"></i></a>
                                <div className="dropdown mx-2">
                                    <a className="btn btn_blue dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        May, 2020
                                    </a>
                                </div>
                                <a href="" className="next"><i className="fas fa-angle-right"></i></a>
                            </div>
                            <div className="col-md-auto">
                                <div className="form-date">
                                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-calender.svg"} alt="" />
                                    <input type="text" className="form-control h-44" placeholder="2020/05/15" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <input className="form-control search_box bg-transparent h-44" type="search" placeholder="고객명/ 전화번호" />
                                    <span className="input-group-append">
                                        <div className="input-group-text border-0 bg-transparent ml-n5"><i className="fa fa-search"></i></div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-end">
                            <div className="col-md-auto">
                                <select className="form-control select-place h-44">
                                    <option value="">단말기 전체</option>
                                </select>
                            </div>
                            <div className="col-md-auto">
                                <select  className="form-control select-place h-44">
                                    <option value="">통신사 전체</option>
                                </select>
                            </div>
                            <div className="col-md-auto">
                                <select  className="form-control select-place h-44">
                                    <option value="">거래처 전체</option>
                                </select>
                            </div>
                            <div className="col-md-auto">
                                <select className="form-control select-place h-44">
                                    <option value="">판매자 전체</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="table-responsive">
                    <table className="table table-borderless table_striped">
                        <thead className="thead_bg">
                            <tr>
                                <th>No</th>
                                <th>통신사</th>
                                <th>거래처</th>
                                <th>장치</th>
                                <th>개통일</th>
                                <th>고객</th>
                                <th>전화번호</th>
                                <th>정산</th>
                                <th>실매출</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01</td>
                                <td>SKT</td>
                                <td>Starbucks</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Brooklyn Wilson</td>
                                <td>(414) 555-0132</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>02</td>
                                <td>SK 7</td>
                                <td>Bank of America</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Audrey Jones</td>
                                <td>(843) 555-0130</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>03</td>
                                <td>KT</td>
                                <td>Pizza Hut</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Theresa Webb</td>
                                <td>(406) 555-0120</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>04</td>
                                <td>SK 7</td>
                                <td>Sony</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Eleanor Howard</td>
                                <td>(702) 555-0122</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>05</td>
                                <td>SK 7</td>
                                <td>eBay</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Regina Robertson</td>
                                <td>(406) 555-0120</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>06</td>
                                <td>SK 7</td>
                                <td>General Electric</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Brooklyn Wilson</td>
                                <td>(239) 555-0108</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>07</td>
                                <td>SK 7</td>
                                <td>L'Oréal</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Regina Robertson</td>
                                <td>(505) 555-0125</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>08</td>
                                <td>SK 7</td>
                                <td>McDonald's</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Robert Fisher</td>
                                <td>(229) 555-0109</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>09</td>
                                <td>SK 7</td>
                                <td>Gillette</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Brooklyn Wilson</td>
                                <td>(239) 555-0108</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                            <tr>
                                <td>06</td>
                                <td>SK 7</td>
                                <td>Facebook</td>
                                <td>SM G981N 128G</td>
                                <td>2020/04/17</td>
                                <td>Regina Robertson</td>
                                <td>(406) 555-0120</td>
                                <td>1,248,500원</td>
                                <td>1,248,500원</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr className="tfooter">
                                <td colspan="7" className="title">정산정보</td>
                                <td className="price">615,000</td>
                                <td className="price">190,000</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </section>

    );
  }
}


const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
  )(Sales);

