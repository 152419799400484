
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import api from "../../utils/api";
import { numberMathFloor, priceFormat } from "../../utils/helper";
// import ModalPrint from "../../ModalPrint";
// import ModalPrintPageWireless from "../ModalPrintPageWireless";

class MoveToPCPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenLoginDevice: false,
    }
  }

  componentDidMount() {
  }

  print = () => {
    this.setState({
      modalPrint: !this.state.modalPrint
    }, () => {
      // if(this.state.modalPrint){
      //   this.typePrint(1)
      // }
    })

  }

  render() {
    const { WirelessAdvice, dataStore } = this.props;
    const { limitNumber, loginDevices } = this.props;

    return (
      <Fragment>
        <Modal isOpen={this.props.isOpen}
          className="modal-dialog modal-dialog-centered modal-detail-print"
          contentClassName=""
          // backdrop={"static"}
          toggle={this.props.toggle}
        >
        <div>
          <div className="modal-header">
            <h5 className="modal-title text-left w-100">서식지(견적서) 출력 안내</h5>
            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
              <img src="/images/icon-close.svg" alt="" />
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="icon-top">
              <img src="/images/icoin-print01.svg" alt="" />              
              <p>
                <span className="text-blue">서식지(견적서)가 PC로 전송되었습니다.</span> <br />
                홈페이지 접속하여 서식지(견적서)를 출력해 주세요.
              </p>
            </div>
            <div className="content-detail">
              <h5>*출력 방법</h5>
              <p>
                PC에서 모비셀 메인화면 하단의 APP전송서식 출력 버튼을 클릭하시면, 앱에서 전송된 서식지(견적서) 목록이 팝업으로 표시됩니다.
              </p>
              <p> 출력을 원하시는 항목의 인쇄하기 버튼을 클릭하시면 인쇄가 진행됩니다.</p>
              <p className="color-orange">
                *동일한 ID로 로그인 하셔야만 전송한 서식지(견적서) 목록을 확인하실 수 없습니다. 
              </p>
            </div> */}


              <div>
                <div className="icon-top">
                  <img src="/images/icoin-print01.svg" alt="" />
                  <p>
                    <span className="text-blue">서식지(견적서)가 PC로 전송되었습니다.</span> <br />
                    홈페이지에 접속하여 서식지(견적서)를 출력해 주세요.
                  </p>
                </div>
                <div className="content-detail">
                  <div className="left-content">
                    <h5>*출력 방법</h5>
                    <p>
                      PC에 모비셀 메인화면 하단의 APP 전송서식 출력 버튼을 클릭하시면,
                    </p>
                    <p>앱에서 전송된 서식지(견적서) 목록이 팝업으로 표시됩니다. </p>
                    <p>출력을 원하시는 항목의 인쇄하기 버튼을 클릭하시면 인쇄가 진행됩니다.</p>
                    <p className="color-orange mt-3">
                      *동일한 ID로 로그인 하셔야만 전송한 서식지(견적서) 목록을 확인하실 수 있습니다. 
                    </p>
                  </div>
                  <div className="right-content">
                    {/* <img src="/images/print-icon-07.svg" alt="" /> */}
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/print-icon-07.svg"} />                    
                  </div>
                </div>
              </div>
          </div>
          <div className="modal-footer justify-content-center border-top-0 mt-3 mb-3">
            <button className="btn btn-submit pl-5 pr-5" data-toggle="modal"onClick={this.props.toggle}>확인</button>
          </div>
        </div>
        </Modal>

        {/* <ModalPrintPageWireless
          isOpen={this.state.modalPrintPage}
          toggle={this.printPage}
          networks={networks}
          currentNetwork={currentNetwork}
          consultantId={WirelessAdvice.id}
          toggleParent={this.props.openPopup}
        /> */}

      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // showLoading: () => dispatch(showLoading()),
    // hideLoading: () => dispatch(hideLoading()),
    // change: () => () => dispatch(change())
  }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(MoveToPCPopup);

