 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import WirelessSim from './Sim/Sim'
import WirelessSubscriptionFee from './SubscriptionFee/SubscriptionFee'
// import WirelessInsurrance from './Insurrance/Insurrance'
// import WirelessInsurranceCancelDate from './InsurranceCancelDate/InsurranceCancelDate'

class WirelessOptionSim extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
      <Fragment>
        <WirelessSim/>
        <WirelessSubscriptionFee/>
      </Fragment>
    );
  }
}

WirelessOptionSim.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessOptionSim);
