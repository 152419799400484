import PropTypes from 'prop-types';
import * as routerName from '../../utils/routerName';
import renderTextField from '../../components/FormHelper/TextField';
import renderRadioField from '../../components/FormHelper/RadioField';
import renderTextFields from '../../components/FormHelper/TextFieldArray';
import renderSelectField from '../../components/FormHelper/SelectField';
import renderCheckboxField from '../../components/FormHelper/CheckboxField';
import renderDatePickerField from '../../components/FormHelper/DatePickerField';
import renderCkeditorField from '../../components/FormHelper/CkeditorField';
import renderFileField from '../../components/FormHelper/FileField';
import renderFileAttachmentField from '../../components/FormHelper/FileAttachmentField';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {showLoading, hideLoading} from '../../store/actions/ui';
import api from '../../utils/api';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import validate from './validate-document.js';
import moment from 'moment';
import { tr } from 'date-fns/locale';
import { getGlobalDataAuth } from '../../store/actions/dataStore';

class SalesDocumentCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            callback:this.props.location?.state?.callback
        }
        
    }
    componentDidMount() {
    }

    comfirmRedirect = () => {
        this.props.hideModal();
        this.props.history.push({ pathname: '/' + routerName.SALES_DOCUMENT })
    }

    popupMsgSuccess = () => {
        let xhtml = (
            <Fragment>
                    <div className="content">
                        <div className="modal-body text-center p-5">
                            <span className="notification">{this.props.SalesDocumentCreate.d_id ?  ('공지사항 업데이트되었습니다.') : ('공지사항 작성 완료되었습니다.')}</span>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn_save" onClick={() => this.comfirmRedirect()} >확인</button>
                    </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }

    popupMsgFail = () => {
        let xhtml = (
            <Fragment>
                    <div className="content">
                        <div className="modal-body text-center p-5">
                            <span className="notification_delete">{this.props.SalesDocumentCreate.d_id ?    ('공지사항 업데이트 실패되었습니다.') : ('시스템에서 오류가 발생하였습니다! 다시 시도해 주세요!')}</span>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn_save" onClick={() => this.props.hideModal()} >확인</button>
                    </div>
            </Fragment>
           
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }


    onSave = (data) => {
        let dataRequest = {
            d_title:data.title,
            d_content:data.content,
            d_att_file:data.attachment ? data.attachment : '',
            d_state_hide:data.d_state_hide ? '1' : '0',
            d_date_hide:data.end_date,
            d_date_show:data.start_date,
            d_state_show:data.d_state_show ? '1' : '0',
            cat_id:data.cat_id,
            d_state_setting:data.stateSetting ? '1' : '0',
            d_setting_type_1:data.stateSettingType1 ? '1' : '0',
            d_setting_type_2:data.stateSettingType2 ? '1' : '0',
            d_state:data.d_state
        }
        if(data.d_id !== '') {
            this.props.showLoading();
            dataRequest.removeFile = data.removeFile
            api.notification.updateDocument(dataRequest, data.d_id).then(res => {
                this.props.hideLoading();
                this.props.getGlobalDataAuth();
                this.popupMsgSuccess()
                this.props.getGlobalDataAuth();
            }).catch(err => {
                this.props.hideLoading();
                this.popupMsgFail()
            })
        } else {
            this.props.showLoading();
            api.notification.createDocument(dataRequest).then(res => {
                this.props.hideLoading();
                this.props.getGlobalDataAuth();
                this.popupMsgSuccess()
                this.props.getGlobalDataAuth();
            }).catch(err => {
                this.props.hideLoading();
                this.popupMsgFail()
            })
        }
        
    }


    onChangeStateSetting = (e) => {
        // if (e.target.value) {
        //     this.props.change('stateSettingType', '0');
        // }
    }

    comfirmDel = (id) => {
        let xhtml = (
            <Fragment>
                 <div className="content-register-modal">
                    <h4 className="text-center">
                        <span className="notification_delete"> 삭제하시겠습니까?</span>
                    </h4>
                    <div className="form-row">
                        <div className="w-100 text-center">
                            <button className="btn btn_close mr-2" onClick={() => this.props.hideModal()}>취소</button>
                            <button className="btn btn_save  ml-2" onClick={() => this.handleDel(id)}>확인</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog-centered',
        }

        this.props.showModal(dataModal);
    }

    handleDel = (id) => {
        this.props.showLoading();
        api.notification.delDocument(id).then(res => {
            this.props.hideLoading();
            this.comfirmRedirect()
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    hanldeCancel = () => {
        this.props.history.push({ pathname: '/' + routerName.SALES_DOCUMENT })
    }

    onClickCallback = () => {
        this.props.history.push({
            pathname: this.state.callback?.routerName || routerName.SALES_DOCUMENT ,
            state: { callback:this.state.callback }
        })
    }

    onClickRemoveImage = (e) => {
        this.props.change('attachment', '')
        this.props.change('att_file_url', '')
        this.props.change('removeFile', true)
    } 

    onChangeStateRemove = (state) => {
        this.props.change('removeFile', state)
    }
    

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <form onSubmit={handleSubmit(this.onSave)} autoComplete="off">
                                <div className="add-notification">
                                    <div className="top">
                                        <div className="group-button">
                                            <button className="btn btn-left p-0 mr-3" type="button" onClick={() => this.onClickCallback()}>
                                                취소
                                            </button>
                                            {this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.d_id ? (
                                                <Fragment>
                                                    <span>|</span>
                                                    <button className="btn btn-left p-0 ml-3 mr-3" type="button" onClick={() => this.comfirmDel(this.props.SalesDocumentCreate.d_id)}>
                                                        삭제
                                                    </button>
                                                </Fragment>
                                            ) : ('')}
                                            <span>|</span>
                                            <button 
                                                className={"btn btn-left p-0 ml-3" } 
                                                onClick={this.props.handleSubmit(values => 
                                                    this.onSave({ 
                                                      ...values,
                                                      d_state: '0'
                                                    }))}
                                            >
                                                초안으로 저장
                                            </button>
                                            <button className={"btn btn-submit px-5"} 
                                                onClick={this.props.handleSubmit(values => 
                                                    this.onSave({ 
                                                      ...values,
                                                      d_state: '1'
                                                    }))}
                                            >
                                                게시
                                            </button>
                                        </div>
                                    </div>
                                    <article className="content-add">
                                        <div className="row">
                                            <div className="col-9 border-right-grey">
                                                <div className="left">
                                                    <div className="form-group box_title">
                                                        {/* <label htmlFor="" className="label-text">사업자명(단체명)</label> */}
                                                        <Field
                                                            id="title"
                                                            label="title"
                                                            name="title"
                                                            component={renderTextField}
                                                        />
                                                    </div>
                                                    <div className="form-group box_content">
                                                        {/* <label htmlFor="" className="label-text">사업자명(단체명)</label> */}
                                                        <Field
                                                            className="form-control h-44"
                                                            placeholder="아이디"
                                                            name="content"
                                                            component={renderCkeditorField}
                                                        />

                                                    </div>
                                                    <div className="form-group box-image">
                                                        <Field
                                                            className="form-control h-44"
                                                            placeholder="아이디"
                                                            name="attachment"
                                                            file_url={this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.att_file_url}
                                                            component={renderFileAttachmentField}
                                                            removeFile={this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.removeFile}
                                                            onChangeStateRemove={this.onChangeStateRemove}
                                                        />
                                                         {(this.props.SalesDocumentCreate && (this.props.SalesDocumentCreate.attachment !== '' || this.props.SalesDocumentCreate.att_file_url)) ? 
                                                        (<a className="btn btn-add-img btn-delete-img" onClick={(e) => this.onClickRemoveImage(e)}>
                                                             <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-delete-file.svg"} alt=""/>
                                                        </a>) : (<></>)}
                                                    </div>
                                               
                                                </div>
                                            </div>
                                            <div className="col-3 pr-2">
                                                <div className="right">
                                                    <h4 className="title">
                                                        노출여부 설정
                                                    </h4>
                                                    <div className="form-group">
                                                        <label className="col-form-label label-text text-gray-white">노출</label>
                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="form-date">
                                                                    <Field
                                                                        className="form-control h-44"
                                                                        placeholder="사업자명(단체명)"
                                                                        name="start_date"
                                                                        type="text"
                                                                        component={renderDatePickerField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-0">
                                                                <div className="toggle-custom">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="d_state_show"
                                                                        name="d_state_show"
                                                                        component={renderCheckboxField}
                                                                        checked={ this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.d_state_show}
                                                                        onChange={(e) => this.onChangeStateSetting(e)}
                                                                    />
                                                                    <label htmlFor="d_state_show">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label label-text text-gray-white">숨김</label>
                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="form-date">
                                                                    <Field
                                                                        className="form-control h-44"
                                                                        placeholder="사업자명(단체명)"
                                                                        name="end_date"
                                                                        type="text"
                                                                        component={renderDatePickerField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-0">
                                                                <div className="toggle-custom">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="d_state_hide"
                                                                        name="d_state_hide"
                                                                        component={renderCheckboxField}
                                                                        checked={ this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.d_state_hide}
                                                                        onChange={(e) => this.onChangeStateSetting(e)}
                                                                    />
                                                                    <label htmlFor="d_state_hide">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form-group form-selete-custom">
                                                        <label className="col-form-label label-text">카테고리</label>
                                                        <Field
                                                            placeholder="Số điện thoại"
                                                            name="cat_id"
                                                            component={renderSelectField}
                                                        >
                                                            {this.props.select_cat_doc_childs.map((item, i) =>
                                                                <option key={i} value={item.cat_id}>
                                                                    {item.cat_parent_name.length ? item.cat_parent_name + ' - ' + item.cat_name : item.cat_name}
                                                                </option>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label label-text">팝업설정</label>
                                                        <div className="toggle-custom mb-3">
                                                            <Field
                                                                type="checkbox"
                                                                id="stateSetting"
                                                                name="stateSetting"
                                                                component={renderCheckboxField}
                                                                checked={this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.stateSetting}
                                                                onChange={(e) => this.onChangeStateSetting(e)}
                                                            />
                                                            <label htmlFor="stateSetting">
                                                            </label>
                                                            <span style={{opacity: '1', color:"#1e2532"}}>팝업설정</span>
                                                        </div>
                                                        <div className="box-setting">
                                                            <div className={"box-radio " + (this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.stateSetting == true ? "d-show" : "d-none")} >
                                                                <div className="custom-control custom-checkbox d-flex align-items-center mr-sm-2 mb-3">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="setting_type_time_not"
                                                                        name="stateSettingType1"
                                                                        value='0'
                                                                        className="custom-control-input"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.stateSettingType1}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="setting_type_time_not">
                                                                        다시보지않기
                                                                    </label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox d-flex align-items-center mr-sm-2">
                                                                    <Field
                                                                        type="checkbox"
                                                                        id="setting_type_time_one_day"
                                                                        name="stateSettingType2"
                                                                        value='0'
                                                                        className="custom-control-input"
                                                                        component={renderCheckboxField}
                                                                        checked={this.props.SalesDocumentCreate && this.props.SalesDocumentCreate.stateSettingType2}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="setting_type_time_one_day">
                                                                        하루동안 보지않기
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    let initValues;
    if (state.dataStore.notification.select_cat_doc_childs.length > 0 && state.form.SalesDocumentCreate) {
        initValues = {
            ...state.form.SalesDocumentCreate.initial,
            'cat_id': state.dataStore.notification.select_cat_doc_childs[0].cat_id,
        };
    }
    if (props.location.state && props.location.state.document) {
        let document = props.location.state.document;
        initValues = {
            ...initValues,
            'd_id': document.d_id,
            'title': document.d_title,
            'content': document.d_content,
            'start_date': document.d_date_hide ? moment(document.d_date_show).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD'),
            'end_date': document.d_date_hide ? moment(document.d_date_hide).format('YYYY-MM-DD'):  moment(new Date()).format('YYYY-MM-DD'), 
            'cat_id': document.cat_id,
            'att_file_url': document.att_file_url,
            'attachment' : document.att_file_url ? document.att_file_url : '',
            'stateSetting': document.d_state_setting == '1' ? true : false ,
            'stateSettingType1': document.d_setting_type_1 == '1' ? true : false,
            'stateSettingType2': document.d_setting_type_2 == '1' ? true : false,
            'd_state_show': document.d_state_show == '1' ? true : false,
            'd_state_hide': document.d_state_hide == '1' ? true : false,
            'd_state':  document.nt_state == '1' ? '1' : '0',
        };
    }
    return {
        initialValues: initValues,
        SalesDocumentCreate: getFormValues('SalesDocumentCreate')(state),
        select_cat_doc_childs: state.dataStore.notification.select_cat_doc_childs,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        getGlobalDataAuth: () => dispatch(getGlobalDataAuth()),
    };
};

const withReduxForm = reduxForm({
    form: 'SalesDocumentCreate',
    initialValues: {
        'd_id':'',
        'title': '',
        'content': '',
        'start_date':  moment(new Date()).format('YYYY-MM-DD'),
        'end_date':  moment(new Date()).format('YYYY-MM-DD'),
        'cat_id': '',
        'stateSetting' : false,
        'stateSettingType1': false,
        'stateSettingType2': false,
        'attachment': '',
        'att_file_url': '',
        'd_state_show': false,
        'd_state_hide': false,
        'd_state': '1',
        'removeFile': false
    },
    enableReinitialize: true,
    validate
}, mapStateToProps);

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(SalesDocumentCreate);

