 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat,chooseOption2G} from './../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";
import {optionWelfare, roundUpNumberPlan} from "../../../utils/helper";

class Conversion2GModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidUpdate(prevProps) {


        var dataSkt = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        var telcom = this.props.WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
        var nameTelcom=""
        if(dataSkt) {
            nameTelcom=dataSkt.toUpperCase()
        }
        if(telcom != null) {
            nameTelcom=telcom.toUpperCase()
        }

        if(nameTelcom && nameTelcom == "LGU+") {

            var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
            var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
            var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
            var combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
            var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
            var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost ? this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost : 0
            var valueBasicCost=0

            var promotionCost = this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

            var promotionDurationDiscountOne=this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne : 0
            var promotionDurationDiscountTwo=this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo : 0
            var promotionDuration= +promotionDurationDiscountOne + +promotionDurationDiscountTwo

            if(basicCostCalc != 0) {
                valueBasicCost=basicCostCalc
            }else {
                valueBasicCost=roundUpNumberPlan(basicCost)
            }

            var moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(nameTelcom && nameTelcom == "LGU+") {
                    if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6){
                        let optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G ?
                            roundUpNumberPlan(this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G) :
                            this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault // truong hop lgu cho option 6

                        let data2g={id: 1, value: '적용'}
                        var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement) / 2)
                        dataCostWelfareDiscount= dataCostWelfareDiscount > 12100 ? 12100 : dataCostWelfareDiscount
                        let dataChoose=chooseOption2G(data2g,{...this.props.WirelessAdvice.WirelessAdviceStep3},nameTelcom,{id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)',
                            cost:dataCostWelfareDiscount})
                        this.props.change('WirelessAdviceStep3.optionalAgreement', dataChoose.optionalAgreement < 0 ? 0 : dataChoose.optionalAgreement)
                    }else {
                        this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                    }

                }

            }
        }else {
            if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount != prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscount) {
                if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                    if(nameTelcom && nameTelcom == "LGU+") {
                        if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6){
                            let optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G ?
                                roundUpNumberPlan(this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G) :
                                this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault // truong hop lgu cho option 6

                            let data2g={id: 1, value: '적용'}
                            var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement) / 2)
                            dataCostWelfareDiscount= dataCostWelfareDiscount > 12100 ? 12100 : dataCostWelfareDiscount
                            let dataChoose=chooseOption2G(data2g,{...this.props.WirelessAdvice.WirelessAdviceStep3},nameTelcom,{id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)',
                                cost:dataCostWelfareDiscount})
                            this.props.change('WirelessAdviceStep3.optionalAgreement', dataChoose.optionalAgreement < 0 ? 0 : dataChoose.optionalAgreement)
                        }else {
                            this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                        }

                    }

                }
            }
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne !== prevProps.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(nameTelcom && nameTelcom == "LGU+") {
                    this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                }

            }
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo !== prevProps.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(nameTelcom && nameTelcom == "LGU+") {
                    this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                }

            }
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.promotion !== prevProps.WirelessAdvice.WirelessAdviceStep3.promotion) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(nameTelcom && nameTelcom == "LGU+") {
                console.log(this.props.WirelessAdvice.WirelessAdviceStep3.promotion,prevProps.WirelessAdvice.WirelessAdviceStep3.promotion,
                    'this.props.WirelessAdvice.WirelessAdviceStep3.promotion',moneyResult,(moneyResult < 0 ? 0 : moneyResult))
                    this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                }

            }
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount !== prevProps.WirelessAdvice.WirelessAdviceStep3.combinedDiscount) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                if(nameTelcom && nameTelcom == "LGU+") {
                    this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                }

            }
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep3.optionFee != 1 && this.props.WirelessAdvice.WirelessAdviceStep3.discostChange !==
            prevProps.WirelessAdvice.WirelessAdviceStep3.discostChange) {
            if (this.props.WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
                console.log(this.props.WirelessAdvice.WirelessAdviceStep3.discostChange,'this.props.WirelessAdvice.WirelessAdviceStep3.discostChange')
                if(nameTelcom && nameTelcom == "LGU+") {
                    if(this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6){
                        let optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G ?
                            roundUpNumberPlan(this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G) :
                            this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault // truong hop lgu cho option 6

                        let data2g={id: 1, value: '적용'}
                        var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement) / 2)
                        dataCostWelfareDiscount= dataCostWelfareDiscount > 12100 ? 12100 : dataCostWelfareDiscount
                        let dataChoose=chooseOption2G(data2g,{...this.props.WirelessAdvice.WirelessAdviceStep3},nameTelcom,{id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)',
                            cost:dataCostWelfareDiscount})
                        this.props.change('WirelessAdviceStep3.optionalAgreement', dataChoose.optionalAgreement < 0 ? 0 : dataChoose.optionalAgreement)
                    }else {
                        this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                    }
                }

            }
        }



    }

    renderWelfareDiscount() {
        const {dataPromotion, WirelessAdvice, stateChangeProp} = this.props;
        let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        let telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
        if(dataSkt) {
            dataSkt=dataSkt.toUpperCase()
        }
        if(telcom != null) {
            telcom=telcom.toUpperCase()
        }
        if (WirelessAdvice.WirelessAdviceStep3.optionFee != null) {
            let data = [
                {id: 0, value: "미적용"},
                {id: 1, value: '적용'},

            ]

            let dataCheckLimit = dataSkt == "SKT" || (telcom != null && telcom == "SKT") ? OPTION_CHOOSE_2G.code_limit.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name) :
                OPTION_CHOOSE_2G.code_limit_LGU.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name)
            if (dataCheckLimit) {
                data = [
                    {id: 0, value: "미적용"},
                    {id: 2, value: '무약정'},
                ]
            }
            let activeData = WirelessAdvice.WirelessAdviceStep3.conversion2G

            if (data && data.length > 0) {
                return (data.map((item, key) => {
                    return (
                        <li className={`cursor ${activeData != null && activeData.value && activeData.value == item.value ? "active" : (activeData == null && item.id == 0 ? "active" : "")}`}
                            key={key} onClick={() => this.choosePromotion(item)}>
                            {item.value}
                        </li>
                    );
                }));
            } else {
                return null
            }
        } else {
            return null;
        }

    }



    choosePromotion = (data) => {
        const {WirelessAdvice} = this.props;

        let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        let telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
        let nameTelcom=""
        if(dataSkt) {
            nameTelcom=dataSkt.toUpperCase()
        }
        if(telcom != null) {
            nameTelcom=telcom.toUpperCase()
        }


        let dataChoose=chooseOption2G(data,{...WirelessAdvice.WirelessAdviceStep3},nameTelcom)

        

        if(dataChoose.optionFee != 1) {
            if(data?.id == 1) {
                if(dataChoose.welfareDiscount != null && dataChoose.welfareDiscount?.id == 6) {
                    var basicCostCalc=dataChoose.basicCostCalc
                    var basicCost=dataChoose.basicCost
                    var valueBasicCost=0
                    if(basicCostCalc != 0) {
                        valueBasicCost=basicCostCalc
                    }else {
                        valueBasicCost=roundUpNumberPlan(basicCost)
                    }
                    var combinedDiscount=dataChoose.combinedDiscount
                    var promotionCost=dataChoose.promotion?.promo_cost ? dataChoose.promotion?.promo_cost : 0
                    var optionalAgreement = dataChoose.optionalAgreement
                    var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2)
                    
                    dataChoose.welfareDiscount.cost = parseInt(dataCostWelfareDiscount) > 0 ? (parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount) : 0
                }
            }else {
                if(data?.id == 0) {
                    if(dataChoose.welfareDiscount != null && dataChoose.welfareDiscount?.id == 6) {
                        dataChoose.welfareDiscount.cost = 12100 // option phuc loi tren 65 tuoi
                    }
                }
            }
        }

        this.props.change('WirelessAdviceStep3', dataChoose)
        this.props.openPopup()
    }


    render() {
        const {handleSubmit, WirelessAdvice} = this.props;
        return (
            <Modal isOpen={this.props.isOpenModal}
                   // backdrop={"static"}
                   toggle={this.props.openPopup} fade={true}
                   className={'modal-dialog modal-dialog-centered modal-additional modal-2g'}
                   contentClassName="">
                <div className="modal-header">
                    <h5 className="modal-title">2G전환고객 옵션
                    </h5>
                    {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                            onClick={this.props.openPopup}>
                        <img src="/images/icon-close.svg" alt=""/>
                    </button> */}
                </div>
                <ModalBody>
                    <ul className="list-additional">
                        {/*<li className="active">*/}
                        {/*미적용*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*적용*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*무약정*/}
                        {/*</li>*/}
                        {this.renderWelfareDiscount()}
                    </ul>
                </ModalBody>


            </Modal>
        );
    }
}

Conversion2GModal.propTypes = {};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(Conversion2GModal);
