import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ForgotPasswordStep1Form from './ForgotPasswordComponent/ForgotPasswordStep1Form';
import ForgotPasswordStep2Form from './ForgotPasswordComponent/ForgotPasswordStep2Form';
import * as routerName from '../../utils/routerName';
import api from "./../../utils/api";
import {showModal, hideModal} from '../../store/actions/modal';
import {showLoading, hideLoading} from '../../store/actions/ui';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change } from 'redux-form';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:1,
            user:'',
            fieldVerify:'전화 인증'
        }
    }

    redirectLogin = () => {
        this.props.hideModal();
        this.props.history.push({pathname:'/'+routerName.SIGN_IN});
    }

    

    componentDidMount() {
       
    }

    changeFieldVerify = () => {
        if(this.props.ForgotPassword.forgot_type == 1) {
            this.setState({
                fieldVerify:'휴대폰번호'
            })
        } else if(this.props.ForgotPassword.forgot_type == 2) {
            this.setState({
                fieldVerify:'이메일 인증'
            })
        }
    }

    nextPage = () =>  {
        let data = {
            username:this.props.ForgotPassword.username
        }
        this.props.showLoading();
        api.auth.checkExistUsername(data).then(res => {
            if(res.data.data.user){
                this.setState({
                    page: this.state.page + 1,
                    user:res.data.data.user
                })
                this.props.hideLoading();
            } 
        }).catch(err => {
            this.popupShowMessageNotExitsUsername();
            this.props.hideLoading();
        })
        
    }
    
    previousPage = () =>  {
        this.setState({page: this.state.page - 1})
    }

    setPage = (page) =>  {
        if( this.props.ForgotPasswordStep1.form1 == true && this.props.ForgotPasswordStep1.form2 == true) {
            this.setState({page: page})
        } else if(this.props.ForgotPasswordStep1.form1 == true && (page == 1 || page == 2) ) {
            this.setState({page: page})
        }
    }


    popupShowMessageNotExitsUsername = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={ process.env.REACT_APP_HOSTNAME+ "/tsc_template/dist/images/icon-rattiec.svg"  } alt=""/>
                </div>
                <h4 className="text-center">
                    죄송합니다.
                </h4>
                <p className="text-center mt-3 mb-2">
                    입력하신 정보와 일치하는 비밀번호가 없습니다.
                </p>
                <p className="text-center">
                    정보 확인 후, 다시 시도해 주세요.
                </p>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={()=> this.props.hideModal()}>다시 시도</button>
            </div>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal-dialog-centered modal-dialog-scrollable',
        }
        
        this.props.showModal(dataModal);
    }

    popupShowMessageSendMailError = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={ process.env.REACT_APP_HOSTNAME+ "/tsc_template/dist/images/icon-rattiec.svg"  } alt=""/>
                </div>
                <h4 className="text-center">
                    죄송합니다.
                </h4>
                <p className="text-center mt-3">
                    시스템 오류 나중에 다시 시도하십시오
                </p>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={()=> this.props.hideModal()}>다시 시도</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        
        this.props.showModal(dataModal);
    }

    popupShowMessageSendForgotPwSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="modal-body p-0">
                    <div className="img-check">
                        <img src={ process.env.REACT_APP_HOSTNAME+ "/tsc_template/dist/images/Icon-modal-01.svg"  } alt=""/>
                    </div>
                    <h4 className="text-center">
                        전송되었습니다.
                    </h4>
                    {this.props.ForgotPassword.forgot_type == 1 ? (
                        <p className="text-center">
                            비밀번호는 
                            {this.state.user.phone.length >= 13 ? (
                                <span>(***) **** - {this.state.user.phone.slice(this.state.user.phone.length - 4, this.state.user.phone.length)}</span>
                            ) : (
                                <span>(***) *** - {this.state.user.phone.slice(this.state.user.phone.length - 4, this.state.user.phone.length)}</span>
                            )}
                             전화번호로 전송되었습니다.<br/>
                            확인 바랍니다.
                        </p>
                    ) : (
                        <p className="text-center">
                            비밀번호는
                            {' '}
                            <span>{this.state.user.email && this.state.user.email.slice(0,  this.state.user.email.length > 3 ? 3 : 1) + '*'.repeat( (this.state.user.email.length - (this.state.user.email.length > 3 ? 3 : 1) - this.state.user.email.substring(this.state.user.email.lastIndexOf("@") + 1).length -1))}@{this.state.user.email.substring(this.state.user.email.lastIndexOf("@") + 1)}</span>
                            {' '}
                            이메일로 전송되었습니다. <br/>
                            확인 바랍니다.
                        </p>
                    )}
                    
                    {/* onClick={() => this.redirectLogin()} */}
                    <button type="button" className="btn btn-secondary btn-ok" onClick={() => this.redirectLogin()}>로그인</button>
                </div>
            </div>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal-dialog-centered modal-dialog-scrollable',
        }
        
        this.props.showModal(dataModal);
    }
    
 

    onSubmit = (data) => {
        
        if(data.forgot_type == 1) {
            this.props.showLoading();
            let dataRequest = {
                phone:this.state.user.phone,
                username:data.username
            }
            api.auth.findPasswordPhone(dataRequest).then(res => {
                this.props.hideLoading();
                this.popupShowMessageSendForgotPwSuccess()
            }).catch(err => {
                this.popupShowMessageSendMailError();
                this.props.hideLoading();
            })
            // send phone chưa xử lý
        } else if(data.forgot_type == 2) {
            this.props.showLoading();
            let dataRequest = {
                email:this.state.user.email,
                username:data.username
            }
            api.auth.findPasswordEmail(dataRequest).then(res => {
                this.props.hideLoading();
                this.popupShowMessageSendForgotPwSuccess()
            }).catch(err => {
                this.popupShowMessageSendMailError();
                this.props.hideLoading();
            })
        }
    }


    renderForgotPasswordComponent() {
        let xhtml = null;
        if(this.state.page == 1) {
            xhtml = <ForgotPasswordStep1Form 
            history={this.props.history} 
            onSubmit={this.nextPage}
            fieldVerify={this.state.fieldVerify}
            />;
        } else if(this.state.page == 2) {
            xhtml = <ForgotPasswordStep2Form 
            history={this.props.history} 
            previousPage={this.previousPage} 
            fieldVerify={this.state.fieldVerify}
            onSubmit={this.onSubmit}
            changeFieldVerify={this.changeFieldVerify}
            user={this.state.user}
            />;
        } 
        
        return xhtml;
    }

    render() {
        return (
            <Fragment>
                <section className="form-reset-password forgot-password">
                    <div className="container">
                        <h1 className="title-page mb-2">비밀번호 찾기</h1>
                        
                        {this.renderForgotPasswordComponent()}
                    </div>
                </section>

            </Fragment>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    let initValues = {};
    if(ownProps.location && ownProps.location.state && ownProps.location.state.username){
        initValues = {
            username: ownProps.location.state.username
        }
    }
    return {
        initialValues: initValues,
        ForgotPassword: getFormValues('ForgotPassword')(state),
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};



const FORM_NAME = 'ForgotPassword';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: { 
        'username': '', 
        'forgot_type': '1',  
        // 'verify': '',  
    },
    enableReinitialize: true
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotPassword);

