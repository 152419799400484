import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import {getFormSyncErrors, getFormValues} from "redux-form";
import {connect} from "react-redux";
import {compose} from "redux";

class CheckInfoConsultantPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
        }
    }


    render() {
        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-dialog-centered modal-reset-product'}
                onOpened={this.modalOpened}
            >
                <div className="modal-body text-center p-5">
                        <span className="notification_delete text-center">상담내용이 없습니다<br/>
                            단말기를 선택하여 상담해주세요</span>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pt-0">
                        <button type="button" className="btn btn-gray mt-0"
                                onClick={this.props.openPopup}>취소
                        </button>
                    </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};

const withConnect = connect(
    mapStateToProps,
);

export default compose(
    withConnect
)(withRouter(CheckInfoConsultantPopup));

