 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


class PopupUserBetaFree extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    closeModal = () => {
        this.props.closePopupDisable()
    }


    render() {
        return (
          <Modal isOpen={this.props.isOpenModal}
          toggle={this.closeModal} 
          fade={true}
          className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
          contentClassName="modal-penalty content">
      <div className="modal-header" style={{border:'none'}}>
          <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                  onClick={() => this.closeModal()}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
      </div>
      <ModalBody className="discount-card pl-0 pr-0 pt-0">
          <div className="content-register-modal">
              <div className="img-check">
                  <img src="/images/icon1.svg" alt=""/>
              </div>
              <h4 className="text-center">무료 서비스 중 입니다.</h4>
              <p className="text-center">
                고객님께서는 현재 무료로 모비셀 서비스 이용 중 입니다.<br />
                많은 이용 부탁드립니다.
              </p>
              <div className="d-flex justify-content-center border-top-0 mt-5 mb-5">
                  <button type="button" className="btn btn-submit pr-5 pl-5 ml-2" onClick={() => this.closeModal()}>확인</button>
              </div>
          </div>
      </ModalBody>


    </Modal>
        );
    }
}

export default PopupUserBetaFree;
