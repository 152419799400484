import PropTypes from 'prop-types';
import * as routerName from '../../utils/routerName';
import renderTextField from '../../components/FormHelper/TextField';
import renderRadioField from '../../components/FormHelper/RadioField';
import renderTextFields from '../../components/FormHelper/TextFieldArray';
import renderSelectField from '../../components/FormHelper/SelectField';
import renderCheckboxField from '../../components/FormHelper/CheckboxField';
import renderDatePickerField from '../../components/FormHelper/DatePickerField';
import renderCkeditorField from '../../components/FormHelper/CkeditorField';
import renderFileField from '../../components/FormHelper/FileField';
import renderFileAttachmentField from '../../components/FormHelper/FileAttachmentField';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showLoading, hideLoading } from '../../store/actions/ui';
import api from '../../utils/api';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import validate from './validate-notification.js';
import moment from 'moment';
import Moment from 'react-moment';
import * as helper from '../../utils/helper';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { addMilliseconds } from 'date-fns';
import { isBuffer } from 'lodash';
import { stringify } from 'query-string';
import { getNotifications, loadmoreDataNotification } from '../../store/actions/dataStore';

class MoneySupportNotificationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            announcements: {},
            telcoms: [],
            dataList: [],
            announcement_models_default: [],
            announcement_models: [],
            announcement_selected: {
                announcement: {},
                i: null,
                k: null
            },
            announcement_model_selected: {},
            announcement_plans: [],
            announcement_plans_default:[],
            price_model: null,
            price_plan: null,
            keyword_model: '',
            keyword_plan: '',
            toggleChangePriceModel: false,
            toggleChangePricePlan: false,
            toggleShowModalChosePlanName:false,
            popupShowModalChoseModelName:false,
            detail:this.props.location?.state?.notice,
            notice:{},
            isLike:false,
            callback:this.props.location?.state?.callback,
            statusData:false
        }
    }

    componentDidMount() {
        if(this.state.detail?.nt_id) {
            this.getListTelcom()
            this.getDetail()
        } else {
            this.comfirmRedirect()
        }

    }

    getDetail = () => {
        api.notification.noticeIncreaseViewer(this.state.detail?.nt_id).then(res => {
            if (!!res.data.data.notice && res.data.status == 1) {
                this.setState({
                    notice: res.data.data.notice,
                    isLike: res.data.data.isLike
                })
                this.props.getNotifications();
            } else if (res.data.status == 0) {
                this.props.history.push({ pathname: '/' + routerName.HOME })
            }
        }).catch(err => {
            this.props.hideLoading()
            this.props.history.push({ pathname: '/' + routerName.HOME })
        })
    }

    onClickLike = () => {
        this.props.showLoading()
        api.notification.likeNotice(this.state.detail?.nt_id).then(res => {
            if (res.data.status == 1) {
                this.setState({
                    notice: {
                        ...this.state.notice,
                        count_like: (this.state.notice.count_like ? parseInt(this.state.notice.count_like) : 0) + 1
                    },
                    isLike: true
                })
            } else {
                this.setState({
                    notice: {
                        ...this.state.notice,
                        count_like: parseInt(this.state.notice.count_like) - 1
                    },
                    isLike: false
                })
            }
            this.props.hideLoading()


        }).catch(err => {
            this.props.hideLoading();
            console.log(err)
        })
    }

    getListTelcom = () => {
        this.props.showLoading()

        api.global.getTelcom().then(res => {
            this.setState({
                telcoms: res.data.data
            }, () => {
                this.getAnnouncementChangList()
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    comfirmRedirect = () => {
        this.props.hideModal();
        this.props.history.push({ pathname: '/' + routerName.MANAGER_NOTIFICATION })
    }

    getAnnouncementChangList = () => {
        this.props.hideLoading();
        this.setState({
            announcements: JSON.parse(this.state.detail.nt_content)
        }, () => {
            this.setDataList()
        })
    }

    setDataList = () => {
        const { announcements, telcoms } = this.state
        let dataList = []
        let statusData=false
        Object.keys(announcements).map((key, i) => {
            let index = telcoms.findIndex((item) => item.id == key)
            if (index !== -1) {
                let rowData = {
                    telcom: telcoms[index],
                    dataRow: announcements[key]
                }
                if (announcements[key].length > 0) {
                    dataList.push(rowData)
                }
            }

            if(announcements[key].length > 0) {
                statusData=true
            }
        })
        this.setState({
            dataList: dataList,
            statusData:statusData
        })
    }


    renderPriceStatusModel = (item) => {
        let priceModel =  item?.factory_price ? parseInt(item?.factory_price) : 0;
        return priceModel;
    }

    renderPriceChangeModel = (item) => {
        let priceModelChange =  item?.factory_price_change ? parseInt(item?.factory_price_change) : 0;

        return priceModelChange;
    }

    renderPriceStatusPlan = (item) => {
        let pricePlan =  item?.subsidy_price ? parseInt(item?.subsidy_price) : 0;
        return pricePlan;
    }

    renderPriceChangePlan = (item) => {
        let pricePlanChange =  item.subsidy_price_change ? parseInt(item.subsidy_price_change) : 0;

        return pricePlanChange;
    }

    onClickCallback = () => {
        if(this.state.callback?.routerName == "") {
            this.props.history.push({
                pathname: '/' + routerName.MANAGER_NOTIFICATION ,
            })
        } else {
            this.props.history.push({
                pathname: this.state.callback?.routerName|| routerName.MANAGER_NOTIFICATION ,
                state: { callback:this.state.callback }
            })
        }
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container_nofitication d-block">
                            <button className="btn btn-back" onClick={() => this.onClickCallback()}>
                                <img src={process.env.REACT_APP_API_URL + "/images/icon-back.svg"} />
                            </button>
                                <div className="add-notification add-notification-02">
                                    <article className="content-add">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="left">
                                                    <div className="form-group box_title">
                                                        <h1>{this.state.detail?.nt_title}</h1>
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <div className="d-flex flex-row justify-content-start align-items-center">
                                                                <div className="sub mr-4">
                                                                    <i className="far fa-clock"></i>
                                                                    <span>
                                                                        <Moment format="YYYY.MM.DD">
                                                                            {this.state.notice && this.state.notice.created_at}
                                                                        </Moment>
                                                                    </span>
                                                                </div>
                                                                <div className="sub mr-4">
                                                                    <i className="far fa-eye"></i> <span>{this.state.notice && this.state.notice.nt_viewer ? this.state.notice.nt_viewer : 0}</span>
                                                                </div>
                                                                <div className="sub" onClick={() => this.onClickLike()} style={{ cursor: 'pointer' }}>
                                                                    <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{this.state.notice && this.state.notice.count_like ? this.state.notice.count_like : 0}</span>
                                                                </div>
                                                            </div>
                                                            {this.state.notice && !!this.state.notice.att_file ? (<div className="sub d-flex">
                                                                <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({this.state.notice && (this.state.notice.att_size / (1024)).toFixed(2)} KB)</span></p>
                                                                <a href={process.env.REACT_APP_API_URL + "api/v1/attachments/download-attachment?att_file=" + this.state.notice.att_file} target="_blank"><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"} alt="" /></a>
                                                            </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="form-group box_content">


                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>통신사</th>
                                                                    <th>모델명</th>
                                                                    <th>출고가</th>
                                                                    <th>표준 요금제</th>
                                                                    <th>공시지원금</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.dataList.map((item, i) =>
                                                                    <Fragment key={i}>
                                                                        {item.dataRow.map((it, k) =>
                                                                            <tr key={k}>
                                                                                {k == 0 && (
                                                                                    <td rowSpan={item.dataRow.length}>
                                                                                        <div className="logo-network">
                                                                                            <img src={item?.telcom?.image_url?.thumbnail} style={{margin:'0'}} alt="" />
                                                                                        </div>
                                                                                    </td>
                                                                                )}
                                                                                <td className="td-add-product" >
                                                                                    <div style={{cursor:'default'}}>
                                                                                        {/*<span  className={"name-devices "+ (this.props.auth.role != 'Admin' ? "remove-before" : null)} >*/}
                                                                                        <span  className={"name-devices remove-before"} >
                                                                                            {it.model_name} {it.device_name ? `(${it.device_name})` : null}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                { this.renderPriceChangeModel(it) === this.renderPriceStatusModel(it) || this.renderPriceStatusModel(it) === 0 ? (
                                                                                    <td className="td-add-product" style={{background: "white"}}>
                                                                                        <div className="prices-devices" style={{padding: 0}}>
                                                                                            <div className="status-prices" style={{color: 'black', fontWeight: 'normal', justifyContent:"flex-end", marginTop: 0}}>
                                                                                                <label className={'m-0'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangeModel(it))}
                                                                                                    <strong style={{fontSize:'12px'}}>{" won" }</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                ) : (
                                                                                    <td className={this.renderPriceChangeModel(it) > this.renderPriceStatusModel(it) ? "status-up" : "status-down"}>
                                                                                        <div>
                                                                                            <div className="prices-devices" style={{cursor:'default',minHeight:'72px',justifyContent:'center'}} >
                                                                                                <span>{helper.priceFormat(this.renderPriceStatusModel(it)) + " won"}</span>
                                                                                                <div className={`status-prices`} >
                                                                                                        <div className="status">
                                                                                                            { this.renderPriceChangeModel(it) > this.renderPriceStatusModel(it) ? <img src="/images/status-up.svg" style={{margin:'0'}} alt="" /> : <img src="/images/status-down.svg" style={{margin:'0'}} alt="" />}
                                                                                                        </div>
                                                                                                    <label className={'m-0'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                        {helper.priceFormat(this.renderPriceChangeModel(it))}
                                                                                                        <strong style={{fontSize:'12px'}}>{" won" }</strong>
                                                                                                    </label>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                )}
                                                                                <td className="td-add-product" >
                                                                                    <div style={{cursor:'default'}}>
                                                                                        {/*<span className={"name-devices "+ (this.props.auth.role != 'Admin' ? "remove-before" : null)} >*/}
                                                                                        <span className={"name-devices remove-before"} >
                                                                                            {it.plan_name}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>

                                                                                { this.renderPriceChangePlan(it) === this.renderPriceStatusPlan(it) || this.renderPriceStatusPlan(it) === 0 ? (
                                                                                    <td className="td-add-product" style={{background: "white"}}>
                                                                                        <div className="prices-devices" style={{padding:0}}>
                                                                                            <div className="status-prices" style={{color: 'black', fontWeight: 'normal', justifyContent:"flex-end", marginTop: 0}}>
                                                                                                <label className={'m-0'} style={{fontSize:'18px',fontWeight: 400}}>
                                                                                                    {helper.priceFormat(this.renderPriceChangePlan(it))}
                                                                                                    <strong style={{fontSize:'12px'}}>{" won" }</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                ) : (
                                                                                     <td className={this.renderPriceChangePlan(it) > this.renderPriceStatusPlan(it) ? "status-up" : "status-down"}>
                                                                                     <div>
                                                                                         <div className="prices-devices" style={{cursor:'default',minHeight:'72px',justifyContent:'center'}}>
                                                                                             <span>{helper.priceFormat(this.renderPriceStatusPlan(it)) + " won"}</span>
                                                                                             <div className={`status-prices `} >
                                                                                                 <div className="status">
                                                                                                     {this.renderPriceChangePlan(it) > this.renderPriceStatusPlan(it) ? <img src="/images/status-up.svg" alt="" style={{margin:'0'}} /> : <img src="/images/status-down.svg" style={{margin:'0'}} alt="" />}
                                                                                                 </div>
                                                                                                 <label className={'m-0'} style={{fontSize:'18px', fontWeight: 400}}>
                                                                                                     {helper.priceFormat(this.renderPriceChangePlan(it))}
                                                                                                     <strong style={{fontSize:'12px'}}>{" won" }</strong>
                                                                                                 </label>
                                                                                             </div>
                                                                                         </div>
                                                                                     </div>
                                                                                 </td>
                                                                                )}

                                                                            </tr>
                                                                        )}
                                                                    </Fragment>
                                                                )}

                                                                {!this.state.statusData && this.state.dataList.length == 0 ? (
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            금일 변동사항 없습니다.
                                                                        </td>
                                                                    </tr>
                                                                ): ""}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {

};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        getNotifications: () => dispatch(getNotifications())
    };
};



const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect
)(MoneySupportNotificationCreate);

