
import PropTypes from 'prop-types';
import React, {Component, Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import PromotionModal from "./PromotionModal"
import {getPromotion} from './../../../store/actions/discount_card';
import "./promotion.scss"
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {priceFormat} from "../../../utils/helper";

class WirelessPromotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalPromotion: false,
            disnable: false,
            dataPromotion: {},
            stateChangeProp: false,
            data:{}
        }
    }

    fetchData = (id) => {
        this.props.showLoading()
        this.props.getPromotion(id).then(res => {
            this.props.hideLoading();
            if (res.data && res.data.length) {
                this.setState({
                    dataPromotion: res,
                    disnable:false
                });

                this.props.change('WirelessAdviceStep3.promotion', res.data[0]);
            }else {
                this.setState({
                    dataPromotion: {},
                    disnable:true
                })
            }
        })
        .catch(() => {
            this.props.hideLoading();
        })
    }

    componentDidMount () {
        if(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {
            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
        }
    }

    componentDidUpdate(prevProps) {

        let idPlan = 10
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee == null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {
            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
        }
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee != null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null &&
            this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee.id) {
            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
            // setTimeout(() => {
            //     this.props.change('WirelessAdviceStep3.promotion',this.props.WirelessAdvice.WirelessAdviceStep3Default.promotion)
            //     this.props.change('WirelessAdviceStep3Default.promotion',{})
            // }, 100)
            // this.setState({
            //     stateChangeProp: true
            // })
        }

    }

    openPopupPromotion = () => this.setState({isOpenModalPromotion: !this.state.isOpenModalPromotion})


    render() {
        const {handleSubmit, WirelessAdvice, WirelessAdviceDetailPlan} = this.props;
        let cost = WirelessAdvice.WirelessAdviceStep3.promotion;

        if (cost && cost.planID) {
            if(WirelessAdvice.WirelessAdviceStep3.optionFee == 1) {
                // var costNumber = Number(cost.promo_cost)
                // var costCurrency = costNumber.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                var costCurrency=priceFormat(cost.promo_cost)
            }else {
                cost.optional_agreement=cost.optional_agreement == null ? 0 : cost.optional_agreement
                // var costNumber = Number(cost.optional_agreement)
                // var costCurrency = costNumber.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

                var costCurrency=priceFormat(cost.optional_agreement)
                if(cost?.planID && cost?.planID == "input_live") {
                    costCurrency=priceFormat(cost.promo_cost)
                }
            }
        }

        return (
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">프로모션</label>
                <div className="col-sm-8">
                    <button 
                        className={'btn btn-input-form mb-0 ' + (cost && cost.planID && costCurrency != 0 ? 'btn-prices-wp' : "")}
                        // disabled={this.state.disnable}
                        onClick={this.openPopupPromotion}
                    >
                        {/*{cost && cost.planID ? ("-" + costCurrency + '원') : '미적용'}*/}
                        {cost && cost.planID ? ( costCurrency != 0 ? ("-" + costCurrency) : 0) : ''}
                        {cost && cost.planID ? '원' : <span className='opacity-disabled'>미적용</span>}
                    </button>
                    <PromotionModal
                        isOpenModalPromotion={this.state.isOpenModalPromotion}
                        dataPromotion={this.state.dataPromotion}
                        openPopupPromotion={this.openPopupPromotion}
                    />
                </div>
            </div>
        );
    }
}

WirelessPromotion.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),

    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPromotion: (data) => dispatch(getPromotion(data)),
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessPromotion);
