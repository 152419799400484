

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {reduxForm, change } from 'redux-form';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import * as routerName from './../../../utils/routerName';
import { Modal } from 'reactstrap';
class LoginDevicePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { limitNumber, loginDevices } = this.props;
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-dialog modal-dialog-centered modal-device-logged"
                    contentClassName="modal-content pl-4 pr-4"
                    // backdrop={"static"}
                    toggle={this.props.toggle}
                >
                    <div className="modal-header">
                        <h5 className="modal-title"> 로그인 중인 디바이스</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body modal-counseling-history">
                        <ul className="list-additional">                         
                            <div className="modal-body modal-counseling-history">
                                <div className="flex-browser">
                                    <div className="left-browser">
                                        <p className="notification_delete mb-1">로그인 한도에 도달했습니다.</p>
                                        <p className="notification_delete">아래 디바이스 중 하나에서 로그아웃해 주세요</p>
                                    </div>
                                    <div className="right-browser">
                                        <p className="notification_delete mb-1">로그인된 사용자 개수 {loginDevices.length}/{limitNumber}</p>
                                    </div>
                                </div>

                                {/* <div className="mt-3 mb-3">
                                    <p className="notification_delete mb-1">로그인된 사용자 개수 {loginDevices.length}/{limitNumber}</p>
                                    <p className="notification_delete mb-1">로그인 한도에 도달했습니다.</p>
                                    <p className="notification_delete">아래 디바이스 중 하나에서 로그아웃해 주세요</p>
                                </div> */}
                            </div>
                            <article>
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr className="bg-purple">
                                                <th className="text-left" width="50%">디바이스</th>
                                                <th className="text-left">최종 이용 날짜</th>
                                                <th className="text-right" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td>데스크톱: 윈도 10 <br />크롬</td>
                                                <td>2020-06-05 </td>
                                                <td className="text-right">
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn btn-wireless" type="button">로그아웃 </button>
                                                    </div>
                                                </td>
                                            </tr> */}
                                            {this.props.children}
                                        </tbody>
                                    </table>
                                </div>
                            </article>
                        </ul>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // showLoading: () => dispatch(showLoading()),
        // hideLoading: () => dispatch(hideLoading()),
        // change: () => () => dispatch(change())
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(LoginDevicePopup);
