import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import ModalWirelessAndWiredCombination from './ModalWirelessAndWiredCombination'
import {ADVICE_TYPE} from "../../../utils/constant";
import {priceFormat, caclWiredAdvice} from "../../../utils/helper";
import CombineDiscountModal from "../../Wireless/CombinedDiscount/CombineDiscountModal";
import _ from 'lodash';
import api from '../../../utils/api';

const TEXT = {
  1: '인터넷 할인',
  2: 'TV 플러스',
}

class WirelessAndWiredCombination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
    }
  }

  showModalWirelessAndWiredCombination = (indexClassify) => {
      const { WiredAdvice: { WiredAdviceStep2 } } = this.props
      if(WiredAdviceStep2.classify[0].classify.length > 0 && WiredAdviceStep2.classify[0].classify[0].device?.wd_id) {
          this.setState({
              indexClassify: indexClassify,
              isShowModal: true,
          })
      }else {
        alert('선택된 인터넷 상품이 없습니다!')
      }

  }

  closeModalWirelessAndWiredCombination = () => {
    this.setState({
      indexClassify: null,
      isShowModal: false,
    })
  }

  transformDataDiscount = (WiredAdviceStep2) => {
    const {WiredAdvice: {WiredAdviceStep1}} = this.props
    let data = {
      conds: [],
      class: [],
      key: WiredAdviceStep1.telcomID,
    };
    WiredAdviceStep2.classify.map((cls) => {
      cls.classify.map((item, index) => {
        if (item?.device?.wd_id) {
          data.conds.push({
            wd_id: item?.device?.wd_id,
            wd_price: item?.device?.wd_commitment_fee,
            combine_discount: (cls.id === 1 && index === 0) ? (WiredAdviceStep2.combineCondition?.id || '') : '',
            wdo_id: item?.options.map(e => e.wdo_id),
            wdo_price: item?.options.map(e => e.wdo_commitment_fee),
          })
          data.class.push(item?.device?.wd_class)
        }
      })
    })
    return data;
  }

  onApplyCombineDiscount = (data) => {

    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    let wiredAdviceStep2 = {
      ...WiredAdviceStep2,
      isCombined: true,
      combineCondition: data.discount_condition,
      combineDevice: null,
      combineOption: null,
      combinePlans: data.discount_condition ? data.plan_modal.plans : [],
      combinePlansSring: data.discount_condition ? JSON.stringify(data.plan_modal.plans) : '',
      optionDiscount: data.discount_condition ? JSON.stringify(data.option_discount) : ''
    }

    let discountConditonId          = data.discount_condition ? data.discount_condition.id : 0;
    let notDiscountTvConditionArray = [4, 5, 6, 10, 11, 12, 13, 14, 15, 17, 18, 19];

    //console.log("wiredAdviceStep2");
    //console.log(wiredAdviceStep2);
    //console.log("data");
    //console.log(data);

    wiredAdviceStep2.classify[0].classify.map((item, index) => {
      if (data.wire_product[index]) {
          wiredAdviceStep2.classify[0].classify[index].discount.combineDiscount = data.discount_condition ? +data.wire_product[index].discount : 0;
          wiredAdviceStep2.classify[0].classify[index].discount_name = data.wire_product[index]?.discount_name ?  data.wire_product[index].discount_name : [];

          /*
          if(discountConditonId == 19 && index == 0){// 요즘가족할인인 경우 인터넷 기본할인 미적용
            wiredAdviceStep2.classify[0].classify[0].discount.device = {additional : 0, name : '', value : 0};
            wiredAdviceStep2.classify[0].classify[0].discount.value  = +wiredAdviceStep2.classify[0].classify[0].cost.value;
          }
          */
          if(index == 0){
            if(discountConditonId == 19){// 요즘가족할인인 경우 인터넷 기본할인 미적용
              wiredAdviceStep2.classify[0].classify[0].discount.device = {additional : 0, name : '', value : 0};
              wiredAdviceStep2.classify[0].classify[0].discount.value  = +wiredAdviceStep2.classify[0].classify[0].cost.value;
            }
            else if(discountConditonId == 13){// 참쉬운 가족결함인 경우
              if(item.device.wd_id == 306 || item.device.wd_id == 307 || item.device.wd_id == 391){
                let isLteDataSenior33 = false;

                data.plan_modal.plans.map((plan) => {
                  let planId = plan?.plan?.id || 0;

                  if(
                    planId == 2869 || planId == 2872 || planId == 2875 || planId == 2878 || 
                    planId == 3891 || planId == 3892 || planId == 3893
                  ){ 
                    isLteDataSenior33 = true;
                  }
                });

                if(isLteDataSenior33){
                  let combineDiscount = wiredAdviceStep2.classify[0].classify[0].discount.combineDiscount;

                  wiredAdviceStep2.classify[0].classify[0].discount_name = [{discount : combineDiscount, name : '참쉬운가족결합'}, {discount : 3100, name : '시니어플러스요금할인'}];
                  wiredAdviceStep2.classify[0].classify[0].discount.combineDiscount = combineDiscount + 3100;
                }
              }
            }
          }
      } else {
        wiredAdviceStep2.classify[0].classify[index].discount.combineDiscount = 0;
        wiredAdviceStep2.classify[0].classify[index].discount_name = [];
      }
    });

    if (data?.option_discount?.tv_plus?.type?.id == 1 && notDiscountTvConditionArray.indexOf(discountConditonId) == -1) {
        if(wiredAdviceStep2.classify[1].classify && wiredAdviceStep2.classify[1].classify.length > 0){
          wiredAdviceStep2.classify[1].classify[0].discount.combineDiscount = data.discount_condition ? +data.option_discount.tv_plus.type.price: 0;
          wiredAdviceStep2.classify[1].classify[0].discount_name = [{discount : +data.option_discount.tv_plus.type.price, name : 'TV 플러스'}]; // tytolee
        } 
    }
    else if(wiredAdviceStep2.classify[1].classify && wiredAdviceStep2.classify[1].classify.length > 0){
      wiredAdviceStep2.classify[1].classify = wiredAdviceStep2.classify[1].classify.map((item, index) => {
        if(index == 0 && discountConditonId == 19 ){ 
          return {
            ...item,
            discount: {
              ...item.discount,
              combineDiscount: 1100
            },
            discount_name : [{discount : 1100, name : '요즘가족결합'}]
          }
        }
        else{
          return {
            ...item,
            discount: {
              ...item.discount,
              combineDiscount: 0
            },
            discount_name : []
          }
        }
      });
    }
    
//    디즈니+ 투게더 결합 주석처리    
//    if(wiredAdviceStep2.classify[1].classify && wiredAdviceStep2.classify[1].classify.length > 0){
//      if(wiredAdviceStep2.classify[1].classify[0].device.wd_id == 368 && wiredAdviceStep2.classify[1].classify[0].options.length > 0){ // 프리미엄 디즈니+
//        wiredAdviceStep2.classify[1].classify[0].options.map((option) => {
//          if(option.wdo_id == 232){ // U+tv 사운드바(디즈니)
//            if(discountConditonId == 17 || discountConditonId == 18){ //투게더 결합
//              wiredAdviceStep2.classify[1].classify[0].discount.options=[{combine:3300, name : '디즈니+ 투게더 결합'}];
//            }
//            else{
//              wiredAdviceStep2.classify[1].classify[0].discount.options=[];
//            }
//          }
//        });
//      }
//    }

    if(wiredAdviceStep2.classify[1].classify && wiredAdviceStep2.classify[1].classify.length > 0){
      if(wiredAdviceStep2.classify[1].classify[0].options.length > 0){
        if(discountConditonId == 1 || discountConditonId == 2 || discountConditonId == 3){ //온가족플랜(신규가입불가), 온가족프리(신규가입불가), 한가족할인(신규가입불가)
          wiredAdviceStep2.classify[1].classify[0].discount.device = {additional : 0, name : '', value : 0};
          wiredAdviceStep2.classify[1].classify[0].discount.value  = +wiredAdviceStep2.classify[1].classify[0].cost.value;
        }
      }
    }

    if(wiredAdviceStep2.classify[2].classify && wiredAdviceStep2.classify[2].classify.length > 0){
      if(discountConditonId == 1 || discountConditonId == 2 || discountConditonId == 3){ //온가족플랜(신규가입불가), 온가족프리(신규가입불가), 한가족할인(신규가입불가)
        wiredAdviceStep2.classify[2].classify[0].discount.device = {additional : 0, name : '', value : 0};
        wiredAdviceStep2.classify[2].classify[0].discount.value  = +wiredAdviceStep2.classify[2].classify[0].cost.value;
      }
    }

    wiredAdviceStep2.combinePlans = wiredAdviceStep2.combinePlans.map((item, index) => {
      let discount_wireless_price = item.discount_wireless_price
      let discount_tv = 0;

      if(notDiscountTvConditionArray.indexOf(discountConditonId) == -1){  //tv plus 적용인 경우에만
        if (data?.option_discount?.tv_plus?.line?.idx == index + 1) {
          if (data?.option_discount?.tv_plus?.type?.id == 1) {
            discount_wireless_price -= 1100
            discount_tv = 1100
          }
  
          if (data?.option_discount?.tv_plus?.type?.id == 2) {
            discount_wireless_price -= 2200
            discount_tv = 2200
          }

          if(wiredAdviceStep2.classify[1].classify && wiredAdviceStep2.classify[1].classify.length > 0){
            let discountDeviceName = wiredAdviceStep2.classify[1].classify[0].discount.device.name;

            if(discountDeviceName == "요즘우리집결합할인 (인터넷결합시)"){
              wiredAdviceStep2.classify[1].classify[0].discount.device = {additional : 0, name : '', value : 0};
              wiredAdviceStep2.classify[1].classify[0].discount.value  = +wiredAdviceStep2.classify[1].classify[0].cost.value;
            }
          }
        }
      }

      return {
        ...item,

        discount_wireless_price,
        discount_tv,
      }
    })
/*
    api.advice.getWiredDiscountCondsItems(this.transformDataDiscount(wiredAdviceStep2))
    .then((response) => { 
      const { WiredAdvice: { WiredAdviceStep2 } } = this.props;

      console.log("WiredAdviceStep2", WiredAdviceStep2);
      console.log("wiredAdviceStep2", wiredAdviceStep2);

      let clone = [...WiredAdviceStep2.classify]
      let condDiscount = response.data.data.condDiscount || []
      let setupFee = response.data.data.setupFee || []
      let wiredAdviceStep2 = {
        ...wiredAdviceStep2,
        setupFee
      }

      let a = [];
      clone.map((cls) => {
        let ab = []
        for (let i = 0; i < condDiscount.length; i++) {
          for (let j = 0; j < cls.classify.length; j++) {
            if (cls.classify[j]?.device?.wd_id == condDiscount[i][0]?.wd_id) {
              ab.push(condDiscount[i])
              break
            }
          }
        }
        a.push(ab)
      })
      a.map((resGroup, resIndex) => {
        clone = clone.map((cls, clsIndex) => ({
          ...cls,
          classify: cls.classify.map((item, itemIndex) => {
            let itemClone = {...item}
            if (resIndex == clsIndex && resGroup[itemIndex]) {
              itemClone = {
                ...itemClone,
                discount: {
                  ...itemClone.discount,
                  device: {
                    ...itemClone.discount.device,
                    name: resGroup[itemIndex][0]?.dwc_name,
                    value: resGroup[itemIndex][0]?.wdci_discount,
                  },
                  options: (resGroup[itemIndex][1] || []).map((i, index) => ({
                    ...itemClone.discount.options[index],
                    name: i.dwc_name,
                    value: i.wdci_discount,
                  })),
                  value: itemClone.cost.value - (resGroup[itemIndex][0]?.wdci_discount || 0) - (resGroup[itemIndex][1]?.wdci_discount || 0),
                },
              }
            }
            return itemClone
          })
        }))
      })

      wiredAdviceStep2 = {
        ...wiredAdviceStep2,
        classify: clone,
        classifyString: JSON.stringify(clone),
      }
              // })
      wiredAdviceStep2 = caclWiredAdvice(wiredAdviceStep2)

      this.props.change('WiredAdviceStep2', wiredAdviceStep2);

      this.setState({
        isShowModal: false
      })
    })
    
    */

    wiredAdviceStep2.classifyString = JSON.stringify(wiredAdviceStep2.classify)

    wiredAdviceStep2 = caclWiredAdvice(wiredAdviceStep2)

    this.props.change('WiredAdviceStep2', wiredAdviceStep2)
    
    this.setState({
      isShowModal: false
    })
    
  }

  render() {
    let {isShowModal} = this.state;
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props;

    //console.log("render WiredAdviceStep2", WiredAdviceStep2);

    return (
      <Fragment>
        <div className="form-group">
          <label className="label-text">유무선 결합</label>
          <button 
            className="btn btn-input-form mb-0" 
            type="button"
            // disabled={WiredAdviceStep2.classify[0].classify.length > 0 && WiredAdviceStep2.classify[0].classify[0].device.wd_id ? false : true}
            style={WiredAdviceStep2.classify[0].classify.length > 0 && WiredAdviceStep2.classify[0].classify[0].device.wd_id  ? {} : {opacity: '.65'}}
            onClick={this.showModalWirelessAndWiredCombination}
          >
            {WiredAdviceStep2.classify[0].classify.length > 0
            && WiredAdviceStep2.classify[0].classify[0].device.wd_id
            && WiredAdviceStep2.combineCondition?.name || '미적용'}
          </button>
        </div>
        {WiredAdviceStep2.classify.map((cls, key) => (
          cls.classify.map((item, ikey) => {
            let combineDiscountTitle = TEXT[cls.id];
            let combineConditionId   = WiredAdviceStep2.combineCondition?.id;
            let isLteDataSenior33    = false;

            if(combineConditionId == 19 && cls.id == 2){
              combineDiscountTitle = "요즘가족결합";
            }

            if(key == 0 && ikey == 0 && combineConditionId == 13){
              let deviceWdId = +item.device.wd_id;

              if(deviceWdId == 306 || deviceWdId == 307 || deviceWdId == 391){
                WiredAdviceStep2.combinePlans.map((plans) => {
                  let planId = plans?.plan?.id || 0;

                  if(
                    planId == 2869 || planId == 2872 || planId == 2875 || planId == 2878 || 
                    planId == 3891 || planId == 3892 || planId == 3893
                  ){ 
                    isLteDataSenior33 = true;
                  }
                });
              }
            }
            
            return (
              item.discount.combineDiscount && +item.discount.combineDiscount > 0 ?
                isLteDataSenior33 ?
                (<Fragment>
                  <h5 className="title-form flex-form mt-4" key={key*ikey}>
                      <span>
                        {combineDiscountTitle} (회선 {ikey + 1})
                      </span>
                      <p className="prices-form mb-0">
                          -{priceFormat(+item.discount.combineDiscount - 3100)}
                          <span className="price-sub">원</span>
                      </p>
                  </h5>
                  <h5 className="title-form flex-form mt-4" key={key*ikey}>
                      <span>시니어플러스요금할인</span>
                      <p className="prices-form mb-0">
                          -{priceFormat(3100)}
                          <span className="price-sub">원</span>
                      </p>
                  </h5>
                </Fragment>) :
                (
                    <Fragment>
                        <h5 className="title-form flex-form mt-4" key={key*ikey}>
                            <span>
                              {combineDiscountTitle} (회선 {ikey + 1})
                            </span>
                            <p className="prices-form mb-0">
                                -{priceFormat(item.discount.combineDiscount)}
                                <span className="price-sub">원</span>
                            </p>
                        </h5>
                    </Fragment>
                ) :
              null
           )
          })
        ))}
        <CombineDiscountModal
          toggleModal={this.closeModalWirelessAndWiredCombination}
          isShowModal={isShowModal}
          adviceType={ADVICE_TYPE.WIRE}
          onApplyCombineDiscount={this.onApplyCombineDiscount}
        />
      </Fragment>
    );
  }
}

WirelessAndWiredCombination.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  openPopup: PropTypes.func,
  isOpenModal: PropTypes.bool,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessAndWiredCombination);
