import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import _ from "lodash";
class KTNumberLineWireCombine extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            list: this.props.list,
            selected: this.props.selected || null
        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {
        this.props.onSelect(this.state.selected, "kt_number_line_wire_combine");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.setState({
            list: this.props.list,
            selected: this.props.selected
        })
    }

    onSelect = (e, line, value) => {
        this.setState({
            selected: {
                [line] : value
            }
        }, () => {
            this.toggleModal(e)
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }


    componentDidUpdate(prevProps){
        if(prevProps.selected && prevProps.selected !== this.props.selected){
            this.setState({
                selected: this.props.selected
            })
        }
    }
    render() {

        let i = 0;
        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">KT 무선결합 회선수
                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                            disabled={_.reject(this.props.plans, {plan: null}).length > 0 ? false : true}
                    >
                        {Object.keys(this.state.selected)[0]}대 (
                        {
                            _.size(this.props.selected) > 0 ? _.map(this.state.selected[Object.keys(this.state.selected)[0]],  (plan_type, index) => {
                                i++;
                                return `${plan_type.plan_name} ${plan_type?.wireless_basic_discount?.value || 0}${plan_type?.wireless_basic_discount?.unit == "won" ? "원" : "%"}${i <  _.size(this.props.selected) ? " | " : "" }`
                            }) : "미적용"
                        })
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-lg modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">무선 결합 회선수</h5>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                {
                                    this.state.list && _.map(this.state.list,  (value, key) => {
                                        let length = _.size(value);
                                        let i = 0;
                                        return (
                                            <li key={key}
                                                className={this.state.selected  && (key in this.state.selected) ? "active" : ""}
                                                onClick={(e) => this.onSelect(e, key, value)}
                                            >
                                                { key }대 (
                                                    {
                                                        _.map(value,  (plan_type, index) => {
                                                            i++;
                                                            return `${plan_type.plan_name} ${plan_type?.wireless_basic_discount?.value || 0}${plan_type?.wireless_basic_discount?.unit == "won" ? "원" : "%"}${i < length ? " | " : "" }`
                                                        })
                                                    }
                                                )
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

KTNumberLineWireCombine.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(KTNumberLineWireCombine);