

import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from '../../../components/FormHelper/CheckboxField';
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import validate from './validate-step-2';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../../store/actions/modal';
import * as routerName from '../../../utils/routerName';
import * as helper from '../../../utils/helper';
import api from "../../../utils/api";
import { SubmissionError } from 'redux-form';
import InfiniteScroll from "react-infinite-scroll-component";
// import { Tooltip } from 'reactstrap';
import Tooltip from "../../../components/Tooltip";
import { CONSULTANT_WIRELESS } from './../../../utils/constant'
import ListTabDevice from "../../../components/Device/ListTabDevice";
import SearchDevice from "../../../components/Device/SearchDevice";
import SwiperSlideComponent from "../../Compare/SwiperSlideComponent";
import SwiperSlideWirelessComponent from "../../Compare/SwiperSlideWirelessComponent";
import { isBuffer } from 'lodash';
import { Redirect } from 'react-router-dom'
import * as routeName from './../../../utils/routerName'
import MessageJSON from "./../../../utils/message";
import { set } from 'lodash';
import {getPlanInformation, getDisclosureSubsidy} from '../../../store/actions/plan_information';
import PlanPackageFeeModal from '../../../components/Wireless/PlanPackageFee/PlanPackageFeeModal';

//
import {numberWithCommas, optionalAgreementCT, optionWelfare, priceFormat,priceFormatDiff, roundUpNumber, getTextWirelessStep3Promotion} from "../../../utils/helper";

import moment from 'moment';
import NumberFormat from 'react-number-format';
import {Modal} from 'reactstrap';

import ResetModal from '../../../components/Wireless/Reset/ResetModal'
//

class WirelessStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant:{
                data:[]
            },
            consultantLast:{
                data:[]
            },
            consultantColors:[],
            colorActive:{},
            idActive:null,
            deviceActive:{},
            consultantActive:{},
            dataFilter:{
                page:1,
                limit:50
            },
            tooltipOpen: true,
            standardActive: '',
            standardNameActive:'',
            showDetailDevice:false,
            //
            isOpenModalPlanPackage: false,
            isShowModal : false,
            error_modal: {
                isShowModal: false,
                message: ""
              },
            //
            loading: true,
            model: '',
            plan: '',
            color :''
        }
    }

    componentDidMount() {
        document.body.classList.add("body_wireless_advice");
        this.getListConsultantsDèault();
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    //
    openPopupopenPopupPlanPackage = () =>
    this.setState({ isOpenModalPlanPackage: !this.state.isOpenModalPlanPackage })

    openPopupopenPopupReset = () =>
    this.setState({ isOpenModalReset: !this.state.isOpenModalReset })
    //

    /**
 * show modal plan
 */
  handleShowModalPlan = () => {
    this.setState({
        isShowModal : true
    })
  }

  toggleModal = () => {
    setTimeout(() => {
      this.setState({
          isShowModal : false
      })
    }, 300);
  }

  toggleErrorModal = (e, modal) => {
    e && e.preventDefault();
    this.setState({
        [modal]: {
            ...this.state[modal],
            isShowModal: !this.state[modal].isShowModal
        }
    })
  }

  planCustomResultPackage = (data) => {
    const { WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice

    let discount = getTextWirelessStep3Promotion(WirelessAdviceStep3)
    if (discount.length > 0) {
      alert(
        `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
      )
    }

    let discost12 = +data.planId.discost_12 + (+data.planId.discost_12*0.1);
    let discost24 = +data.planId.discost_24 + (+data.planId.discost_24*0.1);

    let step3 = {
      ...WirelessAdviceStep3,

      additionalGrants: 0,
      packageFee: data.planCustom,
      promotion: {},
      promotionDurationDiscountOne: 0,
      promotionDurationDiscountTwo: 0,
      basicCost: data.costDistplay,
      basicCostCalc: roundUpNumber(data.costCalc),
      planData: data.planId ? data.planId : data.valFee,
      discountFullPlan: 0,
      discost12: discost12,
      discost24: discost24,
    }
    this.props.change('WirelessAdviceStep3', step3);
    this.props.change('WirelessAdviceStep3Default.promotion',{})


    let cost_display = +data.costDistplay;
    let cost_calc;
    if (data.costCalc == 0) {
      cost_calc = +data.costDistplay;
    } else {
      cost_calc = +data.costCalc;
    }
    let customPlan = 999;
    let dataPlan = data.planId;
    let commitmentRate ;
    if (data.planId.spec_cmm == 1) {
      commitmentRate = +data.planId.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }

    if(isNaN(commitmentRate)){
      commitmentRate = +WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
    }
    
    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;

    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);

    // this.props.change('WirelessAdviceStep3.rateAgreement', +dataPlan.discost_24 + (+dataPlan.discost_24*0.1)) ;
    // this.props.change('WirelessAdviceStep3.rateAgreementDefault', +dataPlan.discost_24 + (+dataPlan.discost_24*0.1)) ;
    this.props.change('WirelessAdviceStep3.rateAgreement', WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? result.rateAgreement : 0);
    this.props.change('WirelessAdviceStep3.rateAgreementDefault', result.rateAgreement) ;
    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreement',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
    } else {
      this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
    }

    // this.optionalAgreementCT(data.costDistplay/1.1, data.costCalc/1.1, 999, data.planId);

      //set lai tien phuc phuc loi
      let dataTelcom = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
      if(dataTelcom) {
          dataTelcom=dataTelcom.toUpperCase()
      }
      var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
      var optionalAgreement=this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement ? this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement : 0
      var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
      var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
      var optionFee=this.props.WirelessAdvice.WirelessAdviceStep3.optionFee
      var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
      var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
      var valueBasicCost=0
      if(basicCostCalc != 0) {
          valueBasicCost=basicCostCalc
      }else {
          valueBasicCost=basicCost
      }
      var combinedDiscount=0
      if(this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
          combinedDiscount = this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price + this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv
      }else {
          combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      }
      combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1))
      this.props.change('WirelessAdviceStep3.welfareDiscount', optionWelfare(basicCost,roundUpNumber(basicCost),welfareDiscount,optionalAgreement,rateAgreement,promotion, combinedDiscount,dataTelcom,optionFee))
  }

  planResultPackage = (data) => {
    const { WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice

    let discount = getTextWirelessStep3Promotion(WirelessAdviceStep3)
    if (discount.length > 0) {
      alert(
        `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
      )
    }
    let discost12 = (+data.discost_12) + (+data.discost_12*0.1);
    let discost24 = (+data.discost_24) + (+data.discost_24*0.1);

    let asIsBasicCost = WirelessAdviceStep3.basicCost;
    let adIsId        = WirelessAdviceStep3.packageFee.id;

    let step3 = {
      ...WirelessAdvice.WirelessAdviceStep3,
      additionalGrants: 0,
      packageFee: data,
      promotion: {},
      promotionDurationDiscountOne: data.plan_promotion_duration.length == 1 && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? data.plan_promotion_duration[0].promotion_duration_discount[0].discount_price : 0,
      promotionDurationDiscountTwo: data.plan_promotion_duration.length == 2 && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? data.plan_promotion_duration[1].promotion_duration_discount[0].discount_price : 0,
      basicCost: +data.cost_display+data.cost_display*10/100,
      basicCostCalc: +data.cost_calc+data.cost_calc*10/100,
      planData: null,
      discountFullPlan: data.discount_full,
      promotionDuration: data.plan_promotion_duration,
      discost12: discost12,
      discost24: discost24,
    }

    this.props.change('WirelessAdviceStep3', step3);
    this.props.change('WirelessAdviceStep3Default.promotion',{})

    let cost_display = +data.cost_display + (+data.cost_display*0.1);
    let cost_calc = +data.cost_calc + (+data.cost_calc*0.1);
    let customPlan = 1;
    let dataPlan = data;
    let commitmentRate ;
    if (data.spec_cmm == 1) {
      commitmentRate = +data.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }

    if(isNaN(commitmentRate)){
      commitmentRate = +WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
    }

    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;

    //console.log("cost_display/1.1 : " + cost_display/1.1);
    //console.log("cost_calc/1.1 : " + cost_calc/1.1);
    //console.log("customPlan : " + customPlan);
    //console.log("dataPlan : " + dataPlan);
    //console.log("commitmentRate : " + commitmentRate);
    //console.log("discostChange : " + discostChange);
    //console.log("telcomID : " + telcomID);

    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);
    
    //console.log("result");
    //console.log(result);

    this.props.change('WirelessAdviceStep3.rateAgreement', WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? result.rateAgreement : 0);
    this.props.change('WirelessAdviceStep3.rateAgreementDefault', result.rateAgreement) ;

    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreement',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
    } else {
      if (data.id == 3688) {
        this.props.change('WirelessAdviceStep3.optionalAgreement', 20000);
        this.props.change('WirelessAdviceStep3.optionalAgreementDefault', 20000);
        this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', 20000);
      } else {
        this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
        this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
        this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
      }
    }
    // this.optionalAgreementCT((+data.cost_display+data.cost_display*10/100)/1.1, (+data.cost_calc+data.cost_calc*10/100)/1.1, 1, data);


      let dataTelcom = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
      if(dataTelcom) {
          dataTelcom=dataTelcom.toUpperCase()
      }
      var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
      // var combinedDiscount=WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      var optionalAgreement=this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement ? this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement : 0
      var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

      var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
      var optionFee=this.props.WirelessAdvice.WirelessAdviceStep3.optionFee
      var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
      var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
      var valueBasicCost=0
      if(basicCostCalc != 0) {
          valueBasicCost=basicCostCalc
      }else {
          valueBasicCost=basicCost
      }
      var combinedDiscount=0

//      console.log("data", data);
//      console.log("this.props.WirelessAdvice.WirelessAdviceStep3", this.props.WirelessAdvice.WirelessAdviceStep3);

      if(this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
        combinedDiscount = +this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price + +this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv;
      }
      else {
        combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      }

      combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1));

      this.props.change('WirelessAdviceStep3.welfareDiscount', optionWelfare(basicCost,roundUpNumber(basicCost),welfareDiscount,optionalAgreement,rateAgreement, promotion,combinedDiscount,dataTelcom,optionFee));

      let isKtPreminum    = this.checkKtPremium(WirelessAdviceStep3);
      let dataCostDispaly = (+data.cost_display + (+data.cost_display * 0.1));
      let dataId          = data.id;

      if(isKtPreminum && asIsBasicCost >= 77000 && adIsId != 2989 && adIsId != 3702 ){
        if(dataCostDispaly < 77000){
          this.setState({
            error_modal: {
                isShowModal: true,
                message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>)
            }
          });
        }
        else if(dataId == 2989 || dataId == 3702){
          this.setState({
            error_modal: {
                isShowModal: true,
                message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>)
            }
          });
        }
      }
  }

//

    fetchMoreData =  () => {
        this.setState({
            dataFilter:{
                page:this.state.dataFilter.page + 1
            }
        }, () => {
            let dataRequest = {
                'telcom': this.props.WirelessAdvice.WirelessAdviceStep1.telcomID,
                'page':this.state.dataFilter.page,
                'limit':50,
                'relationship[0]': 'colors',
                'relationship[1]': 'device|deviceSpecialFeature',
                'relationship[2]': 'plan',
                'query': this.props.WirelessAdvice.WirelessAdviceStep2.keyword,
                'manufactor': this.props.WirelessAdvice.WirelessAdviceStep2.mfID,
                'distinct':true,
                'published':true,
                'is_new':this.props.WirelessAdvice.WirelessAdviceStep2.is_new ? this.props.WirelessAdvice.WirelessAdviceStep2.is_new : '',
                'is_consulted':this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted ? this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted : '',
                'is_bookmarked':this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked ? this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked : '',
            }

            if(this.state.standardActive) {
                dataRequest.standard_type = this.state.standardActive;
            }

            if(this.props.WirelessAdvice.WirelessAdviceStep2.mfID == 0 || this.props.WirelessAdvice.WirelessAdviceStep2.mfID == -1) {
                delete dataRequest['manufactor'];
            }

            if(this.props.WirelessAdvice.WirelessAdviceStep2.sortBy !== '') {
                dataRequest = {
                    ...dataRequest,
                    'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortBy + '|' +this.props.WirelessAdvice.WirelessAdviceStep2.sortDirection
                }
            } else if(this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy !== '') {
                dataRequest = {
                    ...dataRequest,
                    'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy+'|'+this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection
                }
            }
            api.advice.getListConsultants(dataRequest).then(res => {
                this.setState({
                    consultant:{
                        ...res.data.data,
                        data: [...this.state.consultant.data,...res.data.data]
                    },
                    consultantLast:{
                        data:res.data.data
                    },
                    loading: false
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })

        });
    };

    getListConsultants = () => {
        this.setState({
            dataFilter:{
                page: 1
            }
        })
        let dataRequest = {
            'telcom': this.props.WirelessAdvice.WirelessAdviceStep1.telcomID,
            'page': 1,
            'limit': 50,
            'relationship[0]': 'colors',
            'relationship[1]': 'device|deviceSpecialFeature',
            'relationship[2]': 'plan',
            'query': this.props.WirelessAdvice.WirelessAdviceStep2.keyword,
            'manufactor': this.props.WirelessAdvice.WirelessAdviceStep2.mfID,
            'distinct':true,
            'published':true,
            'is_new':this.props.WirelessAdvice.WirelessAdviceStep2.is_new ? this.props.WirelessAdvice.WirelessAdviceStep2.is_new : '',
            'is_consulted':this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted ? this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted : '',
            'is_bookmarked':this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked ? this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked : '',
        }

        if(this.state.standardActive) {
            dataRequest.standard_type = this.state.standardActive;
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep2.mfID == 0 || this.props.WirelessAdvice.WirelessAdviceStep2.mfID == -1) {
            delete dataRequest['manufactor'];
        }

        if(this.props.WirelessAdvice.WirelessAdviceStep2.sortBy && this.props.WirelessAdvice.WirelessAdviceStep2.sortBy !== '') {
            dataRequest = {
                ...dataRequest,
                'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortBy + '|' +this.props.WirelessAdvice.WirelessAdviceStep2.sortDirection
            }
        } else if(this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy && this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy !== '') {
            dataRequest = {
                ...dataRequest,
                'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy+'|'+this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection
            }
        }
        this.props.showLoading();
        api.advice.getListConsultants(dataRequest).then(res => {
            if(res.data.data.length > 0) {
                let consultantColors = res.data.data[0].color.map((item) => ({
                    ...item,
                    files_url: JSON.parse(item.files).map(e => process.env.REACT_APP_API_URL_DEV + 'storage/color_device/original/' + e)
                }))
                this.props.change('WirelessAdviceStep3.phone.color', consultantColors);
                this.props.hideLoading();
                this.setState({
                    consultant:{
                        data:res.data.data
                    },
                    consultantLast:{
                        data:res.data.data
                    },
                    consultantColors: consultantColors,
                    colorActive:res.data.data[0].color[0],
                    idActive:res.data.data[0].rowID,
                    // deviceActive:res.data.data[0].device,
                    consultantActive:res.data.data[0],
                    loading: false
                }, ()=> {
                    api.advice.getDetailDevice(this.state.consultantActive.deviceID).then(res => {
                        this.setState({
                            deviceActive:res.data.data.device,
                            showDetailDevice:true
                        })
                    }).catch(err => {
                        this.setState({
                            showDetailDevice:false
                        })
                    })
                    if(this.props.WirelessAdvice.WirelessAdviceStep3.phone.rowID) {
                        this.clickActiveDevice(this.props.WirelessAdvice.WirelessAdviceStep3.phone)
                    }else {
                        this.props.change('WirelessAdviceStep2.color', this.state.consultantColors[0]);
                    }
                })
            } else {
                this.props.hideLoading();
                this.setState({
                    consultant:{
                        data:[]
                    },
                    consultantColors:[],
                    colorActive:{},
                    idActive:null,
                    deviceActive:{},
                    consultantActive:{},
                    loading: false
                }, ()=> {
                    if(this.props.WirelessAdvice.WirelessAdviceStep3.phone.rowID) {
                        this.clickActiveDevice(this.props.WirelessAdvice.WirelessAdviceStep3.phone)
                    }
                })
            }
        }).catch(err => {
            this.props.hideLoading();
            this.setState({
                loading: false
            })
        })

    }

    getListConsultantsDèault = () => {
        this.setState({
            dataFilter:{
                page: 1
            }
        })
        this.props.change('WirelessAdviceStep1.telcom', this.props.WirelessAdvice.WirelessAdviceStep1.telcomID)
        this.props.change('WirelessAdviceStep2',{
            mfID: '0',
            keyword: '',
            is_new: false,
            is_consulted: false,
            is_bookmarked: false,
            sortBy: '',
            sortDirection: 'asc',
            sortTableFieldBy: '',
            sortTableFieldDirection: 'asc',
            status:false,
            color: '',
        })
        setTimeout(() => {
            let dataRequest = {
                'telcom': this.props.WirelessAdvice.WirelessAdviceStep1.telcomID,
                'page': 1,
                'limit': 50,
                'relationship[0]': 'colors',
                'relationship[1]': 'device|deviceSpecialFeature',
                'relationship[2]': 'plan',
                'query': this.props.WirelessAdvice.WirelessAdviceStep2.keyword,
                'manufactor': this.props.WirelessAdvice.WirelessAdviceStep2.mfID,
                'distinct':true,
                'published':true,
                'is_new':this.props.WirelessAdvice.WirelessAdviceStep2.is_new ? this.props.WirelessAdvice.WirelessAdviceStep2.is_new : '',
                'is_consulted':this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted ? this.props.WirelessAdvice.WirelessAdviceStep2.is_consulted : '',
                'is_bookmarked':this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked ? this.props.WirelessAdvice.WirelessAdviceStep2.is_bookmarked : '',
            }

            if(this.state.standardActive) {
                dataRequest.standard_type = this.state.standardActive;
            }

            if(this.props.WirelessAdvice.WirelessAdviceStep2.mfID == 0 || this.props.WirelessAdvice.WirelessAdviceStep2.mfID == -1) {
                delete dataRequest['manufactor'];
            }

            if(this.props.WirelessAdvice.WirelessAdviceStep2.sortBy && this.props.WirelessAdvice.WirelessAdviceStep2.sortBy !== '') {
                dataRequest = {
                    ...dataRequest,
                    'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortBy + '|' +this.props.WirelessAdvice.WirelessAdviceStep2.sortDirection
                }
            } else if(this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy && this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy !== '') {
                dataRequest = {
                    ...dataRequest,
                    'sort[0]': this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy+'|'+this.props.WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection
                }
            }
            this.props.showLoading();
            api.advice.getListConsultants(dataRequest).then(res => {
                if(res.data.data.length > 0) {
                    let consultantColors = res.data.data[0].color.map((item) => ({
                        ...item,
                        files_url: JSON.parse(item.files).map(e => process.env.REACT_APP_API_URL_DEV + 'storage/color_device/original/' + e)
                    }))
                    this.props.change('WirelessAdviceStep3.phone.color', consultantColors);
                    this.props.hideLoading();
                    this.setState({
                        consultant:{
                            data:res.data.data
                        },
                        consultantLast:{
                            data:res.data.data
                        },
                        consultantColors: consultantColors,
                        colorActive:res.data.data[0].color[0],
                        idActive:res.data.data[0].rowID,
                        // deviceActive:res.data.data[0].device,
                        consultantActive:res.data.data[0],
                        loading: false
                    }, ()=> {
                        api.advice.getDetailDevice(this.state.consultantActive.deviceID).then(res => {
                            this.setState({
                                deviceActive:res.data.data.device,
                                showDetailDevice:true
                            })
                        }).catch(err => {
                            this.setState({
                                showDetailDevice:false
                            })
                        })
                        if(this.props.WirelessAdvice.WirelessAdviceStep3.phone.rowID) {
                            this.clickActiveDevice(this.props.WirelessAdvice.WirelessAdviceStep3.phone)
                        }else {
                            this.props.change('WirelessAdviceStep2.color', this.state.consultantColors[0]);
                        }
                    })
                } else {
                    this.props.hideLoading();
                    this.setState({
                        consultant:{
                            data:[]
                        },
                        consultantColors:[],
                        colorActive:{},
                        idActive:null,
                        deviceActive:{},
                        consultantActive:{},
                        loading: false
                    }, ()=> {
                        if(this.props.WirelessAdvice.WirelessAdviceStep3.phone.rowID) {
                            this.clickActiveDevice(this.props.WirelessAdvice.WirelessAdviceStep3.phone)
                        }
                    })
                }
            }).catch(err => {
                this.props.hideLoading();
                this.setState({
                    loading: false
                })
            })
        })


    }

    clickDevice = (item) => {
        this.props.change('WirelessAdviceStep3.phone', item);
        this.props.change('WirelessAdviceStep2.status', true);
        this.props.onSubmit();
    }

    clickActiveDevice = (item) => {
        this.setState({
            idActive:item.rowID
        }, () => {
            this.onClickSelectedDeviceActive()
        })
    }

    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(/,/g, '').replace(regex, separate);
        return res;
    }

    formatNumberShow = (number) => {
        if(Number(number) == number && number % 1 === 0){
            return this.formatCurrency(Number(number).toFixed(0));
        } else {
            return number;
        }
    }

    popupShowSlider = () => {
        let xhtml = (
            <div id="carouselExampleIndicators" className="carousel slide slider-images" data-ride="carousel">
            <ol className="carousel-indicators dots-carousel">
                {
                    this.state.colorActive.files_url.map((item, i) =>
                        <li data-target="#carouselExampleIndicators" data-slide-to={i} className={i == 0 ? "active" : "" } key={i}><span></span></li>
                    )
                }
            </ol>
            <div className="carousel-inner">
                {
                    this.state.colorActive.files_url.map((item, i) =>
                        <div className={i == 0 ? "carousel-item active" : "carousel-item" } key={i}>
                            <img src={ item.original } className="d-block" alt="..." />
                        </div>
                    )
                }
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span></span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
             <span></span>
            </a>
          </div>
        );
        let dataModal = {
            toggle:true,
            close:true,
            class_n:'modal-lg modal-images modal-images-custom',
            component:xhtml
        }
        this.props.showModal(dataModal);
        setTimeout(() => {
            new window.$('.carousel').carousel({
                touch: true
            })
        })
    }

    selectedFunc = (e) => {

    }

    onChangeSortField = (e) => {
        this.props.change('WirelessAdviceStep2.sortDirection', e.target[e.target.selectedIndex].getAttribute('data-direction'))
        this.props.change('WirelessAdviceStep2.sortBy', e.target.value)

        setTimeout(() => {
            this.getListConsultants();
        })

    }

    onChangeSortTableField = (value1, value2) => {
        this.props.change('WirelessAdviceStep2.sortDirection', '')
        this.props.change('WirelessAdviceStep2.sortBy', '')
        this.props.change('WirelessAdviceStep2.sortTableFieldDirection', value2)
        this.props.change('WirelessAdviceStep2.sortTableFieldBy', value1)

        setTimeout(() => {
            this.getListConsultants();
        })

    }

    onClickSelectedDeviceActive = () => {
        this.setState({
            showDetailDevice:false
        })
        if(this.state.idActive) {
            let rowKey = this.state.consultant.data.findIndex(x => x.rowID === this.state.idActive);

            if(rowKey !== -1) {
                let consultantColors = this.state.consultant.data[rowKey].color.map((item) => ({
                    ...item,
                    files_url: JSON.parse(item.files).map(e => process.env.REACT_APP_API_URL_DEV + 'storage/color_device/original/' + e)
                }))
                this.setState({
                    consultantColors: consultantColors,
                    colorActive:this.state.consultant.data[rowKey].color[0],
                    // deviceActive:this.state.consultant.data[rowKey].device,
                    consultantActive:this.state.consultant.data[rowKey],
                }, () => {
                    api.advice.getDetailDevice(this.state.consultantActive.deviceID).then(res => {
                        this.setState({
                            deviceActive:res.data.data.device,
                            showDetailDevice:true
                        })
                    }).catch(err => {
                        this.setState({
                            showDetailDevice:false
                        })
                    })
                    this.setState({
                        colorActive:this.state.consultantColors[0]
                    }, () => {
                        this.props.change('WirelessAdviceStep2.color', this.state.consultantColors[0]);
                        if(this.state.consultant.data[rowKey].color.length > 0) {
                            // helper.removeSwiperSlider();
                            // helper.loadingSwiperSlider();
                        }
                    })
                })
            }
        }
    }

    onClickSelectedColorActive = (item) => {
        if(item.id) {
            let colorKey = this.state.consultantColors.findIndex(x => x.id === item.id);
            if(colorKey !== -1) {
                this.setState({
                    colorActive:this.state.consultantColors[colorKey]
                }, () => {
                    this.props.change('WirelessAdviceStep2.color', this.state.consultantColors[colorKey]);
                })
            }

        }
    }

    onClickSearchKeyword = (e) => {
        e.preventDefault();
        this.getListConsultants()
    }

    onChangeFilterChecked = (e) => {
        this.props.change('WirelessAdviceStep2.sortDirection', '')
        this.props.change('WirelessAdviceStep2.sortBy', '')
        this.props.change('WirelessAdviceStep2.sortTableFieldDirection', '')
        this.props.change('WirelessAdviceStep2.sortTableFieldBy', '')
        setTimeout(() => {
            this.getListConsultants();
        })
    }

    onClickSelectedManufacture = (id) => {
        this.props.change('WirelessAdviceStep2.mfID', id);
        setTimeout(() => {
            this.getListConsultants();
        });
    }

    getDisclosureSubsidyPrice = async (value) => {
        this.props.showLoading();
        let data = {
          'planID' : value.id ? value.id : null,
          'modelID' : this.props.WirelessAdvice?.WirelessAdviceStep3?.phone?.modelID || null,
        }        
        let result = await this.props.getDisclosureSubsidy(data);
        let disclosureSubsidyDifference;
        if (result.status == 1) {
            if (this.props.checkPlanNew != "planSecond") {
                this.props.change('WirelessAdviceStep3.disclosureSubsidy', result.data?.disclosure_price ? result.data.disclosure_price : 0);
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', result?.data?.disclosure_price ? result.data.disclosure_price : 0);
                this.props.change('WirelessAdviceStep3.displayApplyDate', result.data?.apply_date ? result.data.apply_date : null);
                if (this.props.WirelessAdvice?.WirelessAdviceStep3?.optionFee == 0) {
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                }else{
                    if (result?.data?.disclosure_price) {
                        disclosureSubsidyDifference = (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault);
                    } else {
                        disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
                    }
                    if (this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault) {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
                    }else{
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                    }
                }
                
            } else {
                if (this.props.WirelessAdvice?.WirelessAdviceStep3?.optionFee == 0) {
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', result?.data?.disclosure_price || 0);
                }else{
                    if (result?.data?.disclosure_price) {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', result?.data?.disclosure_price || 0);
                        disclosureSubsidyDifference = (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - (+result?.data?.disclosure_price);
                    } else {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', 0);
                        disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
                    }
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
                }
            }
        }else{
            if (this.props.checkPlanNew != "planSecond") {
                this.props.change('WirelessAdviceStep3.disclosureSubsidy', 0);
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', 0);
                this.props.change('WirelessAdviceStep3.displayApplyDate', null);
            }else{
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', 0);
                disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
            }
        }      

        return result;
    }

    onSubmitToStep3 = async () => {
        const { consultantActive } = this.state
        if(this.state.consultantActive.rowID){
            // let phone = this.state.consultantActive;
            // phone.device = this.state.deviceActive;

            let phone = this.state.consultantActive;
            let rowKey = this.state.consultant.data.findIndex(x => x.rowID === this.state.consultantActive.rowID);
            let row = this.state.consultant.data[rowKey];
            const res = await api.advice.getDetailDevice(row.deviceID);
            phone.device = res.data.data.device;

            let {WirelessAdvice} = this.props;
            let optionalAgreementDefault;
            let optionalAgreement = 0;
            let monthDcPrice = 0;
            let optionalAgreementFix = 0;
            let optionalAgreementFix2 = 0;
            let optionalAgreementArr;
            let optionalAgreementArr2;
            let cost_calc = +this.state.consultantActive.cost_calc;
            let cost_display = +this.state.consultantActive.cost_display;
            let discost_24 = +this.state.consultantActive.discost_24;
            let discount_full = this.state.consultantActive.discount_full;
            let customPlan = 1;
            let commitmentRate;
            if (this.state.consultantActive.spec_cmm == 1) {
                commitmentRate = +this.state.consultantActive.spec_cmm_value/100;
            } else {
                commitmentRate = +this.state.consultantActive.commitment_rate/100;
            }
            let packageFee = {
                // ...this.state.consultantActive,
                // ...this.state.consultantActive.plan,
                id: this.state.consultantActive.planID,
                classID: this.state.consultantActive.classID,
                name: this.state.consultantActive.plan_name,
                cost_display: this.state.consultantActive.cost_display,
                cost_calc: this.state.consultantActive.cost_calc,
                class: this.state.consultantActive.class,
                discost_ad_cond: this.state.consultantActive.discost_ad_cond,
                spec_cmm: this.state.consultantActive.spec_cmm,
                spec_cmm_value: +this.state.consultantActive.spec_cmm_value,
                commitment_rate: +this.state.consultantActive.commitment_rate,
                discost_12: this.state.consultantActive.discost_12,
                discost_24: this.state.consultantActive.discost_24,
                discount_full: discount_full,
                plan_classification : {
                    class: this.state.consultantActive.class
                }
            }
            
            let planData = {...packageFee}
            let disclosureSubsidy = this.state.consultantActive.dps_amt_cost;
            // nếu đã có plan và device cùng matchID thì giữ lại plan cũ
            //let is_same_old_plan_class = WirelessAdvice.WirelessAdviceStep3?.packageFee && WirelessAdvice.WirelessAdviceStep3?.phone?.device?.matchID == phone?.device?.matchID
            let is_same_old_plan_class = false;
            let has_old_plan_custom = WirelessAdvice.WirelessAdviceStep3?.packageFee && WirelessAdvice.WirelessAdviceStep3?.planData

            if (is_same_old_plan_class) {
                packageFee = {...WirelessAdvice.WirelessAdviceStep3?.packageFee}
                // WirelessAdvice.WirelessAdviceStep3?.planData là gói phí custom
                planData = !has_old_plan_custom ? {...WirelessAdvice.WirelessAdviceStep3?.packageFee} : {...WirelessAdvice.WirelessAdviceStep3?.planData}
                customPlan = has_old_plan_custom ? 999 : 1

                if (WirelessAdvice.WirelessAdviceStep3?.packageFee?.spec_cmm == 1) {
                    commitmentRate = +(WirelessAdvice.WirelessAdviceStep3?.packageFee?.spec_cmm_value || 0) / 100;
                } else {
                    commitmentRate = +(WirelessAdvice.WirelessAdviceStep3?.phone?.commitment_rate || 0) / 100;
                }
            }
            
            cost_display = (is_same_old_plan_class) ? (+WirelessAdvice.WirelessAdviceStep3?.packageFee?.cost_display || +WirelessAdvice.WirelessAdviceStep3?.planData?.cost_display) : cost_display
            cost_calc = (is_same_old_plan_class) ? (!has_old_plan_custom ? +WirelessAdvice.WirelessAdviceStep3?.packageFee?.cost_calc : +WirelessAdvice.WirelessAdviceStep3?.planData?.cost_display) : cost_calc

            let result = helper.optionalAgreementCT(
              cost_display,
              cost_calc,
              customPlan,
              planData,
              commitmentRate,
              24,
              WirelessAdvice.WirelessAdviceStep1.telcomID
            );
            if (result.optionalAgreement < 0) {
                optionalAgreement = 0
            } else {
                optionalAgreement = result.optionalAgreement;
            }
            optionalAgreementDefault = optionalAgreement;

            let promotionDuration;
            if (this.state.consultantActive?.plan_promotion_duration.length > 0) {
                promotionDuration = this.state.consultantActive.plan_promotion_duration;
            } else {
                promotionDuration = null;
            }

            /* task 7017:
             Đối với giảm giá kết hợp thì chỉ mobile mới có thể kết hợp thôi, nên cần chỉnh sửa lại để các loại thiết bị khoanh đỏ trong image2 không thể chọn giảm giá kết hợp.
             => Yêu cầu khi chọn thiết bị được match 5G tablet, tablet ,wearable, wifi, pet, iot thì chỉnh sửa để không kích hoạt giảm giá kết hợp.
             Text: "선택할 수 있는 결합상품이 없습니다." (Không có sp kết hợp có thể chọn)*/
            let check_is_mobile = ![3, 7, 8, 10, 11, 12].includes(+phone?.matchID || 0)

            let WirelessAdviceStep3 = {
                ...CONSULTANT_WIRELESS,
                phone: phone,
                startingPrice: this.state.consultantActive.dl_price,
                packageFee: packageFee,
                planData: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.planData : null,
                basicCost: has_old_plan_custom ? WirelessAdvice.WirelessAdviceStep3?.basicCost : helper.roundUpNumber(cost_display * 1.1),
                basicCostCalc:  has_old_plan_custom ? WirelessAdvice.WirelessAdviceStep3?.basicCostCalc : helper.roundUpNumber(cost_calc * 1.1),
                disclosureSubsidy: disclosureSubsidy,
                disclosureSubsidySixPlan: +this.state.consultantActive.dps_amt_cost_six_plan,
                disclosureSubsidyBasicPlan: +this.state.consultantActive.dps_amt_cost_basic_plan,
                disclosureSubsidyType : "six", 
                conversion_price: +this.state.consultantActive.conversion_price,
                conversion_apply_date: this.state.consultantActive.conversion_apply_date,
                manual_conversion_confirm: +this.state.consultantActive.manual_conversion_confirm,
                conversion_price_input : 0,
                conversion_price_input_add : 0,
                conversion_price_input_add_type : null,
                // optionalAgreement: this.state.consultantActive.spec_cmm == 1
                //     ? +(this.state.consultantActive.spec_cmm_value * this.state.consultantActive.cost_display/100)+(this.state.consultantActive.spec_cmm_value * this.state.consultantActive.cost_display/100)*10/100
                //     : +(this.state.consultantActive.commitment_rate * this.state.consultantActive.cost_display/100)+(this.state.consultantActive.commitment_rate * this.state.consultantActive.cost_display/100)*10/100,
                // optionalAgreement: (Math.floor(+this.state.consultantActive.cost_display+(this.state.consultantActive.cost_display*10/100)) -
                //                         Math.floor(+this.state.consultantActive.discost_24 + this.state.consultantActive.discost_24*10/100))*0.25,

                optionalAgreement: optionalAgreement,
                optionalAgreementDefault: helper.roundUpNumber(optionalAgreementDefault),
                interestRate: this.state.consultantActive.installment_rate,
                conversion2G: null,
                optionalAgreementWith2G: null,
                disclosureSubsidyDefault: null,
                disclosureSubsidyDifference: null,
                disclosureSubsidyDifferenceDefault: null,
                promotionDuration: promotionDuration,
                promotionDurationDiscountOne: null,
                promotionDurationDiscountTwo: null,
                insurrance: null,
                insurranceCancelDate: null,
                discost12: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.discost12 : (+this.state.consultantActive.discost_12 * 1.1),
                discost24: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.discost24 : (+this.state.consultantActive.discost_24 * 1.1),
                discostChange: 24,
                displayApplyDate: this.state.consultantActive.dsp_apply_date,
                rateAgreement: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.rateAgreement : (+this.state.consultantActive.discost_24 * 1.1),
                rateAgreementDefault: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.rateAgreementDefault : (+this.state.consultantActive.discost_24 * 1.1),
                contractMonth: 24,
                saler: {...this.props.auth.user},
                discountFullPlan: is_same_old_plan_class ? WirelessAdvice.WirelessAdviceStep3?.discountFullPlan : this.state.consultantActive.discount_full,
                // monthFee: 24 * ((Math.floor(+this.state.consultantActive.cost_display+(this.state.consultantActive.cost_display*10/100)) - Math.floor(+this.state.consultantActive.discost_12 + this.state.consultantActive.discost_12*10/100))*0.25)

                // giữ lại value giảm giá
                optionFee: this.props.WirelessAdvice.WirelessAdviceStep3.optionFee,
                advancedDiscountCardObject: this.props.WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardObject,
                advancedDiscountCard: this.props.WirelessAdvice.WirelessAdviceStep3.advancedDiscountCard,
                advancedDiscountCardID: this.props.WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardID,
                advancedDiscountCardMonth: this.props.WirelessAdvice.WirelessAdviceStep3.advancedDiscountCardMonth,
                combineCondition: check_is_mobile ? this.props.WirelessAdvice.WirelessAdviceStep3.combineCondition : null,
                combineDevice: check_is_mobile ? this.props.WirelessAdvice.WirelessAdviceStep3.combineDevice : null,
                combineOption: check_is_mobile ? this.props.WirelessAdvice.WirelessAdviceStep3.combineOption : null,
                combinePlans: check_is_mobile ? this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans : [],
                combinePlansSring: check_is_mobile ? this.props.WirelessAdvice.WirelessAdviceStep3.combinePlansSring : '',
                optionDiscount: this.props.WirelessAdvice.WirelessAdviceStep3.optionDiscount,
                welfareDiscount: this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount,
                discountCardObject: this.props.WirelessAdvice.WirelessAdviceStep3.discountCardObject,
                discountCard: this.props.WirelessAdvice.WirelessAdviceStep3.discountCard,
                discountCardId: this.props.WirelessAdvice.WirelessAdviceStep3.discountCardId,
                needToResetCombineDiscount: this.props.WirelessAdvice.WirelessAdviceStep3.phone.deviceID !== phone.deviceID,
                
                used_program_count: this.state.consultantActive.used_program_count,
                used_program_count: this.state.consultantActive.device_insurance_count,
                disclosureSubsidy_base : 0
            }
            WirelessAdviceStep3.saler.name = this.props.auth.role == 'Member' || this.props.auth.role == 'Admin' ? '' : this.props.auth.user.name

            // if(this.props.WirelessAdvice.WirelessAdviceStep3.phone.modelID !== phone.modelID && (this.props.WirelessAdvice.WirelessAdviceStep3.phone.classID == this.state.consultantActive.classID)) {
            //     set(WirelessAdviceStep3, 'packageFee', this.props.WirelessAdvice.WirelessAdviceStep3.packageFee);
            //     set(WirelessAdviceStep3, 'basicCost', this.props.WirelessAdvice.WirelessAdviceStep3.basicCost);
            // }

            this.props.change('WirelessAdviceStep2.status', true)
            this.props.change('WirelessAdviceStep3', WirelessAdviceStep3)
            this.props.change('WirelessAdviceStep3.phone.color', this.state.consultantColors);
            this.props.change('WirelessAdviceStep3Default.startingPrice', this.state.consultantActive.dl_price)

            if (is_same_old_plan_class) {
                await this.getDisclosureSubsidyPrice(planData);
            }

            this.props.change('WirelessAdviceStep3.disclosureSubsidy_base', this.props.WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidy || 0);

            this.props.onSubmit()
        }

    }

    onClickAddBookmark = (item) => {
        let data = {
            model: item.modelID,
            plan: item.planID,
        }

        let keyConsultant = this.state.consultant.data.findIndex((i) => {
            return i.rowID == item.rowID
        })
        let consultantArray = [...this.state.consultant.data];

        if(keyConsultant !== -1) {
            if(this.state.consultant.data[keyConsultant].is_bookmark == '1') {
                consultantArray[keyConsultant].is_bookmark = '0';
            } else {
                consultantArray[keyConsultant].is_bookmark = '1';
            }

            this.props.showLoading()
            api.advice.addBookmark(data).then(res => {
                if(res.data.status == 1){
                    this.setState({
                        consultant:{
                            ...this.state.consultant,
                            data:consultantArray,
                        }
                    }, () => {
                        this.props.hideLoading()
                    })
                }
                this.props.hideLoading();
            }).catch(err => {
                this.props.hideLoading();
            })
        }

    }



    toggleTooltip = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    onClickFilterStandard = (item) => {
        if(item == null) {
            this.setState({
                standardActive:'',
                standardNameActive:''
            }, () => {
                this.getListConsultants();
            })
        } else {
            this.setState({
                standardActive:item.id,
                standardNameActive:item.name
            }, () => {
                this.getListConsultants();
            })
        }

    }

    conpareDevice = (type) => {
        this.setState({
            isRedirect: true,
            type,
            model: this.state.consultantActive.modelID,
            plan: this.state.consultantActive.planID,
            color : this.state.colorActive.id
        })
    }

    showPopupDetailDevice = () => {
        let xhtml = (
                <div className="modal-body">
                    <div className="content-detail right-product">
                        <div className="left-detail">
                            <div className="list-detail">
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>색상</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.colorActive  && this.state.colorActive.name }</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>출시일</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.release_date }</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>CPU</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.cpu }</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>RAM</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.memory }GB</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>메모리</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state?.deviceActive?.int_storage } { this.state.deviceActive && this.state.deviceActive.ext_storage ? `, ${this.state.deviceActive.ext_storage}` : ""}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>디스플레이</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state?.deviceActive?.screen_size } 인치 { this.state?.deviceActive?.disp_info }</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>카메라</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.front_cam ? ('전면' + this.state.deviceActive.front_cam) : ""}</span>
                                        <span>{ this.state.deviceActive && this.state.deviceActive.back_cam ? ('후면' + this.state.deviceActive.back_cam)  : ""}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>무게</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.weight }</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>기타</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{ this.state.deviceActive && this.state.deviceActive.battery }</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom">
                                <h5>기타정보</h5>
                                <div className="box-scroll" dangerouslySetInnerHTML={{__html:this.state.deviceActive.extra_desc}}>

                                </div>
                            </div>
                        </div>
                        <div className="right-detail">
                            <div className="slide-color-product">
                                <SwiperSlideWirelessComponent
                                    devices={this.state.consultantColors}
                                    onClickSelectedColorActive={this.onClickSelectedColorActive}
                                    showTitle={true}
                                    navigation={true}
                                    indexActive={1}
                                 />
                            </div>
                            {this.state.deviceActive.device_special_feature && (
                                <ul className="list-feature">
                                    { this.state.deviceActive.device_special_feature.map((item, i) =>
                                        <Fragment key={i}>
                                            <li >
                                                {
                                                    item.pivot && item.pivot.opt_value ? (
                                                        <Tooltip message={item.pivot.opt_value}
                                                                position={'top'}
                                                        >
                                                            {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                                <div className="text-feature" style={{backgroundColor: `${item.sf_type_content.backgroundColor}`,color: `${item.sf_type_content.color}` }} >
                                                            <span >
                                                                {item.sf_name}
                                                            </span>
                                                                </div>
                                                            )}
                                                        </Tooltip>
                                                    ) : (
                                                        <Fragment>
                                                            {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                                <div className="text-feature" style={{backgroundColor: `${item.sf_type_content.backgroundColor}`,color: `${item.sf_type_content.color}` }} >
                                                            <span >
                                                                {item.sf_name}
                                                            </span>
                                                                </div>
                                                            )}
                                                        </Fragment>
                                                    )
                                                }

                                            </li>
                                        </Fragment>
                                    )}
                                </ul>
                            )}

                        </div>
                    </div>
                </div>
        );
        let xhtmlFooter = (
            <button type="button" className="btn btn-submit"  onClick={() => this.onSubmitToStep3CloseModal()}>적용</button>
        );

        let dataModal = {
            toggle:true,
            close:true,
            class_n:'modal-dialog-centered modal-xl detail-step-two',
            component:xhtml,
            html_title: this.state.deviceActive.name ,
            html_footer: xhtmlFooter ,
        }
        this.props.showModal(dataModal);
    }

    onSubmitToStep3CloseModal = () => {
        this.props.hideModal();
        setTimeout(() => {
            this.onSubmitToStep3(this.state.consultantActive)
        })
    }

    render() {
        const { handleSubmit, WirelessAdvice } = this.props;
        let {isShowModal, resultPackage} = this.state;
        if (this.state.isRedirect) {
          return <Redirect
            push
            to={{
              pathname: routeName.COMPARE,
              search: `?model=${this.state.model}&plan=${this.state.plan}&type=${this.state.type}&color=${this.state.color}`,
            }}
          />;
        }
        return (
            <FormSection name="WirelessAdviceStep2">
                <article className="content-product">
                    <div className="row" style={{"flex-wrap" : "nowrap"}}>
                        <div className="col-md-9 left-product">
                            <div className="top">
                                <ListTabDevice
                                  WirelessAdvice={this.props.WirelessAdvice}
                                  onClickSelectedManufacture={this.onClickSelectedManufacture}
                                  manufacturers={this.props.manufacturers}

                                  mfID={this.props.WirelessAdvice.WirelessAdviceStep2.mfID}
                                />
                                <div className="set show">
                                    {/* <button className="btn btn-wireless" onClick={this.openPopupopenPopupPlanPackage}>*/}
                                    <button className="btn btn-wireless" onClick={this.handleShowModalPlan}>
                                        기준요금제 설정
                                    </button>
                                </div>
                                {/* 
                                <PlanPackageFeeModal
                                    openPopup={this.openPopupopenPopupPlanPackage}
                                    isOpenModal={this.state.isOpenModalPlanPackage}
                                />
                                */}
                                
                                
                                <PlanPackageFeeModal 
                                        toggleModal = {this.toggleModal}
                                        isShowModal = {isShowModal}
                                        planCustomResultPackage = {this.planCustomResultPackage}
                                        planResultPackage = {this.planResultPackage}
                                        checkPlanNew = {'planFirst'}
                                        checkUnApply = {false}
                                        getListConsultants = {this.getListConsultants}
                                                                            
                                />
                                
                                <Modal isOpen={this.state.error_modal.isShowModal}
                                        className="modal-dialog modal-dialog-centered"
                                        contentClassName="pl-4 pr-4"
                                        toggle={(e) => this.toggleErrorModal(e, 'error_modal')}
                                    // onOpened = {this.onOpened}
                                    // onClosed = {this.onCloseModal}
                                    // backdrop={"static"}
                                >
                                    <div className="modal-body text-center p-5">
                                        {
                                            this.state.error_modal.message
                                        }
                                    </div>
                                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                                onClick={(e) => this.toggleErrorModal(e, 'error_modal')}>확인
                                        </button>
                                    </div>
                                </Modal>

                                <div className="dropdown show">
                                    <button className="btn btn-product dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        {this.state.standardNameActive ? this.state.standardNameActive : '단말기 유형'}
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-option " aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
                                        <a className={"dropdown-item " + (this.state.standardActive == '' ? "active" : "")} key={'standard_null'} onClick={() => this.onClickFilterStandard(null)}> 전체</a>
                                        {this.props.standardTypeCodes.map((item, i) =>
                                        <a className={"dropdown-item " + (this.state.standardActive == item.id ? "active" : "")} key={i} onClick={() => this.onClickFilterStandard(item)}> {item.name}</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row top-search-query">
                                <div className="col-md-6">
                                    <SearchDevice
                                      onClickSearchKeyword={this.onClickSearchKeyword}
                                    />

                                    {/*<div className="form-search">*/}
                                    {/*    <Field*/}
                                    {/*      className="form-control h-44"*/}
                                    {/*      placeholder="Search"*/}
                                    {/*      name="keyword"*/}
                                    {/*      type="text"*/}
                                    {/*      component={renderTextField}*/}
                                    {/*    />*/}
                                    {/*    <button className="btn my-2 my-sm-0" type="button" onClick={() => this.onClickSearchKeyword()}>*/}
                                    {/*        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-search.svg"} alt=""/>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="col-md-6">
                                    <div className="group-check">
                                        <div className="form-group form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <Field
                                                    type="checkbox"
                                                    id="is_new"
                                                    name="is_new"
                                                    component={renderCheckboxField}
                                                    onChange={(e) => this.onChangeFilterChecked(e)}
                                                />
                                                <label className="custom-control-label"
                                                    htmlFor="is_new">신규단말기</label>
                                            </div>
                                        </div>
                                        <div className="form-group form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <Field
                                                    type="checkbox"
                                                    id="is_consulted"
                                                    name="is_consulted"
                                                    component={renderCheckboxField}
                                                    onChange={(e) => this.onChangeFilterChecked(e)}
                                                />
                                                <label className="custom-control-label"
                                                    htmlFor="is_consulted">인기순</label>
                                            </div>
                                        </div>
                                        <div className="form-group form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <Field
                                                    type="checkbox"
                                                    id="is_bookmarked"
                                                    name="is_bookmarked"
                                                    component={renderCheckboxField}
                                                    onChange={(e) => this.onChangeFilterChecked(e)}
                                                />
                                                <label className="custom-control-label"
                                                    htmlFor="is_bookmarked">즐겨찾기</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                                    <article className="table-form " >
                                        <div className="table-wireless-step" >

                                                <table className="table table-header">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="d-flex align-items-center">
                                                                단말기명

                                                            </div>
                                                        </th>
                                                        <th scope="col">모델명</th>
                                                        <th scope="col">
                                                            <a onClick={() =>
                                                                        WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'factory_price' &&
                                                                        WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'desc' ?
                                                                            this.onChangeSortTableField('factory_price', 'asc')
                                                                        :
                                                                            this.onChangeSortTableField('factory_price', 'desc')
                                                                        }>
                                                                <div className="d-flex align-items-center">

                                                                    출고가


                                                                    <div className="sort-fillter">
                                                                        <button
                                                                        className={WirelessAdvice
                                                                        && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'desc'
                                                                        && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'factory_price' ?
                                                                            "btn btn-sort p-0 sort-active"
                                                                            :
                                                                            "btn btn-sort p-0"}
                                                                        //   onClick={() => this.onChangeSortTableField('factory_price', 'desc')}
                                                                        >
                                                                            <i className="fas fa-sort-up"></i>
                                                                        </button>
                                                                        <button
                                                                        className={WirelessAdvice
                                                                        && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'asc'
                                                                        && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'factory_price' ?
                                                                            "btn btn-sort p-0 sort-active"
                                                                            :
                                                                            "btn btn-sort p-0"}
                                                                        //   onClick={() => this.onChangeSortTableField('factory_price', 'asc')}
                                                                        >
                                                                            <i className="fas fa-sort-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </th>
                                                        <th scope="col" >
                                                            <a onClick={() =>
                                                                WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'disclosure_price' &&
                                                                WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'desc' ?
                                                                    this.onChangeSortTableField('disclosure_price', 'asc')
                                                                :
                                                                    this.onChangeSortTableField('disclosure_price', 'desc')
                                                                }>
                                                            <div className="d-flex align-items-center">

                                                                지원금

                                                                <div className="sort-fillter">
                                                                    <button
                                                                      className={WirelessAdvice
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'desc'
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'disclosure_price' ?
                                                                        "btn btn-sort p-0 sort-active"
                                                                        :
                                                                        "btn btn-sort p-0"}
                                                                    //   onClick={() => this.onChangeSortTableField('disclosure_price', 'desc')}
                                                                      >
                                                                        <i className="fas fa-sort-up"></i>
                                                                    </button>
                                                                    <button
                                                                      className={WirelessAdvice
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'asc'
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'disclosure_price' ?
                                                                        "btn btn-sort p-0 sort-active"
                                                                        :
                                                                        "btn btn-sort p-0"}
                                                                    //   onClick={() => this.onChangeSortTableField('disclosure_price', 'asc')}
                                                                      >
                                                                        <i className="fas fa-sort-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            </a>
                                                        </th>
                                                        <th scope="col">기준요금제</th>
                                                        <th scope="col" style={{"width" : "150px"}}>할부원금</th>
                                                        <th scope="col" className="w-45" >
                                                            <a onClick={() =>
                                                                    WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'ISNULL(N.id)' &&
                                                                    WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'asc' ?
                                                                        this.onChangeSortTableField('ISNULL(N.id)', 'desc')
                                                                    :
                                                                        this.onChangeSortTableField('ISNULL(N.id)', 'asc')
                                                                    }>
                                                            <div className="d-flex align-items-center">

                                                                <i className="far fa-star"></i>

                                                                <div className="sort-fillter">
                                                                    <button
                                                                      className={WirelessAdvice
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'asc'
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'ISNULL(N.id)' ?
                                                                        "btn btn-sort p-0 sort-active"
                                                                        :
                                                                        "btn btn-sort p-0"}
                                                                    //   onClick={() => this.onChangeSortTableField('ISNULL(N.id)', 'desc')}
                                                                      >
                                                                        <i className="fas fa-sort-up"></i>
                                                                    </button>
                                                                    <button
                                                                      className={WirelessAdvice
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldDirection == 'desc'
                                                                      && WirelessAdvice.WirelessAdviceStep2.sortTableFieldBy == 'ISNULL(N.id)' ?
                                                                        "btn btn-sort p-0 sort-active"
                                                                        :
                                                                        "btn btn-sort p-0"}
                                                                    //   onClick={() => this.onChangeSortTableField('ISNULL(N.id)', 'asc')}
                                                                      >
                                                                        <i className="fas fa-sort-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            </a>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </table>

                                               <div className="table-scroll"  id="scrollableDiv" style={{maxHeight: '405px', overflowX: 'auto'}}>

                                                {!this.state.loading && this.state.consultant.data.length == 0 && (
                                                    <table className="table table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={6}><div className="text-center">{ MessageJSON.smart_consultant.no_models}</div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )}
                                                {this.state.consultant.data.length > 0 && <InfiniteScroll
                                                    dataLength={this.state.consultant.data.length}
                                                    next={()=>this.fetchMoreData()}
                                                    // hasMore={this.state.consultant.current_page < this.state.consultant.last_page || false}
                                                    hasMore={this.state.consultantLast.data.length > 0 || false}
                                                    loader={<></>}
                                                    scrollableTarget={`scrollableDiv`}
                                                >
                                               <table className="table table-striped">
                                                    <tbody>
                                                    {this.state.consultant.data.map((item, k) =>
                                                      <Fragment key={k}>
                                                          <tr onClick={() => this.clickActiveDevice(item)}
                                                              onDoubleClick={() => this.onSubmitToStep3(item)}
                                                              className={this.state.idActive == item.rowID ? 'tr-active' : ''}>
                                                              <th scope="row" className="name">{item.device_name} <span className="storage">{item.storage && (item.storage !== '0' && item.storage !== null ) ? (<>({item.storage}GB)</>) : (<></>)}</span></th>
                                                              <td>{item.model_name ? item.model_name : '-'}</td>
                                                              <td>{item.dl_price ? this.formatNumberShow(item.dl_price) : 0} 원</td>
                                                              <td>{item.dps_amt_cost ? this.formatNumberShow(item.dps_amt_cost) : 0}원</td>
                                                              <td>{item.plan_name ? item.plan_name : '-'}</td>
                                                              <td style={{"width" : "150px"}}>{this.formatNumberShow((item.dl_price ? +item.dl_price : 0) - (item.dps_amt_cost ? +item.dps_amt_cost : 0)) }원</td>
                                                              <td className={item.is_bookmark !== '0' ? "check" : ""}>
                                                                  <button className="btn p-0"
                                                                          onClick={() => this.onClickAddBookmark(item)}>
                                                                      <i className="far fa-star"></i>
                                                                  </button>
                                                              </td>
                                                          </tr>
                                                      </Fragment>
                                                    )}

                                                    </tbody>
                                                </table>
                                                </InfiniteScroll>}
                                               </div>

                                        </div>




                                    </article>
                                </div>
                            </div>
                            <div className="button-sumit">
                                {this.state.consultantActive.rowID ? (
                                    <button className="btn btn-submit pl-5 pr-5" onClick={() => this.onSubmitToStep3(this.state.consultantActive)}>
                                        휴대폰 선택
                                    </button>
                                ) :('')}

                            </div>
                        </div>
                        {this.state.consultantColors.length > 0 && this.state.showDetailDevice ? (
                             <div className="col-md-4 right-product">
                             <div className="slide-color-product color-product-swiper">
                                <SwiperSlideWirelessComponent
                                    devices={this.state.consultantColors}
                                    onClickSelectedColorActive={this.onClickSelectedColorActive}

                                    navigation={false}
                                 />
                          </div>

                            <h3 className="name-product ">
                                {this.state.deviceActive?.model_name}
                                <br/>
                                { this.state.deviceActive?.name }
                                <button className="btn p-0">
                                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-search.svg"} onClick={() => this.showPopupDetailDevice()}/>
                                </button>
                            </h3>
                            <ul className="list-feature">
                                { this.state.deviceActive?.device_special_feature?.map((item, i) =>
                                    <Fragment key={i}>
                                        <li >
                                            {
                                                item.pivot && item.pivot.opt_value ? (
                                                    <Tooltip message={item.pivot.opt_value}
                                                             position={'top'}
                                                    >
                                                        {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                            <div className="text-feature" style={{backgroundColor: `${item.sf_type_content.backgroundColor}`,color: `${item.sf_type_content.color}` }} >
                                                        <span >
                                                            {item.sf_name}
                                                        </span>
                                                            </div>
                                                        )}
                                                    </Tooltip>
                                                ) : (
                                                    <Fragment>
                                                        {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                            <div className="text-feature" style={{backgroundColor: `${item.sf_type_content.backgroundColor}`,color: `${item.sf_type_content.color}` }} >
                                                        <span >
                                                            {item.sf_name}
                                                        </span>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                )
                                            }

                                        </li>
                                    </Fragment>
                                )}
                            </ul>
                            <div className="specifications">
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>색상</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.colorActive  && this.state.colorActive.name } </p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>출시일</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.deviceActive && this.state.deviceActive.release_date }</p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>CPU</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.deviceActive && this.state.deviceActive.cpu }</p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>RAM</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.deviceActive && this.state.deviceActive.memory }GB</p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>메모리</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state?.deviceActive?.int_storage } { this.state.deviceActive && this.state.deviceActive.ext_storage ? `, ${this.state.deviceActive.ext_storage}` : ""}</p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>디스플레이</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p> { this.state?.deviceActive?.screen_size } 인치 { this.state?.deviceActive?.disp_info } </p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>카메라</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    { this.state.deviceActive && this.state.deviceActive.front_cam ? (<p>전면 { this.state.deviceActive.front_cam }</p>) : ""}
                                    { this.state.deviceActive && this.state.deviceActive.back_cam ? (<p>후면 { this.state.deviceActive.back_cam }</p>) : ""}

                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>배터리</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.deviceActive && this.state.deviceActive.battery }</p>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-3 left-specifications">
                                    <p>무게</p>
                                </div>
                                <div className="col-9 right-specifications">
                                    <p>{ this.state.deviceActive && this.state.deviceActive.weight }</p>
                                </div>
                                </div>
                            </div>
                            <div className="button-compare">
                                <button
                                    className="btn btn-gray"
                                    onClick={() => this.conpareDevice(1)}>
                                    비교상담
                                </button>
                            </div>
                        </div>
                        ) : ('')}

                    </div>
                </article>
        </FormSection>
        );
    }
}

WirelessStep2.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
        standardTypeCodes: state.dataStore.standardTypeCodes,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        changeModalTitle: (data) => dispatch(changeModalTitle(data)),
        changeModalContent: (data) => dispatch(changeModalContent(data)),
        change: () => dispatch(change()),
        getPlanInformation: (data) => dispatch(getPlanInformation(data)),
        getDisclosureSubsidy: (data) => dispatch(getDisclosureSubsidy(data)),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true, withRef: true }
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessStep2);
