import {
    dataActions
} from '../actions';

const initalState = {
    pricePackages:{
        data:[]
    },
    priceExtraService: null,
    priceGroups:{
        data:[]
    },
    telcomProviders:{
        telcomChildren:[],
        telcomParents:[]
    },
    manufacturers:{},
    standardTypeCodes:{},
    usedProgram:{
        programs:[],
        mainData:{}
    },
    banners:[],
    notification:{
        notices:{
            data:[]
        },
        notifications:{
            data:[]
        },
        cat_notice_parents:[],
        cat_notice_childs:[],
        cat_doc_childs:[],
        cat_doc_parents:[],
        select_cat_doc_childs:[],
        select_cat_notice_childs:[],
        documents:{
            data:[]
        },
        count_ntf:0,
        popup_ads_documents:[],
        popup_ads_notices:[]
    },
};

const dataStore = (state = initalState, action) => {
    switch (action.type) {
        case dataActions.GET_PRICE_PACKAGES: {
            return {
                ...state,
                pricePackages:action.data
            }
        }
        case dataActions.GET_PRICES: {
            return {
                ...state,
                pricePackages:{
                    data:action.data.price_packages
                },
                priceExtraService:action.data.price_extra_service
                // priceGroups:{
                //     data:action.data.price_groups
                // }
            }
        }
        case dataActions.GET_TELCOM_PROVIDERS: {
            return {
                ...state,
                telcomProviders:action.data
            }
        }
        case dataActions.GET_MANUFACTURERS: {
            return {
                ...state,
                manufacturers:action.data.manufacturers
            }
        }
        case dataActions.GET_BANNERS: {
            return {
                ...state,
                banners:action.data
            }
        }
        case dataActions.GET_WIRELESSADVICES: {
            return {
                ...state,
                manufacturers:action.data.manufacturers,
                standardTypeCodes:action.data.standardTypeCodes,
                telcomProviders:{
                    telcomChildren:action.data.telcomChildren,
                    telcomParents:action.data.telcomParents
                }
            }
        }
        case dataActions.GET_GLOBAL_DATA_AUTH: {
            return {
                ...state,
                banners:action.data.banners,
                notification:{
                    count_ntf:action.data.count_ntf? action.data.count_ntf : 0,
                    cat_notice_parents:action.data.cat_notice_parents? action.data.cat_notice_parents : [],
                    cat_notice_childs:action.data.cat_notice_childs? action.data.cat_notice_childs : [],
                    cat_doc_parents:action.data.cat_doc_parents? action.data.cat_doc_parents : [],
                    cat_doc_childs:action.data.cat_doc_childs ? action.data.cat_doc_childs: [],
                    select_cat_doc_childs:action.data.select_cat_doc_childs ? action.data.select_cat_doc_childs : [],
                    select_cat_notice_childs:action.data.select_cat_notice_childs ? action.data.select_cat_notice_childs : [],
                    documents:action.data.documents ? action.data.documents : {data:[]},
                    notices:action.data.notices? action.data.notices : {data:[]},
                    notifications:action.data.notifications? action.data.notifications : {data:[]},
                    popup_ads_documents:action.data.popup_ads_documents ? action.data.popup_ads_documents : [],
                    popup_ads_notices:action.data.popup_ads_notices? action.data.popup_ads_notices : [],
                },
            }
        }

        case dataActions.UPDATE_DATA_NOTICES: {
            return {
                ...state,
                notification:{
                    ...state.notification,
                    notices:action.data.notices? action.data.notices : {data: []},
                    cat_notice_childs:action.data.cat_notice_childs? action.data.cat_notice_childs : {data: []},
                },
            }
        }

        case dataActions.UPDATE_DATA_DOCUMENTS: {
            return {
                ...state,
                notification:{
                    ...state.notification,
                    documents:action.data.documents? action.data.documents : {data: []},
                    cat_doc_childs:action.data.cat_doc_childs? action.data.cat_doc_childs : []
                },
            }
        }
        
        // case dataActions.GET_USED_PROGRAMS: {
        //     return {
        //         ...state,
        //         usedProgram:{
        //             programs:action.data.usedPrograms,
        //             membershipDiscount:[...state.usedProgram.membershipDiscount]
        //         }
        //     }
        // }

        case dataActions.APPLY_USED_PROGRAM: {
            return {
                ...state,
                usedProgram:{
                    programs: [...state.usedProgram.programs],
                    mainData: action.data
                }
            }
        }

        case dataActions.GET_NOTIFICATIONS: {
            return {
                ...state,
                notification:{
                    ...state.notification,
                    notifications:action.data.notifications ? action.data.notifications : {data: []},
                    count_ntf:action.data.count_ntf? action.data.count_ntf : 0,
                },
            }
        }
        case dataActions.LOADMORE_NOTIFICATIONS: {
            return {
                ...state,
                notification:{
                    ...state.notification,
                    notifications:{
                        ...action.data.notifications,
                        data:[...state.notification.notifications.data, ...action.data.notifications.data]
                    }
                },
            }
        }
        case dataActions.CLOSE_POPUP_ADS_NOTICE: {
            let popup_ads_notices = [...state.notification.popup_ads_notices];
            let key = popup_ads_notices.findIndex((item) => item.nt_id == action.data);
            if(key !== -1 ){
                popup_ads_notices[key].is_close = true; 
            }
            return {
                ...state,
                notification:{
                    ...state.notification,
                    popup_ads_notices:popup_ads_notices
                },
            }
        }
        case dataActions.CLOSE_POPUP_ADS_DOCUMENT: {
            let popup_ads_documents = [...state.notification.popup_ads_documents];
            let key = popup_ads_documents.findIndex((item) => item.d_id == action.data);
            if(key !== -1 ){
                popup_ads_documents[key].is_close = true; 
            }
            return {
                ...state,
                notification:{
                    ...state.notification,
                    popup_ads_documents:popup_ads_documents
                },
            }
        }

        
        default:
            return state;
    }
};

export default dataStore;
