import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {getPlanInformation , getPlanClassifications, setBookMark} from '../../../store/actions/plan_information';
import CustomizeLoading from './CustomizeLoading';
import PlanModalComponent from './PlanModalComponent';
import {priceFormat, roundUpNumberPlan} from "../../../utils/helper";
import { showLoading, hideLoading } from '../../../store/actions/ui';
import { getDisclosureSubsidy } from '../../../store/actions/plan_information';
import InfiniteScroll from 'react-infinite-scroll-component';
import {ADVICE_TYPE} from "../../../utils/constant";
class PlanInformationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab : 0 ,
            firstLoad : true,
            dataSearch : {
                searchInput : null,
                currentId : 'last',
                isBookMark: null,
            },
            showModalCustomPlan: false,
            dataCustomValue: '',
            bookMarkPlan: [],
            bookMarkPlanCustom: [],
            dataFilterAll:{
                page:1,
                currentId : 'ALL',
                telcomID  : null,
                matchID: null,
                planID: null,
                searchInput: null,
            },
            plans: {
                data:[]
            }
        };
        this.myRef = React.createRef()
    }


    /**
     * toggle modal
     */
    toggle = () => {
        this.props.toggleModal();
    }

    /**
     * close modal
     */
    closeModal = () => {
        let data = {...this.state.dataSearch};
        data.currentId = 'last';
        data.searchInput = null;
        this.setState({
            dataSearch: data,
            firstLoad : true,
            currentTab: 0,
            plans: {
                data:[]
            }
        })
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.props.showLoading();
        let activeID;
        const { WirelessAdvice, checkPlanNew } = this.props;
        if (checkPlanNew == 'planSecond') {
            if (WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && Object.keys(WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange).length > 0) {
                activeID = WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id ?
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id :
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.plac_id;
            }else{
                activeID = null;
            }
        }else if(checkPlanNew == 'planFirst'){
            activeID = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else{
            activeID = this.props.selected?.plac_id || this.props.selected?.id;
        }
        // let dataNull = [];
        let customID = WirelessAdvice ? WirelessAdvice.WirelessAdviceStep3.packageFee : null;
        // let matchID = this.props.adviceType == ADVICE_TYPE.WIRE ? null : ( WirelessAdvice ? WirelessAdvice.WirelessAdviceStep3?.phone?.matchID : null);


        // let matchID = this.props.adviceType == ADVICE_TYPE.WIRE ? null : ( WirelessAdvice && this.props.type !== "CombineDiscountPlan" ? WirelessAdvice.WirelessAdviceStep3?.phone?.matchID : null);
        // let matchID = WirelessAdvice && this.props.type !== "CombineDiscountPlan" ? WirelessAdvice.WirelessAdviceStep3?.phone?.matchID : null;
        // let matchID = this.props.type == "CombineDiscountPlan" ? null : WirelessAdvice?.WirelessAdviceStep3?.phone?.matchID;

        let matchID;

        if(this.props.type == "CombineDiscountPlan"){
            matchID = null;
        }
        else{
            matchID = WirelessAdvice?.WirelessAdviceStep3?.phone?.matchID;

            if(!matchID){
                matchID = WirelessAdvice?.WirelessAdviceStep3?.phone?.v_device?.matchID;
            }
        }

        // this.showDataPlanById(dataNull);
        // this.showDataPlanCustomById(dataNull);
    
        let telcomId = [];
        if(this.props.telcomID && this.props.telcomID.length > 0){
            telcomId = this.props.telcomID;
        }else{
            if(WirelessAdvice) telcomId.push(WirelessAdvice.WirelessAdviceStep1.telcomID);
        }
        let dataClass = {
            telcomID: telcomId,
            matchID: matchID,
            withCombineDiscount:  this.props.type == "CombineDiscountPlan" ? true : false
            // planID: activeID
        }
        if(this.props.type == "CombineDiscountPlan") dataClass.classID = []
        this.props.showLoading();
        this.props.getPlanClassifications(dataClass).then((data) => {
            this.props.showLoading();
            if (data.data.length > 0) {
                this.plansClassIfication(data.data);
                let lastId = data.data[0].id;
                let planClassID = [];
                data.data.map((items, id) => {
                    planClassID.push(items.id);
                })
                this.setState({
                    ...this.state,
                    currentTab: 'ALL',
                    dataSearch: {
                        currentId: 'ALL'
                    }
                })
                let dataFilter = {
                    'currentId' : 'ALL',
                    'telcomID'  : telcomId[0],
                    'matchID': matchID,
                    'withCombineDiscount': this.props.type == "CombineDiscountPlan" ? true : false,
                    // 'planID': activeID,
                    'page': 1
                };
                this.setState({
                    dataFilterAll: {
                        page:1,
                        currentId : 'ALL',
                        telcomID  : telcomId[0],
                        matchID: matchID,
                        withCombineDiscount: this.props.type == "CombineDiscountPlan" ? true : false,
                        // planID: activeID
                    }
                })
                this.props.showLoading();
                this.props.getPlanInformation(dataFilter).then((res) => {
                    if (res.data.data.length > 0) {
                        this.setState({
                            plans: {
                                data: res.data.data
                            },
                            dataFilterAll: {
                                ...this.state.dataFilterAll,
                                page: res.data.current_page,
                            }
                        })
                        var bookMarkPlanCustomArr = [];
                        var bookMarkPlanArr = [];
                        res.data.data.map((item, idx) => {
                            if(item.plac_id){
                                if (item.is_bookmark == true) {
                                    bookMarkPlanCustomArr.push(item.plac_id)
                                }
                            }
                            if (item.id) {
                                if (item.is_bookmark == true) {
                                    bookMarkPlanArr.push(item.id)
                                }
                            }
                        })
                        this.setState({
                            bookMarkPlanCustom: bookMarkPlanCustomArr,
                            bookMarkPlan: bookMarkPlanArr,
                        })
                    }
                })
            }
            this.props.hideLoading();
        })
        this.props.hideLoading();
    }

    fetchMoreData =  () => {
        
        this.setState({
            dataFilterAll:{
                ...this.state.dataFilterAll,
                page:this.state.dataFilterAll.page + 1
            }
        }, () => {
            this.props.showLoading();
            this.props.getPlanInformation(this.state.dataFilterAll).then((res) => {
                if (res.data.data.length > 0) {
                    this.setState({
                        plans: {
                            ...res.data.data,
                            data: [...this.state.plans.data,...res.data.data]
                        },
                        dataFilterAll: {
                            ...this.state.dataFilterAll,
                            page: res.data.current_page,
                        }
                    }, () => {
                        var bookMarkPlanCustomArr = [];
                        var bookMarkPlanArr = [];
                        this.state.plans.data.map((item, idx) => {
                            if(item.plac_id){
                                if (item.is_bookmark == true) {
                                    bookMarkPlanCustomArr.push(item.plac_id)
                                }
                            }
                            if (item.id) {
                                if (item.is_bookmark == true) {
                                    bookMarkPlanArr.push(item.id)
                                }
                            }
                        })
                        this.setState({
                            bookMarkPlanCustom: bookMarkPlanCustomArr,
                            bookMarkPlan: bookMarkPlanArr,
                        })
                    })
                    
                }
            })  
        })
        this.props.hideLoading();
    }

    /**
     * set current tab and filter by id
     * @param {*} id
     */
    setCurrentTab = (id) => {
        this.props.showLoading();
        let data = {...this.state.dataSearch};
        let telcomID = this.props.WirelessAdvice ? this.props.WirelessAdvice.WirelessAdviceStep1.telcomID : (this.props.telcomID && this.props.telcomID.length > 0 ? this.props.telcomID[0] : null);
        data.currentId = id;
        data.matchID = this.props.WirelessAdvice ? this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.device?.matchID : null;
        this.setState({
            dataFilterAll: {
                ...this.state.dataFilterAll,
                page: 1,
                currentId : id,
                telcomID: telcomID ? telcomID : this.state.telcomID,
                searchInput : null,
            },
            firstLoad : false,
            currentTab : id,
            dataSearch: {
                ...this.state.dataSearch,
                searchInput : null,
                isBookMark: null
            }
        }, () => {
            this.props.showLoading();
            this.props.getPlanInformation(this.state.dataFilterAll).then((res) => {
                if (res.data.data.length > 0) {
                    this.setState({
                        plans: {
                            data: res.data.data
                        },
                        dataFilterAll: {
                            ...this.state.dataFilterAll,
                            page: res.data.current_page,
                        }
                    })
                    var bookMarkPlanCustomArr = [];
                    var bookMarkPlanArr = [];
                    res.data.data.map((item, idx) => {
                        if(item.plac_id){
                            if (item.is_bookmark == true) {
                                bookMarkPlanCustomArr.push(item.plac_id)
                            }
                        }
                        if (item.id) {
                            if (item.is_bookmark == true) {
                                bookMarkPlanArr.push(item.id)
                            }
                        }
                    })
                    this.setState({
                        bookMarkPlanCustom: bookMarkPlanCustomArr,
                        bookMarkPlan: bookMarkPlanArr,
                    })
                }
                this.props.hideLoading();
            });
        });
        this.props.hideLoading();
    }


    /**
     * get className
     */
    getClassName = (index,id) => {
        if (this.state.firstLoad) {
            if (index == 'ALL') {
                return 'active';
            }
        } else {
            if (id == this.state.currentTab) {
                return 'active';
            }
        }
        return '';
    }


    /**
     * render element data plans
     *
     */
    plansClassIfication = (data) => {
        if (data.data && data.data.length > 0) {
            return data.data.map((item, index) => {
                return (
                    <li className="nav-item tab-item" key = {index}>
                        <a
                            className={"nav-link " + this.getClassName(index,item.id)}
                            id={item.id+'-tab'} data-toggle="tab" href={'#'+ item.id}
                            role="tab"
                            aria-controls={item.id}
                            aria-selected={index == 0 ? "true" : 'false'}
                            onClick = {this.setCurrentTab.bind(this,item.id)}
                        >
                            {item.name}
                        </a>
                    </li>
                );
            })
        }

        return '';
    }

    /***
     * show custom data value
     *
     */
    showCustomData = (item) => {
        // debugger
        // return
        this.setState({
            ...this.state,
            dataCustomValue: item,
            showModalCustomPlan: !this.state.showModalCustomPlan
        })
    }

    getPlanData = async (data) => {
        let result;
        if(this.props.type!== "CombineDiscountPlan"){
            result = await this.getDisclosureSubsidyPrice(data);
            data.disclosure_price_six_plan = result.data?.disclosure_price_six_plan || 0;
            data.disclosure_price_basic_plan = result.data?.disclosure_price_basic_plan || 0;
        }
        if(this.props.checkPlanNew && this.props.checkPlanNew == "planThird"){
            this.props.onSelectPlan(data)
        }else{            
            //삭제 필요
            //console.log("요금제 변경 시 발생하는 data값을 가져오는 부분", data);
            this.props.planResultPackage(data) 
        };
        this.props.hideLoading();
        this.toggle();
        
        // if (this.props.checkPlanNew != "planSecond") {
        //     this.getDisclosureSubsidyPrice(data)
        // }else{
        //     this.props.hideLoading();
        //     this.toggle();
        // }
        // this.toggle()
    }

    /**
     * show plan by id
     */
    showDataPlanById = (data) => {
        const { WirelessAdvice, checkPlanNew } = this.props;
        let activeID;
        let planFirstActiveId = null;

        if (checkPlanNew == 'planSecond') {
            if (WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && Object.keys(WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange).length > 0) {
                activeID = WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id ?
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id :
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.plac_id;
            }else{
                activeID = null;
            }

            planFirstActiveId = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else if(checkPlanNew == 'planFirst'){
            activeID = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else{
            activeID = this.props.selected?.plac_id || this.props.selected?.id;
        }
        if (data && data.length > 0) {
            let element = data.map((item,index) => {
                if (item.id) {
                    return(
                        <tr key = {index}
                            /*className={`${activeID == item.id ? 'active' : ''} ${this.props.type== "CombineDiscountPlan" && item.id == 2995 ? 'disabled' : ''}`}*/
                            className={`${planFirstActiveId == item.id && checkPlanNew == 'planSecond' ? 'disabled' : activeID == item.id ? 'active' : ''}`}
                        >
                            <th scope="row" className="name" onClick={this.getPlanData.bind(this, item)} >{item.name}</th>
                            <td onClick={this.getPlanData.bind(this, item)} >월정액 {priceFormat(roundUpNumberPlan(+item.cost_display + item.cost_display*0.1))}원</td>
                            <td onClick={this.getPlanData.bind(this, item)} >{item.call_time_desc}</td>
                            <td onClick={this.getPlanData.bind(this, item)} >{item.data_detail}</td>
                            <td onClick={this.getPlanData.bind(this, item)} >{item.sms_desc}</td>
                            <td className={(this.state.bookMarkPlan.indexOf(item.id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                                <button className="btn p-0 mr-2">
                                    <i className="far fa-star font-16"></i>
                                </button>
                            </td>
                        </tr>
                    );
                }
            });
            return element;
        }else{
            return '';
        }
    }

    /**
     * add bookMark plan
     * @param {*} data
     */
    handleCheckBookMark = (data) => {
        const { WirelessAdvice, auth } = this.props;
        let dataObj = {
            model : WirelessAdvice?.WirelessAdviceStep3?.phone?.modelID || null,
            uid : auth.user.id,
        };
        this.props.showLoading();
        if (data.plac_id) {
            dataObj.plac_id = data.plac_id;
            dataObj.type = 1; //plac_id
        } else {
            dataObj.id = data.id;
            dataObj.type = 0; //id
        }
        this.props.setBookMark(dataObj).then(res => {
            if (res.data.type && res.data.type == 'unCheck') {
                let arrNewCus;
                let arrNewPlan;
                if (dataObj.type == 1) {
                    arrNewCus = this.state.bookMarkPlanCustom.filter(item => item !== +res.data.plac_id)
                }else{
                    arrNewPlan = this.state.bookMarkPlan.filter(item => item !== +res.data.plan)
                }
                this.setState({
                    bookMarkPlanCustom: arrNewCus ? arrNewCus : this.state.bookMarkPlanCustom,
                    bookMarkPlan: arrNewPlan ? arrNewPlan : this.state.bookMarkPlan
                })
            }else{
                if (dataObj.type == 1) {
                    this.setState({
                        bookMarkPlanCustom: [...this.state.bookMarkPlanCustom, res.data.plac_id]
                    })
                }else{
                    this.setState({
                        bookMarkPlan: [...this.state.bookMarkPlan, res.data.plan]
                    })
                }
            }
            this.props.hideLoading();
        }).catch(err => {
        });
    }

    showDataPlanCustomById = (data) => {
        const { WirelessAdvice, checkPlanNew } = this.props;
        let activeID;
        let planFirstActiveId = null;
        if (checkPlanNew == 'planSecond') {
            if (WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && Object.keys(WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange).length > 0) {
                activeID = WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id ?
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id :
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.plac_id;
            }else{
                activeID = null;
            }

            planFirstActiveId = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else if(checkPlanNew == 'planFirst'){
            activeID = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else{
            activeID = this.props.selected?.plac_id || this.props.selected?.id;
        }
        var cost_displayA = {};
        var custom_value = {};
        var custom_value_data = [];
        var custom_value_voice = [];
        if (data && data.length > 0) {
            let element = data.map((item,index) => {
                if (item.plac_id) {
                    if (item.plan && item.plan.length > 0) {
                        if (!custom_value[item.plac_id]) {
                            custom_value[item.plac_id] = []
                        }
                        if (item.plac_custom_value) {
                            custom_value[item.plac_id].push(JSON.parse(item.plac_custom_value))
                        }
                        if (custom_value[item.plac_id][0]) {
                            if (custom_value[item.plac_id][0].data && custom_value[item.plac_id][0].data.length > 0) {
                                custom_value[item.plac_id][0].data.map((val, idx) => {
                                    if (!custom_value_data[item.plac_id]) {
                                        custom_value_data[item.plac_id] = []
                                    }
                                    custom_value_data[item.plac_id].push(parseInt(val.val));

                                    if (!custom_value_voice[item.plac_id]) {
                                        custom_value_voice[item.plac_id] = []
                                    }
                                    custom_value_voice[item.plac_id].push(parseInt(val.val));
                                })
                            }

                        }

                        if (item.plan && item.plan.length > 0) {
                            item.plan.map((cost, idx) => {
                                if (!cost_displayA[item.plac_id]) {
                                    cost_displayA[item.plac_id] = []
                                }
                                cost_displayA[item.plac_id].push(parseInt(cost.cost_display))
                            })
                        }
                        let number = '';
                        if (cost_displayA[item.plac_id]) {
                            number = cost_displayA[item.plac_id].sort(function(a, b){return b-a})
                        }

                        let number_data = '';
                        if (custom_value_data[item.plac_id]) {
                            number_data = custom_value_data[item.plac_id].sort(function(a, b){return b-a})
                        }

                        //
                        let number_voice = '';
                        if (custom_value_voice[item.plac_id]) {
                            number_voice = custom_value_voice[item.plac_id].sort(function(a, b){return b-a})
                        }
                        return(
                            <tr key = {index}
                                /*className={activeID == item.plac_id ? 'active' : ''}*/
                                className={`${planFirstActiveId == item.plac_id && checkPlanNew == 'planSecond' ? 'disabled' : activeID == item.plac_id ? 'active' : ''}`}
                            >
                                <th scope="row" className="name" onClick={this.showCustomData.bind(this, item)} >{item.plac_name}</th>
                                <td onClick={this.showCustomData.bind(this, item)} >{number ? (priceFormat(number[number.length -1]) +'~'+ priceFormat(number[0]))+ '원' : ''} </td>
                                {/* <td>
                                    {number_data ? (number_data[number_data.length - 1]+'분~'+number_data[0]+'분') : ''} /
                                    {number_voice ? (number_voice[number_voice.length - 1]+'~'+number_voice[0]) : ''}
                                </td> */}
                                <td onClick={this.showCustomData.bind(this, item)} >
                                    {item.plac_use_call ? item.plac_use_call + '/ ' : ''}
                                    {item.plac_use_data ? item.plac_use_data + '/ ' : ''}
                                    {item.plac_use_sms}
                                </td>
                                <td className={(this.state.bookMarkPlanCustom.indexOf(item.plac_id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                                    <button className="btn p-0 mr-2">
                                        <i className="far font-16 fa-star"></i>
                                    </button>
                                </td>
                            </tr>
                        );
                    }
                }
            });
            return element;
        }else{
            return '';
        }
    }

    executeScroll = () => {
        // var elmnt = document.getElementById("scroll");
        // elmnt.scrollIntoView();
        // this.myRef.current.scrollIntoView();
        // window.scrollTo(0, 500);   
    }

    showDataPlanCustomAll = (data) => {
        this.props.showLoading();
        const { WirelessAdvice, checkPlanNew } = this.props;
        let activeID;
        let planFirstActiveId = null;

        if (checkPlanNew == 'planSecond') {
            if (WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && Object.keys(WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange).length > 0) {
                activeID = WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange && WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id ?
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.id :
                    WirelessAdvice?.WirelessAdviceStep3?.dataPlanNewDateChange?.plac_id;
            }else{
                activeID = null;
            }

            planFirstActiveId = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
            WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else if(checkPlanNew == 'planFirst'){
            activeID = WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id ?
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.id :
                WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id;
        }else{
            activeID = this.props.selected?.plac_id || this.props.selected?.id;
        }

        var cost_displayA = {};
        var custom_value = {};
        var custom_value_data = [];
        var custom_value_voice = [];
        if (data && data.length > 0) {
            let element = data.map((item,index) => {
                if (item.id) {
                    return(
                        <tr key = {index}
                            /*className={activeID == item.id ? 'active' : ''}*/
                            className={`${planFirstActiveId == item.id && checkPlanNew == 'planSecond' ? 'disabled' : activeID == item.id ? 'active' : ''}`}
                            ref={activeID == item.id ? this.myRef : ''}
                            id={activeID == item.id ? 'scroll' : 'no-scroll'+index}
                        >
                            <th scope="row" className="name" onClick={this.getPlanData.bind(this, item)}>{item.name}</th>
                            <td onClick={this.getPlanData.bind(this, item)} >월정액 {priceFormat(roundUpNumberPlan(+item.cost_display + item.cost_display*0.1))}원</td>
                            <td onClick={this.getPlanData.bind(this, item)}>{item.call_time_desc}</td>
                            <td onClick={this.getPlanData.bind(this, item)}>{item.data_detail}</td>
                            <td onClick={this.getPlanData.bind(this, item)}>{item.sms_desc}</td>
                            <td className={(this.state.bookMarkPlan.indexOf(item.id) != -1) ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                                <button className="btn p-0 mr-2">
                                    <i className="far font-16 fa-star"></i>
                                </button>
                            </td>
                        </tr>
                    );
                }
                // || (item.plan_custom_sms && item.plan_custom_sms.length > 0)
                if (item.plac_id) {
                    if (item.plan && item.plan.length > 0) { 
                        if (!custom_value[item.plac_id]) {
                            custom_value[item.plac_id] = []
                        }
                        if (item.plac_custom_value) {
                            custom_value[item.plac_id].push(JSON.parse(item.plac_custom_value))
                        }
                        if (custom_value[item.plac_id][0]) {
                            if (custom_value[item.plac_id][0].data && custom_value[item.plac_id][0].data.length > 0) {
                                custom_value[item.plac_id][0].data.map((val, idx) => {
                                    if (!custom_value_data[item.plac_id]) {
                                        custom_value_data[item.plac_id] = []
                                    }
                                    custom_value_data[item.plac_id].push(parseInt(val.val));

                                    if (!custom_value_voice[item.plac_id]) {
                                        custom_value_voice[item.plac_id] = []
                                    }
                                    custom_value_voice[item.plac_id].push(parseInt(val.val));
                                })
                            }

                        }

                        if (item.plan && item.plan.length > 0) {
                            item.plan.map((cost, idx) => {
                                if (!cost_displayA[item.plac_id]) {
                                    cost_displayA[item.plac_id] = []
                                }
                                cost_displayA[item.plac_id].push(parseInt(cost.cost_display))
                            })
                        }
                        let number = '';
                        if (cost_displayA[item.plac_id]) {
                            number = cost_displayA[item.plac_id].sort(function(a, b){return b-a})
                        }

                        let number_data = '';
                        if (custom_value_data[item.plac_id]) {
                            number_data = custom_value_data[item.plac_id].sort(function(a, b){return b-a})
                        }

                        //
                        let number_voice = '';
                        if (custom_value_voice[item.plac_id]) {
                            number_voice = custom_value_voice[item.plac_id].sort(function(a, b){return b-a})
                        }
                        return(
                            <tr key = {index}
                                /*className={activeID == item.plac_id ? 'active' : ''}*/
                                className={`${planFirstActiveId == item.plac_id && checkPlanNew == 'planSecond' ? 'disabled' : activeID == item.plac_id ? 'active' : ''}`}
                            >
                                <th scope="row" className="name" width="20" onClick={this.showCustomData.bind(this, item)}>{item.plac_name}</th>
                                <td  width="20" onClick={this.showCustomData.bind(this, item)}>{number ? (priceFormat(number[number.length -1]) +'~'+ priceFormat(number[0]))+ '원' : ''} </td>
                                {/* <td>
                                    {number_data ? (number_data[number_data.length - 1]+'분~'+number_data[0]+'분') : ''} /
                                    {number_voice ? (number_voice[number_voice.length - 1]+'~'+number_voice[0]) : ''}
                                </td> */}
                                <td  onClick={this.showCustomData.bind(this, item)} width="30">
                                    {item.plac_use_call ? item.plac_use_call + '/ ' : ''}
                                    {item.plac_use_data ? item.plac_use_data + '/ ' : ''}
                                    {item.plac_use_sms}
                                </td >
                                <td  width="20" onClick={this.showCustomData.bind(this, item)}></td>
                                <td  width="20" onClick={this.showCustomData.bind(this, item)}></td>
                                <td  width="10" className={(this.state.bookMarkPlanCustom.indexOf(item.plac_id) != -1)  ? 'check text-center' : 'text-center'} onClick={this.handleCheckBookMark.bind(this, item)}>
                                    <button className="btn p-0 mr-2">
                                        <i className="far font-16 fa-star"></i>
                                    </button>
                                </td>
                            </tr>
                        );
                    }
                }
            });
            this.props.hideLoading();
            // this.executeScroll();
            return element;
        }

    }

    /**
     * handle change input
     */
    handleChange = (e) => {
        let newCurrentId = '';
        if (this.props.ificationPlan && this.props.ificationPlan.data.length > 0 && this.props.ificationPlan.data[0] && this.state.currentId == 'last') {
            newCurrentId = this.props.ificationPlan.data[0].id;
        }else{
            newCurrentId = this.state.dataSearch.currentId
        }
        this.setState({
            ...this.state,
            dataSearch : {
                ...this.state.dataSearch,
                searchInput : e.target.value,
                currentId : newCurrentId,
            },
            dataFilterAll: {
                ...this.state.dataFilterAll,
                searchInput : e.target.value,
            }
        })
    }
    /**
     * enter KeyPressed
     * @param {*} e
     */
    enterPressed = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13) {
            this.handleSearch(e);
        }
    }

    /**
     * is book mark search
     *
     */
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            ...this.state,
            dataSearch: {
                ...this.state.dataSearch,
                isBookMark: value ? value : null
            }
        }, () => {
            this.handleSearch();
        });
    }
    /**
     * handle Search
     */
    handleSearch = () => {
        this.props.showLoading();
        let data = {...this.state.dataSearch};
        // data.isBookMark = this.state.dataSearch.isBookMark;
        data.telcomID = this.props.telcomID ? this.props.telcomID : this.props.WirelessAdvice.WirelessAdviceStep1.telcomID;
        data.matchID = this.state.dataFilterAll.matchID;
        data.currentId = this.state.dataFilterAll.currentId;
        data.withCombineDiscount =  this.props.type == "CombineDiscountPlan" ? true : false
        this.props.getPlanInformation(data).then((res) => {
            this.setState({
                plans: {
                    data: res.data.data
                },
                dataFilterAll: {
                    ...this.state.dataFilterAll,
                    page: res.data.current_page,
                }
            }, () => {
                var bookMarkPlanCustomArr = [];
                    var bookMarkPlanArr = [];
                    res.data.data.map((item, idx) => {
                        if(item.plac_id){
                            if (item.is_bookmark == true) {
                                bookMarkPlanCustomArr.push(item.plac_id)
                            }
                        }
                        if (item.id) {
                            if (item.is_bookmark == true) {
                                bookMarkPlanArr.push(item.id)
                            }
                        }
                    })
                    this.setState({
                        bookMarkPlanCustom: bookMarkPlanCustomArr,
                        bookMarkPlan: bookMarkPlanArr,
                    })
            })
            this.props.hideLoading();
        });
    }

    hideModalPlanCustom = (data) => {
        this.setState({
            showModalCustomPlan: false
        }, () => {
            if (data == 'modalCustom') {
                setTimeout(() => {
                    this.toggle();
                }, 200);
            }
        })
    }

    planCustomResult = async (data) => {
        if(this.props.type!== "CombineDiscountPlan"){
            //this.getDisclosureSubsidyPrice(data.planId ? data.planId : '');
            //console.log("tytolee data", data);
            let result = await this.getDisclosureSubsidyPrice(data.planId);
            //console.log("tytolee result", result);
            data.disclosure_price_six_plan = result.data?.disclosure_price_six_plan || 0;
            data.disclosure_price_basic_plan = result.data?.disclosure_price_basic_plan || 0;
            //console.log("tytolee data 2", data);
        }

        if(this.props.checkPlanNew && this.props.checkPlanNew == "planThird"){
            this.props.onSelectPlan(data, 'custom')
        }else{
            this.props.planCustomResultPackage(data)
        };
        // this.getDisclosureSubsidyPrice(data.planId ? data.planId : '');
        this.props.hideLoading();
        this.toggle();
        // if (this.props.checkPlanNew != "planSecond") {
        //     this.getDisclosureSubsidyPrice(data.planId ? data.planId : '')
        // }else{
        //     this.props.hideLoading();
        //     this.toggle();
        // }
        
        // setTimeout(() => {
        //     this.toggle();
        // }, 200);
    }

    handleUnapply = () => {
        let data = {};

        if(this.props.checkPlanNew && this.props.checkPlanNew == "planThird"){
            this.props.onSelectPlan(data)
        }else{
            this.props.planCustomResultPackage(data)
            this.props.planResultPackage(data);
        };
        this.toggle()
    }


    getDisclosureSubsidyPrice = async (value) => {
        this.props.showLoading();
        let data = {
          'planID' : value.id ? value.id : null,
          'modelID' : this.props.WirelessAdvice?.WirelessAdviceStep3?.phone?.modelID || this.props.WirelessAdvice?.WirelessAdviceStep3?.phone?.device_model_price?.modelID,
        }        
        let result = await this.props.getDisclosureSubsidy(data);
        let disclosureSubsidyDifference;
        let telcomID = this.props.WirelessAdvice.WirelessAdviceStep3.phone.telcomID;

        if (result.status == 1) {
            let conversionPrice         = result.data?.conversion_price ? result.data.conversion_price : 0;
            let manualConversionConfirm = result.data?.manual_conversion_confirm ? result.data.manual_conversion_confirm : 0;
            let conversionPriceInput    = +manualConversionConfirm ? 0 : +conversionPrice;

            if (this.props.checkPlanNew != "planSecond") {
                let disclosureSubsidyType   = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyType;
                let resultDisclosureSubsidy = result.data?.disclosure_price ? result.data.disclosure_price : 0;
                
                if(telcomID == 18){
                    let resultDisclosurePriceBasicPlan = result.data?.disclosure_price_basic_plan ? result.data.disclosure_price_basic_plan : 0;
                    let resultDisclosurePriceSixPlan   = result.data?.disclosure_price_six_plan ? result.data.disclosure_price_six_plan : 0;

                    this.props.change('WirelessAdviceStep3.disclosureSubsidyBasicPlan', resultDisclosurePriceBasicPlan);
                    this.props.change('WirelessAdviceStep3.disclosureSubsidySixPlan', resultDisclosurePriceSixPlan);

                    if(disclosureSubsidyType == "six"){
                        resultDisclosureSubsidy = resultDisclosurePriceSixPlan;
                    }
                    else{
                        resultDisclosureSubsidy = resultDisclosurePriceBasicPlan;
                    }
                }

                if(
                    this.props.WirelessAdvice?.WirelessAdviceStep3?.optionFee != 0 &&
                    +this.props.WirelessAdvice.WirelessAdviceStep3.manual_conversion_confirm != 1
                ){
                    if(+this.props.WirelessAdvice.WirelessAdviceStep3.conversion_price_input > 0){
                        alert('전환지원금이 변경됩니다.');
                    }
                    else{
                        conversionPriceInput = 0;
                    }
                }
    
                this.props.change('WirelessAdviceStep3.disclosureSubsidy',          resultDisclosureSubsidy);
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault',   resultDisclosureSubsidy);
                this.props.change('WirelessAdviceStep3.displayApplyDate',           result.data?.apply_date ? result.data.apply_date : null);
                this.props.change('WirelessAdviceStep3.conversion_price',           +conversionPrice);
                this.props.change('WirelessAdviceStep3.conversion_apply_date',      result.data?.conversion_apply_date ? result.data.conversion_apply_date : null);
                this.props.change('WirelessAdviceStep3.manual_conversion_confirm',  +manualConversionConfirm);
                this.props.change('WirelessAdviceStep3.conversion_price_input',     conversionPriceInput);
                this.props.change('WirelessAdviceStep3.conversion_price_input_add', 0);

                if (this.props.WirelessAdvice?.WirelessAdviceStep3?.optionFee == 0) {
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                    this.props.change('WirelessAdviceStep3.converseDifference', null);
                }
                else{
                    if (result?.data?.disclosure_price) {
                        disclosureSubsidyDifference = (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault);
                    }
                    else {
                        disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;
                    }

                    if (this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault) {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
                    }
                    else{
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                    }

                    let converseDifference        = conversionPriceInput;
                    let converseDifferenceDefault = this.props.WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidyDifferenceDefault ? +this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDifferenceDefault : null;

                    if((conversionPriceInput > 0) && (converseDifferenceDefault != null)){
                        converseDifference = converseDifference - converseDifferenceDefault;
                    }
                    else if(converseDifferenceDefault == null){
                        converseDifference = null;
                    }

                    this.props.change('WirelessAdviceStep3.converseDifference', converseDifference);
                }
            }
            else {
                this.props.change('WirelessAdviceStep3.converseDifferenceDefault', conversionPriceInput);

                if (this.props.WirelessAdvice?.WirelessAdviceStep3?.optionFee == 0) {
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', result?.data?.disclosure_price || 0);
                }
                else{
                    if (result?.data?.disclosure_price) {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', result?.data?.disclosure_price || 0);
                        disclosureSubsidyDifference = (+this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) - (+result?.data?.disclosure_price);
                    }
                    else {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', 0);
                        disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
                    }
                    
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
                    this.props.change('WirelessAdviceStep3.converseDifference', +this.props.WirelessAdvice.WirelessAdviceStep3.conversion_price_input - conversionPriceInput);
                }
            }
        }else{
            if (this.props.checkPlanNew != "planSecond") {
                this.props.change('WirelessAdviceStep3.disclosureSubsidy', 0);
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', 0);
                this.props.change('WirelessAdviceStep3.displayApplyDate', null);

                if(telcomID == 18){
                    this.props.change('WirelessAdviceStep3.disclosureSubsidyBasicPlan', 0);
                    this.props.change('WirelessAdviceStep3.disclosureSubsidySixPlan',   0);
                }
            }else{
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDifferenceDefault', 0);
                disclosureSubsidyDifference = this.props.WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy;
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', disclosureSubsidyDifference);
                this.props.change('WirelessAdviceStep3.converseDifferenceDefault', 0);
                this.props.change('WirelessAdviceStep3.converseDifference', +this.props.WirelessAdvice.WirelessAdviceStep3.conversion_price_input);
            }
        }        

        this.props.change('WirelessAdviceStep3.disclosureSubsidy_base', this.props.WirelessAdvice.WirelessAdviceStep3?.disclosureSubsidy || 0);

        return result;
        this.toggle();
        this.props.hideLoading();
        
    }

    componentWillUnmount = () => {
        this.setState({
            plans: {
                data:[]
            }
        })
    }

    render() {
        let plans = this.state.plans.data;
        let ificationPlan = this.props.ificationPlan || [];
        const { WirelessAdvice } = this.props;
        // let customID = WirelessAdvice.WirelessAdviceStep3.packageFee;
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-xl modal-list-plan modal-dialog-centered"
                       contentClassName="pl-5 pr-5"
                       toggle = {this.toggle}
                       onOpened = {this.onOpened}
                       onClosed = {this.closeModal}
                       scrollable = {true}
                       // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title text-left w-100">요금제 선택</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className="modal-body content-product mt-0 pt-1 pl-0 pr-0">
                        <div className="left-product">
                            <div className="top" style={{minHeight: '46px'}}>
                            {
                                ificationPlan.data && ificationPlan.data.length > 0 ? (
                                <ul className="nav nav-tabs list-tab w-100" id="myTab" role="tablist">
                                    <li className="nav-item tab-item">
                                        <a
                                            className = {"nav-link cursor " + this.getClassName('ALL','ALL') }
                                            role="tab"
                                            onClick = {this.setCurrentTab.bind(this,'ALL')}
                                        >
                                            {'전체'}
                                        </a>
                                    </li>
                                    {this.plansClassIfication(ificationPlan)}
                                    {
                                        // TASK 7015 - 2021/05/17
                                        //
                                        // - Tên menu: Tư vấn không dây- Chọn gói phí tablet (Toàn bộ nhà mạng)
                                        // - Loại hình: Bug
                                        // - Nội dung: Lỗi hiển thị gói phí LHPH hiển thị tại thiết bị không phải là mobile như 태블릿 (Tablet) , 포켓 (pocket Wi-Fi), (에그) egg, 워치 (watch),...
                                        // Vì những thiết bị 태블릿 (Tablet) , 포켓 (pocket Wi-Fi), (에그) egg, 워치 (watch),... không phải là mobile nên không được cho hiển thị gói phí LHPH
                                        // - Ngày yc hoàn thành: 05/18
                                        ![38, 39, 40].includes(+WirelessAdvice?.WirelessAdviceStep3?.phone?.device_type) && (<li className="nav-item tab-item">
                                            <a
                                                className = {"nav-link cursor " + this.getClassName('customized','customized') }
                                                role="tab"
                                                onClick = {this.setCurrentTab.bind(this,'customized')}
                                            >
                                                {'맞춤형'}
                                            </a>
                                        </li>)
                                    }

                                </ul>
                                    ) : ''
                                }
                                    

                            </div>

                            <div className="row top-search-query">
                                <div className="col-md-6">
                                    <div className="form-search">
                                        <input className="form-control mr-sm-2"
                                               type="search"
                                               ref={el => this.inputSearch = el}
                                               placeholder="검색어를 입력해 주세요"
                                               onChange = {this.handleChange}
                                               onKeyPress={this.enterPressed}
                                               value={this.state.dataSearch.searchInput ? this.state.dataSearch.searchInput : '' }
                                               aria-label="Search"
                                        />
                                        <button className="btn my-2 my-sm-0" type="submit">
                                            <img src="/images/icon-search.svg" alt="" onClick={this.handleSearch}/>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="group-check justify-content-end">
                                        <div className="form-group form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox"
                                                       className="custom-control-input"
                                                       id="customControlAu122"
                                                       checked={this.state.dataSearch.isBookMark}
                                                       onChange={this.handleInputChange}/>
                                                <label className="custom-control-label" htmlFor="customControlAu122">즐겨찾기 </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {
                                this.props.checkUnApply ?  <div className="row">
                                        <div className="col-md-6">
                                            <button className="btn btn-submit pl-3 pr-3" type="button" onClick={this.handleUnapply}>UnApply</button>
                                        </div>
                                    </div> : ''
                            } */}

                            <div className="tab-content" id="myTabContendt">
                                <div className="fade show" >
                                    <article className="table-form" id="scrollableDiv" style={{maxHeight: '405px', overflowX: 'auto'}}>
                                    {plans.length > 0 && <InfiniteScroll
                                        dataLength={plans.length}
                                        next={()=>this.fetchMoreData()}
                                        hasMore={plans.length > 0 || false}
                                        loader={<></>}
                                        scrollableTarget={`scrollableDiv`}
                                    >
                                        <table className="table table-striped">
                                            <tbody>
                                            {this.props.checkUnApply ?
                                                <tr onClick={this.handleUnapply}>
                                                    <td
                                                        colspan={this.state.currentTab == 'customized' ? "3" : "5"}
                                                        style={{textAlign: 'left !important'}}>
                                                        미적용
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                : ''
                                            }

                                            {
                                                this.state.currentTab == 'customized' ? this.showDataPlanCustomById(plans) :
                                                    this.state.currentTab == 'ALL' ? this.showDataPlanCustomAll(plans) :
                                                        this.showDataPlanById(plans)
                                            }
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>}
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal> 
                <PlanModalComponent
                    showModalCustomPlan={this.state.showModalCustomPlan}
                    hideModalPlanCustom={this.hideModalPlanCustom}
                    dataCustomValue={this.state.dataCustomValue}
                    planCustomResult = {this.planCustomResult}
                    planCustomDateNew = {this.props.checkPlanNew ? true : false}
                    planData={this.props?.planData}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth,
        plans : state.plan.dataPlans,
        ificationPlan : state.plan.ificationPlan,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),

    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPlanInformation: (data) => dispatch(getPlanInformation(data)),
        setBookMark: (data) => dispatch(setBookMark(data)),
        getPlanClassifications: (data) => dispatch(getPlanClassifications(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getDisclosureSubsidy: (data) => dispatch(getDisclosureSubsidy(data)),
    };
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    );

export default compose(
    withConnect,
    withReduxForm,
  )(PlanInformationModal);
