import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Field} from "redux-form";
import renderByFormatField from "./index";
import renderTextField from "../TextField";

const renderRBDeduct = ({ fields }) => (
    <Fragment>
        {fields.map((data, index) => (
            <div className="row">
                <div className="col-3"></div>
                <div className="col-3">
                    <div className="form-group ">
                        <label htmlFor="" className="label-text">차감 R/B ({index + 1})</label>
                        <Field type="text"
                               component={renderByFormatField}
                               name={`${data}.price`}
                               placeholder=" 0 원"
                               className="form-control color-orange h-44" />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group ">
                        <label htmlFor="" className="label-text">R/B 내역 메모</label>
                        <Field type="text" className="form-control h-44"
                               component={renderTextField}
                               name={`${data}.memo`}
                               placeholder=" R/B 내역 메모" />
                    </div>
                </div>
            </div>
        ))}


        <div className="row mb-4">
            <div className="col-3">
            </div>
            <div className="col-9">
                <div className="row">
                    <div className="col-4">
                        <button className="btn btn-add" type={"button"} onClick={() => fields.push({})}><span>+</span>차감 R/B ({fields.length + 1})</button>
                    </div>
                    <div className="col-12">
                        <div className="border-bottom-dashed w-100  pb-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
);

renderRBDeduct.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderRBDeduct;
