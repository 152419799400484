import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {Modal} from 'reactstrap';
import CurrencyInput from 'react-currency-input';
import { priceFormat } from '../../utils/helper';

const ModalOther = ({isOpenModal, toggle, onSelected, installmentPrincipal, other}) => {
  const [value, setValue] = useState(0)
  const [maxValue, setmaxValue] = useState(0)

  useEffect(() => {
    setmaxValue(+installmentPrincipal + +value)
  }, [installmentPrincipal])

  useEffect(() => {
    setValue(+other)
  }, [other])

  const onSubmit = (e) => {
    e.preventDefault()
    if((+value) > maxValue){
      alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
      return;
    }
    onSelected(value)
  }
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      fade={true}
      // backdrop={"static"}
      className={'modal-dialog modal-dialog-centered modal-custom'}
    >
      <div>
        <div className="modal-header">
          <h5 className="modal-title">기타할인 입력</h5>
          <button type="button" className="close close-modal" onClick={toggle}>
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
        </div>
        <div className="modal-body px-0">
          <form onSubmit={onSubmit} className="body-plans row_custom">
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 text-modal-gray mt-6">할부원금: {priceFormat(maxValue)}원</p>
            </div>
            <div className="form-group row mt-4 align-items-center">
              <div className="col-10 pr-0">
                <CurrencyInput
                  precision="0"
                  onChangeEvent={(event, maskedvalue, floatvalue) => setValue(floatvalue)}
                  className="form-control h-44"
                  value={value}
                />
              </div>
              <div className="col-2">
                <span className="text-wrapper-gray">원</span>
              </div>
            </div>
            <h5 className="text-wrapper-gray text-left color-orange mb-3">
              ※할부원금을 초과한 금액을 입력하실 수 없습니다.
            </h5>
            <h5 className="text-wrapper-gray text-left">
              입력한 금액을 적용하시겠습니까?
            </h5>
            <div className="modal-footer justify-content-center border-top-0 py-0 mt-5">
              <button type="button" className="btn btn-gray" onClick={toggle}>취소</button>
              <button type="submit" className="btn btn-submit">확인</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

ModalOther.propTypes = {
  onSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  installmentPrincipal: PropTypes.number.isRequired,
};

export default ModalOther;

