import PropTypes from 'prop-types';
import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import Tooltip from "../../../components/Tooltip";
import WirelessFilter from '../../../components/Wireless/WirelessFilter';
import WirelessCompareProduct from '../../../components/Wireless/WirelessCompareProduct';
import WirelessProductInfo from '../../../components/Wireless/WirelessProductInfo';
import WirelessOptionFee from '../../../components/Wireless/WirelessOptionFee';
import WirelessDiscount from '../../../components/Wireless/WirelessDiscount';
import WirelessTotalPerMonth from '../../../components/Wireless/WirelessTotalPerMonth';
import WirelessSubTotalPerMonth from '../../../components/Wireless/WirelessSubTotalPerMonth';
import WirelessOptionSim from '../../../components/Wireless/WirelessOptionSim';
import WirelessExtraService from '../../../components/Wireless/WirelessExtraService';
import PhoneInsurance from "../../../components/Wireless/PhoneInsurance/PhoneInsurance";
import WirelessNote from './../../../components/Wireless/Note/Note'
import Step3Disable from "./../../../components/Wireless/ShareWireless/ShareWirelessMain";
import CombinedResultList from "./../../../components/Wireless/CombinedDiscount/ResultList";
import { LIST_2G } from './../../../utils/constant'
import SwiperSlideWirelessComponent from "../../Compare/SwiperSlideWirelessComponent";
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../../store/actions/modal';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import api from "../../../utils/api";
import { priceFormat, roundUpNumber } from './../../../utils/helper'

class WirelessStep3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            left: 0,
            width: 0,
            deviceActive:{}
        }
        this.myRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.changeWindow);
        window.addEventListener('resize', this.changeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.changeWindow);
        window.removeEventListener('resize', () => this.changeWindow);
    }

    changeWindow = () => {
        if (this.myRef && this.myRef.current) {
            let widthRight = this.myRef.current.offsetWidth - 30
            let widthBody = document.body.clientWidth
            let left = ((widthBody - 3 * widthRight) / 2) + 30 + 2 * widthRight;

            if (this.myRef.current.getBoundingClientRect().top <= 0) {
                this.setState({
                    left: left,
                    width: widthRight,
                    isSticky: true
                })
            } else {
                this.setState({
                    left: 0,
                    isSticky: false
                })
            }
        }
    }

    showPopupDetailDevice = () => {
        if(this.state.deviceActive?.id) {
            let xhtml = (
                <div className="modal-body">
                    <div className="content-detail right-product">
                        <div className="left-detail">
                            <div className="list-detail">
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>색상</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.name}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>출시일</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.release_date}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>CPU</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.cpu}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>RAM</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.memory}GB</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>메모리</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.int_storage} {this.state.deviceActive?.ext_storage ? `, ${this.state.deviceActive?.ext_storage}` : ""}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>디스플레이</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.screen_size} 인치 {this.state.deviceActive?.disp_info}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>카메라</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.front_cam ? ('전면' + this.state.deviceActive?.front_cam) : ""}</span>
                                        <span>{this.state.deviceActive?.back_cam ? ('후면' + this.state.deviceActive?.back_cam) : ""}</span>
                                    </div>
                                </div>
                                <div className="row row-detail">
                                    <div className="col-4">
                                        <label>가타</label>
                                    </div>
                                    <div className="col-8">
                                        <span>{this.state.deviceActive?.battery}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom">
                                <h5>기타정보</h5>
                                <div className="box-scroll" dangerouslySetInnerHTML={{ __html: this.state.deviceActive?.extra_desc }}>
    
                                </div>
                            </div>
                        </div>
                        <div className="right-detail">
                            <div className="slide-color-product">
                                <SwiperSlideWirelessComponent
                                    devices={this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.color}
                                    onClickSelectedColorActive={this.onClickSelectedColorActive}
                                    
                                    navigation={true}
                                    indexActive={1}
                                />
                            </div>
                            <ul className="list-feature">
                                {this.state.deviceActive?.device_special_feature.map((item, i) =>
                                    <Fragment key={i}>
                                        <li >
                                            {
                                                item.pivot && item.pivot.opt_value ? (
                                                    <Tooltip message={item.pivot.opt_value}
                                                        position={'top'}
                                                    >
                                                        {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                            <div className="text-feature" style={{ backgroundColor: `${item.sf_type_content.backgroundColor}`, color: `${item.sf_type_content.color}` }} >
                                                                <span >
                                                                    {item.sf_name}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </Tooltip>
                                                ) : (
                                                        <Fragment>
                                                            {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                                <div className="text-feature" style={{ backgroundColor: `${item.sf_type_content.backgroundColor}`, color: `${item.sf_type_content.color}` }} >
                                                                    <span >
                                                                        {item.sf_name}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </Fragment>
                                                    )
                                            }
                                        </li>
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            );
            let xhtmlFooter = (
                <button type="button" className="btn btn-submit" onClick={() => { setTimeout(() => {this.props.hideModal()}) }}>적용</button>
            );
    
            let dataModal = {
                toggle: true,
                close: true,
                class_n: 'modal-dialog-centered modal-xl detail-step-two',
                component: xhtml,
                html_title: this.state.deviceActive?.name,
                html_footer: xhtmlFooter,
            }
            this.props.showModal(dataModal);
        } else if(this.props.WirelessAdvice.WirelessAdviceStep3?.phone.deviceID) { 
            this.props.showLoading()
            api.advice.getDetailDevice(this.props.WirelessAdvice.WirelessAdviceStep3?.phone.deviceID).then(res => {
                this.setState({
                    deviceActive:res.data.data.device,
                }, () => {
                    this.props.hideLoading()
                    let xhtml = (
                        <div className="modal-body">
                            <div className="content-detail right-product">
                                <div className="left-detail">
                                    <div className="list-detail">
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>색상</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.name}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>출시일</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.release_date}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>CPU</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.cpu}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>RAM</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.memory}GB</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>메모리</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.int_storage} {this.state.deviceActive?.ext_storage ? `, ${this.state.deviceActive?.ext_storage}` : ""}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>디스플레이</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.screen_size} 인치 {this.state.deviceActive?.disp_info}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>카메라</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.front_cam ? ('전면' + this.state.deviceActive?.front_cam) : ""}</span>
                                                <span>{this.state.deviceActive?.back_cam ? ('후면' + this.state.deviceActive?.back_cam) : ""}</span>
                                            </div>
                                        </div>
                                        <div className="row row-detail">
                                            <div className="col-4">
                                                <label>가타</label>
                                            </div>
                                            <div className="col-8">
                                                <span>{this.state.deviceActive?.battery}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h5>기타정보</h5>
                                        <div className="box-scroll" dangerouslySetInnerHTML={{ __html: this.state.deviceActive?.extra_desc }}>
            
                                        </div>
                                    </div>
                                </div>
                                <div className="right-detail">
                                    <div className="slide-color-product">
                                        <SwiperSlideWirelessComponent
                                            devices={this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.color}
                                            onClickSelectedColorActive={this.onClickSelectedColorActive}
                                            showTitle={true}
                                            navigation={true}
                                            indexActive={1}
                                        />
                                    </div>
                                    <ul className="list-feature">
                                        {this.state.deviceActive?.device_special_feature.map((item, i) =>
                                            <Fragment key={i}>
                                                <li >
                                                    {
                                                        item.pivot && item.pivot.opt_value ? (
                                                            <Tooltip message={item.pivot.opt_value}
                                                                position={'top'}
                                                            >
                                                                {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                                    <div className="text-feature" style={{ backgroundColor: `${item.sf_type_content.backgroundColor}`, color: `${item.sf_type_content.color}` }} >
                                                                        <span >
                                                                            {item.sf_name}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Tooltip>
                                                        ) : (
                                                                <Fragment>
                                                                    {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                                                        <div className="text-feature" style={{ backgroundColor: `${item.sf_type_content.backgroundColor}`, color: `${item.sf_type_content.color}` }} >
                                                                            <span >
                                                                                {item.sf_name}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </Fragment>
                                                            )
                                                    }
                                                </li>
                                            </Fragment>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    );
                    let xhtmlFooter = (
                        <button type="button" className="btn btn-submit" onClick={() => { setTimeout(() => {this.props.hideModal()}) }}>적용</button>
                    );
            
                    let dataModal = {
                        toggle: true,
                        close: true,
                        class_n: 'modal-dialog-centered modal-xl detail-step-two',
                        component: xhtml,
                        html_title: this.state.deviceActive?.name,
                        html_footer: xhtmlFooter,
                    }
                    this.props.showModal(dataModal);
                })
            }).catch(err => {
                this.props.hideLoading();
            })
            
        }
        
    }

    onClickSelectedColorActive = (item) => {
        setTimeout(() => {
            this.props.change('WirelessAdviceStep2.color', item);
        })
    }

    getResultBlockC = () => {
        const { WirelessAdvice } = this.props;
        let result = WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyOption || 0;

        // if (WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_id) {
        //     let ets_price = WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_price || 0;
        //     result += ets_price
        // }
        let moreFeeApplyUsedProgram = WirelessAdvice?.WirelessAdviceStep3?.applyUsedProgram || 0
        result += moreFeeApplyUsedProgram

        return priceFormat(result);
    }

    render() {
        const { WirelessAdvice } = this.props
        const { setPage } = this.props;
        const { left, width } = this.state
        let style = {}
        if (left) {
            style.left = left
        }
        if (width) {
            style.width = width
        }

        return (
            <FormSection name="WirelessAdviceStep3">
                <WirelessFilter setPage={setPage} />
                <div id="divcontents">
                    {WirelessAdvice.WirelessAdviceStep3.reportCreated ? (
                        <Step3Disable data={{
                            ...WirelessAdvice.WirelessAdviceStep3,
                            color: WirelessAdvice.WirelessAdviceStep2.color,
                            conversion_2g_text: LIST_2G[(WirelessAdvice.WirelessAdviceStep3?.conversion2G?.id || 0)],
                            estimated_rates_changing_plans: WirelessAdvice.WirelessAdviceStep3?.estimatedRatesChangingPlans,
                            plan_change: WirelessAdvice.WirelessAdviceStep3?.dataPlanNewDateChange,
                        }} />
                    ) : (
                            <article className="comparative-information">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="left-information total-number">
                                            <div style={{"textAlign" : "left"}}>
                                                <span class="text-content font-13">모비셀서비스는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.</span>
                                            </div>
                                            <WirelessCompareProduct />
                                            <WirelessProductInfo
                                                setPage={this.props.setPage}
                                                showPopupDetailDevice={this.showPopupDetailDevice}
                                            />
                                            <WirelessOptionFee />
                                            <WirelessDiscount 
                                                handleShowModalCombineDiscount={this.props.handleShowModalCombineDiscount}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-4" ref={this.myRef}>
                                        <div
                                            className={`right-information total-number border-bottom-0${this.state.isSticky ? ' right-fixed' : ''}`}
                                            style={style}
                                        >
                                            <WirelessTotalPerMonth />
                                        </div>
                                    </div>
                                </div>
                                <div className="row grape-record">
                                    <div className="col-md-8">
                                        <WirelessSubTotalPerMonth />

                                        <div className="row mt-0">
                                            <div className="col-md-4">
                                                <h4 className="label-text text-bold text-16 color-blue mt-3">
                                                    {/*보험 / 유심*/}
                                                </h4>
                                                <WirelessOptionSim />
                                                <PhoneInsurance />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 className="label-text text-bold text-16 color-blue mt-3">
                                                        {/* // 부가서비스 */}
                                                    </h4>
                                                </div>
                                                <WirelessExtraService />
                                            </div>
                                        </div>

                                        {
                                            // WirelessAdvice?.WirelessAdviceStep3?.usedPhoneCompensation?.ets_id && (
                                                <React.Fragment>
                                                    <div className="d-flex justify-content-end mt-3 mb-1">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <div>
                                                        <span className="text-bold">월 부가서비스 요금</span>:(VAT포함) &nbsp;
                                                        <span className="prices-blue">
                                                            {
                                                                this.getResultBlockC()
                                                            }
                                                        </span>
                                                        <span className="text-bold">원 </span>
                                                        </div>
                                                        <span>*유심비, 가입비 제외</span>
                                                    </div>
                                                    </div>
                                                </React.Fragment>
                                            // )
                                        }

                                        <div className="total-price-bottom">
                                            <div className="left-total">
                                                <h2>월 납부액 (A+B+C)</h2>
                                                {/* <p className="label-prices mb-1 mt-2">월별 청구금액</p> */}
                                                <span className="small-color">(VAT 포함)</span>
                                            </div>
                                            <div className="right-total">
                                                <p className="total-big">
                                                    {
                                                        priceFormat(WirelessAdvice?.WirelessAdviceStep3?.amountMonthlyTotal)
                                                    }
                                                <span>원</span></p>
                                            </div>
                                        </div>
                                        {
                                            WirelessAdvice.WirelessAdviceStep3?.combineCondition && <div className={'line-top pt-2'}><CombinedResultList /></div>
                                        }
                                        <WirelessNote />
                                    </div>
                                </div>
                                
                            </article>
                        )}
                </div>
            </FormSection>
        );
    }
}

WirelessStep3.propTypes = {
    setPage: PropTypes.func,
    WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        changeModalTitle: (data) => dispatch(changeModalTitle(data)),
        changeModalContent: (data) => dispatch(changeModalContent(data)),
        change: () => dispatch(change()),
    }
};
const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessStep3);
