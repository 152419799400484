import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class Statistical extends Component {
  componentDidMount() {
    
  }


  render() {
    return (
        <Fragment>
            <section>
                <div className="container-fluid">
                    <div className="sales_report">
                        <div className="fill_search">
                            <form action="">
                                <div className="row d-flex justify-content-between align-items-center mb-4">
                                    <div className="col-md-4 d-flex align-items-center">
                                        <a href="" className="previous"><i className="fas fa-angle-left"></i></a>
                                        <div className="dropdown mx-2">
                                            <a className="btn btn_blue dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                May, 2020
                                            </a>
                                        </div>
                                        <a href="" className="next"><i className="fas fa-angle-right"></i></a>
                                    </div>
                                    <div className="col-md-auto">
                                        <div className="form-date">
                                            <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-calender.svg"} alt=""/>
                                            <input type="text" className="form-control h-44" placeholder="2020/05/15"/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-group">
                                            <input className="form-control search_box bg-transparent h-44" type="search" placeholder="고객명/ 전화번호"/>
                                            <span className="input-group-append">
                                                <div className="input-group-text border-0 bg-transparent ml-n5"><i className="fa fa-search"></i></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end">
                                    <div className="col-md-auto">
                                        <select className="form-control select-place h-44">
                                            <option value="">단말기 전체</option>
                                        </select>
                                    </div>
                                    <div className="col-md-auto">
                                        <select  className="form-control select-place h-44">
                                            <option value="">통신사 전체</option>
                                        </select>
                                    </div>
                                    <div className="col-md-auto">
                                        <select  className="form-control select-place h-44">
                                            <option value="">거래처 전체</option>
                                        </select>
                                    </div>
                                    <div className="col-md-auto">
                                        <select className="form-control select-place h-44">
                                            <option value="">판매자 전체</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table_striped">
                                <thead className="thead_bg">
                                    <th>통신사</th>
                                    <th>거래처</th>
                                    <th>개통일</th>
                                    <th>정산</th>
                                    <th>실매출</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SKT</td>
                                        <td>IBM</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>SK 7</td>
                                        <td>Pizza Hut</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>KT</td>
                                        <td>eBay</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>거래처</td>
                                        <td>Sony</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>거래처</td>
                                        <td>Louis Vuitton</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>거래처</td>
                                        <td>General Electric</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                    <tr>
                                        <td>거래처</td>
                                        <td>Nintendo</td>
                                        <td>2020/04/17</td>
                                        <td>1,248,500원</td>
                                        <td>1,248,500원</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr className="tfooter">
                                        <td colspan="3" className="title">정산정보</td>
                                        <td className="price">615,000</td>
                                        <td className="price">190,000</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>   
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
  )(Statistical);

