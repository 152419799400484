import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as routerName from '../../utils/routerName';
class MyProfile extends Component {
    componentDidMount() {

    }
    

    renderMyProfileComponent = () => {

    }

    onSubmit = (data) => {
       console.log(data)
    }


    render() {
        const { auth } = this.props

        return (
            <Fragment>
                <section className="page_profile">
                    <div className="container">
                    <div className="info_profile">
                            <div className="box_info">
                                <div className="avatar">
                                    <img src={this.props?.auth?.user?.avatar_url?.thumbnail || "/images/avatar-01.png"}
                                         onError={(e) => {
                                             e.target.src = '/images/avatar-01.png' // some replacement image
                                         }}
                                         alt=""
                                         style={{ 'width': '100%'}}
                                    />
                                </div>
                                <div className="info">
                                    <span className="name">{this.props.auth && this.props.auth.user.name}</span>
                                    <span className="package">{this.props.auth  && this.props.auth.storePlan  && this.props.auth.storePlan.price_package && this.props.auth.storePlan.price_package.pp_name  }</span>
                                    <span className="id">ID {this.props.auth && this.props.auth.user.username}</span>
                                </div>
                            </div>
                            <button className="btn btn-gray h-44"  onClick={() => this.props.history.push({ pathname: '/' + routerName.EDIT_PROFILE })}>프로필 수정  <i className="far fa-edit pl-1" /> </button>
                        </div>
                        <div className="form_profile">
                            
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="label-text">사업자명</label>
                                        <input className="form-control h-44 bg_input" placeholder={this.props.auth && this.props.auth.store.sto_business_name} disabled/> 
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="label-text">아이디</label>
                                        <input className="form-control h-44 bg_input" placeholder={this.props.auth && this.props.auth.user.username} disabled/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="label-text">연락처</label>
                                        <input className="form-control h-44 bg_input"  placeholder={this.props.auth && this.props.auth.user.phone} disabled/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="label-text">매장연락처</label>
                                        <input className="form-control h-44 bg_input"  placeholder={this.props.auth && this.props.auth.store.sto_phone_number} disabled/>
                                    </div>
                                </div>
                                <div className="form-row form-row justify-content-end">
                                    <div className="form-group col-md-6">
                                        <label className="label-text">매장명</label>
                                        <input className="form-control h-44 bg_input"  placeholder={this.props.auth && this.props.auth.store.sto_name} disabled/> 
                                    </div>
                                    <div className="form-group col-md-6">
                                        {(this.props.auth && (this.props.auth.store.sto_business_type === '0')) ? (
                                            <Fragment>
                                                <label className="label-text">이메일</label>
                                                <input className="form-control h-44 bg_input" placeholder={this.props.auth && this.props.auth.user.email}  disabled/>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <label className="label-text">매장 주소</label>
                                                <input className="form-control h-44 bg_input" placeholder={this.props.auth && this.props.auth.store.sto_addr_line1} disabled/> 
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                                {auth?.store?.sto_business_type === '0' ? (
                                    <Fragment>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <div className="form-group">
                                                    <label className="label-text">매장 주소</label>
                                                     <input className="form-control h-44 bg_input" placeholder={auth?.store?.sto_addr_line1} disabled/> 
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control h-44 bg_input mt-ab" placeholder={auth?.store?.sto_addr_line2} disabled/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <div className="form-group">
                                                    <label className="label-text">모비고 아이디</label>
                                                     <input className="form-control h-44 bg_input" placeholder={auth?.user?.mobigo_user_id} disabled/> 
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className="form-row justify-content-end">
                                            <div className="form-group col-md-6" >
                                                <label className={"label-text " } >사업자 등록번호</label>
                                                <input className={"form-control h-44 bg_input "}   placeholder={this.props.auth && this.props.auth.store.sto_business_no} disabled/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input className="form-control h-44 bg_input mt-ab" placeholder={this.props.auth && this.props.auth.store.sto_addr_line2} disabled/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6" >
                                                <label className={"label-text " } >계산서 발행 이메일 주소</label>
                                                <input className={"form-control h-44 bg_input "}   placeholder={this.props.auth && this.props.auth.store.sto_business_email} disabled/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="label-text">이메일</label>
                                                <input className="form-control h-44 bg_input" placeholder={this.props.auth && this.props.auth.user.email}  disabled/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6" >
                                            </div>
                                            <div className="form-group col-md-6">
                                                <div className="form-group">
                                                    <label className="label-text">모비고 아이디</label>
                                                     <input className="form-control h-44 bg_input" placeholder={auth?.user?.mobigo_user_id} disabled/> 
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                
                           
                        </div>
                    </div>
                </section>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {
        auth:state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};

const FORM_NAME = 'MyProfile';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues:{
        sto_business_name: '', 
        sto_name: '', 
        sto_business_no: '',
        sto_phone_number:'',
        sto_fax_number:'',
        email:'',
        sto_addr_line1:'',
        sto_addr_line2:'',
      },
  });
  

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(MyProfile);

