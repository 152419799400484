import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import {priceFormat} from "../../../utils/helper";
import {showLoading, hideLoading} from '../../../store/actions/ui';
import ModalProductInformation from "./ModalProductInformation";
import './ModalProduct.css'
import _ from "lodash";
const option_type = {
  APPLY: 1,
  BASIC: 2,
}

class ModalProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      optionApply: [],
      optionBasic: [],
      activeDevice: -1,
      activeOption: -1,
      modalProductInformation : false
    }
  }

  modalOpened = () => {
    const { WiredAdvice, itemSelected } = this.props
    let telcom_id = WiredAdvice.WiredAdviceStep1.telcomID

    let params = {
      telcom_id,
      wd_class: this.props.wdClass,
    }
    this.props.showLoading()
    api.advice.getListWired(params)
      .then(response => {
        this.props.hideLoading()
        let devices = response.data.data
        if (devices && devices.length > 0) {
          let activeDevice = -1
          let optionApply = []
          let optionBasic = []
          let activeOption = -1
          // nếu đã chọn thiết bị trước đó
          if (itemSelected.device && typeof itemSelected.device === 'object' && Object.keys(itemSelected.device).length > 0) {
            let optionApplySelected = itemSelected.options.filter(e => e.type == option_type.APPLY)
            let optionBasicSelected = itemSelected.options.filter(e => e.type == option_type.BASIC)
            let idOptionApply = optionApplySelected.map(e => e.wdo_id)
            let idOptionBasic = optionBasicSelected.map(e => e.wdo_id)
            // set device selected
            devices.map((device, index) => {

              if (device.wd_id === itemSelected.device?.wd_id) {
                activeDevice = index
                optionApply = device.wired_device_option_apply
                  .map((item) => ({...item, qty: this.getQty(item, optionApplySelected), active: !!+item?.pivot?.required_type, type: option_type.APPLY}))
                  // .map((item) => ({...item, qty: this.getQty(item, optionApplySelected), active: idOptionApply.includes(item.wdo_id), type: option_type.APPLY}))
                optionBasic = device.wired_device_option_basic
                  .map((item) => ({...item, qty: this.getQty(item, optionBasicSelected), active: idOptionBasic.includes(item.wdo_id), type: option_type.BASIC}))
              }
            })
            // // set option selected
            // if (itemSelected.options.length > 0) {
            //   // optionApply.map((option, index) => {
            //   //   if (option.wdo_id === itemSelected.option?.wdo_id) {
            //   //     activeOption = index
            //   //   }
            //   // })
            // }
          } else {
            devices.map((device, index) => {
              if (device.wd_id === itemSelected.device?.wd_id) {
                activeDevice = index
                if (this.checkSelectMulti()) {

                  optionApply = device.wired_device_option_apply
                    .map((item) => ({...item, qty: 1, active: true, type: option_type.APPLY}))
                  optionBasic = device.wired_device_option_basic
                    .map((item) => ({...item, qty: 0, type: option_type.BASIC}))
                } else {
                  optionApply = device.wired_device_option_apply
                    .map((item) => ({...item, qty: 1, active: false, type: option_type.APPLY}))
                }
              }
            })
            // // không thì lấy mặc định theo BO
            // options.map((option, index) => {
            //   if (option.pivot.apply_type) {
            //     activeOption = index
            //   }
            // })
          }

          this.setState({
            devices,
            activeDevice,
            optionApply,
            optionBasic,
            activeOption,
            // options: devices[0].wired_device_options
          })
        }
      })
      .catch(() => {
        this.props.hideLoading()
      })
  }

  getQty = (item, options) => {
    let qty = 1;
    options.map((option) => {
      if (option.wdo_id == item.wdo_id) {
        qty = option.qty
      }
    })
    return qty
  }

  modalClosed = () => {
    this.setState({
      devices: [],
      options: [],
      optionApply: [],
      optionBasic: [],
      activeDevice: -1,
      activeOption: -1,
    })
  }

  selectDevice = (activeDevice) => {
    const { devices } = this.state
    let optionApply = []
    let optionBasic = []
    if (this.checkSelectMulti()) {
      optionApply = activeDevice !== -1 ? devices[activeDevice].wired_device_option_apply
        .map((item) => ({...item, qty: 1, active: !!+item?.pivot?.required_type, type: option_type.APPLY})) : []
      optionBasic = activeDevice !== -1 ? devices[activeDevice].wired_device_option_basic
        .map((item) => ({...item, qty: 0, type: option_type.BASIC})) : []
    } else {
      let optionBasicId = activeDevice !== -1 ? devices[activeDevice].wired_device_option_basic
        .map((item) => item.wdo_id) : []
      if (optionBasicId.length > 1) {
        optionBasicId = [optionBasicId[0]]
      }
      optionApply = activeDevice !== -1 ? devices[activeDevice].wired_device_option_apply
        .map((item) => ({...item, qty: 1, active: optionBasicId.includes(item.wdo_id) ? true : false, type: option_type.APPLY})) : []
    }
    
    let activeOption = -1
    // // set option default in BO
    // options.map((option, index) => {
    //   if (+option.pivot.apply_type) {
    //     activeOption = index
    //   }
    // })
    this.setState({
      activeDevice,
      optionApply,
      optionBasic,
      activeOption,
    })
  }

  selectOption = (type, option) => {
    if (type == option_type.APPLY) {
      let { optionApply, activeDevice, devices, optionBasic } = this.state
      if (this.checkSelectMulti()) {
          optionApply = optionApply.map((op) => {
              if (op.wdo_id == option.wdo_id) {
                  return {
                      ...op,
                      active: op.active ? false : true
                  }
              }
              return op
          })
          if(devices[activeDevice]?.package_limit && optionApply.filter(item => item.active ==  true).length > +devices[activeDevice].package_limit){ /*
            Số package được chọn k được vượt quá số package tối đa được chọn:
             - https://docs.google.com/presentation/d/1FSF0xqtMQTvSnEKhzQ5ahRyCj4Ac7gbA/edit#slide=id.p3
             - FB_612 - flow 6978
          */
              alert(`${optionApply.filter(item => item.active ==  true).length}개 선택시 최대 ${devices[activeDevice].package_limit}개만 선택가능합니다`);
              return;
          }
        let optionBasicDuplicate = _.find(optionBasic.filter(item => item.active ==  true), o => +o.wdo_id ===  +option.wdo_id);
        console.log(optionBasicDuplicate, (+optionBasicDuplicate?.pivot?.duplicate_option || 1))
        if(optionBasicDuplicate && +optionBasicDuplicate?.pivot?.duplicate_option === 0){ // không được trùng lặp
          alert(`선택하신 패키지옵션과 추가옵션은 중복 불가능합니다`);
          return;
        }
      } else {
        optionApply = optionApply.map((op) => {
          return {
            ...op,
            active: false
          }
        }).map((op) => {
          if (op.wdo_id == option.wdo_id) {
            return {
              ...op,
              active: true
            }
          }
          return op
        })
      }
      this.setState({
        optionApply,
      })
    }
    if (type == option_type.BASIC) {
      let { optionBasic, optionApply } = this.state
      optionBasic = optionBasic.map((op) => {
        if (op.wdo_id == option.wdo_id) {
          return {
            ...op,
            active: op.active ? false : true
          }
        }
        return op
      })
      if(option.pivot.duplicate_option && +option.pivot.duplicate_option === 0 && _.findIndex(optionApply.filter(item => item.active ==  true), o => +o.wdo_id ===  +option.wdo_id) >= 0){ // không được trùng lặp
        alert(`선택하신 패키지옵션과 추가옵션은 중복 불가능합니다`);
        return;
      }
      this.setState({
        optionBasic,
      })
    }
  }

  selectItem = () => {
    const {
      devices,
      activeDevice,
    } = this.state
    let device = devices[activeDevice] || {};
    let options = this.getOpntionActive()
    this.setState({
      activeDevice: -1,
      activeOption: -1,
    })
    this.props.getDeviceOptionSelected(device, options)
    this.props.closeModal()
  }

  cancelSelectItem = () => {
    if (!this.props.itemSelected?.device?.wd_id) {
      this.props.removeItem()
    }
    this.props.closeModal()
  }

  modalInformation = () => {
    this.setState({
      modalProductInformation : !this.state.modalProductInformation
    })
  }

  changeQty = (type, option, value) => {
    if (type == option_type.APPLY) {
      let { optionApply } = this.state
      optionApply = optionApply.map((op) => {
        if (op.wdo_id == option.wdo_id) {
          return {
            ...op,
            qty: value
          }
        }
        return op
      })
      this.setState({
        optionApply,
      })
    }
    if (type == option_type.BASIC) {
      let { optionBasic } = this.state
      optionBasic = optionBasic.map((op) => {
        if (op.wdo_id == option.wdo_id) {
          return {
            ...op,
            qty: value
          }
        }
        return op
      })
      this.setState({
        optionBasic,
      })
    }
  }

  renderOption = (type, options, isDisableLi, isDisableSelect) => {

    return options.map((option, index) => (
      <li
        key={index}
        className={option.active ? `bg-item` : ''}
      >
        <span
          // onClick={() => {return (isDisableLi && this.checkSelectMulti()) ? null : this.selectOption(type, option)}}
            //nếu package là bắt buộc được chọn thì k được thay đổi
          onClick={() => {return (isDisableLi || (this.checkSelectMulti() && !!(+option?.pivot?.required_type))) ? null : this.selectOption(type, option)}}
          style={!this.checkSelectMulti() ? {width: '200px', justifyContent: 'flex-start', textAlign: 'left'} : null}
            className={this.checkSelectMulti() ? "option-iot" : ""}
        >
          {option.wdo_name}
        </span>
        {this.checkSelectMulti() && <div style={{width: '46px'}} >
          <select
            className="form-control"
            onChange={(e) => {return isDisableSelect ? null : this.changeQty(type, option, e.target.value)}}
            // value={type == 1 ? +option.qty : 0}
            value={+option?.qty || 0}
            disabled={isDisableSelect ? 'disabled' : ''}>
              <option value={0} key={0}>
                {0}
              </option>
            {[...Array(+option.pivot.quantily).keys()].map(value => (
              <option value={value+1} key={value}>
                {value+1}
              </option>
            ))}
          </select>
        </div>}
        <div onClick={() => {return isDisableLi ? null : this.selectOption(type, option)}} style={{width: '100px'}} className={"text-right"}>
          <span style={{ justifyContent: 'flex-end' }}>
            {priceFormat(option.wdo_commitment_fee)}원
          </span>
        </div>
      </li>
    ))
  }

  renderOptionApply = (height) => (
    <Fragment>
      {this.checkSelectMulti() && <div className="top-content">
        <h5 className="mb-0">패키지옵션</h5>
      </div>}
      <ul className={`list-item mt-0 list_wireless list-scroll height-${height}`} style={{ height: 'auto' }}>
        {(this.state.optionApply.length==0) ? (<li
          className={`bg-item`}
          onClick={() => this.selectOption(-1)}>
          <span>미적용</span>
          <span>0원</span>
        </li>): this.renderOption(
          option_type.APPLY,
          this.state.optionApply,
          !!(+this.state.devices[this.state.activeDevice]?.no_change_apply),
          !!(+this.state.devices[this.state.activeDevice]?.no_change_apply)
        )}
      </ul>
    </Fragment>
  )

  renderOptionBasic = (height) => (
    <Fragment>
      <div className="top-content">
        <h5 className="mb-0">추가옵션</h5>
      </div>
      <ul className={`list-item mt-0 list_wireless list-scroll height-${height}`} style={{ height: 'auto' }}>
        {(this.state.optionBasic.length==0) ? (<li
          className={`bg-item`}
          onClick={() => this.selectOption(-1)}>
          <span>미적용</span>
          <span>0원</span>
        </li>): this.renderOption(
          option_type.BASIC,
          this.state.optionBasic,
          false,
          !!(+this.state.devices[this.state.activeDevice]?.no_change_basic)
        )}
      </ul>
    </Fragment>
  )

  getOpntionActive = () => {
    return [
      ...this.state.optionApply.filter(e => e.active),
      ...this.state.optionBasic.filter(e => e.active)
    ]
  }

  getFee = () => {
    const { devices, activeDevice } = this.state
    let fee = +devices[activeDevice]?.wd_commitment_fee
    let options = this.getOpntionActive()
    fee = options.reduce((prevFee, option) => {
      return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
    }, fee)
    return fee
  }

  checkSelectMulti = () => this.props.wdClass == 101

  render() {
    const {
      devices,
      activeDevice,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.cancelSelectItem}
          fade={true}
          // backdrop={"static"}
          className={`modal-dialog ${this.props.wdClass == 101 ? "modal-product-iot" : "modal-custom-15"} `}
          onOpened={this.modalOpened}
          onClosed={this.modalClosed}
        >
          <div className="modal-header">
            {this.props.wdClass == 90 && <h5 className="modal-title text-left w-100">인터넷 상품 선택</h5>}
            {this.props.wdClass == 91 && <h5 className="modal-title text-left w-100">TV 상품 선택</h5>}
            {this.props.wdClass == 92 && <h5 className="modal-title text-left w-100">전화 상품 선택</h5>}
            {this.props.wdClass == 101 && <h5 className="modal-title text-left w-100">IoT 상품 선택</h5>}
            <button type="button" className="close close-modal" onClick={this.cancelSelectItem}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body pr-0 pl-0">
            <div className="row">
              <div className="col-md-6">
                <div className="top-content h-44">
                  <h5 className="mb-0">1.상품선택</h5>
                  <button
                    className="btn btn-wireless"
                    type="button"
                    onClick={this.modalInformation}
                    // data-toggle="modal"
                    // data-target="#modal-13"
                  >
                    상품 정보 보기
                  </button>
                </div>
                <ul className="list-item mt-0 list_wireless">
                  <li
                    className={activeDevice === -1 ? `bg-item` : ''}
                    onClick={() => this.selectDevice(-1)}
                  >
                    <span>미적용</span>
                    <span>0원</span>
                  </li>
                  {devices.map((device, key) => (
                    <li
                      key={key}
                      className={key === activeDevice ? `bg-item` : ''}
                      onClick={() => this.selectDevice(key)}
                    >
                      <span>{device.wd_name} {device.wd_class == 91 ? `(Ch.${device.wd_channel_total})` : ''}</span>
                      <span>{priceFormat(device.wd_commitment_fee)}원</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <div className="top-content h-44">
                  <h5 className="mb-0">2.옵션선택</h5>
                </div>
                <div>
                  {this.renderOptionApply(this.checkSelectMulti() ? '154' : '363')}
                  {this.checkSelectMulti() && this.renderOptionBasic(this.checkSelectMulti() ? '154' : '363')}
                </div>
              </div>
              <div className="col-12">
                <div className="total-modal">
                  <h4>
                    월 이용료
                  </h4>
                  <span>
                  {priceFormat(this.getFee())}원
                </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button type="button" className="btn btn-gray" onClick={this.cancelSelectItem}>취소</button>
            <button type="button" className="btn btn-submit" onClick={this.selectItem}>확인</button>
          </div>
        </Modal>

        <ModalProductInformation
          toggle={this.modalInformation}
          isOpen={this.state.modalProductInformation}
          devices={devices}
          selectDevice={this.selectDevice}
          wdClass={this.props.wdClass}
          network={this.props.network}
        />
      </React.Fragment>
    );
  }
}

ModalProduct.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  closeModal: PropTypes.func,
  isOpenModal: PropTypes.bool,
  change: PropTypes.func,
  WiredAdvice: PropTypes.object,
  getDeviceOptionSelected: PropTypes.func,
  itemSelected: PropTypes.object,
  wdClass: PropTypes.number,
  removeItem: PropTypes.func,
};

ModalProduct.defaultProps = {
  network : {}
}

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalProduct);
