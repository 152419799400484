import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { priceFormat } from '../../../utils/helper';
class OptionalAgreementModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: this.props.installmentMoney,
            invalid: false,
            errMsg: null
        }
    }
    setValueOpen = () => {
        this.setState({
            valueSelect: this.props.installmentMoney,
        })
    }
    toggle = () => {
        this.props.toggleEvent()
    }

    toggleTwo = () => {
        this.setState({
            modalTwo: !this.state.modalTwo,
            invalid: false
        }, () => {
            this.props.toggleEvent()
        })
    }
    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(/,/g, '').replace(regex, separate);
        return res;
    }

    handleChange = (e) => {
        const { handleSubmit, WirelessAdvice } = this.props;
        let errMsg = null;
        const financialGoal = (e.target.validity.valid) ? e.target.value : this.state.valueSelect;
        let intNumber = financialGoal.split(',').join('');
        var regex=/^[0-9]+$/;
        if (financialGoal.length > 10) {
            errMsg = '금액은 10자를 초과하면 안됩니다!';
            this.setState({
                valueSelect: this.formatCurrency(this.state.valueSelect),
                errMsg: errMsg,
                invalid: true
            })
        }else if(financialGoal && !intNumber.match(regex)){
            errMsg = '숫자로 입력해야 됩니다!';
            this.setState({
                valueSelect:  this.formatCurrency(this.state.valueSelect),
                errMsg: errMsg,
                invalid: true
            })
        }else{
            this.setState({
                valueSelect:  this.formatCurrency(financialGoal),
                errMsg: null,
                invalid: false,
            })
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let errMsg = '';
        if (parseInt(this.state.valueSelect.split(',').join('')) <= parseInt(this.props.validPrice)) {
            let result = this.state.valueSelect.replace(/[^0-9\.]+/g,"");
            this.props.getValue(result);
            this.props.toggleEvent()
        }else{
            errMsg = `금액은 원 ${priceFormat(this.props.validPrice)}원 이하로 입력해 주세요.`;
            this.setState({
                ...this.state,
                errMsg: errMsg,
                invalid: true,
            })
        }
    }
    resetValue = () => {
        this.setState({
            valueSelect: priceFormat(this.props.optionalAgreementDefault)
        })
    }
    render() {
        let {valueSelect, errMsg} = this.state;
        return (
            <Fragment>
                <Modal 
                    isOpen={this.props.showModal} 
                    toggle={this.toggle}
                    // backdrop={"static"}
                    className="modal-dialog-centered modal-custom"
                    contentClassName="pl-4 pr-4"
                    onOpened={this.setValueOpen}
                >
                     <div className="modal-header">
                        <h5 className="modal-title">선택약정 할인금액 변경 </h5>
                        <Button type="button" className="close close-modal" onClick={this.toggle}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </Button>
                    </div>
                    <div className="modal-body px-0">
                        <form onSubmit={this.handleSubmit} className="body-plans pt-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 text-modal-gray">선택약정 기본할인 : {priceFormat(this.props.optionalAgreementDefault)} 원</p>
                                <button type="button" className="btn btn-gray-white" onClick={this.resetValue}>적용</button>
                            </div>
                            <div className="form-group row mt-4 align-items-center">
                                <div className="col-10 pr-0">
                                    <input 
                                        type="text" 
                                        className={this.state.invalid ? 'form-control h-44 is-invalid' : 'form-control h-44'} 
                                        value={valueSelect} 
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-2">
                                    <span className="text-wrapper-gray">원</span>
                                </div>
                            </div>
                            {
                                this.state.invalid ? (
                                    <h5 className="text-wrapper-gray text-left color-orange">
                                        {errMsg}
                                    </h5>
                                ) : ''
                            }
                            {/* <h5 className="text-wrapper-gray text-left color-orange">
                                ※ 할부원금을 초과한 금액은 적용하실 수 없습니다.
                            </h5> */}
                            <h5 className="text-wrapper-gray text-left">
                                입력한 선택약정 할인금액을 적용하시겠습니까?
                            </h5>
                            <div className="d-flex justify-content-center border-top-0 footer-modal">
                                <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" onClick={this.toggleTwo}>취소</button>
                                <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(OptionalAgreementModal);

