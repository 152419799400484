import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import ProductClassificationItem from './ProductClassificationItem'
import ModalProduct from './ModalProduct'
import ModalCost from './ModalCost'
import { priceFormat, caclWiredAdvice } from '../../../utils/helper';
import { LGU_PLUS_ID, CLASSIFY, DEFAULT_CLASSIFY, WD_CLASS } from '../../../utils/constant';
import _ from 'lodash'
import api from "../../../utils/api";
import {Modal} from 'reactstrap';

class ProductClassification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error_modal: {
        isShowModal: false,
        message: ""
      },
      indexClassify: null,
      indexItem: null,
      isShowModalProduct: false,
      isShowModalOption: false,
      isShowModalCost: false,
        code:[]
    }
    this.getWiredDiscountDebounce = _.debounce(this.getWiredDiscount, 50)
  }

  toggleModal = (e, modal) => {
    e && e.preventDefault();
    this.setState({
        [modal]: {
            ...this.state[modal],
            isShowModal: !this.state[modal].isShowModal
        }
    })
  }

  addClassify = (index) => {
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    let classify = [ ...WiredAdviceStep2.classify ]
    classify[index].classify.push(DEFAULT_CLASSIFY)
    this.setStep2(classify)
  }

  showModalProduct = (indexClassify, indexItem) => {
    this.setState({
      indexClassify,
      isShowModalProduct: true,
      indexItem
    })
  }

  closeModalProduct = () => {
    this.setState({
      indexClassify: null,
      isShowModalProduct: !this.state.isShowModalProduct,
    })
  }

  showModalOption = (indexClassify, indexItem) => {
    this.setState({
      indexClassify,
      isShowModalOption: true,
      indexItem
    })
  }

  closeModalOption = () => {
    this.setState({
      indexClassify: null,
      isShowModalOption: !this.state.isShowModalOption,
    })
  }

  showModalCost = (indexClassify, indexItem) => {
    this.setState({
      indexClassify,
      indexItem,
      isShowModalCost: true,
    })
  }

  closeModalCost = () => {
    this.setState({
      indexClassify: null,
      isShowModalCost: !this.state.isShowModalCost,
    })
  }

  updateClassify = (classify) => {
    const { WiredAdvice } = this.props
    const telcomID = WiredAdvice.WiredAdviceStep1.telcomID
    let classifyy = {...classify}
    if ([19, 18, '19', '18'].includes(telcomID)) {
      classifyy[1].classify = _.orderBy(classifyy[1].classify, ['device.wd_commitment_fee'], ['desc'])
    }

    return Object.values(classifyy)
  }

  setStep2 = (classify) => {
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    let step2 = {
      ...WiredAdviceStep2,
      classify,
      classifyString: JSON.stringify(classify)
    }

    let data = this.transformDataDiscount(step2);
    this.getWiredDiscountDebounce(data, step2)
  }

  transformDataDiscount = (WiredAdviceStep2) => {
    const {WiredAdvice: {WiredAdviceStep1}} = this.props
    let data = {
      conds: [],
      class: [],
      key: WiredAdviceStep1.telcomID,
    };
    WiredAdviceStep2.classify.map((cls) => {
      cls.classify.map((item, index) => {
        if (item?.device?.wd_id) {
          data.conds.push({
            wd_id: item?.device?.wd_id,
            wd_price: item?.device?.wd_commitment_fee,
            combine_discount: (cls.id === 1 && index === 0) ? (WiredAdviceStep2.combineCondition?.id || '') : '',
            wdo_id: item?.options.map(e => e.wdo_id),
            wdo_price: item?.options.map(e => e.wdo_commitment_fee),
          })
          data.class.push(item?.device?.wd_class)
        }
      })
    })

//console.log("트랜스폼 데이터", data);

    return data;
  }

  getWiredDiscount = (data, WiredAdviceStep2) => {
    //console.log("getWiredDiscount before WiredAdviceStep2");
    //console.log(WiredAdviceStep2);
    api.advice.getWiredDiscountCondsItems(data)
      .then((response) => {
        let clone = [...WiredAdviceStep2.classify]
        let condDiscount = response.data.data.condDiscount || []
        let setupFee = response.data.data.setupFee || []
        let wiredAdviceStep2 = {
          ...WiredAdviceStep2,
          setupFee
        }
        let a = [];
        clone.map((cls) => {
          let ab = []
          for (let i = 0; i < condDiscount.length; i++) {
            for (let j = 0; j < cls.classify.length; j++) {
              if (cls.classify[j]?.device?.wd_id == condDiscount[i][0]?.wd_id) {
                ab.push(condDiscount[i])
                break
              }
            }
          }
          a.push(ab)
        })
        a.map((resGroup, resIndex) => {
          clone = clone.map((cls, clsIndex) => ({
              ...cls,
              classify: cls.classify.map((item, itemIndex) => {
                let itemClone = {...item}
                if (resIndex == clsIndex && resGroup[itemIndex]) {
                  itemClone = {
                    ...itemClone,
                    discount: {
                      ...itemClone.discount,
                      device: {
                        ...itemClone.discount.device,
                        name: resGroup[itemIndex][0]?.dwc_name,
                        value: resGroup[itemIndex][0]?.wdci_discount,
                      },
                      options: (resGroup[itemIndex][1] || []).map((i, index) => ({
                        ...itemClone.discount.options[index],
                        name: i.dwc_name,
                        value: i.wdci_discount,
                      })),
                      value: itemClone.cost.value - (resGroup[itemIndex][0]?.wdci_discount || 0) - (resGroup[itemIndex][1]?.wdci_discount || 0),
                    },
                  }
                }
                return itemClone
              })
          }))
        })

        // 할인 가격 예외 처리
        if(clone[1].classify && clone[1].classify.length > 0){
          if(clone[1].classify[0].device.wd_id == 368 && clone[1].classify[0].options.length > 0){ // 프리미엄 디즈니+
            clone[1].classify[0].options.map((option) => {
              if(option.wdo_id == 232){ // U+tv 사운드바(디즈니)
                clone[1].classify.map((classifyInfo) => {
                  if(classifyInfo.device.wd_id == 390){ // 프리미엄 플러스
                    if(isNaN(classifyInfo.discount.device.value) == false && Number(classifyInfo.discount.device.value) > 0){
                      classifyInfo.discount.device.value = (Number(classifyInfo.discount.device.value) - 3300) + "";
                    }
                  }
                });
              }
            });
          }

          let uPlusTvPromotionDiscountForFreeCustomersCount = 0;

          clone[1].classify.map((classifyInfo, classifyIndex) => {
            let discount     = classifyInfo.discount;
            let optionsArray = classifyInfo.options;

            if(discount && optionsArray){
              let options = discount.options;
              let wdoName = optionsArray[0]?.wdo_name || "";

              if(options){
                options.map((optionInfo, optionsIndex, optionsArray) => {
                  let name = optionInfo.name;

                  if(name == "U+tv 프리고객 셋탑할인" && wdoName == "크롬캐스트"){
                    uPlusTvPromotionDiscountForFreeCustomersCount++;

                    if(classifyIndex == 0){
                      optionsArray[optionsIndex] = {"name": "", "value": 0};
                    }
                  }
                });
              }
            }
          });

          if(uPlusTvPromotionDiscountForFreeCustomersCount == 3){
            this.setState({
              error_modal: {
                  isShowModal: true,
                  message: (<span className="notification_delete">해당 상품은 최대 2개까지 가입 가능합니다.</span>)
              }
            })

            clone[1].classify.splice(2, 1);
          }
        }

        wiredAdviceStep2 = {
          ...wiredAdviceStep2,
          classify: clone,
          classifyString: JSON.stringify(clone),
        }
        wiredAdviceStep2 = caclWiredAdvice(wiredAdviceStep2);

        //console.log("getWiredDiscount after WiredAdviceStep2");
        //console.log(WiredAdviceStep2);

        this.props.change('WiredAdviceStep2', wiredAdviceStep2);
      })
  }

  getDeviceOptionSelectedLguplusExcept1 = (classify) => {
    if(classify[0].classify.length == 0){ // internet
      let tvClassify       = classify[1].classify;
      let tvClassifyLength = tvClassify.length;
      
      if(tvClassifyLength > 0){
        let tempInt   = 0;
        let tvcl      = null;
        let tvOptions = null;
        let wdoName   = null;

        for(tempInt = 0; tempInt < tvClassifyLength; tempInt++){
          tvcl      = tvClassify[tempInt];
          tvOptions = tvcl.options;

          if(tvOptions.length > 0){
            wdoName = tvOptions[0].wdo_name;

            if(wdoName == "크롬캐스트" || wdoName == "U+tv 프리3 SE" || wdoName == "U+tv 프리3 LE"){
              this.setState({
                error_modal: {
                  isShowModal: true,
                  message: (<span className="notification_delete">해당 상품은 인터넷 상품 적용 후 선택 가능합니다.</span>)
                }
              });
  
              classify[1].classify.splice(tempInt, 1);
            }
          }
        }
      }
    }

    return classify;
  }

  getDeviceOptionSelectedLguplusExcept2 = (classify) => {
    let tempInt                       = 0;
    let internetClassfy               = classify[0].classify;
    let internetClassfyLength         = internetClassfy.length;
    let internetClassfyInfo           = null;
    let internetClassfyInfoDevice     = null;
    let internetClassfyInfoDeviceWdId = null;
    let settopBoxTwoCheck             = false;
    let tvClassify                    = classify[1].classify;
    let tvClassifyLength              = tvClassify.length;
    
    for(tempInt = 0; tempInt < internetClassfyLength; tempInt++){
      internetClassfyInfo           = internetClassfy[tempInt];
      internetClassfyInfoDevice     = internetClassfyInfo.device;
      internetClassfyInfoDeviceWdId = Number(internetClassfyInfoDevice.wd_id);

      if([306, 307, 391].includes(internetClassfyInfoDeviceWdId)){ // 와이파이기본_광랜안심 100MB, 스마트 광랜안심 100MB, 프리미엄 안심 100M
        settopBoxTwoCheck = true;
      }
    }
    
    if(settopBoxTwoCheck){ // 셋탑박스 2개 이상 체크
      let tvcl       = null;
      let tvOptions  = null;
      let wdoName    = null;
      let checkCount = 0;

      for(tempInt = (tvClassifyLength - 1); tempInt >= 0; tempInt--){
        tvcl      = tvClassify[tempInt];
        tvOptions = tvcl.options;

        if(tvOptions.length > 0){
          wdoName = tvOptions[0].wdo_name;
          if(wdoName == "크롬캐스트" || wdoName == "U+tv 프리3 SE" || wdoName == "U+tv 프리3 LE"){
            checkCount++;

            if(checkCount > 1){
              classify[1].classify.splice(tempInt, 1);
            }
          }
        }
      }

      if(checkCount > 1){
        this.setState({
          error_modal: {
            isShowModal: true,
            message: (<span className="notification_delete">해당 상품은 최대 1대까지 가입 가능합니다.</span>)
          }
        });
      }
    }

    return classify;
  }

  getDeviceOptionSelectedLguplusExcept3 = (classify) => {
    let tempInt                       = 0;
    let internetClassfy               = classify[0].classify;
    let internetClassfyLength         = internetClassfy.length;
    let internetClassfyInfo           = null;
    let internetClassfyInfoDevice     = null;
    let internetClassfyInfoDeviceWdId = null;
    let chromecastThreeCheck          = false;
    let tvClassify                    = classify[1].classify;
    let tvClassifyLength              = tvClassify.length;
    
    for(tempInt = 0; tempInt < internetClassfyLength; tempInt++){
      internetClassfyInfo           = internetClassfy[tempInt];
      internetClassfyInfoDevice     = internetClassfyInfo.device;
      internetClassfyInfoDeviceWdId = Number(internetClassfyInfoDevice.wd_id);
      if([
        308, 309, 312, 313, 314, // 와이파이기본_기가슬림안심 500MB, 스마트 기가슬림안심 500M/ 프리미엄 안심 500M, 와이파이기본_2.5기가안심 요금제, 와이파이기본_5기가 요금제, 와이파이기본_10기가 요금제
        351, 355                 // 스마트기가 최대 500M, 스마트기가 최대 2.5G
      ].includes(internetClassfyInfoDeviceWdId)){
        chromecastThreeCheck = true;
      }
    }
    
    if(chromecastThreeCheck){ // 크롬캐스트 3개 체크
      let tvcl       = null;
      let tvOptions  = null;
      let wdoName    = null;
      let checkCount = 0;

      for(tempInt = (tvClassifyLength - 1); tempInt >= 0; tempInt--){
        tvcl      = tvClassify[tempInt];
        tvOptions = tvcl.options;

        if(tvOptions.length > 0){
          wdoName = tvOptions[0].wdo_name;
          if(wdoName == "크롬캐스트"){
            checkCount++;

            if(checkCount > 2){
              classify[1].classify.splice(tempInt, 1);
            }
          }
        }
      }

      if(checkCount > 2){
        this.setState({
          error_modal: {
            isShowModal: true,
            message: (<span className="notification_delete">해당 상품은 최대 2개까지 가입 가능합니다.</span>)
          }
        });
      }
    }

    return classify;
  }

  // 상품 선택시 호출되는 메소드
  getDeviceOptionSelected = (device, options) => {
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    const { indexClassify, indexItem } = this.state
    let classify = [ ...WiredAdviceStep2.classify ]
    let feeOption = options.reduce((prevFee, option) => {
      return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
    }, 0)
    let cl = {
      device: device,
      options: options,
      cost: {
        value: +(device?.wd_commitment_fee || 0) + +(feeOption || 0)
      },
      discount: {
        ...DEFAULT_CLASSIFY.discount,
        value: +(device?.wd_commitment_fee || 0) + +(feeOption || 0)
      },
    }

    let index = classify[this.state.indexClassify].classify.filter(e => Object.keys(e.device).length > 0);

    index = index.length;

    if (Object.keys(classify[indexClassify]?.classify[indexItem]?.device || {}).length > 0) {
      index = this.state.indexItem
    }

    if(
      index != 0 &&
      (
        // 프리미엄 디즈니+ , 프라임 라이트, 프리미엄 내맘대로, 프리미엄 환승구독, 프리미엄 디즈니+
        device.wd_id == 368 || device.wd_id == 319 || device.wd_id == 320 || device.wd_id == 410 || device.wd_id == 424 ||
        // 프리미엄 티빙, 프리미엄 티빙 플러스, 프리미엄 넷플릭스, 프리미엄 넷플릭스 UHD
        device.wd_id == 389 || device.wd_id == 388 || device.wd_id == 321 || device.wd_id == 322
      )
    ){ 
      let message = "해당 상품은 메인 회선으로만 가입 가능합니다.";
      
      this.setState({
        error_modal: {
            isShowModal: true,
            message: (<span className="notification_delete">{message}</span>)
        }
      })

      classify[this.state.indexClassify].classify.splice(index, 1);
    }
    else if(index == 0 && options.length > 0 && options[0].wdo_name == "UHD3 셋톱박스"){
      let message = "해당 상품은 추가 회선으로만 가입 가능합니다.";
      
        this.setState({
          error_modal: {
              isShowModal: true,
              message: (<span className="notification_delete">{message}</span>)
          }
        })
  
        classify[this.state.indexClassify].classify.splice(index, 1);
    }
    else{
      classify[this.state.indexClassify].classify[index] = cl;

      if(
        classify[this.state.indexClassify].classify[index] &&
        Object.keys(classify[this.state.indexClassify].classify[index].device).length == 0
      ){
        classify[this.state.indexClassify].classify.splice(index, 1)
      } 
    }

    classify = this.getDeviceOptionSelectedLguplusExcept1(classify);
    classify = this.getDeviceOptionSelectedLguplusExcept2(classify);
    classify = this.getDeviceOptionSelectedLguplusExcept3(classify);
    classify = this.updateClassify(classify)

    this.setStep2(classify)
  }

  getDiscountPrice = (discountDevice, discountOption) => {
    const {indexClassify, indexItem} = this.state
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    let classify = [ ...WiredAdviceStep2.classify ]
    let { device, options } = classify[indexClassify].classify[indexItem]
    let totalDiscountOption = discountOption.reduce((prevFee, option) => {
      return prevFee + option
    }, 0)
    let totlaFeeOption =  options.reduce((prevFee, option) => {
      return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
    }, 0)
    let cl = {
      ...classify[indexClassify].classify[indexItem],
      discount: {
        ...classify[indexClassify].classify[indexItem].discount,
        device: {
          ...classify[indexClassify].classify[indexItem].discount.device,
          additional: discountDevice,
        },
        options: discountOption.map((value, index) => ({
          ...classify[indexClassify].classify[indexItem].discount.options[index],
          additional: value
        })),
        value: (+(device?.wd_commitment_fee || 0) + totlaFeeOption - discountDevice - totalDiscountOption),
      },
    }
    classify[this.state.indexClassify].classify[this.state.indexItem] = cl
    classify = this.updateClassify(classify)
    this.setStep2(classify)
    this.closeModalCost()
  }

  removeItem = () => {
    const { WiredAdvice: { WiredAdviceStep2 } } = this.props
    let classify = [...WiredAdviceStep2.classify]
    classify[this.state.indexClassify].classify.splice(this.state.indexItem, 1)
    this.setStep2(classify)
  }

  render() {
    const { WiredAdvice: { WiredAdviceStep1, WiredAdviceStep2 }, auth } = this.props
    const { indexClassify, indexItem } = this.state;

    return (
      <Fragment>
        <div className="table-responsive table-wired table-wired-custom">
          <table className="table h-100">
            <thead className="thead-table-blue">
            <tr>
              <th className="text-center" style={{width: '10%'}}>구분</th>
              <th>상품</th>
              <th>부가옵션</th>
              <th>월 이용료</th>
              <th>할인 적용</th>
            </tr>
            </thead>
            <tbody>
            {(WiredAdviceStep2?.classify || []).map((cls, key) => {
              {/*if (WiredAdviceStep1.telcomID != LGU_PLUS_ID && cls.id == CLASSIFY.IOT) {*/}
              if (
                (WiredAdviceStep1.telcomID != LGU_PLUS_ID && cls.id == CLASSIFY.IOT)
                || (WiredAdviceStep1.telcomID == LGU_PLUS_ID && cls.id == CLASSIFY.IOT && auth.role !== 'Admin')
              ) {
                return null
              }
              return <ProductClassificationItem
                key={key}
                item={cls}
                addClassify={() => this.addClassify(key)}
                showModalProduct={(indexItem) => this.showModalProduct(key, indexItem)}
                showModalOption={(indexItem) => this.showModalOption(key, indexItem)}
                showModalCost={(indexItem) => this.showModalCost(key, indexItem)}
              />
            })}
            </tbody>
          </table>
        </div>
        <div className="title-total">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span className="font-weight-bold">월 납부 요금 </span>
              <small
                className="text-muted">[3년약정]
              </small>
            </div>
            <div className="text-right">
              <small className="text-muted">(VAT 포함)</small>
              <span className="px-2">
              <span
                className="total-price">{priceFormat(WiredAdviceStep2.monthlyCosts)}</span>
                <span
                  className="price-sub">원</span>
              </span>
            </div>
          </div>
        </div>

        <ModalProduct
          closeModal={this.closeModalProduct}
          isOpenModal={this.state.isShowModalProduct}
          getDeviceOptionSelected={this.getDeviceOptionSelected}
          indexClassify={this.state.indexClassify}
          itemSelected={
            WiredAdviceStep2?.classify[indexClassify] && WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            ? WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            : {}
          }
          wdClass={WD_CLASS[indexClassify]}
          removeItem={this.removeItem}
          network={WiredAdviceStep1}
        />

        <ModalProduct
          closeModal={this.closeModalOption}
          isOpenModal={this.state.isShowModalOption}
          indexClassify={this.state.indexClassify}
          getDeviceOptionSelected={this.getDeviceOptionSelected}
          itemSelected={
            WiredAdviceStep2?.classify[indexClassify] && WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            ? WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            : {}
          }
          wdClass={WD_CLASS[indexClassify]}
          removeItem={this.removeItem}
          network={WiredAdviceStep1}
        />

        <ModalCost
          closeModal={this.closeModalCost}
          isOpenModal={this.state.isShowModalCost}
          itemSelected={
            WiredAdviceStep2?.classify[indexClassify] && WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            ? WiredAdviceStep2?.classify[indexClassify].classify[indexItem]
            : {}
          }
          getDiscountPrice={this.getDiscountPrice}
        />

        <Modal isOpen={this.state.error_modal.isShowModal}
          className="modal-dialog modal-dialog-centered"
          contentClassName="pl-4 pr-4"
          toggle={(e) => this.toggleModal(e, 'error_modal')}
        >
          <div className="modal-body text-center p-5">{this.state.error_modal.message}</div>
          <div className="modal-footer justify-content-center border-top-0 mb-4">
            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={(e) => this.toggleModal(e, 'error_modal')}>확인</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ProductClassification.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReduxForm =  reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

export default compose(
  withConnect,
  withReduxForm,
)(ProductClassification);
