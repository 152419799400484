import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    reduxForm,
    getFormSyncErrors,
    getFormValues,
    change,
} from 'redux-form';
import CombineResultList from './../../Combine/CombineResultList'
import './ResultList.css'

class CombinedResultList extends Component {

  render() {
    const { WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice
    let optionalContractDiscount = WirelessAdviceStep3.optionFee == 0 ? WirelessAdviceStep3.optionalAgreement : 0
    let promotion = WirelessAdviceStep3.promotion?.promo_cost ? +WirelessAdviceStep3.promotion?.promo_cost : 0

    return <CombineResultList
      combinePlans={WirelessAdviceStep3?.combinePlans || []}
      combineCondition={WirelessAdviceStep3?.combineCondition || {}}
      // removeFirst
      telcomID={WirelessAdviceStep3?.phone?.telcomID}
      optionalContractDiscount={optionalContractDiscount}
      promotion={promotion}
      WirelessAdvice={WirelessAdvice}
    />
  }
}

CombinedResultList.propTypes = {
  WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};
const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(CombinedResultList);
