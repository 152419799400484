import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const renderByFormatField = ({ input, meta: { touched, invalid, error }, type, placeholder, min, max, disabled, errorStyle, col_6
},) => {
    return (
        <Fragment>
            <NumberFormat
                {...input}
                type={type}
                className={'form-control ' + (touched && invalid ? 'is-invalid' : '') + (col_6 || '') + ' h-44'}
                placeholder={placeholder}
                min={min}
                max={max}
                disabled={disabled}
                displayType={'input'}
                thousandSeparator={true}
                suffix={"원"}	 />
            {error !== undefined ? (
                <div className="invalid-feedback" style={errorStyle}>
                    {error}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderByFormatField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderByFormatField;
