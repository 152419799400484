

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import MessageJSON from "../../utils/message.json";

class ConfirmDeleteAll extends Component {
    constructor(props) {
        super(props);
        this.state = {         
        }
    }

    componentDidMount() {
    }

    handleConfirm = (e) => {
        e.preventDefault();
        this.props.deletePrintAll()
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                        className="modal-dialog modal-dialog-centered modal-custom-21"
                       // backdrop={"static"}
                >
                    <div className="modal-header border-0">
                        <h5 className="notification_delete text-center w-100">견적 내역을 전체 삭제하시겠습니까?</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="form_password" onSubmit={(e) => this.handleConfirm(e)}> 
                            <div className="w-100 mb-3 text-center modal-footer">
                                <button type="button" className="btn btn_close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                                    취소 
                                </button>                                
                                <button type="submit" className="btn btn_save">
                                    확인 
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        // auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        // openPopupMenu: (data)  => dispatch(openPopupMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ConfirmDeleteAll);
