
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import {priceFormat} from "../../../utils/helper";

class InputMoneyLive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value:'',
        }
    }

    componentDidMount() {

        const { handleSubmit, WirelessAdvice,dataPromotion } = this.props;
        if(WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost) {
            this.setState({value: WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost});
        }
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee == null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {
        //     this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
        // }
        const { handleSubmit, WirelessAdvice,dataPromotion } = this.props;
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost &&
            this.props.WirelessAdvice.WirelessAdviceStep3.promotion.planID !== prevProps.WirelessAdvice.WirelessAdviceStep3.promotion.planID) {
            this.setState({value: WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost});
        }

    }



    handleChangePart = (values) => {
        const {formattedValue, value} = values;
        this.setState({value: value,profit: formattedValue});
    }

    setPart = (e) => {
        e.preventDefault()
        const {WirelessAdvice} = this.props;
        let dataSetValue={planID:'input_live', promo_name:"직접 입력", promo_cost:this.state.value}
        if(this.state.value == '') {
            dataSetValue={planID:'input_live', promo_name:"직접 입력", promo_cost:0}
        }else {
            if(this.state.value == undefined) {

            }else {
                this.props.change('WirelessAdviceStep3.promotion', dataSetValue)
            }

        }
        setTimeout(() => {
            this.props.openPopupPromotion()
        },200)
        this.props.openPopupInputLive()
    }
    valueMoneyInput = (value) => {
        const { handleSubmit, WirelessAdvice,dataPromotion } = this.props;
        let valueMoney=''
        if(value != '') {
            if(value != undefined) {
                valueMoney=value
            }
            return valueMoney
        }else {
            // this.setState({value: WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost});
            valueMoney=WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost
            return valueMoney
        }
    }

    render() {
        const { handleSubmit, WirelessAdvice,dataPromotion } = this.props;

        return (
            <Modal isOpen={this.props.isOpenModalInputLive}
                   id="modal-78"
                   contentClassName={'modal-content pl-4 pr-4'}
                   toggle={this.props.openPopupInputLive} className={'modal-dialog  modal-dialog-centered modal-custom'}>
                <div className="modal-header">
                    <h5 className="modal-title">프로모션 할인 직접 입력</h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopupInputLive}>
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody>
                    <form onSubmit={this.setPart} className="body-plans row_custom">
                        <div className="d-flex justify-content-between align-items-center">
                            {/*<p className="mb-0 text-modal-gray mt-8">할부원금 : {formatter.format(Number(installmentPrincipal)+Number(cashPayment)).replace('$','')} 원</p>*/}
                            {/*<p className="mb-0 text-modal-gray mt-8">할부원금 :  원</p>*/}
                        </div>
                        <div className="form-group row justify-content-between mt-4 align-items-center">
                            <div className="col-10 pr-0">
                                <CurrencyFormat thousandSeparator={true} prefix={''}
                                                value={this.state.value}
                                                onValueChange={(value) => this.handleChangePart(value)}
                                                className="form-control h-44"

                                />

                            </div>
                            <div className="col-2">
                                <span className="text-wrapper-gray">원</span>
                            </div>
                        </div>
                        <h5  className="text-wrapper-gray text-left color-orange mb-3">
                            ※ VAT 포함 금액을 입력해 주세요.
                        </h5>
                        <h5 className="text-wrapper-gray text-left">
                            입력하신 프로모션 할인 금액을 적용하시겠습니까?
                        </h5>
                        <div className="d-flex justify-content-center border-top-0 mt-5">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" onClick={this.props.openPopupInputLive} >취소</button>
                            <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                        </div>
                    </form>
                </ModalBody>


            </Modal>
        );
    }
}

InputMoneyLive.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(InputMoneyLive);
