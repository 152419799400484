import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {reduxForm, Field, getFormValues, FieldArray} from 'redux-form';
import renderTextField from "../../components/FormHelper/TextField";
import renderByFormatField from "../../components/FormHelper/RenderRB";
import renderRB from "../../components/FormHelper/RenderRB/RenderRBField";
import renderRBDeduct from "../../components/FormHelper/RenderRB/RenderRBDeductField";

const SALE_TYPES = ['','동시 판매','번들','단품'];
class CreateReportSaleStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModalCreateReportSale: false,
            isOpenModalChooseType: false,
            startDate: new Date()
        }
    }

    changeMemo = (event) => {
        this.props.change('step1.memo',event.target.value);
    }

    openPopupCreateReportSale = () =>
        this.setState({ isOpenModalCreateReportSale: !this.state.isOpenModalCreateReportSale })

    openPopupChooseType = () =>
        this.setState({ isOpenModalChooseType: !this.state.isOpenModalChooseType })

    componentDidMount() {

    }

    render() {
        const { step2 } = this.props.ReportSale;
        let rbTotal = step2.rebail_basic_price? parseFloat(step2.rebail_basic_price.replace(',','')):0;
        step2.rb.forEach((val,i) => {
            let price = typeof val.price === 'string' || val.price instanceof String ? parseFloat(val.price.replace(',','')) : parseFloat(val.price);
            rbTotal += price || 0
        })
        step2.rbDeduct.forEach((val,i) => {
            let price = typeof val.price === 'string' || val.price instanceof String ? parseFloat(val.price.replace(',','')) : parseFloat(val.price);
            rbTotal += price || 0
        })
        
        let spRecharge = step2.support_recharge ? parseFloat(step2.support_recharge.replace(',','')) : 0
        let spCash = step2.support_cash ? parseFloat(step2.support_cash.replace(',','')) : 0
        let totalSupport = spCash + spRecharge
        this.props.change('step2.total_sp',totalSupport);
        this.props.change('step2.total_rb',rbTotal);
        this.props.change('step2.actual_profit',totalSupport + rbTotal);
        return (
            <div className="container">
                <div className="report-sale-step main-step-box">
                    <ul className="step-wireless">
                        <li className="item-step" data-link="/create-report-sale-3.html">
                            <div className="open-item">
                                <span className="step-name">Step 1</span>
                                <span>개통정보</span>
                            </div>
                        </li>
                        <li className="item-step active">
                            <div className="open-item">
                                <span className="step-name">Step 2</span>
                                <span>차감정보</span>
                            </div>
                        </li>
                    </ul>
                    {this.props.ReportSale.sales_report_id && this.props.consultant.state == 1 ? <button className="btn btn-gray-white h-44 btn-edit-form">판매수정</button> : ''}
                    <div className="box-right">
                        <div className="row">
                            <div className="col-6 text-right">
                                <span className="title">고객명</span>
                            </div>
                            <div className="col-6">
                                <span className="content">{this.props.ReportSale.step1.customer_name}</span>
                            </div>
                            <div className="col-6 text-right">
                                <span className="title">가입유형</span>
                            </div>
                            <div className="col-6">
                                <span className="content">{SALE_TYPES[this.props.ReportSale.step1.open_type - 1]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="form-box mt-5">
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="content-form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="title-box-line mt-5 mb-3">
                                                    <span>R/B 정보 (추가 및 차감)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group ">
                                                            <label htmlFor="" className="label-text">기본 R/B</label>
                                                            <Field type="text" className="form-control h-44"
                                                                   component={renderByFormatField}
                                                                   placeholder="0 원"
                                                                   name={'step2.rebail_basic_price'} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group ">
                                                            <label htmlFor="" className="label-text">단가표호수 </label>
                                                            <Field type="text" className="form-control h-44"
                                                                   component={renderTextField}
                                                                   placeholder="단가표호수"
                                                                   name={'step2.unit_price_code'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <FieldArray name="step2.rb" component={renderRB} />

                                        </div>
                                        <FieldArray name="step2.rbDeduct" component={renderRBDeduct} />

                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-3">
                                                <h5 className="mb-0 label-prices text-right"><span
                                                    className="color-reds">(A)</span>지급받을
                                                    리베이트 총액</h5>
                                            </div>
                                            <div className="col-3 pl-0">
                                                <div className="form-group mb-0">
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                           name={"step2.total_rb"}
                                                           disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="title-box-line mt-3 mb-3">
                                                    <span>매장지원</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-end">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">현금지원</label>
                                                    <Field component={renderByFormatField} type="text" className="form-control h-44"
                                                           name={"step2.support_cash"}
                                                           placeholder="0 원"/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text">요금지원</label>
                                                    <Field type="text" className="form-control h-44" component={renderByFormatField}
                                                           placeholder="0 원" name={"step2.support_recharge"}/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group row align-items-center">
                                                    <label htmlFor=""
                                                           className="label-prices mb-0 col-6 text-right"> <span
                                                        className="color-reds">(B)</span> 매장지원 총액</label>
                                                    <Field type="text" col_6={"col-6"}
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                           name={"step2.total_sp"}
                                                           disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="title-box-line mt-3 mb-3">
                                                    <span>최종마진</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(A)</span> 지급받을 리베이트 총액</label>
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                           name={"step2.total_rb"}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <label htmlFor="" className="label-text"> <span
                                                        className="color-reds">(B)</span> 매장지원 총액</label>
                                                    <Field type="text" className="form-control h-44"
                                                           component={renderByFormatField}
                                                           placeholder="0 원"
                                                           name={"step2.total_sp"}
                                                           disabled/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group mb-0 row align-items-center form-red">
                                                    <label htmlFor=""
                                                           className="label-text color-reds mb-0 col-6 text-right">최종
                                                        실마진</label>
                                                    <Field type="text"
                                                           component={renderByFormatField}
                                                           name={"step2.actual_profit"}
                                                           col_6="col-6"
                                                            disabled/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="main-btn-submit mt-5">
                                            <div className="group-button w-100">
                                                <button className="btn btn-button btn-gray mr-2 ml-2"
                                                        type="button" onClick={this.props.prev}>취소
                                                </button>
                                                <button type="submit"
                                                        className="btn btn-button btn-submit mr-2 ml-2">판매일보
                                                    저장
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ReportSale: getFormValues('ReportSale')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};

const FORM_NAME = 'ReportSale';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(CreateReportSaleStep1);

