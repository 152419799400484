import api from "../../../utils/api";
import MessageJSON from '../../../utils/message';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values, dispatch, props, field) => {
    const errors = {};
    const previousErrors = props.asyncErrors;
    let phone = values.first_store_phone + '-' + values.last_store_phone;
    if(values.email && field == 'email'){
        let dataEmail = {
            email:values.email
        }
        // return api.auth.checkUniqueEmail(dataEmail).then(response => {
        //     if(response.data.status == 0) {
        //         // throw Object.assign({}, previousErrors, {[field]: MessageJSON.signup.validate.email_unique});
        //         throw {
        //             ...previousErrors, 
        //             email: MessageJSON.signup.validate.email_unique 
        //         }
        //     }
        // })
    } else if(values.username.trim() && values.username.length < 4){
        return sleep(0.1).then(() => {
            throw {
                ...previousErrors, 
                ['username']: MessageJSON.signup.validate.username_min
            }
        })
    }
   
    else if(values.username && field == 'username'){
        let dataUsername = {
            username:values.username
        }

        if (values.user_id && values.user_pw) {
            dataUsername.is_old_user = 1
        }
        return api.auth.checkUniqueUsername(dataUsername).then(response => {
            if(response.data.status == 0) {
                throw {
                    ...previousErrors, 
                    [field]: MessageJSON.signup.validate.username_unique
                }
                // throw Object.assign({}, previousErrors, {[field]: MessageJSON.signup.validate.username_unique});
            } 
        })
    }

    
    else if(phone.length > 12 && (field == 'first_store_phone' || field == 'last_store_phone')){
        let dataPhone = {
            phone: values.first_store_phone + '-' + values.last_store_phone
        }
        // return api.auth.checkUniquePhone(dataPhone).then(response => {
           
        //     if(response.data.status == 0) {
        //         throw {
        //             ...previousErrors, 
        //             ['last_store_phone']: MessageJSON.signup.validate.phone_unique
        //         }
        //         //throw Object.assign({}, previousErrors, {['last_store_phone']: MessageJSON.signup.validate.phone_unique});
        //     }
        // })
    } else if(values.last_store_phone.length < 8){
        return sleep(1).then(() => {
            throw {
                ...previousErrors, 
                ['last_store_phone']: MessageJSON.signup.validate.verify_phone_number_check_format
            }
            // throw Object.assign({}, previousErrors, {['last_store_phone']: MessageJSON.signup.validate.verify_phone_number_check_format});
        })
    }
    return Promise.resolve();
}

export default asyncValidate
