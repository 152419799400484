 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from "../../../utils/api";

//
import {postApplyFee} from '../../../store/actions/post_apply_fee';

class ApplyFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      selected: null,
      telcom_id: null,
      type: null,
      plan_id: null
    }
  }

  handleSubmit = () => {
    //this.props.tytolee();

    //this.props.dataFilterAll;
    //console.log("handleSubmit dataFilterAll", this.props.dataFilterAll);

    this.props.postApplyFee(this.props.dataFilterAll).then((data) => {
      //console.log("postApplyFee", data)

      this.props.recallfee();

    }).catch(err => {
      alert("처리에 실패 했습니다. 다시 시도해주세요");
    });

  }

  render() {
    const { WirelessAdvice } = this.props;
    const { consultants } = this.state

    return (
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-reset-product'}
          onOpened={this.modalOpened}
        >
          <div className="">
            <div className="modal-body text-center">
                <span className="notification_delete">해당 요금제로 적용하시겠습니까?</span>
            </div>
            <div className="modal-footer justify-content-center border-top-0">
              <button type="button" className="btn btn_close" onClick={this.props.openPopup}>
                취소
              </button>
              <button type="button" className="btn btn-submit" onClick={this.handleSubmit}>
                확인
              </button>
            </div>
          </div>
        </Modal>
    );
  }
}

ApplyFee.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    postApplyFee: (data) => dispatch(postApplyFee(data)),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(ApplyFee);
