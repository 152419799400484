import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors, reset } from 'redux-form';
import renderTextField from '../FormHelper/TextField';
import validate from './validate';
import {showLoading, hideLoading} from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import {
    loginAction,
} from '../../store/actions/auth';
import * as routerName from '../../utils/routerName';
import { getFirstNumberPhone, getEmailDomains, getFirstFax } from '../../utils/helper';
import ContactForm from './ContactForm'
import ContactFormSubmit from './ContactFormSubmit'
import ModalConfirm from './ModalConfirm'
import ModalTerm from './ModalTerm'
import api from "./../../utils/api";

const CONFIRM_CONTENT = {
    AD_REQUEST: `광고 문의를 접수하시겠습니까?`,
    PROPOSE_THE_COOPERATION: `제휴제안 문의를 접수하시겠습니까?`,
    CONTENT_COMPLAINTS: `불만/오류 문의를 접수하시겠습니까?`,
}

const SUCCESS_CONTENT = {
    AD_REQUEST: `<p class="mb-2">광고 문의가 정상적으로 접수되었습니다.</p>
        <p class="mb-2">확인 후 남겨주신 연락처 또는 메일로 회신 드리겠습니다.</p>
        <p class="mb-2">※입력된 정보가 정확하지 않을 경우 회신이 늦어질 수 있습니다.</p>
    `,
    PROPOSE_THE_COOPERATION: `<p class="mb-2">제휴 제안이 정상적으로 접수되었습니다.</p>
        <p class="mb-2">확인 후 남겨주신 연락처 또는 메일로 회신 드리겠습니다.</p>
        <p class="mb-2">※입력된 정보가 정확하지 않을 경우 회신이 늦어질 수 있습니다.</p>
    `,
    CONTENT_COMPLAINTS: `<p class="mb-2">불만/오류 문의가 정상적으로 접수되었습니다.</p>
        <p class="mb-2">확인 후 남겨주신 연락처 또는 메일로 회신 드리겠습니다.</p>
        <p class="mb-2">※입력된 정보가 정확하지 않을 경우 회신이 늦어질 수 있습니다.</p>
    `,
}

const contact_type = {
    AD_REQUEST: 1,
    PROPOSE_THE_COOPERATION: 2,
    CONTENT_COMPLAINTS: 3,
}

const initialValues = {
    type: '',
    title: '',
    content: '',
    company_name: '',
    proposer_name: '',
    contact_first_phone: getFirstNumberPhone()[0], // 010, 011, 016, 017, 018, 019
    contact_phone: '',
    email: '',
    email_domain: '',
    email_domain_select: '직접입력',
    company_file: '',
    homepage: 'http://',
    fist_fax: getFirstFax()[0], // 02, 051, 053, 032, 062, 042, 052, 044, 031, 033, 043, 041, 063, 061, 054, 055, 064
    fax: '',
    accept_term: false,
    request_file: '',
}

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModalConfirm: false,
            isShowModalTerm: false,
        }
    }

    componentDidMount() {
        const { ContactForm, type } = this.props
        if (ContactForm?.type && contact_type[type] != ContactForm.type) {
            this.props.reset()
        }
    }

    componentDidUpdate() {
        if (this.props.type) {
            this.props.change('type', contact_type[this.props.type])
        }
    }

    onSubmit = () => {
        const values = this.props.ContactForm
        if (!values.accept_term) {
            return;
        }
        this.props.showLoading()
        let data = {
            type: values.type,
            accept_term: values.accept_term,
            company_file: values.company_file,
            company_name: values.company_name,
            contact_phone: values.contact_phone ? (values.contact_first_phone + values.contact_phone.split('-').join('')) : '',
            content: values.content,
            email: values.email + '@' + values.email_domain,
            fax: values.fax ? (values.fist_fax + '-' + values.fax) : '',
            homepage: values.homepage,
            proposer_name: values.proposer_name,
            request_file: values.request_file,
            title: values.title,
        }
        return api.contact.store(data)
            .then(() => {
                this.props.reset()
                this.props.hideLoading()
                this.props.hideModal()
                this.openPopupCreateContactSuccess()
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    openPopupCreateContactSuccess = () => {

        let xhtml = (
            <Fragment>
                <div className="text-center">
                    <div
                        className="notification_delete mb-2"
                        dangerouslySetInnerHTML={{ __html: SUCCESS_CONTENT[this.props.type] }}
                    ></div>
                </div>
            </Fragment>
        );

        let html_footer = (
            <Fragment>
                <button type="button" className="btn btn-submit" onClick={this.props.hideModal}>
                    확인
                </button>
            </Fragment>
        );

        let dataModal = {
            component: xhtml,
            html_footer: html_footer,
            class_footer: 'border-top-0',
            class_n: 'modal-dialog modal-dialog-centered notifi-001',
        }

        this.props.showModal(dataModal);
    }

    openModalConfirm = () => {
        if (!this.props.ContactForm.accept_term) {
            this.setState({ isShowModalTerm: !this.state.isShowModalTerm })
        } else {
            this.setState({ isShowModalConfirm: !this.state.isShowModalConfirm })
        }
    }

    openModalTerm = () => {
        this.setState({ isShowModalTerm: !this.state.isShowModalTerm })
    }

    render() {
        const { component, type } = this.props;
        if (component === 'form') {
            return <ContactForm
                type={type}
                onSubmit={this.onSubmit}
                getFirstNumberPhone={getFirstNumberPhone()}
                getEmailDomains={getEmailDomains()}
                getFirstFax={getFirstFax()}
            />
        }
        if (component === 'submit') {
            return (
                <Fragment>
                    <ContactFormSubmit onSubmit={this.openModalConfirm}/>
                    <ModalConfirm
                        openPopup={this.openModalConfirm}
                        isOpenModal={this.state.isShowModalConfirm}
                        submit={this.onSubmit}
                        content={CONFIRM_CONTENT[type]}
                    />
                    <ModalTerm
                        openPopup={this.openModalTerm}
                        isOpenModal={this.state.isShowModalTerm}
                    />
                </Fragment>
            )
        }
    }
}

Contact.propTypes = {
    hideModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    reset: PropTypes.func,
    openPopupCreateContactSuccess: PropTypes.func,
    component: PropTypes.string,
    type: PropTypes.string,
    ContactForm: PropTypes.object,
    showModal: PropTypes.func,
    change: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        [FORM_NAME]: getFormValues(FORM_NAME)(state)
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        loginAction: (data) => dispatch(loginAction(data)),
        change: () => dispatch(change()),
        reset: () => dispatch(reset()),
    }
};
const FORM_NAME = 'ContactForm';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: initialValues,
    enableReinitialize: false,
    destroyOnUnmount: false,
    validate: validate
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(Contact);
