import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import CreateReportSaleModal from "../../components/AdvisoryHistory/CreateReportSaleModal";

class AdvisoryHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModalCreateReportSale: false,
        }
    }

    openPopupCreateReportSale = () =>
        this.setState({ isOpenModalCreateReportSale: !this.state.isOpenModalCreateReportSale })

    componentDidMount() {

    }


    render() {
        return (
            <Fragment>
                <section>
                    <div className="container">
                        <div className="sales_report">
                            <div className="fill_search">
                                <form action>
                                    <div className="row d-flex justify-content-between align-items-center mb-4">
                                        <div className="col-md-4 d-flex align-items-center">
                                            <a href className="previous d-flex align-items-center justify-content-center"><i className="fas fa-angle-left" /></a>
                                            <div className="dropdown mx-2 form-date">
                                                <a className="btn btn-drop-date dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    08 May, 2020
                                                 </a>
                                                <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/icon-calender.svg"} alt="" />
                                            </div>
                                            <a href className="next d-flex align-items-center justify-content-center"><i className="fas fa-angle-right" /></a>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <input className="form-control search_box bg-transparent h-44 pr-5" type="search" placeholder="고객명 / 전화번호 / 일련번호 " />
                                                <span className="input-group-append h-44">
                                                    <div className="input-group-text border-0 bg-transparent ml-n5"><i className="fa fa-search" /></div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-end">
                                        <div className="col-2">
                                            <select className="form-control h-44">
                                                <option value>판매자 전체</option>
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <select className="form-control h-44">
                                                <option value>단말기 전체 </option>
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <select className="form-control h-44">
                                                <option value>통신사 전체 </option>
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <select className="form-control h-44">
                                                <option value>유형</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table table-borderless table_striped">
                                    <thead className="thead_bg">
                                        <tr>
                                            <th><input type="checkbox" name id /></th>
                                            <th className="text-center">No</th>
                                            <th>통신사</th>
                                            <th>거래처명</th>
                                            <th>유형</th>
                                            <th>모델명</th>
                                            <th>일련번호</th>
                                            <th>요금제 </th>
                                            <th>개통일</th>
                                            <th>고객명</th>
                                            <th>개통번호</th>
                                            <th>판매자</th>
                                            <th className="text-center">
                                                처리
                                                <button className="btn btn-sort sort-active p-0">
                                                    <i className="fas fa-sort" />
                                                </button>
                                            </th>
                                            <th className="text-center">
                                                상태
                                                <button className="btn btn-sort p-0">
                                                    <i className="fas fa-sort" />
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="align-middle"><input type="checkbox" name id /></td>
                                            <td className="align-middle text-center">1</td>
                                            <td className="align-middle">SKT</td>
                                            <td className="align-middle">다음</td>
                                            <td className="align-middle">MNP</td>
                                            <td className="align-middle">SM-G981N 128GB</td>
                                            <td className="align-middle">123456</td>
                                            <td className="align-middle">
                                                <span type="button" data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">T플랜 에센스</span>
                                            </td>
                                            <td className="align-middle">2020-05-27</td>
                                            <td className="align-middle">Hong Gil Dong     </td>
                                            <td className="align-middle">010-1234-1234</td>
                                            <td className="align-middle">Im Seul Gi</td>
                                            <td className="align-middle">
                                                <a href className="btn btn_Modify w-100" data-toggle="modal" data-target="#edit_sales">
                                                    수정
                                                 </a>
                                            </td>
                                            <td className="align-middle finished_appointment">입력대기</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><input type="checkbox" name id /></td>
                                            <td className="align-middle text-center">1</td>
                                            <td className="align-middle">SKT</td>
                                            <td className="align-middle">다음</td>
                                            <td className="align-middle">MNP</td>
                                            <td className="align-middle">SM-G981N 128GB</td>
                                            <td className="align-middle">123456</td>
                                            <td className="align-middle">
                                                <span type="button" data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">T플랜 에센스</span>
                                            </td>
                                            <td className="align-middle">2020-05-27</td>
                                            <td className="align-middle">Hong Gil Dong     </td>
                                            <td className="align-middle">010-1234-1234</td>
                                            <td className="align-middle">Im Seul Gi</td>
                                            <td className="align-middle">
                                                <a href className="btn btn_processing w-100" onClick={this.openPopupCreateReportSale}>
                                                    판매처리
                                                </a>
                                            </td>
                                            <td className="align-middle wait_registration">입력대기</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><input type="checkbox" name id /></td>
                                            <td className="align-middle text-center">1</td>
                                            <td className="align-middle">SKT</td>
                                            <td className="align-middle">다음</td>
                                            <td className="align-middle">MNP</td>
                                            <td className="align-middle">SM-G981N 128GB</td>
                                            <td className="align-middle">123456</td>
                                            <td className="align-middle">
                                                <span type="button" data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">T플랜 에센스</span>
                                            </td>
                                            <td className="align-middle">2020-05-27</td>
                                            <td className="align-middle">Hong Gil Dong     </td>
                                            <td className="align-middle">010-1234-1234</td>
                                            <td className="align-middle">Im Seul Gi</td>
                                            <td className="align-middle">
                                                <a href className="btn btn_Modify w-100" data-toggle="modal" data-target="#edit_sales">
                                                    수정
                                                 </a>
                                            </td>
                                            <td className="align-middle finished_appointment">입력대기</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><input type="checkbox" name id /></td>
                                            <td className="align-middle text-center">1</td>
                                            <td className="align-middle">SKT</td>
                                            <td className="align-middle">다음</td>
                                            <td className="align-middle">MNP</td>
                                            <td className="align-middle">SM-G981N 128GB</td>
                                            <td className="align-middle">123456</td>
                                            <td className="align-middle">
                                                <span type="button" data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">T플랜 에센스</span>
                                            </td>
                                            <td className="align-middle">2020-05-27</td>
                                            <td className="align-middle">Hong Gil Dong     </td>
                                            <td className="align-middle">010-1234-1234</td>
                                            <td className="align-middle">Im Seul Gi</td>
                                            <td className="align-middle">
                                                <a href className="btn btn_Modify w-100" data-toggle="modal" data-target="#edit_sales">
                                                    수정
                                                </a>
                                            </td>
                                            <td className="align-middle finished_appointment">입력대기</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><input type="checkbox" name id /></td>
                                            <td className="align-middle text-center">1</td>
                                            <td className="align-middle">SKT</td>
                                            <td className="align-middle">다음</td>
                                            <td className="align-middle">MNP</td>
                                            <td className="align-middle">SM-G981N 128GB</td>
                                            <td className="align-middle">123456</td>
                                            <td className="align-middle">
                                                <span type="button" data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">T플랜 에센스</span>
                                            </td>
                                            <td className="align-middle">2020-05-27</td>
                                            <td className="align-middle">Hong Gil Dong     </td>
                                            <td className="align-middle">010-1234-1234</td>
                                            <td className="align-middle">Im Seul Gi</td>
                                            <td className="align-middle">
                                                <a href className="btn btn_Modify w-100" data-toggle="modal" data-target="#edit_sales">
                                                    수정
                                                 </a>
                                            </td>
                                            <td className="align-middle finished_appointment">입력대기</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example" className="pagination-notifi">
                                    <ul className="pagination">
                                        <li className="page-item item-previous">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">
                                                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/Left.svg"} alt="" />
                                                </span>
                                            </a>
                                        </li>
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><a className="page-link" href="#">...</a></li>
                                        <li className="page-item"><a className="page-link" href="#">16</a></li>
                                        <li className="page-item item-next">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">
                                                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/Right.svg"} alt="" />
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <CreateReportSaleModal
                    openPopup={this.openPopupCreateReportSale}
                    isOpenModal={this.state.isOpenModalCreateReportSale}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(AdvisoryHistory);

