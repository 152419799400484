import React, { Component, Fragment } from 'react';
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, 
    reduxForm, 
    FieldArray, 
    getFormSyncErrors, 
    getFormValues, 
    formValueSelector,
    isDirty,
    isPristine,
    isValid,
    isInvalid,
    getFormMeta
 } from 'redux-form';
import validate from './validate-step-1.js';
import {showLoading, hideLoading} from '../../../store/actions/ui'
import api from "../../../utils/api";
import * as routerName from '../../../utils/routerName';
import * as constant from '../../../utils/constant';

const normalize3Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
}
const normalize5Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 5) {
        return onlyNums
    }
}
const normalize2Number = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
}
class SignUpStep1Form extends Component {
    render() {
        const { error, handleSubmit, pristine, reset, submitting, SignUpStep1, onChangeBussinessTypeNull } = this.props;
        return (
            <section className="form-register">
            <form  autoComplete="on" onSubmit={handleSubmit}>
            <div className="content-form form-register">
                <div className="container">
                    <div className="form-group">
                        <label htmlFor="" className="label-text">가입유형</label>
                        <div className="group-radio">
                            <div className="custom-control custom-radio">
                                <Field 
                                    id="member_type_1" 
                                    name="member_type" 
                                    default={'1'}
                                    component={renderRadioField}
                                    type="radio" 
                                    value={constant.MEMBER_TYPE.NEW_MEMBER}
                                    checked={SignUpStep1 && parseInt(SignUpStep1.member_type)}
                                    className="custom-control-input"
                                    onChange={onChangeBussinessTypeNull } /> 
                                <label className="custom-control-label" htmlFor="member_type_1">
                                    신규가입
                                </label>
                            </div>
                            <div className="custom-control custom-radio">
                                <Field 
                                    id="member_type_0"  
                                    name="member_type" 
                                    component={renderRadioField}
                                    type="radio" 
                                    value={constant.MEMBER_TYPE.OLD_MEMBER}
                                    checked={SignUpStep1 && parseInt(SignUpStep1.member_type)}
                                    className="custom-control-input"
                                    onChange={onChangeBussinessTypeNull } />
                                <label className="custom-control-label" htmlFor="member_type_0">
                                    기존 모비고 회원
                                </label>
                            </div>
                        </div>
                    </div>

                    {SignUpStep1 && parseInt(SignUpStep1.member_type) === constant.MEMBER_TYPE.NEW_MEMBER && <Fragment>
                        <div className="form-group">
                            <label htmlFor="" className="label-text">사업자유형</label>
                            <div className="group-radio">
                                <div className="custom-control custom-radio">
                                    <Field 
                                        id="business_type_1" 
                                        name="business_type" 
                                        default={1}
                                        component={renderRadioField}
                                        type="radio" 
                                        value="1"
                                        checked={SignUpStep1 && parseInt(SignUpStep1.business_type)}
                                        className="custom-control-input"
                                        onChange={onChangeBussinessTypeNull} /> 
                                    <label className="custom-control-label" htmlFor="business_type_1">법인/개인 사업자</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <Field 
                                        id="business_type_0"  
                                        name="business_type" 
                                        component={renderRadioField}
                                        type="radio" 
                                        value="0" 
                                        checked={SignUpStep1 && parseInt(SignUpStep1.business_type)}
                                        className="custom-control-input"
                                        onChange={onChangeBussinessTypeNull} />
                                    <label className="custom-control-label" htmlFor="business_type_0">기타 (사업자번호가 없는 경우)</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-5">
                            <label htmlFor="" className="label-text">사업자명(단체명)</label>
                            <Field
                                className="form-control h-44" 
                                placeholder="사업자명(단체명)" 
                                name="business_name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className={"form-group " + (this.props.SignUpStep1.business_type == constant.BUSINESS_ORTHER ? "d-none" : "")}>
                            <label htmlFor="" className="label-text">사업자 등록번호</label>
                            <div className={"row verify"}>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number[0]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize3Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep1.business_type == constant.BUSINESS_ORTHER }
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number[1]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize2Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep1.business_type == constant.BUSINESS_ORTHER }
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-3 pr-0">
                                    <Field
                                        className="form-control h-44" 
                                        name="registration_number[2]"
                                        type="text"
                                        component={renderTextFields}
                                        normalize={normalize5Number}
                                        onChange={this.props.onChangeVerifyRegistration}
                                        disabled={ this.props.SignUpStep1.business_type == constant.BUSINESS_ORTHER }
                                       
                                    />
                                    {/* <input type="text" className="form-control h-44" /> */}
                                </div>
                                <div className="col-md-3 col-6 mt-10">
                                    <button className="btn btn-verify h-44" type='button' onClick={() => this.props.onClickVerifyBussiness()}>
                                        사업자 확인
                                    </button>
                                </div>
                            </div>
                            {  this.props.SignUpStep1GetFormMeta && this.props.SignUpStep1GetFormMeta.registration_number && this.props.SignUpStep1Errors && this.props.SignUpStep1Errors.registration_number_msg ? (
                                <p style={{color:'#dc3545', fontSize:'80%'}}>{this.props.SignUpStep1Errors.registration_number_msg}</p>
                            ) : ''}
                            {this.props.SignUpStep1Errors.registration_number && this.props.SignUpStep1Errors.registration_number.length == 0 ? 
                            (this.props.registration_number.registration_verify_status == true ? 
                                <p style={{color:'green'}}>{this.props.registration_number.registration_verify_msg}</p>
                            :
                                <p style={{color:'#dc3545'}}>{this.props.registration_number.registration_verify_msg}</p>
                            ): ('')}
                            
                        </div>

                        <div className={"form-group " + (this.props.SignUpStep1.business_type == constant.BUSINESS_ORTHER ? "d-none" : "")}>
                            <label htmlFor="" className="label-text">계산서 발행 이메일 주소</label>
                            <Field
                                className="form-control h-44" 
                                placeholder="계산서 발행 이메일 주소" 
                                name="business_email"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className="group-button">
                            <button className="btn btn-close-register" type="button" onClick={this.props.redirectLogin}>가입 취소</button>
                            <button className="btn btn-next" type="submit">다음 단계</button>
                        </div>
                    </Fragment>}

                    {this.props.SignUpStep1 && parseInt(this.props.SignUpStep1.member_type) === constant.MEMBER_TYPE.OLD_MEMBER && <Fragment>

                        <div className="form-group">
                            <p>기존 모비고에서 로그인정보를 인증해주세요.</p>
                            <p>모비고에 등록된 회원정보로 간편하게 가입 가능합니다.</p>
                        </div>

                        <div className="form-group mt-5">
                            <label htmlFor="" className="label-text">모비고 아이디</label>
                            <Field
                                className="form-control h-44" 
                                placeholder="모비고 아이디" 
                                name="user_id"
                                type="text"
                                component={renderTextField}
                            />
                        </div>

                        <div className={"form-group "}>
                            <label htmlFor="" className="label-text">모비고 비밀번호</label>
                            <Field
                                className="form-control h-44" 
                                placeholder="모비고 비밀번호" 
                                name="user_pw"
                                type="password"
                                component={renderTextField}
                            />
                        </div>

                        <div className="group-button">
                            <button className="btn btn-next" type="submit">
                                인증하기
                            </button>
                        </div>

                        <div className="group-button">
                            <button className="btn btn-close-register" type="button" onClick={this.props.redirectLogin}>
                                가입 취소
                            </button>
                            <button className="btn btn-next" type="button" onClick={handleSubmit(this.props.onNextStepOldMember)}>
                                다음
                            </button>
                        </div>
                    </Fragment>}
                </div>
            </div>
        </form>
        </section> 
        );
    }
}

SignUpStep1Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        SignUpStep1: getFormValues('SignUp')(state),
        SignUpStep1Errors: getFormSyncErrors('SignUp')(state),
        SignUpStep1GetFormMeta: getFormMeta('SignUp')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'SignUp';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate, 
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(SignUpStep1Form);
