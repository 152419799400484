import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Modal} from 'reactstrap';
import moment from "moment";

class SignInAlertPopupPaidVersion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_check: false,
        };

        this.setupDontShowInDay = this.setupDontShowInDay.bind(this);
        this.isShowPopup = this.isShowPopup.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
    }

    setupDontShowInDay(event) {
        this.setState({
            is_check: !this.state.is_check
        });
    }

    onClose(event) {
        if (this.state.is_check !== false) {
            localStorage.setItem("show_signin_alert_popup_paid_version", moment().format('YYYY-MM-DD 00:00'));
        } else {
            localStorage.removeItem("show_signin_alert_popup_paid_version");
        }
    }

    isShowPopup() {
        if (this.props.isOpen === true) {
            if (!localStorage.getItem("show_signin_alert_popup_paid_version")) {
                return true;
            }

            const checkisNewDay = moment(localStorage.getItem("show_signin_alert_popup_paid_version"));
            if (checkisNewDay.diff(moment(), 'day') > 0) {
                return true;
            }
        }

        return false;
    }

    redirectToSignUp = () => {
        this.props.history.push('sign-up')
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.isShowPopup()}
                       id={"modal-mobicell-02"}
                       className="modal-dialog-centered modal-mobicell-02"
                       // backdrop={"static"}
                       toggle={this.props.toggle}
                       onClosed={this.onClose}
                >
                    <div className="modal-body">
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src="/images/icon-close.svg" alt=""/>
                        </button>
                        <p className="text-mobicell">모비셀 <span>유료화</span> 오픈!</p>
                        <h2>NO.1 휴대폰 판매 솔루션 <br />
                            모비셀이 새로워졌습니다!</h2>
                        <p className="text-mobicell">모비셀 회원가입 후 새로운 솔루션을 경험해보세요</p>
                        <button className="btn btn-mobicell" onClick={this.redirectToSignUp}>
                            한 달 무료 체험하기
                        </button>
                        <div className="form-group form-check">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" className="custom-control-input" id="123123123" defaultChecked={this.state.is_check} onChange={this.setupDontShowInDay}/>
                                <label className="custom-control-label" htmlFor="123123123">하루동안 보지 않기</label>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        // showLoading: () => dispatch(showLoading()),
        // hideLoading: () => dispatch(hideLoading()),
        // change: () => () => dispatch(change())
    }
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SignInAlertPopupPaidVersion);
