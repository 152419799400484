import React, { Component, Fragment } from 'react';
import CKEditor4 from "react-ckeditor-component";
 import CKEditor5 from '@ckeditor/ckeditor5-react';
import MyUploadAdapter from './MyUploadAdapter';
import FullEditor from 'ckeditor5-build-full';
import axios from '../../../utils/axios';
export default class renderCkeditorField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            change: false,
            files:[]
        }
    }

    handleChange = (e) => {
        const { input } = this.props
        // input.onChange(e.editor.getData())
        input.onChange(e.editor.getData())
        this.setState({
            change:true
        })
    };

    uploadFiles = (image) => {
    }

    render() {
        
        const { input: { value, name } } = this.props
        
        const { input, required, meta: { touched, invalid, error } } = this.props  //whatever props you send to the component from redux-form Field

        return (
            <div className="form-row">
                <div className="form-group col-md-12">
                    <CKEditor4
                        {...input}
                        activeClass={"p10 " + ((this.state.change || touched) && invalid ? 'is-invalid box-invalid' : '') }
                        content={input.value}
                        
                        config={{
                            'toolbar': 'Standard',
                            'extraPlugins': 'autogrow,justify,font,embed,colorbutton,colordialog',
                            'autoGrow_minHeight': 400,
                            'autoGrow_maxHeight': 1000,
                            'autoGrow_bottomSpace': 50,
                            'removePlugins': 'resize',
                            'embed_provider':'//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                            filebrowserUploadUrl: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD) + 'api/v1/attachments/upload-file-ckeditor',
                        }}
                        scriptUrl={'https://cdn.ckeditor.com/4.13.1/standard-all/ckeditor.js'}
                    events={{
                        change: event => this.handleChange(event)
                    }}
                    />
                    {/* <div className={" Editor-content p10 " + ((this.state.change || touched) && invalid ? 'is-invalid box-invalid' : '') }>
                        <CKEditor5
                            {...input}
                            editor={ FullEditor }
                           
                            data={input.value}
                            onInit={editor => {
                                editor.plugins.get("FileRepository").createUploadAdapter = function(loader) {
                                   return  new MyUploadAdapter(loader);
                                };
                                editor.conversion.for('dataDowncast').elementToElement({
                                    model: 'media',
                                    view: (modelElement, writer) => {
                                      const url = modelElement.getAttribute('url');
                                
                                      const emptyElement = writer.createEmptyElement('iframe', {src: url});
                                      const paragraph = writer.createContainerElement('p');
                                
                                      writer.insert(writer.createPositionAt(paragraph, 0), emptyElement);
                                
                                      return paragraph;
                                    },
                                  });
                                
                             }}
                            onChange={ ( event, editor ) => {
                                this.handleChange(editor.getData())
                            } }
                            onBlur={ editor => {
                            } }
                            onFocus={ editor => {
                            } }
                            
                        />
                    </div> */}
                    
                    {error !== undefined ? (
                        <div className="invalid-feedback " style={{position:'relative', top:'25px'}}>
                            {error}
                        </div>
                    ) : ''}
                </div>
            </div>
        )
    }
}
