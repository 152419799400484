
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputMoneyLive from './InputMoneyLive'
import {priceFormat} from "../../../utils/helper";

class PromotionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpenModalInputLive:false
    }
  }

  renderPromotionDuration = (data) => {
    const {WirelessAdvice, type} = this.props;
    let activeOne;
    if (type && type == "one" && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne == null) {
        activeOne = true;
    } else {
        activeOne = false;
    }

    let activeTwo;
    if (type && type == "two" && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo == null) {
        activeTwo = true;
    } else {
        activeTwo = false;
    }
    return (
        <Fragment>
        <li className={`${activeOne || activeTwo ? 'active' : ''}`} onClick={() => this.handlePromotionDiscount(null)}>
            미적용
        </li>
        {this.renderDurationDiscount(data.promotion_duration_discount)}
        </Fragment>
    );
  }

  renderDurationDiscount = (data) =>{
    const {WirelessAdvice, type} = this.props;
    let active;

    if (data && data.length > 0) {
        return (data.map((item, key) => {
            return (
                <Fragment key={key}>
                <li className={`text-sale ${(type && type == "one" && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne == item.discount_price) || (type && type == "two" && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo == item.discount_price) ? 'active' : ''}`} onClick={() => this.handlePromotionDiscount(item.discount_price)}>
                    {item.discount_name} ({priceFormat(item.discount_price)}<span>원 할인</span>)   
                </li>
                </Fragment>
            );
        }));
    }
  }

  handlePromotionDiscount = (price) => {
      this.props.getPromotionDiscount(price);
  }

  render() {
    const { handleSubmit, WirelessAdvice,promotionDuration } = this.props;

    return (
        <Fragment>
            <Modal isOpen={this.props.isOpenModalPromotion}
                // backdrop={"static"}
                   contentClassName={'modal-content pl-4 pr-4'}
                   toggle={this.props.openPopupPromotion} 
                   className={'modal-dialog modal-dialog modal-dialog-centered modal-additional '}>


                <div className="modal-header">
                    <h5 className="modal-title">{promotionDuration.promo_duration_name}
                        <span className="text-content font-13">~ {promotionDuration.promo_duration_time}</span> <br />
                        <span className="text-content font-13">
                            ({promotionDuration.promo_duration_endow})
                        </span>
                    </h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopupPromotion}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody>
                    <ul className="list-additional list-sale-01">
                        
                        {this.renderPromotionDuration(promotionDuration)}
                    </ul>
                </ModalBody>
            </Modal>
            <InputMoneyLive openPopupPromotion={this.props.openPopupPromotion} isOpenModalInputLive={this.state.isOpenModalInputLive}
                            openPopupInputLive={this.openPopupInputLive} />
        </Fragment>

    );
  }
}

PromotionModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(PromotionModal);
