 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../../store/actions/modal';
import  StartingPriceModelForm  from './StartingPriceModelForm';

class WirelessStartingPrice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false,
            startingPrice: '',
            isChangeInput : false,
            errMsg : null
        }
    }
    

    resetStartingPrice = () => {
        this.setState({
            startingPrice: this.props.WirelessAdvice.WirelessAdviceStep3Default.startingPrice
        })
    }
    
    formatCurrency = (number, separate = ",") => {
        var string = number.toString();
        var regex = /\B(?=(\d{3})+(?!\d))/g;
        var res = string.replace(/,/g, '').replace(regex, separate);
        return res;
    }

    onChangeInput = (e) => {
        let value = e.target.value;
        let intNumber = value.split(',').join('');
        let errMsg = null;
        var regex=/^[0-9]+$/;
        if (intNumber.length > 10) {
            errMsg = 'Value is greater than 10 characters !';
        } else if (value && !intNumber.match(regex)) {
            errMsg = 'Type value only number !';
        }
        this.setState({
            startingPrice : this.formatCurrency(value),
            isChangeInput : true,
            errMsg : errMsg
        });
    }

    
    popupShowChangeStartingPrice = () => this.setState({isOpenModal: !this.state.isOpenModal})

    getValueInpuStartingPrice = () => {
        if (this.state.isChangeInput) {
            return this.formatNumberShow(this.state.startingPrice) ;
        } else {
            return this.state.startingPrice ? this.formatNumberShow(this.state.startingPrice) :  this.formatNumberShow(this.props.WirelessAdvice.WirelessAdviceStep3.startingPrice) ;
        }
    }

    showErrorMessage = (errMsg) => {
        return(<span className={'invalid-feedback d-block'}>{errMsg}</span>);
    }

    formatNumberShow = (number) => {
        if (!number) {
            return 0
        }
        if(Number(number) == number && number % 1 === 0){
            return this.formatCurrency(Number(number).toFixed(0));
        } else {
            return number;
        }
    }

    render() {
        return (
            <Fragment>
                <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">출고가</label>
                    <div className="col-sm-8">
                        <button className="btn btn-input-form mb-0" type="button"  onClick={this.popupShowChangeStartingPrice}>
                            {this.formatNumberShow(this.props.WirelessAdvice.WirelessAdviceStep3.startingPrice)}원
                    </button>
                    </div>
                </div>
                <StartingPriceModelForm 
                    isOpenModal={this.state.isOpenModal}  
                    popupShowChangeStartingPrice={this.popupShowChangeStartingPrice} 
                    onChangeInput={this.onChangeInput}
                    startingPriceDefault={this.formatNumberShow(this.props.WirelessAdvice && this.props.WirelessAdvice.WirelessAdviceStep3Default &&  this.props.WirelessAdvice.WirelessAdviceStep3Default.startingPrice) }
                    startingPrice={this.state.isChangeInput ? this.state.startingPrice :( this.state.startingPrice )? this.state.startingPrice : this.formatNumberShow(this.props.WirelessAdvice.WirelessAdviceStep3.startingPrice) }
                    getValueInpuStartingPrice={this.getValueInpuStartingPrice }
                    errMsg={this.state.errMsg}
                    resetStartingPrice={this.resetStartingPrice}
                    showErrorMessage={this.showErrorMessage}
                    />
            </Fragment>
            
        );
    }
}

WirelessStartingPrice.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showModal: (data) => dispatch(showModal(data)),
        changeModalContent: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
    }
};

const withReduxForm = reduxForm({
    form: 'WirelessAdvice',
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessStartingPrice);
