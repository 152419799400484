 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat,chooseOption2G} from './../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";

class MutualServiceDetailImageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidUpdate(prevProps) {

    }



    render() {
        const {handleSubmit, WirelessAdvice,dataImage} = this.props;
        let arrayImage=dataImage && dataImage != null ? dataImage : ''
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpenServiceDetailModal}
                       // backdrop={"static"}
                       toggle={this.props.openPopupServiceDetail} fade={true}
                       className={'modal-dialog modal-dialog-centered modal-lg modal-images'}
                       contentClassName="modal-content pl-4 pr-4">
                    <div className="modal-header border-0">
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopupServiceDetail}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <ModalBody className="discount-card">
                        <div id="carouselExampleIndicators" className="carousel slide slider-images" data-ride="carousel">
                            <ol className="carousel-indicators dots-carousel">

                                {arrayImage && arrayImage.length > 0 && arrayImage.map((item, i) =>
                                    <Fragment key={i}>
                                        <li data-target="#carouselExampleIndicators" className={`${i == 0 ? 'active' : ''}`} data-slide-to={i}><span></span></li>
                                    </Fragment>
                                )}
                            </ol>
                            <div className="carousel-inner">
                                {arrayImage && arrayImage.length > 0 && arrayImage.map((item, i) =>
                                    <Fragment key={i}>
                                        <div className={`${i == 0 ? 'carousel-item active' : 'carousel-item'}`}>
                                            <img src={item.images} className="d-block" alt="..."/>
                                        </div>
                                    </Fragment>
                                )}

                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span></span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span></span>
                            </a>
                        </div>
                    </ModalBody>


                </Modal>
            </Fragment>
        );
    }
}

MutualServiceDetailImageModal.propTypes = {};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(MutualServiceDetailImageModal);
