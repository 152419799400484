import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import ui from './ui';
import modal from './modal';
import dataStore from './dataStore';
import plan from './plan';

const rootReducer = combineReducers({
  auth: auth,
  ui: ui,
  modal: modal,
  form: formReducer,
  dataStore: dataStore,
  plan : plan,
});

export default rootReducer;
