
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
import OpenPopupDiscountCard from "./OpenPopupDiscountCard";
import {getDiscountCard} from '../../../store/actions/discount_card';


class WirelessAdvancedDiscountCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenDiscountCard: false,
      discountCard: [],
      data: null
    }
  }

  componentDidMount() {
    let data = {
      light_and_save_card : true,
      filter_telcomID : this.props.WirelessAdvice.WirelessAdviceStep1.telcomID
    }
    this.props.discountCard(data).then(res => {
      this.setState({
        discountCard : res
      })
    })
  }


  openPopupDiscountCard = () => {
    if(+(this.props.WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
      alert("더이상 할인옵션을 추가하실 수 없습니다.");
    }
    else{
      this.setState({isOpenDiscountCard: !this.state.isOpenDiscountCard});
    }
  }

  isSubmit = (data) => {
    this.props.change('WirelessAdviceStep3.advancedDiscountCardObject', data);
    this.props.change('WirelessAdviceStep3.advancedDiscountCard', data.total);
    this.props.change('WirelessAdviceStep3.advancedDiscountCardID', data.itemData.dcc_id);
    this.props.change('WirelessAdviceStep3.advancedDiscountCardMonth', data.month);
    this.props.change('WirelessAdviceStep3.advancedDiscountCardOption',data.advanced_discount_card_option);

    this.setState({
      data : data
    })

    setTimeout(()=>{
      this.openPopupDiscountCard();
      }, 100);
  }

  render() {
    const { WirelessAdvice }       = this.props;
    let wirelessAdviceStep3        = WirelessAdvice?.WirelessAdviceStep3;
    let advancedDiscountCard       = wirelessAdviceStep3.advancedDiscountCard;
    let usedPhoneCompensation      = wirelessAdviceStep3?.usedPhoneCompensation;
    let advancedDiscountCardObject = wirelessAdviceStep3?.advancedDiscountCardObject;
    let advancedDiscountCardMonth  = wirelessAdviceStep3?.advancedDiscountCardMonth;
    let tupId                      = +(usedPhoneCompensation?.tup_id || 0);
    let disabled                   = "";

    if(tupId == 192){ // KT S23 프리미엄 Y 에디션이고 선택약정이면
      disabled = "disabled";
    }
    
    return (
        <Fragment>
          <div className="form-group row align-items-start">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mt-13">선할인 카드</label>
            <div className="col-sm-8">
              <button
                  className={`btn btn-input-form form-control mb-0 ${advancedDiscountCard ? 'btn-prices-wp' : ''}`}
                  type="button"
                  // data-toggle="modal"
                  // data-target="#modal-09"
                  onClick={this.openPopupDiscountCard}
                  // disabled={this.state.discountCard.length > 0 ? false : true}
                  disabled={ disabled }
              >
                <span className={`${advancedDiscountCard ? '' : 'opacity-disabled'}`}>{advancedDiscountCard ? '-'+priceFormat(advancedDiscountCard) +'원': '미적용'}</span>
              </button>
              {advancedDiscountCard ?
                  <p className="small-color mb-0 pl-2">
                    {advancedDiscountCardObject?.itemData?.dcc_name} / {advancedDiscountCardMonth}개월
                  </p>
                  : ''}

            </div>
          </div>
          <OpenPopupDiscountCard
              isOpenDiscountCard={this.state.isOpenDiscountCard}
              openPopupDiscountCard={this.openPopupDiscountCard}
              discountCard={this.state.discountCard}
              isSubmit={this.isSubmit}
              titleDetail={"라이트 할인카드"}
              type={'light_and_save_card'}
              telcomID={this.props.WirelessAdvice.WirelessAdviceStep1.telcomID}
          />
        </Fragment>
    );
  }
}

WirelessAdvancedDiscountCard.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    discountCard: (data) => dispatch(getDiscountCard(data)),
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessAdvancedDiscountCard);
