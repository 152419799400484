import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {reduxForm, Field, getFormValues} from 'redux-form';
import renderTextField from "../../components/FormHelper/TextField";
import SaleTypeModal from "../../components/ReportSale/SaleTypeModal";
import DatePicker from "react-datepicker";
import api from "../../utils/api";
import moment from "moment";
import renderReportCode from "../../components/FormHelper/ReportCodeField";
import $ from 'jquery';

const SALE_TYPES = ['개통유형','동시 판매','번들','단품'];
var tableHtml = null;
class CreateReportSaleWiredStep1 extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isOpenModalSaleType: false,
            locations: [],
            index: 0,
            open_type_txt: '',
            maxID: '',
            numCol: 0,
            maxLength: 0
        }
    }

    changeOpenCode = (val) => {
        let {step1} = this.props.ReportSale;
        this.props.change('step1.open_code',val.target.value);
        api.consultant.checkOpenCode({
            code: val.target.value,
            storeId: this.props.ReportSale.sales_report_id,
            type: this.props.ReportSale.type
        })
            .then((res) => {
                if (!res.data.data) {
                    this.props.change('checkReportCode',true);
                } else {
                    this.props.change('checkReportCode',false);
                }
            });

    }

    changeMemo = (event) => {
        this.props.change('step1.memo_note',event.target.value);
    }

    openPopupSaleType = () =>
        this.setState({ isOpenModalSaleType: !this.state.isOpenModalSaleType })

    setOpenType = (val,txt) => {
        this.setState({
            open_type_txt: txt
        })
        this.props.change('step1.open_type',val);
    }

    componentDidMount() {

        api.consultant.getLocations({
            sto_id: this.props.auth.store.sto_id,
            telcomID: this.props.telcomId
        })
            .then(res => {
                this.setState({
                    locations: res.data.data
                })
                let {step1} = this.props.ReportSale;
                if(res.data.data.length){
                    if(step1.store_location_id){
                        this.props.change('step1.store_location_id',res.data.data[0].stl_id);
                    }
                    for(let i=0;i<res.data.data.length;i++) {
                        if(res.data.data[i].stl_id == step1.store_location_id) {
                            this.setState({
                                index: i
                            })
                        }
                    }
                }
            }).catch(error => {
        });
        tableHtml = this.renderTableHtml(this.props.consultant);
        if (this.props.ReportSale.step1.open_type) {
            this.setState({
                open_type_txt: SALE_TYPES[this.props.ReportSale.step1.open_type - 1]
            })
        }
        document.body.classList.add("body_wireless_advice");
    }

    getIndexLocation = (index) => {
        this.setState({
            index: index
        })
        
    }

    handleSubmit = () => {
        this.props.history.push("/create-report-sale");
    }

    handleChangeDate = date => {
        if (date) {
            this.setState({
                startDate: date
            });
            this.props.change('step1.open_day',date);
        }
    };

    combineStr(data){
        let str = ''
        if (data) {
             str = data.device?.wd_name || '';
            if (data.option && data.option?.wdo_name != '미적용') {
                str += "+" + data.option?.wdo_name
            }
        }
        return str;
    }

    markupTable = (maxID, numCol)=>{
        $('.wired_option_table_report').css('width', '100%')
        if(numCol > 1){
            // let w = $('#'+maxID).width()
            // console.log(w)
            $('.col_value').css('width', this.state.maxLength+ 40 +'px')
            // $('.mt-col').css('margin-top', '40')
        }
         else {
            $('.col_value').css('width', '102%')
        }
        
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    renderTableHtml(consultant){
        let arr = [
            [],[],[],[]
        ];
        if (Array.isArray(consultant.consultant_device_options)) {
            for (let i=0; i < consultant.consultant_device_options.length; i++) {
                switch (+consultant.consultant_device_options[i].type) {
                    case 1:
                        arr[0].push(consultant.consultant_device_options[i])
                        break;
                    case 2:
                        arr[1].push(consultant.consultant_device_options[i])
                        break;
                    case 3:
                        arr[2].push(consultant.consultant_device_options[i])
                        break;
                    case 4:
                        arr[3].push(consultant.consultant_device_options[i])
                        break;
                }
            }
        }
        let data = arr[0];
        for (let i=1; i < arr.length; i++) {
            if (data.length < arr[i].length) {
                data = arr[i];
            }
        }

        let maxLength = 0;
        let maxID = '';

        let col1= data.map((item,idx) => {
            return <td className="py-0"><label className="label-text mb-0 col_value" style={{position: 'relative', bottom: -3}}>인터</label></td>
        });
         let numCol = data.length;
        let col2=data.map((item,idx) => {
            let str = this.combineStr(arr[0][idx]);
            if(str.length > maxLength) {
                maxLength = str.length;
                maxID = "col2-"+idx;
            }
            return <td><input id={"col2-"+idx} className="form-control h-44 col_value" value={this.combineStr(arr[0][idx])} disabled></input></td>
        });
        let col3=data.map((item,idx) => {
            return <td className="py-0"><label className="label-text mb-0 col_value" style={{position: 'relative', bottom: -3}}>TV</label></td>
        });
        let col4=data.map((item,idx) => {
            let str = this.combineStr(arr[1][idx]);
            if(str.length > maxLength) {
                maxLength = str.length;
                maxID = "col4-"+idx;
            }
            return <td><input id={"col4-"+idx} className="form-control h-44 col_value" value={this.combineStr(arr[1][idx])} disabled></input></td>
        });
        let col5=data.map((item,idx) => {
            return <td className="py-0"><label className="label-text mb-0 col_value" style={{position: 'relative', bottom: -3}}>전화</label></td>
        });
        let col6=data.map((item,idx) => {
            let str = this.combineStr(arr[2][idx]);
            if(str.length > maxLength) {
                maxLength = str.length;
                maxID = "col6-"+idx;
            }
            return <td><input id={"col6-"+idx} className="form-control h-44 col_value" value={this.combineStr(arr[2][idx])} disabled></input></td>
        });
        let col7=data.map((item,idx) => {
            return <td className="py-0"><label className="label-text mb-0 col_value" style={{position: 'relative', bottom: -3}}>IoT</label></td>
        });
        let col8=data.map((item,idx) => {
            let str = this.combineStr(arr[3][idx]);
            if(str.length > maxLength) {
                maxLength = str.length;
                maxID = "col8-"+idx;
            }
            return <td><input id={"col8-"+idx} className="form-control h-44 col_value" value={this.combineStr(arr[3][idx])} disabled ></input></td>
        });

        if(maxLength < 25){
            maxLength = 200;
        } else {
            maxLength *= 8;
        }
        
        this.setState({
            numCol,maxID,maxLength
        });
        return <div className="col-md-8">
        <div className={numCol > 1 ? "row flex-row-custom table-responsive" : "row"} style={{width: "103%"}}>
        <table className="wired_option_table_report">
            <tr>
                {col1}
            </tr>
            <tr>
                {col2}
            </tr>
            <tr>
                
                    {col3}
                
            </tr>
            <tr>
                
                    {col4}
                
            </tr>
            <tr>
                
                    {col5}
                
            </tr>
            <tr>
                
                    {col6}
                
            </tr>
            <tr>
                
                    {col7}
                
            </tr>
            <tr>
                
                    {col8}
                
            </tr>
        </table>
            {/* {htmlDeviceOptions} */}
        </div>
    </div>
    }

    render() {
        const {consultant} = this.props;
        let {step1} = this.props.ReportSale;

        const locationHtml = this.state.locations.length ? this.state.locations.map((item,idx) => {
            return <p key={idx} className="dropdown-item" onClick={() => this.getIndexLocation(idx)}>{item.stl_name}</p>
        }) : '';
        if(this.state.locations?.length && this.state.index) {
            this.props.change('step1.store_location_id',this.state.locations[this.state.index].stl_id);
        }
        
        

        return (
            <div className="container">
                <div className="report-sale-step main-step-box">
                    <ul className="step-wireless">
                        <li className="item-step active">
                            <div className="open-item">
                                <span className="step-name">Step 1</span>
                                <span>개통정보</span>
                            </div>
                        </li>
                        <li className="item-step">
                            <div className="open-item">
                                <span className="step-name">Step 2</span>
                                <span>차감정보</span>
                            </div>
                        </li>
                    </ul>
                    {this.props.ReportSale.sales_report_id && this.props.consultant.state == 1 ? <button className="btn btn-gray-white h-44 btn-edit-form">판매수정</button> : ''}
                </div>
                <section className="form-box mt-5">
                    <form onSubmit={this.props.handleSubmit} autoComplete="off">
                        <div className="content-form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-3 border-right-gray mt-4">

                                        <div className="form-group">
                                            <label htmlFor="" className="label-text">개통일</label>
                                            <div className="form-date">
                                                <img src="/images/icon-calender.svg" alt="" />
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    name="open_day"
                                                    className="form-control h-44 form-datepicker"
                                                    placeholder="개통일"
                                                    selected={moment(step1.open_day).toDate()}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="" className="label-text">거래처</label>
                                            <div className="dropdown custom-dropdown">
                                                <button className="btn dropdown-toggle w-100" type="button"
                                                        id="dropdownMenuButton" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    {this.state.locations.length ? this.state.locations[this.state.index].stl_name : '거래처'}
                                                </button>
                                                <Field type="hidden" className="form-control h-44"
                                                       name="step1.store_location_id"
                                                       component={renderTextField}/>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {locationHtml}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group ">
                                            <label htmlFor="" className="label-text">고객명</label>
                                            <Field type="text" className="form-control h-44"  placeholder="고객명" name="step1.customer_name" component={renderTextField}/>
                                        </div>

                                        <div className="form-group">
                                            <label className="label-text"> YYMMDD-S </label>
                                            <Field type="text" className="form-control h-44" component={renderReportCode}
                                                   placeholder="000000-0" name={"step1.report_code"}/>
                                        </div>

                                        <div className="form-group mb-0 mt-col">
                                            <label className="label-text"> 판매자 </label>
                                            <input className="form-control h-44 " value={consultant.saler_name} disabled/>
                                        </div>

                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-4 border-right-gray mt-4">

                                                <div className="form-group">
                                                    <label className="label-text"> 통신사 </label>
                                                    <input className="form-control h-44" disabled value={consultant ? consultant.telcom_name : ''}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="label-text"> 판매유형 </label>
                                                    <button className="btn btn-input-form mb-0" type="button"
                                                            onClick={this.openPopupSaleType}>
                                                        {step1.open_type?SALE_TYPES[step1.open_type-1] : SALE_TYPES[step1.open_type]}
                                                    </button>
                                                    <Field className="form-control h-44 "
                                                           name="step1.open_type"
                                                           placeholder="개통번호"
                                                           type={"hidden"}
                                                           component={renderTextField}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="label-text"> 결합번호 </label>
                                                    <input className="form-control h-44"
                                                           value={step1.open_code || ''}
                                                           placeholder="개통번호"
                                                           onChange={this.changeOpenCode}
                                                    />
                                                    <Field className="form-control h-44 "
                                                           name="step1.open_code"
                                                           value={step1.open_code}
                                                           type={"hidden"}
                                                           component={renderTextField}/>
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-text"> 결합상품명 </label>
                                                    <input className="form-control h-44 bg_input" value={consultant?.combine_discount_condition?.name || ''}
                                                           disabled/>
                                                </div>

                                            </div>
                                            {tableHtml}
                                            {this.markupTable(this.state.maxID, this.state.numCol)}
                                            <div className="col-12">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="" className="label-text">메모</label>
                                                    <input onChange={this.changeMemo} class="form-control h-44" value={step1.memo_note} ></input>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="main-btn-submit">
                                            <div className="group-button w-100">
                                                <button className="btn btn-button btn-gray mr-2 ml-2" type="button"
                                                        data-toggle="modal" data-target="#register-modal" onClick={this.props.back}>취소
                                                </button>
                                                <button className="btn btn-button btn-submit mr-2 ml-2"
                                                        data-link="/create-report-sale-4.html">다음
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <SaleTypeModal
                    openPopup={this.openPopupSaleType}
                    isOpenModal={this.state.isOpenModalSaleType}
                    setOpenType={this.setOpenType}
                    arr={SALE_TYPES}
                    activeIndex={step1.open_type}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ReportSale: getFormValues('ReportSale')(state),
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};

const FORM_NAME = 'ReportSale';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(CreateReportSaleWiredStep1);

