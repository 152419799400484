import PropTypes from 'prop-types';
import React, { useState, useEffect} from 'react';
import {Modal} from 'reactstrap';
import { priceFormat } from '../../../utils/helper';

const totlaFeeOption = (options = []) => options.reduce((prevFee, option) => {
  return prevFee + (+option.wdo_commitment_fee * (+option.qty || 1))
}, 0)

const ModalCost = ({closeModal, isOpenModal, itemSelected, getDiscountPrice}) => {
  const [discountDevice, setDiscountDevice] = useState(0)
  const [discountOption, setDiscountOption] = useState([])

  useEffect(() => {
    let discountOptionList = [];
    (itemSelected?.options || []).map((option, index) => {
      discountOptionList.push(itemSelected?.discount?.options[index]?.additional || 0)
    })
    setDiscountOption(discountOptionList)
  }, [itemSelected]);

  const totlaOptionDiscount = () => discountOption.reduce((prevFee, option) => {
    return prevFee + option 
  }, 0)

  const changeDiscountOption = (key, value, fee) => {
    if (value > fee) {
      return;
    }
    let discountOptionList = [...discountOption];
    discountOptionList[key] = +value
    setDiscountOption(discountOptionList)
  }

  function onOpened() {
    setDiscountDevice(itemSelected?.discount?.device?.additional || 0)
    // setDiscountOption(itemSelected?.discount?.option?.additional || 0)
  }

  function totalOptionValues(discount = []){
    let total = 0;

    (discount?.options || []).map((option) => { 
      total = total + +(option?.value || 0);
      total = total + +(option?.additional || 0);
      total = total + +(option?.combine || 0);
    })

    return total;
  }

  const calcDiscountOption = () => (
    totlaFeeOption(itemSelected?.options || [])
    - totlaOptionDiscount()
    - totalOptionValues(itemSelected?.discount)
  )
  const calcDiscountDevice = () => (+(itemSelected.device?.wd_commitment_fee || 0) - +discountDevice - (itemSelected?.discount?.device?.value || 0))

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={closeModal}
      fade={true}
      // backdrop={"static"}
      className={'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-cost'}
      onOpened={onOpened}
    >
      <div>
        <div className="modal-header">
          <h5 className="modal-title flex-header">
            프로모션 할인 추가
          </h5>
          <button type="button" className="close close-modal" onClick={closeModal}>
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
          </button>
        </div>
        <div className="modal-body promotional-discount pl-0 pr-0">
          <table className="table">
            <thead>
              <tr>
                <th>
                  가입 상품
                </th>
                <th>
                  납부 요금
                </th>
                <th>
                  추가할인 (프로모션)
                </th>
                <th>
                  추가할인 적용 후
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-bold">
                  {itemSelected.device?.wd_name}
                </td>
                <td>
                  {priceFormat(itemSelected.device?.wd_commitment_fee - itemSelected?.discount?.device?.value)}원
                </td>
                <td>
                  <div className="row align-items-center">
                    <div className="col-9 pr-0">
                      <input
                        type="text"
                        className="form-control h-44"
                        value={discountDevice}
                        // onChange={e => setDiscountDevice(e.target.value)}
                        onChange={e => {
                          let value = e.target.value;
                          let fee = itemSelected.device?.wd_commitment_fee;
                          if(+value <= fee){
                              setDiscountDevice(e.target.value - 0)
                          }
                        }}
                      />
                    </div>
                    <div className="col-2 pl-2">
                      <span className="text-gray">원</span>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="mb-0 blue-white">
                    {priceFormat(+itemSelected.device?.wd_commitment_fee- itemSelected?.discount?.device?.value - +discountDevice)}
                    <span className="text-gray">원</span>
                  </p>
                </td>
              </tr>
              {(itemSelected?.options || []).map((option, key) => (
                <tr key={key}>
                  <td className="text-bold">
                    {
                      (option) ? (
                        (option?.wdo_name && option?.wdo_name != "")
                        ? (option?.wdo_name)
                        : (option?.wdo_model_name || '미적용')
                      ) : '미적용'
                    }
                  </td>
                  <td>
                    {priceFormat((+option.wdo_commitment_fee * (+option.qty || 1)) - itemSelected?.discount?.option?.value)}원
                  </td>
                  <td>
                    <div className="row align-items-center">
                      <div className="col-9 pr-0">
                        {
                          ((+option.wdo_commitment_fee * (+option.qty || 1)) - itemSelected?.discount?.option?.value) > 0 ? (
                              <input
                                type="text"
                                className="form-control h-44"
                                value={discountOption[key]}
                                // onChange={e => setDiscountOption(e.target.value)}
                                onChange={e => changeDiscountOption(key, e.target.value, (+option.wdo_commitment_fee * (+option.qty || 1)))}
                              />
                          ) : (
                            <input
                              type="text"
                              className="form-control h-44"
                              value={discountOption[key]}
                              disabled
                            />
                          )
                        }
                      </div>
                      <div className="col-2 pl-2">
                        <span className="text-gray">원</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="mb-0 blue-white">
                      {priceFormat((+option.wdo_commitment_fee * (+option.qty || 1)) - itemSelected?.discount?.option?.value - +discountOption[key])}
                      <span className="text-gray">원</span>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total-form">
            <h4>
              추가할인 적용 후 총 요금
            </h4>
            <p className="mb-0 blue-white">
              {priceFormat(calcDiscountOption() + calcDiscountDevice())}
              <span className="text-gray">원</span>
            </p>
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button type="button" className="btn btn-gray" onClick={closeModal}>취소</button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={() => getDiscountPrice(discountDevice, discountOption)}
            >적용</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalCost.propTypes = {
  closeModal: PropTypes.func,
  isOpenModal: PropTypes.bool,
  itemSelected: PropTypes.object,
  getDiscountPrice: PropTypes.func,
};

export default ModalCost
