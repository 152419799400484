import React, {Fragment} from 'react';
import {
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return null;
    }
    return <FormHelperText>{touched && error}</FormHelperText>;
};

renderFromHelper.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.bool,
};

const renderSelectField = ({
    classes,
    input,
    label,
    meta: { touched, invalid, error },
    children,
    id,
    defaultValue,
    placeholder,
    className,
    ...custom
}) => {
    return (
        <Fragment>
            <select
                className={`form-control + ${ className + (touched && invalid ? 'is-invalid' : '')} + h-44`}
                onChange={input.onChange}
                id={id}
                value={input.value}
                {...input}
                {...custom}
            >
                {children}
            </select>
            {error !== undefined ? (
                <div className="invalid-feedback">
                    {error}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderSelectField.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    children: PropTypes.array,
    classes: PropTypes.object,
};

export default renderSelectField;
