
 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import * as routerName from './../../../utils/routerName';
import { Modal } from 'reactstrap';
class InstallmentMonthPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isOpen: false
        }
    }

    componentDidMount() {
    }


    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                   // backdrop={"static"}
                    className="modal-additional modal-dialog-centered modal-additional-support"
                    contentClassName="pl-4 pr-4"
                    toggle={this.props.toggle}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">할부개월 수</h5>

                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>

                    </div>
                    <div className="modal-body mt-1">
                        <ul className="list-additional">
                            {this.props.children}
                        </ul>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => () => dispatch(change())
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(InstallmentMonthPopup);
