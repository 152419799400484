import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import CreateReportSaleStep1 from "./CreateReportSaleStep1";
import CreateReportSaleStep2 from "./CreateReportSaleStep2";
import MessageJSON from "../../utils/message.json";
import CreateReportSaleWiredStep1 from "./CreateReportSaleWiredStep1";
import CreateReportSaleWiredStep2 from "./CreateReportSaleWiredStep2";
import api from "../../utils/api";
import {caclWirelessStep3, updateWirelessStep3} from "../../utils/helper";
import queryString, {stringify} from 'query-string';
import {hideLoading, showLoading} from "../../store/actions/ui";
import moment from "moment";

class CreateReportSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant: null
        }
    }

    goBack = () =>{
        if(this.props.auth.role == 'Counselor'){
            this.props.history.push("/");
        } else {
            this.props.history.push("/history-buy-product");
        }
        // this.props.history.goBack();
    }

    componentDidMount() {
        let url = this.props.location.search
        let params = queryString.parse(url);
        showLoading();
        api.consultant.find(params.id)
            .then((res) => {
                res = res.data;
                let WirelessAdviceStep3Clone = res.data ? updateWirelessStep3({}, res.data) : null
                if(res?.data?.telcom){
                    WirelessAdviceStep3Clone.interestRate = res.data.telcom.installment_rate
                    res.data.wirelessAdviceStep3 = caclWirelessStep3(
                        res.data.telcom.installment_payment_method,
                        WirelessAdviceStep3Clone,
                        res.data.telcom.recipe_days_remaining
                    )
                }
                
                this.setState({
                    consultant:res.data
                })
                if (res.data?.sales_report_id) {
                    let  step1 = {
                        open_time: res.data.open_time || '00:00',
                        open_day: res.data.open_day || new Date(),
                        store_location_id: res.data.store_location_id,
                        customer_name: res.data.customer_name,
                        report_code: res.data.report_code,
                        open_code: res.data.open_code,
                        seri: res.data.seri,
                        memo_note : res.data.memo_note,
                        open_type: res.data.open_type || 0
                    }
                    let step2 = {
                        memo_discount: res.data.memo_discount,
                        rebail_basic_price : res.data.rebail_basic_price,
                        unit_price_code : res.data.unit_price_code,
                        rb: res.data.rebail,
                        rbDeduct: res.data.deduct,
                        mnp_fee: res.data.mnp_fee || 0,
                        sim_fee: res.data.sim_fee || 0,
                        cash_recharge: res.data.cash_recharge,
                        card_recharge: res.data.card_recharge,
                        memo_content_recharge : res.data.memo_content_recharge,
                        support_cash : res.data.support_cash,
                        quickbee: res.data.quickbee,
                        detail_memo_support : res.data.detail_memo_support,
                        pay_instead_fee : res.data.pay_instead_fee,
                        pay_instead_installment_payment : res.data.pay_instead_installment_payment,
                        support_recharge : res.data.support_recharge,
                        memo_content_support:res.data.memo_content_support,
                        tax_rate: res.data.tax_rate || 0,
                        actual_profit: res.data.actual_profit,
                        total_rb: res.data.total_rb
                    }

                    if (step2.rbDeduct && step2.rbDeduct.length < 2) {
                        for (let i=0;i<=(2-step2.rbDeduct.length);i++) {
                            step2.rbDeduct.push({
                                price : '',
                                memo : ''
                            })
                        }
                    }

                    if (step2.rb && step2.rb.length < 2) {
                        for (let i=0;i<=(2-step2.rb.length);i++) {
                            step2.rb.push({
                                price : '',
                                memo : ''
                            })
                        }
                    }

                    this.props.change('sales_report_id', res.data.sales_report_id);
                    this.props.change('step1', step1);
                    this.props.change('step2', step2)
                } else {
                    this.props.change('step1.report_code', res.data.report_code)
                }
                if (res.data?.type == 1) {
                    let wirelessAdviceStep3 = res.data.wirelessAdviceStep3
                    let device_recharge = (+wirelessAdviceStep3.startingPrice -
                        +wirelessAdviceStep3.disclosureSubsidy -
                        +wirelessAdviceStep3.installmentPrincipal -
                        +wirelessAdviceStep3.advancedDiscountCard -
                        +wirelessAdviceStep3.pointDiscount
                    ) + +wirelessAdviceStep3.additionalGrants;

                    let discount_installment_fee = (+wirelessAdviceStep3.disclosureSubsidy +
                        +wirelessAdviceStep3.installmentPrincipal +
                        +wirelessAdviceStep3.pointDiscount +
                        +wirelessAdviceStep3.additionalGrants +
                        +wirelessAdviceStep3.advancedDiscountCard
                    ) - +wirelessAdviceStep3.startingPrice;

                    this.props.change('step2.startingPrice', +res.data.wirelessAdviceStep3.startingPrice)
                    this.props.change('step2.disclosureSubsidy', +res.data.wirelessAdviceStep3.disclosureSubsidy)
                    this.props.change('step2.additionalGrants', +res.data.wirelessAdviceStep3.additionalGrants)
                    this.props.change('step2.installmentPrincipal', +res.data.wirelessAdviceStep3.installmentPrincipal)
                    this.props.change('step2.advancedDiscountCard', +res.data.wirelessAdviceStep3.advancedDiscountCard)
                    this.props.change('step2.pointDiscount', +res.data.wirelessAdviceStep3.pointDiscount)
                    this.props.change('step2.device_recharge', device_recharge)
                    this.props.change('step2.discount_installment_fee', discount_installment_fee < 0 ? 0 : discount_installment_fee)
                }
                this.props.change('consultant_id', res.data?.id)
                this.props.change('type', +res.data?.type)
                this.props.hideLoading()

            });
    }

    resetForm = () => {
        this.props.reset('ReportSale');
        this.props.change('step', 1)
    }

    step2 = () => {
        this.props.change('step', 2)
    }

    step1 = () => {
        this.props.change('step', 1)
    }

    handleSubmit = () => {
        const {ReportSale} = this.props
        if (ReportSale.step == 1) {
            this.step2();
        } else {
            this.props.showLoading()
            let data = {
                state: 1,
                sales_report_id: ReportSale.sales_report_id,
                consultant_id: ReportSale.consultant_id,
                open_time: ReportSale.step1.open_time,
                open_day: moment(ReportSale.step1.open_day).format('YYYY-MM-DD'),
                store_location_id: ReportSale.step1.store_location_id,
                customer_name: ReportSale.step1.customer_name,
                report_code: ReportSale.step1.report_code,
                open_code: ReportSale.step1.open_code,
                seri: ReportSale.step1.seri,
                memo_note : ReportSale.step1.memo_note,
                open_type: ReportSale.step1.open_type,
                memo_discount: ReportSale.step2.memo_discount,
                rebail_basic_price : typeof ReportSale.step2.rebail_basic_price === 'string' || ReportSale.step2.rebail_basic_price instanceof String ? parseFloat(ReportSale.step2.rebail_basic_price.replace(',','')) : parseFloat(ReportSale.step2.rebail_basic_price),
                unit_price_code : ReportSale.step2.unit_price_code,
                rb: ReportSale.step2.rb.filter((x)=>{
                    if(!Object.keys(x).length == 0 && x.constructor === Object) {
                        return x;
                    }}).map((x) => {
                    x.price = typeof x.price === 'string' || x.price instanceof String ? parseFloat(x.price.replace(',','')) : parseFloat(x.price)
                    return x;
                }),
                rb_deduct: ReportSale.step2.rbDeduct.filter((x)=>{
                    if(!Object.keys(x).length == 0 && x.constructor === Object) {
                        return x;
                    }}).map((x) => {
                    x.price = typeof x.price === 'string' || x.price instanceof String ? parseFloat(x.price.replace(',','')) : parseFloat(x.price)
                    return x;
                }),
                mnp_fee: ReportSale.step2.mnp_fee,
                sim_fee: ReportSale.step2.sim_fee,
                cash_recharge: typeof ReportSale.step2.cash_recharge === 'string' || ReportSale.step2.cash_recharge instanceof String ? parseFloat(ReportSale.step2.cash_recharge.replace(',','')) : ReportSale.step2.cash_recharge,
                card_recharge: typeof ReportSale.step2.card_recharge === 'string' || ReportSale.step2.card_recharge instanceof String ? parseFloat(ReportSale.step2.card_recharge.replace(',','')) : ReportSale.step2.card_recharge,
                memo_content_recharge : ReportSale.step2.memo_content_recharge,
                support_cash : typeof ReportSale.step2.support_cash === 'string' || ReportSale.step2.support_cash instanceof String ? parseFloat(ReportSale.step2.support_cash.replace(',','')) : ReportSale.step2.support_cash,
                quickbee: typeof ReportSale.step2.quickbee === 'string' || ReportSale.step2.quickbee instanceof String ? parseFloat(ReportSale.step2.quickbee.replace(',','')) : ReportSale.step2.quickbee,
                detail_memo_support : ReportSale.step2.detail_memo_support,
                pay_instead_fee : typeof ReportSale.step2.pay_instead_fee === 'string' || ReportSale.step2.pay_instead_fee instanceof String ? parseFloat(ReportSale.step2.pay_instead_fee.replace(',','')) : ReportSale.step2.pay_instead_fee,
                pay_instead_installment_payment : typeof ReportSale.step2.pay_instead_installment_payment === 'string' || ReportSale.step2.pay_instead_installment_payment instanceof String ? parseFloat(ReportSale.step2.pay_instead_installment_payment.replace(',','')) : ReportSale.step2.pay_instead_installment_payment,
                support_recharge : typeof ReportSale.step2.support_recharge === 'string' || ReportSale.step2.support_recharge instanceof String ? parseFloat(ReportSale.step2.support_recharge.replace(',','')) : ReportSale.step2.support_recharge,
                memo_content_support:ReportSale.step2.memo_content_support,
                tax_rate: ReportSale.step2.tax_rate,
                actual_profit: typeof ReportSale.step2.actual_profit === 'string' || ReportSale.step2.actual_profit instanceof String ? parseFloat(ReportSale.step2.actual_profit.replace(',','')) : ReportSale.step2.actual_profit,
                total_rb: typeof ReportSale.step2.total_rb === 'string' || ReportSale.step2.total_rb instanceof String ? parseFloat(ReportSale.step2.total_rb.replace(',','')) : ReportSale.step2.total_rb,
                device_recharge: typeof ReportSale.step2.device_recharge === 'string' || ReportSale.step2.device_recharge instanceof String ? parseFloat(ReportSale.step2.device_recharge.replace(',','')) : ReportSale.step2.device_recharge,
            }

            api.consultant.createReport(data)
                .then(res => {
                    this.setState({
                        
                        consultant:res.data.data
                    }, () => {
                    })
                    this.resetForm();
                    this.props.hideLoading()
                    if(this.props.auth.role == 'Counselor'){
                        this.props.history.push("/");
                    } else {
                        this.props.history.push("/history-buy-product");
                    }
                    
                })
                .catch(() => {
                    this.props.hideLoading();
                });
        }

    }

    renderHtml(consultant) {
        const { ReportSale } = this.props
        if (!ReportSale) {
            return null;
        }
        if (consultant && consultant.type == 1) {
            if (ReportSale.step == 1) {
                return <CreateReportSaleStep1
                    history={this.props.history}
                    next={this.step2}
                    consultant={consultant}
                    telcomId={consultant.telcom_id}
                    back={this.goBack}
                    onSubmit={this.handleSubmit}
                />
            } else {
                return <CreateReportSaleStep2
                        history={this.props.history}
                        prev={this.step1}
                        consultant={consultant}
                        onSubmit={this.handleSubmit}
                    />
            }
        } else if (consultant && consultant.type == 2) {
            if (ReportSale.step == 1) {
                return <CreateReportSaleWiredStep1
                    history={this.props.history}
                    next={this.step2}
                    consultant={consultant}
                    telcomId={consultant.telcom_id}
                    back={this.goBack}
                    onSubmit={this.handleSubmit}
                />
            } else {
                return <CreateReportSaleWiredStep2
                    history={this.props.history}
                    prev={this.step1}
                    consultant={consultant}
                    onSubmit={this.handleSubmit}
                />
            }
        }
    }

    render() {
        const html = this.renderHtml(this.state.consultant)
        if(!this.state.consultant || !html){
            return (
                <section class="wired-consultation">
            <div class="container">
                <div class="report-sale-step main-step-box">
                    <ul class="step-wireless">
                        <li class="item-step active">
                            <div class="open-item">
                                <span class="step-name">Step 1</span>
                                <span>개통정보</span>
                            </div>
                        </li>
                        <li class="item-step">
                            <div class="open-item">
                                <span class="step-name">Step 2</span>
                                <span>차감정보</span>
                            </div>
                        </li>
                    </ul>
                    <button class="btn btn-gray btn-edit-form">판매수정</button>
                </div>
                <section class="form-box mt-5">
                    <form action="">
                        <div class="content-form">
                            <div class="container">
                                <div class="row">
                                    <div class="col-3 border-right-gray mt-4">

                                        <div class="form-group">
                                            <label for="" class="label-text">접수일자</label>
                                            <div class="form-date">
                                                <img src="/images/icon-calender.svg" alt=""/>
                                                <input type="text" class="form-control h-44 form-datepicker" placeholder="접수일자(청약일)"/>
                                            </div>
                                        </div>

                                        <div class="form-group ">
                                            <label for="" class="label-text">거래처</label>
                                            <input type="text" class="form-control h-44" placeholder="거래처"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="" class="label-text">고객명</label>
                                            <input type="text" class="form-control h-44" placeholder="고객명"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="" class="label-text">YYMMDD-S</label>
                                            <input type="text" class="form-control h-44" placeholder="000000-0"/>
                                        </div>

                                        <div class="form-group mb-0">
                                            <label class="label-text"> 판매자 </label>
                                            <input class="form-control h-44 "  disabled/>
                                        </div>

                                    </div>
                                    <div class="col-9">
                                        <div class="row">
                                            <div class="col-4 border-right-gray mt-4">

                                                <div class="form-group ">
                                                    <label class="label-text"> 통신사 </label>
                                                    <input class="form-control h-44 bg_input" disabled/>
                                                </div>

                                                <div class="form-group">
                                                    <label class="label-text"> 판매유형 </label>
                                                    <button class="btn btn-input-form mb-0" type="button" data-toggle="modal"
                                                    data-target="#modal-91">
                                                    동시판매
                                                    </button>
                                                </div>

                                                <div class="form-group">
                                                    <label class="label-text"> 결합번호 </label>
                                                    <input class="form-control h-44 bg_input" value="개통번호"/>
                                                </div>
                                                <div class="form-group">
                                                    <label class="label-text"> 결합상품명 </label>
                                                    <input class="form-control h-44 bg_input" disabled/>
                                                </div>

                                            </div>

                                            
                                            
                                            <div class="col-12">
                                                <div class="form-group mb-0">
                                                    <label for="" class="label-text">메모</label>
                                                    <input class="form-control h-44" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12">
                                        <div class="main-btn-submit">
                                            <div class="group-button w-100">
                                                <button class="btn btn-button btn-gray mr-2 ml-2" type="button"
                                                    data-toggle="modal" data-target="#register-modal">취소</button>
                                                <button type="button" class="btn btn-button btn-submit mr-2 ml-2"
                                                    data-link="/create-report-sale-4.html">다음</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </section>
            )
        }
        return (
            <Fragment>
                <section className="wired-consultation report-sale-wired">
                    {html}
                </section>
            </Fragment>
        );
    }
}

CreateReportSale.propTypes = {
    history: PropTypes.object,
    reset: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        ReportSale: getFormValues('ReportSale')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

const validate = values => {
    const errors = {}
    let step1 = {
    };
    let step2 = {
    }
    if (!values.step1.customer_name) {
        step1.customer_name = MessageJSON.report_sale.validate.customer_name.required
    } else if (values.step1.customer_name.length > 32) {
        step1.customer_name = MessageJSON.report_sale.validate.customer_name.max
    }

    // if (!values.step1.store_location_id) {
    //     step1.store_location_id = MessageJSON.report_sale.validate.store_location_id.required
    // }

    if (!values.step1.open_time) {
        step1.open_time = MessageJSON.report_sale.validate.open_time.required
    }
    if (!values.step1.report_code) {
        step1.report_code = MessageJSON.report_sale.validate.report_code.required
    } else if(values.step1.report_code.length <= 7) {
        step1.report_code = MessageJSON.report_sale.validate.report_code.format
    }

    if (values.step1.seri && values.step1.seri.length > 16) {
        step1.seri = MessageJSON.report_sale.validate.seri.max
    }

    if (!values.step1.open_type) {
        step1.open_type = MessageJSON.report_sale.validate.open_type.required
    }

    if (values.step2.rebail_basic_price && !+values.step2.rebail_basic_price) {
        if (values.step2.rebail_basic_price.length > 11) {
            step2.rebail_basic_price = MessageJSON.report_sale.validate.rebail_basic_price.max
        }
    }

    if (values.step2.unit_price_code && values.step2.unit_price_code.length > 32) {
        step2.unit_price_code = MessageJSON.report_sale.validate.unit_price_code.max
    }

    if (values.step2.memo_content_support && values.step2.memo_content_support.length > 32) {
        step2.memo_content_support = MessageJSON.report_sale.validate.memo_content_support.max
    }

    if (values.step2.memo_discount && values.step2.memo_discount.length > 64) {
        step2.memo_discount = MessageJSON.report_sale.validate.memo_discount.max
    }

    if (values.step2.memo_content_recharge && values.step2.memo_content_recharge.length > 32) {
        step2.memo_content_recharge = MessageJSON.report_sale.validate.memo_content_recharge.max
    }

    if (values.step2.cash_recharge && !+values.step2.cash_recharge) {
        if (values.step2.cash_recharge.length > 11) {
            step2.cash_recharge = MessageJSON.report_sale.validate.cash_recharge.max
        }
    }

    if (values.step2.card_recharge && !+values.step2.card_recharge) {
        if (values.step2.card_recharge.length > 11) {
            step2.card_recharge = MessageJSON.report_sale.validate.card_recharge.max
        }
    }

    if (values.step2.support_cash && !+values.step2.support_cash) {
        if (values.step2.support_cash.length > 11) {
            step2.support_cash = MessageJSON.report_sale.validate.support_cash.max
        }
    }

    if (values.step2.quickbee && !+values.step2.quickbee) {
        if (values.step2.quickbee.length > 11) {
            step2.quickbee = MessageJSON.report_sale.validate.quickbee.max
        }
    }

    if (values.step2.pay_instead_fee && !+values.step2.pay_instead_fee) {
        if (values.step2.pay_instead_fee.length > 11) {
            step2.pay_instead_fee = MessageJSON.report_sale.validate.pay_instead_fee.max
        }
    }

    if (values.step2.pay_instead_installment_payment && !+values.step2.pay_instead_installment_payment) {
        if (values.step2.pay_instead_installment_payment.length > 11) {
            step2.pay_instead_installment_payment = MessageJSON.report_sale.validate.pay_instead_installment_payment.max
        }
    }

    if (values.step2.support_recharge && values.step2.support_recharge.length > 11) {
        step2.support_recharge = MessageJSON.report_sale.validate.support_recharge.max
    }

    if (values.step2.detail_memo_support && values.step2.detail_memo_support.length > 500) {
        step2.detail_memo_support = MessageJSON.report_sale.validate.detail_memo_support.max
    }

    if (values.step2.rb && values.step2.rb.length) {
        let rbArrayErrors = []
        values.step2.rb.forEach((rb) => {
            const rbErrors = {}
            if (rb) {
                if (rb.price) {
                    if (rb.price.length > 11) {
                        rbErrors.price = MessageJSON.report_sale.validate.rb.price
                    }
                }
                if (rb.memo && rb.memo.length > 32) {
                    rbErrors.memo = MessageJSON.report_sale.validate.rb.memo
                }
                rbArrayErrors.push(rbErrors)
            }
        });
        step2.rb = rbArrayErrors;
    }

    if (values.step2.rbDeduct && values.step2.rbDeduct.length) {
        let rbDeductArrayErrors = []
        values.step2.rbDeduct.forEach((rbDeduct) => {
            const rbErrors = {}
            if (rbDeduct) {
                if (rbDeduct.price) {
                    if (rbDeduct.price.length > 11) {
                        rbErrors.price = MessageJSON.report_sale.validate.rbDeduct.price
                    }
                }
                if (rbDeduct.memo && rbDeduct.memo.length > 32) {
                    rbErrors.memo = MessageJSON.report_sale.validate.rbDeduct.memo
                }
                rbDeductArrayErrors.push(rbErrors)
            }
        });
        step2.rbDeduct = rbDeductArrayErrors;
    }

    if (!values.step1.open_code) {
        if (+values.type == 2) {
            step1.open_code = MessageJSON.report_sale.validate.open_code.required
        } else {
            step1.open_code = MessageJSON.report_sale.validate.compine_code.required
        }

    } else if (values.step1.open_code.length > 16) {
        if (+values.type == 2) {
            step1.open_code = MessageJSON.report_sale.validate.open_code.max
        } else {
            step1.open_code = MessageJSON.report_sale.validate.compine_code.max
        }

    } else if (values.checkReportCode) {
        step1.open_code = MessageJSON.report_sale.validate.open_code.duplicated
    }

    errors.step1 = step1
    errors.step2 = step2
    return errors
}

const FORM_NAME = 'ReportSale';
const withReduxForm = reduxForm({
    form: FORM_NAME,
    key: FORM_NAME,
    initialValues: {
        checkReportCode: false,
        consultant_id: 0,
        sales_report_id: null,
        type: 0,
        step: 1,
        step1: {
            open_time: '00:00',
            open_day: new Date(),
            store_location_id: '',
            customer_name: '',
            report_code: '',
            open_code: '',
            seri: '',
            memo_note : '',
            open_type: 0,
        },
        step2: {
            memo_discount: '',
            rebail_basic_price : '',
            unit_price_code : '',
            rb: [
                {
                    price : '',
                    memo : ''
                },
                {
                    price : '',
                    memo : ''
                },
            ],
            rbDeduct: [
                {
                    price : '',
                    memo : ''
                },
                {
                    price : '',
                    memo : ''
                },
            ],
            mnp_fee: 0,
            sim_fee: 0,
            cash_recharge: '',
            card_recharge: '',
            memo_content_recharge : '',
            support_cash : '',
            quickbee: '',
            detail_memo_support : '',
            pay_instead_fee : '',
            pay_instead_installment_payment : '',
            support_recharge : '',
            memo_content_support:'',
            tax_rate: 0,
            actual_profit: '',
            total_rb: '',
            total_sp:'',
            startingPrice: '',
            disclosureSubsidy: '',
            additionalGrants: '',
            installmentPrincipal: '',
            advancedDiscountCard: '',
            pointDiscount :'',
            device_recharge:'',
            discount_installment_fee: ''
        }
    },
    validate
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(CreateReportSale);

