import PropTypes from 'prop-types';
import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Field, reduxForm} from 'redux-form';
import api from '../../utils/api';
import _ from 'lodash';
import {isIssetArray, addOrRemoveToArray, priceFormat, roundUpNumber, optionalAgreementCT} from "../../utils/helper";
import {showLoading, hideLoading} from '../../store/actions/ui';
import PlanInformationModal from "../../components/Wireless/PackageFee/PlanInformationModal";
import PlanInformationModalByTelcom  from "../../components/Wireless/PackageFee/PlanInformationModal";

import queryString from 'query-string';
import InfiniteScroll from "react-infinite-scroll-component";
import * as routeName from './../../utils/routerName';
import MessageJSON from '../../utils/message';
import {OPTION_FEE,CONSULTANT_WIRELESS} from "../../utils/constant";
import { ReactComponent as IconReset } from './../../assets/images/icon-reset.svg';

class SmartAdvice extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataFilters:{
                telcoms: [],
                manus: [],
                standard_types: [],
                installment_months: [],
                subsidy_types: [],
                price_types: [],

            },
            filtersSelected: {
                telcom: [], //nullable
                manufactor: null, //nullable
                standard_type: null, //nullable
                calc_inst_month: null,// required| default: 24
                calc_subsidy_type: null, //required
                price_type: null, //nullable
                plan: null,
                distinct: true,
                calc: true,
                relationship : ['colors'],
                published: true,
                // sort: ["plan_sort|asc"],
                sort: [],
                limit : 50,
                smart: true,
            },
            sorts: {
                // telcomID: {
                //     dl_price: 'desc',
                // }
            },
            planIDSeleted:{
                // telcomID: planID
            },
            telcomIDPlanModal: null,
            dataModelPlan: [ ],
            // modalPlans: {
            //     isShowModal : false,
            //
            // },
            modalPlansByTelcom: {
                isShowModalByTelcom : false,
            },
            WirelessAdviceStep3: CONSULTANT_WIRELESS,

        }
    }

    componentDidMount =() =>  {
        this.props.showLoading();
        document.body.classList.add("body_wireless_advice");
        let filters =  api.smart_consultant.getFilters();
        Promise.all([filters]).then(res => {
            const data = res[0].data.data;
            this.setState({
                dataFilters: {
                    ...this.state.dataFilters,
                    ...data
                },
                filtersSelected:{
                    ...this.state.filtersSelected,
                    // manufactor: data.manus[0].id,
                    // standard_type: Object.keys(data.standard_types)[0] ,
                    calc_inst_month: data.installment_months[2],
                    calc_subsidy_type: Object.keys(data.subsidy_types)[0]  ,
                }
            }, () => {
                this.props.hideLoading();
            })
        }).catch(err => {
            this.props.hideLoading();
        })
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    onResetFilter = () => {
        this.setState({
            filtersSelected : {
                telcom: [],
                calc_subsidy_type: Object.keys(this.state.dataFilters.subsidy_types)[0],
                calc_inst_month: this.state.dataFilters.installment_months[2],
                plan: null,
                manufactor: null, //nullable
                standard_type: null, //nullable
                price_type: null, //nullable
                distinct: true,
                calc: true,
                relationship : ['colors', 'plan'],
                published: true,
                // sort: ["plan_sort|asc"],
                sort: [],
            },
            sorts: {},
            dataModelPlan: [],
            planIDSeleted: [],

        })
    }
    // onSelectTelcom = (e, telcomID) => {
    //     const checked = e.target.checked;
    //     let telcom = [];
    //     if(checked) {
    //         telcom = [...this.state.filtersSelected.telcom, telcomID];
    //         this.updateFilter(telcom, 'telcom')
    //     } else {
    //         telcom = this.state.filtersSelected.telcom.filter(tel => tel!==telcomID);
    //         this.setState({
    //             sorts: {..._.filter(this.state.sorts, (telcom, value) => telcom !== telcomID)},
    //             planIDSeleted: {..._.filter(this.state.planIDSeleted, (telcom, value) => telcom !== telcomID)},
    //         }, () => {
    //             this.updateFilter(telcom, 'telcom')
    //         })
    //     }
    // }

    onSelectTelcom = async (e, telcomID) => {
        const checked = e.target.checked;
        let telcom = [];
        let dataModelPlan = [];
        let sorts = [];
        let planIDSeleted = [];
        if(checked) {
            this.props.showLoading();

            telcom = [...this.state.filtersSelected.telcom, telcomID];
            this.setState({
                filtersSelected: {
                    ...this.state.filtersSelected,
                    telcom,
                },
            }, () => {
                let sorts = this.state.sorts[telcomID];
                let dataSort = [];
                if(sorts){
                    for (const [key, value] of Object.entries(sorts)) {
                        dataSort.push(`${key}|${value}`)
                    }
                }else{
                    dataSort = [...this.state.filtersSelected.sort];
                }
                let data = {
                    ...this.state.filtersSelected,
                    telcom: telcomID,
                    sort:dataSort,
                    plan: this.state.filtersSelected.plan ? this.state.filtersSelected.plan.id : this.state.planIDSeleted[telcomID]?.id,
                };
                api.smart_consultant.getModelPlan(queryString.stringify(data, {arrayFormat: 'bracket'})).then((res) => {
                    let result = {
                        current_page: 1,
                        data : res.data.data,
                    };
                    this.setState({
                        dataModelPlan: [
                            ...this.state.dataModelPlan,
                            {telcomID : telcomID, result : result}
                            ]
                    }, () => {
                        this.props.hideLoading();
                    });
                })
                .catch(() => {
                    this.props.hideLoading();
                });
            })
        } else {
            telcom = this.state.filtersSelected.telcom.filter(tel => tel!==telcomID);
            dataModelPlan = this.state.dataModelPlan.filter(tel => tel.telcomID !== telcomID);
            sorts = {..._.filter(this.state.sorts, (telcom, value) => telcom !== telcomID)};
            planIDSeleted = {..._.filter(this.state.planIDSeleted, (telcom, value) => telcom !== telcomID)};

            this.setState({
                filtersSelected: {
                    ...this.state.filtersSelected,
                    telcom,
                },
                sorts,
                planIDSeleted,
                dataModelPlan
            }, () => {
            })
        }
    }
    renderTelcom = (telcom) => {
        if (Object.keys(telcom).length > 0) {
            return (
                <Fragment>
                    {
                        Object.values(telcom).map((item, i) => {
                                return <div className="col-md-6" key={i}>
                                    <label className="form-group form-check check-smart" htmlFor={`telcom-${item.id}`}>
                                        <div className="custom-control custom-checkbox mr-sm-2">
                                            <input type="checkbox"
                                                   className="custom-control-input"
                                                   checked={this.state.filtersSelected.telcom.includes(item.id) ? "checked" : ""}
                                                   id={`telcom-${item.id}`}
                                                   onChange={(e) => this.onSelectTelcom(e, item.id)}
                                            />
                                            <div className="custom-control-label">
                                                <img src={item.image_url && item.image_url && item.image_url.original
                                                    ? item.image_url.original
                                                    : (item.image_url && item.image_url.original ? item.image_url.original : "")}
                                                     alt={item.name}/>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            }
                        )
                    }
                </Fragment>
            )
        }
    }

    renderManufacturers = (manus) => {
        if(isIssetArray(manus)){
            return (
                <Fragment>
                    <ul className="list-network col-8 mb-0">
                        {
                            manus.map((item, i) => {
                                return (
                                    <li className={ this.state.filtersSelected.manufactor == item.id ? "active": "" }
                                        key={i}
                                        onClick={(e) => this.onChangeFilter(e, item.id, 'manufactor')}
                                    >
                                        <span>
                                            { item.name }
                                        </span>
                                    </li>
                                )
                            })
                        }
                        <li className={ this.state.filtersSelected.manufactor == "other" ? "active": "" }  onClick={(e) => this.onChangeFilter(e, "other", 'manufactor')}>
                            <span>기타</span>
                        </li>
                    </ul>
                </Fragment>
            )
        }
    }

    renderDeviceType = (standard_type) => {
        if(!_.isEmpty(standard_type)){
            return (
                <Fragment>
                    <ul className="list-network mb-0 col-8">
                        {
                            _.map(standard_type, (value, key) => (
                                <li className={ this.state.filtersSelected.standard_type == key ? "active": ""}
                                    onClick={(e) => this.onChangeFilter(e, key, 'standard_type')}
                                    key={key}>
                                    <span>{ value }</span>
                                </li>
                            ))
                        }
                    </ul>
                </Fragment>
            )
        }
    }

    renderSubsidyType = (subsidy_types) => {
        if(!_.isEmpty(subsidy_types)){
            return (
                <Fragment>
                    <ul className="list-network mb-0 mt-0">
                        {
                            _.map(subsidy_types, (value, key) => (
                                <li className={ this.state.filtersSelected.calc_subsidy_type == key ? "active": ""}
                                    onClick={(e) => this.onChangeFilter(e, key, 'calc_subsidy_type')}
                                    key={key}
                                >
                                    <span>{ value }</span>
                                </li>
                            ))
                        }
                    </ul>
                </Fragment>
            )
        }
    }

    renderInstallmentMonth = (installment_month) => {
        if(isIssetArray(installment_month)){
            return (
                <Fragment>
                    <ul className="list-network  mb-0">
                        {
                            installment_month.map((item, i) => {
                                return (
                                    <li className={ this.state.filtersSelected.calc_inst_month == item ? "active": ""}
                                        onClick={(e) => this.onChangeFilter(e, item, 'calc_inst_month')}
                                        key={i}
                                    >
                                        <span>
                                            { item }개월
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Fragment>

            )
        }
    }


    updateFilter(value, type){
        if(!['calc_inst_month','calc_subsidy_type'].includes(type) && this.state.filtersSelected[type] == value){
            value = ''
        };

        this.setState(prevState => ({
            filtersSelected: {
                ...this.state.filtersSelected,
                [type]: value,
            }
        }), () => {
            //call api get device
            if(this.state.filtersSelected.telcom.length > 0  ){
                this.fetchModelPlan();
            }else{
                this.setState({
                    dataModelPlan: []
                })
            }
        });
    }

    fetchModelPlan = () => {
        this.props.showLoading();
        let dataAPI = [];
        this.state.filtersSelected.telcom.map((telcom, key) => {

            let sorts = this.state.sorts[telcom];
            let dataSort = [];
            if(sorts){
                for (const [key, value] of Object.entries(sorts)) {
                    dataSort.push(`${key}|${value}`)
                }
            }else{
                dataSort = [...this.state.filtersSelected.sort];
            }

            let data = {
                ...this.state.filtersSelected,
                telcom: telcom,
                sort:dataSort,
                plan: this.state.filtersSelected.plan ? this.state.filtersSelected.plan.id : this.state.planIDSeleted[telcom]?.id,
            };
            let modelByTelcom = api.smart_consultant.getModelPlan(queryString.stringify(data, {arrayFormat: 'bracket'})).then((res) => {
                let result = {
                    current_page: 1,
                    data : res.data.data,
                };
                return {telcomID : telcom, result : result}
            });

            dataAPI.push(modelByTelcom);
        })
        Promise.all(dataAPI).then(res => {
            this.setState({
                dataModelPlan: res,

            }, () => {
                this.props.hideLoading();
            })
        }).catch(err => {
            this.setState({
                dataModelPlan: [],

            }, () => {
                this.props.hideLoading();
            });
        })
    }

    onChangeFilter = (e, value, type) => {
        this.updateFilter(value, type)
        window.scrollTo(0, 0)
    }


    onChangePriceType = (value) => {
        this.setState({
            filtersSelected: {
                ...this.state.filtersSelected,
                plan: null
            },
            planIDSeleted: {}
        }, () => {
            this.updateFilter(value, 'price_type')
        })
        window.scrollTo(0, 0)
    }

    onSort = (telcomID, field, sort) => {
        this.setState({
            sorts: {
                ...this.state.sorts,
                [telcomID] : {
                    // ...this.state.sorts[telcomID], //sort 1 colum
                    [field] : sort
                }
            }
        },() => {
            this.fetchModelByTelcome(telcomID);
        })
    }



    fetchMoreData = async (telcomID, result) => {
        let sorts = this.state.sorts[telcomID];
        let plan = this.state.planIDSeleted?.[telcomID];
        let dataSort = [];
        if(sorts){
            for (const [key, value] of Object.entries(sorts)) {
                dataSort.push(`${key}|${value}`)
            }
        }else{
            dataSort = [...this.state.filtersSelected.sort];
        }
        let data = {
            ...this.state.filtersSelected,
            telcom: telcomID,
            sort:dataSort,
            page: result.current_page + 1,
            plan: plan?.id,
        };
        api.smart_consultant.getModelPlan(queryString.stringify(data, {arrayFormat: 'bracket'})).then(res => {
            let indexOldModelPlan = _.findIndex(this.state.dataModelPlan, {telcomID: telcomID});
            let dataModel = {
                ...this.state.dataModelPlan[indexOldModelPlan],
                result: {
                    ...this.state.dataModelPlan[indexOldModelPlan].result,
                    data: [
                        ...this.state.dataModelPlan[indexOldModelPlan].result.data,
                        ...res.data.data
                    ],
                    current_page: this.state.dataModelPlan[indexOldModelPlan].result.current_page + 1
                }
            }
            let newDataModelPlan = [...this.state.dataModelPlan];
            newDataModelPlan[indexOldModelPlan] = dataModel;
            this.setState({
                dataModelPlan: newDataModelPlan
            }, () => {
            });
        }).catch(err => {

        })
    }

    redirectWireLessAdvice = (telcomeID, model) => {
        let telcom = _.find(this.state.dataFilters.telcoms, {id: telcomeID});
        /* tiền giảm giá cam kết lựa chọn */
        let cost_display = +model.cost_display + (+model.cost_display*0.1);
        let cost_calc = +model.cost_calc + (+model.cost_calc*0.1);
        let customPlan = 1;
        let commitmentRate ;
        if (model.spec_cmm == 1) {
            commitmentRate = +model.spec_cmm_value/100;
        } else {
            commitmentRate = +model.commitment_rate/100;
        }
        let discostChange = 24;
        let telcomID = +model.telcomID;
        let optionalAgreement = optionalAgreementCT(cost_display/1.1, cost_calc/+1.1, customPlan, {...model, id: model.planID}, commitmentRate, discostChange, telcomID)?.optionalAgreement || 0;
        /* end : tiền giảm giá cam kết lựa chọn */

        this.props.history.push({
            pathname: `/${routeName.WIRELESS_ADVICE}`,
            state: {
                WirelessAdviceStep3:{
                    ...this.state.WirelessAdviceStep3,
                    telcomeID,
                    model,
                    successString: telcom.image_url ? telcom.image_url.original : "",
                    telcomCode: telcom.code ? telcom.code : "",
                    telcomName: telcom.name ? telcom.name: "",
                    installmentMonth: this.state.filtersSelected.calc_inst_month,
                    optionFee: this.state.filtersSelected.calc_subsidy_type  == 79 ? OPTION_FEE.disclosureSubsidy : OPTION_FEE.optionalAgreement,
                    disclosureSubsidy: model.dps_amt_cost,
                    // optionalAgreement: model.spec_cmm == 1
                    //     ? +(model.spec_cmm_value * model.cost_display/100)+(model.spec_cmm_value * model.cost_display/100)*10/100
                    //     : +(model.commitment_rate * model.cost_display/100)+(model.commitment_rate * model.cost_display/100)*10/100,
                    optionalAgreement: optionalAgreement,
                    packageFee:{
                        // ...model.plan,
                        id: model.planID,
                        classID: model.classID,
                        name: model.plan_name,
                        cost_display: model.cost_display,
                        class: model.class,
                        discost_ad_cond: model.discost_ad_cond,
                        spec_cmm: model.spec_cmm,
                        spec_cmm_value: model.spec_cmm_value,
                        discost_12: model.discost_12,
                        discost_24: model.discost_24,
                        plan_classification : {
                            class: model.class,
                        }
                    },
                    startingPrice: model.dl_price,
                    basicCost: Math.floor(+model.cost_display+(model.cost_display*10/100)),
                    interestRate: model.installment_rate,
                    conversion2G: null,
                    optionalAgreementDefault: null,
                    discost12: model.discost_12,
                    discost24: model.discost_24,
                    displayApplyDate: model.dsp_apply_date,
                    rateAgreement: +model.discost_24 + model.discost_24*10/100,
                    rateAgreementDefault: +model.discost_24 + model.discost_24*10/100,
                    saler: this.props.auth.user,
                    contractMonth: 24,
                    discostChange: 24,
                },
                color: model.color ? model.color[0] : "",
                installment_payment_method: telcom.installment_payment_method,
                referrer: '/smart-advice',
            }
        });
    }

    renderModelPlans = (models) => {
        if(isIssetArray(models)){
            return (
                <Fragment>
                    {
                        models.map((item, i) => {
                            let telcom = _.find(this.state.dataFilters.telcoms, {id: item.telcomID});
                            let dataModelsByTelcom = _.find(this.state.dataModelPlan, {telcomID: item.telcomID});
                            return (
                                <Fragment key={i}>
                                    <div className="card mb-3" key={item.telcomID} id={`telcom-${item.telcomID}`} >
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="btn bt-mobile btn-block text-left"
                                                        type="button" data-toggle="collapse"

                                                        data-target={`#collapseOne-${item.telcomID}`} aria-expanded="true"
                                                        aria-controls={`collapseOne-${item.telcomID}`}>
                                                            {(telcom.code == 'hello_lg' || telcom.code == 'hello_kt' ) ? (<span className="font-14">{telcom.name}</span>) : (<img
                                                        src={ telcom.image_url && telcom.image_url['original'] ? telcom.image_url['original'] : "" }
                                                        alt=""/>)}
                                                    
                                                </button>
                                            </h2>
                                        </div>

                                        <div id={`collapseOne-${item.telcomID}`} className="collapse show" data-parent={`#telcom-${item.telcomID}`}
                                             aria-labelledby="headingOne">
                                            <div className="card-body">
                                                <article className="table-form">
                                                    <div className="">
                                                        <InfiniteScroll
                                                            dataLength={dataModelsByTelcom.result.data.length}
                                                            next={() => this.fetchMoreData(dataModelsByTelcom.telcomID, dataModelsByTelcom.result)}
                                                            // hasMore={dataModelsByTelcom.result.current_page < dataModelsByTelcom.result.last_page}
                                                            hasMore={dataModelsByTelcom.result.data.length > 0 || false}
                                                            // loader={<div className="text-center">로드중...</div>}
                                                            scrollableTarget={`scrollableDiv-${i}`}
                                                            scrollThreshold={0.5}
                                                        >
                                                            <table className="table">
                                                                <thead>
                                                                <tr>
                                                                    <th
                                                                        className="wt-170 text-left"
                                                                        scope="col"

                                                                    >
                                                                        <div>
                                                                            단말기명
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="d-flex align-items-center">
                                                                            <span
                                                                                className={'cursor'}
                                                                                onClick={() => this.state.sorts[item.telcomID]
                                                                                && this.state.sorts[item.telcomID].dl_price == "asc"
                                                                                    ? this.onSort(item.telcomID, 'dl_price', 'desc')
                                                                                    : this.onSort(item.telcomID, 'dl_price', 'asc')}
                                                                            >
                                                                                출고가
                                                                            </span>
                                                                            <div className="sort-fillter"
                                                                                 onClick={() => {this.state.sorts[item.telcomID]
                                                                                                && this.state.sorts[item.telcomID].dl_price == "asc"
                                                                                                    ? this.onSort(item.telcomID, 'dl_price', 'desc')
                                                                                                    : this.onSort(item.telcomID, 'dl_price', 'asc')
                                                                                                document.getElementById(`scrollableDiv-${i}`).scrollTop = 0
                                                                                                }}
                                                                            >
                                                                                <button className={`btn btn-sort ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].dl_price == "desc" ? "sort-active" : ""} p-0`}>
                                                                                    <i className="fas fa-sort-up"></i>
                                                                                </button>
                                                                                <button className={`btn btn-sort ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].dl_price == "asc" ? "sort-active" : ""}  p-0`} >
                                                                                    <i className="fas fa-sort-down"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className={`${this.state.filtersSelected.calc_subsidy_type == 79 ? 'd-flex' : ''} align-items-center`}>
                                                                            <span
                                                                                className={'cursor'}
                                                                                onClick={() => this.state.filtersSelected.calc_subsidy_type == 79
                                                                                    ?  this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].dps_amt_cost == "desc" ?this.onSort(item.telcomID, 'dps_amt_cost', 'asc') : this.onSort(item.telcomID, 'dps_amt_cost', 'desc')
                                                                                    // : this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].commitment_price == "desc" ?this.onSort(item.telcomID, 'commitment_price', 'asc') : this.onSort(item.telcomID, 'commitment_price', 'desc') 
                                                                                    : null
                                                                                }

                                                                            >
                                                                                {
                                                                                    this.state.filtersSelected.calc_subsidy_type == 79  // nếu là hỗ trợ thông báo thì hiện cột THTTB
                                                                                        ? "지원금"
                                                                                        : "선택약정할인"
                                                                                }
                                                                            </span>
                                                                            {this.state.filtersSelected.calc_subsidy_type == 79&&
                                                                            <div className="sort-fillter"
                                                                                 onClick={() => {this.state.filtersSelected.calc_subsidy_type == 79
                                                                                     ?  this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].dps_amt_cost == "desc" ?this.onSort(item.telcomID, 'dps_amt_cost', 'asc') : this.onSort(item.telcomID, 'dps_amt_cost', 'desc')
                                                                                     : this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].commitment_price == "desc" ?this.onSort(item.telcomID, 'commitment_price', 'asc') : this.onSort(item.telcomID, 'commitment_price', 'desc') 
                                                                                     document.getElementById(`scrollableDiv-${i}`).scrollTop = 0
                                                                                    }}
                                                                            >
                                                                                <button className={`btn btn-sort p-0 ${ this.state.sorts[item.telcomID] && (this.state.filtersSelected.calc_subsidy_type == 79 ? this.state.sorts[item.telcomID].dps_amt_cost : this.state.sorts[item.telcomID].commitment_price) == "desc" ? "sort-active" : ""}`}

                                                                                >
                                                                                    <i className="fas fa-sort-up"></i>
                                                                                </button>
                                                                                <button className={`btn btn-sort p-0 ${ this.state.sorts[item.telcomID] && (this.state.filtersSelected.calc_subsidy_type == 79 ? this.state.sorts[item.telcomID].dps_amt_cost : this.state.sorts[item.telcomID].commitment_price) == "asc" ? "sort-active" : ""}`}>
                                                                                    <i className="fas fa-sort-down"></i>
                                                                                </button>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="d-flex align-items-center">
                                                                            <span
                                                                                className={'cursor'}
                                                                                onClick={()=>this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].installment_price == "desc" ? this.onSort(item.telcomID, 'installment_price', 'asc') : this.onSort(item.telcomID, 'installment_price', 'desc')}
                                                                            >
                                                                                할부금
                                                                            </span>
                                                                            <div className="sort-fillter"
                                                                                 onClick={()=>{this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].installment_price == "desc" ? this.onSort(item.telcomID, 'installment_price', 'asc') : this.onSort(item.telcomID, 'installment_price', 'desc')
                                                                                               document.getElementById(`scrollableDiv-${i}`).scrollTop = 0
                                                                                }}
                                                                            >
                                                                                <button className={`btn btn-sort p-0  ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].installment_price == "desc" ? "sort-active" : ""} `} >
                                                                                    <i className="fas fa-sort-up"></i>
                                                                                </button>
                                                                                <button className={`btn btn-sort p-0  ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].installment_price == "asc" ? "sort-active" : ""} `}>
                                                                                    <i className="fas fa-sort-down"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="d-flex align-items-center">
                                                                            <span
                                                                                className={'cursor'}
                                                                                onClick={()=>this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].monthly_bill_price == "desc" ? this.onSort(item.telcomID, 'monthly_bill_price', 'asc') : this.onSort(item.telcomID, 'monthly_bill_price', 'desc')}
                                                                            >
                                                                                월 청구금액
                                                                            </span>
                                                                            <div className="sort-fillter"
                                                                                 onClick={()=>{this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].monthly_bill_price == "desc" ? this.onSort(item.telcomID, 'monthly_bill_price', 'asc') : this.onSort(item.telcomID, 'monthly_bill_price', 'desc')
                                                                                                document.getElementById(`scrollableDiv-${i}`).scrollTop = 0
                                                                                 }}>
                                                                                <button className={`btn btn-sort p-0  ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].monthly_bill_price == "desc" ? "sort-active" : ""} `}
                                                                                >
                                                                                    <i className="fas fa-sort-up"></i>
                                                                                </button>
                                                                                <button className={`btn btn-sort p-0  ${this.state.sorts[item.telcomID] && this.state.sorts[item.telcomID].monthly_bill_price == "asc" ? "sort-active" : ""} `}>
                                                                                    <i className="fas fa-sort-down"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="wt-170 text-right" scope="col">
                                                                        <div className={"th-blue"}>
                                                                            <div
                                                                                className="d-flex justify-content-end cursor align-items-center"
                                                                                onClick={() => this.handleShowModalPlanByTelcom(item.telcomID)}
                                                                            >
                                                                                <img className="mr-1" src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-list.svg"}
                                                                                     alt=""/>
                                                                                요금제
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                            <div className="table-scroll" id={`scrollableDiv-${i}`}>
                                                                <table className="table table-striped mb-0">
                                                                    <tbody>
                                                                    {
                                                                        item.result.data.length > 0 ?
                                                                            item.result.data.map((model, i) => {

                                                                                /* tiền giảm giá cam kết lựa chọn */
                                                                                let cost_display = +model.cost_display + (+model.cost_display*0.1);
                                                                                let cost_calc = +model.cost_calc + (+model.cost_calc*0.1);
                                                                                let customPlan = 1;
                                                                                let commitmentRate ;
                                                                                if (model.spec_cmm == 1) {
                                                                                    commitmentRate = +model.spec_cmm_value/100;
                                                                                } else {
                                                                                    commitmentRate = +model.commitment_rate/100;
                                                                                }
                                                                                let discostChange = 24;
                                                                                let telcomID = +model.telcomID;
                                                                                let optionalAgreement = optionalAgreementCT(cost_display/1.1, cost_calc/+1.1, customPlan, {...model, id: model.planID}, commitmentRate, discostChange, telcomID).optionalAgreement || 0;
                                                                                let monthly_bill_price = +model.monthly_bill_price
                                                                                if (this.state.filtersSelected.calc_subsidy_type == 80) {
                                                                                    monthly_bill_price = roundUpNumber(monthly_bill_price) - optionalAgreement
                                                                                }
                                                                                if (monthly_bill_price < 0) {
                                                                                    monthly_bill_price = 0
                                                                                }
                                                                                /* end : tiền giảm giá cam kết lựa chọn */
                                                                                return (
                                                                                    <tr key={i} onDoubleClick={() => this.redirectWireLessAdvice(item.telcomID, model)}>
                                                                                        <th scope="row" className="name wt-170 text-left">
                                                                                            <div>
                                                                                                {model.device_name}
                                                                                                <span className="storage">
                                                                                                    {model.storage
                                                                                                    && (model.storage !== '0'
                                                                                                        && model.storage !== null )
                                                                                                        ?(<> ({model.storage}GB)</>)
                                                                                                        : (<></>)}
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                        <td className="prices-table">
                                                                                            <div>
                                                                                                {model.dl_price ? priceFormat(model.dl_price) : 0}원
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                {
                                                                                                    this.state.filtersSelected.calc_subsidy_type == 79  // nếu là hỗ trợ thông báo thì hiện cột THTTB
                                                                                                        ? (model.dps_amt_cost ? priceFormat(model.dps_amt_cost) : 0)
                                                                                                        : (optionalAgreement ? priceFormat(+optionalAgreement) : 0)
                                                                                                }원
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                {model.installment_price ? priceFormat(model.installment_price) : 0}원
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                {monthly_bill_price  ? priceFormat(roundUpNumber(monthly_bill_price)) : 0} 원
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="text-right">
                                                                                            <div>
                                                                                                {model.plan_name ? model.plan_name : '-'}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : (
                                                                                <tr>
                                                                                    <th colSpan={6} className="name">{ MessageJSON.smart_consultant.no_models}</th>
                                                                                </tr>
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </InfiniteScroll>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })

                    }
                </Fragment>
            )
        }
    }

    /**
     * show modal plan
     */
    // handleShowModalPlan = () => {
    //     if(this.state.filtersSelected.telcom.length > 0){
    //         this.setState({
    //             modalPlans: {
    //                 ...this.state.modalPlans,
    //                 isShowModal : true,
    //             },
    //         })
    //     }
    // }

    /**
     * toogle modal plan
     */
    // toggleModal = () => {
    //     this.setState({
    //         modalPlans: {
    //             ...this.state.modalPlans,
    //             isShowModal : false
    //         }
    //     })
    // }
    /**
     *
     */
    // planCustomResultPackage = (data) => {
    // }

    /**
     * return result plan id
     */
    // planResultPackage = (data) => {
    //     this.setState({
    //         filtersSelected: {
    //             ...this.state.filtersSelected,
    //             price_type: null
    //         }
    //     }, () => {
    //         this.updateFilter(data, 'plan');
    //     })
    // }



    //modal  plan by 1 telcom
    /**
     * show modal plan
     */
    handleShowModalPlanByTelcom = (telcomID) => {
        this.setState({
            modalPlansByTelcom: {
                ...this.state.modalPlansByTelcom,
                isShowModalByTelcom : true,
            },
            telcomIDPlanModal: telcomID
        })
    }

    /**
     * toogle modal plan
     */
    toggleModalByTelcom = () => {
        this.setState({
            modalPlansByTelcom: {
                ...this.state.modalPlansByTelcom,
                isShowModalByTelcom : false
            }
        })
    }
    /**
     *
     */
    planCustomResultPackageByTelcom = (plan) => {
        this.setState({
            planIDSeleted: {
                ...this.state.planIDSeleted,
                [this.state.telcomIDPlanModal] : plan.planId //telcomID: plan
            }
        }, () => {
            this.fetchModelByTelcome(this.state.telcomIDPlanModal, plan.planId);
        })
    }

    /**
     * return result plan id
     */
    planResultPackageByTelcom = (plan) => {
        this.setState({
            planIDSeleted: {
                ...this.state.planIDSeleted,
                [this.state.telcomIDPlanModal] : plan //telcomID: plan
            },
            filtersSelected:{
                ...this.state.filtersSelected,
                price_type: null,
            }
        }, () => {
            this.fetchModelByTelcome(this.state.telcomIDPlanModal, plan);
        })

    }
    //end modal  plan by 1 telcom

    fetchModelByTelcome = (telcomID, plan) => {
        this.props.showLoading();
        let sorts = this.state.sorts[telcomID];
        let dataSort = [];
        if(sorts){
            for (const [key, value] of Object.entries(sorts)) {
                dataSort.push(`${key}|${value}`)
            }
        }else{
            dataSort = [...this.state.filtersSelected.sort];
        }
        let data = {
            ...this.state.filtersSelected,
            telcom: telcomID,
            sort:dataSort,
            page: 1, // reset to page 1
            plan: plan ? plan.id : this.state.planIDSeleted[telcomID]?.id,
        };
        api.smart_consultant.getModelPlan(queryString.stringify(data, {arrayFormat: 'bracket'}))
            .then(res => {
                let indexOldModelPlan = _.findIndex(this.state.dataModelPlan, {telcomID: telcomID});
                let dataModel = {
                    ...this.state.dataModelPlan[indexOldModelPlan],
                    result: {
                        ...this.state.dataModelPlan[indexOldModelPlan].result,
                        data: res.data.data,
                        current_page: this.state.dataModelPlan[indexOldModelPlan].result.current_page + 1
                    }
                }
                let newDataModelPlan = [...this.state.dataModelPlan];
                newDataModelPlan[indexOldModelPlan] = dataModel;
                this.setState({
                    dataModelPlan: newDataModelPlan
                }, () => {
                    this.props.hideLoading();
                });
            })
            .catch(err => {
                this.props.hideLoading();
            })

    }

    render() {
        let {telcoms, standard_types, manus,  subsidy_types, installment_months, price_types} = this.state.dataFilters;
        let modelPlans = this.state.dataModelPlan;
        let {plan} = this.state.filtersSelected;
        // let {isShowModal} = this.state.modalPlans;
        let {isShowModalByTelcom} = this.state.modalPlansByTelcom;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <section className="advisory-smart">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="left-advisory-smart">
                                        <div className="button-resset" onClick={() => this.onResetFilter()}>
                                            <button className="btn btn-wireless">
                                               <IconReset className="mr-1" />
                                                초기화
                                            </button>
                                        </div>
                                        <h2 className="title-smart">통신사 선택 </h2>
                                        <div className="group-check row">
                                            {this.renderTelcom(telcoms)}
                                        </div>
                                        <div className={`${this.state.filtersSelected.telcom.length > 0 ? "click-check" : "no-check"}`}>
                                            <article>
                                                <div className="form-group align-items-start row">
                                                    <h2 className="title-smart mt-3 col-4">제조사</h2>
                                                    { this.renderManufacturers(manus)}
                                                </div>
                                                <div className="form-group align-items-start row">
                                                    <h2 className="title-smart mt-3 col-4">단말기 유형 </h2>
                                                    { this.renderDeviceType(standard_types)}

                                                </div>
                                                <div className="form-group align-items-start row">
                                                    <h2 className="title-smart mt-3 col-4">보조금유형</h2>
                                                    <div className="col-8 pl-0">
                                                        { this.renderSubsidyType(subsidy_types)}

                                                    </div>
                                                </div>
                                                <div className="form-group align-items-start row">
                                                    <h2 className="title-smart mt-3 col-4">할부개월</h2>
                                                    <div className="col-8 pl-0">
                                                        { this.renderInstallmentMonth(installment_months)}
                                                    </div>
                                                </div>
                                                <div className="form-group align-items-start row">
                                                    <h2 className="title-smart mt-3 col-4">요금제 설정</h2>
                                                    <ul className="list-network  mb-0 col-8 mt-0">
                                                        <li className={ this.state.filtersSelected.price_type && this.state.filtersSelected.price_type == price_types[0] ? "active": ""}
                                                            onClick={(e) => this.onChangePriceType(price_types[0])}>
                                                            <span>고가</span>
                                                        </li>
                                                        <li  className={ this.state.filtersSelected.price_type && this.state.filtersSelected.price_type == price_types[1] ? "active": ""}
                                                             onClick={() => this.onChangePriceType(price_types[1])}>
                                                            <span>저가</span>
                                                        </li>
                                                        {/*<li className={  this.state.filtersSelected.plan && !_.isEmpty(this.state.filtersSelected.plan)   ? "active": ""  }*/}
                                                        {/*onClick={this.handleShowModalPlan} >*/}
                                                        {/*<span>{ this.state.filtersSelected?.plan?.name || "수동 선택"}</span>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="right-advisory-smart show-content">
                                        <div style={{"textAlign" : "right"}}>
                                            <span class="text-content font-13">모비셀서비스는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.</span>
                                        </div>
                                        <div className="accordion" id="accordionExample">
                                            { this.renderModelPlans(modelPlans)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*<PlanInformationModal*/}
                {/*toggleModal = {this.toggleModal}*/}
                {/*isShowModal = {isShowModal}*/}
                {/*telcomID = {this.state.filtersSelected.telcom}*/}
                {/*planCustomResultPackage = {this.planCustomResultPackage}*/}
                {/*planResultPackage = {this.planResultPackage}*/}
                {/*checkUnApply = {true}*/}
                {/*selected={this.state.filtersSelected.plan}*/}
                {/*/>*/}
                <PlanInformationModalByTelcom
                    toggleModal = {this.toggleModalByTelcom}
                    isShowModal = {isShowModalByTelcom}
                    telcomID = {[this.state.telcomIDPlanModal]}
                    planCustomResultPackage = {this.planCustomResultPackageByTelcom}
                    planResultPackage = {this.planResultPackageByTelcom}
                    checkUnApply = {true}
                    selected={this.state.planIDSeleted?.[this.state.telcomIDPlanModal]}
                    planData={this.state.planIDSeleted?.[this.state.telcomIDPlanModal]?.planId}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(SmartAdvice);

