 import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from "../../utils/api";
import { showLoading, hideLoading } from '../../store/actions/ui';

class ModalResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-payment-custom'}
          onOpened={this.modalOpened}
        >
          <div className="modal-body">
            {this.props.status && (
              <p className="notification_delete text-center">
                해당 계좌는 등록 성공했습니다. member ID는<br /> <strong>{this.props.memberId}</strong> 입니다.
              </p>
            )}
            {!this.props.status && (
              <p className="notification_delete text-center">
                해당 계좌는 등록 실패했습니다. <br />
                등록 정보는 다시 확인해주세요!
              </p>
            )}
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button type="button" className="btn btn-submit" onClick={this.props.submit}>확인</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ModalResult.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  selectItem: PropTypes.func,
  openPopup: PropTypes.func,
  submit: PropTypes.func,
  isOpenModal: PropTypes.bool,
  status: PropTypes.bool,
  memberId: PropTypes.any,
};

const FORM_NAME = 'PaymentHyosungCMS';

const mapStateToProps = state => {
  return {
    PaymentHyosungCMS: getFormValues(FORM_NAME)(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalResult);
