import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {reduxForm, getFormSyncErrors, getFormValues, change} from 'redux-form';
import {Modal} from 'reactstrap';
import api from "../../../utils/api";
import {showLoading, hideLoading} from '../../../store/actions/ui';
import {POTENTIAL_CUSTOMER} from "../../../utils/constant";
import DetailCustomerModal from './DetailCustomerModal'
import EditPotentialCustomersModal from './EditPotentialCustomersModal'
import {updateWiredAdviceStep1, updateWiredAdviceStep2} from "../../../utils/helper";


class PotentialCustomersModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            customersObjChoose: {},
            selected: null,
            customerDetail: {},
            params: {
                limit: 20,
                type: "wired"
            },
            openSubModalState: false,
            modalDetailCustomer: false,
            modalEditCustomer: false,
            id: ''
        }
    }

    modalOpened = () => {
        this.props.showLoading()
        api.customer.getList(this.state.params)
            .then(response => {
                this.props.hideLoading()
                this.setState({
                    customers: response.data.data.data
                })
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    modalOpenedSub = () => {

    }
    openSubModal = (id) => {
        const state = this.state.openSubModalState
        this.setState({
            openSubModalState: !state,
            id: id
        })
    }

    openEditCustomer = (closeAll = null) => {
        const state = this.state.modalEditCustomer
        this.setState({
            modalEditCustomer: !state,
        })
    }


    normalizeInputs = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        let topPhone = value.substring(0, 3)
        let checkCodePhone = POTENTIAL_CUSTOMER.code_phone.includes(topPhone)
        if (checkCodePhone) {
            if (value.length > 0) {
                if (cvLength < 4) return currentValue;
                // return `${currentValue.slice(3, 7)}-${currentValue.slice(7, 11)}`;
                return `${currentValue.slice(3, 6)}-${currentValue.slice(6, 11)}`;
            }
        } else {
            if (value.length > 0) {
                if (cvLength <= 4) return currentValue;
                if (cvLength > 4 && cvLength <= 8) return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}`;
                if (cvLength > 4 && cvLength <= 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 7)}`;
            }
        }

    };

    normalizePhone = (value) => {
        if (!value) {
            return value
        }
        const onlyNums = value.replace(/[^\d]/g, '')
        if (onlyNums.length <= 3) {
            return onlyNums
        }
        if (onlyNums.length < 7) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
        }
        if (onlyNums.length == 7) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3,7)
        }
        if (onlyNums.length == 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4,8)
        }
        if (onlyNums.length > 4 && onlyNums.length > 8) return 'error';
    }

    deleteCus = (id) => {
        this.props.showLoading()
        const {WiredAdvice: {CreatePotentialCustomer}} = this.props
        api.customer.delete(id,{limit:20,type:'wired'})
            .then(response => {

                if (CreatePotentialCustomer?.id && (CreatePotentialCustomer?.id == id)) {
                    this.props.change('CreatePotentialCustomer', {
                        id: '',
                        name: '',
                        phone: '',
                        phoneFormat: '',
                        note: '',
                        topPhone: '010',
                    })
                }

                this.setState({
                    customers: response.data.data.data
                })
                this.props.hideLoading()
                this.openSubModal(0)
            })
            .catch(() => {
                this.props.hideLoading()
            })

    }

    openDetailCustomer = (closeAll = null) => {
        const state = this.state.modalDetailCustomer
        this.setState({
            modalDetailCustomer: !state,
        })
    }

    handleClickRow = (customer) => {

        this.openDetailCustomer()
        let dataDetail = this.state.customers.filter(item => item.id == customer?.id)
        if (dataDetail.length > 0) {
            let customer = {
                id: dataDetail[0]?.id,
                name: dataDetail[0]?.name,
                phone: dataDetail[0]?.phone,
                phoneFormat: this.normalizePhone(dataDetail[0]?.phone.slice(3,dataDetail[0]?.phone.length)),
                note: dataDetail[0]?.note,
                topPhone: dataDetail[0]?.phone.substring(0, 3)
            }
            this.setState({
                customerDetail: dataDetail[0],
                customersObjChoose:customer
            })
            // this.props.change('CreatePotentialCustomer', customer)
        }

    }

    loadCustomer = () => {
        this.props.change('CreatePotentialCustomerDefault', {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate: true,
        });
        let customer = this.state.customerDetail

        // this.props.openPopup()
        this.props.showLoading()
        api.advice.getConsultant(customer.consultant_id)
            .then(response => {
                const {data} = response.data
                const {WiredAdvice: {WiredAdviceStep1, WiredAdviceStep2}} = this.props

                let WiredAdviceStep1Clone = updateWiredAdviceStep1(WiredAdviceStep1, data)
                let WiredAdviceStep2Clone = updateWiredAdviceStep2(WiredAdviceStep2, data)

                let customer = {
                    id: data?.customer_id,
                    name: data?.customer?.name,
                    phone: data?.customer?.phone,
                    phoneFormat: this.normalizePhone(data?.customer?.phone.slice(3,data?.customer?.phone.length)),
                    note: data?.customer_note,
                    topPhone: data?.customer?.phone.substring(0, 3),
                    statusCreate: true,
                }


                // this.props.change('WiredAdviceStep1', WiredAdviceStep1)
                // this.props.change('WiredAdviceStep3.stateLoadPotential', true)
                // this.props.change('WiredAdviceStep3Default.welfareDiscount', WiredAdviceStep3Clone.welfareDiscount)
                // this.props.change('WiredAdviceStep3Default.promotion', data.plan_promotion || {})
                // this.props.change('WiredAdviceStep3Default.pointDiscount', data?.point_discount)
                // this.props.change('id', data.id)
                // this.props.change('WiredAdviceStep2.color', data.device_color)
                // this.props.change('WiredAdviceStep3', WiredAdviceStep3Clone)
                // this.props.change('CreatePotentialCustomerDefault', customer)
                // this.props.hideLoading()
                // this.props.change('WiredAdviceStep3.stateLoadPotential', false)



                this.props.change('WiredAdviceStep1', WiredAdviceStep1Clone)
                this.props.change('WiredAdviceStep2', WiredAdviceStep2Clone)

                this.props.change('CreatePotentialCustomerDefault', customer)
                this.props.hideLoading()

                if (this.props.isOpenModalCreate) {
                    this.props.openPopupCreate()
                }

                setTimeout(() => {
                    this.props.openPopup()
                },200)
                setTimeout(() => {
                    this.openDetailCustomer()
                },100)
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }

    editCustmer = (id) => {
        this.openEditCustomer()
        let dataEdit = this.state.customers.filter(item => item.id == id)
        if (dataEdit.length > 0) {
            let customer = {
                id: dataEdit[0]?.id,
                name: dataEdit[0]?.name,
                phone: dataEdit[0]?.phone,
                phoneFormat: this.normalizePhone(dataEdit[0]?.phone.slice(3,dataEdit[0]?.phone.length)),
                note: dataEdit[0]?.note,
                topPhone: dataEdit[0]?.phone.substring(0, 3)
            }
            this.props.change('EditPotentialCustomer', customer)


        }
    }

    toggleModal = () => {
        this.props.openPopup()
        if (this.props.isOpenModalCreate) {
            this.props.openPopupCreate()
        }
    }

    render() {
        const {customers} = this.state
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpenModal}
                    toggle={this.toggleModal}
                    fade={true}
                    // backdrop={"static"}
                    className={'modal-dialog modal-xl modal-people-review'}
                    onOpened={this.modalOpened}
                    contentClassName="pl-4 pr-4"
                >

                    <div className="modal-header">
                        <h5 className="modal-title flex-header">
                            가망고객
                        </h5>
                        <button type="button" className="close close-modal" onClick={this.toggleModal}>
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                        </button>
                    </div>
                    <div className="modal-body modal-counseling-history">
                        <article>
                            <table className="table table-header">
                                <thead>
                                <tr className="bg-purple">
                                    <th className="text-left">No</th>
                                    <th className="text-left">고객명</th>
                                    <th className="text-left">연락처</th>
                                    <th className="text-left">통신사</th>
                                    <th className="text-left">모델명</th>
                                    <th className="text-left">요금제</th>
                                    <th className="text-left w-170">메모</th>
                                    <th className="text-right"></th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                                <table className="table">
                                    <tbody>
                                    {customers.map((customer, key) => (
                                        <tr key={key}>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{key + 1}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{customer?.name}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{customer?.phone}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{customer?.telcom_name}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{customer?.model_name_wired == null ? '인터넷' : customer.model_name_wired}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left">{customer?.plan_name_wired == null ? '인터넷 요금제' : customer.plan_name_wired}</td>
                                            <td onClick={() => this.handleClickRow(customer)}
                                                className="text-left color-sliver">{customer?.note ? ((customer?.note).length > 15 ? customer?.note.substring(0, 15) + '...' : customer?.note) :
                                                customer?.note}</td>
                                            <td className="text-right">
                                                <div className={'d-flex justify-content-end'}>
                                                    <button className="btn p-0 mr-2" onClick={()=>this.editCustmer(customer?.id)}>
                                                        <img src="/images/icon-edit.svg" alt=""/>
                                                    </button>
                                                    <button type="button" className="btn p-0"
                                                            onClick={() => this.openSubModal(customer?.id)}>
                                                        <img src="/images/delete-02.svg" alt=""/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </article>
                    </div>

                </Modal>
                <Modal
                    isOpen={this.state.openSubModalState}
                    toggle={this.openSubModal}
                    fade={true}
                    // backdrop={"static"}
                    className={'modal-dialog modal-dialog-centered'}
                    onOpened={this.modalOpenedSub}
                >

                    <div className="modal-body text-center p-5">
                        <span className="notification_delete">삭제하시겠습니까?</span>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn_close" onClick={() => this.openSubModal(0)}>취소</button>
                        <button type="button" className="btn btn-submit pr-5 pl-5"
                                onClick={() => this.deleteCus(this.state.id)}>확인
                        </button>
                    </div>

                </Modal>

                <DetailCustomerModal isOpenModal={this.state.modalDetailCustomer}
                                     customersObjChoose={this.state.customersObjChoose}
                                     loadCustomer={this.loadCustomer}
                                     openModalPopup={this.openDetailCustomer}
                                     customerDetail={this.state.customerDetail}/>
                <EditPotentialCustomersModal modalOpened={this.modalOpened} isOpenModal={this.state.modalEditCustomer} openPopup={this.openEditCustomer}  />
            </Fragment>
        );
    }
}

PotentialCustomersModal.propTypes = {
    WiredAdvice: PropTypes.object,
    isOpenModal: PropTypes.bool,
    openPopup: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    change: PropTypes.func,
    telcomParents: PropTypes.object,
    openPopupCreate: PropTypes.func,
    isOpenModalCreate: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        telcomParents: state.dataStore.telcomProviders,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PotentialCustomersModal);
