import {
    DISCOUNT_CARD
} from '../actions';

import api from "../../utils/api";

export const getDiscountCardSuccess = (data) => {
    return {
        type: DISCOUNT_CARD.GET_DISCOUNT_CARD_SUCCESS,
        data: data
    }
};

export const getDiscountCard = (data) => dispatch => {
    return api.discount_card.getDiscountCard(data)
        .then(res => {
            dispatch(getDiscountCardSuccess(res.data.data))
            return Promise.resolve(res.data.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getPromotion = (data) => dispatch => {
    return api.advice.getDetailPromotion(data)
        .then(res => {
            dispatch(getDiscountCardSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getDetailPlan = (data, params) => dispatch => {
    return api.advice.getDetailPlan(data,params)
        .then(res => {
            dispatch(getDiscountCardSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};
