import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import WirelessStep1 from './WirelessAdviceComponent/WirelessStep1';
import WirelessStep2 from './WirelessAdviceComponent/WirelessStep2';
import WirelessStep3 from './WirelessAdviceComponent/WirelessStep3';
import {
    getAdviceWireless
} from '../../store/actions/dataStore';
import {showModal, hideModal} from '../../store/actions/modal';
import {reduxForm, getFormSyncErrors, getFormValues, change} from 'redux-form';
import {showLoading, hideLoading} from '../../store/actions/ui';
import _ from 'lodash'
import {
    caclWirelessStep3,
    getTextWirelessStep3Discount
} from './../../utils/helper'
import api from "../../utils/api";
import {INSTALLMENT_PAYMENT_METHOD, CONSULTANT_WIRELESS, ADVICE_TYPE} from './../../utils/constant'
import WirelessAdviceStepHeader from './WirelessAdviceComponent/WirelessAdviceStepHeader'
import CombineDiscountModal from "./../../components/Wireless/CombinedDiscount/CombineDiscountModal";
import LockWirelessPopup from './WirelessAdviceComponent/LockWirelessPopup';

//import getPayForMonthAfter_lguplus_2 from './../../components/Wireless/UsedPhoneCompensation/UsedProgramMainPopup.js'

class WirelessAdvice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            finishInit: false,
            referrer: '',
            isShowModal: false,
            isShowLockWireless: true,
        }

        this.saveDataDebounce = _.debounce(this.saveData, 500)
        // this.step2Ref = React.createRef();
    }

    componentDidMount() {
        const {history} = this.props
        this.props.showLoading();

        if (
            history.location.state
            && history.location.state.WirelessAdviceStep3
            && Object.keys(history.location.state.WirelessAdviceStep3).length > 0
        ) {
            let state = {...history.location.state};
            let step3 = {
                ...state.WirelessAdviceStep3,
                phone: state.WirelessAdviceStep3.model,
            }
            if (state.dataPageHistoryConsultant) {
                step3 = {
                    ...state.WirelessAdviceStep3,
                    phone: state.WirelessAdviceStep3.model,
                }
                setTimeout(() => {
                    this.props.change('id', state.id)
                }, 50)
            }
            let step1 = {
                telcomID: state.WirelessAdviceStep3.telcomeID,
                telcomName: state.WirelessAdviceStep3.telcomName,
                telcomCode: state.WirelessAdviceStep3.telcomCode,
                status: true,
                successString: state.WirelessAdviceStep3.successString,
                installmentPaymentMethod: state.installment_payment_method,
            }
            let step2 = {
                status: true,
                color: state.color
            }
            this.setState({
                referrer: state.referrer
            })
            this.props.change('step', 3)
            this.changeUrl(3)
            setTimeout(() => {
                this.props.change('WirelessAdviceStep1', step1)
                this.props.change('WirelessAdviceStep2', step2)
                this.props.change('WirelessAdviceStep3', step3);
            }, 50)
            delete state.WirelessAdviceStep3;
            delete state.color;
            delete state.referrer;

            history.replace({...history.location, state});
        } else {
            this.setState({
                referrer: ''
            })
        }

        this.props.getAdviceWireless().then(() => {
            this.props.hideLoading();
            if (
                history.location.state
                && history.location.state.WirelessAdviceStep3
                && Object.keys(history.location.state.WirelessAdviceStep3).length > 0
            ) {
                this.setState({
                    finishInit: true
                })
            } else {
                this.setState({
                    finishInit: true
                })
            }
        }).catch(() => {
            this.props.hideLoading();
        });

        this.hashHistoryListener = history.listen((newLocation, action) => {
            if (this.state.referrer
                || [2, 3, '2', '3'].includes(this.props.WirelessAdvice.step)) {
                if (action === "POP") {
                    if (this.state.referrer) {
                        this.props.history.push(this.state.referrer)
                        this.setState({
                            referrer: ''
                        })
                    } else {
                        if (this.props.WirelessAdvice.step == 2) {
                            this.props.change('step', 1)
                            this.changeUrl(1)
                        }
                        if (this.props.WirelessAdvice.step == 3) {
                            this.props.change('step', 2)
                            this.changeUrl(2)
                        }
                    }
                }
            } else if (this.props.WirelessAdvice.step == 1) {
                if (action === "POP") {
                    this.props.history.goBack()
                }
            }
        });
    }

    componentWillUnmount() {
        this.hashHistoryListener();
    }

    componentDidUpdate(prevProps) {
        let prevWirelessAdvice = prevProps.WirelessAdvice || {}
        let propsWirelessAdvice = this.props.WirelessAdvice || {}
        if ([2, 3].includes(+propsWirelessAdvice.step)) {
            // nếu next step
            // gửi request lưu tư vấn
            if (prevWirelessAdvice.step < propsWirelessAdvice.step) {
                let propsWirelessAdviceStep3 = propsWirelessAdvice.WirelessAdviceStep3
                // nếu step 1 lên step 2
                // và ko có thiết bị được chọn hoặc chọn lại nhà mạng khác
                if (
                    propsWirelessAdvice.step == 2
                    && (
                        Object.keys(propsWirelessAdvice.WirelessAdviceStep3.phone).length === 0
                        || prevWirelessAdvice?.WirelessAdviceStep1?.telcomID != propsWirelessAdvice?.WirelessAdviceStep1?.telcomID
                    )
                ) {
                    propsWirelessAdviceStep3 = CONSULTANT_WIRELESS
                }
                let wirelessAdviceStep3 = caclWirelessStep3(
                    propsWirelessAdvice.WirelessAdviceStep1.installmentPaymentMethod,
                    propsWirelessAdviceStep3,
                    propsWirelessAdvice.WirelessAdviceStep1.recipe_days_remaining
                )
                this.props.change('WirelessAdviceStep3', wirelessAdviceStep3);

                // nếu step 2 lên step 3
                // và đang có thiết bị chọn trước đó
                if (
                    propsWirelessAdvice.step == 3
                    && (
                        prevWirelessAdvice.WirelessAdviceStep3.phone.model_name
                        || prevWirelessAdvice.WirelessAdviceStep3.phone.name
                    )
                ) {
                    let a = {...prevWirelessAdvice.WirelessAdviceStep3}
                    if (propsWirelessAdvice.WirelessAdviceStep3.installmentMonth === prevWirelessAdvice.WirelessAdviceStep3.installmentMonth) {
                        a.installmentMonth = null
                    }
                    let discount = getTextWirelessStep3Discount(a)
                    if (discount.length > 0) {
                        setTimeout(() => {
                            alert(
                                `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
                            )
                        }, 100)
                    }
                }

                if (Object.keys(prevWirelessAdvice.WirelessAdviceStep3.phone).length > 0 && propsWirelessAdvice.step == 2) {
                    this.props.change('id', null);
                    this.props.change('CreatePotentialCustomer', {
                        id: '',
                        name: '',
                        phone: '',
                        phoneFormat: '',
                        note: '',
                        topPhone: '010',
                        statusCreate:false
                    });
                    this.props.change('EditPotentialCustomer', {
                        id: '',
                        name: '',
                        phone: '',
                        phoneFormat: '',
                        note: '',
                        topPhone: '010',
                    });
                    this.props.change('CreatePotentialCustomerDefault', {
                        id: '',
                        name: '',
                        phone: '',
                        phoneFormat: '',
                        note: '',
                        topPhone: '010',
                        statusCreate:false
                    });
                }
                this.saveDataDebounce()
            }

            setTimeout(() => {
                if (this.props.WirelessAdvice && prevProps.WirelessAdvice) {
                    const {WirelessAdvice: {WirelessAdviceStep1, WirelessAdviceStep3}} = this.props
                    let keysToCheckDiff = [
                        'startingPrice',
                        'basicCost',
                        'optionFee',
                        'disclosureSubsidy',
                        'additionalGrants',
                        'additionalGrantsType',
                        'optionalAgreement',
                        'pointDiscount',
                        'advancedDiscountCard',
                        'cashPayment',
                        'other',
                        'installmentMonth',
                        'promotion',
                        'promotionDurationDiscountOne',
                        'promotionDurationDiscountTwo',
                        'combinedDiscount',
                        'welfareDiscount',
                        'discountCard',
                        'sim',
                        'insurrance',
                        'extraServices',
                        'cancel_date',
                        'interestRate',
                        'applyUsedProgram',
                        'usedPhoneMemberShip',
                        'saler',
                        'note',
                        'conversion2G',
                        'rateAgreement',
                        'contractMonth',
                        'discostChange',
                        'disCost12',
                        'disCost24',
                        'dataPlanNewDateChange',
                        'insurranceCancelDate',
                        'subscriptionFee',
                        'planChangeDate',
                        'combineCondition', // điều kiện kết hợp
                        'combineDevice', // kết hợp thiết bị có dây
                        'combineOption', // kết hợp option thiết bị có dây
                        'combinePlans', // kết hợp gói phí
                        'combinePlansSring', // kết hợp gói phí
                        'customerId', // kết hợp gói phí,
                        'mutualService', //dịch vụ tương trợ,
                        // 'customerIdEdit'
                        // 'instMonthlyDefer_1',

                        'usedPhoneCompensation',
                        'soldierDiscount',
                        'soldierLguplus',
                        'soldierSkt',
                        'soldierKt',
                        'disclosureSubsidyType',
                        'conversion_price_input',
                        'conversion_price_input_add',
                        'requestForMonth'
                    ]
                    var checkWirelessAdviceStep3OldObj = _.pick(prevProps.WirelessAdvice.WirelessAdviceStep3, ...keysToCheckDiff)
                    var checkWirelessAdviceStep3NewObj = _.pick(WirelessAdviceStep3, ...keysToCheckDiff)
                    var checkEqual = _.isEqual(checkWirelessAdviceStep3OldObj, checkWirelessAdviceStep3NewObj)
                    if (!checkEqual) {
                        let wirelessAdviceStep3 = caclWirelessStep3(WirelessAdviceStep1.installmentPaymentMethod, WirelessAdviceStep3, WirelessAdviceStep1.recipe_days_remaining)
                        this.props.change('WirelessAdviceStep3', wirelessAdviceStep3);
                        //console.log("바뀐 wirelessAdviceStep3의 값", wirelessAdviceStep3)

                        // save data
                        this.saveDataDebounce()
                    }
                }
            }, 50)
        }
    }

    saveData = () => {
        const {
            WirelessAdvice: {
                id,
                WirelessAdviceStep1,
                WirelessAdviceStep2,
                WirelessAdviceStep3,
                CreatePotentialCustomer,
                EditPotentialCustomer,
                CreatePotentialCustomerDefault,
            },
        } = this.props
        let data = {
            ...WirelessAdviceStep3,
            model_id: WirelessAdviceStep3.phone?.modelID || WirelessAdviceStep3.phone?.id || null,
            promotion: WirelessAdviceStep3.promotion?.id || null,
            promo_cost: WirelessAdviceStep3.promotion?.planID === 'input_live' ? WirelessAdviceStep3.promotion?.promo_cost : null,
            welfareDiscount: WirelessAdviceStep3.welfareDiscount?.id || null,
            sim: WirelessAdviceStep3.sim?.id || null,
            packageFee: WirelessAdviceStep3.packageFee?.id || null,
            insurrance: WirelessAdviceStep3.insurrance?.ins_id || null,
            saler: WirelessAdviceStep3.saler?.id || null,
            telcomID: WirelessAdviceStep1?.telcomID || null,
            customerId: CreatePotentialCustomerDefault?.id || null,
            customerIdEdit: EditPotentialCustomer?.id || null,
            customerNote: CreatePotentialCustomerDefault?.note || (EditPotentialCustomer?.note || null),
            color: WirelessAdviceStep2?.color?.id || null,
            applyDate: WirelessAdviceStep3?.displayApplyDate || null,
            planChangeId: WirelessAdviceStep3?.dataPlanNewDateChange?.id || null,
            conversion2G: WirelessAdviceStep3?.conversion2G?.id || null,
            subscriptionFee: WirelessAdviceStep3?.subscriptionFee?.id || null,
            type: window.currentDevice,
            combineCondition: WirelessAdviceStep3?.combineCondition?.id || null,
            combineDevice: WirelessAdviceStep3?.combineDevice?.wd_id || null,
            combineOption: WirelessAdviceStep3?.combineOption?.wdo_id || null,
            combinePlans: WirelessAdviceStep3?.combinePlans?.map((item) => ({
                idx: item.idx,
                plan_id: item.plan?.id,
                discount_wireless_price: item.discount_wireless_price,
                is_commitment: item.is_commitment,
                disabled: item.disabled,
                discount_wireless_name: (item.discount_condition_name || []).map(e => e.name).join('/'),
                plan_price: item.plan?.cost_display,
            })),
            optionDiscount: WirelessAdviceStep3?.optionDiscount || '',
            usedPhoneCompensation: WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || null,
            usedPhoneMemberShip: WirelessAdviceStep3?.usedPhoneMemberShip?.tcm_id || null,
            usedPhoneOptionFee: WirelessAdviceStep3?.usedPhoneOptionFee || null,
            usedPhoneInstallmentMonth: WirelessAdviceStep3?.usedPhoneInstallmentMonth || null,
            //request_ForMonth: WirelessAdviceStep3?.request_ForMonth || null //
        }
        if (id) {
            return api.advice.updateWirelessConsultant(id, data)
        }
        return api.advice.createWirelessConsultant(data)
            .then((response) => {
                this.props.change('id', response.data.data.id);
            })
    }

    changeUrl = step => {
        this.props.history.push('/wireless-advice?step=' + step)
        // window.history.pushState('', '', '/wireless-advice?step=' + step)
    }

    nextPage = () => {
        this.props.change('step', this.props.WirelessAdvice.step + 1)
        this.changeUrl(this.props.WirelessAdvice.step + 1)
    }

    previousPage = () => {
        this.props.change('step', this.props.WirelessAdvice.step - 1)
        this.changeUrl(this.props.WirelessAdvice.step - 1)
    }

    setPage = (step) => {
        let createPotentialCustomer = {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
        }

        if (this.props.WirelessAdvice.WirelessAdviceStep1.status == true) {
            if (step === 3 && Object.keys(this.props.WirelessAdvice.WirelessAdviceStep3.phone).length > 0) {
                if (this.step2Ref?.getWrappedInstance()?.wrappedInstance) {
                    this.step2Ref.getWrappedInstance().wrappedInstance.onSubmitToStep3()
                } else {
                    this.props.change('step', step)
                }
            } else {
                this.props.change('step', step)
            }
            this.changeUrl(step)
        } else {
            alert('통신사를 먼저 선택해 주세요.')
        }
        // if(
        //   this.props.WirelessAdvice.WirelessAdviceStep1.status == true
        //   && this.props.WirelessAdvice.WirelessAdviceStep2.status == true
        // ) {
        //   this.props.change('step', step)
        //   this.changeUrl(step)
        //   if(step == 1) {
        //     // this.props.change('id', null)
        //     this.props.change('CreatePotentialCustomer', createPotentialCustomer)
        //   }
        //   return
        // }

        // if(
        //   this.props.WirelessAdvice.WirelessAdviceStep1.status  == true
        //   && (step == 1 || step == 2)
        // ) {
        //   this.props.change('step', step)
        //   this.changeUrl(step)
        //   if(step == 1) {
        //     this.props.change('id', null)
        //     this.props.change('CreatePotentialCustomer', createPotentialCustomer)
        //   }
        //   return
        // }
    }

    onSelectedTelcom = (item) => {
        this.props.change('WirelessAdviceStep1.telcomID', item.id);
        this.props.change('WirelessAdviceStep1.telcomName', item.name);
        this.props.change('WirelessAdviceStep1.telcomCode', item.code);
        this.props.change('WirelessAdviceStep1.status', true);
        this.props.change('WirelessAdviceStep1.successString', item.image_url.original);
        this.props.change('WirelessAdviceStep1.installmentPaymentMethod', item.installment_payment_method);
        this.props.change('WirelessAdviceStep1.recipe_days_remaining', item.recipe_days_remaining);
        this.props.change('step', 2)
        this.changeUrl(2)
    }


    /**
     * show modal plan
     */
    handleShowModalCombineDiscount = () => {
        this.setState({
            isShowModal: true
        })
    }

    /**
     *
     */
    toggleModal = () => {
        this.setState({
            isShowModal: false
        })
    }

    onApplyCombineDiscount = (data) => {
        //console.log("결합할인 회선 확인 data : ", data)
        const { WirelessAdvice: { WirelessAdviceStep3 } } = this.props;
        //console.log("WirelessAdvice의 onApplyCombineDiscount", WirelessAdviceStep3)
        let wirelessAdviceStep3 = {
            ...WirelessAdviceStep3,
            combineCondition: data.discount_condition,
            combineDevice: data.wire_product[0]?.device || null,
            combineOption: data.wire_product[0]?.option || null,
            combinePlans: data.discount_condition ? data.plan_modal.plans : [],
            combinePlansSring: data.discount_condition ? JSON.stringify(data.plan_modal.plans) : '',
            optionDiscount: JSON.stringify(data.option_discount)
        }
        if(data?.discount_condition?.id && [4, 5, 16].includes(+data.discount_condition.id)){
            wirelessAdviceStep3.isRateAgreement = true;
            wirelessAdviceStep3 = {
                ...WirelessAdviceStep3,
                ...wirelessAdviceStep3,
                rateAgreement: 0
            }
        }else{
            let rateAgreement;
            let rateAgreementValue_12 = 0;
            let rateAgreementValue_24 = 0;
            if (this.props.WirelessAdvice.WirelessAdviceStep3?.planData) {
                rateAgreementValue_12 = this.props.WirelessAdvice.WirelessAdviceStep3?.planData?.discost_12 || 0;
                rateAgreementValue_24 = this.props.WirelessAdvice.WirelessAdviceStep3?.planData?.discost_24 || 0;
            } else {
                rateAgreementValue_12 = this.props.WirelessAdvice.WirelessAdviceStep3?.packageFee?.discost_12 || 0;
                rateAgreementValue_24 = this.props.WirelessAdvice.WirelessAdviceStep3?.packageFee?.discost_24 || 0;
            }
            if (this.props.WirelessAdvice.WirelessAdviceStep3?.discostChange == 12){
                rateAgreement = +rateAgreementValue_12 + (+rateAgreementValue_12*0.1);
            }else{
                rateAgreement = +rateAgreementValue_24 + (+rateAgreementValue_24*0.1);
            }

            wirelessAdviceStep3.isRateAgreement = false;
            wirelessAdviceStep3 = {
                ...WirelessAdviceStep3,
                ...wirelessAdviceStep3,
                rateAgreement: rateAgreement
            }
        }
        wirelessAdviceStep3.combinePlans = wirelessAdviceStep3.combinePlans.map((item, index) => {
          let discount_wireless_price = item.discount_wireless_price
          let discount_tv = 0
          if (data?.option_discount?.tv_plus?.line?.idx == index + 1) {
            if (data?.option_discount?.tv_plus?.type?.id == 1) {
              discount_wireless_price -= 1100
              discount_tv = 1100
            }
            if (data?.option_discount?.tv_plus?.type?.id == 2) {
              discount_wireless_price -= 2200
              discount_tv = 2200
            }
          }
          return {
            ...item,
            discount_wireless_price,
            discount_tv,
          }
        })

        /* task 7017:
         Đối với giảm giá kết hợp thì chỉ mobile mới có thể kết hợp thôi, nên cần chỉnh sửa lại để các loại thiết bị khoanh đỏ trong image2 không thể chọn giảm giá kết hợp.
         => Yêu cầu khi chọn thiết bị được match 5G tablet, tablet ,wearable, wifi, pet, iot thì chỉnh sửa để không kích hoạt giảm giá kết hợp.
         Text: "선택할 수 있는 결합상품이 없습니다." (Không có sp kết hợp có thể chọn)*/
        let check_is_mobile = ![3, 7, 8, 10, 11, 12].includes(+WirelessAdviceStep3.phone?.matchID || 0)
        if (!check_is_mobile) {
            wirelessAdviceStep3.combineCondition = null;
            wirelessAdviceStep3.combineDevice = null;
            wirelessAdviceStep3.combineOption = null;
            wirelessAdviceStep3.combinePlans = [];
            wirelessAdviceStep3.combinePlansSring = '';
        }

        this.props.change('WirelessAdviceStep3', wirelessAdviceStep3);
        this.setState({
            isShowModal: false
        })
    }

    showLockWireless = () => {
        this.setState({
            isShowLockWireless: !this.state.isShowLockWireless,
        });
    }

    render() {
        if (!this.state.finishInit) {
            return null
        }
        const {WirelessAdvice} = this.props
        if (!WirelessAdvice) {
            return null;
        }
        return (
            <div className="main-wrapper">
                <section className="wireless-advice">
                    <div className="container">
                        <WirelessAdviceStepHeader
                            WirelessAdvice={WirelessAdvice}
                            setPage={this.setPage}
                        />
                        {WirelessAdvice.step == 1 && <WirelessStep1
                            history={this.props.history}
                            onSubmit={this.nextPage}
                            telcomProviders={this.props.telcomProviders}
                            onSelectedTelcom={this.onSelectedTelcom}
                        />}
                        {WirelessAdvice.step == 2 && <WirelessStep2
                            // ref={this.step2Ref}
                            ref={ref => this.step2Ref = ref}
                            history={this.props.history}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                            manufacturers={this.props.manufacturers}
                        />}
                        {WirelessAdvice.step == 3 && <WirelessStep3
                            history={this.props.history}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                            manufacturers={this.props.manufacturers}
                            setPage={this.setPage}
                            handleShowModalCombineDiscount={this.handleShowModalCombineDiscount}
                        />}
                    </div>
                </section>
                <CombineDiscountModal
                    toggleModal={this.toggleModal}
                    isShowModal={this.state.isShowModal}
                    adviceType={ADVICE_TYPE.WIRELESS}
                    plans
                    onApplyCombineDiscount={this.onApplyCombineDiscount}
                />
                {/*<LockWirelessPopup
                    isOpen = {this.state.isShowLockWireless}
                    toggle = {this.showLockWireless}
                    {...this.props}
                />*/}
            </div>
        );
    }
}

WirelessAdvice.propTypes = {
    WirelessAdvice: PropTypes.object,
    history: PropTypes.object,
    telcomProviders: PropTypes.object,
    manufacturers: PropTypes.any,
    change: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getAdviceWireless: PropTypes.func,
    CreatePotentialCustomer: PropTypes.any,
    EditPotentialCustomer: PropTypes.any,
    CreatePotentialCustomerDefault: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        telcomProviders: state.dataStore.telcomProviders,
        manufacturers: state.dataStore.manufacturers,
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
        CreatePotentialCustomer: getFormValues('CreatePotentialCustomer')(state),
        CreatePotentialCustomerErrors: getFormSyncErrors('CreatePotentialCustomer')(state),
        EditPotentialCustomer: getFormValues('EditPotentialCustomer')(state),
        EditPotentialCustomerErrors: getFormSyncErrors('EditPotentialCustomer')(state),
        CreatePotentialCustomerDefault: getFormValues('CreatePotentialCustomerDefault')(state),
        CreatePotentialCustomerDefaultErrors: getFormSyncErrors('CreatePotentialCustomerDefault')(state),

    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        getAdviceWireless: () => dispatch(getAdviceWireless())
    };
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    key: FORM_NAME,
    initialValues: {
        step: 1,
        id: null,
        WirelessAdviceStep1: {
            telcomID: '',
            telcomName: '',
            telcomCode: '',
            status: false,
            successString: '',
            installmentPaymentMethod: INSTALLMENT_PAYMENT_METHOD.principalAndInteres
        },
        WirelessAdviceStep2: {
            mfID: '0', //bằng tất cả default
            keyword: '',
            is_new: false,
            is_consulted: false,
            is_bookmarked: false,
            sortBy: '',
            sortDirection: 'asc',
            sortTableFieldBy: '',
            sortTableFieldDirection: 'asc',
            status: false,
            color: '',
        },
        WirelessAdviceStep3: CONSULTANT_WIRELESS,
        WirelessAdviceStep3Default: {
            startingPrice: null, // Giá khởi điểm (출고가)
            welfareDiscount: null, // giảm giá phúc lợi (복지 할인)
            promotion: {}, // promotion (프로모션)
            pointDiscount: null, // giảm giá point (포인트 할인)
        },
        CreatePotentialCustomer: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate:false
        },
        EditPotentialCustomer: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
        },
        CreatePotentialCustomerDefault: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate:false
        },
    },
    enableReinitialize: true,
    destroyOnUnmount: false,
}, mapStateToProps);

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(WirelessAdvice);
