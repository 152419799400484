import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as routerName from '../../utils/routerName';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SupportMoneyModalOne from './SupportMoneyModalOne';
import PlanModal from './PlanModal';
class SupportMoney extends Component {
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            modalPlan: false,
            money: 69000,
            moneyDefault: 69000,
            installmentMoney: 470000
        }
    }

    showModal = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    showModalPlan = () => {
        this.setState({
            modalPlan: !this.state.modalPlan
        })
    }

    toggle = () => {
        this.showModal()
    }
    togglePlan = () => {
        this.showModalPlan()
    }
    toggleEvent = () => {
        this.showModal()
    }
    toggleEventPlan = () => {
        this.showModalPlan()
    }
    getValue = (data) => {
        this.setState({money:data})
    }
    render() {
        // let moneyShow = 
        return (
            <Fragment>
                <div className="left border-0">
                    <div className="form-group row mt-5">
                        <label htmlFor="staticEmail"
                            className="col-sm-2 col-form-label label-text">추가지원금</label>
                        <div className="col-sm-4 pl-2">
                            <Button className="btn btn-input-form mb-0"  type="button" onClick={this.toggle}
                            data-target="#modal-06">
                            {this.state.money}
                            </Button>
                            <Button className="btn btn-input-form mb-0"  type="button" onClick={this.togglePlan}>
                            </Button>
                        </div>
                    </div>
                </div>
                <SupportMoneyModalOne 
                    showModal={this.state.modal} 
                    toggleEvent={this.toggleEvent} 
                    money={this.state.money}
                    moneyDefault={this.state.moneyDefault}
                    getValue={this.getValue}
                    installmentMoney={this.state.installmentMoney}
                />

                <PlanModal
                    showModal={this.state.modalPlan} 
                    toggleEventPlan={this.toggleEventPlan} 
                />
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(SupportMoney);

