import React from 'react';
import {Modal} from 'reactstrap';

class ModalPrintCompare extends React.Component{

  render(){
    return (
      <Modal
        className={'modal-dialog modal-dialog-centered modal-lg modal-print'}
        // backdrop={"static"}
        toggle={this.props.toggle}
        isOpen={this.props.isOpen}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close close-modal"
              // data-dismiss="modal"
              // aria-label="Close"
              onClick={this.props.toggle}
            >
               <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body mt-4 mb-3">
            <h5 className="title">출력</h5>
            <div className="button-print">
              <button className="btn btn-print">
                <img src="/images/print-icon.svg" alt="" />
                <span>서식지 출력하기123 </span>
              </button>
              <button
                className="btn btn-print"
                id={'btn-print-wired-product'}
                data-print-content={process.env.REACT_APP_API_URL+'/wired-product-compare-tab?tab='+this.props.type}
              >
                <img
                  src="/images/print-icon.svg"
                  alt=""
                  id={'btn-print-wired-product-img'}
                />
                <span>견적서 출력하기</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalPrintCompare;
