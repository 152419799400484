import MessageJSON from "../../../utils/message.json";
import * as constant from '../../../utils/constant';
function ValidateEmail(mail) 
{
    if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

const validate = values => {
    const errors = {};
    const {
        business_name,
        registration_number,
        business_email,
        business_type,
        member_type,
        user_id,
        user_pw,
    } = values;
    if (!business_name) {
        errors.business_name = MessageJSON.signup.validate.business_name_required;
    } 
    if (business_type !== constant.BUSINESS_ORTHER && member_type == constant.MEMBER_TYPE.NEW_MEMBER) {
        
        errors.registration_number = [];
        if (registration_number && !registration_number[0]) {
            if (registration_number) {
                errors.registration_number[0] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[0].length < 3) {
            if (registration_number) {
                errors.registration_number[0] =  ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number && registration_number[0].length > 3) {
            if (registration_number) {
                errors.registration_number[0] = ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
        if (registration_number && !registration_number[1]) {
            if (registration_number) {
                errors.registration_number[1] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[1].length < 2) {
            if (registration_number) {
                errors.registration_number[1] =  ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number && registration_number[1].length > 2) {
            if (registration_number) {
                errors.registration_number[1] = ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
        if (registration_number && !registration_number[2]) {
            if (registration_number) {
                errors.registration_number[2] = ' ';
                errors.registration_number_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number && registration_number[2].length < 5) {
            if (registration_number) {
                errors.registration_number[2] =  ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number && registration_number[2].length > 5) {
            if (registration_number) {
                errors.registration_number[2] = ' ';
                errors.registration_number_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }

        if (!business_email) {
            errors.business_email = MessageJSON.signup.validate.business_email_required;
        } else if (business_email.trim() && business_email.length > 32) {
            errors.business_email = MessageJSON.signup.validate.business_email_max;
        } else if (!ValidateEmail(business_email)) {
            errors.business_email = MessageJSON.signup.validate.business_email_email;
        }
    }

    if (member_type == constant.MEMBER_TYPE.OLD_MEMBER) {
        if (!user_id) {
            errors.user_id = MessageJSON.signup.validate.user_id.required;
        }
        if (!user_pw) {
            errors.user_pw = MessageJSON.signup.validate.user_pw.required;
        }
    }

    return errors;
};

export default validate;
