import React from 'react'

const Page404 = () => {
    return (
        <div className="page">
            <div className="page-content">
                <div className="container text-center">
                    {/* <div className="display-1 text-muted mb-5"><i className="si si-exclamation"></i> 403</div> */}
                    <h1 className="h2 mb-3">죄송합니다!</h1>
                    <p className="h4 text-muted font-weight-normal mb-7">해당 사이트에 접속 권한이 없습니다!</p>
                    <a className="btn btn-primary" href="/">
                        <i className="fa fa-arrow-left"></i> 이전
                    </a>
                </div>
            </div>
        </div>
    )
};
export default Page404;
