

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { openPopupMenu, allowAccessMenu, updatePasswordMenu } from '../../store/actions/auth';
import * as routerName from './../../utils/routerName';
import api from "./../../utils/api";
import { Modal } from 'reactstrap';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageJSON from "./../../utils/message.json";

class RegisterAccessPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isOpen: false,
            password:'',
            password_confirmation: '',
            errors : {
                password: MessageJSON.access_menu.validate.password.required_reg,
                password_confirmation: MessageJSON.access_menu.validate.password_confirmation.required_reg,
            }, 
            focus: {
                password: false,
                password_confirmation: false,
            },
            dirty: {
                password: false,
                password_confirmation: false,
            }
        }
    }

    componentDidMount() {
    }

    handleInputFocus = (event) => {
        const value = event.target.value;
        const inputName = event.target.name;

        this.setState({
            ...this.state, 
            focus: {
                ...this.state.focus,
                [inputName]: true,
            }
        })
    }

    handelCheck = (e) => {
    }

    handlePasswordChange = (event) => {
        const value = event.target.value;

        if (6 <= value.length) {
            this.setState({
                ...this.state,
                password: value,
                errors: {
                    ...this.state.errors,
                    password: '',
                    // password_confirmation: ''
                }
            }, () => {
            })

            // reset error form 
            if (value == this.state.password_confirmation && this.state.dirty.password_confirmation) {
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {}
                }, () => {
                })
            } else {
                // set error to re-password
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {
                        password: '',
                        password_confirmation:  MessageJSON.access_menu.validate.password_confirmation.same
                    }
                }, () => {
                })
            }
     
        } else {
            var msgError_Pw = '';
            if (value.length < 1) {
                msgError_Pw = MessageJSON.access_menu.validate.password.required_reg;

            } else if (value.length < 6) {
                msgError_Pw = MessageJSON.access_menu.validate.password.min;
            }
            this.setState({
                ...this.state,
                password: value,
                errors: {
                    ...this.state.errors,
                    password: msgError_Pw,
                }
            }, () => {
            })

            if (value !== this.state.password_confirmation && this.state.dirty.password_confirmation) {
                var msgError = MessageJSON.access_menu.validate.password_confirmation.same;
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {
                        password: msgError_Pw,
                        password_confirmation: msgError,
                    }
                }, () => {
                })
            }  
        }
    }

    handlePasswordCfChange = (event) => {
        const value = event.target.value;

        let msgError = '';
        if (value.length < 1 || value.length < 6 || value !== this.state.password ) {

            if (value.length < 1) {
               msgError = MessageJSON.access_menu.validate.password.required_reg;
            } else if (value.length < 6) {
               msgError = MessageJSON.access_menu.validate.password.min;
            } else {
               msgError = MessageJSON.access_menu.validate.password_confirmation.same;
            }

            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    ...this.state.errors,
                    password_confirmation: msgError
                }
            }, () => {
            })
        } else {
            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    password: '',
                    password_confirmation: ''
                }
            }, () => {
            })
        }
    }

    handleSubmit = () => {
        this.props.showLoading()
        let data = _.pick(this.state, ['password']);
        api.auth.registerPasswordMenu(data)
            .then(response => {
                let { user } = this.props.auth;
                user.password_menu = response.data.password_menu;
                this.props.updatePasswordMenu(user);
                this.props.allowAccessMenu(true);
                this.props.openPopupMenu(false);
                this.props.hideLoading();
            
                this.onClose();
            })
            .catch(err => {
                this.props.hideLoading();
            });
    }

    onClose = () => {      
        //reset error, focus 
        this.setState({
            ...this.state,
            focus: {
                password: false,
                password_confirmation: false,
            }
        })
        this.props.toggle()
    }

    onCloseWithGoto = () => {   
        // this.props.history.push(`/${routerName.HOME}`);
        window.location.href = `/${routerName.HOME}`;
    }

    componentDidUpdate(prevProps, prevState) {
        //add whatever comparison logic you need here
        if(this.state.password !== prevState.password && !this.state.dirty.password) {
            this.setState({ 
                ...this.state,
                dirty: {
                    ...this.state.dirty,
                    password: true,
                }
            }, () => {
            });
        }

        if(this.state.password_confirmation !== prevState.password_confirmation && !this.state.dirty.password_confirmation) {
            this.setState({ 
                ...this.state,
                dirty: {
                    ...this.state.dirty,
                    password_confirmation: true,
                }
            }, () => {
            });
        }
    }

    onOpened = () => {
        this.setState({
            dirty: {
                password: false,
                password_confirmation: false,
            }
        }) 
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    className="modal-dialog modal-dialog-centered"
                    contentClassName="pl-4 pr-4"
                    onOpened = {this.onOpened}
                       // backdrop={"static"}
                >
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-center w-100">비밀번호 등록</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={() => this.onCloseWithGoto()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action>
                            <div className="form-group">
                                <label className="col-form-label label-text">비밀번호</label>
                                <input 
                                    className="form-control h-44" placeholder="비밀번호"
                                    name="password"
                                    type="password"
                                    onChange={(e) => this.handlePasswordChange(e)}
                                    onFocus={(e) => this.handleInputFocus(e)} 
                                    onDirty={this.handelCheck}
                                />
                                {
                                   this.state.dirty.password && this.state.errors.password && (<p className="small text-danger mt-1">{this.state.errors.password}</p>)
                                }

                            </div>
                            <div className="form-group">
                                <label className="col-form-label label-text">비밀번호 확인</label>
                                <input 
                                    className="form-control h-44" placeholder="비밀번호 확인"
                                    name="password_confirmation"
                                    type="password"
                                    onChange={(e) => this.handlePasswordCfChange(e)}
                                    onFocus={(e) => this.handleInputFocus(e)} 
                                />
                                {
                                    this.state.dirty.password_confirmation && this.state.errors.password_confirmation && (<p className="small text-danger mt-1">{this.state.errors.password_confirmation}</p>)
                                }
                            </div>
                            <div className="w-100 mt-5 text-center">
                                {/* <button className="btn btn_close mx-2" data-dismiss="modal" aria-label="Close" onClick={this.onClose()}>취소2</button> */}
                                <button type="button" className="btn btn_close mx-2" data-dismiss="modal" aria-label="Close" onClick={() => this.onCloseWithGoto()}>
                                    취소
                                </button>
                                <button type="button" className="btn btn_save mx-2" data-dismiss="modal" aria-label="Close" onClick={this.handleSubmit}
                                        disabled={ (this.state.errors.password || this.state.errors.password_confirmation) ? true : false}>
                                    확인
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        allowAccessMenu: (data) => dispatch(allowAccessMenu(data)),
        openPopupMenu: (data)  => dispatch(openPopupMenu(data)),
        updatePasswordMenu: (data)  => dispatch(updatePasswordMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(RegisterAccessPopup);
