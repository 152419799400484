import React from 'react';
import {Modal} from 'reactstrap';
import {priceFormat} from "../../../utils/helper";
import ModalImage from "../../../containers/Compare/ModalImage";
class ModalProductInformation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      images : [],
      isOpenPopupImage : false
    }
  }


  onClickLoadImage = (images = null) => {

    if(images){
      let imagesParse = JSON.parse(images);
      let imageArray = [];
      if(imagesParse.length > 0 ){
        imagesParse.map(item =>  {
          imageArray.push({ original : process.env.REACT_APP_API_PUBLIC_FOLDER + item})
        })
      }

      this.setState({
        images : imageArray,
        isOpenPopupImage : !this.state.isOpenPopupImage
      })
    }else{
      this.setState({
        isOpenPopupImage : !this.state.isOpenPopupImage,
        images : []
      })
    }
  }

  onClickItem = (index) => {
    this.props.selectDevice(index);
    this.props.toggle();
  }

  br2nl (str, replaceMode) {

    var replaceStr = (replaceMode) ? "\n" : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
  }

  nl2br (str, replaceMode, isXhtml) {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  }

  render() {
    let {devices,wdClass,network} = this.props;

    var nameNetWork = '';
    if(wdClass){
      switch (wdClass) {
        case 90 :
          nameNetWork = `[${network.name}] 인터넷 상품 상세정보`;
          break;
        case 91 :
          nameNetWork = `[${network.name}] TV 상품 상세정보`;
          break;
        case 92 :
          nameNetWork = `[${network.name}] 전화 상품 상세정보`;
          break;
        case 101 :
          nameNetWork = `[${network.name}] IoT상품 상세`;
      }
    }

    return(
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={'modal-dialog modal-custom-16'}
        >
          <div className="modal-header">
            <h5 className="modal-title">
              {nameNetWork}
            </h5>
            <button
              type="button"
              className="close close-modal"
              onClick={this.props.toggle}
            >
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body pl-0 pr-0">
            <ul className="list-detail-internet">
              {devices.length > 0 && devices.map((item,index) => {
                return (
                  <li key={index}>
                    <div className="left">
                      <div className="top-detail">
                        <h6>{item.wd_name}</h6>
                        <span>월 {priceFormat(item.wd_commitment_fee)}원</span>
                        {item.wd_image && <a
                          className="btn btn-wireless h-25"
                          onClick={()=>this.onClickLoadImage(item.wd_image)}
                        >
                          <img src="/images/icon-12.svg" alt="" />
                          상세보기
                        </a>}

                      </div>
                      <p dangerouslySetInnerHTML={{__html: item.wd_description ? item.wd_description.replace(/\r?\n/g, '<br />') : ''}}/>
                      {/*<p>*/}
                      {/*  {item.wd_description ? item.wd_description.replace(/\r?\n/g, '<br />') : ''}*/}
                      {/*</p>*/}
                    </div>
                    <div className="right">
                      <button
                        className="btn btn-submit h-32"
                        onClick={()=>this.onClickItem(index)}
                      >
                        선택
                      </button>
                    </div>
                  </li>
                )
              })}

            </ul>
          </div>
        </Modal>


        <ModalImage
          isOpenPopupImage={this.state.isOpenPopupImage}
          openPopupImage={this.onClickLoadImage}
          imageDetail={this.state.images}
        />


      </React.Fragment>

    )
  }
}

export default ModalProductInformation;
