import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import { Modal } from 'reactstrap';
import { showLoading, hideLoading } from '../../../store/actions/ui';

class ModalWirelessAndWiredCombination extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }



  render() {

    const { isOpenModal, closeModal } = this.props

    return (
      <Modal
        isOpen={isOpenModal}
        toggle={closeModal}
        fade={true}
        // backdrop={"static"}
        className={'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'}
      >
        <div className="modal-content pl-5 pr-5">
          <div className="modal-header">
            <h5 className="modal-title flex-header">
              유무선 결합 설정
            </h5>
            <button type="button" className="close close-modal" onClick={closeModal}>
            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body wireless-connection pl-0 pr-0">
            <form action="">
              <div className="top">
                <div className="row align-items-center">
                  <label className="label-title col-3 mn-0">결합상품 선택 </label>
                  <input type="text" className="form-control h-44 col-4" value="온가족플랜" data-toggle="modal" data-target="#modal-19" />
                  <div className="col-3 d-flex align-items-center">
                    <a href="" className="btn btn-wireless text-white h-25" data-toggle="modal" data-target="#modal-21">
                      <img src="/images/icon-12.svg" alt="" />
                      상세보기
                    </a>
                  </div>
                </div>
              </div>
              <article>
                <div className="product-wireless">
                  <div className="row">
                    <div className="col-3">
                      <label className="label-title mb-0">결합상품 선택 </label>
                    </div>
                    <div className="col-4">
                      <label className="label-title weight-normal mb-0">광랜 + WiFi 외 2건</label>
                    </div>
                    <div className="col-md-5">
                      <div className="d-flex justify-content-end">
                        <p className="mb-0 text-wireless">유선할인</p>
                        <p className="mb-0 text-wireless blue-white text-bold ml-3"> -1,100<span className="weight-normal text-gray">원</span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>무선상품</th>
                      <th className="text-right text-wireless">무선할인</th>
                      <th className="text-center text-wireless">(선택약정 25%)</th>
                      <th>결합옵션</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="row w-200">
                          <div className="col-4">
                            <p className="mb-1 text-name text-wrap text-bold">회선 1</p>
                            <p className="mb-0 text-name text-wrap text-gray-white">(대표)</p>
                          </div>
                          <div className="col-8 pl-0">
                            <button className="btn btn-input-form mb-0 h-32" type="button" data-toggle="modal" data-target="#exampleModal122">
                              슬림
                            </button>
                            <p className="text-small-blue mb-0">TV플러스(신규)</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex pb-3 justify-content-end">
                          <p className="mb-0 text-wireless blue-white text-bold"> -1,100<span className="weight-normal text-gray">원</span></p>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="abcd01" />
                          <label className="custom-control-label" htmlFor="abcd01"></label>
                        </div>
                      </td>
                      <td rowSpan="5">
                        <div className="form-group">
                          <label className="text-name text-wrap text-bold">지정할인<span className="weight-normal">(회선
                              선택)</span> </label>
                          <button className="btn btn-input-form h-32 mb-0" type="button" data-toggle="modal" data-target="#modal-57">
                            미적용
                          </button>
                        </div>
                        <div className="form-group mt-5">
                          <label className="text-name text-wrap text-bold">TV 플러스<span className="weight-normal">(가입자구분/회선 선택)</span> </label>
                          <button className="btn btn-input-form mb-0" type="button">
                            신규 가입자 / 회선 1
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="row w-200">
                          <div className="col-4">
                            <p className="mb-1 text-name text-wrap text-bold">회선 1</p>
                            <p className="mb-0 text-name text-wrap text-gray-white">(대표)</p>
                          </div>
                          <div className="col-8 pl-0">
                            <button className="btn btn-input-form h-32 mb-0" type="button" data-toggle="modal" data-target="#exampleModal122">
                              미적용
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <p className="mb-0 text-wireless blue-white text-bold"> 0<span className="weight-normal text-gray">원</span></p>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="abcd012" />
                          <label className="custom-control-label" htmlFor="abcd012"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="row w-200">
                          <div className="col-4">
                            <p className="mb-1 text-name text-wrap text-bold">회선 1</p>
                            <p className="mb-0 text-name text-wrap text-gray-white">(대표)</p>
                          </div>
                          <div className="col-8 pl-0">
                            <button className="btn btn-input-form h-32 mb-0" type="button" data-toggle="modal" data-target="#exampleModal122">
                              미적용
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <p className="mb-0 text-wireless blue-white text-bold"> 0<span className="weight-normal text-gray">원</span></p>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="abcd013" />
                          <label className="custom-control-label" htmlFor="abcd013"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="row w-200">
                          <div className="col-4">
                            <p className="mb-1 text-name text-wrap text-bold">회선 1</p>
                            <p className="mb-0 text-name text-wrap text-gray-white">(대표)</p>
                          </div>
                          <div className="col-8 pl-0">
                            <button className="btn btn-input-form h-32 mb-0" type="button" data-toggle="modal" data-target="#exampleModal122">
                              미적용
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <p className="mb-0 text-wireless blue-white text-bold"> 0<span className="weight-normal text-gray">원</span></p>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="abcd014" />
                          <label className="custom-control-label" htmlFor="abcd014"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="row w-200">
                          <div className="col-4">
                            <p className="mb-1 text-name text-wrap text-bold">회선 1</p>
                            <p className="mb-0 text-name text-wrap text-gray-white">(대표)</p>
                          </div>
                          <div className="col-8 pl-0">
                            <button className="btn btn-input-form h-32 mb-0" type="button" data-toggle="modal" data-target="#exampleModal122">
                              미적용
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <p className="mb-0 text-wireless blue-white text-bold"> 0<span className="weight-normal text-gray">원</span></p>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="abcd015" />
                          <label className="custom-control-label" htmlFor="abcd015"></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="modal-footer justify-content-center border-top-0 mt-3">
                <button type="button" className="btn btn-gray pl-5 pr-5" data-dismiss="modal">취소</button>
                <button type="button" className="btn btn-submit pl-5 pr-5">적용</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalWirelessAndWiredCombination.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  closeModal: PropTypes.func,
  isOpenModal: PropTypes.bool,
  WiredAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(ModalWirelessAndWiredCombination);
