import MessageJSON from '../../../utils/message';

const validate = values => {
    const errors = {};
    const {
        package_type,
        package_id,
        payment_method,
        organization_type,
        company_card_id,
        month,
        year,
        security_number,
        card_stock_number,
        card_stock_name,
        card_number} = values;
        
    // if (!package_type) {
    //     errors.package_type = MessageJSON.signup.validate.package_type_required;
    // } 
    // if (!package_id) {
    //     errors.package_id = MessageJSON.signup.validate.package_id_required;
    // } 
    // if (!payment_method) {
    //     errors.payment_method = MessageJSON.signup.validate.payment_method_required;
    // } 
    // if (!organization_type) {
    //     errors.organization_type = MessageJSON.signup.validate.organization_type_required;
    // } 
    // if (!company_card_id) {
    //     errors.company_card_id = MessageJSON.signup.validate.company_card_id_required;
    // } 
    // if (!month) {
    //     errors.month = MessageJSON.signup.validate.month_required;
    // } 
    // if (!year) {
    //     errors.year = MessageJSON.signup.validate.year_required;
    // } 
    // if (!card_stock_number) {
    //     errors.card_stock_number = MessageJSON.signup.validate.card_stock_number_required;
    // } 
    // if (!card_stock_name) {
    //     errors.card_stock_name = MessageJSON.signup.validate.card_stock_name_required;
    // } 
    // if (!security_number) {
    //     errors.security_number = MessageJSON.signup.validate.security_number_required;
    // } 
    // if (!card_number) {
    //     errors.card_number = MessageJSON.signup.validate.card_number_required;
    // } 

    
    return errors;
};

export default validate;
