import React, { Fragment, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs, Virtual } from 'swiper';
import ModalImage from "./ModalImage";
// import 'swiper/swiper-bundle.css';
import './Compare.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Virtual]);

function SwiperSlideWirelessComponent({ devices, onClickSelectedColorActive, showTitle = false }) {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [isOpenPopupImage, openPopupImage] = useState(false);

  const [imageDetail, setImageDetail] = useState([]);

  const [indexColorActive, setIndexColorActive] = useState(devices.length ? 0 : null);



  const slides = [];
  for (let i = 0; i < devices.length; i += 1) {
    let file = devices[i].files_url;
    if (file) {
      slides.push(
          <SwiperSlide key={`slide-${i}`} virtualIndex={i}>
            <a >
              <img
                src={file[0]}
                alt=""
                onClick={() => {
                  openPopupImage(!isOpenPopupImage)
                  setImageDetail(file)
                }}
              />
            </a>
          </SwiperSlide>
      );
    }

  }

  const thumbs = [];
  for (let i = 0; i < devices.length; i += 1) {
    thumbs.push(
      <SwiperSlide
          key={`thumb-${i}`}
      >
        <span
          style={{ "backgroundColor": `${devices[i].code}` }}
        ></span>
      </SwiperSlide>
    );
  }

  const slides2 = [];
  for (let i = 0; i < 5; i += 1) {
    slides2.push(
      <SwiperSlide key={`slide-${i}`} tag="li" activeIndex={1}>
        <img
          src={`https://picsum.photos/id/${i}/163/100`}
          style={{ listStyle: 'none' }}
          alt={`Slide ${i}`}
        />
      </SwiperSlide>
    );
  }
  return (
    <React.Fragment>
      <Swiper
        id="main"
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: controlledSwiper }}
        className={'swiper-top gallery-top'}
        navigation
        slidesPerView={1}
        onSlideChange={(swiper) => {
          onClickSelectedColorActive(devices[+swiper.realIndex])
          setIndexColorActive(+swiper.realIndex)
        }}
        // virtual
      >
        {slides}
      </Swiper>
      {showTitle ? ( <h3 className="name-product ">{  devices[indexColorActive] && devices[indexColorActive].name }</h3>) : (<></>)}
      <Swiper
        id="thumbs"
        slidesPerView={devices.length}
        onSwiper={setThumbsSwiper}
        className={'swiper-thumbs gallery-thumbs transform-0'}
        // onReachEnd={() => console.log('Swiper end reached')}
        activeSlideKey={indexColorActive}
      >

        {thumbs}
      </Swiper>

      <ModalImage
        isOpenPopupImage={isOpenPopupImage}
        openPopupImage={() => openPopupImage(!isOpenPopupImage)}
        imageDetail={imageDetail}
      />

    </React.Fragment>
  );
}

export default SwiperSlideWirelessComponent;
