import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Header from './Header';
import Footer from './Footer';
import { getRegToken, isTokenSentToServer } from '../../firebase/firebase';
import { getGlobalDataAuth } from '../../store/actions/dataStore';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import {showLoading, hideLoading} from '../../store/actions/ui';
import * as constant from '../../utils/constant';
class Main extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if(!localStorage.getItem('device_token')){
            try {
                if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(async (permission) => {
                        if (permission === "granted") {
                            if (isTokenSentToServer()) {
    
                            } else {
                                getRegToken();
                            }
                        }
                    })
                    .catch(err => {
                    });
                }
            } catch (error) {
            }
            
        }


        if(this.props.isAuthenticated) {
            this.props.showLoading()
            this.props.getGlobalDataAuth().then(res=>{
                if (!this.props.isCreateReport) {
                    this.props.hideLoading()
                }
                this.props.hideLoading()

            }).catch(err => {
                this.props.hideLoading()
            })
        }
        
    }


    render() {
        return (
            <Fragment>
                <Header {...this.props} />
                {React.cloneElement(this.props.children, this.props)}
                {this.props.footer && this.props.isAuthenticated && this.props.auth?.accountErrStatus == constant.ACCOUNT.ACCOUNT_SUCCESS &&(
                    <Footer {...this.props} />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        auth:state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getGlobalDataAuth: (data) => dispatch(getGlobalDataAuth(data)),
        showLoading:() => dispatch(showLoading()),
        hideLoading:() => dispatch(hideLoading())
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(Main);

