import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customizeDatepicker.css';
import moment from 'moment';
import {PACKAGE_DEFAULT, LABEL_DEFAULT_DATE , ALIAS_FOR_DATEPICKER} from './PackageDateChange';

class ChangeDateExtraServiceModal extends Component {
    render() {
        return(
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                       className="modal-small modal-dialog-centered modal-custom-11"
                       contentClassName="pl-4 pr-4"
                       // backdrop={"static"}
                    toggle={this.props.toggle}
                >
                    <div>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {this.props.title ? this.props.title : '휴대폰보험 해지가능 날짜'}
                            </h5>
                            {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                                <img src="/images/icon-close.svg" alt="" />
                            </button> */}
                        </div>
                        <div className="modal-body discount-card pl-0 pr-0 pt-0">
                            <form>
                                <ul className="list-item mt-0">
                                    {this.props.children}
                                </ul>
                            </form>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}



export default ChangeDateExtraServiceModal;

