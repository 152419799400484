import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string'
import api from './../../utils/api'
import { withRouter } from "react-router";
import Tooltip from "./../../components/Tooltip";
import {
  getAdviceWireless
} from './../../store/actions/dataStore';
import {showLoading, hideLoading} from './../../store/actions/ui';
import ModalSelectTelcom from './../../components/Compare/ModalSelectTelcom'
import ModalSelectDevice from './../../components/Compare/ModalSelectDevice'
import DescriptionDetailDeviceModal from './../../components/Compare/DescriptionDetailDeviceModal'
import { priceFormat, loadingSwiperSlider, removeSwiperSlider, optionalAgreementCT } from '../../utils/helper';
import { OPTION_FEE, CONSULTANT_WIRELESS } from '../../utils/constant';
import * as routeName from '../../utils/routerName';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getFormValues, reduxForm} from "redux-form";
import { Swiper, SwiperSlide, Navigation ,Pagination } from 'swiper/react';
import validate from "../Advice/WirelessAdviceComponent/validate-step-2";
import SwiperSlideComponent from "./SwiperSlideComponent";
import {COMPARE_TYPE} from './../../utils/constant'

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

class CompareDevice extends Component {

  constructor(props) {
    super(props)
    this.state = {
      devices: [],
      params: {
        calc: true,
        calc_inst_month: 24,
        calc_subsidy_type: 79,
        distinct: true,
        manufactor: '',
        price_type: '',
        published: true,
        relationship: ['colors', 'device|deviceSpecialFeature', 'telcom'],
        sort: ['plan_sort|asc'],
        standard_type: '',
      },
      deviceSelectedIndex: 0,
      deviceIndex: [],
      isOpenModalSelectTelcom: false,
      isOpenModalSelectDevice: false,
      isOpenModalDetailDevice: false,
      isOpenModalSelectDeviace: false,
      devicesDetail: [],
      urlParams : {}
    }
  }

  componentDidMount() {
    this.props.showLoading()
    const { history } = this.props
    let searchObj = queryString.parse(history.location.search);
    
    if (searchObj.model && searchObj.type != COMPARE_TYPE.CONSULTANT) {
      let models         = searchObj.model;
      let plans          = searchObj.plan;
      let searchObjColor = searchObj.color;
      let request        = [];

      if (typeof models === 'string') {
        request.push(api.advice.getModel(models, {...this.state.params, plan: plans}))
      }
      else {
        models.map((id, index) => {
          request.push(api.advice.getModel(id, {...this.state.params, plan: plans[index]}))
        })
      }

      Promise.all(request)
        .then(response => {
          let devices = response.map(res => ({
            telcom: res.data.data.telcom,
            info: res.data.data,
          }));
          let models    = response.map(res => (res.data.data.id));
          let plans     = response.map(res => (res.data.data.plan.id));
          let color     = response.map(res => {
            if(searchObjColor){
              return searchObjColor;
            }
            else{
              return res.data.data.colors[0].colorID;
            }
          });

          let urlParams = {
            ...this.state.urlParams,
            type: 1,
            model: models,
            plan: plans,
            color:color
          }

          this.setState({
            devices : devices,
            urlParams : urlParams
          });
        })
    }

    this.props.getAdviceWireless()
    this.props.hideLoading()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isReset === false && this.props.isReset === true) {
      this.setState({
        devices: []
      })
    }
    if (this.state.devices.length > 0 && !this.props.hasData) {
      this.props.setHasData(true)
    }
    if (this.state.devices.length == 0 && this.props.hasData) {
      this.props.setHasData(false)
    }
  }

  clickChooseTelcom = (deviceSelectedIndex) => {
    this.setState({
      deviceSelectedIndex,
      isOpenModalSelectTelcom: true,
    })
  }

  closeModalSelectelcom = () => {
    this.setState({
      isOpenModalSelectTelcom: false,
    })
  }

  onSelectedTelcom = (telcom) => {
    let { deviceSelectedIndex, devices } = this.state
    let device = {
      telcom,
      info: {},
    }
    devices[deviceSelectedIndex] = device
    this.setState({
      devices
    })
    this.closeModalSelectelcom()
    this.updateUrl()
  }

  clickChooseDevice = (deviceSelectedIndex) => {
    const {devices} = this.state
    if (
      devices[deviceSelectedIndex]
      && devices[deviceSelectedIndex].telcom
      && Object.keys(devices[deviceSelectedIndex].telcom).length > 0
    ) {
      this.setState({
        deviceSelectedIndex,
        isOpenModalSelectDevice: true,
      })
    } else {
      alert('통신사를 선택해주세요')
    }
  }

  clickDevice = (info,deviceSelectedIndex) => {
      const {devices} = this.state
      if(info.length > 0) {
        if(info[0].info && Object.keys(info[0].info).length > 0) {
            let data={info:info}
            this.setState({
                deviceIndex:[...this.state.deviceIndex,deviceSelectedIndex]
            })
        }
      }else {
          if (
              info
              && Object.keys(info).length > 0
          ) {
              let data={info:info}
              this.setState({
                  deviceIndex:[...this.state.deviceIndex,deviceSelectedIndex]
              })
          }
      }

  }

  closeModalSelecDevice = () => {
    this.setState({
      isOpenModalSelectDevice: false,
    })
  }
    closeModalDetailDevice = () => {
        this.setState({
            isOpenModalDetailDevice: false,
        })
    }
    openModalDetailDevice = (index) => {
        let { devices,devicesDetail } = this.state

        if(devices.length > 0) {
            devices.map((value,key) => {
                if(value.info && value.info.v_device) {
                    if(key == index) {
                        this.setState({
                            isOpenModalDetailDevice: !this.state.isOpenModalDetailDevice,
                            devicesDetail:[value]
                        })
                    }

                }
            })
        }
    }

  onSelectedDevice = (info) => {
    let { deviceSelectedIndex, devices } = this.state
    this.closeModalSelecDevice()
    let params = this.state.params
    params.plan = info.planID
    api.advice.getModel(info.modelID, params)
      .then((res) => {
        let device = {
          telcom: devices[deviceSelectedIndex].telcom,
          info: res.data.data,
        }
        this.clickDevice(info,deviceSelectedIndex)
        // let device = {
        //   telcom: devices[deviceSelectedIndex].telcom,
        //   info,
        // }
        devices[deviceSelectedIndex] = device
        this.setState({
          devices
        }, () => {
          this.updateUrl()
        })
      })
  }

  updateUrl = () => {
    let models = this.state.devices.map(e => e.info?.id)
    let plans  = this.state.devices.map(e => e.info?.plan?.id);
    let color  = this.state.devices.map(e => {
      let index                = models.indexOf(e.info.id);
      let colorId              = '';
      let urlParamsColor       = this.state.urlParams.color;
      let urlParamsColorLength = 0;

      if(urlParamsColor){
        urlParamsColorLength = urlParamsColor.length;
      }
      
      if(e.info.colors){
        if(index == -1){
          colorId = e.info.colors[0].colorID;
        }
        else if(urlParamsColorLength > index){
            colorId = this.state.urlParams.color[index];
        }
        else{
          colorId = e.info.colors[0].colorID;
        }
      }
      
      return colorId;
    });

    let params = {
      ...this.state.urlParams,
      type: 1,
      model: models,
      plan: plans,
      color:color
    }

    this.setState({
      urlParams: params
    });

    params = queryString.stringify(params);

    // this.props.history.push({
    //   pathname: '/compare',
    //   search: '?' + params
    // })
    window.history.pushState('', '', '/compare?' + params)
    //this.props.getParams(params);
    //this.props.getParams(COMPARE_TYPE.DEVICE, params);

    let option           = new Array();
    let installmentMonth = new Array();
    let other            = new Array();
    let cashPayment      = new Array();
    let cashPaymentId    = new Array();
    let tempInt          = 0;
    let modelsLength     = models.length;

    for(tempInt = 0; tempInt < modelsLength; tempInt++){
      option[tempInt]           = OPTION_FEE.disclosureSubsidy;
      installmentMonth[tempInt] = 24;
      other[tempInt]            = "";
      cashPayment[tempInt]      = "";
      cashPaymentId[tempInt]    = 0;
    }
    
    /*
    this.props.getParams(
      COMPARE_TYPE.CONSULTANT,
      queryString.stringify({
        type             : COMPARE_TYPE.CONSULTANT,
        model            : models,
        option           : option,
        installmentMonth : installmentMonth,
        other            : other,
        cashPayment      : cashPayment,
        cashPaymentId    : cashPaymentId,
        packageFee       : plans,
        colors           : color
      })
    );
    */

    this.props.getParamTotal(params, queryString.stringify({
      type             : COMPARE_TYPE.CONSULTANT,
      model            : models,
      option           : option,
      installmentMonth : installmentMonth,
      other            : other,
      cashPayment      : cashPayment,
      cashPaymentId    : cashPaymentId,
      packageFee       : plans,
      colors           : color
    }));
  }

  onClickSelectedColorActive = (colorInfo) => {
    let params = this.state.urlParams;
    let models = params.model;
    let color  = params.color;
    let index  = models.indexOf(Number(colorInfo.modelID));

    color[index] = colorInfo.colorID;

    params.color = color;

    this.setState({
      urlParams: params
    });
    
    params = queryString.stringify(params);

    window.history.pushState('', '', '/compare?' + params);

    this.props.getParams(COMPARE_TYPE.DEVICE, params);
  }

  redirectWireLessAdvice = (index) => {
    let { devices, urlParams} = this.state
    let telcomeID = devices[index].telcom.id
    let model = devices[index].info
    let telcom = devices[index].telcom;

    if (!model || (model && Object.keys(model).length === 0)) {
      alert('단말기를 선택해주세요.')
      return;
    }

    // let commitmentRate ;
    // if (+model.plan.spec_cmm == 1) {
    //   commitmentRate = +model.plan.spec_cmm_value/100;
    // } else {
    //   commitmentRate = +model.commitment_rate/100;
    // }
    // let discostChange = 24;
    // let dataOption = optionalAgreementCT((+plan.cost_display+plan.cost_display*10/100)/1.1, (+plan.cost_calc+plan.cost_calc*10/100)/1.1, 1, plan, commitmentRate, discostChange, telcomeID);

    let packageFee = {
        // ...this.state.consultantActive,
        // ...this.state.consultantActive.plan,
        id: model?.plan?.id,
        classID: model?.plan?.classID,
        name: model?.plan?.name,
        cost_display: model?.plan?.cost_display,
        class: model?.plan?.class,
        discost_ad_cond: model?.plan?.discost_ad_cond,
        spec_cmm: model?.plan?.spec_cmm,
        spec_cmm_value: model?.plan?.spec_cmm_value,
        discost_12: model?.plan?.discost_12,
        discost_24: model?.plan?.discost_24,
        discount_full: model?.plan?.discount_full,
        plan_classification : {
          class: model?.plan?.class
        }
    }
    let commitmentRate;
    if (model?.plan?.spec_cmm == 1) {
      commitmentRate = +model?.plan?.spec_cmm_value/100;
    } else {
      commitmentRate = model?.telcom?.commitment_rate/100;
    }
    let result = optionalAgreementCT(
      +model?.plan?.cost_display,
      +model?.plan?.cost_calc,
      1,
      packageFee,
      commitmentRate,
      24,
      model?.telcomID
    );
    let optionalAgreement;
    if (result.optionalAgreement < 0) {
        optionalAgreement = 0
    } else {
        optionalAgreement = result.optionalAgreement;
    }
    let optionalAgreementDefault = optionalAgreement;
    let stateColor = "";

    if(model.colors){
      let colorIndex = 0;
      
      model.colors.map((info, i) => {
        if(info.colorID == urlParams.color[index]){
          colorIndex = i;
        }
      });

      stateColor = model.colors[colorIndex];
    }

    let state = {
      WirelessAdviceStep3: {
        ...CONSULTANT_WIRELESS,
        telcomeID,
        model: {
          ...model,
          model_name: model.name,
        },
        successString: telcom.image_url ? telcom.image_url.original : "",
        installmentMonth: 24,
        optionFee: OPTION_FEE.disclosureSubsidy,
        optionalAgreement: optionalAgreement,
        optionalAgreementDefault: optionalAgreementDefault,
        packageFee: packageFee,
        startingPrice: model.factory_price,
        basicCost: Math.floor(+model?.plan?.cost_display+(model.plan?.cost_display*10/100)),
        disclosureSubsidy: model.device_model_price?.disclosure_price || 0,
        disclosureSubsidySixPlan: +(model.device_model_price?.disclosure_price_six_plan || 0),
        disclosureSubsidyBasicPlan: +(model.device_model_price?.disclosure_price_basic_plan || 0),
        conversion_price: +(model.device_model_price?.conversion_price || 0),
        conversion_apply_date: model.device_model_price?.conversion_apply_date,
        manual_conversion_confirm: +(model.device_model_price?.manual_conversion_confirm || 0),
        conversion_price_input : 0,
        conversion_price_input_add : 0,
        interestRate: model.telcom.installment_rate,
        conversion2G: null,
        discost12: (model.plan?.discost_12 || 0) * 1.1,
        discost24: (model.plan?.discost_24 || 0) * 1.1,
        discostChange: 24,
        displayApplyDate: model.price_apply_date ? (model.price_apply_date).split(" ")[0] : null,
        rateAgreement: +model.plan?.discost_24 * 1.1,
        saler: this.props.auth.user,
        contractMonth: 24,
        disclosureSubsidyType : "six"
      },
      color: stateColor,
      referrer: '/compare' + window.location.search,
    }

    //console.log("state", state);

    this.props.history.push({
      pathname: `/${routeName.WIRELESS_ADVICE}`,
      state
    });
  }

  removeItem = (index) => {
    let { devices } = this.state;
    let params      = this.state.urlParams;
    let color       = params.color;

    color.splice(index, 1);

    params.color = color;
    
    //devices[index] = null;
    //devices = devices.filter(e => e !== null);
    devices.splice(index, 1);
    
    this.setState({
      devices   : devices,
      urlParams : params
    }, () => {
      this.updateUrl();
    });
  }

  getActiveColorIndex = (deviceIndex) => {
    let index             = 0;
    let colorId           = 0;
    let devices           = this.state.devices;
    let color             = this.state.urlParams.color;
    let colorLength       = color.length;;
    let colors            = [];
    let tempInt           = 0;
    let colorsLength      = 0;
    let colorsInfo        = 0;
    let colorsInfoColorId = 0;

    if(colorLength > deviceIndex){
      colorId      = this.state.urlParams.color[deviceIndex];
      colors       = devices[deviceIndex].info.colors;
      colorsLength = colors.length;

      for(tempInt = 0; tempInt < colorsLength; tempInt++){
        colorsInfo        = colors[tempInt];
        colorsInfoColorId = colorsInfo.colorID;

        if(Number(colorId) == Number(colorsInfoColorId)){
          index = tempInt;

          break;
        }
      }
    }

    return index;
  }

  render() {
    const { devices, deviceSelectedIndex,devicesDetail } = this.state;
    const { telcomProviders } = this.props

    return (
      <Fragment>
        <table className="table table-striped remove-cursor">
          <tbody>
            <tr>
              <th className="text-center">
                통신사 선택
              </th>
              {[...Array(3).keys()].map(index => (
                <td key={index} className="cursor-pointer" onClick={() => this.clickChooseTelcom(index)}>
                  <div className="provider-choose-table">
                    {devices[index] ? (
                      <img src={devices[index].telcom?.image_url?.original} alt={devices[index].telcom?.code} />
                    ) : (
                      <h5 className="mb-0 label-prices">
                        통신사
                      </h5>
                    )}
                    <a ></a>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <th rowSpan="2" className="py-0 bg-white">단말기</th>
              {[...Array(3).keys()].map(index => (
                (devices[index] && devices[index].info && Object.keys(devices[index].info).length > 0) ? (
                  <td className="py-0 bg-white" key={index}>
                    <h2 className=" name">
                      <a onClick={() => this.clickChooseDevice(index)}>
                        <span className="model-code">{devices[index].info.name}</span>
                        {devices[index].info.v_device.name}
                      </a>
                      <a
                        className="btn reset-table"
                        onClick={() => this.clickChooseDevice(index)}
                      >
                        <img src="/images/reset-table.svg" alt="" />
                      </a>
                    </h2>
                    <div className="slide-color-product">

                      <SwiperSlideComponent
                        devices={devices[index].info?.colors || []}
                        onClickSelectedColorActive={this.onClickSelectedColorActive}
                        indexActive={this.getActiveColorIndex(index)}
                      />
                  </div>
                  </td>
                ) : (
                  <td className="py-0 bg-white" key={index}>
                    <button
                      className="btn gallary-table"
                      onClick={() => this.clickChooseDevice(index)}
                      type={'button'}
                    >
                      <img src="/images/img-07.png" alt="" />
                    </button>
                  </td>
                )
              ))}
            </tr>
            <tr>
              {[...Array(3).keys()].map(index => (
                <td className="bg-white right-product" key={index}>
                  {devices[index] && (
                    <ul className="list-feature m-0 p-0">
                      {(devices[index].info.v_device?.device_special_feature || []).map((item, i) =>
                        <Fragment key={i}>
                          <li>
                            {item.pivot && item.pivot.opt_value ? (
                              <Tooltip message={item.pivot.opt_value} position={'top'}>
                                {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                <div
                                  className="text-feature"
                                  style={{
                                    backgroundColor: `${item.sf_type_content.backgroundColor}`,
                                    color: `${item.sf_type_content.color}`
                                  }}
                                >
                                  <span>
                                    {item.sf_name}
                                  </span>
                                </div>
                                )}
                              </Tooltip>
                            ) : (
                              <Fragment>
                                {item.image_url ? (<img src={item.image_url.original} alt=""></img>) : (
                                <div
                                  className="text-feature"
                                  style={{
                                    backgroundColor: `${item.sf_type_content.backgroundColor}`,
                                    color: `${item.sf_type_content.color}`
                                  }}
                                >
                                  <span>
                                    {item.sf_name}
                                  </span>
                                </div>
                                )}
                              </Fragment>
                            )}
                          </li>
                        </Fragment>
                      )}
                    </ul>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>단말기 설명</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    <button className="btn btn-table " type="button"  data-toggle="modal" onClick={()=>this.openModalDetailDevice(index)} data-target="#exampleModal">
                      단말기 설명
                    </button>
                  )}
                    {!devices[index] && (
                      <button className="btn btn-table disabled" type="button"  data-toggle="modal"  data-target="#exampleModal">
                        단말기 설명
                      </button>
                    )}
                </td>
              ))}
            </tr>
            <tr>
              <th>출시일</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && devices[index].info?.release_date}
                </td>
              ))}
            </tr>
            <tr>
              <th>출고가</th>
              {[...Array(3).keys()].map(index => (
                <td className="prices-pink" key={index}>
                  {devices[index] && (
                    `${devices[index].info.factory_price ? priceFormat(devices[index].info.factory_price) +' 원' : ''}`
                  )}
                </td>
              ))}
            </tr>
            {/*<tr>*/}
              {/*<th>상세 OS</th>*/}
              {/*{[...Array(3).keys()].map(index => (*/}
                {/*<td key={index}>*/}
                  {/*{devices[index] && (*/}
                    {/*<div dangerouslySetInnerHTML={{__html: devices[index].info?.v_device?.os_desc}} />*/}
                  {/*)}*/}
                {/*</td>*/}
              {/*))}*/}
            {/*</tr>*/}
            <tr>
              <th>CPU</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.cpu
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>디스플레이</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.disp_info
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>디스플레이 크기 </th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && devices[index].info && devices[index].info.v_device ?  devices[index].info.v_device.screen_size+' inches' : '' }
                </td>
              ))}
            </tr>
            <tr>
              <th>카메라</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && Object.keys(devices[index].info).length > 0 && (
                    <Fragment>
                      <p className="mb-0">전면: {devices[index].info?.v_device?.front_cam}</p>
                      <p className="mb-0">후면: {devices[index].info?.v_device?.back_cam}</p>
                    </Fragment>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>내장 메모리</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.int_storage
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>외장 메모리</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.ext_storage
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>배터리</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.battery
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>RAM</th>
              {[...Array(3).keys()].map(index => (
                <td key={index}>
                  {devices[index] && (
                    devices[index].info?.v_device?.memory + "GB"
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="group-table-button ">
          {[...Array(3).keys()].map(index => (
            devices[index] ? (
              <div className="item" key={index}>
                <button className="btn btn-submit mx-1 px-3" type="button" onClick={() => this.redirectWireLessAdvice(index)}>
                  상세상담
                </button>
                <button className="btn btn-gray mx-1 px-4" type="button" onClick={() => this.removeItem(index)}>
                  초기화
                </button>
              </div>
            ) : (
              <div className="item opaity-5" key={index}>
                <button className="btn btn-submit mx-1 px-3" type="button">
                  상세상담
                </button>
                <button className="btn btn-gray mx-1 px-4" type="button">
                  초기화
                </button>
              </div>
            )
          ))}
        </div>

        <ModalSelectTelcom
          isOpenModal={this.state.isOpenModalSelectTelcom}
          toggleModal={this.closeModalSelectelcom}
          telcomProviders={telcomProviders}
          onSelectedTelcom={this.onSelectedTelcom}
        />

        <ModalSelectDevice
          isOpenModal={this.state.isOpenModalSelectDevice}
          toggleModal={this.closeModalSelecDevice}
          onSelectedDevice={this.onSelectedDevice}
          telcomId={(this.state.devices[deviceSelectedIndex]?.telcom?.id || 0)}
          model={(this.state.devices[deviceSelectedIndex]?.info || {})}
          manufacturers={this.props.manufacturers}
          WirelessAdvice={this.props.WirelessAdvice}
        />

        <DescriptionDetailDeviceModal
          devicesDetail={this.state.devicesDetail.length > 0 ? this.state.devicesDetail : devices}
          isOpenModal={this.state.isOpenModalDetailDevice}
          cloaseModal={this.closeModalDetailDevice}
        />

      </Fragment>
    );
  }
}

CompareDevice.propTypes = {
  history: PropTypes.object,
  telcomProviders: PropTypes.object,
  getAdviceWireless: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  isReset: PropTypes.bool,
  auth: PropTypes.object,
  setHasData: PropTypes.func,
  hasData: PropTypes.bool,
  getParams: PropTypes.func,
};
const FORM_NAME = 'WirelessAdvice';
const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  validate,
});

const mapStateToProps = state => {
  return {
    telcomProviders: state.dataStore.telcomProviders,
    auth: state.auth,
    manufacturers: state.dataStore.manufacturers,
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAdviceWireless: () => dispatch(getAdviceWireless()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm
)(withRouter(CompareDevice));
