
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {numberWithCommas, optionalAgreementCT, optionWelfare, priceFormat,priceFormatDiff, roundUpNumber, getTextWirelessStep3Promotion} from "../../../utils/helper";
import {CONSULTANT_WIRELESS} from "../../../utils/constant";
import PlanInformationModal from './PlanInformationModal';

import moment from 'moment';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import NumberFormat from 'react-number-format';
import {Modal} from 'reactstrap';

class WirelessPackageFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModal : false,
      resultPackage : {},
      error_modal: {
        isShowModal: false,
        message: ""
      },
    }
  }

  /**
 * show modal plan
 */
  handleShowModalPlan = () => {
    this.setState({
        isShowModal : true
    })
  }

  /**
   *
   */
  toggleModal = () => {
    setTimeout(() => {
      this.setState({
          isShowModal : false
      })
    }, 300);
  }

  toggleErrorModal = (e, modal) => {
    e && e.preventDefault();
    this.setState({
        [modal]: {
            ...this.state[modal],
            isShowModal: !this.state[modal].isShowModal
        }
    })
  }

  planCustomResultPackage = (data) => {
    const { WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice

    let discount = getTextWirelessStep3Promotion(WirelessAdviceStep3)
    if (discount.length > 0) {
      alert(
        `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
      )
    }

    let discost12 = +data.planId.discost_12 + (+data.planId.discost_12*0.1);
    let discost24 = +data.planId.discost_24 + (+data.planId.discost_24*0.1);

    let step3 = {
      ...WirelessAdviceStep3,

      additionalGrants: 0,
      packageFee: data.planCustom,
      promotion: {},
      promotionDurationDiscountOne: 0,
      promotionDurationDiscountTwo: 0,
      basicCost: data.costDistplay,
      basicCostCalc: roundUpNumber(data.costCalc),
      planData: data.planId ? data.planId : data.valFee,
      discountFullPlan: 0,
      discost12: discost12,
      discost24: discost24,
    }
    this.props.change('WirelessAdviceStep3', step3);
    this.props.change('WirelessAdviceStep3Default.promotion',{})


    let cost_display = +data.costDistplay;
    let cost_calc;
    if (data.costCalc == 0) {
      cost_calc = +data.costDistplay;
    } else {
      cost_calc = +data.costCalc;
    }
    let customPlan = 999;
    let dataPlan = data.planId;
    let commitmentRate ;
    if (data.planId.spec_cmm == 1) {
      commitmentRate = +data.planId.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }

    if(isNaN(commitmentRate)){
      commitmentRate = +WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
    }
    
    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;

    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);

    // this.props.change('WirelessAdviceStep3.rateAgreement', +dataPlan.discost_24 + (+dataPlan.discost_24*0.1)) ;
    // this.props.change('WirelessAdviceStep3.rateAgreementDefault', +dataPlan.discost_24 + (+dataPlan.discost_24*0.1)) ;
    this.props.change('WirelessAdviceStep3.rateAgreement', WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? result.rateAgreement : 0);
    this.props.change('WirelessAdviceStep3.rateAgreementDefault', result.rateAgreement) ;
    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreement',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
    } else {
      this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
    }

    // this.optionalAgreementCT(data.costDistplay/1.1, data.costCalc/1.1, 999, data.planId);

      //set lai tien phuc phuc loi
      let dataTelcom = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
      if(dataTelcom) {
          dataTelcom=dataTelcom.toUpperCase()
      }
      var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
      var optionalAgreement=this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement ? this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement : 0
      var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
      var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
      var optionFee=this.props.WirelessAdvice.WirelessAdviceStep3.optionFee
      var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
      var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
      var valueBasicCost=0
      if(basicCostCalc != 0) {
          valueBasicCost=basicCostCalc
      }else {
          valueBasicCost=basicCost
      }
      var combinedDiscount=0
      if(this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
          combinedDiscount = this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price + this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv
      }else {
          combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      }
      combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1))
      this.props.change('WirelessAdviceStep3.welfareDiscount', optionWelfare(basicCost,roundUpNumber(basicCost),welfareDiscount,optionalAgreement,rateAgreement,promotion, combinedDiscount,dataTelcom,optionFee))
  }

  checkKtPremium = (WirelessAdviceStep3) => {
    let result       = false;
    let combinePlans = WirelessAdviceStep3.combinePlans;

    if(combinePlans && combinePlans.length > 0){
      let discountConditionName = combinePlans[0].discount_condition_name;

      if(discountConditionName && discountConditionName.length > 0){
        let firstDiscountConditionName = discountConditionName[0];
        let code                       = firstDiscountConditionName.code;

        if(code && code == "KT_PREMIUM_DISCOUNT"){
          result = true;
        }
      }
    }

    return result;
  }

  planResultPackage = (data) => {
    const { WirelessAdvice } = this.props;
    const { WirelessAdviceStep3 } = WirelessAdvice

    let discount = getTextWirelessStep3Promotion(WirelessAdviceStep3)
    if (discount.length > 0) {
      alert(
        `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
      )
    }
    let discost12 = (+data.discost_12) + (+data.discost_12*0.1);
    let discost24 = (+data.discost_24) + (+data.discost_24*0.1);

    let asIsBasicCost = WirelessAdviceStep3.basicCost;
    let adIsId        = WirelessAdviceStep3.packageFee.id;

    let step3 = {
      ...WirelessAdvice.WirelessAdviceStep3,
      additionalGrants: 0,
      packageFee: data,
      promotion: {},
      promotionDurationDiscountOne: data.plan_promotion_duration.length == 1 && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? data.plan_promotion_duration[0].promotion_duration_discount[0].discount_price : 0,
      promotionDurationDiscountTwo: data.plan_promotion_duration.length == 2 && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? data.plan_promotion_duration[1].promotion_duration_discount[0].discount_price : 0,
      basicCost: +data.cost_display+data.cost_display*10/100,
      basicCostCalc: +data.cost_calc+data.cost_calc*10/100,
      planData: null,
      discountFullPlan: data.discount_full,
      promotionDuration: data.plan_promotion_duration,
      discost12: discost12,
      discost24: discost24,
    }

    this.props.change('WirelessAdviceStep3', step3);
    this.props.change('WirelessAdviceStep3Default.promotion',{})

    let cost_display = +data.cost_display + (+data.cost_display*0.1);
    let cost_calc = +data.cost_calc + (+data.cost_calc*0.1);
    let customPlan = 1;
    let dataPlan = data;
    let commitmentRate ;
    if (data.spec_cmm == 1) {
      commitmentRate = +data.spec_cmm_value/100;
    } else {
      commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
    }

    if(isNaN(commitmentRate)){
      commitmentRate = +WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
    }

    let discostChange = WirelessAdvice.WirelessAdviceStep3.discostChange;
    let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID;

    //console.log("cost_display/1.1 : " + cost_display/1.1);
    //console.log("cost_calc/1.1 : " + cost_calc/1.1);
    //console.log("customPlan : " + customPlan);
    //console.log("dataPlan : " + dataPlan);
    //console.log("commitmentRate : " + commitmentRate);
    //console.log("discostChange : " + discostChange);
    //console.log("telcomID : " + telcomID);

    let result = optionalAgreementCT(cost_display/1.1, cost_calc/1.1, customPlan, dataPlan, commitmentRate, discostChange, telcomID);
    
    //console.log("result");
    //console.log(result);

    this.props.change('WirelessAdviceStep3.rateAgreement', WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? result.rateAgreement : 0);
    this.props.change('WirelessAdviceStep3.rateAgreementDefault', result.rateAgreement) ;

    if (result.optionalAgreement < 0) {
      this.props.change('WirelessAdviceStep3.optionalAgreement',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
      this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
    } else {
      if (data.id == 3688) {
        this.props.change('WirelessAdviceStep3.optionalAgreement', 20000);
        this.props.change('WirelessAdviceStep3.optionalAgreementDefault', 20000);
        this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', 20000);
      } else {
        this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
        this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
        this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
      }
    }
    // this.optionalAgreementCT((+data.cost_display+data.cost_display*10/100)/1.1, (+data.cost_calc+data.cost_calc*10/100)/1.1, 1, data);


      let dataTelcom = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
      if(dataTelcom) {
          dataTelcom=dataTelcom.toUpperCase()
      }
      var rateAgreement=(this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement && !this.props.WirelessAdvice.WirelessAdviceStep3.isRateAgreement) ? this.props.WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
      // var combinedDiscount=WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      var optionalAgreement=this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement ? this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement : 0
      var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

      var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount
      var optionFee=this.props.WirelessAdvice.WirelessAdviceStep3.optionFee
      var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
      var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
      var valueBasicCost=0
      if(basicCostCalc != 0) {
          valueBasicCost=basicCostCalc
      }else {
          valueBasicCost=basicCost
      }
      var combinedDiscount=0

//      console.log("data", data);
//      console.log("this.props.WirelessAdvice.WirelessAdviceStep3", this.props.WirelessAdvice.WirelessAdviceStep3);

      if(this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans.length > 0) {
        combinedDiscount = +this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_wireless_price + +this.props.WirelessAdvice.WirelessAdviceStep3.combinePlans[0].discount_tv;
      }
      else {
        combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
      }

      combinedDiscount= parseInt(parseFloat(combinedDiscount).toFixed(1));

      this.props.change('WirelessAdviceStep3.welfareDiscount', optionWelfare(basicCost,roundUpNumber(basicCost),welfareDiscount,optionalAgreement,rateAgreement, promotion,combinedDiscount,dataTelcom,optionFee));

      let isKtPreminum    = this.checkKtPremium(WirelessAdviceStep3);
      let dataCostDispaly = (+data.cost_display + (+data.cost_display * 0.1));
      let dataId          = data.id;

      if(isKtPreminum && asIsBasicCost >= 77000 && adIsId != 2989 && adIsId != 3702 ){
        if(dataCostDispaly < 77000){
          this.setState({
            error_modal: {
                isShowModal: true,
                message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>)
            }
          });
        }
        else if(dataId == 2989 || dataId == 3702){
          this.setState({
            error_modal: {
                isShowModal: true,
                message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>)
            }
          });
        }
      }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let {isShowModal, resultPackage} = this.state;
    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금제</label>
          <div className="col-sm-8">
            <button
              type="button"
              className="btn btn-input-form btn-devices mb-0 pr-2"
              // value={
              //   WirelessAdvice.WirelessAdviceStep3.packageFee
              //   ? WirelessAdvice.WirelessAdviceStep3.packageFee.plac_name
              //     ? WirelessAdvice.WirelessAdviceStep3.packageFee.plac_name
              //     : WirelessAdvice.WirelessAdviceStep3.packageFee.name
              //   : '미적용'
              // }
              onClick={this.handleShowModalPlan}
            >{WirelessAdvice.WirelessAdviceStep3.packageFee
              ? WirelessAdvice.WirelessAdviceStep3.packageFee.plac_name
                ? WirelessAdvice.WirelessAdviceStep3.packageFee.plac_name
                : WirelessAdvice.WirelessAdviceStep3.packageFee.name
              : '미적용'}</button>
          </div>
        </div>
        <PlanInformationModal 
          toggleModal = {this.toggleModal}
          isShowModal = {isShowModal}
          planCustomResultPackage = {this.planCustomResultPackage}
          planResultPackage = {this.planResultPackage}
          checkPlanNew = {'planFirst'}
          checkUnApply = {false}
          // getDisclosurePrice={this.getDisclosurePrice}
        />
        <Modal isOpen={this.state.error_modal.isShowModal}
                className="modal-dialog modal-dialog-centered"
                contentClassName="pl-4 pr-4"
                toggle={(e) => this.toggleErrorModal(e, 'error_modal')}
            // onOpened = {this.onOpened}
            // onClosed = {this.onCloseModal}
            // backdrop={"static"}
        >
            <div className="modal-body text-center p-5">
                {
                    this.state.error_modal.message
                }
            </div>
            <div className="modal-footer justify-content-center border-top-0 mb-4">
                <button type="button" className="btn btn-submit pl-5 pr-5"
                        onClick={(e) => this.toggleErrorModal(e, 'error_modal')}>확인
                </button>
            </div>
        </Modal>
    </Fragment>
    );
  }
}

WirelessPackageFee.propTypes = {
  WirelessAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessPackageFee);
