import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 
import PaperMoneyModal from './PaperMoneyModal';

class PaperMoneyComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            price: 0,
          };
    }

    setValuePay = (value) => {
        this.setState({
            price: value
        })
    }
    render() {
        const {
            price
        } = this.state;
        return(
            <Fragment>
                <div className="flex-wrapper">
                    <div className="left border-0">
                        <div className="form-group row align-items-start">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">공시지원금</label>
                            <div className="col-sm-8 pl-2">
                                <button className="btn btn-prices-wp mb-0" type="button" data-toggle="modal" data-target="#modal-24">{this.state.price} <span>원</span></button>
                    
                            </div>
                        </div>
                    </div>
                </div>
                
                <PaperMoneyModal setValuePay = {this.setValuePay}/>
            </Fragment>

        );
    }
}
 
export default PaperMoneyComponent;
