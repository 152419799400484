import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';

class ResetModal extends Component {

  handleSubmit = () => {
    this.props.openPopup()
    this.props.resetForm()
  }

  render() {

    return (
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-reset-product'}
          onOpened={this.modalOpened}
        >
          <div className="">
            <div className="modal-body text-center">
                <span className="notification_delete">해당 화면은 모두 초기화처리 됩니다.</span>
            </div>
            <div className="modal-footer justify-content-center border-top-0">
              <button type="button" className="btn btn_close" onClick={this.props.openPopup}>
                취소
              </button>
              <button type="button" className="btn btn-submit" onClick={this.handleSubmit}>
                확인
              </button>
            </div>
          </div>
        </Modal>
    );
  }
}

ResetModal.propTypes = {
  isOpenModal: PropTypes.bool,
  openPopup: PropTypes.func,
  resetForm: PropTypes.func,
};

export default ResetModal;
