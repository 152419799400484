
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import OptionalAgreementModal from './OptionalAgreementModal';
import {numberWithCommas,priceFormat, roundUpNumber, optionalAgreementCT, getOptionalAgreementHardcording} from "../../../utils/helper";
import { OPTION_CHOOSE_2G, INSTALLMENT_PAYMENT_METHOD } from './../../../utils/constant'
class WirelessOptionalAgreement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      money: 17270,
      moneyDefault: 17270,
      installmentMoney: 470000
    }
  }

  componentDidUpdate(){
    const { WirelessAdvice, change } = this.props
    if(WirelessAdvice.WirelessAdviceStep3.optionFee === 0){
      let id                = WirelessAdvice.WirelessAdviceStep3.packageFee?.id;
      let optionalAgreement = WirelessAdvice.WirelessAdviceStep3.optionalAgreement;
 
      optionalAgreement = getOptionalAgreementHardcording(+id, optionalAgreement); // 선택약정 하드코딩 변경

      change('WirelessAdviceStep3.optionalAgreement', optionalAgreement);
    }  
  }

  showModal = () => {
    this.setState({
        modal: !this.state.modal
    })
  }

  toggle = () => {
      const {WirelessAdvice } = this.props;
      if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {
      }else {
          this.showModal()
      }

  }
  toggleEvent = () => {
    this.showModal()
  }
  getValue = (data) => {
    this.props.change('WirelessAdviceStep3.optionalAgreement', data)
    this.props.change('WirelessAdviceStep3.optionalAgreementDefault', data)
  }

  getTotal = () => {
    const { WirelessAdvice } = this.props;

    let result              = null;
    let wirelessAdviceStep3 = WirelessAdvice.WirelessAdviceStep3;
    let contractMonth       = +wirelessAdviceStep3.contractMonth;
    let optionFee           = +wirelessAdviceStep3.optionFee;
    let optionalAgreement   = +(wirelessAdviceStep3?.optionalAgreement || 0);
    let conversion2G        = wirelessAdviceStep3?.conversion2G;
    let conversion2GId      = +(conversion2G?.id || 0);
    let monthFee            = 0;
    let month               = "";
    
    if(conversion2GId == 0){
      if(contractMonth == 23){
        month = "12개월 + 12개월";
      }
      else{
        month = contractMonth + "개월";
      } 

      if(optionFee == 0){
        if(contractMonth == 23){
          contractMonth = 24;
        }

        optionalAgreement = priceFormat(optionalAgreement).toString().split(',').join('');
        monthFee          = contractMonth * +optionalAgreement;
        monthFee          = priceFormat(monthFee);
      }

      result = "총 할인금액(" + month + ")" + monthFee + "원";
    }
    else{
      result = "2G전환 요금할인";
    }

    return result;
  }

  render() {
    this.componentDidUpdate();
    const { WirelessAdvice } = this.props;

    let validPrice = WirelessAdvice.WirelessAdviceStep3.basicCost;
    let optionalAgreement = WirelessAdvice.WirelessAdviceStep3.optionalAgreement;
    let optionalAgreementDefault=WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault;
    let optionalAgreementWith2G=WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G;
    let moneyFormat= optionalAgreement ? '-'+ priceFormat(optionalAgreement) : 0;
    // let moneyFormat= priceFormat((validPrice - WirelessAdvice.WirelessAdviceStep3.rateAgreement)*0.25);
    let monthFee = 0;
    
    if (WirelessAdvice.WirelessAdviceStep3.optionFee == 0) {
      let price = priceFormat(+optionalAgreement).toString().split(',').join('');
      monthFee = priceFormat(+price*(WirelessAdvice.WirelessAdviceStep3.contractMonth));
    }else{
      monthFee = 0;
    }
    let resultAgreement;
    let styleNumber;
    if (WirelessAdvice.WirelessAdviceStep3.optionFee ==1 ) {
      if (optionalAgreementWith2G !== null) {
        if(WirelessAdvice.WirelessAdviceStep3.conversion2G && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id && (WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1 || WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 2)) {
          resultAgreement =  '-'+priceFormat(OPTION_CHOOSE_2G.price_optionalAgreement_HTTB);
        }else {
          resultAgreement = optionalAgreement == optionalAgreementWith2G ? 0 : moneyFormat;
        }
      } else {
        if(WirelessAdvice.WirelessAdviceStep3.conversion2G && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id && (WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1 || WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 2)) {
          resultAgreement = moneyFormat;
        }else {
          resultAgreement = 0;
        }
      }
    }else{
      resultAgreement = moneyFormat;
    }

    if (resultAgreement != 0) {
      styleNumber = "btn-prices-wp";
    } else {
      styleNumber = "";
    }

    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mb-3">선택약정 </label>

          <div className="col-sm-8">
            <button className={`mb-0 btn btn-input-form ${styleNumber}`} type="button" onClick={this.toggle}>
              {resultAgreement}
              <span>원</span>
            </button>
            <p className="small-color mb-0 pl-2">
              { this.getTotal() }
            </p>
          </div>
        </div>
        <OptionalAgreementModal
          showModal={this.state.modal}
          toggleEvent={this.toggleEvent}
          money={this.state.money}
          moneyDefault={this.state.moneyDefault}
          getValue={this.getValue}
          installmentMoney={priceFormat(optionalAgreement)}
          optionalAgreementDefault={optionalAgreementDefault}
          validPrice = {validPrice}
        />
      </Fragment>
    );
  }
}

WirelessOptionalAgreement.propTypes = {
  WirelessAdvice: PropTypes.object
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessOptionalAgreement);
