import React from 'react';
import {Modal} from 'reactstrap';

class OpenPopupChangeMonth extends React.Component{

    renderMonth = (month) => {
        if(month){
            var parseJson = JSON.parse(month)
            return parseJson.map((item,index)=>{
                return (
                    <li
                        key={index}
                        onClick={()=>this.onClickMonth(item)}
                    >
                        {item}개월
                    </li>
                )
            })
        }
        return ''
    }

    onClickMonth = (month) => {
        this.props.changeStateDataMonth(month)
        this.props.openPopupChangeMonth();
    }

    render() {
        let {itemData} = this.props
        return(
            <Modal
                toggle={this.props.openPopupChangeMonth}
                isOpen={this.props.isOpenPopupChangeMonth}
                className={'modal-dialog-centered modal-additional modal-mont-installment'}
                contentClassName={'pl-4 pr-4'}
                // backdrop={"static"}
            >
                <div className="modal-header">
                    <h5 className="modal-title">할부개월</h5>
                    
                </div>
                <div className="modal-body">
                    <ul className="list-additional">
                        {this.renderMonth(itemData.dcc_installment_month)}
                    </ul>
                </div>

            </Modal>
        )
    }
}

export default OpenPopupChangeMonth;
