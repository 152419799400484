import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import api from "./../../utils/api";
import {priceFormat} from "./../../utils/helper";
import { showLoading, hideLoading } from './../../store/actions/ui';
import ListTabDevice from "./ListTabDevice";
import * as helper from "../../utils/helper";
import MessageJSON from "../../utils/message";
import SearchDevice from "./SearchDevice";
import {Field} from "redux-form";
import renderCheckboxField from "../FormHelper/CheckboxField";
import InfiniteScroll from "react-infinite-scroll-component";
import renderTextField from "../FormHelper/TextField";
class ListDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultant:{
        data:[]
      },
      consultantLast:{
        data:[]
      },
      consultantColors:[],
      colorActive:{},
      idActive:null,
      deviceActive:{},
      consultantActive:{},
      dataFilter:{
        'page':1,
        'limit': 50,
        'relationship[0]': 'colors',
        'relationship[1]': 'device|deviceSpecialFeature',
        'relationship[2]': 'plan',
        'query': '',
        'manufactor': '',
        'distinct': true,
        // 'sort[0]': '',
        'published': true,
        'is_new': '',
        'is_consulted': '',
        'is_bookmarked': '',
      },
      tooltipOpen: true,
      mfID : 0,
      standardActive: '',
      standardNameActive: '',
      loading: true
    }
  }

  componentDidMount() {
    this.getListConsultants();
  }

  getListConsultants = () => {

    this.setState({
      dataFilter : {
        ...this.state.dataFilter,
        'telcom': this.props.telcomId,
        manufactor : this.state.mfID,
        'page':1,
        'limit': 20,
      }
    },()=> {
      let dataRequest = {
        ...this.state.dataFilter,
        'telcom': this.props.telcomId,
        manufactor : this.state.mfID,
        'page':1,
        'limit': 20,
      }
      if(this.state.mfID == 0 || this.state.mfID == -1) {
        delete dataRequest['manufactor'];
      }
        if(this.state.standardActive) {
            dataRequest.standard_type = this.state.standardActive;
        }

      this.props.showLoading();
      api.advice.getListConsultants(dataRequest).then(res => {
        this.props.hideLoading();
        if(res.data.data.length > 0) {
          let model = this.props?.model || {}
          this.props.hideLoading();
          this.setState({
            consultant:{
              data:res.data.data
            },
            consultantLast:{
              data:res.data.data
            },
            consultantColors:res.data.data[0].color,
            colorActive:res.data.data[0].color[0],
            idActive: model.rowID ? model.rowID : res.data.data[0].rowID,
            deviceActive:res.data.data[0].device,
            consultantActive:res.data.data[0],
            loading: false
          })
        } else {
          this.props.hideLoading();
          this.setState({
            consultant:{
              data:[]
            },
            consultantLast:{
              data: []
            },
            consultantColors:[],
            colorActive:{},
            idActive:null,
            deviceActive:{},
            consultantActive:{},
            loading: false
          })
        }
      }).catch(err => {
        this.props.hideLoading();
        this.setState({
          loading: false
        })
      })
    })

  }

    onClickFilterStandard = (item) => {
        if(item == null) {
            this.setState({
                standardActive:'',
                standardNameActive:''
            }, () => {
                this.getListConsultants();
            })
        } else {
            this.setState({
                standardActive:item.id,
                standardNameActive:item.name
            }, () => {
                this.getListConsultants();
            })
        }

    }

  fetchMoreData = async () => {
    this.setState({
      dataFilter:{
        ...this.state.dataFilter,
        page: this.state.dataFilter.page + 1
      }
    }, () => {

      let dataRequest = {
        ...this.state.dataFilter,
        'telcom': this.props.telcomId,
        'manufactor' : this.state.mfID,
        'page':this.state.dataFilter.page,
        'limit':this.state.dataFilter.limit,
        'relationship[0]': 'colors',
        'relationship[1]': 'device|deviceSpecialFeature',
        'relationship[2]': 'plan',
        'query':this.state.dataFilter.query,
        'distinct':true,
        'sort[0]': this.state.dataFilter['sort[0]'],
        'published':true
      }
      if(this.state.mfID == 0 || this.state.mfID == -1) {
        delete dataRequest['manufactor'];
      }
        if(this.state.standardActive) {
            dataRequest.standard_type = this.state.standardActive;
        }


      api.advice.getListConsultants(dataRequest).then(res => {
        this.setState({
          consultant:{
              ...res.data.data,
              data: [...this.state.consultant.data,...res.data.data]
          },
          loading: false,
        })
      }).catch(err => {
        this.setState({
          loading: false
        })
      })

    });
  };


  onClickSelectedManufacture = (id) => {
    // this.props.change('WirelessAdviceStep2.mfID', id);
    this.setState({
      mfID : id
    },() => {
      setTimeout(() => {
        this.getListConsultants();
      });
    })

  }

  onClickSearchKeyword = (e) => {
      e.preventDefault();
      this.getListConsultants()
  }

  onChangeSearch = (e) => {
    this.setState({
      dataFilter : {
        ...this.state.dataFilter,
        query : e.target.value
      }
    })
  }


  onChangeFilterChecked = (e) => {
    this.setState({
      dataFilter : {
        ...this.state.dataFilter,
        [e.target.name] : e.target.value == false ?true : ''
      }
    },()=>{
      setTimeout(() => {
        this.getListConsultants();
      })
    })
  }

  onChangeSortTableField = (value) => {

    switch (value) {

      case 'dl_price' : if(this.state.dataFilter['sort[0]'] == 'plan_sort|asc'){
        this.setState({
          dataFilter : {
            ...this.state.dataFilter,
            ['sort[0]'] : 'dl_price|asc'
          }
        },()=>{
          setTimeout(() => {
            this.getListConsultants();
          })
        })
      }else if(this.state.dataFilter['sort[0]'] == 'dl_price|asc') {
        this.setState({
          dataFilter : {
            ...this.state.dataFilter,
            ['sort[0]'] : 'dl_price|desc'
          }
        },()=>{
          setTimeout(() => {
            this.getListConsultants();
          })
        })
      }else{
        this.setState({
          dataFilter : {
            ...this.state.dataFilter,
            ['sort[0]'] : 'dl_price|asc'
          }
        },()=>{
          setTimeout(() => {
            this.getListConsultants();
          })
        })
      }
      break;
      case 'dps_amt_cost' :
        if(this.state.dataFilter['sort[0]'] == 'plan_sort|asc'){
          this.setState({
            dataFilter : {
              ...this.state.dataFilter,
              ['sort[0]'] : 'dps_amt_cost|asc'
            }
          },()=>{
            setTimeout(() => {
              this.getListConsultants();
            })
          })
        }else if(this.state.dataFilter['sort[0]'] == 'dps_amt_cost|asc') {
          this.setState({
            dataFilter : {
              ...this.state.dataFilter,
              ['sort[0]'] : 'dps_amt_cost|desc'
            }
          },()=>{
            setTimeout(() => {
              this.getListConsultants();
            })
          })
        }else{
          this.setState({
            dataFilter : {
              ...this.state.dataFilter,
              ['sort[0]'] : 'dps_amt_cost|asc'
            }
          },()=>{
            setTimeout(() => {
              this.getListConsultants();
            })
          })
        }
        break;
        case 'ISNULL(N.id)' : if(this.state.dataFilter['sort[0]'] == 'plan_sort|asc'){
            this.setState({
              dataFilter : {
                ...this.state.dataFilter,
                ['sort[0]'] : 'ISNULL(N.id)|asc'
              }
            },()=>{
              setTimeout(() => {
                this.getListConsultants();
              })
            })
          }else if(this.state.dataFilter['sort[0]'] == 'ISNULL(N.id)|asc') {
            this.setState({
              dataFilter : {
                ...this.state.dataFilter,
                ['sort[0]'] : 'ISNULL(N.id)|desc'
              }
            },()=>{
              setTimeout(() => {
                this.getListConsultants();
              })
            })
          }else{
            this.setState({
              dataFilter : {
                ...this.state.dataFilter,
                ['sort[0]'] : 'ISNULL(N.id)|asc'
              }
            },()=>{
              setTimeout(() => {
                this.getListConsultants();
              })
            })
          }
          break;
    }
  }

  onClickAddBookmark = (item) => {
    let data = {
        model: item.modelID,
        plan: item.planID,
    }

    let keyConsultant = this.state.consultant.data.findIndex((i) => {
        return i.rowID == item.rowID
    })
    let consultantArray = [...this.state.consultant.data];

    if(keyConsultant !== -1) {
        if(this.state.consultant.data[keyConsultant].is_bookmark == '1') {
            consultantArray[keyConsultant].is_bookmark = '0';
        } else {
            consultantArray[keyConsultant].is_bookmark = '1';
        }

        this.props.showLoading()
        api.advice.addBookmark(data).then(res => {
            if(res.data.status == 1){
                this.setState({
                    consultant:{
                        ...this.state.consultant,
                        data:consultantArray,
                    }
                }, () => {
                    this.props.hideLoading()
                })
            }
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
        })
    }

}


  render() {
    return (
      <div className="left-product">
        <div className="top">
          <ListTabDevice
            WirelessAdvice={this.props.WirelessAdvice}
            manufacturers={this.props.manufacturers}
            onClickSelectedManufacture={this.onClickSelectedManufacture}
            mfID={this.state.mfID}
          />
            <div className="dropdown show">
                <button className="btn btn-product dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    {this.state.standardNameActive ? this.state.standardNameActive : '단말기 유형'}
                </button>
                <div className="dropdown-menu dropdown-menu-option " aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
                    <a className={"dropdown-item " + (this.state.standardActive == '' ? "active" : "")} key={'standard_null'} onClick={() => this.onClickFilterStandard(null)}> 전체</a>
                    {this.props.standardTypeCodes.map((item, i) =>
                        <a className={"dropdown-item " + (this.state.standardActive == item.id ? "active" : "")} key={i} onClick={() => this.onClickFilterStandard(item)}> {item.name}</a>
                    )}
                </div>
            </div>
        </div>

        <div className="row top-search-query">
          <div className="col-md-6">

              <form onSubmit={this.onClickSearchKeyword}>
                  <div className="form-search">
                      <input
                      type="text"
                      className={'form-control h-44 '}
                      onChange={this.onChangeSearch}
                      />
                      <button
                      className="btn my-2 my-sm-0"
                      type="button" onClick={this.onClickSearchKeyword}>
                      <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-search.svg"} alt=""/>
                      </button>
                  </div>
              </form>
            {/*<div className="form-search">*/}
              {/*<input*/}
                {/*type="text"*/}
                {/*className={'form-control h-44 '}*/}
                {/*onChange={this.onChangeSearch}*/}
              {/*/>*/}

              {/*<button*/}
                {/*className="btn my-2 my-sm-0"*/}
                {/*type="button" onClick={this.onClickSearchKeyword}>*/}
                {/*<img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-search.svg"} alt=""/>*/}
              {/*</button>*/}
            {/*</div>*/}
          </div>
          <div className="col-md-6">
            <div className="group-check">
              <div className="form-group form-check">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Field
                    type="checkbox"
                    id="is_new"
                    name="is_new"
                    component={renderCheckboxField}
                    onChange={(e) => this.onChangeFilterChecked(e)}
                  />
                  <label className="custom-control-label"
                         htmlFor="is_new">신규단말기</label>
                </div>
              </div>
              <div className="form-group form-check">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Field
                    type="checkbox"
                    id="is_consulted"
                    name="is_consulted"
                    component={renderCheckboxField}
                    onChange={(e) => this.onChangeFilterChecked(e)}
                  />
                  <label className="custom-control-label"
                         htmlFor="is_consulted">인기순</label>
                </div>
              </div>
              <div className="form-group form-check">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Field
                    type="checkbox"
                    id="is_bookmarked"
                    name="is_bookmarked"
                    component={renderCheckboxField}
                    onChange={(e) => this.onChangeFilterChecked(e)}
                  />
                  <label className="custom-control-label"
                         htmlFor="is_bookmarked">즐겨찾기</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
            <article className="table-form ">
              <div className="table-wireless-step table-cutstom-09">
                <table className="table table-header">
                  <thead className="table-header">
                    <tr>
                      <th scope="col" className="text-left">
                        단말기명
                      </th>
                      <th
                        scope="col"
                        className="text-left">
                        모델명
                      </th>

                      <th
                        scope="col"
                        className="text-left cursor"
                        onClick={() => this.onChangeSortTableField('dl_price')}>
                          <div className={"d-flex"}>
                              <span>출고가</span>
                              <div className="sort-fillter">
                                  <button className={`btn btn-sort  p-0 ${ this.state.dataFilter['sort[0]'] == 'dl_price|desc' ? 'sort-active' : ''}`}>
                                      <i
                                          className={`fas fa-sort-up`}
                                      ></i>
                                  </button>
                                  <button className={`btn btn-sort  p-0 ${this.state.dataFilter['sort[0]'] == 'dl_price|asc' ? 'sort-active' : ''}`}>
                                      <i
                                          className={`fas fa-sort-down`}
                                      ></i>
                                  </button>
                              </div>
                          </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left cursor"
                        onClick={() => this.onChangeSortTableField('dps_amt_cost')}>
                        <div className={"d-flex"}>
                            <span>지원금</span>
                            <div className="sort-fillter">
                                <button className={`btn btn-sort  p-0 ${ this.state.dataFilter['sort[0]'] == 'dps_amt_cost|desc' ? 'sort-active' : ''}`}>
                                    <i
                                        className={`fas fa-sort-up`}
                                    ></i>
                                </button>
                                <button className={`btn btn-sort  p-0 ${this.state.dataFilter['sort[0]'] == 'dps_amt_cost|asc' ? 'sort-active' : ''}`}>
                                    <i
                                        className={`fas fa-sort-down`}
                                    ></i>
                                </button>
                            </div>
                        </div>
                      </th>
                      <th scope="col" className="text-left">기준요금제</th>
                      <th scope="col" className="text-right">
                        <a onClick={() => this.onChangeSortTableField('ISNULL(N.id)')}>
                            <div className="d-flex align-items-center">
                                
                                <i className="far fa-star"></i>
                                
                                <div className="sort-fillter">
                                    <button
                                        className={`btn btn-sort p-0  ${this.state.dataFilter['sort[0]'] == 'ISNULL(N.id)|asc' ? 'sort-active' : ''}`}>
                                        <i className="fas fa-sort-up"></i>
                                    </button>
                                    <button
                                        className={`btn btn-sort p-0  ${this.state.dataFilter['sort[0]'] == 'ISNULL(N.id)|desc' ? 'sort-active' : ''}`}
                                        >
                                        <i className="fas fa-sort-down"></i>
                                    </button>
                                </div>
                            </div>
                            </a>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className="table-scroll"  id="scrollableDiv" style={{maxHeight: '40vh', overflowX: 'auto'}}>
                  <InfiniteScroll
                    dataLength={this.state.consultant.data.length}
                    next={()=>this.fetchMoreData()}
                    // hasMore={this.state.consultant.current_page < this.state.consultant.last_page || false}
                    hasMore={this.state.consultantLast.data.length > 0 || false}
                    scrollableTarget={`scrollableDiv`}
                  >
                    <table className="table table-striped">
                      <tbody>

                      {this.state.consultant.data.length > 0 && this.state.consultant.data.map((item, k) =>
                        <Fragment key={k}>
                          <tr
                            onClick={ () => this.props.clickActiveDevice(item) }
                            className={this.state.idActive == item.rowID ?  'tr-active' : ''}
                          >
                            <th scope="row" className="name">{item.device_name}</th>
                            <td>{item.model_name}</td>
                            <td>{priceFormat(item.dl_price)}원</td>
                            <td>{priceFormat(item.dps_amt_cost)}원</td>
                            <td>{item.plan_name ? item.plan_name : '-'}</td>
                            <td className={item.is_bookmark !== '0' ? "check" : ""}>
                                <button className="btn p-0"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.onClickAddBookmark(item)
                                        }}>
                                    <i className="far fa-star"></i>
                                </button>
                            </td>
                          </tr>
                        </Fragment>
                      )}
                      {!this.state.loading && this.state.consultant.data.length == 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">{ MessageJSON.smart_consultant.no_models}</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    );
  }
}

ListDevice.propTypes = {
  telcomId: PropTypes.number,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  clickActiveDevice: PropTypes.func,
  model: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    telcomProviders: state.dataStore.telcomProviders,
      standardTypeCodes: state.dataStore.standardTypeCodes,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(ListDevice);
