import MessageJSON from "../../utils/message.json";
import * as constant from '../../utils/constant';
function ValidateEmail(mail) 
{
    if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

const validate = values => {
    const errors = {};
    if (!values.paymentNumber) {
        errors.paymentNumber = 'Tên chủ thẻ không được để trống';
    } 
    if (!values.payerNumber) {
        errors.payerNumber = 'Tên chủ thẻ không được để trống';
    }
    if (!values.payerName) {
        errors.payerName = 'Tên chủ thẻ không được để trống';
    }
    if (!values.email) {
        errors.email = 'Tên chủ thẻ không được để trống';
    } 
    if (!values.phone) {
        errors.phone = 'Tên chủ thẻ không được để trống';
    }

    return errors;
};

export default validate;
