 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import WirelessProduct from './Product/Product'
import WirelessStartingPrice from './StartingPrice/StartingPrice'
import WirelessPackageFee from './PackageFee/PackageFee'
import WirelessBasicCost from './BasicCost/BasicCost'

class WirelessProductInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    return (
      <div className="flex-wrapper">
        <div className="left">
          <WirelessProduct 
            setPage={this.props.setPage}
            showPopupDetailDevice={this.props.showPopupDetailDevice}
            />
          <WirelessStartingPrice/>
        </div>
        <div className="right">
          <WirelessPackageFee/>
          <WirelessBasicCost/>
        </div>
      </div>
    );
  }
}

WirelessProductInfo.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessProductInfo);
