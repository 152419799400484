import React from "react";
import {priceFormat, roundUpNumber} from "../../../../utils/helper";
import WirelessConversion2G from "../../Conversion2G/Conversion2G";

class Second extends React.Component {
  render() {
    let {data} = this.props;
    let promotion = data.promotion
    var costNumber = 0;
    var costPromotion = "";

    console.log("promotion", promotion);

    if (promotion && promotion.planID) {
      if(data.optionFee == 1){
        costNumber = Number(promotion.promo_cost);
      }
      else{
        costNumber = Number(promotion.optional_agreement);

        if(promotion.planID == "input_live") {
          costNumber = Number(promotion.promo_cost);
        }
      }

      costPromotion = costNumber.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }

    let welfare=data.welfareDiscount

    var costWelfare = 0;
    var textWelfare = null;
    if(welfare && welfare.cost) {
      costWelfare = Number(welfare.cost)
      textWelfare = welfare.value
    }

    let mutualService=data.mutualService
    var costNumber = 0
    if(mutualService && mutualService.price_month) {
      costNumber=Number(mutualService.price_month)
    }
    let data_1 =mutualService && mutualService != null ? (mutualService.mus_id != 0 ? ("-" + priceFormat(costNumber)+"원") : '미적용') : '결합 할인';
    let dataNameService=mutualService && mutualService != null && mutualService.mus_id != 0 ? mutualService.mus_name : '';



    let dataSkt = data?.phone?.short_name
    let telcom = data?.telcomPotentialCustomer
    if(dataSkt) {
      dataSkt=dataSkt.toUpperCase()
    }
    if(telcom != null) {
      telcom=telcom.toUpperCase()
    }

    let data2G = data.conversion2G

    let showText2G="미적용"
    if(data2G != null) {
      if(data2G?.id != 0) {
        if(data.optionFee == 1) {
          if(data2G?.id ==2) {
            showText2G=data2G?.value
          }else {
            showText2G="공시지원금 할인"
          }
        }else {
          showText2G="70%할인"
        }
      }
    }

    return(
      <div className="right">
        <div className="form-group row" style={{position: 'relative'}}>
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금약정 </label>
          <div className="col-sm-4 col-form-label label-text" style={{position: 'absolute',top: 30, fontSize: '11px', color: '#2f8bff'}}>
            {dataSkt == "LGU" && +data.packageFee.discount_full === 1 ? (
              <div>
                <span>프리미어요금제</span><br />
                <span> 약정할인</span>
              </div>
            ) : ""}
          </div>
          <div className="col-sm-8">
            <button
              className={"btn btn-input-form mb-0" + (data.rateAgreement > 0 ? ' btn-prices-wp' : '')}
              disabled
            >
              {data.rateAgreement != 0 ? '- ' + priceFormat(data.rateAgreement)+"원" : 0 +" 원"}
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text" />

          <div className="col-sm-8">
            <ul className="list-month">
              <li className={data?.discostChange == 12 ? 'active' : ''}>
                <button className="btn" type="button">
                  {dataSkt == "LGU" ? "미적용" : "12개월"}
                </button>
              </li>
              <li  className={data?.discostChange == 24 ? 'active' : ''}>
                <button className="btn" type="button">24개월</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">프로모션 </label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0" + (promotion && promotion.planID ? ' btn-prices-wp' : '')} type="button">
              {promotion && promotion.planID ? ("-" + costPromotion + '원') : '미적용'}
            </button>
          </div>
        </div>
        {data.packageFee.plan_promotion_duration.map((promotion_duration, index) => (
          promotion_duration.promotion_duration_discount.map((duration_discount) => (
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text text-blue" >
                {index == 0 && dataSkt === 'LGU' && (data.packageFee.name?.includes('5G') || data.packageFee.name?.includes('LTE') || 
                    data.phone.device_name?.includes('5G') || data.phone.device_name?.includes('LTE')) ? (<React.Fragment>시그니처<br/>가족결합</React.Fragment>) : promotion_duration.promo_duration_name}
                {index > 0 && promotion_duration.promo_duration_name}
              </label>
              <div className="col-sm-8">
                {index == 0 && <button className={"btn btn-input-form mb-0" + (+data.promotionDurationDiscountOne === +duration_discount.discount_price ? ' btn-prices-wp' : '')} type="button">
                  {+data.promotionDurationDiscountOne === +duration_discount.discount_price ? priceFormat(duration_discount.discount_price) : '미적용'}
                </button>}
                {index > 0 && <button className={"btn btn-input-form mb-0" + (+data.promotionDurationDiscountOne === +duration_discount.discount_price ? ' btn-prices-wp' : '')} type="button">
                  {+data.promotionDurationDiscountOne === +duration_discount.discount_price ? priceFormat(duration_discount.discount_price) : '미적용'}
                </button>}
              </div>
            </div>
          ))
        ))}

        {dataSkt != null && (dataSkt == "SKT" || dataSkt == "LGU" || dataSkt == "KT") ?
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">
              {dataSkt == "SKT" && (<React.Fragment>0히어로<br/>할인제도</React.Fragment>)}
              {dataSkt == "KT" && (<React.Fragment>Y군인<br/>요금할인</React.Fragment>)}
              {dataSkt == "LGU" && (<React.Fragment>현역병사<br/>요금할인</React.Fragment>)}
            </label>
            <div className="col-sm-8">
              <button className={"btn btn-input-form mb-0" + (data.soldierDiscount != 0 ? ' btn-prices-wp' : '')} type="button" data-toggle="modal" data-target="#modal-18">
                {data.soldierDiscount != 0 ? '-' + priceFormat(data.soldierDiscount)+"원" : "미적용"}
              </button>
            </div>
          </div> : ""
        }

        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">결합 할인</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0" + (data.combinePlans.length > 0 ? ' btn-prices-wp' : '')} type="button" data-toggle="modal" data-target="#modal-18">
              {
                data.combinePlans.length > 0 && (
                  (
                    data.combinePlans[0].discount_wireless_price + +(data?.combinePlans[0]?.discount_tv || 0)
                  ) > 0 ? '-' : ""
                ) + priceFormat(roundUpNumber(data.combinePlans[0].discount_wireless_price) + +(data?.combinePlans[0]?.discount_tv || 0))
              }
              {data.combinePlans.length > 0 ? '원' : <span className='opacity-disabled'>미적용</span>}
            </button>
            {data.combinePlans.length > 0 && <p class="text-right mb-0 small-color">
             {data.combineCondition.name}
            </p>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">복지 할인</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0" + (welfare && welfare.cost ? ' btn-prices-wp' : '')} type="button">
              {welfare && welfare.cost ? ("-" + priceFormat(costWelfare)+"원") : "미적용"}
            </button>
            {textWelfare && <p class="small-color mb-0 pl-2 text-right">
              {textWelfare}
            </p>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">청구할인카드</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0" + (data.discountCard ? ((+data.discountCard > 0) ? ' btn-prices-wp' : '') : '')} type="button">
              {data.discountCard ? ((+data.discountCard > 0) ? '-'+priceFormat(data.discountCard) +'원' : '미적용') : '미적용'}
            </button>
            {data.discountCardObject?.dcc_name && <p class="small-color mb-0 pl-2">
              {data.discountCardObject?.dcc_name} / {data?.discountCardObject?.discount_card_option}
            </p>}
          </div>
        </div>
        {/*
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text pb-3">상조서비스</label>
          <div className="col-sm-8">
            <button className={"btn btn-input-form mb-0" + (costNumber > 0 ? ' btn-prices-wp' : '')} type="button">
              {data_1}
            </button>
            <p className="text-right mb-0 small-color text-right">{dataNameService}</p>
          </div>
        </div>


        {dataSkt == "SKT" || (dataSkt != null && dataSkt == "SKT") || (dataSkt != null && dataSkt == "LGU") ?
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">2G전환</label>
            <div className="col-sm-8">
              <button className="btn btn-input-form mb-0" type="button" data-toggle="modal" data-target="#modal-28 ">
                {showText2G}
              </button>
            </div>
          </div>
          : ""}
          */}




        <div className="form-group row">
          <div className="col-md-4">
            <p className="mb-0 label-prices">월 요금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue">
              {priceFormat(data?.monthlyFee)}
              <span className="small-color">원</span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Second;
