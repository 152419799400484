import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import renderTextField from '../FormHelper/TextField';
import validate from './validate';
import {showLoading, hideLoading} from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import {
    loginAction,
} from '../../store/actions/auth';
import * as routerName from '../../utils/routerName';
import { getFirstNumberPhone, getEmailDomains, getFirstFax } from '../../utils/helper';

class ContactForm extends Component {
    render() {
        const { handleSubmit, type } = this.props;
        return (
            <Fragment>
                <button type="button" className="btn btn-submit" onClick={this.props.handleSubmit}>문의하기</button>
                <button type="button" className="btn btn-gray" onClick={this.props.hideModal}>취소</button>
            </Fragment>
        );
    }
}

ContactForm.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        loginAction: (data) => dispatch(loginAction(data)),
        change: () => dispatch(change()),
    }
};
const FORM_NAME = 'ContactForm';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: false,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(ContactForm);
