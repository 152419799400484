import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
 
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
class InstallmentMonthModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberMonth: 24
        };

    }

    handleChange = (val) => {
        this.setState({
            numberMonth: val
        }, () => {
            this.props.setValuePay(this.state.numberMonth)

        })
    }



    chooseInstallmentMonth = (installmentMonth) => {
        const {WirelessAdvice} = this.props;
        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        this.props.change('WirelessAdviceStep3.installmentMonth', installmentMonth)

    }

    renderInstallmentMonth () {
        const {monthV} = this.props;
        const {WirelessAdvice} = this.props;
        let usedPhoneCompensation = WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation
        let telcomID = WirelessAdvice.WirelessAdviceStep1.telcomID
            
        let data = [48,36,30,24,18,12];
        switch(telcomID) {
            case 17: // skt
                data = [48,36,30,24,18,12,10,6];
                break;
            case 18: // lgu
                data = [48,36,30,24,18,12,10,9,6,3];
                break;
            case 19: // kt
                data = [48,36,30,24,18,12,6];
                break;
            case 20: // sk7
            case 21: // mmobile
            case 22: // u-mobi
            case 23: // hello kt
                data = [36, 30, 24, 18, 12]
                break;
            case 24: // hello lg
                data = [36, 30, 24]
                break;

            default:
                break;
        }

        let viewList = ''
        if( usedPhoneCompensation==null || usedPhoneCompensation.tup_id=='')
            return (data.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <li
                            className={(monthV&&monthV==item?'active':'') + " cursor" }
                            onClick={this.chooseInstallmentMonth.bind(this, item)}
                            data-dismiss="modal">
                            {item}개월
                        </li>
                    </Fragment>
                );
            }));
        else 
        {
            return (data.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <li
                            hidden={usedPhoneCompensation?.tup_inst_device_mo.includes(item.toString())?false:true}
                            className={(monthV&&monthV==item?'active':'') + " cursor" }
                            onClick={this.chooseInstallmentMonth.bind(this, item)}
                            data-dismiss="modal">
                            {item}개월
                        </li>
                    </Fragment>
                );
            }));
        }
           
    }
    render() {
        const {
            numberMonth
        } = this.state;

        return (
            <Fragment>
                {/* modal */}

                <div className="modal fade" id="modal-29"
                     // data-backdrop="static"
                     data-keyboard="false"
                     tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-additional modal-mont-installment" role="document">
                        <div className="modal-content pl-4 pr-4">
                            <div className="modal-header">
                                <h5 className="modal-title">할부개월</h5>
                                {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                    <img src="/images/icon-close.svg" alt="" />
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <ul className="list-additional">
                                  {this.renderInstallmentMonth()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* end modal */}
            </Fragment>

        );
    }
}


InstallmentMonthModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(InstallmentMonthModal);
