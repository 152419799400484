import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ConfirmPopup from "./ConfirmPopup";
import SignatureCanvas from 'react-signature-canvas'

class ConfirmReportModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      selected: null,
      isOpenPopup: false,
    }
  }

  hidePopup = () => {
    this.setState({ isOpenPopup: !this.state.isOpenPopup })
    this.props.hideReport();
  }

  openPopupConfirm = () =>
      this.setState({ isOpenPopup: !this.state.isOpenPopup })

  render() {
    return (
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-custom-18'}
        >
          <div className="">
            <div className="modal-body">
              <h2 className="text-16 text-center">
                판매현황 저장
              </h2>
              <div className="form-signs" style={{height: '132px'}}>
                <h4 className="label-text text-gray mb-0">
                  고객 서명
                </h4>
                <SignatureCanvas
                  penColor='green'
                  canvasProps={{
                    width: 413,
                    height: 132,
                    className: 'sigCanvas'
                  }}
                />
              </div>
              <p className="mt-8 text-10 mb-0 text-sliver">
                서비스 가입기간 동안 이용하고 고객관계관리 및 고지사항 전달을 위해 약정기간동안 고객정보를 보유합니다.
              </p>
              <div className="modal-footer justify-content-center border-top-0">
                <button type="button" className="btn btn-gray" onClick={this.props.openPopup}>취소</button>
                <button type="button" className="btn btn-submit" onClick={this.openPopupConfirm}>저장
                </button>
              </div>
            </div>
          </div>
          <ConfirmPopup
              openPopup={this.openPopupConfirm}
              isOpenModal={this.state.isOpenPopup}
              hide={this.hidePopup}
          />
        </Modal>
    );
  }
}


export default ConfirmReportModal;
