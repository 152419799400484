import MessageJSON from "../../../utils/message.json";

const validate = values => {
    const errors = {};
    if (!values.username) {
        errors.username = MessageJSON.forgot_pw.validate.username_required;
    } else if (values.username.trim() && values.username.length < 4) {
        errors.username = MessageJSON.forgot_pw.validate.username_min;
    } else if (values.username.trim() && values.username.length > 10) {
        errors.username =  MessageJSON.forgot_pw.validate.username_max;
    }

    return errors;
};

export default validate;
