import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import DiscountConditionModal from "./DiscountConditionModal";
import {ADVICE_TYPE, TELCOM_ID} from "../../../utils/constant";
import {priceFormat, roundUpNumber} from '../../../utils/helper';
import _ from "lodash";
class CombinedDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
        }
    }
    
    render() {
        const {WirelessAdvice: { WirelessAdviceStep3,WirelessAdviceStep1 }} = this.props;
        let check = false;
        if (WirelessAdviceStep3.combinePlans.length > 0) {
            if (WirelessAdviceStep3.combinePlans[0].discount_wireless_price + +(WirelessAdviceStep3?.combinePlans[0]?.discount_tv || 0) == 0) {
                check = true;
            }else{
                check = false;
            }
        }
        let dataTelcom = WirelessAdviceStep3?.phone?.short_name
        if(dataTelcom) {
            dataTelcom=dataTelcom.toUpperCase()
        }
        let telcom_id=WirelessAdviceStep1.telcomID
        return (
            <div className="form-group row align-items-start">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mt-13">결합 할인</label>
                <div className="col-sm-8">
                    <button
                        disabled={(telcom_id == TELCOM_ID.SKT || telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU)
                            && !_.includes([3, 7, 8, 10, 11, 12], (+WirelessAdviceStep3.phone?.matchID  || 0))  /* task 7017:
                             Đối với giảm giá kết hợp thì chỉ mobile mới có thể kết hợp thôi, nên cần chỉnh sửa lại để các loại thiết bị khoanh đỏ trong image2 không thể chọn giảm giá kết hợp.
                             => Yêu cầu khi chọn thiết bị được match 5G tablet, tablet ,wearable, wifi, pet, iot thì chỉnh sửa để không kích hoạt giảm giá kết hợp.
                             Text: "선택할 수 있는 결합상품이 없습니다." (Không có sp kết hợp có thể chọn)*/

                            ? false : true}
                        className={`btn btn-input-form mb-0 ${WirelessAdviceStep3.combinePlans.length > 0 && !check  ? 'btn-prices-wp' : ''}`}
                        type="button"
                        onClick={this.props.handleShowModalCombineDiscount}
                    >
                        {
                            WirelessAdviceStep3.combinePlans.length > 0
                                && (
                                    (WirelessAdviceStep3.combinePlans[0].discount_wireless_price + +(WirelessAdviceStep3?.combinePlans[0]?.discount_tv || 0)) > 0 ? '-' : "")
                                + priceFormat(roundUpNumber(WirelessAdviceStep3.combinePlans[0].discount_wireless_price)
                                + +(WirelessAdviceStep3?.combinePlans[0]?.discount_tv || 0))
                        }
                        {WirelessAdviceStep3.combinePlans.length > 0 ? '원' : <span className='opacity-disabled'>미적용</span>}

                    </button>
                    {WirelessAdviceStep3.combineCondition && (
                        <p className="text-right mb-0 small-color">
                            {WirelessAdviceStep3.combineCondition.name}
                        </p>
                    )}
                    {telcom_id == TELCOM_ID.SKT || telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU
                        ? _.includes([3, 7, 8, 10, 11, 12], (+WirelessAdviceStep3.phone?.matchID  || 0))
                            ? ( <p className="text-right mb-0 small-color" style={{color:'#696969'}}> 선택할 수 있는 결합상품이 없습니다.</p>)
                            : ""
                        : <p className="text-right mb-0 small-color" style={{color:'#696969'}}> 선택할 수 있는 결합상품이 없습니다.  </p>}

                </div>
            </div>
        );
    }
}

CombinedDiscount.propTypes = {
    handleSubmit: PropTypes.func,
    WirelessAdvice: PropTypes.object,
    handleShowModalCombineDiscount: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};
const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CombinedDiscount);
