import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router-dom";
import ConfirmPopup from "../Wireless/ReportSale/ConfirmPopup";
import ResetModal from "../Wireless/Reset/ResetModal";

class CreateReportSaleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            selected: null,
        }
    }

    handleSubmit = () => {
        this.props.history.push("/create-report-sale");
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpenModal}
                toggle={this.props.openPopup}
                fade={true}
                // backdrop={"static"}
                className={'modal-dialog modal-dialog-centered'}
                onOpened={this.modalOpened}
            >
                <div className="modal-content">
                    <div className="modal-body text-center p-5">
                        <span className="notification_delete">SM G981N 128G 상담건을 판매일보에 등록 하시겠습니까?</span>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0">
                        <button type="button" className="btn btn_close" onClick={this.props.openPopup}>취소</button>
                        <button type="button" className="btn btn-submit pr-5 pl-5"
                                onClick={this.handleSubmit}>확인
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(CreateReportSaleModal);
