import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ForgotIdStep1Form from './ForgotIdComponent/ForgotIdStep1Form';
import ForgotIdStep2Form from './ForgotIdComponent/ForgotIdStep2Form';
import * as routerName from '../../utils/routerName';
import api from "./../../utils/api";
import { showModal, hideModal } from '../../store/actions/modal';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change } from 'redux-form';
import { getFirstNumberPhone } from '../../utils/helper';
class ForgotId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            users: [],
            fieldVerify: '전화 인증',
            userChecked: {}
        }
    }

    redirectLogin = () => {
        this.props.hideModal();
        this.props.history.push({ pathname: '/' + routerName.SIGN_IN });
    }


    onChangTab = (data) =>  {
        this.props.change('forgot_type', data);
    }

    componentDidMount() {

    }

    changeFieldVerify = () => {
        if (this.props.ForgotId.forgot_type == 1) {
            this.setState({
                fieldVerify: '휴대폰번호'
            })
        } else if (this.props.ForgotId.forgot_type == 2) {
            this.setState({
                fieldVerify: '이메일 인증'
            })
        }
    }

    nextPage = () => {
        
        let data = {};
        if(this.props.ForgotId.forgot_type == 1) {
            this.props.showLoading();
            let data = {
                phone: this.props.ForgotId.first_phone + '-' + this.props.ForgotId.last_phone,
            }
            api.auth.getForgotId(data).then(res => {
                if (res.data.data.users.length > 0) {
                    this.setState({
                        page: this.state.page + 1,
                        users: res.data.data.users,
                        userChecked: res.data.data.users[0]
                    })
                    this.props.hideLoading();
                } else {
                    this.popupShowMessageNotExitsId();
                    this.props.hideLoading();
                }
            }).catch(err => {
                this.popupShowMessageNotExitsId();
                this.props.hideLoading();
            })
        } else if(this.props.ForgotId.forgot_type == 2) {
            this.props.showLoading();
            let data = {
                email: this.props.ForgotId.email,
            }
            api.auth.getForgotId(data).then(res => {
                if (res.data.data.users.length > 0) {
                    this.setState({
                        page: this.state.page + 1,
                        users: res.data.data.users,
                        userChecked: res.data.data.users[0]
                    })
                    this.props.hideLoading();
                } else {
                    this.popupShowMessageNotExitsId();
                    this.props.hideLoading();
                }
            }).catch(err => {
                this.popupShowMessageNotExitsId();
                this.props.hideLoading();
            })
        }
        
    }

    previousPage = () => {
        this.setState({ page: this.state.page - 1 })
    }

    setPage = (page) => {
        if (this.props.ForgotIdStep1.form1 == true && this.props.ForgotIdStep1.form2 == true) {
            this.setState({ page: page })
        } else if (this.props.ForgotIdStep1.form1 == true && (page == 1 || page == 2)) {
            this.setState({ page: page })
        }
    }


    popupShowMessageNotExitsId = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    죄송합니다.
                </h4>
                <p className="text-center mt-3">
                    입력하신 정보와 일치하는 아이디가 없습니다.
                </p>
                <p className="text-center">
                    정보 확인 후, 다시 시도해 주세요.
                </p>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>다른 방법으로 아이디 찾기
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }

    popupShowMessageSendMailError = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center">
                    죄송합니다.
                </h4>
                <p className="text-center mt-3">
                    시스템 오류 나중에 다시 시도하십시오
                </p>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>다시 시도</button>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }

    popupShowMessageSendForgotPwSuccess = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="modal-body">
                    <div className="img-check">
                        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/Icon-modal-01.svg"} alt="" />
                    </div>
                    <h4 className="text-center">
                        전송되었습니다.
                    </h4>
                    {this.props.ForgotId.forgot_type == 1 ? (
                        <p className="text-center">
                            비밀번호가 <span>*** **** - {this.state.user.phone.slice(this.state.user.phone.length - 4, this.state.user.phone.length)}</span> (으)로 전송되었습니다.<br />
                            확인 바랍니다.
                        </p>
                    ) : (
                            <p className="text-center">
                                비밀번호가 <span>{this.state.user.email.slice(0, 2)}***@gmail.com</span> (으)로 전송되었습니다.<br />
                            확인 바랍니다.
                            </p>
                        )}

                    {/* onClick={() => this.redirectLogin()} */}
                    <button type="button" className="btn btn-secondary btn-ok" onClick={() => this.redirectLogin()}>로그인</button>
                </div>
            </div>
        );
        let dataModal = {
            component: xhtml
        }

        this.props.showModal(dataModal);
    }



    onSubmit = (data) => {

        if (data.forgot_type == 1 && this.state.user.phone == data.verify) {
            this.props.showLoading();
            let dataRequest = {
                phone: data.verify
            }
            api.auth.findPasswordPhone(dataRequest).then(res => {
                this.props.hideLoading();
                this.popupShowMessageSendForgotPwSuccess()
            }).catch(err => {
                this.popupShowMessageSendMailError();
                this.props.hideLoading();
            })
            // send phone chưa xử lý
        } else if (data.forgot_type == 2 && this.state.user.email == data.verify) {
            this.props.showLoading();
            let dataRequest = {
                email: data.verify
            }
            api.auth.findPasswordEmail(dataRequest).then(res => {
                this.props.hideLoading();
                this.popupShowMessageSendForgotPwSuccess()
            }).catch(err => {
                this.popupShowMessageSendMailError();
                this.props.hideLoading();
            })
        }
    }

    onChangeChecked = (object) => {
        this.setState({
            userChecked:object
        })
    }


    renderForgotIdComponent() {
        let xhtml = null;
        if (this.state.page == 1) {
            xhtml = <ForgotIdStep1Form
                history={this.props.history}
                onSubmit={this.nextPage}
                fieldVerify={this.state.fieldVerify}
                onChangTab={this.onChangTab}
            />;
        } else if (this.state.page == 2) {
            xhtml = <ForgotIdStep2Form
                history={this.props.history}
                previousPage={this.previousPage}
                fieldVerify={this.state.fieldVerify}
                onSubmit={this.onSubmit}
                changeFieldVerify={this.changeFieldVerify}
                users={this.state.users}
                userChecked={this.state.userChecked}
                onChangeChecked={this.onChangeChecked}
            />;
        }

        return xhtml;
    }

    render() {
        return (
            <Fragment>
                <section className="form-search-id">
                    <div className="container">
                        <h1 className="title-page mb-2">아이디 찾기</h1>
                        
                        {this.renderForgotIdComponent()}
                    </div>
                </section>


            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        ForgotId: getFormValues('ForgotId')(state),

    };
};
const mapDispatchToProps = dispatch => {
    return {
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};



const FORM_NAME = 'ForgotId';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        'last_phone': '',
        'first_phone': '',
        'forgot_type': '',  
        'email': ''
    },
    enableReinitialize: true
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotId);

