import React, {Component, Fragment} from 'react';
import {Modal} from 'reactstrap';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import DiscountConditionModal from "./DiscountConditionModal";
import ImageSliderModal from "./ImageSliderModal";
import WireProductModal from "./WireProductModal";
import {hideModal, showModal} from "../../../store/actions/modal";
import PlanInformationModal from "../PackageFee/PlanInformationModal";
import {ADVICE_TYPE} from '../../../utils/constant';
import {priceFormat, roundUpNumber} from "../../../utils/helper";
import _ from 'lodash';
import DiscountIndicated from "./OptionsModals/DiscountIndicated";
import TeenagerPromotion from "./OptionsModals/TeenagersPromotion";
import TVPlus from "./OptionsModals/TVPlus";
import NanumDiscount from "./OptionsModals/NanumDiscount";
import api from "../../../utils/api";
import SubsriptionYears from "./OptionsModals/SubsriptionYears";
import KTNumberLineWireCombine from "./OptionsModals/KTNumberLineWireCombine";
import LGUPatron from "./OptionsModals/LGUPatron";
import KTPremiumDiscount2 from "./OptionsModals/KTPremiumDiscount2";
import UTV from "./OptionsModals/UTV";
import {compose} from "redux";
import {WIRELESS_ADVICE} from "../../../utils/routerName";
import KTTeenagers from './OptionsModals/KTTeenagers';

class CombineDiscountModal extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            error_modal: {
                isShowModal: false,
                message: ""
            },
            TVPlusCombineCondition: [1, 2, 3, 16], // id m?u đi?u ki?n co option TVPlus
            onlyWirelessCombineCondition: [4, 5, 7, 11, 12, 17],
            telcomID: this.props.adviceType == ADVICE_TYPE.WIRELESS ? this.props.WirelessAdvice.WirelessAdviceStep1.telcomID : this.props.WiredAdvice.WiredAdviceStep1.telcomID,
            deviceID: this.props.adviceType == ADVICE_TYPE.WIRELESS ? this.props.WirelessAdvice.WirelessAdviceStep3.phone.deviceID : null,
            discount_condition: {
                isShowModal: false,
                list: [],
                discount_condition_selected: this.props.discountCondition ? this.props.discountCondition : null,
            },
            images_slider_modal: {
                isShowModal: false,
            },
            wire_product: {
                isShowModal: false,
                wireDiscount: [],
                wireDevices: this.props.wireProduct && this.props.wireProduct.wireDevices ? this.props.wireProduct.wireDevices : [], // danh sach s?n ph?m co day
                internetWireDevices: this.props.wireProduct && this.props.wireProduct.internetWireDevices ? this.props.wireProduct.internetWireDevices : [], // danh sach s?n ph?m co day thuoc loai internet
                classifyString: "",
                dataWireDeviceByCombineID: [],// danh sach s?n ph?m co day c?a 1 m?u đi?u ki?n
                selected: {
                    wire_device: this.props.wireProduct && this.props.wireProduct.selected && this.props.wireProduct.selected.wire_device ? this.props.wireProduct.selected.wire_device : null,
                    option: this.props.wireProduct && this.props.wireProduct.selected && this.props.wireProduct.selected.option ? this.props.wireProduct.selected.option : null,
                },
                dataWireDevice: []// danh sach s?n ph?m co day c?a t? v?n khong day
            },
            line_discount_matched: null,
            plan_modal: {
                isShowModal: false,
                openPlanIdx: null,
                plan_selected: null,
                discount_line_cnt: 0,
                line_can_select_cnt: 0,
                line_discount_matched: null,
                plans: this.props.planModals && this.props.planModals.plans ? this.props.planModals.plans : {},
            },
            options_modal: {
                discount_indicated: {
                    selected: null
                },
                tv_plus: {
                    selected: {
                        type: null,
                        line: null
                    }
                },
                sub_years: {
                    selected: null
                },
                kt_number_line_wire_combine: {
                    selected: null
                },
                lgu_patron: {
                    selected: null
                },
                u_tv: {
                    selected: false
                },
                over_2_lines_combine: {
                    selected: false
                },
                tv_standard: {
                    selected: false
                },
                kt_premium_discount: {
                    selected: null
                },
                kt_premium_discount_except: {
                    selected: null
                },
                teenagers_promotion: {
                    selected: []
                },
                nanum_discount : {
                    selected: []
                },
                kt_teenagers_promotion: {
                    selected: []
                }
            },
            dataApplied: null, // d? li?u tr? v? sau khi apply combine,
            wire_product_together: [
                308, 309, 310, 311, 312, //와이파이기본_기가슬림안심 500MB, 스마트 기가슬림안심 500M, 와이파이기본_기가안심 1GB, 스마트 기가안심 1G, 와이파이기본_2.5기가안심 요금제
                313, 314, 351, 353, 355, // 와이파이기본_5기가 요금제, 와이파이기본_10기가 요금제, 스마트기가 최대 500M, 스마트기가 최대 1G, 스마트기가 최대 2.5G
                392, 393 // 프리미엄 안심 500M, 프리미엄 안심 1기가
            ], // danh sach s?n ph?m internet ap d?ng đi?u ki?n gi?m gia together
            plan_together: [ // danh sach goi phi ap d?ng đi?u ki?n gi?m gia together
                3634, 3669, 3671, 2684, 3667, // 5G 시그니처, 5G 시그니처(넷플릭스), 5G 시그니처 (유튜브 프리미엄), 5G 프리미어 슈퍼, 5G 프리미어 슈퍼(넷플릭스)
                3670, 2683, 3666, 3668, 2682, // 5G 프리미어 슈퍼(유튜브 프리미엄), 5G 프리미어 플러스, 5G 프리미어 플러스(넷플릭스), 5G 프리미어 플러스 (유튜브 프리미엄), 5G 프리미어 레귤러
                3633, 3631, 3701, 3630, 3641, // 5G 프리미어 에센셜, LTE 프리미어 플러스, LTE 프리미어 플러스 (유튜브 프리미엄), LTE 프리미어 에센셜, 5G 슈퍼 플래티넘 (신규가입불가)
                3640, 3639, 3760, 3761, 3759, // 5G 플래티넘 (신규가입불가), 5G 프리미엄 (신규가입불가), 5G 프리미어 레귤러 (디즈니+), 5G 프리미어 플러스 (디즈니+), 5G 프리미어 슈퍼 (디즈니+)
                3758, 3800, 3802, 3801, 3803, // 5G 시그니처 (디즈니+), 5G 프리미어 플러스 (헬로렌탈구독), 5G 프리미어 슈퍼 (헬로렌탈구독), 5G 시그니처 (헬로렌탈구독), 5G 프리미어 플러스 (일리커피구독)
                3804, 3805, 3820, 3819, 3817, // 5G 프리미어 슈퍼 (일리커피구독), 5G 시그니처 (일리커피구독), 5G 프리미어 레귤러 (티빙), 5G 프리미어 플러스 (티빙), 5G 프리미어 슈퍼 (티빙)
                3818, 3834, 3835, 3839, 3841, // 5G 시그니처 (티빙), 5G 프리미어 슈퍼 (넷플릭스+유튜브 프리미엄), 5G 시그니처 (넷플릭스+유튜브 프리미엄), 5G 프리미어 플러스 (갤럭시버즈2프로), 5G 시그니처 (갤럭시버즈2프로)
                3837, 3838, 3842, 3843, 3844, // 5G 프리미어 슈퍼 (갤럭시워치5), 5G 시그니처 (갤럭시워치5), 5G 프리미어 플러스 (갤럭시버즈), 5G 프리미어 슈퍼 (갤럭시버즈), 5G 시그니처 (갤럭시버즈)
                3762, 3816, 3807, 3806, 3737, // LTE 프리미어 플러스 (디즈니+), LTE 프리미어 플러스 (티빙), LTE 프리미어 플러스 (헬로렌탈구독), LTE 프리미어 플러스 (일리커피구독), 5G 프리미어 플러스 (에어팟) (신규가입불가)
                3738, 3739, 3894, 3895, 3840,  // 5G 프리미어 슈퍼 (에어팟) (신규가입불가), 5G 시그니처 (에어팟) (신규가입불가), 5G 시그니처 (애플워치SE2), 5G 프리미어 슈퍼 (애플워치SE2), 5G 프리미어 슈퍼 (갤럭시버즈2프로)
                3926, 3927 // 5G 프리미어 에센셜 청소년, LTE 프리미어 에센셜 청소년
            ],
            /* 업무번호 3593 KT 총액결합 2회선 추가
            check_case: null, //결합할인 관련인지 확인
            check_equal_case: null, //총액결합할인이 중복 선택되었는지 확인
            check_kt_premium: null //KT 프리미엄 결합할인이 체크되었는지 확인
            */
        };
    }

    makeNewPlans = () => { //무선상담 진입 시 호출
        //console.log("Combine의 makeNewPlans")
        let plans = [];
        for (let i = 1; i <= 5; i++) {
            plans.push({
                idx: i,
                plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                discount_wireless_price: 0,
                discount_condition_name: [],
                is_commitment: false,
                disabled: true
            })
        }
        return plans;
    }


    componentDidMount = async () => {
        let telcomID = this.props.adviceType == ADVICE_TYPE.WIRELESS
            ? this.props.WirelessAdvice.WirelessAdviceStep1.telcomID
            : this.props.WiredAdvice.WiredAdviceStep1.telcomID;
        let plans = this.makeNewPlans();

        let data = {
            filter_type: this.props.adviceType,
            filter_telcomID: telcomID
        }
        if (this.props.adviceType == ADVICE_TYPE.WIRE) {
            data.filter_state = 1
        } else if (this.props.adviceType == ADVICE_TYPE.WIRELESS) {
            data.filter_wireless_state = 1
        }
        let combinesCondition = await api.advice.getListCombineCondition(data)

        let listCombineConditions = combinesCondition.data.data;
        for (let listCombineCondition of listCombineConditions) {
            listCombineCondition.plans = plans;
        }

        this.setState({
            telcomID,
            discount_condition: {
                ...this.state.discount_condition,
                list: listCombineConditions,
            },
            plan_modal: {
                ...this.state.plan_modal,
                plans
            }
        });
    }


    componentDidUpdate = async (prevProps, prevState) =>  {


        if(this.props.adviceType == ADVICE_TYPE.WIRE){
            if (prevProps.WiredAdvice?.WiredAdviceStep1?.telcomID !== this.props.WiredAdvice?.WiredAdviceStep1?.telcomID || this.props.WiredAdvice?.WiredAdviceStep1?.telcomID !== this.state.telcomID) {
                let data = {
                    filter_type: this.props.adviceType,
                    filter_telcomID: this.props.WiredAdvice?.WiredAdviceStep1.telcomID,
                    filter_state : 1
                }

                let response = await api.advice.getListCombineCondition(data)
                let combineConditions = response.data.data;
                for (let combineCondition of combineConditions) {
                    combineCondition['plans'] = this.makeNewPlans();
                }

                this.setState({
                    telcomID: this.props.WiredAdvice?.WiredAdviceStep1.telcomID,
                    discount_condition: {
                        ...this.state.discount_condition,
                        list: combineConditions
                    },
                })
            }

            if (prevProps.WiredAdvice.id !== this.props.WiredAdvice.id && !this.props.WiredAdvice.id) { // m?i khi reset form t? v?n
                //console.log("Component 업데이트의 첫번쨰 if")
                let plans = [];
                for (let i = 1; i <= 5; i++) {
                    plans.push({
                        idx: i,
                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                        discount_wireless_price: 0,
                        discount_condition_name: [],
                        is_commitment: false,
                        disabled: true
                    })
                }
                this.setState({
                    dataApplied: null,
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: null
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        wireDiscount: [],
                        selected: {
                            wire_device: null,
                            option: null,
                        }
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected:  null
                        },
                        tv_plus: {
                            selected: {
                                type:  null,
                                line:  null
                            }
                        },
                        kt_number_line_wire_combine: {
                            selected:  null
                        },
                        sub_years: {
                            selected:  null
                        },
                        lgu_patron: {
                            selected:  null
                        },
                        u_tv: {
                            selected:  false
                        },
                        over_2_lines_combine: {
                            selected:  false
                        },
                        tv_standard: {
                            selected: false
                        },
                        kt_premium_discount: {
                            selected:  null
                        },
                        kt_premium_discount_except: {
                            selected: null
                        },
                        teenagers_promotion: {
                            selected:  []
                        },
                        nanum_discount: {
                            selected:  []
                        },
                        kt_teenagers_promotion: {
                            selected: []
                        }
                    }
                }, async () => {
                    await this.onSelectDiscountCondition(null)
                    await this.getDiscountWireLessPrice();
                    await this.getWireDiscount();
                    await this.onApplyCombineDiscount();
                })
            }


            if(this.state.discount_condition.discount_condition_selected && this.props.WiredAdvice?.WiredAdviceStep2?.isCombined){// n?u co ch?n đi?u ki?n gi?m gia k?t h?p

                // n?u thay đ?i cac s?n ph?m co day ? form t? v?n co day step 2 => update l?i gia ti?n k?t h?p
                let wireDevices = this.props.WiredAdvice.WiredAdviceStep2.classify;
                let classifyStringProps =  this.props.WiredAdvice.WiredAdviceStep2?.classifyString || "";
                let internetWireDevices = [];
                if (wireDevices[0].classify.length > 0) {
                    _.map(wireDevices[0].classify, (value) => {
                        if (!_.isEmpty(value.device)) internetWireDevices.push(value);
                    });
                }

                if (classifyStringProps !== prevProps.WiredAdvice.WiredAdviceStep2?.classifyString) { // n?u co thay đ?i s?n ph?m co day
                    if (!_.isEmpty(this.props.WiredAdvice.WiredAdviceStep2.classify[0]?.classify[0]?.device)) {
                        let options_modal = this.state.options_modal;
                        let hasTVDevice = _.filter(this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify, (value, i) => {
                            return !_.isEmpty(value.device)
                        }).length > 0 ? true : false;
                        let combineCondition = this.state.discount_condition.discount_condition_selected;
                        let plans = this.state.plan_modal.plans;
                        if(hasTVDevice){ // n?u co s?n ph?m tivi
                            if(combineCondition && [1, 2, 3].includes(+combineCondition.id) && plans.length > 0 && !_.isEmpty(plans[0].plan)){ // n?u co s?n ph?m TV, t? đ?ng apply vao line 1
                                /*options_modal = {
                                    ...options_modal,
                                    tv_plus : {
                                        selected: !_.isEmpty(this.state.options_modal.tv_plus.selected) && this.state.options_modal.tv_plus.selected.type ? this.state.options_modal.tv_plus.selected : { // n?u khong ch?n cai nao thi t? đ?ng active TV plus
                                            type: {
                                                id: 1,
                                                type: "OPTION",
                                                code: "TVPLUS",
                                                name: "TV플러스(신규)",
                                                short_name:  "TV플러스(신규)",
                                                show_name:"결합 신규 가입자",
                                                description: "무선 1,100원 / TV 1,100원 할인",
                                                price: 1100
                                            },
                                            line: this.state.plan_modal.plans[0]
                                        }
                                    },
                                }*/
                            }
                        }else{
                            options_modal = {
                                ...options_modal,
                                tv_plus : {
                                    selected: {
                                        type: null,
                                        line: null
                                    }
                                }
                            }
                        }

                        this.setState({
                            wire_product: {
                                ...this.state.wire_product,
                                internetWireDevices,
                                classifyString: classifyStringProps,
                                selected: {
                                    wire_device: this.props.WiredAdvice.WiredAdviceStep2.classify[0].classify[0].device,
                                    option: this.props.WiredAdvice.WiredAdviceStep2.classify[0].classify[0].options[0],
                                }
                            },
                            options_modal: options_modal
                        }, async () => {
                            await this.onSelectDiscountCondition(_.find(this.state.discount_condition.list, c => c.id == this.state.discount_condition.discount_condition_selected.id))
                            await this.getDiscountWireLessPrice();
                            await this.getWireDiscount();
                            await this.onApplyCombineDiscount();
                        })
                    } else { // n?u s?n ph?m co day null => reset data
                        let plans = this.state.plan_modal.plans;
                        _.map(plans, (plan, i) => {
                            plan.discount_wireless_price = 0;
                            plan.discount_condition_name = [];
                            plan.plan = null;
                            plan.disabled = true;
                        });
                        this.setState({
                            discount_condition: {
                                ...this.state.discount_condition,
                                discount_condition_selected: null,
                            },
                            plan_modal: {
                                ...this.state.plan_modal,
                                discount_line_cnt: 0,
                                line_can_select_cnt: 0,
                                plans
                            },
                            wire_product: {
                                ...this.state.wire_product,
                                wireDiscount: [],
                                selected: {
                                    wire_device: null,
                                    option: null,
                                }
                            },
                            options_modal: {
                                ...this.state.options_modal,
                                discount_indicated: {
                                    selected: null
                                },
                                tv_plus: {
                                    selected: {
                                        type: null,
                                        line: null
                                    }
                                },
                                sub_years: {
                                    selected: null
                                },
                                lgu_patron: {
                                    selected: null
                                },
                                u_tv: {
                                    selected: false
                                },
                                over_2_lines_combine: {
                                    selected: false
                                },
                                tv_standard: {
                                    selected: false
                                },
                                kt_premium_discount: {
                                    selected: null
                                },
                                kt_premium_discount_except: {
                                    selected: null
                                },
                                teenagers_promotion: {
                                    selected: []
                                },
                                nanum_discount: {
                                    selected: []
                                },
                                kt_teenagers_promotion: {
                                    selected: []
                                }
                            }
                        }, async () => {
                            await this.getDiscountWireLessPrice();
                            await this.getWireDiscount();
                            await this.onApplyCombineDiscount();
                        });
                    }
                }
            }
        } else {
            if (prevProps.WirelessAdvice?.WirelessAdviceStep1?.telcomID !== this.props.WirelessAdvice?.WirelessAdviceStep1?.telcomID
                || this.props.WirelessAdvice?.WirelessAdviceStep1?.telcomID !== this.state.telcomID) {
                    //console.log("DIdupdate의 ADVICE_TYPE.WIRE 가 아닐때")
                let plans = [];
                for (let i = 1; i <= 5; i++) { //초반 생성 회선 수 증가
                    plans.push({
                        idx: i,
                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                        discount_wireless_price: 0,
                        discount_condition_name: [],
                        is_commitment: false,
                        disabled: true
                    })
                }
                let data = {
                    filter_type: this.props.adviceType,
                    filter_telcomID: this.props.WirelessAdvice?.WirelessAdviceStep1?.telcomID,
                    filter_wireless_state : 1
                }

                let response = await api.advice.getListCombineCondition(data)
                let combineConditions = response.data.data;
                for (let combineCondition of combineConditions) {
                    combineCondition['plans'] = plans;
                }

                this.setState({
                    telcomID: this.props.WirelessAdvice?.WirelessAdviceStep1.telcomID,
                    deviceID: this.props.WirelessAdvice?.WirelessAdviceStep3.phone.deviceID,
                    dataApplied: null,
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: null,
                        list: combineConditions
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        wireDiscount: [],
                        selected: {
                            wire_device: null,
                            option: null,
                        }
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected:  null
                        },
                        tv_plus: {
                            selected: {
                                type:  null,
                                line:  null
                            }
                        },
                        kt_number_line_wire_combine: {
                            selected:  null
                        },
                        sub_years: {
                            selected:  null
                        },
                        lgu_patron: {
                            selected:  null
                        },
                        u_tv: {
                            selected:  false
                        },
                        over_2_lines_combine: {
                            selected:  false
                        },
                        tv_standard: {
                            selected: false
                        },
                        kt_premium_discount: {
                            selected:  null
                        },
                        kt_premium_discount_except: {
                            selected: null
                        },
                        teenagers_promotion: {
                            selected:  []
                        },
                        nanum_discount: {
                            selected:  []
                        },
                        kt_teenagers_promotion: {
                            selected: []
                        }
                    }
                }, async () => {
                    await this.onSelectDiscountCondition(null)
                    await this.updateDiscountWireLessPrice();
                    await this.getWireDiscount();
                    await this.onApplyCombineDiscount();
                })
            }
            if (prevProps.WirelessAdvice?.id !== this.props.WirelessAdvice?.id && !this.props.WirelessAdvice?.id && +this.props.WirelessAdvice?.step == 3) { // m?i khi reset form t? v?n
                //console.log("컴포넌트 업데이트 마지막 부분")
                let plans = [];
                for (let i = 1; i <= 5; i++) {
                    plans.push({
                        idx: i,
                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                        discount_wireless_price: 0,
                        discount_condition_name: [],
                        is_commitment: false,
                        disabled: true
                    })
                }
                this.setState({
                    telcomID: this.props.WirelessAdvice?.WirelessAdviceStep1.telcomID,
                    dataApplied: null,
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: null
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        wireDiscount: [],
                        selected: {
                            wire_device: null,
                            option: null,
                        }
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected:  null
                        },
                        tv_plus: {
                            selected: {
                                type:  null,
                                line:  null
                            }
                        },
                        kt_number_line_wire_combine: {
                            selected:  null
                        },
                        sub_years: {
                            selected:  null
                        },
                        lgu_patron: {
                            selected:  null
                        },
                        u_tv: {
                            selected:  false
                        },
                        over_2_lines_combine: {
                            selected:  false
                        },
                        tv_standard: {
                            selected: false
                        },
                        kt_premium_discount: {
                            selected:  null
                        },
                        kt_premium_discount_except: {
                            selected: null
                        },
                        teenagers_promotion: {
                            selected:  []
                        },
                        nanum_discount: {
                            selected:  []
                        },
                        kt_teenagers_promotion: {
                            selected: []
                        }
                    }
                }, async () => {
                    await this.onSelectDiscountCondition(null)
                    await this.updateDiscountWireLessPrice();
                    await this.getWireDiscount();
                    await this.onApplyCombineDiscount();
                })
            }

            if (prevProps.WirelessAdvice.WirelessAdviceStep3.optionFee !== this.props.WirelessAdvice.WirelessAdviceStep3.optionFee && this.state.plan_modal.plans.length > 0) { // n?u co ch?n goi phi va thay đ?i thanh cam k?t l?a ch?n hay h? tr? thong bao thi update l?i
                let plans = this.state.plan_modal.plans;
                _.map(plans, (plan, i) => {
                    plan.is_commitment = i == 0 ? (this.props.adviceType == ADVICE_TYPE.WIRELESS && !this.props?.WirelessAdvice?.WirelessAdviceStep3?.optionFee ? true : false) : !!plan.is_commitment
                });
                this.setState({
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: plans
                    }
                }, async () => {
                    await this.updateDiscountWireLessPrice();
                    await this.getWireDiscount();
                    await this.onApplyCombineDiscount();
                })
            }

            if (this.props.WirelessAdvice.WirelessAdviceStep3.packageFee && JSON.stringify(prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee) !== JSON.stringify(this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee)) { // n?u thay đ?i goi phi
                let new_plan = {};
                if ("id" in this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee) {
                    new_plan = {
                        ...this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee,
                        class: this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plan_classification?.class || this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee.class
                    };
                } else if ("plac_id" in this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee && this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee?.plac_id) {
                    new_plan = {
                        ...this.props.WirelessAdvice?.WirelessAdviceStep3?.planData,
                        class: this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee?.class,
                        name: this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.name ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.name : this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.plac_name,
                        planCustom: this.props.WirelessAdvice?.WirelessAdviceStep3?.packageFee,
                        cost_display: this.props.WirelessAdvice.WirelessAdviceStep3.basicCost ? (+this.props.WirelessAdvice.WirelessAdviceStep3.basicCost * 10 / 11) : 0
                    }
                }
                var oldPlansArr = this.state.plan_modal.plans;
                oldPlansArr.length > 0 && oldPlansArr.splice(0, 1, {...oldPlansArr[0], plan: new_plan});



                this.setState({
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: oldPlansArr
                    }
                }, async () => {
                    if(new_plan  && !this.state.plan_together.includes(+new_plan.id) && [17, 18].includes(this.state.discount_condition.discount_condition_selected?.id)){
                        _.map(oldPlansArr, (plan, i) => {
                            if(i !== 0) plan.plan = null;
                            plan.discount_wireless_price = 0;
                            plan.discount_condition_name = [];
                            plan.disabled = true;
                        });
                        this.setState({
                            discount_condition: {
                                ...this.state.discount_condition,
                                discount_condition_selected: null,
                            },
                            plan_modal: {
                                ...this.state.plan_modal,
                                discount_line_cnt: 0,
                                line_can_select_cnt: 0,
                                new_plan
                            },
                            wire_product: {
                                ...this.state.wire_product,
                                wireDiscount: [],
                                selected: {
                                    wire_device: null,
                                    option: null,
                                }
                            },
                            options_modal: {
                                ...this.state.options_modal,
                                discount_indicated: {
                                    selected: null
                                },
                                tv_plus: {
                                    selected: {
                                        type: null,
                                        line: null
                                    }
                                },
                                sub_years: {
                                    selected: null
                                },
                                lgu_patron: {
                                    selected: null
                                },
                                u_tv: {
                                    selected: false
                                },
                                over_2_lines_combine: {
                                    selected: false
                                },
                                tv_standard: {
                                    selected: false
                                },
                                kt_premium_discount: {
                                    selected: null
                                },
                                kt_premium_discount_except: {
                                    selected: null
                                },
                                teenagers_promotion: {
                                    selected: []
                                },
                                nanum_discount: {
                                    selected: []
                                },
                                kt_teenagers_promotion: {
                                    selected: []
                                }
                            }
                        }, async () => {
                            await this.getDiscountWireLessPrice();
                            await this.getWireDiscount();
                            await this.onApplyCombineDiscount();
                        });
                    }else{
                        await this.getDiscountWireLessPrice();
                        await this.getWireDiscount();
                        await this.onApplyCombineDiscount();
                    }
                })
            };

            if ((this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount !== prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscount)
                || (!isNaN(this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement) && this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement !== prevProps.WirelessAdvice.WirelessAdviceStep3.optionalAgreement)
                || (JSON.stringify(this.props.WirelessAdvice.WirelessAdviceStep3.promotion) !== JSON.stringify(prevProps.WirelessAdvice.WirelessAdviceStep3.promotion))
            ){
                await this.updateDiscountWireLessPrice();
                await this.getWireDiscount();
                await this.onApplyCombineDiscount();
            }


            // goi phi d? ki?n
            if(this.state.discount_condition.discount_condition_selected
                && (
                    (JSON.stringify(this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange) !== JSON.stringify(prevProps.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange))
                    || (this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscountExpectedPlan && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscountExpectedPlan !== prevProps.WirelessAdvice.WirelessAdviceStep3.welfareDiscountExpectedPlan)
                    || (!isNaN(this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementPlanEstimated) && this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementPlanEstimated !== prevProps.WirelessAdvice.WirelessAdviceStep3.optionalAgreementPlanEstimated)
                    || (JSON.stringify(this.props.WirelessAdvice.WirelessAdviceStep3.promotion) !== JSON.stringify(prevProps.WirelessAdvice.WirelessAdviceStep3.promotion))
                )
            ){
                this.getDiscountWireLessPriceExpectedPlan(); // update goi phi d? ki?n

            }

            // 작동은 하나 console에서 에러 메시지가 출력
                            /*let conditionCombine = this.state.discount_condition.discount_condition_selected;
                if(conditionCombine !=null){
                    console.log("conditionCombine", conditionCombine.id)
                }
                if(conditionCombine !=null && conditionCombine.id == 6){
                    this.setState({
                        check_case: 1
                    })
                }*/
                /*let plans = this.state.plan_modal.plans;

                if(this.state.check_case == 0 && plans.length == 7){
                    console.log("didupdate 삭제")
                    plans.splice(5,2);
                    this.setState({
                        plan_modal: {
                            ...this.state.plan_modal,
                            plans: plans
                        },
                        check_case: 0
                    })
                }*/
            
            //

            //
            /*if(this.state.check_case == 1 && this.state.check_kt_premium == 1){
                let plans = this.state.plan_modal.plans;
                let wireProduct = this.state.wire_product.selected;
                let discount_line_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.discount_line_cnt : 0; //기존 wireProduct.wire_device.discount_line_cnt = 5의 값
                let line_can_select_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.wireless_select_line_cnt : 0;
                    //3593 수정 start
                    //console.log("this.state.options_modal.kt_premium_discount.selected", this.state.options_modal.kt_premium_discount.selected)
                    if(this.state.check_case == 1 && this.state.options_modal.kt_premium_discount.selected){
                        if(plans.length == 5 || plans.length == 7){
                            discount_line_cnt = 7;
                            line_can_select_cnt = 7;

                            if(plans.length == 5){
                                for (let i = 6; i <= 7; i++) { //초반 생성 회선 수 증가
                                    plans.push({
                                        idx: i,
                                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                                        discount_wireless_price: 0,
                                        discount_condition_name: [],
                                        is_commitment: false,
                                        disabled: true
                                    })
                                }
                            }
                            wireProduct.wire_device.discount_line_cnt = 7;
                            wireProduct.wire_device.wire_select_line_cnt = 7;
                            wireProduct.wire_device.wireless_select_line_cnt = 7;
                            
                            this.setState({
                                plan_modal: {
                                    ...this.state.plan_modal,
                                    discount_line_cnt,
                                    line_can_select_cnt,
                                    plans: plans
                                },
                                wire_product: {
                                    ...this.state.wire_product,
                                    //line_can_select_cnt,
                                    //discount_line_cnt,
                                    selected:wireProduct,
                                    wireDiscount: [],
                                },
                            })

                        }
                    }
            }*/
            //this.state.options_modal.kt_premium_discount.selected
            //
        }
    }


    getDiscountWireLessPriceExpectedPlan = async () => {
        if(this.props.adviceType == ADVICE_TYPE.WIRELESS){
            if(this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange){
                let dataPlanNewDateChange = await this.getDiscountWireLessPrice( "expected");
                this.props.change('WirelessAdviceStep3.discountWireLessPriceExpectedPlan', dataPlanNewDateChange)
            }else{
                this.props.change('WirelessAdviceStep3.discountWireLessPriceExpectedPlan', null)
            }
        }

    }
    /**
     * toggle modal
     */
    toggle = () => {
        this.props.toggleModal();
    }


    /**
     * close modal
     */
    onCloseModal = () => {
        let dataApplied = JSON.parse(this.state.dataApplied);

        if (!dataApplied && _.isEmpty(dataApplied) && !this.props.WiredAdvice?.WiredAdviceStep2?.isCombined) {
            this.setState({
                discount_condition: {
                    ...this.state.discount_condition,
                    discount_condition_selected: null
                },
                wire_product: {
                    ...this.state.wire_product,
                    isShowModal: false,
                    line_can_select_cnt: 0,
                    discount_line_cnt: 0,
                    dataWireDeviceByCombineID: [],
                    wireDiscount: [],
                    selected: {
                        wire_device: null,
                        option: null,
                    }
                },
                options_modal: {
                    ...this.state.options_modal,
                    discount_indicated: {
                        selected: null
                    },
                    tv_plus: {
                        selected: {
                            type: null,
                            line: null
                        }
                    },
                    sub_years: {
                        selected: null
                    },
                    lgu_patron: {
                        selected: null
                    },
                    u_tv: {
                        selected: false
                    },
                    over_2_lines_combine: {
                        selected: false
                    },
                    tv_standard: {
                        selected: false
                    },
                    kt_premium_discount: {
                        selected: null
                    },
                    kt_premium_discount_except: {
                        selected: null
                    },
                    teenagers_promotion: {
                        selected: []
                    },
                    nanum_discount: {
                        selected: []
                    },
                    kt_teenagers_promotion: {
                        selected: []
                    }
                }
            }, () => {
                this.updateDiscountWireLessPrice();
                this.getWireDiscount();
            });
        } else {
            // gi? l?i gia tr? đa applied tr??c đo
            if (dataApplied && !_.isEmpty(dataApplied)) {
                this.setState({
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: dataApplied.discount_condition
                    },
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: dataApplied?.plan_modal?.plans
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected: dataApplied?.option_discount?.discount_indicated
                        },
                        tv_plus: {
                            selected: {
                                type: dataApplied?.option_discount?.tv_plus?.type,
                                line: dataApplied?.option_discount?.tv_plus?.line
                            }
                        },
                        sub_years: {
                            selected: dataApplied?.option_discount?.sub_years
                        },
                        kt_number_line_wire_combine: {
                            selected: dataApplied?.option_discount?.kt_number_line_wire_combine
                        },
                        lgu_patron: {
                            selected: dataApplied?.option_discount?.lgu_patron
                        },
                        u_tv: {
                            selected: dataApplied?.option_discount?.u_tv
                        },
                        over_2_lines_combine: {
                            selected: dataApplied?.option_discount?.over_2_lines_combine
                        },
                        tv_standard: {
                            selected: dataApplied?.option_discount?.tv_standard
                        },
                        kt_premium_discount: {
                            selected: dataApplied?.option_discount?.kt_premium_discount
                        },
                        kt_premium_discount_except: {
                            selected: dataApplied?.option_discount?.kt_premium_discount_except
                        },
                        teenagers_promotion: {
                            selected: dataApplied?.option_discount?.teenagers_promotion
                        },
                        nanum_discount: {
                            selected: dataApplied?.option_discount?.nanum_discount
                        },
                        kt_teenagers_promotion: {
                            selected: dataApplied?.option_discount?.kt_teenagers_promotion
                        }

                    }
                }, () => {
                    this.updateDiscountWireLessPrice();
                    this.getWireDiscount();
                });
            }
        }
    }


    /**
     * detect open modal
     */
    onOpened = async () => {
        this.props.showLoading();
        let wire_product = this.state.wire_product;
        let wireDevices = wire_product.wireDevices;
        let plans = this.state.plan_modal.plans;
        if (this.props.adviceType == ADVICE_TYPE.WIRE && this.props.WiredAdvice.WiredAdviceStep2.classify[0].classify) {
            wireDevices = this.props.WiredAdvice.WiredAdviceStep2.classify;
            let internetWireDevices = [];
            let discount_line_cnt = this.state.plan_modal.discount_line_cnt;
            let line_can_select_cnt = this.state.plan_modal.line_can_select_cnt;
            let options_modal = this.state.options_modal;
            if (wireDevices[0].classify.length > 0) {
                _.map(wireDevices[0].classify, (value) => {
                    if (!_.isEmpty(value.device)) internetWireDevices.push(value);
                });
            }
            wire_product = {
                ...wire_product,
                wireDevices,
                internetWireDevices,
                classifyString: this.props.WiredAdvice.WiredAdviceStep2?.classifyString || "",
                selected: {
                    ...wire_product.selected,
                    wire_device: internetWireDevices && internetWireDevices.length > 0 ? internetWireDevices[0].device : this.state.wire_product.selected.wire_device,
                    option: internetWireDevices && internetWireDevices.length > 0 ? internetWireDevices[0].options[0] : this.state.wire_product.selected.options[0],
                }
            }

            let wire_device = wire_product.selected; // s?n ph?m internet đ?u tien
            let combineCondition = this.state.discount_condition.discount_condition_selected;
            if (combineCondition) { // n?u đa t?n t?i m?u đi?u ki?n, thi ki?m tra l?i s?n ph?m hi?n t?i co ap d?ng cho m?u đi?u ki?n hi?n t?i khong
                let result = await api.advice.getListWireDevice(combineCondition.id);
                if (result.status == 200) {
                    let dataWireDevice = result.data.data;
                    let new_wire_device = _.find(dataWireDevice, (wire) => { // l?y l?i đi?u ki?n kh?p c?a sp co day va m?u đi?u ki?n
                        return wire.wire_device.wd_id == wire_device.wire_device.wd_id
                    })
                    if (!new_wire_device || (new_wire_device && new_wire_device.state == 0)) { // n?u m?u đi?u ki?n hi?n t?i k ap d?ng gi?m gia cho s?n ph?m nay
                        if (this.props.adviceType == ADVICE_TYPE.WIRE) {
                        }
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                message: (
                                    <span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
                            },
                            discount_condition: {
                                ...this.state.discount_condition,
                                discount_condition_selected: this.props.adviceType == ADVICE_TYPE.WIRE ? null : this.state.discount_condition.discount_condition_selected // n?u ? t? van co day thi reset m?u đi?u ki?n
                            },
                            wire_product: { // n?u ? t? v?n khong day thi reset s?n ph?m co day
                                ...this.state.wire_product,
                                selected: {
                                    wire_device: this.props.adviceType == ADVICE_TYPE.WIRELESS ? null : this.state.wire_product.selected.wire_device,
                                    option: this.props.adviceType == ADVICE_TYPE.WIRELESS ? null : this.state.wire_product.selected.option
                                }
                            }
                        }, () => {
                            this.props.hideLoading();
                        })
                        return;
                    } else {
                        //console.log("discount_line_cnt 진입")
                        //console.log("wire_device.wire_device.discount_line_cnt", wire_device.wire_device.discount_line_cnt)
                        wire_device.wire_device = new_wire_device;
                        discount_line_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.discount_line_cnt : 0;
                        line_can_select_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.wire_select_line_cnt : 0;
                        _.map(plans, (plan, i) => {
                            plan.discount_wireless_price = 0;
                            plan.discount_condition_name = [];
                            plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                        });
                    }
                } else {

                }


                /** n?u đa ch?n m?u đi?u ki?n, n?u co s?n ph?m TV , co goi phi đ?u tien, active tvplus cho line đ?u tien**/
                let hasTVDevice = _.filter(this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify, (value, i) => {
                    return !_.isEmpty(value.device)
                }).length > 0 ? true : false;
                if(combineCondition && [1, 2, 3].includes(+combineCondition.id) && hasTVDevice && plans.length > 0 && !_.isEmpty(plans[0].plan)){ // n?u co s?n ph?m TV, t? đ?ng apply vao line 1
                    /*options_modal = {
                        ...options_modal,
                        tv_plus : {
                            selected: !_.isEmpty(this.state.options_modal.tv_plus.selected) && this.state.options_modal.tv_plus.selected.type ? this.state.options_modal.tv_plus.selected : { // n?u khong ch?n cai nao thi t? đ?ng active TV plus
                                type: {
                                    id: 1,
                                    type: "OPTION",
                                    code: "TVPLUS",
                                    name: "TV플러스(신규)",
                                    short_name:  "TV플러스(신규)",
                                    show_name:"결합 신규 가입자",
                                    description: "무선 1,100원 / TV 1,100원 할인",
                                    price: 1100
                                },
                                line: this.state.plan_modal.plans[0]
                            }
                        },
                    }*/
                }
                /** end **/
            }

            this.setState({
                plan_modal: {
                    ...this.state.plan_modal,
                    line_can_select_cnt,
                    discount_line_cnt,
                    plans: plans
                },
                wire_product,
                options_modal
            }, async () => {
                await this.getWireDiscount();
                await this.getDiscountWireLessPrice();
            })

        } else {
            this.setState({
                wire_product,
            }, async () => {
                await this.getWireDiscount();
                await this.getDiscountWireLessPrice();
            })
        }

        this.props.hideLoading();
    }


    onApplyWireProduct = async (e, wireProduct) => { // ch? co ? t? v?n khong day
        let plans = this.state.plan_modal.plans;
        let plans_line_not_null = _.reject(plans, {plan: null});// s? plan đa ch?n
        this.setState({
            wire_product: {
                ...this.state.wire_product,
                selected: wireProduct,
                dataWireDevice: wireProduct.wire_devices_list
            }
        }, async () => {
            wireProduct = this.state.wire_product.selected;

            //console.log("wireProduct : ", wireProduct)
            
            if (!_.isEmpty(wireProduct.wire_device)) { // n?u co ch?n s?n ph?m co day
                if (wireProduct.wire_device.state != 0) {  // s?n ph?m co day đ??c ap d?ng cho m?u đi?u ki?n nay hay khong hay khong
                    // khi ch?n l?i s?n ph?m co day, gi? l?i goi phi đa ch?n, ch? update gia ti?n gi?m gia
                    let conditionCombine = this.state.discount_condition.discount_condition_selected;
                    if (conditionCombine && [4, 10, 12, 16, 11, 13, 17, 18].includes(conditionCombine.id)) {
                        let combineConditionID = conditionCombine.id;
                        switch (combineConditionID) {
                            case 4:
                                combineConditionID = 16;
                                break;
                            case 11:
                                combineConditionID = 10;
                                break;
                            case 12:
                                combineConditionID = 13;
                                break;
                            case 17:
                                combineConditionID = 18;
                                break;
                        }

                        conditionCombine = _.find(this.state.discount_condition.list, (val, i) => val.id == combineConditionID);
                        await this.onSelectDiscountCondition(conditionCombine, 'onApplyWireProduct');
                    }

                    let adviceType = this.props.adviceType; // lo?i t? v?n: khong day hay co day

                    
                    let discount_line_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.discount_line_cnt : 0; //기존 wireProduct.wire_device.discount_line_cnt = 5의 값
                    let line_can_select_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.wireless_select_line_cnt : 0;
                    
                    //3593 수정 start
                    /* 업무번호 3593 KT 총액결합 2회선 추가
                    if(this.state.check_case == 1 && this.state.check_kt_premium == 1){ //총액결합 할인과 KT 프리미엄 결합할인이 모두 선택되었을 때에만 회선 추가
                        if(plans.length == 5 || plans.length == 7){
                            discount_line_cnt = 7;
                            line_can_select_cnt = 7;

                            if(plans.length == 5){ //회선수가 5개일때
                                for (let i = 6; i <= 7; i++) { //6,7회선을 추가
                                    plans.push({
                                        idx: i,
                                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                                        discount_wireless_price: 0,
                                        discount_condition_name: [],
                                        is_commitment: false,
                                        disabled: true
                                    })
                                }
                            }
                            wireProduct.wire_device.discount_line_cnt = 7;
                            wireProduct.wire_device.wire_select_line_cnt = 7;
                            wireProduct.wire_device.wireless_select_line_cnt = 7;
                            
                            this.setState({
                                plan_modal: {
                                    ...this.state.plan_modal,
                                    discount_line_cnt,
                                    line_can_select_cnt,
                                    plans: plans
                                },
                                wire_product: {
                                    ...this.state.wire_product,
                                    //line_can_select_cnt,
                                    //discount_line_cnt,
                                    selected:wireProduct,
                                    wireDiscount: [],
                                },
                            })
                            //console.log("회선 추가 후 ", this.state.wire_Product)

                        }
                    }
                    // else if(this.state.check_case != 1 || wireProduct.option != null){
                    //     console.log("pop 사용할 조건 진입") //안들어감
                    //     plans.splice(5,2);
                    //     //discount_line_cnt = 5;
                    //     //line_can_select_cnt = 5;
                    //     console.log(plans)
                    //     this.setState({
                    //         plan_modal: {
                    //             ...this.state.plan_modal,
                    //             plans: plans
                    //         }
                    //     })
                    // }
                    */
                    //3593 수정  end

                    _.map(plans, (plan, i) => {
                        plan.plan = i < line_can_select_cnt ? plan.plan : null;
                        plan.discount_wireless_price = 0;
                        plan.discount_condition_name = [];
                        if(this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee){
                            plan.is_commitment = true;
                        }
                        // plan.is_commitment = this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee ? true : false
                        plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                    });
                    this.setState({
                        // wire_product: {
                        //     ...this.state.wire_product,
                        //     selected: wireProduct
                        // },
                        discount_condition: {
                            ...this.state.discount_condition,
                            discount_condition_selected: conditionCombine
                        },
                        plan_modal: {
                            ...this.state.plan_modal,
                            discount_line_cnt,
                            line_can_select_cnt,
                            plans: plans
                        },
                        options_modal: { // n?u s? line đa ch?n tr??c đo <= s? line co th? ch?n thi gi? nguyen, khong thi v? null
                            ...this.state.options_modal,
                            discount_indicated: {
                                selected: this.state.options_modal.discount_indicated.selected && this.state.options_modal.discount_indicated.selected.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.discount_indicated.selected : null
                            },
                            tv_plus: {
                                selected: {
                                    type: this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.tv_plus.selected.type : null,
                                    line: this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.tv_plus.selected.line : null
                                }
                            },
                            teenagers_promotion: {
                                selected: this.state.options_modal?.teenagers_promotion?.selected || []
                            },
                            nanum_discount: {
                                selected: this.state.options_modal?.nanum_discount?.selected || []
                            },
                            kt_teenagers_promotion: {
                                selected: this.state.options_modal?.kt_teenagers_promotion?.selected || []
                            }
                        }
                    }, async () => {
                        await this.getWireDiscount();
                        await this.getDiscountWireLessPrice();
                        if (![4, 16, 10, 11, 12, 13, 17, 18].includes(conditionCombine.id)) this.toggleModal(e, 'wire_product');
                    })
                } else {
                    // this.toggleModal(e,'error_modal');
                    this.setState({
                        error_modal: {
                            isShowModal: true,
                            message: (
                                <span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
                        }
                    })

                    let plans = this.state.plan_modal.plans;
                    for (let i = 0 ; i < plans.length; i++) {
                        if (i > 0) {
                            plans[i] = {
                                ... plans[i],
                                plan: null,
                                discount_wireless_price: 0,
                                discount_condition_name: [],
                            }
                        }
                    }

                    let plans_line_not_null = _.reject(plans, {plan: null});// s? plan đa ch?n
                    _.map(plans, (plan, i) => {
                        // if(i !== 0) plan.plan = null;
                        plan.discount_wireless_price = 0;
                        plan.discount_condition_name = [];
                        if(this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee){
                            plan.is_commitment = true;
                        }
                        // plan.is_commitment = this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee ? true : false
                        plan.disabled = true;
                    });

                    this.setState({
                        plan_modal: {
                            ...this.state.plan_modal,
                            plans: plans
                        },
                        wire_product: {
                            ...this.state.wire_product,
                            isShowModal: false,
                            line_can_select_cnt: 0,
                            discount_line_cnt: 0,
                            wireDiscount: [],
                        },
                        options_modal: { // n?u s? line đa ch?n tr??c đo <= s? line co th? ch?n thi gi? nguyen, khong thi v? null
                            ...this.state.options_modal,
                            discount_indicated: {
                                selected : null
                            },
                            tv_plus: {
                                selected: {
                                    type : null,
                                    line : null
                                }
                            },
                            teenagers_promotion: {
                                selected: []
                            },
                            nanum_discount: {
                                selected: []
                            },
                            kt_teenagers_promotion: {
                                selected: []
                            }
                        }
                    }, async () => {
                        await this.getWireDiscount();
                        await this.getDiscountWireLessPrice();
                    });
                }
            } else { // n?u ch?n null => reset data
                //console.log("추가 한 것 하단에 진입")
                let combineCondition = this.state.discount_condition.discount_condition_selected

                if (combineCondition && [4, 10, 12, 16, 11, 13, 17, 18].includes(combineCondition.id)) {
                    let combineConditionID = combineCondition.id;
                    switch (combineConditionID) {
                        case 16:
                            combineConditionID = 4;
                            break;
                        case 10:
                            combineConditionID = 11;
                            break;
                        case 13:
                            combineConditionID = 12;
                            break;
                        case 18:
                            combineConditionID = 17;
                            break;
                    }
                    combineCondition = _.find(this.state.discount_condition.list, (val, i) => val.id == combineConditionID);
                    await this.onSelectDiscountCondition(combineCondition, 'onApplyWireProduct');
                }
                let discount_line_cnt = this.state.plan_modal.discount_line_cnt;
                let line_can_select_cnt = this.state.plan_modal.line_can_select_cnt;
                if (this.state.onlyWirelessCombineCondition.includes(combineCondition.id)) { //
                    //m?u đi?u ki?n k c?n ch?n s?n ph?m co day => cho ch?n goi phi
                    discount_line_cnt = combineCondition?.condition_default?.discount_line_cnt || 0;
                    line_can_select_cnt = combineCondition?.condition_default?.wireless_select_line_cnt || 0; // ch? k?t h?p v?i khong day nen l?y s? goi phi co th? ch?n
                }

                _.map(plans, (plan, i) => {
                    // if(i !== 0) plan.plan = null;
                    plan.discount_wireless_price = 0;
                    plan.discount_condition_name = [];
                    if(this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee){
                        plan.is_commitment = true;
                    }
                    // plan.is_commitment = this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee ? true : false
                    plan.disabled = combineCondition && [4, 10, 12, 16, 11, 13, 17, 18].includes(combineCondition.id) && plan.idx <= +line_can_select_cnt ? false : true; // nh?ng đi?u ki?n gi?m gia k?t h?p c? khong day va co day
                });

                this.setState({
                    plan_modal: {
                        ...this.state.plan_modal,
                        discount_line_cnt: discount_line_cnt,
                        line_can_select_cnt: line_can_select_cnt,
                        plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: combineCondition
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        wireDiscount: [],
                        selected: {
                            wire_device: null,
                            option: null,
                        }
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected: this.state.options_modal.discount_indicated.selected && this.state.options_modal.discount_indicated.selected.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.discount_indicated.selected : null
                        },
                        tv_plus: {
                            selected: {
                                type: this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.tv_plus.selected.type : null,
                                line: this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? this.state.options_modal.tv_plus.selected.line : null
                            }
                        },
                        kt_number_line_wire_combine: {
                            selected: this.state.options_modal?.kt_number_line_wire_combine?.selected || null
                        },
                        sub_years: {
                            selected: [4, 16].includes(combineCondition.id) ? this.state.options_modal.sub_years.selected : null
                        },
                        lgu_patron: {
                            selected: this.state.options_modal?.lgu_patron?.selected || null
                        },
                        u_tv: {
                            selected: this.state.options_modal?.u_tv?.selected || false
                        },
                        over_2_lines_combine: {
                            selected: this.state.options_modal?.over_2_lines_combine?.selected || false
                        },
                        tv_standard: {
                            selected: this.state.options_modal?.tv_standard?.selected || false
                        },
                        kt_premium_discount: {
                            selected: this.state.options_modal?.kt_premium_discount?.selected || null
                        },
                        kt_premium_discount_except: {
                            selected: this.state.options_modal?.kt_premium_discount_except?.selected || null
                        },
                        teenagers_promotion: {
                            selected: this.state.options_modal?.teenagers_promotion?.selected || []
                        },
                        nanum_discount: {
                            selected: this.state.options_modal?.nanum_discount?.selected || []
                        },
                        kt_teenagers_promotion: {
                            selected: this.state.options_modal?.kt_teenagers_promotion?.selected || []
                        }
                    }
                }, async () => {

                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                    if (![4, 16, 10, 11, 12, 13, 17, 18].includes(combineCondition.id)) this.toggleModal(e, 'wire_product');
                });

            }
        })
    }

    onSelectDiscountCondition = async (combineCondition, type = "select-condition") => {
        let combineConditionID = combineCondition && combineCondition.id;
        
        if (combineCondition && !this.state.wire_product.selected.wire_device) {
            switch (combineConditionID) {
                case 16:
                    combineConditionID = 4;
                    break;
                case 10:
                    combineConditionID = 11;
                    break;
                case 13:
                    combineConditionID = 12;
                    break;
                case 18:
                    combineConditionID = 17;
                    break;
            }
            combineCondition = _.find(this.state.discount_condition.list, (val, i) => val.id == combineConditionID);
        }

        if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day // 무선

            let plans = this.state.plan_modal.plans;
            // l? ma sai task reset mo đo thi check l?i cai ni
            // if (type == "select-condition") {
            //     for (let i = 0 ; i < plans.length; i++) {
            //         if (i > 0) {
            //             plans[i] = {
            //                 ... plans[i],
            //                 plan: null,
            //                 discount_wireless_price: 0,
            //                 discount_condition_name: [],
            //             }
            //         }
            //     }
            // }

            let plans_line_not_null = _.reject(plans, {plan: null});// s? plan đa ch?n
            _.map(plans, (plan, i) => {
                // if(i !== 0) plan.plan = null;
                plan.discount_wireless_price = 0;
                plan.discount_condition_name = [];
                if(this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee){
                    plan.is_commitment = true;
                }
                // plan.is_commitment = this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee ? true : false
                plan.disabled = true;
            });
            if (combineCondition) { // n?u co ch?n m?u đi?u ki?n
                // if(this.state.onlyWirelessCombineCondition.includes(combineCondition.id) && combineCondition?.condition_default?.state == 0){ // n?u m?u đi?u ki?n ch? ap d?ng cho goi phi va k đ??c ap d?ng
                if (combineCondition?.condition_default?.state == 0) { // n?u m?u đi?u ki?n ch? ap d?ng cho goi phi va k đ??c ap d?ng
                    this.setState({
                        error_modal: {
                            isShowModal: true,
                            message: (
                                <span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
                        }
                    })
                    return;
                }


                let wire_device = this.state.wire_product.selected;


                let discount_line_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.discount_line_cnt : 0;
                let line_can_select_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.wireless_select_line_cnt : 0;
                let dataApplied = JSON.parse(this.state.dataApplied);
                let options_modal = {...this.state.options_modal};
                if (dataApplied && !_.isEmpty(dataApplied) && dataApplied.discount_condition) {
                    options_modal = {
                        ...options_modal,
                        discount_indicated: {
                            selected: dataApplied.option_discount.discount_indicated && dataApplied.option_discount.discount_indicated.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? dataApplied.option_discount.discount_indicated : null
                        },
                        tv_plus: {
                            selected: {
                                type: dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? dataApplied.option_discount.tv_plus.type : null,
                                line: dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? dataApplied.option_discount.tv_plus.line : null
                            }
                        },
                        sub_years: {
                            selected: dataApplied?.option_discount?.sub_years || null
                        },
                        kt_number_line_wire_combine: {
                            selected: dataApplied?.option_discount?.kt_number_line_wire_combine || null
                        },
                        lgu_patron: {
                            selected: dataApplied?.option_discount?.lgu_patron || null
                        },
                        u_tv: {
                            selected: dataApplied?.option_discount?.u_tv || false
                        },
                        over_2_lines_combine: {
                            selected: dataApplied?.option_discount?.over_2_lines_combine || false
                        },
                        tv_standard: {
                            selected: dataApplied?.option_discount?.tv_standard || false
                        },
                        kt_premium_discount: {
                            selected: dataApplied?.option_discount?.kt_premium_discount || null
                        },
                        kt_premium_discount_except: {
                            selected: dataApplied?.option_discount?.kt_premium_discount_except || null
                        },
                        teenagers_promotion: {
                            selected: dataApplied?.option_discount?.teenagers_promotion || []
                        },
                        nanum_discount: {
                            selected: dataApplied?.option_discount?.nanum_discount || []
                        },
                        kt_teenagers_promotion: {
                            selected: dataApplied?.option_discount?.kt_teenagers_promotion || []
                        }
                    };
                }
                else {
                    options_modal = {
                        ...options_modal,
                        discount_indicated: {
                            selected: options_modal.discount_indicated.selected && options_modal.discount_indicated.selected.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? options_modal.discount_indicated.selected : null
                        },
                        tv_plus: {
                            selected: {
                                type: options_modal.tv_plus.selected.line && options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? options_modal.tv_plus.selected.type : null,
                                line: options_modal.tv_plus.selected.line && options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? options_modal.tv_plus.selected.line : null,
                            }
                        },
                        sub_years: {
                            selected: options_modal?.sub_years?.selected || null
                        },
                        kt_number_line_wire_combine: {
                            selected: options_modal?.kt_number_line_wire_combine?.selected || null
                        },
                        lgu_patron: {
                            selected: options_modal?.lgu_patron?.selected || null
                        },
                        u_tv: {
                            selected: options_modal?.u_tv?.selected || false
                        },
                        over_2_lines_combine: {
                            selected: options_modal?.over_2_lines_combine?.selected || false
                        },
                        tv_standard: {
                            selected: options_modal?.tv_standard?.selected || false
                        },
                        kt_premium_discount: {
                            selected: options_modal?.kt_premium_discount?.selected || null
                        },
                        kt_premium_discount_except: {
                            selected: options_modal?.kt_premium_discount_except?.selected || null
                        },
                        teenagers_promotion: {
                            selected: options_modal?.teenagers_promotion?.selected || []
                        },
                        nanum_discount : {
                            selected: options_modal?.nanum_discount?.selected || []
                        },
                        kt_teenagers_promotion: {
                            selected: options_modal?.kt_teenagers_promotion?.selected || []
                        }
                    };

                }

                let dataWireDevice = this.state.wire_product.dataWireDevice;
                if (wire_device.wire_device) { // n?u đa co thi?t b?, thi gi? nguyen goi phi, c?p nh?t l?i gia ti?n gi?m gia
                    if (type == "select-condition") {
                        let result = await api.advice.getListWireDevice(combineCondition.id);
                        if (result.status == 200) {
                            dataWireDevice = result.data.data;
                        }
                    }
                    let new_wire_device = _.find(dataWireDevice, (wire) => { // l?y l?i đi?u ki?n kh?p c?a sp co day va m?u đi?u ki?n
                        return wire.wire_device.wd_id == wire_device.wire_device.wd_id
                    })
                    if (!new_wire_device || (new_wire_device && new_wire_device.state == 0)) { // n?u m?u đi?u ki?n hi?n t?i k ap d?ng gi?m gia cho s?n ph?m nay
                        // this.setState({
                        //     error_modal: {
                        //         isShowModal: true,
                        //         message: (<span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
                        //     }
                        // })
                        wire_device.wire_device = null;
                        wire_device.option = null;
                    } else {
                        wire_device.wire_device = new_wire_device;
                        discount_line_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.discount_line_cnt : 0;
                        line_can_select_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.wireless_select_line_cnt : 0;
                        _.map(plans, (plan, i) => {
                            plan.discount_wireless_price = 0;
                            plan.discount_condition_name = [];
                            plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                        });
                    }

                }
                else { // n?u khong co thi?t b?, thi v?n gi? l?i goi phi đa ch?n
                    _.map(plans, (plan, i) => {
                        plan.discount_wireless_price = 0;
                        plan.discount_condition_name = [];
                        plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                    });
                }

                let sub_years = null;
                let kt_number_line_wire_combine = null;
                if (combineCondition.id == 4) {
                    sub_years = combineCondition.condition_default ? combineCondition.condition_default.basic_condition : null;
                    options_modal.sub_years.selected = options_modal.sub_years.selected || sub_years[1];

                }
                if (combineCondition.id == 16) {
                    sub_years = combineCondition.condition_default ? combineCondition.condition_default.basic_condition : null;
                    options_modal.sub_years.selected = options_modal.sub_years.selected || sub_years[1];
                }

                if (combineCondition.id == 5) {
                    kt_number_line_wire_combine = combineCondition.condition_default ? combineCondition.condition_default.KT_number_of_lines_wireless_combined : null;
                    options_modal.kt_number_line_wire_combine.selected = {
                        1: kt_number_line_wire_combine?.line[1] || null
                    };
                }
                if (![1, 2, 3, 16].includes(combineCondition.id)) { // reset TV plus
                    options_modal.tv_plus.selected.type = null;
                    options_modal.tv_plus.selected.line = null;
                }

                if (this.state.onlyWirelessCombineCondition.includes(combineCondition.id)) {// m?u đi?u ki?n k c?n ch?n s?n ph?m co day => cho ch?n goi phi
                    discount_line_cnt = combineCondition?.condition_default?.discount_line_cnt || 0;
                    line_can_select_cnt = combineCondition?.condition_default?.wireless_select_line_cnt || 0; // ch? k?t h?p v?i khong day nen l?y s? goi phi co th? ch?n
                    _.map(plans, (plan, i) => {
                        plan.discount_wireless_price = 0;
                        plan.discount_condition_name = [];
                        plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                    });
                }
                if ([17, 18].includes(combineCondition.id)) {
                    if (wire_device.wire_device && !_.includes(this.state.wire_product_together, +wire_device.wire_device.wd_id)) { // n?u co s?n ph?m internet k thu?c s?n ph?m  k?t h?p LGU together
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                message: (
                                    <span className="notification_delete">결합을 적용할 수 없습니다.<br/> 결합조건을 확인해 주세요.</span>)
                            }
                        })
                        return null
                    }

                    if (plans_line_not_null.length > 0 && _.findIndex(plans_line_not_null, (plan) => {
                        return !this.state.plan_together.includes(+plan.plan.id);
                    }) >= 0) { // n?u co goi phi k thu?c goi phi k?t h?p LGU together
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                // message: (<span className="notification_delete">투게더 결합 불가능한 요금제 입니다.</span>)
                                message: (<span className="notification_delete">결합을 적용할 수 없습니다.<br/>결합조건을 확인해 주세요</span>)
                            }
                        });

                        return null
                    }
                }

                this.setState({
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: combineCondition
                    },
                    plan_modal: {
                        ...this.state.plan_modal,
                        line_can_select_cnt,
                        discount_line_cnt,
                        plans: plans
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        isShowModal: false,
                        wireDiscount: [],
                        selected: wire_device,
                        dataWireDevice: dataWireDevice
                    },
                    options_modal
                }, async () => {
                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                    
                    if (combineCondition.id == 20) { // Y끼리 무선결합
                        if([
                            2994, 2991, 2993, 3836, 2989, // 5G 세이브, 5G 슬림, 5G Y 슬림, 5G 슬림 플러스, 5G 심플 110GB
                            3907, 3908, 3909, 2986, 3000, // 5G 심플 50GB, 5G 심플 70GB, 5G 심플 90GB, 베이직, Y 스페셜
                            3002, 2985, 3821, 3755, 3719, // 스페셜, 초이스 베이직, 초이스 베이직 (BC바로 카드), 초이스 베이직 (디즈니+), 초이스 베이직 (바디프랜드)
                            3771, 3777, 3774, 3790, 3871, // 초이스 베이직 (삼성), 초이스 베이직 (시즌/지니+), 초이스 베이직 (우리카드), 초이스 베이직 (유튜브 프리미엄)
                            3809, 3697, 3011, 3785, 3849, // 초이스 베이직 (티빙/지니), 초이스 베이직 (현대카드), 초이스 베이직(넷플릭스), 초이스 베이직(디바이스), 초이스 베이직(코웨이)
                            3005, 3822, 3025, 3718, 3778, //초이스 스페셜, 초이스 스페셜 (BC바로 카드), 초이스 스페셜 (넷플릭스), 초이스 스페셜 (바디프랜드), 초이스 스페셜 (시즌/지니+)
                            3775, 3789, 3872, 3810, 3699, // 초이스 스페셜 (우리카드), 초이스 스페셜 (유튜브 프리미엄), 초이스 스페셜 (티빙/밀리), 초이스 스페셜 (티빙/지니), 초이스 스페셜 (현대카드)
                            3786, 3756, 3772, 3850, 3013, // 초이스 스페셜(디바이스), 초이스 스페셜(디즈니+), 초이스 스페셜(삼성), 초이스 스페셜(코웨이), 초이스 프리미엄
                            2979, 3757, 3717, 3773, 3779, // 초이스 프리미엄 (넷플릭스), 초이스 프리미엄 (디즈니+), 초이스 프리미엄 (바디프랜드), 초이스 프리미엄 (삼성), 초이스 프리미엄 (시즌/지니+)
                            3776, 3788, 3698, 3823, 3787, // 초이스 프리미엄 (우리카드), 초이스 프리미엄 (유튜브 프리미엄), 초이스 프리미엄 (현대카드), 초이스 프리미엄(BC바로 카드), 초이스 프리미엄(디바이스)
                            3851, 3873, 3811, 2998        //초이스 프리미엄(코웨이), 초이스 프리미엄(티빙/밀리), Y베이직
                        ].includes(+plans[0].plan.id)){
                            this.onApplyCombineDiscount();
                        }
                    }
                    if (combineCondition.id == 21) { // 친구사이 무선결합
                        this.onApplyCombineDiscount();
                    }
                });
            } else {
                this.setState({
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: null,
                        isShowModal: false
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        isShowModal: false,
                        line_can_select_cnt: 0,
                        discount_line_cnt: 0,
                        wireDiscount: [],
                    },
                }, async () => {
                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                });
            }

        }
        else {// t? v?n co day
            let plans = this.props.WiredAdvice?.WiredAdviceStep2?.isCombined
                        && JSON.parse(this.state.dataApplied)?.discount_condition?.id == combineCondition?.id
                            ? this.state.plan_modal.plans : this.makeNewPlans();
            // if (this.props.WiredAdvice?.WiredAdviceStep1?.telcomID == 18) {
                plans = this.state.plan_modal.plans
            // }
            let combineConditions = this.state.discount_condition.list;
            /* 18/7/2021 => Sinh cmt code nay l?i, BA yeu c?u k l?u lai l?ch s? goi phi đa ch?n c?a đi?u ki?n k?t h?p tr??c đo
            // for (const condition of combineConditions) {
            //     if (condition?.id === combineCondition?.id) {
            //         plans = condition.plans;
            //     }
            // }
            */
            let plans_line_not_null = _.reject(plans, {plan: null});// s? plan đa ch?n
            _.map(plans, (plan, i) => {
                plan.plan = combineCondition ? plan.plan : null;
                plan.discount_wireless_price = 0;
                plan.discount_condition_name = [];
                plan.disabled = true;
            });
            if (combineCondition) {
                let wire_device = this.state.wire_product.selected;
                // let plans = this.state.plan_modal.plans;
                let discount_line_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.discount_line_cnt : 0;
                let line_can_select_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.wire_select_line_cnt : 0;
                let dataApplied = JSON.parse(this.state.dataApplied);
                let options_modal = this.state.options_modal;
                if (dataApplied && !_.isEmpty(dataApplied)) {
                    let hasTVDevice = _.filter(this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify, (value, i) => {
                        return !_.isEmpty(value.device)
                    }).length > 0 ? true : false;
                    let tv_plus = {
                        selected: {
                            type: !hasTVDevice && combineCondition && plans_line_not_null.length > 0 && [1, 2, 3].includes(+combineCondition.id)  // n?u khong co s?n ph?m TV
                                ? null
                                : (dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx
                                    ? dataApplied.option_discount.tv_plus.type
                                    : null),
                            line: !hasTVDevice && combineCondition && plans_line_not_null.length > 0 && [1, 2, 3].includes(+combineCondition.id)  // n?u khong co s?n ph?m TV
                                ? null
                                : (dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx ? dataApplied.option_discount.tv_plus.line : null)
                        }
                    }
                    options_modal = {
                        discount_indicated: {
                            selected: dataApplied.option_discount.discount_indicated && dataApplied.option_discount.discount_indicated.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx ? dataApplied.option_discount.discount_indicated : null
                        },
                        tv_plus: tv_plus,
                        sub_years: {
                            selected: dataApplied?.option_discount?.sub_years || null
                        },
                        kt_number_line_wire_combine: {
                            selected: dataApplied?.option_discount?.kt_number_line_wire_combine || null
                        },
                        lgu_patron: {
                            selected: dataApplied?.option_discount?.lgu_patron || null
                        },
                        u_tv: {
                            selected: dataApplied?.option_discount?.u_tv || false
                        },
                        over_2_lines_combine: {
                            selected: dataApplied?.option_discount?.over_2_lines_combine || false
                        },
                        tv_standard: {
                            selected: dataApplied?.option_discount?.tv_standard || false
                        },
                        kt_premium_discount: {
                            selected: dataApplied?.option_discount?.kt_premium_discount || null
                        },
                        kt_premium_discount_except: {
                            selected: dataApplied?.option_discount?.kt_premium_discount_except || null
                        },
                        teenagers_promotion: {
                            selected: dataApplied?.option_discount?.teenagers_promotion || []
                        },
                        nanum_discount: {
                            selected: dataApplied?.option_discount?.nanum_discount || []
                        },
                        kt_teenagers_promotion: {
                            selected: dataApplied?.option_discount?.kt_teenagers_promotion || []
                        }

                    };
                }
                else {
                    options_modal = {
                        discount_indicated: {
                            selected: options_modal.discount_indicated.selected && options_modal.discount_indicated.selected.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx ? options_modal.discount_indicated.selected : null
                        },
                        tv_plus: {
                            selected: {
                                type: options_modal.tv_plus.selected.line && options_modal.tv_plus.selected.line.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx ? options_modal.tv_plus.selected.type : null,
                                line: options_modal.tv_plus.selected.line && options_modal.tv_plus.selected.line.idx <= plans_line_not_null?.[plans_line_not_null.length - 1]?.idx ? options_modal.tv_plus.selected.line : null,
                            }
                        },
                        sub_years: {
                            selected: options_modal?.sub_years?.selected || null
                        },
                        kt_number_line_wire_combine: {
                            selected: options_modal?.kt_number_line_wire_combine?.selected || null
                        },
                        lgu_patron: {
                            selected: options_modal?.lgu_patron?.selected || null
                        },
                        u_tv: {
                            selected: options_modal?.u_tv?.selected || false
                        },
                        over_2_lines_combine: {
                            selected: options_modal?.over_2_lines_combine?.selected || false
                        },
                        tv_standard: {
                            selected: options_modal?.tv_standard?.selected || false
                        },
                        kt_premium_discount: {
                            selected: options_modal?.kt_premium_discount?.selected || null
                        },
                        kt_premium_discount_except: {
                            selected: options_modal?.kt_premium_discount_except?.selected || null
                        },
                        teenagers_promotion: {
                            selected: options_modal?.teenagers_promotion?.selected || []
                        },
                        nanum_discount: {
                            selected: options_modal?.nanum_discount?.selected || []
                        },
                        kt_teenagers_promotion: {
                            selected: options_modal?.kt_teenagers_promotion?.selected || []
                        }
                    };
                }


                let hasTVDevice = _.filter(this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify, (value, i) => {
                    return !_.isEmpty(value.device)
                }).length > 0 ? true : false;
                let dataWireDeviceByCombineID = [];
                if(hasTVDevice && combineCondition && plans_line_not_null.length > 0 && [1, 2, 3].includes(+combineCondition.id)){ // n?u co s?n ph?m TV va k?t
                    // h?p đi?u ki?n gi?m gia k?t h?p SKT plan (1, 2, 3), t? đ?ng apply vao line 1 : https://docs.google.com/presentation/d/1Nk5se66qp2qAkTAZYrbFD6JFzMBeb4lWvYiAZkODcPA/edit#slide=id.gd44b1e4dff_0_176

                    /*options_modal = {
                        ...options_modal,
                        tv_plus : {
                            selected: !_.isEmpty(this.state.options_modal.tv_plus.selected) && this.state.options_modal.tv_plus.selected.type ? this.state.options_modal.tv_plus.selected : { // n?u khong ch?n cai nao thi t? đ?ng active TV plus
                                type: {
                                    id: 1,
                                    type: "OPTION",
                                    code: "TVPLUS",
                                    name: "TV플러스(신규)",
                                    short_name:  "TV플러스(신규)",
                                    show_name:"결합 신규 가입자",
                                    description: "무선 1,100원 / TV 1,100원 할인",
                                    price: 1100
                                },
                                line: this.state.plan_modal.plans[0]
                            }
                        },
                    }*/
                }else if(combineCondition && plans_line_not_null.length > 0 && [16].includes(+combineCondition.id)){

                    options_modal = {
                        ...options_modal,
                        tv_plus : {
                            selected: {
                                type: dataApplied && !_.isEmpty(dataApplied) && dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? dataApplied.option_discount.tv_plus.type : null,
                                line: dataApplied && !_.isEmpty(dataApplied) && dataApplied.option_discount.tv_plus.line && dataApplied.option_discount.tv_plus.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx ? dataApplied.option_discount.tv_plus.line : null
                            }
                        },
                    }

                }
                let sub_years = null;
                if (combineCondition.id == 16) {
                    sub_years = combineCondition.condition_default ? combineCondition.condition_default.basic_condition : null;
                    options_modal.sub_years.selected = dataApplied && !_.isEmpty(dataApplied) && dataApplied?.option_discount?.sub_years || sub_years[1];
                }

                //sau khi ch?n m?u đi?u ki?n, c?p nh?t l?i m?u đi?u ki?n đi kem s?n ph?m co day đa ch?n
                let internetWireDevices = this.state.wire_product.internetWireDevices;
                let result = await api.advice.getListWireDevice(combineCondition.id);
                if (result.status == 200) {
                    dataWireDeviceByCombineID = result.data.data;
                    let new_wire_device = _.find(dataWireDeviceByCombineID, (wire) => { // l?y l?i đi?u ki?n kh?p c?a sp co day va m?u đi?u ki?n
                        return wire.wire_device.wd_id == wire_device.wire_device.wd_id
                    })
                    if (!new_wire_device || (new_wire_device && new_wire_device.state == 0)) { // n?u m?u đi?u ki?n hi?n t?i k ap d?ng gi?m gia cho s?n ph?m nay
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                message: (
                                    <span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
                            },
                            discount_condition: {
                                ...this.state.discount_condition,
                                discount_condition_selected: null
                            },
                        })
                        return;
                    } else { // update l?i cac gia tr? đi?u ki?n gi?m gia k?t h?p c?a s?n ph?m
                        wire_device.wire_device = new_wire_device;
                        discount_line_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.discount_line_cnt : 0;
                        line_can_select_cnt = wire_device.wire_device && !_.isEmpty(wire_device.wire_device) ? wire_device.wire_device.wire_select_line_cnt : 0;
                        _.map(plans, (plan, i) => {
                            plan.discount_wireless_price = 0;
                            plan.discount_condition_name = [];
                            plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                        });
                    }
                } else {

                }
                if ([17, 18].includes(combineCondition.id)) {
                    if (wire_device.wire_device && !_.includes(this.state.wire_product_together, +wire_device.wire_device.wd_id)) { // n?u co s?n ph?m internet k thu?c s?n ph?m  k?t h?p LGU together
                        if(combineCondition.id == 18) {

                            let check = (element) => internetWireDevices.includes(element.device.wd_id);

                            if(check == false) {
                                this.setState({
                                    error_modal: {
                                        isShowModal: true,
                                        message: (
                                            <span className="notification_delete">선택하신 인터넷 상품은 유무선 결합을 적용할 수 없습니다.<br/>설정된 유무선 결합을 초기화합니다.</span>)                            },
                                    discount_condition: {
                                        ...this.state.discount_condition,
                                        discount_condition_selected: null,
                                    },
                                    wire_product: {
                                        ...this.state.wire_product,
                                        internetWireDevices: []
                                    },
                                },async () => {
                                    if(!this.state.discount_condition.isShowModal){ // n?u đang show modal gi?m gia k?t h?p thi ch? c?n hi?n message l?i k?t h?p la đ??c
                                        await this.onSelectDiscountCondition(null)
                                        await this.getDiscountWireLessPrice();
                                        await this.getWireDiscount();
                                        await this.onApplyCombineDiscount();
                                    }
                                })
                            } else {

                                let new_plans_line = plans.map((plan) => ({
                                    ...plan,
                                    disabled: false
                                }));

                                options_modal = {
                                    ...options_modal,
                                    nanum_discount : {
                                        selected: options_modal?.nanum_discount?.selected || []
                                    }
                                }

                                this.setState({
                                    plan_modal: {
                                        ...this.state.plan_modal,
                                        plans: new_plans_line,
                                    },
                                    discount_condition: {
                                        ...this.state.discount_condition,
                                        discount_condition_selected: combineCondition
                                    },
                                    /*plan_modal: {
                                        ...this.state.plan_modal,
                                        line_can_select_cnt,
                                        discount_line_cnt,
                                        plans: plans
                                    },*/
                                    wire_product: {
                                        ...this.state.wire_product,
                                        isShowModal: false,
                                        wireDiscount: [],
                                        dataWireDeviceByCombineID,
                                        selected: wire_device
                                    },
                                    options_modal
                                }, async () => {
                                    await this.getWireDiscount();
                                    await this.getDiscountWireLessPrice();
                                })
                            }

                        } else {
                            this.setState({
                                error_modal: {
                                    isShowModal: true,
                                    message: (
                                        <span className="notification_delete">선택하신 인터넷 상품은 유무선 결합을 적용할 수 없습니다.<br/>설정된 유무선 결합을 초기화합니다.</span>)                            },
                                discount_condition: {
                                    ...this.state.discount_condition,
                                    discount_condition_selected: null,
                                },
                                wire_product: {
                                    ...this.state.wire_product,
                                    internetWireDevices: []
                                },
                            },async () => {
                                if(!this.state.discount_condition.isShowModal){ // n?u đang show modal gi?m gia k?t h?p thi ch? c?n hi?n message l?i k?t h?p la đ??c
                                    await this.onSelectDiscountCondition(null)
                                    await this.getDiscountWireLessPrice();
                                    await this.getWireDiscount();
                                    await this.onApplyCombineDiscount();
                                }
                            })
                        }

                        return null
                    }
                    if (plans_line_not_null.length > 0 && _.findIndex(plans_line_not_null, (plan) => {
                        return !this.state.plan_together.includes(+plan.plan.id);
                    }) >= 0) { // n?u co goi phi k thu?c goi phi k?t h?p LGU together
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                message: (<span className="notification_delete">투게더 결합 불가능한 요금제 입니다.</span>)
                            }
                        })
                        return null
                    }
                }

                this.setState({
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: combineCondition
                    },
                    plan_modal: {
                        ...this.state.plan_modal,
                        line_can_select_cnt,
                        discount_line_cnt,
                        plans: plans
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        isShowModal: false,
                        wireDiscount: [],
                        dataWireDeviceByCombineID,
                        selected: wire_device
                    },
                    options_modal
                }, async () => {
                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                });

            } else { // n?u ch?n k ap d?ng, thi reset data
                this.setState({
                    plan_modal: {
                        ...this.state.plan_modal,
                        discount_line_cnt: 0,
                        line_can_select_cnt: 0,
                        plans
                    },
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: null,
                        isShowModal: false
                    },
                    wire_product: {
                        ...this.state.wire_product,
                        isShowModal: false,
                        line_can_select_cnt: 0,
                        discount_line_cnt: 0,
                        dataWireDeviceByCombineID: [],
                        wireDiscount: [],
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected: null
                        },
                        tv_plus: {
                            selected: {
                                type: null,
                                line: null
                            }
                        },
                        sub_years: {
                            selected: null
                        },
                        lgu_patron: {
                            selected: null
                        },
                        u_tv: {
                            selected: false
                        },
                        over_2_lines_combine: {
                            selected: false
                        },
                        tv_standard: {
                            selected: false
                        },
                        kt_premium_discount: {
                            selected: null
                        },
                        kt_premium_discount_except: {
                            selected: null
                        },
                        teenagers_promotion: {
                            selected: []
                        },
                        nanum_discount: {
                            selected: []
                        },
                        kt_teenagers_promotion: {
                            selected: []
                        }
                    }
                }, async () => {
                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                });
            }

        }

    }

    getWireDiscount = () => {
        let combineDiscount = this.state.discount_condition.discount_condition_selected;
        let wire_device = this.state.wire_product.selected.wire_device;
        let internetWireDevices = this.state.wire_product.internetWireDevices;
        let plans_line_not_null = _.reject(this.state.plan_modal.plans, {plan: null});// s? plan đa ch?n
        let discount_line_cnt = this.state.plan_modal.discount_line_cnt; // s? line t?i đa đ??c ap d?ng gi?m gia
        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
        let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
            return +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100;
        });
        let conditionDiscountValue;
        let line_discount_matched;
        let wireDiscounts = [];
        let year_selected = this.state.options_modal.sub_years.selected;

        if (combineDiscount) {
            switch (combineDiscount.id) {
                case 1: // SKT plan ca gia đinh
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched && !_.isEmpty(wire_device)) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                        }
                        wireDiscounts.push(wireDiscount);
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        let firstLineWireDevice = null;
                        let secondLineWireDevice = null;
                        let TVWireDevices = this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify;

                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0,
                                discount_name: []
                            };
                            if (i == 0) {
                                firstLineWireDevice = objectCombineWD;
                                if (objectCombineWD && objectCombineWD.state == 1) { // n?u s?n ph?m co ap d?ng gi?m gia
                                    if (line_discount_matched) {
                                        if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                        } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                        }
                                        if(wireDiscount.discount > 0 ) wireDiscount.discount_name.push({
                                            discount: +wireDiscount.discount,
                                            name: combineDiscount.name
                                        })
                                        
                                        if(_.find(TVWireDevices, (wire, i) => {

                                            return (wire?.device?.wd_name ?? '').includes('B tv All');
                                        } )){ 
                                            /* SKT 유선상담화면 핵심요금제 할인 종료, 핵심요금제 할인 삭제 요청 2496
                                            if([239, 240, 241].includes(+objectCombineWD.wd_id) ){ // + 1,100 won
                                                // T_Giga인터넷라이트 500M
                                                // T_Giga인터넷 1G
                                                // Giga프리미엄
                                                wireDiscount.discount_name.push({
                                                    discount: 1100,
                                                    name: '핵심요금제 결합할인'
                                                })
                                                wireDiscount.discount = +wireDiscount.discount + 1100;

                                            }else if([235, 236, 332, 242, 243, 244].includes(+objectCombineWD.wd_id) ){ // + 2,200 won
                                                //Giga 라이트 + WIFI
                                                //GIGA + WIFI
                                                // GIGA 프리미엄 + WIFI 1.7G
                                                // Giga프리미엄x2.5
                                                // Giga프리미엄x5
                                                // Giga프리미엄x10
                                                wireDiscount.discount_name.push({
                                                    discount: 2200,
                                                    name: '핵심요금제 결합할인'
                                                })
                                                wireDiscount.discount = +wireDiscount.discount + 2200;
                                            }
                                            */
                                        }
                                        
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            } else if (i == 1) {
                                secondLineWireDevice = objectCombineWD;
                                if (objectCombineWD && objectCombineWD.state == 1) { // n?u s?n ph?m co ap d?ng gi?m gia
                                    if (line_discount_matched) {
                                        if (line_discount_matched.wire_basic_discount_2_line && line_discount_matched.wire_basic_discount_2_line.unit == "won") {
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_2_line.value;
                                        } else if (line_discount_matched.wire_basic_discount_2_line && line_discount_matched.wire_basic_discount_2_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_2_line.value / 100;
                                        }
                                    }
                                    // task flow: 6834

                                    if([234, 238].includes(+firstLineWireDevice.wd_id) && [245, 248].includes(+secondLineWireDevice.wd_id) && plans_line_not_null.length >= 4 ){
                                        wireDiscount.discount = 5500;
                                    }

                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                        })
                    }
                    break;
                case 2: //STK free ca gia dinh
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched && !_.isEmpty(wire_device)) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                        }
                        wireDiscounts.push(wireDiscount);
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        let TVWireDevices = this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify;

                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0,
                                discount_name: []

                            };
                            if (i == 0) {
                                if (objectCombineWD && objectCombineWD.state == 1) { // n?u s?n ph?m co ap d?ng gi?m gia
                                    if (line_discount_matched) {
                                        if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                        } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                        }
                                        if(wireDiscount.discount > 0 ) wireDiscount.discount_name.push({
                                            discount: +wireDiscount.discount,
                                            name: combineDiscount.name
                                        })
                                         
                                        if(_.find(TVWireDevices, (wire, i) => {

                                            return wire.device.wd_name.includes('B tv All');
                                        } )){
                                            /* SKT 유선상담화면 핵심요금제 할인 종료, 핵심요금제 할인 삭제 요청 2496
                                            if([239, 240, 241, 242, 243, 244  ].includes(+objectCombineWD.wd_id) ){ // + 1,100 won
                                                // T_Giga인터넷라이트 500M
                                                // T_Giga인터넷 1G
                                                // Giga프리미엄
                                                // Giga프리미엄x2.5
                                                // Giga프리미엄x5
                                                //  Giga프리미엄x10
                                                wireDiscount.discount_name.push({
                                                    discount: 1100,
                                                    name: '핵심요금제 결합할인'
                                                })
                                                wireDiscount.discount = +wireDiscount.discount + 1100;

                                            }else if([235, 236, 332].includes(+objectCombineWD.wd_id) ){ // + 2,200 won
                                                //Giga 라이트 + WIFI
                                                //GIGA + WIFI
                                                // GIGA 프리미엄 + WIFI 1.7G
                                                wireDiscount.discount_name.push({
                                                    discount: 2200,
                                                    name: '핵심요금제 결합할인'
                                                })
                                                wireDiscount.discount = +wireDiscount.discount + 2200;
                                            }
                                            */
                                        }
                                        
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                        })
                    }
                    break;
                case 3:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[1] : null; // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        let wire_basic_discount_conditions = conditionDiscountValue && conditionDiscountValue.wire_basic_discount_conditions
                            ? conditionDiscountValue.wire_basic_discount_conditions : null; // đi?u ki?n gi?m gia c? b?n co day

                        let checkWireBasicDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wire_basic_discount_conditions, line_discount_cnt);
                        // n?u th?a đi?u ki?n c? b?n
                        if (line_discount_matched && checkWireBasicDiscountCondition) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                        }
                        wireDiscounts.push(wireDiscount);
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[1] : null; // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };
                            let wire_basic_discount_conditions = conditionDiscountValue && conditionDiscountValue.wire_basic_discount_conditions
                                ? conditionDiscountValue.wire_basic_discount_conditions : null; // đi?u ki?n gi?m gia c? b?n co day

                            let checkWireBasicDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wire_basic_discount_conditions, line_discount_cnt);
                            if (i == 0 && checkWireBasicDiscountCondition && objectCombineWD && objectCombineWD.state == 1) { //
                                if (line_discount_matched) {
                                    if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                        wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                    } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                    }
                                }
                                wireDiscounts.push(wireDiscount);
                            }
                        })
                    }
                    break;
                case 6:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device?.condition_value || null;
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        let basic_discount_conditions_group_basic_wire = conditionDiscountValue?.basic_discount_conditions_group_basic_wire;
                        let basic_discount_conditions_group_option_wire = conditionDiscountValue?.basic_discount_conditions_group_option_wire;
                        let basic_discount_condition = conditionDiscountValue?.basic_discount_condition;
                        let KT_premium_2_discount_condition_group = conditionDiscountValue?.KT_premium_2_discount_condition_group;

                        if (plans_line_not_null.length > 0) {
                            let wd_base_discount_price = 0;
                            let base_condition_price = 0;
                            if (basic_discount_conditions_group_basic_wire) {
                                if (basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line && basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line?.unit == "won") {
                                    wd_base_discount_price = basic_discount_conditions_group_basic_wire.line[1].wire_basic_discount_1_line.value;
                                } else if (basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line && basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line?.unit == "percent") {
                                    wd_base_discount_price = +wire_device.wire_device.wd_commitment_fee * +basic_discount_conditions_group_basic_wire.line[1].wire_basic_discount_1_line.value / 100;
                                }
                                wireDiscount.discount = +wd_base_discount_price
                            }
                            let basic_discount_line_matched = _.maxBy(_.filter(basic_discount_condition, (level, i) => { //  line t?ng s? ti?n goi phi v??t đi?u ki?n
                                return sum_price_of_line_discount_cnt >= +level.the_total_basic_cost_of_wireless;
                            }), (lv, i) => {
                                return +lv?.the_total_basic_cost_of_wireless
                            })
                            if (basic_discount_line_matched) { // n?u đ?t đi?u ki?n c? b?n
                                if (basic_discount_line_matched?.the_total_basic_discount_of_wire_1_line?.unit == "won") {
                                    base_condition_price = +basic_discount_line_matched.the_total_basic_discount_of_wire_1_line.value;
                                } else if (basic_discount_line_matched?.the_total_basic_discount_of_wire_1_line?.unit == "percent") {
                                    base_condition_price = (+wire_device.wire_device.wd_commitment_fee * +basic_discount_line_matched.the_total_basic_discount_of_wire_1_line.value / 100);
                                }
                                // wireDiscount.discount = base_condition_price > wd_base_discount_price ? base_condition_price : wd_base_discount_price; // n?u gi?m gia c? b?n nh? h?n thi l?y gi?m gia c? b?n c?a s?n ph?m ban đ?u
                                wireDiscount.discount = +wireDiscount.discount + +base_condition_price;
                            }


                            // Group đi?u gi?m gia KT Premium -2 [19]
                            // let checkKTPremium1DiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, KT_premium_2_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);
                            // if(checkKTPremium1DiscountCondition){ // neu thoa dieu kien KT 2
                            //     let matched_line = KT_premium_2_discount_condition_group?.line?.[1];
                            //     if (matched_line?.wire_basic_discount_1_line?.unit == "won") {
                            //         wireDiscount.discount =   +wireDiscount.discount + +matched_line.wire_basic_discount_1_line.value;
                            //     } else if (matched_line?.wire_basic_discount_1_line?.unit == "percent") {
                            //         wireDiscount.discount =   +wireDiscount.discount + (+wire_device.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_1_line.value / 100);
                            //     }
                            // }
                            if (basic_discount_conditions_group_option_wire) {
                                let matched_line = basic_discount_conditions_group_option_wire?.line?.[1]?.[wireDiscount.option?.wdo_id];
                                if (matched_line?.wire_basic_discount_1_line?.unit == "won") {
                                    wireDiscount.discount = +wireDiscount.discount + +matched_line.wire_basic_discount_1_line.value;
                                } else if (matched_line?.wire_basic_discount_1_line?.unit == "percent") {
                                    wireDiscount.discount = +wireDiscount.discount + (+wire_device.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_1_line.value / 100);
                                }

                            }
                        }

                        if(this.state.options_modal.kt_premium_discount.selected){
                            if(this.state.options_modal.kt_premium_discount.selected.baseLineOptionSelected == "mobile"){
                                let baseDiscount = 5500;
                                console.log("getWireDiscount 함수의 case 6 의 mobile")

                                //if(
                                //    wireDiscount.device.wire_device.wd_name.indexOf("슬림") >= 0 &&
                                //    (+this.state.options_modal.kt_premium_discount.selected.plan.cost_display * 1.1) < 108900
                                //){
                                //    baseDiscount = 3300;
                                //}
                                wireDiscount.discount = +wireDiscount.discount - baseDiscount;

                                if(wireDiscount.discount < 0){
                                    wireDiscount.discount = 0;
                                }
                            }
                        }
                        
                        wireDiscounts.push(wireDiscount);
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD?.condition_value || null;
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: this.state.wire_product.selected.option,
                                discount: 0,
                                discount_name: []
                            };
                            let basic_discount_conditions_group_basic_wire = conditionDiscountValue?.basic_discount_conditions_group_basic_wire;
                            let basic_discount_conditions_group_option_wire = conditionDiscountValue?.basic_discount_conditions_group_option_wire;
                            let basic_discount_condition = conditionDiscountValue?.basic_discount_condition;
                            let KT_premium_2_discount_condition_group = conditionDiscountValue?.KT_premium_2_discount_condition_group;
                            if (plans_line_not_null.length > 0) {
                                if (i == 0 && objectCombineWD && objectCombineWD.state == 1) { //
                                    let wd_base_discount_price = 0;
                                    let base_condition_price = 0;
                                    if (basic_discount_conditions_group_basic_wire) {
                                        if (basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line && basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line?.unit == "won") {
                                            wd_base_discount_price = basic_discount_conditions_group_basic_wire.line[1].wire_basic_discount_1_line.value;
                                        } else if (basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line && basic_discount_conditions_group_basic_wire?.line?.[1]?.wire_basic_discount_1_line?.unit == "percent") {
                                            wd_base_discount_price = +objectCombineWD.wire_device.wd_commitment_fee * +basic_discount_conditions_group_basic_wire.line[1].wire_basic_discount_1_line.value / 100;
                                        }
                                        wireDiscount.discount = +wd_base_discount_price;
                                        if(+wireDiscount.discount > 0) wireDiscount.discount_name.push({ // task flow: 6770
                                            name: "무선결합 할인",
                                            discount: +wd_base_discount_price
                                        })

                                    }


                                    let basic_discount_line_matched = _.maxBy(_.filter(basic_discount_condition, (level, i) => { //  line t?ng s? ti?n goi phi v??t đi?u ki?n
                                        return sum_price_of_line_discount_cnt >= +level.the_total_basic_cost_of_wireless;
                                    }), (lv, i) => {
                                        return +lv?.the_total_basic_cost_of_wireless
                                    })
                                    if (basic_discount_line_matched) { // n?u đ?t đi?u ki?n c? b?n
                                        if (basic_discount_line_matched?.the_total_basic_discount_of_wire_1_line?.unit == "won") {
                                            base_condition_price = +basic_discount_line_matched.the_total_basic_discount_of_wire_1_line.value;
                                        } else if (basic_discount_line_matched?.the_total_basic_discount_of_wire_1_line?.unit == "percent") {
                                            base_condition_price = (+objectCombineWD.wire_device.wd_commitment_fee * +basic_discount_line_matched.the_total_basic_discount_of_wire_1_line.value / 100);
                                        }

                                        // wireDiscount.discount = base_condition_price > wd_base_discount_price ? base_condition_price : wd_base_discount_price; // n?u gi?m gia c? b?n nh? h?n thi l?y gi?m gia c? b?n c?a s?n ph?m ban đ?u
                                        wireDiscount.discount = +wireDiscount.discount + +base_condition_price;
                                        if(+wireDiscount.discount > 0)   wireDiscount.discount_name.push({
                                            name: "총액결합할인",
                                            discount: +base_condition_price
                                        })

                                    }


                                    // Group đi?u gi?m gia KT Premium -2 [19]
                                    // let checkKTPremium1DiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, KT_premium_2_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);

                                    // if(checkKTPremium1DiscountCondition){ // neu thoa dieu kien KT 2
                                    //     let matched_line = KT_premium_2_discount_condition_group?.line?.[1];
                                    //     if (matched_line?.wire_basic_discount_1_line?.unit == "won") {
                                    //         wireDiscount.discount =   +wireDiscount.discount + +matched_line.wire_basic_discount_1_line.value;
                                    //     } else if (matched_line?.wire_basic_discount_1_line?.unit == "percent") {
                                    //         wireDiscount.discount =   +wireDiscount.discount + (+objectCombineWD.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_1_line.value / 100);
                                    //     }
                                    // }
                                    if (basic_discount_conditions_group_option_wire) {
                                        let matched_line = basic_discount_conditions_group_option_wire?.line?.[1]?.[wireDiscount.option?.wdo_id];
                                        if (matched_line?.wire_basic_discount_1_line?.unit == "won") {
                                            wireDiscount.discount = +wireDiscount.discount + +matched_line.wire_basic_discount_1_line.value;
                                        } else if (matched_line?.wire_basic_discount_1_line?.unit == "percent") {
                                            wireDiscount.discount = +wireDiscount.discount + (+objectCombineWD.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_1_line.value / 100);
                                        }
                                    }

                                    if(this.state.options_modal.kt_premium_discount.selected){
                                        if(this.state.options_modal.kt_premium_discount.selected.baseLineOptionSelected == "mobile"){
                                            let baseDiscount = 5500;
                                            console.log("getWireDiscount 함수의 case 6의 else 쪽 mobile")

                                            //if(
                                            //    wireDiscount.device.wire_device.wd_name.indexOf("슬림") >= 0 &&
                                            //    (+this.state.options_modal.kt_premium_discount.selected.plan.cost_display * 1.1) < 108900
                                            //){
                                            //    baseDiscount = 3300;
                                            //}

                                            wireDiscount.discount = +wireDiscount.discount - baseDiscount;
            
                                            if(wireDiscount.discount < 0){
                                                wireDiscount.discount = 0;
                                            }
                                        }
                                    }
                                    
                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                        })
                    }
                    break
                case 16:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = _.find(conditionDiscountValue, val => val.idx == year_selected.idx); // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                            wireDiscounts.push(wireDiscount);
                        }
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        let TVWireDevices = this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify;

                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = _.find(conditionDiscountValue, val => val.idx == year_selected.idx); // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0,
                                discount_name: []
                            };
                            if (i == 0 && objectCombineWD && objectCombineWD.state == 1) { // ch? gi?m gia s?n ph?m line 1
                                if (line_discount_matched) {
                                    if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                        wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                    } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                    }
                                    if(wireDiscount.discount > 0 ) wireDiscount.discount_name.push({
                                        discount: +wireDiscount.discount,
                                        name: combineDiscount.name
                                    })
                                    
                                    if(_.find(TVWireDevices, (wire, i) => {

                                        return wire.device.wd_name.includes('B tv All');
                                    } )){ //task flow 6769 (https://primarynet.flow.team/flow_layout.act)
                                        //  "S?n ph?m TV ALL tr? len"
                                        /* SKT 유선상담화면 핵심요금제 할인 종료, 핵심요금제 할인 삭제 요청 2496
                                        if([239, 240, 241, 242, 243, 244  ].includes(+objectCombineWD.wd_id) ){ // + 1,100 won
                                            // T_Giga인터넷라이트 500M
                                            // T_Giga인터넷 1G
                                            // Giga프리미엄
                                            // Giga프리미엄x2.5
                                            // Giga프리미엄x5
                                            //  Giga프리미엄x10
                                            wireDiscount.discount_name.push({
                                                discount: 1100,
                                                name: '핵심요금제 결합할인'
                                            })
                                            wireDiscount.discount = +wireDiscount.discount + 1100;

                                        }else if([235, 236, 332].includes(+objectCombineWD.wd_id) ){ // + 2,200 won
                                            //Giga 라이트 + WIFI
                                            //GIGA + WIFI
                                            // GIGA 프리미엄 + WIFI 1.7G
                                            wireDiscount.discount_name.push({
                                                discount: 2200,
                                                name: '핵심요금제 결합할인'
                                            })
                                            wireDiscount.discount = +wireDiscount.discount + 2200;
                                        }
                                        */
                                    }

                                    wireDiscounts.push(wireDiscount);
                                }
                            }

                        })
                    }
                    break
                case 19: //SKT 요즘가족결합 
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) {
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched && !_.isEmpty(wire_device)) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                        }
                        wireDiscounts.push(wireDiscount);
                    } else {
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;

                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0,
                                discount_name: []

                            };
                            if (i == 0) {
                                if (objectCombineWD && objectCombineWD.state == 1) { // n?u s?n ph?m co ap d?ng gi?m gia
                                    if (line_discount_matched) {
                                        if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                        }
                                        else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                        }

                                        if(wireDiscount.discount > 0 ){
                                            wireDiscount.discount_name.push({
                                                discount: +wireDiscount.discount,
                                                name: combineDiscount.name
                                            });
                                        } 
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                        });
                    }
                    break;
                case 8:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue?.line?.[1];// chi co 1 line
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                            wireDiscounts.push(wireDiscount);
                        }
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value && objectCombineWD.condition_value.basic_condition ? objectCombineWD.condition_value.basic_condition : null;
                            line_discount_matched = conditionDiscountValue?.line?.[1];// chi co 1 line
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };
                            if (i == 0 && objectCombineWD && objectCombineWD.state == 1 && line_discount_matched && plans_line_not_null.length >= 1) { // nhi?u h?n 1 goi phi
                                if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                    wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                    wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                }
                                wireDiscounts.push(wireDiscount);
                            }
                        })
                    }
                    break
                case 9:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue?.line?.[1];// chi co 1 line
                        let wireless_add_discount_condition = conditionDiscountValue?.wireless_add_discount_condition;
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        let checkWireBasicDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                        if (wireless_add_discount_condition) {
                            if (checkWireBasicDiscountCondition) {
                                if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                    wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                    wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                }
                                wireDiscounts.push(wireDiscount);
                            }
                        }

                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD?.condition_value?.basic_condition || null;
                            line_discount_matched = conditionDiscountValue?.line?.[1];// chi co 1 line
                            let wireless_add_discount_condition = conditionDiscountValue?.wireless_add_discount_condition;

                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };

                            let checkWireBasicDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                            if (wireless_add_discount_condition) {
                                if (i == 0 && objectCombineWD && objectCombineWD.state == 1 && line_discount_matched && checkWireBasicDiscountCondition) {
                                    if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                        wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                    } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                        })
                    }
                    break
                case 10:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value.basic_condition ? wire_device.condition_value.basic_condition : null;
                        line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched && !_.isEmpty(wire_device)) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                            let wire_add_discount_condition = conditionDiscountValue?.wire_add_discount_condition || null; // đi?u ki?n gi?m gia them co day
                            let checkWireAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wire_add_discount_condition, line_discount_cnt);
                            // n?u th?a đi?u ki?n gi?m gia them
                            if (line_discount_matched && checkWireAddDiscountCondition) {
                                if (line_discount_matched.wire_add_discount_1_line && line_discount_matched.wire_add_discount_1_line.unit == "won") {
                                    wireDiscount.discount = +wireDiscount.discount + +line_discount_matched.wire_add_discount_1_line.value;
                                } else if (line_discount_matched.wire_add_discount_1_line && line_discount_matched.wire_add_discount_1_line.unit == "percent") {
                                    wireDiscount.discount = +wireDiscount.discount + (+wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_add_discount_1_line.value / 100);
                                }
                            }
                            wireDiscounts.push(wireDiscount);
                        }
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD?.condition_value?.basic_condition || null;
                            line_discount_matched = conditionDiscountValue && conditionDiscountValue.line ? conditionDiscountValue.line[line_discount_cnt] : null; // đi?u ki?n trung kh?p
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };
                            if (i == 0 && objectCombineWD && objectCombineWD.state == 1 && line_discount_matched) {
                                if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                    wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                                } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                    wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                                }

                                let wire_add_discount_condition = conditionDiscountValue?.wire_add_discount_condition || null; // đi?u ki?n gi?m gia them co day

                                let checkWireAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wire_add_discount_condition, line_discount_cnt);
                                // n?u th?a đi?u ki?n gi?m gia them
                                if (checkWireAddDiscountCondition) {
                                    if (line_discount_matched.wire_add_discount_1_line && line_discount_matched.wire_add_discount_1_line.unit == "won") {
                                        wireDiscount.discount = +wireDiscount.discount + +line_discount_matched.wire_add_discount_1_line.value;
                                    } else if (line_discount_matched.wire_add_discount_1_line && line_discount_matched.wire_add_discount_1_line.unit == "percent") {
                                        wireDiscount.discount = +wireDiscount.discount + (+objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_add_discount_1_line.value / 100);
                                    }
                                }
                                wireDiscounts.push(wireDiscount);
                            }
                        });
                    }
                    break;
                case 13:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device?.condition_value || null;
                        line_discount_matched = conditionDiscountValue?.basic_discount_conditions_2?.line?.[1];// chi co 1 line
                        let wireDiscount = {
                            device: wire_device,
                            option: this.state.wire_product.selected.option,
                            discount: 0
                        };
                        if (line_discount_matched) {
                            if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "won") {
                                wireDiscount.discount = +line_discount_matched.wire_basic_discount_1_line.value;
                            } else if (line_discount_matched.wire_basic_discount_1_line && line_discount_matched.wire_basic_discount_1_line.unit == "percent") {
                                wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_1_line.value / 100;
                            }
                            wireDiscounts.push(wireDiscount);
                        }
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            let line_discount_matched_cd_base = objectCombineWD?.condition_value?.basic_discount_conditions_2?.line?.[1] || null;
                            let line_discount_matched_cd_3 = objectCombineWD?.condition_value?.basic_discount_conditions_3 || null;
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };
                            if (objectCombineWD && objectCombineWD.state == 1) {
                                if (i == 0 && line_discount_matched_cd_base) {
                                    if (line_discount_matched_cd_base.wire_basic_discount_1_line && line_discount_matched_cd_base.wire_basic_discount_1_line.unit == "won") {
                                        wireDiscount.discount = +line_discount_matched_cd_base.wire_basic_discount_1_line.value;
                                    } else if (line_discount_matched_cd_base.wire_basic_discount_1_line && line_discount_matched_cd_base.wire_basic_discount_1_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched_cd_base.wire_basic_discount_1_line.value / 100;
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                                if (i == 1 && line_discount_matched_cd_3) {
                                    let matched_line = _.find(line_discount_matched_cd_3, wire => wire.wd_id == objectCombineWD.wd_id)
                                    if (matched_line) {
                                        if (matched_line.wire_basic_discount_2_line && matched_line.wire_basic_discount_2_line.unit == "won") {
                                            wireDiscount.discount = +matched_line.wire_basic_discount_2_line.value;
                                        } else if (matched_line.wire_basic_discount_2_line && matched_line.wire_basic_discount_2_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_2_line.value / 100;
                                        }
                                        wireDiscounts.push(wireDiscount);
                                    }
                                }
                                if (i == 2 && line_discount_matched_cd_3) {
                                    let matched_line = _.find(line_discount_matched_cd_3, wire => wire.wd_id == objectCombineWD.wd_id)
                                    if (matched_line) {
                                        if (matched_line.wire_basic_discount_3_line && matched_line.wire_basic_discount_3_line.unit == "won") {
                                            wireDiscount.discount = +matched_line.wire_basic_discount_3_line.value;
                                        } else if (matched_line.wire_basic_discount_3_line && matched_line.wire_basic_discount_3_line.unit == "percent") {
                                            wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +matched_line.wire_basic_discount_3_line.value / 100;
                                        }
                                        wireDiscounts.push(wireDiscount);
                                    }
                                }
                            }
                        })
                    }
                    break
                case 14: // LG Home2 HanBang
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device?.condition_value || null;
                        let line_discount_cnt = plans_line_not_null.length > wire_device?.discount_line_cnt ? wire_device?.discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let basic_discount_conditions_1 = conditionDiscountValue?.basic_discount_conditions_1 || null;
                        let basic_discount_conditions_2 = conditionDiscountValue?.basic_discount_conditions_2 || null;
                        let basic_discount_conditions_3 = conditionDiscountValue?.basic_discount_conditions_3 || null;
                        if (!_.isEmpty(wire_device)) {
                            let wireDiscount = {
                                device: wire_device,
                                option: this.state.wire_product.selected.option,
                                discount: 0
                            };
                            let active_condition_group = [];
                            if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_1?.wireless_basic_discount_conditions, line_discount_cnt)) {
                                active_condition_group.push(basic_discount_conditions_1?.line?.[line_discount_cnt])
                            }
                            ;
                            if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_2?.wireless_basic_discount_conditions, line_discount_cnt)) {
                                active_condition_group.push(basic_discount_conditions_2?.line?.[line_discount_cnt])
                            }
                            ;
                            if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_3?.wire_wireless_basic_discount_condition, line_discount_cnt)) {
                                active_condition_group.push(basic_discount_conditions_3?.line?.[line_discount_cnt])
                            }
                            ;
                            let wire_price = +wire_device.wire_device.wd_commitment_fee;
                            let sum_price_discount = _.sumBy(active_condition_group, (condition) => {
                                let price_discount = 0;
                                if (condition?.wire_basic_discount_1_line?.unit == "won") {
                                    price_discount = +condition?.wire_basic_discount_1_line?.value
                                }
                                if (condition?.wire_basic_discount_1_line?.unit == "percent") {
                                    price_discount = +wire_price * +(condition?.wire_basic_discount_1_line?.value / 100)
                                }
                                return +price_discount
                            })
                            wireDiscount.discount = +sum_price_discount;
                            if (wireDiscount.discount > 0) wireDiscounts.push(wireDiscount);
                        }

                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;
                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD?.condition_value || null;
                            let line_discount_cnt = plans_line_not_null.length > wire_device?.discount_line_cnt ? wire_device?.discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                            let basic_discount_conditions_1 = conditionDiscountValue?.basic_discount_conditions_1 || null;
                            let basic_discount_conditions_2 = conditionDiscountValue?.basic_discount_conditions_2 || null;
                            let basic_discount_conditions_3 = conditionDiscountValue?.basic_discount_conditions_3 || null;


                            if (i == 0 && objectCombineWD && objectCombineWD.state == 1) { // ch? gi?m gia s?n ph?m line 1
                                let wireDiscount = {
                                    device: objectCombineWD,
                                    option: wd.option,
                                    discount: 0
                                };
                                let active_condition_group = [];
                                if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_1?.wireless_basic_discount_conditions, line_discount_cnt)) {
                                    active_condition_group.push(basic_discount_conditions_1?.line?.[line_discount_cnt])
                                }
                                ;
                                if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_2?.wireless_basic_discount_conditions, line_discount_cnt)) {
                                    active_condition_group.push(basic_discount_conditions_2?.line?.[line_discount_cnt])
                                }
                                ;
                                if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_3?.wire_wireless_basic_discount_condition, line_discount_cnt)) {
                                    active_condition_group.push(basic_discount_conditions_3?.line?.[line_discount_cnt])
                                }
                                ;
                                let wire_price = +objectCombineWD.wire_device.wd_commitment_fee;
                                let sum_price_discount = _.sumBy(active_condition_group, (condition) => {
                                    let price_discount = 0;
                                    if (condition?.wire_basic_discount_1_line?.unit == "won") {
                                        price_discount = +condition?.wire_basic_discount_1_line?.value
                                    }
                                    if (condition?.wire_basic_discount_1_line?.unit == "percent") {
                                        price_discount = +wire_price * +(condition?.wire_basic_discount_1_line?.value / 100)
                                    }
                                    return +price_discount
                                })
                                wireDiscount.discount = +sum_price_discount;
                                if (wireDiscount.discount > 0) wireDiscounts.push(wireDiscount);
                            }
                        })
                    }
                    break
                case 17:
                case 18:
                    if (this.props.adviceType == ADVICE_TYPE.WIRELESS) { // t? v?n khong day
                        conditionDiscountValue = wire_device && wire_device.condition_value && wire_device.condition_value ? wire_device.condition_value : combineDiscount.condition_default;
                        if(wire_device && conditionDiscountValue){
                            discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                            let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                            let basic_condition = conditionDiscountValue?.basic_condition;
                            let line_discount_matched = _.find(basic_condition, (val)=>{
                                return +val.value == line_discount_cnt
                            });
                            let wireDiscount = {
                                device: wire_device,
                                option: this.state.wire_product.selected.option,
                                discount: 0
                            };

                            if (line_discount_matched) {
                                if (line_discount_matched.wire_basic_discount && line_discount_matched.wire_basic_discount.unit == "won") {
                                    wireDiscount.discount = +line_discount_matched.wire_basic_discount.value;
                                } else if (line_discount_matched.wire_basic_discount && line_discount_matched.wire_basic_discount.unit == "percent") {
                                    wireDiscount.discount = +wire_device.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount.value / 100;
                                }
                                if (wireDiscount.device.state == 0) {
                                    wireDiscount.discount = 0
                                }
                                wireDiscounts.push(wireDiscount);
                            }
                        }
                    } else {// t? v?n co day
                        let dataWireDeviceByCombineID = this.state.wire_product.dataWireDeviceByCombineID;

                        _.map(internetWireDevices, (wd, i) => {
                            let objectCombineWD = _.find(dataWireDeviceByCombineID, (combine, i) => {
                                return wd.device && wd.device.wd_id == combine.wd_id;
                            })
                            conditionDiscountValue = objectCombineWD && objectCombineWD.condition_value ? objectCombineWD.condition_value : combineDiscount.condition_default;
                            discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                            let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                            let basic_condition = conditionDiscountValue?.basic_condition;
                            let line_discount_matched = _.find(basic_condition, (val)=>{
                                return +val.value == line_discount_cnt
                            });
                            let wireDiscount = {
                                device: objectCombineWD,
                                option: wd.option,
                                discount: 0
                            };
                            let arr_id_not_apply = [306, 307];

                            if (i == 0 && objectCombineWD && objectCombineWD.state == 1) {
                                if (line_discount_matched) {
                                    if (line_discount_matched.wire_basic_discount && line_discount_matched.wire_basic_discount.unit == "won") {
                                        if(arr_id_not_apply.includes(objectCombineWD.wire_device.wd_id) == false)
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount.value;
                                    } else if (line_discount_matched.wire_basic_discount && line_discount_matched.wire_basic_discount.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount.value / 100;
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }
                            if (i == 1 && objectCombineWD && objectCombineWD.state == 1) { // ch? gi?m gia s?n ph?m line 1
                                if (line_discount_matched) {
                                    if (line_discount_matched.wire_basic_discount_2_line && line_discount_matched.wire_basic_discount_2_line.unit == "won") {
                                        if(arr_id_not_apply.includes(objectCombineWD.wire_device.wd_id) == false && objectCombineWD.wire_device.wd_name.includes('100MB') == false)
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_2_line.value;
                                    } else if (line_discount_matched.wire_basic_discount_2_line && line_discount_matched.wire_basic_discount_2_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_2_line.value / 100;
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }

                            if (i == 2 && objectCombineWD && objectCombineWD.state == 1) { // ch? gi?m gia s?n ph?m line 1
                                if (line_discount_matched) {
                                    if (line_discount_matched.wire_basic_discount_3_line && line_discount_matched.wire_basic_discount_3_line.unit == "won") {
                                        if(arr_id_not_apply.includes(objectCombineWD.wire_device.wd_id) == false && objectCombineWD.wire_device.wd_name.includes('100MB') == false)
                                            wireDiscount.discount = +line_discount_matched.wire_basic_discount_3_line.value;
                                    } else if (line_discount_matched.wire_basic_discount_3_line && line_discount_matched.wire_basic_discount_3_line.unit == "percent") {
                                        wireDiscount.discount = +objectCombineWD.wire_device.wd_commitment_fee * +line_discount_matched.wire_basic_discount_3_line.value / 100;
                                    }
                                    wireDiscounts.push(wireDiscount);
                                }
                            }

                        })
                    }
                    break

            }
            this.setState({
                wire_product: {
                    ...this.state.wire_product,
                    wireDiscount: wireDiscounts,
                },
            })
        } else {
            this.setState({
                wire_product: {
                    ...this.state.wire_product,
                    wireDiscount: [],
                },
                options_modal: {
                    discount_indicated: {
                        selected: null
                    },
                    tv_plus: {
                        selected: {
                            type: null,
                            line: null
                        }
                    },
                    sub_years: {
                        selected: null
                    },
                    kt_number_line_wire_combine: {
                        selected: null
                    },
                    lgu_patron: {
                        selected: null
                    },
                    u_tv: {
                        selected: false
                    },
                    over_2_lines_combine: {
                        selected: false
                    },
                    tv_standard: {
                        selected: false
                    },
                    kt_premium_discount: {
                        selected: null
                    },
                    kt_premium_discount_except: {
                        selected: null
                    },
                    teenagers_promotion: {
                        selected: []
                    },
                    nanum_discount: {
                        selected: []
                    },
                    kt_teenagers_promotion: {
                        selected: []
                    }
                },
            })
        }
    }
    updateDiscountWireLessPrice = async () => { // update c? gia ti?n cam k?t c?a goi phi hi?n t?i va goi phi d? ki?n
        await this.getDiscountWireLessPrice();
        await this.getDiscountWireLessPriceExpectedPlan()
    }
    getDiscountWireLessPrice = (type = "") => {
        /** k đ??c thay đ?i**/
        let conditionCombine = this.state.discount_condition.discount_condition_selected;
        let adviceType = this.props.adviceType; // lo?i t? v?n: khong day hay co day
        let discount_line_cnt = this.state.plan_modal.discount_line_cnt; // s? line t?i đa đ??c ap d?ng gi?m gia
        let line_can_select_cnt = this.state.plan_modal.line_can_select_cnt; // s? plan co th? ch?n
        let wire_device = this.state.wire_product.selected;
        let plans_line = [...this.state.plan_modal.plans]; // danh sach cac line plan
        let welfareDiscount = this.props.adviceType == ADVICE_TYPE.WIRELESS ? this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount : null ;
        let optionalAgreement = this.props.adviceType == ADVICE_TYPE.WIRELESS ?  this.props.WirelessAdvice.WirelessAdviceStep3?.optionalAgreement : null ;
        let promo_cost = this.props.adviceType == ADVICE_TYPE.WIRELESS ? this.props.WirelessAdvice.WirelessAdviceStep3?.promotion?.promo_cost : null ;
        if(type === "expected"){
            let dataPlanNewDateChange = {};
            if ("id" in this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange) {
                dataPlanNewDateChange = {
                    ...this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange,
                    class: this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange?.plan_classification?.class || this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange.class
                };
            } else if ("plac_id" in this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange && this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange?.plac_id) {
                dataPlanNewDateChange = {
                    ...this.props.WirelessAdvice?.WirelessAdviceStep3?.newPlanData, //
                    class: this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange?.class,
                    name: this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange?.name || this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange.plac_name,
                    planCustom: this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange,
                    // cost_display: this.props.WirelessAdvice.WirelessAdviceStep3.basicCost ? (+this.props.WirelessAdvice.WirelessAdviceStep3.basicCost * 10 / 11) : 0
                    cost_display: this.props.WirelessAdvice.WirelessAdviceStep3.dataPlanNewDateChange?.cost_display || 0
                }
            }
            plans_line[0] = {
                disabled: false,
                discount_condition_name: [],
                discount_wireless_price: 0,
                idx: 1,
                is_commitment: true,
                plan : dataPlanNewDateChange
            }
            welfareDiscount = this.props.WirelessAdvice.WirelessAdviceStep3?.welfareDiscountExpectedPlan || null;
            optionalAgreement = this.props.WirelessAdvice.WirelessAdviceStep3?.optionalAgreementPlanEstimated || 0;
        }
        let new_plans_line = plans_line;
        let line_discount_matched = null;
        let conditionDiscountValue;
        let year_selected = null;
        let kt_number_line_wire_combine_selected = null;

        //console.log("this.props.WirelessAdvice");
        //console.log(this.props.WirelessAdvice);
        //console.log("this.state");
        //console.log(this.state);
        
        /**  end k đ??c thay đ?i**/
        if (conditionCombine && wire_device) {
            /** k đ??c thay đ?i**/
            let condition_id = conditionCombine.id;
            let plans_line_not_null = _.reject(plans_line, {plan: null});// s? plan đa ch?n
            /**  end k đ??c thay đ?i**/

            switch (condition_id) {
                case 1: // SKT Plan c? gia đinh
                    conditionDiscountValue = wire_device?.wire_device?.condition_value?.basic_condition || null;
                    if (conditionDiscountValue) {
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        line_discount_matched = conditionDiscountValue.line?.[line_discount_cnt] || null; // đi?u ki?n trung kh?p
                        let discount_price_each_plan = 0;

                        /* gi?m gia c? b?n */
                        discount_price_each_plan = line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "won"  // n?u d?n v? la won => s? ti?n gi?m gia m?i line = s? ti?n gi?m gia / s? line đ??c gi?m gia
                            ? +line_discount_matched.wireless_basic_discount.value / +line_discount_cnt : discount_price_each_plan;
                        if (line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "percent") {
                            let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                let cost_display = +plan.plan.cost_display;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }
                                
                                return cost_display + cost_display * 10 / 100;
                            });
                            discount_price_each_plan = +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_basic_discount.value) / 100 / +line_discount_cnt;
                        }

                        /* end gi?m gia c? b?n */

                        /* gi?m gia them
                            n?u co wireless_add_discount_condition.all_wireless_number_line goi phi thoa đi?u kien 1 trong 6 lo?i goi phi ()
                         */
                        let wireless_add_discount_condition = conditionDiscountValue && conditionDiscountValue.wireless_add_discount_condition
                            ? conditionDiscountValue.wireless_add_discount_condition : null; // đi?u ki?n gi?m gia them khong day
                        let checkAddDiscountCondition = false; // check đi?u ki?n gi?m gia them
                        if (wireless_add_discount_condition) { // n?u s? line đa ch?n l?n h?n s? line ap d?ng đi?u ki?n them
                            checkAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                            if (checkAddDiscountCondition) {
                                if (line_discount_matched && line_discount_matched.wireless_add_discount && line_discount_matched.wireless_add_discount.unit == "won") {
                                    discount_price_each_plan += (+line_discount_matched.wireless_add_discount.value / +line_discount_cnt);
                                } else if (line_discount_matched && line_discount_matched.wireless_add_discount && line_discount_matched.wireless_add_discount.unit == "percent") {
                                    let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                        let cost_display = +plan.plan.cost_display;

                                        if(plan.plan.plac_id){
                                            let planId = plan.plan.id;

                                            plan.plan.planCustom.plan.map((planInfo) => {
                                                if(planInfo.id == planId){
                                                    cost_display = +planInfo.cost_display;
                                                }
                                            });
                                        }

                                        return cost_display + cost_display * 10 / 100;
                                    });
                                    discount_price_each_plan += +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_add_discount.value) / 100 / +line_discount_cnt;
                                }
                            }
                        }

                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                let TVplusPrice = 0;
                                if (checkAddDiscountCondition && !_.find(new_discount_condition_name, wireless_add_discount_condition)
                                    && +line_discount_matched.wireless_add_discount.value !== 0 // n?u line < 2 thi hi?n th? ten gi?m gia them, l?n h?n 2 thi k hi?n th? :  https://docs.google.com/presentation/d/1Nk5se66qp2qAkTAZYrbFD6JFzMBeb4lWvYiAZkODcPA/edit#slide=id.gd44b1e4dff_0_156
                                ) {
                                    new_discount_condition_name.push(wireless_add_discount_condition);   // th?a đi?u ki?n them
                                }
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/


                                /** n?u co ch?n gi?m gia TVPlus**/
                                if (this.state.options_modal.tv_plus.selected.type) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                        // new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "TVPLUS");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia tvplus => + them gia ti?n gi?m gia vao line đo tuy theo option
                                        TVplusPrice = (+this.state.options_modal.tv_plus.selected.type.price)
                                        plan.discount_wireless_price = plan.discount_wireless_price + TVplusPrice;
                                        new_discount_condition_name.push(this.state.options_modal.tv_plus.selected.type);
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }

                                /** n?u co ch?n gi?m gia ch? đ?nh **/
                                if (this.state.options_modal.discount_indicated.selected) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "INDICATED");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                        // plan.discount_wireless_price = discount_price_each_plan * line_discount_cnt + +plan.discount_wireless_price;
                                        plan.discount_wireless_price = discount_price_each_plan * line_discount_cnt + TVplusPrice;
                                        new_discount_condition_name.push({
                                            name: "지정할인",
                                            type: "OPTION",
                                            code: "INDICATED"
                                        });
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_wireless_price = this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx == plan.idx ? TVplusPrice : 0;
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 2: // SKT free c? gia đinh


                    conditionDiscountValue =  wire_device?.wire_device?.condition_value?.basic_condition || null;

                    if (conditionDiscountValue) {
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        line_discount_matched = conditionDiscountValue.line[line_discount_cnt]; // đi?u ki?n trung kh?p
                        let discount_price_each_plan = 0;

                        /* gi?m gia c? b?n */
                        discount_price_each_plan = line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "won"  // n?u d?n v? la won => s? ti?n gi?m gia m?i line = s? ti?n gi?m gia / s? line đ??c gi?m gia
                            ? +line_discount_matched.wireless_basic_discount.value / +line_discount_cnt : discount_price_each_plan;
                        if (line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "percent") {
                            let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                let cost_display = +plan.plan.cost_display;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                return cost_display + cost_display * 10 / 100;
                            });
                            discount_price_each_plan = +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_basic_discount.value) / 100 / +line_discount_cnt;
                        }
                        /* end gi?m gia c? b?n */

                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/

                                /** c?ng d?n gia ti?n vao 1 line đ?u tien  **/
                                if (plan.idx == 1) {
                                    plan.discount_wireless_price = discount_price_each_plan * line_discount_cnt;
                                } else {
                                    plan.discount_wireless_price = 0;
                                }
                                /** end c?ng d?n gia ti?n vao 1 line đ?u tien  **/


                                /** n?u co ch?n gi?m gia TVPlus**/
                                if (this.state.options_modal.tv_plus.selected.type) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "TVPLUS");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia tvplus => + them gia ti?n gi?m gia vao line đo tuy theo option
                                        plan.discount_wireless_price = plan.discount_wireless_price + (+this.state.options_modal.tv_plus.selected.type.price);
                                        new_discount_condition_name.push(this.state.options_modal.tv_plus.selected.type);
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }
                                /** end  **/

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 3: // SKT giam gia c? gia đinh
                    conditionDiscountValue = wire_device?.wire_device?.condition_value?.basic_condition || null;
                    if (conditionDiscountValue) {
                        line_discount_matched = conditionDiscountValue.line[1]; // ch? co 1 line
                        let line_discount_cnt = 1; // s? line đ??c ap d?ng gi?m gia ch? 1 line
                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/

                                /** n?u co ch?n gi?m gia TVPlus**/
                                if (this.state.options_modal.tv_plus.selected.type) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "TVPLUS");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia tvplus => + them gia ti?n gi?m gia vao line đo tuy theo option
                                        plan.discount_wireless_price = plan.discount_wireless_price + (+this.state.options_modal.tv_plus.selected.type.price);
                                        new_discount_condition_name.push(this.state.options_modal.tv_plus.selected.type);
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }
                                /** end  **/

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 4: // SKT giam gia c? gia đinh
                    conditionDiscountValue = this.state.discount_condition.discount_condition_selected && this.state.discount_condition.discount_condition_selected.condition_default ? this.state.discount_condition.discount_condition_selected.condition_default : null;
                    year_selected = this.state.options_modal.sub_years.selected;
                    if (conditionDiscountValue && conditionDiscountValue.state == 1 && year_selected) { // đi?u ki?n ph?i đ??c ap d?ng
                        discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let discount_price_each_plan = 0;
                                let basicCost = +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            basicCost = +planInfo.cost_display + +planInfo.cost_display * 10 / 100;
                                        }
                                    });
                                }

                                if (index === 0 && welfareDiscount && [3, 4, 5].includes(welfareDiscount.id)) {
                                    let welfareDiscountCost = welfareDiscount?.cost || 0;
                                    basicCost = +basicCost - +welfareDiscountCost
                                }

                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/

                                /** n?u co ch?n gi?m gia theo s? n?m đ?ng ky **/
                                if (this.state.options_modal.sub_years.selected) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == this.state.options_modal.sub_years.selected.code);
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/
                                    let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);

                                    discount_price_each_plan = year_selected.price_condition[planTypeName].unit == "won" ? +year_selected.price_condition[planTypeName].value : discount_price_each_plan;
                                    discount_price_each_plan = year_selected.price_condition[planTypeName].unit == "percent" ? basicCost * +year_selected.price_condition[planTypeName].value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }
                                /** end  **/

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 16: // Whole family discount
                    // conditionDiscountValue = this.state.discount_condition.discount_condition_selected && this.state.discount_condition.discount_condition_selected.condition_default  ? this.state.discount_condition.discount_condition_selected.condition_default : null;
                    conditionDiscountValue = wire_device.wire_device && wire_device.wire_device.condition_value
                    && wire_device.wire_device.condition_value.basic_condition
                        ? wire_device.wire_device.condition_value.basic_condition
                        : null;
                    year_selected = this.state.options_modal.sub_years.selected;
                    if (conditionDiscountValue && wire_device.wire_device.state == 1 && year_selected) { // đi?u ki?n ph?i đ??c ap d?ng
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/

                                /** n?u co ch?n gi?m gia theo s? n?m đ?ng ky **/
                                if (this.state.options_modal.sub_years.selected) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == this.state.options_modal.sub_years.selected.code);
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/
                                    let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);

                                    discount_price_each_plan = year_selected.price_condition[planTypeName].unit == "won" ? +year_selected.price_condition[planTypeName].value : discount_price_each_plan;
                                    //discount_price_each_plan = year_selected.price_condition[planTypeName].unit == "percent" ? (+plan.plan.cost_display + +plan.plan.cost_display * 10 / 100) * +year_selected.price_condition[planTypeName].value / 100 : discount_price_each_plan;
                                    let costDisplay = +plan.plan.cost_display;

                                    if(plan.plan.plac_id){
                                        let planId = plan.plan.id;
    
                                        plan.plan.planCustom.plan.map((planInfo) => {
                                            if(planInfo.id == planId){
                                                costDisplay = +planInfo.cost_display;
                                            }
                                        });
                                    }

                                    costDisplay = costDisplay + costDisplay * 10 / 100;

                                    if (index === 0 && welfareDiscount && [3, 4, 5].includes(welfareDiscount.id)) {
                                        let welfareDiscountCost = welfareDiscount?.cost || 0;
                                        costDisplay = +costDisplay - +welfareDiscountCost;
                                    }

                                    discount_price_each_plan = year_selected.price_condition[planTypeName].unit == "percent" ? costDisplay * +year_selected.price_condition[planTypeName].value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }
                                /** end  **/

                                /** n?u co ch?n gi?m gia TVPlus**/
                                if (this.state.options_modal.tv_plus.selected.type) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "TVPLUS");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.tv_plus.selected.line && this.state.options_modal.tv_plus.selected.line.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia tvplus => + them gia ti?n gi?m gia vao line đo tuy theo option
                                        plan.discount_wireless_price = plan.discount_wireless_price + (+this.state.options_modal.tv_plus.selected.type.price);
                                        new_discount_condition_name.push(this.state.options_modal.tv_plus.selected.type);
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }
                                /** end  **/

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 19: // SKT 요즘가족결합 
                    conditionDiscountValue =  wire_device?.wire_device?.condition_value?.basic_condition || null;

                    if (conditionDiscountValue) {
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        line_discount_matched = conditionDiscountValue.line[line_discount_cnt]; // đi?u ki?n trung kh?p
                        let discount_price_each_plan = 0;

                        discount_price_each_plan = line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "won"  // n?u d?n v? la won => s? ti?n gi?m gia m?i line = s? ti?n gi?m gia / s? line đ??c gi?m gia
                            ? +line_discount_matched.wireless_basic_discount.value / +line_discount_cnt : discount_price_each_plan;
                        if (line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "percent") {
                            let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                let cost_display = +plan.plan.cost_display;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                return cost_display + cost_display * 10 / 100;
                            });
                            discount_price_each_plan = +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_basic_discount.value) / 100 / +line_discount_cnt;
                        }
                        
                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { 
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;

                                if (this.state.options_modal.discount_indicated.selected) {                                        
                                    if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) {
                                        plan.discount_wireless = [];
                                        plan.discount_wireless_price = discount_price_each_plan * line_discount_cnt;

                                        plan.discount_wireless.push({
                                            'name' : conditionCombine?.name || "",
                                            'discount' : discount_price_each_plan * line_discount_cnt
                                        });
                                        
                                        new_discount_condition_name.push({
                                            name: "지정할인",
                                            type: "OPTION",
                                            code: "INDICATED"
                                        });

                                        plan.discount_condition_name = new_discount_condition_name;

                                    }
                                    else {
                                        plan.discount_wireless_price = 0;
                                        plan.discount_wireless = [];
                                    }
                                }
                                else{
                                    plan.discount_wireless = [];
                                }
                                
                                count++;
                            }

                            return plan
                        })
                    }
                    break;
                case 5: //KT K?t h?p gia đinh (k?t h?p khong day)
                    conditionDiscountValue = this.state.discount_condition?.discount_condition_selected?.condition_default || null;
                    kt_number_line_wire_combine_selected = this.state.options_modal.kt_number_line_wire_combine.selected;
                    if (conditionDiscountValue && conditionDiscountValue.state == 1 && kt_number_line_wire_combine_selected && conditionDiscountValue.KT_number_of_lines_wireless_combined) { // đi?u ki?n ph?i đ??c ap d?ng
                        // discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 1;
                        discount_line_cnt = 1; // ch? ap d?ng line 1
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;

                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/

                                /** n?u co ch?n gi?m gia theo s? n?m đ?ng ky **/
                                    // let plan_class = plan.plan_class;
                                let cost_display = +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display * 1.1;
                                        }
                                    });
                                }

                                cost_display = cost_display - +(this.props.WirelessAdvice.WirelessAdviceStep3.promotion.promo_cost || 0);

                                let welfareDiscountCost = 0;

                                if([3, 4, 5].includes(welfareDiscount?.id)){
                                    welfareDiscountCost = welfareDiscount.cost;
                                }

                                if(!this.props?.WirelessAdvice?.WirelessAdviceStep3?.optionFee && index == 0){ // task flow : 6813
                                    cost_display = cost_display - +welfareDiscountCost - +optionalAgreement; // - +promo_cost;
                                }
                                else{
                                    cost_display = cost_display - +welfareDiscountCost;
                                }

                                let plan_class = plan?.plan?.class;
                                let line_matched = kt_number_line_wire_combine_selected[Object.keys(kt_number_line_wire_combine_selected)[0]];
                                
                                if (plan_class) {
                                    discount_price_each_plan = line_matched[plan_class].wireless_basic_discount.unit == "won" ? +line_matched[plan_class].wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = line_matched[plan_class].wireless_basic_discount.unit == "percent" ? +cost_display * +(line_matched[plan_class].wireless_basic_discount.value) / 100 : discount_price_each_plan;

                                    plan.discount_wireless_price = discount_price_each_plan;
                                }
                                /** end  **/

                                plan.discount_wireless = [];

                                count++;
                            }

                            return plan
                        })
                    }
                    break;
                case 6: //Gi?m gia k?t h?p t?ng s? ti?n - Total combine discount //KT 총액 결합 할인
                //console.log("케이스 6번")
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;
                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng

                        // 업무번호 3593 KT 총액결합 2회선 추가
                        /*if(this.state.check_kt_premium == 1 && (plans_line.length == 5 || plans_line.length == 7)){ // KT 프리미엄이 선택되었고, 5,7회선일때 읽는 활성화를 7회선으로 증가
                            discount_line_cnt = 7;
                        }*/

                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia //요금제가 적용된 회선 수
                        //console.log("case 6 line_discount_cnt", line_discount_cnt)
                        //console.log("case 6 discount_line_cnt", discount_line_cnt)
                        //console.log("case 6 plans_line_not_null.length", plans_line_not_null.length)
                        
                        // 업무번호 3593 KT 총액결합 2회선 추가
                        /*if(this.state.check_kt_premium == 1 && (plans_line.length == 5 || plans_line.length == 7)){ // 활성화를 7회선으로 증가
                            line_discount_cnt = 7;
                        }*/

                        let count = 1;

                        let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                            let cost_display = +plan.plan.cost_display;

                            if(plan.plan.plac_id){
                                let planId = plan.plan.id;

                                plan.plan.planCustom.plan.map((planInfo) => {
                                    if(planInfo.id == planId){
                                        cost_display = +planInfo.cost_display;
                                    }
                                });
                            }

                            return plan.idx <= line_discount_cnt ? +((cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2) : 0;
                        });

                        let basic_discount_condition = conditionDiscountValue?.basic_discount_condition;
                        let basic_discount_conditions_group_basic_wire = conditionDiscountValue?.basic_discount_conditions_group_basic_wire;
                        let basic_discount_conditions_group_option_wire = conditionDiscountValue?.basic_discount_conditions_group_option_wire;
                        let KT_premium_1_discount_condition_group = conditionDiscountValue?.KT_premium_1_discount_condition_group;
                        let KT_premium_2_discount_condition_group = conditionDiscountValue?.KT_premium_2_discount_condition_group;

                        let basic_discount_line_matched = _.maxBy(_.filter(basic_discount_condition, (level, i) => { //  line t?ng s? ti?n goi phi v??t đi?u ki?n
                            return sum_price_of_line_discount_cnt >= +level.the_total_basic_cost_of_wireless;
                        }), (lv, i) => {
                            return +lv?.the_total_basic_cost_of_wireless
                        })

                        let checkKTPremium1DiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);
                        let wire_wireless_basic_discount_condition = null;
                        let activeKTPremiumPlans = [];
                        let nonActiveKTPremiumPlans = [];
                        let sum_price_of_none_active_KT_premium = 0;
                        let ktPremiumDiscountSelectedIdx = this.state.options_modal.kt_premium_discount?.selected?.idx || 0;

                        if(checkKTPremium1DiscountCondition && this.state.options_modal.kt_premium_discount.selected){ //대상이고 사용자 선택 시
                            let tempInt                = 0;
                            let baseIndex              = -1;
                            let plansLineNotNullLength = plans_line_not_null.length;

                            for(tempInt = 0; tempInt < plansLineNotNullLength; tempInt++){
                                let planInfo = plans_line_not_null[tempInt];

                                if(planInfo.idx == ktPremiumDiscountSelectedIdx){
                                    baseIndex = tempInt;

                                    break;
                                }
                            }

                            let firstPlanCostDisplay = +plans_line_not_null[baseIndex]?.plan?.cost_display + +(plans_line_not_null[baseIndex]?.plan?.cost_display * 0.1);
                            let firstPlanId          = +plans_line_not_null[baseIndex]?.plan?.id;

                            if(firstPlanCostDisplay >= 77000){
                                if(firstPlanId == 2989 || firstPlanId == 3702){
                                    if((this.state.error_modal?.expectedId || -1) != firstPlanId){ //동일한 요금제인지 확인 -> 동일하면 계속 창이 띄워지기 때문
                                        this.setState({
                                            error_modal: {
                                                isShowModal: true,
                                                message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>),
                                                expectedId : firstPlanId
                                            }
                                        });
                                    }

                                    checkKTPremium1DiscountCondition = false; //프리미엄 할인 불가
                                    this.onSelectOptionDiscount(null, 'kt_premium_discount');
                                }
                            }
                            else if(_.find(this.state.options_modal.kt_premium_discount_except.selected, i => i == baseIndex) == false){ // 77000이 아닐때 신규인지 기존인지 확인 -> 해당되는 것이 아닐때
                                if((this.state.error_modal?.expectedId || -1) != firstPlanId){
                                    this.setState({
                                        error_modal: {
                                            isShowModal: true,
                                            message: (<span className="notification_delete">프리미엄할인 조건을 확인해주세요</span>),
                                            expectedId : firstPlanId
                                        }
                                    });
                                }

                                checkKTPremium1DiscountCondition = false;
                                this.onSelectOptionDiscount(null, 'kt_premium_discount');
                            }
                            else if(ktPremiumDiscountSelectedIdx == 1){ //첫번째 회선인지 확인 -> 무선상담에서 kT프리미엄 할인 적용이 불가한 것
                                checkKTPremium1DiscountCondition = false;

                                if(type !== "expected"){ //메인
                                    this.onSelectOptionDiscount(null, 'kt_premium_discount');
                                }
                                else{ //변경 요금제
                                    if((this.state.error_modal?.expectedId || -1) != firstPlanId){
                                        this.setState({
                                            error_modal: {
                                                isShowModal: true,
                                                message: (<span className="notification_delete">변경 후 요금제가 프리미엄할인 조건에 충족되지 않아 균등할인으로 적용됩니다.</span>),
                                                expectedId : firstPlanId
                                            }
                                        });
                                    }
                                }
                            }
                        }
                        else if(checkKTPremium1DiscountCondition == false && this.state.options_modal.kt_premium_discount.selected && type === "expected"){ //대상 아니고, 선택되었고, 변경 요금제일때
                            let tempInt                = 0;
                            let baseIndex              = -1;
                            let plansLineNotNullLength = plans_line_not_null.length;
                            let firstPlanId            = 0;

                            for(tempInt = 0; tempInt < plansLineNotNullLength; tempInt++){
                                let planInfo = plans_line_not_null[tempInt];

                                if(planInfo.idx == ktPremiumDiscountSelectedIdx){
                                    baseIndex = tempInt;

                                    break;
                                }
                            }

                            firstPlanId = +plans_line_not_null[baseIndex]?.plan?.id;

                            if((this.state.error_modal?.expectedId || -1) != firstPlanId){
                                this.setState({
                                    error_modal: {
                                        isShowModal: true,
                                        message: (<span className="notification_delete">변경 후 요금제가 프리미엄할인 조건에 충족되지 않아 균등할인으로 적용됩니다.</span>),
                                        expectedId : firstPlanId
                                    }
                                });
                            }
                        }

                        if (checkKTPremium1DiscountCondition) { //프리미엄 할인 적용
                            wire_wireless_basic_discount_condition = KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition;
                            
                            activeKTPremiumPlans = _.filter(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => { // list plan active KT premium 1
                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
                                let kt_premium_except_plan = [
                                    2989, // 5G 심플
                                    3702, // 5G 심플 복지
                                ]
                                if(this.state.discount_condition.discount_condition_selected.id == 6 && plan.plan && kt_premium_except_plan.includes(+plan.plan.id)) return false; // BA yeu c?u b? active KT-premium  goi phi 5G 심플
                                return (+plan?.plan?.cost_display + +plan?.plan?.cost_display * 10 / 100) >= +wire_wireless_basic_discount_condition[planTypeName]
                            })
                            nonActiveKTPremiumPlans = _.filter(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => { // list plan  khong active KT premium 1
                                if (!_.find(activeKTPremiumPlans, (activePlan, i) => {
                                    return plan?.idx == activePlan.idx
                                })) {
                                    return plan;
                                }
                            });

                            if (nonActiveKTPremiumPlans.length > 0) {
                                if (this.state.options_modal.kt_premium_discount.selected) {
                                    //nonActiveKTPremiumPlans.push(this.state.options_modal.kt_premium_discount.selected);

                                    let isExist = false;

                                    nonActiveKTPremiumPlans.map((plan) => {
                                        if(plan.idx == ktPremiumDiscountSelectedIdx){
                                            isExist = true;
                                        }
                                    });

                                    if(isExist == false){
                                        plans_line_not_null.map((plan) => {
                                            if(plan.idx == ktPremiumDiscountSelectedIdx){
                                                nonActiveKTPremiumPlans.push(plan);
                                            }
                                        });
                                    }
                                }

                                sum_price_of_none_active_KT_premium = _.sumBy(nonActiveKTPremiumPlans, (plan, i) => {
                                    //return plan.idx <= line_discount_cnt ? +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100 : 0;
                                    let result = 0;

                                    if(plan.idx <= line_discount_cnt){
                                        //result = +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100;
                                        result = ((+plan.plan.cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2);
                                    }

                                    return +result;
                                });

                                //console.log("nonActiveKTPremiumPlans sum_price_of_none_active_KT_premium", sum_price_of_none_active_KT_premium);

                                if (this.state.options_modal.kt_premium_discount_except.selected) {
                                    let ktPreminumDiscountSet = new Set(this.state.options_modal.kt_premium_discount_except.selected);
                                    let ktPreminumDiscountArr = [...ktPreminumDiscountSet];

                                    ktPreminumDiscountArr.map((except) => {
                                        let indexPlan = plans_line[except];
                                        
                                        //sum_price_of_none_active_KT_premium = sum_price_of_none_active_KT_premium - (+indexPlan.plan.cost_display * 1.1);

                                        if(indexPlan && indexPlan.idx != ktPremiumDiscountSelectedIdx){
                                            sum_price_of_none_active_KT_premium = sum_price_of_none_active_KT_premium - Number(((+indexPlan.plan.cost_display - +(indexPlan.plan?.discost_24 || 0))* 1.1).toFixed(2));
                                        }
                                    });
                                }
                            }
                            else{
                                let tempInt                = 0;
                                let baseIndex              = -1;
                                let plansLineNotNullLength = plans_line_not_null.length;

                                for(tempInt = 0; tempInt < plansLineNotNullLength; tempInt++){
                                    let planInfo = plans_line_not_null[tempInt];

                                    if(planInfo.idx == ktPremiumDiscountSelectedIdx){
                                        baseIndex = tempInt;

                                        break;
                                    }
                                }

                                sum_price_of_none_active_KT_premium = +plans_line_not_null[baseIndex]?.plan?.cost_display + +(plans_line_not_null[baseIndex]?.plan?.cost_display * 0.1);
                            }
                        }
                        //end Group đi?u gi?m gia KT Premium -1 [19]

                        let next_line_base_line = 0;
                        let currentPercent = 0;
                        //console.log("getDiscountWireLessPrice 의 plans_line 과 plan 값 확인")
                        //console.log("getDiscountWireLessPrice 의 plans_line 과 plan 값 확인", plans_line)

                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                let discount_price_each_plan = 0;
                                let KT_premium_1_discount = 0;
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                // let price_plan = +plan.plan.cost_display + +plan.plan.cost_display * 10 / 100;
                                // let price_plan = ((+plan.plan.cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2); // tr? đi ti?n gi?m gia cam k?t 24 thang (task 6909)
                                let cost_display = +plan.plan.cost_display;
                                let price_plan = ((+cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2); // tr? đi ti?n gi?m gia cam k?t 24 thang (task 6909) 

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });

                                    price_plan = ((+cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2);
                                }

                                let percentage = 0;

                                if (index === line_discount_cnt - 1) { //결합할인 처음 적용 시 1회선에만 적용되기 때문에 currentPercent는 0, 전체 percent는 따라서 100
                                    percentage = (100.00 - +currentPercent).toFixed(2)
                                } else { //초기 1 회선이 아닌 경우에 해당 과정으로 진입
                                    percentage = (+price_plan * 100 / +sum_price_of_line_discount_cnt).toFixed(2);
                                    //console.log("percantage 계산으로 추정 중", percentage)
                                    //console.log("percantage 계산으로 추정 중", +price_plan)
                                    //console.log("percantage 계산으로 추정 중", +sum_price_of_line_discount_cnt)
                                    currentPercent = +currentPercent + +percentage;
                                }

                                let total_discount = 0;

                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "won") {
                                    total_discount = +basic_discount_line_matched.the_total_basic_discount_of_wireless.value;
                                }
                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "percent") {
                                    total_discount = +sum_price_of_line_discount_cnt * (+basic_discount_line_matched.the_total_basic_discount_of_wireless.value) / 100;
                                }

                                plan.discount_wireless_price = percentage * total_discount / 100;
                                new_discount_condition_name.push({
                                    name: `기여도할인 (${percentage}%)`,
                                    type: "OPTION",
                                    code: "KT_PREMIUM_DISCOUNT",
                                    percentage: percentage
                                });

                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                /** n?u actice KT premium 1  **/
                                if (checkKTPremium1DiscountCondition) {
                                    //console.log("checkKTPremium1DiscountCondition이 참일때")
                                    //console.log("checkKTPremium1DiscountCondition이 참일때 KT_premium_1_discount_condition_group?.line : ", KT_premium_1_discount_condition_group?.line)
                                    let linesDiscount = KT_premium_1_discount_condition_group?.line; //회선 2개를 추가해도 변동없이 5개의 플랜으로 유지 중

                                    //console.log("checkKTPremium1DiscountCondition이 참일때", linesDiscount)
                                    //if(this.state.check_kt_premium == 1){ //해당 부분은 필요없다고 판단 중
                                    //    linesDiscount = 7;
                                    //}
                                    
                                    //console.log("checkKTPremium1DiscountCondition이 참일때", linesDiscount)
                                    let linesDiscountWithoutBaseline = _.filter(linesDiscount, (line, index) => { // đi?u ki?n k ph?i base line
                                        return index != 1
                                    })

                                    //console.log("linesDiscountWithoutBaseline : ", linesDiscountWithoutBaseline)
                                    let matchedLine = null;
                                    new_discount_condition_name = [];

                                    if (this.state.options_modal.kt_premium_discount.selected) { // n?u apply KT premium
                                        let percentage_non_actice = (+price_plan * 100 / +sum_price_of_none_active_KT_premium).toFixed(2);
                                        let total_discount_non_actice = 0;
                                        //if (nonActiveKTPremiumPlans.length > 0) {
                                            basic_discount_line_matched = _.maxBy(_.filter(basic_discount_condition, (level, i) => { //  line t?ng s? ti?n goi phi khong v??t đi?u ki?n
                                                return sum_price_of_none_active_KT_premium >= +level.the_total_basic_cost_of_wireless;
                                            }), (lv, i) => {
                                                return +lv?.the_total_basic_cost_of_wireless
                                            })
                                        //}

                                        if (ktPremiumDiscountSelectedIdx == plan.idx) { // n?u la base line
                                            new_discount_condition_name.push({
                                                name: `베이스회선`,
                                                type: "OPTION",
                                                code: "KT_PREMIUM_DISCOUNT"
                                            });
                                            let activeKTPremiumPlansFilterLength = _.filter(activeKTPremiumPlans, p => p.idx != plan.idx).length;
                                            let ktPremiumDiscountExceptLength    = 0;

                                            if (this.state.options_modal.kt_premium_discount_except.selected) {
                                                let baseIndex              = -1;
                                                let tempInt                = 0;
                                                let plansLineNotNullLength = plans_line_not_null.length;

                                                ktPremiumDiscountExceptLength = this.state.options_modal.kt_premium_discount_except.selected.length;
                                                
                                                for(tempInt = 0; tempInt < plansLineNotNullLength; tempInt++){
                                                    let planInfo = plans_line_not_null[tempInt];

                                                    if(planInfo.idx == ktPremiumDiscountSelectedIdx){
                                                        baseIndex = tempInt;

                                                        break;
                                                    }
                                                }

                                                if(_.find(this.state.options_modal.kt_premium_discount_except.selected, i => i == baseIndex)){
                                                    ktPremiumDiscountExceptLength = ktPremiumDiscountExceptLength - 1;
                                                }
                                            }

//                                            if ((plans_line_not_null.length - 1) == (activeKTPremiumPlansFilterLength + ktPremiumDiscountExceptLength)) {// n?u t?t c? cac line tr? base line đ?u active
//                                                matchedLine = linesDiscount[1];
//                                                new_discount_condition_name.push(KT_premium_1_discount_condition_group.wire_wireless_basic_discount_condition);
//                                                // 베이스 회선은 프리미엄 결합이 아닌 총액결합할인을 받는다고 해서 이전 소스 주석처리 start!
//                                                //if (matchedLine?.wireless_basic_discount?.unit == "won") {
//                                                //    KT_premium_1_discount = +matchedLine?.wireless_basic_discount?.value;
//                                                //}
//                                                //if (matchedLine?.wireless_basic_discount?.unit == "percent") {
//                                                //    KT_premium_1_discount = +price_plan * (+matchedLine.wireless_basic_discount.value) / 100;
//                                                //}
//                                                // 베이스 회선은 프리미엄 결합이 아닌 총액결합할인을 받는다고 해서 이전 소스 주석처리 end!
//
//                                                basic_discount_line_matched = _.maxBy(_.filter(basic_discount_condition, (level, i) => { //  line t?ng s? ti?n goi phi khong v??t đi?u ki?n
//                                                    return (+plan.plan.cost_display + +plan.plan.cost_display * 10 / 100) >= +level.the_total_basic_cost_of_wireless;
//                                                }), (lv, i) => {
//                                                    return +lv?.the_total_basic_cost_of_wireless
//                                                })
//
//                                                console.log("basic_discount_line_matched : " + basic_discount_line_matched);
//
//                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "won") {
//                                                    KT_premium_1_discount = +basic_discount_line_matched.the_total_basic_discount_of_wireless.value;
//                                                }
//                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "percent") {
//                                                    KT_premium_1_discount = +sum_price_of_none_active_KT_premium * (+basic_discount_line_matched?.the_total_basic_discount_of_wireless.value) / 100;
//                                                }
//                                            }
//                                            else { // n?u trong s? cac line đa ch?n, t?n t?i line k đ??c actice => quay v? tinh theo m?c gi?m gia c? b?n
                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "won") {
                                                    total_discount_non_actice = +basic_discount_line_matched.the_total_basic_discount_of_wireless.value;
                                                }
                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "percent") {
                                                    total_discount_non_actice = +sum_price_of_none_active_KT_premium * (+basic_discount_line_matched?.the_total_basic_discount_of_wireless.value) / 100;
                                                }

                                                KT_premium_1_discount = +percentage_non_actice * total_discount_non_actice / 100;
                                                new_discount_condition_name.push({
                                                    name: `기여도할인 (${percentage_non_actice}%)`,
                                                    type: "OPTION",
                                                    code: "KT_PREMIUM_DISCOUNT",
                                                    percentage: percentage_non_actice
                                                });
//                                            }
                                            /* 하드코딩 제외 처리
                                            let except_discount_plans = [
                                                3005, 3013, 3699, 3025, 3698, // 초이스 스페셜, 초이스 프리미엄, 초이스 스페셜 (현대카드), 초이스 스페셜 (넷플릭스), 초이스 프리미엄 (현대카드)
                                                2979, 2856, 2770, 3718, 3717, // 초이스 프리미엄 (넷플릭스), 순 완전무한99 (3G), LTE 데이터 선택 109 (신규가입불가), 초이스 스페셜 (바디프랜드), 초이스 프리미엄 (바디프랜드)
                                                3786, 3787, 3789, 3788, 3810, // 초이스 스페셜(디바이스), 초이스 프리미엄(디바이스), 초이스 스페셜 (유튜브 프리미엄), 초이스 프리미엄 (유튜브 프리미엄), 초이스 스페셜 (티빙/지니)
                                                3811, 3772, 3773, 3775, 3776, // 초이스 프리미엄(티빙/지니), 초이스 스페셜(삼성), 초이스 프리미엄 (삼성), 초이스 스페셜 (우리카드), 초이스 프리미엄 (우리카드)
                                                3778, 3779, 3822, 3823, 3850, // 초이스 스페셜 (시즌/지니+), 초이스 프리미엄 (시즌/지니+), 초이스 스페셜 (BC바로 카드), 초이스 프리미엄(BC바로 카드), 초이스 스페셜(코웨이)
                                                3851, 3872, 3873 // 초이스 프리미엄(코웨이), 초이스 스페셜 (티빙/밀리), 초이스 프리미엄(티빙/밀리)
                                            ]
                                            if(except_discount_plans.includes(+plan.plan.id)){
                                                if([
                                                    263, 266, 272, 278, 356, //인터넷 슬림, 인터넷 슬림 와이파이, 패밀리 인터넷 슬림, 인터넷 슬림 와이드, 가족안심 인터넷슬림
                                                    363, 369, 376, 380 // 가족안심 인터넷 슬림 와이파이, 안심인터넷 슬림, 안심 인터넷 슬림 와이파이, 안심 인터넷 슬림 와이드
                                                ].includes(+wire_device.wire_device.wd_id)){
                                                    KT_premium_1_discount = 14300;
                                                }
                                                else{
                                                    KT_premium_1_discount = 16610;
                                                }
                                                // 기존에 주석 처리 되었있었음 start!
                                                //else if([
                                                //    264, 265, 267, 268, 269, //인터넷 베이직, 인터넷 에센스, 인터넷 베이직 와이파이, 인터넷 에센스 와이파이, 인터넷 프리미엄
                                                //    270, 271, 273, 274, 275, //인터넷 프리미엄플러스, 인터넷 슈퍼프리미엄, 패밀리 인터넷 베이직, 패밀리 인터넷 에센스, 패밀리 인터넷 슬림 와이파이
                                                //    276, 277, 278, 279, 280, //패밀리 인터넷 베이직 와이파이, 패밀리 인터넷 에센스 와이파이, 인터넷 슬림 와이드, 인터넷 베이직 와이드, 인터넷 에센스 와이드
                                                //    281 // 인터넷 프리미엄 와이드
                                                //].includes(+wire_device.wire_device.wd_id)){
                                                //    KT_premium_1_discount = 16610;
                                                //}
                                                // 기존에 주석 처리 되었있었음 end!
                                            }
                                            */
                                            if(this.state.options_modal.kt_premium_discount.selected.baseLineOptionSelected == "mobile"){
                                                let baseDiscount = 5500;
                                                console.log("getDiscountWIreLessPrice 함수의 case 6 mobile")

                                                //if(
                                                //    this.state.wire_product.wireDiscount[0].device.wire_device.wd_name.indexOf("슬림") >= 0 &&
                                                //    (+this.state.options_modal.kt_premium_discount.selected.plan.cost_display * 1.1) < 108900
                                                //){
                                                //    baseDiscount = 3300;
                                                //}
    
                                                KT_premium_1_discount = KT_premium_1_discount + baseDiscount;
                                            }
                                        }
                                        else {
                                            if (_.find(activeKTPremiumPlans, p => plan.idx == p.idx)) { // n?u goi phi active

                                                // 업무번호 3593 KT 총액결합 2회선 추가
                                                /*if(next_line_base_line > 3){ //프리미엄 결합 조건에 적용되는 요금제가 5개 이상일 때 그 이상의 금액에도 값을 할당하기 위함
                                                    next_line_base_line = 3;
                                                }*/
                                                matchedLine = linesDiscountWithoutBaseline[next_line_base_line];
                                                
                                                //console.log("프리미엄 기타 회선 금액 조회 1")
                                                //console.log("프리미엄 기타 회선 금액 조회 1 activeKTPremiumPlans: ", activeKTPremiumPlans)
                                                //console.log("프리미엄 기타 회선 금액 조회 1 next_line_base_line :", next_line_base_line)
                                                //console.log("프리미엄 기타 회선 금액 조회 1 matchedLine :", matchedLine)


                                                if (matchedLine?.wireless_basic_discount?.unit == "won") {
                                                    KT_premium_1_discount = +matchedLine?.wireless_basic_discount?.value;
                                                    //console.log("+matchedLine?.wireless_basic_discount?.value", +matchedLine?.wireless_basic_discount?.value)
                                                }
                                                if (matchedLine?.wireless_basic_discount?.unit == "percent") {
                                                    KT_premium_1_discount = +price_plan * (+matchedLine.wireless_basic_discount.value) / 100;
                                                    //console.log("+price_plan * (+matchedLine.wireless_basic_discount.value) / 100", +price_plan * (+matchedLine.wireless_basic_discount.value) / 100)
                                                }
                                                next_line_base_line++;
                                                new_discount_condition_name.push(KT_premium_1_discount_condition_group.wire_wireless_basic_discount_condition);
                                            }
                                            else if(_.find(this.state.options_modal.kt_premium_discount_except.selected, i => i == index)){
                                                //console.log("프리미엄 기타 회선 금액 조회 2")
                                                let pricePlan = ((+plan.plan.cost_display - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2);
                                                KT_premium_1_discount = pricePlan * 0.25;
                                                new_discount_condition_name = [{name: "총액결합할인(이전결합)", type: "OPTION", code: "KT_PREMIUM_DISCOUNT"}];
                                            }
                                            else { // n?u goi phi khong active
                                                //console.log("프리미엄 기타 회선 금액 조회 3")
                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "won") {
                                                    total_discount_non_actice = +basic_discount_line_matched.the_total_basic_discount_of_wireless.value;
                                                }
                                                if (basic_discount_line_matched?.the_total_basic_discount_of_wireless?.unit == "percent") {
                                                    total_discount_non_actice = +sum_price_of_none_active_KT_premium * (+basic_discount_line_matched?.the_total_basic_discount_of_wireless.value) / 100;
                                                }

                                                KT_premium_1_discount = percentage_non_actice * total_discount_non_actice / 100;

                                                new_discount_condition_name.push({
                                                    name: `기여도할인 (${percentage_non_actice}%)`,
                                                    type: "OPTION",
                                                    code: "KT_PREMIUM_DISCOUNT",
                                                    percentage: percentage_non_actice
                                                });
                                            }
                                        }
                                        plan.discount_wireless_price = KT_premium_1_discount;
                                        plan.discount_condition_name = new_discount_condition_name;

                                        //console.log("kT 프리미엄 할인이 가능한 요금제이면서, 베이스 회선이 아닐때의 값인듯? : ", plan.discount_wireless_price)
                                        //console.log("kT 프리미엄 할인이 가능한 요금제이면서, 베이스 회선이 아닐때의 이름인듯? : ", plan.discount_condition_name)
                                    }

                                }
                                /** end n?u actice KT premium 1  **/
                                plan.discount_wireless = [];

                                count++;
                            }

                            if (this.state.options_modal.kt_teenagers_promotion.selected) {
                                var index = _.findIndex(this.state.options_modal.kt_teenagers_promotion.selected, {idx: plan.idx});

                                if(index >= 0 && plan.plan){
                                    let planPlan                     = plan.plan;
                                    let planPlanPlanClassification   = planPlan.plan_classification;
                                    let planPlanPlanClassificationId = +planPlanPlanClassification.id;
                                    let planPlanCostDisplay          = +(planPlan.cost_display * 1.1);

                                    if(
                                        (planPlanPlanClassificationId == "93" || planPlanPlanClassificationId == "94" || planPlanPlanClassificationId == "160") &&
                                        planPlanCostDisplay >= 80000
                                    ){
                                        plan.discount_wireless_price =  +plan.discount_wireless_price + 5500;
                                        plan.discount_condition_name.push({
                                            name: `청소년추가할인 -5,500원 할인`,
                                            type: "OPTION",
                                            code: "KT_TEENAGERS_PROMOTION"
                                        });
                                    }
                                }
                            }

                            return plan
                        })

                        /*
                        if (this.state.options_modal.kt_premium_discount_except.selected) {
                            let tempInt                       = 0;
                            let ktPremiumDiscountExceptLength = this.state.options_modal.kt_premium_discount_except.selected.length;
                            let tempIndex                     = 0;
                            let plan                          = null;
                            let discountConditionName         = null;
                            let costDisplay                   = 0;
                            let pricePlan                     = 0;

                            for(tempInt = 0; tempInt < ktPremiumDiscountExceptLength; tempInt++){
                                tempIndex             = this.state.options_modal.kt_premium_discount_except.selected[tempInt];
                                plan                  = new_plans_line[tempIndex];
                                discountConditionName = [{name: "총액결합할인(이전결합)", type: "OPTION", code: "KT_PREMIUM_DISCOUNT"}];
                                costDisplay           = +plan.plan.cost_display;
                                pricePlan             = ((+costDisplay - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2); // tr? đi ti?n gi?m gia cam k?t 24 thang (task 6909)

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            costDisplay = +planInfo.cost_display;
                                        }
                                    });

                                    pricePlan = ((+costDisplay - +(plan.plan?.discost_24 || 0))* 1.1).toFixed(2);
                                }

                                plan.discount_wireless_price = pricePlan * 0.25;
                                plan.discount_condition_name = discountConditionName;
                            }
                        }
                        */

                        // /** n?u co ch?n gi?m gia ch? đ?nh **/
                        if (this.state.options_modal.discount_indicated.selected) {
                            let price_sum = _.sumBy(new_plans_line, plan => plan.discount_wireless_price);
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let new_discount_condition_name = [];
                                if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                    plan.discount_wireless_price = price_sum;
                                    new_discount_condition_name.push({name: "지정할인", type: "OPTION", code: "INDICATED"});
                                    plan.discount_condition_name = new_discount_condition_name;
                                } else {
                                    plan.discount_wireless_price = 0;
                                    plan.discount_condition_name = new_discount_condition_name;
                                }
                                return plan
                            })
                        }
                    }
                    break;
                case 7: //KT K?t h?p gia đinh (k?t h?p khong day)
                    conditionDiscountValue = this.state.discount_condition?.discount_condition_selected?.condition_default || null;
                    let KT_number_of_lines_wireless_combined = conditionDiscountValue?.KT_number_of_lines_wireless_combined || [];
                    if (conditionDiscountValue && conditionDiscountValue.state == 1 && _.size(KT_number_of_lines_wireless_combined) > 0) { // đi?u ki?n ph?i đ??c ap d?ng
                        discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia

                        let count = 1;
                        let dataCheck = KT_number_of_lines_wireless_combined?.line?.[line_discount_cnt];
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                let cost_display = +plan.plan.cost_display;
                                let plan_price = cost_display + cost_display * 10 / 100;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });

                                    plan_price = cost_display + cost_display * 10 / 100;
                                }

                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
                                let checkLine = _.maxBy(_.filter(dataCheck, (level, i) => {
                                    return plan_price >= +level.condition[planTypeName];
                                }), (lv, i) => {
                                    return +lv.condition[planTypeName]
                                })
                                if (checkLine) {
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "won" ? +checkLine.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "percent" ? +plan_price * +checkLine.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 8: //KT K?t h?p Olle2
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;

                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                        // discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia

                        let count = 1;
                        let dataCheck = conditionDiscountValue?.KT_number_of_lines_wireless_combined?.line;

                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                plan.discount_condition_name = new_discount_condition_name;

                                let cost_display = +plan.plan.cost_display;
                                let plan_price = cost_display + cost_display * 10 / 100;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });

                                    plan_price = cost_display + cost_display * 10 / 100;
                                }

                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);

                                let checkLine = _.maxBy(_.filter(dataCheck, (level, i) => {
                                    return plan_price >= +level.condition[planTypeName];
                                }), (lv, i) => {
                                    return +lv.condition[planTypeName]
                                })

                                if (checkLine) {
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "won" ? +checkLine.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "percent" ? +plan_price * +checkLine.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 9: //KT k?t h?p premium single
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;
                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                        let line_discount_cnt = 1; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;
                        let basic_discount_line = conditionDiscountValue?.basic_condition?.line?.[1];
                        let wireless_add_discount_condition = conditionDiscountValue?.basic_condition?.wireless_add_discount_condition;
                        if (wireless_add_discount_condition && basic_discount_line) {
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let discount_price_each_plan = 0;
                                if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                    let new_discount_condition_name = [];
                                    plan.discount_wireless_price = discount_price_each_plan;
                                    /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                    plan.discount_condition_name = new_discount_condition_name;
                                    let checkWireBasicDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                                    if (checkWireBasicDiscountCondition) {
                                        let cost_display = +plan.plan.cost_display;
                                        let plan_price = cost_display + cost_display * 10 / 100;

                                        if(plan.plan.plac_id){
                                            let planId = plan.plan.id;
        
                                            plan.plan.planCustom.plan.map((planInfo) => {
                                                if(planInfo.id == planId){
                                                    cost_display = +planInfo.cost_display;
                                                }
                                            });
        
                                            plan_price = cost_display + cost_display * 10 / 100;
                                        }

                                        discount_price_each_plan = basic_discount_line.wireless_basic_discount.unit == "won" ? +basic_discount_line.wireless_basic_discount.value : discount_price_each_plan;
                                        discount_price_each_plan = basic_discount_line.wireless_basic_discount.unit == "percent" ? +plan_price * +basic_discount_line.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                        let plan_except = [ // Yeu c?u hardcoding chi phi gi?m gia goi phi 5G심플 (5G simple), 5G심플 복지 (5G simple phuc l?i) nh? sau: đ?i " 17,250원 "  thanh " 0원 " 
                                            2989, // 5G심플
                                            3702, // 5G심플 복지 
                                        ]
                                        if (plan_except.includes(+plan?.plan?.id)) {
                                            discount_price_each_plan = 0
                                        }
                                        plan.discount_wireless_price = discount_price_each_plan;
                                        new_discount_condition_name.push(wireless_add_discount_condition)
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }

                                    plan.discount_wireless = [];
                                    count++;
                                }
                                return plan
                            })
                        }
                    }
                    break;
                case 20:
                    let tempInt = 0;

                    if([
                        2994, 2991, 2993, 3836, 2989, // 5G 세이브, 5G 슬림, 5G Y 슬림, 5G 슬림 플러스, 5G 심플 110GB
                        3907, 3908, 3909, 2986, 3000, // 5G 심플 50GB, 5G 심플 70GB, 5G 심플 90GB, 베이직, Y 스페셜
                        3002, 2985, 3821, 3755, 3719, // 스페셜, 초이스 베이직, 초이스 베이직 (BC바로 카드), 초이스 베이직 (디즈니+), 초이스 베이직 (바디프랜드)
                        3771, 3777, 3774, 3790, 3871, // 초이스 베이직 (삼성), 초이스 베이직 (시즌/지니+), 초이스 베이직 (우리카드), 초이스 베이직 (유튜브 프리미엄)
                        3809, 3697, 3011, 3785, 3849, // 초이스 베이직 (티빙/지니), 초이스 베이직 (현대카드), 초이스 베이직(넷플릭스), 초이스 베이직(디바이스), 초이스 베이직(코웨이)
                        3005, 3822, 3025, 3718, 3778, //초이스 스페셜, 초이스 스페셜 (BC바로 카드), 초이스 스페셜 (넷플릭스), 초이스 스페셜 (바디프랜드), 초이스 스페셜 (시즌/지니+)
                        3775, 3789, 3872, 3810, 3699, // 초이스 스페셜 (우리카드), 초이스 스페셜 (유튜브 프리미엄), 초이스 스페셜 (티빙/밀리), 초이스 스페셜 (티빙/지니), 초이스 스페셜 (현대카드)
                        3786, 3756, 3772, 3850, 3013, // 초이스 스페셜(디바이스), 초이스 스페셜(디즈니+), 초이스 스페셜(삼성), 초이스 스페셜(코웨이), 초이스 프리미엄
                        2979, 3757, 3717, 3773, 3779, // 초이스 프리미엄 (넷플릭스), 초이스 프리미엄 (디즈니+), 초이스 프리미엄 (바디프랜드), 초이스 프리미엄 (삼성), 초이스 프리미엄 (시즌/지니+)
                        3776, 3788, 3698, 3823, 3787, // 초이스 프리미엄 (우리카드), 초이스 프리미엄 (유튜브 프리미엄), 초이스 프리미엄 (현대카드), 초이스 프리미엄(BC바로 카드), 초이스 프리미엄(디바이스)
                        3851, 3873, 3811, 2998        //초이스 프리미엄(코웨이), 초이스 프리미엄(티빙/밀리), Y베이직
                    ].includes(+plans_line[0].plan.id)){
                        let cost                  = +plans_line[0].plan.cost_display * 1.1;
                        let discountWirelessPrice = 0;

                        if(cost < 29700){
                            discountWirelessPrice = 1100;
                        }
                        else if(cost >= 29700 && cost < 54890){
                            discountWirelessPrice = 3300;
                        }
                        else if(cost >= 54890 && cost < 73700){
                            discountWirelessPrice = 5500;
                        }
                        else if(cost >= 73700 && cost < 84700){
                            discountWirelessPrice = 7700;
                        }
                        else if(cost >= 84700){
                            discountWirelessPrice = 11000;
                        }

                        plans_line[0].discount_wireless_price = discountWirelessPrice;
                    }
                    else{
                        this.setState({
                            discount_condition: {
                                ...this.state.discount_condition,
                                discount_condition_selected: null,
                                isShowModal: false,
                            },
                            wire_product: {
                                ...this.state.wire_product,
                                isShowModal: false,
                                line_can_select_cnt: 0,
                                discount_line_cnt: 0,
                                wireDiscount: [],
                            },
                            error_modal: {
                                isShowModal: true,
                                message: (<span className="notification_delete">결합을 적용할 수 없습니다.<br/>결합조건을 확인해 주세요</span>)
                            }
                        });
                    }
                    
                    for(tempInt = 1; tempInt < 5; tempInt++){
                        if(plans_line[tempInt].plan){
                            plans_line[tempInt].plan = null;
                        }
                    }

                    new_plans_line = plans_line;
                    
                    break;
                case 21 :
                    let cost                  = +plans_line[0].plan.cost_display * 1.1;
                    let discountWirelessPrice = 0;
                    
                    if(cost < 29700){
                        discountWirelessPrice = 1100;
                    }
                    else if(cost >= 29700 && cost < 54890){
                        discountWirelessPrice = 3300;
                    }
                    else if(cost >= 54890 && cost < 73700){
                        discountWirelessPrice = 5500;
                    }
                    else if(cost >= 73700 && cost < 84700){
                        discountWirelessPrice = 7700;
                    }
                    else if(cost >= 84700){
                        discountWirelessPrice = 11000;
                    }

                    plans_line[0].discount_wireless_price = discountWirelessPrice;

                    for(tempInt = 1; tempInt < 5; tempInt++){
                        if(plans_line[tempInt].plan){
                            plans_line[tempInt].plan = null;
                        }
                    }

                    new_plans_line = plans_line;

                    break;
                case 10:
                    conditionDiscountValue = wire_device?.wire_device?.condition_value?.basic_condition || null;
                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        line_discount_matched = conditionDiscountValue.line[line_discount_cnt]; // đi?u ki?n trung kh?p
                        let discount_price_each_plan = 0;
                        /* gi?m gia c? b?n */
                        discount_price_each_plan = line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "won"  // n?u d?n v? la won => s? ti?n gi?m gia m?i line = s? ti?n gi?m gia / s? line đ??c gi?m gia
                            ? +line_discount_matched.wireless_basic_discount.value / +line_discount_cnt : discount_price_each_plan;
                        if (line_discount_matched && line_discount_matched.wireless_basic_discount && line_discount_matched.wireless_basic_discount.unit == "percent") {
                            let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                let cost_display = +plan.plan.cost_display;

                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                return cost_display + cost_display * 10 / 100;
                            });
                            discount_price_each_plan = +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_basic_discount.value) / 100 / +line_discount_cnt;
                        }
                        /* end gi?m gia c? b?n */

                        /* gi?m gia them
                           n?u co wireless_add_discount_condition.all_wireless_number_line goi phi thoa đi?u kien 1 trong 6 lo?i goi phi ()
                        */
                        let wireless_add_discount_condition = conditionDiscountValue && conditionDiscountValue.wireless_add_discount_condition
                            ? conditionDiscountValue.wireless_add_discount_condition : null; // đi?u ki?n gi?m gia them khong day
                        let checkAddDiscountCondition = {}; // check đi?u ki?n gi?m gia them
                        if (wireless_add_discount_condition) { // n?u s? line đa ch?n l?n h?n s? line ap d?ng đi?u ki?n them
                            // checkAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, discount_price_each_plan, line_discount_matched, wireless_add_discount_condition, line_discount_cnt);
                            // discount_price_each_plan = checkAddDiscountCondition.discount_price_each_plan; //
                            checkAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                            if (checkAddDiscountCondition) {
                                if (line_discount_matched && line_discount_matched.wireless_add_discount && line_discount_matched.wireless_add_discount.unit == "won") {
                                    discount_price_each_plan += (+line_discount_matched.wireless_add_discount.value / +line_discount_cnt);
                                } else if (line_discount_matched && line_discount_matched.wireless_add_discount && line_discount_matched.wireless_add_discount.unit == "percent") {
                                    let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                        let cost_display = +plan.plan.cost_display;

                                        if(plan.plan.plac_id){
                                            let planId = plan.plan.id;

                                            plan.plan.planCustom.plan.map((planInfo) => {
                                                if(planInfo.id == planId){
                                                    cost_display = +planInfo.cost_display;
                                                }
                                            });
                                        }

                                        return cost_display + cost_display * 10 / 100;
                                    });
                                    discount_price_each_plan += +sum_price_of_line_discount_cnt * (+line_discount_matched.wireless_add_discount.value) / 100 / +line_discount_cnt;
                                }
                            }
                        }
                        /* end gi?m gia them khong day */
                        let count = 1;
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                if (checkAddDiscountCondition && !_.find(new_discount_condition_name, wireless_add_discount_condition))
                                    new_discount_condition_name.push(wireless_add_discount_condition);   // th?a đi?u ki?n them
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/


                                /** n?u co ch?n gi?m gia ch? đ?nh **/
                                if (this.state.options_modal.discount_indicated.selected) {
                                    /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                    new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                    let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "INDICATED");
                                    if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                    /** end **/

                                    if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                        plan.discount_wireless_price = discount_price_each_plan * line_discount_cnt;
                                        new_discount_condition_name.push({
                                            name: "지정할인",
                                            type: "OPTION",
                                            code: "INDICATED"
                                        });
                                        plan.discount_condition_name = new_discount_condition_name;
                                    } else {
                                        plan.discount_wireless_price = 0;
                                        plan.discount_condition_name = new_discount_condition_name;
                                    }
                                }
                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 11: // LG 가족무한사랑(유무선신규가입불가)
                    conditionDiscountValue = this.state.discount_condition?.discount_condition_selected?.condition_default || null;
                    let group_condition_group_classification_group_wireless_package_commitment = conditionDiscountValue?.group_condition_group_classification_group_wireless_package_commitment || [];
                    let LGU_long_term_customer_discount = conditionDiscountValue?.LGU_long_term_customer_discount || [];
                    if (conditionDiscountValue && conditionDiscountValue.state == 1 && _.size(group_condition_group_classification_group_wireless_package_commitment) > 0) { // đi?u ki?n ph?i đ??c ap d?ng
                        discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia

                        let count = 1;
                        let dataCheck = group_condition_group_classification_group_wireless_package_commitment?.line?.[line_discount_cnt];
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                let cost_display = +plan.plan.cost_display;
                                
                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                let plan_price = cost_display + cost_display * 10 / 100;                                
                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
                                let checkLine = _.maxBy(_.filter(dataCheck, (level, i) => {
                                    return plan_price >= +level.condition[planTypeName];
                                }), (lv, i) => {
                                    return +lv.condition[planTypeName]
                                })
                                if (checkLine) {
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "won" ? +checkLine.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "percent" ? +plan_price * +checkLine.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }
                                /** n?u co ch?n gi?m gia khach hang than thi?t **/
                                let lgu_patron_price = 0;
                                if (this.state.options_modal.lgu_patron.selected) {
                                    let data_lgu_patron = conditionDiscountValue?.LGU_long_term_customer_discount?.line || {};
                                    if (_.size(data_lgu_patron) > 0) {
                                        let matched_line = _.take(plans_line_not_null, line_discount_cnt).length == 1 ? data_lgu_patron[1] : (_.take(plans_line_not_null, line_discount_cnt).length >= 2 ? data_lgu_patron[2] : null);
                                        let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                            let cost_display = +plan.plan.cost_display;
                                
                                            if(plan.plan.plac_id){
                                                let planId = plan.plan.id;

                                                plan.plan.planCustom.plan.map((planInfo) => {
                                                    if(planInfo.id == planId){
                                                        cost_display = +planInfo.cost_display;
                                                    }
                                                });
                                            }

                                            return cost_display + cost_display * 10 / 100;
                                        });
                                        if (matched_line) {
                                            let new_price = plan.discount_wireless_price;
                                            if (this.state.options_modal.lgu_patron.selected.idx == 1) { //
                                                if (matched_line[1].wireless_basic_discount.unit == "won") {
                                                    lgu_patron_price = +matched_line[1].wireless_basic_discount.value;
                                                }
                                                if (matched_line[1].wireless_basic_discount.unit == "percent") {
                                                    lgu_patron_price = +sum_price_of_line_discount_cnt * +matched_line[1].wireless_basic_discount.value / 100;
                                                }
                                            } else if (this.state.options_modal.lgu_patron.selected.idx == 2) {
                                                if (matched_line[2].wireless_basic_discount.unit == "won") {
                                                    lgu_patron_price = +matched_line[2].wireless_basic_discount.value;
                                                }
                                                if (matched_line[2].wireless_basic_discount.unit == "percent") {
                                                    lgu_patron_price = +sum_price_of_line_discount_cnt * +matched_line[2].wireless_basic_discount.value / 100;
                                                }
                                            }
                                            new_price = new_price + (lgu_patron_price / plans_line_not_null.length);
                                            plan.discount_wireless_price = new_price;
                                        }
                                    }
                                }

                                plan.discount_wireless = [];

                                count++;
                            }

                            return plan
                        })
                        // /** n?u co ch?n gi?m gia ch? đ?nh **/
                        if (this.state.options_modal.discount_indicated.selected) {
                            let price_sum = _.sumBy(new_plans_line, plan => plan.discount_wireless_price);
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let new_discount_condition_name = [];
                                if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                    plan.discount_wireless_price = price_sum;
                                    new_discount_condition_name.push({name: "지정할인", type: "OPTION", code: "INDICATED"});
                                    plan.discount_condition_name = new_discount_condition_name;
                                } else {
                                    plan.discount_wireless_price = 0;
                                    plan.discount_condition_name = new_discount_condition_name;
                                }
                                return plan
                            })
                        }
                    }
                    break;
                case 12: // LG 참쉬운가족결합
                    conditionDiscountValue = this.state.discount_condition?.discount_condition_selected?.condition_default || null;
                    let condition_group = conditionDiscountValue?.group_condition_group_classification_group_wireless_package_commitment || [];
                    if (conditionDiscountValue && conditionDiscountValue.state == 1 && _.size(condition_group) > 0) { // đi?u ki?n ph?i đ??c ap d?ng
                        discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia

                        let count = 1;
                        let dataCheck = condition_group?.line?.[line_discount_cnt];
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                let cost_display = +plan.plan.cost_display;
                                
                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                let plan_price = cost_display + cost_display * 10 / 100;
                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
                                let checkLine = _.maxBy(_.filter(dataCheck, (level, i) => {
                                    return plan_price >= +level.condition[planTypeName];
                                }), (lv, i) => {
                                    return +lv.condition[planTypeName]
                                })
                                if (checkLine) {
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "won" ? +checkLine.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "percent" ? +plan_price * +checkLine.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }

                                plan.discount_wireless = [];

                                count++;
                            }

                            return plan
                        })

                        // /** n?u co ch?n gi?m gia ch? đ?nh **/
                        if (this.state.options_modal.discount_indicated.selected) {
                            let price_sum = _.sumBy(new_plans_line, plan => plan.discount_wireless_price);
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let new_discount_condition_name = [];
                                if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                    plan.discount_wireless_price = price_sum;
                                    new_discount_condition_name.push({name: "지정할인", type: "OPTION", code: "INDICATED"});
                                    plan.discount_condition_name = new_discount_condition_name;
                                } else {
                                    plan.discount_wireless_price = 0;
                                    plan.discount_condition_name = new_discount_condition_name;
                                }
                                return plan
                            })
                        }

                    }
                    break;
                case 13:
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;
                    let basic_discount_conditions = conditionDiscountValue?.basic_discount_conditions_1 || [];
                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;
                        let dataCheck = basic_discount_conditions?.line?.[line_discount_cnt];
                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                let cost_display = +plan.plan.cost_display;
                                
                                if(plan.plan.plac_id){
                                    let planId = plan.plan.id;

                                    plan.plan.planCustom.plan.map((planInfo) => {
                                        if(planInfo.id == planId){
                                            cost_display = +planInfo.cost_display;
                                        }
                                    });
                                }

                                let plan_price = cost_display + cost_display * 10 / 100;
                                let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
                                let checkLine = _.maxBy(_.filter(dataCheck, (level, i) => {
                                    return plan_price >= +level.condition[planTypeName];
                                }), (lv, i) => {
                                    return +lv.condition[planTypeName]
                                })
                                if (checkLine) {
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "won" ? +checkLine.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = checkLine.wireless_basic_discount.unit == "percent" ? +plan_price * +checkLine.wireless_basic_discount.value / 100 : discount_price_each_plan;
                                    plan.discount_wireless_price = discount_price_each_plan;
                                }

                                plan.discount_wireless = [];

                                count++;
                            }

                            return plan
                        })

                        // /** n?u co ch?n gi?m gia ch? đ?nh **/
                        if (this.state.options_modal.discount_indicated.selected) {
                            let price_sum = _.sumBy(new_plans_line, plan => plan.discount_wireless_price);
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let new_discount_condition_name = [];
                                if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                    plan.discount_wireless_price = price_sum;
                                    new_discount_condition_name.push({name: "지정할인", type: "OPTION", code: "INDICATED"});
                                    plan.discount_condition_name = new_discount_condition_name;
                                } else {
                                    plan.discount_wireless_price = 0;
                                    plan.discount_condition_name = new_discount_condition_name;
                                }
                                return plan
                            })
                        }
                    }
                    break;

                case 14: // LG 한방에 home2(신규가입불가)
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;

                    if (conditionDiscountValue && wire_device.wire_device.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let count = 1;
                        let using_U_TV_discount_conditions = conditionDiscountValue?.using_U_TV_discount_conditions || null;
                        let basic_discount_conditions_1 = conditionDiscountValue?.basic_discount_conditions_1 || null;
                        let basic_discount_conditions_2 = conditionDiscountValue?.basic_discount_conditions_2 || null;
                        let basic_discount_conditions_3 = conditionDiscountValue?.basic_discount_conditions_3 || null;
                        let active_condition_group = [];
                        if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_1?.wireless_basic_discount_conditions, line_discount_cnt)) {
                            active_condition_group.push(basic_discount_conditions_1?.line?.[line_discount_cnt])
                        }
                        ;
                        if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_2?.wireless_basic_discount_conditions, line_discount_cnt)) {
                            active_condition_group.push(basic_discount_conditions_2?.line?.[line_discount_cnt])
                        }
                        ;
                        if (this.checkAddDiscountCondition(plans_line_not_null, basic_discount_conditions_3?.wire_wireless_basic_discount_condition, line_discount_cnt)) {
                            active_condition_group.push(basic_discount_conditions_3?.line?.[line_discount_cnt])
                        }
                        ;

                        let plan_discount = _.take(plans_line_not_null, +line_discount_cnt); // danh sach goi phi đc ap d?ng gi?m gia
                        let sum_price_plan_discount = _.sumBy(plan_discount, (plan, i) => { // t?ng gia ti?n cac goi phi đ??c gi?m gia
                            let cost_display = +plan.plan.cost_display;
                                
                            if(plan.plan.plac_id){
                                let planId = plan.plan.id;

                                plan.plan.planCustom.plan.map((planInfo) => {
                                    if(planInfo.id == planId){
                                        cost_display = +planInfo.cost_display;
                                    }
                                });
                            }

                            return cost_display + cost_display * 10 / 100;
                        });
                        let sum_price_discount = _.sumBy(active_condition_group, (condition) => {
                            let price_discount = 0;
                            if (condition?.wireless_basic_discount?.unit == "won") {
                                price_discount = +condition?.wireless_basic_discount?.value
                            }
                            if (condition?.wireless_basic_discount?.unit == "percent") {
                                price_discount = +sum_price_plan_discount * +(condition?.wireless_basic_discount?.value / 100)
                            }
                            return price_discount
                        })

                        new_plans_line = _.map(plans_line, (plan, index) => {
                            let discount_price_each_plan = 0;
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                plan.discount_wireless_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                if (sum_price_discount > 0) {
                                    plan.discount_wireless_price = sum_price_discount / line_discount_cnt;
                                }

                                if (this.state.options_modal.u_tv.selected && plans_line_not_null.length == 1 && plan.idx == 1) {// n?u co ch?n u_tv =>  ch? ap d?ng cho line 1
                                    let wireless_basic_discount = using_U_TV_discount_conditions?.line?.[1]?.wireless_basic_discount;
                                    if (wireless_basic_discount?.unit == "won") {
                                        plan.discount_wireless_price += +wireless_basic_discount?.value
                                    }
                                    if (wireless_basic_discount?.unit == "percent") {
                                        plan.discount_wireless_price += +sum_price_plan_discount * +(wireless_basic_discount?.value / 100)
                                    }
                                }

                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })

                        // /** n?u co ch?n gi?m gia ch? đ?nh **/
                        if (this.state.options_modal.discount_indicated.selected) {
                            let price_sum = _.sumBy(new_plans_line, plan => plan.discount_wireless_price);
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                let new_discount_condition_name = [];
                                if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                    plan.discount_wireless_price = price_sum;
                                    new_discount_condition_name.push({name: "지정할인", type: "OPTION", code: "INDICATED"});
                                    plan.discount_condition_name = new_discount_condition_name;
                                } else {
                                    plan.discount_wireless_price = 0;
                                    plan.discount_condition_name = new_discount_condition_name;
                                }
                                return plan
                            })
                        }
                    }
                    break;
                case 15: // LG HanBang yo
                    conditionDiscountValue = wire_device?.wire_device?.condition_value || null;
                    if (conditionDiscountValue) {
                        //console.log("conditionDiscountValue");
                        //console.log(conditionDiscountValue);

                        let basic_discount_condition = conditionDiscountValue?.basic_discount_condition || null;
                        let standard_or_higher_tv = conditionDiscountValue?.standard_or_higher_tv || null;
                        let over_2_lines_combine = conditionDiscountValue?.over_2_lines_combine || null;
                        let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                        let discount_price_each_plan = 0;
                        let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                            let cost_display = +plan.plan.cost_display;
                                
                            if(plan.plan.plac_id){
                                let planId = plan.plan.id;

                                plan.plan.planCustom.plan.map((planInfo) => {
                                    if(planInfo.id == planId){
                                        cost_display = +planInfo.cost_display;
                                    }
                                });
                            }

                            return cost_display + cost_display * 10 / 100;
                        });
                        /* gi?m gia c? b?n */
                        discount_price_each_plan = basic_discount_condition && basic_discount_condition.wireless_basic_discount && basic_discount_condition.wireless_basic_discount.unit == "won"  // n?u d?n v? la won => s? ti?n gi?m gia m?i line = s? ti?n gi?m gia / s? line đ??c gi?m gia
                            ? +basic_discount_condition.wireless_basic_discount.value / +line_discount_cnt : discount_price_each_plan;
                        if (basic_discount_condition && basic_discount_condition.wireless_basic_discount && basic_discount_condition.wireless_basic_discount.unit == "percent") {
                            discount_price_each_plan = +sum_price_of_line_discount_cnt * (+basic_discount_condition.wireless_basic_discount.value) / 100 / +line_discount_cnt;
                        }

                        /* end gi?m gia c? b?n */

                        /* gi?m gia them
                            n?u co wireless_add_discount_condition.all_wireless_number_line goi phi thoa đi?u kien 1 trong 6 lo?i goi phi ()
                         */
                        let wireless_add_discount_condition = basic_discount_condition && basic_discount_condition.wireless_basic_discount_conditions
                            ? basic_discount_condition.wireless_basic_discount_conditions : null; // đi?u ki?n gi?m gia them khong day
                        let checkAddDiscountCondition = false; // check đi?u ki?n gi?m gia them
                        if (wireless_add_discount_condition) { // n?u s? line đa ch?n l?n h?n s? line ap d?ng đi?u ki?n them
                            checkAddDiscountCondition = this.checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt);
                            
                            if (checkAddDiscountCondition) {
                                if (basic_discount_condition && basic_discount_condition.wireless_add_discount && basic_discount_condition.wireless_add_discount.unit == "won") {
                                    discount_price_each_plan += (+basic_discount_condition.wireless_add_discount.value / +line_discount_cnt);
                                } else if (basic_discount_condition && basic_discount_condition.wireless_add_discount && basic_discount_condition.wireless_add_discount.unit == "percent") {
                                    let sum_price_of_line_discount_cnt = _.sumBy(_.take(plans_line_not_null, line_discount_cnt), (plan, i) => {
                                        let cost_display = +plan.plan.cost_display;
                                
                                        if(plan.plan.plac_id){
                                            let planId = plan.plan.id;

                                            plan.plan.planCustom.plan.map((planInfo) => {
                                                if(planInfo.id == planId){
                                                    cost_display = +planInfo.cost_display;
                                                }
                                            });
                                        }

                                        return cost_display + cost_display * 10 / 100;
                                    });
                                    discount_price_each_plan += +sum_price_of_line_discount_cnt * (+basic_discount_condition.wireless_add_discount.value) / 100 / +line_discount_cnt;
                                }
                            }
                        }

                        let count = 1;

                        new_plans_line = _.map(plans_line, (plan, index) => {
                            if (plan.plan && count <= line_discount_cnt) { // n?u co plan đa ch?n va s? plan đc ch?n nh? h?n s? line t?i đa ap d?ng gi?m gia
                                let new_discount_condition_name = [];
                                if (checkAddDiscountCondition && !_.find(new_discount_condition_name, wireless_add_discount_condition)) new_discount_condition_name.push(wireless_add_discount_condition);   // th?a đi?u ki?n them
                                let new_price = discount_price_each_plan;
                                plan.discount_condition_name = new_discount_condition_name;
                                /** n?u co ch?n cac option gi?m gia, thi remove đi?u ki?n them  **/
                                /** Khi gia nh?p t? lo?i hinh TV thong th??ng tr? len [47]**/
                                if (this.state.options_modal.tv_standard.selected && standard_or_higher_tv) {
                                    if (standard_or_higher_tv.wireless_basic_discount.unit == "won") {
                                        new_price += +standard_or_higher_tv.wireless_basic_discount.value;
                                    } else if (standard_or_higher_tv.wireless_basic_discount.unit == "percent") {
                                        new_price += +sum_price_of_line_discount_cnt * +standard_or_higher_tv.wireless_basic_discount.value / 100 / +line_discount_cnt;
                                    }
                                }
                                /** end Khi gia nh?p t? lo?i hinh TV thong th??ng tr? len [47]**/

                                /** K?t h?p tren 2 day [49]**/
                                if (this.state.options_modal.over_2_lines_combine.selected && over_2_lines_combine) {
                                    if (over_2_lines_combine.wireless_basic_discount.unit == "won") {
                                        new_price += +over_2_lines_combine.wireless_basic_discount.value;
                                    } else if (over_2_lines_combine.wireless_basic_discount.unit == "percent") {
                                        new_price += +(+sum_price_of_line_discount_cnt * +over_2_lines_combine.wireless_basic_discount.value / 100 / +line_discount_cnt);
                                    }
                                }

                                plan.discount_wireless_price = new_price;
                                /** end K?t h?p tren 2 day [49]**/
                                plan.discount_wireless = [];

                                count++;
                            }
                            return plan
                        })
                    }
                    break;
                case 17:
                case 18:
                    conditionDiscountValue    = this.state.discount_condition.discount_condition_selected && this.state.discount_condition.discount_condition_selected.condition_default ? this.state.discount_condition.discount_condition_selected.condition_default : null;
                    let teenagers_selected    = this.state.options_modal.teenagers_promotion.selected;
                    let nanumDiscountSelected = this.state.options_modal.nanum_discount.selected || [];

                    if (plans_line_not_null.length > 0 && _.findIndex(plans_line_not_null, (plan) => {
                        return !this.state.plan_together.includes(+plan.plan.id);
                    }) >= 0) { // n?u co goi phi k thu?c goi phi k?t h?p LGU together
                        new_plans_line = plans_line;
                    }
                    else{
                        if (conditionDiscountValue && conditionDiscountValue.state == 1) { // đi?u ki?n ph?i đ??c ap d?ng
                            discount_line_cnt = conditionDiscountValue.discount_line_cnt ? conditionDiscountValue.discount_line_cnt : 0;
                            let line_discount_cnt = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // s? line đ??c ap d?ng gi?m gia
                            let count = 1;
                            let basic_condition = conditionDiscountValue?.basic_condition;
                            let matched_basic_condition = _.find(basic_condition, (val)=>{
                                return +val.value == line_discount_cnt
                            });
    
                            let total_discount_indicated = 0;
                            new_plans_line = _.map(plans_line, (plan, index) => {
                                if (plan.plan && count <= line_discount_cnt) {
                                    let discount_price_each_plan = 0;
                                    let cost_display = +plan.plan.cost_display;
                                    
                                    if(plan.plan.plac_id){
                                        let planId = plan.plan.id;
    
                                        plan.plan.planCustom.plan.map((planInfo) => {
                                            if(planInfo.id == planId){
                                                cost_display = +planInfo.cost_display;
                                            }
                                        });
                                    }
    
                                    let basicCost = cost_display + cost_display * 10 / 100;
                                    let new_discount_condition_name = [];
                                    plan.discount_wireless_price = 0;
                                    plan.discount_wireless = [];
                                    plan.discount_condition_name = new_discount_condition_name;
    
                                    discount_price_each_plan = matched_basic_condition && matched_basic_condition.wireless_basic_discount.unit == "won" ? +matched_basic_condition.wireless_basic_discount.value : discount_price_each_plan;
                                    discount_price_each_plan = matched_basic_condition && matched_basic_condition.wireless_basic_discount.unit == "percent" ? (basicCost * +matched_basic_condition.wireless_basic_discount.value / 100) : discount_price_each_plan;
                                    plan.discount_wireless_price = 0;
    
                                    if (this.state.options_modal.discount_indicated.selected) {
                                        this.state.options_modal.nanum_discount.selected = [];
                                        nanumDiscountSelected = [];
                                    }
    
                                    if(discount_price_each_plan > 0){
                                        if(nanumDiscountSelected.length > 0){ // 나눔할인 금액이 있는 경우 적용
                                            discount_price_each_plan = nanumDiscountSelected[index]?.value; 
                                        }
    
                                        plan.discount_wireless_price = discount_price_each_plan;
                                        plan.discount_wireless.push({
                                            'name' : conditionCombine?.name || "",
                                            'discount' : discount_price_each_plan
                                        })
                                    }
    
                                    if(teenagers_selected && teenagers_selected.length > 0
                                        && _.find(teenagers_selected, (val)=> {
                                            return +val.idx == plan.idx
                                        })){
                                        plan.discount_wireless_price = +plan.discount_wireless_price + 10000
                                        new_discount_condition_name.push({
                                            name: "청소년할인 프로모션 추가 10,000원 할인",
                                            type: "OPTION",
                                            code: "TEENAGERS_PROMOTION",
                                            short_name:  "청소년할인 프로모션 추가 10,000원 할인",
                                            show_name:"청소년할인",
                                        });
                                        plan.discount_wireless.push({
                                            'name' : '청소년할인',
                                            'discount' : 10000
                                        })
    
                                    }
                                    total_discount_indicated = discount_price_each_plan * line_discount_cnt;
    
                                    /** n?u co ch?n gi?m gia ch? đ?nh **/
                                    if (this.state.options_modal.discount_indicated.selected) {
                                        /** n?u co ch?n option gi?m gia thi remove đi?u ki?n them **/
                                        // new_discount_condition_name = _.filter(new_discount_condition_name, {type: "OPTION"});
                                        // let index_indicated_option = new_discount_condition_name.findIndex(x => x.code && x.code == "INDICATED");
                                        // if (index_indicated_option >= 0) new_discount_condition_name.splice(index_indicated_option, 1);
                                        /** end **/
    
                                        if (this.state.options_modal.discount_indicated.selected.idx == plan.idx) { // n?u line hi?n t?i đ??c ch?n gi?m gia ch? đinh => c?ng d?n gia ti?n vao 1 line
                                            plan.discount_wireless = [];
                                            //plan.discount_wireless_price = 0;
                                            if(plan.discount_wireless_price > 0){
                                                plan.discount_wireless_price = total_discount_indicated;
                                                plan.discount_wireless.push({
                                                    'name' : conditionCombine?.name || "",
                                                    'discount' : total_discount_indicated
                                                })
                                            }
                                            if(teenagers_selected && teenagers_selected.length > 0
                                                && _.find(teenagers_selected, (val)=> {
                                                    return +val.idx == plan.idx
                                                })){
                                                plan.discount_wireless_price = +plan.discount_wireless_price + 10000
                                                plan.discount_wireless.push({
                                                    'name' :  "청소년할인",
                                                    'discount' : 10000
                                                })
                                            }
    
                                            new_discount_condition_name.push({
                                                name: "지정할인",
                                                type: "OPTION",
                                                code: "INDICATED"
                                            });
                                            plan.discount_condition_name = new_discount_condition_name;
    
                                        } else {
                                            plan.discount_wireless_price = 0;
                                            plan.discount_wireless = [];
                                            if(teenagers_selected && teenagers_selected.length > 0
                                                && _.find(teenagers_selected, (val)=> {
                                                    return +val.idx == plan.idx
                                                })){
                                                plan.discount_wireless_price = +plan.discount_wireless_price + 10000
                                                plan.discount_wireless.push({
                                                    'name' :  "청소년할인",
                                                    'discount' : 10000
                                                })
                                            }
                                            plan.discount_condition_name = new_discount_condition_name;
                                        }
                                    }
                                    else{
                                        plan.discount_wireless = [];
                                    }
                                    count++;
                                }
                                return plan
                            })
                        }
                    }
                    
                    break;
                default:
                    break;
            }
        } else {

        }

        //console.log("new_plans_line");
        //console.log(new_plans_line);

        if(type === "expected"){ // n?u la goi phi d? ki?n
            return new_plans_line[0];
        }else{
            this.setState({
                plan_modal: {
                    ...this.state.plan_modal,
                    plans: new_plans_line,
                    line_discount_matched
                }
            }, () => {
            })
        }

    }

    toggleModal = (e, modal) => {
        e && e.preventDefault();
        //3593 작업 start
        // 업무번호 3593 KT 총액결합 2회선 추가
        /*if(modal == "discount_condition"){ //총액결합 선택 시
            let plans = this.state.plan_modal.plans;
            let conditionCombine = this.state.discount_condition.discount_condition_selected;
            if(conditionCombine !=null){
                //console.log("conditionCombine", conditionCombine.id)
                //console.log("conditionCombine : ", conditionCombine)
                //console.log("conditionCombine : : ", this.state.discount_condition)
            }
            if(conditionCombine != null){
            if((this.state.check_case == 1 && conditionCombine.id == 6) && conditionCombine != null){
                if((conditionCombine.id == 6 && plans.length == 7) || conditionCombine == null){
                    //console.log("모달 오픈 두번쨰")
                    //console.log(conditionCombine.id)
                    //console.log("토글 삭제 진입")
                    //console.log("conditionCombine", conditionCombine.id)
                    plans.splice(5,2);
                    this.setState({
                        check_case: 0,
                        check_kt_premium: null,
                        plan_modal: {
                            ...this.state.plan_modal,
                            plans: plans
                        },
                    })
                }
            }
            else if(this.state.check_case == 0 || this.state.check_case == null){
            //else if(this.state.check_case == 0 || this.state.check_case == null){
                if(conditionCombine !=null && conditionCombine.id == 6){
                    //console.log("모달 오픈 첫번쨰")
                    this.setState({
                        check_case: 1,
                        //check_equal_case: conditionCombine.id
                    })
                }
            }
            // if(this.state.check_equal_case == conditionCombine.id){
            //     this.setState({
            //         check_case: 1,
            //         check_equal_case: conditionCombine.id
            //     })
            // }
            } 
            // else if(conditionCombine !=null && conditionCombine.id != 6 && plans.length == 7){
            //     console.log("모달 오픈 두번쨰")
            //     console.log(conditionCombine.id)
            //     console.log("토글 삭제 진입")
            //     plans.splice(5,2);
            //     this.setState({
            //         plan_modal: {
            //             ...this.state.plan_modal,
            //             plans: plans
            //         },
            //         check_case: 0
            //     })
            // }
        }*/ 
        //3593 작업 end

        this.setState({
            [modal]: {
                ...this.state[modal],
                isShowModal: !this.state[modal].isShowModal
            }
        })
        
    }

    openPlanModal = (e, plan_idx) => {
        e.preventDefault();
        let planOld = _.find(this.state.plan_modal.plans, plan => plan.idx == plan_idx);
        this.setState({
            plan_modal: {
                ...this.state.plan_modal,
                openPlanIdx: plan_idx,
                plan_selected: planOld?.plan || null
            }
        }, () => {
            this.toggleModal(e, "plan_modal");
        })
    }

    togglePlanModal = () => {
        this.setState({
            plan_modal: {
                ...this.state.plan_modal,
                isShowModal: false
            }
        })
    }

    openModalCannotSelectPlan = (discountConditionId = null) => {
        let message = "선택한 대표 요금제로는 가족무한사랑 결합할 수 없습니다."
        if (discountConditionId && [17, 18].includes(+discountConditionId)) {
            message = "결합을 적용할 수 없습니다. 결합조건을 확인해 주세요."
        }
        this.setState({
            error_modal: {
                isShowModal: true,
                message: (<span className="notification_delete">{message}</span>)
            }
        })
    }

    onSelectPlan = async (data, type = "") => {
        if ([17, 18].includes(this.state.discount_condition.discount_condition_selected.id) && !_.isEmpty(data) && !_.includes(this.state.plan_together, +data.id)) {
            this.openModalCannotSelectPlan(this.state.discount_condition.discount_condition_selected.id);
        } else {
            if (type == "custom") {
                let new_data = {};
                if (!_.isEmpty(data)) {
                    new_data = data.planId ? {...data.planId} : {};
                    new_data.cost_display = +data.costDistplay * 10 / 11;
                    new_data.name = data?.planCustom?.plac_name || (data.planId ? data.planId.name : new_data.name);
                    new_data.class = data?.planCustom?.class || null;
                    new_data.planCustom = data?.planCustom || null;
                    data = new_data
                }
            }
            let oldPlansArr = this.state.plan_modal.plans;
            let idxPlan = this.state.plan_modal.openPlanIdx;
            var index = _.findIndex(oldPlansArr, {idx: idxPlan});
            oldPlansArr.splice(index, 1, {
                ...oldPlansArr[index],
                plan: _.isEmpty(data) ? null : data,
                discount_wireless_price: 0,
                discount_condition_name: [],
            });

            let options_modal = this.state.options_modal;

            let plans_line_not_null = _.reject(oldPlansArr, {plan: null});// s? plan đa ch?n
            _.map(oldPlansArr, (plan, i) => {
                plan.idx = i + 1;
                plan.plan = plans_line_not_null[i]?.plan || null;
                plan.is_commitment = plans_line_not_null[i]?.is_commitment || false;
                plan.discount_wireless_price = plans_line_not_null[i]?.discount_wireless_price || 0;
                plan.discount_condition_name = plans_line_not_null[i]?.discount_condition_name || [];
                // // plan.disabled = plan.disabled;
            })
            plans_line_not_null = _.reject(oldPlansArr, {plan: null});// s? plan đa ch?n

            if(this.props.adviceType == ADVICE_TYPE.WIRE){
                let hasTVDevice = _.filter(this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify, (value, i) => {
                    return !_.isEmpty(value.device)
                }).length > 0 ? true : false;

                if(hasTVDevice && this.state.discount_condition.discount_condition_selected
                    && [1, 2, 3].includes(+this.state.discount_condition.discount_condition_selected.id)
                    && plans_line_not_null.length > 0
                ){ // // n?u co s?n ph?m TV va k?t h?p đi?u ki?n gi?m gia k?t h?p SKT plan (1, 2, 3), t? đ?ng apply vao line 1 : https://docs.google.com/presentation/d/1Nk5se66qp2qAkTAZYrbFD6JFzMBeb4lWvYiAZkODcPA/edit#slide=id.gd44b1e4dff_0_176
                    options_modal = {
                        ...this.state.options_modal,
                        tv_plus : {
                            selected: !_.isEmpty(this.state.options_modal.tv_plus.selected) && this.state.options_modal.tv_plus.selected.type ? this.state.options_modal.tv_plus.selected : null /* { // n?u khong ch?n cai nao thi t? đ?ng active TV plus
                                type: {
                                    id: 1,
                                    type: "OPTION",
                                    code: "TVPLUS",
                                    name: "TV플러스(신규)",
                                    short_name:  "TV플러스(신규)",
                                    show_name:"결합 신규 가입자",
                                    description: "무선 1,100원 / TV 1,100원 할인",
                                    price: 1100
                                },
                                line: this.state.plan_modal.plans[0]
                            }*/
                        },
                    }

                }
            }

            let teenagers_promotion    = options_modal.teenagers_promotion?.selected || [];
            let nanum_discount         = options_modal?.nanum_discount?.selected || [];
            let kt_premium_discount    = options_modal?.kt_premium_discount?.selected || null;
            let kt_teenagers_promotion = options_modal?.kt_teenagers_promotion?.selected || null;

            if(_.isEmpty(data)){
                if(plans_line_not_null.length < 2){
                    teenagers_promotion = []
                }else{
                    teenagers_promotion.splice(plans_line_not_null.length, 1);
                }
            }

            if(nanum_discount.length != plans_line_not_null.length){
                nanum_discount = [];
            }

            if(kt_premium_discount != null){
                if(plans_line_not_null.length < 2){
                    kt_teenagers_promotion = null;
                }
                else if(kt_teenagers_promotion != null){
                    let tempInt                    = 0;
                    let count5g                    = 0;
                    let ktTeenagersPromotionLength = kt_teenagers_promotion.length;

                    for(tempInt = (ktTeenagersPromotionLength - 1); tempInt >= 0; tempInt--){
                        let promotionInfo = kt_teenagers_promotion[tempInt];
                        let index2         = _.findIndex(plans_line_not_null, {idx: promotionInfo.idx});

                        if(index2 < 0){
                            kt_teenagers_promotion.splice(tempInt, 1);
                        }
                    }

                    for(tempInt = (plans_line_not_null.length - 1); tempInt >= 0; tempInt--){
                        let plansInfo   = plans_line_not_null[tempInt];
                        let classId     = plansInfo.plan.classID;
                        let costDisplay = +plansInfo.plan.cost_display * 1.1;
    
                        if((classId == "93" || classId == "94" || classId == "160") && costDisplay >= 80000){
                            count5g++;
                        }
                        else{
                            let index2 = _.findIndex(kt_teenagers_promotion, {idx: plansInfo.idx});

                            if(index2 >= 0){
                                kt_teenagers_promotion.splice(index2, 1);
                            }
                        }
                    }

                    ktTeenagersPromotionLength = kt_teenagers_promotion.length;
                    
                    if(count5g < 2){
                        kt_teenagers_promotion = null;
                    }
                    else if(ktTeenagersPromotionLength == count5g){
                        this.setState({
                            error_modal: {
                                isShowModal: true,
                                message: (<span className="notification_delete">부모회선 확인 불가</span>)
                            }
                        });

                        kt_teenagers_promotion = null;
                    }
                }
            }

            options_modal = {
                discount_indicated: {
                    selected: options_modal.discount_indicated.selected
                    && (
                        options_modal.discount_indicated.selected.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx
                        && _.find(oldPlansArr, function (subArray) {
                            return options_modal.discount_indicated.selected && options_modal.discount_indicated.selected.plan && subArray.plan && subArray.plan.id == options_modal.discount_indicated.selected.plan.id
                        }) !== undefined
                    )
                        ? options_modal.discount_indicated.selected
                        : null
                },
                tv_plus: {
                    selected: {
                        type: options_modal.tv_plus.selected != null && options_modal.tv_plus.selected.line && plans_line_not_null.length > 0 && options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx
                            && _.find(oldPlansArr, function (subArray) {
                                return true; //task 7179
                                //return options_modal.tv_plus.selected && options_modal.tv_plus.selected.line && subArray.plan && subArray.plan.id == options_modal.tv_plus.selected.line.plan.id
                            }) !== undefined
                            ? options_modal.tv_plus.selected.type
                            : null,
                        line: options_modal.tv_plus.selected != null && options_modal.tv_plus.selected.line && plans_line_not_null.length > 0 && options_modal.tv_plus.selected.line.idx <= plans_line_not_null[plans_line_not_null.length - 1].idx
                            && _.find(oldPlansArr, function (subArray) {
                                return true; //task 7179
                                //return options_modal.tv_plus.selected && options_modal.tv_plus.selected.line && subArray.plan && subArray.plan.id == options_modal.tv_plus.selected.line.plan.id
                            }) !== undefined
                            ? options_modal.tv_plus.selected.line
                            : null,
                    }
                },
                sub_years: {
                    selected: options_modal?.sub_years?.selected || null
                },
                kt_number_line_wire_combine: {
                    selected: options_modal?.kt_number_line_wire_combine?.selected || null
                },
                lgu_patron: {
                    selected: options_modal?.lgu_patron?.selected || null
                },
                u_tv: {
                    selected: options_modal?.u_tv?.selected || false
                },
                over_2_lines_combine: {
                    selected: options_modal?.over_2_lines_combine?.selected || false
                },
                tv_standard: {
                    selected: options_modal?.tv_standard?.selected || false
                },
                kt_premium_discount: {
                    selected: kt_premium_discount
                },
                kt_premium_discount_except: {
                    selected: options_modal?.kt_premium_discount_except?.selected || null
                },
                teenagers_promotion: {
                    selected: teenagers_promotion
                },
                nanum_discount: {
                    selected: nanum_discount
                },
                kt_teenagers_promotion: {
                    selected: kt_teenagers_promotion || null
                }
            };

            let discountConditions = this.state.discount_condition.list;
            for (let discountCondition of discountConditions) {
                if (discountCondition.id === this.state.discount_condition.discount_condition_selected.id) {
                    discountCondition.plans = oldPlansArr;
                }
            }

            let combineConditionId = this.state.discount_condition.discount_condition_selected ? this.state.discount_condition.discount_condition_selected.id : null;

            if(combineConditionId == 6){
                let costDisplay = Number(data.cost_display) * 1.1;
                let name        = "";

                if(data.plan_classification){
                    name = data.plan_classification.name;
                }

                if(costDisplay >= 65890 && costDisplay < 77000 && name.indexOf("LTE") >= 0){
                    this.comfirmKtPremiumExcept(discountConditions, oldPlansArr, options_modal, index, data);
                }
                else if(
                    "2968" == data.id || // 데이터ON 비디오 플러스
                    "2957" == data.id || // Y데이터 ON비디오플러스
                    "2774" == data.id || // LTE 데이터 선택 65.8
                    "2716" == data.id || // Y 데이터 선택 65.8 
                    "2936" == data.id || // Y24 65.8 (전역)
                    "2704" == data.id // 순 완전무한61
                ){ 
                    this.comfirmKtPremiumExcept(discountConditions, oldPlansArr, options_modal, index, data);
                }
                else{
                    let ktPremiumDiscountExcept = options_modal.kt_premium_discount_except.selected;
                    
                    if(ktPremiumDiscountExcept){
                        let tempInt                       = 0;
                        let ktPremiumDiscountExceptLength = ktPremiumDiscountExcept.length;

                        for(tempInt = (ktPremiumDiscountExceptLength - 1); tempInt >= 0; tempInt--){
                            if(ktPremiumDiscountExcept[tempInt] == index){
                                ktPremiumDiscountExcept.splice(tempInt, 1);
                            }
                        }

                        options_modal.kt_premium_discount_except.selected = ktPremiumDiscountExcept;
                    }
                    
                    if(_.isEmpty(data) && (kt_premium_discount?.idx || -1) == (index + 1) && plans_line_not_null.length == index){
                        options_modal.kt_premium_discount.selected    = null;
                        options_modal.kt_teenagers_promotion.selected = null;
                    }
                    
                    this.setState({
                        discount_condition: {
                            ... this.state.discount_condition,
                            list: discountConditions,
                        },
                        plan_modal: {
                            ...this.state.plan_modal,
                            plans: oldPlansArr
                        },
                        options_modal
                    }, async () => {
                        await this.getWireDiscount();
                        await this.getDiscountWireLessPrice();
                    });    
                }
            }
            else{
                this.setState({
                    discount_condition: {
                        ... this.state.discount_condition,
                        list: discountConditions,
                    },
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: oldPlansArr
                    },
                    options_modal
                }, async () => {
                    await this.getWireDiscount();
                    await this.getDiscountWireLessPrice();
                });
            }
        }
    }

    comfirmKtPremiumExcept = (discountConditions, oldPlansArr, options_modal, index, data) => {
        let xhtml = (
            <Fragment>
                <div className="content-register-modal">
                    <h4 className="text-center">
                        <span className="notification_delete">2022.12.01 이전 결합자로 적용하시겠습니까?</span>
                    </h4>
                    <div className="form-row">
                        <div className="w-100 text-center">
                            <button className="btn btn_close mr-2" onClick={() => this.comfirmKtPremiumExceptNo(discountConditions, oldPlansArr, options_modal, index)}>신규결합자</button>
                            <button className="btn btn_save  ml-2" onClick={() => this.comfirmKtPremiumExceptYes(discountConditions, oldPlansArr, options_modal, index, data)}>이전결합자</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: "modal-dialog-scrollable modal-lg modal-dialog-centered",
        }

        this.props.showModal(dataModal);
    }

    comfirmKtPremiumExceptNo = (discountConditions, oldPlansArr, options_modal, index) => {
        let ktPremiumDiscountExcept = options_modal.kt_premium_discount_except.selected;

        if(options_modal.kt_premium_discount_except.selected != null){
            let tempInt                       = 0;
            let ktPremiumDiscountExceptLength = ktPremiumDiscountExcept.length;

            for(tempInt = 0; tempInt < ktPremiumDiscountExceptLength; tempInt++){
                if(ktPremiumDiscountExcept[tempInt] == index){
                    ktPremiumDiscountExcept.splice(tempInt, 1);

                    break;
                }
            }
        }

        this.setState({
            discount_condition: {
                ... this.state.discount_condition,
                list: discountConditions,
            },
            plan_modal: {
                ...this.state.plan_modal,
                plans: oldPlansArr
            },
            options_modal
        }, async () => {
            await this.getWireDiscount();
            await this.getDiscountWireLessPrice();
        });

        this.props.hideModal();
    }

    comfirmKtPremiumExceptYes = (discountConditions, oldPlansArr, options_modal, index, data) => {
        let ktPremiumDiscountExcept = options_modal.kt_premium_discount_except.selected;

        oldPlansArr.splice(index, 1, {
            ...oldPlansArr[index],
            plan: _.isEmpty(data) ? null : data,
            discount_wireless_price: 0,
            discount_condition_name: [],
        });

        if(ktPremiumDiscountExcept == null){
            ktPremiumDiscountExcept = new Array();
        }

        ktPremiumDiscountExcept[ktPremiumDiscountExcept.length] = index;

        options_modal = {
            ...options_modal,
            kt_premium_discount_except : {
                selected: ktPremiumDiscountExcept
            }
        };

        this.setState({
            discount_condition: {
                ... this.state.discount_condition,
                list: discountConditions,
            },
            plan_modal: {
                ...this.state.plan_modal,
                plans: oldPlansArr
            },
            options_modal
        }, async () => {
            await this.getWireDiscount();
            await this.getDiscountWireLessPrice();
        });

        this.props.hideModal();
    }

    // kt 프리미엄 유지 여부를 확인하기 위한 메소드 start!
    getActiveKTPremiumPlans = (oldPlansArr) => {
        //console.log("kT 프리미엄 유지 여부 메소드")
        //console.log("getActiveKTPremiumPlans 함수의 인자값 : ", oldPlansArr)
        let plans_line_not_null                   = _.reject(oldPlansArr, {plan: null});// số plan đã chọn
        let wire_device                           = this.state.wire_product.selected;
        let conditionDiscountValue                = wire_device?.wire_device?.condition_value || null;
        let KT_premium_1_discount_condition_group = conditionDiscountValue?.KT_premium_1_discount_condition_group;
        let discount_condition                    = KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition;
        let discount_line_cnt                     = this.state.plan_modal.discount_line_cnt;
        let line_discount_cnt                     = plans_line_not_null.length > discount_line_cnt ? discount_line_cnt : plans_line_not_null.length; // số line được áp dụng giảm giá
        let activeKTPremiumPlans                  = _.filter(_.take(plans_line_not_null,line_discount_cnt ), (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);

            return discount_condition && !_.includes([2989, 2991, 2993, 2994, 2976 ], plan.plan.id) && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +discount_condition[planTypeName];
        });

        if(this.state.options_modal.kt_premium_discount_except.selected != null){
            let exceptSelectedLength = this.state.options_modal.kt_premium_discount_except.selected.length;
            let tempInt              = 0;
            let tempInt2             = 0;
            let plan                 = null;
            let planIdx              = null;

            for(tempInt = 0; tempInt < exceptSelectedLength; tempInt++){
                plan    = plans_line_not_null[this.state.options_modal.kt_premium_discount_except.selected[tempInt]];
                planIdx = (plan?.idx || -1);

                for(tempInt2 = 0; tempInt2 < activeKTPremiumPlans.length; tempInt2++){
                    if(planIdx == activeKTPremiumPlans[tempInt2].idx){
                        break;
                    }
                }

                if(tempInt2 == activeKTPremiumPlans.length){
                    activeKTPremiumPlans.push(plan);
                }
            }
        }

        activeKTPremiumPlans.sort(function(a, b) {
            return a.idx - b.idx;
        });
        
        return activeKTPremiumPlans;
    }

    checkActiveOptionDiscount = (oldPlansArr) => {
        //console.log("checkActiveOptionDiscount")
        let plans = _.reject(oldPlansArr, {plan: null});;
        if(plans.length > 0){
            let wireCombineCondition =  this.state.wire_product.selected.wire_device || null;
            let KT_premium_1_discount_condition_group = wireCombineCondition?.condition_value?.KT_premium_1_discount_condition_group;
            let line_discount_cnt = plans.length > wireCombineCondition?.discount_line_cnt ? wireCombineCondition.discount_line_cnt : plans.length; // s? line đ??c ap d?ng gi?m gia
            let checkWireBasicDiscountCondition = this.checkAddDiscountConditionKtPremium(plans, KT_premium_1_discount_condition_group?.wire_wireless_basic_discount_condition, line_discount_cnt);
            return checkWireBasicDiscountCondition;
        }
    }

    checkAddDiscountConditionKtPremium (plans_line_not_null, wireless_add_discount_condition, line_discount_cnt){
        //console.log("checkAddDiscountConditionKtPremium")
        //console.log("checkAddDiscountConditionKtPremium 의 plans_line_not_null : ", plans_line_not_null)
        plans_line_not_null = _.filter(plans_line_not_null, (plan) => {
            if( !(plan?.plan?.name.substring(0,2) == "5G" && (+plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100) < 80000)) return  plan; // task 6748 flow
        })
        let all_wireless_number_line = wireless_add_discount_condition?.all_wireless_number_line;
        let uniform_plan_number_line = wireless_add_discount_condition?.uniform_plan_number_line;
        let result = false;
        let plan_apply_discount = _.take(plans_line_not_null,line_discount_cnt );
        let count = 0;
        // check s? line th?a đi?u ki?n
        if(plan_apply_discount.length > 0) _.map(plan_apply_discount, (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan?.plan?.discost_ad_cond);
            if(wireless_add_discount_condition
                && planTypeName
                && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +wireless_add_discount_condition[planTypeName]
            ) count++;

        })
        // check s? line đ?ng nh?t th?a đi?u ki?n
        let is_uniform_active = false;
        let count_group =  _(plan_apply_discount).map((value, key) => {
            return value.plan // task 6748 flow
        }).groupBy('discost_ad_cond').value();

        _.forEach(count_group, (val, i) => {
            let planTypeNameGroup = this.getPlanTypeNameById(parseInt(i));
            let same_plan_count = _(val).groupBy('id').map(
                (group) => {
                    return ({id: group[0].id, qty: group.length, price: +group[0].cost_display + group[0].cost_display*10/100})
                }
            ).value();
            let same_plan = _.maxBy(same_plan_count,  'qty');
            // n?u co s? đ??ng day gi?ng nhau th?a 1 trong đi?u ki?n
            if(wireless_add_discount_condition && same_plan.qty >= +uniform_plan_number_line && same_plan.price >= +wireless_add_discount_condition[planTypeNameGroup] ) is_uniform_active = true;
            if(is_uniform_active) return false;
        });

        if(all_wireless_number_line && count >= all_wireless_number_line || is_uniform_active) result = true; // n?u th?a 1 trong 2
        return result;
    }
    // kt 프리미엄 유지 여부를 확인하기 위한 메소드 end!

    // planCustomResultPackage = (data) => {
    //     let new_data = {};
    //     if(!_.isEmpty(data)){
    //         new_data = data.planId ? {...data.planId} : {};
    //         new_data.cost_display = +data.costDistplay*10/11;
    //         new_data.name = data?.planCustom?.plac_name || (data.planId ? data.planId.name : new_data.name);
    //         new_data.class = data?.planCustom?.class || null;
    //         new_data.planCustom = data?.planCustom || null;
    //     }
    //     this.onSelectPlan(new_data);
    // }

    /**
     * return result plan id
     */
    // planResultPackage = (data) => {
    //     this.onSelectPlan(data);
    // }


    /*
    * plans_line_not_null         : danh sach goi phi đa ch?n
    * discount_price_each_plan    : gia ti?n gi?m gia m?i goi phi hi?n t?i
    * line_discount_matched       : line đi?u ki?n matched
    * wireless_add_discount_condition     : object đi?u ki?n gi?m gia them
    * line_discount_cnt     : s? dong ap d?ng gi?m gia
    * */
    checkAddDiscountCondition(plans_line_not_null, wireless_add_discount_condition, line_discount_cnt, condition = ">=") {
        //총액결합선택 후, 유무선 선택
        let all_wireless_number_line = wireless_add_discount_condition?.all_wireless_number_line;
        let uniform_plan_number_line = wireless_add_discount_condition?.uniform_plan_number_line;
        let result = false;
        let plan_apply_discount = _.take(plans_line_not_null, line_discount_cnt);
        let count = 0;
        if(this.state.discount_condition.discount_condition_selected.id == 6){
            var removeItem = [2989];
            plan_apply_discount = plan_apply_discount.filter(function(item){
                return removeItem.indexOf( item.plan.id ) == -1;
            });

            let ktPremiumDiscountExcept = this.state.options_modal.kt_premium_discount_except.selected;

            if(ktPremiumDiscountExcept != null){
                count = count + ktPremiumDiscountExcept.length;
            }
        }

        // check s? line th?a đi?u ki?n
        _.map(plan_apply_discount, (plan, i) => {
            let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
            let cost_display = +plan?.plan?.cost_display + +plan?.plan?.cost_display * 10 / 100;

            if(plan.plan.plac_id){
                let planId = plan.plan.id;

                plan.plan.planCustom.plan.map((planInfo) => {
                    if(planInfo.id == planId){
                        cost_display = +planInfo.cost_display + +planInfo.cost_display * 10 / 100;
                    }
                });
            }

            if (wireless_add_discount_condition[planTypeName] && cost_display >= +wireless_add_discount_condition[planTypeName]){
                count++;
            } 
        })

        // check s? line đ?ng nh?t th?a đi?u ki?n
        let is_uniform_active = false;
        let count_group = _(plan_apply_discount).map((value, key) => {
            return value.plan
        }).groupBy('discost_ad_cond').value();
        _.forEach(count_group, (val, i) => {
            let planTypeNameGroup = this.getPlanTypeNameById(parseInt(i));
            let same_plan_count = _(val).groupBy('id').map(
                (group) => ({
                    id: group[0].id,
                    qty: group.length,
                    price: +group[0].cost_display + group[0].cost_display * 10 / 100
                })
            ).value();
            same_plan_count = _.orderBy(same_plan_count, ['price'], ['desc'])
            let same_plan = _.maxBy(same_plan_count, 'qty');
            // n?u co s? đ??ng day gi?ng nhau th?a 1 trong đi?u ki?n
            if ((condition == ">=" ? (same_plan.qty >= +uniform_plan_number_line) : (same_plan.qty > +uniform_plan_number_line)) && same_plan.price >= +wireless_add_discount_condition[planTypeNameGroup]) is_uniform_active = true;
            if (is_uniform_active) return false;
        });

        if((condition == ">=" ? (count >= all_wireless_number_line) : (count > all_wireless_number_line)) || is_uniform_active) result = true; // n?u th?a 1 trong 2

        return result;
    }

    getPlanTypeNameById = (planType) => {
        let planTypeName = "";
        switch (+planType) {
            case 18:
                planTypeName = "conventional_commitment";
                break;
            case 19:
                planTypeName = "not_conventional_commitment";
                break;
            case 20:
                planTypeName = "fit_type_commitment";
                break;
            case 21:
                planTypeName = "not_fit_type_commitment";
                break;
            case 22:
                planTypeName = "not_3G_commitment";
                break;
            case 23:
                planTypeName = "other";
                break;
        }
        return planTypeName;
    }


    onChangeIsCommitment = (idx) => {
        if (this.props.adviceType == ADVICE_TYPE.WIRELESS && idx == 1) {
            alert("유무선 결합 설정 팝업에서는 첫번째 회선을 수정할 수 없습니다.");
        } else {
            var oldPlansArr = this.state.plan_modal.plans;
            var index = _.findIndex(oldPlansArr, {idx: idx});
            oldPlansArr.splice(index, 1, {...oldPlansArr[index], is_commitment: !oldPlansArr[index].is_commitment});
            this.setState({
                plan_modal: {
                    ...this.state.plan_modal,
                    plans: oldPlansArr
                }
            })
        }
    }


    /* on select option discount
    *  update gia ti?n c?a m?i goi phi
    * */
    onSelectOptionDiscount = (selected, type) => { //총액결합할인 선택 후, 유무선 결합 상품 선택시
        //console.log("onSelectOptionDiscount")
        switch (type) {
            case "discount_indicated" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            ...this.state.options_modal.discount_indicated,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "tv_plus" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        tv_plus: {
                            ...this.state.options_modal.tv_plus,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "sub_years" : // option gi?m gia khach hang lau n?m
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        sub_years: {
                            ...this.state.options_modal.sub_years,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                })
                break;
            case "kt_number_line_wire_combine" : // option gi?m gia khach hang lau n?m
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        kt_number_line_wire_combine: {
                            ...this.state.options_modal.kt_number_line_wire_combine,
                            selected: selected
                        }
                    }
                }, () => {
                    // this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                })
                break;
            case "lgu_patron" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        lgu_patron: {
                            ...this.state.options_modal.lgu_patron,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "u_tv" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        u_tv: {
                            ...this.state.options_modal.u_tv,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "over_2_lines_combine" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        over_2_lines_combine: {
                            ...this.state.options_modal.over_2_lines_combine,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "tv_standard" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        tv_standard: {
                            ...this.state.options_modal.tv_standard,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getDiscountWireLessPrice();
                })
                break;
            case "kt_premium_discount" : // gi?m gia KT //총액결합할인 선택 후, 유무선 상품 선택 시 진입 -> 총애결합의 경우 KT프리미엄 할인 선택이 가능하기에 진입
            //console.log("kt_premium_discount 케이스 this.state.options_modal.kt_premium_discount : ", this.state.options_modal.kt_premium_discount.selected)
            //console.log("kt_premium_discount 케이스의 selected : ", selected)
                let ktPremiumDiscountExcept = this.state.options_modal.kt_premium_discount_except.selected;
                let ktTeenagersPromotion    = this.state.options_modal.kt_teenagers_promotion.selected;

                //console.log("kt_premium_discount의 selected : ", selected)                
                if(selected == null){
                    ktTeenagersPromotion = null;
                    //console.log("kt_premium_discount 선택 후 selected 확인 == null")
                        // 업무번호 3593 KT 총액결합 2회선 추가
                        /*this.setState({ //선택된 것이 없을 때 값을 초기화
                            check_kt_premium: null
                        })*/
  
                }
                // 업무번호 3593 KT 총액결합 2회선 추가
                /*if(selected != null){ //선택된 것이 있을 때 값을 변경
                    this.setState({
                        check_kt_premium: 1
                    })
                }*/

                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        kt_premium_discount: {
                            ...this.state.options_modal.kt_premium_discount,
                            selected: selected
                        },
                        kt_premium_discount_except: {
                            selected: ktPremiumDiscountExcept
                        },
                        discount_indicated: {
                            ...this.state.options_modal.discount_indicated,
                            selected: null
                        },
                        kt_teenagers_promotion: {
                            ...this.state.options_modal.kt_teenagers_promotion,
                            selected: ktTeenagersPromotion
                        }
                    }
                }, () => {
                    this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                })
                break;
            case "teenagers_promotion" : // gi?m gia ch? đ?nh
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        teenagers_promotion: {
                            ...this.state.options_modal.teenagers_promotion,
                            selected: selected
                        },
                    }
                }, () => {
                    this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                })
                break;
            case "nanum_discount" :
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        nanum_discount: {
                            ...this.state.options_modal.nanum_discount,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                })
                break;
            case "kt_teenagers_promotion" :
                this.setState({
                    options_modal: {
                        ...this.state.options_modal,
                        kt_teenagers_promotion: {
                            ...this.state.options_modal.kt_teenagers_promotion,
                            selected: selected
                        }
                    }
                }, () => {
                    this.getWireDiscount();
                    this.getDiscountWireLessPrice();
                });
                break;
        }
    }

    renderDiscountOption() {
        let lines = this.state.plan_modal.plans;
        let combine_condition_id = this.state.discount_condition.discount_condition_selected ? this.state.discount_condition.discount_condition_selected.id : null;
        let TVdevices = this.props.adviceType == ADVICE_TYPE.WIRE ? this.props.WiredAdvice.WiredAdviceStep2.classify[1].classify : [];// n?u co s?n ph?m la TV thi active TVPlus ? t? v?n co day
        let discount_line_cnt = this.state.plan_modal.discount_line_cnt; // s? line ap d?ng gi?m gia
        let xhtml = null;
        let plans_line_not_null = _.reject(lines, {plan: null});// s? plan đa ch?n
        switch (combine_condition_id) {
            case 1: // SKT plan c? gia đinh
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.wire_product.selected.wire_device && (
                                <DiscountIndicated
                                    selected={this.state.options_modal.discount_indicated.selected}
                                    onSelect={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                    discount_line_cnt={discount_line_cnt}

                                />
                            )
                        }
                        {
                            this.props.adviceType == ADVICE_TYPE.WIRELESS && this.state.wire_product.selected.wire_device ? (
                                <TVPlus 
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : this.props.adviceType == ADVICE_TYPE.WIRE && TVdevices.length > 0 && !_.isEmpty(TVdevices[0].device) ? ( // n?u co s?n ph?m la TV thi active TVPlus ? t? v?n co day
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : ""
                        }

                    </td>
                )
                break;
            case 2: // SKT free c? gia đinh
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.props.adviceType == ADVICE_TYPE.WIRELESS && this.state.wire_product.selected.wire_device ? (
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : this.props.adviceType == ADVICE_TYPE.WIRE && TVdevices.length > 0 && !_.isEmpty(TVdevices[0].device) ? ( // n?u co s?n ph?m la TV thi active TVPlus ? t? v?n co day
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : ""
                        }
                    </td>
                )
                break;
            case 3: // SKT gi?m gia c? gia đinh
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.props.adviceType == ADVICE_TYPE.WIRELESS && this.state.wire_product.selected.wire_device ? (
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : this.props.adviceType == ADVICE_TYPE.WIRE && TVdevices.length > 0 && !_.isEmpty(TVdevices[0].device) ? ( // n?u co s?n ph?m la TV thi active TVPlus ? t? v?n co day
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : ""
                        }
                    </td>
                )
                break;
            case 4: // SKT gi?m gia c? gia đinh
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.discount_condition?.discount_condition_selected?.condition_default?.state == 1 && (
                                <SubsriptionYears
                                    selected={this.state.options_modal.sub_years.selected}
                                    onSelect={this.onSelectOptionDiscount}
                                    years={this.state.discount_condition.discount_condition_selected
                                    && this.state.discount_condition.discount_condition_selected.condition_default
                                        ? this.state.discount_condition.discount_condition_selected.condition_default.basic_condition
                                        : []}
                                    plans={plans_line_not_null}
                                />
                            )

                        }
                    </td>
                )
                break;

            case 16: // Whole family discount
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            <SubsriptionYears
                                selected={this.state.options_modal.sub_years.selected}
                                onSelect={this.onSelectOptionDiscount}
                                years={this.state.discount_condition.discount_condition_selected
                                && this.state.discount_condition.discount_condition_selected.condition_default
                                    ? this.state.discount_condition.discount_condition_selected.condition_default.basic_condition
                                    : []}
                                plans={plans_line_not_null}
                            />
                        }
                        {
                            this.props.adviceType == ADVICE_TYPE.WIRELESS && this.state.wire_product.selected.wire_device ? (
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : this.props.adviceType == ADVICE_TYPE.WIRE && TVdevices.length > 0 && !_.isEmpty(TVdevices[0].device) ? ( // n?u co s?n ph?m la TV thi active TVPlus ? t? v?n co day
                                <TVPlus
                                    selected={this.state.options_modal.tv_plus.selected}
                                    onApply={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                />
                            ) : ""
                        }
                    </td>
                )
                break;
            case 5: // KT K?t h?p gia đinh (k?t h?p khong day)
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.discount_condition?.discount_condition_selected?.condition_default?.state == 1 && (
                                <KTNumberLineWireCombine
                                    selected={this.state.options_modal.kt_number_line_wire_combine.selected}
                                    onSelect={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                    list={this.state.discount_condition?.discount_condition_selected?.condition_default?.KT_number_of_lines_wireless_combined?.line || []}
                                />
                            )

                        }
                    </td>
                )
                break;
            case 6: // Gi?m gia k?t h?p t?ng s? ti?n - Total combine discount
            //console.log("renderDiscountOption case 6")
            // 업무번호 3593 KT 총액결합 2회선 추가
            /*
                if(this.state.check_kt_premium == 1 && lines.length == 5){
                    let wireProduct = this.state.wire_product.selected;
                    let discount_line_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.discount_line_cnt : 0; //기존 wireProduct.wire_device.discount_line_cnt = 5의 값
                    let line_can_select_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.wireless_select_line_cnt : 0;
                    //3593 수정 start
                    //console.log("this.state.options_modal.kt_premium_discount.selected", this.state.options_modal.kt_premium_discount.selected)
                    if(this.state.check_case == 1 ){
                    //if(this.state.check_case == 1 && this.state.options_modal.kt_premium_discount.selected){    
                        if(lines.length == 5 || lines.length == 7){
                            discount_line_cnt = 7;
                            line_can_select_cnt = 7;

                            if(lines.length == 5){
                                for (let i = 6; i <= 7; i++) { //초반 생성 회선 수 증가
                                    lines.push({
                                        idx: i,
                                        plan: this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 1 ? this.props.WirelessAdvice.WirelessAdviceStep3.packageFee : null,
                                        discount_wireless_price: 0,
                                        discount_condition_name: [],
                                        is_commitment: false,
                                        disabled: true
                                    })
                                }
                            }

                            if(wireProduct != null){
                                wireProduct.wire_device.discount_line_cnt = 7;
                                wireProduct.wire_device.wire_select_line_cnt = 7;
                                wireProduct.wire_device.wireless_select_line_cnt = 7;
                            }

                            _.map(lines, (plan, i) => {
                                plan.plan = i < line_can_select_cnt ? plan.plan : null;
                                plan.discount_wireless_price = 0;
                                plan.discount_condition_name = [];
                                if(this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee){
                                    plan.is_commitment = true;
                                }
                                // plan.is_commitment = this.props.adviceType == ADVICE_TYPE.WIRELESS && i == 0 && !this.props.WirelessAdvice.WirelessAdviceStep3.optionFee ? true : false
                                plan.disabled = plan.idx > +line_can_select_cnt ? true : false;
                            });
                            
                            this.setState({
                                plan_modal: {
                                    ...this.state.plan_modal,
                                    discount_line_cnt,
                                    line_can_select_cnt,
                                    plans: lines
                                },
                                wire_product: {
                                    ...this.state.wire_product,
                                    line_can_select_cnt,
                                    discount_line_cnt,
                                    selected:wireProduct,
                                    wireDiscount: [],
                                },
                            })
                            //console.log("케이스 6으로 넘어가는 부분 wire_product", this.state.wire_product)

                        }
                    }
                }
                else if(this.state.check_kt_premium != 1 && lines.length == 7){
                    console.log("모바일 변경 확인")
                    let wireProduct = this.state.wire_product.selected;
                    let discount_line_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.discount_line_cnt : 0; //기존 wireProduct.wire_device.discount_line_cnt = 5의 값
                    let line_can_select_cnt = wireProduct.wire_device && !_.isEmpty(wireProduct.wire_device) ? wireProduct.wire_device.wireless_select_line_cnt : 0;
                    lines.splice(5,2);
                    //discount_line_cnt = 5;
                    //line_can_select_cnt = 5;
                    //wireProduct.wire_device.discount_line_cnt = 7;
                    //wireProduct.wire_device.wire_select_line_cnt = 7;
                    //wireProduct.wire_device.wireless_select_line_cnt = 7;
                    this.setState({
                        plan_modal: {
                            ...this.state.plan_modal,
                            discount_line_cnt,
                            line_can_select_cnt,
                            plans: lines
                        },
                        wire_product: {
                            ...this.state.wire_product,
                            line_can_select_cnt,
                            discount_line_cnt,
                            selected:wireProduct,
                            wireDiscount: [],
                        },
                    })
                } //3593 수정 end
                */
                console.log("KTPremiumDiscount2의 선택 사항 확인 중", this.state.options_modal.kt_premium_discount.selected)
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.wire_product.selected.wire_device && (
                                <Fragment>
                                    <DiscountIndicated
                                        selected={this.state.options_modal.discount_indicated.selected}
                                        onSelect={this.onSelectOptionDiscount}
                                        plans={this.state.plan_modal.plans}
                                        discount_line_cnt={discount_line_cnt}
                                        kt_premium_discount={this.state.options_modal.kt_premium_discount.selected}
                                    />
                                    <KTPremiumDiscount2
                                        selected={this.state.options_modal.kt_premium_discount.selected}
                                        onSelect={this.onSelectOptionDiscount}
                                        plans={this.state.plan_modal.plans}
                                        discount_line_cnt={discount_line_cnt}
                                        wireCombineCondition={this.state.wire_product.selected.wire_device}
                                        exceptSelected={this.state.options_modal.kt_premium_discount_except.selected}
                                    />
                                    <KTTeenagers
                                     selected={this.state.options_modal.kt_teenagers_promotion.selected}
                                     onSelect={this.onSelectOptionDiscount}
                                     plans={this.state.plan_modal.plans}
                                     discount_line_cnt={discount_line_cnt}
                                     kt_premium_discount={this.state.options_modal.kt_premium_discount.selected}
                                     wireCombineCondition={this.state.wire_product.selected.wire_device}
                                     exceptSelected={this.state.options_modal.kt_premium_discount_except.selected}
                                    />
                                </Fragment>
                            )
                        }
                    </td>
                )
                break;
            case 10: // LG Unlimited family love
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.wire_product.selected.wire_device && (
                                <DiscountIndicated
                                    selected={this.state.options_modal.discount_indicated.selected}
                                    onSelect={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                    discount_line_cnt={discount_line_cnt}
                                />
                            )
                        }
                    </td>
                )
                break;
            case 11: // LG Unlimited family love (k?t h?p khong day)
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}
                        />
                        <LGUPatron
                            selected={this.state.options_modal.lgu_patron.selected}
                            onSelect={this.onSelectOptionDiscount}
                        />
                    </td>
                )
                break;
            case 12: // LG Unlimited family love (k?t h?p khong day)
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}
                        />
                    </td>
                )
                break;
            case 13: // LG k?t h?p simple family
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}
                        />
                    </td>
                )
                break;
            case 14: // LG Home2 HanBang
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}
                        />
                        {
                            this.state.wire_product.selected.wire_device && (
                                <UTV
                                    isApply={this.state.options_modal.u_tv.selected}
                                    onSelect={this.onSelectOptionDiscount}
                                    plans={this.state.plan_modal.plans}
                                    // disabled={_.reject(this.state.plan_modal.plans, {plan: null}).length == 1 ? false : true}
                                    wireCombineCondition={this.state.wire_product.selected.wire_device}
                                />
                            )

                        }
                    </td>
                )
                break;
            case 15: // LG HanBang Yo
                xhtml = (
                    <td rowSpan={lines.length}>
                        {
                            this.state.wire_product.selected.wire_device && (
                                <Fragment>
                                    <div className="form-group">
                                        <label className="text-name text-wrap text-bold">2회선 이상 결합 <span
                                            className="weight-normal">(1인 이상 65.8 요금제 이용)</span>
                                        </label>
                                        <button className="btn btn-input-form h-32 mb-0" type="button"
                                                onClick={() => this.onSelectOptionDiscount(!this.state.options_modal.over_2_lines_combine.selected, 'over_2_lines_combine')}

                                        >
                                            {this.state.options_modal?.over_2_lines_combine?.selected ? "적용" : "미적용"}
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <label className="text-name text-wrap text-bold">TV 일반형 이상 가입 시
                                            <span
                                                className="weight-normal">(대표자 추가할인)</span>
                                        </label>
                                        <button className="btn btn-input-form h-32 mb-0" type="button"
                                                onClick={() => this.onSelectOptionDiscount(!this.state.options_modal.tv_standard.selected, 'tv_standard')}
                                        >
                                            {this.state.options_modal?.tv_standard?.selected ? "적용" : "미적용"}
                                        </button>
                                    </div>
                                </Fragment>


                            )
                        }
                    </td>
                )
                break;
            case 17:
            case 18:
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated 
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}

                        />
                        {
                            plans_line_not_null.length > 1 && <TeenagerPromotion
                                selected={this.state.options_modal.teenagers_promotion.selected}
                                onSelect={this.onSelectOptionDiscount}
                                plans={this.state.plan_modal.plans}
                                discount_line_cnt={discount_line_cnt}
                                soldierLguplus={this.props.WirelessAdvice?.WirelessAdviceStep3?.soldierLguplus}
                            />
                        }
                        {
                            plans_line_not_null.length > 1 && <NanumDiscount 
                                selected={this.state.options_modal.nanum_discount.selected}
                                onApply={this.onSelectOptionDiscount}
                                plans={this.state.plan_modal.plans}
                            />
                        }
                    </td>
                )
                if (this.state.wire_product?.wireDiscount[0]?.device?.state == 0) {
                    xhtml = <td rowSpan={lines.length}></td>
                }
                break;
            case 19:
                xhtml = (
                    <td rowSpan={lines.length}>
                        <DiscountIndicated
                            selected={this.state.options_modal.discount_indicated.selected}
                            onSelect={this.onSelectOptionDiscount}
                            plans={this.state.plan_modal.plans}
                            discount_line_cnt={discount_line_cnt}

                        />
                    </td>
                )
                if (this.state.wire_product?.wireDiscount[0]?.device?.state == 0) {
                    xhtml = <td rowSpan={lines.length}></td>
                }
                break;
            default:
                xhtml = (
                    <td rowSpan={lines.length}>


                    </td>
                );
                break
        }
        return xhtml
    }

    renderLines() {
        let lines = this.state.plan_modal.plans;
        //console.log("renderLines의 this.state.plan_modal.plans", lines)
        return (
            <Fragment>
                {
                    lines && lines.length > 0 ? lines.map((item, i) => {
                        return (
                            <tr key={i}
                                className={!this.state.discount_condition.discount_condition_selected || item.disabled ? "disabled-tr" : ""}>
                                <td>
                                    <div className="row w-200">
                                        <div className="col-4">
                                            <p className="mb-0 text-name text-wrap text-bold">회선 {item.idx}</p>
                                            {item.idx == 1 && (
                                                <p className="mb-0 mt-0 text-name text-wrap text-gray-white">(대표)</p>)}
                                        </div>
                                        <div className="col-8 pl-0">
                                            <button className="btn h-32 btn-input-form mb-0 form-control"
                                                    disabled={!this.state.discount_condition.discount_condition_selected || (this.props.adviceType == ADVICE_TYPE.WIRELESS && item.idx == 1) || item.disabled ? true : false}
                                                    onClick={(e) => this.openPlanModal(e, item.idx)}
                                            >
                                                {this.state.discount_condition.discount_condition_selected && item.plan && item.plan.name && !item.disabled ? item.plan.name : "미적용"}
                                            </button>
                                            <p className="text-small-blue mb-0">
                                                {this.state.discount_condition.discount_condition_selected
                                                && item.discount_condition_name
                                                && item.discount_condition_name.length > 0
                                                && !item.disabled
                                                && item.discount_condition_name.map((obj, i) => {
                                                    return obj.short_name ? `${obj.short_name} ${i + 1 < item.discount_condition_name.length ? "/" : ""} ` : (obj.name ? `${obj.name} ${i + 1 < item.discount_condition_name.length ? "/" : ""} ` : "");
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex pb-3 justify-content-end">
                                        <p className="mb-0 text-wireless blue-white text-bold font-16">
                                            {this.state.discount_condition.discount_condition_selected && item.discount_wireless_price && +item.discount_wireless_price > 0
                                                ? `-${ priceFormat(roundUpNumber(item.discount_wireless_price))}`
                                                : 0}
                                            <span className="weight-normal text-gray">원</span></p>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className="custom-control mb-2 d-flex justify-content-center pl-0 custom-checkbox">
                                        <input type="checkbox"
                                               className={`custom-control-input form-control`}
                                            // disabled={!this.state.discount_condition.discount_condition_selected
                                            // ||(this.props.adviceType == ADVICE_TYPE.WIRELESS && item.idx == 1)
                                            // || item.disabled ? true : false}
                                               id={`is_commitment_plan_${item.idx}`}
                                               checked={this.state.plan_modal.plans[i].is_commitment}
                                               onChange={() => this.onChangeIsCommitment(item.idx)}
                                        />
                                        <label
                                            className={`custom-control-label ${!this.state.discount_condition.discount_condition_selected
                                            || (this.props.adviceType == ADVICE_TYPE.WIRELESS && item.idx == 1)
                                            || item.disabled ? "cursor-not-allowed" : ""}`}
                                            htmlFor={`is_commitment_plan_${item.idx}`}
                                            // disabled={!this.state.discount_condition.discount_condition_selected || (this.props.adviceType == ADVICE_TYPE.WIRELESS && item.idx == 1)  || item.disabled  ? true : false}
                                        ></label>
                                    </div>
                                </td>
                                {//결합옵션 화면 출력 담당
                                    i == 0 &&
                                    (
                                        this.renderDiscountOption()
                                    )
                                }
                            </tr>

                        )
                    }) : (
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td rowSpan={5} height="300px">
                            </td>
                        </tr>
                    )
                }
            </Fragment>

        )
    }


    getStatusButtonCombineCondition = () => {
        let result = {
            disabled: false,
            message: ""
        }
        let combineDiscount = this.state.discount_condition.discount_condition_selected;
        let wire_device = this.state.wire_product.selected.wire_device;
        let plan = this.state.plan_modal.plans.length > 0 && this.state.plan_modal.plans[0] ? this.state.plan_modal.plans[0].plan : null;
        let messages = {
            at_least_1_plan: (<span className="notification_delete"> 무선상품을 1개 이상 선택하셔야 합니다.</span>),
            at_least_1_wire: (<span className="notification_delete"> 유무선 결합상품은 유선상품을 선택하셔야 합니다.</span>),
            combine_comdition_not_apply: (
                <span className="notification_delete"> 결합을 적용할 수 없습니다. <br/> 결합조건을 확인해 주세요.</span>)
        }
        if (combineDiscount) {
            switch (combineDiscount.id) {
                case 1:
                case 2:
                case 3:
                case 6:
                case 8:
                case 9:
                case 10:
                case 13:
                case 14:
                case 15:
                case 16:
                case 18:
                    if (!wire_device) {
                        result.disabled = true;
                        result.message = messages.at_least_1_wire
                    }
                    if (wire_device && _.isEmpty(plan)) {
                        result.disabled = true;
                        result.message = messages.at_least_1_plan
                    }
                    break;
                case 4:
                case 5:
                case 7:
                case 11:
                case 12:
                case 17:
                    if (wire_device && _.isEmpty(plan)) {
                        result.disabled = true;
                        result.message = messages.at_least_1_plan
                    }
                    break;
                default:

                    break;
            }
        } else {
        }
        return result
    }
    onApplyCombineDiscount = () => {
        let result = this.getStatusButtonCombineCondition();
        if (result.disabled) {
            this.setState({
                error_modal: {
                    isShowModal: true,
                    message: result.message
                }
            })
        } else {
            let data = {
                discount_condition: this.state.discount_condition.discount_condition_selected,
                wire_product: this.state.wire_product.wireDiscount,
                plan_modal: {
                    line_discount_matched: this.state.plan_modal.line_discount_matched,
                    plans: this.state.plan_modal.plans,
                },
                option_discount: {
                    discount_indicated: this.state.options_modal.discount_indicated.selected,
                    tv_plus: this.state.options_modal.tv_plus.selected,
                    sub_years: this.state.options_modal.sub_years.selected,
                    kt_number_line_wire_combine: this.state.options_modal.kt_number_line_wire_combine.selected,
                    lgu_patron: this.state.options_modal.lgu_patron.selected,
                    u_tv: this.state.options_modal.u_tv.selected,
                    over_2_lines_combine: this.state.options_modal.over_2_lines_combine.selected,
                    tv_standard: this.state.options_modal.tv_standard.selected,
                    kt_premium_discount: this.state.options_modal.kt_premium_discount.selected,
                    kt_premium_discount_except: this.state.options_modal.kt_premium_discount_except.selected,
                    teenagers_promotion: this.state.options_modal.teenagers_promotion.selected,
                    nanum_discount:this.state.options_modal.nanum_discount.selected,
                    kt_teenagers_promotion:this.state.options_modal.kt_teenagers_promotion.selected,
                }
            }

            this.setState({
                dataApplied: JSON.stringify(data)
            }, async () => {
                await this.props.onApplyCombineDiscount({...data})
            });

        }

    }

    onCancelCombineDiscount = () => {
        if (this.props.adviceType == ADVICE_TYPE.WIRE && !this.props.WiredAdvice?.WiredAdviceStep2?.isCombined) {
            this.setState({
                discount_condition: {
                    ...this.state.discount_condition,
                    discount_condition_selected: null
                },
                wire_product: {
                    ...this.state.wire_product,
                    isShowModal: false,
                    line_can_select_cnt: 0,
                    discount_line_cnt: 0,
                    dataWireDeviceByCombineID: [],
                    wireDiscount: [],
                },
                options_modal: {
                    ...this.state.options_modal,
                    discount_indicated: {
                        selected: null
                    },
                    tv_plus: {
                        selected: {
                            type: null,
                            line: null
                        }
                    },
                    sub_years: {
                        selected: null
                    },
                    lgu_patron: {
                        selected: null
                    },
                    u_tv: {
                        selected: false
                    },
                    over_2_lines_combine: {
                        selected: false
                    },
                    tv_standard: {
                        selected: false
                    },
                    kt_premium_discount: {
                        selected: null
                    },
                    teenagers_promotion: {
                        selected: null
                    },
                    nanum_discount: {
                        selected: null
                    },
                    kt_teenagers_promotion: {
                        selected: []
                    }
                }
            }, () => this.toggle());
        } else {
            // gi? l?i gia tr? đa applied tr??c đo
            let dataApplied = JSON.parse(this.state.dataApplied);
            if (dataApplied && !_.isEmpty(dataApplied)) {
                this.setState({
                    discount_condition: {
                        ...this.state.discount_condition,
                        discount_condition_selected: dataApplied.discount_condition
                    },
                    plan_modal: {
                        ...this.state.plan_modal,
                        plans: dataApplied?.plan_modal?.plans
                    },
                    options_modal: {
                        ...this.state.options_modal,
                        discount_indicated: {
                            selected: dataApplied?.option_discount?.discount_indicated
                        },
                        tv_plus: {
                            selected: {
                                type: dataApplied?.option_discount?.tv_plus?.type,
                                line: dataApplied?.option_discount?.tv_plus?.line
                            }
                        },
                        sub_years: {
                            selected: dataApplied?.option_discount?.sub_years
                        },
                        kt_number_line_wire_combine: {
                            selected: dataApplied?.option_discount?.kt_number_line_wire_combine
                        },
                        lgu_patron: {
                            selected: dataApplied?.option_discount?.lgu_patron
                        },
                        u_tv: {
                            selected: dataApplied?.option_discount?.u_tv
                        },
                        over_2_lines_combine: {
                            selected: dataApplied?.option_discount?.over_2_lines_combine
                        },
                        tv_standard: {
                            selected: dataApplied?.option_discount?.tv_standard
                        },
                        kt_premium_discount: {
                            selected: dataApplied?.option_discount?.kt_premium_discount
                        },
                        teenagers_promotion: {
                            selected: dataApplied?.option_discount?.teenagers_promotion
                        },
                        nanum_discount: {
                            selected: dataApplied?.option_discount?.nanum_discount
                        },
                        kt_teenagers_promotion: {
                            selected: dataApplied?.option_discount?.kt_teenagers_promotion
                        }
                    }
                }, () => this.toggle());
            } else {
                this.toggle()
            }

        }
    }

    getKtPremiumText = () => {
        let result          = (<Fragment>무선상품</Fragment>);
        let combineDiscount = this.state.discount_condition.discount_condition_selected;

        if(combineDiscount){
            if(+combineDiscount.id == 6){
                result = (<Fragment>무선상품 <font size="1" style={{color:'red'}}>[22.12.01 이전결합자 여부는 요금제 선택시 적용]</font></Fragment>);
            }
        }

        return result;
    }

    render() {
        const {isShowModal} = this.state.discount_condition;
        const isShowImageSliderModal = this.state.images_slider_modal.isShowModal;
        const isShowWireProductModal = this.state.wire_product.isShowModal;
        const isShowPlanModal = this.state.plan_modal.isShowModal;
        const line_discount_matched = this.state.line_discount_matched;
        let sumWireDevice = 0; // t?ng s?n ph?m co day
        this.props.adviceType == ADVICE_TYPE.WIRE
        && _.map(this.props.WiredAdvice.WiredAdviceStep2.classify, (value, i) => {
            return _.map(value.classify, (val, key) => {
                return !_.isEmpty(val.device) ? sumWireDevice++ : false
            })
        })
        let wireDiscount = this.state.wire_product.wireDiscount.length > 0 ? _.sumBy(this.state.wire_product.wireDiscount, (value) => +value.discount) : 0;
        let lines = this.state.plan_modal.plans;

        //console.log("this.state.wire_product.selected", this.state.wire_product.selected);

        if((this.state?.discount_condition?.discount_condition_selected?.id || 0) == 13){ // 참쉬운 가족결합
            let wdId = +this.state.wire_product.selected.wire_device.wd_id;

            if(wdId == 306 || wdId == 307 || wdId == 391){ // 와이파이기본_광랜안심 100MB, 스마트 광랜안심 100MB, 프리미엄 안심 100M
                let isLteDataSenior33 = false;

                lines.map((line) => {
                    let planId = line?.plan?.id || 0;

                    if(
                        planId == 2869 || planId == 2872 || planId == 2875 || planId == 2878 || // LTE데이터 시니어 33, 추가 요금 걱정 없는 데이터 시니어 49, 추가 요금 걱정 없는 데이터 시니어 69, LTE 시니어 16.5
                        planId == 3891 || planId == 3892 || planId == 3893 // 5G 시니어 A형, 5G 시니어 B형, 5G 시니어 C형
                    ){ 
                        isLteDataSenior33 = true;
                    }
                });

                if(isLteDataSenior33){
                    wireDiscount = wireDiscount + 3100;  
                }
            }
        }
        
        return (
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-dialog combine-condition-modal"
                       contentClassName=""
                       toggle={this.toggle}
                       onOpened={this.onOpened}
                       onClosed={this.onCloseModal}
                    // backdrop={"static"}
                    >
                    <div className="modal-header">
                        <h5 className="modal-title flex-header" style={{"width" : "17%"}}>
                            유무선 결합 설정
                        </h5>
                        <div style={{"textAlign" : "right"}}>
                            <span class="text-content font-13">모비셀서비스는 참고자료로만 이용부탁드리며 관련하여 발생하는 차액에 대한 보상은 이루어지지 않습니다.</span>
                        </div>
                        <button type="button" className="close close-modal"
                            // onClick={() => this.toggle()}
                                onClick={() => this.onCancelCombineDiscount()}
                        >
                            <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt=""/>
                        </button>
                    </div>
                    <div className="modal-body wireless-connection pl-0 pr-0 pb-0 pt-1">
                        <form action="">
                            <div className="top">
                                <div className="row align-items-center">
                                    <label className="label-title col-2 mn-0 mb-0">결합상품 선택 </label>
                                    <button type="button"
                                            className="form-control btn-input-form h-44 col-5 btn-devices pr-2"
                                            onClick={(e) => this.toggleModal(e, 'discount_condition')}
                                    >
                                        {this.state.discount_condition.discount_condition_selected && this.state.discount_condition.discount_condition_selected.name
                                            ? (`[${
                                                    _.includes([4, 16, 10, 11, 12, 13, 17, 18], this.state.discount_condition.discount_condition_selected.id)
                                                        ? "유무선/무선"
                                                        : (
                                                            this.state.discount_condition.discount_condition_selected.type && this.state.discount_condition.discount_condition_selected.type.id == 104
                                                                ? "유무선"
                                                                : (this.state.discount_condition.discount_condition_selected.type && this.state.discount_condition.discount_condition_selected.type.id == 105 ? "무선" : "")
                                                        )
                                                    }]`
                                                + " " + this.state.discount_condition.discount_condition_selected.name  + " "
                                                + (this.state.discount_condition.discount_condition_selected
                                                        && ([5, 8, 10, 11, 14, 15].includes(+this.state.discount_condition.discount_condition_selected.id) ? "(신규가입불가)" : "")))
                                            : "미적용"}

                                    </button>
                                    <div className="col-3 d-flex align-items-center">
                                        <a href="#" className={`btn btn-wireless px-4 text-white h-36
                                        ${this.state.discount_condition.discount_condition_selected
                                        && this.state.discount_condition.discount_condition_selected.images
                                        && this.state.discount_condition.discount_condition_selected.images.length > 0
                                            ? "" : "disabled"}`}
                                           onClick={(e) => this.toggleModal(e, 'images_slider_modal')}
                                        >
                                            <img src="/images/icon-12.svg" alt=""/>
                                            상세보기
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <article>
                                <div className="product-wireless">
                                    <div className="row align-items-center h-36">
                                        <div className="col-2">
                                            <label className="label-title mb-0">유선상품 </label>
                                        </div>

                                        {
                                            this.props.adviceType == ADVICE_TYPE.WIRELESS ? (
                                                <button className="btn btn-input-form h-36 btn-devices pr-2 col-5 mb-0"
                                                        type="button"
                                                        disabled={(this.state.discount_condition && this.state.discount_condition.discount_condition_selected
                                                            && _.includes([4, 16, 10, 11, 12, 13, 17, 18], this.state.discount_condition.discount_condition_selected.id)) ||
                                                        (this.state.discount_condition && this.state.discount_condition.discount_condition_selected
                                                            && !this.state.onlyWirelessCombineCondition.includes(this.state.discount_condition.discount_condition_selected.id)) // [] la m?u đi?u ki?n ap d?ng to?n b? s?n ph?m internet, ch? k?t h?p vs goi phi, khong k?t h?p v?i s?n ph?m co day
                                                            ? false : true}
                                                        onClick={(e) => this.toggleModal(e, 'wire_product')}
                                                >
                                                    {/*{this.state.discount_condition?.discount_condition_selected?.type?.id !== 105 // ch? ket h?p vs co day*/}
                                                    {this.state.wire_product?.selected?.wire_device?.wire_device?.wd_name
                                                        ? this.state.wire_product.selected.wire_device.wire_device.wd_name
                                                        : "미적용"}

                                                    {this.state.wire_product.selected.wire_device
                                                    && this.state.wire_product.selected.option
                                                    && this.state.wire_product.selected.option.wdo_name && this.state.wire_product.selected.option.wdo_name !== "미적용"
                                                        ? ` + ${this.state.wire_product.selected.option.wdo_name}`
                                                        : ""}
                                                </button>
                                            ) : (
                                                <div className={"pr-2 col-5 pl-0"}>
                                                    <label className="label-title weight-normal mb-0">

                                                        {
                                                            (`
                                                            ${this.state.wire_product.selected
                                                            && this.state.wire_product.selected.wire_device
                                                            && this.state.wire_product.selected.wire_device.wire_device
                                                            && this.state.wire_product.selected.wire_device.wire_device.wd_name
                                                                ? this.state.wire_product.selected.wire_device.wire_device.wd_name
                                                                : (this.state.wire_product.selected
                                                                    && this.state.wire_product.selected.wire_device
                                                                    && this.state.wire_product.selected.wire_device.wd_name
                                                                    ? this.state.wire_product.selected.wire_device.wd_name
                                                                    : "미적용")}
                                                                ${sumWireDevice > 1 ? ` 외 ${sumWireDevice - 1}건` : "" }
                                                            `)
                                                        }
                                                    </label>
                                                </div>
                                            )
                                        }
                                        <div className="col-5">
                                            <div className="d-flex justify-content-end">
                                                <p className="mb-0 text-wireless">유선할인</p>
                                                <p className="mb-0 text-wireless blue-white text-bold ml-3 font-16">
                                                    {(+wireDiscount + +((!_.isEmpty(this.state.options_modal.tv_plus.selected.type) && this.state.options_modal.tv_plus.selected.type.id == 1) ? this.state.options_modal.tv_plus.selected.type.price : 0)) > 0
                                                        ? `-${priceFormat(+wireDiscount + +((!_.isEmpty(this.state.options_modal.tv_plus.selected.type) && this.state.options_modal.tv_plus.selected.type.id == 1) ? this.state.options_modal.tv_plus.selected.type.price : 0))}`
                                                        : 0}<span className="weight-normal text-gray">원</span>
                                                </p> {/* ti?n gi?m gia  = ti?n gi?m gia co day + ti?n gi?m gia TV plus*/}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th className="pb-2">
                                            {this.getKtPremiumText()}
                                        </th>
                                        <th className="text-right text-wireless pb-2">무선할인</th>
                                        <th className="text-center text-wireless pb-2">(선택약정 25%)</th>
                                        <th className="pb-2">결합옵션</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderLines()}

                                    </tbody>
                                </table>
                            </article>
                            <div className="modal-footer justify-content-center border-top-0 pt-0">
                                <button type="button"
                                        className="btn btn-gray"
                                    // onClick={() => this.toggle()}
                                        onClick={() => this.onCancelCombineDiscount()}
                                >취소
                                </button>
                                <button type="button"
                                    // disabled={this.getStatusButtonCombineCondition()}
                                        className="btn btn-submit"
                                        onClick={(e) => this.onApplyCombineDiscount(e)}
                                >적용
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <DiscountConditionModal 
                    toggleModal={this.toggleModal}
                    isShowModal={isShowModal}
                    onSelect={this.onSelectDiscountCondition}
                    selected={this.state.discount_condition.discount_condition_selected}
                    adviceType={this.props.adviceType}
                    telcomID={this.state.telcomID}
                    combinesDiscount={this.state.discount_condition.list}
                    planModal={this.state.plan_modal}
                    planTogether={this.state.plan_together}
                    openCannotSelectModal={this.openModalCannotSelectPlan}
                />
                <ImageSliderModal
                    images={this.state.discount_condition
                    && this.state.discount_condition.discount_condition_selected
                    && this.state.discount_condition.discount_condition_selected.image_url}
                    toggleModal={this.toggleModal}
                    isShowModal={isShowImageSliderModal}
                />
                <WireProductModal
                    toggleModal={this.toggleModal}
                    isShowModal={isShowWireProductModal}
                    combineCondition={this.state.discount_condition.discount_condition_selected}
                    selected={this.state.wire_product.selected}
                    onApply={this.onApplyWireProduct}
                />
                <PlanInformationModal
                    toggleModal={this.togglePlanModal}
                    isShowModal={isShowPlanModal}
                    telcomID={[this.state.telcomID]}
                    planCustomResultPackage={this.planCustomResultPackage}
                    planResultPackage={this.planResultPackage}
                    onSelectPlan={this.onSelectPlan}
                    checkUnApply={true}
                    checkPlanNew={'planThird'}
                    WirelessAdvice={this.props.WirelessAdvice}
                    selected={this.state.plan_modal.plan_selected}
                    type={"CombineDiscountPlan"}
                    adviceType={this.props.adviceType}
                />

                <Modal isOpen={this.state.error_modal.isShowModal}
                       className="modal-dialog modal-dialog-centered"
                       contentClassName="pl-4 pr-4"
                       toggle={(e) => this.toggleModal(e, 'error_modal')}
                    // onOpened = {this.onOpened}
                    // onClosed = {this.onCloseModal}
                    // backdrop={"static"}
                >
                    <div className="modal-body text-center p-5">
                        {
                            this.state.error_modal.message
                        }
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                onClick={(e) => this.toggleModal(e, 'error_modal')}>확인
                        </button>
                    </div>
                </Modal>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WiredAdvice: getFormValues('WiredAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    };
};


const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CombineDiscountModal);

