import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import './PaymentForm.css';

const _KSPAY_DOMAIN = "https://kspay.ksnet.to/";
const _KSPAY_URL = "https://kspay.ksnet.to/store/KSPayWebV1.4/KSPayPWeb.jsp";
const _KSPAY_WIN_NAME = "_KSPAY_WIN";
const PAYMENT_CALLBACK = "http://192.168.1.35:8000/api/payment-result"
const STORE_ID = 2999199999

function PaymentForm({location}) {
  const refFrm = useRef(null);
  const [KSPayWeb, setKSPayWeb] = useState({
    sndPaymethod: 1000000000,
    sndOrdernumber: 'carrot_1234',
    sndGoodname: 'sndGoodname',
    sndAmount: 1004,
    sndOrdername: 'sndOrdername',
    sndEmail: 'sndEmail@carrot.co.kr',
    sndMobile: '01112341234',
    sndReply: 'http://localhost:3000/payment-result',
    sndGoodType: 1,
    sndCurrencytype: 'WON',
    reWHCid: '',
    reWHCtype: '',
    reWHHash: '',
    sndStoreid: '2999199999',
    sndShowcard: 'C',
    iframeYn: 'Y',
  })
  const [frmTarget, setFrmTarget] = useState('')
  const [frmAction, setFrmAction] = useState('')
  const [kspayFrm, setKspayFrm] = useState('')
  const [kspayFrmTarget, setKspayFrmTarget] = useState(null)
  const [kspayFrmAction, setKspayFrmAction] = useState(null)
  const [iWinX, setIWinX] = useState(0)
  const [iWinY, setIWinY] = useState(0)
  const [popupFlag, setPopupFlag] = useState(false)
  const [kspayPopTxKey, setKspayPopTxKey] = useState(null)
  const [kspayPopObj, setKspayPopObj] = useState(null)
  const [showPopup, setshowPopup] = useState(false)

  // useEffect(() => {
  //   var script = document.createElement("script");
  //   script.type = "text/javascript";

  //   // Add script content

  //   script.innerHTML = `
  //     var _KSPAY_DOMAIN = "https://kspay.ksnet.to/";
  //     var _KSPAY_URL = "https://kspay.ksnet.to/store/KSPayWebV1.4/KSPayPWeb.jsp";
  //     var _KSPAY_WIN_NAME = "_KSPAY_WIN";

  //     var _kspay_pop_obj;
  //     var _kspay_pop_tx_key;
  //     var _kspay_pop_status = 0;
  //     var _kspay_pop_callback;
  //     var cnt = 0;
  //     var popupStatus = false;

  //     var _kspay_frm_target;
  //     var _kspay_frm_action;
  //     var _kspay_frm;
  //     var closeIframe;

  //     document.writeln('<script type="text/javascript" src="" + _KSPAY_DOMAIN + "store/KSPayWebV1.4/js/vendors/jquery.min.k.js"></script>');
  //     document.writeln('<script type="text/javascript" src="" + _KSPAY_DOMAIN + "store/KSPayWebV1.4/js/vendors/jquery.selectric.min.js"></script>');

  //     var submitI = function(formid, ifrmName, iWinX, iWinY) {

  //       if (formid.target != ifrmName) {
  //         if (document.getElementById("popup") == null) {
  //           var installpop = "";
  //           installpop += '<div id="overlay" style="position:fixed; top:0; left:0; width:100%; height:100%; background: #000;  opacity: 0.7; z-index: 9999;" ></div>';
  //           installpop += '<div id="popup" style="position:absolute; top:50%; left:50%; width:822px; height:630px; border-radius:20px; margin-left:-411px;margin-top:-315px;  z-index: 10000; text-align: center; " >" >';
  //           installpop += '<iframe id="payment-frame" name="payment-frame" src="" width="822" height="630" scrolling="no" frameborder="0" allowtransparency="true" style="background: #FFFFFF; border-radius:20px;"></iframe>';
  //           installpop += '</div>';

  //           var attachElement = document.body;
  //           var newDiv;
  //           newDiv = document.createElement("span");
  //           newDiv.innerHTML = installpop;
  //           attachElement.appendChild(newDiv);
  //         }
  //       }
  //       // $("#popup").html('<iframe id="payment-frame" name="payment-frame" src="" width="' + iWinX + '" height="' + iWinY + '" scrolling="no" frameborder="0" allowtransparency="true" style="background: #FFFFFF; border-radius:20px;"></iframe>');
  //       // $("#overlay, #popup").show();

  //       formid.method = "post";
  //       formid.target = "payment-frame";
  //       // var _call_url = _KSPAY_URL;
  //       // _call_url = _call_url;
  //       formid.action = _KSPAY_URL;
  //       formid.submit();

  //     };

  //     function isMobile() {
  //       return false
  //       var filter = "win16|win32|win64|mac|macintel";
  //       if (navigator.platform) {
  //         if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       }
  //     }

  //     function _pay(_frm) {
  //       if (isMobile()) {
  //         alert("isMobile()");
  //         return;
  //       }
  //       if ("undefined" != typeof(_frm.sndMinAmount) && null != _frm.sndMinAmount.value) {
  //         if (parseInt(_frm.sndMinAmount.value, 10) > parseInt(_frm.sndAmount.value, 10)) {
  //           alert(_frm.sndMinAmount.value + "sndMinAmount.");
  //           return;
  //         }
  //       }
  //       if ("undefined" != typeof(_frm.sndMaxAmount) && null != _frm.sndMaxAmount.value) {
  //         if (parseInt(_frm.sndMaxAmount.value, 10) < parseInt(_frm.sndAmount.value, 10)) {
  //           alert(_frm.sndMaxAmount.value + "sndMaxAmount.");
  //           return;
  //         }
  //       }
  //       if (_frm.sndStoreid.value == "2999199996" && (parseInt(_frm.sndAmount.value, 10) < 1000 || parseInt(_frm.sndAmount.value, 10) > 1000000)) {
  //         alert("sndStoreid");
  //         return;
  //       }
  //       if ('_KSPAY_WIN' != _frm.target) {
  //         _kspay_frm_target = _frm.target;
  //         _kspay_frm_action = _frm.action;
  //         _kspay_frm = _frm;
  //       }

  //       var u_agent = navigator.userAgent;
  //       var trident = u_agent.match(/Trident\\/(\\d.\\d)/i);

  //       if (trident != null) {
  //         if (trident[1] == "5.0" || trident[1] == "6.0" || trident[1] == "7.0") {
  //           iWinX = "820";
  //           iWinY = "630";
  //         } else {
  //           iWinX = "822";
  //           iWinY = "610";
  //         }
  //       } else {
  //         iWinX = "822";
  //         iWinY = "630";
  //       }

  //       if ("undefined" != typeof(_frm.iframeYn) && null != _frm.iframeYn.value && _frm.iframeYn.value == "N") {
  //         popupFlag = true;
  //       } else {
  //         popupFlag = false;
  //       }
  //       if (!popupFlag) {
  //         submitI(_frm, _KSPAY_WIN_NAME, iWinX, iWinY);
  //       } else {
  //         _kspay_pop_tx_key = "KSPAY" + (new Date().getTime()); //여러 번 호출될 수 있으니 인증값 수신여부 체크를 위한 키를 생성한다.
  //         if (_frm.target != _KSPAY_WIN_NAME) {
  //           var installpop = "";
  //           installpop += '<div id="" + _KSPAY_WIN_NAME + "_div" style="position:fixed; top:0; left:0; width:100%; height:100%; z-index:20000000000000000000000000;">';
  //           installpop += '<div style="position:absolute; top:0; left:0; width:100%; height:100%; background:#000; opacity:.5; filter:alpha(opacity=50); line-height:450px;"></div>';
  //           installpop += '</div>';

  //           var attachElement = document.body;
  //           var newDiv;
  //           newDiv = document.createElement("div");
  //           newDiv.setAttribute("id", _KSPAY_WIN_NAME);
  //           newDiv.innerHTML = installpop;
  //           attachElement.appendChild(newDiv);
  //         }
  //         closeIframe = function() {
  //           var element = document.getElementById(_KSPAY_WIN_NAME);
  //           element.parentNode.removeChild(element);
  //         };
  //         var left_ = screen.width;
  //         var top_ = screen.height;

  //         left_ = left_ / 2 - (iWinX / 2);
  //         top_ = top_ / 2 - (iWinY / 2);

  //         _kspay_pop_obj = window.open('about:blank', _KSPAY_WIN_NAME,
  //           'height=' + iWinY + ',width=' + iWinX + ',dependent=yes, status=no, minimizable=no, fullscreen=no, location=no, scrollbars=no, menubar=no, toolbar=no, titlebar=no, directories=no, resizable=no,left=' + left_ + ',top=' + top_ + '');

  //         if (_kspay_pop_obj == null) {
  //           if (_KSPAY_WIN_NAME == _frm.target) {
  //             _frm.target = _kspay_frm_target;
  //             _frm.action = _kspay_frm_action;
  //             _kspay_frm = null;
  //           }

  //           alert("팝업이 차단되어 결제를 진행하지 못했습니다. 팝업차단을 해제해주세요."); //팝업이 차단되어 결제를 진행하지 못했습니다. 팝업차단을 해제해주세요.

  //           return false;
  //         }

  //         var _call_url = _KSPAY_URL;

  //         _kspay_pop_callback = "";
  //         _kspay_pop_status = 0;
  //         popupStatus = true;
  //         _KSP_CHECK_CHILD();
  //         _frm.target = _KSPAY_WIN_NAME;
  //         _frm.action = _call_url;

  //         _frm.submit();
  //       }

  //     }

  //     function closePayment() {
  //       try {
  //         $("#overlay,#popup").hide();
  //         $("#payment-frame").remove();
  //       } catch (e) {}
  //     }

  //     function closeEvent() {
  //       try {
  //         if (popupFlag) {
  //           closeIframe();
  //         } else {
  //           closePayment();
  //         }
  //       } catch (e) {}
  //     }

  //     function sleep(milliseconds) {
  //       var start = new Date().getTime();

  //       for (var i = 0; i < 1e7; i++) {

  //         if ((new Date().getTime() - start) > milliseconds) {
  //           break;
  //         }
  //       }
  //     }

  //     function closeFormEvent() {
  //       try {
  //         sleep(1000);
  //         mcancel();
  //       } catch (e) {
  //         closeEvent();
  //       }
  //     }
  //     //popup
  //     function _KSP_CHECK_CHILD() {
  //       if (1 == _kspay_pop_status) return;

  //       var is_open = true;
  //       var use_polling = (("unknown" != typeof(window.localStorage)) && ("undefined" != typeof(window.localStorage)));

  //       try {
  //         if (null == _kspay_pop_obj || ("undefined" != typeof(_kspay_pop_obj.closed) && _kspay_pop_obj.closed)) is_open = false;
  //       } catch (ex) {}

  //       if (use_polling && null != _kspay_pop_tx_key) {
  //         var robj = localStorage.getItem("kspay_" + _kspay_pop_tx_key);
  //         cnt++;
  //         if (null != robj) {
  //           localStorage.removeItem("kspay_" + _kspay_pop_tx_key);
  //           _kspay_pop_status = 1;

  //           if (_kspay_pop_callback) {
  //             _kspay_pop_callback(robj);
  //           }
  //           return;
  //         }
  //       }
  //       if (cnt < 300) {
  //         if (!is_open) {
  //           localStorage.removeItem("kspay_" + _kspay_pop_tx_key);
  //           if (popupStatus) {
  //             if (_KSPAY_WIN_NAME == _kspay_frm.target) {
  //               _kspay_frm.target = _kspay_frm_target;
  //               _kspay_frm.action = _kspay_frm_action;
  //               _kspay_frm = null;
  //             }
  //             closeFormEvent();
  //           }
  //           return;
  //         } else {
  //           setTimeout("_KSP_CHECK_CHILD()", 1000);
  //         }
  //       }

  //     }

  //     function _submit(_frm) {
  //       // _frm.sndReply.value = 'http://192.168.1.35:8000/api/payment-result' // getLocalUrl("payment");
  //       _pay(_frm);
  //     }

  //     function getLocalUrl(mypage) {
  //       var myloc = location.href;
  //       return myloc.substring(0, myloc.lastIndexOf('/')) + '/' + mypage;
  //     }
  //     // goResult() - 함수설명 : 결재완료후 결과값을 지정된 결과페이지(kspay_wh_result.jsp)로 전송합니다.
  //     function goResult() {
  //       document.KSPayWeb.target = "";
  //       document.KSPayWeb.action = "/payment-result";
  //       document.KSPayWeb.submit();
  //     }
  //     // eparamSet() - 함수설명 : 결재완료후 (kspay_wh_rcv.jsp로부터)결과값을 받아 지정된 결과페이지(kspay_wh_result.jsp)로 전송될 form에 세팅합니다.
  //     function eparamSet(rcid, rctype, rhash) {
  //       document.KSPayWeb.reWHCid.value = rcid;
  //       document.KSPayWeb.reWHCtype.value = rctype;
  //       document.KSPayWeb.reWHHash.value = rhash;
  //     }

  //     function mcancel() {
  //       closeEvent();
  //     }

  //     $('#ksnet-submit').click(function() {
  //       _submit(document.KSPayWeb)
  //     })
  //   `

  //   // Append

  //   document.body.appendChild(script);
  // }, [])

  useEffect(() => {
    if (window.addEventListener) {
    window.addEventListener("message", onMessage, false);        
    } 
    else if (window.attachEvent) {
      window.attachEvent("onmessage", onMessage, false);
    }
  }, [])

  const onMessage = (event) => {
    var data = event.data;
    if (data.type === 'PAYMENT_RESULT') {
      setshowPopup(false)
      if (data.status) {
      } else {
      }
    }
  }

  const _submit = () => {
    setshowPopup(true)
    _pay(refFrm.current)
  }

  function isMobile() {
    return false
    // var filter = "win16|win32|win64|mac|macintel";
    // if (navigator.platform) {
    //     if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
  }

  const _pay = (_frm) => {
    if (isMobile()) {
        alert("PC\uD658\uACBD\uC5D0\uC11C\uB9CC \uC9C0\uC6D0\uB429\uB2C8\uB2E4.");
        return;
    }
    if ("undefined" != typeof(_frm.sndMinAmount) && null != _frm.sndMinAmount.value) {
        if (parseInt(_frm.sndMinAmount.value, 10) > parseInt(_frm.sndAmount.value, 10)) {
            alert(_frm.sndMinAmount.value + "\uC6D0 \uC774\uC0C1\uC758 \uAE08\uC561\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4.");
            return;
        }
    }
    if ("undefined" != typeof(_frm.sndMaxAmount) && null != _frm.sndMaxAmount.value) {
        if (parseInt(_frm.sndMaxAmount.value, 10) < parseInt(_frm.sndAmount.value, 10)) {
            alert(_frm.sndMaxAmount.value + "\uC6D0 \uC774\uD558\uC758 \uAE08\uC561\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4.");
            return;
        }
    }
    if (_frm.sndStoreid.value == "2999199996" && (parseInt(_frm.sndAmount.value, 10) < 1000 || parseInt(_frm.sndAmount.value, 10) > 1000000)) {
        alert("1000\uC6D0 \uC774\uC0C1 1000000\uC6D0 \uC774\uD558\uC758 \uAE08\uC561\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4.");
        return;
    }
    if ('_KSPAY_WIN' != frmTarget) {
        setKspayFrmTarget(frmTarget)
        setKspayFrmAction(frmAction)
        setKspayFrm(_frm)
    }

    var u_agent = navigator.userAgent;
    var trident = u_agent.match(/Trident\/(\d.\d)/i);

    if (trident != null) {
        if (trident[1] == "5.0" || trident[1] == "6.0" || trident[1] == "7.0") {
            setIWinX("820");
            setIWinY("630");
        } else {
            setIWinX("822");
            setIWinY("610");
        }
    } else {
        setIWinX(822);
        setIWinY(630);
    }

    if ("undefined" != typeof(_frm.iframeYn) && null != _frm.iframeYn.value && _frm.iframeYn.value == "N") {
        setPopupFlag(true);
    } else {
        setPopupFlag(false);
    }
    if (!popupFlag) {
        submitI(_frm, _KSPAY_WIN_NAME, 822, 630);
    } else {
        // setKspayPopTxKey("KSPAY" + (new Date().getTime())); //여러 번 호출될 수 있으니 인증값 수신여부 체크를 위한 키를 생성한다.
        // if (frmTarget != _KSPAY_WIN_NAME) {
        //     var installpop = "";
        //     installpop += "<div id=\"" + _KSPAY_WIN_NAME + "_div\" style=\"position:fixed; top:0; left:0; width:100%; height:100%; z-index:20000000000000000000000000;\">";
        //     installpop += "<div style=\"position:absolute; top:0; left:0; width:100%; height:100%; background:#000; opacity:.5; filter:alpha(opacity=50); line-height:450px;\"></div>";
        //     installpop += "</div>";

        //     var attachElement = document.body;
        //     var newDiv;
        //     newDiv = document.createElement("div");
        //     newDiv.setAttribute("id", _KSPAY_WIN_NAME);
        //     newDiv.innerHTML = installpop;
        //     attachElement.appendChild(newDiv);
        // }
        // const closeIframe = function() {
        //     var element = document.getElementById(_KSPAY_WIN_NAME);
        //     element.parentNode.removeChild(element);
        // };
        // var left_ = screen.width;
        // var top_ = screen.height;

        // left_ = left_ / 2 - (iWinX / 2);
        // top_ = top_ / 2 - (iWinY / 2);

        // setKspayPopObj(window.open('about:blank', _KSPAY_WIN_NAME,
        //     'height=' + iWinY + ',width=' + iWinX + ',dependent=yes, status=no, minimizable=no, fullscreen=no, location=no, scrollbars=no, menubar=no, toolbar=no, titlebar=no, directories=no, resizable=no,left=' + left_ + ',top=' + top_ + ''));

        // if (kspayPopObj == null) {
        //     if (_KSPAY_WIN_NAME == frmTarget) {
        //         setFrmTarget(kspayFrmTarget);
        //         setFrmAction(kspayFrmAction);
        //         setKspayFrm(null);
        //     }

        //     alert("\uD31D\uC5C5\uC774 \uCC28\uB2E8\uB418\uC5B4 \uACB0\uC81C\uB97C \uC9C4\uD589\uD558\uC9C0 \uBABB\uD588\uC2B5\uB2C8\uB2E4. \uD31D\uC5C5\uCC28\uB2E8\uC744 \uD574\uC81C\uD574\uC8FC\uC138\uC694."); //팝업이 차단되어 결제를 진행하지 못했습니다. 팝업차단을 해제해주세요.

        //     return false;
        // }

        // var _call_url = _KSPAY_URL;

        // _kspay_pop_callback = "";
        // _kspay_pop_status = 0;
        // popupStatus = true;
        // _KSP_CHECK_CHILD();
        // frmTarget = _KSPAY_WIN_NAME;
        // frmAction = _call_url;

        // _frm.submit();
    }

}

  const submitI = (formid, ifrmName, iWinX, iWinY) => {

    // // if (formid.target != ifrmName) {
    //   if (document.getElementById("popup") == null) {
    //     // var installpop = "<iframe id=\"payment-frame\" name=\"payment-frame\" width=\"820\" height=\"70\" scrolling=\"no\" frameborder=\"0\" allowtransparency=\"true\"></iframe>";
    //     var installpop = "";
    //     installpop += "<div id=\"overlay\" style=\"position:fixed; top:0; left:0; width:100%; height:100%; background: #000;  opacity: 0.7; z-index: 9999;\" ></div>";
    //     installpop += "<div id=\"popup\" style=\"position:absolute; top:50%; left:50%; width:" + iWinX + "px; height:" + iWinY + "px; border-radius:20px; margin-left:-" + parseInt(iWinX, 10) / 2 + "px;margin-top:-" + parseInt(iWinY, 10) / 2 + "px;  z-index: 10000; text-align: center; \" >";
    //     installpop += "<iframe id=\"payment-frame\" name=\"payment-frame\" src=\"\" width=" + iWinX + " height=" + iWinY + " scrolling=\"no\" frameborder=\"0\" allowtransparency=\"true\" style=\"background: #FFFFFF; border-radius:20px;\"  ></iframe>";
    //     installpop += "</div>";

    //     var attachElement = document.body;
    //     var newDiv;
    //     newDiv = document.createElement("span");
    //     newDiv.innerHTML = installpop;
    //     attachElement.appendChild(newDiv);
    //   }
    // // }

    // $("#popup").html('<iframe id="payment-frame" name="payment-frame" src="" width="' + iWinX + '" height="' + iWinY + '" scrolling="no" frameborder="0" allowtransparency="true" style="background: #FFFFFF; border-radius:20px;"></iframe>');
    // $("#overlay, #popup").show();

    formid.method = "post";
    formid.target = "payment-frame";
    var _call_url = _KSPAY_URL;
    formid.action = _call_url;
    formid.submit();

  };

  return (
    <Fragment>
    <form className="d-flex justify-content-center" name="KSPayWeb" action="" method="post" ref={refFrm}>
      <table width="560" border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="25">&nbsp;</td>
            <td width="505" align="center">
              <table border='0' cellPadding='0' cellSpacing='0' width='500' align='center'>
                  <tbody>
                  <tr>
                    <td height="40" sstyle={{padding:'0px 0px 0px 15px'}}>
                      <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_tit3.gif" width="30" height="30" align="absmiddle" />
                      <strong>고객에게 보여지지 않아야 하는 설정값 항목</strong>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <table width="400" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td width="110">
                              <img src="data:image/gif;base64,R0lGODlhCwALAMQAALPJ3tjj7tnk79rl77LJ3n2kx/v8/YWpy26Ywfn7/Iyuzvr7/djk7rTK336kyGqWv////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwNjcgNzkuMTU3NzQ3LCAyMDE1LzAzLzMwLTIzOjQwOjQyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzMwN0E4RUI1Njk1MTFFNUJGQjVDRjhFMzBDMDg4MzMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzMwN0E4RUM1Njk1MTFFNUJGQjVDRjhFMzBDMDg4MzMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMzA3QThFOTU2OTUxMUU1QkZCNUNGOEUzMEMwODgzMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzA3QThFQTU2OTUxMUU1QkZCNUNGOEUzMEMwODgzMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAAAAAAALAAAAAALAAsAAAU9ICSO5Lg0DuI0y5goTxwriQg8B2Mwx9OIjodgFHg4RIiHYWR4IICPwYjxKIgIOB3PJ3rJZjXRKbVqlc6QEAA7" width="11" height="11" align="absmiddle" />
                              결제수단
                            </td>
                            <td width="290">
                              <select name="sndPaymethod" defaultValue={'1000000000'} className="form-control">
                                <option value="1000000000">신용카드</option>
                                <option value="0100000000">가상계좌</option>
                                <option value="0010000000">계좌이체</option>
                                <option value="0000010000">휴대폰결제</option>
                              </select>
                            </td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" />
                              주문번호
                            </td>
                            <td width="290">
                              <input className="form-control" type='text' name='sndOrdernumber' defaultValue='carrot_1234' size='30' />
                            </td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="40" style={{padding:'0px 0px 0px 15px'}}>
                      <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_tit4.gif" width="30" height="30" align="absmiddle" /> 
                      <strong>고객에게 보여주는 항목</strong>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <table width="400" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" /> 
                              상품명
                            </td>
                            <td width="290"><input className="form-control" type='text' name='sndGoodname' defaultValue='sndGoodname' size='30' /></td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" /> 
                              금액
                            </td>
                            <td width="290"><input className="form-control" type='text' name='sndAmount' defaultValue='1004' size='15' maxLength='9' /></td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" /> 
                              주문자명
                            </td>
                            <td width="290"><input className="form-control" type='text' name='sndOrdername' defaultValue='sndOrdername' size='30' /></td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" /> 
                              전자우편
                            </td>
                            <td width="290"><input className="form-control" type='text' name='sndEmail' defaultValue='sndEmail@carrot.co.kr' size='30' /></td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                          <tr>
                            <td width="110">
                              <img src="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/ico_right.gif" width="11" height="11" align="absmiddle" /> 
                              이동전화
                            </td>
                            <td width="290"><input className="form-control" type='text' name='sndMobile' defaultValue='01112341234' size='12' maxLength='12' /></td>
                          </tr>
                          <tr bgcolor="#E3E3E3">
                            <td height="1" colSpan="2"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="40" align="center"><input type="button" className="btn btn-primary" value="결 제" id="ksnet-submit" onClick={_submit} /></td>
                  </tr>
                  <tr>
                    <td height="66" align='center' background="https://kspay.ksnet.to/store/KSPayFlashV1.3/mall/imgs/bg_rem.gif"> 위의 파라미터는 기본파라미터만 정의되어있으며 <br />
                      추가파라미터 기능추가는 소스상에 hidden으로 처리되어있으므로 <br />
                      메뉴얼 참조후 세팅하여 사용하시면 됩니다. </td>
                  </tr>
                </tbody>
              </table>
              <input type="hidden" name="sndReply" value={PAYMENT_CALLBACK} />
              <input type="hidden" name="sndGoodType" value="1" />
              <input type="hidden" name="sndCurrencytype" value="WON" />
              <input type="hidden" name="reWHCid" value="" />
              <input type="hidden" name="reWHCtype" value="" />
              <input type="hidden" name="reWHHash" value="" />
              <input type="hidden" name="sndStoreid" value={STORE_ID} />
              <input type="hidden" name="sndShowcard" value="C" />
              <input type="hidden" name="iframeYn" value="Y" />
            </td>
            <td width="30">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </form>
    <Fragment>
      <div id="overlay" style={{display: showPopup ? 'block' : 'none'}}></div>
      <div id="popup" style={{display: showPopup ? 'block' : 'none'}}>
        <iframe
          id="payment-frame"
          name="payment-frame"
          src=""
          width="822"
          height="630"
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          style={{background: '#FFFFFF', borderRadius: '20px'}}
        ></iframe>
      </div>
    </Fragment>
    </Fragment>
  )
}

PaymentForm.propTypes = {
  location: PropTypes.object,
}

export default PaymentForm;
