import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {Modal} from 'reactstrap';
import ListDevice from './../Device/ListDevice'

const ModalSelectInstallmentMonth = ({isOpenModal, toggle, installmentMonth, onSelected}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      fade={true}
      // backdrop={"static"}
      className={'modal-dialog modal-dialog-centered modal-additional modal-mont-installment'}
    >
      <div className="">
        <div className="modal-header">
          <h5 className="modal-title">할부개월</h5>
          {/* <button type="button" className="close close-modal" onClick={toggle}>
            <img src="/images/icon-close.svg" alt="" />
          </button> */}
        </div>
        <div className="modal-body px-0">
          <ul className="list-additional">
            {[36, 30, 24, 18, 12].map((month, key) => (
              <li
                key={key}
                className={month === installmentMonth ? 'active' : ''}
                onClick={() => onSelected(month)}
              >
                {month}개월
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}

ModalSelectInstallmentMonth.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  installmentMonth: PropTypes.number.isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default ModalSelectInstallmentMonth;

