import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customizeDatepicker.css';
import moment from 'moment';
import {PACKAGE_DEFAULT, LABEL_DEFAULT_DATE , ALIAS_FOR_DATEPICKER} from './PackageDateChange';


class ChangeDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initData : [
                '미적용',
                LABEL_DEFAULT_DATE,
                '서비스 유지',
                '31일',
                '91일',
                '93일',
                '95일(당일포함)',
                '125일',
                '183일',
                '익월말(M+1)',
                'M+2',
                'M+3',
                'M+4',
                'M+5',
                'M+6'
            ],

            value : PACKAGE_DEFAULT,
            labelDate : LABEL_DEFAULT_DATE,
            isClick : false,
            resetDate: false,
        };
    }


    /**
     *
     */
    toggleModal = () => {
        this.props.toggleModal();
    }


    /**
     *
     */
    elementIconDatePicker = (value, planChangeDateSelect) => {
        let data = '';
        if (value && !this.state.resetDate) {
            data= this.state.labelDate;
        }else{
            data = value;
        }
        return (
            <div className="d-flex w-100">
                <span className="left">{value&&!this.state.resetDate ? value : this.state.labelDate}</span>
                    <div className="right">
                    <div className="btn p-0">
                        <img src="../../images/icon-calender.svg" alt=""/>
                    </div>
                </div>
            </div>
        )
       
    }

    /**
     * handle change
     */
    handleChange = (value) => {
        if (value != ALIAS_FOR_DATEPICKER) {
            this.setState({
                labelDate : LABEL_DEFAULT_DATE
            });
        }
        this.setState({
            isClick : true,
            value : value
        }, () => {
            if (value != 'date') {
                this.handleSubmit();
            }
        });
        
    }

    /**
     * closeModal
     */
    closeModal = () => {
        this.setState({
            isClick : false,
            value : PACKAGE_DEFAULT,
            labelDate : LABEL_DEFAULT_DATE,
            resetDate: false,
        })
    }

    /**
     * handle select datepicker
     */
    handleSelectDatePicker = (val) => {
        let dataFormat = moment(val).format("YYYY-MM-DD");
        this.setState({
            value : dataFormat,
            labelDate : dataFormat,
            resetDate: true,
        }, () => {
            this.handleSubmit();
        });
    }

    /**
     * get classname
     */
    getClassName = (tabIndex, planChangeDateSelect) => {
        if (this.state.isClick) {
            if (tabIndex == this.state.value) {
                return 'active';
            }
        }else{
            if (planChangeDateSelect === tabIndex) {
                return 'active';
            }
        }
        return '';
    }

    /**
     * handle submit form
     */
    handleSubmit = () => {
        let value = this.state.value;
        this.props.getValuesDateSelect(value);
        // check user click datepicker and choose date
        if (this.state.labelDate != LABEL_DEFAULT_DATE) {
            value = this.state.labelDate;
        }

        // check user click datepicker and not choose date
        if (value == ALIAS_FOR_DATEPICKER) {
            value = PACKAGE_DEFAULT;
        }

        // plus day
        if (this.state.value != 'date'){
            console.log("value", value);
            let date = value.replace(/\D/g, "");
            console.log("date", date);

            if (date) {
                console.log("this.state.value.indexOf('M')", this.state.value.indexOf("M"));

                if(this.state.value.indexOf("M") >= 0){
                    value = moment().add(+date + 1, 'M').startOf('month').format("YYYY-MM-DD");

                    console.log("after value", value);
                }
                else{    
                    let plusDay = moment(new Date()).add(date, 'days').toDate();

                    value = moment(plusDay).format("YYYY-MM-DD");    
                }
            }
        }
        let dateFormat =  moment(value).format("YYYY-MM-DD") != 'Invalid date' ? moment(value).format("YYYY-MM-DD") : value;
        this.props.getValuesDate(dateFormat);
        this.toggleModal();
    }

    /**
     * render element option
     */
    renderElement = (data, planChangeDateSelect, planChangeDateSelectDateTime) => {
        let dateTimeOld = '';
        if (planChangeDateSelectDateTime && planChangeDateSelect == 'date') {
            dateTimeOld =  planChangeDateSelectDateTime;
        }
        let element = data.map((item,index) => {
            return(
                index != 1
                    ?
                        <li key={index} 
                            className = {this.getClassName(item, planChangeDateSelect, planChangeDateSelectDateTime)} 
                            onClick={this.handleChange.bind(this,item)} 
                        >
                            <span className="left">{item}</span>
                        </li>
                    :
                        <li key={index} 
                            className = {this.getClassName(ALIAS_FOR_DATEPICKER, planChangeDateSelect, planChangeDateSelectDateTime)} 
                            onClick={this.handleChange.bind(this,ALIAS_FOR_DATEPICKER)}
                        >
                            <DatePicker
                                locale={process.env.REACT_APP_CONTENT_LANGUAGE}
                                customInput={this.elementIconDatePicker(dateTimeOld, planChangeDateSelect)}
                                onSelect={this.handleSelectDatePicker}
                                dateFormat="YYYY-MM-DD"
                            />
                        </li>


            )
        });
        return element;
    }


    render() {
        let {initData} = this.state;
        let {planChangeDateSelect, planChangeDateSelectDateTime} = this.props;
        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                    className="modal-custom-11 modal-small modal-dialog-centered modal-dialog-scrollable"
                    contentClassName=""
                    toggle = {this.toggleModal}
                       // backdrop={"static"}
                    onClosed = {this.closeModal}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            요금제 변경가능 날짜
                        </h5>
                        {/* <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.toggleModal}>
                            <img src="/images/icon-close.svg" alt=""/>
                        </button> */}
                    </div>

                    <div className="modal-body discount-card pl-0 pt-0 pr-0">
                        <form action="">
                            <ul className="list-item max-auto mt-0">
                                {this.renderElement(initData, planChangeDateSelect, planChangeDateSelectDateTime)}
                            </ul>

                            {/* <div className="modal-footer justify-content-center border-top-0 mb-2">
                                <button type="button" className="btn btn-submit pl-5 pr-5" onClick = {this.handleSubmit} >확인</button>
                            </div> */}
                        </form>
                    </div>
                </Modal>

            </Fragment>
        );
    }
}


export default ChangeDateModal;

