
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import * as helper from './../../../utils/helper'

class WirelessProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onReSelectModel = () => {
        const { WirelessAdvice } = this.props;
        const { WirelessAdviceStep3 } = WirelessAdvice
        // let discount = helper.getTextWirelessStep3Discount(WirelessAdviceStep3)
        // if (discount.length > 0) {
        //     alert(
        //         `아래 항목이 초기화 되었습니다. \n - ${discount.join(', ')}`
        //     )
        // }
        
        // if (!helper.checkBlockFeature()) {
            this.props.setPage(2)
        // }
    }

    render() {
        const { WirelessAdvice } = this.props;
        const { WirelessAdviceStep2, WirelessAdviceStep3 } = WirelessAdvice

        return (
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text button_search">
                    단말기
                    <button className="btn p-0" onClick={() => this.props.showPopupDetailDevice()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-detail.svg"} alt="" />
                    </button>
                </label>

                <div className="col-sm-8">
                    <button className="btn btn-input-form btn-devices mb-0" type="button" onClick={this.onReSelectModel}>
                        {WirelessAdviceStep3?.phone?.model_name || WirelessAdviceStep3?.phone.name || '미적용'}
                        <span className="color-product" style={{ backgroundColor: `${WirelessAdviceStep2?.color?.code}` }}>
                        </span>
                    </button>
                </div>
            </div>
        );
    }
}

WirelessProduct.propTypes = {
    WirelessAdvice: PropTypes.object,
    setPage: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {

    }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    // withReduxForm,
)(WirelessProduct);
