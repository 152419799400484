 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat, priceFormatRound} from "../../../utils/helper";

const WD_FINES_HTTB = {
    20: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 35, 14: 35, 15: 35, 16: 35, 17: -15, 18: -15, 19: -15, 20: -15, 21: -40, 22: -40, 23: -40, 24: -40},
    21: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45},
    22: null,
    23: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45},
    24: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 50, 8: 50, 9: 50, 10: 50, 11: 50, 12: 50, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45}
}

const WD_FINES_CKLC = {
    20: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 35, 14: 35, 15: 35, 16: 35, 17: -15, 18: -15, 19: -15, 20: -15, 21: -40, 22: -40, 23: -40, 24: -40},
    21: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45},
    22: null,
    23: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60, 12: 60, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45},
    24: {1: 100, 2: 100, 3: 100, 4: 100, 5: 100, 6: 100, 7: 50, 8: 50, 9: 50, 10: 50, 11: 50, 12: 50, 13: 30, 14: 30, 15: 30, 16: 30, 17: -20, 18: -20, 19: -20, 20: -20, 21: -45, 22: -45, 23: -45, 24: -45}
}

class PenaltyInquiryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    calcCommitmentRefund = (telcom, month, price = 0, discostChange = 24, listPercent) => {
        let total = 0;
        if (discostChange == 24) {
            let monthPercent = listPercent[telcom];
            if (!monthPercent) {
                return 0
            }
            [...Array(month).keys()].map((index) => {
                let percent = monthPercent[index + 1] / 100
                total += price * percent
            })

            return total
        } else if (discostChange == 12) {
            if (telcom == 20 && month == 12) { // SK 7 MOBILE
                return price * 6;
            }
        }

        return 0
    }

    calcPenalty = (optionFee, month) => {
        const { WirelessAdvice } = this.props
        const { WirelessAdviceStep1, WirelessAdviceStep3 } = WirelessAdvice
        let totalPennatlyMoney = 0;

        let totalDisclosure = +WirelessAdviceStep3.disclosureSubsidy + +WirelessAdviceStep3.additionalGrants
        let telcomID = +WirelessAdviceStep1.telcomID
        let rateAgreement = +WirelessAdviceStep3.rateAgreement
        let discostChange = +WirelessAdviceStep3.discostChange
        let optionFeeOptionalAgreement=WirelessAdvice.WirelessAdviceStep3.optionalAgreement
        optionFeeOptionalAgreement=priceFormatRound(optionFeeOptionalAgreement)
        let calcNumber = 0;

        if (optionFee == 1) {
            switch(telcomID) {
                case 17: { // SKT
                    if (month === 12) {
                        calcNumber = 6
                    } else if (month === 18) {
                        calcNumber = 12
                    }
                    totalPennatlyMoney = totalDisclosure - ((totalDisclosure / 18) * calcNumber);
                    break;
                }
                case 18: // LGU
                case 22: // U+알뜰모바일
                case 19: { // KT
                    if (month === 12) {
                        calcNumber = 365
                    } else if (month === 18) {
                        calcNumber = 545
                    }
                    let thoigiancamket = 730 // 2 years
                    totalPennatlyMoney = totalDisclosure * ((thoigiancamket - calcNumber) / (thoigiancamket - 180));
                    break;
                }
                case 20: // SK 7 MOBILE
                case 21: // KT M MOBILE
                case 23: // LG HELLOVISION KT
                case 24: { // LG HELLOVISION LG
                    let commitmentRefund = 0
                    if (month === 12) {
                        commitmentRefund = this.calcCommitmentRefund(telcomID, month, rateAgreement, discostChange, WD_FINES_HTTB)
                        calcNumber = 0.5
                    } else if (month === 18) {
                        commitmentRefund = this.calcCommitmentRefund(telcomID, month, rateAgreement, discostChange, WD_FINES_HTTB)
                        calcNumber = 0.25
                    }
                    totalPennatlyMoney = (totalDisclosure * calcNumber) + commitmentRefund;
                    break;
                }

                default:
                    return 0;
            }
        } else {
            switch(telcomID) {
                case 17: // SKT
                case 18: // LGU
                case 19: { // KT
                    if (month === 12) {
                        totalPennatlyMoney = (optionFeeOptionalAgreement * 12) * (12 / (24 - 6))
                    } else if (month === 18) {
                        totalPennatlyMoney = (optionFeeOptionalAgreement * 18) * (6 / (24 - 6))
                    }
                    break;
                }
                case 20: { // SK 7 MOBILE
                    totalPennatlyMoney = this.calcCommitmentRefund(telcomID, month, optionFeeOptionalAgreement, discostChange, WD_FINES_CKLC)
                    totalPennatlyMoney += this.calcCommitmentRefund(telcomID, month, rateAgreement, discostChange, WD_FINES_CKLC)
                    break;
                }
                case 21: { // KT M MOBILE
                    totalPennatlyMoney = this.calcCommitmentRefund(telcomID, month, optionFeeOptionalAgreement, discostChange, WD_FINES_CKLC)
                    totalPennatlyMoney += this.calcCommitmentRefund(telcomID, month, rateAgreement, discostChange, WD_FINES_CKLC)
                    break;
                }
                case 22: { // U+알뜰모바일
                    totalPennatlyMoney = 0
                    break;
                }
                case 23: // LG HELLOVISION KT
                case 24: { // LG HELLOVISION LG
                    totalPennatlyMoney = this.calcCommitmentRefund(telcomID, month, optionFeeOptionalAgreement, discostChange, WD_FINES_CKLC)
                    totalPennatlyMoney += this.calcCommitmentRefund(telcomID, month, rateAgreement, discostChange, WD_FINES_CKLC)
                    break;
                }

                default:
                    return 0;
            }
            
        }

        return totalPennatlyMoney
    }

    render() {
        const { WirelessAdvice } = this.props;
        let optionFeeDisclosureSubsidy=WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
        let optionFeeOptionalAgreement=WirelessAdvice.WirelessAdviceStep3.optionalAgreement
        optionFeeOptionalAgreement=priceFormatRound(optionFeeOptionalAgreement)
        // optionFeeDisclosureSubsidy=priceFormatRound(optionFeeDisclosureSubsidy)

        return (
            <Modal isOpen={this.props.isOpenModal}
                   // backdrop={"static"}
                   toggle={this.props.openPopupPenatly} fade={true}
                   className={'modal-dialog modal-dialog-centered modal-dialog-scrollable'}
                   contentClassName="modal-penalty content">
                <div className="modal-header">
                    <h5 className="modal-title">위약금 조회 </h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                            onClick={this.props.openPopupPenatly}>
                     <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody className="discount-card pl-0 pr-0 pt-0">
                    <div>
                        <ul className="list-item max-auto mt-0">
                            <li className="border-0 pt-0 pb-0">
                                <h6 className="modal-title">
                                    총 할인금액 ({
                                        WirelessAdvice.WirelessAdviceStep3.optionFee == 1 
                                        ? '공시지원금'
                                        : '선택약정'
                                    }) : {
                                        WirelessAdvice.WirelessAdviceStep3.optionFee == 1 
                                        ? priceFormat(optionFeeDisclosureSubsidy)
                                        : priceFormat(optionFeeOptionalAgreement * WirelessAdvice.WirelessAdviceStep3.contractMonth)
                                    } 원
                                </h6>
                            </li>
                            <li>
                                <span className="left">위약금 (12개월째)</span>
                                <div className="right">
                                    <h4 className="mb-0 title-page text-bold">
                                        {/*priceFormat((optionFeeDisclosureSubsidy*(730-365)/(730-180)))*/}
                                        {priceFormat(this.calcPenalty(WirelessAdvice.WirelessAdviceStep3.optionFee, 12))} 원
                                    </h4>
                                </div>
                            </li>
                            <li>
                                <span className="left">위약금 (18개월째)</span>
                                <div className="right">
                                    <h4 className="mb-0 title-page text-bold">
                                        {/*priceFormat((optionFeeDisclosureSubsidy*(730-545)/(730-180)))*/}
                                        {priceFormat(this.calcPenalty(WirelessAdvice.WirelessAdviceStep3.optionFee, 18))} 원
                                    </h4>
                                </div>
                            </li>
                        </ul>
                        <div className="modal-footer justify-content-center border-top-0">
                            <button
                                type="button"
                                className="btn btn-submit pl-5 pr-5"
                                onClick={this.props.openPopupPenatly}>
                                확인
                            </button>
                        </div>
                    </div>
                        {/*<div>
                            <ul className="list-item">
                                <li className="border-0 pt-0">
                                    <h6 className="modal-title">
                                        총 할인금액 (선택약정) : {priceFormat(optionFeeOptionalAgreement*WirelessAdvice.WirelessAdviceStep3.contractMonth)} 원
                                    </h6>
                                </li>
                                <li>
                                    <span className="left">위약금 (12개월째)</span>
                                    <div className="right">
                                        <h4 className="mb-0 title-page text-bold">
                                            {priceFormat((optionFeeOptionalAgreement*12)*(12/(24-6)))} 원
                                        </h4>
                                    </div>
                                </li>
                                <li>
                                    <span className="left">위약금 (18개월째)</span>
                                    <div className="right">
                                        <h4 className="mb-0 title-page text-bold">
                                            {priceFormat((optionFeeOptionalAgreement*18)*(6/(24-6)))} 원
                                        </h4>
                                    </div>
                                </li>
                            </ul>
                            <div className="modal-footer justify-content-center border-top-0">
                                <button
                                    type="button"
                                    className="btn btn-submit pl-5 pr-5"
                                    onClick={this.props.openPopupPenatly}>
                                    확인
                                </button>
                            </div>
                        </div>*/}
                </ModalBody>
            </Modal>
        );
    }
}

PenaltyInquiryModal.propTypes = {
    WirelessAdvice: PropTypes.object,
    openPopupPenatly: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PenaltyInquiryModal);
