

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { openPopupMenu, allowAccessMenu, updatePasswordMenu } from '../../store/actions/auth';
import * as routerName from './../../utils/routerName';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import api from "./../../utils/api";
import MessageJSON from "./../../utils/message.json";


class ChangePasswordPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isOpen: false,
            old_password:'',
            password:'',
            password_confirmation: '',
            errors : {
                old_password: MessageJSON.access_menu.validate.old_password.required,
                password: MessageJSON.access_menu.validate.password.required,
                password_confirmation: MessageJSON.access_menu.validate.password_confirmation.required,
            },
            focus: {
                old_password: false,                
                password: false,                
                password_confirmation: false,
            },        
            dirty: {
                old_password: false,  
                password: false,
                password_confirmation: false,
            }
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        //add whatever comparison logic you need here
        if(this.state.password !== prevState.password && !this.state.dirty.password) {
            this.setState({ 
                ...this.state,
                dirty: {
                    ...this.state.dirty,
                    password: true,
                }
            }, () => {
            });
        }

        if(this.state.password_confirmation !== prevState.password_confirmation && !this.state.dirty.password_confirmation) {
            this.setState({ 
                ...this.state,
                dirty: {
                    ...this.state.dirty,
                    password_confirmation: true,
                }
            }, () => {
            });
        }

        if(this.state.old_password !== prevState.old_password && !this.state.dirty.old_password) {
            this.setState({ 
                ...this.state,
                dirty: {
                    ...this.state.dirty,
                    old_password: true,
                }
            }, () => {
            });
        }
    }

    handleInputFocus = (event) => {
        const value = event.target.value;
        const inputName = event.target.name;

        this.setState({
            ...this.state, 
            focus: {
                ...this.state.focus,
                [inputName]: true,
            }
        })
    }

    onClose = () => {
        this.props.toggle()
        // this.setState({
        //     dirty: {
        //         old_password: false,  
        //         password: false,
        //         password_confirmation: false,
        //     }
        // }, () => {
        // })
    }

    onOpened = () => {
        this.setState({
            dirty: {
                old_password: false,  
                password: false,
                password_confirmation: false,
            }
        }, () => {
        })
    }

    handleOldPasswordChange = (event) => {
        const target = event.target;
        const value =target.value;
        const name = target.name;
        if (value.length < 1) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    old_password: MessageJSON.access_menu.validate.old_password.required
                }
            })
        } else {
            this.setState({
                ...this.state,
                old_password: value,
                errors: {
                    ...this.state.errors,
                    old_password: ''
                }
            }, () => {
            })
        }
    }
    

  handlePasswordChange = (event) => {
        const value = event.target.value;

        // if (6 <= value.length && value == this.state.password_confirmation) {
        // debugger
        if (6 <= value.length) {
            this.setState({
                ...this.state,
                password: value,
                errors: {
                    ...this.state.errors,
                    password: '',
                    // password_confirmation: ''
                }
            }, () => {
            })

            // reset error form 
            if (value == this.state.password_confirmation && this.state.dirty.password_confirmation) {
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {}
                }, () => {
                })
            } else {
                // set error to re-password
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {
                        password: '',
                        password_confirmation:  MessageJSON.access_menu.validate.password_confirmation.same
                    }
                }, () => {
                })
            }
     
        } else {
            var msgError_Pw = '';
            if (value.length < 1) {
                msgError_Pw = MessageJSON.access_menu.validate.password.required;
            }
            if (value.length < 6) {
                msgError_Pw = MessageJSON.access_menu.validate.password.min;
            }

            this.setState({
                ...this.state,
                password: value,
                errors: {
                    ...this.state.errors,
                    password: msgError_Pw,
                }
            }, () => {
            })


            if (value !== this.state.password_confirmation && this.state.dirty.password_confirmation) {
                var msgError = MessageJSON.access_menu.validate.password_confirmation.same;
                this.setState({
                    ...this.state,
                    password: value,
                    errors: {
                        password: msgError_Pw,
                        password_confirmation: msgError,
                    }
                }, () => {
                })
            }        
        }  

        // if (value.length >= 6 && value == this.state.password_confirmation && this.state.dirty.password_confirmation) {
        //     debugger
        //     this.setState({
        //         ...this.state,
        //         password: value,
        //         errors: {}
        //     }, () => {
        //     })
        // }
    }

    handlePasswordCfChange = (event) => {
        const value = event.target.value;

        if (value.length < 1) {
            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    ...this.state.errors,
                    password_confirmation: MessageJSON.access_menu.validate.password_confirmation.required
                }
            })
        }
        else if (value.length < 6) {
            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    ...this.state.errors,
                    password_confirmation:  MessageJSON.access_menu.validate.password.min
                }
            })
        }
        else if (value !== this.state.password) {
            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    ...this.state.errors,
                    password_confirmation:  MessageJSON.access_menu.validate.password_confirmation.same
                }
            })
        }
        else {
            this.setState({
                ...this.state,
                password_confirmation: value,
                errors: {
                    ...this.state.errors,
                    password_confirmation: ''
                }
            }, () => {
            })
        }
    }


    handleSubmit = () => {
        this.props.showLoading();
        let data = _.pick(this.state, ['old_password', 'password', 'password_confirmation']);   
        api.auth.changePasswordMenu(data) 
            .then(response => {
                let { user } = this.props.auth;
                user.password_menu = response.data.password_menu;

                this.props.hideLoading();
                this.props.updatePasswordMenu(user);
                this.props.allowAccessMenu(true);
                this.onClose();

                // setTimeout(() => {
                //     alert('Cập nhật mật khẩu thành công')
                // }, 1000);
            })
            .catch(err => {
                if (err.response.data.errors) {
                    this.setState({
                        ...this.state,
                        errors: {
                            ...this.state.errors,
                            old_password: err.response.data.errors.old_password?.[0],
                            // password: err.response.data.errors.password?.[0],
                            // password_confirmation: err.response.data.errors.password_confirmation?.[0],
                        },
                        dirty: {
                            ...this.state.dirty,
                            old_password: true,
                        }
                    }, () => {
                    })
                }
            
                this.props.hideLoading();
                // alert('Có lỗi xử lý')
            });
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    className="modal-dialog modal-dialog-centered"
                    contentClassName="pl-4 pr-4"
                    onOpened = {this.onOpened}
                >
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-center w-100">비밀번호 변경</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={() => this.onClose()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action>
                            <div className="form-group">
                                <label className="col-form-label label-text">기존 비밀번호</label>
                                <input 
                                    className="form-control h-44" placeholder="기존 비밀번호"
                                    name="old_password"
                                    type="password"
                                    onChange={(e) => this.handleOldPasswordChange(e)}
                                    onFocus={(e) => this.handleInputFocus(e)} 
                                />
                                {
                                    this.state.dirty.old_password && this.state.errors.old_password && (<p class="small text-danger mt-1">{this.state.errors.old_password}</p>)
                                }
                            </div>

                            <div className="form-group">
                                <label className="col-form-label label-text">신규 비밀번호</label>
                                <input 
                                    className="form-control h-44" placeholder="신규 비밀번호"
                                    name="password"
                                    type="password"
                                    onChange={(e) => this.handlePasswordChange(e)}
                                    onFocus={(e) => this.handleInputFocus(e)} 
                                />
                                {
                                     this.state.dirty.password && this.state.errors.password && (<p class="small text-danger mt-1">{this.state.errors.password}</p>)
                                }
                            </div>

                            <div className="form-group">
                                <label className="col-form-label label-text">비밀번호 확인</label>
                                <input 
                                    className="form-control h-44" placeholder="비밀번호 확인"
                                    name="password_confirmation"
                                    type="password"
                                    onChange={(e) => this.handlePasswordCfChange(e)}
                                    onFocus={(e) => this.handleInputFocus(e)} 
                                />
                                {
                                     this.state.dirty.password_confirmation && this.state.errors.password_confirmation && (<p class="small text-danger mt-1">{this.state.errors.password_confirmation}</p>)
                                }
                            </div>
                            <div className="w-100 mt-5 text-center">
                                {/* <button className="btn btn_close mx-2" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>취소2</button> */}
                                <button type="button" className="btn btn_close mx-2" data-dismiss="modal" aria-label="Close" onClick={() => this.onClose()}>
                                    취소
                                </button>
                                <button type="button" className="btn btn_save mx-2" data-dismiss="modal" aria-label="Close" onClick={this.handleSubmit}
                                    disabled={ (this.state.errors.old_password || this.state.errors.password || this.state.errors.password_confirmation) ? true : false}>
                                    확인
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => () => dispatch(change()),
        updatePasswordMenu: (data) => dispatch(updatePasswordMenu(data)),
        allowAccessMenu: (data) => dispatch(allowAccessMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ChangePasswordPopup);
