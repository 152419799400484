import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import PropTypes from 'prop-types';
import _ from "lodash";
class UTV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            list: this.props?.plans || [],
            isApply: this.props.isApply,
            disabled: true,
            wireCombineCondition: this.props?.wireCombineCondition || null
        };
    }

    componentDidMount(){
        this.setState({
            disabled: _.reject(this.props.plans, {plan: null}).length == 1 ? false : true
        })
    }

    /**
     * close modal
     */
    onCloseModal = () => {
        this.props.onSelect(this.state.isApply, "u_tv");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.setState({
            isApply: this.props.isApply
        })
    }

    onSelect = (e, value) => {
        this.setState({
            isApply: value
        }, () => {
            this.toggleModal(e)
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }


    componentDidUpdate(prevProps){
        if(prevProps.isApply !== this.props.isApply){
            this.setState({
                isApply: this.props.isApply,
            })
        }
        if(prevProps.wireCombineCondition && prevProps.wireCombineCondition !== this.props.wireCombineCondition){
            let disabled = this.checkActiveOptionDiscount() ? false : true;
            this.setState({
                wireCombineCondition : this.props.wireCombineCondition,
                disabled
            })
        }
        if(prevProps.plans && prevProps.plans !== this.props.plans){
            let disabled = this.checkActiveOptionDiscount() ? false : true;
            this.setState({
                list : this.props.plans,
                disabled
            })
        }
    }
    getPlanTypeNameById = (planType) => {
        let planTypeName = "";
        switch (+planType) {
            case 18:
                planTypeName = "conventional_commitment";
                break;
            case 19:
                planTypeName = "not_conventional_commitment";
                break;
            case 20:
                planTypeName = "fit_type_commitment";
                break;
            case 21:
                planTypeName = "not_fit_type_commitment";
                break;
            case 22:
                planTypeName = "not_3G_commitment";
                break;
            case 23:
                planTypeName = "other";
                break;
        }
        return planTypeName;
    }

    checkActiveOptionDiscount = () => {
        let result = false;
        let wireCombineCondition =  this.props.wireCombineCondition ? this.props.wireCombineCondition : this.state.wireCombineCondition;
        let plans = _.reject(this.state.list, {plan: null});
        if(plans.length == 1 && wireCombineCondition){
            let plan = plans[0];
            let utv_condition = wireCombineCondition?.condition_value?.using_U_TV_discount_conditions?.wireless_basic_discount_conditions;
            let planTypeName = this.getPlanTypeNameById(plan.plan.discost_ad_cond);
            if(utv_condition && +plan?.plan?.cost_display+ +plan?.plan?.cost_display*10/100 >= +utv_condition[planTypeName]){
                result = true;
            };
        }

        return result;
    }
    render() {
        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">U+TV 이용 시<span
                        className="weight-normal"> (회선수가 1개인 경우)</span>
                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                            disabled={this.state.disabled ? true : false}
                    >
                        { this.state.isApply ? "할인 적용" : "미적용"}
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered modal-additional"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">U+TV 이용 시 할인</h5>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional ">
                                <li className={!this.state.isApply ? "active" : "" }
                                    onClick={(e) =>  this.setState({
                                        isApply: false
                                    }, () => {
                                        this.toggleModal(e)
                                    })}
                                >
                                    미적용
                                </li>
                                <li className={this.state.isApply ? "active" : "" }
                                    onClick={(e) =>  this.setState({
                                        isApply: true
                                    }, () => {
                                        this.toggleModal(e)
                                    })}
                                >
                                    할인 적용
                                </li>
                            </ul>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

UTV.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(UTV);