import {
    PLAN_CLASSIFICATIONS_INFORMATION,
} from '../actions';

import api from "../../utils/api";

export const getPlanInformationSuccess = (data) => {
    return {
        type: PLAN_CLASSIFICATIONS_INFORMATION.GET_PLAN_INFORMATION_SUCCESS,
        data: data
    }
};


export const getPlanClassificationsSuccess = (data) => {
    return {
        type: PLAN_CLASSIFICATIONS_INFORMATION.GET_PLAN_NOTICE_INFORMATION_SUCCESS,
        data: data
    }
};


export const getPlanClassificationsInformation = (data) => dispatch => {
    dispatch(getPlanClassificationsSuccess([]));
    return api.plan_classifications_information.getPlanClassificationsInformation(data)
        .then(res => {
            dispatch(getPlanClassificationsSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

/*
export const getPlanInformation = (dataFilter) => dispatch => {
    dispatch(getPlanInformationSuccess([]));
    return api.plan_classifications_information.getPlanInformation(dataFilter)
        .then(res => {
            dispatch(getPlanInformationSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};
*/
export const getPlanInformation2 = (dataFilter) => dispatch => {
    dispatch(getPlanInformationSuccess([]));
    return api.plan_classifications_information.getPlanInformation2(dataFilter)
        .then(res => {
            dispatch(getPlanInformationSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getMatchPlanId = (dataFilter) => dispatch => {
    dispatch(getPlanInformationSuccess([]));
    return api.plan_classifications_information.getMatchPlanId(dataFilter)
        .then(res => {
            dispatch(getPlanInformationSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const getDisclosureSubsidy = (data) => dispatch => {
    return api.plan_classifications_information.getDisclosureSubsidy(data)
        .then(res => {
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

export const setBookMark = (data) => dispatch => {
    return api.plan_classifications_information.setBookMark(data)
        .then(res => {
            return Promise.resolve(res.data);
        }).catch(err => {
            return Promise.reject(false);
        })
}
