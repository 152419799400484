import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import PlanInformationModal from './PlanInformationModal';
import {getPlanInformation , getPlanClassifications} from '../../store/actions/plan_information';

class PlanInformationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal : false
        };
    }

    /**
     * show modal plan
     */
    handleShowModalPlan = () => {
        this.setState({
            isShowModal : true
        })
        
       
    }

    /**
     * 
     */
    toggleModal = () => {
        this.setState({
            isShowModal : false
        })
    }
 

    render() {
        let {isShowModal} = this.state;
        return(
            <Fragment>
                <div className="flex-wrapper">
                    <div className="left border-0">
                        <div className="form-group row align-items-start">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">포인트 할인</label>
                            <div className="col-sm-8 pl-2">
                                <input type="button" className="form-control text-left h-44" value="T플랜 에센스" onClick={this.handleShowModalPlan}/>
                            </div>
                        </div>
                    </div>
                </div>
                <PlanInformationModal 
                    toggleModal = {this.toggleModal}
                    isShowModal = {isShowModal}
                />
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
      
    };
};
const mapDispatchToProps = dispatch => {
    return {
      
    };
};
 
export default connect(mapStateToProps,mapDispatchToProps)(PlanInformationComponent);

