
import PropTypes from 'prop-types';
import React, { Component, Fragment,useState  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PenaltyInquiry from "./../PenaltyInquiry/PenaltyInquiry"
import PlanDetails from "./PlanDetails"
import {getDetailPlan} from "../../../store/actions/discount_card";
const queryString = require('query-string');

class PlanDetailsShortDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal:false,
            data:{}
        }
    }

    fetchData = (id, typePlan=false) => {

        if(typePlan) {
            let dataPlanCus={}
            dataPlanCus.plancustom=1
            this.props.getDetailPlan(id,dataPlanCus).then(res => {
                if(res.data) {
                    res.data.call_time_desc=res.data.plac_use_call
                    res.data.sms_desc=res.data.plac_use_sms
                    res.data.data_detail=res.data.plac_use_data
                    res.data.call_time_adt=0
                    res.data.extra_info=res.data.plac_use_extra
                    res.data.description=res.data.plac_use_extra
                    this.setState({
                        data : res.data
                    })
                }
            })
        }else {
            this.props.getDetailPlan(id).then(res => {
                if(res.data) {
                    this.setState({
                        data : res.data
                    })
                }
            })
        }

    }
    componentDidMount () {
        if(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {
            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee == null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {
            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
        }
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee != null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null &&
            this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee.id) {
            if(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id == undefined) {
                this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.planData.id);
            }else {
                this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id);
            }
        }
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.planData != null && this.props.WirelessAdvice.WirelessAdviceStep3.planData != null &&
            this.props.WirelessAdvice.WirelessAdviceStep3.planData.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.planData.id) {

            this.fetchData(this.props.WirelessAdvice.WirelessAdviceStep3.planData.id);
        }
    }

    openPopupPlanDetails = () => this.setState({isOpenModal: !this.state.isOpenModal})

    renderDescripton = () => {
        const {data}=this.state
        if(data && data.description) {
            let dataRender=data.description.split(/\r\n|\r|\n/g)
            return dataRender.map((item, key) => {
                return (<li key={key}><p>{item}</p></li>);
            })
        }

    }


    render() {
        const { handleSubmit, WirelessAdvice } = this.props;
        const {data}=this.state

        let welfare=WirelessAdvice.WirelessAdviceStep3.welfareDiscount

        var checkClearPhone = Object.keys(WirelessAdvice.WirelessAdviceStep3.phone).length


        return (

            <Fragment>
                <div className="row mt-2 align-items-center justify-content-between">
                    <PenaltyInquiry />
                    <div className={'col-md-1'}>
                        <span className={'border-line'}> </span>
                    </div>
                    <PlanDetails dataPlanDetail={this.state.data} />
                </div>
                <ul className="list-detail-product">
                    <li>
                        <a style={{cursor:'default'}}>
                            <img src="/images/ic_settings_phone.svg" alt="" />
                            <span>{checkClearPhone ? data.call_time_desc : ''}</span>
                        </a>
                    </li>
                    <li>
                        <a style={{cursor:'default'}}>
                            <img src="/images/icon-gmail.svg" alt="" />
                            <span>{checkClearPhone ?data.sms_desc : ''}</span>
                        </a>
                    </li>
                    <li>
                        <a style={{cursor:'default'}}>
                            <img src="/images/icon-teth.svg" alt="" />
                            <span>{checkClearPhone ? data.data_detail : ''}</span>
                        </a>
                    </li>
                    <li>
                        <a style={{cursor:'default'}}>
                            <img src="/images/ic_videocam.svg" alt="" />
                            <span>{checkClearPhone ? data.call_time_adt + '분' : ''}</span>
                        </a>
                    </li>
                </ul>
                <ul className="list-text-content">
                    {/*<li>*/}
                        {/*<p dangerouslySetInnerHTML={{__html: data.description}} />*/}
                    {/*</li>*/}

                    {checkClearPhone ? this.renderDescripton() : ''}

                </ul>
            </Fragment>
        );
    }
}

PlanDetailsShortDescription.propTypes = {
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getDetailPlan: (data,params) => dispatch(getDetailPlan(data,params)),
    }
};



const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
     //withReduxForm,
)(PlanDetailsShortDescription);
