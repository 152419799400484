import React, { Component, Fragment } from 'react'

export default class FileRequestField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: '',
            file_name: '',
            change: false
        }
        this.textInput = React.createRef();
    }

    onChange = (e) => {
        if(e.target.files[0]){
            this.props.input.onChange(e.target.files[0])
            this.setState({
                file: e.target.files[0],
                file_name: e.target.files[0].name,
                change: true
            });
        } else {
            this.setState({
                file: '',
                file_name: '',
                change: true
            })
        }
    }

    upload = () => {
        this.textInput.current.click();
    }

    render() {
        const { input: { value, name } } = this.props
        const { input, required, meta: { touched, invalid, error },file_url, accept } = this.props  //whatever props you send to the component from redux-form Field
        return (
           <div className="w-calc">
                <div className="form-input w-100 d-flex">
                <input
                    type="text"
                    className={'form-control w-calc-100 mr-17 ponter-events ' + (touched && invalid ? 'is-invalid' : '')}
                    value={this.state.file_name}
                />
                <div className="upload-file w_100  mr-0">
                    <button className="btn w_100" type="button">
                        파일첨부
                    </button>
                    <input
                        type="file"
                        name={name}
                        onChange={this.onChange}
                        accept={accept}
                    />
                </div>
            </div>
            {error !== undefined ? (
                    <div className="text-danger">
                        {error}
                    </div>
                ) : ''}
           </div>
        )
        // return (
        //     <div className="form-row">
        //         <div className="form-group col-md-12">
        //             <Fragment>
        //                 <div className={"file " + ((this.state.change || touched) && invalid ? 'is-invalid box-invalid' : '') }>
        //                     <div className="title">
        //                         {this.state.file_name ? this.state.file_name : file_url}
        //                     </div>
        //                     <input
        //                         name={name}
        //                         accept='.PDF, .doc, .docx, .xls, .xlsx, .ppt, .jpeg, .jpg, .png'
        //                         type='file'
        //                         onChange={this.onChange}
        //                         className={'form-control ' + (invalid ? 'is-invalid' : '') + ' h-44'}
        //                         hidden
        //                         id='selectImage'
        //                     />
        //                     <a type="button" className="btn btn-add-img" onClick={this.upload}>
        //                         <img src="images/icon-notifi-attachment.svg"  />
        //                     </a>
        //                 </div>
                       
        //                 {error !== undefined ? (
        //                     <div className="invalid-feedback" style={{position:'relative', top:'25px'}}>
        //                         {error}
        //                     </div>
        //                 ) : ''}
        //             </Fragment>
        //         </div>
        //     </div>
        // )
    }
}
