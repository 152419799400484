import React from 'react';
import PropTypes from 'prop-types';
import PaymentForm from './../../components/Payment/PaymentForm';

function Payment() {
  return (
    <div className="container">
      <PaymentForm />
    </div>
  )
}

Payment.propTypes = {
 //
}

export default Payment;
