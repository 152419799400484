import React from 'react';
import {Modal} from 'reactstrap'
import {numberWithCommas,priceFormat} from "../../../utils/helper";
class OpenPopupCondition extends React.Component{


    renderConditionDiscount = (data) => {
        if(data) {
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    return (
                        <li
                            key={index}
                            onClick={()=>this.onClickItem(item,'condition_discount')}
                        >
                            {item.discount_condition}, 월 {priceFormat(item.money_condition)} 원 할인
                        </li>
                    )
                })
            }
        }
        return '';
    }

    renderConditionPromotion = (data) => {
        if(data) {
            var parseJson = JSON.parse(data)
            if (parseJson && parseJson.length > 0) {
                return parseJson.map((item,index) => {
                    return (
                        <li
                            key={index}
                            onClick={()=>this.onClickItem(item,'condition_promotion')}
                        >
                            [프로모션] {item.condition}, 월 {priceFormat(item.money)} 원 할인 {
                                item?.date || ''
                            }
                        </li>
                    )
                })
            }
        }
        return ''
    }

    onClickItem = (data,state) => {
        if(state == 'condition_discount'){
            let result = {
                state,
                condition : data.discount_condition,
                money : data.money_condition
            }
            this.props.changeStateDataCondition(result)
            this.props.openPopupCondition();
        }else if(state == 'condition_promotion'){
            let result = {
                state,
                condition : data.condition,
                money : data.money,
                except : data.except
            }
            this.props.changeStateDataCondition(result)
            this.props.openPopupCondition();
        }

    }


    render(){
        let {itemData} = this.props
        return (
            <Modal
                toggle={this.props.openPopupCondition}
                // backdrop={"static"}
                isOpen={this.props.isOpenPopupCondition}
                className={'modal-dialog-centered modal-additional modal-custom-22'}
                contentClassName={'content'}
            >
                <div className="modal-header">
                    <h5 className="modal-title">할인카드 조건 </h5>
                    {/* <button
                      type="button"
                      className="close close-modal"
                      // data-dismiss="modal"
                      // aria-label="Close"
                      onClick={this.props.openPopupCondition}
                    >
                        <img src="/images/icon-close.svg" alt="" />
                    </button> */}
                </div>
                <div className="modal-body">
                    <ul className="list-additional">
                        {this.renderConditionDiscount(itemData.dcc_discount)}
                        {this.renderConditionPromotion(itemData.dcc_promotion)}
                    </ul>
                </div>
            </Modal>
        )
    }
}

export default OpenPopupCondition;
