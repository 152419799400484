 
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {priceFormat,chooseOption2G} from './../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";

import MutualServiceDetailModal from "./MutualServiceDetailModal"
import {getDetailPlan} from "../../../store/actions/discount_card";
import {detailMutualService, getListMutualService} from "../../../store/actions/dataStore";
import api from "../../../utils/api";
import {updateWirelessStep3} from "../../../utils/helper";
import {hideLoading, showLoading} from "../../../store/actions/ui";

const divStyle = {
    zIndex: '999'
};
const none = {
    display: 'none'
};

class MutualServiceGModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenServiceModal:false,
            data:{},
            modal: false,
            isGoing: false,
            checked: [],
            arrayDefaut: [],
            selected:null,
            dataService:[]
        }
        this.myRef = React.createRef();
    }


    componentDidMount () {
        const {handleSubmit, WirelessAdvice} = this.props;
        let idTelcom=WirelessAdvice.WirelessAdviceStep1 && WirelessAdvice.WirelessAdviceStep1.telcomID &&
            WirelessAdvice.WirelessAdviceStep1.telcomID != '' ? WirelessAdvice.WirelessAdviceStep1.telcomID : 0
        this.props.getMutualService(idTelcom).then(res => {
            if(res.data) {
                this.setState({
                    data : res.data
                })
            }
        })

    }
    inputRefs = [];
    setRef = (ref) => {
        if (ref && ref != null) {
            this.inputRefs.push(ref);
        }
    };
    triggerClickCheckBox = (id) => {

        let dataMapRef = []
        this.inputRefs.map((item) => {
            if (item && item.value && item.value != null && item.value == id) {
                dataMapRef.push(item)
            }
        })
        if (dataMapRef && dataMapRef.length > 0) {
            dataMapRef[0].click()
        }
    }

    openPopupService = () => {
        this.setState({isOpenServiceModal: !this.state.isOpenServiceModal})
        // this.inputRefs=[]
    }
    openPopupServiceSub = () => {
        this.setState({isOpenServiceModal: !this.state.isOpenServiceModal})
    }


    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }
    closePopup=() => {
        this.setState({selected: null})
        this.inputRefs=[]
        this.props.closePopup()
    }

    handleSubmit = () => {
        const { WirelessAdvice} = this.props;
        let mutualService=WirelessAdvice.WirelessAdviceStep3.mutualService

        let selected = this.state.selected
        if(selected == null) {
            selected=mutualService && mutualService.mus_id ? mutualService.mus_id : null
        }
        if(+selected !== 0) {
            this.openPopupService()
            this.props.showLoading()
            api.advice.detailMutualService(selected)
                .then(response => {
                    const {data} = response.data
                    this.setState({dataService: data})
                    this.props.hideLoading()

                })
                .catch(() => {
                    this.props.hideLoading();
                })
        }else {
            let dataChoose={mus_id:0, mus_name:'미적용',total_discount:0}
            this.props.change('WirelessAdviceStep3.mutualService', dataChoose)
            this.closePopup()
        }


    }

    renderDescripton = (data) => {

        if(data) {
            let dataRender=data.split(/\r\n|\r|\n/g)
            data = data.replace(/\&nbsp;/g, '')
            data = data.replace(/<img[^>]*>/g,"");

            var length = 128;
            data = data.length > length ?
                data.substring(0, length - 3) + "..." :
                data;
            // return dataRender.map((item, key) => {
            //     item = item.replace(/\&nbsp;/g, '')
            //     return (<p className="mb-0" key={key}>{item.replace(/<(.|\n)*?>/g, '')}</p>);
            // })

            return (<p dangerouslySetInnerHTML={{__html: data}} />);

        }

    }

    render() {
        const {handleSubmit, WirelessAdvice} = this.props;
        let {selected,data} = this.state;
        let mutualService=WirelessAdvice.WirelessAdviceStep3.mutualService
        if(data && data.length > 0) {
            let dataDefault=[{mus_id:0, mus_name:'미적용'}]
            data=[...dataDefault, ...data]
        }
        const html = data && data.length > 0 && data.map((item, key) => {
            // check
            let check = false
            if(mutualService && mutualService.mus_id == item.mus_id) {
                check = true;
                if(selected != null) {
                    check = false;
                }
            }

            return <tr key={key} onClick={() => this.triggerClickCheckBox(item.mus_id)} style={divStyle}>
                <td className="abc" style={{verticalAlign:'middle'}}>
                    <div className="d-flex">
                        <div className="custom-control custom-radio">
                            <input  style={none}
                                    ref={this.setRef}
                                    type="radio"
                                    id={`check-00${key}`}
                                    name="customRadio"
                                    className="custom-control-input"
                                    onChange={this.handleChangeRadio}
                                    value={item.mus_id}
                            />
                            <input type="radio" className="custom-control-input"
                                   value={item.mus_id}
                                   checked={check}
                                   onClick={() => this.triggerClickCheckBox(item.mus_id)}
                                   defaultChecked={check}
                            />
                            <label className="custom-control-label ml-3"
                                   htmlFor={`check-00${key}`} onClick={() => this.triggerClickCheckBox(item.mus_id)}
                            ></label>
                        </div>
                    </div>
                </td>
                <td style={{verticalAlign:'middle'}}>
                    {item.mus_name}
                </td>
                <td>
                    {this.renderDescripton(item.mus_desc)}
                </td>
            </tr>

        });

        return (
            <Fragment>
                <Modal isOpen={this.props.isOpenModal}
                       // backdrop={"static"}
                       toggle={this.props.closePopup} fade={true}
                       className={'modal-dialog-centered modal-additional modal-custom-06'}
                       contentClassName="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">상조서비스 선택
                        </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close"
                                onClick={()=>this.closePopup()}>
                          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <ModalBody>
                        <article className="modal-support-service">
                            <table className="table mb-0">
                                <thead>
                                <tr className="bg-purple">
                                    <th className="text-left"></th>
                                    <th className="text-left">서비스명</th>
                                    <th className="text-left">설명</th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table-scroll">
                                <table className="table">
                                    <tbody>
                                    {html}
                                    </tbody>
                                </table>
                            </div>
                        </article>
                        <div className="modal-footer justify-content-center border-top-0 mt-3">
                            <button type="button" className="btn btn-gray pl-5 pr-5 mx-2" data-dismiss="modal" onClick={()=>this.closePopup()}>취소</button>
                            <button type="button" className="btn btn-submit pl-5 pr-5 mx-2" data-toggle="modal" onClick={() => this.handleSubmit()}
                                    data-target="#modal-64">완료</button>
                        </div>
                    </ModalBody>
                </Modal>
                <MutualServiceDetailModal
                    closeFirstPopup={this.closePopup}
                    dataSelected={this.state.dataService}
                    isOpenServiceModal={this.state.isOpenServiceModal}
                    openPopupServiceSub={this.openPopupServiceSub} />
            </Fragment>
        );
    }
}

MutualServiceGModal.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        getMutualService: (data) => dispatch(getListMutualService(data)),
        detailMutualService: (data) => dispatch(detailMutualService(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(MutualServiceGModal);
