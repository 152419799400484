
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors } from 'redux-form';
import validate from './validate';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../../store/actions/modal';
import {
    loginAction,
} from '../../../store/actions/auth';
import * as routerName from '../../../utils/routerName';

class SignInForm extends Component {

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    componentDidMount () {
        document.body.classList.add("body_wireless_advice");
    }
      
    

    popupShowMessageError = (title) => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={ process.env.REACT_APP_HOSTNAME+ "/tsc_template/dist/images/icon-rattiec.svg"  } alt=""/>
                </div>
                <h4 className="text-center">
                    { title }
                </h4>
                {/* <p className="text-center">
                    Vui long nh?p l?i tai kho?n ho?c m?t kh?u
                </p> */}
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);
    }

    onChangeNullToken = () => {
        this.props.change('token', '')
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <section className="form-login">
                <div className="container">
                    <h1>로그인</h1>
                    <p>모비셀 서비스 이용을 위해 로그인해주세요</p>
                    <div className="text-center pt-3">
                        <h4>모비셀은 <span className="text-danger">모비고와 별도 회원 가입과 결제가 필요</span>합니다.</h4>
                        <h4>모비셀 회원가입 후 이용 부탁드립니다.</h4>

                    </div>

                    <form onSubmit={handleSubmit} style={{marginTop: "50px"}}>
                        <div className="form-group">
                            <Field
                                id="username"
                                label="username"
                                name="username"
                                component={renderTextField}
                                onChange={() => this.onChangeNullToken()}
                            />
   
                        </div>
                        <div className="form-group">
                            <Field
                                id="password"
                                label="password"
                                name="password"
                                component={renderTextField}
                                type="password"
                                onChange={() => this.onChangeNullToken()}
                            />
                          
                        </div>
                        <div className="form-group form-check">
                            <div className="custom-control custom-checkbox mr-sm-2">
                            <Field
                                type="checkbox" 
                                id="customControlAutosizing11" 
                                name="rememberMe"
                                component={renderCheckboxField}
                            />
                                <label className="custom-control-label" htmlFor="customControlAutosizing11">로그인 상태 유지</label>
                            </div>
                        </div>
                        <div className="button-login">
                            <button className="btn btn-login" type="submit">
                                로그인
                            </button>
                        </div>
                        <div className="link-register">
                            <ul className="list-item">
                                <li>
                                    <a href={`/${routerName.SIGN_UP}`}>회원가입</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href={`/${routerName.FORGOT_PASSWORD}`}>비밀번호찾기</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href={`/${routerName.FORGOT_ID}`}>아이디찾기</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </section>

        );
    }
}

SignInForm.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        loginAction: (data) => dispatch(loginAction(data)),
        change: () => dispatch(change()),
    }
};
const FORM_NAME = 'SignIn';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(SignInForm);
