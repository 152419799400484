
import PropTypes from 'prop-types';
import React, {Component, Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
    Field,
    reduxForm,
    FieldArray,
    getFormSyncErrors,
    getFormValues,
    getFormMeta,
    change,
    FormSection
} from 'redux-form';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import PromotionModal from "./PromotionModal"
import {getPromotion} from '../../../store/actions/discount_card';
import "./promotion.scss"
import {hideLoading, showLoading} from "../../../store/actions/ui";
import {priceFormat} from "../../../utils/helper";

class WirelessPromotionDurationTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalPromotion: false,
            disnable: false,
            dataPromotion: {},
            stateChangeProp: false,
            data:{}
        }
    }

    getPromotionDiscount = (price) => {
        let {WirelessAdvice} = this.props;
        this.props.change('WirelessAdviceStep3.promotionDurationDiscountTwo', price);
        this.setState({
            isOpenModalPromotion: false
        })
        //change props promotion discount
    }

    openPopupPromotion = () => this.setState({isOpenModalPromotion: !this.state.isOpenModalPromotion})

    // renderPromotionDurationName = (data) => {
    //     if(data && data.length > 0) {
    //         return <span>{data[1].promo_duration_name}</span> 
    //     }
    // }
    render() {
        let {WirelessAdvice} = this.props;
        let promotionDuration = WirelessAdvice.WirelessAdviceStep3.promotionDuration;
        let promotionDurationDiscount;
        let style;
        if (WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo && WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo != 0) {
            style = true;
            promotionDurationDiscount = priceFormat(WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo);
        }else{
            promotionDurationDiscount = '미적용'
            style = false;
        }
        return (
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text text-blue">{promotionDuration[1].promo_duration_name}</label>
                <div className="col-sm-8">
                    <button 
                        className={`btn btn-input-form mb-0 ${style ? 'btn-prices-wp' : ''}`}
                        // disabled={this.state.disnable}
                        onClick={this.openPopupPromotion}
                    >
                        {promotionDurationDiscount}
                        {promotionDurationDiscount != '미적용' ? <span>원</span> : ''}
                    </button>
                    
                    
                    <PromotionModal
                        isOpenModalPromotion={this.state.isOpenModalPromotion}
                        promotionDuration={promotionDuration[1]}
                        openPopupPromotion={this.openPopupPromotion}
                        getPromotionDiscount={this.getPromotionDiscount}
                        type="two"
                    />
                </div>
            </div>
        );
    }
}

WirelessPromotionDurationTwo.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        WirelessAdvice: getFormValues('WirelessAdvice')(state),
        WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),

    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPromotion: (data) => dispatch(getPromotion(data)),
        change: () => dispatch(change()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(WirelessPromotionDurationTwo);
