import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as routerName from "../../utils/routerName";
import { Link } from "react-router-dom";
import api from "./../../utils/api";
import {
    closePopupAdsDocument,
    closePopupAdsNotice,
    dataBannersSuccess,
} from '../../store/actions/dataStore';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Moment from 'react-moment';
import { Prompt } from 'react-router'
import PopupPageEmpty from "../PopupPageEmpty";
import * as helper from '../../utils/helper';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { allowAccessMenu, openPopupMenu } from './../../store/actions/auth';
import RBCarousel from "react-bootstrap-carousel";
// import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import _, { result } from "lodash";
import ReactDOM from "react-dom";
import { showModal, hideModal, changeModalTitle, changeModalContent } from '../../store/actions/modal';
import { ReactComponent as Home1 } from './../../assets/images/icpn-smart.svg';
import { ReactComponent as Home2 } from './../../assets/images/home-01.svg';
import { ReactComponent as Home3 } from './../../assets/images/home-02.svg';
import { ReactComponent as Home4 } from './../../assets/images/home-03.svg';
import { ReactComponent as Home5 } from './../../assets/images/home-04.svg';
import { ReactComponent as Home6 } from './../../assets/images/print-home.svg';
import { ReactComponent as Home7 } from './../../assets/images/icon-thongbao-02.svg';
import { ReactComponent as Home8 } from './../../assets/images/icon-cloud.svg';
import PopupInactive from '../../components/PopupInactive';

const cookies = new Cookies();

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeFiles: [],
            documentFiles: [],
            telcom: [],
            popupPageEmpty: false,
            popupPayment: true,
            banners: this.props?.banners || [],
            globalState: false,
            telcoms: [],
            isOpenPopupDisable: false,
            title: null,
            content: null,
            iconLock: null,
        }

    }

    componentDidMount = () => {
        document.body.classList.add("body_home_page");
        // cookies.remove('userFollowAdsNoticeTSC', { path: '/' });
        let telcom = [];
        let banners = [];
        let flag = false;
        api.global.getBanners().then(res => {
            banners = res.data.data;
            var dif = _.differenceWith(banners, this.props.banners, _.isEqual);
            if (dif) this.props.updateBanners(banners);
        }).catch(err => {

        });
        this.getListTelcom()
        api.advice.getTelcomNotParent().then(res => {
            Object.keys(res.data.data).map((items) => {
                telcom.push({ id: Object.keys(res.data.data[items])[0], name: res.data.data[items][Object.keys(res.data.data[items])[0]]})
            })
            this.setState({
                telcom: telcom,
            })
        }).catch(err => {

        });

    }

    onClickFollowAdsNotice = (id, state) => {
        var value1 = document.getElementById('stateSettingTypeN1' + id)?.checked || false;
        var value2 = document.getElementById('stateSettingTypeN2' + id)?.checked || false;
        let noticeFiles = this.state.noticeFiles;
        if (state === '0') {
            noticeFiles[id] = {
                state1: value1,
                stateCode:  value1 ? state : '-1'
            }

        } else {
            noticeFiles[id] = {
                state2: value2,
                stateCode:  value2 ? state : '-1'
            }
        }
        this.setState({
            noticeFiles: noticeFiles
        })
    }

    submitCloseNotice = (id, state) => {
        if (state == '1') {
            /*
            let follow = {
                date: []
            }
            if (cookies.get('userFollowAdsNoticeTSC') && cookies.get('userFollowAdsNoticeTSC').date) {
                follow.date = [...cookies.get('userFollowAdsNoticeTSC').date];
            }
            follow.date[id] = moment(new Date()).format('YYYY-MM-DD');
            cookies.set('userFollowAdsNoticeTSC', follow, { path: '/' });
            */
            localStorage.setItem('userFollowAdsNoticeTSC' + id, moment(new Date()).format('YYYY-MM-DD'));
        }
        let data = {
            nt_id: id,
            state: state
        }
        api.notification.followPopupAdsNotice(data).then(res => {
            this.props.closePopupAdsNotice(id)
        }).catch(err => {
        })
    }

    onClickFollowAdsDocument = (id, state) => {
        var value1 = document.getElementById('stateSettingTypeD1' + id)?.checked || false;
        var value2 = document.getElementById('stateSettingTypeD2' + id)?.checked || false;
        let documentFiles = this.state.documentFiles;
        if (state == '0') {
            documentFiles[id] = {
                state1: value1,
                stateCode:  value1 ? state : '-1'
            }
        } else {
            documentFiles[id] = {
                state2: value2,
                stateCode: value2 ? state : '-1'
            }
        }

        this.setState({
            documentFiles: documentFiles
        })
    }

    submitCloseDocument = (id, state) => {
        if (state == '1') {
            /*
            let follow = {
                date: []
            }
            if (cookies.get('userFollowAdsDocumentTSC') && cookies.get('userFollowAdsDocumentTSC').date) {
                follow.date = [...cookies.get('userFollowAdsDocumentTSC').date];
            }
            follow.date[id] = moment(new Date()).format('YYYY-MM-DD');
            cookies.set('userFollowAdsDocumentTSC', follow, { path: '/' });
            */
            localStorage.setItem('userFollowAdsDocumentTSC' + id, moment(new Date()).format('YYYY-MM-DD'));
        }
        let data = {
            d_id: id,
            state: state
        }
        api.notification.followPopupAdsDocument(data).then(res => {
            this.props.closePopupAdsDocument(id)
        }).catch(err => {
        })
    }


    onClikClosePopupAdsNotice = (item) => {
        let follow = {
            date: []
        }
        if (item.follow_ads !== null) {
            /*
            if (cookies.get('userFollowAdsNoticeTSC') && cookies.get('userFollowAdsNoticeTSC').date) {
                follow.date = [...cookies.get('userFollowAdsNoticeTSC').date];
            }
            follow.date[item.nt_id] = moment(new Date()).format('YYYY-MM-DD');
            cookies.set('userFollowAdsNoticeTSC', follow, { path: '/' });
            */
            localStorage.setItem('userFollowAdsNoticeTSC' + item.nt_id, moment(new Date()).format('YYYY-MM-DD'));
        }
        if (this.state.noticeFiles[item.nt_id] && this.state.noticeFiles[item.nt_id].stateCode) {
            if (this.state.noticeFiles[item.nt_id].state1 == false && (this.state.noticeFiles[item.nt_id].state2 == undefined || this.state.noticeFiles[item.nt_id].state2 == false)) {
                this.submitCloseNotice(item.nt_id, '-1')
                // xoa cokkie
                /*
                follow.date = [...cookies.get('userFollowAdsNoticeTSC').date];
                follow.date.splice(item.nt_id, 1);
                cookies.set('userFollowAdsNoticeTSC', follow, { path: '/' });
                */
                follow.date = [...cookies.get('userFollowAdsNoticeTSC').date];

                let itemdate = follow.date.splice(item.nt_id, 1);

                if(itemdate){
                    localStorage.setItem('userFollowAdsNoticeTSC' + item.nt_id, itemdate[0]);
                }
                
            } else {
                this.submitCloseNotice(item.nt_id, this.state.noticeFiles[item.nt_id].stateCode)
            }
        } else {
            this.props.closePopupAdsNotice(item.nt_id)
        }
    }

    onClickClosePopupAdsDocument = (item) => {
        let follow = {
            date: []
        }
        if (item.follow_ads !== null) {
/*
            if (cookies.get('userFollowAdsDocumentTSC') && cookies.get('userFollowAdsDocumentTSC').date) {
                follow.date = [...cookies.get('userFollowAdsDocumentTSC').date];
            }
            follow.date[item.d_id] = moment(new Date()).format('YYYY-MM-DD');
            cookies.set('userFollowAdsDocumentTSC', follow, { path: '/' });
*/
            localStorage.setItem('userFollowAdsDocumentTSC' + item.d_id, moment(new Date()).format('YYYY-MM-DD'));
        }

        if (this.state.documentFiles[item.d_id] && this.state.documentFiles[item.d_id].stateCode) {
            if (this.state.documentFiles[item.d_id].state1 == false && (this.state.documentFiles[item.d_id].state2 == undefined || this.state.documentFiles[item.d_id].state2 == false)) {
                this.submitCloseDocument(item.d_id, '-1');
                // xoa cokkie
                /*
                follow.date = [...cookies.get('userFollowAdsDocumentTSC').date];
                follow.date.splice(item.d_id, 1);
                cookies.set('userFollowAdsDocumentTSC', follow, { path: '/' });
                */
                follow.date = [...cookies.get('userFollowAdsDocumentTSC').date];

                let itemdate = follow.date.splice(item.d_id, 1);

                if(itemdate){
                    localStorage.setItem('userFollowAdsDocumentTSC' + item.d_id, itemdate[0]);
                }
            } else {
                this.submitCloseDocument(item.d_id, this.state.documentFiles[item.d_id].stateCode)
            }
        } else {
            this.props.closePopupAdsDocument(item.d_id)
        }

    }
    checkNavigateMenu = () => {
        // disable function
        // alert('disable');

        // let {
        //     user,
        //     isAllowAccessMenu,
        // } = this.props.auth;

        // this.props.openPopupMenu(true);

    }
    openPopupDisable = (e, state, value) => {
        // console.log(state,value, 'openPopupDisable 정산관리');
        e.preventDefault();
        if (state) {
            this.setState({
                // title: '서비스 준비중입니다.',
                title: '실제 개통에 사용하지 마십시오.',
                content: `
                    <!--빠른 시일내에 준비하여 찾아뵙겠습니다.-->
                    베타테스트 기간입니다.<br>
                    테스트 기간에는 실제 개통에 사용 자제해주세요.<br>
                    서류 미비나 금액 오류 등이 발생할 수 있습니다.
                `
            })
        }
        this.setState({
            isOpenPopupDisable: true,
            iconLock: value
        })
    }
    closePopupDisableHandle = () => {
        this.setState({
            isOpenPopupDisable: false
        })
    }

    modelPrintClose = () => {
        this.setState({
            title: null,
            content: null
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.banners && this.props.banners.length > 0 && prevProps.banners !== this.props.banners) {
            this.setState({
                banners: this.props.banners
            })
        }        
        if ((this.props.popup_ads_notices.length > 0 || this.props.popup_ads_documents.length > 0) && this.state.globalState == false) {
            //console.log("this.props.popup_ads_notices");
            //console.log(this.props.popup_ads_notices);
            //console.log("cookies.get('userFollowAdsNoticeTSC')");
            //console.log(cookies.get('userFollowAdsNoticeTSC'));

            let noticeFiles = this.state.noticeFiles;
            let documentFiles = this.state.documentFiles;
            this.props.popup_ads_notices.map((item, i) => {
                noticeFiles[item.nt_id] = {
                    state1: (item.follow_ads && item.follow_ads.state == 1) ? true : false,
                }
            })

            this.props.popup_ads_documents.map((item, i) => {
                documentFiles[item.d_id] = {
                    state1: (item.follow_ads && item.follow_ads.state == 1) ? true : false,
                }
            })

            this.setState({
                noticeFiles: noticeFiles,
                documentFiles: documentFiles,
                globalState: true
            })

        }
        if (cookies.get('loginToDayTSC') && cookies.get('loginToDayTSC').newLogin && this.props.auth.user.type_forgot_pw !== null) {
            this.popupShowMessageChangePassword()
        }
    }


    shouldBlockNavigation = () => {
        let loginToday = {
            ...cookies.get('loginToDayTSC'),
            newLogin: false
        }
        cookies.set('loginToDayTSC', loginToday, { path: '/' });
    }

    onClickModalPrint = () => {
        // debugger
        this.setState({
            popupPageEmpty: !this.state.popupPageEmpty
        })
    }

    componentWillUnmount() {
        this.shouldBlockNavigation()
        document.body.classList.remove("body_home_page");
    }

    renderBanners = () => {
        if (this.state.banners.length > 0) {
            return (
                <section className="banner-wrapper">
                    <div className="home-slider slider-banner">
                        <RBCarousel
                            autoplay={this.state.autoplay}
                            pauseOnVisibility={true}
                            onSelect={this._visiableOnSelect}
                            leftIcon={this.state.leftIcon}
                            rightIcon={this.state.rightIcon}
                            slideshowSpeed={5000}
                            version={4}
                            className="slide"
                        >
                            {this.state.banners.map((banner, key) => {
                                return (
                                    <div key={key}
                                        className={`${banner.link ? "cursor-pointer" : ""}`}
                                    >
                                        <img src={banner?.file_url?.original} data-link={banner?.link}
                                            className="link-banner d-block w-100" alt="..." />
                                    </div>
                                )
                            })}
                        </RBCarousel>
                    </div>
                </section>
            )
        }
    }


    popupShowMessageChangePassword = () => {
        let xhtml = (
            <Fragment>
                <div className="text-center pb-5">
                    <span className="notification_delete">비밀번호를 다시 설정해주세요!</span>
                </div>
                <div className="modal-footer mt-4 justify-content-center border-top-0 pb-3">
                    <button type="button" className="btn btn-submit" onClick={() => {
                        this.props.hideModal(); this.props.history.push({
                            pathname: routerName.EDIT_PROFILE,
                        })
                    }}>비밀번호 변경</button>
                </div>
            </Fragment>
        );
        let dataModal = {
            component: xhtml,
            class_n: "modal-dialog-centered modal-pass-notifi modal-pass-notifi-01"
        }
        this.props.showModal(dataModal);
    }

    popupShowDocumentCheck = (d_id) => {
        if(
            cookies.get('userFollowAdsDocumentTSC') &&
            cookies.get('userFollowAdsDocumentTSC').date[d_id] !== moment(new Date()).format('YYYY-MM-DD')            
        ){
            return true;
        }

        if(
            localStorage.getItem('userFollowAdsDocumentTSC' + d_id) &&
            localStorage.getItem('userFollowAdsDocumentTSC' + d_id) !== moment(new Date()).format('YYYY-MM-DD')
        ){
            return true;
        }

        return false;
    }

    popupShowDocument = () => {
        /* Task 7315 yeu c?u hi?n popup cho tai kho?n admin */
        // if (this.props.auth.role !== 'Admin' && this.props.auth.role != 'Counselor') {
            return (
                <Fragment>
                    { this.props.popup_ads_documents.map((item, i) =>
                        <Fragment key={i}>
                            {item.d_state_setting == '1'
                                // && (cookies.get('loginToDayTSC') && cookies.get('loginToDayTSC').newLogin)
                                && (item.follow_ads && item.follow_ads.state == '1' || item.follow_ads == null)
                                //&& (cookies.get('userFollowAdsDocumentTSC') && cookies.get('userFollowAdsDocumentTSC').date[item.d_id] !== moment(new Date()).format('YYYY-MM-DD') || cookies.get('userFollowAdsDocumentTSC') == undefined)
                                && (
                                    (
                                        cookies.get('userFollowAdsDocumentTSC') &&
                                        cookies.get('userFollowAdsDocumentTSC').date[item.d_id] !== moment(new Date()).format('YYYY-MM-DD')
                                    ) ||
                                    (localStorage.getItem('userFollowAdsDocumentTSC' + item.d_id) ?? '1900-01-01') !== moment(new Date()).format('YYYY-MM-DD')
                                )
                                ? (
                                    <div className={"modal fade show " + (item.is_close ? "d-none" : "d-block")} >
                                        <div className="modal-dialog modal-dialog-centered modal-notifi-home" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header border-bottom-0">
                                                    <button type="button" className="close" onClick={() => this.onClickClosePopupAdsDocument(item)}>
                                                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="x" />
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    <form className="modal-notification modal-dialog-scrollable">
                                                        <div className="img-form">
                                                            <div className="modal-content content px-0 popup-home">
                                                                <div className="modal-body discount-card pb-0 pl-0 pr-0">
                                                                    <div className="container_nofitication">
                                                                        <div className="box_title">
                                                                            <h2><a
                                                                                onClick={() => this.onClickRedrictDetail({ document: item }, routerName.SALES_DOCUMENT_DETAIL)}
                                                                            >{item && item.d_title}</a></h2>
                                                                            <div className="d-flex flex-row justify-content-between sub d-flex">
                                                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                                                    <div className="sub mr-4">
                                                                                        <i className="far fa-clock"></i>
                                                                                        <span>
                                                                                            <Moment format="YYYY-MM-DD">
                                                                                                {item && item.created_at}
                                                                                            </Moment>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="sub mr-4">
                                                                                        <i className="far fa-eye"></i> <span>{item && item.d_viewer ? item.d_viewer : 0}</span>
                                                                                    </div>
                                                                                    <div className="sub" style={{ cursor: 'pointer' }}>
                                                                                        <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{item && item.count_like ? item.count_like : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                {item && !!item.att_file ? (<div className="sub d-flex">
                                                                                    <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({item && (item.att_size / (1024)).toFixed(2)} KB)</span></p>
                                                                                    <a ><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"} alt="" /></a>
                                                                                </div>) : ('')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="box_content ck-content" dangerouslySetInnerHTML={{ __html: item && item.d_content }} >

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="group-check" style={{ minHeight: '30px' }}>
                                                            {item.d_setting_type_1 == '1' ? (
                                                                <div className="form-group form-check" onClick={() => this.onClickFollowAdsDocument(item.d_id, '0')}>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id={"stateSettingTypeD1" + item.d_id} checked={this.state.documentFiles[item.d_id] && this.state.documentFiles[item.d_id].state1 == true ? true : false} readOnly />
                                                                        <label className="custom-control-label" htmlFor={"stateSettingTypeD1" + item.d_id}>다시보지않기</label>
                                                                    </div>
                                                                </div>
                                                            ) : ('')}
                                                            {item.d_setting_type_2 == '1' ? (
                                                                <div className="form-group form-check ml-5" onClick={() => this.onClickFollowAdsDocument(item.d_id, '1')} >
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id={"stateSettingTypeD2" + item.d_id} checked={this.state.documentFiles[item.d_id] && this.state.documentFiles[item.d_id].state2 == true ? true : false} readOnly />
                                                                        <label className="custom-control-label" htmlFor={"stateSettingTypeD2" + item.d_id}>하루동안 보지않기</label>
                                                                    </div>
                                                                </div>
                                                            ) : ('')}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ('')}
                        </Fragment>)}
                </Fragment>)
        // }
    }

    popupShowNotice = (nt_id) => {
        if(
            cookies.get('userFollowAdsNoticeTSC') &&
            cookies.get('userFollowAdsNoticeTSC').date[nt_id] !== moment(new Date()).format('YYYY-MM-DD')
        ){
            return true;
        }

        if(
            localStorage.getItem('userFollowAdsNoticeTSC' + nt_id) &&
            localStorage.getItem('userFollowAdsNoticeTSC' + nt_id) !== moment(new Date()).format('YYYY-MM-DD')
        ){
            return false;
        }

        return false;
    }

    popupShowNotice = () => {
        /* Task 7315 yeu c?u hi?n popup cho tai kho?n admin */
        // if (this.props.auth.role !== 'Admin' && this.props.auth.role != 'Counselor') {
            return (
                <Fragment>
                    {this.props.popup_ads_notices.map((item, i) =>
                        <Fragment key={i}>
                            {item.nt_state_setting == '1'
                                // && (cookies.get('loginToDayTSC') && cookies.get('loginToDayTSC').newLogin)
                                && (item.follow_ads && item.follow_ads.state == '1' || item.follow_ads == null)
                                //&& (cookies.get('userFollowAdsNoticeTSC') && cookies.get('userFollowAdsNoticeTSC').date[item.nt_id] !== moment(new Date()).format('YYYY-MM-DD') || cookies.get('userFollowAdsNoticeTSC') == undefined)
                                && (
                                    (
                                        cookies.get('userFollowAdsNoticeTSC') &&
                                        cookies.get('userFollowAdsNoticeTSC').date[item.nt_id] !== moment(new Date()).format('YYYY-MM-DD')
                                    ) ||
                                    (localStorage.getItem('userFollowAdsNoticeTSC' + item.nt_id) ?? '1900-01-01') !== moment(new Date()).format('YYYY-MM-DD')
                                )
                                ? (
                                    <div className={"modal fade show " + (item.is_close ? "d-none" : "d-block")} >
                                        <div className="modal-dialog modal-dialog-centered modal-notifi-home modal-notifi-home__money-support" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header border-bottom-0">
                                                    <button type="button" className="close" onClick={() => this.onClikClosePopupAdsNotice(item)}>
                                                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="x" />
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    <form className="modal-notification modal-dialog-scrollable">
                                                        <div className="img-form">
                                                            <div className="modal-content content px-0 popup-home" >
                                                                <div className="modal-body discount-card pb-0 pl-0 pr-0">
                                                                    <div className="container_nofitication">
                                                                        <div className="box_title pt-0">
                                                                            <h2><a onClick={() => this.onClickRedrictDetail({ notice: item }, routerName.NOTIFICATION_DETAIL)}>{item && item.nt_title}</a></h2>
                                                                            <div className="d-flex flex-row justify-content-between sub d-flex">
                                                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                                                    <div className="sub mr-4">
                                                                                        <i className="far fa-clock"></i>
                                                                                        <span>
                                                                                            <Moment format="YYYY-MM-DD">
                                                                                                {item && item.created_at}
                                                                                            </Moment>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="sub mr-4">
                                                                                        <i className="far fa-eye"></i> <span>{item && item.nt_viewer ? item.nt_viewer : 0}</span>
                                                                                    </div>
                                                                                    <div className="sub" style={{ cursor: 'pointer' }}>
                                                                                        <i className={"far fa-thumbs-up " + (!this.state.isLike ? 'text-secondary' : '')}></i> <span>{item && item.count_like ? item.count_like : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                {item && !!item.att_file ? (<div className="sub d-flex">
                                                                                    <p className="mb-0 pt-1"><span className="text-gray"> 첨부파일 1개</span><span>({item && (item.att_size / (1024)).toFixed(2)} KB)</span></p>
                                                                                    <a ><img src={process.env.REACT_APP_HOSTNAME + "/images/dowload.svg"} alt="" /></a>
                                                                                </div>) : ('')}
                                                                            </div>

                                                                        </div>
                                                                        {item.cat_id !== null ? (
                                                                            <div className="box_content ck-content" dangerouslySetInnerHTML={{ __html: item && item.nt_content }} ></div>)
                                                                            : this.renderBoxContentMonneySuport(item)}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="group-check" style={{ minHeight: '30px' }}>
                                                            {item.nt_setting_type_1 == '1' ? (
                                                                <div className="form-group form-check" >
                                                                    <div className="custom-control custom-checkbox" >
                                                                        <input type="checkbox" className="custom-control-input" id={"stateSettingTypeN1" + item.nt_id} checked={(this.state.noticeFiles[item.nt_id] && this.state.noticeFiles[item.nt_id].state1) == true ? true : false}
                                                                            ref={"stateSettingTypeN1" + item.nt_id}
                                                                            readOnly
                                                                            onClick={(e) => this.onClickFollowAdsNotice(item.nt_id, '0')}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor={"stateSettingTypeN1" + item.nt_id}>다시보지않기</label>
                                                                    </div>
                                                                </div>
                                                            ) : ('')}
                                                            {item.nt_setting_type_2 == '1' ? (
                                                                <div className="form-group form-check ml-5">
                                                                    <div className="custom-control custom-checkbox"  >
                                                                        <input type="checkbox" className="custom-control-input" id={"stateSettingTypeN2" + item.nt_id} checked={(this.state.noticeFiles[item.nt_id] && this.state.noticeFiles[item.nt_id].state2 == true) ? true : false}
                                                                            ref={"stateSettingTypeN2" + item.nt_id}
                                                                            readOnly
                                                                            onClick={(e) => this.onClickFollowAdsNotice(item.nt_id, '1')}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor={"stateSettingTypeN2" + item.nt_id}>하루동안 보지않기</label>
                                                                    </div>
                                                                </div>
                                                            ) : ('')}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ('')}
                        </Fragment>
                    )}
                </Fragment>
            )
        // }
    }

    getListTelcom = () => {
        this.props.showLoading()
        api.global.getTelcom().then(res => {
            this.setState({
                telcoms: res.data.data
            })
            this.props.hideLoading();
        }).catch(err => {
            this.props.hideLoading();
        })
    }



    setDataList = (data) => {
        let announcements = JSON.parse(data)
        let dataList = []
        Object.keys(announcements).map((key, i) => {
            let index = this.state.telcoms.findIndex((item) => item.id == key)
            if (index !== -1) {
                let rowData = {
                    telcom: this.state.telcoms[index],
                    dataRow: announcements[key]
                }
                if (announcements[key].length > 0) {
                    dataList.push(rowData)
                }
            }

        })
        return dataList
    }

    renderBoxContentMonneySuport = (data) => {
        let dataList = this.setDataList(data.nt_content)
        console.log(dataList)
        return (<table className="table mb-0">
            <thead>
                <tr>
                    <th>통신사</th>
                    <th>모델명</th>
                    <th>출고가</th>
                    <th>표준 요금제</th>
                    <th>공시지원금</th>
                </tr>
            </thead>
            <tbody>
                {dataList.length > 0 && dataList.map((item, i) =>
                    <Fragment key={i}>
                        {item.dataRow.map((it, k) =>
                            <tr key={k}>
                                {k == 0 && (
                                    <td rowSpan={item.dataRow.length} style={{verticalAlign: 'middle'}}>
                                        <div className="logo-network">
                                            <img src={item?.telcom?.image_url?.thumbnail} alt="" />
                                        </div>
                                    </td>
                                )}
                                <td className="td-add-product" style={{verticalAlign: 'middle'}}>
                                    {it.model_name} {it.device_name ? `(${it.device_name})` : null}
                                </td>
                                {this.renderPriceChangeModel(it) == this.renderPriceStatusModel(it) || this.renderPriceStatusModel(it) == 0 ? (
                                    <td>
                                        <div className="prices-devices" data-toggle="modal" data-target="#modal-26" style={{justifyContent: 'center'}}>
                                            <div className="status-prices" style={{margin:0, justifyContent: 'flex-end', fontWeight: 400, fontSize:15}}>
                                                {helper.priceFormat(this.renderPriceChangeModel(it)) + " won"}
                                            </div>
                                        </div>
                                    </td>
                                ) : (
                                    <td className={this.renderPriceChangeModel(it) > this.renderPriceStatusModel(it) ? "status-up" : "status-down"}>
                                        <div className="prices-devices" data-toggle="modal" data-target="#modal-26">
                                            <span style={{fontSize:15}}>{helper.priceFormat(this.renderPriceStatusModel(it)) + " won"}</span>
                                            <div className="status-prices" style={{fontWeight: 400,fontSize:15}}>
                                                <div className="status">
                                                    {this.renderPriceChangeModel(it) > this.renderPriceStatusModel(it) ? <img src="/images/status-up.svg" alt="" /> : <img src="/images/status-down.svg" alt="" />}
                                                </div>
                                                {helper.priceFormat(this.renderPriceChangeModel(it)) + " won"}
                                            </div>
                                        </div>
                                    </td>
                                )}
                                <td className="td-add-product" style={{verticalAlign: 'middle'}}>
                                    {it.plan_name}
                                </td>

                                {this.renderPriceChangePlan(it) == this.renderPriceStatusPlan(it) || this.renderPriceStatusPlan(it) == 0 ? (
                                    <td>
                                        <div className="prices-devices" data-toggle="modal" data-target="#modal-26" style={{justifyContent: 'center'}}>
                                            <div className="status-prices" style={{margin:0, justifyContent: 'flex-end', fontWeight: 400, fontSize:15}}>
                                                {helper.priceFormat(this.renderPriceChangePlan(it)) + " won"}
                                            </div>
                                        </div>
                                    </td>
                                ) : (
                                    <td className={this.renderPriceChangePlan(it) > this.renderPriceStatusPlan(it) ? "status-up" : "status-down"}>
                                        <div className="prices-devices" data-toggle="modal" data-target="#modal-26" style={{}}>
                                            <span style={{fontSize:15}}>{helper.priceFormat(this.renderPriceStatusPlan(it)) + " won"}</span>
                                            <div className="status-prices" style={{fontWeight: 400,fontSize:15}}>
                                                <div className="status">
                                                    {this.renderPriceChangePlan(it) > this.renderPriceStatusPlan(it) ? <img src="/images/status-up.svg" alt="" /> : <img src="/images/status-down.svg" alt="" />}
                                                </div>
                                                {helper.priceFormat(this.renderPriceChangePlan(it)) + " won"}
                                            </div>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        )}
                    </Fragment>
                )}
                {dataList.length == 0 && (
                    <tr>
                        <td colSpan="5">금일 변동사항 없습니다.</td>
                    </tr>
                )}

            </tbody>
        </table>)
    }

    renderPriceStatusModel = (item) => {
        let priceModel = item.model_factory_price ? item.model_factory_price : item.factory_price || 0
        return priceModel;
    }

    renderPriceChangeModel = (item) => {
        let priceModelChange = item.crw_factory_price ? item.crw_factory_price : item.factory_price_change || 0

        return priceModelChange;
    }

    renderPriceStatusPlan = (item) => {
        let pricePlan = item.model_subsidy_price ? item.model_subsidy_price : item.subsidy_price || 0
        return pricePlan;
    }

    renderPriceChangePlan = (item) => {
        let pricePlanChange = item.crw_subsidy_price ? item.crw_subsidy_price : item.subsidy_price_change || 0

        return pricePlanChange;
    }

    popupShowPayment = () => {
        return (<></>) // xoa khi hoan thanh ch?c n?ng thanh toan
        if ((cookies.get('loginToDayTSC') && cookies.get('loginToDayTSC').newLogin)
            && this.props.auth.storePlan
            && moment(this.props.auth.storePlan.expired_date).format('YYYY-MM-DD') == moment(this.props.auth.storePlanLast.expired_date).format('YYYY-MM-DD')
            && this.props.auth.storePlan.isFree == '0'
            && moment(this.props.auth.storePlan.expired_date).diff(moment(), 'days') + 1 <= 7) {
            return (<div className={"modal fade show " + (this.state.popupPayment ? "d-block" : "d-none")} >
                <div className="modal-dialog modal-dialog-centered modal_custom_notifi" role="document">

                    <div className="modal-content" style={{ "height": "349px" }}>
                        <div className="modal-header border-bottom-0">
                            <button type="button" className="close" onClick={() => this.setState({ popupPayment: false })}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="content-register-modal md-signup">
                            <p className="text-center mb-2">
                                안녕하세요.
                                    </p>
                            <p className="text-center mb-1">
                                <span className="text-primary">{this.props.auth.storePlan.price_package.pp_name}</span> 요금제 무료사용기간이  <span className="text-danger">{moment(this.props.auth.storePlan.expired_date).diff(moment(), 'days') + 1}</span>일 남았습니다.
                                    </p>
                            <p className="text-center mb-2">
                                모비셀 사용을 계획하실 경우 서비스요금제 결제 진행 부탁드립니다.
                                    </p>
                            <p className="text-center mb-2">
                                결제가 이루어지지 않을 경우 서비스 이용이 중단됩니다.
                                    </p>
                            <p className="text-center mb-2">
                                지속적인 사용을 원하실 경우 결제 진행 부탁드립니다. “
                                    </p>
                            <Link to={{
                                pathname: routerName.PAYMENT_MANAGEMENT,
                            }}><button type="button" className="btn btn-secondary btn-ok mt-5">확인</button></Link>

                        </div>
                    </div>
                </div>
            </div>)
        } else if ((cookies.get('loginToDayTSC') && cookies.get('loginToDayTSC').newLogin) &&
            this.props.auth.storePlan && moment(this.props.auth.storePlan.expired_date).format('YYYY-MM-DD') == moment(this.props.auth.storePlanLast.expired_date).format('YYYY-MM-DD') &&
            this.props.auth.storePlan.isFree == '1' &&
            moment(this.props.auth.storePlan.expired_date).diff(moment(), 'days') + 1 <= 7) {
            return (
                <div className={"modal fade show " + (this.state.popupPayment ? "d-block" : "d-none")} >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title text-center w-100">무료 사용 기간 만료 안내 </h5>
                                <button type="button" className="close" onClick={() => this.setState({ popupPayment: false })}>
                                    <span >×</span>
                                </button>
                            </div>
                            <div className="content-register-modal md-signup mt-4">
                                <p className="text-center">모비셀 무료 체험이 <span className="text-danger">{moment(this.props.auth.storePlan.expired_date).diff(moment(), 'days') + 1}</span>일 후 종료될 예정입니다.</p>
                                <p className="text-center">서비스가 만족스러우셨다면</p>
                                <p className="text-center">월정액상품 결제를 부탁드립니다.</p>
                                <p className="text-center">결제가 진행되지 않은 경우 무료체험 종료 후</p>
                                <p className="text-center">서비스 제공이 중단되오니 참고 부탁드립니다.</p>
                                <Link to={{
                                    pathname: routerName.PAYMENT_MANAGEMENT,
                                }}><button type="button" className="btn btn-secondary mt-5 mb-5 btn-ok">월정액상품 결제하기</button></Link>

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }

    }

    onClickRedrictDetail = (item, url) => {
        let data = {
            ...item,
            callback: {
                routerName: routerName.HOME,
                ...this.state
            }
        }
        this.props.history.push({
            pathname: url,
            key: Math.random(),
            state: data
        })
    }

    render() {
        return (
            <Fragment>
                <Prompt
                    message={(location, action) => {
                        if (location.pathname !== '/' + routerName.HOME) {
                            this.shouldBlockNavigation()
                        }
                        return true
                    }}
                />
                <div className="main-wrapper">
                    {this.renderBanners()}
                    <section className="content-home">
                        <ul className="list-home">
                            <li>
                                {helper.checkBlockFeature() ? (
                                    <a onClick={(e) => this.openPopupDisable(e, false, 'iconLock')}>
                                        <Home1 />
                                        <h3>SMART 상담</h3>
                                    </a>
                                ) : (
                                    <Link to={`/${routerName.SMART_ADVICE}`}>
                                        <Home1 />
                                        <h3>SMART 상담</h3>
                                    </Link>
                                )}
                            </li>
                            <li>
                                <Link to={`/${routerName.WIRELESS_ADVICE}`}>
                                    <Home2 />
                                    <h3>무선상담</h3>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${routerName.WIRED_ADVICE}`}>
                                    <Home3 />
                                    <h3>유선상담</h3>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${routerName.COMPARE}`}>
                                    <Home4 />
                                    <h3>비교상담</h3>
                                </Link>
                            </li>
                            <li className={helper.permissionsMenu(this.props.auth.role, ['Admin', 'Member'])}>
                                {helper.checkBlockFeature() ? (
                                    <a onClick={(e) => this.openPopupDisable(e,'','iconLock')}>
                                        <Home5 />
                                        <h3>정산관리</h3>
                                    </a>
                                ) : (
                                    <Link to={`/${routerName.HISTORY_BUY_PRODUCT}`} onClick={() => this.checkNavigateMenu()}>
                                        <Home5 />
                                        <h3>정산관리</h3>
                                    </Link>
                                )}
                            </li>
                            <li>
                                {/*<a onClick={(e) => this.openPopupDisable(e, true)}>*/}
                                <a onClick={this.onClickModalPrint}>
                                    <Home6 />
                                    <h3>빈서식지 출력</h3>
                                </a>
                            </li>
                        </ul>
                        <div className="resources">
                            <div className="item">
                                <div className="top">
                                    <h4>
                                        <Home7 className="mr-1" />
                                공지사항
                                </h4>
                                    <Link to={{
                                        pathname: routerName.MANAGER_NOTIFICATION,
                                    }}>
                                        더보기
                                <i className="fas fa-caret-right"></i>
                                    </Link>
                                </div>
                                <article>
                                    <ul className="list-item">
                                        {this.props.notices.data.map((item, i) =>
                                            <Fragment key={i}>
                                                {i <= 4 ?
                                                    (<li >
                                                        <a
                                                        onClick={() => this.onClickRedrictDetail({ notice: item }, routerName.NOTIFICATION_DETAIL)}
                                                        >{item.nt_title}</a>
                                                        {item.nt_state == 0 ? (<span> (초안) </span>) : ('')}
                                                    </li>)
                                                    : (<></>)}
                                            </Fragment>

                                        )}

                                    </ul>
                                </article>
                            </div>
                            <div className="item">
                                <div className="top">
                                    <h4>
                                        <Home8 className="mr-1" />
                                판매 도우미 자료실
                                </h4>
                                    {helper.checkBlockFeature() ? (
                                        <a onClick={(e) => this.openPopupDisable(e, false, 'iconLock')}>
                                            더보기
                                            <i className="fas fa-caret-right"></i>
                                        </a>
                                    ) : (
                                        <Link to={{
                                            pathname: routerName.SALES_DOCUMENT,
                                        }}>
                                            더보기
                                        <i className="fas fa-caret-right"></i>
                                        </Link>
                                    )}
                                </div>
                                <article>
                                    <ul className="list-item">
                                        {this.props.documents.data.map((item, i) =>
                                            <Fragment key={i}>
                                                {i <= 4 ?
                                                    (<li >
                                                        <a
                                                            onClick={(e) => this.openPopupDisable(e, false, 'iconLock')}
                                                            // onClick={() => this.onClickRedrictDetail({ document: item }, routerName.SALES_DOCUMENT_DETAIL)}
                                                        >{item.d_title}
                                                        </a>
                                                        {item.d_state == 0 ? (<span> (초안) </span>) : ('')}
                                                    </li>)
                                                    : (<></>)}
                                            </Fragment>
                                        )}
                                    </ul>
                                </article>
                            </div>
                        </div>
                    </section>
                </div>
                { this.popupShowNotice()}
                { this.popupShowDocument()}
                { this.popupShowPayment()}



                {this.state.telcom.length > 0 && <PopupPageEmpty
                    toggle={this.onClickModalPrint}
                    isOpen={this.state.popupPageEmpty}
                    telcom={this.state.telcom}
                />}

                <PopupInactive
                    isOpenModal={this.state.isOpenPopupDisable}
                    closePopupDisable={this.closePopupDisableHandle}
                    title={this.state.title || null}
                    content={this.state.content || null}
                    callback={this.state.title ? this.onClickModalPrint : null}
                    onModelClosed={this.modelPrintClose}
                    iconLock={this.state.iconLock}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        popup_ads_documents: state.dataStore.notification.popup_ads_documents,
        popup_ads_notices: state.dataStore.notification.popup_ads_notices,
        documents: state.dataStore.notification.documents,
        notices: state.dataStore.notification.notices,
        banners: state.dataStore.banners
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closePopupAdsNotice: (data) => dispatch(closePopupAdsNotice(data)),
        closePopupAdsDocument: (data) => dispatch(closePopupAdsDocument(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        openPopupMenu: (status) => dispatch(openPopupMenu(status)),
        updateBanners: (banners) => dispatch(dataBannersSuccess(banners)),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
