import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';
import { compose } from 'redux';
import CurrencyFormat from 'react-currency-format';
import { roundUpNumber } from './../../../utils/helper';
import { OPTION_FEE, KT_S23_SBS_PRM_Y_EDT } from './../../../utils/constant'

class CashPaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            part: 0,
            value: 0,
            active: 0
        };
        this.refClose = React.createRef();
        this.refCloseInput = React.createRef();
    }

    componentDidMount() {
        if (this.props.WirelessAdvice?.WirelessAdviceStep3?.cashPayment) {
            this.setState({
                part: this.props.WirelessAdvice.WirelessAdviceStep3.cashPayment,
                active: this.props.WirelessAdvice.WirelessAdviceStep3.cashPaymentId,
            })
        }
    }

    componentDidUpdate(prevProps) {
        let prevStep3 = prevProps.WirelessAdvice?.WirelessAdviceStep3
        let step3 = this.props.WirelessAdvice?.WirelessAdviceStep3
        // nếu trả hết bằng tiền mặt
        if (
            prevStep3?.optionFee !== step3?.optionFee
            && step3?.cashPaymentId == 1
        ) {
            let newCashPayment = +step3?.cashPayment
            if (step3?.optionFee == 1) {
                newCashPayment -= +step3?.disclosureSubsidy
            } else {
                newCashPayment += +step3?.disclosureSubsidy
            }
            this.changeCashPayment(newCashPayment)
        }
        // nếu trả 1 phần tiền mặt
        if (
            step3?.cashPaymentId == 2
            && (
                prevStep3?.optionFee !== step3?.optionFee
                || prevStep3?.disclosureSubsidy !== step3?.disclosureSubsidy
                || prevStep3?.pointDiscount !== step3?.pointDiscount
                || prevStep3?.advancedDiscountCard !== step3?.advancedDiscountCard
                || prevStep3?.other !== step3?.other
            )
        ) {
            let newCashPayment = +step3?.cashPayment
            let installmentPrincipal = +step3.startingPrice - (+step3.disclosureSubsidy + +step3.additionalGrants + +step3.pointDiscount + +step3.advancedDiscountCard + newCashPayment + +step3.other)
            if (step3.optionFee === OPTION_FEE.optionalAgreement) {
                installmentPrincipal += +step3.disclosureSubsidy + +step3.additionalGrants;
            }
            if (installmentPrincipal < 0) {
                newCashPayment += installmentPrincipal
            }
            this.changeCashPayment(newCashPayment)
        }
    }

    changeCashPayment = (cashPayment, cashPaymentId = null) => {
        if (cashPaymentId !== null) {
            this.props.change('WirelessAdviceStep3.cashPaymentId', cashPaymentId);
        }

        cashPayment = cashPaymentId === 0 ? null : (cashPayment > 0 ? cashPayment : 0);

        this.props.change('WirelessAdviceStep3.cashPayment', cashPayment);
    }

    handleChange = (val) => {
        this.setState({active: 0});
        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        this.changeCashPayment(val, 0)
    }

    fullPayment = () => {
        const {WirelessAdvice} = this.props;
        this.setState({active: 1});
        let cashPayment=WirelessAdvice.WirelessAdviceStep3.cashPayment
        let  installmentPrincipal = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal
        
        cashPayment = Number(cashPayment)+Number(installmentPrincipal)

        if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
              let instPrincipalUsedPhone2 = Number(WirelessAdvice.WirelessAdviceStep3.startingPrice) * Number(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate) / 100; // 2구간 할부원금
      
              cashPayment = Math.round(Number(WirelessAdvice.WirelessAdviceStep3.installmentPrincipal) - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1);
            }
        }

        this.changeCashPayment(cashPayment, 1)
    }
    setActive = () => {
        this.setState({active: 2});
    }
    setPart = (e) => {
        e.preventDefault()
        const {WirelessAdvice} = this.props;
        //WirelessAdvice.WirelessAdviceStep3.promotion = data
        let cashPayment          = WirelessAdvice.WirelessAdviceStep3.cashPayment
        let installmentPrincipal = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal

        if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
            if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
                let instPrincipalUsedPhone2 = WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

                if((WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35)) < this.state.part){
                    alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                    return;
                }
            }
            else{
                if(Number(installmentPrincipal)+Number(cashPayment)<this.state.part){
                    alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                    return;
                }
            }
        }
        else{
            if(Number(installmentPrincipal)+Number(cashPayment)<this.state.part){
                alert('※할부원금을 초과한 금액을 입력하실 수 없습니다.');
                return;
            }
        }
        
        this.changeCashPayment(this.state.part, 2)
        this.refCloseInput.current.click();
        this.refClose.current.click();
    }


    handleChangePart = (values) => {
        const {formattedValue, value} = values;
        this.setState({part: value,profit: formattedValue});
    }
    
    render() {
        const {WirelessAdvice} = this.props;
        let cashPayment = WirelessAdvice.WirelessAdviceStep3.cashPayment
        let  installmentPrincipal = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal
        const {
            total
        } = this.state;
        const formatter = new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
          })
        
        let cashPaymentValue = this.state.active === 2 ? this.state.part : WirelessAdvice?.WirelessAdviceStep3?.cashPayment?.toString().split('.')[0];

        //console.log("cashPaymentValue : >" + cashPaymentValue + "<");

        if(cashPaymentValue == 0){
            cashPaymentValue = "";
        }

        return (
            <Fragment>
                {/* modal */}
                <div className="modal fade"
                     // data-backdrop="static"
                     data-keyboard="false"
                     id="modal-24" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-additional modal_price_pay" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">현금납부액 </h5>
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" ref={this.refClose}>
                                    {/*<img src="/images/icon-close.svg" alt="" />*/}
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="list-additional">
                                    <li className={(this.state.active==0?'active':'') + ' cursor'}  onClick={this.handleChange.bind(this, null)} data-dismiss="modal">
                                        미적용
                                    </li>
                                    <li className={(this.state.active==1?'active':'') + ' cursor'} onClick={this.fullPayment.bind()} data-dismiss="modal">
                                        현금 완납
                                    </li>
                                    <li className={(this.state.active==2?'active':'') + ' cursor'} onClick={this.setActive.bind()}  data-toggle="modal" data-target="#modal-78" >
                                        부분 납부
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade"
                     id="modal-78" tabIndex={-1}
                     // data-backdrop="static"
                     data-keyboard="false"
                     role="dialog" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered modal-custom" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">현금 납부액 입력 </h5>
                                <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close">
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                </button>
                            </div>
                            <div className="modal-body px-0">
                                <form onSubmit={this.setPart} className="body-plans row_custom">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-modal-gray mt-8">
                                            할부원금 : {
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation
                                                &&
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18
                                                &&
                                                WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191
                                                    ? formatter.format(Math.round(WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - (WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100) - ((WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100) / 65 * 35)) - 1).replace('$','')
                                                    : formatter.format(Number(installmentPrincipal)+Number(cashPayment)).replace('$','')
                                            } 원
                                        </p>
                                    </div>
                                    <div className="form-group row justify-content-between mt-4 align-items-center">
                                        <div className="col-10 pr-0">
                                            {/*<input className="form-control h-44"  value={this.state.part} onChange={this.handleChangePart} />*/}
                                            <CurrencyFormat thousandSeparator={true} prefix={''}
                                                            value={cashPaymentValue}
                                                            onValueChange={(value) => this.handleChangePart(value)}
                                                            className="form-control h-44"
                                            />

                                        </div>
                                        <div className="col-2">
                                            <span className="text-wrapper-gray">원</span>
                                        </div>
                                    </div>
                                    <h5  className="text-wrapper-gray text-left color-orange mb-3">
                                        ※ 할부원금을 초과한 금액은 적용하실 수 없습니다.
                                    </h5>
                                    <h5 className="text-wrapper-gray text-left">
                                        입력한 현금 납부액을 적용하시겠습니까?
                                    </h5>
                                    <div className="d-flex justify-content-center border-top-0 mt-5">
                                        <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" data-dismiss="modal" ref={this.refCloseInput}>취소</button>
                                        <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end modal */}
            </Fragment>

        );
    }
}

CashPaymentModal.propTypes = {
  WirelessAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(CashPaymentModal);
