 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
// import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import SubscriptionFeeModal from "./SubscriptionFeeModal";
import {getSubscriptionFees} from './../../../store/actions/subscription_fee';
class SubscriptionFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: {},
      hide: false
    }
  }

  componentDidMount() {
    const { WirelessAdvice } = this.props;
      this.props.getSubscriptionFees(WirelessAdvice.WirelessAdviceStep1.telcomID).then(res => {
          this.setState({
            result : res,
            hide: res.data.length == 0 ? true : false
          })
      })
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let item=WirelessAdvice.WirelessAdviceStep3.subscriptionFee
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
    return (
      <div className={"form-group " + (this.state.result?.data?.length > 0 ? "d-show" : "d-none")}>
        <label className="col-form-label label-text">가입비</label>
        <button
          disabled={this.state.hide}
          className={`btn btn-input-form mb-0${item ? '' : ' opacity-disabled'}`}
          type="button"
          data-toggle="modal"
          data-target="#modal-95">
          {(item ? formatter.format(item.tmf_fee).replace('$','') +"원" : '미적용')} 
        </button>
        <p className="text-right mb-0 small-color">
          {item ? item.tmf_name : ''}
        </p>
        <SubscriptionFeeModal dataFee={this.state.result}  itemFee={item?item:null} />
      </div>
    );
  }
}

SubscriptionFee.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
    return {
      getSubscriptionFees: (id) => dispatch(getSubscriptionFees(id))
    }
  };
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  
  export default compose(
    withConnect,
    // withReduxForm,
  )(SubscriptionFee);
