import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';
import { Modal } from 'reactstrap';
import _ from "lodash";
import api from "../../../utils/api";

import SelerCreateModal from './SelerCreateModal'
import {Fragment} from "react";

class SelerModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sellers: {},
      isOpenModalCreate:false,
      openSubModalState: false,
      deleteId : null
    }

  }

    openPopupCreateSeller = () => this.setState({isOpenModalCreate: !this.state.isOpenModalCreate})

  selecteSaler = (saler) => {
    this.props.change('WirelessAdviceStep3.saler', saler.user)
    this.props.openPopup()
  }

  deleteSaler = () => {
    api.auth.deleteSeller(this.state.deleteId).then(res => {
      const sellers  = res.data.data ? res.data.data : [];
      this.setState({
          openSubModalState : false,
          sellers: sellers
      })
    }).catch(err => {});
  }

  openSubModal = () => {
    const state = this.state.openSubModalState
    this.setState({
        openSubModalState: !state
    })
}

  onOpened = () => {
      let data = {
          paginate: 999999,
      }
      api.auth.getSellers(data).then(res => {
          const sellers  = res.data.data ? res.data.data : [];
          this.setState({
              sellers: sellers,
          })
      }).catch(err => {
      });
  }

  render() {
    const { WirelessAdvice, auth } = this.props;
    const { store, user } = auth
    let sellers = this.state.sellers?.data || [];
    if (sellers && !sellers.map(e => e.id).includes(user.id)) {
      if (!this.props.auth.role == 'Member') {
          sellers = [user, ...sellers]
      }
    }

    return (
      <Fragment>
          <Modal
              isOpen={this.props.isOpenModal}
              // backdrop={"static"}
              toggle={this.props.openPopup}
              onOpened = {this.onOpened}
              fade={true}
              className={'modal-dialog modal-dialog-centered modal-additional modal-seller-01'}
              contentClassName=""
          >
              <div className="modal-header pt-4 pb-1">
                  <div className={'custom-create-seller'}>
                      <h5 className="modal-title">상담사</h5>
                      <button className="btn btn-wireless" onClick={()=>this.openPopupCreateSeller()}>판매자 등록</button>
                  </div>
                  <button
                      type="button"
                      className="close close-modal"
                      onClick={this.props.openPopup}
                  >
                      <img src="/images/icon-close.svg" alt="" />
                  </button>
              </div>
              <div className="modal-body mt-1">
                  {/* 
                  <ul className="list-additional">
                      {sellers && sellers.map((saler, key) => (
                          <li
                              key={key}
                              onClick={() => this.selecteSaler(saler)}
                              className={WirelessAdvice?.WirelessAdviceStep3?.saler?.id === saler.user.id ? 'active' : ''}
                          >
                              {saler?.user?.name}
                          </li>
                      ))}
                  </ul>
                  */}
                  <table className="table table-striped">
                    <tbody>
                    {sellers && sellers.map((saler, key) => (
                      <tr key={key} style={{backgroundColor : WirelessAdvice?.WirelessAdviceStep3?.saler?.id === saler.user.id ? '#e5effb' : ''}}>
                        <td onClick={() => this.selecteSaler(saler)} style={{cursor : 'pointer'}} >
                          {saler?.user?.name}
                        </td>
                        <td className='text-center' >
                          {WirelessAdvice?.WirelessAdviceStep3?.saler?.id !== saler.user.id ? (
                            <button className="btn p-0" type="button" data-toggle="modal" data-target="#deleteModal" onClick={() => {this.setState({openSubModalState : true, deleteId: saler.user.id})}}>
                              <img src="/images/icon-close.svg" alt="" />
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
              </div>
          </Modal>
          <SelerCreateModal isOpenModal={this.state.isOpenModalCreate} openPopup={this.openPopupCreateSeller} onOpened={this.onOpened} />
          <Modal
              isOpen={this.state.openSubModalState}
              toggle={this.openSubModal}
              fade={true}
              className={'modal-dialog modal-dialog-centered'}
              onOpened={() => {}}
          >
              <div className="modal-body text-center p-5">
                  <span className="notification_delete">삭제하시겠습니까?</span>
              </div>
              <div className="modal-footer justify-content-center border-top-0">
                  <button type="button" className="btn btn_close" onClick={() => this.openSubModal()}>취소</button>
                  <button type="button" className="btn btn-submit pr-5 pl-5" onClick={() => this.deleteSaler()}>확인</button>
              </div>
          </Modal>
      </Fragment>
    );
  }
}

SelerModal.propTypes = {
  openPopup: PropTypes.func,
  auth: PropTypes.object,
  WirelessAdvice: PropTypes.object,
  isOpenModal: PropTypes.any,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(SelerModal);
