
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputMoneyLive from './InputMoneyLive'

class PromotionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpenModalInputLive:false
    }
  }
  renderPromotion () {
      const { dataPromotion, WirelessAdvice } = this.props;
      let activeData=WirelessAdvice.WirelessAdviceStep3.promotion
      // if(dataPromotion && dataPromotion.data &&  dataPromotion.data.length > 0) {
      if(dataPromotion) {
          let defaultData=(<li className={`cursor ${activeData != null && activeData.planID ? "" : "active"}`}>
                      미적용
                  </li>)
          let dataDefault=[{planID:0, promo_name:"미적용", promo_cost:0}]
          let dataInputLive=[{planID:'input_live', promo_name:"직접 입력", promo_cost:0}]
          // let dataDefault=[{planID:0, promo_name:"미적용", promo_cost:0}]
          var dataMerge=[]
          if(dataPromotion && dataPromotion.data &&  dataPromotion.data.length > 0) {
              dataMerge=[...dataDefault,...dataPromotion.data,...dataInputLive]
          }else {
              dataMerge=[...dataDefault,...dataInputLive]
          }


          return (dataMerge.map((item, key) => {
              return (
                  <Fragment key={key}>
                  <li className={`cursor ${this.activeChoose(item)}`}
                      onClick={() => this.choosePromotion(item)}>
                      {item.planID != 0 && item.planID != 'input_live' ? "[프로모션]" : ""} {item.promo_name}
                    </li>
                  </Fragment>
              );
          }));
      }else {
          return null
      }

  }


    activeChoose =(item) => {
        const { dataPromotion, WirelessAdvice } = this.props;
        let activeData=WirelessAdvice.WirelessAdviceStep3.promotion
        // activeData != null && activeData.planID && activeData.promo_name == item.promo_name ? "active" : (activeData == null && item.planID == 0 ? "active" : "")
        if(activeData != null && activeData.promo_name == item.promo_name){
            return 'active';
        }else {
            if(activeData == null && item.planID == 0){
                return 'active';
            }else {
                return ""
            }
        }

    }


    choosePromotion = (data) => {
        if(data.planID != 0) {
            if(data.planID == 'input_live') {
                this.openPopupInputLive()
            }else {
                this.props.change('WirelessAdviceStep3.promotion', data)
                this.props.openPopupPromotion()
            }
        }else {
            // this.props.change('WirelessAdviceStep3.promotion', {})
            this.props.change('WirelessAdviceStep3.promotion', data)
            this.props.openPopupPromotion()
        }
        // this.props.openPopupPromotion()

    }

    openPopupInputLive = () => this.setState({isOpenModalInputLive: !this.state.isOpenModalInputLive})


  render() {
    const { handleSubmit, WirelessAdvice,dataPromotion } = this.props;

    return (
        <Fragment>
            <Modal isOpen={this.props.isOpenModalPromotion}
                // backdrop={"static"}
                   contentClassName={'modal-content pl-4 pr-4'}
                   toggle={this.props.openPopupPromotion} className={'modal-dialog modal-dialog modal-dialog-centered modal-additional '}>


                <div className="modal-header">
                    <h5 className="modal-title">프로모션</h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopupPromotion}>
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody>
                    <ul className="list-additional">
                        {this.renderPromotion()}
                    </ul>
                </ModalBody>


            </Modal>
            <InputMoneyLive openPopupPromotion={this.props.openPopupPromotion} isOpenModalInputLive={this.state.isOpenModalInputLive}
                            openPopupInputLive={this.openPopupInputLive} />
        </Fragment>

    );
  }
}

PromotionModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(PromotionModal);
