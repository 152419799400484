 import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <Modal
          isOpen={this.props.isOpenModal}
          toggle={this.props.openPopup}
          fade={true}
          // backdrop={"static"}
          className={'modal-dialog modal-dialog-centered modal-payment-custom'}
          onOpened={this.modalOpened}
        >
          <div className="modal-body">
            <p className="notification_delete text-center">
                {this.props.content}
            </p>
          </div>
          <div className="modal-footer justify-content-center border-top-0">
            <button type="button" className="btn btn-gray" onClick={this.props.openPopup}>취소</button>
            <button type="button" className="btn btn-submit" onClick={this.props.submit}>확인</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ModalConfirm.propTypes = {
  openPopup: PropTypes.func,
  submit: PropTypes.func,
  isOpenModal: PropTypes.bool,
  content: PropTypes.string,
};

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalConfirm);
