import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';

class WirelessNote extends Component {

  constructor(props) {
    super(props);
  }

  onBlur = (e) => {
      this.props.change('WirelessAdviceStep3.note', e.target.value);
  }

  render() {
    const { WirelessAdvice } = this.props

    return (
      <div className="form-group line-top mt-2">
        <label htmlFor="" className="label-text">메모</label>
        <Field
          name="note" 
          component={() =>
              <textarea
                className="form-control"
                rows="6"
                placeholder="메모"
                name="note"
                onBlur={this.onBlur}
                style={{cursor: 'auto'}}
                defaultValue={WirelessAdvice?.WirelessAdviceStep3?.note}
              ></textarea>
          }
        />
      </div>
    );
  }
}

WirelessNote.propTypes = {
  change: PropTypes.func,
  WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  const form_value = getFormValues('WirelessAdvice')(state);
  return {
    WirelessAdvice: form_value,
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: dispatch(change())
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessNote);
