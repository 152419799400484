import React from 'react';
import { Field } from "redux-form";
import renderTextField from "../FormHelper/TextField";

class SearchDevice extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.onClickSearchKeyword}>
        <div className="form-search">
          <Field
            className="form-control h-44"
            placeholder="Search"
            name="keyword"
            type="text"
            component={renderTextField}
          />
          <button className="btn my-2 my-sm-0" type="submit">
            <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-search.svg"} alt="" />
          </button>
        </div>
      </form>
    )
  }
}

export default SearchDevice;
