import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

function MenuDocuments({ menus, onClickChangeTab, cat_id, isNotice = false }) {
    return (
       
            <Swiper
                slidesPerView={4}
                navigation={true}
                pagination={false}
                scrollbar={false}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                {isNotice && (<SwiperSlide className="nav-item" key={0} onClick={() => onClickChangeTab(0)}>
                        <a  className={`nav-link ` + (cat_id == 0 ? 'active' : '') }>공시지원금 변동</a></SwiperSlide>) }
                {isNotice && menus.map((item, i) =>
                    <SwiperSlide className="nav-item" key={i} onClick={() => onClickChangeTab(item.cat_id)}>
                        <a  className={`nav-link ` + (item.cat_id == cat_id ? 'active'  : '') }>{item.cat_name}</a></SwiperSlide>
                )}
                {!isNotice && menus.map((item, i) =>
                    <SwiperSlide className="nav-item" key={i} onClick={() => onClickChangeTab(item.cat_id)}>
                        <a  className={`nav-link ` + (item.cat_id == cat_id ? 'active' : (i == 0 && cat_id == '') ? 'active' : '') }>{item.cat_name}</a></SwiperSlide>
                )}

            </Swiper>
    );
}

export default MenuDocuments;
