import PropTypes from 'prop-types';
import React from 'react';

const WiredAdviceStepHeader = ({ WiredAdvice, setPage }) => (
  <ul className="step-wireless a">
    <li 
      className={`item-step${WiredAdvice?.step == 1 ? ' active' : ''}`}
      onClick={() => setPage(1)}
    >
      {WiredAdvice?.step <= 1 ? (
        <div className="open-item">
          <span className="step-name">Step 1</span>
          <span>통신사 선택</span>
        </div>
      ) : (
        <div className="content-complete">
          { WiredAdvice?.WiredAdviceStep1?.status == true ? (
            <img src={WiredAdvice?.WiredAdviceStep1?.successString} />
          ) : ('')}
          
        </div>
      )}
    </li>
    <li 
      className={`item-step${WiredAdvice?.step == 2 ? ' active' : ''}`}
      onClick={() => setPage(2)}
    >
      {WiredAdvice?.step <= 2 ? (
        <div className="open-item">
          <span className="step-name">Step 2</span>
          <span>단말기 선택</span>
        </div>
      ) : (
        <div className="content-complete">
          <h4>{WiredAdvice?.WirelessAdviceStep3?.phone?.name}</h4>
        </div>
      )}
    </li>

  </ul>
)

WiredAdviceStepHeader.propTypes = {
    WiredAdvice: PropTypes.object,
  setPage: PropTypes.func,
}

export default WiredAdviceStepHeader;
