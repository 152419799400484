import React from "react";
import {  Modal } from 'reactstrap';
class ModalPrintChooseNetwork extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      active : 0
    }
  }

  chooseNetwork = (id,name) => {
    this.setState({
      active : id
    },()=>{
      let data = {
        id : id,
        name : name,
        type : this.props.type
      }
      this.props.valueNetwork(data);
    })
  }


  render() {
    let {active} = this.state
    return(
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={'modal-dialog-centered modal-additional'}
      >
        <div className="modal-content pl-4 pr-4">
          <div className="modal-header">
            <h5 className="modal-title">이전 통신사 </h5>
            <button type="button"
                    className="close close-modal"
                    onClick={this.props.toggle}

            >
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body">
            <ul className="list-additional">
              <li
                className={active == 0 ? 'active' :''}
                onClick={()=>this.chooseNetwork(0,'미설정')}
              >
                미설정
              </li>
              {this.props.networks.map((item,index) => {
                return(
                  <li
                    className={active == item.id ? 'active' :''}
                    onClick={()=>this.chooseNetwork(item.id,item.name)}
                    key={index}>
                    {item.name}
                  </li>
                )
              })}
              {this.props.type == 'network' ?
                <li
                className={active == "MVNO" ? 'active' :''}
                onClick={()=>this.chooseNetwork('MVNO','MVNO')}
              >
                MVNO(자필기입)
              </li> :
                <li
                  className={active == "giro" ? 'active' :''}
                  onClick={()=>this.chooseNetwork('giro','지로납부')}
                >
                  지로납부
                </li>
              }


            </ul>
          </div>
        </div>

      </Modal>
    )
  }
}

export default ModalPrintChooseNetwork;
