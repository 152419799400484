
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import WirelessPointDiscount from './PointDiscount/PointDiscount'
import WirelessAdvancedDiscountCard from './AdvancedDiscountCard/AdvancedDiscountCard'
import WirelessUsedPhoneCompensation from './UsedPhoneCompensation/UsedPhoneCompensation'
import WirelessCashPayment from './CashPayment/CashPayment'
import WirelessOther from './Other/Other'
import WirelessInstallmentMonth from './InstallmentMonth/InstallmentMonth'
import WirelessRateAgreement from './RateAgreement/RateAgreement'
import WirelessPromotion from './Promotion/Promotion'
import WirelessPromotionDurationOne from './PromotionDuration/WirelessPromotionDurationOne'
import WirelessPromotionDurationTwo from './PromotionDuration/WirelessPromotionDurationTwo'
import CombinedDiscount from './CombinedDiscount/CombinedDiscount'
import WirelessWelfareDiscount from './WelfareDiscount/WelfareDiscount'
import WirelessDiscountCard from './DiscountCard/DiscountCard'
import WirelessMutualService from './MutualService/MutualService'
import WirelessConversion2G from './Conversion2G/Conversion2G'
import { priceFormat, roundUpNumber } from './../../utils/helper'
import {TELCOM_ID, KT_S23_SBS_INS_GRC_VAL, KT_S23_SBS_PRM_Y_EDT} from "../../utils/constant";
import LguplusSoldier from './Soldier/LguplusSoldier';
import SktplusSoldier from './Soldier/SktplusSoldier';
import KtSoldier from './Soldier/KtSoldier';
import Conversion from './Conversion/Conversion';
import ConversionAdd from './Conversion/ConversionAdd';

class WirelessFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  getPrincipalHtml = () => {
    const { WirelessAdvice }  = this.props;
    let result                = null;
    let wirelessAdviceStep3   = WirelessAdvice?.WirelessAdviceStep3;
    let usedPhoneCompensation = wirelessAdviceStep3?.usedPhoneCompensation;
    let installmentPrincipal  = wirelessAdviceStep3?.installmentPrincipal;
    let tupId                 = +(usedPhoneCompensation?.tup_id || 0);
    let etsId                 = usedPhoneCompensation?.ets_id;
    
    if(tupId == 191){ // 갤럭시S23 중고폰가격보장프로그램
      result = <Fragment>
        <div class="form-group row justify-content-between align-items-start">
          <div class="col-auto">
            <p class="mb-0 label-prices color-blue color-reds">1구간 할부원금</p>
          </div>
          <div class="col-auto">
            <p class="text-right mb-0 prices-blue">
              0
              <span class="small-color">원</span>
            </p>
          </div>
        </div>
      </Fragment>;
    }
    else if(tupId == 190){ // KT S23 FE 폰 구독 
      result = <Fragment>
        <div className="form-group mt-5 row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-reds">할부유예금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue color-reds">
              {priceFormat(KT_S23_SBS_INS_GRC_VAL)}
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
      </Fragment>;
    }
    else if(tupId == 192){ // KT S23 프리미엄 Y 에디션
      result = <Fragment>
        <div className="form-group mt-5 row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-reds">할부유예금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue color-reds">
              {priceFormat(KT_S23_SBS_PRM_Y_EDT)}
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
        <div className="form-group mt-5 row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-blue">할부원금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue">
              {priceFormat(installmentPrincipal)}
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
      </Fragment>;
    }
    else{
      result = <Fragment>
        <div className="form-group mt-5 row">
          <div className="col-md-4">
            <p className="mb-0 label-prices color-blue">할부원금</p>
          </div>
          <div className="col-sm-8">
            <p className="text-right mb-0 prices-blue">
              { tupId && !etsId ? 0 : priceFormat(installmentPrincipal) }
              <span className="small-color">원</span>
            </p>
          </div>
        </div>
      </Fragment>;
    }

    return result;
  }

  getInstallmentPeeHtml = () => {
    const { WirelessAdvice }  = this.props;
    let result                = null;
    let wirelessAdviceStep3   = WirelessAdvice?.WirelessAdviceStep3;
    let usedPhoneCompensation = wirelessAdviceStep3?.usedPhoneCompensation;
    let installmentFee        = +(wirelessAdviceStep3?.installmentFee || 0);
    let instMonthlyDefer1     = +(wirelessAdviceStep3?.instMonthlyDefer_1 || 0);
    let tupId                 = +(usedPhoneCompensation?.tup_id || 0);
    let etsId                 = usedPhoneCompensation?.ets_id;
    
    if(tupId == 190){ // KT KT S23 FE 폰 구독 
      result = <div className="form-group row align-items-start mt-4"></div>;
    }
    else if(tupId == 191){ // // 갤럭시S23 중고폰가격보장프로그램
      result = <div className="form-group row align-items-start mt-4">
        <div className="col-md-4">
          <p className="mb-0 label-prices mt-2 color-reds">할부수수료</p>
        </div>
        <div className="col-sm-8">
          <p className="text-right mb-0 prices-number color-reds"> 
            {priceFormat(installmentFee)}
            <span className="small-color">원</span>
          </p>
        </div>
      </div>;
    }
    else{
      result = <div className="form-group row align-items-start mt-4">
        <div className="col-md-4">
          <p className="mb-0 label-prices mt-2">할부수수료</p>
        </div>
        <div className="col-sm-8">
          <p className="text-right mb-0 prices-number"> 
            { tupId && !etsId ? priceFormat(instMonthlyDefer1 * 6) : priceFormat(installmentFee) }
            <span className="small-color">원</span>
          </p>
          <p className="text-right mb-0 small-color">
            (원리금균등, 연 {priceFormat(WirelessAdvice?.WirelessAdviceStep3?.interestRate, 1)}%)
          </p>
        </div>
      </div>;
    }
    
    return result;
  }

  getMonthlyDeviceInstallmentHtml = () => {
    const { WirelessAdvice }        = this.props;
    let result                      = null;
    let wirelessAdviceStep3         = WirelessAdvice?.WirelessAdviceStep3;
    let usedPhoneCompensation       = wirelessAdviceStep3?.usedPhoneCompensation;
    let installmentMonthly          = +(wirelessAdviceStep3?.installmentMonthly || 0);
    let instMonthlyDefer1           = +(wirelessAdviceStep3?.instMonthlyDefer_1 || 0);
    let installmentMonthlyPrincipal = +(wirelessAdviceStep3?.installmentMonthlyPrincipal || 0);
    let installmentMonthlyFee       = +(wirelessAdviceStep3?.installmentMonthlyFee || 0);
    let tupId                       = +(usedPhoneCompensation?.tup_id || 0);
    let etsId                       = usedPhoneCompensation?.ets_id;

    if(tupId == 190){ // KT KT S23 FE 폰 구독 
      result = <div className="form-group row justify-content-between align-items-start">
        <div className="col-auto">
          <p className="mb-0 label-prices color-reds">월 단말 할부금</p>
        </div>
        <div className="col-auto">
          <p className="text-right mb-0 prices-blue">
            { priceFormat(installmentMonthly) }
            <span className="small-color">원</span>
          </p>
        </div>
      </div>;
    }
    else if(tupId == 191){ // // 갤럭시S23 중고폰가격보장프로그램
      result = <div className="form-group row justify-content-between align-items-start">
        <div className="col-auto">
          <p className="mb-0 label-prices color-reds">1구간 월 단말 할부금</p>
        </div>
        <div className="col-auto">
          <p className="text-right mb-0 prices-blue">
            { priceFormat(installmentMonthly) }
            <span className="small-color">원</span>
          </p>
        </div>
      </div>;
    }
    else{
      result = <div className="form-group row justify-content-between align-items-start">
        <div className="col-auto">
          <p className="mb-0 label-prices">월 단말 할부금</p>
        </div>
        <div className="col-auto">
          <p className="text-right mb-0 prices-blue">
            { priceFormat(installmentMonthly) }
            <span className="small-color">원</span>
          </p>
          <p className="text-right mb-0 small-color">
            { tupId && !etsId ? `0원 + ${ priceFormat(instMonthlyDefer1) }` : `${priceFormat(installmentMonthlyPrincipal)}원 + ${priceFormat(installmentMonthlyFee)}원` }
          </p>
        </div>
      </div>;
    }
    
    return result;
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
    let telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
    if(dataSkt) {
        dataSkt=dataSkt.toUpperCase()
    }
    if(telcom != null) {
        telcom=telcom.toUpperCase()
    }

    let rateAgreement;
    let styleNumber;
    if (!WirelessAdvice.WirelessAdviceStep3?.isRateAgreement) {
      
      rateAgreement = WirelessAdvice.WirelessAdviceStep3.rateAgreement ? '-' + priceFormat(WirelessAdvice.WirelessAdviceStep3.rateAgreement) : 0
    } else {
      rateAgreement = 0
    }
    if (rateAgreement != 0) {
      styleNumber = "btn-prices-wp";
    } else {
      styleNumber = "";
    }

    let telcom_id=WirelessAdvice.WirelessAdviceStep1.telcomID
    
    return (
      <div className="flex-wrapper">
        <div className="left">
          <Conversion />
          <ConversionAdd />
          <WirelessPointDiscount/>
          <WirelessAdvancedDiscountCard/>
          {telcom_id == TELCOM_ID.SKT || telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU ? <WirelessUsedPhoneCompensation/> : ""}
          <WirelessCashPayment/>
          <WirelessOther/>
          <WirelessInstallmentMonth/>
          { this.getPrincipalHtml() }{/* 할부원금표시 */} 
          { this.getInstallmentPeeHtml() }{/* 할부수수료 */}
          { this.getMonthlyDeviceInstallmentHtml() }{/* 월단말할부금 */}
        </div>
        <div className="right d-flex flex-column justify-content-between">
          <div>
            <div className="form-group row" style={{position: 'relative'}}>
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">요금약정</label>
              <div className="col-sm-8">
                <button type="button"
                  className={`btn btn-input-form mb-0 ${styleNumber}`}
                  disabled=""
                  readOnly
                >
                  {rateAgreement}
                  <span>원</span>
                </button>
                
              </div>
              <div className="col-sm-4 col-form-label label-text" style={{position: 'absolute',top: 30, fontSize: '11px', color: '#2f8bff'}}>
              {dataSkt == "LGU+" && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1 ? (
                <div>
                  <span>프리미어요금제</span><br />
                  <span> 약정할인</span>
                </div>
              ) : ""}
              </div>
            </div>
            <WirelessRateAgreement/>

            <WirelessPromotion/>
            {WirelessAdvice.WirelessAdviceStep3?.promotionDuration != null && WirelessAdvice.WirelessAdviceStep3.promotionDuration.length == 1 ? <WirelessPromotionDurationOne /> : ''}
            {WirelessAdvice.WirelessAdviceStep3?.promotionDuration != null && WirelessAdvice.WirelessAdviceStep3.promotionDuration.length == 2 ? <WirelessPromotionDurationOne /> : ''}
            {WirelessAdvice.WirelessAdviceStep3?.promotionDuration != null && WirelessAdvice.WirelessAdviceStep3.promotionDuration.length == 2 ? <WirelessPromotionDurationTwo /> : ''}
            {telcom_id == TELCOM_ID.LGU ? <LguplusSoldier /> : ""}
            {telcom_id == TELCOM_ID.SKT ? <SktplusSoldier/> : ""}
            {telcom_id == TELCOM_ID.KT ? <KtSoldier/> : ""}
            <CombinedDiscount
              handleShowModalCombineDiscount={this.props.handleShowModalCombineDiscount}
            />
            <WirelessWelfareDiscount/> 
            <WirelessDiscountCard/>

            {telcom_id == TELCOM_ID.KT || telcom_id == TELCOM_ID.LGU ? <WirelessMutualService/> : ""}
          </div>

          <div className="form-group row mb-4 pb-2">
            <div className="col-md-4">
              <p className="mb-0 label-prices">
                월 요금
              </p>
            </div>
            <div className="col-sm-8">
              <p className="text-right mb-0 prices-blue">
                {priceFormat(WirelessAdvice?.WirelessAdviceStep3?.monthlyFee)}
                {/* test */}
              <span className="small-color">원</span></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WirelessFilter.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WirelessFilter);

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
})(withConnect);

// export default compose(
//   withConnect,
//   withReduxForm,
// )(WirelessFilter);
