import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';



const renderTextFieldCustom = ({ input, meta: { touched, invalid, error }, type, placeholder, min, max, disabled, errorStyle,errorPhone
}) => {
    return (

        <Fragment>
            <input
                {...input}
                type={type}
                className={'form-control ' + (errorPhone !== undefined && errorPhone.phone ? 'is-invalid' : '') + ' h-44'}
                placeholder={placeholder}
                min={min}
                max={max}
                autoComplete="new-password"
                disabled={disabled}
                 />
            {error !== undefined ? (
                <div className="invalid-feedback" style={errorStyle}>
                    {error}
                </div>
            ) : ''}
            {errorPhone !== undefined ? (
                <div className="invalid-feedback" style={errorStyle}>
                    {errorPhone !== undefined && errorPhone.phone ? errorPhone.phone : ''}
                </div>
            ) : ''}
        </Fragment>
    )
};

renderTextFieldCustom.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default renderTextFieldCustom;
