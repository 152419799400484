import axios from 'axios';
import setAuthorizationHeader from './setAuthorizationHeader';
import { logoutSuccess } from '../store/actions/auth';
import reduxStore from '../store/reducers';
// import history from './history';


const instance = new axios.create({
    baseURL: (process.env.NODE_ENV === 'development') ?
        'http://localhost:8000/' : process.env.REACT_APP_API_URL_PROD,
    headers: {
        'content-language': process.env.REACT_APP_CONTENT_LANGUAGE ,
        'Cache-Control': 'no-cache' ,
        "Pragma": 'no-cache',
        "X-Requested-With": 'XMLHttpRequest',
        "Accept": 'application/json',
    }
});
const {dispatch} = reduxStore;
instance.interceptors.response.use(function (response)  {
    const newToken = response.headers.authorization;
    if (newToken) {
        localStorage.setItem('token', newToken);
        setAuthorizationHeader(newToken);
    }
    return response;
}, function (error)  {
    if (!!localStorage.getItem('user') && error && error.response && error.response.status === 401) {
        window.store.dispatch(logoutSuccess())
    }

    // if (error && error.response && error.response.status === 401) {
    //     window.store.dispatch(logoutSuccess())
    // }
    return Promise.reject(error);
});

export default instance;
