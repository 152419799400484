import {
    userActions
} from '../actions';
import MessageJSON from '../../utils/message';
import api from "../../utils/api";
import setAuthorizationHeader from "../../utils/setAuthorizationHeader";
import {renderButtonChat} from './../actions/ui'

export const loginSuccess = (data) => {
    setAuthorizationHeader(data.token);
    return {
        type: userActions.LOGIN_SUCCEED,
        payload: {
            token: data.token,
            user: data.user,
            role: data.role,
            store: data.store,
            storePlan: data.storePlan,
            storePlanLast: data.storePlanLast,
            accountErrStatus: data.accountErrStatus
        }
    }
};

export const updateProfileSuccess = (user) => {
    return {
        type: userActions.PROFILE_SUCCEED,
        payload: {
            user: user,
        }
    }
};

export const updateOptionPrint = (data) => {
    
    return {
        type: userActions.UPDATE_OPTION_PRINT,
        payload: {
            data: data,
        }
    }
};

export const logoutSuccess = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('storeTSC');
    localStorage.removeItem('storePlan');
    localStorage.removeItem('isAllowAccessMenu');
    localStorage.removeItem('isOpenPopupMenu'); 
    localStorage.removeItem('storePlanLast'); 
    localStorage.removeItem('accountErrStatus'); 
    return {
        type: userActions.LOGOUT_SUCCEED,
    }
};


export const getCurrentUserSucceed = (data) => {
    setAuthorizationHeader(data.token);
    return {
        type: userActions.GET_CURRENT_USER_SUCCEED,
        payload: {
            user: data.user,
            token: data.token,
            role: data.role,
            store: data.store,
            storePlan: data.storePlan,
            isAllowAccessMenu: data.isAllowAccessMenu,
            isOpenPopupMenu: data.isOpenPopupMenu,
            storePlanLast: data.storePlanLast,
            accountErrStatus: data.accountErrStatus,
        }
    }
};

export const loginAction = (data) => dispatch => {
    return api.auth.login(data)
        .then(response => {
            if(response.data.data.token && response.data.data.user){
                let user = response.data.data.user;
                let token = response.data.data.token;
                let role = response.data.data.role;
                let store = response.data.data.store;
                let storePlan = response.data.data.storePlan;
                let storePlanLast = response.data.data.storePlanLast;
                dispatch(loginSuccess(response.data.data));
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
                localStorage.setItem('role', response.data.data.role);
                localStorage.setItem('storeTSC', JSON.stringify(response.data.data.store));
                localStorage.setItem('storePlan', JSON.stringify(response.data.data.storePlan));
                localStorage.setItem('storePlanLast',  JSON.stringify(response.data.data.storePlanLast));
                localStorage.setItem('accountErrStatus',  response.data.data.accountErrStatus);
                dispatch(renderButtonChat(user));
                dispatch(updateLoginLog(user));
                return Promise.resolve(response.data.data);
            } else if (response?.data?.status == 106){
                let token = response.data.data.token;
                localStorage.setItem('token', token);
                setAuthorizationHeader(token);
                return Promise.resolve(response?.data);
            }
        })
        .catch(error => {
            if(error.response.status == 400) {
                if(error.response.data.status == 101) {
                    return Promise.reject(MessageJSON.login.not_find_store);
                } else if(error.response.data.status == 102) {
                    return Promise.reject(MessageJSON.login.not_find_store_plan);
                } else if(error.response.data.status == 103) {
                    return Promise.reject(MessageJSON.login.store_expdate);
                }else if(error.response.data.status == 104) {
                    return Promise.reject(MessageJSON.login.status_not_paid);
                }
            }

            return Promise.reject(error.response.data);
        });
};

export const logoutAction = (data) => dispatch => {
    return api.auth.logout(data)
        .then(response => {
            dispatch(logoutSuccess());
            window.location.reload()
            dispatch(renderButtonChat(null));
            return Promise.resolve(response);
        }).catch(error => {

        });
};

export const logoutDevice = (data) => dispatch => {
    return api.auth.logoutDevice(data)
        .then(response => {
            return Promise.resolve(response);
        }).catch(error => {

        });
};

export const updateLoginLog = (user) => dispatch => {
    if (user.roles[0].name == 'Member' || user.roles[0].name == 'Admin') {
        let data = {
            type: window.currentDevice === "web" ? 0 : 1,
        }
        return api.auth.updateLoginLog(data)
        .then(response => {
            return Promise.resolve(true);
        }).catch(error => {
            return Promise.resolve(false);
        });
    }
};


export const allowAccessMenu = (data=false) => {
    localStorage.setItem('isAllowAccessMenu', data);
    return {
        type: userActions.ALLOW_ACCESS_MENU,
        payload: {
            data: data,
        }
    }
};

export const openPopupMenu = (data=false) => {
    localStorage.setItem('isOpenPopupMenu', data);
    return {
        type: userActions.OPEN_POPUP_MENU,
        payload: {
            data: data,
        }
    }
};

export const updatePasswordMenu = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
    return {
        type: userActions.UPDATE_PASSWORD_MENU,
        payload: {
            data: data,
        }
    }
};

export const updateExpireDate = (data) => {
    localStorage.setItem('storePlanLast', JSON.stringify(data));
    localStorage.setItem('accountErrStatus', 0);
    return {
        type: userActions.UPDATE_EXPIRE_DATE,
        payload: {
            data: data,
        }
    }
};

export const updateExpiredDate = (data) => {
    localStorage.setItem('storePlanLast', JSON.stringify(data));
    localStorage.setItem('storePlan', JSON.stringify(data));
    localStorage.setItem('accountErrStatus', 0);
    return {
        type: userActions.UPDATE_EXPIRED_DATE,
        payload: {
            data: data,
        }
    }
};
