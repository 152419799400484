
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from "../../../components/FormHelper/CheckboxField"
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta } from 'redux-form';
import validate from './validate-step-2';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import {
    loginAction,
} from '../../../store/actions/auth';
import * as routerName from '../../../utils/routerName';
import api from "../../../utils/api";
import { SubmissionError } from 'redux-form';

class ForgotPasswordStep2Form extends Component {

    componentDidMount() {
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Fragment>
                <p className="text-wrapper light-18">
                    회원정보에 기재된 휴대폰번호 또는 이메일로 임시비밀번호가 발송됩니다.
                 <br />
                    임시비밀번호 발급 수단을 선택해주세요.
                </p>
                <form onSubmit={handleSubmit} autoComplete="on">
                    <div className="custom-control custom-radio mt-5 mb-3 ml-5 pl-55">
                        <Field
                            id="forgot_type_1"
                            name="forgot_type"
                            component={renderRadioField}
                            type="radio"
                            value="1"
                            checked={this.props.ForgotPasswordStep2 && parseInt(this.props.ForgotPasswordStep2.forgot_type)}
                            onChange={this.props.changeFieldVerify}
                            className="custom-control-input" />
                        <label className="custom-control-label text-radio" htmlFor="forgot_type_1">휴대폰번호 
                        {this.props.user.phone.length >= 13 ? (
                            <span>*** **** - {this.props.user.phone.slice(this.props.user.phone.length - 4, this.props.user.phone.length)}</span>
                        ) : (
                            <span>*** *** - {this.props.user.phone.slice(this.props.user.phone.length - 4, this.props.user.phone.length)}</span>
                        )}
                        
                        </label>
                    </div>
                    <div className="custom-control custom-radio ml-5 pl-55">
                        <Field
                            id="forgot_type_2"
                            name="forgot_type"
                            component={renderRadioField}
                            type="radio"
                            value="2"
                            checked={this.props.ForgotPasswordStep2 && parseInt(this.props.ForgotPasswordStep2.forgot_type)}
                            onChange={this.props.changeFieldVerify}
                            className="custom-control-input" />
                        <label className="custom-control-label text-radio" htmlFor="forgot_type_2">이메일 
                        <span>{this.props.user.email && this.props.user.email.slice(0,  this.props.user.email.length > 3 ? 3 : 1) + '*'.repeat( (this.props.user.email.length - (this.props.user.email.length > 3 ? 3 : 1) - this.props.user.email.substring(this.props.user.email.lastIndexOf("@") + 1).length -1))}@{this.props.user.email.substring(this.props.user.email.lastIndexOf("@") + 1)}</span></label>
                    </div>
                    {/* <div className="form-group mt-5">
                    <label className="label-text">{this.props.fieldVerify}</label>
                    <div className="row">
                        <div className="col-12">
                            <Field
                                id="verify"
                                label="verify"
                                name="verify"
                                component={renderTextField}
                                placeholder={this.props.fieldVerify}
                            />
                        </div>
                    </div>
                </div> */}
                    <div className="submit-form mt-100">
                        <button className="btn btn-submit" type="submit" >
                            임시비밀번호 받기
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

ForgotPasswordStep2Form.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        ForgotPasswordStep2: getFormValues('ForgotPassword')(state),
        ForgotPasswordStep2Errors: getFormSyncErrors('ForgotPassword')(state),
        ForgotPasswordStep2Meta: getFormMeta('ForgotPassword')(state),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = 'ForgotPassword';


const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotPasswordStep2Form);
