
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector } from 'redux-form';
import validate from './validate-step-1.js';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import * as routerName from '../../../utils/routerName';

class WiredStep1 extends Component {
    
    componentDidMount () {
    }

    renderLinkTelcom = () => {
        let link=''
        return this.props.telcomProviders && this.props.telcomProviders.telcomParents.map((item, i) => {
            if(item.code.toUpperCase() == "SKT") {
                //link= 'https://ucyber.skbroadband.com:8443/normal.do?serviceId=S_COMM0013&viewId=V_COMM1001'
                link= 'https://www.bworld.co.kr/myb/product/join/address/search.do';
            }else if(item.code.toUpperCase() == "LGU") {
                //link='https://www.uplus.co.kr/ent/scve/vodc/RetrieveServiceArea.hpi#tabType202'
                link='https://www.lguplus.com/support/online/coverage-check';
            }else {
                link= 'https://help.kt.com/serviceinfo/SearchHomePhone.do'
            }

            return <div className="box-item border-0 h-44" key={i}>
                <a className="btn btn-gray-white" href={link} target="_blank">
                    가용확인
                </a>
            </div>

        })
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="list-box">             
                <div className="box-main">


                    { this.props.telcomProviders && this.props.telcomProviders.telcomParents.map((item, i) =>
                        <Fragment key={i}>
                            {i <= 2 ? (
                                <div className="box-item " onClick={()=> this.props.onSelectedTelcom(item)}>
                                    <img src={item.image_url.original} alt="" />
                                    <span>{item.name}</span>
                                </div>
                            ): ('')}

                        </Fragment>

                    )}

                </div>
                <div className="box-main mt-5">
                    {this.renderLinkTelcom()}

                </div>
            </div>
        );
    }
}

WiredStep1.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
    initialValues: { 

    },
    
});


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default  compose(
    withConnect,
    withReduxForm,
  )(WiredStep1);
