 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {getDetailPlan} from "../../../store/actions/discount_card";
const style={
    overflow:'inherit'
}
class PlanDetailsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        data:{}
    }
  }


  render() {
    const { handleSubmit, WirelessAdvice,dataPlanDetail } = this.props;


    return (
        <Modal isOpen={this.props.isOpenModal}
               // backdrop={"static"}
               toggle={this.props.openPopupPlanDetails} fade={true} className={'modal-dialog modal-dialog-centered modal-information-plans'} contentClassName="modal-content content">


                <div className="modal-header">
                    <h5 className="modal-title">{WirelessAdvice.WirelessAdviceStep3.packageFee && WirelessAdvice.WirelessAdviceStep3.packageFee.name ? WirelessAdvice.WirelessAdviceStep3.packageFee.name : ""} </h5>
                    <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopupPlanDetails}>
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                    </button>
                </div>
                <ModalBody className="modal-body essence-plan pl-0 pr-0">
                    <form action="">
                        <ul className="list-essencs">
                            <li>
                                <img src="/images/ic_settings_phone.svg" alt="" />
                                {dataPlanDetail.call_time_desc}
                            </li>
                            <li>
                                <img src="/images/icon-gmail.svg" alt=""/>
                                {dataPlanDetail.sms_desc}
                            </li>
                            <li>
                                <img src="/images/icon-teth.svg" alt=""/>
                                {dataPlanDetail.data_detail}
                            </li>
                            <li>
                                <img src="/images/ic_videocam.svg" alt=""/>
                                {dataPlanDetail.call_time_adt}분
                            </li>
                        </ul>
                        <article className="content-essencs" style={style}>
                           <div className="essencs_custom">
                                <h3 className="title-essencs">
                                    주요혜택 및 요금제 안내
                                </h3>
                                {/*<p>*/}
                                    {/*{dataPlanDetail.extra_info}*/}
                                {/*</p>*/}
                                <p dangerouslySetInnerHTML={{__html: dataPlanDetail.extra_info}} />
                           </div>
                        </article>
                        <div className="modal-footer justify-content-center border-top-0">
                            <button type="button" className="btn btn-submit pl-5 pr-5" onClick={this.props.openPopupPlanDetails}>확인</button>
                        </div>
                    </form>
                </ModalBody>

        </Modal>
    );
  }
}

PlanDetailsModal.propTypes = {
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
      getDetailPlan: (data) => dispatch(getDetailPlan(data)),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
   withReduxForm,
)(PlanDetailsModal);
