

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { openPopupMenu, allowAccessMenu, updatePasswordMenu } from '../../store/actions/auth';
import * as routerName from './../../utils/routerName';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import api from "../../utils/api";
import MessageJSON from "./../../utils/message.json";
import ResetSuccessPopup from './../AccessMenu/ResetSuccessPopup';

class ForgotAccessPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            isOpenPopupNotify: false,
            msgNotify:'',
            errors: {
            },
        }
    }

    componentDidMount() {
    }

    resetToMail = () => {
        this.props.showLoading()
        let data = {
            'type': 'email',
        };
        api.auth.resetPasswordMenu(data)
        .then(response => {
            let { user } = this.props.auth;
            user.password_menu = response.data.password_menu;

            this.props.hideLoading();
            this.props.updatePasswordMenu(user);
            this.onClose();
            setTimeout(() => {
                let email = this.props.auth.user.email;       
                email = `${email.slice(0, 2)}***@${email.substring(email.lastIndexOf("@") + 1)}`;                
                
                this.setState({
                    ...this.state,
                    isOpenPopupNotify: true,
                    // msgNotify: MessageJSON.access_menu.msg_notify.send_mail_success,       
                    msgNotify: `이메일 ${email} 의 수신함을 확인해주세요`
                })
            }, 1000);
        })
        .catch(err => {
            this.props.hideLoading();
        });
    }

    resetToPhone = () => {
        this.props.showLoading()
        let data = {
            'type': 'phone',
        };
        api.auth.resetPasswordMenu(data)
        .then(response => {
            let { user } = this.props.auth;
            user.password_menu = response.data.password_menu;

            this.props.hideLoading();
            this.props.updatePasswordMenu(user);
            this.onClose();

            setTimeout(() => {
                let phone = this.props.auth.user.phone;
                if (phone) {
                    // let phone = `(***) **** - ${ phone.slice(9) }`;
                    phone = `(***) **** - ${ phone.slice(phone.length - 4) }`;


                    this.setState({
                        ...this.state,
                        isOpenPopupNotify: true,
                        // msgNotify: MessageJSON.access_menu.msg_notify.send_phone_success,
                        msgNotify: `${phone} 의 문자메시지를 확인해주세요.`
                    })
                }
  
            }, 1000);
        })
        .catch(err => {
            this.props.hideLoading();
            // alert('Có lỗi xử lý')
        });
    }

    onClose = () => {       
        this.props.toggle()
    }


    onTogglePopupNotify = () => {       
        this.setState({
            ...this.state,
            isOpenPopupNotify: !this.state.isOpenPopupNotify
        })
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                       toggle={this.props.toggle}
                    className="modal-dialog modal-dialog-centered"
                    contentClassName="modal-content pl-4 pr-4"
                       // backdrop={"static"}
                >
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-center w-100">임시 비밀번호가 전송됩니다</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={() => this.onClose()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action>
                            <div className="form-group">
                                <p className="my-5 text-center notification_delete">
                                전송 방법을 선택해주세요. 
                            </p>
                            </div>
                            <div className="w-100 mt-5 pt-4 text-center">
                                <button type="button" className="btn btn_close mx-2" onClick={this.resetToMail}>
                                    이메일 
                                </button>
                                <button type="button" className="btn btn_close mx-2" onClick={this.resetToPhone}>
                                    판매자 휴대폰  
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>

                <ResetSuccessPopup 
                    isOpen = {this.state.isOpenPopupNotify}
                    toggle = {this.onTogglePopupNotify}
                    msgNotify = {this.state.msgNotify}
                    // allowAccessMenu = {this.allowAccessMenu}
                />

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        updatePasswordMenu: (data) => dispatch(updatePasswordMenu(data))
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ForgotAccessPopup);
