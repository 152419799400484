import {
    GET_COST_DISPLAY_PLAN
} from '../actions';

import api from "../../utils/api";

export const getCostDisplayPlanSuccess = (data) => {
    return {
        type: GET_COST_DISPLAY_PLAN.GET_COST_DISPLAY_PLAN_SUCCESS,
        data: data
    }
};

export const getCostDiplayPlan = (data) => dispatch => {
    return api.get_cost_display_plan.getCostDiplayPlan(data)
        .then(res => {
            dispatch(getCostDisplayPlanSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};
