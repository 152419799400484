import {
    dataActions, PLAN_INFORMATION
} from '../actions';

const initalState = {
    plans:{},
    informations:{}
};

const dataPlans = (state = initalState, action) => {
    switch (action.type) {
       
        case PLAN_INFORMATION.GET_PLAN_INFORMATION_SUCCESS: {
            return {
                ...state,
                dataPlans : action.data
            }
        }

        case PLAN_INFORMATION.GET_PLAN_NOTICE_INFORMATION_SUCCESS: {
            return {
                ...state,
                ificationPlan : action.data
            }
        }

        default:
            return state;
    }
};

export default dataPlans;
