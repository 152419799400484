import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import ModalImage from "./ModalImage";
// import 'swiper/swiper-bundle.css';
import './Compare.css';

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

SwiperSlideComponent.defaultProps = {
  onClickSelectedColorActive: ()=>{},
  navigation: false
};

function SwiperSlideComponent({devices,onClickSelectedColorActive, navigation, indexActive=0}) {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [isOpenPopupImage, openPopupImage] = useState(false);

  const [imageDetail, setImageDetail] = useState([]);



  const slides = [];
  for (let i = 0; i < devices.length; i += 1) {
    slides.push(
      <SwiperSlide key={`slide-${i}`} >
        <a >
          <img
            src={devices[i].files_url[0].thumbnail}
            alt=""
            onClick={()=>{
              openPopupImage(!isOpenPopupImage)
              setImageDetail(devices[i].files_url.map(e => e.original))
            }}
          />
        </a>
      </SwiperSlide>
    );
  }

  const thumbs = [];
  for (let i = 0; i < devices.length; i += 1) {
    thumbs.push(
      <SwiperSlide
        key={`thumb-${i}`}
        onClick={()=> onClickSelectedColorActive(devices[i])}
      >
        <span
          style={{ "backgroundColor": `${devices[i].code}` }}
        ></span>
      </SwiperSlide>
    );
  }

  const slides2 = [];
  for (let i = 0; i < 5; i += 1) {
    slides2.push(
      <SwiperSlide key={`slide-${i}`} tag="li" activeIndex={1}>
        <img
          src={`https://picsum.photos/id/${i}/163/100`}
          // src={`https://picsum.photos/id/${i + 1}/500/300`}
          style={{ listStyle: 'none' }}
          alt={`Slide ${i}`}
        />
      </SwiperSlide>
    );
  }

  useEffect(() => {
  	if (controlledSwiper) {
      if(devices.length > 0){
        controlledSwiper.slideTo(indexActive, 0, false);
      }
    }
  });

  return (
    <React.Fragment>
      <Swiper
        id="main"
        thumbs={{ swiper: thumbsSwiper }}
        // controller={{ control: controlledSwiper }}
        className={'swiper-top gallery-top'}
        // tag="section"
        // wrapperTag="ul"
        navigation={navigation}
        // pagination
        // spaceBetween={0}
        slidesPerView={1}
        // onInit={(swiper) => console.log('Swiper initialized!', swiper)}
        onSlideChange={(swiper) => {
          // console.log('Slide index changed to: ', swiper);
        }}
        onObserverUpdate={(swiper) => {
          console.log('onObserverUpdate call');
        }}
        onSwiper={setControlledSwiper}
        // onReachEnd={() => console.log('Swiper end reached')}
        //activeSlideKey={`slide-${indexActive}`}
      >
        {slides}
      </Swiper>

      <Swiper
        id="thumbs"
        // spaceBetween={5}
        slidesPerView={devices.length}
        onSwiper={setThumbsSwiper}
        className={'swiper-thumbs gallery-thumbs transform-0'}
        
        onReachEnd={() => {
          //console.log('Swiper end reached')
        }}

        onSlideChange={(swiper) => {
          //console.log('Slide index changed to: ', swiper);
        }}
      >
        {thumbs}
      </Swiper>
      
      <ModalImage
        isOpenPopupImage={isOpenPopupImage}
        openPopupImage={()=>openPopupImage(!isOpenPopupImage)}
        // devices={devices}
        imageDetail={imageDetail}
      />

    </React.Fragment>
  );
}

export default SwiperSlideComponent;
