import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {change, Field, getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from "moment/moment";
import api from "../../utils/api";
import {updateWirelessStep3} from "../../utils/helper";
import {hideLoading, showLoading} from "../../store/actions/ui";
import  { Redirect } from 'react-router-dom'
import {OPTION_FEE} from "../../utils/constant";
import * as routeName from "../../utils/routerName";
import { withRouter } from 'react-router';
const divStyle = {
    zIndex: '999'
};
const none = {
    display: 'none'
};
class HistoryConsultantComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected:null,
            step: 3,
            id: null,
            WirelessAdviceStep3: {
                saler: null, // THIẾT BỊ ĐẦU CUỐI (단말기)

                phone: {}, // THIẾT BỊ ĐẦU CUỐI (단말기)
                startingPrice: null, // Giá khởi điểm (출고가)
                packageFee: null, // gói phí (요금제)
                planData: null, // gói phí (요금제)
                newPlanData: null, // gói phí mới (요금제)
                namePlanNewDateChange: null,
                basicCost: null, // chi phí cơ bản (기본료)

                optionFee: OPTION_FEE.disclosureSubsidy, // 0: giảm giá cam kết lựa chọn (선택약정할인) | 1: tiền hỗ trợ thêm (추가지원금)
                disclosureSubsidy: null, // tiền hỗ trợ thông báo (공시지원금)
                displayApplyDate: null, // ngày tham gia (기준)
                disclosureSubsidyDefault: null,
                disclosureSubsidyDifference: null,
                disclosureSubsidyDifferenceDefault: null,
                additionalGrants: null, // tiền hỗ trợ thêm (추가지원금)
                additionalGrantsType: null,
                optionalAgreement: null, // cam kết lựa chọn (선택약정)
                optionalAgreementDefault: null,
                contractMonth: null, // số tháng cam kết (약정개월)
                optionalAgreementWith2G: null,
                pointDiscount: null, // giảm giá point (포인트 할인)
                advancedDiscountCard: null, // thẻ giảm giá trước (선할인 카드)
                usedPhoneCompensation: null, // bồi thường điện thoại cũ (중고폰보상 프로그램)
                applyUsedProgram: null, // Tiền yêu cầu theo tháng sau khi apply bồi thường DT cũ

                cashPayment: null, // nạp tiền mặt (현금납부)
                other: null, // khác (기타)
                installmentMonth: 24, // số tháng trả góp (할부개월)

                rateAgreement: null, // cam kết gói phí (요금약정)
                rateAgreementDefault: null,
                rateAgreementEstimate: null,
                discost12: null, // cam kết gói phí (요금약정)
                discost24: null, // cam kết gói phí (요금약정)
                discostChange: 12, // cam kết gói phí (요금약정)
                promotion: {}, // promotion (프로모션)
                combinedDiscount: null, // giảm giá kết hợp (결합 할인)
                welfareDiscount: null, // giảm giá phúc lợi (복지 할인)
                discountCard: null, // thẻ giảm giá (할인카드)
                mutualService: null, // dịch vụ tương trợ (상조서비스)
                conversion2G: null, // chuyển đổi 2G (2G전환)


                sim: null, // SIM (유심)
                subscriptionFee: null, // Phí đăng ký (가입비)
                insurrance: null, // Bảo hiểm thiết bị (휴대폰 보험)
                insurranceCancelDate: null, // Ngày có thể hủy (휴대폰 보험)

                extraServices: [], // Dịch vụ bổ sung

                // tinh tien
                interestRate: 5.9, // tiền lãi trên 1 năm
                installmentPrincipal: 0, // Nguyên giá trả góp (할부원금)
                installmentFee: 0, // Tổng lãi suất trả góp (할부수수료)
                installmentMonthly : 0, // Tiền trả góp thiết bị theo tháng (월 단말 할부금)
                installmentMonthlyPrincipal : 0, // Tiền trả góp thiết bị theo tháng Nguyên giá trả góp
                installmentMonthlyFee : 0, // Tiền trả góp thiết bị theo tháng lãi suất trả góp
                monthlyFee: 0, // chi phi theo thang (월 요금)
                moneyRemainingMonth: 0, // Số tiền dự kiến tháng này (당월 예상 금액)
                estimatedAmountMonth: 0, // Số tiền dự kiến tháng này (당월 예상 금액)
                amountMonthlyDevice: 0, // Tiền yêu cầu theo tháng A + B (월별 청구금액)
                amountMonthlyOption: 0, // Tiền yêu cầu theo tháng C (월별 청구금액)
                amountMonthlyTotal: 0, // Tiền yêu cầu theo tháng A + B + C (월별 청구금액)
                status: false,
                planNewDateChange: 0,  // giá gói phí muốn đổi (요금제)
                dataPlanNewDateChange: null,  // gói phí muốn đổi (요금제)
                estimatedRatesChangingPlans: 0, // chi phí dự kiến khi thay đổi gói phí (요금제 변경시 예상요금 )
                optionalAgreementPlanEstimated: 0,
                note: '',

                customerId: null,
                customerNote: '',
            },
            currentPage: 1,
            consultantPerPage: 5
        }
        this.myRef = React.createRef();
    }

    inputRefs = [];
    setRef = (ref) => {
        if (ref && ref != null) {
            this.inputRefs.push(ref);
        }
    };

    triggerClickCheckBox = (id) => {

        let dataMapRef = []
        this.inputRefs.map((item) => {
            if (item && item.value && item.value != null && item.value == id) {
                dataMapRef.push(item)
            }
        })
        if (dataMapRef && dataMapRef.length > 0) {
            dataMapRef[0].click()
        }
    }

    handleChangeRadio = (e) => {
        this.setState({selected: e.target.value})
    }

    handleSubmit = () => {
        const {selected} = this.state
        if(selected) {
            this.props.showLoading()
            api.advice.getConsultant(selected)
                .then(response => {
                    this.props.hideLoading()
                    const {data} = response.data
                    if(data.consultant_wireless.model != null) {
                        data.consultant_wireless.model.short_name=data.telcom.short_name
                    }
                    // if(data.consultant_wireless.welfare_discount != null) {
                    //     let basicCostData=data.consultant_wireless && data.consultant_wireless.plan ? +data.consultant_wireless.plan.cost_display+(data.consultant_wireless.plan.cost_display*10/100) : null
                    //     let dataWelfareDiscount=[
                    //         {id:0, value:"미적용", cost:0},
                    //         {id:1, value:'장애인 (35% 할인)',cost:basicCostData != null ? basicCostData*0.35 : 69000*0.35},
                    //         {id:2, value:'국가유공자 (35% 할인)',cost:basicCostData != null ? basicCostData*0.35 : 69000*0.35},
                    //         {id:3, value:'기초생활수급자 생계/의료(28,600원 할인)',cost:28600},
                    //         {id:4, value:'기초생활수급자 주거/교육(12,100원 할인,최대 23,650원)', cost:23650},
                    //         {id:5, value:'차상위계층(12,100원 할인, 최대 23,650원 할인)',cost:23650},
                    //         {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:12100},
                    //     ]
                    //     let dataFilterWelfare=dataWelfareDiscount.filter(item => item.id == (+data.consultant_wireless.welfare_discount))
                    //     if(dataFilterWelfare.length > 0) {
                    //         data.consultant_wireless.welfare_discount={id:dataFilterWelfare[0].id, value:dataFilterWelfare[0].value, cost:dataFilterWelfare[0].cost}
                    //     }
                    // }
                    // if(data.consultant_wireless && data.consultant_wireless.conversion_2g != null) {
                    //     let data2g = [
                    //         {id: 0, value: "미적용"},
                    //         {id: 1, value: '적용'},
                    //         {id: 2, value: '무약정'},
                    //     ]
                    //     let dataFilter=data2g.filter(item => item.id == (+data.consultant_wireless.conversion_2g))
                    //     if(dataFilter.length > 0) {
                    //         data.consultant_wireless.conversion_2g={id:dataFilter[0].id, value:dataFilter[0].value}
                    //     }
                    // }

                    const {WirelessAdvice: {WirelessAdviceStep3}} = this.props
                    let WirelessAdviceStep3Clone = updateWirelessStep3(WirelessAdviceStep3, data)
                    WirelessAdviceStep3Clone.telcomeID=data.telcom.id
                    WirelessAdviceStep3Clone.model=data.consultant_wireless.model
                    WirelessAdviceStep3Clone.successString=data && data.telcom && data.telcom.image_url ? data.telcom.image_url.original : ""
                    this.props.history.push({
                        pathname: `/${routeName.WIRELESS_ADVICE}`,
                        state: {
                            id:data.id,
                            step:3,
                            dataPageHistoryConsultant:true,
                            WirelessAdviceStep3:WirelessAdviceStep3Clone,
                            // WirelessAdviceStep3:{
                            //     ...this.state.WirelessAdviceStep3,
                            //     successString: data && data.telcom && data.telcom.image_url ? data.telcom.image_url.original : "",
                            //     installmentMonth: data.consultant_wireless.installment_month,
                            //     optionFee: data.consultant_wireless.option_fee,
                            //     disclosureSubsidy: data.consultant_wireless.disclosure_subsidy,
                            //     optionalAgreement: data.consultant_wireless.optional_agreement,
                            //     packageFee:{
                            //         id: data.consultant_wireless && data.consultant_wireless.plan && data.consultant_wireless.plan.id? data.consultant_wireless.plan.id : null,
                            //         classID: data.consultant_wireless && data.consultant_wireless.plan && data.consultant_wireless.plan.classID ? data.consultant_wireless.plan.classID : null,
                            //         name: data.consultant_wireless && data.consultant_wireless.plan && data.consultant_wireless.plan.name ? data.consultant_wireless.plan.name : null,
                            //         cost_display: data.consultant_wireless && data.consultant_wireless.plan && data.consultant_wireless.plan.cost_display ? data.consultant_wireless.plan.cost_display :null,
                            //     },
                            //     phone:{
                            //         modelID:data.consultant_wireless.model.id
                            //     },
                            //     telcomeID:data.telcom.id,
                            //     model:data.consultant_wireless.model,
                            //     startingPrice: data.consultant_wireless.starting_price,
                            //     basicCost: +data.consultant_wireless.plan.cost_display+(data.consultant_wireless.plan.cost_display*10/100),
                            //     interestRate: data.telcom.commitment_rate,
                            //     conversion2G: data.consultant_wireless.conversion_2g,
                            //     optionalAgreementDefault: null,
                            //     discost12: data.consultant_wireless.plan.discost_12,
                            //     discost24: data.consultant_wireless.plan.discost_24,
                            //     displayApplyDate: data.consultant_wireless.apply_date,
                            //     rateAgreement: +data.consultant_wireless.plan.discost_12 + data.consultant_wireless.plan.discost_12*10/100,
                            //     saler: data.saler,
                            //     promotion: data.consultant_wireless.plan_promotion,
                            //     welfareDiscount: data.consultant_wireless.welfare_discount,
                            //     discountCard: data.consultant_wireless.discount_card,
                            //     pointDiscount: data.consultant_wireless.point_discount,
                            //     advancedDiscountCard: data.consultant_wireless.advanced_discount_card,
                            //     usedPhoneCompensation: data.consultant_wireless.apply_used_program,
                            //     cashPayment: data.consultant_wireless.cash_payment,
                            //     other: data.consultant_wireless.other,
                            //     installmentMonth: data.consultant_wireless.installment_month,
                            //     subscriptionFee: data.consultant_wireless.member_ship_fee,
                            //     insurrance: data.consultant_wireless.device_insurance,
                            //     insurranceCancelDate: moment(data?.consultant_wireless?.insurrance_cancel_date).format('YYYY-MM-DD'),
                            //     note: data.note,
                            //     planChangeDate: data.consultant_wireless.plan_change_date,
                            // },
                            color: data.consultant_wireless.device_color,
                        }
                    });

                })
                .catch(() => {
                    this.props.hideLoading()
                })
        }

    }

    handleClickPaginate = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    handleNext = () => {
        const {consultants}=this.props
        const lastPage = consultants.length / this.state.consultantPerPage;
        this.state.currentPage === lastPage ? this.setState({currentPage: lastPage}) : this.setState({currentPage: this.state.currentPage + 1})
    };

    handleBack = () => {
        this.state.currentPage === 1 ? this.setState({currentPage: 1}) : this.setState({currentPage: this.state.currentPage - 1})
    };



  render() {
        const {consultants}=this.props
      let {selected} = this.state;

      const {currentPage, consultantPerPage} = this.state

      const lastPage = currentPage * consultantPerPage;
      const firstPage = lastPage - consultantPerPage;
      const currentConsultants = consultants.slice(firstPage, lastPage);

      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(consultants.length / consultantPerPage); i++) {
          pageNumbers.push(i);
      }

      const renderPagination = pageNumbers.map(number => {
          return (
              <li className={(this.state.currentPage === number? 'active': '')+ ' page-item'} key={number} >
                  <a className="page-link" id={number} onClick={this.handleClickPaginate}>{number}</a>
              </li>
          );
      });

      // const html = consultants && consultants.length > 0 && consultants.map((consultant, key) => {
      const html = currentConsultants && currentConsultants.length > 0 && currentConsultants.map((consultant, key) => {

          return <tr key={key} onClick={() => this.triggerClickCheckBox(consultant.id)} style={divStyle}>
              <td >
                  <div className="d-flex">
                      <div className="custom-control custom-radio">
                          <input
                              type="radio"
                              style={none}
                              ref={this.setRef}
                              id={`check-00${consultant.type_name+key}`}
                              name="customRadio"
                              className="custom-control-input"
                              onChange={this.handleChangeRadio}
                              value={consultant.id}
                          />
                          <input type="radio" className="custom-control-input"
                                 value={consultant.id}

                                 onClick={() => this.triggerClickCheckBox(consultant.id)}

                          />
                          <label className="custom-control-label ml-3"
                                 htmlFor={`check-00${consultant.type_name+key}`}>
                              {consultant.telcom_name}
                          </label>
                      </div>
                  </div>
              </td>
              <td>{consultant.model_name}</td>
              <td>{consultant.plan_name}</td>
              <td className="text-right">
                  <b>
                      {moment(consultant.updated_at).format('YYYY-MM-DD')}
                  </b>
                  <span className="color-sliver ml-1">
                        {moment(consultant.updated_at).format('HH:mm:ss')}
                      </span>
              </td>
          </tr>

      });

    return (
      <Fragment>

          <article className="article_counseling">
              <div className="table-responsive">
                  <table className="table tbale-header w-100">
                  <thead>
                      <tr className="bg-purple">
                          <th className="text-left">통신사</th>
                          <th className="text-left">모델명</th>
                          <th className="text-left">요금제</th>
                          <th className="text-right">상담날짜/시간</th>
                      </tr>
                      </thead>
                  </table>
                <div className="table-scroll">
                <table className="table">
                      <tbody>
                      {html}
                      {/*{consultants.map((consultant, key) => (*/}
                          {/*<tr key={key} onClick={() => this.triggerClickCheckBox(consultant.id)}>*/}
                              {/*<td>*/}
                                  {/*<div className="d-flex">*/}
                                      {/*<div className="custom-control custom-radio">*/}
                                          {/*<input*/}
                                              {/*type="radio"*/}
                                              {/*ref={this.setRef}*/}
                                              {/*id={`check-00${consultant.type_name+key}`}*/}
                                              {/*name="customRadio"*/}
                                              {/*className="custom-control-input"*/}
                                              {/*onChange={this.handleChangeRadio}*/}
                                              {/*value={consultant.id}*/}
                                          {/*/>*/}
                                          {/*<input type="radio" className="custom-control-input"*/}
                                                 {/*value={consultant.id}*/}
                                                 {/*checked={check}*/}
                                                 {/*onClick={() => this.triggerClickCheckBox(consultant.id)}*/}
                                                 {/*defaultChecked={check}*/}
                                          {/*/>*/}
                                          {/*<label className="custom-control-label ml-3"*/}
                                                 {/*htmlFor={`check-00${consultant.type_name+key}`}>*/}
                                              {/*{consultant.telcom_name}*/}
                                          {/*</label>*/}
                                      {/*</div>*/}
                                  {/*</div>*/}
                              {/*</td>*/}
                              {/*<td>{consultant.model_name}</td>*/}
                              {/*<td>{consultant.plan_name}</td>*/}
                              {/*<td className="text-right">*/}
                                  {/*<b>*/}
                                      {/*{moment(consultant.updated_at).format('YYYY-MM-DD')}*/}
                                  {/*</b>*/}
                                  {/*<span className="color-sliver ml-1">*/}
                        {/*{moment(consultant.updated_at).format('HH:mm:ss')}*/}
                      {/*</span>*/}
                              {/*</td>*/}
                          {/*</tr>*/}
                      {/*))}*/}
                      </tbody>
                  </table>

                    <nav aria-label="Page navigation example" className="pagination-notifi">
                        <ul className="pagination">
                            <li className="page-item item-previous" >
                                <a className="page-link" onClick={this.handleBack} aria-label="Previous">
                                            <span aria-hidden="true">
                                                <img src="/images/Left.svg" alt="" />
                                            </span>
                                </a>
                            </li>
                            {renderPagination}
                            <li className="page-item item-next" >
                                <a className="page-link" onClick={this.handleNext} aria-label="Next">
                                            <span aria-hidden="true">
                                                <img src="/images//Right.svg" alt="" />
                                            </span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
              </div>



          </article>
          <div className="d-flex justify-content-center border-top-0 mt-3">
              <button type="button" className="btn btn-gray mx-2 pr-5 pl-5" data-dismiss="modal">취소</button>
              <button type="button" className="btn btn-submit mx-2 pl-4 pr-4" disabled={this.state.selected ? false : true} data-dismiss="modal" onClick={this.handleSubmit}>불러오기</button>
          </div>

      </Fragment>
    );
  }
}

HistoryConsultantComponent.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    change: PropTypes.func,
    WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
      WirelessAdvice: getFormValues('WirelessAdvice')(state),
      WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
      telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
      showLoading: () => dispatch(showLoading()),
      hideLoading: () => dispatch(hideLoading()),
  };
};




const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default withRouter(compose(
  withConnect,
    withReduxForm
)(HistoryConsultantComponent));

