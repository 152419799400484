import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import _ from "lodash";
class DiscountIndicated extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            list: [],
            selected: null
        };
    }





    /**
     * close modal
     */
    onCloseModal = () => {
        this.props.onSelect(this.state.selected, "discount_indicated");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        this.setState({
            list: plans_line_not_null,
            selected: this.props.selected
        })
    }

    onSelect = (e, value) => {
        this.setState({
            selected: value
        }, () => {
            this.toggleModal(e)
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
            isShowModal: !this.state.isShowModal
        })
    }


    componentDidUpdate(prevProps){
        if(prevProps.selected && prevProps.selected !== this.props.selected){
            this.setState({
                selected: this.props.selected
            })
        }
    }
    render() {
        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">지정할인<span
                        className="weight-normal">(회선 선택)</span>
                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                            disabled={_.reject(this.props.plans, {plan: null}).length > 0 && !this.props.kt_premium_discount ? false : true}
                    >
                        { this.props.selected ? `회선 ${this.props.selected.idx}`  : (this.state.selected ? `회선 ${this.state.selected.idx}`  : "미적용")}
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered modal-additional modal-small"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">지정할인</h5>
                            <button type="button" className="close close-modal"
                                    onClick={(e) => this.toggleModal(e)}
                            >
                               <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional max-auto mb-5">
                                <li className={!this.state.selected ? "active" : "" }
                                    onClick={(e) =>  this.setState({
                                        selected: null
                                    }, () => {
                                        this.toggleModal(e)
                                    })}
                                >
                                    미적용
                                </li>
                                {
                                    this.state.list.length > 0 ? this.state.list.map( (value, key) => {
                                        return this.props.discount_line_cnt && key < this.props.discount_line_cnt ? (
                                            <li key={key}
                                                className={this.state.selected && this.state.selected.idx == value.idx ? "active" : ""}
                                                onClick={(e) => this.onSelect(e, value)}
                                            >
                                                회선 { value.idx}
                                            </li>
                                        ) : null
                                    }) : null
                                }
                            </ul>
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

DiscountIndicated.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(DiscountIndicated);