import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, change } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
import CurrencyInput from 'react-currency-input';
class ConversionAddModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalTwo: false,
            setModalTwo: false,
            resultMoney : 0,
            moneyNoChange: 0,
            valueSelect: 0,
            invalid: false,
            active: 0,
        }
    }

    toggle = () => {
        this.props.toggleEvent()
    }

    /**
     * close modal
     */
    closeModal = () => {
       this.setState({
          valueSelect : this.props.additionalGrants
       })
    }

    toggleTwo = () => {
        this.setState({
            modalTwo: !this.state.modalTwo,
            invalid: false
        }, () => {
            if (this.state.modalTwo == false) {
                setTimeout(() => {
                    this.toggle();
                }, 100);
            }
        })
    }

    getValueMoney = (value) => {
        this.setState({
            ...this.state,
            active: value
        })
        
        switch (value) {
            case '0':
                this.setState({
                    resultMoney : 0,
                    valueSelect : 0
                }, () => {
                    this.props.getValue(this.state.resultMoney, value);
                    this.props.toggleEvent();
                })
                break;
            case '15':
                this.setState({
                    resultMoney : +this.props.validPrice,
                    valueSelect : +this.props.validPrice
                }, () => {
                    this.props.getValue(this.state.resultMoney, value);
                    this.props.toggleEvent();
                })
                break;
            default:
                break;
        }
    }
    handleChange = (e,maskedvalue, floatvalue) => {
        //const financialGoal = (e.target.validity.valid) ? floatvalue : this.state.valueSelect;
        //this.setState({valueSelect:financialGoal ? financialGoal : 0 });
        let financialGoal = 0;

        if(e.target.validity.valid){
            financialGoal = floatvalue;

            if(financialGoal > +this.props.validPrice){
                financialGoal = 0;

                alert("공시지원금의 최대 15%까지만 적용가능합니다.\n전환추가지원금이 초기화 됩니다.");
            }
        }
        else{
            financialGoal = this.state.valueSelect;
        }

        this.setState({valueSelect : financialGoal });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            active: 'modal',
        })
        let valueCheck = this.state.valueSelect.toString();
        if (!valueCheck.includes("e+21") && (parseInt(this.state.valueSelect) <= (parseInt(this.props.validPrice) + 1))) {
            this.setState({
                resultMoney : this.state.valueSelect,
                valueSelect : this.state.valueSelect
            }, () => {
                this.props.getValue(this.state.resultMoney, -1);
            })
            this.toggleTwo()
        }else{
            this.setState({
                modalTwo: true,
                invalid: true
            })
        }
    }
    resetValue = () => {
        this.setState({
            valueSelect: +this.props.validPrice
        })
    }
    render() {
        let {valueSelect} = this.state;
        
        return (
            <Fragment>
                <Modal 
                    isOpen={this.props.showModal} 
                    toggle={this.toggle}  
                    // backdrop={"static"}
                    className="modal-dialog-centered modal-additional-support modal-additional"
                    contentClassName="pl-4 pr-4"
                    onClosed = {this.closeModal}
                >
                     <div className="modal-header">
                        <h5 className="modal-title">전환추가지원</h5>
                        {/* <button type="button" className="close close-modal" onClick={this.toggle}>
                            <img src="/images/icon-close.svg" alt="" />
                        </button> */}
                    </div>
                    <div className="modal-body mt-1">
                        <ul className="list-additional">
                            <li className={this.props.additionalGrants == 0 || this.state.active == 0 ? 'active' : ''} onClick={this.getValueMoney.bind(this, "0")}>
                                미적용
                            </li>
                            <li className={this.props.additionalGrants != 0 && this.state.active == '15' ? 'active' : ''} onClick={this.getValueMoney.bind(this, "15")}>
                                15%
                            </li>
                            <li className={ this.props.additionalGrants != 0 && this.state.active == 'modal' ? 'active cursor' : ''} onClick={this.toggleTwo}>
                                직접 입력
                            </li>
                        </ul>
                    </div>
                </Modal>
                <Modal 
                    isOpen={this.state.modalTwo} 
                    toggle={this.toggleTwo} 
                    // backdrop={"static"}
                    className="modal-dialog-centered modal-custom"
                    contentClassName="pl-4 pr-4"
                >
                     <div className="modal-header">
                        <h5 className="modal-title">전환추가지원 입력 </h5>
                        <button type="button" className="close close-modal" onClick={this.toggleTwo}>
                        <i className="sprite sprite-close-modal"></i>
                        </button>
                    </div>
                    <div className="modal-body px-0">
                        <form onSubmit={this.handleSubmit} className="body-plans">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 text-modal-gray">지원금 15% 금액 : {priceFormat(this.props.validPrice)} 원</p>
                                <button type="button" className="btn btn-gray-white" onClick={this.resetValue}>적용</button>
                            </div>
                            <div className="form-group row mt-4 align-items-center">
                                <div className="col-10 pr-0">
                                    <CurrencyInput 
                                        precision="0"
                                        type="text" 
                                        className={this.state.invalid ? 'form-control h-44 is-invalid' : 'form-control h-44'} 
                                        value={valueSelect} 
                                        onChangeEvent={this.handleChange.bind(this)} 
                                    />
                                </div>
                                <div className="col-2">
                                    <span className="text-wrapper-gray">원</span>
                                </div>
                            </div>
                            {
                            this.state.invalid ? (
                                <h5 className="text-wrapper-gray text-left color-orange">
                                    금액은 원 {priceFormat(this.props.validPrice)}원 이하로 입력해 주세요.
                                </h5>
                            ) : ''
                            }
                            <h5 className="text-wrapper-gray text-left color-orange">
                                ※ 지원금 15% 초과한 금액은 적용하실 수 없습니다.
                            </h5>
                            <h5 className="text-wrapper-gray text-left">
                                입력한 전환추가지원금을 적용하시겠습니까?
                            </h5>
                            <div className="d-flex justify-content-center border-top-0 pt-1 mt-5">
                                <button type="button" className="btn btn-gray pr-5 pl-5 mr-2" onClick={this.toggleTwo}>취소</button>
                                <button type="submit" className="btn btn-submit pr-5 pl-5 ml-2">확인</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(ConversionAddModal);

