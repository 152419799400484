import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

class ModalHistoryAdvice extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                
               <div className=" pl-4 pr-4">
         
            <div className="content-product modal-counseling-history">
                <div className="left-product">
                    <div className="top">
                        <ul className="nav nav-tabs list-tab justify-content-center" id="myTabsa" role="tablist">
                            <li className="nav-item tab-item">
                                <a className="nav-link active" id="wireless-tab" data-toggle="tab" href="#wireless" role="tab" aria-controls="wireless" aria-selected="false">무선상담내역</a>
                            </li>
                            <li className="nav-item tab-item"> 
                                <a className="nav-link" id="wired-tab" data-toggle="tab" href="#wired" role="tab" aria-controls="wired" aria-selected="true">유선상담 저장내역</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <article>
                    <div className="tab-content" id="myTabCoantendt">
                        <div className="tab-pane fade active show" id="wireless" role="tabpanel" aria-labelledby="wireless-tab">
                            <table className="table">
                                <thead>
                                    <tr className="bg-purple">
                                        <th className="text-left">통신사</th>
                                        <th className="text-left">모델명</th>
                                        <th className="text-left">요금제</th>
                                        <th className="text-right">상담날짜/시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-002" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-002">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-003" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-003">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-004" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-004">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-005" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-005">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-006" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-006">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="wired" role="tabpanel" aria-labelledby="wired-tab">
                            <table className="table">
                                <thead>
                                    <tr className="bg-purple">
                                        <th className="text-left">통신사</th>
                                        <th className="text-left">모델명</th>
                                        <th className="text-left">요금제</th>
                                        <th className="text-right">상담날짜/시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-002" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-002">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-003" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-003">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-004" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-004">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-005" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-005">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <div className="d-flex">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id="check-006" name="customRadio" className="custom-control-input"/>
                                                <label className="custom-control-label ml-3" htmlFor="check-006">KT</label>
                                            </div>
                                           </div>
                                        </td>
                                        <td>갤럭시 A31 (SM-A315NK)</td>
                                        <td>데이터ON 비디오</td>
                                        <td className="text-right"> <b>2020-06-05</b> <span className="color-sliver">15:58:53</span> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </article>
            </div>
            <div className="modal-footer justify-content-center border-top-0 mt-3">
                <button type="button" className="btn btn-gray pr-5 pl-5" data-dismiss="modal">취소</button>
                <button type="button" className="btn btn-submit pl-4 pr-4" data-dismiss="modal">불러오기</button>
            </div>
        </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(ModalHistoryAdvice);

