import MessageJSON from '../../../utils/message';
var format = /[ !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~`]/;

function ValidateEmail(mail) 
{
 if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

const ko = /[\u3131-\uD79D]/ugi

const validate = values => {
    const errors = {};
    const { 
        username,
        password,
        repassword,
        store_name,
        sto_addr_line1,
        sto_addr_line2,
        sto_addr_code,
        first_store_phone,
        last_store_phone,
        first_user_phone,
        last_user_phone,
        store_phone_code,
        email,
        name,
        rulesChecked,
        business_email1,
        registration_number1,
        business_type
    } = values;
    if (!username) {
        errors.username = MessageJSON.signup.validate.username_required;
    } 
    // else if (username.trim() && username.length < 4) {
    //     errors.username = MessageJSON.signup.validate.username_min;
    // } 
    else if (username.trim() && username.length > 10) {
        errors.username =  MessageJSON.signup.validate.username_max;
    } else if (format.test(username)) {
        errors.username =  MessageJSON.signup.validate.username_characters;
    } else if (!!username.match(ko)) {
        errors.username =  MessageJSON.signup.validate.username_characters;
    }
    if (!name) {
        errors.name = MessageJSON.signup.validate.name_required;
    } else if (name.trim() && name.length > 64) {
        errors.name = MessageJSON.signup.validate.name_max;
    }
    if (!password) {
        errors.password = MessageJSON.signup.validate.password_required;
    } else if (password.trim() && password.length < 6) {
        errors.password = MessageJSON.signup.validate.password_min;
    } else if (password.trim() && password.length > 14) {
        errors.password = MessageJSON.signup.validate.password_max;
    } else if (!!password.match(ko)) {
        errors.password =  MessageJSON.signup.validate.password_characters;
    }
    if (!repassword) {
        errors.repassword = MessageJSON.signup.validate.repassword_required;
    } else if (repassword.trim() && repassword.length < 6) {
        errors.repassword = MessageJSON.signup.validate.repassword_min;
    } else if (repassword.trim() && repassword.length > 14) {
        errors.repassword = MessageJSON.signup.validate.repassword_max;
    } else if (password !== repassword) {
        errors.repassword = MessageJSON.signup.validate.repassword_same;
    }
    if (!store_name) {
        errors.store_name = MessageJSON.signup.validate.store_name_required;
    }  else if (store_name.trim() && store_name.length > 64) {
        errors.store_name = MessageJSON.signup.validate.store_name_max;
    }
    if (!sto_addr_line1) {
        errors.sto_addr_line1 = MessageJSON.signup.validate.sto_addr_line1_required;
    } else if (sto_addr_line1.trim() && sto_addr_line1.length > 64) {
        errors.sto_addr_line1 = MessageJSON.signup.validate.sto_addr_line1_max;
    }
    if (!sto_addr_line2) {
        errors.sto_addr_line2 = MessageJSON.signup.validate.sto_addr_line2_required;
    }if (sto_addr_line2.trim() && sto_addr_line2.length > 64) {
        errors.sto_addr_line2 = MessageJSON.signup.validate.sto_addr_line2_max;
    }
    if (!sto_addr_code) {
        errors.sto_addr_code = MessageJSON.signup.validate.sto_addr_code_required;
    } else if (sto_addr_code.trim() && sto_addr_code.length > 10) {
        errors.sto_addr_code = MessageJSON.signup.validate.sto_addr_code_max;
    }
    if (!first_store_phone) {
        errors.first_store_phone = MessageJSON.signup.validate.first_store_phone_required;
    }
    if (!last_store_phone) {
        errors.last_store_phone = MessageJSON.signup.validate.last_store_phone_required;
    } 
    if (!first_user_phone) {
        errors.first_user_phone = MessageJSON.signup.validate.first_user_phone_required;
    }
    if (!last_user_phone) {
        errors.last_user_phone = MessageJSON.signup.validate.last_user_phone_required;
    } 
    // else if(last_store_phone.length < 9){
    //     errors.last_store_phone =  MessageJSON.signup.validate.verify_phone_number_check_format;
    // }
    
    if (!store_phone_code) {
        errors.store_phone_code = MessageJSON.signup.validate.store_phone_code_required;
    }
    if (!email) {
        errors.email = MessageJSON.signup.validate.email_required;
    } else if (email.trim() && email.length > 32) {
        errors.email = MessageJSON.signup.validate.email_max;
    } else if (!ValidateEmail(email)) {
        errors.email = MessageJSON.signup.validate.email_email;
    }
   
    if (!rulesChecked || rulesChecked==false) {
        errors.rulesChecked = MessageJSON.signup.validate.rulesChecked_required;
    }

    if (business_type == 1) {

        if (!business_email1) {
            errors.business_email1 = MessageJSON.signup.validate.business_email_required;
        } else if (business_email1.trim() && business_email1.length > 32) {
            errors.business_email1 = MessageJSON.signup.validate.business_email_max;
        } else if (!ValidateEmail(business_email1)) {
            errors.business_email1 = MessageJSON.signup.validate.business_email_email;
        }

        errors.registration_number1 = [];
        if (registration_number1 && !registration_number1[0]) {
            if (registration_number1) {
                errors.registration_number1[0] = ' ';
                errors.registration_number1_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number1 && registration_number1[0].length < 3) {
            if (registration_number1) {
                errors.registration_number1[0] =  ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number1 && registration_number1[0].length > 3) {
            if (registration_number1) {
                errors.registration_number1[0] = ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
        if (registration_number1 && !registration_number1[1]) {
            if (registration_number1) {
                errors.registration_number1[1] = ' ';
                errors.registration_number1_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number1 && registration_number1[1].length < 2) {
            if (registration_number1) {
                errors.registration_number1[1] =  ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number1 && registration_number1[1].length > 2) {
            if (registration_number1) {
                errors.registration_number1[1] = ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
        if (registration_number1 && !registration_number1[2]) {
            if (registration_number1) {
                errors.registration_number1[2] = ' ';
                errors.registration_number1_msg = MessageJSON.signup.validate.registration_number_field_1_required;
            }
        } else if (registration_number1 && registration_number1[2].length < 5) {
            if (registration_number1) {
                errors.registration_number1[2] =  ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_min;
            }
        } else if (registration_number1 && registration_number1[2].length > 5) {
            if (registration_number1) {
                errors.registration_number1[2] = ' ';
                errors.registration_number1_msg =  MessageJSON.signup.validate.registration_number_field_1_max;
            }
        }
    }

    return errors;
};

export default validate;
