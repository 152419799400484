import React, { Component, Fragment } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';

export default class renderDatePickerField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date()
        }
    }

    handleChange = date => {
        let now = new Date();
        if(moment(date).format('YYYY-MM-DD') >= moment(now).format('YYYY-MM-DD')) {
            this.setState({
                startDate: date
            }, () => {
                this.props.input.onChange(moment(date).format('YYYY-MM-DD'))
            });
        }
        
    };


    render() {
        const { input: { value, name } } = this.props
        const { input, required, meta: { touched, invalid, error } } = this.props  //whatever props you send to the component from redux-form Field
        return (
            <div className="form-row">
                <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/src/images/icon-calender.svg"} alt="" />
                <DatePicker
                    {...input}
                    value={moment(value).format('YYYY-MM-DD')}
                    name={name}
                    selected={this.state.startDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(e) => this.handleChange(e)}
                    className={'form-control ' + (invalid ? 'is-invalid' : '') + ' h-44'}
                    autoComplete="off"
                    minDate={new Date()}
                />
                {error !== undefined ? (
                    <div className="invalid-feedback d-block" style={{position:'relative', top:'25px'}}>
                        {error}
                    </div>
                ) : ''}
            </div>
        )
    }
}
