import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import api from "../../utils/api";
import { bindActionCreators, compose } from 'redux';
import { change, Field, getFormMeta, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { hideLoading, showLoading } from "../../store/actions/ui";
import { hideModal, showModal } from "../../store/actions/modal";
import PropTypes from 'prop-types'
import { Redirect } from 'react-router';
import {
    getAdviceWireless
} from './../../store/actions/dataStore';
import { allowAccessMenu, openPopupMenu } from './../../store/actions/auth';
import RegisterAccessPopup from './../AccessMenu/RegisterAccessPopup';
import AuthAccessPopup from './../AccessMenu/AuthAccessPopup';
import * as helper from './../../utils/helper';
import { Link } from 'react-router-dom';
import * as routerName from './../../utils/routerName';
import Tooltip from "../../components/Tooltip";
import ko from "date-fns/locale/ko"; // the locale you want
registerLocale("ko", ko); // register it with the name you want



class HisoryBuyProductComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            isOpen: false,
            data: {},
            customer_info: '',
            result: {
                created_at: new Date(),
                customer_info: '',
                page: 1,
                last_page: 0
            },
            id: 0,
            sellers: [],
            model: [],
            telcom: [],
            typeChoose: [],
            valSellers: '',
            valTelcom: '',
            valModel: '',
            valType: '',

            isOpenAuthPopup: false,
            isOpenRegisterPopup: false,
            sortType: 'asc'
        };
    }

    componentWillUnmount() {
        document.body.classList.remove("body_wireless_advice");
    }

    componentDidMount() {  
        document.body.classList.add("body_wireless_advice");   
        let {
            user,
            isAllowAccessMenu,
        } = this.props.auth;
        if (window._prePath) {
            // đang f5 lại page đó
            // alert('f5')
            var preURL = `/${routerName.HISTORY_BUY_PRODUCT}`;
            window._prePath = '';
        } else {
            // navigate from route
            // alert('notf5');
            var preURL = this.props.location.state?.from; 
        }        

        let checkMatchURL = helper.checkMatchURL(preURL);
        let checkAllow = checkMatchURL && isAllowAccessMenu ? true : false;

        this.props.allowAccessMenu(checkAllow);
        if (!checkAllow) {
            this.props.openPopupMenu(true);
        }
        // debugger



        let dateCreated = this.state.startDate != '' ? moment(this.state.startDate).format('YYYY-MM-DD') : '';
        let data = {
            created_at: dateCreated,
            customer_info: '',
            page: 1,
            last_page: 0,
        }
        let dataAll={
            dataAll:1,
            created_at: dateCreated,
            customer_info: '',
        }
        // get telcom
        // this.props.getAdviceWireless().then(res => {
        //     this.setState({
        //         telcom: res,
        //     });
        //
        // }).catch(() => {
        //
        // });

        // get seller
        // this.getSellers();

        //get devide
        // api.consultant.getAllModel()
        //     .then(response => {
        //         this.setState({
        //             model: response.data.data,
        //         });
        //     })

        this.props.showLoading();
        api.consultant.getList(data)
            .then(response => {
                this.setState({
                    data: response.data.data,
                    last_page: response.data.data.last_page,

                });
                // this.props.hideLoading();
            })

        this.fetchMapData(dataAll)



    }

    fetchMapData = (dataAll) => {
        let sellers=[]
        let model=[]
        let telcom=[]
        let typeChoose=[]

        api.consultant.getList(dataAll)
            .then(response => {
                this.props.showLoading()
                if(response.data && response.data.data) {
                    (response.data.data || []).map((value => {
                        let existIDSeller=sellers.filter(item =>item.id == value.saler_id)
                        if(existIDSeller.length == 0) {
                            sellers.push({id:value.saler_id, name:value.saler_name})
                        }
                        let existIDModel=model.filter(item =>item.model_name == value.model_name)
                        if(existIDModel.length == 0) {
                            model.push({model_name:value.model_name})
                        }
                        let existIDTelcom=telcom.filter(item =>item.id == value.telcom_id)
                        if(existIDTelcom.length == 0) {
                            telcom.push({id:value.telcom_id, name:value.telcom_name})
                        }
                        let existIDType=typeChoose.filter(item =>item.id == value.type)
                        if(existIDType.length == 0) {
                            typeChoose.push({id:value.type, name:value.type_name})
                        }
                    }))

                    this.setState({
                        sellers: sellers,
                        model: model,
                        telcom: telcom,
                        typeChoose: typeChoose,
                    }, () => {

                    })
                }
                this.props.hideLoading()
            })
            .catch(() => {
                this.props.hideLoading()
            })
    }


    onToggleMainPopup = () => {
        this.props.openPopupMenu(false)
    }

    allowAccessMenu = () => {
        this.props.allowAccessMenu(true);
        this.setState({
            ...this.state,
            isOpenAuthPopup: false,
            isOpenRegisterPopup: false,
        })
    }



    renderTelcom(data) {
        let val = data ? data : []
        return (val.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.id}>{item.name}</option>
                </Fragment>
            );
        }));


    }

    renderModel(data) {
        let val = data ? data : []
        return (val.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.model_name}>{item.model_name}</option>
                </Fragment>
            );
        }));

    }

    renderTypeChoose(data) {
        let val = data ? data : []

        return (val.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.id}>{item.name}</option>
                </Fragment>
            );
        }));

    }

    renderSellers(data) {
        let val = data ? data : []
        return (val.map((item, key) => {
            return (
                <Fragment key={key}>
                    <option value={item.id}>{item.name}</option>
                </Fragment>
            );
        }));



        // return (val.map((item, key) => {
        //     return (
        //         <Fragment key={key}>
        //             <option value={item.uID}>{item.user.name}</option>
        //         </Fragment>
        //     );
        // }));
    }
    search = (current_page = 1, sortType='') => {
        this.props.showLoading();

        let dateCreated = this.state.startDate != '' ? moment(this.state.startDate).format('YYYY-MM-DD') : '';
        let data = {
            created_at: dateCreated,
            customer_info: this.state.customer_info,
            page: current_page,
            valSellers: this.state.valSellers,
            valModel: this.state.valModel,
            valTelcom: this.state.valTelcom,
            valType: this.state.valType,
            sortType: sortType,

        }
        let dataAll = {
            created_at: dateCreated,
            customer_info: '',
            dataAll:1,

        }
        api.consultant.getList(data)
            .then(response => {
                this.setState({
                    data: response.data.data,
                    last_page: response.data.data.last_page
                });
                this.props.hideLoading();
            })
            .catch(() => {
                this.props.hideLoading()
            })

        this.fetchMapData(dataAll)

    }

    delete() {
        api.consultant.delete({ id: this.state.id })
            .then(response => {
                this.search()

            })

    }
    getSellers() {
        // this.props.showLoading();
        api.auth.getSellers().then(res => {
            const sellers = res.data.data ? res.data.data : [];
            this.setState({
                sellers: sellers,
            }, () => {
                // this.props.hideLoading();
            })
        }).catch(err => {
            // this.props.hideLoading();
        });
    }
    activeType() {
        // api.consultant.updateType({ id: this.state.id })
        //     .then(response => {
        //         this.search()

        //     })
        this.props.openPopupMenu(true);
        this.props.history.push("/create-report-sale?id=" + this.state.id);
        // window.location.href = "/create-report-sale?id=" + this.state.id

    }

    edit(id) {
        // api.consultant.updateType({ id: this.state.id })
        //     .then(response => {
        //         this.search()

        //     })
        this.props.history.push("/create-report-sale?id=" + this.state.id);
        // window.location.href = "/create-report-sale?id=" + id;

    }
    renderConsultant() {
        // return
        let data = this.state.data && this.state.data.data ? this.state.data.data : [];
        if (data.length == 0) {
            return (
                <Fragment>
                    <tr >
                        <td className="align-middle text-center" colSpan="14">데이터가 없습니다!</td>

                    </tr>

                </Fragment>
            );
        }
        else
            return (data.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <tr >
                            <td className="align-middle text-center">{key+1}</td>
                            <td className="align-middle">{item.telcom_name}</td>
                            <td className="align-middle">{item.stl_name}</td>
                            <td className="align-middle">{item.type_name}</td>
                            <td className="align-middle model_name">{item.type == 1 ? item.model_name : item.type_packet_wired}</td>
                            {/* <td className="align-middle">{item.type == 1 ? item.seri : (item.model_name_wired_history_product)}</td>  */}
                            <td className="align-middle">{item.type == 1 ? item.seri : ""}</td> 
                            <td className="align-middle">
                                <span data-toggle="tooltip" data-placement="top" title data-original-title="T플랜 에센스에센스 ">{item.type == 1 ? item.plan_name : (item.plan_name_wired_history_product)}</span>
                            </td>
                            <td className="align-middle">{moment(item.created_at).format('YYYY-MM-DD')=='Invalid date'?'':moment(item.created_at).format('YYYY-MM-DD')}</td>
                            <td className="align-middle">{item.customer_name}    </td>
                            <td className="align-middle">{item.type_name == 'wireless' && item.open_code}</td>
                            <td className="align-middle">{item.saler_name}</td>
                            <td className="align-middle">
                                {
                                item.state != 1 ? <a href data-toggle={'modal'} onClick={() => this.setId(item.id)}
                                    className={"btn btn_processing w-100"} data-target="#edit_sales">{'판매처리'}
                                </a> : 
                                <Link className={"btn btn_Modify w-100"} to={"/create-report-sale?id=" + item.id} onClick={() => this.props.openPopupMenu(true)} >확인</Link>
                                }
                            
                                {/* <a href data-toggle={item.state != 1 ? "modal" : ''} onClick={item.state != 1 ? () => this.setId(item.id) : () => this.edit(item.id)}
                                    className={item.state != 1 ? "btn btn_processing w-100" : "btn btn_Modify w-100"} data-target="#edit_sales">{item.state != 1 ? '판매처리' : '수정'}
                                </a>  */}
                            </td>
                            <td className={item.state != 1 ? "text-danger" : "align-middle finished_appointment"}>
                                {item.state != 1 ? '입력대기' : '판매완료'}
                            </td>
                            <td className="align-middle">
                                <Tooltip message={"삭제"}
                                        position={'top'}
                                >
                                    <button className="btn p-0" type="button" onClick={() => this.setId(item.id)} data-toggle="modal" data-target="#modal-35">
                                        <img src="/images/delete-02.svg" alt="" />
                                    </button>
                                </Tooltip>
                                
                            </td>
                        </tr>

                    </Fragment>
                );
            }));

    }

    setId(id) {
        this.props.openPopupMenu(true);
        this.setState({
            id: id
        });
    }

    setTelCom(e) {
        this.setState({
            valTelcom: e.target.value
        },
            () => {
                this.search()
            }
        );
    }

    setModel(e) {
        this.setState({
            valModel: e.target.value
        },
            () => {
                this.search()
            }
        );
    }

    setSellers(e) {
        this.setState({
            valSellers: e.target.value
        },
            () => {
                this.search()
            });
    }

    setType(e) {
        this.setState({
            valType: e.target.value
        },
            () => {
                this.search()
            });
    }

    renderPaging() {
        // return

        let data = this.state.data && this.state.data.data ? this.state.data : '';
        if (data.data != '') {
            if (data.last_page - data.current_page > 3)
                if (data.current_page == 1)
                    return (
                        <Fragment >
                            <li className="page-item item-previous" >
                                <a className="page-link" onClick={() => data.current_page > 1 ? this.search(data.current_page - 1) : ''} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item active"><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 1)}>{data.current_page + 1}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 2)}>{data.current_page + 2}</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" >
                                <a className="page-link" onClick={() => this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
                else
                    return (
                        <Fragment >
                            <li className="page-item item-previous" >
                                <a className="page-link" onClick={() => data.current_page > 1 ? this.search(data.current_page - 1) : ''} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item "><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item" hidden={data.current_page == 2 ? true : false}><a className="page-link" href="#">...</a></li>
                            <li className="page-item active"><a className="page-link" onClick={() => this.search(data.current_page)}>{data.current_page}</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.current_page + 1)}>{data.current_page + 1}</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li className="page-item"><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" >
                                <a className="page-link" onClick={() => this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
            else
                if (data.last_page > 4)
                    return (
                        <Fragment >
                            <li className="page-item item-previous" hidden={data.last_page && data.last_page == 1 ? true : false}>
                                <a className="page-link" onClick={() => data.current_page == 1 ? '' : this.search(data.current_page - 1)} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li className="page-item "><a className="page-link" onClick={() => this.search(1)}>{1}</a></li>
                            <li className="page-item" hidden={data.current_page == 2 ? true : false}><a className="page-link" href="#">...</a></li>
                            <li hidden={data.last_page - data.current_page < 3 ? true : false} className={(data.last_page - 3) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 3)}>{data.last_page ? (data.last_page - 3) : ''}</a></li>
                            <li className={(data.last_page - 2) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 2)}>{data.last_page ? (data.last_page - 2) : ''}</a></li>

                            <li hidden={data.last_page - data.current_page > 2 ? true : false} className={(data.last_page - 1) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 1)}>{data.last_page ? (data.last_page - 1) : ''}</a></li>
                            <li className="page-item" hidden={data.last_page - data.current_page < 3 ? true : false} ><a className="page-link" href="#">...</a></li>
                            <li hidden={data.last_page == 1 ? true : false} className={(data.last_page - 0) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" hidden={data.last_page && data.last_page == 1 ? true : false} >
                                <a className="page-link" onClick={() => data.last_page == data.current_page ? '' : this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
                else
                    return (
                        <Fragment >
                            <li className="page-item item-previous" hidden={data.last_page && data.last_page == 1 ? true : false}>
                                <a className="page-link" onClick={() => data.current_page == 1 ? '' : this.search(data.current_page - 1)} aria-label="Previous">
                                    <span aria-hidden="true">
                                        <img src="/images/Left.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li hidden={data.last_page < 4 ? true : false} className={(data.last_page - 3) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 3)}>{data.last_page ? (data.last_page - 3) : ''}</a></li>
                            <li hidden={data.last_page < 3 ? true : false} className={(data.last_page - 2) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 2)}>{data.last_page ? (data.last_page - 2) : ''}</a></li>
                            <li hidden={data.last_page < 2 ? true : false} className={(data.last_page - 1) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page - 1)}>{data.last_page ? (data.last_page - 1) : ''}</a></li>
                            <li hidden={data.last_page == 1 ? true : false} className={(data.last_page - 0) == data.current_page ? "page-item active" : "page-item"}><a className="page-link" onClick={() => this.search(data.last_page)}>{data.last_page}</a></li>
                            <li className="page-item item-next" hidden={data.last_page && data.last_page == 1 ? true : false} >
                                <a className="page-link" onClick={() => data.last_page == data.current_page ? '' : this.search(data.current_page + 1)} aria-label="Next">
                                    <span aria-hidden="true">
                                        <img src="/images//Right.svg" alt="" />
                                    </span>
                                </a>
                            </li>
                        </Fragment>
                    );
        }



    }
    handleChange = date => {
        this.setState({
            startDate: date
        }, () => {
            this.search()
        });
    };


    openDatepicker = () => {
        this._calendar.setOpen(true)
        // this.setState({
        //     isOpen: !this.state.isOpen
        // }, () => {
        //     this._calendar.setOpen(this.state.isOpen)

        // })
    }

    nextMonth = () => {
        // this._calendar.setOpen(true)
        var moment = require('moment');
        let startdate = moment(this.state.startDate);
        // alert(this.state.startDate );
        // this.state.startDate = moment().add(1, 'M')._d;
        // this.state.startDate = moment(startdate).add(1, 'M')._d;
        this.setState({
            startDate: moment(startdate).add(1, 'M')._d
        }, () => {
            this.search();

        })
    }

    prevMonth = () => {
        // this._calendar.setOpen(true)
        var moment = require('moment');
        let startdate = moment(this.state.startDate);
        // alert(this.state.startDate );
        // this.state.startDate = moment().add(1, 'M')._d;
        // this.state.startDate = moment(startdate).add(1, 'M')._d;
        this.setState({
            startDate: moment(startdate).subtract(1, 'M')._d
        }, () => {
            this.search();

        })
    }


    handleChangeValue(e) {
        this.setState({ customer_info: e.target.value });
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    coverLanguage(str) {
        switch (str) {
            case  "January":
                str = "1월"
                break;
            case  "February":
                str = "2월"
                break;
            case  "March":
                str = "3월"
                break;
            case  "April":
                str = "4월"
                break;
            case "May":
                str = "5월"
                break;
            case  "June":
                str = "6월"
                break;
            case  "July":
                str = "7월"
                break;
            case  "August":
                str = "8월"
                break;
            case  "September":
                str = "9월"
                break;
            case  "October":
                str = "10월"
                break;
            case  "November":
                str = "11월"
                break;
            case  "December":
                str = "12월"
                break;
        }
        return this.state.startDate.getFullYear()+' 년   '+ str + '  '+ this.state.startDate.getDate()+' 일'    
    }

    sortData = () => {
        let {sortType} = this.state;
        this.search(this.state.result.page, sortType);
        this.setState({
            ...this.state,
            sortType: sortType == 'asc' ? 'desc' : 'asc' 
        })
    }


    render() {
        const {
            user,
            isOpenPopupMenu,
            isAllowAccessMenu,
        } = this.props.auth;


        if (this.props.auth.isAllowAccessMenu) {
            const {
                price,
                dataSource,
            } = this.state;
            const ExampleCustomInput = () => (

                <input class="btn btn-drop-date dropdown-toggle" placeholder={this.coverLanguage(moment(this.state.startDate).format('MMMM'))} readOnly onClick={this.openDatepicker} />
            );

            let data = this.state.telcom ? this.state.telcom : {}
            let arrChil = data.telcomChildren ? data.telcomChildren : []
            let arrParent = data.telcomParents ? data.telcomParents : []
            let arr = arrChil.concat(arrParent)
            return (

                <Fragment>
                    <section>
                        <div className="container">
                            <div className="sales_report">
                                <div className="fill_search">

                                    <form action>
                                        <div className="row d-flex justify-content-between align-items-center mb-4">
                                            <div className="col-md-4 d-flex align-items-center">
                                                <a href onClick={this.prevMonth} className="previous d-flex align-items-center justify-content-center"><i className="fas fa-angle-left" /></a>
                                                <div className="dropdown mx-2 form-date">
                                                    <DatePicker
                                                        locale="ko"
                                                        ref={(c) => this._calendar = c}
                                                        className="btn btn-drop-date dropdown-toggle"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChange}
                                                        value={this.state.startDate}
                                                        dateFormat="MMMM d, yyyy"
                                                        customInput={<ExampleCustomInput />}
                                                    />
                                                    <img onClick={this.openDatepicker} src="/images/icon-calender.svg" alt="" />
                                                </div>
                                                <a href onClick={this.nextMonth} className="next d-flex align-items-center justify-content-center "><i className="fas fa-angle-right" /></a>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <input onKeyDown={this.handleKeyDown.bind(this)} onChange={this.handleChangeValue.bind(this)} className="form-control search_box rounded-0 bg-transparent pb-0 h-44 pr-5" type="search" placeholder="고객/ 단말기 모델명/ 단말기일련번호" />
                                                    <span onClick={this.search.bind()} style={{ zIndex: 9999, cursor: 'pointer' }} className="input-group-append h-44">
                                                        <div className="input-group-text border-0 bg-transparent ml-n5"><i className="fa fa-search" /></div>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row d-flex justify-content-end align-items-center">
                                            <div className="col-2">
                                                <select onChange={this.setSellers.bind(this)} className="form-control h-44">
                                                    <option value=''>판매자</option>
                                                    {this.renderSellers(this.state.sellers)}
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <select onChange={this.setModel.bind(this)} className="form-control h-44">
                                                    <option value=''>단말기 전체 </option>
                                                    {this.renderModel(this.state.model)}
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <select onChange={this.setTelCom.bind(this)} className="form-control h-44">
                                                    <option value="">통신사 전체 </option>
                                                    {/*{this.renderTelcom(arr)}*/}
                                                    {this.renderTelcom(this.state.telcom)}
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <select onChange={this.setType.bind(this)} className="form-control h-44">
                                                    <option value="">유형</option>
                                                    {this.renderTypeChoose(this.state.typeChoose)}
                                                    {/*<option value="1">wireless</option>*/}
                                                    {/*<option value="2">wired</option>*/}
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-responsive sales_report_table mt-4">
                                    <table className="table table-borderless table_striped">
                                        <thead className="thead_bg">
                                            <tr>
                                                <th className="text-center"><div>NO</div></th>
                                                <th><div>통신사</div></th>
                                                <th><div>거래처명</div></th>
                                                <th><div>유형</div></th>
                                                <th><div>모델명</div></th>
                                                <th><div>일련번호</div></th>
                                                <th><div>요금제</div> </th>
                                                <th><div>개통일</div></th>
                                                <th><div>고객명</div></th>
                                                <th><div>개통번호</div></th>
                                                <th><div>판매자</div></th>
                                                <th className="text-center" onClick={() => this.sortData()}>
                                                    <div>
                                                        처리
                                                        {/* <button class="btn btn-sort sort-active p-0"> */}
                                                        <button className="btn btn-sort p-0">
                                                            <i className="fas fa-sort"></i>
                                                        </button>
                                                    </div>
                                                </th>
                                                <th className="text-center" onClick={() => this.sortData()}>
                                                    <div>
                                                        상태
                                                        <button className="btn btn-sort p-0">
                                                            <i className="fas fa-sort"></i>
                                                        </button>
                                                    </div>
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderConsultant()}
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example" className="pagination-notifi">
                                    <ul className="pagination">

                                        {this.renderPaging()}

                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </section>

                    {/* model delete */}
                    <div className="modal fade" id="modal-35" tabindex="-1" role="dialog" aria-labelledby="delete_coupon" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body text-center p-5">
                                    <span className="notification_delete">삭제하시겠습니까?</span>
                                </div>
                                <div className="modal-footer justify-content-center border-top-0">
                                    <button type="button" className="btn btn_close" data-dismiss="modal">취소</button>
                                    <button type="button" className="btn btn-submit pr-5 pl-5" onClick={() => this.delete()} data-dismiss="modal">확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* model xu ly */}
                    <div className="modal fade" id="edit_sales" tabindex="-1" role="dialog" aria-labelledby="delete_coupon" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body text-center p-5">
                                    <span className="notification_delete">등록하시겠습니까?</span>
                                </div>
                                <div className="modal-footer justify-content-center border-top-0">
                                    <button type="button" className="btn btn_close" data-dismiss="modal">취소</button>
                                    {/* <Link className="btn btn-submit pr-5 pl-5" to={"/create-report-sale?id=" + this.state.id}>확인</Link> */}
                                    <button type="button" className="btn btn-submit pr-5 pl-5" onClick={() => this.activeType()} data-dismiss="modal">확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            );

        } else {
            return (
                <Fragment>
                    <RegisterAccessPopup
                        // isOpen = {this.state.isOpenRegisterPopup}
                        isOpen={isOpenPopupMenu && !isAllowAccessMenu && !user.password_menu}
                        toggle={this.onToggleMainPopup}
                        allowAccessMenu={this.allowAccessMenu}
                    />

                    <AuthAccessPopup
                        // isOpen = {this.state.isOpenAuthPopup}
                        isOpen={isOpenPopupMenu && !isAllowAccessMenu && user.password_menu}
                        toggle={this.onToggleMainPopup}
                        allowAccessMenu={this.allowAccessMenu}
                    />
                    {/* user.password_menu, isOpenPopupMenu, isAllowAccessMenu, this.props.auth,  'main_render' */}

                </Fragment>
            )
        }


    }
}




HisoryBuyProductComponent.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getAdviceWireless: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        // formValues: getFormValues('create-seller')(state),
        // formErrors: getFormSyncErrors('create-seller')(state),
        // metaForm: getFormMeta('create-seller')(state),
        auth: state.auth,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        // showModal: (data) => dispatch(showModal(data)),
        // hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        // getAdviceWireless: () => dispatch(getAdviceWireless()),
        allowAccessMenu: (status) => dispatch(allowAccessMenu(status)),
        openPopupMenu: (status) => dispatch(openPopupMenu(status)),
    };
};
const initialValues = {
    'name': '',
    'username': '',
    'password': '',
    'repassword': '',
    'dial_number': '010',
    'phone': "",
    'code': "",
}

const withReduxForm = reduxForm({
    form: "create-seller",
    initialValues: initialValues,
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,

}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(HisoryBuyProductComponent);
