import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as helper from '../../utils/helper';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, formValueSelector, change, SubmissionError, getFormAsyncErrors, reset } from 'redux-form';
import renderSelectField from '../FormHelper/SelectField';
import renderTextField from '../FormHelper/TextField';
import renderRadioField from '../FormHelper/RadioField';
import rendercustomRadio from '../FormHelper/RadioField/customRadio';
import renderTextFields from '../FormHelper/TextFieldArray';
import renderCheckboxField from '../FormHelper/CheckboxField';
import api from "./../../utils/api";
import ModalListMember from "./ModalListMember";
import ModalARS from "./ModalARS";
import ModalCall from "./ModalCall";
import ModalResult from "./ModalResult";
import { showLoading, hideLoading, showSidebar, hideSidebar } from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import validate from './validate-cms';

const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4)
    }
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
}

class PaymentHyosungCMSMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isDisableForm: false,
            isShowModalARS: false,
            isShowModalCall: false,
            isShowModalResult: false,
            resultStatus: true,
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps?.PaymentHyosungCMS?.paymentKind && this.props.PaymentHyosungCMS?.paymentKind && prevProps?.PaymentHyosungCMS?.paymentKind != this.props.PaymentHyosungCMS?.paymentKind) {
    //         this.props.reset()
    //         this.props.change('paymentKind', this.props.PaymentHyosungCMS.paymentKind)
    //         this.setState({
    //             isDisableForm: false
    //         })
    //     }
    // }

    resetForm = (value) => {
        this.props.reset()
        this.props.change('paymentKind', value)
        this.setState({
            isDisableForm: false
        })
    }

    openPopupopenPopup = () =>
        this.setState({ isOpenModal: !this.state.isOpenModal })

    openModalARS = () =>
        this.setState({ isShowModalARS: !this.state.isShowModalARS })

    openModalCall = () =>
        this.setState({ isShowModalCall: !this.state.isShowModalCall })

    openModalResult = () =>
        this.setState({ isShowModalResult: !this.state.isShowModalResult })

    selectItem = (item) => {
        if (item) {
            this.props.showLoading();
            api.payment.getMember(item)
                .then(response => {
                    this.props.hideLoading()
                    let memberResponse = response.data.data.member
                    let phone = memberResponse.phone.slice(3)
                    this.props.change('memberId', memberResponse.memberId)
                    this.props.change('firstPhone', memberResponse.phone.slice(0, 2))
                    this.props.change('phone', phone.slice(0, 4) + '-' + phone.slice(4, 8))
                    this.props.change('email', memberResponse.email)
                    this.props.change('paymentNumber', memberResponse.paymentNumber)
                    this.props.change('payerName', memberResponse.payerName)
                    this.props.change('payerNumber', memberResponse.payer_number)
                    this.props.change('validYear', memberResponse.valid_year)
                    this.props.change('validMonth', memberResponse.valid_month)
                    this.props.change('paymentKind', memberResponse.paymentKind == '카드결제' ? 'CARD' : 'CMS')
                    if (memberResponse.paymentKind !== '카드결제') {
                        this.props.change('paymentCompany', memberResponse.paymentCompany)
                    }
                    this.setState({
                        isDisableForm: true
                    })
                })
                .catch(() => {
                    this.props.hideLoading()
                })
        }
    }
    

    registerMemberPayment = () => {
        const { PaymentHyosungCMS } = this.props
        if (PaymentHyosungCMS.memberId) {
            this.popupShowRegisterUserUniqueCMS()
            return
        }
        let params = {
            ...PaymentHyosungCMS,
            paymentKind: PaymentHyosungCMS.paymentKind == 'CARD' ? 'CARD' : (this.props.paymentMethod == 1 ? 'REALCMS' : "CMS")
        }
        return api.payment.registerMemberPayment(params)
            .then((response) => {
                this.props.change('memberId', response.data.data.member.memberId)
                if (params.paymentKind == 'CMS') {
                    this.setState({
                        isShowModalARS: true
                    })
                } else {
                    this.setState({
                        isDisableForm: true
                    })

                    // if (this.props.paymentMethod == 1) {
                        this.popupShowRegisterUser1SuccessCMS()
                    // } else {
                    //     this.popupShowRegisterUser0SuccessCMS()
                    // }
                }
            })
            .catch(e => {
                let data = null;

                try{
                    if(typeof e.response.data == 'string'){
                        data = "(" + e.response.data + ")";
                    }
                    else{
                        data = "(" + Object.values(e.response.data)[0] + ")";
                    }
                }
                catch(e){
                    data = "";
                }
                
                this.popupShowRegisterUserFailCMS(data);

                if (e.response.status === 422) {
                    throw new SubmissionError(e.response.data)
                }
            })
    }

    submitARS = () => {
        this.setState({
            isShowModalARS: false,
            isShowModalCall: true,
        })
        let data = {
            memberId: this.props.PaymentHyosungCMS.memberId,
            phone: this.props.PaymentHyosungCMS.firstPhone + this.props.PaymentHyosungCMS.phone.split('-').join(''),
            callMode: "Outbound",
            paymentKind: this.props.PaymentHyosungCMS.paymentKind,
            paymentCompany: this.props.PaymentHyosungCMS.paymentCompany,
            paymentNumber: this.props.PaymentHyosungCMS.paymentNumber,
            payerName: this.props.PaymentHyosungCMS.payerName,
            payerNumber: this.props.PaymentHyosungCMS.payerNumber
        }
        api.payment.ARS(data)
    }

    submitEndCall = () => {
        api.payment.getMember(this.props.PaymentHyosungCMS.memberId)
            .then(() => {
                this.setState({
                    isShowModalCall: false,
                    isShowModalResult: true,
                    resultStatus: true
                })
            })
            .catch(() => {
                this.setState({
                    isShowModalCall: false,
                    isShowModalResult: true,
                    resultStatus: false
                })
            })
    }

    submitResult = () => {
        this.setState({
            isShowModalResult: false
        })
        if (this.setState.resultStatus) {
            // if (this.props.paymentMethod == 1) {
            //     this.popupShowRegisterUser1SuccessCMS()
            // } else {
                this.popupShowRegisterUser1SuccessCMS()
            // }
            this.setState({
                isDisableForm: true
            })
        } else {
            api.payment.removeMember(this.props.PaymentHyosungCMS.memberId)
            this.props.reset()
            this.props.change('paymentKind', 'CMS')
            this.setState({
                isDisableForm: false
            })
        }
    }

    popupShowRegisterUser0SuccessCMS = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    정기결제 등록이 완료되었습니다.
                </h4>
                <p className="text-center">
                    모비셀 사용요금은 선불이며, <span className="color-orange">매월 말일</span> 등록하신 정보로 정기결제가 진행됩니다.
                </p>
                <p className="text-center">
                    결제가 이루어지지 않은 경우 정기결제가 해지되며, 프로그램 사용이 정지됩니다.
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal_payment_notifi',
        }
        this.props.showModal(dataModal);
    }

    popupShowRegisterUser1SuccessCMS = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    결제수단이 등록되었습니다
                </h4>
                <p className="text-center">
                    결제하기 버튼을 클릭해주세요.
                    <br/>
                    등록하신 결제수단으로 결제가 진행됩니다.
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml,
            class_n:'modal_payment_notifi',
        }
        this.props.showModal(dataModal);
    }

    popupShowRegisterUserFailCMS = (data) => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    효성 CMS 등록이 실패했습니다.
                </h4>
                <p className="text-center">
                    효성 CMS 등록이 실패했습니다. 다시 확인해 주세요!
                    <br/>
                    {data}
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal() }>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);
    }   

    popupShowRegisterUserUniqueCMS = () => {
        let xhtml = (
            <div className="content-register-modal">
                <h4 className="text-center mb-5">
                    효성CMS 계좌 등록이 실패했습니다.
                </h4>
                <p className="text-center">
                    해당 효성CMS 계좌는 이미 사용되어 있습니다. 신규 등록하거나 계속 사용해 주세요!
                </p>
                <button type="button" className="btn btn-gray  btn-ok " onClick={() => this.props.hideModal()} >확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml
        }
        this.props.showModal(dataModal);
    }   

    render() {
        return (
            <Fragment>
                <form onSubmit={this.props.handleSubmit(this.registerMemberPayment)}>
                    <div className="payment-information">
                        <div className="row form-group align-items-end">
                            <div className="col-6">
                                <div className="form-group row">
                                    <label className="label-text mb-0 col-sm-4">결제방식</label>
                                    <div className="custom-control custom-radio col-sm-4">
                                        <Field
                                            name="paymentKind"
                                            id="payment_state_card"
                                            component={rendercustomRadio}
                                            checked={this.props.PaymentHyosungCMS?.paymentKind || 0}
                                            type="radio"
                                            className="custom-control-input"
                                            value="CARD"
                                            disabled={this.state.isDisableForm}
                                            onChanged={this.resetForm}
                                        />
                                        <label
                                            className="custom-control-label text-extra"
                                            htmlFor="payment_state_card"
                                        >
                                            카드
                                        </label>
                                    </div>
                                    {/*
                                    <div className="custom-control custom-radio col-sm-4">
                                        <Field
                                            name="paymentKind"
                                            id="payment_state_cms"
                                            component={rendercustomRadio}
                                            checked={this.props.PaymentHyosungCMS?.paymentKind || 0}
                                            type="radio"
                                            className="custom-control-input"
                                            value="CMS"
                                            disabled={this.state.isDisableForm}
                                            onChanged={this.resetForm}
                                        />
                                        <label
                                            className="custom-control-label text-extra"
                                            htmlFor="payment_state_cms"
                                        >
                                            계좌이체
                                        </label>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-5">
                                    <label className="label-text">결제금액</label>
                                    <input
                                        className='form-control  h-44'
                                        placeholder="결제금액"
                                        type="text"
                                        value={helper.priceFormat(this.props.price)}
                                        disabled={'disabled'}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-5">
                                    <label className="label-text">이름</label>
                                    <Field
                                        placeholder="이름"
                                        name="payerName"
                                        type="text"
                                        component={renderTextField}
                                        disabled={this.state.isDisableForm}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group row mb-5">
                                    <div className="col-6 pr-1">
                                        <label className="label-text">
                                            {this.props.PaymentHyosungCMS?.paymentKind == 'CARD' ? "신용카드번호" : '출금 계좌번호'}
                                        </label>
                                        <Field
                                            placeholder={this.props.PaymentHyosungCMS?.paymentKind == 'CARD' ? "신용카드번호" : '출금 계좌번호'}
                                            name="paymentNumber"
                                            type="text"
                                            component={renderTextField}
                                            disabled={this.state.isDisableForm}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <label className="label-text">생년월일 또는 사업자번호</label>
                                        <div className="form-date">
                                            <Field
                                                placeholder="생년월일/사업자번호"
                                                name="payerNumber"
                                                type="text"
                                                component={renderTextField}
                                                disabled={this.state.isDisableForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="label-text">이메일</label>
                                    <Field
                                        placeholder="이메일"
                                        name="email"
                                        type="text"
                                        component={renderTextField}
                                        disabled={this.state.isDisableForm}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.props.PaymentHyosungCMS?.paymentKind == 'CMS' && (
                                <div className="col-6">
                                    <label className="label-text">은행 선택</label>
                                    <div className="dropdown">
                                        <Field
                                            placeholder="은행 선택"
                                            name="paymentCompany" // tên công ty thẻ
                                            component={renderSelectField}
                                            disabled={this.state.isDisableForm}>
                                            <option value={''}>
                                                은행 선택
                                            </option>
                                            {helper.getPaymentCompany().map((item, i) => (
                                                <option value={item.code} key={i}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                            )}
                            {this.props.PaymentHyosungCMS?.paymentKind == 'CARD' && (
                                <div className="col-6">
                                    <label className="label-text">유효기간</label>
                                    <div className="form-group row mb-5">
                                        <div className="col-7 pr-1">
                                            <div className="dropdown">
                                                <Field
                                                    placeholder="tháng"
                                                    name="validMonth" // tên công ty thẻ
                                                    component={renderSelectField}
                                                    disabled={this.state.isDisableForm}>
                                                    {helper.getMonthCardPayment().map((item, i) =>
                                                        <option value={item} key={i}>{`${item}월`}</option>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-5 pl-1">
                                            <Field
                                                placeholder="년"
                                                name="validYear" // tên công ty thẻ
                                                component={renderSelectField}
                                                disabled={this.state.isDisableForm}>
                                                {helper.getYearCardPayment().map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-6">
                                <label className="label-text">전화번호</label>
                                <div className="form-group row mb-5">
                                    <div className="col-md-5 col-4 pr-1">
                                        <Field
                                            name="firstPhone"
                                            component={renderSelectField}
                                            disabled={this.state.isDisableForm}>
                                            {helper.getFirstNumberPhone().map((item) =>
                                                <option key={item} value={item}>{item}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-md-7 col-8 pl-1">
                                        <Field
                                            placeholder="전화번호"
                                            name="phone"
                                            type="text"
                                            component={renderTextField}
                                            normalize={normalizePhone}
                                            onChange={this.props.onChangeVerifyPhoneClearCountDown}
                                            disabled={this.state.isDisableForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button-information">
                            <button
                                type="button"
                                className="btn"
                                onClick={this.props.handleSubmit(values =>
                                    this.registerMemberPayment({
                                        ...values
                                    })
                                )}
                             >
                                신규 등록
                            </button>
                            <button
                                type="button"
                                className="btn btn-yellow"
                                onClick={this.openPopupopenPopup}>
                                기존 결제정보
                            </button>
                        </div>
                    </div>
                </form>
                <ModalListMember
                    openPopup={this.openPopupopenPopup}
                    isOpenModal={this.state.isOpenModal}
                    selectItem={this.selectItem}
                    paymentMethod={this.props.paymentMethod}
                />
                <ModalARS
                    openPopup={this.openModalARS}
                    isOpenModal={this.state.isShowModalARS}
                    submit={this.submitARS}
                />
                <ModalCall
                    openPopup={this.openModalCall}
                    isOpenModal={this.state.isShowModalCall}
                    submit={this.submitEndCall}
                />
                <ModalResult
                    openPopup={this.openModalResult}
                    isOpenModal={this.state.isShowModalResult}
                    status={this.state.resultStatus}
                    submit={this.submitResult}
                    memberId={this.props.PaymentHyosungCMS?.memberId}
                />
            </Fragment>
        )
    }
}

PaymentHyosungCMSMember.propTypes = {
    paymentState: PropTypes.number,
    onChangeVerifyPhoneClearCountDown: PropTypes.func,
    PaymentHyosungCMS: PropTypes.object,
    price: PropTypes.number,
    handleSubmit: PropTypes.func,
    change: PropTypes.func,
    reset: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
}

const FORM_NAME = 'PaymentHyosungCMS';

const mapStateToProps = state => {
    return {
        // initialValues: initValues,
        // priceGroups: state.dataStore.priceGroups,
        // pricePackages: state.dataStore.pricePackages,
        // auth: state.auth,
        PaymentHyosungCMS: getFormValues(FORM_NAME)(state),
        // PaymentHyosungCMSErrors: getFormSyncErrors(FORM_NAME)(state),
        // priceExtraService: state.dataStore.priceExtraService,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        change: () => dispatch(change()),
        reset: () => dispatch(reset('PaymentHyosungCMS')),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    };
};

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues: {
        "memberId": "",
        // "memberName": "SOFTONE",
        // "smsFlag": "N",
        "firstPhone": "010",
        "phone": "",
        "email": "",
        // "receiptNumber": "",
        // "memberKind": "000",
        // "managerId": "",
        "paymentKind": "CARD",
        "paymentNumber": "",
        "payerName": "",
        "payerNumber": "",
        "validYear": helper.getYearCardPayment()[0],
        "validMonth": helper.getMonthCardPayment()[0],
        "paymentCompany": "",
        // "password": "11"
    },
    // validate,
    enableReinitialize: true,
}, mapStateToProps);


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(PaymentHyosungCMSMember);
