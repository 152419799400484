
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, change} from 'redux-form';
import { Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import api from "../../utils/api";
import ConfirmDeleteAllPopup from './../Print/ConfirmDeleteAllPopup';
import GuidePrintIEPopup from './../Print/GuidePrintIEPopup';
import { numberMathFloor, priceFormat } from "../../utils/helper";
import { isThisSecond } from 'date-fns';

class AppPrintListPopup extends Component {

  constructor(props) {
    super(props);
      this.state = {
        isOpenConfirmAll: false,
        isOpenGuide: false,
    }
  }

  componentDidMount() {
  }

  onToggleGuide = () => {
    this.setState({
        isOpenGuide: !this.state.isOpenGuide,
    })
  }

  onToggleConfirmAll = () => {
    this.setState({
        isOpenConfirmAll: !this.state.isOpenConfirmAll,
    })
  }

  showGuidePrint = () => {
    this.onToggleGuide();
  }

  showConfirmDeleteAll = () => {
    this.setState({
      ...this.state,
      isOpenConfirmAll: true,
    }, () => {
    })
  }

  deletePrintAll = () => {
    this.onToggleConfirmAll();
 
    api.appPrint.deleteAll().then(res => {
      if (res?.data?.data) {
        this.props.reUpdatePrintList();
      }
    })  
  }

  onOpened = () => {
    // debugger
  }

  render() {
    const { printList } = this.props;

    return (
      <Fragment>
        <Modal isOpen={this.props.isOpen}
          className="modal-dialog modal-dialog-centered modal-detail-print"
          contentClassName=""
          // backdrop={"static"}
          toggle={this.props.toggle}
          onOpened = {this.onOpened}

        >
          <div className="modal-header border-0">
            <h5 className="modal-title">앱 견적 출력</h5>
            <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
              <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body p-0">
            {/* <div className="flex-print">
              <a className="left-print" onClick={this.showGuidePrint}>
                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icoin-print02.svg"} alt="" />
                   <span>출력옵션 설정안내</span>
              </a>
              <div className="right-print">
                <p className="color-orange">
                  <img src="/images/icoin-print03.svg" alt="" />
                  출력 전 ‘출력옵션 설정안내'를 확인해 주세요
                </p>
                <p className="mb-0">
                  사용하시는 브라우져에 따라 인쇄옵션 설정상태나 팝업 해제 여부를 반드시 확인해 주세요.
                </p>
                <p>
                  설정상태가 다를 경우, 서식지 양식이 정상적으로 출력되지 않아 개통 시 미비가 발생할 수 있으며,
                  <br/>
                  출력 기능이 정상적으로 동작하지 않을 수 있습니다다.
                </p>
                <div className="button-delete-print">
                  <button 
                    className="btn"
                    type="button"
                    onClick={()=> this.showConfirmDeleteAll()}
                    disabled={ printList?.length > 0 ? false : true}
                  >
                    <img src="/images/delete-black.svg" alt="" />
                    전체
                  </button>
                </div>
              </div>
            </div> */}
            <div className="d-flex justify-content-between">
              <p className="m-0">
                모비셀 앱에서 전송된 서식지 또는 견적서 인쇄내역이 표시됩니다.<br/>
                전송된 내역은 하루동안만 유지되며, 다음날 자동으로 삭제되니 참고해 주세요.
              </p>
              <div className="button-delete-print mt-0">
                <button 
                  className="btn"
                  type="button"
                  onClick={()=> this.showConfirmDeleteAll()}
                  disabled={ printList?.length > 0 ? false : true}
                >
                  <img src="/images/delete-black.svg" alt="" />
                  전체
                </button>
              </div>
            </div>
            <div className="list-print">
              <table className="table">
                <tbody>
                  {this.props.children}    
                </tbody>
              </table>
            </div>
          </div>
        </Modal>


        <ConfirmDeleteAllPopup
            isOpen = {this.state.isOpenConfirmAll}
            toggle = {this.onToggleConfirmAll}
            deletePrintAll = {this.deletePrintAll}
        />

        <GuidePrintIEPopup
            isOpen = {this.state.isOpenGuide}
            toggle = {this.onToggleGuide}
        />

      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // showLoading: () => dispatch(showLoading()),
    // hideLoading: () => dispatch(hideLoading()),
    // change: () => () => dispatch(change())
  }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(AppPrintListPopup);

