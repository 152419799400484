import api from "../../../utils/api";
import MessageJSON from '../../../utils/message';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values, dispatch, props, field) => {
    const previousErrors = props.asyncErrors;
    let phone = values.first_store_phone + '-' + values.last_store_phone;
    if((values.first_store_phone || values.last_store_phone) && phone.length == 13){
        let dataPhone = {
            phone: values.first_store_phone + '-' + values.last_store_phone,
            email: values.email
        }
        // return api.auth.checkUniquePhone(dataPhone).then(response => {
        //     if(response.data.status == 0) {
        //         return sleep(1).then(() => {
        //             throw {
        //                 ...previousErrors, 
        //                 ['last_store_phone']: MessageJSON.signup.validate.verify_phone_number_check_format
        //             }
        //             // throw Object.assign({}, previousErrors, {['last_store_phone']: MessageJSON.signup.validate.verify_phone_number_check_format});
        //         })
        //     }
        // })
        let dataPassword = {
            oldpassword: values.oldpassword,
            id: JSON.parse(localStorage.getItem('user')).id
        }
        return api.auth.checkSamePassword(dataPassword).then(response => {
            if(response.data.status == 0) {
                return sleep(0.1).then(() => {
                    throw {
                        ...previousErrors, 
                        ['oldpassword']: MessageJSON.edit_profile.validate.oldpassword_not_same
                    }
                    // throw Object.assign({}, previousErrors, {['last_store_phone']: MessageJSON.signup.validate.verify_phone_number_check_format});
                })
            }
        })
    } 
    return Promise.resolve();
}

export default asyncValidate