import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';
import WiredStep1 from './WiredAdviceComponent/WiredStep1';
import WiredStep2 from './WiredAdviceComponent/WiredStep2';
import WiredAdviceStepHeader from './WiredAdviceComponent/WiredAdviceStepHeader'
import {INSTALLMENT_PAYMENT_METHOD, CONSULTANT_WIRED} from "../../utils/constant";
import {caclWiredAdvice} from "../../utils/helper";
import {hideLoading, showLoading} from "../../store/actions/ui";
import {hideModal, showModal} from "../../store/actions/modal";
import {getAdviceWireless} from "../../store/actions/dataStore";
import api from "../../utils/api";
import _ from 'lodash'

class WiredAdvice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 2,
            count: 0,
            finishInit: false
        }

        //this.saveDataDebounce = _.debounce(this.saveData, 500)
        this.saveDataDebounce = _.debounce(this.saveData, 500)
        this.getWiredDiscountDebounce = _.debounce(this.getWiredDiscount, 50)
    }

    componentDidMount() {
        const {history} = this.props
        this.props.showLoading();
        this.props.getAdviceWireless().then(() => {
            this.props.hideLoading();
        }).catch(() => {
            this.props.hideLoading();
        });

        if (
            history.location.state
            && history.location.state.WiredAdviceStep1
            && history.location.state.WiredAdviceStep1.telcomID
            && history.location.state.WiredAdviceStep1.successString
        ) {
            setTimeout(() => {
                let state = {...history.location.state};
                this.props.change('id', state.id)
                this.props.change('step', 2)
                window.history.pushState('', '', '/wired-advice?step=' + 2)
                this.props.change('WiredAdviceStep1', state.WiredAdviceStep1)
                this.props.change('WiredAdviceStep2', state.WiredAdviceStep2)

                delete state.WiredAdviceStep2;
                delete state.WiredAdviceStep1;

                history.replace({...history.location, state});
            }, 100)
        }

        this.setState({
            finishInit: true
        })

        this.hashHistoryListener = history.listen((newLocation, action) => {
            if (this.props.WiredAdvice.step == 2) {
                if (action === "POP") {
                    this.props.change('step', 1)
                    window.history.pushState('', '', '/wired-advice?step=' + 1)
                }
            } else if (this.props.WiredAdvice.step == 1) {
                if (action === "POP") {
                    this.props.history.goBack()
                }
            }
        });
    }

    componentWillUnmount() {
        this.hashHistoryListener();
    }

    componentDidUpdate(prevProps) {
        const {WiredAdvice} = this.props

        if (prevProps.WiredAdvice?.step == 1 && WiredAdvice?.step == 2) {
            let WiredAdviceStep2 = caclWiredAdvice(WiredAdvice.WiredAdviceStep2)
            this.props.change('WiredAdviceStep2', WiredAdviceStep2);

            // // save data
            setTimeout(() => {
                this.saveDataDebounce()
            }, 50)
        }

        setTimeout(() => {
            if (WiredAdvice && prevProps.WiredAdvice) {
                const {WiredAdvice: {WiredAdviceStep2}} = this.props
                let keysToCheckDiff = [
                    // 'classifyString',
                    // 'classify',
                    'combineCondition',
                ]
                var checkWiredAdviceStep2OldObj = _.pick(prevProps.WiredAdvice.WiredAdviceStep2, ...keysToCheckDiff)
                var checkWiredAdviceStep2NewObj = _.pick(WiredAdviceStep2, ...keysToCheckDiff)
                var checkEqual = _.isEqual(checkWiredAdviceStep2OldObj, checkWiredAdviceStep2NewObj)
                if (!checkEqual) {
                    // this.checkToUpdateDiscount(prevProps)
                    setTimeout(() => {
                        let data = this.transformDataDiscount(WiredAdviceStep2)
                        this.getWiredDiscountDebounce(data, WiredAdviceStep2)
                    }, 50)
                } else {
                    this.checkToUpdateConsulant(prevProps)
                }
            }
        }, 50)
    }

    checkToUpdateConsulant = (prevProps) => {
        const {WiredAdvice: {WiredAdviceStep2}} = this.props
        let keysToCheckDiff = [
            'classifyString',
            'classify',
            'bookmark',
            'discountCard',
            'note',
            'saler',
            'combineCondition', // điều kiện kết hợp
            'combineDevice', // kết hợp thiết bị có dây
            'combineOption', // kết hợp option thiết bị có dây
            'combinePlans', // kết hợp gói phí
            'combinePlansSring', // kết hợp gói phí
            'optionDiscount', // kết hợp gói phí
            'customerId', // customer
            'penalty12',
            'penalty24',
        ]
        var checkWiredAdviceStep2OldObj = _.pick(prevProps.WiredAdvice.WiredAdviceStep2, ...keysToCheckDiff)
        var checkWiredAdviceStep2NewObj = _.pick(WiredAdviceStep2, ...keysToCheckDiff)
        var checkEqual = _.isEqual(checkWiredAdviceStep2OldObj, checkWiredAdviceStep2NewObj)
        if (!checkEqual) {
            let WiredAdviceStep2 = caclWiredAdvice(this.props.WiredAdvice.WiredAdviceStep2)
            setTimeout(() => {
                this.props.change('WiredAdviceStep2', WiredAdviceStep2);
            }, 50)

            // save data
            setTimeout(() => {
                this.saveDataDebounce()
            }, 100)
        }
    }

    transformDataDiscount = (WiredAdviceStep2) => {
        const {WiredAdvice: {WiredAdviceStep1}} = this.props
        let data = {
            conds: [],
            class: [],
            key: WiredAdviceStep1.telcomID,
        };
        WiredAdviceStep2.classify.map((cls) => {
            cls.classify.map((item, index) => {
                if (item?.device?.wd_id) {
                    data.conds.push({
                        wd_id: item?.device?.wd_id,
                        wd_price: item?.device?.wd_commitment_fee,
                        combine_discount: (cls.id === 1 && index === 0) ? (WiredAdviceStep2.combineCondition?.id || '') : '',
                        wdo_id: item?.options.map(e => e.wdo_id || null),
                        wdo_price: item?.options.map(e => e.wdo_commitment_fee || 0),
                    })
                    data.class.push(item?.device?.wd_class)
                }
            })
        })
        return data;
    }

    getWiredDiscount = (data, WiredAdviceStep2) => {
        console.log("getWiredDiscount befor WiredAdviceStep2");
        console.log(WiredAdviceStep2);
        api.advice.getWiredDiscountCondsItems(data)
            .then((response) => {
                let clone = [...WiredAdviceStep2.classify]
                let condDiscount = response.data.data.condDiscount || []
                let setupFee = response.data.data.setupFee || []
                let wiredAdviceStep2 = {
                    ...WiredAdviceStep2,
                    setupFee
                }
                let a = [];
                clone.map((cls) => {
                    let ab = []
                    for (let i = 0; i < condDiscount.length; i++) {
                        for (let j = 0; j < cls.classify.length; j++) {
                            if (cls.classify[j]?.device?.wd_id == condDiscount[i][0]?.wd_id) {
                                ab.push(condDiscount[i])
                                break
                            }
                        }
                    }
                    a.push(ab)
                })
                a.map((resGroup, resIndex) => {
                    clone = clone.map((cls, clsIndex) => ({
                        ...cls,
                        classify: cls.classify.map((item, itemIndex) => {
                            let itemClone = {...item}
                            if (resIndex == clsIndex && resGroup[itemIndex]) {
                                itemClone = {
                                    ...itemClone,
                                    discount: {
                                        ...itemClone.discount,
                                        device: {
                                            ...itemClone.discount.device,
                                            name: resGroup[itemIndex][0]?.dwc_name,
                                            value: resGroup[itemIndex][0]?.wdci_discount,
                                        },
                                        options: (resGroup[itemIndex][1] || []).map((i, index) => ({
                                            ...itemClone.discount.options[index],
                                            name: i.dwc_name,
                                            value: i.wdci_discount,
                                        })),
                                        value: itemClone.cost.value - (resGroup[itemIndex][0]?.wdci_discount || 0) - (resGroup[itemIndex][1]?.wdci_discount || 0),
                                    },
                                }
                            }
                            return itemClone
                        })
                    }))
                })
                // condDiscount.map((resItem) => {
                //   clone = clone.map((cls) => ({
                //     ...cls,
                //     classify: cls.classify.map((item) => {
                //       let itemClone = {...item}
                //       if (item?.device?.wd_id == resItem[0]?.wd_id) {
                //         itemClone = {
                //           ...itemClone,
                //           discount: {
                //             ...itemClone.discount,
                //             device: {
                //               ...itemClone.discount.device,
                //               name: resItem[0]?.dwc_name,
                //               value: resItem[0]?.wdci_discount,
                //             },
                //             option: {
                //               ...itemClone.discount.option,
                //               name: resItem[1]?.dwc_name,
                //               value: resItem[1]?.wdci_discount,
                //             },
                //             value: itemClone.cost.value - resItem[0]?.wdci_discount - resItem[1]?.wdci_discount,
                //           },
                //         }
                //       }
                //       return itemClone
                //     })
                //   }))

                // 할인 가격 예외 처리
        if(clone[1].classify && clone[1].classify.length > 0){
            if(clone[1].classify[0].device.wd_id == 368 && clone[1].classify[0].options.length > 0){ // 프리미엄 디즈니+
              clone[1].classify[0].options.map((option) => {
                if(option.wdo_id == 232){ // U+tv 사운드바(디즈니)
                  clone[1].classify.map((classifyInfo) => {
                    if(classifyInfo.device.wd_id == 390){ // 프리미엄 플러스
                      if(isNaN(classifyInfo.discount.device.value) == false && Number(classifyInfo.discount.device.value) > 0){
                        classifyInfo.discount.device.value = (Number(classifyInfo.discount.device.value) - 3300) + "";
                      }
                    }
                  });
                }
              });
            }
  
            let uPlusTvPromotionDiscountForFreeCustomersCount = 0;
  
            clone[1].classify.map((classifyInfo, classifyIndex) => {
              let discount     = classifyInfo.discount;
              let optionsArray = classifyInfo.options;

              if(discount && optionsArray){
                let options = discount.options;
                let wdoName = optionsArray[0]?.wdo_name || "";

                if(options){
                  options.map((optionInfo, optionsIndex, optionsArray) => {
                    let name = optionInfo.name;
  
                    if(name == "U+tv 프리고객 셋탑할인" && wdoName == "크롬캐스트"){
                      uPlusTvPromotionDiscountForFreeCustomersCount++;
  
                      if(classifyIndex == 0){
                        optionsArray[optionsIndex] = {"name": "", "value": 0};
                      }
                    }
                  });
                }
              }
            });
  
            if(uPlusTvPromotionDiscountForFreeCustomersCount == 3){
              this.setState({
                error_modal: {
                    isShowModal: true,
                    message: (<span className="notification_delete">해당 상품은 최대 2개까지 가입 가능합니다.</span>)
                }
              })
  
              clone[1].classify.splice(2, 1);
            }
          }

                wiredAdviceStep2 = {
                    ...wiredAdviceStep2,
                    classify: clone,
                    classifyString: JSON.stringify(clone),
                }
                // })
                wiredAdviceStep2 = caclWiredAdvice(wiredAdviceStep2)

                console.log("getWiredDiscount after WiredAdviceStep2");
                console.log(WiredAdviceStep2);

                setTimeout(() => {
                    this.props.change('WiredAdviceStep2', wiredAdviceStep2);
                }, 500)

                // save data
                setTimeout(() => {
                    this.saveDataDebounce()
                }, 600)
            })
    }

    checkToUpdateDiscount = (prevProps) => {
        // const { WiredAdvice: { WiredAdviceStep2 } } = this.props
        // let keysToCheckDiff = [
        //   'classifyString',
        //   'classify',
        // ]
        // var checkWiredAdviceStep2OldObj = _.pick(prevProps.WiredAdvice.WiredAdviceStep2, ...keysToCheckDiff)
        // var checkWiredAdviceStep2NewObj = _.pick(WiredAdviceStep2, ...keysToCheckDiff)
        // var checkEqual = _.isEqual(checkWiredAdviceStep2OldObj, checkWiredAdviceStep2NewObj)
        // if (!checkEqual) {
        //   let data = this.transformDataDiscount(WiredAdviceStep2)
        //   this.getWiredDiscountDebounce(data, WiredAdviceStep2)
        // }
    }

    transformDataConsultant = (WiredAdviceStep1, WiredAdviceStep2, CreatePotentialCustomer,CreatePotentialCustomerDefault) => {
        let classify = [];
        WiredAdviceStep2.classify.map((cls) => {
            cls.classify.map((item) => {
                classify.push({
                    type: cls.id,
                    device_id: (item.device?.wd_id || ''),
                    option_ids: item.options.map((e, index) => ({
                        option_id: e.wdo_id,
                        type: e.type,
                        qty: e.qty,
                        discount_additional: item.discount?.options[index]?.additional || 0,
                        discount_basic_value: item.discount?.options[index]?.value || 0,
                        discount_basic_name: item.discount?.options[index]?.name || '',
                    })),
                    cost: (item.cost?.value || 0),
                    discount: (item.discount?.value || 0),
                    discount_device: (item.discount?.device?.additional || 0),
                    // discount_option: (item.discount?.option?.additional || 0),
                    basic_device_discount: (item.discount?.device?.value || 0),
                    basic_device_discount_name: (item.discount?.device?.name || ''),
                    // basic_option_discount: (item.discount?.option?.value || 0),
                    // basic_option_discount_name: (item.discount?.option?.name || ''),
                    combine_discount: (item.discount?.combineDiscount || 0),
                })
            })
        })
        let data = {
            ...WiredAdviceStep2,
            saler: WiredAdviceStep2.saler?.id,
            telcomID: WiredAdviceStep1?.telcomID,
            customerId: CreatePotentialCustomerDefault?.id,
            customerNote: CreatePotentialCustomerDefault?.note,
            type: window.currentDevice,
            classify,
            combineCondition: WiredAdviceStep2?.combineCondition?.id || '',
            combineDevice: WiredAdviceStep2?.combineDevice?.wd_id || '',
            combineOption: WiredAdviceStep2?.combineOption?.wdo_id || '',
            combinePlans: WiredAdviceStep2?.combinePlans?.map((item) => ({
                idx: item.idx,
                plan_id: item.plan?.id,
                plan_price: item.plan?.cost_display,
                discount_wireless_price: item.discount_wireless_price,
                discount_wireless_name: (item.discount_condition_name || []).map(e => e.name).join('/'),
                is_commitment: item.is_commitment,
                discount_tv: item.discount_tv,
            })),
            optionDiscount: WiredAdviceStep2?.optionDiscount || '',
            setupFee: WiredAdviceStep2?.setupFee || [],
        }
        return data
    }

    saveData = () => {
        const {WiredAdvice: {id, WiredAdviceStep1, WiredAdviceStep2, CreatePotentialCustomer,CreatePotentialCustomerDefault}} = this.props
        let data = this.transformDataConsultant(WiredAdviceStep1, WiredAdviceStep2, CreatePotentialCustomer,CreatePotentialCustomerDefault)
        if (id) {
            return api.advice.updateWiredConsultant(id, data)
        }
        return api.advice.createWiredConsultant(data)
            .then((response) => {
                this.props.change('id', response.data.data.id);
            })
    }

    nextPage = () => {
        this.setState({page: this.state.page + 1})
    }

    previousPage = () => {
        this.setState({page: this.state.page - 1})
    }

    onSelectedTelcom = (item) => {
        let step1 = {
            telcomID: item.id,
            status: true,
            successString: item.image_url.original,
            installmentPaymentMethod: item.installment_payment_method,
            name: item.name,
            code: item.short_name,
        }
        let step2 = {
            ...CONSULTANT_WIRED,
            saler: {...this.props.auth.user}
        }

        let cloneConsultantWired = {
            status: false,
            saler: null,
            note: '',
            customerId: null,
            customerNote: '',
            bookmark: null,
            monthlyCosts: 0,
            discountCard: {},
            discountCardPrice: 0,
            totalCombinedDiscount: 0,
            classifyString: '',
            classify: [
                {
                    id: 1,
                    name: '인터넷',
                    icon: '/tsc_template/dist/images/globe.svg',
                    classify: []
                },
                {
                    id: 2,
                    name: 'TV',
                    icon: '/images/tivi.svg',
                    classify: []
                },
                {
                    id: 3,
                    name: '전화',
                    icon: '/images/phone.svg',
                    classify: []
                },
                {
                    id: 4,
                    name: 'IoT',
                    icon: '/images/home.svg',
                    classify: []
                },
            ],
            combineCondition: null, // điều kiện kết hợp
            combineDevice: null, // kết hợp thiết bị có dây
            combineOption: null, // kết hợp option thiết bị có dây
            combinePlans: [], // kết hợp gói phí
            combinePlansSring: '', // kết hợp gói phí
            optionDiscount: '', // kết hợp gói phí
        }
        let step2Clone = {...cloneConsultantWired, saler: {...this.props.auth.user}}

        if (this.props.WiredAdvice?.WiredAdviceStep1?.telcomID == item.id) {
            step2Clone = this.props.WiredAdvice?.WiredAdviceStep2
        }
        if(step2Clone.saler) step2Clone.saler.name = this.props?.auth?.role == 'Member' || this.props?.auth?.role == 'Admin' ? '' : this.props?.auth?.user?.name


        this.props.change('WiredAdviceStep1', step1);
        this.props.change('WiredAdviceStep2', step2Clone);
        this.props.change('step', 2)
        window.history.pushState('', '', '/wired-advice?step=' + 2)
    }

    setPage = (step) => {
        if (
            this.props.WiredAdvice.WiredAdviceStep1.status == true
            && this.props.WiredAdvice.WiredAdviceStep2.status == true
        ) {
            this.props.change('step', step)
            window.history.pushState('', '', '/wired-advice?step=' + step)
            if (step == 1) {
                this.props.change('id', null)
                this.props.change('WiredAdviceStep2', CONSULTANT_WIRED)
                this.props.change('CreatePotentialCustomer', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                });
                this.props.change('EditPotentialCustomer', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                });
                this.props.change('CreatePotentialCustomerDefault', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                    statusCreate: false
                });

            }
            return
        }

        if (
            this.props.WiredAdvice.WiredAdviceStep1.status == true
            && (step == 1 || step == 2)
        ) {
            this.props.change('step', step)
            window.history.pushState('', '', '/wired-advice?step=' + step)
            if (step == 1) {
                this.props.change('id', null)
                this.props.change('CreatePotentialCustomer', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                });

                this.props.change('EditPotentialCustomer', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                });
                this.props.change('CreatePotentialCustomerDefault', {
                    id: '',
                    name: '',
                    phone: '',
                    phoneFormat: '',
                    note: '',
                    topPhone: '010',
                    statusCreate: false
                });
            }
            return
        }
    }

    render() {
        if (!this.state.finishInit) {
            return null
        }
        const {WiredAdvice} = this.props
        if (!WiredAdvice) {
            return null;
        }
        return (
            <Fragment>
                <div className="main-wrapper">
                    <section className="wired-consultation">
                        <div className="container">
                            <WiredAdviceStepHeader
                                WiredAdvice={WiredAdvice}
                                setPage={this.setPage}
                            />
                            {WiredAdvice.step == 1 && <WiredStep1
                                history={this.props.history}
                                onSubmit={this.nextPage}
                                telcomProviders={this.props.telcomProviders}
                                onSelectedTelcom={this.onSelectedTelcom}
                            />}
                            {WiredAdvice.step == 2 && <WiredStep2
                                history={this.props.history}
                                onSubmit={this.nextPage}
                                telcomProviders={this.props.telcomProviders}
                                onSelectedTelcom={this.onSelectedTelcom}
                            />}
                        </div>
                    </section>
                </div>

            </Fragment>
        );
    }
}

WiredAdvice.propTypes = {
    WiredAdvice: PropTypes.object,
    history: PropTypes.object,
    telcomProviders: PropTypes.object,
    manufacturers: PropTypes.any,
    change: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    getAdviceWireless: PropTypes.func,
    CreatePotentialCustomer: PropTypes.any,
    EditPotentialCustomer: PropTypes.any,
    CreatePotentialCustomerDefault: PropTypes.any,
    auth: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        telcomProviders: state.dataStore.telcomProviders,
        manufacturers: state.dataStore.manufacturers,
        WiredAdvice: getFormValues('WiredAdvice')(state),
        WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
        CreatePotentialCustomer: getFormValues('CreatePotentialCustomer')(state),
        CreatePotentialCustomerErrors: getFormSyncErrors('CreatePotentialCustomer')(state),
        EditPotentialCustomer: getFormValues('EditPotentialCustomer')(state),
        EditPotentialCustomerErrors: getFormSyncErrors('EditPotentialCustomer')(state),
        CreatePotentialCustomerDefault: getFormValues('CreatePotentialCustomerDefault')(state),
        CreatePotentialCustomerDefaultErrors: getFormSyncErrors('CreatePotentialCustomerDefault')(state)
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        change: () => dispatch(change()),
        getAdviceWireless: () => dispatch(getAdviceWireless())
    };
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    key: FORM_NAME,
    initialValues: {
        step: 1,
        id: null,
        WiredAdviceStep1: {
            telcomID: '',
            status: false,
            successString: '',
            installmentPaymentMethod: INSTALLMENT_PAYMENT_METHOD.principalAndInteres
        },
        WiredAdviceStep2: CONSULTANT_WIRED,
        CreatePotentialCustomer: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            customerId: null
        },
        EditPotentialCustomer: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            customerId: null
        },
        CreatePotentialCustomerDefault: {
            id: '',
            name: '',
            phone: '',
            phoneFormat: '',
            note: '',
            topPhone: '010',
            statusCreate: false
        },
    },
    enableReinitialize: true,
    destroyOnUnmount: false,
}, mapStateToProps);

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(WiredAdvice);
