import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import WirelessSeler from './Seler/Seler'

class WirelessAdviceInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { WirelessAdvice, auth } = this.props;

    return (
      <div className={'bottom-right'}>
        <div className="pt-2">
          <div className="form-group row align-items-center">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">
              매장명
            </label>
            <div className="col-sm-8">
              <input type="text" className="form-control h-44" value={auth?.store?.sto_name} disabled onClick={this.openPopup} />


              {/*<button className="btn btn-input-form mb-0" type="button" onClick={this.openPopup}>*/}
              {/*  {auth?.store?.sto_name}*/}
              {/*</button>*/}
            </div>
          </div>
          <WirelessSeler />
          <div className="form-group row align-items-center mb-0">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">
              전화번호
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control h-44"
                value={auth?.store?.sto_phone_number}
                disabled
                onClick={this.openPopup}
              />
              {/*<button className="btn btn-input-form mb-0" type="button" onClick={this.openPopup}>*/}
              {/*  {WirelessAdvice?.WirelessAdviceStep3?.saler?.phone}*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

WirelessAdviceInformation.propTypes = {
  WirelessAdvice: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessAdviceInformation);
