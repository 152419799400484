import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';

class TelcomList extends Component {

  popupShowSelectedTelcomChild = (item) => {
        if(item.childrens && item.childrens.length > 0) {
            let xhtml = (
                <div className="pl-3 pr-3 ">
                    <div className="modal-header pt-4">
                        <h5 className="modal-title">하위 통신사 </h5>
                        <button type="button" className="close close-modal" onClick={() => this.props.hideModal()}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-additional">
                            {item.childrens.map((item, i) =>
                                <li className={i == 0 ? "" : ""} key={i} onClick={()=> {this.props.onSelectedTelcom(item); this.props.hideModal()}}>
                                    {item.name}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            );
            let dataModal = {
                toggle:true,
                // close:true,
                class_n:'modal-dialog modal-dialog-centered modal-additional',
                component:xhtml
            }
            this.props.showModal(dataModal);
        } else{
            this.props.onSelectedTelcom(item)
        }
    }

  render() {
    const {telcomProviders, onSelectedTelcom} = this.props
    return (
      <div className="list-box">
            {/* process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/box-01.svg" */}
            <div className="box-main">
                { this.props.telcomProviders && this.props.telcomProviders.telcomParents.map((item, i) => 
                    <Fragment key={i}>
                        {i <= 2 ? (
                             <div className="box-item " onClick={()=>this.popupShowSelectedTelcomChild(item)}>
                                <img src={item.image_url.original} alt="" />
                                <span>{item.name}</span>
                            </div>
                        ): ('')}
                       
                    </Fragment>
                    
                )}
            </div>
            <div className="box-extra">
                { this.props.telcomProviders && this.props.telcomProviders.telcomChildren.map((item, i) => 
                    <Fragment key={i}>
                        {i <= 5 ? (
                            <div className="box-item" onClick={()=>this.popupShowSelectedTelcomChild(item)}>
                                <img src={item.image_url.original} alt="" />
                            </div>
                        ): ('')}
                        
                    </Fragment>
                )}
            </div>
        </div>
    );
  }
}

TelcomList.propTypes = {
  telcomProviders: PropTypes.object.isRequired,
  onSelectedTelcom: PropTypes.func.isRequired,
};
const mapStateToProps = (state, props) => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
    }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelcomList);
