import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createStore, applyMiddleware} from 'redux';
import {getCurrentUserSucceed} from './store/actions/auth';
import setAuthorizationHeader from "./utils/setAuthorizationHeader";
import reducer from './store/reducers';
import thunk from 'redux-thunk';
import ScrollToTopPages from './components/ScrollToTopPages';
import {renderButtonChat} from './store/actions/ui';
import {updateLoginLog} from './store/actions/auth';
import {getNotifications} from './store/actions/dataStore';
import {logoutSuccess} from './store/actions/auth';
import App from "./App";
import * as serviceWorker from './serviceWorker';
import axios from './utils/axios'
import moment from 'moment'

export const store = createStore(reducer,  process.env.NODE_ENV === 'production' ? applyMiddleware(thunk)  : composeWithDevTools(applyMiddleware(thunk)));

window.logoutSuccess = logoutSuccess;
window.getNotifications = getNotifications;
window.store = store;
// // Remove console.log
function noop() {}
if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
}else{
    // console.warn = noop;
    // console.log = noop;
}

if(!localStorage.getItem('device_token')){
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
        })
        .catch(function (err) {
        });
}}

function renderApp() {
    if (document.getElementById('root')) {
        return ReactDOM.render(
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTopPages>
                        <App />
                    </ScrollToTopPages>
                </BrowserRouter>
            </Provider>
            , document.getElementById('root'));
            if(!localStorage.getItem('device_token')){
                serviceWorker();
            }
    }
}

if (localStorage.token && localStorage.user) {
    let tet =  localStorage.getItem('isOpenPopupMenu');

    setAuthorizationHeader(localStorage.token);
    axios.get('api/get-expired-date')
    .then((response) => {
        if (moment(response.data) >= moment()) {
            const data = {
                token: localStorage.getItem('token'),
                user: JSON.parse(localStorage.getItem('user')),
                role: localStorage.getItem('role'),
                store: JSON.parse(localStorage.getItem('storeTSC')),
                storePlan: JSON.parse(localStorage.getItem('storePlan')),
                isAllowAccessMenu: localStorage.getItem('isAllowAccessMenu') == 'true' ? true : false,
                isOpenPopupMenu: localStorage.getItem('isOpenPopupMenu') == 'true' ? true : false,
                storePlanLast: JSON.parse(localStorage.getItem('storePlanLast')),
                accountErrStatus: localStorage.getItem('accountErrStatus'),
                // preURL: localStorage.getItem('preURL'),
            };

            let user = JSON.parse(localStorage.getItem('user'));
            store.dispatch(getCurrentUserSucceed(data));
            store.dispatch(renderButtonChat(user));
            store.dispatch(updateLoginLog(user));
            renderApp(); 
        }  else {
            const data = {
                token: localStorage.getItem('token'),
                user: JSON.parse(localStorage.getItem('user')),
                role: localStorage.getItem('role'),
                store: JSON.parse(localStorage.getItem('storeTSC')),
                storePlan: JSON.parse(localStorage.getItem('storePlan')),
                isAllowAccessMenu: localStorage.getItem('isAllowAccessMenu') == 'true' ? true : false,
                isOpenPopupMenu: localStorage.getItem('isOpenPopupMenu') == 'true' ? true : false,
                storePlanLast: JSON.parse(localStorage.getItem('storePlanLast')),
                accountErrStatus: 1,
                // preURL: localStorage.getItem('preURL'),
            };

            let user = JSON.parse(localStorage.getItem('user'));
            store.dispatch(getCurrentUserSucceed(data));
            store.dispatch(renderButtonChat(user));
            store.dispatch(updateLoginLog(user));
            renderApp(); 
        }
        // else {
        //     // store.dispatch(logoutSuccess())
        //     // localStorage.removeItem('preURL');
        //     setAuthorizationHeader();
        //     renderApp();
        // }
    })
    const data = {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
        role: localStorage.getItem('role'),
        store: JSON.parse(localStorage.getItem('storeTSC')),
        storePlan: JSON.parse(localStorage.getItem('storePlan')),
        isAllowAccessMenu: localStorage.getItem('isAllowAccessMenu') == 'true' ? true : false,
        isOpenPopupMenu: localStorage.getItem('isOpenPopupMenu') == 'true' ? true : false,
        storePlanLast: JSON.parse(localStorage.getItem('storePlanLast')),
        accountErrStatus: localStorage.getItem('accountErrStatus'),
        // preURL: localStorage.getItem('preURL'),
    };

    let user = JSON.parse(localStorage.getItem('user'));
    store.dispatch(getCurrentUserSucceed(data));
    store.dispatch(renderButtonChat(user));
    store.dispatch(updateLoginLog(user));
    renderApp();
} else {
    store.dispatch(logoutSuccess())
    setAuthorizationHeader();
    store.dispatch(renderButtonChat(null));
    renderApp();
}
