import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getPlanInformation , getPlanClassifications} from '../../store/actions/plan_information';
import CustomizeLoading from './CustomizeLoading';
import PlanModalComponent from './PlanModalComponent';
class PlanInformationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab : 0 ,
            isLoading : false,
            firstLoad : true,
            dataSearch : {
                searchInput : null,
                currentId : 'last',
            },
            showModalCustomPlan: false,
            dataCustomValue: '',
        };
    }


    /**
     * toggle modal
     */
    toggle = () => {
        this.props.toggleModal();
    }


    /**
     * close modal
     */
    closeModal = () => {
        let data = {...this.state.dataSearch};
        data.currentId = 'last';
        data.searchInput = null;
        this.setState({
            dataSearch : data,
            firstLoad : true,
        })
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        this.setState({
            isLoading : true,
        });
        this.props.getPlanClassifications().then((data) => {
             if (data.data.length > 0) {
                 let lastId = data.data[0].id;
                 let dataFilter = {
                     'currentId' : lastId
                 };
                this.props.getPlanInformation(dataFilter).then(() => {
                    // TODO
                    this.setState({
                        isLoading : false
                    });
                }) 
             }
            
         }) 
    }


    /**
     * set current tab and filter by id
     * @param {*} id 
     */
    setCurrentTab = (id) => {
        this.setState({
            isLoading : true,
            firstLoad : false
        });
        let data = {...this.state.dataSearch};
        data.currentId = id;
        this.setState({
            currentTab : id,
            dataSearch : data
        });
        this.props.getPlanInformation(data).then(() => {
            this.setState({
                isLoading : false
            });
        });
    }


    /**
     * get className
     */
    getClassName = (index,id) => {
        if (this.state.firstLoad) {
            if (index == 0) {
                return 'active';
            }
        } else {
            if (id == this.state.currentTab) {
                return 'active';
            }
        }
        return '';
    }


    /**
     * render element data plans
     * 
     */
    plansClassIfication = (data) => {
        if (data.data && data.data.length > 0) {
            return data.data.map((item, index) => {
                return ( 
                    <li className="nav-item tab-item" key = {index}>
                        <a 
                            className={"nav-link " + this.getClassName(index,item.id)} 
                            id={item.id+'-tab'} data-toggle="tab" href={'#'+ item.id} 
                            role="tab"
                            aria-controls={item.id} 
                            aria-selected={index == 0 ? "true" : 'false'}
                            onClick = {this.setCurrentTab.bind(this,item.id)}
                        >
                            {item.name}
                        </a>
                    </li>
                );
            })
        } 
        
        return '';
    }

    /***
     * show custom data value
     * 
    */
   showCustomData = (item) => {
       this.setState({
            ...this.state,
            dataCustomValue: item,
            showModalCustomPlan: !this.state.showModalCustomPlan
       })
   }
    /**
     * show plan by id
     */
    showDataPlanById = (data) => {

        if (data.data && data.data.length > 0) {
            let element = data.data.map((item,index) => {
                return( 
                    <tr key = {index} onClick={this.showCustomData.bind(this)}>
                        <th scope="row" className="name">{item.name}</th>
                        <td>{item.cost_display}</td>
                        <td>{item.call_time_desc}</td>
                        <td>{item.data_detail}</td>
                        <td>{item.sms_desc}</td>
                        <td className="check">
                            <button className="btn p-0">
                                <i className="far fa-star"></i>
                            </button>
                        </td>
                    </tr>
                );

            });
            return element;
        }
    }

    showDataPlanCustomById = (data) => {
        if (data.data && data.data.length > 0) {
            let element = data.data.map((item,index) => {
                return( 
                    <tr key = {index} onClick={this.showCustomData.bind(this, item)}>
                        <th scope="row" className="name">{item.plac_name}</th>
                        <td>1900-2000</td>
                        <td>{item.plac_use_call}/{item.plac_use_data}</td>
                        <td className="check">
                            <button className="btn p-0">
                                <i className="far fa-star"></i>
                            </button>
                        </td>
                    </tr>
                );

            });
            return element;
        }
    }


    /**
     * handle change input
     */
    handleChange = (e) => {
        let data = {...this.state.dataSearch};
        data.searchInput = e.target.value;
        this.setState({
            dataSearch : data
        })
    }
    /**
     * enter KeyPressed
     * @param {*} e 
     */
    enterPressed = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13) {  
           this.handleSearch(e);
        } 
    }
    /**
     * handle Search
     */
    handleSearch = (e) => {
        e.preventDefault();
        let value = this.state.dataSearch.searchInput;
        if (value) {
            this.setState({
                isLoading : true
            });
            let data = {...this.state.dataSearch};
            this.props.getPlanInformation(data).then(() => {
                data.searchInput = null;
                this.setState({
                    dataSearch : data,
                    isLoading : false
                });
                this.inputSearch.value = "";
            });
        } 
    }
    /**
     * show loading
     */
    showLoadingScreen = (isLoading) => {
        if (isLoading) {
            return(<CustomizeLoading/>);
        }
    }
 
    toggleEventPlanCustom = () => {
        this.showCustomData()
    }

    render() {
        let plans = this.props.plans || [];
        let ificationPlan = this.props.ificationPlan || [];
        let {isLoading} = this.state;
        return(
            <Fragment>
                {this.showLoadingScreen(isLoading)}
                <Modal isOpen={this.props.isShowModal} 
                    className="modal-xl modal-list-plan"
                    contentClassName="pl-5 pr-5"
                    toggle = {this.toggle}
                       // backdrop={"static"}
                    onOpened = {this.onOpened}
                    onClosed = {this.closeModal}
                >
                    <div className="modal-header">
                        <h5 className="modal-title text-left w-100">요금제 선택 </h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>
                        
                    <div className="modal-body content-product mt-0 pl-0 pr-0">
                        <div className="left-product">
                            <div className="top">
                                <ul className="nav nav-tabs list-tab w-100 justify-content-between" id="myTab" role="tablist">
                                    {this.plansClassIfication(ificationPlan)}
                                    <li className="nav-item tab-item">
                                        <a 
                                            className= {"nav-link cursor " + this.getClassName('customized','customized')}
                                            role="tab"
                                            onClick = {this.setCurrentTab.bind(this,'customized')}
                                        >
                                            {'맞춤형'}
                                        </a>
                                    </li>
                                </ul>
                                
                            </div>
                            
                            <div className="row top-search-query">
                                <div className="col-md-6">
                                    <div className="form-search">
                                        <input className="form-control mr-sm-2" 
                                            type="search" 
                                            ref={el => this.inputSearch = el} 
                                            placeholder="검색어를 입력해 주세요" 
                                            onChange = {this.handleChange} 
                                            onKeyPress={this.enterPressed}
                                            aria-label="Search"
                                        />
                                        <button className="btn my-2 my-sm-0" type="submit">
                                            <img src="/images/icon-search.svg" alt="" onClick = {this.handleSearch}/>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="group-check justify-content-end">
                                        <div className="form-group form-check">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" className="custom-control-input" id="customControlAu122"/>
                                                <label className="custom-control-label" htmlFor="customControlAu122">즐겨찾기 </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-content" id="myTabContendt">
                                <div className="fade show" >
                                    <article className="table-form">
                                        <table className="table table-striped">
                                            <tbody>
                                                {this.state.currentTab == 'customized' ? this.showDataPlanCustomById(plans) : this.showDataPlanById(plans)}
                                            </tbody>
                                        </table>
                                    </article>
                                </div> 
                            </div>
                        </div>
                    </div>
                </Modal>
                <PlanModalComponent
                    showModalCustomPlan={this.state.showModalCustomPlan} 
                    toggleEventPlanCustom={this.toggleEventPlanCustom} 
                    dataCustomValue={this.state.dataCustomValue}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        plans : state.plan.dataPlans,
        ificationPlan : state.plan.ificationPlan
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPlanInformation: (data) => dispatch(getPlanInformation(data)),
        getPlanClassifications: () => dispatch(getPlanClassifications()),
    };
};
 
export default connect(mapStateToProps ,mapDispatchToProps )(PlanInformationModal);