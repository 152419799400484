
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {
    optionalAgreementCT, priceFormat, priceFormatDiff, roundUpNumber,
    roundUpNumberPlan
} from '../../../utils/helper';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";

class WirelessRateAgreement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: 12,
    }
  }

  changeMonth = (value, discost) => {
    const { handleSubmit, WirelessAdvice } = this.props;
    this.setState({
      active: value
    }, () => {
      this.props.change('WirelessAdviceStep3.rateAgreement', +discost);
      this.props.change('WirelessAdviceStep3.discostChange', value);
      let customPlan;
      // if ((discost + discost*0.1) != 0) {
      //   if (WirelessAdvice.WirelessAdviceStep3.planData == null) {
      //     customPlan = 1;
      //   }else{
      //     customPlan = 999;
      //   }
      // }
      if (WirelessAdvice.WirelessAdviceStep3.planData == null) {
        customPlan = 1;
      }else{
        customPlan = 999;
      }

      let planData;
      let commitmentRate;
      if (WirelessAdvice.WirelessAdviceStep3.planData != null) {
        planData = WirelessAdvice.WirelessAdviceStep3.planData;
        if (WirelessAdvice.WirelessAdviceStep3.planData.spec_cmm == 1) {
          commitmentRate = +WirelessAdvice.WirelessAdviceStep3.planData.spec_cmm_value/100;
        } else {
          commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate ? WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100 : WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
        }
      } else {
        planData = WirelessAdvice.WirelessAdviceStep3.packageFee;
        if (WirelessAdvice.WirelessAdviceStep3.packageFee.spec_cmm == 1) {
          commitmentRate = +WirelessAdvice.WirelessAdviceStep3.packageFee.spec_cmm_value/100;
        } else {
          commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate ? WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100 : WirelessAdvice.WirelessAdviceStep3.phone.telcom.commitment_rate/100;
        }
      }
      let result = optionalAgreementCT(WirelessAdvice.WirelessAdviceStep3.basicCost/1.1, WirelessAdvice.WirelessAdviceStep3.basicCostCalc/1.1,
        customPlan, planData, commitmentRate, value, WirelessAdvice.WirelessAdviceStep1.telcomID);
      // this.props.change('WirelessAdviceStep3.rateAgreement', result.rateAgreement) ;
      // this.props.change('WirelessAdviceStep3.rateAgreementDefault', result.rateAgreement) ;
      if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {

        var basicCostCalc=WirelessAdvice.WirelessAdviceStep3.basicCostCalc
        var basicCost=WirelessAdvice.WirelessAdviceStep3.basicCost
        var valueBasicCost=0
        if(basicCostCalc != 0) {
            valueBasicCost=basicCostCalc
        }else {
            valueBasicCost=roundUpNumberPlan(basicCost)
        }

          var dataSkt = this.props.WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
          var telcom = this.props.WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
          var nameTelcom=""
          if(dataSkt) {
              nameTelcom=dataSkt.toUpperCase()
          }
          if(telcom != null) {
              nameTelcom=telcom.toUpperCase()
          }
          var moneyResult=0
          if(nameTelcom == "LGU+"){

              var basicCostCalc=this.props.WirelessAdvice.WirelessAdviceStep3.basicCostCalc
              var basicCost=this.props.WirelessAdvice.WirelessAdviceStep3.basicCost
              var rateAgreement=(discost + discost*0.1)
              var combinedDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? this.props.WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
              var promotion=this.props.WirelessAdvice.WirelessAdviceStep3.promotion && this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
              var welfareDiscount=this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount && this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost ? this.props.WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost : 0
              var valueBasicCost=0

              var promotionCost = this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? this.props.WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

              var promotionDurationDiscountOne=this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne : 0
              var promotionDurationDiscountTwo=this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? this.props.WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo : 0
              var promotionDuration= +promotionDurationDiscountOne + +promotionDurationDiscountTwo

              if(basicCostCalc != 0) {
                  valueBasicCost=basicCostCalc
              }else {
                  valueBasicCost=roundUpNumberPlan(basicCost)
              }

              moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement

          }else {
            moneyResult = (valueBasicCost - (discost + discost*0.1)) * OPTION_CHOOSE_2G.percent_optionalAgreement
          }

          console.log('moneyResult', moneyResult);
          this.props.change('WirelessAdviceStep3.optionalAgreement',moneyResult);
          this.props.change('WirelessAdviceStep3.optionalAgreementDefault',moneyResult);

        // this.props.change('WirelessAdviceStep3.optionalAgreement',(valueBasicCost*OPTION_CHOOSE_2G.percent_optionalAgreement));
        // this.props.change('WirelessAdviceStep3.optionalAgreementDefault',(valueBasicCost*OPTION_CHOOSE_2G.percent_optionalAgreement));

        if (result.optionalAgreement < 0) {
            this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
        }else {
            this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
        }
    }else {

        if (result.optionalAgreement < 0) {
            this.props.change('WirelessAdviceStep3.optionalAgreement',0);
            this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
            this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
        } else {
          let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
          if(dataSkt) {
              dataSkt=dataSkt.toUpperCase()
          }
          if (dataSkt != "LGU+" && WirelessAdvice.WirelessAdviceStep3.discountFullPlan != 1) {
              if (planData.id == 3688) {
                  this.props.change('WirelessAdviceStep3.optionalAgreement', 20000);
                  this.props.change('WirelessAdviceStep3.optionalAgreementDefault', 20000);
                  this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', 20000);
              } else {
                  this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
                  this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
                  this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
              }
          } else {
              this.props.change('WirelessAdviceStep3.optionalAgreement',result.optionalAgreement);
              this.props.change('WirelessAdviceStep3.optionalAgreementDefault',result.optionalAgreement);
              this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',result.optionalAgreement);
          }
        }
    }
    })
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    // console.log(WirelessAdvice.WirelessAdviceStep3);
    let discost12 = +WirelessAdvice.WirelessAdviceStep3.discost12;
    let discost24 = +WirelessAdvice.WirelessAdviceStep3.discost24;
    let dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
    let telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
    if(dataSkt) {
        dataSkt=dataSkt.toUpperCase()
    }
    if(telcom != null) {
        telcom=telcom.toUpperCase()
    }
    return (
      <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text"></label>
        <div className="col-sm-8">
            {dataSkt == "LGU+" && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1 ? (
                <ul className="list-month">
                  <li className={WirelessAdvice?.WirelessAdviceStep3?.discostChange == 12 ? 'active' : ''}>
                    <button className="btn" type="button" onClick={this.changeMonth.bind(this, 12, 0)}>미적용 </button>
                  </li>
                  <li className={WirelessAdvice?.WirelessAdviceStep3?.discostChange == 24 ? 'active' : ''}>
                    <button className="btn" type="button" onClick={this.changeMonth.bind(this, 24, discost24)}>24개월 적용</button>
                  </li>
                </ul>
              ) : (
                <ul className="list-month">
                  <li className={WirelessAdvice?.WirelessAdviceStep3?.discostChange == 12 ? 'active' : ''}>
                    <button className="btn" type="button" onClick={this.changeMonth.bind(this, 12, discost12)}>12개월</button>
                  </li>
                  <li className={WirelessAdvice?.WirelessAdviceStep3?.discostChange == 24 ? 'active' : ''}>
                    <button className="btn" type="button" onClick={this.changeMonth.bind(this, 24, discost24)}>24개월</button>
                  </li>
                </ul>
              )}


        </div>
      </div>
    );
  }
}

WirelessRateAgreement.propTypes = {
  WirelessAdvice: PropTypes.object,
  change: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessRateAgreement);
