import React from 'react';
import {Modal} from 'reactstrap';


class ModalImage extends React.Component{
  render() {
    return(
      <Modal
        className={'modal-dialog-centered modal-images modal-images-custom'}
        isOpen={this.props.isOpenPopupImage}
        toggle={this.props.openPopupImage}
      >
          <div className="modal-header border-0">
            <button
              type="button"
              className="close close-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={()=>this.props.openPopupImage()}
            >
          <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
            </button>
          </div>
          <div className="modal-body">
            <div id="carouselExampleIndicators" className="carousel slide slider-images" data-ride="carousel">
              <ol className="carousel-indicators dots-carousel">
                {(this.props.imageDetail || []).map((item,index) => {
                  return(
                    <li data-target="#carouselExampleIndicators" data-slide-to={index} className={index == 0 ? 'active' : ''}><span /></li>
                  )
                })}
              </ol>
              <div className="carousel-inner">
                {(this.props.imageDetail || []).map((item,index) => {
                  return(
                    <div
                      className={`carousel-item ${index == 0 ? 'active' :''}`}
                      key={index}>
                      <img src={item} className="d-block" alt="..." />
                    </div>
                  )
                })}
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span />
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span />
              </a>
            </div>
          </div>
      </Modal>
    )
  }
}

export default ModalImage;
