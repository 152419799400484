 
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import OtherModal from './OtherModal';
class WirelessOther extends Component {

  constructor(props) {
    super(props);
    this.state = {
      price: 0,
    };
  }

  onClickShowModal = () => {
    const { WirelessAdvice } = this.props;

    if(+(WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190){ // KT KT S23 FE 폰 구독 
      alert("더이상 할인옵션을 추가하실 수 없습니다.");
    }
  }

  render() {
    const { WirelessAdvice }  = this.props;
    let wirelessAdviceStep3   = WirelessAdvice?.WirelessAdviceStep3;
    let value                 = wirelessAdviceStep3.other;
    let usedPhoneCompensation = wirelessAdviceStep3?.usedPhoneCompensation;
    let tupId                 = +(usedPhoneCompensation?.tup_id || 0);
    let disabled              = "";

    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });

    if(tupId == 192){ // KT S23 프리미엄 Y 에디션이고 선택약정이면
      disabled = "disabled";
    }

    let stypeNumber = false;

    if (value && value > 0) {
      stypeNumber = true;
    }
    else {
      stypeNumber = false;
    }

    return (
      <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">기타할인</label>
        <div className="col-sm-8">
          <button className='btn btn-input-form mb-0' type="button" data-toggle="modal" data-target="#modal-90" onClick={this.onClickShowModal} disabled={ disabled }>
            {value ? (
              <Fragment>
                {(+value ? (
                  <span style={{color: 'rgb(47, 139, 255)', fontWeight: 'bold'}}>
                    {"-" + formatter.format(value).replace('$','')}
                  </span>
                ) : (
                  <span>0</span>
                ))}
                원
              </Fragment>
            ) : <span className='opacity-disabled'>미적용</span>}
          </button>
        </div>
        {
          +(WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) != 190 && // KT KT S23 FE 폰 구독이 아닐 때
          <OtherModal key={WirelessAdvice?.WirelessAdviceStep3?.other} />
        }
      </div>
    );
  }
}

WirelessOther.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

// const FORM_NAME = 'WirelessAdvice';

// const withReduxForm = reduxForm({
//   form: FORM_NAME,
//   destroyOnUnmount: false,
// });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  // withReduxForm,
)(WirelessOther);
