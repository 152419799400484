
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import OpenPopupDiscountCard from "../AdvancedDiscountCard/OpenPopupDiscountCard";
import {getDiscountCard} from '../../../store/actions/discount_card';
import {numberWithCommas,priceFormat} from "../../../utils/helper";
class WirelessDiscountCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenDiscountCard: false,
      discountCard: [],
      data: null
    }
  }

  componentDidMount() {
    let data = {
      billing_discount_card : true,
      filter_telcomID : this.props.WirelessAdvice.WirelessAdviceStep1.telcomID
    };
    this.props.discountCard(data).then(res => {
      this.setState({
        discountCard : res
      });
    });
  }

  openPopupDiscountCard = () => this.setState({isOpenDiscountCard: !this.state.isOpenDiscountCard})

  isSubmit = (data) => {
    this.props.change('WirelessAdviceStep3.discountCardObject', data)
    this.props.change('WirelessAdviceStep3.discountCard', data.total)
    this.props.change('WirelessAdviceStep3.discountCardId', data.itemData.dcc_id)
    this.setState({
      data
    })

    setTimeout(()=>{
      this.openPopupDiscountCard();
    }, 50);

    // this.setState({
    //   data
    // })
  }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let {data} = this.state;
    return (
        <Fragment>
          <div className="form-group row align-items-start">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text mt-13">청구할인카드</label>
            <div className="col-sm-8">
              <button
                  className={`btn btn-input-form mb-0 ${WirelessAdvice.WirelessAdviceStep3.discountCard ? 'btn-prices-wp' : ''}`}
                  type="button"
                  onClick={this.openPopupDiscountCard}
                  disabled={this.state.discountCard.length > 0 ? false : true}
              >
                {WirelessAdvice.WirelessAdviceStep3.discountCard ? '-'+priceFormat(WirelessAdvice.WirelessAdviceStep3.discountCard) +'원': <span className='opacity-disabled'>미적용</span>}
              </button>
              {WirelessAdvice.WirelessAdviceStep3.discountCard ?
                  <p className="small-color mb-0 pl-2">
                    {WirelessAdvice.WirelessAdviceStep3.discountCardObject?.itemData?.dcc_name || ''}
                    /
                    {(WirelessAdvice.WirelessAdviceStep3.discountCardObject?.dataTextCondition || '').split(",")[0]}
                    {/* 개월 */}
                  </p>
                  : ''}
            </div>
          </div>

          <OpenPopupDiscountCard
              isOpenDiscountCard={this.state.isOpenDiscountCard}
              openPopupDiscountCard={this.openPopupDiscountCard}
              discountCard={this.state.discountCard}
              isSubmit={this.isSubmit}
              titleDetail={"청구할인카드"}
              type={'billing_discount_card'}
              telcomID={this.props.WirelessAdvice.WirelessAdviceStep1.telcomID}
          />
        </Fragment>
    );
  }
}

WirelessDiscountCard.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    discountCard: (data) => dispatch(getDiscountCard(data)),
    change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessDiscountCard);
