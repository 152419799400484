import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import SignInForm from './SignInComponent/SignInForm.js';
import LoginDevicePopup from './SignInComponent/LoginDevicePopup.js';
import LogoutDevicePopup from './SignInComponent/LogoutDevicePopup.js';
import { Field, reduxForm } from 'redux-form';
import {showLoading, hideLoading} from '../../store/actions/ui';
import {showModal, hideModal, changeModalTitle, changeModalContent} from '../../store/actions/modal';
import {
    loginAction,
    logoutDevice
} from '../../store/actions/auth';
import * as routerName from '../../utils/routerName';
import * as helper from '../../utils/helper';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Moment from 'react-moment';
import queryString from "query-string";
import * as UAParser from "ua-parser-js"
import SignInAlertPopupServer from "./SignInComponent/SignInAlertPopupServer";
import SignInAlertPopupPaidVersion from "./SignInComponent/SignInAlertPopupPaidVersion";
import SignInAlertPopupPaidVersion2 from "./SignInComponent/SignInAlertPopupPaidVersion2"; //

const cookies = new Cookies();

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenLoginDevice: false,
            isOpenLogoutDevice: false,
            isOpenAlertPopupServer: true,
            isOpenAlertPopupPaidVersion: true,
            isOpenAlertPopupPaidVersion2: true, //
            loginDevices: [],
            deviceRemove: {},
            limitNumber: 0,
        };
    }

    componentDidMount() {

    }

    popupShowMessageError = (title) => {
        console.log(title)
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={ process.env.REACT_APP_HOSTNAME+ "/tsc_template/dist/images/icon-rattiec.svg"  } alt=""/>
                </div>
                <h4 className="text-center" dangerouslySetInnerHTML={{__html: title}}>
                </h4>
                <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" onClick={() => this.props.hideModal()}>확인</button>
            </div>
        );
        let dataModal = {
            component:xhtml,
            center: true
        }
        this.props.showModal(dataModal);
    }

    renderLoginDevices = () => {
        let xhtml = (this.state.loginDevices || []).map((item, index) => {
            let parser = new UAParser(item.description);
                parser = parser.getResult();
            // let uastring = "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1";
            let detailDevice = '';
            if (item.device == 'SMARTPHONE') {
                detailDevice = `( ${parser?.device?.model } )`
            }


            return (
                <tr key={index}>
                    <td>
                        {item.device}
                        {detailDevice}
                        : {`${parser.os.name} ${parser.os.version} `}
                        <br /> {parser.browser.name} {parser.browser.version}
                    </td>
                    <td>
                        {item.logined_at}
                        <br/>
                        {/* <small>{item.IP}</small> */}
                    </td>
                    <td className="text-right">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-wireless" type="button" onClick={() => this.showConfirmLogout(item)}> 로그아웃  </button>
                        </div>
                    </td>
                </tr>
            )
        });
        return xhtml
    }

    showConfirmLogout = (device) => {
        this.setState({
            ...this.state,
            isOpenLogoutDevice: true,
            deviceRemove: device
        }, () => {
        })
    }

    onLogoutDevice = () => {
        let device = this.state.deviceRemove ;
        this.props.logoutDevice(device).then(res => {
            if(res?.data?.data) {
                let updateListDevices = (this.state.loginDevices || []).filter(item => {
                    return item.id !== device.id
                })
                this.setState({
                    ...this.state,
                    loginDevices: updateListDevices,
                    isOpenLogoutDevice: false,
                    deviceRemove: {}
                }, () => {
                })
            }
        });
    }

    onToggleLoginDevice = () => {
        this.setState({
            isOpenLoginDevice: !this.state.isOpenLoginDevice,
        })
    }

    onToggleLogoutDevice = () => {
        this.setState({
            isOpenLogoutDevice: !this.state.isOpenLogoutDevice,
        })
    }

    onToggleAlertPopupServer = () => {
        this.setState({
            isOpenAlertPopupServer: !this.state.isOpenAlertPopupServer,
        });
    }

    onToggleAlertPopupPaidVersion = () => {
        this.setState({
            isOpenAlertPopupPaidVersion: !this.state.isOpenAlertPopupPaidVersion,
        });
    }
    onToggleAlertPopupPaidVersion2 = () => { //
        this.setState({
            isOpenAlertPopupPaidVersion2: !this.state.isOpenAlertPopupPaidVersion2,
        });
    }

    onSubmit = data => {
        const { username, password, rememberMe } = data;
        let dataRequest = {
            username: data.username,
            password: data.password,
            rememberMe: data.rememberMe,
            token: data.token,
        }

        if(localStorage.getItem("device_token")){
            let fb_token =  localStorage.getItem("device_token")
            dataRequest = {
                ...dataRequest,
                fb_token:fb_token
            }
        } else if(localStorage.getItem("fb_token")) {
            let fb_token =  localStorage.getItem("fb_token")
            dataRequest = {
                ...dataRequest,
                fb_token:fb_token
            }
        }
        this.props.showLoading();
        this.props.loginAction(dataRequest).then(res => {
            if (res.status == 106) {
                this.props.hideLoading();
                this.setState({
                    ...this.state,
                    isOpenLoginDevice: !this.state.isOpenLoginDevice,
                    loginDevices: res?.data?.loginDevices || [],
                    limitNumber: res?.data?.limitNumber || 0,
                }, () => {
                })
                return
            }

            if(rememberMe == true){
                let dataRememberMe = {
                    token: res.token,
                    username: data.username,
                    rememberMe: data.rememberMe
                }
                cookies.set('rememberMeTSC', dataRememberMe, { path: '/' });
            } else {
                let dataRememberMe = {
                    token: '',
                    username: '',
                    rememberMe: data.rememberMe
                }
                cookies.set('rememberMeTSC', dataRememberMe, { path: '/' });
            }
            let loginToday  = {};
            if(cookies.get('loginToDayTSC')
                && cookies.get('loginToDayTSC').today == moment(new Date()).format('YYYY-MM-DD')
                && cookies.get('loginToDayTSC').username == data.username){
                loginToday = {
                    today: moment(new Date()).format('YYYY-MM-DD'),
                    username: data.username,
                    countLogin: cookies.get('loginToDayTSC').countLogin + 1,
                    newLogin:true
                }
            } else {
                loginToday = {
                    today: moment(new Date()).format('YYYY-MM-DD'),
                    username: data.username,
                    countLogin: 1,
                    newLogin:true
                }
            }

            cookies.set('loginToDayTSC', loginToday, { path: '/' });
            this.props.hideLoading();
            const { location } = this.props;
            let params = queryString.parse(location.search);

            if(params.id){
                this.props.history.push(`?id=${params.id}`);
            }else{
                this.props.history.push(`/${routerName.HOME}`);
            }

            if(res.token){
                (function() {
                    function l() {
                      if (window.ChannelIOInitialized) {
                        return;
                      }
                      window.ChannelIOInitialized = true;
                      var s = document.createElement('script');
                      s.type = 'text/javascript';
                      s.async = true;
                      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
                      s.charset = 'UTF-8';
                      var x = document.getElementsByTagName('script')[0];
                      x.parentNode.insertBefore(s, x);
                    }
                    l();
                    window.addEventListener('DOMContentLoaded', l, false);
                    window.addEventListener('load', l, false);
                  })();
            }
        })
        .catch(errors => {
            this.props.hideLoading();
            if (errors.user === 'block') {
                return this.popupShowOldMemberBlock()
            }
            if (typeof errors === 'string') {
                return this.popupShowMessageError(errors);
            } else {
                return this.popupShowMessageError(errors.mess);
            }
        });
    };

    popupShowOldMemberBlock = () => {
        let xhtml = (
            <div className="content-register-modal">
                <div className="img-check">
                    <img src={process.env.REACT_APP_HOSTNAME + "/tsc_template/dist/images/icon-rattiec.svg"} alt="" />
                </div>
                <h4 className="text-center mb-5">
                    본사 요청으로 서비스가 일시 중단되었습니다
                </h4>
                <p className="text-center">
                    모비고 서비스 사용을 원하시는 경우
                </p>
                <p className="text-center">
                    본사 (IM 영업팀)으로 연락해주시기 바랍니다
                </p>
                <button
                    type="button"
                    className="btn btn-secondary btn-ok"
                    onClick={this.props.hideModal}>
                    확인
                </button>
            </div>
        );
        let dataModal = {
            component: xhtml,
            class_n: 'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        }

        this.props.showModal(dataModal);
    }

    renderSignInComponent() {
        let xhtml = null;
        xhtml = <SignInForm history={this.props.history} onSubmit={this.onSubmit}/>;
        return xhtml;
    }

    render() {
        return (
            <div>
                {this.renderSignInComponent()}

                <LoginDevicePopup
                    isOpen = {this.state.isOpenLoginDevice}
                    toggle = {this.onToggleLoginDevice}
                    limitNumber = {this.state.limitNumber}
                    loginDevices = {this.state.loginDevices}
                >
                    {this.renderLoginDevices()}
                </LoginDevicePopup>

                <LogoutDevicePopup
                    isOpen = {this.state.isOpenLogoutDevice}
                    toggle = {this.onToggleLogoutDevice}
                    // deviceRemove = {this.state.deviceRemove}
                    logoutDevice = {this.onLogoutDevice}
                />

                {/*<SignInAlertPopupServer
                    isOpen = {this.state.isOpenAlertPopupServer}
                    toggle = {this.onToggleAlertPopupServer}
                />*/}

                <SignInAlertPopupPaidVersion
                    isOpen = {this.state.isOpenAlertPopupPaidVersion}
                    toggle = {this.onToggleAlertPopupPaidVersion}
                    {...this.props}
                />
                <SignInAlertPopupPaidVersion2
                    isOpen = {this.state.isOpenAlertPopupPaidVersion2}
                    toggle = {this.onToggleAlertPopupPaidVersion2}
                    {...this.props}
                />
            </div>
        );
    }0
}

SignInForm.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => {
    let initValues;
    if (!!cookies.get('rememberMeTSC')) {
        initValues = {
            username: cookies.get('rememberMeTSC').username,
            password:  cookies.get('rememberMeTSC').rememberMe ? '******' : '',
            rememberMe: cookies.get('rememberMeTSC').rememberMe,
            token:cookies.get('rememberMeTSC').token
        };
    }
    return {
        initialValues: initValues,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        showModal: (data) => dispatch(showModal(data)),
        hideModal: () => dispatch(hideModal()),
        loginAction: (data) => dispatch(loginAction(data)),
        logoutDevice: (data) => dispatch(logoutDevice(data)),
    }
};
const FORM_NAME = 'SignIn';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    initialValues:{
        username: '',
        password: '',
        rememberMe: false,
        token:''
    },
    enableReinitialize: true
});



const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm
)(SignIn);

