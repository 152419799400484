import {
    POST_APPLY_FEE,
} from '../actions';

import api from "../../utils/api";

export const postApplyFeeSuccess = (data) => {
    return {
        type: POST_APPLY_FEE.POST_APPLY_FEE,
        data: data
    }
};


export const postApplyFee = (dataFilter) => dispatch => {
    dispatch(postApplyFeeSuccess([]));
    return api.post_apply_fee.postApplyFee(dataFilter)
        .then(res => {
            dispatch(postApplyFeeSuccess(res.data))
            return Promise.resolve(res.data);
        }).catch(error => {
            return Promise.reject(false);
        });
};

