
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {OPTION_CHOOSE_2G} from "../../../utils/constant";
import {optionalAgreementCT, priceFormat, roundUpNumber, roundUpNumberPlan} from '../../../utils/helper';
const codeLimit = ["SM-G160N", "SM-J250", "SM-J737S", "LM-Y110S", "LGM-X100S", "LGM-K121S", "SM-A205S", "SM-A102", "LM-X420N", "LM-220N"];
const noDropStyle = {
    cursor: 'no-drop',
};
const noDefultStyle = {
    cursor: 'pointer',
};


class WirelessOptionFee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: 0
    }
  }


    componentDidUpdate(prevProps) {
        let idPlan = 10
        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee == null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null) {

        }

        if (prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee != null && this.props.WirelessAdvice.WirelessAdviceStep3.packageFee != null &&
            this.props.WirelessAdvice.WirelessAdviceStep3.packageFee.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.packageFee.id) {
            setTimeout(() => {

                // this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreement)
                this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault)
                this.active(this.props.WirelessAdvice.WirelessAdviceStep3.optionFee) //goi load 2g
            }, 500)
        }else {

            if (this.props.WirelessAdvice.WirelessAdviceStep3.planData != null && prevProps.WirelessAdvice.WirelessAdviceStep3.planData != null &&
                this.props.WirelessAdvice.WirelessAdviceStep3.planData?.id !== prevProps.WirelessAdvice.WirelessAdviceStep3.planData?.id) {
                setTimeout(() => {
                    this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', this.props.WirelessAdvice.WirelessAdviceStep3.optionalAgreementDefault)
                    this.active(this.props.WirelessAdvice.WirelessAdviceStep3.optionFee) //goi load 2g
                }, 500)
            }
        }


    }

    optionalAgreementCTOld = (cost_display, cost_calc, customPlan, data, discost, discostChange) => {
        const { WirelessAdvice } = this.props;
        let optionalAgreement = 0;
        let monthDcPrice = 0;
        let optionalAgreementFix = 0;
        let optionalAgreementFix2 = 0;
        let optionalAgreementArr;
        let optionalAgreementArr2;
        let discount_full = WirelessAdvice.WirelessAdviceStep3.discountFullPlan;
        let commitmentRate = WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate == 0 ? 1 : WirelessAdvice.WirelessAdviceStep3.phone.commitment_rate/100;
        if (WirelessAdvice.WirelessAdviceStep1.telcomID != 18) {
            if (discostChange == 12) {
                if (priceFormat(cost_calc) != 0) {
                    optionalAgreement = ((cost_calc) - (+discost))*(commitmentRate);
                    monthDcPrice = cost_calc % 10;
                    optionalAgreementFix = optionalAgreement.toFixed(1, '.');
                    optionalAgreementArr = optionalAgreementFix.split(',');
                    if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
                        optionalAgreement = ((cost_calc + cost_calc*10/100) - (+discost + discost*10/100)) * (commitmentRate);
                        optionalAgreement = Math.round(optionalAgreement);
                    } else {
                        optionalAgreementFix2 = (optionalAgreement/100).toFixed(2, '.');
                        optionalAgreementArr2 = optionalAgreementFix2.split('.');
                        if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                            optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+discost + discost*10/100))*(commitmentRate);
                            optionalAgreement = Math.round(optionalAgreement);
                        } else if(optionalAgreementArr2[1] < 50){
                            let selDcPrice = parseInt(+optionalAgreementArr2[0] + parseFloat(0.5));
                            optionalAgreement = ((selDcPrice*100)*1.1);
                        }else{
                            optionalAgreement = Math.round(optionalAgreement);
                            optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
                        }
                    }
                } else {
                    optionalAgreement = ((+cost_display) - (+discost))*(commitmentRate);
                    monthDcPrice = +cost_display % 10;
                    optionalAgreementFix = optionalAgreement.toFixed(1, '.');
                    optionalAgreementArr = optionalAgreementFix.split(',');
                    if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
                        optionalAgreement = ((+cost_display +cost_display*10/100) - (+discost + discost*10/100)) * (commitmentRate);
                        optionalAgreement = Math.round(optionalAgreement);
                    } else {
                        optionalAgreementFix2 = (optionalAgreement/100).toFixed(2, '.');
                        optionalAgreementArr2 = optionalAgreementFix2.split('.');
                        if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                            optionalAgreement = ((+cost_display+cost_display*10/100) - (+discost + discost*10/100))*(commitmentRate);
                            optionalAgreement = Math.round(optionalAgreement);
                        } else if(optionalAgreementArr2[1] < 50){
                            let selDcPrice = parseInt(+optionalAgreementArr2[0] + parseFloat(0.5));
                            optionalAgreement = ((selDcPrice*100)*1.1);
                        }else{
                            optionalAgreement = Math.round(optionalAgreement);
                            optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
                        }
                    }
                }
            }else{
                if (priceFormat(cost_calc) != 0) {
                    optionalAgreement = (cost_calc - (+discost))*(commitmentRate);
                    monthDcPrice = cost_calc % 10;
                    optionalAgreementFix = optionalAgreement.toFixed(1, '.');
                    optionalAgreementArr = optionalAgreementFix.split(',');
                    if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
                        optionalAgreement = ((cost_calc) - (+discost)) * (commitmentRate);
                        optionalAgreement = Math.round(optionalAgreement);
                    } else {
                        optionalAgreementFix2 = (optionalAgreement/100).toFixed(2, '.');
                        optionalAgreementArr2 = optionalAgreementFix2.split('.');
                        if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                            optionalAgreement = ((cost_calc+cost_calc*10/100) - (+discost + discost*10/100))*(commitmentRate);
                            optionalAgreement = Math.round(optionalAgreement);
                        } else if(optionalAgreementArr2[1] < 50){
                            let selDcPrice = parseInt(+optionalAgreementArr2[0] + parseFloat(0.5));
                            optionalAgreement = ((selDcPrice*100)*1.1);
                        }else{
                            optionalAgreement = Math.round(optionalAgreement);
                            optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
                        }
                    }
                } else {
                    optionalAgreement = (cost_display - (+discost))*(commitmentRate);
                    monthDcPrice = cost_display % 10;
                    optionalAgreementFix = optionalAgreement.toFixed(1, '.');
                    optionalAgreementArr = optionalAgreementFix.split(',');
                    if (optionalAgreementArr[1] != 0 && monthDcPrice == 0 && customPlan != 999) {
                        optionalAgreement = ((cost_display) - (+discost)) * (commitmentRate);
                        optionalAgreement = Math.round(optionalAgreement);
                    } else {
                        optionalAgreementFix2 = (optionalAgreement/100).toFixed(2, '.');
                        optionalAgreementArr2 = optionalAgreementFix2.split('.');
                        if (optionalAgreementArr2[1] == 50 || optionalAgreementArr2[1] == 5 || optionalAgreementArr2[1] == 0) {
                            optionalAgreement = ((cost_display+cost_display*10/100) - (+discost + discost*10/100))*(commitmentRate);
                            optionalAgreement = Math.round(optionalAgreement);
                        } else if(optionalAgreementArr2[1] < 50){
                            let selDcPrice = parseInt(+optionalAgreementArr2[0] + parseFloat(0.5));
                            optionalAgreement = ((selDcPrice*100)*1.1);
                        }else{
                            optionalAgreement = Math.round(optionalAgreement);
                            optionalAgreement = (Math.round(optionalAgreement/100))*100*1.1;
                        }
                    }
                }
            }
        }else{
            if (discount_full == 1) {
                //TH3: Giảm giá cam kết theo gói LGU+
                if (discostChange == 12) {
                    this.props.change('WirelessAdviceStep3.rateAgreement', 0) ;
                }else{
                    this.props.change('WirelessAdviceStep3.rateAgreement', +data.discost_24 + data.discost_24*10/100) ;
                }
                if (priceFormat(cost_calc) != 0) {
                    optionalAgreement = (+cost_calc+cost_calc*10/100)*(commitmentRate);
                } else {
                    optionalAgreement = (+cost_display+cost_display*10/100)*(commitmentRate);
                }
            }else{
                if (discostChange == 12) {
                    if (priceFormat(cost_calc) != 0) {
                        optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+discost + discost*10/100))*(commitmentRate);
                    } else {
                        optionalAgreement = ((+cost_display+cost_display*10/100) - (+discost + discost*10/100))*(commitmentRate);
                    }
                }else{
                    if (priceFormat(cost_calc) != 0) {
                        optionalAgreement = ((+cost_calc+cost_calc*10/100) - (+discost + discost*10/100))*(commitmentRate);
                    } else {
                        optionalAgreement = ((+cost_display+cost_display*10/100) - (+discost + discost*10/100))*(commitmentRate);
                    }
                }
            }

        }

        if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G?.id == 1) {

            var basicCostCalc=WirelessAdvice.WirelessAdviceStep3.basicCostCalc
            var basicCost=WirelessAdvice.WirelessAdviceStep3.basicCost
            var valueBasicCost=0
            if(basicCostCalc != 0) {
                valueBasicCost=basicCostCalc
            }else {
                valueBasicCost=basicCost
            }

            this.props.change('WirelessAdviceStep3.optionalAgreement',(valueBasicCost*OPTION_CHOOSE_2G.percent_optionalAgreement));
            this.props.change('WirelessAdviceStep3.optionalAgreementDefault',(valueBasicCost*OPTION_CHOOSE_2G.percent_optionalAgreement));

            if (optionalAgreement < 0) {
                this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
            }else {
                // this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',optionalAgreement);
                this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',(valueBasicCost*OPTION_CHOOSE_2G.percent_optionalAgreement));
            }

        }else {
            if (optionalAgreement < 0) {
                this.props.change('WirelessAdviceStep3.optionalAgreement',0);
                this.props.change('WirelessAdviceStep3.optionalAgreementDefault',0);
                this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',0);
            } else {
                this.props.change('WirelessAdviceStep3.optionalAgreement',optionalAgreement);
                this.props.change('WirelessAdviceStep3.optionalAgreementDefault',optionalAgreement);
                this.props.change('WirelessAdviceStep3.optionalAgreementWith2G',optionalAgreement);
            }
        }

    }



    changeMonth = (value, discost) => {
        const { handleSubmit, WirelessAdvice } = this.props;

        this.props.change('WirelessAdviceStep3.rateAgreement', discost + discost*0.1);
        this.props.change('WirelessAdviceStep3.discostChange', value);
        let customPlan;
        if ((discost + discost*0.1) != 0) {
            if (WirelessAdvice.WirelessAdviceStep3.planData == null) {
                customPlan = 1;
            }else{
                customPlan = 999;
            }
        }
        optionalAgreementCT(WirelessAdvice.WirelessAdviceStep3.basicCost/1.1,
            WirelessAdvice.WirelessAdviceStep3.basicCostCalc/1.1,
            customPlan,
            WirelessAdvice.WirelessAdviceStep3.packageFee,
            discost,
            value
        );
    }

    activeFollowChoose2G=(value) => {
        const { handleSubmit, WirelessAdvice } = this.props;

        var dataSkt = WirelessAdvice.WirelessAdviceStep3?.phone?.short_name
        var telcom = WirelessAdvice.WirelessAdviceStep3?.telcomPotentialCustomer
        var nameTelcom=""
        if(dataSkt) {
            nameTelcom=dataSkt.toUpperCase()
        }
        if(telcom != null) {
            nameTelcom=telcom.toUpperCase()
        }

        // var rateAgreement=(WirelessAdvice.WirelessAdviceStep3.rateAgreementDefault) ? WirelessAdvice.WirelessAdviceStep3.rateAgreementDefault : 0
        var rateAgreement=(WirelessAdvice.WirelessAdviceStep3.rateAgreement) ? WirelessAdvice.WirelessAdviceStep3.rateAgreement : 0
        var basicCostCalc=WirelessAdvice.WirelessAdviceStep3.basicCostCalc
        var basicCost=WirelessAdvice.WirelessAdviceStep3.basicCost

        var combinedDiscount=WirelessAdvice.WirelessAdviceStep3.combinedDiscount ? WirelessAdvice.WirelessAdviceStep3.combinedDiscount : 0
        var welfareDiscount=WirelessAdvice.WirelessAdviceStep3.welfareDiscount && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost ? WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.cost : 0
        var promotion=WirelessAdvice.WirelessAdviceStep3.promotion && WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0

        var promotionDurationDiscountOne=WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne ? WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountOne : 0
        var promotionDurationDiscountTwo=WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo ? WirelessAdvice.WirelessAdviceStep3.promotionDurationDiscountTwo : 0
        var promotionDuration= +promotionDurationDiscountOne + +promotionDurationDiscountTwo

        var valueBasicCost=0
        if(basicCostCalc != 0) {
            valueBasicCost=basicCostCalc
        }else {
            valueBasicCost=roundUpNumberPlan(basicCost)
        }

        if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 2 && value == 0) {
            alert(`2G전환고객을 "무약정"으로 설정하신 경우 선택약정으로 변경하실 수 없습니다.`);
        }else {

            this.props.change('WirelessAdviceStep3.optionFee', value)
            let dataCheckLimit = (nameTelcom && nameTelcom == "LGU+") ? OPTION_CHOOSE_2G.code_limit_LGU.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name) :
                OPTION_CHOOSE_2G.code_limit.includes(WirelessAdvice.WirelessAdviceStep3.phone.model_name)
            if(value != 1) {
                if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
                    if(WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {

                        if(WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G == null){
                            this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                        }
                        let moneyResult = (WirelessAdvice.WirelessAdviceStep3.basicCost != null ? roundUpNumberPlan(WirelessAdvice.WirelessAdviceStep3.basicCost) : 0) * OPTION_CHOOSE_2G.percent_optionalAgreement

                        if(nameTelcom && nameTelcom == "LGU+") {
                            moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
                        }

                        this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : moneyResult)
                    }

                }else {
                    if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {
                        var basicCostValue=WirelessAdvice.WirelessAdviceStep3.basicCostCalc != 0 ? WirelessAdvice.WirelessAdviceStep3.basicCostCalc :
                            (WirelessAdvice.WirelessAdviceStep3.basicCost != null ? WirelessAdvice.WirelessAdviceStep3.basicCost : 0)

                        let moneyResult = (roundUpNumberPlan(basicCostValue) - rateAgreement) * OPTION_CHOOSE_2G.percent_optionalAgreement
                        if(nameTelcom && nameTelcom == "LGU+") {

                            moneyResult=(valueBasicCost - welfareDiscount - rateAgreement - promotion - promotionDuration - combinedDiscount)*OPTION_CHOOSE_2G.percent_optionalAgreement
                        }

                        this.props.change('WirelessAdviceStep3.optionalAgreement', moneyResult < 0 ? 0 : Math.round(moneyResult))

                        if(WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6) {
                            var discostChange=WirelessAdvice.WirelessAdviceStep3.discostChange
                            var discost = 0
                            if(discostChange == 12) {
                                discost = +WirelessAdvice.WirelessAdviceStep3.discost12
                            }else {
                                discost = +WirelessAdvice.WirelessAdviceStep3.discost24
                            }
                            if(dataSkt == "LGU+" &&  WirelessAdvice.WirelessAdviceStep3.optionFee == 0 && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1) {
                                if(discostChange == 12) {
                                    discost = 0
                                }else {
                                    discost = +WirelessAdvice.WirelessAdviceStep3.discost24
                                }
                            }
                            this.changeMonth(discostChange,discost)
                            var promotionCost=WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
                            var optionalAgreement = moneyResult
                            var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2)
                            if(parseInt(dataCostWelfareDiscount) > 0){
                                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount
                            }else {
                                dataCostWelfareDiscount=0
                            }
                            dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1))
                            this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount})
                        }

                    }else {
                        this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                    }
                }
            }else {
                if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && dataCheckLimit) {
                    if(WirelessAdvice.WirelessAdviceStep3.conversion2G.id != 0) {
                        this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB)
                        if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                            this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                        }
                        this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.startingPrice != null ? WirelessAdvice.WirelessAdviceStep3.startingPrice : 0)
                    }else {
                        if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null) {
                            let optionalAgreement = WirelessAdvice.WirelessAdviceStep3.optionalAgreement
                            let optionalAgreementWith2G = WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G
                            this.props.change('WirelessAdviceStep3.optionalAgreement', optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement)
                            let disclosureSubsidyDefault = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault
                            let disclosureSubsidy = WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
                            this.props.change('WirelessAdviceStep3.disclosureSubsidy', disclosureSubsidyDefault)
                        }
                    }
                }else {
                    if(WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 1) {
                        this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB)
                        let numberDisclosureSubsidy=WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy
                        let startingPrice=+WirelessAdvice.WirelessAdviceStep3.startingPrice
                        numberDisclosureSubsidy=+numberDisclosureSubsidy
                        let disclosureSubsidy=numberDisclosureSubsidy

                        if(startingPrice <  OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                            if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                                this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                            }
                            this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.startingPrice)
                        }else {
                            if (disclosureSubsidy <= OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit) {
                                if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                                }
                                this.props.change('WirelessAdviceStep3.disclosureSubsidy', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB_not_limit)
                            }else {
                                if(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault == null){
                                    this.props.change('WirelessAdviceStep3.disclosureSubsidyDefault', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy)
                                }
                            }
                        }

                        let optionAgree=WirelessAdvice.WirelessAdviceStep3.optionalAgreement
                        if(WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G == null){
                            this.props.change('WirelessAdviceStep3.optionalAgreementWith2G', optionAgree != null ? optionAgree : null)
                        }
                        this.props.change('WirelessAdviceStep3.optionalAgreement', OPTION_CHOOSE_2G.price_optionalAgreement_HTTB)


                        if(WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6) {
                            var discostChange=WirelessAdvice.WirelessAdviceStep3.discostChange
                            var discost = 0
                            if(discostChange == 12) {
                                discost = +WirelessAdvice.WirelessAdviceStep3.discost12
                            }else {
                                discost = +WirelessAdvice.WirelessAdviceStep3.discost24
                            }
                            if(dataSkt == "LGU+" &&  WirelessAdvice.WirelessAdviceStep3.optionFee == 0 && WirelessAdvice.WirelessAdviceStep3.discountFullPlan == 1) {
                                if(discostChange == 12) {
                                    discost = 0
                                }else {
                                    discost = +WirelessAdvice.WirelessAdviceStep3.discost24
                                }
                            }
                            this.changeMonth(discostChange,discost)
                            var promotionCost=WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost ? WirelessAdvice.WirelessAdviceStep3.promotion?.promo_cost : 0
                            var optionalAgreement = OPTION_CHOOSE_2G.price_optionalAgreement_HTTB
                            var dataCostWelfareDiscount = ((valueBasicCost - combinedDiscount - parseInt(promotionCost) - optionalAgreement)/2)
                            if(parseInt(dataCostWelfareDiscount) > 0){
                                dataCostWelfareDiscount=parseInt(dataCostWelfareDiscount) > 12100 ? 12100 : dataCostWelfareDiscount
                            }else {
                                dataCostWelfareDiscount=0
                            }
                            dataCostWelfareDiscount= parseInt(parseFloat(dataCostWelfareDiscount).toFixed(1))
                            this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:dataCostWelfareDiscount})
                        }
                        // if(WirelessAdvice.WirelessAdviceStep3.welfareDiscount != null && WirelessAdvice.WirelessAdviceStep3.welfareDiscount?.id == 6) {
                        //     this.props.change('WirelessAdviceStep3.welfareDiscount', {id:6, value:'만 65세 이상 기초연금수급자(최대 12,100원)', cost:12100})
                        // }

                    }else {
                        let optionalAgreement = WirelessAdvice.WirelessAdviceStep3.optionalAgreement
                        let optionalAgreementWith2G = WirelessAdvice.WirelessAdviceStep3.optionalAgreementWith2G
                        this.props.change('WirelessAdviceStep3.optionalAgreement', optionalAgreementWith2G != null ? optionalAgreementWith2G : optionalAgreement)
                        if (WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault !== null)  {
                            this.props.change('WirelessAdviceStep3.disclosureSubsidy', WirelessAdvice.WirelessAdviceStep3.disclosureSubsidyDefault)
                        }
                    }
                }
                // console.log(WirelessAdvice.WirelessAdviceStep3);
            }
        }
    }

    active = (value) => {
        const { handleSubmit, WirelessAdviceStep3 } = this.props.WirelessAdvice;
        // this.props.change('WirelessAdviceStep3.optionFee', value)
        let disclosureSubsidyDifference;
        let check = false;
        if (value == 0) {
            this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference', null);
            this.props.change('WirelessAdviceStep3.converseDifference', null);
        }else{
            if (WirelessAdviceStep3.dataPlanNewDateChange != null) {
                disclosureSubsidyDifference = (+WirelessAdviceStep3.disclosureSubsidy) - (+WirelessAdviceStep3.disclosureSubsidyDifferenceDefault)
                this.props.change('WirelessAdviceStep3.disclosureSubsidyDifference',disclosureSubsidyDifference);
                this.props.change('WirelessAdviceStep3.converseDifference',(+WirelessAdviceStep3.conversion_price_input) - (+WirelessAdviceStep3.converseDifferenceDefault));
            }
        }
        let usedPhone = WirelessAdviceStep3.usedPhoneCompensation;    // nếu đã chọn CT bthuong DT cũ

        //console.log("WirelessAdviceStep3.usedPhoneCompensation : 값 확인 : ", WirelessAdviceStep3.usedPhoneCompensation)
        //console.log("WirelessAdviceStep3 값 확인",WirelessAdviceStep3)

        if (usedPhone) {
            if (usedPhone.vprogram_install_types && usedPhone.vprogram_install_types.length > 0) {      //là nhà mạng LGU
                let {installmentType} = WirelessAdviceStep3;
                let arrSubType = [];
                usedPhone.vprogram_install_types.forEach((item, index) => {
                    let tmp = item.name.split('/');
                    if (tmp[0] == installmentType.typeName) {
                        arrSubType.push({
                            ...item,
                            'subTypeName': tmp[1]
                        })
                    }
                });

                arrSubType.forEach((item, index) => {
                    if (item.code_group == value) {
                        check = true;
                    }
                })
            } else if (usedPhone.vprogram_types && usedPhone.vprogram_types.length > 0) {    //các nhà mạng còn lại
                usedPhone.vprogram_types.forEach((item, index) => {
                    if (item.code_group == value) {
                        check = true;
                    }
                })
            }
        }
        if (!check) {
            this.props.change('WirelessAdviceStep3.applyUsedProgram', 0);
            this.props.change('WirelessAdviceStep3.usedPhoneCompensation', {
                "tup_id": '',
                "tup_name": "미적용"
            });
        }


        this.setState({
            active: value
        }, () => {
            this.activeFollowChoose2G(value)
        })
    }

  render() {
    const { handleSubmit, WirelessAdvice } = this.props;
    let noDrop=WirelessAdvice.WirelessAdviceStep3.conversion2G != null && WirelessAdvice.WirelessAdviceStep3.conversion2G.id == 2 ? 1 : 0
    return (
      <ul className="list-tab">
        <li className={WirelessAdvice.WirelessAdviceStep3.optionFee == 1 ? "tab-item active" : 'tab-item'} onClick={this.active.bind(this, 1)}>
          {
              WirelessAdvice.WirelessAdviceStep3.optionFee == 1 ? <img src="/images/icon-dis-notifi.svg" alt="" /> : <img src="/images/icon-dis-notifi.svg" alt="" />
          }


          공시지원금
        </li>
        <li style={noDrop == 1 ? noDropStyle : noDefultStyle} className={WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? "tab-item active" : "tab-item"} onClick={this.active.bind(this, 0)}>
            {
              WirelessAdvice.WirelessAdviceStep3.optionFee == 0 ? <img src="/images/Icon-dis-fill.svg" alt="" /> : <img src="/images/Icon-dis-fill.svg" alt="" />
            }

          선택약정할인
        </li>
      </ul>
    );
  }
}

WirelessOptionFee.propTypes = {
    WirelessAdvice: PropTypes.object
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
      change: () => dispatch(change()),
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessOptionFee);
