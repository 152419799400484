import React, { Component, Fragment , PureComponent} from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../store/actions/ui";
import api from "../../../utils/api";
import PropTypes from 'prop-types';
import {ADVICE_TYPE} from "../../../utils/constant";
import _ from "lodash";

class DiscountConditionModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            selected: null
        };
    }

    componentDidUpdate = async (prevProps, prevState) =>  {
        if(this.props.combinesDiscount && this.props.combinesDiscount.length > 0 && JSON.stringify(this.props.combinesDiscount) !== JSON.stringify(this.state.list)){
            this.setState({
                list: this.props.combinesDiscount,
            })
        }
    }

    /**
     * close modal
     */
    onCloseModal = () => {
        //if(JSON.stringify(this.props.selected) !== JSON.stringify(this.state.selected)) this.props.onSelect(this.state.selected);
    }


    /**
     * detect open modal
     */
    onOpened = () => {

    }

    onSelect = (e, value) => {
       /* this.setState({
            selected: value
        }, () => {
            this.props.toggleModal(e,'discount_condition')
        })*/

        // this.props.toggleModal(e,'discount_condition')

        let isAllow = true;
        const selectedPlans = this.props.planModal.plans;

        // console.log('value', value);
        // console.log('planModal', this.props.planModal);
        // console.log('planTogether', this.props.planTogether);
        // console.log('discountCondition', this.props.discountCondition);
        // console.log('selectedPlans', selectedPlans);

        for (let selectedPlan of selectedPlans) {
            const data = selectedPlan.plan;
            if ([17, 18].includes(value.id) && data && !_.includes(this.props.planTogether, +data.id)) {
                console.log('cannot select this');
                isAllow = false;
                this.props.openCannotSelectModal(value.id);
            }
        }

        if (isAllow === true) {
            this.props.onSelect(value);
            this.setState({
                selected: value,
            }, () => {
                this.props.toggleModal(e,'discount_condition')
            });
        }
    }

    render() {

        return(
            <Fragment>
                <Modal isOpen={this.props.isShowModal}
                       className="modal-dialog modal-dialog-centered modal-additional modal_custom_01 "
                       contentClassName=""
                       toggle = {(e) => this.props.toggleModal(e,'discount_condition')}
                       onOpened = {this.onOpened}
                       onClosed = {this.onCloseModal}
                       // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">유무선 결합 상품</h5>
                        {/*<button type="button" className="close close-modal"*/}
                                {/*onClick={(e) => this.props.toggleModal(e,'discount_condition')}*/}
                        {/*>*/}
                            {/*<img src="/images/icon-close.svg" alt=""/>*/}
                        {/*</button>*/}
                    </div>
                    <div className="modal-body">
                        <ul className="list-additional list-modal-wiress">
                            <li className={!this.props.selected ? "active" : "" }
                                onClick={() =>  this.setState({
                                    selected: null
                                }, async (e) => {
                                    await this.props.toggleModal(e, 'discount_condition')
                                    this.props.onSelect(null);
                                })}
                                //onClick={(e) => this.onSelect(e, null)}
                            >
                                <div className="flex-title mb-0">
                                    <h4 className="title  mb-0">
                                        미적용
                                    </h4>
                                </div>
                            </li>
                            {
                                this.state.list && this.state.list.map( (value, key) => {
                                    return (
                                        <li key={key}
                                            // className={`
                                            //     ${this.props.selected && this.props.selected.id == value.id ? "active" : ""}
                                            // `}
                                            className={`
                                                ${this.props.selected && this.props.selected.id == value.id ? "active" : ""}
                                                ${_.includes([4, 10, 12, 17], value.id) ? "d-none" : ""}
                                            `}
                                            onClick={(e) => this.onSelect(e, value)}
                                        >
                                            <div className="flex-title mb-0">
                                                <h4 className="title mb-0">
                                                    {value.name} {([5, 8, 10, 11, 14, 15].includes(+value.id) && value.name === '가족무한사랑'
                                                    ? "(유무선신규가입불가)" : ([5, 8, 10, 11, 14, 15].includes(+value.id) ? "(신규가입불가)" : ""))} {/*Task 6869 - Flow*/}
                                                </h4>
                                            </div>
                                            {
                                                this.props.adviceType == ADVICE_TYPE.WIRELESS && (
                                                    <p className="mb-0 text-gray-white">
                                                        {
                                                            _.includes([16, 11, 13, 18], value.id) ? "유무선/무선"
                                                                :
                                                                (
                                                                    value.type && value.type.id == 104 ? "유무선" : (value.type && value.type.id == 105 ? "무선": "")
                                                                )
                                                        }
                                                    </p>)
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

DiscountConditionModal.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
    adviceType: PropTypes.string,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(DiscountConditionModal);
