import React, { Component,PureComponent, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';
import {getFormValues} from 'redux-form';
import {hideLoading, showLoading} from "../../../../store/actions/ui";
import api from "../../../../utils/api";
import PropTypes from 'prop-types';
import _ from "lodash";
class TeenagersPromotion extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            list: [],
            selected: null,
            error_modal: {
                isShowModal: false,
                message: ""
            }
        };
    }

    toggleErrorModal = (e, modal) => {
        e && e.preventDefault();
        this.setState({
            [modal]: {
                ...this.state[modal],
                isShowModal: !this.state[modal].isShowModal
            }
        })
    }




    /**
     * close modal
     */
    onCloseModal = (e) => {
        this.props.onSelect(this.state.selected, "teenagers_promotion");
    }


    /**
     * detect open modal
     */
    onOpened = () => {
        let plans_line_not_null = _.reject(this.props.plans, {plan: null});// số plan đã chọn
        this.setState({
            list: plans_line_not_null,
            selected: this.props.selected
        })
    }

    onSelect = (e, value) => {
        let selected = [...this.state.selected];
        if(value){
            if(this.state.selected && this.state.selected.length > 0){
                var index = _.findIndex(this.state.selected, {idx: value.idx});
                if(index < 0){
                    selected.push(value)
                }else{
                    selected.splice(index, 1);
                }
            }else{
                selected.push(value)
            }
        }else{
            selected = []
        }
        selected = _.orderBy(selected, ['idx'],['asc'])
        this.setState({
            selected: selected
        })

    }

    toggleModal = (e) => {
        e.preventDefault();
        if(this.state.isShowModal == false){
            let soldier = this.props.soldierLguplus;

            if(soldier != null) {
                this.setState({
                    error_modal: {
                        isShowModal: true,
                        message: (<span className="notification_delete">결합을 적용할 수 없습니다. 결합조건을 확인해 주세요.</span>)
                    }
                });
            }
            else{
                this.setState({
                    isShowModal: !this.state.isShowModal
                });
            }
        }
        else{
            this.setState({
                isShowModal: !this.state.isShowModal
            });
        }
    }



    cancelApply = (e) => {
        this.setState({
            selected: this.props.selected,
            isShowModal: false
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.selected && prevProps.selected !== this.props.selected){
            this.setState({
                selected: this.props.selected
            })
        }
    }

    render() {
        return(
            <Fragment>
                <div className="form-group">
                    <label className="text-name text-wrap text-bold">청소년할인 프로모션
                    </label>
                    <button className="btn btn-input-form h-32 mb-0" type="button"
                            onClick={(e) => this.toggleModal(e)}
                    >
                        { this.props.selected && this.props.selected.length > 0
                            ? `적용 ${this.props.selected.map(val => {
                                return `/ 회선${val.idx}`
                            }).join(" ")}`
                            : "미적용"
                        }
                        {/*{ this.props.selected ? `회선 ${this.props.selected.idx}`  : (this.state.selected ? `회선 ${this.state.selected.idx}`  : "미적용")}*/}
                    </button>
                    <Modal isOpen={this.state.isShowModal}
                           className="modal-dialog modal-dialog-centered modal-additional modal-small"
                           contentClassName="pl-4 pr-4"
                           toggle = {(e) => this.toggleModal(e)}
                           onOpened = {this.onOpened}
                           onClosed = {this.onCloseModal}
                           // backdrop={"static"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">청소년할인 프로모션 (만18세이하)</h5>
                            <button type="button" className="close close-modal"
                                    onClick={(e) => this.toggleModal(e)}
                            >
                               <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-additional max-auto mb-5">
                                <li className={!this.state.selected || this.state.selected.length <= 0 ? "active" : "" }
                                    onClick={(e) =>  this.onSelect(e, null)}
                                >
                                    미적용
                                </li>
                                {
                                    this.state.list.length > 0 ? this.state.list.map( (value, key) => {
                                        return this.props.discount_line_cnt && key < this.props.discount_line_cnt ? (
                                            <li key={key}
                                                className={this.state.selected
                                                                    && this.state.selected.length > 0
                                                                    && _.findIndex(this.state.selected, {idx: value.idx}) >= 0
                                                                    ? "active"
                                                                    : ""}
                                                onClick={(e) => this.onSelect(e, value)}
                                            >
                                                회선 { value.idx}
                                            </li>
                                        ) : null
                                    }) : null
                                }
                            </ul>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 mb-4 mx-0 px-0">
                            <button type="button" className="btn btn-gray pr-5 pl-5 mx-1"
                                    onClick={(e) => this.cancelApply(e)}
                            >취소</button>
                            <button type="button"
                                    className="btn btn-submit pl-5 pr-5 mx-1"
                                    onClick={(e) => this.toggleModal(e)}
                            >확인</button>
                        </div>
                    </Modal>
                    <Modal isOpen={this.state.error_modal.isShowModal}
                       className="modal-dialog modal-dialog-centered"
                       contentClassName="pl-4 pr-4"
                       toggle={(e) => this.toggleErrorModal(e, 'error_modal')}
                    // onOpened = {this.onOpened}
                    // onClosed = {this.onCloseModal}
                    // backdrop={"static"}
                >
                    <div className="modal-body text-center p-5">
                        {
                            this.state.error_modal.message
                        }
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 mb-4">
                        <button type="button" className="btn btn-submit pl-5 pr-5"
                                onClick={(e) => this.toggleErrorModal(e, 'error_modal')}>확인
                        </button>
                    </div>
                </Modal>
                </div>
            </Fragment>
        );
    }
}

TeenagersPromotion.propTypes = {
    toggleModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    onSelect: PropTypes.func,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(TeenagersPromotion);