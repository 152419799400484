import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import CreateReportStep1 from './SaleCreateComponent/CreateReportStep1';
import CreateReportStep2 from './SaleCreateComponent/CreateReportStep2';
class SaleCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 2
        }
    }

    componentDidMount() {

    }

    nextPage = () => {
        this.setState({ page: this.state.page + 1 })
    }

    previousPage = () => {
        this.setState({ page: this.state.page - 1 })
    }

    setPage = (page) => {
        this.setState({ page: page })
    }

    renderCreateReportComponent() {
        let xhtml = null;
        if (this.state.page == 1) {
            xhtml = <CreateReportStep1 history={this.props.history} onSubmit={this.nextPage} />;
        } else if (this.state.page == 2) {
            xhtml = <CreateReportStep2 history={this.props.history} previousPage={this.previousPage}
                onSubmit={this.nextPage} />;
        } 

        return xhtml;
    }


    render() {
        return (
            <Fragment>
                    <div className="main-wrapper">
                    <section className="wired-consultation">
                        <div className="container">
                            <div className="report-sale-step main-step-box">
                            <ul className="step-wireless">
                                <li className="item-step active">
                                    <div className="open-item">
                                        <span className="step-name">Step 1</span>
                                        <span>개통정보</span>
                                    </div>
                                    <div className="content-complete">
                                        <img src="../../images/box-04.svg" alt=""/>
                                    </div>              
                                </li>
                                <li className="item-step">
                                    <div className="open-item">
                                        <span className="step-name">Step 2</span>
                                        <span>차감정보</span>
                                    </div>
                                </li>
                            </ul>
                            <button className="btn btn-gray btn-edit-form">상담수정</button>
                            <div className="box-right">
                                <div className="row">
                                    <div className="col-6 text-right">
                                        <span className="title">고객명</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="content">고객명</span>
                                    </div>
                                    <div  className="col-6 text-right">
                                        <span className="title">단말기</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="content">아이폰 11 (64GB)</span>
                                    </div>
                                    <div className="col-6 text-right">
                                        <span className="title">가입유형</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="content">신규가입</span>
                                    </div>
                                </div>
                            </div>
                            {this.renderCreateReportComponent()}
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};




const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
)(SaleCreate);

