import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
// import CloseIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
// import { Modal } from '@material-ui/core';
import * as modalActions from './../../store/actions/modal';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
// import styles from './styles';


class CommonModal extends Component {
    constructor(props) {
        super(props);
        this.wrapperUserRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // if (this.wrapperUserRef && this.wrapperUserRef.current && !this.wrapperUserRef.current.contains(event.target)) {
        // }
    }

    handleHideModal = () => {
        const { classes, open, component, modalActionCreators, title, toggle, class_n, close, html_title, html_footer, class_footer, backdrop, center } = this.props;
        const { hideModal, showModal } = modalActionCreators;
        hideModal()
    }

    onClosed = () => {
        const { classes, open, component, modalActionCreators, title, toggle, class_n, close, html_title, html_footer, class_footer, backdrop, center } = this.props;
        const { clearModal, showModal } = modalActionCreators;
        clearModal()

        setTimeout(() => {
            if (document.getElementById('notice-id')) {
                document.getElementById('notice-id').click()
                document.getElementById('notice-id').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }, 100)
    }

    render() {
        
        const { classes, open, component, modalActionCreators, title, toggle, class_n, close, html_title, html_footer, class_footer, backdrop, center } = this.props;
        const { hideModal, showModal } = modalActionCreators;

        return (
            <Modal isOpen={open}
                toggle={backdrop === 'static' ? null : this.handleHideModal}
                className={class_n}
                fade={true}
                // backdrop={backdrop || true}
                centered={center}
                ref={this.wrapperUserRef}
                onClosed={this.onClosed}
            >
                {close && html_title ? (
                        <div className="pl-4 pr-4">
                            <ModalHeader>
                                <div  dangerouslySetInnerHTML={{ __html: html_title }} />
                                <button type="button" className="close close-modal" onClick={() => this.handleHideModal()}>
                                    <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                                </button>
                            </ModalHeader>
                        </div>
                    )
                    : (close) ?
                        (<ModalHeader className="border-bottom-0">
                            <button type="button" className="close close-modal" onClick={() => this.handleHideModal()}>
                                <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                            </button>
                        </ModalHeader>)
                        : ''}




                <ModalBody>

                    {component}
                </ModalBody>

                {html_footer ? (
                        <ModalFooter className={`justify-content-center ${class_footer}`}>
                            {html_footer}
                        </ModalFooter>
                    )
                    : ''}
            </Modal>
        );

    }
}

CommonModal.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    open: PropTypes.bool,
    component: PropTypes.object,
    modalActionCreators: PropTypes.shape({
        hideModal: PropTypes.func,
        clearModal: PropTypes.func,
    }),
    html_footer: PropTypes.object,
    backdrop: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        open: state.modal.showModal,
        component: state.modal.component,
        title: state.modal.title,
        toggle: state.modal.toggle,
        class_n: state.modal.class_n,
        close: state.modal.close,
        html_title: state.modal.html_title,
        html_footer: state.modal.html_footer,
        class_footer: state.modal.class_footer,
        backdrop: state.modal.backdrop,
        center: state.modal.center
    };
};

const mapDispatchToProps = dispatch => {
    return {
        modalActionCreators: bindActionCreators(modalActions, dispatch),
    };
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    // withStyles(styles),
    withConnect,
)(CommonModal);
