import React, { Component, Fragment } from 'react';
import { Modal} from 'reactstrap';
import { connect } from 'react-redux';

import {showLoading, hideLoading} from './../../store/actions/ui';
import './PlanInformationModal.css'

class DescriptionDetailDeviceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    renderDescripton = () => {
        const {devicesDetail}=this.props
        if(devicesDetail.length > 0) {
            let dataRender=devicesDetail[0].info && devicesDetail[0].info.v_device ? devicesDetail[0].info?.v_device?.extra_desc : undefined


            if(dataRender != undefined) {
                return (<div dangerouslySetInnerHTML={{__html: dataRender}} />);
                // dataRender=dataRender.split(/\r\n|\r|\n/g)
                //
                // return dataRender.map((item, key) => {
                //     if(item != '') {
                //         return (<div key={key}>{item.replace(/<\/?[^>]+(>|$)/g, "")}</div>);
                //     }
                //
                // })
            }
        }

    }



    render() {
        const {devicesDetail}=this.props
        return(
            <Fragment>

                <Modal isOpen={this.props.isOpenModal}
                       fade={true} className={'modal-dialog modal-dialog-centered modal-lg'} contentClassName="modal-content content pl-5 pr-5"
                       toggle = {this.props.cloaseModal}
                       // backdrop={"static"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title text-left w-100">단말기 설명</h5>
                        <button type="button" className="close close-modal" data-dismiss="modal" aria-label="Close" onClick={this.props.cloaseModal}>
                        <img src={process.env.REACT_APP_HOSTNAME + "/images/icon-close.svg"} alt="" />
                        </button>
                    </div>

                    <div className="modal-body content-product modal-detail-mobile mt-0 pl-0 pr-0">
                        <div className="left-product">
                            {this.renderDescripton()}
                        </div>
                    </div>
                </Modal>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    };
};

export default connect(mapStateToProps ,mapDispatchToProps )(DescriptionDetailDeviceModal);
