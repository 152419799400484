
 
import renderTextField from '../../../components/FormHelper/TextField';
import renderTextFieldAsync from '../../../components/FormHelper/TextFieldAsync';
import renderRadioField from '../../../components/FormHelper/RadioField';
import renderTextFields from '../../../components/FormHelper/TextFieldArray';
import renderCheckboxField from '../../../components/FormHelper/CheckboxField';
import renderSelectField from '../../../components/FormHelper/SelectField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta } from 'redux-form';
import validate from './validate-step-2';
import { showLoading, hideLoading } from '../../../store/actions/ui';
import * as routerName from '../../../utils/routerName';
import api from "../../../utils/api";
import { SubmissionError } from 'redux-form';

class CreateReportStep2 extends Component {

    componentDidMount() {
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <section className="form-box mt-5">
                <form action>
                    <div className="content-form">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="title-box-line mb-3">
                                                <span>단말기 판매 정보</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">단말기</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="아이폰 11 (64GB)" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">출고가</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="374,500원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">공시지원금</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="140,000원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">할부원금</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="234,000 원" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">판매점 할인 (1)</label>
                                                <input type="text" className="form-control h-44" placeholder="0 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">판매점 할인 (2)</label>
                                                <input type="text" className="form-control h-44" placeholder="0 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">할인메모</label>
                                                <input type="text" className="form-control h-44" placeholder="할인메모" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">선할인카드</label>
                                                <input type="text" className="form-control h-44" placeholder="240,000원" />
                                                <p className="font-10 text-right">
                                                    SKT Card Samsung 2 V2
               </p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">포인트 할인</label>
                                                <input type="text" className="form-control h-44" placeholder="10,000 원" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="title-box-line mt-5 mb-3">
                                                <span>R/B 정보 (추가및 차감)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">기본 R/B</label>
                                                <input type="text" className="form-control h-44" placeholder="0 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">추가 R/B (1)</label>
                                                <input type="text" className="form-control h-44" placeholder=" 0 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">R/B 내역 메모</label>
                                                <input type="text" className="form-control h-44" placeholder=" R/B 내역 메모 " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3" />
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">추가 R/B (2)</label>
                                                <input type="text" className="form-control h-44" placeholder=" 0 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">R/B 내역 메모</label>
                                                <input type="text" className="form-control h-44" placeholder=" R/B 내역 메모 " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-add"><span>+</span>추가 R/B (3)</button>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3" />
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">차감 R/B (1)</label>
                                                <input type="text" className="form-control h-44" defaultValue=" - 10,000 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">R/B 내역 메모</label>
                                                <input type="text" className="form-control h-44" placeholder=" R/B 내역 메모" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3" />
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">차감 R/B (2)</label>
                                                <input type="text" className="form-control h-44" defaultValue=" - 0 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">R/B 내역 메모</label>
                                                <input type="text" className="form-control h-44" placeholder=" R/B 내역 메모" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-add"><span>+</span>차감 R/B (3)</button>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3" />
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">차감 R/B (2)</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="4,000 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor className="label-text">MNP 수수료</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="custom-control custom-radio mr-0">
                                                        <input type="radio" id="item001" name="customRadio" className="custom-control-input" defaultChecked />
                                                        <label className="custom-control-label" htmlFor="item001">미적용</label>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="custom-control custom-radio mr-0">
                                                        <input type="radio" id="customRadio22523442" name="customRadio" className="custom-control-input" defaultChecked="true" />
                                                        <label className="custom-control-label" htmlFor="customRadio22523442">800 원</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <span className="label-text">리베이트 총액 (추가+차감)</span>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="XX,XXX 원" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="title-box-line mt-3 mb-3">
                                                <span>고객입금</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">현금</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="0 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">카드</label>
                                                <input type="text" className="form-control h-44 bg_input" defaultValue="0 원" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">카드 내역 메모</label>
                                                <input type="text" className="form-control h-44" defaultValue="카드 내역 메모" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="title-box-line mt-3 mb-3">
                                                <span>정산</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">무선</label>
                                                <input type="text" className="form-control h-44" disabled defaultValue="4,000 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">유선</label>
                                                <input type="text" className="form-control h-44" placeholder=" 0 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">정산(계)</label>
                                                <input type="text" className="form-control h-44" disabled defaultValue="XX,XXX 원" />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">실매출</label>
                                                <input type="text" className="form-control h-44" disabled defaultValue="XX,XXX 원" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group ">
                                                <label htmlFor className="label-text">세금비율</label>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="custom-control custom-radio mr-0">
                                                            <input type="radio" id="item0012" name="customRadio" className="custom-control-input" defaultChecked />
                                                            <label className="custom-control-label" htmlFor="item0012">0%</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="custom-control custom-radio mr-0">
                                                            <input type="radio" id="item0013" name="customRadio" className="custom-control-input" defaultChecked />
                                                            <label className="custom-control-label" htmlFor="item0013">10%</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="custom-control custom-radio mr-0">
                                                            <input type="radio" id="item0014" name="customRadio" className="custom-control-input" defaultChecked="true" />
                                                            <label className="custom-control-label" htmlFor="item0014">13%</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-btn-submit mt-5">
                                        <div className="group-button w-100">
                                            <button className="btn btn-button btn-gray mr-2 ml-2" type="button">취소</button>
                                            <button type="button" className="btn btn-button btn-submit mr-2 ml-2">판매일보 저장</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        );
    }
}

CreateReportStep2.propTypes = {
    handleSubmit: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
    }
};
const FORM_NAME = '';


const withReduxForm = reduxForm({
    form: FORM_NAME,
    validate,
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(CreateReportStep2);
