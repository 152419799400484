
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change, FormSection } from 'redux-form';
import {numberWithCommas, priceFormat, roundUpNumber} from "../../../utils/helper";
import SupportMoneyModalOne from './SupportMoneyModalOne';
class WirelessAdditionalGrants extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      money: 69000,
      moneyDefault: 69000,
      installmentMoney: 470000
    }
  }
  showModal = () => {
    this.setState({
        modal: !this.state.modal
    })
  }

  toggle = () => {
    const {WirelessAdvice: { WirelessAdviceStep3 } } = this.props;

    if(+(WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190 && +(WirelessAdviceStep3?.cashPayment || 0) > 0){
      this.showModal();
    }
    else{
      if (+WirelessAdviceStep3.additionalGrants == 0 && +WirelessAdviceStep3.installmentPrincipal == 0) {
        alert(`현재 할부원금은 0원입니다.\n더이상 할인옵션을 추가하실 수 없습니다.`);
      }
      else {
        this.showModal();
      }
    }

    

    return;

    /* 6841 - Yêu cầu khi tiền hỗ trợ thêm bằng - vẫn cho click vô để chỉnh sửa lại */
    // if (+WirelessAdviceStep3.installmentPrincipal <= 0) {
    //   alert('현재 할부원금은 0원입니다.\n더이상 할인옵션을 추가하실 수 없습니다')
    //   return;
    // }

    // if ((parseInt(WirelessAdviceStep3.startingPrice) - parseInt(WirelessAdviceStep3.disclosureSubsidy)) == 0) {
    //   alert('금액은 원 0원 이하로 입력해 주세요.')
    // }else{
    //   this.showModal()
    // }
  }

  toggleEvent = () => {
    this.setState({
      modal: false
    })
  }

  formatNumberTens = (n) => {
    let number =  n.toFixed(2).replace(/(\d)(?=(\d{2})+\.)/g, '$1,');
    let arr = number.split(',');
    arr[(arr.length - 1)] = '00';
    let result = arr.join().split(",").join("");
    return +result;
  }

  getValue = (data, value) => {
    const {WirelessAdvice: { WirelessAdviceStep3 } } = this.props;
    // const result = WirelessAdviceStep3.installmentPrincipal - data;
    // if (result < 0) {
    //   data = parseInt(WirelessAdviceStep3.installmentPrincipal);
    // }

    // let number  = this.formatNumberTens(data);
    let number = Math.floor(data / 10) * 10;
    this.props.change('WirelessAdviceStep3.additionalGrants', number)
    this.props.change('WirelessAdviceStep3.additionalGrantsType', value)
  }
  render() {
    const { handleSubmit, WirelessAdvice } = this.props;

    /** task 6841 **/
    let validPrice = parseInt(WirelessAdvice.WirelessAdviceStep3.startingPrice) - parseInt(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy);
    // let validPrice = parseInt(WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy) * 0.15;
    /** end task 6841 **/

    if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation){ // 중고폰 보상 프로그램 적용
      if(WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.telcomID == 18 && WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_id != 191){ // lguplus만 적용
        let instPrincipalUsedPhone2 = WirelessAdvice.WirelessAdviceStep3.startingPrice * WirelessAdvice.WirelessAdviceStep3.usedPhoneCompensation.tup_w2nd_rate / 100; // 2구간 할부원금

        validPrice = WirelessAdvice.WirelessAdviceStep3.installmentPrincipal - instPrincipalUsedPhone2 - (instPrincipalUsedPhone2 / 65 * 35) - 1;
      }
    }

    let additionalGrants = WirelessAdvice.WirelessAdviceStep3.additionalGrants;

    let result;
    let style = false;
    if (WirelessAdvice.WirelessAdviceStep3.optionFee == 0) {
      result = '미적용';
    } else {
      if (WirelessAdvice.WirelessAdviceStep3.additionalGrants) {
        result = '-'+ priceFormat(WirelessAdvice.WirelessAdviceStep3.additionalGrants);
        style = true;
      } else {
        result = '미적용';
      }
    }
    return (
      <Fragment>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label label-text">공시추가지원</label>
          <div className="col-sm-8 m-left">
            <button className={`${style ? "btn btn-input-form mb-0 btn-prices-wp" : "btn btn-input-form mb-0"} ${result === '미적용' ? ' opacity-disabled ' : ''}`} type="button" onClick={this.toggle}>
              {result}
              { style ? (
                  <span>원</span>
                ) : ''
              }
            </button>
          </div>
        </div>
        {(+WirelessAdvice.WirelessAdviceStep3.additionalGrants > 0 || +WirelessAdvice.WirelessAdviceStep3.installmentPrincipal > 0)
          ? (
            <SupportMoneyModalOne
              showModal={this.state.modal}
              toggleEvent={this.toggleEvent}
              money={this.state.money}
              moneyDefault={this.state.moneyDefault}
              getValue={this.getValue}
              installmentMoney={this.state.installmentMoney}
              validPrice={validPrice}
              additionalGrants={additionalGrants}
              disclosureSubsidy={WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy}
              wirelessAdvice={WirelessAdvice}
            />
          )
          : +(WirelessAdvice.WirelessAdviceStep3?.usedPhoneCompensation?.tup_id || 0) == 190 && +(WirelessAdvice.WirelessAdviceStep3?.cashPayment || 0) > 0
            ? (
              <SupportMoneyModalOne
              showModal={this.state.modal}
              toggleEvent={this.toggleEvent}
              money={this.state.money}
              moneyDefault={this.state.moneyDefault}
              getValue={this.getValue}
              installmentMoney={this.state.installmentMoney}
              validPrice={+WirelessAdvice.WirelessAdviceStep3.cashPayment}
              additionalGrants={additionalGrants}
              disclosureSubsidy={WirelessAdvice.WirelessAdviceStep3.disclosureSubsidy}
              wirelessAdvice={WirelessAdvice}
            />
            )
            : null
        }
    </Fragment>
    );
  }
}

WirelessAdditionalGrants.propTypes = {
};

const mapStateToProps = state => {
  return {
    WirelessAdvice: getFormValues('WirelessAdvice')(state),
    WirelessAdviceErrors: getFormSyncErrors('WirelessAdvice')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
  }
};

const FORM_NAME = 'WirelessAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(WirelessAdditionalGrants);
