

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, FieldArray, getFormSyncErrors, getFormValues, getFormMeta, change } from 'redux-form';
import { showLoading, hideLoading } from '../../store/actions/ui';
import { openPopupMenu, allowAccessMenu } from '../../store/actions/auth';
import * as routerName from './../../utils/routerName';
import api from "./../../utils/api";
import { Modal } from 'reactstrap';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageJSON from "./../../utils/message.json";

class ResetSuccessPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isOpen: false,
            errors : {
            }
        }
    }

    componentDidMount() {
    }

    onClose = () => {
        this.props.toggle()
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen}
                       toggle={this.props.toggle}
                    className="modal-dialog modal-dialog-centered"
                    contentClassName="pl-4 pr-4"
                       // backdrop={"static"}
                >
                  
                    <div className="modal-body">
                        <div className="content-register-modal">
                            <div className="img-check">
                                <img src="/images/Icon-modal-01.svg" alt="" />
                            </div>
                            <h4 className="text-center">
                                임시비밀번호가 전송 완료되었습니다.
                            </h4>
                            <p className="text-center mt-3">
                                {/* 비밀번호는 (010) 1234 - **** 전화번호로 전송되었습니다. */}
                                {this.props.msgNotify}
                            </p>
                            <button type="button" className="btn btn-secondary btn-ok" data-dismiss="modal" aria-label="Close" 
                                    onClick={() => this.onClose()}> 확인  
                            </button>
                            
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        change: () => dispatch(change()),
        allowAccessMenu: (data) => dispatch(allowAccessMenu(data)),
        openPopupMenu: (data)  => dispatch(openPopupMenu(data)),
    }
};
const FORM_NAME = '';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    withReduxForm,
)(ResetSuccessPopup);
