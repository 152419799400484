import React, {Fragment} from 'react';


class ListTabDevice extends React.Component{
  render() {
    return(
      <ul className="nav nav-tabs list-tab" >
        <li className="nav-item tab-item" key={0}  >
          <button
            className={this.props.mfID == '0' ? 'btn nav-link active' : 'nav-link'} id="all"
            onClick={() => this.props.onClickSelectedManufacture('0')}>전체</button>
        </li>
        { this.props && this.props.manufacturers && this.props.manufacturers.map((item, k) =>
          <Fragment key={k}>
            <li className="nav-item tab-item" >
              <button
                className={this.props.mfID == item.id ? 'btn nav-link active' : 'nav-link'}
                id={k}
                onClick={() => this.props.onClickSelectedManufacture(item.id)}>{item.name}
              </button>
            </li>
          </Fragment>
        )}
        <li className="nav-item tab-item"  key={-1} >
          <button
            className={this.props.mfID == 'other' ? 'btn nav-link active' : 'nav-link'}
            id="other"
            onClick={() => this.props.onClickSelectedManufacture('other')}
          >
            기타
          </button>
        </li>
      </ul>
    )
  }
}

export default ListTabDevice;
