import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import {showLoading, hideLoading} from '../../../store/actions/ui';
import { priceFormat } from '../../../utils/helper';

const setupFee = ({WiredAdvice}) => {
  let total = 0;
  let setupFee = WiredAdvice.WiredAdviceStep2.setupFee || []

  let setupFee_night = WiredAdvice.WiredAdviceStep2.setupFee || [] //야간 설치 비용

  //let telcomID = WiredAdvice.WiredAdviceStep1.telcomID;
  //let telcom = WiredAdvice.WiredAdviceStep2.telcomID || []

  //console.log("값 확인을 위해 필요",WiredAdvice.WiredAdviceStep1.telcomID);
  setupFee.map((e) => {total += +((e.fee + '').split(',').join(''))})

  return (
    <Fragment>
      <div>
        <span className="font-weight-bold font-14">설치비(별도청구금액)</span>
      </div>
      <div className="installation-cost-social">
        <table>
          <tr>
            <td>
              
            {setupFee.map((item, key) => (
            <span className="list-cost-social" title={item.name} key={key}>
            {item.icon.split('+').map((icon, iKey) => (
              <img
                key={iKey}
                src={icon}
                alt={item.name}
                className="ml-1 mr-1"
              />
            )).reduce((prev, curr) => [prev, '+', curr])}
            <span>(주간)</span>
            <span>{priceFormat((item.fee + '').split(',').join(''))}원</span>
            </span>
            ))}
            </td>
            <td>
            {setupFee_night.map((item, key) => (
            <span className="list-cost-social" title={item.name} key={key}>
            {item.icon.split('+').map((icon, iKey) => (
              <img
                key={iKey}
                src={icon}
                alt={item.name}
                className="ml-1 mr-1"
              />
            )).reduce((prev, curr) => [prev, '+', curr])}
            <span>(야간)</span>
            <span>{priceFormat((item.fee_night + '').split(',').join(''))}원</span>
          </span>
        ))}
            </td>
          </tr>
        </table>    

      </div>
      <div className="installation-cost">
        <small className="text-muted">(VAT 포함)</small>
        <span className="px-2">
          <span className="total-price">(주간기준){priceFormat(total)}</span>
          <span className="price-sub">원</span>
        </span>

      
      </div>
    </Fragment>
  );
}

setupFee.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    WiredAdvice: getFormValues('WiredAdvice')(state),
    WiredAdviceErrors: getFormSyncErrors('WiredAdvice')(state),
    telcomParents: state.dataStore.telcomProviders.telcomParents,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    change: () => dispatch(change()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }
};

const FORM_NAME = 'WiredAdvice';

const withReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withReduxForm,
)(setupFee);
