export const flashMessageActions = {
    SHOW_MESSAGE: 'FLASH_MESSAGE/SHOW',
    HIDE_MESSAGE: 'FLASH_MESSAGE/HIDE'
}

export const userActions = {
    GET_CURRENT_USER_SUCCEED: 'USER/GET_CURRENT_USER_SUCCEED',
    LOGOUT_SUCCEED: "USER/LOGOUT_SUCCEED",
    LOGIN_SUCCEED: "USER/LOGIN_SUCCEED",
    PROFILE_SUCCEED: "USER/PROFILE_SUCCEED",
    ALLOW_ACCESS_MENU: "ALLOW_ACCESS_MENU",
    OPEN_POPUP_MENU: "OPEN_POPUP_MENU",
    UPDATE_PASSWORD_MENU: "UPDATE_PASSWORD_MENU",
    UPDATE_EXPIRED_DATE: "UPDATE_EXPIRED_DATE",
    UPDATE_EXPIRE_DATE: "UPDATE_EXPIRE_DATE",
    UPDATE_OPTION_PRINT: "UPDATE_OPTION_PRINT",
}

export const modalActions = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: "HIDE_MODAL",
    CLEAR_MODAL: "CLEAR_MODAL",
    CHANGE_MODAL_TITLE: "CHANGE_MODAL_TITLE",
    CHANGE_MODAL_CONTENT: "CHANGE_MODAL_CONTENT",
}

export const dataActions = {
    GET_PRICE_PACKAGES: 'GET_PRICE_PACKAGES',
    GET_TELCOM_PROVIDERS: 'GET_TELCOM_PROVIDERS',
    GET_BANNERS: 'GET_BANNERS',
    GET_MANUFACTURERS: 'GET_MANUFACTURERS',
    GET_WIRELESSADVICES: 'GET_WIRELESSADVICES',
    GET_USED_PROGRAMS: 'GET_USED_PROGRAMS',
    APPLY_USED_PROGRAM: 'APPLY_USED_PROGRAM',
    GET_PRICES: 'GET_PRICES',
    GET_GLOBAL_DATA_AUTH: 'GET_GLOBAL_DATA_AUTH',
    UPDATE_DATA_NOTICES: 'UPDATE_DATA_NOTICES',
    UPDATE_DATA_DOCUMENTS: 'UPDATE_DATA_DOCUMENTS',
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    LOADMORE_NOTIFICATIONS: 'LOADMORE_NOTIFICATIONS',
    CLOSE_POPUP_ADS_DOCUMENT: 'CLOSE_POPUP_ADS_DOCUMENT',
    CLOSE_POPUP_ADS_NOTICE: 'CLOSE_POPUP_ADS_NOTICE',
}


export const SHOW_LOADING = 'GLOBAL_SHOW_LOADING';
export const HIDE_LOADING = 'GLOBAL_HIDE_LOADING';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';


export const DISCOUNT_CARD = {
    GET_DISCOUNT_CARD_SUCCESS : 'GET_DISCOUNT_CARD_SUCCESS'
}
export const GET_COST_DISPLAY_PLAN = {
    GET_COST_DISPLAY_PLAN_SUCCESS : 'GET_COST_DISPLAY_PLAN_SUCCESS'
}


export const SIM = {
    GET_SIM_SUCCESS : 'GET_SIM_SUCCESS'
}

export const SUBSCRIPTIONFEE = {
    GET_SUBSCRIPTIONFEE_SUCCESS : 'GET_SUBSCRIPTIONFEE_SUCCESS'
}
export const PLAN_INFORMATION = {
    GET_PLAN_INFORMATION_SUCCESS : 'GET_PLAN_INFORMATION_SUCCESS',
    GET_PLAN_NOTICE_INFORMATION_SUCCESS : 'GET_PLAN_NOTICE_INFORMATION_SUCCESS',
}

//
export const PLAN_CLASSIFICATIONS_INFORMATION = {
    GET_PLAN_INFORMATION_SUCCESS : 'GET_PLAN_INFORMATION_SUCCESS',
    GET_PLAN_NOTICE_INFORMATION_SUCCESS : 'GET_PLAN_NOTICE_INFORMATION_SUCCESS',
}

export const POST_APPLY_FEE = {
    POST_APPLY_FEE : 'POST_APPLY_FEE'
}

//

export const RENDER_BUTTON_CHAT = 'RENDER_BUTTON_CHAT';

// 0216
export const GET_HYOSUNG_EXPIRED_DATE = {
    GET_HYOSUNG_EXPIRED_DATE_SUCCESS : 'GET_HYOSUNG_EXPIRED_DATE_SUCCESS'
}
//

// 0226
export const POST_BOOKMARKS_FORM = {
    POST_BOOKMARKS_FORM_SUCCESS : 'POST_BOOKMARKS_FORM_SUCCESS'
}
//